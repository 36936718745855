import { useState, useEffect, useContext } from 'react';
import ReactApexChart from 'react-apexcharts';

import { useTheme, styled } from '@mui/material/styles';
import {
  Card,
  CardHeader,
  useMediaQuery,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Container
} from '@mui/material';
import { UserListHead, UserListToolbar } from '../../../components/_dashboard/user';
import { CustomFormModel } from '../../../components/custom-form-model/CustomFormModel';
import inputContex from '../../../Context/UploadContext/inputContext';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

// const CHART_DATA = [4344, 5435, 1443, 4443];

export default function BarChart({ data, handleDialog, agentBar, customerBar }) {
  const { toneIndex, setToneIndex } = useContext(inputContex);
  const [test, setTest] = useState(false);
  // console.log('data-----', data);
  useEffect(() => {
    if (test) {
      // if (toneIndex.data !== 2) {
      handleDialog();
      setTest(false);
      // }
    }
  }, [test]);

  const options = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false
      },
      events: {
        dataPointSelection: (config, event, chartContext) => {
          console.log('config----', config);
          console.log('event----', event);
          console.log('agentBar,customerBAr ---------', agentBar, customerBar);
          console.log('chartContext----', chartContext.dataPointIndex);
          if (agentBar) {
            setToneIndex({ data: chartContext.dataPointIndex, isAgent: true, isCustomer: false });
          } else {
            setToneIndex({ data: chartContext.dataPointIndex, isAgent: false, isCustomer: true });
          }
          if (chartContext.dataPointIndex !== 2) {
            setTest(true);
          }
        }
      }
    },
    colors: ['rgb(247, 45, 72)', 'rgb(136, 132, 216)', 'rgb(130, 202, 157)'],
    plotOptions: {
      bar: {
        cursor: 'pointer',
        columnWidth: '30%',
        distributed: true
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      labels: {
        style: {
          colors: ['rgb(247, 45, 72)', 'rgb(136, 132, 216)', 'rgb(130, 202, 157)'],
          fontSize: '12px'
        }
      }
    }
  };
  const series = [
    {
      data: [
        {
          x: 'High',
          y: data[0]
        },
        {
          x: 'Medium',
          y: data[1]
        },
        {
          x: 'Low',
          y: data[2]
        }
      ]
    }
  ];

  return (
    <>
      <Card sx={{ padding: 1 }}>
        {/* <CardHeader title={`Overall Sentiments: ${chart_details.Overall_Sentiment}`} /> */}
        {/* <ChartWrapperStyle dir="ltr"> */}
        <ReactApexChart
          style={{ border: '1 px solid red' }}
          type="bar"
          series={series}
          options={options}
          height={320}
        />
        {/* </ChartWrapperStyle> */}
      </Card>
    </>
  );
}
