import React, { useState } from 'react';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useFormik } from 'formik';
// material
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Container, Stack, Typography } from '@mui/material';
// Components
import Page from '../components/Page';
import API from '../E2E/axios.util';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import CustomerForm from '../layouts/Customer/CustomerForm';
import { CustomerDataModel } from '../layouts/Customer/CustomerDataModel';
import { Notification } from '../components/Notification/Notification';

export default function Customer() {
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [open, setOpen] = useState();
  const [update, setUpdate] = useState(0);
  const [customerList, setcustomerList] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const userDetails = JSON.parse(localStorage.getItem('user'));
  console.log('userDetailes --', userDetails);

  const CustomerSchema = Yup.object().shape({
    customerFirstName: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{3,30}$/,
        'First name should in between 3 to 30 character long and it should not start with space and does not contain special character and number.'
      )
      .max(30, 'First name should be in between 3 to 30 character long. ')
      .required('This field is required.'),
    customerLastName: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{3,30}$/,
        'Last name should in between 3 to 30 character long and it should not start with space and does not contain special character and number.'
      )
      .max(30, 'Last name should be in between 3 to 30 character long .')
      .required('This field is required.'),
    customerOrganisation: Yup.string().required('This field is required.'),
    customerEmail: Yup.string()
      .email('Email must be valid email address.')
      .matches(/^[\w-.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, 'Email must be a valid email address.')
      .required('This field is required.'),
    customerMobile: Yup.string()
      .matches(/^((\+91-?)|\+)?[0-9][0-9]{9}$/, 'Invalid contact number.')
      .required('This field is required.'),
    customerOrgName: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{3,40}$/,
        'Customer Organisation name should be 3 to 40 character long and it should not start with blank space and not have special character and number.'
      )
      .max(40, 'Customer Organisation Name should be in between 3 to 40 character long.')
      .required('This field is required.'),
    process: Yup.array().of(
      Yup.string()
        .matches(
          /^(?!^ )[A-Za-z ]{3,30}$/,
          'Process should be in between 3 to 30 character long and it should not start with space and does not contain special character and number.'
        )
        .max(30, 'Process should be in between 3 to 30 character long.')
        .required('This field is required.')
    ),
    businessDomain: Yup.string().required('This field is required.'),
    dispositionLevel: Yup.string().when('dispositionEnable', {
      is: true, // When param1 is true
      then: Yup.string().required('Disposition Level is required.'), // param2 is required
      otherwise: Yup.string() // Otherwise, no validation
    }),
    language: Yup.array().min(1, 'This field is required.'),
    customerServices: Yup.array().min(1, 'This field is required.'),
    dashboardServices: Yup.array().min(1, 'This field is required.')
    // dashboardServices: Yup.string().required('This field is required.')
    // AwsServices: Yup.bool().required(false, 'Field must be checked'),
    // CustomAiServices: Yup.boolean().required('This field is required.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerFirstName: '',
      customerLastName: '',
      customerOrganisation: `${
        userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : ''
      }`,
      customerEmail: '',
      customerMobile: '',
      process: [''],
      businessDomain: '',
      dispositionEnable: false,
      dispositionLevel: '',
      logo: '',
      customerOrgName: '',
      language: [],
      customerServices: [],
      AwsServices: false,
      // NvidiaServices: false,
      CustomAiServices: false,
      dashboardServices: []
      // dashboardServices: false
    },
    validationSchema: CustomerSchema,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        first_name: formik.values.customerFirstName,
        last_name: formik.values.customerLastName,
        email: formik.values.customerEmail,
        mobile: formik.values.customerMobile,
        customer_organisation_name: formik.values.customerOrgName,
        logo: formik.values.logo,
        organisation_id: formik.values.customerOrganisation,
        processes: formik.values.process,
        domain_id: formik.values.businessDomain,
        intent_disposition: formik.values.dispositionEnable,
        level_1: formik.values.dispositionLevel === 'level_1',
        level_2: formik.values.dispositionLevel === 'level_2',
        level_3: formik.values.dispositionLevel === 'level_3',
        customer_language: formik.values.language,
        kpi_services: formik.values.customerServices,
        aws_services: formik.values.AwsServices,
        // nvidia_services: formik.values.NvidiaServices,
        custom_ai_services: formik.values.CustomAiServices,
        dashboard_services: formik.values.dashboardServices
      };
      await API.post('/secure/add_customer', data, config)
        .then((res) => {
          formik.resetForm();
          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          handleDialog();
          setUpdate(update + 1);
        })
        .catch((err) => {
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });

  const handleDialog = () => {
    formik.resetForm();
    setOpen(!open);
  };

  const handleAlert = () => {
    setAlert(false);
  };

  return (
    <Page title="Customer">
      <>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Customers {customerList && customerList.count && `(${customerList.count})`}
          </Typography>
          {userDetails.role !== 'Manager' && (
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={handleDialog}
              sx={{
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              New Customer
            </Button>
          )}
        </Stack>
        <Container>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleDialog}
            open={open}
            title="Add New Customer"
          >
            <CustomerForm handleDialog={handleDialog} formik={formik} />
          </CustomFormModel>
        </Container>
        <CustomerDataModel
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          handleDialog={handleDialog}
          formik={formik}
          isUpdated={update}
          updateComponent={setUpdate}
          customerList={customerList}
          setcustomerList={setcustomerList}
        />
      </>
      <Notification
        message={alertContent || 'Customer list fetch succesfully'}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}
