import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import API from '../../../E2E/axios.util';
import API from '../../E2E/axios.util';
// import { Notification } from '../../Notification/Notification';
import { Notification } from '../../components/Notification/Notification';

// ----------------------------------------------------------------------

export default function ForgetPasswordForm() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const ForgetSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address.')
      .required('This field is required.')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ForgetSchema,
    onSubmit: async () => {
      await handleRequest(formik);
    }
  });

  const handleRequest = async (formik) => {
    const data = {
      email: formik.values.email
    };
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8;application/json'
    };
    await API.post('auth/reseting_password_request', data, headers)
      .then((res) => {
        console.log('forget password', res.data.message);
        formik.resetForm();
      })
      .then(() => {
        localStorage.clear();
        setAlertContent(`Success: Email sent successfully.`);
        setAlertType('success');
        setAlert(true);
      })
      .catch((err) => {
        setAlertContent(
          `Please check your email: ${
            err.response.data.message ? err.response.data.message : 'Internal Server Error'
          }`
        );
        setAlert(true);
        setAlertType('error');
      });
  };
  const handleAlert = () => {
    setAlert(false);
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ marginBottom: 3 }}>
          <TextField
            required
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          style={{
            backgroundColor: '#3892CF',
            '&:hover': {
              backgroundColor: '#3892CF'
            }
          }}
        >
          Change Password
        </LoadingButton>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
          <Link
            variant="subtitle2"
            sx={{ cursor: 'pointer', color: '#3892CF' }}
            onClick={() => navigate('/Login')}
          >
            Sign In
          </Link>
        </Stack>
      </Form>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </FormikProvider>
  );
}
