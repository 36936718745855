/* eslint-disable   arrow-body-style */
/* eslint-disable prefer-template */
/* eslint-disable no-else-return */

import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
  CardHeader,
  Card,
  Grid,
  Button,
  Select,
  MenuItem,
  Typography,
  Tooltip as Tip
} from '@mui/material';
import { ResponsiveContainer, PieChart, Pie, Sector, Cell, Legend, LabelList } from 'recharts';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { Icon } from '@iconify/react';
import download from '@iconify/icons-eva/download-fill';
import inputContex from '../../Context/UploadContext/inputContext';

function SentimentGraph({
  handleDialog,
  setDate,
  setDeadAirDate,
  sentimentData,
  downloadChart,
  defaultDashboardData,
  handleSentimentPopup,
  newDefaultTone,
  handleSummarySentimentPopup,
  setPageForPopup
}) {
  const {
    setDataIndex,
    setToneDate,
    setGlobalToneValue,
    setUpdateGlobal,
    updateGlobal,
    setPieUpdateGlobal,
    pieUpdateGlobal,
    setGlobalBarValue,
    setGlobalDate
  } = useContext(inputContex);
  // const arrayReverseObj = (obj) => {
  //   const newArray = [];

  //   Object.keys(obj)
  //     .sort()
  //     .reverse()
  //     .forEach((item) => {
  //       console.log(item);
  //       newArray.push({
  //         key: item,
  //         id: item.value
  //       });
  //     });

  //   console.log(newArray);
  //   return newArray;
  // };

  // useEffect(() => {
  //   if (defaultDashboardData) {
  //     arrayReverseObj(defaultDashboardData.sentiment_graph);
  //   } else if (sentimentData) {
  //     arrayReverseObj(sentimentData);
  //   }
  // }, [sentimentData, defaultDashboardData]);
  // console.log('sentiment data --------', sentimentData);
  // console.log('defaultDashboard data --------', defaultDashboardData);

  const positive = [];
  const negative = [];
  const neutral = [];
  if (sentimentData) {
    // console.log('initial dates sentiment data', Object.keys(sentimentData));
    Object.keys(sentimentData).forEach((key, index) => {
      if (sentimentData[key].TOTAL !== 0) {
        positive.push(sentimentData[key].POSITIVE.toFixed(1));
        negative.push(sentimentData[key].NEGATIVE.toFixed(1));
        neutral.push(sentimentData[key].NEUTRAL.toFixed(1));
      }
    });
  } else if (defaultDashboardData) {
    Object.keys(defaultDashboardData).forEach((key, index) => {
      if (defaultDashboardData[key].TOTAL !== 0) {
        positive.push(defaultDashboardData[key].POSITIVE.toFixed(1));
        negative.push(defaultDashboardData[key].NEGATIVE.toFixed(1));
        neutral.push(defaultDashboardData[key].NEUTRAL.toFixed(1));
      }
    });
  }

  // console.log('positive------', positive);
  // console.log('negative------', negative);
  // console.log('neutral------', neutral);

  const series = [
    {
      name: 'Positive',
      type: 'bar',
      data: positive
    },
    {
      name: 'Negative',
      type: 'bar',
      data: negative
    },
    {
      name: 'Neutral',
      type: 'bar',
      data: neutral
    },
    {
      name: 'Negative Trend',
      type: 'line',
      data: negative
    }
  ];

  console.log('graphData---', defaultDashboardData);

  let positiveSentimentForPieChart = 0;
  let negativeSentimentForPieChart = 0;
  let neutralSentimentForPieChart = 0;

  positive.map((item) => (positiveSentimentForPieChart += parseFloat(item)));
  negative.map((item) => (negativeSentimentForPieChart += parseFloat(item)));
  neutral.map((item) => (neutralSentimentForPieChart += parseFloat(item)));

  const pieChartData = [
    { name: 'Positive', value: positiveSentimentForPieChart },
    { name: 'Negative', value: negativeSentimentForPieChart },
    { name: 'Neutral', value: neutralSentimentForPieChart }
  ];

  const COLORS = ['#02cf5b', '#f75e5e', '#02b6c9'];

  const RADIAN = Math.PI / 180;
  // const renderCustomizedLabel = ({
  //   cx,
  //   cy,
  //   midAngle,
  //   innerRadius,
  //   outerRadius,
  //   percent,
  //   value
  // }) => {
  //   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  //   const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
  //   const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

  //   return (
  //     <text
  //       x={x}
  //       y={y}
  //       fill="white"
  //       textAnchor={x > cx ? 'start' : 'end'}
  //       dominantBaseline="central"
  //       className="label-percentage"
  //     >
  //       {`${value.toFixed(0)} Calls`}
  //     </text>
  //   );
  // };
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value
  }) => {
    // console.log('indexxxx', index);
    const lineRadius = innerRadius + (outerRadius - innerRadius); // Adjust the multiplier to place the label line
    const lineX = cx + lineRadius * Math.cos(-midAngle * RADIAN);
    const lineY = cy + lineRadius * Math.sin(-midAngle * RADIAN);

    const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const color = COLORS[index % COLORS.length];

    return (
      <Tip title={`${value} calls`} arrow>
        {/* <line x1={cx} y1={cy} x2={lineX} y2={lineY} stroke={color} strokeWidth={2} />
        <line x1={lineX} y1={lineY} x2={x} y2={y} stroke={color} strokeWidth={2} /> */}
        <text
          x={x}
          y={y}
          fill={color}
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
          style={{
            cursor: 'pointer',
            border: 'none', // Change the border style when clicked
            outline: 'none'
          }}
        >
          {value > 0 && `${formatNumber(value)} calls (${(percent * 100).toFixed(0)}%)`}
        </text>
      </Tip>
    );
  };

  const [dateTofix, setDateTofix] = React.useState();
  const [defaultDate, setDefautDashboardDate] = React.useState();
  const [chartType, setChartType] = useState('summary');
  // console.log('dateTofix', dateTofix);

  const pieSummary = [
    { value: 'Positive', type: 'square', color: '#02cf5b' },
    { value: 'Negative', type: 'square', color: '#f75e5e' },
    { value: 'Neutral', type: 'square', color: '#02b6c9' }
  ];
  const options = {
    chart: {
      id: `basic-bar${Math.random()}`,
      toolbar: {
        show: true,
        tools: {
          download: true, // Set to false to remove the download tool
          selection: false, // Set to false to remove the selection tool
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: []
        },
        export: {
          csv: {
            filename: 'sentiment_result',
            columnDelimiter: ',',
            headerCategory: 'Date',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            }
          },
          svg: {
            filename: undefined
          },
          png: {
            filename: undefined
          }
        },
        autoSelected: 'zoom'
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          setDataIndex(config.dataPointIndex);
          setGlobalToneValue();

          setToneDate(null);
          if (setDate) {
            setDate(null);
          }
          if (setDeadAirDate) {
            setDeadAirDate(null);
          }

          if (sentimentData) {
            if (setGlobalDate) {
              setGlobalDate(Object.keys(sentimentData)[config.dataPointIndex]);
            }
            if (setGlobalBarValue) {
              setGlobalBarValue(config.seriesIndex);
            }
            if (setPageForPopup) {
              setPageForPopup(1);
            }
            if (handleSentimentPopup) {
              handleSentimentPopup(
                Object.keys(sentimentData)[config.dataPointIndex],
                config.seriesIndex
              );
            }

            setUpdateGlobal(updateGlobal + 1);
          } else {
            if (setGlobalDate) {
              setGlobalDate(Object.keys(defaultDashboardData)[config.dataPointIndex]);
            }
            if (setGlobalBarValue) {
              setGlobalBarValue(config.seriesIndex);
            }
            if (setPageForPopup) {
              setPageForPopup(1);
            }
            if (newDefaultTone) {
              newDefaultTone(
                config.seriesIndex,
                'sentiment',
                Object.keys(defaultDashboardData)[config.dataPointIndex]
              );
            }

            setUpdateGlobal(updateGlobal + 1);
          }
        }
      },
      type: 'bar',
      width: 700,
      height: 450
      // stacked: true
      // stackType: '100%'
    },
    colors: ['#02cf5b', '#f75e5e', '#02b6c9', 'grey'],

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40%'
        // barHeight: '20%'
      }
    },
    dataLabels: {
      enabled: false,
      // textAnchor: 'start',
      style: {
        fontSize: '11px',
        colors: ['white']
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: '#000',
        opacity: 0.45
      }

      // offsetX: 0
    },
    stroke: {
      width: [0, 0, 0, 1],
      colors: ['grey']
    },
    title: {
      text: ''
    },
    xaxis: {
      categories:
        (sentimentData &&
          Object.keys(sentimentData).filter((item) => sentimentData[item].TOTAL !== 0)) ||
        (defaultDashboardData &&
          Object.keys(defaultDashboardData).filter(
            (item) => defaultDashboardData[item].TOTAL !== 0
          )),
      labels: {
        formatter(val) {
          const index = val.indexOf('/');

          // If '/' is found, return the substring before it
          if (index !== -1) {
            return val.substring(0, index);
          }

          // If '/' is not found, return the entire string
          return val;
        }
      },
      title: {
        text: undefined
      }
      // max: 100
    },
    yaxis: {
      title: {
        text: 'Call Count',
        offsetX: 2,
        offsetY: 10,
        style: {
          // color: undefined,
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 650,
          cssClass: 'apexcharts-xaxis-title'
        }
      },
      labels: {
        formatter(val) {
          return `${val.toFixed(0)}`;
        }
      }
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val} Calls`;
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      // onItemHover: {
      //   highlightDataSeries: false
      // },
      position: 'bottom',
      horizontalAlign: 'center'
      // offsetX: 40,
      // onItemClick: {
      //   toggleDataSeries: false
      // }
    }
  };

  function formatNumber(num) {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B';
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + 'K';
    } else {
      return num.toString();
    }
  }

  return (
    <Card sx={{ height: 365 }}>
      <div
        style={{
          padding: 10,
          borderBottom: '1px solid #f1f3f4',
          backgroundColor: '#F4F4F4',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <b>Sentiment</b>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Select
            size="small"
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
            // MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            <MenuItem value="summary">Summary</MenuItem>
            <MenuItem value="trend">Trend</MenuItem>
          </Select>
          {sentimentData && downloadChart && (
            <Button
              // style={{ marginTop: 20, marginLeft: '7rem' }}
              disabled={
                positiveSentimentForPieChart +
                  negativeSentimentForPieChart +
                  neutralSentimentForPieChart ===
                0
              }
              size="small"
              onClick={(e) => downloadChart('sentiment_graph')}
              variant="primary"
            >
              <Icon icon={download} />
            </Button>
          )}
        </div>
      </div>
      {chartType === 'trend' && (
        <ResponsiveContainer width="100%" height="83%">
          {series[0].data.length > 0 && series[1].data.length > 0 && series[2].data.length > 0 ? (
            <ReactApexChart options={options} series={series} type="bar" />
          ) : (
            <div
              style={{
                width: '110%',
                height: '90%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <span style={{ fontSize: 20 }}>
                <b>No Negative Sentiment Calls</b>
              </span>
            </div>
          )}
        </ResponsiveContainer>
      )}
      {chartType === 'summary' && (
        <>
          {series[0].data.length > 0 && series[1].data.length > 0 && series[2].data.length > 0 ? (
            <ResponsiveContainer width="100%" height="83%">
              <PieChart>
                <Pie
                  data={pieChartData}
                  cx="50%"
                  cy="50%"
                  // label={renderCustomizedLabel}
                  // labelLine
                  outerRadius="95%"
                  fill="#8884d8"
                  dataKey="value"
                >
                  {pieChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                      style={{ cursor: 'pointer', outline: 'none', stroke: 'none' }}
                      onClick={() => {
                        setGlobalDate();
                        setGlobalBarValue();
                        if (setGlobalToneValue) {
                          setGlobalToneValue(entry.name.toLowerCase());
                        }
                        if (setPageForPopup) {
                          setPageForPopup(1);
                        }
                        if (setPieUpdateGlobal) {
                          setPieUpdateGlobal(pieUpdateGlobal + 1);
                        }
                        if (handleSummarySentimentPopup) {
                          handleSummarySentimentPopup(entry.name.toLowerCase());
                        }
                      }}
                    />
                  ))}
                </Pie>
                <Legend
                  // payload={pieSummary.map((item, index) => ({
                  //   type: 'square',
                  //   value: `${item.value}`,
                  //   color: item.color
                  // }))}
                  layout="vertical"
                  verticalAlign="top"
                  align="right"
                  formatter={(value, entry) => `${value}: ${entry.payload.value} calls`}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <div
              style={{
                width: '100%',
                height: '80%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <span style={{ fontSize: 20 }}>
                <b>No Negative Sentiment Calls</b>
              </span>
            </div>
          )}
        </>
      )}
    </Card>
  );
}

export default SentimentGraph;
