/* eslint-disable arrow-body-style */
import React, { useState, useEffect, useContext } from 'react';
import {
  Link,
  Container,
  Stack,
  Typography,
  Grid,
  Tabs,
  Tab,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Tooltip as Tip,
  Button,
  TablePagination,
  Paper,
  Backdrop,
  CircularProgress
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Pagination from '@mui/material/Pagination';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import API from '../E2E/axios.util';
import Page from '../components/Page';
import inputContext from '../Context/UploadContext/inputContext';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';

const GoodCalls = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const navigate = useNavigate();
  const locationData = useLocation();

  const [goodCalls, setGoodCalls] = useState(
    locationData.state && locationData.state.initialData.storedTrainedCallsDetails
      ? locationData.state.initialData.storedTrainedCallsDetails
      : []
  );
  const [selectedParameter, setSelectedParameter] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // console.log('goodCalls', goodCalls);
  const userDetails = JSON.parse(localStorage.getItem('user'));
  // console.log(userDetails, 'userDetails');
  const token = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  const good_call_list = async () => {
    setLoading(true);
    await API.get(`/secure/training_calls_details`, config)
      .then((res) => {
        console.log(res, 'res');
        setGoodCalls(res.data.count);
        // console.log('KEYS::::', Object.keys(goodCalls));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // console.log('KEYS::::', Object.keys(goodCalls));

  useEffect(() => {
    good_call_list();
  }, []);

  const useStyles = makeStyles((theme) => ({
    cards: {
      // [theme.breakpoints.down('md')]: {
      //   padding: 10
      // },
      // [theme.breakpoints.down('sm')]: {
      //   padding: 20
      // },
      accordion: {
        backgroundColor: '#3892CF', // Customize background color
        borderRadius: '8px', // Customize border radius
        boxShadow: 'none', // Remove box shadow
        // marginBottom: theme.spacing(3), // Add some margin at the bottom
        '&.css-1yvw12g-MuiPaper-root-MuiAccordion-root': {
          backgroundColor: '#88d4da'
        },
        '.MuiAccordionDetails-root': {
          backgroundColor: 'red'
        }
      },
      accordionSummary: {
        backgroundColor: 'red', // Customize background color
        borderBottom: '1px solid #ccc', // Add bottom border
        borderRadius: '8px 8px 0 0', // Adjust border radius
        '& .MuiAccordionSummary-content': {
          margin: '0'
        }
      },
      accordionSummaryExpanded: {
        backgroundColor: 'pink' // Customize background color when expanded
      }
      // accordionDetails: {
      //   padding: theme.spacing(2) // Add padding
      // }
    }
  }));
  const classes = useStyles();

  const handleDialogTrainingCalls = () => {
    setOpen(!open);
  };

  const { toneDate, setToneDate, dataIndex, setDataIndex } = useContext(inputContext);

  const {
    setinputId,
    setinputOrgId,
    setrequestId,
    setUploadedAudio,
    setuploadStatus,
    setAudioS3,
    uploadStatus,
    setProcess,
    setcustomerId,
    setOriginalDate,
    setAgentId,
    setAgentName,
    setAgentLastName,
    kpi,
    setKpi
  } = useContext(inputContext);

  // pagination

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Page title="Good Calls">
        <Container>
          <Box sx={{ padding: '3px', marginBottom: 4 }}>
            <Typography role="presentation" variant="h4" mt="2">
              Training Calls
            </Typography>
          </Box>
          {Object.entries(goodCalls).filter(([param, data]) => data && data.length > 0).length ===
          0 ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <span style={{ fontSize: 20 }}>
                <b>No Data Available</b>
              </span>
            </div>
          ) : (
            Object.entries(goodCalls)
              .filter(([param, data]) => data && data.length > 0)
              .map(([param, data], index) => {
                // console.log('data', data);
                return (
                  <Grid Conatainer spacing={4} sx={{ marginBottom: '5px' }}>
                    <Grid item>
                      <Accordion
                        sx={{
                          '&.MuiAccordion-root': {
                            backgroundColor: '#3892CF',
                            color: 'white'
                          }
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          sx={{
                            '&MuiButtonBase-root-MuiAccordionSummary-root.MuiAccordionSummary-root.Mui-expanded':
                              {
                                backgroundColor: '#3892CF',
                                borderRadius: '10px',
                                color: 'white'
                              },
                            '&.MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
                              color: 'white'
                            }
                          }}
                        >
                          <Typography>{param}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            '&.MuiCollapse-wrapperInner': {
                              backgroundColor: '#EAEEF3',
                              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
                            },
                            backgroundColor: '#EAEEF3',
                            // borderRadius: '10px',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
                          }}
                        >
                          <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                              <>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left" sx={{ backgroundColor: '#EAEEF3' }}>
                                      <b>Request Id</b>
                                    </TableCell>
                                    <TableCell align="center" sx={{ backgroundColor: '#EAEEF3' }}>
                                      <b>Date</b>
                                    </TableCell>
                                    <TableCell align="center" sx={{ backgroundColor: '#EAEEF3' }}>
                                      <b>Good Parameters</b>
                                    </TableCell>
                                    <TableCell align="center" sx={{ backgroundColor: '#EAEEF3' }}>
                                      <b>View</b>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                {data && data.length > 0 ? (
                                  <TableBody>
                                    {data &&
                                      data
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, i) => {
                                          return (
                                            <TableRow key={i}>
                                              <TableCell align="left">{item.request_id}</TableCell>
                                              <TableCell align="center">
                                                {moment(item.original_date, 'YYYY-MM-DD').format(
                                                  'DD-MM-YYYY'
                                                )}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  whiteSpace: 'nowrap',
                                                  maxWidth: '130px',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis'
                                                }}
                                                title={item.good_parameters.join(',')}
                                              >
                                                {item.good_parameters.join(',')}
                                              </TableCell>

                                              <TableCell align="center">
                                                <Link
                                                  underline="hover"
                                                  sx={{ cursor: 'pointer', color: '#3892CF' }}
                                                  onClick={() => {
                                                    navigate(
                                                      '/dashboard/workspace/Upload/Results',
                                                      {
                                                        state: {
                                                          storedTrainedCallsDetails: goodCalls,
                                                          path: '/dashboard/feedback/training_calls'
                                                        }
                                                      }
                                                    );
                                                    setinputId(item.input_id);
                                                    setinputOrgId(item.org_id);
                                                    setrequestId(item.request_id);
                                                    setinputOrgId(item.org_id);
                                                    setrequestId(item.request_id);
                                                    setUploadedAudio(
                                                      decodeURI(
                                                        item.interaction_id
                                                          ?.split('/')
                                                          .pop()
                                                          .split('#')[0]
                                                          .split('?')[0]
                                                      )
                                                    );
                                                    setAudioS3(item.interaction_id);
                                                    // setProcess(formik.values.process);
                                                    setcustomerId(userDetails.customer_id);
                                                    setOriginalDate(
                                                      moment(
                                                        item.original_date,
                                                        'YYYY-MM-DD'
                                                      ).format('DD-MM-YYYY')
                                                    );
                                                    setAgentName('');
                                                    setAgentLastName('');
                                                    setAgentId('');
                                                  }}
                                                >
                                                  <Tip
                                                    title={decodeURI(
                                                      item.interaction_id
                                                        .split('/')
                                                        .pop()
                                                        .split('#')[0]
                                                        .split('?')[0]
                                                    )}
                                                    followCursor
                                                    placement="top"
                                                  >
                                                    <Typography sx={{ fontWeight: 600 }}>
                                                      {`${decodeURI(
                                                        item.interaction_id
                                                          ?.split('/')
                                                          .pop()
                                                          .split('#')[0]
                                                          .split('?')[0]
                                                      ).slice(0, 14)}.....`}
                                                    </Typography>
                                                  </Tip>
                                                </Link>
                                              </TableCell>
                                              {/* <TableCell style={{ textAlign: 'center' }}>
                                      {item.interaction_id}
                                    </TableCell> */}
                                            </TableRow>
                                          );
                                        })}
                                  </TableBody>
                                ) : (
                                  <TableBody>
                                    <TableRow>
                                      {loading ? (
                                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                                          <CircularProgress sx={{ color: '#3892CF' }} />
                                        </TableCell>
                                      ) : (
                                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                                          Data Not Found
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  </TableBody>
                                )}
                              </>
                            </Table>
                          </TableContainer>
                          {Math.ceil(data?.length) > 5 && (
                            <Stack
                              spacing={1}
                              sx={{
                                padding: 2,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center'
                              }}
                            >
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                rowsPerPage={rowsPerPage}
                                count={data && Math.ceil(data?.length)}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </Stack>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                );
              })
          )}
        </Container>
      </Page>
    </>
  );
};

export default GoodCalls;

const CallListData = {
  parameter1: [
    { date: '11-02-2024', call_name: 'eleven_call', call_url: 'abc' },
    { date: '12-02-2024', call_name: 'twelve_call', call_url: 'abc' },
    { date: '13-02-2024', call_name: 'thirteen_call', call_url: 'abc' }
  ],
  parameter2: [
    { date: '20-02-2024', call_name: 'twenty', call_url: 'xyz' },
    { date: '21-02-2024', call_name: 'twentyone', call_url: 'xyz1' }
  ],
  parameter3: [{ date: '26-02-2024', call_name: 'twentysix', call_url: 'lmn' }]
};
