import React, { useState, useRef, useEffect } from 'react';
import {
  Card,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TablePagination,
  Skeleton,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Box,
  Stack,
  Pagination
} from '@mui/material';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import accountReactivateOutline from '@iconify/icons-mdi/account-reactivate-outline';
import { makeStyles } from '@mui/styles';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
// Component
import Scrollbar from '../../components/Scrollbar';
import { CustomFormModel } from '../../components/custom-form-model/CustomFormModel';
import API from '../../E2E/axios.util';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
import { CustomerEditForm } from '../../components/custom-form-model/CRUDForms';

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: '#3892CF',
        color: 'white'
        // borderRadius: '50%',
      }
    }
  }
}));

const CUSTOMER_TABLE_HEAD = [
  { id: 'customerId', numeric: false, disablePadding: false, label: 'CustomerId' },
  {
    id: 'customerOrgName',
    numeric: false,
    disablePadding: false,
    label: 'CustomerOrgName'
  },
  { id: 'customerName', numeric: false, disablePadding: false, label: 'CustomerName' },
  { id: 'mobile', numeric: false, disablePadding: false, label: 'Contact No' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'OrganisationName', numeric: false, disablePadding: false, label: 'OrgName' },
  { id: 'businessDomain', numeric: false, disablePadding: false, label: 'Domain' },
  { id: 'process', numeric: false, disablePadding: false, label: 'Process' },
  { id: '' }
];

export const CustomerDataModel = ({
  setAlert,
  setAlertContent,
  setAlertType,
  handleDialog,
  formik,
  isUpdated,
  updateComponent,
  customerList,
  setcustomerList
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState();
  const [customerSearch, setCustomerSearch] = useState();

  const userDetails = JSON.parse(localStorage.getItem('user'));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleGetCustomer = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await API.get(`/secure/list_customer?page=${page}`, config)
        .then((res) => {
          setcustomerList(res.data);
          // setAlertContent(`Success: Customer list fetch successfully.`);
          setAlertType('success');
          setAlert(true);
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAlertContent(`${err}`);
          setAlertType('error');
          setAlert(true);
          setLoading(false);
        });
    } else {
      setAlertContent(`Error : Authentication Failed Due to Token Expired.`);
      setAlertType('error');
      setAlert(true);
    }
  };

  const showToolProcessTip = (processes) => {
    if (processes.length > 2) {
      return (
        <Tooltip
          title={processes.map((element) => (
            <span>{element}, </span>
          ))}
          followCursor
          placement="top"
        >
          <TableCell align="left" sx={{ cursor: 'pointer' }}>
            <span>
              {processes[0]}, {processes[1]} .....
            </span>
          </TableCell>
        </Tooltip>
      );
    }
    return (
      <TableCell align="left">
        {processes.map((process) => (
          <span>{process} </span>
        ))}
      </TableCell>
    );
  };

  const showToolTip = (value) => {
    if (value.length > 10) {
      return (
        <Tooltip title={value} followCursor placement="top">
          <TableCell align="left" sx={{ cursor: 'pointer' }}>
            <span>{value.slice(0, 10)}.....</span>
          </TableCell>
        </Tooltip>
      );
    }
    return <TableCell align="left">{value}</TableCell>;
  };

  const handleSearch = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await API.get(
        `/secure/customer_search?page=${page}&max_rows=${rowsPerPage}&query=${query}`,
        config
      )
        .then((res) => {
          setCustomerSearch(res.data);
          setLoading(false);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!query) {
      handleGetCustomer();
    }
  }, [isUpdated, page]);

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    if (query?.length > 0) {
      handleSearch();
    }
    console.log('query', query);
  }, [debouncedQuery, isUpdated, page]);
  // const filteredData =
  //   customerList && customerList.customer_list.length > 0
  //     ? customerList.customer_list.filter((item) =>
  //         item.customer_first_name.toLowerCase().includes(query?.toLowerCase())
  //       )
  //     : [];
  // console.log('filteredData', filteredData);
  return (
    <Card>
      <UserListToolbar
        placeholder="Search"
        onFilterName={(e) => {
          setQuery(e.target.value);
          if (query?.length > 0) {
            setPage(1);
          }
        }}
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead headLabel={CUSTOMER_TABLE_HEAD} rowCount={page} />
            {(query && !loading && customerSearch && (
              <TableBody>
                {customerSearch.results
                  // .filter((ele) => ele.customer_id !== 768)
                  // customerSearch.customers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      customer_id,
                      customer_organisation_name,
                      organisation_name,
                      customer_first_name,
                      customer_last_name,
                      customer_email,
                      customer_mobile,
                      domain_name,
                      processes,
                      language
                    } = row;
                    // console.log('process --', processes);
                    return (
                      <TableRow hover key={1} tabIndex={-1} role="checkbox">
                        <TableCell align="center">{customer_id}</TableCell>
                        <TableCell component="th" scope="row" padding="normal">
                          {customer_organisation_name}
                        </TableCell>
                        <TableCell align="left">
                          {customer_first_name} {customer_last_name}
                        </TableCell>

                        {showToolTip(customer_mobile)}

                        {showToolTip(customer_email)}
                        <TableCell align="left">{organisation_name}</TableCell>
                        <TableCell align="left">{domain_name}</TableCell>
                        {showToolProcessTip(processes)}

                        {userDetails.role !== 'Manager' && (
                          <TableCell align="right">
                            <UserMoreMenu
                              formik={formik}
                              isUpdated={isUpdated}
                              updateComponent={updateComponent}
                              setAlert={setAlert}
                              setAlertContent={setAlertContent}
                              setAlertType={setAlertType}
                              customerId={customer_id}
                              customerDetails={row}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            )) ||
              //  : (
              //   <TableBody>
              //     <TableRow>
              //       <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
              //         <CircularProgress sx={{ color: '#3892CF' }} />
              //       </TableCell>
              //     </TableRow>
              //   </TableBody>
              // )
              (!loading && customerList && customerList.results.length > 0 ? (
                <TableBody>
                  {customerList.results
                    // .filter((ele) => ele.customer_id !== 768)
                    // customerList.customer_list
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        customer_id,
                        customer_organisation_name,
                        organisation_name,
                        customer_first_name,
                        customer_last_name,
                        customer_email,
                        customer_mobile,
                        domain_name,
                        processes,
                        language,
                        dashboard_services,
                        kpi_services,
                        custom_ai_services,
                        aws_services
                      } = row;
                      // console.log('process --', processes);
                      return (
                        <TableRow hover key={1} tabIndex={-1} role="checkbox">
                          <TableCell align="center">{customer_id}</TableCell>
                          <TableCell component="th" scope="row" padding="normal">
                            {customer_organisation_name}
                          </TableCell>
                          {/* <TableCell align="left">
                            {customer_first_name} {customer_last_name}
                          </TableCell> */}
                          {showToolTip(`${customer_first_name} ${customer_last_name}`)}

                          {showToolTip(customer_mobile)}

                          {showToolTip(customer_email)}
                          <TableCell align="left">{organisation_name}</TableCell>
                          <TableCell align="left">{domain_name}</TableCell>
                          {showToolProcessTip(processes)}

                          {userDetails.role !== 'Manager' && (
                            <TableCell align="right">
                              <UserMoreMenu
                                formik={formik}
                                isUpdated={isUpdated}
                                updateComponent={updateComponent}
                                setAlert={setAlert}
                                setAlertContent={setAlertContent}
                                setAlertType={setAlertType}
                                customerId={customer_id}
                                customerDetails={row}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    {loading ? (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <CircularProgress sx={{ color: '#3892CF' }} />
                      </TableCell>
                    ) : (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        Data Not Found
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              ))}
          </Table>
        </TableContainer>
      </Scrollbar>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        rowsPerPage={rowsPerPage}
        count={!loading && customerList?.customer_list ? customerList?.customer_list?.length : 0}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <Stack
        spacing={2}
        sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
      >
        <Pagination
          classes={{
            root: classes.ul
          }}
          count={
            query && customerSearch
              ? Math.ceil(customerSearch.count / 10)
              : Math.ceil(customerList?.count / 10)
          }
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Stack>
    </Card>
  );
};

function UserMoreMenu({
  formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  customerId,
  customerDetails
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(openEdit);

  const handleDialog = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };

  const handleDeleteCustomer = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.delete(
      `/secure/deactivate_customer?id=${customerId}&org_id=${customerDetails.organisation_id}`,
      config
    )
      .then((res) => {
        console.log('delete Organisation res-', res);
        setAlertContent(`Success : Customer deleted successfully.`);
        setAlertType('success');
        setAlert(true);
      })
      .then(() => {
        updateComponent(isUpdated + 1);
        setIsOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };
  const handleReactivateCustomer = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = { org_id: customerDetails.organisation_id, customer_id: customerId };
    await API.post('/secure/reactivate_customer', data, config)
      .then((res) => {
        console.log('delete Organisation res-', res);
        setAlertContent(`${res.data.message}`);
        setAlertType('success');
        setAlert(true);
      })
      .then(() => {
        // updateComponent(isUpdated + 1);
        setIsOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={openEdit}
        title="Update Customer"
      >
        <CustomerEditForm
          formik={formik}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          setAlert={setAlert}
          isUpdated={isUpdated}
          updateComponent={updateComponent}
          handleDialog={handleDialog}
          customerDetails={customerDetails}
        />
      </CustomFormModel>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={handleDialog}
          disabled={customerId === 768}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem to="#" sx={{ color: 'text.secondary' }} onClick={handleReactivateCustomer}>
          <ListItemIcon>
            <Icon icon={accountReactivateOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Reactivate" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={handleDeleteCustomer}
          disabled={customerId === 768}
        >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
