import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  return (
    // <Box
    //   component="img"
    //   src={`http://65.1.148.190:8020${logoUrl.logo}`}
    //   sx={{ width: '100%', height: 50, ...sx }}
    // />
    // <Box
    //   component="img"
    //   src="/static/inteliLogo.svg"
    //   sx={{ width: 180, height: 60, cursor: 'pointer', ...sx }}
    // />

    <Box
      component="img"
      // src="/static/SCOR.png"
      src="/static/inteliLogo.png"
      sx={{ width: 180, height: 60, cursor: 'pointer', ...sx }}
      onClick={() => {
        // if (userDetails.role === 'Guest') {
        //   navigate('/dashboard/workspace/upload', { replace: true });
        // } else if (
        //   userDetails.role === 'Agent' &&
        //   userDetails.dashboard_service.includes('sales')
        // ) {
        //   navigate('/dashboard/admin/task', { replace: true });
        if (userDetails.role === 'Guest') {
          navigate('/dashboard/workspace/upload', { replace: true });
        } else if (userDetails.role === 'Agent') {
          navigate('/dashboard/workspace/upload', { replace: true });
        } else if (
          (userDetails.role === 'Customer_Admin' || userDetails.role === 'QA') &&
          userDetails.Submenus.includes('Revenue_intelligence')
        ) {
          navigate('/dashboard/app/sales', { replace: true });
        } else if (
          (userDetails.role === 'Customer_Admin' || userDetails.role === 'QA') &&
          userDetails.Submenus.includes('Call_analysis')
        ) {
          navigate('/dashboard/app/call', { replace: true });
        } else if (
          (userDetails.role === 'Customer_Admin' || userDetails.role === 'QA') &&
          userDetails.Submenus.includes('Collection')
        ) {
          navigate('/dashboard/app/collections', { replace: true });
        } else if (
          (userDetails.role === 'Customer_Admin' || userDetails.role === 'QA') &&
          userDetails.Submenus.includes('Compliance')
        ) {
          navigate('/dashboard/app/compliance', { replace: true });
        } else if (userDetails.role === 'Manager' && userDetails.Submenus.includes('Collection')) {
          navigate('/dashboard/app/collections', { replace: true });
        } else if (
          userDetails.role === 'Manager' &&
          userDetails.Submenus.includes('Revenue_intelligence')
        ) {
          navigate('/dashboard/app/sales', { replace: true });
        } else if (
          userDetails.role === 'Manager' &&
          userDetails.Submenus.includes('Call_analysis')
        ) {
          navigate('/dashboard/app/call', { replace: true });
        } else if (userDetails.role === 'Manager' && userDetails.Submenus.includes('Compliance')) {
          navigate('/dashboard/app/compliance', { replace: true });
        } else if (
          userDetails.role === 'Sales_Admin' &&
          userDetails.Submenus.includes('Revenue_intelligence')
        ) {
          navigate('/dashboard/app/sales', { replace: true });
        } else if (
          userDetails.role === 'Collection_Admin' &&
          userDetails.Submenus.includes('Call_analysis')
        ) {
          navigate('/dashboard/app/call', { replace: true });
        } else {
          navigate('/dashboard/app/call', { replace: true });
        }
      }}
    />

    // <img src="/static/inteliLogo.png" alt="source not found" style={{ cursor: 'pointer' }} />
  );
}
