/* eslint-disable  no-unneeded-ternary */
import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, FormikProvider, Form, Field, FieldArray, useFormik, ErrorMessage } from 'formik';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  Grid,
  Card,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TextField,
  TablePagination,
  Skeleton,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Select as Selectbox,
  Container,
  InputLabel,
  FormControl,
  Box,
  Chip,
  FormHelperText,
  TableHead
} from '@mui/material';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import eyeIcon from '@iconify/icons-mdi/eye';
// Component
import Scrollbar from '../../components/Scrollbar';
import { CustomFormModel } from '../../components/custom-form-model/CustomFormModel';
import API from '../../E2E/axios.util';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
import ParameterItem from './ParameterItem';

// import { KeywordEditForm } from '../../components/custom-form-model/CRUDForms';

const SCRIPT_TABLE_HEAD = [
  {
    id: 'OrgName',
    numeric: false,
    disablePadding: false,
    label: 'Organisation Name'
  },
  { id: 'customerName', numeric: false, disablePadding: false, label: 'Customer Name' },
  { id: 'modified_date', numeric: false, disablePadding: false, label: 'Modified Date' },
  { id: 'Script', numeric: false, disablePadding: false, label: 'Script' },
  { id: 'Status', numeric: false, disablePadding: false, label: 'Status', alignCenter: true },
  { id: '' }
];

export default function ComplianceDataModel({
  setAlert,
  setAlertContent,
  setAlertType,
  handleDialog,
  formik,
  isUpdated,
  updateComponent,
  keywordList,
  setKeywordList
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState();
  const [keywordSearch, setKeywordSearch] = useState();

  const userDetails = JSON.parse(localStorage.getItem('user'));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Function to call list api for keywords
  const handleGetKeywords = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await API.get('/secure/list_compliance', config)
        .then((res) => {
          setKeywordList(res.data);
          setAlertContent(`Success: Script list fetch successfully.`);
          setAlertType('success');
          setAlert(true);
          setLoading(false);
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setKeywordList();
          console.log(err);
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
          setLoading(false);
        });
    } else {
      setAlertContent(`Error : Authentication Failed Due to Token Expired.`);
      setAlertType('error');
      setAlert(true);
      setLoading(false);
    }
  };

  //   Function to show tooltip
  const showToolTip = (value) => {
    if (value.length > 10) {
      return (
        <Tooltip title={value} followCursor placement="top">
          <TableCell align="left" sx={{ cursor: 'pointer' }}>
            <span>{value.slice(0, 10)}.....</span>
          </TableCell>
        </Tooltip>
      );
    }
    return <TableCell align="left">{value}</TableCell>;
  };

  const showToolSentenceTip = (processes) => {
    if (processes.length > 2) {
      return (
        <Tooltip
          title={processes.map((element) => (
            <span>{Object.values(element)[0]}, </span>
          ))}
          followCursor
          placement="top"
        >
          <TableCell align="left" sx={{ cursor: 'pointer' }}>
            <div>
              {Object.values(processes[0])[0]}, {Object.values(processes[1])[0]} .....
            </div>
            <div>{Object.values(processes[1])[0]} .....</div>
          </TableCell>
        </Tooltip>
      );
    }
    return (
      <TableCell align="left">
        {processes.map((process) => (
          <div>{Object.values(process)[0]} </div>
        ))}
      </TableCell>
    );
  };

  useEffect(() => {
    handleGetKeywords();
  }, [isUpdated]);

  const handleSearch = async () => {
    // setLoading(true);
    const token = localStorage.getItem('token');
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await API.get(`/secure/keywords_search?query=${query}`, config)
        .then((res) => {
          setKeywordSearch(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    if (query) {
      handleSearch();
      console.log('query', query);
    }
  }, [query]);
  const theme = useTheme();
  // const [isOpen, setIsOpen] = useState(false);
  const [openIcon, setIconOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(openIcon);
  const [Questions, setQuestions] = useState([]);
  const handleIcon = () => {
    setIconOpen(!openIcon);
  };
  const changedDate = (date) => {
    const newdate = new Date(date);
    const formatStartDate = `${newdate.getDate()}-${
      newdate.getMonth() + 1
    }-${newdate.getFullYear()}`;
    return formatStartDate;
  };

  return (
    <>
      <Card>
        {/* <UserListToolbar placeholder="Search" onFilterName={(e) => setQuery(e.target.value)} /> */}
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead headLabel={SCRIPT_TABLE_HEAD} rowCount={page} />
              <TableBody>
                {keywordList && keywordList.compliance_list.length > 0 ? (
                  keywordList.compliance_list
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        compliance_id,
                        org_name,
                        customer_organisation_name,
                        questions,
                        dashboard_services,
                        status,
                        created_date,
                        modified_date
                      } = row;
                      // console.log('process --', processes);
                      return (
                        <TableRow hover key={1} tabIndex={-1} role="checkbox">
                          <TableCell component="th" scope="row" padding="normal">
                            {org_name}
                          </TableCell>
                          <TableCell align="left">{customer_organisation_name}</TableCell>
                          <TableCell align="left">
                            {modified_date
                              ? moment(changedDate(modified_date), 'D-M-YYYY').format('DD/MM/YYYY')
                              : moment(changedDate(created_date), 'D-M-YYYY').format('DD/MM/YYYY')}
                          </TableCell>
                          {/* <TableCell align="left">{processes[0]}</TableCell> */}
                          <TableCell align="left">
                            <Tooltip title="script">
                              <Icon
                                icon={eyeIcon}
                                style={{ cursor: 'pointer' }}
                                width={24}
                                height={24}
                                onClick={() => {
                                  handleIcon();
                                  setQuestions(questions);
                                }}
                              />
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center">
                            <Chip label={status} sx={{ color: '#3892CF' }} />
                          </TableCell>
                          {/* {showToolProcessTip(questions)} */}
                          {/* <TableCell align="left">
                          {sentences.map((sentence) => (
                            <div>{Object.values(sentence)[0]} </div>
                          ))}
                        </TableCell> */}

                          {userDetails.role !== 'Manager' && (
                            <TableCell align="right">
                              <UserMoreMenu
                                formik={formik}
                                isUpdated={isUpdated}
                                updateComponent={updateComponent}
                                setAlert={setAlert}
                                setAlertContent={setAlertContent}
                                setAlertType={setAlertType}
                                keywordId={compliance_id}
                                Details={row}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    {loading ? (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <CircularProgress sx={{ color: '#3892CF' }} />
                      </TableCell>
                    ) : (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        Script Not Found
                      </TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          rowsPerPage={rowsPerPage}
          count={
            !loading && keywordList?.compliance_list ? keywordList?.compliance_list?.length : 0
          }
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleIcon}
        open={openIcon}
        title="Question List"
      >
        <TableContainer sx={{ border: '1px solid lightgray', borderRadius: '10px' }}>
          <Table>
            <TableHead sx={{ backgroundColor: '#f8f6fa' }}>
              <TableRow>
                <TableCell align="center">Sr.No</TableCell>
                <TableCell align="center">Parameter</TableCell>
                <TableCell align="center">Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Questions.length > 0 &&
                Questions.map((el, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{el.Category}</TableCell>
                    <TableCell align="center">
                      {Object.keys(el.description)
                        .map((language) => el.description[language])
                        .join(',')}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomFormModel>
    </>
  );
}

function UserMoreMenu({
  formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  keywordId,
  Details
}) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(openEdit);

  const handleDialog = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };

  const navigate = useNavigate();

  const handleDelete = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = {
      id: keywordId,
      status: Details.status === 'Active' ? 'Inactive' : 'Active'
    };
    await API.put(`/secure/script_status`, data, config)
      .then((res) => {
        console.log('delete Organisation res-', res);
        setAlertContent(`${res.data.message}`);
        setAlertType('success');
        setAlert(true);
      })
      .then(() => {
        setIsOpen(false);
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={openEdit}
        title="Update Script"
      >
        <ComplianceEditForm
          formik={formik}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          setAlert={setAlert}
          isUpdated={isUpdated}
          updateComponent={updateComponent}
          handleDialog={handleDialog}
          Details={Details}
        />
      </CustomFormModel>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => navigate('/dashboard/workspace/compliance/update', { state: Details })}
          disabled={Details.status === 'Inactive'}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {Details.status === 'Active' ? (
          <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDelete}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Inactive" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ) : (
          <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDelete} disabled>
            <ListItemIcon>
              <div
                style={{
                  width: '13px',
                  height: '13px',
                  borderRadius: '50%',
                  backgroundColor: '#02A902'
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Active" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

const ComplianceEditForm = ({
  formik,
  setAlertContent,
  setAlertType,
  setAlert,
  isUpdated,
  updateComponent,
  handleDialog,
  Details
}) => {
  // const parameterList = [
  //   { name: 'Call Opening Script', max_score: 2 },
  //   { name: 'Salutation', max_score: 3 },
  //   { name: 'Query Understanding', max_score: 4 },
  //   { name: 'Apology & Empathy', max_score: 3 },
  //   { name: 'Paraphrasing', max_score: 3 },
  //   { name: 'Verification - Reason / Permission', max_score: 2 },
  //   { name: 'Clarity of speech', max_score: 4 },
  //   { name: 'Rate of speech', max_score: 4 },
  //   { name: 'Usage of Courteous Words and Phrases', max_score: 5 },
  //   { name: 'Tone & Voice Modulation', max_score: 4 },
  //   { name: 'First Call Resolution Given', max_score: 4 },
  //   { name: 'Listening Skills', max_score: 4 },
  //   { name: 'Interuption', max_score: 4 },
  //   { name: 'Probing', max_score: 5 },
  //   { name: 'Hold procedure', max_score: 3 },
  //   { name: 'Ability to Explain', max_score: 5 },
  //   { name: 'Correct Genesys Disposition', max_score: 3 },
  //   { name: 'Patience', max_score: 2 },
  //   { name: 'Transfer to CSAT', max_score: 3 },
  //   { name: 'Call Closing', max_score: 3 },
  //   { name: 'Pro Active Information', max_score: 3 },
  //   { name: 'Transfer/Escalation/Conferencing', max_score: 5 },
  //   { name: 'Usage of Ready Reckoner', max_score: 3 },
  //   { name: 'Authentication', max_score: 4 },
  //   { name: 'Language Fluency', max_score: 5 },
  //   { name: 'Complete and Accurate Information', max_score: 5 },
  //   { name: 'OCRM Tagging and System Navigation', max_score: 5 },
  //   { name: 'Call avoidance', max_score: 0 },
  //   { name: 'Unprofessional / Rude on call', max_score: 0 },
  //   { name: 'Call Closing Script', max_score: 3 },
  //   { name: 'Verification / Security Check / Authentication', max_score: 0 },
  //   { name: 'System downtime', max_score: 0 },
  //   { name: 'Purpose of Calling ', max_score: 0 },
  //   { name: 'Rating Scale Explanation', max_score: 0 },
  //   { name: 'Confirm KYC details before closing', max_score: 0 },
  //   { name: 'Call Drop', max_score: 3 }
  // ];

  const parameterList = [
    { name: 'Greeting & Introduced Self', type: 'Multivalue', max_score: 3 },
    { name: 'Introduction of Company', type: 'Multivalue', max_score: 0 },
    { name: 'Identification of Right Party Contact (RPC)', type: 'Multivalue', max_score: 0 },
    { name: 'Reaching RPC', type: 'Multivalue', max_score: 3 },
    { name: 'Purpose of Call', type: 'Boolean', max_score: 1 },
    { name: 'Comprehending the Customer', type: 'Boolean', max_score: 5 },
    { name: 'Effective Probing', type: 'Multivalue', max_score: 7 },
    { name: 'Creating Urgency', type: 'Multivalue', max_score: 10 },
    { name: 'Objection Handling', type: 'Multivalue', max_score: 10 },
    { name: 'Script Adherence', type: 'Multivalue', max_score: 10 },
    { name: 'Call Control', type: 'Boolean', max_score: 5 },
    { name: 'Complete and Correct Information (Minor Impact)', type: 'Multivalue', max_score: 8 },
    { name: 'Reason for Delay (RFD)', type: 'Multivalue', max_score: 5 },
    { name: 'Wrong Information/False Commitment', type: 'Multivalue', max_score: 0 },
    { name: 'Summarization', type: 'Multivalue', max_score: 2 },
    { name: 'Thanking the Customer', type: 'Multivalue', max_score: 1 },
    { name: 'Disposition', type: 'Boolean', max_score: 0 },
    { name: 'Active Listening', type: 'Boolean', max_score: 4 },
    { name: 'Empathy', type: 'Multivalue', max_score: 2 },
    { name: 'Confidence', type: 'Multivalue', max_score: 4 },
    { name: 'Professional', type: 'Boolean', max_score: 4 },
    { name: 'Language and Grammar', type: 'Boolean', max_score: 4 },
    { name: 'Clarity of Speech', type: 'Boolean', max_score: 4 },
    { name: 'Tone and Voice Modulation', type: 'Boolean', max_score: 4 },
    { name: 'Hold Procedure', type: 'Boolean', max_score: 4 },
    { name: 'Avoid being Rude and Unprofessional', type: 'Boolean', max_score: 0 },
    { name: 'Call avoidance', type: 'Boolean', max_score: 0 },
    { name: 'Verification / Security Check / Authentication', type: 'Boolean', max_score: 0 },
    { name: 'System downtime', type: 'Boolean', max_score: 0 },
    { name: 'Confirm KYC details before closing', type: 'Boolean', max_score: 0 }
  ];

  const categoryList = ['Soft Skill', 'Critical Skill'];

  const FieldType = [{ name: 'Boolean' }, { name: 'Multivalue' }];

  //   if (Details.dashboard_services.includes('sales')) {
  //     categoryList.push({ name: 'Lead Identifiers', id: 'Lead_Identifier' });
  //   } else if (Details.dashboard_services.includes('collections')) {
  //     categoryList.push({ name: 'Unpleasant', id: 'Unpleasant' });
  //   }
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const { getFieldProps, errors, touched } = formik;

  const [keywordId, setkeywordId] = useState(Details.compliance_id);
  const [organisationId, setorganisationId] = useState(Details.organisation_id);
  const [customerOrgId, setcustomerOrgId] = useState(Details.customer_id);
  const [services, setServices] = useState(Details.dashboard_services);
  console.log('formik.values', formik.values.keywords);
  const [processArr, setprocessArr] = useState(Details.processes);
  const [keywordArr, setkeywordArr] = useState();

  const [organisationList, setorganisationList] = useState([]);
  const [customerOrgList, setcustomerOrgList] = useState([]);
  const [processList, setprocessList] = useState([]);
  const [loading, setloading] = useState(false);

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setorganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertDictToArr = (data) => {
    const arr = [];
    data.map((element) => {
      const arrElement = Object.values(element)[0];
      arr.push(arrElement);
      return element;
    });
    setkeywordArr(arr.toString());
  };
  const formatData = Details.questions.map((item) => {
    // Extract the "description" property from array1
    const { description, ...rest } = item;

    // Convert "description" to match the structure of array2
    const convertedDescription = Object.keys(description).map((language) => ({
      language,
      phrases: description[language]
    }));

    // Create the new object with the converted structure
    return {
      ...rest,
      description: convertedDescription
    };
  });

  useEffect(() => {
    getOrganisationList();
    convertDictToArr(Details.questions);
    // formik.setFieldValue(`sentences`, Details.sentences);
    formik.setFieldValue(`keywords`, formatData);
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${organisationId}`, config)
      .then((res) => {
        setcustomerOrgList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (organisationId !== null) {
      getCustomerList();
    } else {
      setcustomerOrgList([]);
    }
  }, [organisationId]);

  const getProcessList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `/secure/process_list?customer_id=${customerOrgId}&organisation_id=${organisationId}`,
        config
      );
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (customerOrgId) {
      getProcessList();
    } else {
      setprocessList([]);
    }
  }, [customerOrgId]);

  const handleCancel = () => {
    handleDialog();
  };

  const handleSubmit = async () => {
    setloading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = {
      id: keywordId,
      //   processes: processArr,
      organisation_id: organisationId,
      customer_id: customerOrgId,
      //   sentences: formik.values.sentences,
      questions: formik.values.keywords
    };
    await API.put('/secure/update_compliance', data, config)
      .then((res) => {
        setloading(false);
        setAlertContent(`Success : ${res.data.message}`);
        setAlert(true);
        setAlertType('success');
        handleDialog();
      })
      .then(() => {
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        setloading(false);
        setAlertContent(`${err.response.data.message}`);
        setAlert(true);
        setAlertType('error');
        handleDialog();
      });
  };

  // const [parameterShown, setShownParameter] = useState('');

  const handleParameter = (e, index) => {
    // setShownParameter(e.target.value);
    formik.setFieldValue(`keywords[${index}].Category`, e.target.value);
    const selectedValue = e.target.value;
    const selectedParameter = parameterList.find((param) => param.name === selectedValue);
    if (selectedParameter) {
      formik.setFieldValue(`keywords[${index}].weightage`, selectedParameter.max_score);
    }
  };

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200,
      maxWidth: 80
    }
  }));

  const classes = useStyles();
  const matchIndex = Details.questions.length;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Selectbox
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  disabled
                  defaultValue={organisationId}
                  onChange={(e) => setorganisationId(e.target.value)}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {organisationList
                    ? organisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Selectbox
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  disabled
                  defaultValue={customerOrgId}
                  onChange={(e) => {
                    // setServices(e.target.value.dashboard_services);
                    // console.log(e.target.value);
                    //   formik.setFieldValue('keywords', [
                    //     {
                    //       question: [''],
                    //       Category: ''
                    //     }
                    //   ]);
                    setcustomerOrgId(e.target.value);
                  }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {customerOrgList
                    ? customerOrgList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={customer_id}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="process-select-label">
                Select Process
              </InputLabel>
              <Selectbox
                required
                labelId="process-select-label"
                label="Select Process"
                id="select-process"
                disabled
                defaultValue={processArr && processArr[0]}
                onChange={(e) => setprocessArr([e.target.value])}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {processList
                  ? processList.map((element) => {
                      const process = Object.values(element)[0];
                      return (
                        <MenuItem key={element} value={element}>
                          {element}
                        </MenuItem>
                      );
                    })
                  : null}
              </Selectbox>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FieldArray
              name="keywords"
              render={(arrayHelpers) => (
                <>
                  {formik.values.keywords.map((keyword, index) => (
                    <ParameterItem
                      key={index}
                      index={index}
                      row={keyword}
                      arrayHelpers={arrayHelpers}
                      formik={formik}
                      parameterList={parameterList} // replace with your actual parameterList
                      categoryList={categoryList} // replace with your actual categoryList
                    />
                  ))}
                </>
              )}
            />
          </Grid>
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
            onClick={handleCancel}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            type="submit"
            onClick={!errors.keywords ? handleSubmit : null}
            loading={loading}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Update
          </LoadingButton>
        </Container>
      </Form>
    </FormikProvider>
  );
};
