/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import * as Yup from 'yup';
import { Formik, FormikProvider, Form, Field, FieldArray, useFormik, ErrorMessage } from 'formik';
import { Icon } from '@iconify/react';
// import FileUpload from 'react-mui-fileuploader';
import moment from 'moment';
// import MultiSelect from 'react-multi-select-component';
import {
  Grid,
  Select as Selectbox,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Container,
  Checkbox,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormHelperText,
  Box,
  Avatar,
  Button
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import uploadOutline from '@iconify/icons-mdi/upload-outline';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs'; // Import the dayjs library
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Lottie from 'react-lottie';
import './EditForm.css';
import 'react-datepicker/dist/react-datepicker.css';
import API from '../../E2E/axios.util';
import Scrollbar from '../Scrollbar';
import { Notification } from '../Notification/Notification';
import UploadGif from '../animate/animations/upload.json';
// import { values } from 'lodash';

export const OrganisationForm = ({ formik, handleDialog }) => {
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const [disable, setDisable] = useState();
  console.log('disable', disable);
  const countryList = [
    'Brazil',
    'Canada',
    'India',
    'Malaysia',
    'Mauritius',
    'Mexico',
    'Philippines',
    'Russia',
    'Singapore',
    'South Africa',
    'United Kingdom',
    'United State of America',
    'Vietnam'
  ];
  // const aws = formik.values.AwsServices;
  // // const nvidia = formik.values.NvidiaServices;
  // const customAi = formik.values.CustomAiServices;
  // console.log(aws, customAi);

  // useEffect(() => {
  //   // if (aws || nvidia === true) {
  //   //   setDisable(true);
  //   // } else if (nvidia || customAi === true) {
  //   //   setDisable(true);
  //   // } else
  //   if (aws || customAi === true) {
  //     setDisable(true);
  //   } else if (aws || customAi === false) {
  //     setDisable(false);
  //   }
  // }, [aws, customAi]);
  const handleCancel = () => {
    handleDialog();
    formik.resetForm();
  };

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));

  const classes = useStyles();

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Name"
              {...getFieldProps('OrganisationName')}
              error={Boolean(touched.OrganisationName && errors.OrganisationName)}
              helperText={touched.OrganisationName && errors.OrganisationName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              type="email"
              label="Email"
              {...getFieldProps('OrganisationEmail')}
              error={Boolean(touched.OrganisationEmail && errors.OrganisationEmail)}
              helperText={touched.OrganisationEmail && errors.OrganisationEmail}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              // type="number"
              label="Contact Number"
              {...getFieldProps('OrganisationMobile')}
              // onKeyPress={validateVolunteerRejx}
              error={Boolean(touched.OrganisationMobile && errors.OrganisationMobile)}
              helperText={touched.OrganisationMobile && errors.OrganisationMobile}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="State"
              {...getFieldProps('OrganisationState')}
              error={Boolean(touched.OrganisationState && errors.OrganisationState)}
              helperText={touched.OrganisationState && errors.OrganisationState}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="City"
              {...getFieldProps('OrganisationCity')}
              error={Boolean(touched.OrganisationCity && errors.OrganisationCity)}
              helperText={touched.OrganisationCity && errors.OrganisationCity}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Pincode"
              {...getFieldProps('OrganisationPincode')}
              error={Boolean(touched.OrganisationPincode && errors.OrganisationPincode)}
              helperText={touched.OrganisationPincode && errors.OrganisationPincode}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel required id="Country-select-label">
                Select Country
              </InputLabel>
              <Selectbox
                required
                labelId="Country-select-label"
                label="Select Country"
                // id="select-country"
                {...getFieldProps('OrganisationCountry')}
                error={Boolean(touched.OrganisationCountry && errors.OrganisationCountry)}
                helperText={touched.OrganisationCountry && errors.OrganisationCountry}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {countryList
                  ? countryList.map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))
                  : null}
              </Selectbox>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.OrganisationCountry && errors.OrganisationCountry}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              multiline
              rows={3}
              label="Address"
              {...getFieldProps('OrganisationAddress')}
              error={Boolean(touched.OrganisationAddress && errors.OrganisationAddress)}
              helperText={touched.OrganisationAddress && errors.OrganisationAddress}
            />
          </Grid>
          {/* <Grid item xs={9}>
            <div>
              <FormControlLabel
                control={<Checkbox />}
                label="AWS service"
                onChange={(e) => {
                  formik.setFieldValue('AwsServices', e.target.checked);
                }}
                disabled={formik.values.AwsServices ? false : disable}
              /> */}
          {/* <FormControlLabel
                control={<Checkbox />}
                label="Nvidia service"
                onChange={(e) => {
                  formik.setFieldValue('NvidiaServices', e.target.checked);
                }}
                disabled={formik.values.NvidiaServices ? false : disable}
              /> */}
          {/* <FormControlLabel
                control={<Checkbox />}
                label="Custom AI Service"
                onChange={(e) => {
                  formik.setFieldValue('CustomAiServices', e.target.checked);
                }}
                disabled={formik.values.CustomAiServices ? false : disable}
              />
            </div>
          </Grid> */}
          {/* <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Alloted Time"
              {...getFieldProps('OrganisationPincode')}
              error={Boolean(touched.OrganisationPincode && errors.OrganisationPincode)}
              helperText={touched.OrganisationPincode && errors.OrganisationPincode}
            />
          </Grid> */}
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              variant="contained"
              onClick={handleCancel}
              style={{
                marginRight: 5,
                marginBottom: 5,
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={handleSubmit}
              loading={isSubmitting}
              style={{
                marginRight: 5,
                marginBottom: 5,
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              Add
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export const OrganisationEditForm = ({
  formik,
  organisationDetail,
  setAlertContent,
  setAlertType,
  setAlert,
  isUpdated,
  updateComponent,
  handleDialog
}) => {
  const [orgId, setorgId] = useState(organisationDetail.organisation_id);
  const [orgName, setorgName] = useState(organisationDetail.organisation_name);
  const [orgEmail, setorgEmail] = useState(organisationDetail.organisation_email);
  const [orgMobile, setorgMobile] = useState(organisationDetail.organisation_mobile);
  const [orgCity, setorgCity] = useState(organisationDetail.organisation_city);
  const [orgState, setorgState] = useState(organisationDetail.organisation_state);
  const [orgCountry, setorgCountry] = useState(organisationDetail.organisation_country);
  console.log('orgCountry', orgCountry);
  const [orgAddress, setorgAddress] = useState(organisationDetail.organisation_address);
  const [orgPincode, setorgPincode] = useState(organisationDetail.organisation_pincode);
  const [loading, setloading] = useState(false);

  const validateVolunteerRejx = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const countryList = [
    'Brazil',
    'Canada',
    'India',
    'Malaysia',
    'Mauritius',
    'Mexico',
    'Philippines',
    'Russia',
    'Singapore',
    'South Africa',
    'United Kingdom',
    'United State of America',
    'Vietnam'
  ];

  const initialValues = {
    name: orgName,
    email: orgEmail,
    mobile: orgMobile,
    address: orgAddress,
    country: orgCountry,
    city: orgCity,
    state: orgState,
    pincode: orgPincode
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{1,50}$/,
        'Organisation name should be 2 to 50 character long and it should not start with blank space and not have special character and number.'
      )
      .max(50, 'Organisation name should be in between 2 to 50 character long .')
      .required('This field is required.'),
    email: Yup.string()
      .email('Email must be a valid email address.')
      .matches(/^[\w-.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, 'Email must be a valid email address.')
      .required('This field is required.'),
    mobile: Yup.string()
      .matches(/^((\+91-?)|\+)?[0-9][0-9]{9}$/, 'Invalid contact number.')
      .nullable()
      .required('This field is required.'),
    address: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{2,500}$/,
        'Address length should be 3 to 500 character long and it should not start with space and does not contain special character and number.'
      )
      .max(500, 'Address length should be 3 to 500 character long.')
      .required('This field is required.'),
    country: Yup.string().required('This field is required.'),
    state: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{1,50}$/,
        'State name should be 2 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'State name should be 2 to 30 character long.')
      .required('This field is required.'),
    city: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{1,50}$/,
        'City name should be 2 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'City name should be 2 to 30 character long.')
      .required('This field is required.'),
    pincode: Yup.string()
      .matches(/^\S+[0-9 ]{5,6}$/, 'Invalid pincode.')
      .max(6, 'Pincode should be 5 to 6 digits.')
      .nullable()
      .required('This field is required.')
  });

  // console.log('formik.values.OrganisationName', errors.OrganisationName);
  const handleEditOrganisation = async (values) => {
    setloading(true);
    console.log('values', values);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = {
      id: orgId,
      name: values.name,
      email: values.email,
      mobile: values.mobile,
      address: values.address,
      country: orgCountry,
      city: values.city,
      state: values.state,
      pincode: values.pincode
    };
    await API.put('/secure/update_organisation', data, config)
      .then((res) => {
        setloading(false);
        setAlertContent(`Success : ${res.data.message}`);
        setAlert(true);
        setAlertType('success');
        handleDialog();
      })
      .then(() => {
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        setloading(false);
        setAlertContent(`${err.response.data.message}`);
        setAlert(true);
        setAlertType('error');
        handleDialog();
      });
  };

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));

  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleEditOrganisation}
      validationSchema={validationSchema}
    >
      {(props) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Field
                as={TextField}
                label="Name"
                name="name"
                placeholder="Organisation Name"
                fullWidth
                error={props.errors.name && props.touched.name}
                size="big"
                helperText={<ErrorMessage name="name" />}
                required
                sx={{ marginBottom: '1.5%' }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                label="Email"
                name="email"
                placeholder="Email"
                fullWidth
                error={props.errors.email && props.touched.email}
                size="big"
                helperText={<ErrorMessage name="email" />}
                required
                sx={{ marginBottom: '1.5%' }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                label="Mobile"
                name="mobile"
                placeholder="Contact Number"
                fullWidth
                error={props.errors.mobile && props.touched.mobile}
                size="big"
                helperText={<ErrorMessage name="mobile" />}
                required
                sx={{ marginBottom: '1.5%' }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                label="State"
                name="state"
                placeholder="State"
                fullWidth
                error={props.errors.state && props.touched.state}
                size="big"
                helperText={<ErrorMessage name="state" />}
                required
                sx={{ marginBottom: '1.5%' }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                label="City"
                name="city"
                placeholder="City"
                fullWidth
                error={props.errors.city && props.touched.city}
                size="big"
                helperText={<ErrorMessage name="city" />}
                required
                sx={{ marginBottom: '1.5%' }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                label="Pincode"
                name="pincode"
                placeholder="Pincode"
                fullWidth
                error={props.errors.pincode && props.touched.pincode}
                size="big"
                helperText={<ErrorMessage name="pincode" />}
                required
                sx={{ marginBottom: '1.5%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel required id="Country-select-label">
                  Select Country
                </InputLabel>
                <Selectbox
                  required
                  labelId="Country-select-label"
                  label="Select Country"
                  id="select-country"
                  name="country"
                  defaultValue={orgCountry}
                  onChange={(e) => setorgCountry(e.target.value)}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {countryList
                    ? countryList.map((country) => (
                        <MenuItem key={country} value={country}>
                          {country}
                        </MenuItem>
                      ))
                    : null}
                </Selectbox>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Address"
                name="address"
                placeholder="Address"
                fullWidth
                error={props.errors.address && props.touched.address}
                size="big"
                helperText={<ErrorMessage name="address" />}
                required
                sx={{ marginBottom: '1.5%' }}
              />
            </Grid>
          </Grid>
          <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              variant="contained"
              onClick={handleDialog}
              style={{
                marginRight: 5,
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              variant="contained"
              type="submit"
              // onClick={handleEditOrganisation}
              loading={loading}
              style={{
                marginRight: 5,
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              Update
            </LoadingButton>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export const CustomerEditForm = ({
  formik,
  setAlertContent,
  setAlertType,
  setAlert,
  isUpdated,
  updateComponent,
  handleDialog,
  customerDetails
}) => {
  const languageList = ['Hindi', 'English', 'Marathi', 'Gujrati', 'Arabic', 'Telugu', 'Oriya'];
  // const salesLanguageList = ['Hindi', 'English'];
  // const options = ['tone', 'unpleasant', 'dead_air', 'sentiments'];
  const options = [
    { name: 'Tone', id: 'tone' },
    { name: 'Unpleasant Words', id: 'unpleasant' },
    { name: 'Dead Air', id: 'dead_air' },
    { name: 'Sentiments', id: 'sentiments' }
  ];
  const dashboards = [
    { name: 'Revenue Intelligence', id: 'sales' },
    { name: 'Call Analysis', id: 'collections' },
    { name: 'Compliance', id: 'compliance' },
    { name: 'Collections', id: 'collections_2' }
  ];
  console.log('customerDetails', customerDetails);
  // const { getFieldProps } = formik;
  const [customerId, setcustomerId] = useState(customerDetails.customer_id);
  const [organisationName, setorganisationName] = useState(customerDetails.organisation_id);
  const [domainName, setdomainName] = useState(customerDetails.domain_id);
  const [customerOrgName, setcustomerOrgName] = useState(
    customerDetails.customer_organisation_name
  );
  const [customerFirstName, setcustomerFirstName] = useState(customerDetails.customer_first_name);
  const [customerLastName, setcustomerLastName] = useState(customerDetails.customer_last_name);
  const [customerEmail, setcustomerEmail] = useState(customerDetails.customer_email);
  const [customerMobile, setcustomerMobile] = useState(customerDetails.customer_mobile);
  const [customerProcess, setCustomerProcess] = useState(customerDetails.processes);
  const [loading, setloading] = useState(false);
  const [FileData, setFileData] = useState(
    customerDetails.logo
      ? {
          name: customerDetails.logo.split('/')[customerDetails.logo.split('/').length - 1],
          data: null
        }
      : { name: '', data: null }
  );
  const [customerLanguage, setCustomerLanguage] = useState(
    customerDetails.customer_language ? customerDetails.customer_language : []
  );
  const customerDashboardServices = [];
  if (customerDetails.dashboard_services.includes('collections')) {
    customerDashboardServices.push('Call Analysis');
  }
  if (customerDetails.dashboard_services.includes('sales')) {
    customerDashboardServices.push('Revenue Intelligence');
  }
  if (customerDetails.dashboard_services.includes('compliance')) {
    customerDashboardServices.push('Compliance');
  }
  if (customerDetails.dashboard_services.includes('collections_2')) {
    customerDashboardServices.push('Collections');
  }
  const [selectDashboard, setSelectDashboard] = useState(customerDashboardServices);
  const [customerKPI, setCustomerKPI] = useState(customerDetails.kpi_services);
  const [aws, setAws] = useState(customerDetails.aws_services);
  const [customAi, setCustomAi] = useState(customerDetails.custom_ai_services);
  const [audioFileData, setaudioFileData] = useState({ name: '', data: null });
  const [salesChecked, setsalesChecked] = useState(customerDetails.aws_services);
  const [collectionsChecked, setcollectionsChecked] = useState(customerDetails.custom_ai_services);
  // console.log('values.customerProcess ------', customerProcess);
  const userDetails = JSON.parse(localStorage.getItem('user'));

  const initialValues = {
    orgName: organisationName,
    customerOrg: customerOrgName,
    language: customerLanguage,
    process: customerProcess,
    firstName: customerFirstName,
    lastName: customerLastName,
    email: customerEmail,
    mobile: customerMobile,
    domain: domainName,
    dispositionLevel: customerDetails?.level_1
      ? 'level_1'
      : customerDetails?.level_2
      ? 'level_2'
      : customerDetails?.level_3
      ? 'level_3'
      : '',
    dispositionEnable: customerDetails?.intent_disposition
      ? customerDetails.intent_disposition
      : false,
    dashboardServices: customerDetails.dashboard_services,
    kpiServices: customerKPI,
    awsServices: aws,
    customAiServices: customAi
  };
  console.log('initialValues', initialValues);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{3,50}$/,
        'Firstname should in between 3 to 30 character long and it should not start with space and does not contain special character and number.'
      )
      .max(30, 'Firstname should be in between 3 to 30 character long.')
      .required('This field is required.'),
    lastName: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{3,50}$/,
        'Lastname should in between 3 to 30 character and it should not start with space and does not contain special character and number.'
      )
      .max(
        30,
        'Lastname should be in between 3 to 30 character and it should not start with space and does not contain special character and number.'
      )
      .required('This field is required.'),
    orgName: Yup.string().required('This field is required.'),
    email: Yup.string()
      .email('Email must be valid email address.')
      .matches(/^[\w-.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, 'Email must be a valid email address.')
      .required('This field is required.'),
    mobile: Yup.string()
      .matches(/^((\+91-?)|\+)?[0-9][0-9]{9}$/, 'Invalid contact number.')
      .required('This field is required.'),
    customerOrg: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{3,40}$/,
        'Organisation name should be 3 to 40 character long and it should not start with blank space and not have special character and number.'
      )
      .max(40, 'Customer Organisation Name should be in between 3 to 40 character long.')
      .required('This field is required.'),
    process: Yup.array().of(
      Yup.string()
        .matches(
          /^(?!^ )[A-Za-z ]{3,30}$/,
          'Process should be in between 3 to 30 character and it should not start with space and does not contain special character and number.'
        )
        .max(30, 'Process should be in between 3 to 30 character long.')
        .required('This field is required.')
    ),
    domain: Yup.string().required('This field is required.'),
    dispositionLevel: Yup.string().when('dispositionEnable', {
      is: true, // When param1 is true
      then: Yup.string().required('Disposition Level is required.'), // param2 is required
      otherwise: Yup.string() // Otherwise, no validation
    }),
    kpiServices: Yup.array().min(1, 'This field is required.'),
    language: Yup.array().min(1, 'This field is required.'),
    dashboardServices: Yup.array().min(1, 'This field is required.')
  });

  /* ------ Creating state for organisation api --------- */
  const [orgList, setorgList] = useState();
  const [domainList, setdomainList] = useState();
  let logoName;

  if (customerDetails.logo) {
    logoName = customerDetails.logo.split('/');
  }

  const inputRef = React.useRef(null);

  useEffect(() => {
    formik.setFieldValue(`process`, customerDetails.processes);
  }, []);

  const handleOrganisationList = () => {
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getOrganisation() {
      return API.get(`/secure/list_organisation?page=1&max_rows=10`, config);
    }
    function getDomain() {
      return API.get(`/secure/list_domain?page=1&max_rows=10`, config);
    }
    Promise.all([getOrganisation(), getDomain()])
      .then(([orgData, domainData]) => {
        setorgList(orgData.data.organisation_list);
        setdomainList(domainData.data.domain_list);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  React.useEffect(() => {
    handleOrganisationList();
  }, []);

  const handleFileChange = (event, fileName) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (fileReader.readyState === 2) {
        const file = e.target.result;
        setFileData({ name: fileName.name, data: file });
        formik.setFieldValue(`logo`, { name: fileName.name, data: file });
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };
  console.log('formik.values.logo', formik.values.logo);
  const logoSampleFileName = (logoName) => {
    if (logoName) {
      return logoName[logoName.length - 1];
    }
    return 'Upload Logo';
  };

  const handleEditCustomer = async (values) => {
    console.log('render handleEditCustomer');
    setloading(true);
    console.log('first', values);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = {
      id: customerId,
      customer_organisation_name: values.customerOrg,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      mobile: values.mobile,
      logo: FileData.data ? FileData : '',
      customer_language: values.language,
      processes: values.process,
      organisation_id: organisationName,
      domain_id: domainName,
      intent_disposition: values.dispositionEnable,
      level_1: values.dispositionLevel === 'level_1',
      level_2: values.dispositionLevel === 'level_2',
      level_3: values.dispositionLevel === 'level_3',
      dashboard_services: values.dashboardServices,
      kpi_services: values.kpiServices,
      custom_ai_services: values.customAiServices,
      aws_services: values.awsServices
    };
    await API.put('/secure/update_customer', data, config)
      .then((res) => {
        setloading(false);
        setAlertContent(`Success : ${res.data.message}`);
        setAlert(true);
        setAlertType('success');
        handleDialog();
      })
      .then(() => {
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        setloading(false);
        setAlertContent(`${err.response.data.message}`);
        setAlert(true);
        setAlertType('error');
        // handleDialog();
      });
  };
  // <---------------------------------------------------------------------------->
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    variant: 'menu'
  };
  const useStyles = makeStyles((theme) => ({
    formControl: {
      // margin: theme.spacing(1),
      width: 300
    },
    indeterminateColor: {
      color: '#f50057'
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    menuPaper: {
      maxHeight: 200
    }
  }));

  const newDash = dashboards.map((el) => el.name);
  const newDashId = dashboards.map((el) => el.id);
  const classes = useStyles();
  const dashboardAllSelected = newDash.length > 0 && selectDashboard.length === newDash.length;
  const handleDashboardChange = (event, props) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setSelectDashboard(selectDashboard.length === newDash.length ? [] : newDash);
      props.setFieldValue(
        'dashboardServices',
        selectDashboard.length === newDashId.length ? [] : newDashId
      );
      return;
    }
    const dashboardServices = [];
    if (value.includes('Call Analysis')) {
      dashboardServices.push('collections');
    }
    if (value.includes('Revenue Intelligence')) {
      dashboardServices.push('sales');
    }
    if (value.includes('Compliance')) {
      dashboardServices.push('compliance');
    }
    if (value.includes('Collections')) {
      dashboardServices.push('collections_2');
    }
    props.setFieldValue('dashboardServices', dashboardServices);
    // props.setFieldValue('dashboardServices', value);
    setSelectDashboard(value);
  };
  const handleServiceChange = (event, props) => {
    setSelectDashboard([event.target.value]);
    // console.log('first-----', [event.target.value]);
    props.setFieldValue('dashboardServices', [event.target.value]);
    // setcollectionsChecked(false);
    // setsalesChecked(false);
    // props.setFieldValue('awsServices', false);
    // props.setFieldValue('customAiServices', false);
  };

  const kpiDash = options.map((el) => el.id);
  const kpiAllSelected = kpiDash.length > 0 && customerKPI.length === kpiDash.length;
  const handleKpiChange = (event, props) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setCustomerKPI(customerKPI.length === kpiDash.length ? [] : kpiDash);
      props.setFieldValue('kpiServices', customerKPI.length === kpiDash.length ? [] : kpiDash);
      return;
    }
    props.setFieldValue('kpiServices', value);
    setCustomerKPI(value);
  };
  const isAllLanguageSelected =
    languageList.length > 0 && customerLanguage.length === languageList.length;

  const handleLanguageChange = (event, props) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setCustomerLanguage(customerLanguage.length === languageList.length ? [] : languageList);
      props.setFieldValue(
        'language',
        customerLanguage.length === languageList.length ? [] : languageList
      );
      return;
    }
    props.setFieldValue('language', value);
    setCustomerLanguage(value);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleEditCustomer}
      validationSchema={validationSchema}
    >
      {(props) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {userDetails.role !== 'Super_Admin' ? (
                <TextField
                  required
                  fullWidth
                  label="Organisation Name"
                  defaultValue={userDetails.organisation_name}
                  disabled
                />
              ) : (
                <FormControl fullWidth>
                  <InputLabel required id="Organisation-select-label">
                    Select Organisation
                  </InputLabel>
                  <Selectbox
                    required
                    labelId="Organisation-select-label"
                    label="Select Organisation"
                    id="select-organisation"
                    defaultValue={organisationName}
                    onChange={(e) => setorganisationName(e.target.value)}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                  >
                    {orgList
                      ? orgList.map((element) => {
                          const { organisation_id, organisation_name } = element;
                          return (
                            <MenuItem key={organisation_id} value={organisation_id}>
                              {organisation_name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Selectbox>
                </FormControl>
              )}
            </Grid>
            {/* <Grid item xs={6}>
              <TextField
                required
                fullWidth
                label="Customer Organisation Name"
                defaultValue={customerOrgName}
                onChange={(e) => setcustomerOrgName(e.target.value)}
              />
            </Grid> */}
            <Grid item xs={6}>
              <Field
                as={TextField}
                label="Customer Organisation Name"
                name="customerOrg"
                placeholder="Customer Organisation Name"
                fullWidth
                error={props.errors.customerOrg && props.touched.customerOrg}
                size="big"
                helperText={<ErrorMessage name="customerOrg" />}
                required
                sx={{ marginBottom: '1.5%' }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                {/* <InputLabel required id="Dashboards-select-label">
                  Select Dashboards
                </InputLabel>
                <Selectbox
                  required
                  labelId="Dashboards-select-label"
                  label="Select Dashboards"
                  id="select-dashboards"
                  // {...getFieldProps('dashboardServices')}
                  value={selectDashboard}
                  onChange={(e) => handleServiceChange(e, props)}
                  // error={Boolean(touched.dashboardServices && errors.dashboardServices)}
                  // helperText={touched.dashboardServices && errors.dashboardServices}
                >
                  {dashboards
                    ? dashboards.map((element) => (
                        // const { organisation_id, organisation_name } = element;
                        <MenuItem key={element.id} value={element.id}>
                          {element.name}
                        </MenuItem>
                      ))
                    : null}
                </Selectbox> */}
                {/* <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.dashboardServices && errors.dashboardServices}
                </FormHelperText> */}
                <InputLabel required labelId="Mutiple-select-label">
                  Select Dashboards
                </InputLabel>
                <Selectbox
                  labelId="Mutiple-select-label"
                  label="Select Dashboard"
                  id="select-dashboard"
                  multiple
                  value={selectDashboard}
                  onChange={(e) => handleDashboardChange(e, props)}
                  renderValue={(selectDashboard) => selectDashboard.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                    classes={{
                      // root: isAllSelected ? classes.selectedAll : ''
                      root: dashboardAllSelected ? classes.selectedAll : ''
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={dashboardAllSelected}
                        indeterminate={
                          selectDashboard.length > 0 && selectDashboard.length < newDash.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {dashboards.map((dashboard) => (
                    <MenuItem key={dashboard.id} value={dashboard.name}>
                      <ListItemIcon>
                        <Checkbox checked={selectDashboard.indexOf(dashboard.name) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={dashboard.name} />
                    </MenuItem>
                  ))}
                </Selectbox>
              </FormControl>
            </Grid>
            {/* <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel required id="language-select-label">
                  Select Language
                </InputLabel>
                <Selectbox
                  required
                  labelId="language-select-label"
                  label="Select Language"
                  id="select-Language"
                  defaultValue={customerLanguage}
                  onChange={(e) => setCustomerLanguage(e.target.value)}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {allLanguageList.map((element) => (
                    // const { organisation_id, organisation_name } = element;
                    <MenuItem key={element} value={element}>
                      {element}
                    </MenuItem>
                  ))}
                </Selectbox>
                <Selectbox
                  required
                  labelId="language-select-label"
                  label="Select Language"
                  id="select-Language"
                  defaultValue={customerLanguage}
                  onChange={(e) => setCustomerLanguage(e.target.value)}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {allLanguageList.map((element) => (
                    // const { organisation_id, organisation_name } = element;
                    <MenuItem key={element} value={element}>
                      {element}
                    </MenuItem>
                  ))}
                </Selectbox>
              </FormControl>
            </Grid> */}
            <Grid item xs={6}>
              <FieldArray
                name="process"
                render={(arrayHelpers) => (
                  <>
                    {props.values.process.map((row, index) => {
                      console.log('index--', index);
                      console.log('props ----', props.values);
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '5px'
                          }}
                        >
                          {/* <Field
                            as={TextField}
                            label="Process Name"
                            name="process"
                            placeholder="Process Name"
                            fullWidth
                            error={props.errors.process && props.touched.process}
                            size="big"
                            helperText={<ErrorMessage name="process" />}
                            required
                            sx={{ marginBottom: '1.5%' }}
                          /> */}
                          <TextField
                            // defaultValue={row}
                            required
                            fullWidth
                            label="Process"
                            {...props.getFieldProps(`process[${index}]`)}
                            error={Boolean(
                              props.touched &&
                                props.touched.process &&
                                props.touched.process[index] &&
                                props.touched.process[index] &&
                                props.errors &&
                                props.errors.process &&
                                props.errors.process[index] &&
                                props.errors.process[index]
                            )}
                            helperText={<ErrorMessage name={`process[${index}]`} />}
                          />
                          {index === props.values.process.length - 1 ? (
                            <>
                              <RemoveCircleIcon
                                sx={{
                                  color: 'white',
                                  backgroundColor: '#3892CF',
                                  '&:hover': {
                                    backgroundColor: '#3892CF'
                                  },
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                  marginX: '5px',
                                  visibility: `${index > 0 ? 'visible' : 'hidden'}`
                                }}
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                              <AddCircleIcon
                                sx={{
                                  color: 'white',
                                  backgroundColor: '#3892CF',
                                  '&:hover': {
                                    backgroundColor: '#3892CF'
                                  },
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                  marginX: '5px'
                                }}
                                onClick={() => {
                                  arrayHelpers.push('');
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <RemoveCircleIcon
                                sx={{
                                  color: 'white',
                                  backgroundColor: '#3892CF',
                                  '&:hover': {
                                    backgroundColor: '#3892CF'
                                  },
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                  marginX: '5px'
                                }}
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                              <AddCircleIcon
                                sx={{
                                  color: 'white',
                                  backgroundColor: '#3892CF',
                                  '&:hover': {
                                    backgroundColor: '#3892CF'
                                  },
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                  marginX: '5px',
                                  visibility: 'hidden'
                                }}
                                onClick={() => {
                                  arrayHelpers.push('');
                                }}
                              />
                            </>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              {/* <TextField
                required
                fullWidth
                label="First Name"
                defaultValue={customerFirstName}
                onChange={(e) => setcustomerFirstName(e.target.value)}
              /> */}
              <Field
                as={TextField}
                label="First Name"
                name="firstName"
                placeholder="First Name"
                fullWidth
                error={props.errors.firstName && props.touched.firstName}
                size="big"
                helperText={<ErrorMessage name="firstName" />}
                required
                sx={{ marginBottom: '1.5%' }}
              />
            </Grid>
            <Grid item xs={6}>
              {/* <TextField
                required
                fullWidth
                label="Last Name"
                defaultValue={customerLastName}
                onChange={(e) => setcustomerLastName(e.target.value)}
              /> */}
              <Field
                as={TextField}
                label="Last Name"
                name="lastName"
                placeholder="Last Name"
                fullWidth
                error={props.errors.lastName && props.touched.lastName}
                size="big"
                helperText={<ErrorMessage name="lastName" />}
                required
                sx={{ marginBottom: '1.5%' }}
              />
            </Grid>
            <Grid item xs={6}>
              {/* <TextField
                required
                fullWidth
                label="Email"
                defaultValue={customerEmail}
                onChange={(e) => setcustomerEmail(e.target.value)}
              /> */}
              <Field
                as={TextField}
                label="Email"
                name="email"
                placeholder="Email"
                fullWidth
                error={props.errors.email && props.touched.email}
                size="big"
                helperText={<ErrorMessage name="email" />}
                required
                sx={{ marginBottom: '1.5%' }}
              />
            </Grid>
            <Grid item xs={6}>
              {/* <TextField
                required
                fullWidth
                label="Mobile"
                defaultValue={customerMobile}
                onChange={(e) => setcustomerMobile(e.target.value)}
              /> */}
              <Field
                as={TextField}
                label="Mobile No"
                name="mobile"
                placeholder="Mobile"
                fullWidth
                error={props.errors.mobile && props.touched.mobile}
                size="big"
                helperText={<ErrorMessage name="mobile" />}
                required
                sx={{ marginBottom: '1.5%' }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel required id="domain-select-label">
                  Business Domain
                </InputLabel>
                <Selectbox
                  required
                  labelId="domain-select-label"
                  label="Business Domain"
                  id="select-business"
                  defaultValue={domainName}
                  onChange={(e) => setdomainName(e.target.value)}
                >
                  {domainList
                    ? domainList.map((domain) => {
                        const { domain_id, domain_name } = domain;
                        return (
                          <MenuItem key={domain_id} value={domain_id}>
                            {domain_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{
                        color: '#3892CF',
                        '&:hover': {
                          color: '#3892CF'
                        }
                      }}
                    />
                  }
                  checked={props.values.dispositionEnable}
                  label="Disposition Enable"
                  onChange={(e) => {
                    props.setFieldValue('dispositionEnable', e.target.checked);
                    if (e.target.checked === false) {
                      props.setFieldValue('dispositionLevel', '');
                    }
                  }}
                />
              </div>
            </Grid>
            {props.values.dispositionEnable && (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel required id="disposition-select-label">
                    Select Disposition Level
                  </InputLabel>
                  <Selectbox
                    required
                    labelId="disposition-select-label"
                    label="Select Disposition Level"
                    id="select-level"
                    {...props.getFieldProps('dispositionLevel')}
                    error={Boolean(props.touched.dispositionLevel && props.errors.dispositionLevel)}
                    helperText={props.touched.dispositionLevel && props.errors.dispositionLevel}
                  >
                    <MenuItem value="level_1">Level 1</MenuItem>
                    <MenuItem value="level_2">Level 2</MenuItem>
                    <MenuItem value="level_3">Level 3</MenuItem>
                  </Selectbox>
                  <FormHelperText sx={{ color: '#d32f2f' }}>
                    {props.touched.dispositionLevel && props.errors.dispositionLevel}
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel required labelId="Mutiple-select-label">
                  Select KPI's
                </InputLabel>
                <Selectbox
                  labelId="Mutiple-select-label"
                  label="Select Process"
                  id="select-process"
                  multiple
                  value={customerKPI}
                  onChange={(e) => handleKpiChange(e, props)}
                  renderValue={(customerKPI) => customerKPI.join(', ')}
                  MenuProps={MenuProps}
                  error={Boolean(props.touched.kpiServices && props.errors.kpiServices)}
                  helperText={props.touched.kpiServices && props.errors.kpiServices}
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: kpiAllSelected ? classes.selectedAll : ''
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={kpiAllSelected}
                        indeterminate={
                          customerKPI.length > 0 && customerKPI.length < kpiDash.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      <ListItemIcon>
                        <Checkbox checked={customerKPI.indexOf(option.id) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  ))}
                </Selectbox>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {props.touched.kpiServices && props.errors.kpiServices}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {/* <MultiSelect selected={selectedOption} onChange={setSelectedOption} options={options} /> */}
              <FormControl fullWidth>
                <InputLabel required labelId="Mutiple-select-label">
                  Select Language's
                </InputLabel>
                <Selectbox
                  required
                  labelId="Mutiple-select-label"
                  label="Select Language"
                  id="select-language"
                  name="customerServices"
                  multiple
                  value={customerLanguage}
                  onChange={(e) => handleLanguageChange(e, props)}
                  renderValue={(customerLanguage) => customerLanguage.join(', ')}
                  MenuProps={MenuProps}
                  onBlur={props.handleBlur}
                  error={Boolean(props.touched.language && props.errors.language)}
                  helperText={props.touched.language && props.errors.language}
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllLanguageSelected ? classes.selectedAll : ''
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllLanguageSelected}
                        indeterminate={
                          customerLanguage.length > 0 &&
                          customerLanguage.length < languageList.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {languageList.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={customerLanguage.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Selectbox>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {props.touched.language && props.errors.language}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{
                        color: props.values.customAiServices === true ? 'grey' : '#3892CF',
                        '&:hover': {
                          color: '#3892CF'
                        }
                      }}
                      defaultChecked={customerDetails.aws_services}
                    />
                  }
                  label="AWS service"
                  defaultValue={customerDetails.aws_services}
                  onChange={(e) => {
                    console.log('e.checked', e.target.checked);
                    props.setFieldValue('awsServices', e.target.checked);
                  }}
                  disabled={props.values.customAiServices}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{
                        color: props.values.awsServices === true ? 'grey' : '#3892CF',
                        '&:hover': {
                          color: '#3892CF'
                        }
                      }}
                      defaultChecked={customerDetails.custom_ai_services}
                    />
                  }
                  label="Custom AI Service"
                  defaultValue={customerDetails.custom_ai_services}
                  onChange={(e) => {
                    console.log('e.checked', e.target.checked);
                    props.setFieldValue('customAiServices', e.target.checked);
                  }}
                  disabled={props.values.awsServices}
                />
              </div>
              {/* <div>
                {props.values.dashboardServices.includes('collections') ? (
                  <FormControlLabel
                    control={<Checkbox checked={collectionsChecked} />}
                    label="Custom AI Service"
                    onChange={(e) => {
                      props.setFieldValue('customAiServices', e.target.checked);
                      setcollectionsChecked(e.target.checked);
                      props.setFieldValue('awsServices', false);
                    }}
                  />
                ) : (
                  props.values.dashboardServices.includes('sales') && (
                    <FormControlLabel
                      control={<Checkbox checked={salesChecked} />}
                      label="AWS service"
                      onChange={(e) => {
                        props.setFieldValue('awsServices', e.target.checked);
                        setsalesChecked(e.target.checked);
                        props.setFieldValue('customAiServices', false);
                      }}
                    />
                  )
                )}
              </div> */}
            </Grid>
            <Grid item xs={6}>
              <>
                <LoadingButton
                  fullWidth
                  required
                  size="large"
                  variant="contained"
                  startIcon={<Icon icon={uploadOutline} />}
                  sx={{
                    height: 55,
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                >
                  {!FileData.name ? logoSampleFileName(logoName) : FileData.name}
                  {FileData.data || customerDetails.logo ? (
                    <Avatar
                      src={
                        FileData.data
                          ? FileData.data
                          : `${process.env.REACT_APP_SERVER_MEDIA_URL}${customerDetails.logo}`
                      }
                      alt="photoURL"
                      sx={{ marginLeft: 2 }}
                    />
                  ) : null}
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    multiple
                    ref={inputRef}
                    onChange={(event) => {
                      handleFileChange(event, event.currentTarget.files[0]);
                    }}
                    required
                  />
                </LoadingButton>
                {/* {FileData.data || customerDetails.logo ? (
                  <img
                    src={
                      FileData.data
                        ? FileData.data
                        : `${process.env.REACT_APP_SERVER_APP_URL}${customerDetails.logo}`
                    }
                    alt="Not Found"
                    height="100"
                    width="300"
                  />
                ) : null} */}
              </>
            </Grid>
          </Grid>
          <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              variant="contained"
              onClick={handleDialog}
              style={{
                marginRight: 5,
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              variant="contained"
              type="submit"
              // onClick={handleEditCustomer}
              loading={loading}
              style={{
                marginRight: 5,
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              Update
            </LoadingButton>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export const UserForm = ({ props, handleDialog, userRoleName, setUserRoleName }) => {
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = props;
  const inputRef = React.useRef(null);
  const inputImgRef = React.useRef(null);
  const [userAudio, setuserAudio] = useState({
    File_Name: '',
    Data: ''
  });
  const [userImage, setuserImage] = useState({
    File_Name: '',
    Data: ''
  });
  const [OrganisationList, setOrganisationList] = useState();
  const [customerList, setcustomerList] = useState();
  const [roleList, setroleList] = useState();
  const [processList, setprocessList] = useState();
  const [languageList, setLanguageList] = useState([]);
  const [processName, setprocessName] = useState();
  const [updatedRoleList, setupdatedRoleList] = useState();

  const userDetails = JSON.parse(localStorage.getItem('user'));
  const [options, setoptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [qaList, setQaList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [selectedDateOfJoining, setSelectedDateOfJoining] = useState(null);
  const [QAElement, setQAElement] = useState();

  console.log('userRoleName', userRoleName);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: 54
      // overflow: 'scroll'
    }),
    valueContainer: (base, state) => ({
      ...base,
      maxHeight: 40,
      overflow: 'auto'
    })
  };

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));
  const classes = useStyles();
  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${props.values.organisation}`, config)
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (props.values.organisation !== '') {
      getCustomerList();
    } else {
      setcustomerList([]);
    }
  }, [props.values.organisation]);

  const getRoleList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getRole() {
      return API.get(`/secure/role_list`, config);
    }
    function getLanguage() {
      return API.get(`/secure/language_list?customer_id=${props.values.customer}`, config);
    }

    Promise.all([getRole(), getLanguage()])
      .then(([roleData, languageRes]) => {
        setroleList(roleData.data.roles);
        setLanguageList(languageRes.data.language_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createFilterOptions = (value) => {
    const arr = [];
    value.map((process) => {
      const obj1 = { value: process, label: process };
      arr.push(obj1);
      return process;
    });
    setoptions(arr);
  };

  useEffect(() => {
    const processArr = [];
    if (selectedOption) {
      selectedOption.map((data) => {
        const processObj = { process: data.value };
        processArr.push(data.value);
        return data;
      });
    }
    props.setFieldValue('process', processArr);
  }, [selectedOption]);
  // const [output, setoutput] = React.useState([]);
  // const handleChange = (event) => {
  //   console.log('check');
  //   setoutput([event.target.value]);
  //   props.setFieldValue('process', output);
  // };
  // useEffect(() => {
  //   setSelected(output);
  // }, [output]);

  useEffect(() => {
    if (props.values.customer !== null) {
      getRoleList();
    } else {
      setroleList([]);
    }
  }, [props.values.customer]);

  const getProcessList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `/secure/process_list?customer_id=${props.values.customer}&organisation_id=${props.values.organisation}`,
        config
      );
    }

    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
        createFilterOptions(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (props.values.role) {
      getProcessList();
    } else {
      setprocessList([]);
    }
  }, [props.values.role]);

  const handleAudioFileChange = (event, fileName) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const fileType = event.target.files[0].type.substring(0, 6);
      console.log('event.target.file ----', fileType);
      if (fileType !== 'audio/') {
        alert('Invalid File Format');
        setuserAudio({});
      } else if (fileReader.readyState === 2) {
        const file = e.target.result;
        setuserAudio({ File_Name: fileName.name, Data: file });
        props.setFieldValue(`userVoice`, { file_name: fileName.name, file_data: file });
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };

  const handleImgFileChange = (event, fileName) => {
    console.log('event------', fileName);
    // console.log('event------', event.target.files[0]);
    const file_type = fileName.type.substring(0, 6);
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (fileReader.readyState === 2 && file_type === 'image/') {
        const file = e.target.result;
        setuserImage({ File_Name: fileName.name, Data: file });
        props.setFieldValue(`userPic`, { name: fileName.name, data: file });
      } else {
        // setAlert(true);
        alert('Select valid file');
        // setAlertContent('Select valid file');
        // setAlertType('error');
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };

  const handleProcess = (processValue) => {
    props.setFieldValue('process', [processValue]);
    setprocessName([processValue]);
  };

  const handleCancel = () => {
    handleDialog();
    props.resetForm();
  };

  // Removing super_admin role from role list
  useEffect(() => {
    const filterRoles = () => {
      if (roleList) {
        const newRoleList = roleList.filter(
          (role) => role.role_name !== 'Super_Admin' && role.role_name !== 'Guest'
        );

        setupdatedRoleList(newRoleList);
        console.log('role list in useEffect', newRoleList);
      }
    };

    filterRoles();
  }, [roleList]);

  const getQaList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getQa() {
      return API.get(
        `/secure/QA_List?customer_id=${props.values.customer}&organisation_id=${props.values.organisation}`,
        config
      );
    }

    Promise.all([getQa()])
      .then(([res]) => {
        console.log('qa res', res.data);
        setQaList(res.data.qa_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getManagerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getManager() {
      return API.get(
        `/secure/Manager_List?customer_id=${props.values.customer}&organisation_id=${props.values.organisation}`,
        config
      );
    }

    Promise.all([getManager()])
      .then(([res]) => {
        console.log('qa res', res.data);
        setManagerList(res.data.manager_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      props.values.customer !== '' &&
      props.values.organisation !== '' &&
      userRoleName === 'Agent'
    ) {
      getQaList();
      getManagerList();
    }
  }, [props.values.customer, props.values.organisation, userRoleName]);

  return (
    <FormikProvider value={props}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <>
                <FormControl fullWidth>
                  <InputLabel required id="Organisation-select-label">
                    Select Organisation
                  </InputLabel>
                  <Selectbox
                    required
                    labelId="Organisation-select-label"
                    label="Select Organisation"
                    id="select-organisation"
                    {...getFieldProps('organisation')}
                    error={Boolean(touched.organisation && errors.organisation)}
                    helperText={touched.organisation && errors.organisation}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                  >
                    {OrganisationList
                      ? OrganisationList.map((element) => {
                          const { organisation_id, organisation_name } = element;
                          return (
                            <MenuItem key={organisation_id} value={organisation_id}>
                              {organisation_name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Selectbox>
                  <FormHelperText sx={{ color: '#d32f2f' }}>
                    {touched.organisation && errors.organisation}
                  </FormHelperText>
                </FormControl>
              </>
            )}
            {/* <FormControl fullWidth>
              <InputLabel id="Organisation-select-label">Selectbox Organisation</InputLabel>
              <Selectbox
                required
                labelId="Organisation-select-label"
                label="Selectbox Organisation"
                id="select-organisation"
                {...getFieldProps('organisation')}
                error={Boolean(touched.organisation && errors.organisation)}
                helperText={touched.organisation && errors.organisation}
              >
                {OrganisationList
                  ? OrganisationList.map((element) => {
                      const { organisation_id, organisation_name } = element;
                      return (
                        <MenuItem key={organisation_id} value={organisation_id}>
                          {organisation_name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Selectbox>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.organisation && errors.organisation}
              </FormHelperText>
            </FormControl> */}
          </Grid>
          <Grid item xs={4}>
            {userDetails.role === 'QA' || userDetails.role === 'Customer_Admin' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Customer
                </InputLabel>
                <Selectbox
                  required
                  labelId="Organisation-select-label"
                  label="Select Customer"
                  id="select-customer"
                  {...getFieldProps('customer')}
                  error={Boolean(touched.customer && errors.customer)}
                  helperText={touched.customer && errors.customer}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {customerList
                    ? customerList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={customer_id}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.customer && errors.customer}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel required id="role-select-label">
                Select Role
              </InputLabel>
              <Selectbox
                required
                labelId="role-select-label"
                label="Select Role"
                id="select-role"
                {...getFieldProps('role')}
                error={Boolean(touched.role && errors.role)}
                helperText={touched.role && errors.role}
              >
                {updatedRoleList
                  ? updatedRoleList.map((element) => {
                      const { role_id, role_name } = element;
                      return (
                        <MenuItem
                          key={role_id}
                          value={role_id}
                          onClick={() => setUserRoleName(role_name)}
                        >
                          {role_name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Selectbox>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.role && errors.role}
              </FormHelperText>
            </FormControl>
          </Grid>
          {userDetails.role === 'QA' ? (
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                label="Select Qa"
                defaultValue={`${userDetails.first_name} ${userDetails.last_name}`}
                disabled
              />
            </Grid>
          ) : (
            userRoleName === 'Agent' && (
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel required id="qa-select-label">
                    Select QA
                  </InputLabel>
                  <Selectbox
                    required
                    labelId="qa-select-label"
                    label="Select QA"
                    id="select-qa"
                    // {...getFieldProps('qa_id')}
                    value={QAElement}
                    onChange={(e) => {
                      setQAElement(e.target.value);
                      props.setFieldValue('qa_id', e.target.value.user_id);
                      props.setFieldValue(
                        'qa_name',
                        `${e.target.value.first_name} ${e.target.value.last_name}`
                      );
                    }}
                    error={Boolean(touched.qa_id && errors.qa_id)}
                    helperText={touched.qa_id && errors.qa_id}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                  >
                    {qaList.length > 0 ? (
                      qaList.map((element, index) => {
                        const { first_name, last_name, user_id } = element;
                        return (
                          <MenuItem key={index} value={element}>
                            {`${first_name} ${last_name}`}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>
                        <i>Please Add QA</i>
                      </MenuItem>
                    )}
                  </Selectbox>
                  <FormHelperText sx={{ color: '#d32f2f' }}>
                    {touched.qa_id && errors.qa_id}
                  </FormHelperText>
                </FormControl>
              </Grid>
            )
          )}
          {userRoleName === 'Agent' && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel required id="manager-select-label">
                  Select Manager
                </InputLabel>
                <Selectbox
                  required
                  labelId="manager-select-label"
                  label="Select manager"
                  id="select-manager"
                  // {...getFieldProps('manager_id')}
                  onChange={(e) => {
                    props.setFieldValue('manager_id', e.target.value.user_id);
                    props.setFieldValue(
                      'manager_name',
                      `${e.target.value.first_name} ${e.target.value.last_name}`
                    );
                  }}
                  error={Boolean(touched.manager_id && errors.manager_id)}
                  helperText={touched.manager_id && errors.manager_id}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {managerList.length > 0 ? (
                    managerList.map((element, index) => {
                      const { first_name, last_name, user_id } = element;
                      return (
                        <MenuItem key={index} value={element}>
                          {`${first_name} ${last_name}`}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>
                      <i>Please Add Manaager</i>
                    </MenuItem>
                  )}
                </Selectbox>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.manager_id && errors.manager_id}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={userRoleName === 'Agent' ? 6 : 4}>
            {(props.values.role &&
              (props.values.role === 1 ? (
                <FormControl fullWidth>
                  <InputLabel required id="process-select-label">
                    Select Process
                  </InputLabel>
                  <Selectbox
                    required
                    labelId="process-select-label"
                    label="Select Process"
                    id="select-process"
                    value={processName}
                    error={Boolean(touched.process && errors.process)}
                    helperText={touched.process && errors.process}
                    onChange={(e) => handleProcess(e.target.value)}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                  >
                    {processList
                      ? processList.map((element) => (
                          <MenuItem key={element} value={element}>
                            {element}
                          </MenuItem>
                        ))
                      : null}
                  </Selectbox>
                  <FormHelperText sx={{ color: '#d32f2f' }}>
                    {touched.process && errors.process}
                  </FormHelperText>
                </FormControl>
              ) : (
                <Select
                  className="basic-single"
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  isMulti
                  isSearchable={false}
                  styles={customStyles}
                  placeholder="Select Process"
                />
                // <FormControl fullWidth>
                //   <InputLabel required id="process-select-label">
                //     Select Process
                //   </InputLabel>
                //   <Selectbox
                //     required
                //     labelId="Mutiple-select-label"
                //     label="Select Process"
                //     id="select-process"
                //     // multiple
                //     value={output}
                //     onChange={handleChange}
                //     // name="agent"
                //     // onBlur={formik.handleBlur}
                //     // error={Boolean(touched.agent && errors.agent)}
                //     // helperText={touched.agent && errors.agent}
                //   >
                //     {options.map(([el], i) => (
                //       <MenuItem value={el}>{el}</MenuItem>
                //     ))}
                //   </Selectbox>
                // </FormControl>
              ))) || (
              <FormControl fullWidth>
                <InputLabel required id="process-select-label">
                  Select Process
                </InputLabel>
                <Selectbox
                  required
                  labelId="process-select-label"
                  label="Select Process"
                  id="select-process"
                  error={Boolean(touched.process && errors.process)}
                  helperText={touched.process && errors.process}
                  onChange={(e) => handleProcess(e.target.value)}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {processList
                    ? processList.map((element) => {
                        const process = Object.values(element)[0];
                        return (
                          <MenuItem key={process} value={process}>
                            {process}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.process && errors.process}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="First Name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Last Name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="language-select-label">
                Select Language
              </InputLabel>
              <Selectbox
                required
                labelId="language-select-label"
                label="Select Language"
                id="select-Language"
                {...getFieldProps('language')}
                error={Boolean(touched.language && errors.language)}
                helperText={touched.language && errors.language}
              >
                {languageList.length > 0 ? (
                  languageList.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>No Languages</MenuItem>
                )}
                {/* <MenuItem key="hindi" value="Hindi">
                  Hindi
                </MenuItem>
                <MenuItem key="english" value="English">
                  English
                </MenuItem>
                <MenuItem key="marathi" value="Marathi">
                  Marathi
                </MenuItem>
                <MenuItem key="gujrati" value="Gujrati">
                  Gujrati
                </MenuItem>
                <MenuItem key="arabic" value="Arabic">
                  Arabic
                </MenuItem>
                <MenuItem key="telugu" value="Telugu">
                  Telugu
                </MenuItem>
                <MenuItem key="oriya" value="Oriya">
                  Oriya
                </MenuItem> */}
              </Selectbox>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.language && errors.language}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              type="email"
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              // required
              fullWidth
              label="Mobile(Optional)"
              {...getFieldProps('contactNumber')}
              error={Boolean(touched.contactNumber && errors.contactNumber)}
              helperText={touched.contactNumber && errors.contactNumber}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Employee ID"
              {...getFieldProps('employeeId')}
              error={Boolean(touched.employeeId && errors.employeeId)}
              helperText={touched.employeeId && errors.employeeId}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <LoadingButton
              fullWidth
              variant="contained"
              onClick={() => {
                inputRef.current?.click();
              }}
              sx={{
                height: 53,
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              {!userAudio.File_Name ? 'Upload Voice (optional)' : userAudio.File_Name}
              <input
                type="file"
                hidden
                accept="audio/*"
                multiple
                ref={inputRef}
                onChange={(event) => {
                  handleAudioFileChange(event, event.currentTarget.files[0]);
                }}
                // required
              />
            </LoadingButton>
            {!userAudio.File_Name ? null : (
              <audio src={userAudio.Data} controls>
                <track default kind="captions" srcLang="en" src="" />
              </audio>
            )}
          </Grid> */}
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Date Of Joining *"
                required
                value={selectedDateOfJoining}
                format="DD/MM/YYYY"
                onChange={(date) => {
                  setSelectedDateOfJoining(date);
                  props.setFieldValue('dateOfJoining', dayjs(date).format('DD-MM-YYYY'));
                  // console.log('Selected date:', dayjs(date).format('DD-MM-YYYY'));
                }}
                slotProps={{
                  textField: {
                    // size: 'small',
                    fullWidth: true,
                    error: Boolean(props.touched.dateOfJoining && props.errors.dateOfJoining),
                    helperText: props.touched.dateOfJoining && props.errors.dateOfJoining
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              variant="contained"
              onClick={() => {
                inputImgRef.current?.click();
              }}
              sx={{
                height: 53,
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              {!userImage.File_Name ? 'Upload Profile Picture (optional)' : userImage.File_Name}
              {!userImage.File_Name ? null : (
                <Avatar src={userImage.Data} alt="fileUrl" sx={{ marginLeft: 2 }} />
              )}
              <input
                type="file"
                hidden
                accept="Image/*"
                multiple
                ref={inputImgRef}
                onChange={(event) => {
                  handleImgFileChange(event, event.currentTarget.files[0]);
                }}
                // required
              />
            </LoadingButton>
          </Grid>
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleCancel}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={isSubmitting}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Add
          </LoadingButton>
          {/* <Notification></Notification> */}
        </Container>
      </Form>
    </FormikProvider>
  );
};

export const RoleEditForm = ({
  formik,
  handleDialog,
  Id,
  Name,
  Description,
  Isdefault,
  Isadmin,
  setAlert,
  setAlertType,
  setAlertContent,
  updateComponent,
  isUpdated,
  filterName,
  handleRoleSearch,
  delayMsg
}) => {
  const { isSubmitting } = formik;
  const [RoleId, setId] = React.useState(Id);
  const [RoleName, setName] = React.useState(Name);
  const [RoleDescription, setDescription] = React.useState(Description);
  const [RoleIsdefault, setIsDefault] = React.useState(Isdefault);
  const [RoleIsadmin, setIsadmin] = React.useState(Isadmin);

  console.log('RoleIsdefault:-', RoleIsdefault);
  console.log('RoleIsadmin', RoleIsadmin);
  const handleEditRole = async () => {
    const token = await localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    const data = {
      id: RoleId,
      name: RoleName,
      description: RoleDescription,
      is_default: RoleIsdefault,
      is_admin: RoleIsadmin
    };
    await API.put('/secure/update_role', data, { headers })
      .then((res) => {
        setAlertContent(res.data.message);
        setAlertType('success');
        setAlert(true);
        handleDialog();
        if (filterName) {
          handleRoleSearch(filterName);
        } else {
          // updateComponent(isUpdated + 1);
          delayMsg();
        }
        // console.log(res.data);
      })
      .catch((err) => {
        setAlertContent(err);
        setAlertType('error');
        setAlert(true);
        // console.log(err.response.data.message);
      });
  };
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleEditRole}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Role name"
              defaultValue={Name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Roles Description"
              defaultValue={Description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <Checkbox
              // type="checkbox"
              style={{ width: 40, height: 20 }}
              autoComplete="Roles Isdefault"
              // defaultValue={Isdefault}
              checked={RoleIsdefault}
              onChange={() => setIsDefault(!RoleIsdefault)}
            />
            Default &nbsp;
            <Checkbox
              style={{ width: 40, height: 20 }}
              // defaultValue={Isadmin}
              checked={RoleIsadmin}
              onChange={() => setIsadmin(!RoleIsadmin)}
            />
            Admin &nbsp;
          </Grid>
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton variant="contained" onClick={handleDialog} style={{ marginRight: 5 }}>
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleEditRole}
            loading={isSubmitting}
            style={{ marginRight: 5 }}
          >
            Update
          </LoadingButton>
        </Container>
      </Form>
    </FormikProvider>
  );
};

export const UserEditForm = ({
  // formik,
  tabRoleValue,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  userDetails,
  handleDialog,
  userId
}) => {
  console.log('userDetails', userDetails);
  const inputRef = React.useRef(null);
  const inputImgRef = React.useRef(null);
  const [userAudio, setuserAudio] = useState(
    userDetails.voice_sample
      ? {
          File_Name:
            userDetails.voice_sample.split('/')[userDetails.voice_sample.split('/').length - 1],
          Data: null
        }
      : { File_Name: '', Data: null }
  );
  const [userImage, setuserImage] = useState(
    userDetails.profile_picture
      ? {
          File_Name:
            userDetails.profile_picture.split('/')[
              userDetails.profile_picture.split('/').length - 1
            ],
          Data: null
        }
      : { File_Name: '', Data: null }
  );
  const [loading, setloading] = useState(false);

  const [firstName, setfirstName] = useState(userDetails.first_name);
  const [lastName, setlastName] = useState(userDetails.last_name);
  const [userEmail, setuserEmail] = useState(userDetails.user_email);
  const [userMobile, setuserMobile] = useState(userDetails.user_mobile);
  const [userOrg, setuserOrg] = useState(userDetails.organisation_id);
  const [userCustomer, setuserCustomer] = useState(userDetails.customer_id);
  const [userRole, setuserRole] = useState(userDetails.role_id);
  const [userLanguage, setuserLanguage] = useState(userDetails.language);
  const [userProcess, setuserProcess] = useState(
    // userDetails.role_id === 1 ? userDetails.processes[0] : userDetails.processes
    userDetails.processes
  );
  const [userEmployeeId, setuserEmployeeId] = useState(userDetails.empcode);
  const [userQa, setUserQa] = useState(userDetails.qa_id);
  const [userManager, setUserManager] = useState(userDetails.manager_id);
  const [userRoleName, setUserRoleName] = useState(userDetails.role_name);
  const [selectedDateOfJoining, setSelectedDateOfJoining] = useState(
    dayjs(moment(userDetails.date_of_joining, 'DD-MM-YYYY').format('YYYY-MM-DD'))
  );
  const [processObject, setprocessObject] = useState();

  const [OrganisationList, setOrganisationList] = useState();
  const [customerList, setcustomerList] = useState();
  const [roleList, setroleList] = useState();
  const [processList, setprocessList] = useState();
  const [newRoleList, setnewRoleList] = useState();
  const [qaList, setQaList] = useState();
  const [managerList, setManagerList] = useState();
  const [languageList, setLanguageList] = useState([]);

  // console.log('userProcess', userProcess.length);
  const [selectedOption, setSelectedOption] = useState(
    userProcess.length > 0
      ? userProcess.map((data) => {
          const arrayForDefaultValue = [];
          const obj2 = { value: data, label: data };
          arrayForDefaultValue.push(obj2);
          return obj2;
        })
      : []
  );
  console.log('selectedOption', selectedOption);
  const [options, setoptions] = useState([]);

  const customStyles = {
    // control: (provided, state) => ({
    //   ...provided,
    //   minHeight: 54
    //   // overflow: 'scroll'
    // })
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : formik.errors.process ? '#D14343' : '#ddd',
      minHeight: 54,
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : formik.errors.process ? '#D14343' : '#ddd'
      }
    }),
    valueContainer: (base, state) => ({
      ...base,
      maxHeight: 40,
      overflow: 'auto'
    })
  };

  const user = JSON.parse(localStorage.getItem('user'));

  let profilePic;
  let userVoice;

  useEffect(() => {
    if (userProcess) {
      setprocessObject(userProcess[0]);
    }
  }, [userProcess]);

  if (userDetails.profile_picture && userDetails.voice_sample) {
    profilePic = userDetails.profile_picture.split('/');
    userVoice = userDetails.voice_sample.split('/');
  }

  const SampleFileName = (name) => {
    if (name) {
      return name[name.length - 1];
    }
    return 'Update Voice';
  };
  const SampleImageName = (name) => {
    if (name) {
      return name[name.length - 1];
    }
    return 'Update Profile';
  };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async (userOrg) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${userOrg}`, config)
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userOrg !== null) {
      getCustomerList(userOrg);
    } else {
      setcustomerList([]);
    }
  }, [userOrg]);

  const getRoleList = async (userCustomer) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getRole() {
      return API.get(`/secure/role_list`, config);
    }
    function getLanguage() {
      return API.get(`/secure/language_list?customer_id=${userCustomer}`, config);
    }

    Promise.all([getRole(), getLanguage()])
      .then(([roleData, languageRes]) => {
        setroleList(roleData.data.roles);
        setLanguageList(languageRes.data.language_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userCustomer !== null) {
      getRoleList(userCustomer);
    } else {
      setroleList([]);
    }
  }, [userCustomer]);

  const getProcessList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `/secure/process_list?customer_id=${userCustomer}&organisation_id=${userOrg}`,
        config
      );
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
        createFilterOptions(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userCustomer !== null) {
      getProcessList();
    } else {
      setprocessList([]);
    }
  }, [userCustomer]);

  const handleAudioFileChange = (event, fileName) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (fileReader.readyState === 2) {
        const file = e.target.result;
        setuserAudio({ File_Name: fileName.name, Data: file });
        formik.setFieldValue(`userVoice`, { file_name: fileName.name, file_data: file });
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };

  const handleImgFileChange = (event, fileName) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (fileReader.readyState === 2) {
        const file = e.target.result;
        setuserImage({ File_Name: fileName.name, Data: file });
        formik.setFieldValue(`userPic`, { name: fileName.name, data: file });
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };

  const createFilterOptions = (value) => {
    const arr = [];
    value.map((process) => {
      const obj1 = { value: process, label: process };
      arr.push(obj1);
      return process;
    });

    setoptions(arr);
  };

  useEffect(() => {
    const processArr = [];
    if (selectedOption) {
      selectedOption.map((data) => {
        const processObj = { process: data.value };
        processArr.push(data.value);
        console.log('first', data);
        return data;
      });
    }
    setuserProcess(processArr);
    formik.setFieldValue('process', processArr);
  }, [selectedOption]);

  // Qa and manager Apis

  const getQaList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getQa() {
      return API.get(
        `/secure/QA_List?customer_id=${userCustomer}&organisation_id=${userOrg}`,
        config
      );
    }

    Promise.all([getQa()])
      .then(([res]) => {
        console.log('qa res', res.data);
        setQaList(res.data.qa_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getManagerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getManager() {
      return API.get(
        `/secure/Manager_List?customer_id=${userCustomer}&organisation_id=${userOrg}`,
        config
      );
    }

    Promise.all([getManager()])
      .then(([res]) => {
        console.log('qa res', res.data);
        setManagerList(res.data.manager_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userCustomer && userOrg && userRoleName === 'Agent') {
      getQaList();
      getManagerList();
    }
  }, [userCustomer, userOrg, userRoleName]);

  // <----------------------------------------------------->
  const userEditSchema = Yup.object().shape({
    firstname: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{2,29}$/,
        'First name should be 3 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'First name should be 3 to 30 character long.')
      .required('This field is required.'),
    lastname: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{2,29}$/,
        'Last name should be 3 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'Last name should be 3 to 30 character long.')
      .required('This field is required.'),
    email: Yup.string()
      .email('Email must be a valid email address.')
      .matches(/^[\w-.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, 'Email must be a valid email address.')
      .required('This field is required.'),
    organisation: Yup.string().nullable().required('This field is required.'),
    role: Yup.string().nullable().required('This field is required.'),
    process: Yup.array().of(Yup.string()).min(1, 'This field is required.'),
    customer: Yup.string().required('This field is required.').nullable(),
    contactNumber: Yup.string()
      .matches(/^((\+91-?)|\+)?[0-9][0-9]{9}$/, 'Invalid contact number.')
      .nullable(),
    employeeId: Yup.string()
      .max(20, 'ID should be 2 to 20 character long.')
      .matches(/^[a-zA-Z0-9]+$/, 'Employe ID should only contain numbers and alphabets.')
      .required('This field is required.'),
    language: Yup.string().required('This field is required.'),
    dateOfJoining: Yup.string().required('This field is required.')
  });

  const userAgentEditSchema = Yup.object().shape({
    firstname: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{2,29}$/,
        'First name should be 3 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'First name should be 3 to 30 character long.')
      .required('This field is required.'),
    lastname: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{2,29}$/,
        'Last name should be 3 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'Last name should be 3 to 30 character long.')
      .required('This field is required.'),
    email: Yup.string()
      .email('Email must be a valid email address.')
      .matches(/^[\w-.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, 'Email must be a valid email address.')
      .required('This field is required.'),
    organisation: Yup.string().nullable().required('This field is required.'),
    role: Yup.string().nullable().required('This field is required.'),
    process: Yup.array().of(Yup.string()).min(1, 'This field is required.'),
    customer: Yup.string().required('This field is required.').nullable(),
    contactNumber: Yup.string()
      .matches(/^((\+91-?)|\+)?[0-9][0-9]{9}$/, 'Invalid contact number.')
      .nullable(),
    employeeId: Yup.string()
      .max(20, 'ID should be 2 to 20 character long.')
      .matches(/^[a-zA-Z0-9]+$/, 'Employe ID should only contain numbers and alphabets.')
      .required('This field is required.'),
    language: Yup.string().required('This field is required.'),
    qa_id: Yup.string().nullable().required('This field is required.'),
    manager_id: Yup.string().nullable().required('This field is required.'),
    dateOfJoining: Yup.string().required('This field is required.')
  });
  // <-------------------------------------------->
  const formik = useFormik({
    initialValues: {
      firstname: firstName,
      lastname: lastName,
      email: userEmail,
      process: userRoleName === 'Agent' && userProcess.length > 0 ? [userProcess[0]] : userProcess,
      organisation: userOrg,
      role: userRole,
      contactNumber: userMobile,
      userVoice: [],
      userPic: '',
      customer: userCustomer,
      employeeId: userEmployeeId,
      language: userLanguage,
      qa_id: userQa,
      qa_name: userDetails.qa_name,
      manager_id: userManager,
      manager_name: userDetails.manager_name,
      dateOfJoining: userDetails.date_of_joining
    },
    validationSchema: userRoleName === 'Agent' ? userAgentEditSchema : userEditSchema
  });

  console.log('formik.values', formik.values);
  const handleEditUser = async () => {
    setloading(true);
    console.log('values ---', formik.values);
    console.log('renderhandle');
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = {
      id: userId,
      first_name: formik.values.firstname,
      last_name: formik.values.lastname,
      email: formik.values.email,
      organisation_id: userOrg,
      role_id: userRole,
      mobile: formik.values.contactNumber,
      customer_id: userCustomer,
      profile_picture: formik.values.userPic,
      voice_sample: formik.values.userVoice,
      processes: userProcess,
      employeeId: formik.values.employeeId,
      language: formik.values.language,
      qa_id: formik.values.qa_id,
      qa_name: formik.values.qa_name,
      manager_id: formik.values.manager_id,
      manager_name: formik.values.manager_name,
      date_of_joining: formik.values.dateOfJoining
    };
    await API.put('/secure/update_user', data, config)
      .then((res) => {
        setloading(false);
        setAlertContent(`Success : ${res.data.message}`);
        setAlert(true);
        setAlertType('success');
        handleDialog();
      })
      .then(() => {
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        setloading(false);
        setAlertContent(`${err.response.data.message}`);
        setAlert(true);
        setAlertType('error');
        handleDialog();
      });
  };

  // Removing super_admin role from role list
  useEffect(() => {
    const filterRoles = () => {
      if (roleList) {
        const newRoleList = roleList.filter(
          (role) => role.role_name !== 'Super_Admin' && role.role_name !== 'Guest'
        );

        setnewRoleList(newRoleList);
        console.log('role list in useEffect', newRoleList);
      }
    };

    filterRoles();
  }, [roleList]);

  console.log('formik.errors ----', formik.errors);

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));

  console.log('userProcess', userProcess);

  const classes = useStyles();

  return (
    <FormikProvider
      values={formik}
      // validationSchema={validationSchema}
      // onSubmit={handleEditUser}
    >
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {user.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={user.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Selectbox
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  defaultValue={userOrg}
                  onChange={(e) => {
                    setuserCustomer(null);
                    setSelectedOption(null);
                    setuserRole(null);
                    setuserProcess([]);
                    formik.setFieldValue('process', []);
                    formik.setFieldValue('role', null);
                    formik.setFieldValue('customer', null);

                    setUserQa(null);
                    formik.setFieldValue('qa_id', null);
                    setUserManager(null);
                    formik.setFieldValue('manager_id', null);
                    setUserRoleName(null);
                    setuserOrg(e.target.value);
                  }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {OrganisationList
                    ? OrganisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={4}>
            {user.role === 'QA' || user.role === 'Customer_Admin' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={user.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Customer
                </InputLabel>
                <Selectbox
                  required
                  labelId="Organisation-select-label"
                  label="Select Customer"
                  id="select-customer"
                  error={Boolean(formik.errors.customer)}
                  touched={formik.errors.customer}
                  value={userCustomer}
                  // defaultValue={userCustomer}
                  onChange={(e) => {
                    setuserRole(null);
                    setuserProcess([]);
                    setSelectedOption(null);
                    formik.setFieldValue('process', []);
                    formik.setFieldValue('role', null);

                    setUserQa(null);
                    formik.setFieldValue('qa_id', null);
                    setUserManager(null);
                    formik.setFieldValue('manager_id', null);
                    setuserCustomer(e.target.value);
                    setUserRoleName(null);
                    formik.setFieldValue('customer', e.target.value);
                  }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {customerList
                    ? customerList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={customer_id}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
                {formik?.errors?.customer && (
                  <Typography sx={{ fontSize: '12px', color: '#D14444' }}>
                    {formik.errors.customer}
                  </Typography>
                )}
              </FormControl>
            )}
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel required id="role-select-label">
                Select Role
              </InputLabel>
              <Selectbox
                required
                labelId="role-select-label"
                label="Select Role"
                id="select-role"
                error={Boolean(formik.errors.role)}
                touched={formik.errors.role}
                value={userRole}
                // defaultValue={userRole}
                onChange={(e) => {
                  setuserProcess([]);
                  formik.setFieldValue('process', []);
                  setUserQa(null);
                  formik.setFieldValue('qa_id', null);
                  setUserManager(null);
                  formik.setFieldValue('manager_id', null);
                  setSelectedOption([]);
                  setuserRole(e.target.value);
                  formik.setFieldValue('role', e.target.value);
                }}
              >
                {newRoleList
                  ? newRoleList.map((element) => {
                      const { role_id, role_name } = element;
                      return (
                        <MenuItem
                          key={role_id}
                          value={role_id}
                          onClick={() => setUserRoleName(role_name)}
                        >
                          {role_name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Selectbox>
              {formik?.errors?.role && (
                <Typography sx={{ fontSize: '12px', color: '#D14444' }}>
                  {formik.errors.role}
                </Typography>
              )}
            </FormControl>
          </Grid>
          {userRoleName === 'Agent' && userDetails?.qa_id !== null && (
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel required id="qa-select-label">
                  Select QA
                </InputLabel>
                <Selectbox
                  required
                  labelId="qa-select-label"
                  label="Select QA"
                  id="select-qa"
                  error={Boolean(formik.errors.qa_id)}
                  touched={formik.errors.qa_id}
                  value={userQa}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                  // defaultValue={userRole}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    const selectedQA = qaList.find((element) => element.user_id === selectedValue);

                    if (selectedQA) {
                      const { first_name, last_name } = selectedQA;

                      formik.setFieldValue('qa_id', selectedValue);
                      setUserQa(selectedValue);

                      // Set the qa_name combining first_name and last_name
                      const fullName = `${first_name} ${last_name}`;
                      formik.setFieldValue('qa_name', fullName);
                    }
                  }}
                >
                  {qaList?.length > 0 ? (
                    qaList.map((element, index) => {
                      const { first_name, last_name, user_id } = element;
                      return (
                        <MenuItem key={index} value={user_id}>
                          {`${first_name} ${last_name}`}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>
                      <i>Please Add QA</i>
                    </MenuItem>
                  )}
                </Selectbox>
                {formik?.errors?.qa_id && (
                  <Typography sx={{ fontSize: '12px', color: '#D14444' }}>
                    {formik.errors.qa_id}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          )}

          {userRoleName === 'Agent' && userDetails?.manager_id !== null && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel required id="manager-select-label">
                  Select Manager
                </InputLabel>
                <Selectbox
                  required
                  labelId="manager-select-label"
                  label="Select Manager"
                  id="select-manager"
                  error={Boolean(formik.errors.manager_id)}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                  touched={formik.errors.manager_id}
                  value={userManager}
                  // defaultValue={userRole}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    const selectedManager = managerList.find(
                      (element) => element.user_id === selectedValue
                    );

                    if (selectedManager) {
                      const { first_name, last_name } = selectedManager;

                      formik.setFieldValue('manager_id', selectedValue);
                      setUserManager(selectedValue);

                      // Set the qa_name combining first_name and last_name
                      const fullName = `${first_name} ${last_name}`;
                      formik.setFieldValue('manager_name', fullName);
                    }
                  }}
                >
                  {managerList?.length > 0 ? (
                    managerList.map((element, index) => {
                      const { first_name, last_name, user_id } = element;
                      return (
                        <MenuItem key={index} value={user_id}>
                          {`${first_name} ${last_name}`}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>
                      <i>Please Add Manaager</i>
                    </MenuItem>
                  )}
                </Selectbox>
                {formik?.errors?.manager_id && (
                  <Typography sx={{ fontSize: '12px', color: '#D14444' }}>
                    {formik.errors.manager_id}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          )}

          <Grid
            item
            xs={
              userRoleName === 'Agent' &&
              userDetails?.manager_id !== null &&
              userDetails?.qa_id !== null
                ? 6
                : 4
            }
          >
            {(userRole &&
              (userRole === 1 ? (
                <FormControl fullWidth>
                  <InputLabel required id="process-select-label">
                    Select Process
                  </InputLabel>
                  <Selectbox
                    required
                    labelId="process-select-label"
                    label="Select Process"
                    id="select-process"
                    error={Boolean(formik.errors.process)}
                    touched={formik.errors.process}
                    value={
                      userRoleName === 'Agent' && userProcess.length > 0
                        ? [userProcess[0]]
                        : userProcess
                    }
                    // defaultValue={userProcess}
                    onChange={(e) => {
                      console.log('onChange Triggered');
                      formik.setFieldValue('process', [e.target.value]);
                      setuserProcess([e.target.value]);
                    }}
                  >
                    {processList
                      ? processList.map((element) => (
                          <MenuItem key={element} value={element}>
                            {element}
                          </MenuItem>
                        ))
                      : null}
                  </Selectbox>
                  {formik?.errors?.process && (
                    <Typography sx={{ fontSize: '12px', color: '#D14444' }}>
                      {formik.errors.process}
                    </Typography>
                  )}
                </FormControl>
              ) : (
                <>
                  <Select
                    className="basic-single"
                    value={selectedOption}
                    // defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                    isMulti
                    isSearchable={false}
                    styles={customStyles}
                    placeholder="Select Process"
                  />
                </>
              ))) || (
              <FormControl fullWidth>
                <InputLabel required id="process-select-label">
                  Select Process
                </InputLabel>
                <Selectbox
                  required
                  labelId="process-select-label"
                  label="Select Process"
                  id="select-process"
                  error={Boolean(formik.errors.process)}
                  touched={formik.errors.process}
                  value={
                    userRoleName === 'Agent' && userProcess.length > 0
                      ? [userProcess[0]]
                      : userProcess
                  }
                  // defaultValue={userProcess.length > 1 && userProcess[0]}
                  onChange={(e) => {
                    console.log('newOnchange');
                    formik.setFieldValue('process', [e.target.value]);
                    setuserProcess([e.target.value]);
                  }}
                >
                  {processList
                    ? processList.map((element) => {
                        // const process = Object.values(element);
                        console.log('userProcess --', userProcess);

                        return (
                          <MenuItem key={element} value={element}>
                            {element}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
                {formik?.errors?.process && (
                  <Typography sx={{ fontSize: '12px', color: '#D14444' }}>
                    {formik.errors.process}
                  </Typography>
                )}
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="First Name"
              name="firstname"
              placeholder="First Name"
              fullWidth
              {...formik?.getFieldProps('firstname')}
              error={Boolean(formik?.errors?.firstname && formik?.touched?.firstname)}
              size="big"
              helperText={formik?.errors?.firstname && formik?.touched?.firstname}
              required
              sx={{ marginBottom: '1.5%' }}
            />
            {formik?.errors?.firstname && formik?.touched?.firstname && (
              <Typography sx={{ fontSize: '12px', color: '#D14444' }}>
                {formik.errors.firstname}
              </Typography>
            )}
            {/* <TextField
                fullWidth
                required
                label="First Name"
                error={Boolean(props.touched.firstname && props.errors.firstname)}
                helperText={props.touched.firstname && props.errors.firstname}
                defaultValue={firstName}
                onChange={(e) => setfirstName(e.target.value)}
              /> */}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              name="lastname"
              placeholder="Last Name"
              fullWidth
              {...formik?.getFieldProps('lastname')}
              error={Boolean(formik?.errors?.lastname && formik?.touched?.lastname)}
              size="big"
              helperText={formik?.errors?.lastname && formik?.touched?.lastname}
              required
              sx={{ marginBottom: '1.5%' }}
            />
            {formik?.errors?.lastname && formik?.touched?.lastname && (
              <Typography sx={{ fontSize: '12px', color: '#D14350' }}>
                {formik.errors.lastname}
              </Typography>
            )}
            {/* <TextField
              required
              fullWidth
              label="Last Name"
              defaultValue={lastName}
              onChange={(e) => setlastName(e.target.value)}
            /> */}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="language-select-label">
                Select Language
              </InputLabel>
              <Selectbox
                required
                labelId="language-select-label"
                label="Select Language"
                id="select-Language"
                {...formik?.getFieldProps('language')}
                error={Boolean(formik?.touched.language && formik?.errors.language)}
                helperText={formik?.touched.language && formik?.errors.language}
              >
                {languageList.length > 0 ? (
                  languageList.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>No Language</MenuItem>
                )}
              </Selectbox>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {formik?.touched.language && formik?.errors.language}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Email"
              name="email"
              placeholder="Email"
              fullWidth
              {...formik?.getFieldProps('email')}
              error={Boolean(formik?.errors?.email && formik?.touched?.email)}
              size="big"
              helperText={formik?.errors?.email && formik?.touched?.email}
              required
              sx={{ marginBottom: '1.5%' }}
            />
            {formik?.errors?.email && formik?.touched?.email && (
              <Typography sx={{ fontSize: '12px', color: '#D14350' }}>
                {formik.errors.email}
              </Typography>
            )}
            {/* <TextField
              fullWidth
              required
              type="email"
              label="Email"
              defaultValue={userEmail}
              onChange={(e) => setuserEmail(e.target.value)}
            /> */}
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="number"
              label="Mobile No."
              name="contactNumber"
              placeholder="Mobile (optional)"
              fullWidth
              {...formik?.getFieldProps('contactNumber')}
              error={Boolean(formik?.errors?.contactNumber && formik?.touched?.contactNumber)}
              size="big"
              helperText={formik?.errors?.contactNumber && formik?.touched?.contactNumber}
              sx={{ marginBottom: '1.5%' }}
            />
            {formik?.errors?.contactNumber && formik?.touched?.contactNumber && (
              <Typography sx={{ fontSize: '12px', color: '#D14350' }}>
                {formik.errors.contactNumber}
              </Typography>
            )}
            {/* <TextField
              fullWidth
              type="number"
              label="Mobile"
              defaultValue={userMobile}
              onChange={(e) => setuserMobile(e.target.value)}
            /> */}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Employe ID"
              name="employeeId"
              placeholder="Employe ID"
              fullWidth
              {...formik?.getFieldProps('employeeId')}
              error={Boolean(formik?.errors?.employeeId && formik?.touched?.employeeId)}
              size="big"
              helperText={formik?.errors?.employeeId && formik?.touched?.employeeId}
              required
              sx={{ marginBottom: '1.5%' }}
            />
            {formik?.errors?.employeeId && formik?.touched?.employeeId && (
              <Typography sx={{ fontSize: '12px', color: '#D14350' }}>
                {formik.errors.employeeId}
              </Typography>
            )}
            {/* <TextField
              required
              fullWidth
              label="Employee ID"
              defaultValue={userEmployeeId}
              onChange={(e) => setuserEmployeeId(e.target.value)}
            /> */}
          </Grid>
          {/* <Grid item xs={6}>
            <LoadingButton
              fullWidth
              variant="contained"
              onClick={() => {
                inputRef.current?.click();
              }}
              style={{
                height: 53,
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              {!userAudio.File_Name
                ? `${SampleFileName(userVoice)} (optional)`
                : `${userAudio.File_Name} (optional)`}
              <input
                type="file"
                hidden
                accept="audio/*"
                multiple
                ref={inputRef}
                onChange={(event) => {
                  handleAudioFileChange(event, event.currentTarget.files[0]);
                }}
                // required
              />
            </LoadingButton>
            {userAudio.Data || userDetails.voice_sample !== 'voice sample not given' ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 8
                }}
              >
                <audio
                  src={
                    userAudio.Data
                      ? userAudio.Data
                      : `${process.env.REACT_APP_SERVER_MEDIA_URL}${userDetails.voice_sample}`
                  }
                  controls
                >
                  <track default kind="captions" srcLang="en" />
                </audio>
              </Box>
            ) : null}
          </Grid> */}
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Date Of Joining *"
                required
                value={selectedDateOfJoining}
                format="DD/MM/YYYY"
                onChange={(date) => {
                  setSelectedDateOfJoining(date);
                  formik.setFieldValue('dateOfJoining', dayjs(date).format('DD-MM-YYYY'));
                  // console.log('Selected date:', dayjs(date).format('DD-MM-YYYY'));
                }}
                slotProps={{
                  textField: {
                    // size: 'small',
                    fullWidth: true,
                    error: Boolean(formik.touched.dateOfJoining && formik.errors.dateOfJoining),
                    helperText: formik.touched.dateOfJoining && formik.errors.dateOfJoining
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              variant="contained"
              onClick={() => {
                inputImgRef.current?.click();
              }}
              sx={{
                height: 53,
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              {!userImage.File_Name
                ? `${SampleImageName(profilePic)} (optional)`
                : `${userImage.File_Name} (optional)`}
              {userImage.Data || userDetails.profile_picture !== 'profile_pic not given' ? (
                <Avatar
                  src={
                    userImage.Data
                      ? userImage.Data
                      : `${process.env.REACT_APP_SERVER_MEDIA_URL}${userDetails.profile_picture}`
                  }
                  alt="photoURL"
                  sx={{ marginLeft: 2 }}
                />
              ) : null}

              <input
                type="file"
                hidden
                accept="Image/*"
                multiple
                ref={inputImgRef}
                onChange={(event) => {
                  handleImgFileChange(event, event.currentTarget.files[0]);
                }}
              />
            </LoadingButton>
            {/* {userImage.Data || userDetails.profile_picture !== 'profile_pic not given' ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={
                    userImage.Data
                      ? userImage.Data
                      : `${process.env.REACT_APP_SERVER_APP_URL}${userDetails.profile_picture}`
                  }
                  alt="Not Found"
                  height="100"
                  width="300"
                  style={{ marginTop: 5, borderRadius: 10 }}
                />
              </div>
            ) : null} */}
          </Grid>
        </Grid>

        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleDialog}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            // type="submit"
            onClick={Object.keys(formik.errors).length === 0 ? handleEditUser : null}
            loading={loading}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Update
          </LoadingButton>
        </Container>
      </form>
    </FormikProvider>
  );
};

export const UploadUserVoice = ({
  // formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  userDetails,
  handleDialog,
  userId
}) => {
  console.log('userDetails', userDetails);
  const inputRef = React.useRef(null);
  const inputImgRef = React.useRef(null);
  const [userAudio, setuserAudio] = useState(
    userDetails.voice_sample
      ? {
          File_Name:
            // userDetails.voice_sample.split('/')[userDetails.voice_sample.split('/').length - 1],
            'Upload User Voice',
          Data: null
        }
      : { File_Name: '', Data: null }
  );
  console.log('userAudio', userAudio);
  const [userImage, setuserImage] = useState(
    userDetails.profile_picture
      ? {
          File_Name:
            userDetails.profile_picture.split('/')[
              userDetails.profile_picture.split('/').length - 1
            ],
          Data: null
        }
      : { File_Name: '', Data: null }
  );
  const [loading, setloading] = useState(false);

  const [firstName, setfirstName] = useState(userDetails.first_name);
  const [lastName, setlastName] = useState(userDetails.last_name);
  const [userEmail, setuserEmail] = useState(userDetails.user_email);
  const [userMobile, setuserMobile] = useState(userDetails.user_mobile);
  const [userOrg, setuserOrg] = useState(userDetails.organisation_id);
  const [userCustomer, setuserCustomer] = useState(userDetails.customer_id);
  const [userRole, setuserRole] = useState(userDetails.role_id);
  const [userLanguage, setuserLanguage] = useState(userDetails.language);
  const [userProcess, setuserProcess] = useState(userDetails.processes);
  const [userEmployeeId, setuserEmployeeId] = useState(userDetails.empcode);
  const [userQa, setUserQa] = useState(userDetails.qa_id);
  const [userManager, setUserManager] = useState(userDetails.manager_id);
  const [userRoleName, setUserRoleName] = useState(userDetails.role_name);

  const [processObject, setprocessObject] = useState();

  const [OrganisationList, setOrganisationList] = useState();
  const [customerList, setcustomerList] = useState();
  const [roleList, setroleList] = useState();
  const [processList, setprocessList] = useState();
  const [newRoleList, setnewRoleList] = useState();
  const [qaList, setQaList] = useState();
  const [managerList, setManagerList] = useState();

  console.log('userProcess', userProcess.length);
  const [selectedOption, setSelectedOption] = useState(
    userProcess.length > 0
      ? userProcess.map((data) => {
          const arrayForDefaultValue = [];
          const obj2 = { value: data, label: data };
          arrayForDefaultValue.push(obj2);
          return obj2;
        })
      : []
  );
  console.log('selectedOption', selectedOption);
  const [options, setoptions] = useState([]);

  const customStyles = {
    // control: (provided, state) => ({
    //   ...provided,
    //   minHeight: 54
    //   // overflow: 'scroll'
    // })
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : formik.errors.process ? '#D14343' : '#ddd',
      minHeight: 54,
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : formik.errors.process ? '#D14343' : '#ddd'
      }
    })
  };

  const user = JSON.parse(localStorage.getItem('user'));

  let profilePic;
  let userVoice;

  useEffect(() => {
    if (userProcess) {
      setprocessObject(userProcess[0]);
    }
  }, [userProcess]);

  if (userDetails.profile_picture && userDetails.voice_sample) {
    profilePic = userDetails.profile_picture.split('/');
    userVoice = userDetails.voice_sample.split('/');
  }

  const SampleFileName = (name) => {
    if (name) {
      return name[name.length - 1];
    }
    return 'Update Voice';
  };
  const SampleImageName = (name) => {
    if (name) {
      return name[name.length - 1];
    }
    return 'Update Profile';
  };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async (userOrg) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${userOrg}`, config)
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userOrg !== null) {
      getCustomerList(userOrg);
    } else {
      setcustomerList([]);
    }
  }, [userOrg]);

  const getRoleList = async (userCustomer) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getRole() {
      return API.get(`/secure/role_list`, config);
    }

    Promise.all([getRole()])
      .then(([roleData]) => {
        setroleList(roleData.data.roles);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userCustomer !== null) {
      getRoleList(userCustomer);
    } else {
      setroleList([]);
    }
  }, [userCustomer]);

  const getProcessList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `/secure/process_list?customer_id=${userCustomer}&organisation_id=${userOrg}`,
        config
      );
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
        createFilterOptions(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userCustomer !== null) {
      getProcessList();
    } else {
      setprocessList([]);
    }
  }, [userCustomer]);

  const handleAudioFileChange = (event, fileName) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (fileReader.readyState === 2) {
        const file = e.target.result;
        setuserAudio({ File_Name: fileName.name, Data: file });
        formik.setFieldValue(`userVoice`, { file_name: fileName.name, file_data: file });
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };

  const handleImgFileChange = (event, fileName) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (fileReader.readyState === 2) {
        const file = e.target.result;
        setuserImage({ File_Name: fileName.name, Data: file });
        formik.setFieldValue(`userPic`, { name: fileName.name, data: file });
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };

  const createFilterOptions = (value) => {
    const arr = [];
    value.map((process) => {
      const obj1 = { value: process, label: process };
      arr.push(obj1);
      return process;
    });

    setoptions(arr);
  };

  useEffect(() => {
    const processArr = [];
    if (selectedOption) {
      selectedOption.map((data) => {
        const processObj = { process: data.value };
        processArr.push(data.value);
        console.log('first', data);
        return data;
      });
    }
    setuserProcess(processArr);
    formik.setFieldValue('process', processArr);
  }, [selectedOption]);

  // Qa and manager Apis

  const getQaList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getQa() {
      return API.get(
        `/secure/QA_List?customer_id=${userCustomer}&organisation_id=${userOrg}`,
        config
      );
    }

    Promise.all([getQa()])
      .then(([res]) => {
        console.log('qa res', res.data);
        setQaList(res.data.qa_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getManagerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getManager() {
      return API.get(
        `/secure/Manager_List?customer_id=${userCustomer}&organisation_id=${userOrg}`,
        config
      );
    }

    Promise.all([getManager()])
      .then(([res]) => {
        console.log('qa res', res.data);
        setManagerList(res.data.manager_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userCustomer && userOrg && userRoleName === 'Agent') {
      getQaList();
      getManagerList();
    }
  }, [userCustomer, userOrg, userRoleName]);

  // <----------------------------------------------------->
  const userEditSchema = Yup.object().shape({
    firstname: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{2,29}$/,
        'First name should be 3 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'First name should be 3 to 30 character long.')
      .required('This field is required.'),
    lastname: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{2,29}$/,
        'Last name should be 3 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'Last name should be 3 to 30 character long.')
      .required('This field is required.'),
    email: Yup.string()
      .email('Email must be a valid email address.')
      .matches(/^[\w-.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, 'Email must be a valid email address.')
      .required('This field is required.'),
    organisation: Yup.string().nullable().required('This field is required.'),
    role: Yup.string().nullable().required('This field is required.'),
    process: Yup.array().of(Yup.string()).min(1, 'This field is required.'),
    customer: Yup.string().required('This field is required.').nullable(),
    contactNumber: Yup.string()
      .matches(/^((\+91-?)|\+)?[0-9][0-9]{9}$/, 'Invalid contact number.')
      .nullable(),
    employeeId: Yup.string()
      .max(20, 'ID should be 2 to 20 character long.')
      .matches(/^[a-zA-Z0-9]+$/, 'Employe ID should only contain numbers and alphabets.')
      .required('This field is required.'),
    language: Yup.string().required('This field is required.')
  });

  const userAgentEditSchema = Yup.object().shape({
    firstname: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{2,29}$/,
        'First name should be 3 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'First name should be 3 to 30 character long.')
      .required('This field is required.'),
    lastname: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{2,29}$/,
        'Last name should be 3 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'Last name should be 3 to 30 character long.')
      .required('This field is required.'),
    email: Yup.string()
      .email('Email must be a valid email address.')
      .matches(/^[\w-.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, 'Email must be a valid email address.')
      .required('This field is required.'),
    organisation: Yup.string().nullable().required('This field is required.'),
    role: Yup.string().nullable().required('This field is required.'),
    process: Yup.array().of(Yup.string()).min(1, 'This field is required.'),
    customer: Yup.string().required('This field is required.').nullable(),
    contactNumber: Yup.string()
      .matches(/^((\+91-?)|\+)?[0-9][0-9]{9}$/, 'Invalid contact number.')
      .nullable(),
    employeeId: Yup.string()
      .max(20, 'ID should be 2 to 20 character long.')
      .matches(/^[a-zA-Z0-9]+$/, 'Employe ID should only contain numbers and alphabets.')
      .required('This field is required.'),
    language: Yup.string().required('This field is required.'),
    qa_id: Yup.string().nullable().required('This field is required.'),
    manager_id: Yup.string().nullable().required('This field is required.')
  });
  // <-------------------------------------------->
  const formik = useFormik({
    initialValues: {
      firstname: firstName,
      lastname: lastName,
      email: userEmail,
      process: userProcess,
      organisation: userOrg,
      role: userRole,
      contactNumber: userMobile,
      userVoice: [],
      userPic: '',
      customer: userCustomer,
      employeeId: userEmployeeId,
      language: userLanguage,
      qa_id: userQa,
      manager_id: userManager
    }
  });

  console.log('formik.values', formik.values);
  const handleEditUser = async () => {
    setloading(true);
    console.log('values ---', formik.values);
    console.log('renderhandle');
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = {
      user_id: userId,
      org_id: userOrg,
      customer_id: userCustomer,
      voice_sample: [formik.values.userVoice]
    };
    // await API.put('/secure/update_user', data, config)
    await API.post('/secure/voice_sample_of_user', data, config)
      .then((res) => {
        setloading(false);
        setAlertContent(`Success : ${res.data.message}`);
        setAlert(true);
        setAlertType('success');
        handleDialog();
      })
      .then(() => {
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        setloading(false);
        setAlertContent(`${err.response.data.message}`);
        setAlert(true);
        setAlertType('error');
        handleDialog();
      });
  };

  // Removing super_admin role from role list
  useEffect(() => {
    if (roleList) {
      console.log('role list in useEffect', roleList);
      const newRoleList = roleList.filter((role) => role.role_name !== 'Super_Admin');
      // console.log('newRoleList ----', newRoleList);
      setnewRoleList(newRoleList);
    }
  }, [roleList]);

  console.log('formik.errors ----', formik.errors);

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));

  console.log('userProcess', userProcess);

  const classes = useStyles();

  const uploadOptions = {
    loop: true,
    autoplay: true,
    animationData: UploadGif,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <FormikProvider
      values={formik}
      // validationSchema={validationSchema}
      // onSubmit={handleEditUser}
    >
      <form>
        <Grid container>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                width: '60%',
                border: '1px dashed black',
                padding: 10,
                paddingLeft: 30,
                paddingRight: 30
              }}
            >
              <Lottie options={uploadOptions} height={150} width={150} />
              <LoadingButton
                fullWidth
                variant="contained"
                onClick={() => {
                  inputRef.current?.click();
                }}
                style={{
                  height: 53,
                  backgroundColor: '#3892CF',
                  '&:hover': {
                    backgroundColor: '#3892CF'
                  }
                }}
              >
                {!userAudio.File_Name ? `${SampleFileName(userVoice)}` : `${userAudio.File_Name}`}
                <input
                  type="file"
                  hidden
                  accept="audio/*"
                  multiple
                  ref={inputRef}
                  onChange={(event) => {
                    handleAudioFileChange(event, event.currentTarget.files[0]);
                  }}
                  // required
                />
              </LoadingButton>

              {userAudio.Data || userDetails.voice_sample !== 'voice sample not given' ? (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 8
                  }}
                >
                  <audio
                    src={
                      userAudio.Data
                        ? userAudio.Data
                        : `${process.env.REACT_APP_SERVER_MEDIA_URL}${userDetails.voice_sample}`
                    }
                    controls
                  >
                    <track default kind="captions" srcLang="en" />
                  </audio>
                </Box>
              ) : null}
            </div>
          </Grid>
        </Grid>

        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleDialog}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            // type="submit"
            onClick={Object.keys(formik.errors).length === 0 ? handleEditUser : null}
            loading={loading}
            style={{
              marginRight: 5,
              backgroundColor: userAudio.Data === null ? '#E0E0E0' : '#3892CF',
              '&:hover': {
                backgroundColor: userAudio.Data === null ? '#E0E0E0' : '#3892CF'
              }
            }}
            disabled={userAudio.Data === null}
          >
            Upload
          </LoadingButton>
        </Container>
      </form>
    </FormikProvider>
  );
};

export const UploadEditForm = ({
  // formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  inputId,
  inputDetails,
  handleDialog
}) => {
  // const { handleSubmit, isSubmitting } = formik;
  const inputRef = useRef(null);
  const [userAudio, setuserAudio] = useState({ File_Name: '', Data: '' });
  const {
    customer_organisation_name,
    first_name,
    last_name,
    processes,
    customer_id,
    agent_id,
    organisation_id,
    input_file,
    input_id,
    domain_id,
    original_date,
    campaign_id,
    campaign_name
  } = inputDetails;

  const [customerID, setcustomerID] = useState(customer_id);
  const [organisationId, setorganisationId] = useState(organisation_id);
  const [campaignId, setcampaignId] = useState(campaign_id);
  const [domainId, setdomainId] = useState(domain_id);
  const [agentId, setagentId] = useState(agent_id);
  const [selectedDisposition, setSelectedDisposition] = useState(inputDetails.disposition_type);

  const [dashboardServices, setDashboardService] = useState();
  console.log('inputdashboardServices---', dashboardServices);
  const [inputProcess, setinputProcess] = useState(processes);
  console.log('inputProcess---', inputProcess);
  const changedDate = (date) => {
    const newdate = new Date(date);
    const formatStartDate = `${newdate.getFullYear()}-${
      newdate.getMonth() + 1
    }-${newdate.getDate()}`;
    return formatStartDate;
  };
  const [createdDate, setcreatedDate] = useState(changedDate(original_date));

  const [loading, setloading] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem('user'));

  /* ----- Creating state for customer, process and agent ------ */
  const [OrganisationList, setOrganisationList] = useState();
  const [customerList, setcustomerList] = useState();
  const [processList, setprocessList] = useState();
  const [categoryList, setCategoryList] = useState();
  const [campaignList, setcampaignList] = useState([]);
  console.log('inputProcess2----', processList);
  const [agentList, setagentList] = useState();
  let inputVoice;

  if (input_file) {
    inputVoice = input_file.split('/');
  }

  const SampleFileName = (name) => {
    if (name) {
      return name[name.length - 1];
    }
    return 'Update Voice';
  };
  // const initialValues = {
  //   uploadFile: [],
  //   customer: '',
  //   process: [],
  //   organisationId: '',
  //   domainId: '',
  //   agent: '',
  //   createDate: ''
  // };
  // useEffect(() => {
  //   formik.setFieldValue('uploadFile', inputDetails.input_file);
  //   formik.setFieldValue('customer', customerID);
  //   formik.setFieldValue('process', inputProcess[0]);
  //   formik.setFieldValue('organisationId', organisationId);
  //   formik.setFieldValue('domainId', domainId);
  //   formik.setFieldValue('agent', agentId);
  //   formik.setFieldValue('createDate', createdDate);
  // }, []);
  // console.log('formik.values', formik.values);
  const handleAudioFileChange = (event, fileName) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (fileReader.readyState === 2) {
        const file = e.target.result;
        setuserAudio({ File_Name: fileName.name, Data: file });
        formik.setFieldValue(`uploadFile`, { File_Name: fileName.name, Data: file });
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomer = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${organisationId}`, config)
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (organisationId) {
      getCustomer();
    } else {
      setcustomerList([]);
    }
  }, [organisationId]);

  const getProccesList = async (customerId) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(`/secure/process_list?customer_id=${customerId}`, config);
    }
    function getCategory() {
      return API.get(
        `/secure/get_categories?customer_id=${customerId}&org_id=${organisationId}`,
        config
      );
    }
    Promise.all([getProcess(), getCategory()])
      .then(([processData, categoryData]) => {
        setprocessList(processData.data.processes);
        setCategoryList(categoryData.data.categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getcampaignList = async (customerId, orgId) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `/secure/get_campaign?customer_id=${customerId}&organisation_id=${orgId}`,
        config
      );
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setcampaignList(processData.data.campaign_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (customerID !== null) {
      // setprocessList(null);
      getProccesList(customerID);
      getcampaignList(customerID, organisationId);
    } else {
      setprocessList(null);
      setcampaignList(null);
    }
  }, [customerID]);

  const getAgentList = async (inputProcess) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getRole() {
      return API.get(
        `secure/list_of_agent?customer_id=${customerID}&organisation_id=${organisationId}&processes=${inputProcess}`,
        config
      );
    }
    Promise.all([getRole()])
      .then(([agentData]) => {
        setagentList(agentData.data.agent_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (inputProcess) {
      getAgentList(inputProcess);
    } else {
      setagentList([]);
    }
  }, [inputProcess]);

  const setCustomerValue = (value) => {
    formik.setFieldValue('agent', '');
    formik.setFieldValue('process', []);
    setinputProcess([]);
    setagentId('');
    formik.setFieldValue('customer', value);
    setcustomerID(value);

    const orgId = customerList.filter((element) => element.customer_id === value);
    console.log('orgId ---', orgId);
    setorganisationId(orgId[0].organisation_id);
    setdomainId(orgId[0].domain_id);
    setprocessList(null);
  };
  const setCampaignValue = (value) => {
    setcampaignId(value);
  };

  const uploadEditSchema = Yup.object().shape({
    // uploadFile: Yup.object().required('Upload Source File.'),
    customer: Yup.string().required('This field is required.'),
    process: Yup.array().min(1, 'This field is required.'),
    organisationId: Yup.string().required('This field is required.'),
    agent: Yup.string().required('This field is required.'),
    // campaign: Yup.string().required('This field is required.'),
    createDate: Yup.date().required('This field is required.')
    // disposition: Yup.string().required('This field is required.')
    // .max(new Date(), intl.formatMessage({ id: 'start_date.error.max' }))
  });
  // <-------------------------------------------->
  const formik = useFormik({
    initialValues: {
      uploadFile: input_file,
      customer: customer_id,
      process: processes,
      organisationId: organisation_id,
      domainId: domain_id,
      agent: agent_id,
      createDate: original_date,
      disposition: inputDetails.disposition_type ? inputDetails.disposition_type : ''
      // qa_name: '',
      // manager_name: ''
      // campaign: null
    },
    validationSchema: uploadEditSchema,
    onSubmit: () => {
      handleEditUpload();
    }
  });

  const handleEditUpload = async () => {
    setloading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    console.log('uploadfile----------', formik.values.uploadFile);
    const data = {
      id: input_id,
      campaign_id: campaignId,
      inputFile: formik.values.uploadFile,
      customer_id: customerID,
      user_id: agentId,
      processes: inputProcess,
      organisation_id: organisationId,
      domain_id: domainId,
      original_date: createdDate,
      disposition: selectedDisposition
      // qa_name: '',
      // manager_name: ''
    };
    await API.put('/secure/update_input', data, config)
      .then((res) => {
        setloading(false);
        setAlertContent(`Success : ${res.data.message}`);
        setAlert(true);
        setAlertType('success');
        handleDialog();
      })
      .then(() => {
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        setloading(false);
        setAlertContent(`${err.response.data.message}`);
        setAlert(true);
        setAlertType('error');
        handleDialog();
      });
  };

  const setDateFormat = (date) => {
    const formatStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return formatStartDate;
  };

  const CustomInput = React.forwardRef((props, ref) => (
    <div style={{ position: 'relative' }}>
      <input {...props} ref={ref} />
      <CalendarMonthIcon
        onClick={props.onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '10px',
          // pointerEvents: 'none',
          cursor: 'pointer',
          zIndex: 5
        }}
      />
    </div>
  ));

  const handleCheckInDate = (date) => {
    const start_date = setDateFormat(date);
    setcreatedDate(start_date);
    formik.setFieldValue('createDate', start_date);
  };

  // const handleOrgChange = (e) => {
  //   setcustomerList(null);
  //   formik.setFieldValue('customer', null);
  //   formik.setFieldValue('organisationId', e.target.value);
  // };
  // const handleProcessChange = (e) => {
  //   formik.setFieldValue('agent', null);
  //   formik.setFieldValue('process', e.target.value);
  // };
  // const handleCustomerChange = (e) => {
  //   formik.setFieldValue('process', null);
  //   formik.setFieldValue('customer', e.target.value);
  // };

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));

  const classes = useStyles();
  console.log('formik.errors', formik.errors);
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={6}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Selectbox
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  defaultValue={organisationId}
                  // {...formik.getFieldProps('organisationId)
                  onChange={(e) => {
                    formik.setFieldValue('agent', '');
                    formik.setFieldValue('process', []);
                    formik.setFieldValue('customer', '');
                    setagentId('');
                    setinputProcess([]);
                    setcustomerID('');
                    setorganisationId(e.target.value);
                    // setprocessList([]);
                    // setcampaignId(null);
                    // setcampaignList([]);
                  }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {OrganisationList
                    ? OrganisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' || userDetails.role === 'Customer_Admin' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Selectbox
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  value={customerID}
                  error={Boolean(formik.errors.customer)}
                  touched={formik.errors.customer}
                  // defaultValue={customerID}
                  disabled={userDetails.role === 'Agent'}
                  // {...formik.getFieldProps('customer')}
                  onChange={(e) => {
                    // setDashboardService(e.target.value.dashboard_services);
                    setCustomerValue(e.target.value);
                  }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {customerList
                    ? customerList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={customer_id}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
                {formik.errors.customer && (
                  <Typography sx={{ fontSize: '12px', color: '#D14444' }}>
                    {formik.errors.customer}
                  </Typography>
                )}
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="process-select-label">Select Disposition</InputLabel>
              <Selectbox
                labelId="process-select-label"
                label="Select Disposition"
                id="select-process"
                name="disposition"
                value={selectedDisposition}
                error={Boolean(formik.touched.disposition && formik.errors.disposition)}
                helperText={formik.touched.disposition && formik.errors.disposition}
                onChange={(e) => {
                  formik.setFieldValue('disposition', e.target.value);
                  setSelectedDisposition(e.target.value);
                }}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {categoryList
                  ? categoryList.map((element) => (
                      <MenuItem key={element} value={element}>
                        {element}
                      </MenuItem>
                    ))
                  : null}
              </Selectbox>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {formik.touched.disposition && formik.errors.disposition}
              </FormHelperText>
            </FormControl>
          </Grid>
          {userDetails.Submenus.includes('Revenue_intelligence') && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="customer-select-label">Select Campaign</InputLabel>
                <Selectbox
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  defaultValue={campaignId}
                  disabled={userDetails.role === 'Agent'}
                  // {...formik.getFieldProps('customer')}
                  onChange={(e) => setCampaignValue(e.target.value)}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {campaignList?.length > 0 ? (
                    campaignList.map((element) => {
                      const { campaign_id, campaign_name } = element;
                      return (
                        <MenuItem key={campaign_id} value={campaign_id}>
                          {campaign_name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <i>
                      <MenuItem>No campaign Available</MenuItem>
                    </i>
                  )}
                </Selectbox>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="process-select-label">
                Select Process
              </InputLabel>
              <Selectbox
                required
                labelId="process-select-label"
                label="Select Process"
                id="select-process"
                // {...formik.getFieldProps('process')}
                disabled={userDetails.role === 'Agent'}
                error={Boolean(formik.errors.process)}
                touched={formik.errors.process}
                value={inputProcess[0]}
                // defaultValue={inputProcess ? inputProcess[0] : null}
                onChange={(e) => {
                  setagentId('');
                  setinputProcess([e.target.value]);
                  formik.setFieldValue('agent', '');
                  formik.setFieldValue('process', [e.target.value]);
                }}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {processList
                  ? processList.map((element) => (
                      <MenuItem key={element} value={element}>
                        {element}
                      </MenuItem>
                    ))
                  : null}
              </Selectbox>
              {formik.errors.process && (
                <Typography sx={{ fontSize: '12px', color: '#D14444' }}>
                  {formik.errors.process}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              selected={new Date(createdDate)}
              onChange={handleCheckInDate}
              maxDate={new Date()}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
              placeholderText="Select Date *"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="agent-select-label">
                Select Agent
              </InputLabel>
              <Selectbox
                required
                labelId="agent-select-label"
                label="Select Agent"
                id="select-agent"
                value={agentId}
                error={Boolean(formik.errors.agent)}
                touched={formik.errors.agent}
                defaultValue={agentId}
                disabled={userDetails.role === 'Agent'}
                // onChange={(e) => {
                //   formik.setFieldValue('agent', e.target.value);
                //   setagentId(e.target.value);
                // }}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  const selectedQA = agentList.find((element) => element.user_id === selectedValue);

                  if (selectedQA) {
                    // const { qa_name, manager_name } = selectedQA;
                    formik.setFieldValue('agent', selectedValue);
                    setagentId(e.target.value);
                    // formik.setFieldValue('qa_name', 'test_qa');
                    // formik.setFieldValue('manager_name', 'test_manager');
                  }
                }}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {agentList
                  ? agentList.map((element) => {
                      const { user_id, first_name, last_name, empcode } = element;
                      return (
                        <MenuItem key={user_id} value={user_id}>
                          {empcode} - {first_name} {last_name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Selectbox>
              {formik.errors.agent && (
                <Typography sx={{ fontSize: '12px', color: '#D14444' }}>
                  {formik.errors.agent}
                </Typography>
              )}
            </FormControl>
          </Grid>
          {/* <Grid item xs={6}>
            <LoadingButton
              fullWidth
              variant="contained"
              size="large"
              sx={{
                height: 50,
                marginTop: 1,
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
              onClick={() => {
                inputRef.current?.click();
              }}
            >
              {!userAudio.File_Name ? SampleFileName(inputVoice) : userAudio.File_Name}
              <input
                type="file"
                hidden
                accept="audio/*"
                multiple
                ref={inputRef}
                onChange={(event) => {
                  handleAudioFileChange(event, event.currentTarget.files[0]);
                }}
                disabled
              />
            </LoadingButton>
          </Grid> */}
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleDialog}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Update
          </LoadingButton>
        </Container>
      </Form>
    </FormikProvider>
  );
};

export const KeywordEditForm = ({
  formik,
  setAlertContent,
  setAlertType,
  setAlert,
  isUpdated,
  updateComponent,
  handleDialog,
  Details
}) => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const { getFieldProps, errors, touched } = formik;

  const [keywordId, setkeywordId] = useState(Details.keyword_id);
  const [organisationId, setorganisationId] = useState(Details.organisation_id);
  const [customerOrgId, setcustomerOrgId] = useState(Details.customer_id);
  const [services, setServices] = useState(Details.dashboard_services);
  console.log('first', services);
  const [processArr, setprocessArr] = useState(Details.processes);
  const [keywordArr, setkeywordArr] = useState();

  const [organisationList, setorganisationList] = useState([]);
  const [customerOrgList, setcustomerOrgList] = useState([]);
  const [processList, setprocessList] = useState([]);
  const [loading, setloading] = useState(false);

  const categoryList = [
    { name: 'Lead Identifiers', id: 'Lead_Identifier' },
    { name: 'Unpleasant', id: 'Unpleasant' },
    { name: 'Positive Words', id: 'Positive Words' }
  ];

  // if (services.includes('sales')) {
  //   categoryList.push({ name: 'Lead Identifiers', id: 'Lead_Identifier' });
  // }
  // if (services.includes('collections')) {
  //   categoryList.push({ name: 'Unpleasant', id: 'Unpleasant' });
  // }
  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setorganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertDictToArr = (data) => {
    const arr = [];
    data.map((element) => {
      const arrElement = Object.values(element)[0];
      arr.push(arrElement);
      return element;
    });
    setkeywordArr(arr.toString());
  };

  useEffect(() => {
    getOrganisationList();
    convertDictToArr(Details.key_words);
    formik.setFieldValue(`sentences`, Details.sentences);
    formik.setFieldValue(`keywords`, Details.key_words);
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${organisationId}`, config)
      .then((res) => {
        setcustomerOrgList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (organisationId !== null) {
      getCustomerList();
    } else {
      setcustomerOrgList([]);
    }
  }, [organisationId]);

  const getProcessList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `/secure/process_list?customer_id=${customerOrgId}&organisation_id=${organisationId}`,
        config
      );
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (customerOrgId) {
      getProcessList();
    } else {
      setprocessList([]);
    }
  }, [customerOrgId]);

  const handleCancel = () => {
    handleDialog();
  };

  const handleSubmit = async () => {
    setloading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = {
      id: keywordId,
      processes: processArr,
      organisation_id: organisationId,
      customer_id: customerOrgId,
      sentences: formik.values.sentences,
      keywords: formik.values.keywords
    };
    await API.put('/secure/update_keywords', data, config)
      .then((res) => {
        setloading(false);
        setAlertContent(`Success : ${res.data.message}`);
        setAlert(true);
        setAlertType('success');
        handleDialog();
      })
      .then(() => {
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        setloading(false);
        setAlertContent(`${err.response.data.message}`);
        setAlert(true);
        setAlertType('error');
        handleDialog();
      });
  };

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));

  const classes = useStyles();

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Selectbox
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  disabled
                  defaultValue={organisationId}
                  onChange={(e) => setorganisationId(e.target.value)}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {organisationList
                    ? organisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Selectbox
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  defaultValue={customerOrgId}
                  disabled
                  onChange={(e) => {
                    // setServices(e.target.value.dashboard_services);
                    console.log('evalue', e.target.value);
                    // formik.setFieldValue('keywords', [
                    //   {
                    //     words: [''],
                    //     Category: ''
                    //   }
                    // ]);

                    setcustomerOrgId(e.target.value);
                    // setServices(e.target.value.dashboard_services);
                  }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {customerOrgList
                    ? customerOrgList.map((element) => {
                        const { customer_id, customer_organisation_name, dashboard_services } =
                          element;
                        return (
                          <MenuItem
                            key={customer_id}
                            value={customer_id}
                            onClick={() => setServices(dashboard_services)}
                          >
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="process-select-label">
                Select Process
              </InputLabel>
              <Selectbox
                required
                labelId="process-select-label"
                label="Select Process"
                id="select-process"
                // disabled
                defaultValue={processArr[0]}
                onChange={(e) => setprocessArr([e.target.value])}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {processList
                  ? processList.map((element) => {
                      const process = Object.values(element)[0];
                      return (
                        <MenuItem key={element} value={element}>
                          {element}
                        </MenuItem>
                      );
                    })
                  : null}
              </Selectbox>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              defaultValue={keywordArr}
              fullWidth
              required
              multiline
              rows={3}
              label="Keywords"
              onChanges={(e) => setkeywordArr(e.target.value)}
            />
          </Grid> */}
          <Grid item xs={12}>
            <FieldArray
              name="keywords"
              render={(arrayHelpers) => (
                <>
                  {formik.values.keywords.map((row, index) => {
                    console.log('keywordss ---', row);
                    console.log('index--', index);
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '5px'
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={8}>
                            <TextField
                              required
                              fullWidth
                              multiline
                              rows={2}
                              label="Keywords"
                              name={`keywords[${index}].words`}
                              value={row.words ? row.words.toString() : ''}
                              onBlur={formik.handleBlur}
                              // {...getFieldProps(`keywords[${index}].words`)}
                              onChange={(e) => {
                                // console.warn('keywords --------------', e.target.value);
                                formik.setFieldValue(
                                  `keywords[${index}].words`,
                                  e.target.value.split(',')
                                );
                              }}
                              error={Boolean(
                                touched &&
                                  touched.keywords &&
                                  touched.keywords[index] &&
                                  touched.keywords[index].words &&
                                  errors &&
                                  errors.keywords &&
                                  errors.keywords[index] &&
                                  errors.keywords[index].words
                              )}
                              helperText={<ErrorMessage name={`keywords[${index}].words`} />}
                            />
                          </Grid>

                          <Grid item xs={4}>
                            <FormControl fullWidth>
                              {/* <InputLabel required id="category-select-label">
                                Select Category
                              </InputLabel> */}
                              <TextField
                                required
                                fullWidth
                                label="Select Category"
                                name={`keywords[${index}].Category`}
                                value={row.Category ? row.Category.toString() : ''}
                                onBlur={formik.handleBlur}
                                // {...getFieldProps(`keywords[${index}].words`)}
                                onChange={(e) => {
                                  // console.warn('keywords --------------', e.target.value);
                                  formik.setFieldValue(
                                    `keywords[${index}].Category`,
                                    e.target.value
                                  );
                                }}
                                error={Boolean(
                                  touched &&
                                    touched.keywords &&
                                    touched.keywords[index] &&
                                    touched.keywords[index].Category &&
                                    errors &&
                                    errors.keywords &&
                                    errors.keywords[index] &&
                                    errors.keywords[index].Category
                                )}
                                helperText={
                                  touched &&
                                  touched.keywords &&
                                  touched.keywords[index] &&
                                  touched.keywords[index].Category &&
                                  errors &&
                                  errors.keywords &&
                                  errors.keywords[index] &&
                                  errors.keywords[index].Category
                                }
                              />
                              {/* <Selectbox
                                required
                                labelId="category-select-label"
                                label="Select Category"
                                id="select-Category"
                                {...getFieldProps(`keywords[${index}].Category`)}
                                error={Boolean(
                                  touched &&
                                    touched.keywords &&
                                    touched.keywords[index] &&
                                    touched.keywords[index].Category &&
                                    errors &&
                                    errors.keywords &&
                                    errors.keywords[index] &&
                                    errors.keywords[index].Category
                                )}
                                helperText={
                                  touched &&
                                  touched.keywords &&
                                  touched.keywords[index] &&
                                  touched.keywords[index].Category &&
                                  errors &&
                                  errors.keywords &&
                                  errors.keywords[index] &&
                                  errors.keywords[index].Category
                                }
                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                              >
                                {categoryList.map((element) => (
                                  <MenuItem key={element.id} value={element.id}>
                                    {element.name}
                                  </MenuItem>
                                ))}
                              </Selectbox> */}
                              {/* <FormHelperText sx={{ color: '#d32f2f' }}>
                                {touched &&
                                  touched.keywords &&
                                  touched.keywords[index] &&
                                  touched.keywords[index].Category &&
                                  errors &&
                                  errors.keywords &&
                                  errors.keywords[index] &&
                                  errors.keywords[index].Category}
                              </FormHelperText> */}
                            </FormControl>
                          </Grid>
                        </Grid>

                        {index === formik.values.keywords.length - 1 ? (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: `${index > 0 ? 'visible' : 'hidden'}`
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.push({
                                  words: [''],
                                  Category: ''
                                });
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: 'hidden'
                              }}
                              onClick={() => {
                                arrayHelpers.push({
                                  words: [''],
                                  Category: ''
                                });
                              }}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FieldArray
              name="sentences"
              render={(arrayHelpers) => (
                <>
                  {formik.values.sentences.map((row, index) => {
                    console.log('row ---', row);
                    // console.log('index--', index);
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '5px'
                        }}
                      >
                        <TextField
                          defaultValue={Object.values(row)[0]}
                          required
                          fullWidth
                          label="Sentence"
                          {...getFieldProps(`sentences[${index}].sentence`)}
                        />
                        {index === formik.values.sentences.length - 1 ? (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: 'blue',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: `${index > 0 ? 'visible' : 'hidden'}`
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: 'blue',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: 'blue',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: 'blue',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: 'hidden'
                              }}
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            />
          </Grid> */}
          {/* <Grid item xs={12}>
            <FieldArray
              name="sentences"
              render={(arrayHelpers) => (
                <>
                  {formik.values.sentences.map((row, index) => {
                    console.log('row ---', row);
                    console.log('index--', index);
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '5px'
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={8}>
                            <TextField
                              required
                              fullWidth
                              multiline
                              rows={2}
                              label="Sentences"
                              name={`sentences[${index}].sentence`}
                              value={row.sentence ? row.sentence.toString() : ''}
                              onBlur={formik.handleBlur}
                              // {...getFieldProps(`keywords[${index}].words`)}
                              onChange={(e) => {
                                // console.warn('keywords --------------', e.target.value);
                                formik.setFieldValue(
                                  `sentences[${index}].sentence`,
                                  e.target.value.split(',')
                                );
                              }}
                              error={Boolean(
                                touched &&
                                  touched.sentences &&
                                  touched.sentences[index] &&
                                  touched.sentences[index].sentence &&
                                  errors &&
                                  errors.sentences &&
                                  errors.sentences[index] &&
                                  errors.sentences[index].sentence
                              )}
                              helperText={<ErrorMessage name={`sentences[${index}].sentence`} />}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl fullWidth>
                              <TextField
                                required
                                fullWidth
                                label="Select Category"
                                name={`sentences[${index}].Category`}
                                value={row.Category ? row.Category.toString() : ''}
                                onBlur={formik.handleBlur}
                                // {...getFieldProps(`keywords[${index}].words`)}
                                onChange={(e) => {
                                  // console.warn('keywords --------------', e.target.value);
                                  formik.setFieldValue(
                                    `sentences[${index}].Category`,
                                    e.target.value
                                  );
                                }}
                                error={Boolean(
                                  touched &&
                                    touched.sentences &&
                                    touched.sentences[index] &&
                                    touched.sentences[index].Category &&
                                    errors &&
                                    errors.sentences &&
                                    errors.sentences[index] &&
                                    errors.sentences[index].Category
                                )}
                                helperText={
                                  touched &&
                                  touched.sentences &&
                                  touched.sentences[index] &&
                                  touched.sentences[index].Category &&
                                  errors &&
                                  errors.sentences &&
                                  errors.sentences[index] &&
                                  errors.sentences[index].Category
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>

                        {index === formik.values.sentences.length - 1 ? (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: `${index > 0 ? 'visible' : 'hidden'}`
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: 'hidden'
                              }}
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            />
          </Grid> */}
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
            onClick={handleCancel}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            type="submit"
            onClick={!errors.keywords ? handleSubmit : null}
            loading={loading}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Update
          </LoadingButton>
        </Container>
      </Form>
    </FormikProvider>
  );
};

// deal edit form

export const DealEditForm = ({
  // formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  inputId,
  inputDetails,
  handleDialog
}) => {
  // const { handleSubmit, isSubmitting } = formik;
  const inputRef = useRef(null);
  const [userAudio, setuserAudio] = useState({ File_Name: '', Data: '' });
  const {
    deal_id,
    org_name,
    organisation_id,
    customer_id,
    customer_organisation_name,
    agent_id,
    first_name,
    last_name,
    deal_name,
    deal_stage,
    deal_status,
    input_id,
    created_date,
    created_by,
    reason
  } = inputDetails;

  const [customerID, setcustomerID] = useState(customer_id);
  const [organisationId, setorganisationId] = useState(organisation_id);
  const [domainId, setdomainId] = useState();
  const [agentId, setagentId] = useState(agent_id);
  const [dealName, setdealName] = useState(deal_name);
  const [dealReason, setdealReason] = useState(reason);
  console.log('------first', dealName);
  const [dealId, setdealId] = useState(deal_id);
  const [dealStage, setdealStage] = useState(deal_stage);
  const [dealStatus, setdealStatus] = useState(deal_status);
  const [inputProcess, setinputProcess] = useState();
  console.log('inputProcess---', inputProcess);
  const [createdDate, setcreatedDate] = useState();

  const [loading, setloading] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem('user'));

  /* ----- Creating state for customer, process and agent ------ */
  const [OrganisationList, setOrganisationList] = useState();
  const [customerList, setcustomerList] = useState();
  const [processList, setprocessList] = useState();
  console.log('inputProcess2----', processList);
  const [agentList, setagentList] = useState();
  let inputVoice;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // customer: '',
      // organisationId: `${userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : ''}`,
      // agent: '',
      deal_name: dealName,
      deal_reason: dealStatus === 'Closed Won' || dealStatus === 'Closed Lost' ? dealReason : null
      // taskStatus: TaskStatus
      // inputId: '',
      // deal_id
    },
    validationSchema: Yup.object().shape({
      // customer: Yup.string().required('This field is required.'),
      // organisationId: Yup.string().required('This field is required.'),
      // agent: Yup.string().required('This field is required.'),
      deal_name: Yup.string()
        .max(30, 'Deal name should be 2 to 30 character long.')
        .required('This field is required.')
      // taskStatus: Yup.string().required('This field is required.')
    }),
    onSubmit: async () => {
      setloading(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        // id: input_id,
        // inputFile: formik.values.uploadFile,
        // customer_id: customerID,
        agent_id: agentId,
        // organisation_id: organisationId,
        deal_name: formik.values.deal_name,
        deal_stage: dealStage,
        deal_status: dealStatus,
        id: dealId,
        reason:
          dealStatus === 'Closed Won' || dealStatus === 'Closed Lost'
            ? formik.values.deal_reason === ''
              ? null
              : formik.values.deal_reason
            : null

        // domain_id: domainId,
        // original_date: createdDate
      };
      await API.put('/secure/update_deal', data, config)
        .then((res) => {
          setloading(false);
          setAlertContent(`Success : ${res.data.message}`);
          setAlert(true);
          setAlertType('success');
          handleDialog();
        })
        .then(() => {
          updateComponent(isUpdated + 1);
        })
        .catch((err) => {
          setloading(false);
          setAlertContent(`${err.response.data.message}`);
          setAlert(true);
          setAlertType('error');
          handleDialog();
        });
    }
  });

  // if (input_file) {
  //   inputVoice = input_file.split('/');
  // }
  const dealStageList = [
    { dealStage_id: 'briefing_complete', dealStage_name: 'Briefing Complete' },
    { dealStage_id: 'decision_awaited', dealStage_name: ' Decision Awaited' },
    { dealStage_id: 'contract_sent', dealStage_name: 'Contract Sent' },
    { dealStage_id: 'deal_won', dealStage_name: 'Deal Won' },
    { dealStage_id: 'deal_lost', dealStage_name: 'Deal Lost' }
  ];
  const dealStatusList = [
    { dealStatus_id: 'in_progress', dealStatus_name: 'In-progress' },
    { dealStatus_id: 'closed_won', dealStatus_name: 'Closed Won' },
    { dealStatus_id: 'closed_lost', dealStatus_name: 'Closed Lost' }
  ];
  const SampleFileName = (name) => {
    if (name) {
      return name[name.length - 1];
    }
    return 'Update Voice';
  };

  const handleAudioFileChange = (event, fileName) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (fileReader.readyState === 2) {
        const file = e.target.result;
        setuserAudio({ File_Name: fileName.name, Data: file });
        formik.setFieldValue(`uploadFile`, { File_Name: fileName.name, Data: file });
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomer = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${organisationId}`, config)
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (organisationId) {
      getCustomer();
    } else {
      setcustomerList([]);
    }
  }, [organisationId]);

  const getProccesList = async (customerId) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(`/secure/process_list?customer_id=${customerId}`, config);
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAgentList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getRole() {
      return API.get(
        `secure/list_of_agent?customer_id=${customerID}&organisation_id=${organisationId}`,
        config
      );
    }
    Promise.all([getRole()])
      .then(([agentData]) => {
        setagentList(agentData.data.agent_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (customerID !== null) {
      // getProccesList(customerID);
      getAgentList();
    } else {
      // setprocessList(null);
      setagentList([]);
    }
  }, [customerID]);

  const setCustomerValue = (value) => {
    setcustomerID(value);
    const orgId = customerList.filter((element) => element.customer_id === value);
    console.log('orgId ---', orgId);
    setorganisationId(orgId[0].organisation_id);
    setdomainId(orgId[0].domain_id);
    setprocessList(null);
  };

  const setDateFormat = (date) => {
    const formatStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return formatStartDate;
  };

  const CustomInput = React.forwardRef((props, ref) => (
    <div style={{ position: 'relative' }}>
      <input {...props} ref={ref} />
      <CalendarMonthIcon
        onClick={props.onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '10px',
          // pointerEvents: 'none',
          cursor: 'pointer',
          zIndex: 5
        }}
      />
    </div>
  ));

  const handleCheckInDate = (date) => {
    const start_date = setDateFormat(date);
    setcreatedDate(start_date);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={6}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              // <TextField
              //   required
              //   fullWidth
              //   label="Organisation Name"
              //   defaultValue={organisationId}
              //   disabled
              // />
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Selectbox
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  defaultValue={organisationId}
                  // {...formik.getFieldProps('organisationId)
                  onChange={(e) => setorganisationId(e.target.value)}
                  disabled
                >
                  {OrganisationList
                    ? OrganisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Selectbox
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  defaultValue={customerID}
                  // {...formik.getFieldProps('customer')}
                  onChange={(e) => setCustomerValue(e.target.value)}
                  disabled
                >
                  {customerList
                    ? customerList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={customer_id}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          {/* <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="process-select-label">
                Select Process
              </InputLabel>
              <Selectbox
                required
                labelId="process-select-label"
                label="Select Process"
                id="select-process"
                // {...formik.getFieldProps('process')}
                // defaultValue={inputProcess[0]}
                onChange={(e) => setinputProcess([e.target.value])}
              >
                {processList
                  ? processList.map((element) => (
                      <MenuItem key={element} value={element}>
                        {element}
                      </MenuItem>
                    ))
                  : null}
              </Selectbox>
            </FormControl>
          </Grid> */}
          <Grid item xs={6}>
            {/* <TextField required fullWidth label="Agnet Name" defaultValue={agentId} disabled /> */}
            {userDetails.role === 'Agent' ? (
              <FormControl fullWidth>
                <InputLabel required id="agent-select-label">
                  Select Agent
                </InputLabel>
                <Selectbox
                  required
                  labelId="agent-select-label"
                  label="Select Agent"
                  id="select-agent"
                  disabled
                  defaultValue={agentId}
                  onChange={(e) => setagentId(e.target.value)}
                >
                  {agentList
                    ? agentList.map((element) => {
                        const { user_id, first_name, last_name, empcode } = element;
                        return (
                          <MenuItem key={user_id} value={user_id}>
                            {empcode} - {first_name} {last_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="agent-select-label">
                  Select Agent
                </InputLabel>
                <Selectbox
                  required
                  labelId="agent-select-label"
                  label="Select Agent"
                  id="select-agent"
                  defaultValue={agentId}
                  disabled
                  onChange={(e) => setagentId(e.target.value)}
                >
                  {agentList
                    ? agentList.map((element) => {
                        const { user_id, first_name, last_name, empcode } = element;
                        return (
                          <MenuItem key={user_id} value={user_id}>
                            {empcode} - {first_name} {last_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Deal Name"
              // name="deal_name"
              defaultValue={dealName}
              onChange={(e) => {
                setdealName(e.target.value);
                formik.setFieldValue('deal_name', e.target.value);
              }}
              error={Boolean(formik.touched.deal_name && formik.errors.deal_name)}
              helperText={formik.touched.deal_name && formik.errors.deal_name}
            />
            {/* <FormHelperText sx={{ color: '#d32f2f' }}>
              {formik.touched.deal_name && formik.errors.deal_name}
            </FormHelperText> */}
            {/* <Field
              as={TextField}
              label="Deal Name"
              name="dealName"
              // value={dealName}
              // onChange={(e) => console.log(e.target.value)}
              fullWidth
              error={formik.errors.dealName && formik.touched.dealName}
              size="big"
              helperText={<ErrorMessage name="dealName" />}
              required
              sx={{ marginBottom: '1.5%' }}
            /> */}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="dealStage-select-label">
                Select Deal Stage
              </InputLabel>
              <Selectbox
                required
                labelId="dealStage-select-label"
                label="Select Deal Stage"
                id="select-stage"
                defaultValue={dealStage}
                // {...getFieldProps('dealStage')}
                error={Boolean(formik.touched.dealStage && formik.errors.dealStage)}
                helperText={formik.touched.dealStage && formik.errors.dealStage}
                onChange={(e) => setdealStage(e.target.value)}
              >
                {dealStageList
                  ? dealStageList.map((element) => {
                      const { dealStage_id, dealStage_name } = element;
                      return (
                        <MenuItem key={dealStage_name} value={dealStage_name}>
                          {dealStage_name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Selectbox>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {formik.touched.dealStage && formik.errors.dealStage}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="deal-select-label">
                Deal Status
              </InputLabel>
              <Selectbox
                required
                labelId="deal-select-label"
                label="Deal Status"
                id="select-deal"
                // {...getFieldProps('dealStatus')}
                defaultValue={dealStatus}
                error={Boolean(formik.touched.dealStatus && formik.errors.dealStatus)}
                helperText={formik.touched.dealStatus && formik.errors.dealStatus}
                onChange={(e) => setdealStatus(e.target.value)}
              >
                {dealStatusList
                  ? dealStatusList.map((element) => {
                      const { dealStatus_id, dealStatus_name } = element;
                      return (
                        <MenuItem key={dealStatus_name} value={dealStatus_name}>
                          {dealStatus_name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Selectbox>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {formik.touched.dealStatus && formik.errors.dealStatus}
              </FormHelperText>
            </FormControl>
          </Grid>
          {dealStatus === 'Closed Lost' || dealStatus === 'Closed Won' ? (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Deal Reason"
                // name="deal_name"
                defaultValue={dealReason}
                onChange={(e) => {
                  setdealReason(e.target.value);
                  formik.setFieldValue('deal_reason', e.target.value);
                }}
                error={Boolean(formik.touched.deal_reason && formik.errors.deal_reason)}
                helperText={formik.touched.deal_reason && formik.errors.deal_reason}
              />
            </Grid>
          ) : null}
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleDialog}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            type="submit"
            // onClick={formik.errors.length >= 0 ? null : handleEditUpload}
            loading={loading}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Update
          </LoadingButton>
        </Container>
      </Form>
    </FormikProvider>
  );
};
// campaign edit form

export const CampaignEditForm = ({
  // formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  inputId,
  inputDetails,
  handleDialog
}) => {
  // const { handleSubmit, isSubmitting } = formik;
  const inputRef = useRef(null);
  const [userAudio, setuserAudio] = useState({ File_Name: '', Data: '' });
  const {
    campaign_id,
    org_name,
    organisation_id,
    customer_id,
    customer_organisation_name,
    campaign_name,
    product,
    created_date,
    campaign_startdate,
    campaign_enddate,
    created_by
  } = inputDetails;

  const [customerID, setcustomerID] = useState(customer_id);
  const [organisationId, setorganisationId] = useState(organisation_id);
  const [campaignId, setcampaignId] = useState(campaign_id);
  const [campaignName, setcampaignName] = useState(campaign_name);
  const [campaignProduct, setcampaignProduct] = useState(product);
  const [startDate, setstartDate] = useState(campaign_startdate);
  const [endDate, setendDate] = useState(campaign_enddate);
  const [createdDate, setcreatedDate] = useState(created_date);

  const userDetails = JSON.parse(localStorage.getItem('user'));
  const UploadSchemma = Yup.object().shape({
    customer: Yup.string().required('This field is required.'),
    organisationId: Yup.string().required('This field is required.'),
    campaign_name: Yup.array().of(
      Yup.string()
        .matches(
          /^\S+[A-Za-z\s]{2,50}$/,
          'Campaign should be in between 3 to 30 character long and it should not start with space and does not contain special character and number.'
        )
        .max(30, 'Campaign should be in between 3 to 30 character long.')
        .required('This field is required.')
    ),
    campaign_product: Yup.string()
      .matches(
        /^\S+[A-Za-z\s]{2,50}$/,
        'Product should not start with space and does not contain special character and number.'
      )
      .max(30, 'Product name should be in between 3 to 30 character long.')
      .required('This field is required.'),
    start_date: Yup.date().required('This field is required.'),
    end_date: Yup.date().required('This field is required.')
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: userDetails.role === 'QA' ? userDetails.customer_id : customerID,
      organisationId: `${
        userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : organisationId
      }`,
      campaign_name: campaignName,
      campaign_product: campaignProduct,
      start_date: startDate,
      end_date: endDate
    },
    validationSchema: UploadSchemma,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
        // onUploadProgress: (data) => {
        //   setUploaded(Math.round((data.loaded / data.total) * 100));
        // }
      };
      const data = {
        customer_id: formik.values.customer,
        organisation_id: formik.values.organisationId,
        id: campaignId,
        campaign_name: formik.values.campaign_name,
        product: formik.values.campaign_product,
        campaign_startdate: formik.values.start_date,
        campaign_enddate: formik.values.end_date
      };
      console.log('data ---------------------', data);
      await API.put('/secure/update_campaign', data, config)
        .then((res) => {
          // formik.resetForm();
          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          handleDialog();
        })
        .then(() => {
          updateComponent(isUpdated + 1);
        })
        .catch((err) => {
          console.log('error----', err);
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });
  // useEffect(() => {
  //   formik.setFieldValue('campaignProduct', campaignProduct);
  // }, [campaignProduct]);
  // useEffect(() => {
  //   formik.setFieldValue('campaignName', campaignName);
  // }, [campaignName]);

  const [loading, setloading] = useState(false);
  // const userDetails = JSON.parse(localStorage.getItem('user'));

  /* ----- Creating state for customer, process and agent ------ */
  const [OrganisationList, setOrganisationList] = useState();
  const [customerList, setcustomerList] = useState();

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomer = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${organisationId}`, config)
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (organisationId) {
      getCustomer();
    } else {
      setcustomerList([]);
    }
  }, [organisationId]);

  const setCustomerValue = (value) => {
    setcustomerID(value);
    const orgId = customerList.filter((element) => element.customer_id === value);
    console.log('orgId ---', orgId);
    setorganisationId(orgId[0].organisation_id);
  };

  const handleEditUpload = async () => {
    setloading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = {
      customer_id: customerID,
      organisation_id: organisationId,
      id: campaignId,
      campaign_name: campaignName,
      product: campaignProduct,
      campaign_startdate: startDate,
      campaign_enddate: endDate
    };
    await API.put('/secure/update_campaign', data, config)
      .then((res) => {
        setloading(false);
        setAlertContent(`Success : ${res.data.message}`);
        setAlert(true);
        setAlertType('success');
        handleDialog();
      })
      .then(() => {
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        setloading(false);
        setAlertContent(`${err.response.data.message}`);
        setAlert(true);
        setAlertType('error');
        handleDialog();
      });
  };

  const setDateFormat = (date) => {
    const formatStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return formatStartDate;
  };
  console.log('campaignname-------', formik.values.campaign_name);
  const CustomInput = React.forwardRef((props, ref) => (
    <div style={{ position: 'relative' }}>
      <input {...props} ref={ref} />
      <CalendarMonthIcon
        onClick={props.onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '10px',
          // pointerEvents: 'none',
          cursor: 'pointer',
          zIndex: 5
        }}
      />
    </div>
  ));

  console.log('createdDate', createdDate);

  const handleCheckInDate = (date) => {
    const start_date = setDateFormat(date);
    formik.setFieldValue('start_date', start_date);
    setstartDate(start_date);
    // formik.setFieldValue('end_date', '');
    // setendDate(null);
  };
  const handleCheckOutDate = (date) => {
    const end_date = setDateFormat(date);
    formik.setFieldValue('end_date', end_date);
    setendDate(end_date);
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      // margin: theme.spacing(1),
      width: 300
    },
    indeterminateColor: {
      color: '#f50057'
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    menuPaper: {
      maxHeight: 200
    }
  }));
  const classes = useStyles();
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={6}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Selectbox
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  defaultValue={organisationId}
                  // {...formik.getFieldProps('organisationId)
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                  onChange={(e) => {
                    formik.setFieldValue('organisationId', e.target.value);
                    setorganisationId(e.target.value);
                  }}
                >
                  {OrganisationList
                    ? OrganisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Selectbox
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  defaultValue={customerID}
                  // {...formik.getFieldProps('customer')}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                  onChange={(e) => {
                    formik.setFieldValue('customer', e.target.value);
                    setCustomerValue(e.target.value);
                  }}
                >
                  {customerList
                    ? customerList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={customer_id}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>

          <Grid item xs={6}>
            <FieldArray
              name="campaign_name"
              render={(arrayHelpers) => (
                <>
                  {formik.values.campaign_name.map((row, index) => {
                    console.log('index--', index);

                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '5px'
                        }}
                      >
                        <TextField
                          // defaultValue={row}
                          required
                          fullWidth
                          label="Campaign Name"
                          // name={`campaign_name[${index}]`}
                          // onChange={(e) => {
                          //   formik.setFieldValue(`campaign_name[${index}]`, e.target.value);
                          //   // setcampaignName([e.target.value]);
                          // }}
                          {...formik.getFieldProps(`campaign_name[${index}]`)}
                          error={Boolean(
                            formik.touched &&
                              formik.touched.campaign_name &&
                              formik.touched.campaign_name[index] &&
                              formik.touched.campaign_name[index] &&
                              formik.errors &&
                              formik.errors.campaign_name &&
                              formik.errors.campaign_name[index] &&
                              formik.errors.campaign_name[index]
                          )}
                          helperText={
                            formik.touched &&
                            formik.touched.campaign_name &&
                            formik.touched.campaign_name[index] &&
                            formik.touched.campaign_name[index] &&
                            formik.errors &&
                            formik.errors.campaign_name &&
                            formik.errors.campaign_name[index] &&
                            formik.errors.campaign_name[index]
                          }
                        />
                        {index === formik.values.campaign_name.length - 1 ? (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: `${index > 0 ? 'visible' : 'hidden'}`
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: 'hidden'
                              }}
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              as={TextField}
              label="Product"
              name="campaign_product"
              onChange={(e) => {
                formik.setFieldValue('campaign_product', e.target.value);
                setcampaignProduct(e.target.value);
              }}
              fullWidth
              error={formik.errors.campaign_product && formik.touched.campaign_product}
              size="big"
              helperText={<ErrorMessage name="campaign_product" />}
              required
              sx={{ marginBottom: '1.5%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              selected={new Date(startDate)}
              onChange={handleCheckInDate}
              maxDate={new Date()}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
              placeholderText="Select Date *"
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              selected={new Date(endDate)}
              onChange={handleCheckOutDate}
              maxDate={new Date()}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
              placeholderText="Select Date *"
            />
          </Grid>
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleDialog}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            type="submit"
            // onClick={formik.errors.length >= 0 ? null : handleEditUpload}
            loading={loading}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Update
          </LoadingButton>
        </Container>
      </Form>
    </FormikProvider>
  );
};
