/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-nested-ternary */
/* eslint-disable object-shorthand */

import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useNavigate } from 'react-router-dom';
// material
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Container,
  Stack,
  Typography,
  Grid,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  FormHelperText
} from '@mui/material';
import uploadLoop from '@iconify/icons-line-md/upload-loop';
import downloadLoop from '@iconify/icons-line-md/download-loop';
import removeIcon from '@iconify/icons-mdi/remove';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikProvider, Form } from 'formik';
import FileSaver, { saveAs } from 'file-saver';

// Components
import Page from '../components/Page';
import API from '../E2E/axios.util';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import ComplianceForm from '../layouts/Compliance/ComplianceForm';
import ComplianceDataModel from '../layouts/Compliance/ComplianceDataModel';
import { Notification } from '../components/Notification/Notification';

const ENV_PATH = process.env.REACT_APP_SERVER_MEDIA_URL;

export default function UploadKeywords() {
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [open, setOpen] = useState();
  const [update, setUpdate] = useState(0);
  const [keywordList, setKeywordList] = useState();
  const [upload, setUpload] = useState(false);
  const [organisationList, setorganisationList] = useState();
  const [customerList, setcustomerList] = useState();
  const [processList, setProcessList] = useState();
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const [downloadSample, setDownloadSample] = useState();
  const [organisation, setOrganisation] = useState(
    userDetails.role !== 'Super Admin' ? userDetails.organisation_id : null
  );
  const [customer, setCustomer] = useState(
    userDetails.role !== 'Super Admin' ? userDetails.customer_id : null
  );
  // const [process, setProcess] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const KeywordSchema = Yup.object().shape({
    organisation: Yup.string().required('This field is required.'),
    customerOrganisation: Yup.string().required('This field is required.'),
    // sentences: Yup.array().of(
    //   Yup.object({
    //     sentence: Yup.string().required('This field is required.'),
    //     Category: Yup.string().required('This field is required.')
    //   })
    // ),
    // keywords: Yup.array().of(Yup.string().required('This field is required.'))
    process: Yup.array().of(Yup.string().required('This field is required.')),
    // process: Yup.string().required('This field is required.'),
    keywords: Yup.array().of(
      Yup.object({
        description: Yup.object({
          phrases: Yup.array().of(
            Yup.string()
              .max(500, 'Max 500 alphabets are allowed')
              .required('This field is required.')
          )
        }),
        Category: Yup.string()
          .required('This field is required.')
          .test('unique-category', 'Category should be unique.', function (value) {
            const categories = formik.values.keywords.map((keyword) =>
              keyword.Category?.toLowerCase()
            );
            const lowercaseValue = value?.toLowerCase();

            return categories.filter((category) => category === lowercaseValue).length === 1;
          }),
        Parameter: Yup.string().required('This field is required.'),
        weightage: Yup.string().required('This field is required.'),
        Type: Yup.string().required('This field is required.')
      })
    )
    // keywordCategory: Yup.array().of(Yup.string().required('This field is required.')),
    // sentenceCategory: Yup.array().of(Yup.string().required('This field is required.'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      organisation: `${userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : ''}`,
      customerOrganisation:
        userDetails.role === 'QA' ||
        userDetails.role === 'Customer_Admin' ||
        userDetails.role === 'QA_admin'
          ? userDetails.customer_id
          : '',
      process: [''],
      keywords: [
        {
          description: [{ language: '', phrases: [''] }],
          Parameter: '',
          Category: '',
          Type: '',
          Value: [''],
          weightage: '',
          aiMode: false
        }
      ]
      // sentences: [
      //   {
      //     sentence: '',
      //     Category: ''
      //   }
      // ]
    },
    validationSchema: KeywordSchema,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        processes: formik.values.process,
        organisation_id: formik.values.organisation,
        customer_id: formik.values.customerOrganisation,
        // sentences: formik.values.sentences,
        questions: formik.values.keywords
      };
      await API.post('/secure/add_compliance', data, config)
        .then((res) => {
          formik.resetForm();
          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          handleDialog();
          setUpdate(update + 1);
        })
        .catch((err) => {
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });

  const handleDialog = () => {
    setOpen(!open);
    formik.resetForm();
  };

  const handleAlert = () => {
    setAlert(false);
  };

  const handleDialogForExcel = () => {
    formikExcel.resetForm();
    setUpload(!upload);
  };

  const csvUploadSchema = Yup.object().shape({
    // organisation: Yup.string().required('This field is required.'),
    // customerOrganisation: Yup.string().required('This field is required.'),

    process: Yup.string().required('This field is required.')
  });

  const formikExcel = useFormik({
    initialValues: {
      process: '',
      userExcel: []
    },
    validationSchema: csvUploadSchema,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        organisation_id: organisation,
        customer_id: customer,
        processes: formikExcel.values.process,
        file: formikExcel.values.userExcel
      };

      await API.post('/secure/upload_script_compliance', data, config)
        .then((res) => {
          formikExcel.resetForm();
          setAlertContent(`Success : ${res.data.message}`);
          setAlert(true);
          setAlertType('success');
          setUpdate(update + 1);
          handleDialogForExcel();
        })
        .catch((err) => {
          setAlertContent(`Error : ${err.response.data.message}`);
          setAlert(true);
          setAlertType('error');
        });
    }
  });

  const inputRef = useRef();

  const [QaExcel, setQaExcel] = useState({
    File_Name: 'Upload File',
    Data: null
  });

  const handleExcelFileChange = (name, event, fileName) => {
    // console.log('encountered');
    const file = event.target.files[0];
    if (file) {
      console.log('fileReader', file);

      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const fileData = e.target.result;

        setQaExcel({ File_Name: fileName.name, Data: fileData });
        formikExcel.setFieldValue(`userExcel`, { File_Name: fileName.name, Data: fileData });
      };

      fileReader.readAsDataURL(file);
    }
  };
  const [loadingForExcelUpload, setLoadingForExcelUpload] = useState(false);
  const handleExcelUpload = async () => {
    setLoadingForExcelUpload(true);
    // const token = localStorage.getItem('token');
    // const config = {
    //   headers: { Authorization: `Bearer ${token}` }
    // };
    // const data = {
    //   bulkFile: formikExcel.values.userExcel
    // };

    // console.log('data', data);
    // await API.post('/secure/add_bulk_user', data, config)
    //   .then((res) => {
    //     formikExcel.resetForm();
    //     setLoadingForExcelUpload(false);
    //     setAlertContent(`Success : ${res.data.message}`);
    //     setAlert(true);
    //     setAlertType('success');
    //     handleDialogForExcel();
    //   })
    //   .catch((err) => {
    //     setLoadingForExcelUpload(false);
    //     setAlertContent(`${err.response.data.message}`);
    //     setAlert(true);
    //     setAlertType('error');
    //   });
  };

  function handleSampleDownload() {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    API.get(
      `/secure/sample_excel_file_compliance?customer_id=${userDetails.customer_id}`,
      config
    ).then((res) => {
      FileSaver.saveAs(
        `${ENV_PATH}${res.data.excel_file_url}`,
        res.data.excel_file_url.split('/').pop()
      );
    });
  }

  // functions for select customer, process, organisation

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation', config)
      .then((res) => {
        setorganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${organisation}`, config)
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (organisation) {
      getCustomerList();
    } else {
      setcustomerList([]);
    }
  }, [organisation]);

  const getProcessList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `/secure/process_list?customer_id=${customer}&organisation_id=${organisation}`,
        config
      );
    }

    Promise.all([getProcess()])
      .then(([processData]) => {
        setProcessList(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (customer) {
      getProcessList();
    } else {
      setProcessList([]);
    }
  }, [customer]);

  // -----------------------------------------------------------
  // handlecustomer values.

  const handleCustomerChange = (e) => {
    setCustomer(e.customer_organisation_name);
    // formik.setFieldValue('customerOrganisation', e.customer_id);
    // setServices(e.dashboard_services);
  };

  const handleProcess = (processValue, index) => {
    // console.log('first---', index);
    formikExcel.setFieldValue('process', processValue);
    // setProcess(processValue);
  };
  console.log('guruu', formikExcel);
  return (
    <Page title="Script">
      <>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Script
            {keywordList && keywordList.compliance_list
              ? ` (${keywordList.compliance_list.length})`
              : ''}
          </Typography>
          {/* {userDetails.role !== 'Manager' && (
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={() => navigate('/dashboard/workspace/compliance/add')}
              sx={{
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              Add Script
            </Button>
          )} */}

          <div
            style={{
              width: 300,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              alignItems: 'center'
            }}
          >
            {/* {userDetails.role === 'Customer_Admin' && (
              <Button
                variant="contained"
                onClick={handleDialogForExcel}
                to="#"
                startIcon={<Icon icon={uploadLoop} />}
                sx={{
                  backgroundColor: '#107c41',
                  '&:hover': {
                    backgroundColor: '#3AB170'
                  },
                  marginRight: '10px'
                }}
              >
                Upload Excel
              </Button>
            )} */}
            {userDetails.role !== 'Manager' && (
              <Button
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => navigate('/dashboard/workspace/compliance/add')}
                sx={{
                  backgroundColor: '#3892CF',
                  '&:hover': {
                    backgroundColor: '#3892CF'
                  }
                }}
              >
                Add Script
              </Button>
            )}
          </div>
        </Stack>
        <CustomFormModel
          handleDialog={handleDialogForExcel}
          open={upload}
          title="Upload Excel For Script"
        >
          <FormikProvider values={formikExcel}>
            <form onSubmit={formikExcel.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {userDetails.role !== 'Super_Admin' ? (
                    <TextField
                      required
                      fullWidth
                      label="Organisation Name"
                      defaultValue={userDetails.organisation_name}
                      disabled
                    />
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel required id="Organisation-select-label">
                        Select Organisation
                      </InputLabel>
                      <Select
                        required
                        labelId="Organisation-select-label"
                        label="Select Organisation"
                        id="select-organisation"
                        value={organisation}
                        onChange={(e) => setOrganisation(e.target.value)}
                        // {...getFieldProps('organisation')}
                        // error={Boolean(touched.organisation && errors.organisation)}
                        // helperText={touched.organisation && errors.organisation}
                        // MenuProps={{ classes: { paper: classes.menuPaper } }}
                      >
                        {organisationList
                          ? organisationList.map((element) => {
                              const { organisation_id, organisation_name } = element;
                              return (
                                <MenuItem key={organisation_id} value={organisation_id}>
                                  {organisation_name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                      {/* <FormHelperText sx={{ color: '#d32f2f' }}>
                        {touched.organisation && errors.organisation}
                      </FormHelperText> */}
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {userDetails.role === 'QA' ||
                  userDetails.role === 'Customer_Admin' ||
                  userDetails.role === 'QA_admin' ? (
                    <TextField
                      required
                      fullWidth
                      label="Customer Name"
                      defaultValue={userDetails.customer_name}
                      disabled
                    />
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel required id="customer-select-label">
                        Select Customer
                      </InputLabel>
                      <Select
                        required
                        labelId="customer-select-label"
                        label="Select Customer"
                        id="select-customer"
                        // name="customerOrganisation"
                        // {...getFieldProps('customerOrganisation')}
                        value={customer}
                        onChange={(e) => handleCustomerChange(e.target.value)}
                        // error={Boolean(touched.customerOrganisation && errors.customerOrganisation)}
                        // helperText={touched.customerOrganisation && errors.customerOrganisation}
                        // MenuProps={{ classes: { paper: classes.menuPaper } }}
                      >
                        {customerList
                          ? customerList.map((element) => {
                              const { customer_id, customer_organisation_name } = element;
                              return (
                                <MenuItem key={customer_id} value={element}>
                                  {customer_organisation_name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                      {/* <FormHelperText sx={{ color: '#d32f2f' }}>
                        {touched.customerOrganisation && errors.customerOrganisation}
                      </FormHelperText> */}
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel required id="process-select-label">
                      Select Process
                    </InputLabel>
                    <Select
                      required
                      labelId="process-select-label"
                      label="Select Process"
                      id="select-process"
                      // name="process"
                      // {...getFieldProps('process')}
                      error={Boolean(formikExcel.touched.process && formikExcel.errors.process)}
                      value={formikExcel.values.process}
                      helperText={formikExcel.touched.process && formikExcel.errors.process}
                      onChange={(e) => handleProcess(e.target.value)}
                      // MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                      {processList
                        ? processList.map((element, index) => {
                            const process = Object.values(element)[0];
                            return (
                              <MenuItem key={element} value={element}>
                                {element}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {formikExcel.touched.process && formikExcel.errors.process}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: 200,
                      border: '1px dashed black',
                      padding: 10,
                      paddingLeft: 30,
                      paddingRight: 30,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'end',
                        marginBottom: 4
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => handleSampleDownload()}
                        to="#"
                        startIcon={<Icon icon={downloadLoop} />}
                        sx={{
                          backgroundColor: '#107c41',
                          '&:hover': {
                            backgroundColor: '#3AB170'
                          }
                        }}
                        // disabled={!downloadSample}
                      >
                        Sample
                      </Button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <LoadingButton
                        variant="contained"
                        disabled={QaExcel.Data !== null}
                        onClick={() => {
                          inputRef.current?.click();
                        }}
                        style={{
                          width: 350,
                          height: 53,
                          backgroundColor: QaExcel.Data !== null ? '#E0E0E0' : '#3892CF',
                          '&:hover': {
                            backgroundColor: QaExcel.Data !== null ? '#E0E0E0' : '#3892CF'
                          }

                          // '&:hover': {
                          //   backgroundColor: '#3892CF'
                          // }
                        }}
                      >
                        {!QaExcel.File_Name ? `Upload File` : `${QaExcel.File_Name}`}
                        <input
                          type="file"
                          // hidden
                          style={{ display: 'none' }}
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          // multiple
                          ref={inputRef}
                          onChange={(event) => {
                            console.log('encountered');
                            handleExcelFileChange('qa', event, event.currentTarget.files[0]);
                          }}
                        />
                      </LoadingButton>
                      {QaExcel.File_Name !== 'Upload File' && (
                        <IconButton
                          onClick={() => {
                            setQaExcel({
                              File_Name: 'Upload File',
                              Data: null
                            });
                            formikExcel.setFieldValue(`userExcel`, {
                              File_Name: 'Upload File',
                              Data: null
                            });
                            inputRef.current.value = null;
                          }}
                        >
                          <Icon icon={removeIcon} sx={{ color: '#fffff' }} />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  variant="contained"
                  onClick={handleDialogForExcel}
                  style={{
                    marginRight: 5,
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                >
                  Cancel
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={loadingForExcelUpload}
                  style={{
                    marginRight: 5,
                    backgroundColor: QaExcel?.Data === null ? '#E0E0E0' : '#3892CF',
                    '&:hover': {
                      backgroundColor: QaExcel?.Data === null ? '#E0E0E0' : '#3892CF'
                    }
                    // backgroundColor: '#3892CF',
                    // '&:hover': {
                    //   backgroundColor: '#3892CF'
                    // }
                  }}
                  disabled={QaExcel.Data === null}
                >
                  Upload
                </LoadingButton>
              </Container>
            </form>
          </FormikProvider>
        </CustomFormModel>
        {/* <Container>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleDialog}
            open={open}
            title="Add Script"
          >
            <ComplianceForm />
          </CustomFormModel>
        </Container> */}
        <ComplianceDataModel
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          handleDialog={handleDialog}
          formik={formik}
          isUpdated={update}
          updateComponent={setUpdate}
          keywordList={keywordList}
          setKeywordList={setKeywordList}
        />
      </>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}
