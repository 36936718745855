/* eslint-disable object-shorthand */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useContext } from 'react';
import './UploadForm.css';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import fileDownload from '@iconify/icons-fa-solid/file-download';
import { ReactTransliterate } from 'react-transliterate';
import 'react-transliterate/dist/index.css';
import Select from 'react-select';
import {
  Input,
  Card,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  Pagination,
  Menu,
  MenuItem,
  IconButton,
  Button,
  ListItemIcon,
  ListItemText,
  Box,
  LinearProgress,
  Typography,
  Collapse,
  TableHead,
  Tooltip as Tip,
  Stack,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Grid
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import editFill from '@iconify/icons-eva/edit-fill';
import eyeIcon from '@iconify/icons-mdi/eye';
import cogs from '@iconify/icons-mdi/cogs';
import cogPlay from '@iconify/icons-mdi/cog-play';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { ArrowBackOutlined, ArrowForwardSharp } from '@mui/icons-material';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
// components
import useWebSocket from '../../hooks/useWebSocket';
import { UploadEditForm } from '../../components/custom-form-model/CRUDForms';
import inputContex from '../../Context/UploadContext/inputContext';
import { CustomFormModel } from '../../components/custom-form-model/CustomFormModel';
import Scrollbar from '../../components/Scrollbar';
import API from '../../E2E/axios.util';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: '#3892CF',
        color: 'white'
        // borderRadius: '50%',
      }
    }
  },
  root: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#1D9FD9' // change this to your desired color
    }
  },
  tab: {
    '&.Mui-selected': {
      color: '#1D9FD9' // change this to your desired color
    }
  }
}));

export const UploadDataModel = ({
  handleDialog,
  formik,
  setAlert,
  setAlertContent,
  setAlertType,
  isUpdated,
  updateComponent,
  fromDate,
  toDate,
  updatedDateFormat,
  page,
  setPage,
  uploadDataList,
  setuploadDataList,
  uploadDataCount,
  setUploadDataCount,
  filter,
  setFilter,
  loading,
  setLoading,
  setDateSort,
  setDurationSort,
  dateSort,
  durationSort,
  sortForDate,
  setSortForDate,
  sortForDuration,
  setSortForDuration,
  sortForProcessDate,
  setSortForProcessDate,
  processSort,
  setProcessDateSort,
  storedDataForFilter,
  setStoredDataForFilter
}) => {
  const classes = useStyles();
  const locationData = useLocation();

  const [batch, setBatch] = useState(1);
  const [pageValue, setPageValue] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isCollapse, setisCollapse] = useState(false);
  const [statusCheck, setstatusCheck] = useState(0);
  const [query, setQuery] = useState(
    locationData?.state?.storedInfo?.query ? locationData?.state?.storedInfo?.query : ''
  );
  const [highlightWords, setHighlightWords] = useState(
    locationData?.state?.storedInfo?.highlightWords
      ? locationData?.state?.storedInfo?.highlightWords
      : ''
  );
  const [uploadSearch, setUploadSearch] = useState();

  const [value, setValue] = useState(
    locationData?.state?.storedInfo?.flag ? locationData?.state?.storedInfo?.flag : 0
  );

  const [showDateButton, setShowDateButton] = useState(false);
  const [showDurationButton, setShowDurationButton] = useState(false);
  const [showProcessDateButton, setShowProcessButton] = useState(false);

  const handleMouseEnter = () => {
    setShowDateButton(true);
  };

  const handleMouseLeave = () => {
    setShowDateButton(false);
  };
  const handleMouseDurationEnter = () => {
    setShowDurationButton(true);
  };

  const handleMouseDurationLeave = () => {
    setShowDurationButton(false);
  };

  const handleMouseProcessEnter = () => {
    setShowProcessButton(true);
  };
  const handleMouseProcessLeave = () => {
    setShowProcessButton(false);
  };

  const UPLOAD_TABLE_HEAD = [
    {
      id: 'RequestId',
      numeric: false,
      disablePadding: false,
      label: 'Request Id',
      alignCenter: true
    },
    {
      id: 'organisation',
      numeric: false,
      disablePadding: false,
      label: 'Organisation',
      alignCenter: true
    },
    {
      id: 'callDate',
      numeric: false,
      disablePadding: false,
      label: 'Call Date',
      alignCenter: true,
      sort: 'date'
    },
    {
      id: 'processedDate',
      numeric: false,
      disablePadding: false,
      label: 'Processed Date',
      sort: 'process_date'
    },
    { id: 'filename', numeric: false, disablePadding: false, label: 'File', alignCenter: true },
    {
      id: 'duration',
      numeric: false,
      disablePadding: false,
      label: 'Call Duration',
      alignCenter: true,
      sort: 'duration'
    },
    { id: 'customer', numeric: false, disablePadding: false, label: 'Customer', alignCenter: true },
    { id: 'agent', numeric: false, disablePadding: false, label: 'Agent', alignCenter: true },
    { id: 'process', numeric: false, disablePadding: false, label: 'Process', alignCenter: true },
    { id: 'language', numeric: false, disablePadding: false, label: 'Language', alignCenter: true },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status', alignCenter: true },
    { id: '' }
  ];

  const userDetails = JSON.parse(localStorage.getItem('user'));
  const { uploadStatus, setuploadStatus } = useContext(inputContex);

  const handleChangePage = (event, newPage) => {
    console.log('newPage ----', newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUploadData = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    // await API.get(`/secure/list_enhance`, config);
    let url = `/secure/list_input?page=${page}&flag=${
      (value === 0 && 'all') ||
      (value === 1 && 'audited_comment') ||
      (value === 2 && 'audited_no_comment')
    }&date=${updatedDateFormat.value}`;

    if (updatedDateFormat.label === 'Customize') {
      url += `&start_date=${formatDate(fromDate)}&end_date=${formatDate(toDate)}`;
    }
    if (sortForDate) {
      url += `&sort_by_date=${
        dateSort === 'asec' ? 'original_date' : dateSort === 'desc' ? '-original_date' : null
      }`;
    }
    if (sortForProcessDate) {
      url += `&sort_processed_date=${
        processSort === 'asec'
          ? 'processed_date'
          : processSort === 'desc'
          ? '-processed_date'
          : null
      }`;
    }
    if (sortForDuration) {
      url += `&duration_sort=${durationSort}`;
    }
    await API.get(url, config)
      .then((res) => {
        setuploadDataList(res.data);
        setUploadSearch();
        setLoading(false);
        setAlertContent(`Success: Upload list fetch successfully.`);
        setAlertType('success');
        setLoading(false);
        setAlert(true);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
        setLoading(false);
      });
  };
  const getUploadDataCount = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    // await API.get(`/secure/list_enhance`, config);
    let url = `/secure/count_list_input?date=${updatedDateFormat.value}`;
    if (updatedDateFormat.label === 'Customize') {
      url += `&start_date=${formatDate(fromDate)}&end_date=${formatDate(toDate)}`;
    }
    await API.get(url, config)
      .then((res) => {
        setUploadDataCount(res.data);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
        // setLoading(false);
      });
  };

  const getUploadStatusData = async () => {
    // setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    // await API.get(`/secure/list_enhance`, config);
    await API.get(`/secure/list_input?page=${page}`, config)
      .then((res) => {
        setuploadDataList(res.data);

        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  const changedDate = (date) => {
    const newdate = new Date(date);
    const formatStartDate = `${newdate.getDate()}-${
      newdate.getMonth() + 1
    }-${newdate.getFullYear()}`;
    return formatStartDate;
  };

  const getStatus = (status, reason) => {
    if (status !== 'Process Completed' && status !== 'Failed') {
      return (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
          {status}
        </Box>
      );
    }
    if (status === 'Failed') {
      return (
        <Tooltip title={reason} followCursor placement="top" sx={{ cursor: 'pointer' }}>
          <Box sx={{ width: '100%' }}>{status}</Box>
        </Tooltip>
      );
    }
    return status;
  };
  // const getStatus = (status, request_id, reason) => {
  //   if (uploadStatus) {
  //     if (
  //       uploadStatus.message.request_id === request_id &&
  //       uploadStatus.message.status === 'Queued' &&
  //       status === 'Queued'
  //     ) {
  //       return (
  //         <Box sx={{ width: '100%' }}>
  //           <LinearProgress />
  //           {uploadStatus.message.status}
  //         </Box>
  //       );
  //     }
  //     if (
  //       uploadStatus.message.request_id === request_id &&
  //       uploadStatus.message.status === 'In Progress' &&
  //       status === 'In Progress'
  //     ) {
  //       return (
  //         <Box sx={{ width: '100%' }}>
  //           <LinearProgress />
  //           {uploadStatus.message.status}
  //         </Box>
  //       );
  //     }
  //     if (
  //       uploadStatus.message.request_id === request_id &&
  //       uploadStatus.message.status === 'Process Completed'
  //     ) {
  //       return uploadStatus.message.status;
  //     }
  //     if (
  //       uploadStatus.message.request_id === request_id &&
  //       uploadStatus.message.status === 'Failed'
  //     ) {
  //       return (
  //         <Tooltip title={reason} followCursor placement="top" sx={{ cursor: 'pointer' }}>
  //           <Box sx={{ width: '100%' }}>{uploadStatus.message.status}</Box>
  //         </Tooltip>
  //       );
  //     }
  //     if (status === 'Failed') {
  //       return (
  //         <Tooltip title={reason} followCursor placement="top" sx={{ cursor: 'pointer' }}>
  //           <Box sx={{ width: '100%' }}>{status}</Box>
  //         </Tooltip>
  //       );
  //     }
  //     if (status !== 'Process Completed') {
  //       return (
  //         <Box sx={{ width: '100%' }}>
  //           <LinearProgress />
  //           {status}
  //         </Box>
  //       );
  //     }
  //   } else if (status !== 'Process Completed' && status !== 'Failed') {
  //     return (
  //       <Box sx={{ width: '100%' }}>
  //         <LinearProgress />
  //         {status}
  //       </Box>
  //     );
  //   } else if (status === 'Failed') {
  //     return (
  //       <Tooltip title={reason} followCursor placement="top" sx={{ cursor: 'pointer' }}>
  //         <Box sx={{ width: '100%' }}>{status}</Box>
  //       </Tooltip>
  //     );
  //   }
  //   return status;
  // };

  const getAudioFileName = (fileName) => {
    if (fileName === null) {
      return '';
    }
    const nameArr = fileName.split('/');
    return nameArr[nameArr.length - 1];
  };

  const [message] = useWebSocket('user');

  useEffect(() => {
    if (message && uploadDataList) {
      for (let i = 0; i < uploadDataList.results.length; i++) {
        // console.log('uploadDataList.results[i].input_id', uploadDataList.results[i].input_id);
        // console.log('insideMessage', message.message.input_id);
        if (uploadDataList.results[i].input_id === message.message.input_id) {
          // console.log('message.input_id', message.message.input_id);
          uploadDataList.results[i].status = message.message.status;
          uploadDataList.results[i].request_id = message.message?.request_id;
          uploadDataList.results[i].reason = message.message.reason;
          uploadDataList.results[i].s3url = message.message.filename;
          break;
        }
      }
      // console.log('newDatalistwithMessage', uploadDataList.results);
      // setuploadDataList(uploadDataList);
    }
  }, [message, uploadDataList]);

  console.log('message', message);
  useEffect(() => {
    if (message) {
      setuploadStatus(message);
      setstatusCheck(statusCheck + 1);
      // getUploadStatusData();
      // console.warn('message ----->', message);
      // console.warn('uploadDataList ----->', uploadDataList);
    } else {
      console.log('No Response From Server');
    }
  }, [message]);

  const formatDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = `${inputDate.getFullYear()}-${(inputDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${inputDate.getDate().toString().padStart(2, '0')}`;

    return formattedDate;
  };
  const handleSearch = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `secure/transcript_search?page=${page}&query=${query}&flag=${
      (value === 0 && 'all') ||
      (value === 1 && 'audited_comment') ||
      (value === 2 && 'audited_no_comment')
    }&search_flag=no_keywords&batch=${batch}&date=${updatedDateFormat.value}`;
    if (updatedDateFormat.label === 'Customize') {
      url += `&start_date=${formatDate(fromDate)}&end_date=${formatDate(toDate)}`;
    }
    await API.get(url, config)
      .then((res) => {
        setUploadSearch(res.data);
        setuploadDataList();
        setLoading(false);
        console.log('search', res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploadSearch();
        setLoading(false);
      });
  };
  const [keyword, setKeyword] = useState(
    locationData?.state?.storedInfo?.keyword ? locationData?.state?.storedInfo?.keyword : ''
  );
  const handleKeywordSearch = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `secure/transcript_search?page=${page}&query=${keyword}&flag=${
      (value === 0 && 'all') ||
      (value === 1 && 'audited_comment') ||
      (value === 2 && 'audited_no_comment')
    }&search_flag=keywords&batch=${batch}&date=${updatedDateFormat.value}`;
    if (updatedDateFormat.label === 'Customize') {
      url += `&start_date=${formatDate(fromDate)}&end_date=${formatDate(toDate)}`;
    }
    await API.get(url, config)
      .then((res) => {
        setUploadSearch(res.data);
        setuploadDataList();
        setLoading(false);
        // console.log('search', res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploadSearch();
        setLoading(false);
      });
    await API.get(`/secure/convert_to_hindi?query=${keyword}`, config)
      .then((res) => {
        setHighlightWords(res.data.result.split(' '));
        setLoading(false);
      })
      .catch((err) => {
        setHighlightWords();
        setLoading(false);
      });
  };

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const debouncedQuery = useDebounce(query, 700);

  useEffect(() => {
    if (query?.length > 0 && (!keyword || keyword === '')) {
      handleSearch();
    }
    // console.log('query', query);
  }, [debouncedQuery, isUpdated, page, batch, toDate, updatedDateFormat]);

  useEffect(() => {
    if (keyword?.length > 0) {
      handleKeywordSearch();
    }
  }, [page, batch, toDate, updatedDateFormat, isUpdated]);

  useEffect(() => {
    if (!query && filter === false) {
      if (!keyword || keyword === '') {
        getUploadData();
        getUploadDataCount();
      }
    }
  }, [
    isUpdated,
    page,
    query,
    value,
    keyword,
    updatedDateFormat,
    toDate,
    filter,
    dateSort,
    durationSort,
    processSort
  ]);

  const handleKeyPress = () => {
    if (
      query &&
      uploadSearch &&
      Number(pageValue) <= Math.ceil(uploadSearch.count / 10) &&
      Number(pageValue) !== 0
    ) {
      setPage(Number(pageValue));
      setPageValue('');
    }
    if (
      !query &&
      uploadDataList &&
      Number(pageValue) <= Math.ceil(uploadDataList.count / 10) &&
      Number(pageValue) !== 0
    ) {
      setPage(Number(pageValue));
      setPageValue('');
    }
  };

  // useEffect(() => {
  //   if (query) {
  //     handleSearch();
  //     // console.log('query', query);
  //   }
  // }, [isUpdated, page, rowsPerPage]);

  const showTip = (value) => {
    if (value.length > 10) {
      return (
        <Tip title={value} followCursor placement="top">
          <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>{` ${value.slice(
            0,
            10
          )}.....`}</Typography>
          {/* <span style={{ fontSize: 12 }}>{value.slice(0, 10)}.....</span> */}
        </Tip>
      );
    }
    return <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>{value}</Typography>;
  };

  // tabs
  const handleChange = (event, newValue) => {
    setQuery('');
    setKeyword('');
    setValue(newValue);
    setPage(1);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const customStyles = {
    option: (base, state) => ({
      ...base,
      fontWeight: state.isSelected ? '350' : 'normal',
      maxHeight: 35
    }),
    control: (base, state) => ({
      ...base,
      fontWeight: '500',
      height: '55px',
      // padding: '6px',
      border: '1px solid #f0f0f0'
      // maxHeight: 70
    })
  };

  const [selectlang, setselectlang] = useState({ label: 'Hindi', value: 'hi' });

  const handleLanguageChange = (e) => {
    setselectlang(e);
    setKeyword('');
  };

  console.log('selectlang', selectlang);

  const lang_list = [
    { label: 'Hindi', value: 'hi' },
    { label: 'Marathi', value: 'mr' },
    { label: 'Gujarati', value: 'gu' },
    { label: 'Punjabi', value: 'pa' },
    { label: 'Sanskrit', value: 'sa' }
  ];

  return (
    <div>
      <Tabs
        style={{ padding: 2, marginBottom: '8px' }}
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        className={classes.root}
      >
        <Tab
          label={`All Calls  ${
            filter === true
              ? `(${uploadDataList.count})`
              : uploadDataCount
              ? `(${uploadDataCount.all_count})`
              : ''
          }`}
          {...a11yProps(0)}
          className={classes.tab}
        />
        {filter === false && (
          <Tab
            label={`Audited With Comment  ${
              uploadDataCount ? `(${uploadDataCount.audited_with_comment})` : ''
            }`}
            {...a11yProps(1)}
            className={classes.tab}
            disabled={!userDetails?.Submenus.includes('Script')}
          />
        )}
        {filter === false && (
          <Tab
            label={`Audited Without Comment  ${
              uploadDataCount ? `(${uploadDataCount.audited_without_comment})` : ''
            }`}
            {...a11yProps(2)}
            className={classes.tab}
            disabled={!userDetails?.Submenus.includes('Script')}
          />
        )}
      </Tabs>
      <Card sx={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <span>
            {(!keyword || keyword === '') && !filter ? (
              <UserListToolbar
                filterName={query}
                clearFilterName={() => setQuery('')}
                placeholder="Search"
                onFilterName={(e) => {
                  setQuery(e.target.value);
                  if (e.target.value?.length > 0 || query.length > 0) {
                    setPage(1);
                  }
                }}
              />
            ) : (
              <div style={{ width: 5, margin: 5 }} />
            )}
          </span>

          {!filter && (
            <span style={{ display: 'flex', alignItems: 'center', padding: 2 }}>
              <Select
                styles={customStyles}
                onChange={(e) => handleLanguageChange(e)}
                options={
                  lang_list
                    ? [
                        ...lang_list.map((e) => ({
                          value: e.value,
                          label: e.label
                        }))
                      ]
                    : [{ value: 'all', label: 'All' }]
                }
                value={selectlang}
                isSearchable={false}
                style={{ color: '#000' }}
                placeholder="Select Language"
              />
              <div
                style={{
                  backgroundColor: 'rgb(255, 255, 255)',
                  border: '1px solid #f0f0f0',
                  borderRadius: '5px',
                  width: '400px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '11px',
                  marginLeft: '10px'
                }}
              >
                <ReactTransliterate
                  value={keyword}
                  onChangeText={(text) => {
                    setKeyword(text);
                  }}
                  lang={`${selectlang.value}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 2,
                    border: 'none',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    outline: 'none'
                  }}
                  placeholder="Search by keyword..."
                />
                <Button
                  variant="contained"
                  startIcon={<SearchOutlinedIcon />}
                  disabled={!keyword || keyword === '' || query?.length > 0}
                  onClick={() => {
                    setPage(1);
                    handleKeywordSearch();
                  }}
                  size="small"
                  sx={{
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                >
                  Search
                </Button>
              </div>
              <IconButton
                title="Download"
                disabled={!uploadSearch || uploadSearch?.results?.file_path === '' || loading}
                onClick={() =>
                  saveAs(
                    `${process.env.REACT_APP_SERVER_MEDIA_URL}${uploadSearch?.results?.file_path}`,
                    'Call_List.xlsx'
                  )
                }
                style={{
                  backgroundColor:
                    !uploadSearch || uploadSearch?.results?.file_path === '' || loading
                      ? 'grey'
                      : '#039452',
                  '&:hover': {
                    backgroundColor: '#3AB170'
                  },
                  marginLeft: '10px'
                }}
              >
                <Icon icon={fileDownload} color="#ffff" width={30} height={30} />
              </IconButton>
            </span>
          )}
        </div>

        <Scrollbar sx={{ cursor: 'pointer' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {UPLOAD_TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={
                        headCell.alignRight ? 'right' : (headCell.alignCenter && 'center') || 'left'
                      }
                      sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa', whiteSpace: 'nowrap' }}
                      // style={{
                      //   whiteSpace: 'nowrap',
                      //   maxWidth: '80px', // Adjust the max width as needed
                      //   overflow: 'hidden',
                      //   textOverflow: 'ellipsis' // Add ellipsis for truncation
                      // }}
                    >
                      {headCell.sort === 'date' ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          {headCell.label}
                          <div
                            style={{
                              position: 'relative',
                              width: 'fit-content'
                            }}
                          >
                            {dateSort === 'asec' ? (
                              <IconButton
                                sx={{
                                  visibility: showDateButton || sortForDate ? 'visible' : 'hidden'
                                }}
                                onClick={() => {
                                  setPage(1);
                                  setSortForDate(true);
                                  setSortForDuration(false);
                                  setSortForProcessDate(false);
                                  setDateSort('desc');
                                }}
                              >
                                <ArrowUpwardIcon sx={{ width: 15, height: 15 }} />
                              </IconButton>
                            ) : dateSort === 'desc' ? (
                              <IconButton
                                sx={{
                                  visibility: showDateButton || sortForDate ? 'visible' : 'hidden'
                                }}
                                onClick={() => {
                                  setPage(1);
                                  setSortForDate(true);
                                  setSortForDuration(false);
                                  setSortForProcessDate(false);
                                  setDateSort('asec');
                                }}
                              >
                                <ArrowDownwardIcon sx={{ width: 15, height: 15 }} />
                              </IconButton>
                            ) : null}
                          </div>
                        </div>
                      ) : headCell.sort === 'duration' ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          onMouseEnter={handleMouseDurationEnter}
                          onMouseLeave={handleMouseDurationLeave}
                        >
                          {headCell.label}
                          <div
                            style={{
                              position: 'relative',
                              width: 'fit-content'
                            }}
                          >
                            {durationSort === 'asec' ? (
                              <IconButton
                                sx={{
                                  visibility:
                                    showDurationButton || sortForDuration ? 'visible' : 'hidden'
                                }}
                                onClick={() => {
                                  setPage(1);
                                  setSortForDuration(true);
                                  setSortForDate(false);
                                  setSortForProcessDate(false);
                                  setDurationSort('desc');
                                }}
                              >
                                <ArrowDownwardIcon sx={{ width: 15, height: 15 }} />
                              </IconButton>
                            ) : durationSort === 'desc' ? (
                              <IconButton
                                sx={{
                                  visibility:
                                    showDurationButton || sortForDuration ? 'visible' : 'hidden'
                                }}
                                onClick={() => {
                                  setPage(1);
                                  setSortForDuration(true);
                                  setSortForDate(false);
                                  setSortForProcessDate(false);
                                  setDurationSort('asec');
                                }}
                                // title="Sort in ascending order"
                              >
                                <ArrowUpwardIcon sx={{ width: 15, height: 15 }} />
                              </IconButton>
                            ) : null}
                          </div>
                        </div>
                      ) : headCell.sort === 'process_date' ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          onMouseEnter={handleMouseProcessEnter}
                          onMouseLeave={handleMouseProcessLeave}
                        >
                          {headCell.label}
                          <div
                            style={{
                              position: 'relative',
                              width: 'fit-content'
                            }}
                          >
                            {processSort === 'asec' ? (
                              <IconButton
                                sx={{
                                  visibility:
                                    showProcessDateButton || sortForProcessDate
                                      ? 'visible'
                                      : 'hidden'
                                }}
                                onClick={() => {
                                  setPage(1);
                                  setSortForProcessDate(true);
                                  setSortForDate(false);
                                  setSortForDuration(false);
                                  setProcessDateSort('desc');
                                }}
                              >
                                <ArrowUpwardIcon sx={{ width: 15, height: 15 }} />
                              </IconButton>
                            ) : processSort === 'desc' ? (
                              <IconButton
                                sx={{
                                  visibility:
                                    showProcessDateButton || sortForProcessDate
                                      ? 'visible'
                                      : 'hidden'
                                }}
                                onClick={() => {
                                  setPage(1);
                                  setSortForProcessDate(true);
                                  setSortForDate(false);
                                  setSortForDuration(false);
                                  setProcessDateSort('asec');
                                }}
                              >
                                <ArrowDownwardIcon sx={{ width: 15, height: 15 }} />
                              </IconButton>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {((query || keyword) &&
                !loading &&
                uploadSearch &&
                uploadSearch.results?.data?.length > 0 && (
                  <TableBody>
                    {uploadSearch.results.data
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          input_id,
                          customer_organisation_name,
                          first_name,
                          last_name,
                          agent_id,
                          processes,
                          status,
                          request_id,
                          organisation_name,
                          input_file,
                          created_date,
                          modified_date,
                          original_date,
                          reason,
                          campaign_name,
                          campaign_id,
                          kpi_services,
                          dashboard_services,
                          processed_date,
                          s3url,
                          duration,
                          language_detection
                        } = row;
                        // console.warn('requset id --', request_id);
                        return (
                          <>
                            <TableRow hover key={1} tabIndex={-1} role="checkbox">
                              <TableCell align="center">{request_id}</TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '80px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                title={organisation_name}
                              >
                                {/* <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setisCollapse(!isCollapse)}
                              sx={{ '& > *': { borderBottom: 'unset' } }}
                            >
                              {isCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton> */}
                                {organisation_name}
                              </TableCell>
                              {/* <TableCell>{created_date ? changedDate(created_date) : null}</TableCell> */}
                              <TableCell
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '120px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                align="center"
                                title={changedDate(original_date)}
                              >
                                {original_date ? changedDate(original_date) : null}
                              </TableCell>
                              <TableCell
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '120px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                align="center"
                                title={changedDate(processed_date)}
                              >
                                {processed_date ? changedDate(processed_date) : null}
                              </TableCell>
                              <TableCell
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '120px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                align="center"
                                title={getAudioFileName(s3url)}
                              >
                                {getAudioFileName(s3url)}
                              </TableCell>
                              <TableCell
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '120px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                align="center"
                                title={duration}
                              >
                                {duration ? duration : '-'}
                              </TableCell>

                              <TableCell
                                component="th"
                                scope="row"
                                padding="normal"
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '80px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                align="center"
                                title={customer_organisation_name}
                              >
                                {customer_organisation_name}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="normal"
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '80px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                align="center"
                                title={`${first_name} ${last_name}`}
                              >
                                {first_name} {last_name}
                              </TableCell>
                              <TableCell align="center">
                                {processes.map((element) => (
                                  <span>{element} </span>
                                ))}
                              </TableCell>
                              <TableCell
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '120px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                align="center"
                                title={language_detection}
                              >
                                {language_detection ? language_detection : '-'}
                              </TableCell>
                              <TableCell align="center">{getStatus(status, reason)}</TableCell>

                              {userDetails.role !== 'Manager' && (
                                <TableCell align="center">
                                  <UserMoreMenu
                                    formik={formik}
                                    isUpdated={isUpdated}
                                    updateComponent={updateComponent}
                                    setAlert={setAlert}
                                    setAlertContent={setAlertContent}
                                    setAlertType={setAlertType}
                                    inputId={input_id}
                                    inputDetails={row}
                                    page={page}
                                    value={value}
                                    updatedDateFormat={updatedDateFormat}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    query={query}
                                    keyword={keyword}
                                    highlightWords={highlightWords}
                                    sortForDate={sortForDate}
                                    dateSort={dateSort}
                                    sortForDuration={sortForDuration}
                                    durationSort={durationSort}
                                    storedDataForFilter={storedDataForFilter}
                                    uploadDataList={uploadDataList}
                                    uploadDataCount={uploadDataCount}
                                  />
                                </TableCell>
                              )}
                            </TableRow>
                            {/* <TableRow>
                          <TableCell colSpan={12}>
                            <Collapse in={isCollapse} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="purchases">
                                  <UserListHead headLabel={UPLOADFILE_TABLE_HEAD} />
                                  <TableBody>
                                    <TableRow>
                                      <TableCell align="left">File Name</TableCell>
                                      <TableCell />
                                      <TableCell />
                                      <TableCell />
                                      <TableCell align="left">Status</TableCell>
                                      {userDetails.role !== 'Manager' && (
                                        <TableCell align="right">
                                          <UserMoreMenu
                                            formik={formik}
                                            isUpdated={isUpdated}
                                            updateComponent={updateComponent}
                                            setAlert={setAlert}
                                            setAlertContent={setAlertContent}
                                            setAlertType={setAlertType}
                                            inputId={input_id}
                                            inputDetails={row}
                                          />
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow> */}
                          </>
                        );
                      })}
                  </TableBody>
                  // <TableBody>
                  //   <TableRow>
                  //     <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                  //       <CircularProgress />
                  //     </TableCell>
                  //   </TableRow>
                  // </TableBody>
                )) ||
                // (!loading && query && !uploadSearch && (
                //   <TableBody sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                //     <TableRow>Data not found</TableRow>
                //   </TableBody>
                // )) ||
                (!loading && uploadDataList && uploadDataList.results.length > 0 ? (
                  <TableBody>
                    {uploadDataList.results
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          input_id,
                          customer_organisation_name,
                          agent_id,
                          first_name,
                          last_name,
                          processes,
                          status,
                          request_id,
                          organisation_name,
                          input_file,
                          created_date,
                          original_date,
                          modified_date,
                          reason,
                          campaign_id,
                          campaign_name,
                          kpi_services,
                          dashboard_services,
                          processed_date,
                          s3url,
                          duration,
                          language_detection
                        } = row;
                        // console.warn('requset id --', request_id);
                        return (
                          <>
                            <TableRow hover key={1} tabIndex={-1} role="checkbox">
                              <TableCell align="center">{request_id}</TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '80px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                title={organisation_name}
                              >
                                {/* <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setisCollapse(!isCollapse)}
                              sx={{ '& > *': { borderBottom: 'unset' } }}
                            >
                              {isCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton> */}
                                {organisation_name}
                              </TableCell>
                              {/* <TableCell>{created_date ? changedDate(created_date) : null}</TableCell> */}
                              <TableCell
                                component="th"
                                align="center"
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '80px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                title={changedDate(original_date)}
                              >
                                {original_date ? changedDate(original_date) : null}
                              </TableCell>
                              <TableCell
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '120px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                align="center"
                                title={changedDate(processed_date)}
                              >
                                {processed_date ? changedDate(processed_date) : null}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '120px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                title={getAudioFileName(s3url)}
                              >
                                {getAudioFileName(s3url)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '120px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                title={duration}
                              >
                                {duration ? duration : '-'}
                              </TableCell>

                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                                padding="normal"
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '80px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                title={customer_organisation_name}
                              >
                                {customer_organisation_name}
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                                padding="normal"
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '80px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                title={`${first_name} ${last_name}`}
                              >
                                {first_name} {last_name}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '80px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                title={processes[0]}
                              >
                                {processes.map((element) => (
                                  <span>{element} </span>
                                ))}
                              </TableCell>
                              <TableCell
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '120px', // Adjust the max width as needed
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                }}
                                align="center"
                                title={language_detection}
                              >
                                {language_detection ? language_detection : '-'}
                              </TableCell>
                              <TableCell align="center">{getStatus(status, reason)}</TableCell>

                              {userDetails.role !== 'Manager' && (
                                <TableCell align="center">
                                  <UserMoreMenu
                                    formik={formik}
                                    isUpdated={isUpdated}
                                    updateComponent={updateComponent}
                                    setAlert={setAlert}
                                    setAlertContent={setAlertContent}
                                    setAlertType={setAlertType}
                                    inputId={input_id}
                                    inputDetails={row}
                                    page={page}
                                    value={value}
                                    updatedDateFormat={updatedDateFormat}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    query={query}
                                    keyword={keyword}
                                    highlightWords={highlightWords}
                                    sortForDate={sortForDate}
                                    dateSort={dateSort}
                                    sortForDuration={sortForDuration}
                                    durationSort={durationSort}
                                    storedDataForFilter={storedDataForFilter}
                                    uploadDataList={uploadDataList}
                                    uploadDataCount={uploadDataCount}
                                  />
                                </TableCell>
                              )}
                            </TableRow>
                            {/* <TableRow>
                          <TableCell colSpan={12}>
                            <Collapse in={isCollapse} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="purchases">
                                  <UserListHead headLabel={UPLOADFILE_TABLE_HEAD} />
                                  <TableBody>
                                    <TableRow>
                                      <TableCell align="left">File Name</TableCell>
                                      <TableCell />
                                      <TableCell />
                                      <TableCell />
                                      <TableCell align="left">Status</TableCell>
                                      {userDetails.role !== 'Manager' && (
                                        <TableCell align="right">
                                          <UserMoreMenu
                                            formik={formik}
                                            isUpdated={isUpdated}
                                            updateComponent={updateComponent}
                                            setAlert={setAlert}
                                            setAlertContent={setAlertContent}
                                            setAlertType={setAlertType}
                                            inputId={input_id}
                                            inputDetails={row}
                                          />
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow> */}
                          </>
                        );
                      })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      {loading ? (
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <CircularProgress sx={{ color: '#3892CF' }} />
                        </TableCell>
                      ) : (
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          Data Not Found
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                ))}
            </Table>
          </TableContainer>
        </Scrollbar>

        <Stack
          spacing={2}
          sx={{
            padding: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TextField
              type="number"
              placeholder="Search Page"
              size="small"
              color="info"
              value={pageValue}
              onChange={(e) => setPageValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleKeyPress();
                }
              }}
              sx={{
                width: 120
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={handleKeyPress}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'lightblue'
                        },
                        borderRadius: '10px'
                      }}
                    >
                      <SearchOutlinedIcon style={{ color: '#3892CF' }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              variant="contained"
              startIcon={<ArrowBackOutlined />}
              sx={{
                marginLeft: 5,
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
              disabled={batch === 1}
              size="small"
              onClick={() => {
                if (batch !== 1) {
                  setBatch(batch - 1);
                }
              }}
            >
              Previous Batch
            </Button>
            <Pagination
              classes={{
                root: classes.ul
              }}
              count={
                (query || keyword) && uploadSearch
                  ? Math.ceil(uploadSearch.count / 10)
                  : Math.ceil(uploadDataList?.count / 10)
              }
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
            <Button
              variant="contained"
              endIcon={<ArrowForwardSharp />}
              sx={{
                marginLeft: 1,
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
              disabled={uploadDataList || uploadSearch?.results?.data?.length === 0}
              size="small"
              onClick={() => {
                setBatch(batch + 1);
                setPage(1);
              }}
            >
              Next Batch
            </Button>
          </div>
        </Stack>
        {/* {!loading && uploadSearch && uploadDataList ? (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          rowsPerPage={rowsPerPage}
          count={query ? uploadSearch.max_record_count : uploadDataList.count}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          rowsPerPage={rowsPerPage}
          count={uploadDataList?.results ? uploadDataList?.count : 0}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )} */}
      </Card>
    </div>
  );
};

function UserMoreMenu({
  formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  inputId,
  inputDetails,
  page,
  updatedDateFormat,
  value,
  fromDate,
  toDate,
  query,
  keyword,
  highlightWords,
  sortForDate,
  dateSort,
  sortForDuration,
  durationSort,
  storedDataForFilter,
  uploadDataCount,
  uploadDataList
}) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(null);
  const navigate = useNavigate();

  const {
    setinputId,
    setinputOrgId,
    setrequestId,
    setUploadedAudio,
    setuploadStatus,
    setAudioS3,
    uploadStatus,
    setProcess,
    setcustomerId,
    setOriginalDate,
    setAgentId,
    setAgentName,
    setAgentLastName,
    kpi,
    setKpi
  } = useContext(inputContex);

  // useEffect(() => {
  //   if (message) {
  //     setuploadStatus(message);
  //     console.warn('message ----->', message);
  //   } else {
  //     console.log('No Response From Server');
  //   }
  // }, [message]);
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const handleDialog = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };

  const handleDeleteInput = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.delete(`/secure/deactivate_input?id=${inputId}`, config)
      .then((res) => {
        setAlertContent(`Success : Upload record deleted successfully.`);
        setAlertType('success');
        setAlert(true);
      })
      .then(() => {
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  const handleRunProcess = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/terminate_process?request_id=${inputDetails.request_id}`, config)
      .then((res) => {
        setAlertContent(`${res.data.message}`);
        setAlertType('success');
        setAlert(true);
      })
      .then(() => {
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  const checkStatus = () => {
    if (uploadStatus) {
      if (
        uploadStatus.message.request_id === inputDetails.request_id &&
        uploadStatus.message.status !== 'Process Completed'
      ) {
        console.log('inside checkstatus if');
        return true;
      }
      return false;
    }
    return inputDetails.status !== 'Process Completed';
  };

  const changedDate = (date) => {
    const newdate = new Date(date);
    const formatStartDate = `${newdate.getDate()}-${
      newdate.getMonth() + 1
    }-${newdate.getFullYear()}`;
    return formatStartDate;
  };
  const formatDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = `${inputDate.getFullYear()}-${(inputDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${inputDate.getDate().toString().padStart(2, '0')}`;

    return formattedDate;
  };

  const currentDate = new Date(inputDetails.original_date);
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
  const currentDay = currentDate.getDate();
  const updatedCurrentDate = `${currentYear}-${currentMonth
    .toString()
    .padStart(2, '0')}-${currentDay.toString().padStart(2, '0')}`;
  // Add one day to the current date
  currentDate.setDate(currentDate.getDate() + 1);

  // Extract the year, month, and day components of the updated date
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
  const day = currentDate.getDate();

  // Format the updated date as a string in the desired format (YYYY-MM-DD)
  const updatedDate = `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`;

  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={openEdit}
        title="Update Uploaded Data"
      >
        <UploadEditForm
          formik={formik}
          isUpdated={isUpdated}
          updateComponent={updateComponent}
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          inputId={inputId}
          inputDetails={inputDetails}
          handleDialog={handleDialog}
        />
      </CustomFormModel>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          sx={{ color: 'text.secondary' }}
          disabled={inputDetails.status !== 'Process Completed'}
          // disabled={checkStatus()}
          onClick={() => {
            // if (inputDetails.dashboard_services.includes('collections')) {
            navigate('/dashboard/workspace/Upload/Results', {
              state: {
                path: '/dashboard/workspace/Upload',
                pageNo: page,
                flag: value,
                format: updatedDateFormat,
                startDate: formatDate(fromDate),
                endDate: formatDate(toDate),
                query: query,
                keyword: keyword,
                highlightWords: highlightWords,
                sortForDate: sortForDate,
                dateSort: dateSort,
                sortForDuration: sortForDuration,
                durationSort: durationSort,
                filterValues: storedDataForFilter,
                uploadDataList: uploadDataList,
                uploadDataCount: uploadDataCount
              }
            });
            // localStorage.setItem(
            //   'storedInfo',
            //   JSON.stringify({
            //     pageNo: page,
            //     flag: value,
            //     format: updatedDateFormat,
            //     startDate: fromDate,
            //     endDate: toDate
            //   })
            // );
            setinputId(inputId);
            setinputOrgId(inputDetails.organisation_id);
            setrequestId(inputDetails.request_id);
            setUploadedAudio('');
            setAudioS3(inputDetails.s3url);
            setProcess(inputDetails.processes[0]);
            setcustomerId(inputDetails.customer_id);
            setOriginalDate(changedDate(inputDetails.original_date));
            setKpi(inputDetails.kpi_services);
            setAgentName(inputDetails.first_name);
            setAgentLastName(inputDetails.last_name);
            setAgentId(inputDetails.agent_id);
            // } else if (inputDetails.dashboard_services.includes('sales')) {
            //   localStorage.removeItem('productInfo');
            //   localStorage.removeItem('audioList');
            //   localStorage.removeItem('agentTaskList');
            //   localStorage.removeItem('dealStatus');

            //   navigate('/dashboard/sales', {
            //     replace: true,
            //     state: {
            //       customer_id:
            //         inputDetails && inputDetails.customer_id ? inputDetails.customer_id : null,
            //       organisation_id:
            //         inputDetails && inputDetails.organisation_id
            //           ? inputDetails.organisation_id
            //           : null,
            //       campaign_id:
            //         inputDetails && inputDetails.campaign_id ? inputDetails.campaign_id : null,
            //       format: 'customized',
            //       start_date:
            //         inputDetails && inputDetails.original_date ? updatedCurrentDate : null,
            //       end_date: inputDetails && inputDetails.original_date ? updatedDate : null
            //       // start_date:''
            //     }
            //   });
            // } else if (inputDetails.dashboard_services.includes('compliance')) {
            //   localStorage.removeItem('storedFormik');
            //   localStorage.removeItem('storedOpportunityInfo');
            //   localStorage.removeItem('storedCountInfo');
            //   localStorage.removeItem('storedScoreCardInfo');
            //   localStorage.removeItem('storedGraphInfo');

            //   navigate('/dashboard/app', {
            //     replace: true,
            //     state: {
            //       // customer_id:
            //       //   inputDetails && inputDetails.customer_id ? inputDetails.customer_id : null,
            //       organisation:
            //         inputDetails && inputDetails.organisation_id
            //           ? inputDetails.organisation_id
            //           : null,
            //       customer:
            //         inputDetails && inputDetails.customer_id ? inputDetails.customer_id : null,
            //       dateFormat: 'customized',
            //       startDate: inputDetails && inputDetails.original_date ? updatedCurrentDate : null,
            //       endDate: inputDetails && inputDetails.original_date ? updatedDate : null,
            //       process: inputDetails && inputDetails.processes ? inputDetails.processes : null,
            //       agent: inputDetails && inputDetails.agent_id ? inputDetails.agent_id : null
            //       // processes: inputDetails && inputDetails.processes ? inputDetails.processes : null,
            //       // agent_id: inputDetails && inputDetails.agent_id ? inputDetails.agent_id : null
            //       // state: {
            //       //   organisation: org_id,
            //       //   dateFormat: dateFormat,
            //       //   startDate: startDate,
            //       //   endDate: endDate
            //       // }
            //     }
            //   });
            // }
          }}
        >
          <ListItemIcon>
            <Icon icon={eyeIcon} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDeleteInput}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {userDetails.role !== 'Guest' && (
          <MenuItem
            to="#"
            sx={{ color: 'text.secondary' }}
            disabled={inputDetails.status !== 'Process Completed'}
            onClick={handleDialog}
          >
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {/* <MenuItem
          sx={{ color: 'text.secondary' }}
          disabled={inputDetails.status === 'Process Completed'}
          // disabled={!checkStatus()}
          onClick={handleRunProcess}
        >
      <ListItemIcon>
            <Icon icon={cogPlay} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Stop Process" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          sx={{ color: 'text.secondary' }}
          // disabled={inputDetails.status !== 'Process Completed'}
          disabled
          onClick={() => {
            navigate('/dashboard/workspace/Upload/Action', { replace: true });
            setinputId(inputId);
          }}
        >
          <ListItemIcon>
            <Icon icon={cogs} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Action" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
      </Menu>
    </>
  );
}

export default function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}
