/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';

import WaveSurfer from 'wavesurfer.js';
import Play from '@mui/icons-material/PlayCircle';
import Pause from '@mui/icons-material/PauseCircleFilled';
import { Tooltip, Button, CircularProgress } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: '#cce6ff',
  progressColor: '#3399ff',
  cursorColor: '#3399ff',
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 200,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true
});

export default function Waveform({ url, dialog }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [progress, setProgress] = useState();
  const [volume, setVolume] = useState(0.5);
  const [error, setError] = useState('');

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);
    wavesurfer.current.load(url);
    // if (wavesurfer.current) {
    //   wavesurfer.current.on('error', handleError);
    // }
    wavesurfer.current.on('ready', function () {
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
      }
    });
    wavesurfer.current.on('finish', () => {
      setPlay(false);
    });
    wavesurfer.current.on('loading', (percentage) => {
      setProgress(percentage);
    });

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => {
      wavesurfer.current.destroy();
    };
  }, [url]);

  const handleError = (error) => {
    console.error('Error loading audio:', error);
    setError(`Error loading audio:${error}`);
    // Handle the error state here, such as displaying an error message
  };

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  const onVolumeChange = (e) => {
    const { target } = e;
    const newVolume = +target.value;

    if (newVolume) {
      setVolume(newVolume);
      wavesurfer.current.setVolume(newVolume || 1);
    }
  };

  return (
    <div>
      {!error ? (
        <div
          id="waveform"
          ref={waveformRef}
          style={{ width: 400, height: 200, maxWidth: 400, overflowX: 'auto' }}
        />
      ) : (
        <div>{error}</div>
      )}
      <div
        className="controls"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        {/* <button onClick={handlePlayPause}>{!playing ? 'Play' : 'Pause'}</button> */}
        <Button
          disabled={progress !== 100}
          onClick={handlePlayPause}
          style={{ marginTop: 7, color: '#3399ff' }}
        >
          {playing ? <Pause /> : <Play size="large" />}
        </Button>
        <Tooltip title="Transcript">
          {dialog && (
            <MenuBookIcon
              variant="contained"
              onClick={dialog}
              sx={{ cursor: 'pointer', marginTop: '5px', marginLeft: 4, color: '#3399ff' }}
            />
          )}
        </Tooltip>
      </div>
    </div>
  );
}
