import React, { useState, useEffect } from 'react';

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import InputState from './Context/UploadContext/InputState';

// ----------------------------------------------------------------------

export default function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setUser(user);
  }, []);

  return (
    // <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
    //   <JoyCssVarsProvider>
    //     <CssBaseline enableColorScheme />
    <InputState>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router />
      </ThemeConfig>
    </InputState>
    //   </JoyCssVarsProvider>
    // </MaterialCssVarsProvider>
  );
}
