/* eslint-disable arrow-body-style */
import React, { useMemo } from 'react';
import ReactWordcloud from 'react-wordcloud';

function Wordcloud(cloudData) {
  // console.log(cloudData.data);

  // const options = {
  //   colors: ['#3399ff'],
  //   enableTooltip: false,
  //   // deterministic: false,
  //   fontFamily: 'impact',
  //   fontSizes: [20, 30]
  //   // fontStyle: 'normal',
  //   // fontWeight: 'normal',
  //   // padding: 1
  //   // rotations: 3,
  //   // rotationAngles: [0, 90],
  //   // scale: 'sqrt'
  //   // spiral: 'archimedean',
  //   // transitionDuration: 1000
  // };
  const options = {
    // colors: ['#0071c1'],
    deterministic: false,
    enableOptimizations: false,
    enableTooltip: false,
    fontSizes: [20, 50],
    fontWeight: 'normal',
    fontFamily: 'impact',
    rotations: 0
    // enableOptimizations: true
  };

  const callbacks = {
    // getWordColor: (word) => (word.value > 50 ? 'blue' : 'red'),
    onWordClick: (e) => console.log(e, 'word')
    // onWordMouseOver: console.log,
    // getWordTooltip: (word) => `${word.text} (${word.value}) [${word.value > 50 ? 'good' : 'bad'}]`
  };

  // const newData = cloudData.data.map((item) => ({
  //   text: item.Text,
  //   value: 50
  // }));
  // console.log('new data ---', newData);
  return (
    <ReactWordcloud callbacks={callbacks} maxWords={40} options={options} words={cloudData.data} />
  );
}

export default Wordcloud;
