import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
// import { fShortenNumber } from '../../../utils/formatNumber';
// components
import numeral from 'numeral';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(3),
  height: theme.spacing(3),
  justifyContent: 'center',
  marginBottom: theme.spacing(3)
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object
};

export default function AppWidgetSummary({
  handleUnpleasant,
  title,
  total,
  icon,
  color,
  bgColor,
  cardWidth,
  sx,
  ...other
}) {
  function fShortenNumber(number) {
    const format = number ? numeral(number).format('0.00a') : '';

    return result(format, '.00');
  }
  function result(format, key = '.00') {
    const isInteger = format.includes(key);

    return isInteger ? format.replace(key, '') : format;
  }

  return (
    <Card
      sx={{
        height: 170,
        width: cardWidth,
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: bgColor,
        ...sx
      }}
      {...other}
      onClick={handleUnpleasant}
    >
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
          marginBottom: '5px'
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
      </StyledIcon>

      <Typography variant="h3" sx={{ color: '#1F2937' }}>
        {total}
      </Typography>

      <Typography variant="subtitle2" sx={{ color: '#1F2937', opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
}
