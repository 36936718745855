import * as React from 'react';
import { Chip, Stack } from '@mui/material';
import Page from '../../../components/Page';

export default function Phrases(data) {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  const phrase_data = data.data;
  //   console.log('keyword data -----', phrase_data);
  // console.log('key ----', Object.keys(phrase_data));

  return (
    <Page>
      {phrase_data && Object.keys(phrase_data)?.length > 0 ? (
        Object.keys(phrase_data).map((key, index) => (
          <Chip
            key={index}
            title={phrase_data[key]}
            label={phrase_data[key]}
            variant="filled"
            style={{
              backgroundColor: phrase_data[key] === 0 ? '#ff6666' : '#023972',
              color: phrase_data[key] === 0 ? 'Primary' : '#ffff',
              margin: '5px',
              width: 200
            }}
            size="medium"
          />
        ))
      ) : (
        <div
          style={{
            width: '90%',
            height: 200,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          No Phrases Found
        </div>
      )}
    </Page>
  );
}
