import React, { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
// material
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import API from '../E2E/axios.util';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import DealForm from '../layouts/Deal/DealForm';
import { DealDataModel } from '../layouts/Deal/DealDataModel';
import { Notification } from '../components/Notification/Notification';
import useWebSocket from '../hooks/useWebSocket';
import inputContex from '../Context/UploadContext/inputContext';

// ----------------------------------------------------------------------

export default function UploadContent() {
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [open, setOpen] = useState();
  const [update, setUpdate] = useState(0);
  const [uploaded, setUploaded] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // Context API
  const { uploadStatus, setuploadStatus } = useContext(inputContex);
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem('user'));

  const UploadSchemma = Yup.object().shape({
    // uploadFile: Yup.object().required('Upload Source File.'),
    customer: Yup.string().required('This field is required.'),
    organisationId: Yup.string().required('This field is required.'),
    agent: Yup.string().required('This field is required.'),
    dealName: Yup.string()
      .max(30, 'Deal name should be 2 to 30 character long.')
      .required('This field is required.'),
    dealStage: Yup.string().required('This field is required.'),
    dealStatus: Yup.string().required('This field is required.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // uploadFile: [],
      customer: userDetails.role === 'QA' ? userDetails.customer_id : '',
      organisationId: `${userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : ''}`,
      domainId: '',
      agent: '',
      dealName: '',
      dealStage: '',
      dealStatus: '',
      dealReason: ''
    },
    validationSchema: UploadSchemma,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        onUploadProgress: (data) => {
          setUploaded(Math.round((data.loaded / data.total) * 100));
        }
      };
      const data = {
        // inputFile: formik.values.uploadFile,
        customer_id: formik.values.customer,
        user_id: formik.values.agent,
        processes: formik.values.process,
        organisation_id: formik.values.organisationId,
        deal_name: formik.values.dealName,
        deal_stage: formik.values.dealStage,
        deal_status: formik.values.dealStatus,
        reason: formik.values.dealReason
        // domain_id: formik.values.domainId,
        // original_date: formik.values.createDate
      };
      console.log('data ---------------------', data);
      await API.post('/secure/register_deal', data, config)
        .then((res) => {
          formik.resetForm();
          // handleRunProcess(res.data.id);
          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          navigate('/dashboard/admin/deal', {
            replace: true
          });
          handleDialog();

          setUpdate(update + 1);
        })
        .catch((err) => {
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });

  const handleAlert = () => {
    setAlert(false);
  };

  const handleDialog = () => {
    setOpen(!open);
    formik.resetForm();
  };

  return (
    <Page title="Deal Track">
      <>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Deal Track
          </Typography>
          {/* {userDetails.role !== 'Manager' && (
            <Button variant="contained" startIcon={<Icon icon={plusFill} />} onClick={handleDialog}>
              create Deal
            </Button>
          )} */}
        </Stack>
        <Container>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleDialog}
            open={open}
            title="Deal Content"
          >
            <DealForm handleDialog={handleDialog} formik={formik} uploaded={uploaded} />
          </CustomFormModel>
        </Container>
        <DealDataModel
          handleDialog={handleDialog}
          formik={formik}
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          isUpdated={update}
          updateComponent={setUpdate}
        />
      </>
      <Notification
        message={alertContent || 'Deal list fetch successfully.'}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}
