import { React, useState } from 'react';
import './tableComponent.css';
import {
  Grid,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import Scrollbar from '../Scrollbar';

function TableComponent({ TableinputData, tablecolData }) {
  const columnHeadings = {
    agent_name: 'Agent Name',
    qa_name: 'QA',
    tl_name: 'Team Lead',
    ai_count: 'AI Count',
    high_tone: 'High Tone',
    medium_tone: 'Medium Tone',
    low_tone: 'Low Tone',
    // emp_code: 'Emp Tone',
    average_ai_score: 'Average AI Score',
    manual_count: 'Manual Count',
    average_manual_score: 'Average Manual Score',
    category: 'Category',
    ai_audited_count: 'AI Audit Count',
    ai_score: ' AI Audit Score',
    manual_audited_count: 'Manual Audit Count',
    manual_score: 'Manual Audit Score',
    agent_positive: 'Agent Positive',
    customer_positive: 'Customer Positive'
  };

  const getProgressBarClass = (value) => {
    if (value >= 75) {
      return 'progress-bar-fill-high';
    }
    if (value >= 50) {
      return 'progress-bar-fill-medium';
    }
    return 'progress-bar-fill-low';
  };

  return (
    <Grid item xs={12} md={12}>
      <Card>
        {TableinputData && TableinputData.length > 0 ? (
          <Scrollbar>
            <TableContainer
              sx={{ width: '100%', maxHeight: 450, overflowY: 'auto', overFlowX: 'off' }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tablecolData.map((colName, index) => (
                      <TableCell
                        key={index}
                        align="center"
                        sx={{
                          color: '#1F2937',
                          fontWeight: 'bold',
                          fontFamily: 'Public Sans, sans-serif',
                          backgroundColor: '#f8f6fa'
                        }}
                      >
                        {columnHeadings[colName] || colName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TableinputData.map((item, index) => (
                    <TableRow key={index} className="tablecontent">
                      {tablecolData.map((colName, colIndex) => (
                        <TableCell key={colIndex} align="center">
                          {colName.includes('score') ? (
                            <div className="progress-bar">
                              <div
                                className={`progress-bar-fill ${getProgressBarClass(
                                  item[colName]
                                )}`}
                                style={{ width: `${item[colName]}%` }}
                              >
                                {item[colName].toFixed(2)}%
                              </div>
                            </div>
                          ) : (
                            item[colName]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        ) : (
          <div
            style={{
              width: '100%',
              height: 300,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <span style={{ fontSize: 20 }}>
              <b>No Data Available</b>
            </span>
          </div>
        )}
      </Card>
    </Grid>
  );
}

export default TableComponent;
