/* eslint-disable prefer-arrow-callback */
/* eslint-disable react/no-this-in-sfc */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useFormik } from 'formik';
// material
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Container, Stack, Typography } from '@mui/material';
// Components
import Page from '../components/Page';
import API from '../E2E/axios.util';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import KeywordForm from '../layouts/Keywords/KeywordForm';
import KeywordDataModel from '../layouts/Keywords/KeywordDataModel';
import { Notification } from '../components/Notification/Notification';

export default function UploadKeywords() {
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [open, setOpen] = useState();
  const [update, setUpdate] = useState(0);
  const [keywordList, setKeywordList] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const userDetails = JSON.parse(localStorage.getItem('user'));

  const KeywordSchema = Yup.object().shape({
    organisation: Yup.string().required('This field is required.'),
    customerOrganisation: Yup.string().required('This field is required.'),
    // sentences: Yup.array().of(
    //   Yup.object({
    //     sentence: Yup.array()
    //       .min(1, 'This field is required.')
    //       // .max(50, 'Max 50 words should be accepted.')
    //       .of(Yup.string().required('This field is required.')),
    //     Category: Yup.string()
    //       .required('This field is required.')
    //       .min(3, 'Category must be at least 3 characters.')
    //       .max(15, 'Category must not exceed 15 characters')
    //       // .matches(
    //       //   /^[A-Z][a-zA-Z]*$/,
    //       //   'Category must start with a capital letter  and Category should not start or end with Blank space also should not contain symbols.'
    //       // )
    //       .test('unique-category', 'Category should be unique.', function (value) {
    //         const categories = formik.values.sentences.map((sentence) =>
    //           sentence.Category?.toLowerCase()
    //         );
    //         const lowercaseValue = value?.toLowerCase();

    //         return categories.filter((category) => category === lowercaseValue).length === 1;
    //       })
    //   })
    // ),
    // keywords: Yup.array().of(Yup.string().required('This field is required.'))
    process: Yup.array().of(Yup.string().required('This field is required.')),
    // process: Yup.string().required('This field is required.'),
    keywords: Yup.array().of(
      Yup.object({
        words: Yup.array()
          .min(1, 'This field is required.')
          // .max(50, 'Max 50 words should be accepted.')
          .of(
            Yup.string()
              // .matches(
              //   /^(?!^ )[A-Za-z ]+$/,
              //   'Keywords should not start with space and does not contain special character and number.'
              // )
              .required('This field is required.')
          ),
        Category: Yup.string()
          .required('This field is required.')
          .min(3, 'Category must be at least 3 characters.')
          .max(50, 'Category must not exceed 50 characters')
          .matches(
            /^[A-Z][a-zA-Z\s]*$/,
            'Category must start with a capital letter. It should not contain symbols.'
          )
          .test('unique-category', 'Category should be unique.', function (value) {
            const categories = formik.values.keywords.map((keyword) =>
              keyword.Category?.toLowerCase()
            );
            const lowercaseValue = value?.toLowerCase();

            return categories.filter((category) => category === lowercaseValue).length === 1;
          })
      })
    )
    // keywordCategory: Yup.array().of(Yup.string().required('This field is required.')),
    // sentenceCategory: Yup.array().of(Yup.string().required('This field is required.'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      organisation: `${userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : ''}`,
      customerOrganisation:
        userDetails.role === 'QA' || userDetails.role === 'Customer_Admin'
          ? userDetails.customer_id
          : '',
      process: [''],
      keywords: [
        {
          words: [''],
          Category: ''
        }
      ],
      sentences: [
        {
          sentence: [''],
          Category: ''
        }
      ]
    },
    validationSchema: KeywordSchema,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        processes: formik.values.process,
        organisation_id: formik.values.organisation,
        customer_id: formik.values.customerOrganisation,
        sentences: formik.values.sentences,
        keywords: formik.values.keywords
      };
      await API.post('/secure/add_keywords', data, config)
        .then((res) => {
          formik.resetForm();
          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          handleDialog();
          setUpdate(update + 1);
        })
        .catch((err) => {
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });

  const handleDialog = () => {
    setOpen(!open);
    formik.resetForm();
  };

  const handleAlert = () => {
    setAlert(false);
  };

  return (
    <Page title="Keywords">
      <>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            KeyWords {keywordList ? `(${keywordList.count})` : ''}
          </Typography>
          {userDetails.role !== 'Manager' && (
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={handleDialog}
              sx={{
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              Add Keywords
            </Button>
          )}
        </Stack>
        <Container>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleDialog}
            open={open}
            title="Add New Keywords"
          >
            <KeywordForm handleDialog={handleDialog} formik={formik} />
          </CustomFormModel>
        </Container>
        <KeywordDataModel
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          handleDialog={handleDialog}
          formik={formik}
          isUpdated={update}
          updateComponent={setUpdate}
          keywordList={keywordList}
          setKeywordList={setKeywordList}
        />
      </>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}
