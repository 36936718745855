import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { FormikProvider, Form, useFormik } from 'formik';
import {
  Grid,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Container,
  FormHelperText,
  Alert,
  Rating,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { LoadingButton } from '@mui/lab';
import LinearProgress, { LinearProgressProps as props } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import DatePicker from 'react-datepicker';
// import './DealForm.css';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import API from '../../E2E/axios.util';
import { Notification } from '../../components/Notification/Notification';
// components

// ----------------------------------------------------------------------

const FeedbackEditForm = ({
  handleDialog,
  //   dashboardFormik,
  isUpdated,
  updateComponent,
  inputDetails
}) => {
  console.log('inputDetails', inputDetails);
  // use formik for task .
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: userDetails.role === 'QA' ? userDetails.customer_id : inputDetails.customer_id,
      organisationId: `${
        userDetails.role !== 'Super_Admin'
          ? userDetails.organisation_id
          : inputDetails.organisation_id
      }`,
      agent: inputDetails.agent_id,
      feedback: inputDetails.feedback_desc,
      rating: inputDetails.rating
    },
    validationSchema: Yup.object().shape({
      // customer: Yup.string().required('This field is required.'),
      // organisationId: Yup.string().required('This field is required.'),
      //   agent: Yup.string().required('This field is required.'),
      feedback: Yup.string()
        .matches(
          /^\S+[A-Za-z\s]{2,80}$/,
          'Feedback should be in between 3 to 80 character and it should not start with space and does not contain special character and number.'
        )
        .max(80, 'feedback should be 3 to 80 character long.')
        .required('This field is required.')
      //   comment: Yup.string()
      //     .matches(
      //       /^\S+[A-Za-z\s]{2,50}$/,
      //       'Comment should be in between 3 to 50 character and it should not start with space and does not contain special character and number.'
      //     )
      //     .max(50, 'Comment should be 3 to 50 character long.')
      //     .required('This field is required.'),
      //   status: Yup.string().required('This field is required.')
    }),
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        // customer_id: dashboardFormik.values.customer,
        // user_id: dashboardFormik.values.campaign,
        // organisation_id: dashboardFormik.values.organisation,
        feedback_desc: formik.values.feedback,
        rating: formik.values.rating,
        id: inputDetails.feedback_id
        // opportunity_status: formik.values.status,
        // id: inputDetails.opportunity_id
        // input_id,
        // deal_id
      };
      console.log('data ---------------------', data);
      await API.put('/secure/update_feedback', data, config)
        .then((res) => {
          formik.resetForm();
          // handleRunProcess(res.data.id);
          //   navigate('/dashboard/admin/task');
          //   setAlertContent(`Success: ${res.data.message}`);
          //   setAlertType('success');
          //   setAlert(true);
          handleDialog();
          // updateComponent(isUpdated + 1);
        })
        .then(updateComponent(isUpdated + 1))
        .catch((err) => {
          console.log('error----', err);
          //   setAlertContent(`${err.response.data.message}`);
          //   setAlertType('error');
          //   setAlert(true);
        });
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const [userAudio, setuserAudio] = useState({ File_Name: '', Data: '' });

  /* ----- Creating state for customer, process and agent ------ */
  const [customerList, setcustomerList] = useState();
  const [processList, setprocessList] = useState();
  const [agentList, setagentList] = useState();
  const [OrganisationList, setOrganisationList] = useState();
  const [checkInDate, setCheckInDate] = useState(null);
  // const [alert, setAlert] = useState(false);
  // const [alertContent, setAlertContent] = useState('');
  // const [alertType, setAlertType] = useState('');

  const statusList = [
    { status_id: 'completed', status_name: 'Completed' },
    { status_id: 'assigned', status_name: 'Assigned' }
  ];

  const handleCancel = () => {
    handleDialog();
    formik.resetForm();
  };
  // const handleAlert = () => {
  //   setAlert(false);
  // };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/list_of_customer?organisation_id=${formik.values.organisationId}`,
      config
    )
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.organisationId !== '') {
      getCustomerList();
    } else {
      setcustomerList([]);
    }
  }, [formik.values.organisationId]);

  const getAgentList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getRole() {
      return API.get(
        `secure/list_of_agent?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisationId}`,
        config
      );
    }
    Promise.all([getRole()])
      .then(([agentData]) => {
        setagentList(agentData.data.agent_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (formik.values.customer !== '') {
      getAgentList();
    } else {
      setagentList([]);
    }
  }, [formik.values.customer]);

  const setCustomerValue = (value) => {
    formik.setFieldValue('customer', value.customer_id);
  };

  console.log('first', formik.values);

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));
  const classes = useStyles();

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={6}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Select
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  {...getFieldProps('organisationId')}
                  error={Boolean(touched.organisationId && errors.organisationId)}
                  helperText={touched.organisationId && errors.organisationId}
                  disabled
                >
                  {OrganisationList
                    ? OrganisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.organisationId && errors.organisationId}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Select
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  {...getFieldProps('customer')}
                  error={Boolean(touched.customer && errors.customer)}
                  helperText={touched.customer && errors.customer}
                  disabled
                  // onChange={(e) => setCustomerValue(e.target.value)}
                >
                  {customerList
                    ? customerList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={customer_id}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.customer && errors.customer}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="agent-select-label">
                Select Agent
              </InputLabel>
              <Select
                required
                labelId="agent-select-label"
                label="Select Agent"
                id="select-agent"
                {...getFieldProps('agent')}
                error={Boolean(touched.agent && errors.agent)}
                helperText={touched.agent && errors.agent}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                disabled
              >
                {agentList
                  ? agentList.map((element) => {
                      const { user_id, first_name, last_name, empcode } = element;
                      return (
                        <MenuItem key={user_id} value={user_id}>
                          {empcode} - {first_name} {last_name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.agent && errors.agent}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {/* <InputLabel id="agent-select-label">Rating</InputLabel> */}
            <Box
              sx={{
                border: '1px solid RGB(220 224 228)',
                borderRadius: '5px',
                height: 54,
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center'
              }}
            >
              <Rating
                name="size-large"
                defaultValue={inputDetails.rating}
                size="large"
                onChange={(e) => {
                  console.log('ratingfield', e.target.value);
                  formik.setFieldValue('rating', e.target.value);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                fullWidth
                label=" Feedback"
                rows={2}
                multiline
                {...getFieldProps('feedback')}
                error={Boolean(touched.feedback && errors.feedback)}
                helperText={touched.feedback && errors.feedback}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleCancel}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={isSubmitting}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Submit
          </LoadingButton>
        </Container>
        {/* <Notification
          message={alertContent}
          open={alert}
          severity={alertType}
          onClose={handleAlert}
        /> */}
      </Form>
    </FormikProvider>
  );
};

export default FeedbackEditForm;
