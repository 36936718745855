/* eslint-disable use-isnan */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-key */
/* eslint-disable no-return-await */
/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-template */
/* eslint-disable  no-useless-concat */
import * as Yup from 'yup';
import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import {
  Grid,
  Box,
  Modal,
  Typography,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardHeader,
  Button,
  Container,
  Stack,
  Select as Selectbox,
  InputLabel,
  FormControl,
  Menu,
  ListSubheader,
  ListItemText,
  MenuItem,
  FormHelperText,
  TextField,
  IconButton,
  Tooltip as Tip,
  CircularProgress,
  Hidden,
  Checkbox,
  Divider,
  Collapse,
  Pagination,
  Link,
  Backdrop
} from '@mui/material';
import Select, { components } from 'react-select';
import PortraitIcon from '@mui/icons-material/Portrait';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CampaignIcon from '@mui/icons-material/Campaign';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FilterAltIcon from '@mui/icons-material/FilterAltOutlined';
import { makeStyles } from '@mui/styles';
import CallIcon from '@mui/icons-material/Call';
import GetAppIcon from '@mui/icons-material/GetApp';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PercentIcon from '@mui/icons-material/Percent';
import ApprovalIcon from '@mui/icons-material/Approval';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import DatePicker from 'react-datepicker';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import useMediaQuery from '@mui/material/useMediaQuery';
import Highlighter from 'react-highlight-words';
import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material/styles';
import { ResponsiveContainer } from 'recharts';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  CircularGauge,
  Scale,
  Label,
  RangeContainer,
  Range,
  Export,
  Title as TitleGauge,
  Font
} from 'devextreme-react/circular-gauge';
import moment from 'moment';
import themes from 'devextreme/ui/themes';
import Chip from '@mui/material/Chip';
import { Icon } from '@iconify/react';
import download from '@iconify/icons-eva/download-fill';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import callIcon from '@iconify/icons-mdi/call';
import mailIcon from '@iconify/icons-mdi/mail';
import ReactApexChart from 'react-apexcharts';
import FileSaver from 'file-saver';
import Scrollbar from '../components/Scrollbar';
import AppWidgetSummary from '../components/charts/AppWidgetSummary';
import './CSS/ArrowBox.css';
import API from '../E2E/axios.util';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import Wordcloud from '../layouts/Upload/Graphs/Wordcloud';
import { Notification } from '../components/Notification/Notification';
import inputContex from '../Context/UploadContext/inputContext';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';

// import TreeMap from '../assets/TreeMap.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 500,
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 5,
  p: 4
};

// <---------------------------------------------------------->

//  <---------------------------------------------------------->

const customStyles = {
  option: (base, state) => ({
    ...base,
    fontWeight: state.isSelected ? '500' : 'normal',
    maxHeight: 35
  }),
  control: (base, state) => ({
    ...base,
    fontWeight: '500',
    border: 'none'
    // maxHeight: 35
  })
};

const CustomControlOrg = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <CorporateFareIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );
};

const CustomControlCustomer = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <PortraitIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );
};

const CustomControlCampaign = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <CampaignIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );
};

const CustomControlDate = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <CalendarMonthIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );
};

export default function SalesAnalysis() {
  // usecontext for navigation
  const {
    setinputId,
    setinputOrgId,
    setrequestId,
    setUploadedAudio,
    setuploadStatus,
    setAudioS3,
    uploadStatus,
    setProcess,
    setcustomerId,
    setOriginalDate,
    setAgentId,
    setAgentName,
    setAgentLastName,
    kpi,
    setKpi
  } = useContext(inputContex);
  const classes = useStyles();
  const ref = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [words, setWords] = useState([]);
  const [count, setCount] = useState([]);
  const [organisationList, setOrganisationList] = useState();
  const [customerList, setCustomerList] = useState([]);
  const [sentences, setSentences] = useState([]);
  const [probability, setProbability] = useState(0);
  const [intensionCount, setIntensionCount] = useState();
  const [call, setCall] = useState();
  const [treeIndex, setTreeIndex] = useState();
  const [test, setTest] = useState(false);
  const [map, setMap] = useState(false);
  const [positiveWord, setPositiveWord] = useState([]);
  const [callDetails, setCallDetails] = useState();
  // const [storedFormik, setStoredFormik] = useState();
  const [transcriptList, setTranscriptList] = useState([]);
  const [highlightWords, setHighlightWords] = useState([]);
  const [s3Url, setS3Url] = useState([]);
  const [indexForTrans, setIndexForTrans] = useState();
  const [indexForAudio, setIndexForAudio] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [campaignList, setcampaignList] = useState([]);
  const [popupDataForInsights, setPopupDataForInsights] = useState();
  // console.log('popupDataForInsights', popupDataForInsights);
  const [keyInsightsOpen, setKeyInsightsOpen] = useState();
  const [leadStatusUpdate, setLeadStatusUpdate] = useState(0);

  const state = useLocation();

  const [salesProductInfo, setSalesProductInfo] = useState(
    state.state?.initialData ? state.state.initialData.storedInfo.salesCardInfo : null
  );
  // const [positiveLeadWords, setPositiveLeadWords] = useState([]);
  const [agentTaskList, setAgentTaskList] = useState(
    state.state?.initialData ? state.state.initialData.storedInfo.agentTaskInfo : []
  );
  const [dealStatus, setDealStatus] = useState(
    state.state?.initialData ? state.state.initialData.storedInfo.dealStatusInfo : null
  );
  // const [sentiments, setSentiments] = useState(
  //   JSON.parse(localStorage.getItem('sentiment'))
  //     ? JSON.parse(localStorage.getItem('sentiment'))
  //     : null
  // );
  const [dealStatusShow, setDealStatusShow] = useState(false);
  const [getAudioList, setGetAudioList] = React.useState(
    state.state?.initialData ? state.state.initialData.storedInfo.audioListInfo : []
  );
  const [page, setPage] = React.useState(1);
  const [popupPage, setPopupPage] = React.useState(1);
  const [insightName, setInsightsName] = React.useState('');

  // change pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageForpopup = (event, newPage) => {
    setPopupPage(newPage);
  };
  const [treeMapData, setTreeMapData] = React.useState([]);
  // status
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');

  // User details
  const userDetails = JSON.parse(localStorage.getItem('user'));

  // creating state for incoming values

  const [test1, setTest1] = useState(
    state.state !== null
      ? userDetails.role !== 'Super_Admin'
        ? userDetails.customer_id
        : state.state.initialData.storedInfo.newValues.newCustomer
      : { value: 'all', label: 'All' }
  );
  const [test2, setTest2] = useState(
    state.state !== null
      ? userDetails.role !== 'Super_Admin'
        ? userDetails.organisation_id
        : state.state.initialData.storedInfo.newValues.newOrganisation
      : { value: 'all', label: 'All' }
  );
  const [test3, setTest3] = useState(
    userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'Customer_Admin'
      ? null
      : state.state !== null
      ? state.state.initialData.storedInfo.newValues.newCompaign
      : { value: 'all', label: 'All' }
  );
  const [test4, setTest4] = useState(
    state.state !== null
      ? state.state.initialData.storedInfo.newValues?.newFormat
      : { value: 'one_week', label: 'One Week' }
  );
  const [test5, settest5] = useState(state.state !== null ? state.state.start_date : null);
  const [test6, settest6] = useState(state.state !== null ? state.state.end_date : null);

  useEffect(() => {
    if (state.state !== null) {
      // setTest1(state.state.customer_id);
      // setTest2(state.state.organisation_id);
      // setTest3(state.state.campaign_id);
      // setTest4(state.state.format);
      settest5(state.state.start_date);
      settest6(state.state.end_date);
    }
  }, []);

  // creating function for fetching default dashboard api
  const weekBeforeDate = new Date(Date.now() - 604800000);
  const presentDate = new Date(Date.now());
  const setWeekBeforeDate = `${weekBeforeDate.getFullYear()}-${
    weekBeforeDate.getMonth() + 1
  }-${weekBeforeDate.getDate()}`;
  const setPresentDate = `${presentDate.getFullYear()}-${
    presentDate.getMonth() + 1
  }-${presentDate.getDate()}`;
  const [checkInDate, setCheckInDate] = useState(test5 ? new Date(test5) : weekBeforeDate);
  const [checkOutDate, setCheckOutDate] = useState(test6 ? new Date(test6) : presentDate);

  const setDateFormat = (date) => {
    const formatStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return formatStartDate;
  };

  const handleCheckInDate = (date) => {
    setCheckInDate(date);
    setCheckOutDate('');
    formik.setFieldValue('endDate', '');
    const start_date = setDateFormat(date);
    formik.setFieldValue('startDate', start_date);
  };

  const handleCheckOutDate = (date) => {
    setCheckOutDate(date);
    const start_date = setDateFormat(date);
    formik.setFieldValue('endDate', start_date);
  };

  // useEffect For Default Dashboard
  useEffect(() => {
    if (
      formik.values.organisation === 'all' ||
      formik.values.customer === 'all' ||
      ((userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'Customer_Admin') &&
        state?.state === null)
      // (userDetails.role === 'QA' && formik.values.campaign === null)
    ) {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
        // onUploadProgress: (data) => {
        //   setUploaded(Math.round((data.loaded / data.total) * 100));
        // }
      };
      let url = `/secure/default_sales_product_info?date=one_week`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (userDetails.role === 'QA' || userDetails.role === 'Manager') {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          // console.log('ressss', res);
          localStorage.setItem('productInfo', JSON.stringify(res.data.count));
          setSalesProductInfo(res?.data?.count);
          // setAgentTaskList(res?.data?.all_audios?.agent_task);
        })
        .catch((err) => console.log('err', err));
    }
  }, [leadStatusUpdate]);

  // Popup Click API For Default Intent

  // useEffect(() => {
  //   if (insightName !== '') {
  //     handleIntentPopupAPI(insightName);
  //   }
  // }, [popupPage]);

  useEffect(() => {
    if (userDetails.role === 'Super_Admin') {
      if (
        formik.values.organisation === 'all' ||
        formik.values.customer === 'all' ||
        ((userDetails.role === 'QA' ||
          userDetails.role === 'Manager' ||
          userDetails.role === 'Customer_Admin') &&
          state?.state === null)
      ) {
        setLoading(true);
        const token = localStorage.getItem('token');
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        let url = `/secure/identified_leads_audio?page=${page}&date=one_week`;

        if (
          userDetails.role === 'Customer_Admin' ||
          userDetails.role === 'QA' ||
          userDetails.role === 'Manager'
        ) {
          url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
        }
        API.get(url, config)
          .then((res) => {
            localStorage.setItem('audioList', JSON.stringify(res.data));
            localStorage.setItem(
              'newValues',
              JSON.stringify({
                newOrganisation: { value: 'all', label: 'All' },
                newCustomer: { value: 'all', label: 'All' },
                newCompaign: { value: 'all', label: 'All' },
                newFormat: { value: 'one_week', label: 'One Week' }
              })
            );
            setGetAudioList(res?.data);
            setLoading(false);
          })
          .catch((err) => {
            setGetAudioList([]);
            setLoading(false);
            console.log('err', err);
          });
      } else {
        getIdentifiedListFilter();
      }
    } else {
      getIdentifiedListFilter();
    }
  }, [leadStatusUpdate, page]);

  useEffect(() => {
    if (userDetails.role === 'Super_Admin') {
      if (
        formik.values.organisation === 'all' ||
        formik.values.customer === 'all' ||
        ((userDetails.role === 'QA' ||
          userDetails.role === 'Manager' ||
          userDetails.role === 'Customer_Admin') &&
          state?.state === null)
      ) {
        setLoading(true);
        const token = localStorage.getItem('token');
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        let url = `/secure/default_key_insights?page=${popupPage}&date=one_week&flag=${insightName}`;
        if (userDetails.role === 'Customer_Admin') {
          url += `&org_id=${userDetails.organisation_id}`;
        } else if (userDetails.role === 'QA' || userDetails.role === 'Manager') {
          url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
        }

        API.get(url, config)
          .then((res) => {
            setPopupDataForInsights(res.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log('err', err);
            setLoading(false);
          });
      } else {
        setLoading(true);
        const token = localStorage.getItem('token');
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };

        let url = `/secure/filter_key_insights?page=${popupPage}&date=${formik.values.format}&org_id=${formik.values.organisation}&flag=${insightName}&customer_id=${formik.values.customer}`;

        if (formik.values.format === 'customized') {
          url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
        }

        if (formik.values.campaign !== null && formik.values.campaign !== '') {
          url += `&campaign_id=${formik.values.campaign}`;
        }
        // const url = `/secure/default_key_insights?page=${popupPage}&date=six_month&flag=${intent}`;

        API.get(url, config)
          .then((res) => {
            setPopupDataForInsights(res.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log('err', err);
            setLoading(false);
          });
      }
    } else {
      setLoading(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      let url = `/secure/filter_key_insights?page=${popupPage}&date=${formik.values.format}&org_id=${formik.values.organisation}&flag=${insightName}&customer_id=${formik.values.customer}`;

      if (formik.values.format === 'customized') {
        url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
      }

      if (formik.values.campaign !== null && formik.values.campaign !== '') {
        url += `&campaign_id=${formik.values.campaign}`;
      }
      // const url = `/secure/default_key_insights?page=${popupPage}&date=six_month&flag=${intent}`;

      API.get(url, config)
        .then((res) => {
          setPopupDataForInsights(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log('err', err);
          setLoading(false);
        });
    }
  }, [insightName, popupPage]);

  useEffect(() => {
    if (
      formik.values.organisation === 'all' ||
      formik.values.customer === 'all' ||
      ((userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'Customer_Admin') &&
        state?.state === null)
    ) {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let url = `/secure/default_agent_task?date=one_week`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (userDetails.role === 'QA' || userDetails.role === 'Manager') {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          localStorage.setItem('agentTaskList', JSON.stringify(res.data.agent_tasks));
          setAgentTaskList(res?.data?.agent_tasks);
        })
        .catch((err) => console.log('err', err));
    }
  }, []);

  useEffect(() => {
    if (
      formik.values.organisation === 'all' ||
      formik.values.customer === 'all' ||
      ((userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'Customer_Admin') &&
        state?.state === null)
    ) {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let url = `/secure/default_deal_status?date=one_week`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (userDetails.role === 'QA' || userDetails.role === 'Manager') {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          setDealStatus(res.data.deal_status);
          localStorage.setItem('dealStatus', JSON.stringify(res.data.deal_status));
        })
        .catch((err) => console.log('err', err));
    }
  }, []);

  const CustomInput = React.forwardRef((props, ref) => (
    <div style={{ position: 'relative' }}>
      <input {...props} ref={ref} />
      <CalendarMonthIcon
        onClick={props.onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '10px',
          // pointerEvents: 'none',
          cursor: 'pointer',
          zIndex: 0
        }}
      />
    </div>
  ));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const SaleSchema = Yup.object().shape({
    organisation: Yup.string().nullable().required('This field is required.'),
    customer: Yup.string().nullable().required('This field is required.'),
    format: Yup.string().nullable().required('This field is required.'),
    startDate: Yup.date().required('This field is required.'),
    endDate: Yup.date().required('This field is required.')
    // campaign: Yup.string().nullable().required('This field is required.')
  });

  const token = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  // function positiveLeads() {
  //   let url = `/secure/positive_leads?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}`;

  //   if (formik.values.format === 'customized') {
  //     url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
  //   }

  //   if (formik.values.campaign !== null && formik.values.campaign !== '') {
  //     url += `&campaign_id=${formik.values.campaign}`;
  //   }

  //   API.get(url, config)
  //     .then((res) => setPositiveLeadWords(res.data.positive_lead))
  //     .catch((err) => console.log('err', err));
  // }

  function getSalesProductInfo() {
    let url = `/secure/sales_product_info?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&campaign_id=${formik.values.campaign}`;
    }

    API.get(url, config)
      .then((res) => {
        localStorage.setItem('productInfo', JSON.stringify(res.data.count));
        setSalesProductInfo(res.data.count);
      })
      .catch((err) => {
        console.log('err', err);
        setSalesProductInfo(null);
        setAlert(true);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
      });
  }

  function getAudioListData() {
    let url = `/secure/audio_list?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&campaign_id=${formik.values.campaign}`;
    }

    API.get(url, config)
      .then((res) => {
        setGetAudioList(res.data);
        localStorage.setItem('audioList', JSON.stringify(res.data));
        // setAlert(true);
        // setAlertContent(`${res.data.message}`);
        // setAlertType('success');
      })
      .catch((err) => {
        console.log('err', err);
        setGetAudioList([]);
        setAlert(true);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
      });
  }

  function getAgentTaskList() {
    let url = `/secure/agent_tasks?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&campaign_id=${formik.values.campaign}`;
    }

    API.get(url, config)
      .then((res) => {
        setAgentTaskList(res.data.agent_tasks);
        localStorage.setItem('agentTaskList', JSON.stringify(res.data.agent_tasks));

        console.log('res setAgentTaskList', res.data);
        // setAlert(true);
        // setAlertContent(`${res.data.message}`);
        // setAlertType('success');
      })
      .catch((err) => {
        console.log('err', err);
        setAgentTaskList([]);
        setAlert(true);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
      });
  }

  function getDealStatus() {
    let url = `/secure/deal_status?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&campaign_id=${formik.values.campaign}`;
    }

    API.get(url, config)
      .then((res) => {
        // setAgentTaskList(res.data.agent_tasks);
        setDealStatus(res.data.deal_status);
        localStorage.setItem('dealStatus', JSON.stringify(res.data.deal_status));

        console.log('res deal status', res.data);
        console.log('res deal status 1', res.data.deal_status);
        // setAlert(true);
        // setAlertContent(`${res.data.message}`);
        // setAlertType('success');
      })
      .catch((err) => {
        console.log('err', err);
        setDealStatus(null);
      });
  }

  function getIdentifiedListFilter() {
    setLoading(true);
    let url = `/secure/filter_identified_leads_audio?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}&page=${page}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&campaign_id=${formik.values.campaign}`;
    }

    API.get(url, config)
      .then((res) => {
        setGetAudioList(res.data);
        localStorage.setItem('audioList', JSON.stringify(res.data));
        setLoading(false);
      })
      .catch((err) => {
        setGetAudioList();
        setLoading(false);
      });
  }

  // function getSentiments() {
  //   let url = `/secure/sentiments_analysis?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}`;

  //   if (formik.values.format === 'customized') {
  //     url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
  //   }

  //   if (formik.values.campaign !== null && formik.values.campaign !== '') {
  //     url += `&campaign_id=${formik.values.campaign}`;
  //   }

  //   API.get(url, config)
  //     .then((res) => {
  //       // setAgentTaskList(res.data.agent_tasks);
  //       setSentiments(res.data);
  //       localStorage.setItem('sentiment', JSON.stringify(res.data));

  //       console.log('res deal status', res.data);
  //       console.log('res deal status 1', res.data);
  //       // setAlert(true);
  //       // setAlertContent(`${res.data.message}`);
  //       // setAlertType('success');
  //     })
  //     .catch((err) => {
  //       console.log('err', err);
  //     });
  // }

  const storedData = {
    salesCardInfo: salesProductInfo ? salesProductInfo : null,
    dealStatusInfo: dealStatus ? dealStatus : null,
    agentTaskInfo: agentTaskList ? agentTaskList : null,
    audioListInfo: JSON.parse(localStorage.getItem('audioList'))
      ? JSON.parse(localStorage.getItem('audioList'))
      : null,
    formikInfo: JSON.parse(localStorage.getItem('formik'))
      ? JSON.parse(localStorage.getItem('formik'))
      : null,
    newValues: JSON.parse(localStorage.getItem('newValues'))
      ? JSON.parse(localStorage.getItem('newValues'))
      : null
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      organisation:
        userDetails.role !== 'Super_Admin'
          ? userDetails.organisation_id
          : test2.value
          ? test2.value
          : '',
      customer:
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'Customer_Admin'
          ? userDetails.customer_id
          : test1
          ? test1.value
          : null,
      format: test4 ? test4.value : 'one_week',
      campaign: test3 ? test3.value : null,
      startDate: test5 ? test5 : setWeekBeforeDate,
      endDate: test6 ? test6 : setPresentDate
    },
    validationSchema: SaleSchema,
    onSubmit: async () => {
      setPage(1);

      // console.log('values', formik.values);
      localStorage.setItem('formik', JSON.stringify(formik.values));
      const newValues = {
        newOrganisation: test2,
        newCustomer: test1,
        newCompaign: test3,
        newFormat: test4
      };
      localStorage.setItem('newValues', JSON.stringify(newValues));
      // localStorage.setItem('newValues', );
      // &campaign_id=${formik.values.campaign}

      // positiveLeads();
      getSalesProductInfo();
      getIdentifiedListFilter();
      getAgentTaskList();
      getDealStatus();
      // getSentiments();
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  // <--------------------------------------------------------->

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      // `/secure/list_of_customer?organisation_id=${formik.values.organisation}&services=["sales"]`,
      `/secure/dashboard_customer?organisation_id=${formik.values.organisation}&services=sales`,
      config
    )
      .then((res) => {
        setCustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      formik.values.organisation !== '' &&
      formik.values.organisation !== null &&
      formik.values.organisation !== 'all'
    ) {
      // if (JSON.parse(localStorage.getItem('formik'))?.customer && state.state !== null) {
      //   formik.setFieldValue('customer', JSON.parse(localStorage.getItem('formik'))?.customer);
      // }

      getCustomerList();
    } else {
      setCustomerList([]);
    }
  }, [formik.values.organisation]);

  const getCampaign = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/get_campaign?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisation}`,
      config
    )
      .then((res) => {
        console.log('res compaign', res.data);
        setcampaignList(res.data.campaign_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      formik.values.organisation &&
      formik.values.customer &&
      formik.values.customer !== '' &&
      formik.values.customer !== 'all'
    ) {
      // if (state.state !== null && JSON.parse(localStorage.getItem('formik'))?.campaign) {
      //   formik.setFieldValue('campaign', state.state.campaign_id);
      // }
      getCampaign();
    } else {
      setcampaignList([]);
    }
  }, [formik.values.customer]);

  const setCustomerValue = (value) => {
    // console.log('checkfuctionrun');
    formik.setFieldValue('customer', value);
    formik.setFieldValue('campaign', '');
  };

  const setCampaignValue = (e) => {
    if (e === 'clear') {
      setTest3(null);
      formik.setFieldValue('campaign', null);
    } else {
      setTest3(e);
      formik.setFieldValue('campaign', e.value);
    }
  };

  // <---------------------------------------------------------------->

  console.log('calls ----', call);
  const treeSeries = [
    {
      data: [
        {
          x: 'Total Calls',
          y: call?.Total_Calls
        }
      ]
    }
  ];
  console.log('getAudioList parsad 0', getAudioList);
  // ------------------------------------------------------------------
  // treemap for sales analysis.
  const mapOptions = {
    chart: {
      id: `basic-bar${Math.random()}`,
      toolbar: {
        show: false
      },
      events: {
        dataPointSelection: (config, event, chartContext) => {
          const validateText = chartContext.w.config.series[0].data[chartContext.dataPointIndex].x;
          setTreeIndex(chartContext.dataPointIndex);
          if (validateText === 'Product and pricing Info') {
            // handleIntentPopupAPI('product_info');
            setInsightsName('product_info');
            handleKeyInsightsPopup();
          }
          if (validateText === 'Organisation and Person mention') {
            // handleIntentPopupAPI('competitor_info');
            setInsightsName('competitor_info');
            // setPopupDataForInsights(audioListData.compitators);
            handleKeyInsightsPopup();
          }
          if (validateText === 'Buying Intent') {
            // handleIntentPopupAPI('buying_info');
            setInsightsName('buying_info');
            // setPopupDataForInsights(audioListData.buying_info);
            handleKeyInsightsPopup();
          }
          if (validateText === 'Follow ups') {
            // handleIntentPopupAPI('followup_info');
            setInsightsName('followup_info');
            // setPopupDataForInsights(audioListData.followup);
            handleKeyInsightsPopup();
          }
        }
      },
      // width: '130%',
      // height: '100%',
      type: 'treemap'
    },
    colors: ['#84CAFE', '#84CAFE', '#84CAFE', '#84CAFE'],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        fontFamily: 'Monospace',
        // fontWeight: 'bold',
        colors: ['#2e2a2a']
      },
      formatter: function (text, op) {
        // console.log('value tree', text);
        // console.log('salesProductInfoTree', salesProductInfo);
        return [
          text,
          `${op.value}/${salesProductInfo && salesProductInfo.total ? salesProductInfo?.total : 0}`
          // `${op.value}/10}`
        ];
      }
    },
    // responsive: [
    //   {
    //     breakpoint: 2300,
    //     options: {
    //       chart: {
    //         width: 500,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 2100,
    //     options: {
    //       chart: {
    //         width: 470,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 1900,
    //     options: {
    //       chart: {
    //         width: 450,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 1700,
    //     options: {
    //       chart: {
    //         width: 420,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 1500,
    //     options: {
    //       chart: {
    //         width: 399,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 1430,
    //     options: {
    //       chart: {
    //         width: 399,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 1400,
    //     options: {
    //       chart: {
    //         width: 397,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 1350,
    //     options: {
    //       chart: {
    //         width: 393,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 1300,
    //     options: {
    //       chart: {
    //         width: 385,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 1270,
    //     options: {
    //       chart: {
    //         width: 375,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 1248,
    //     options: {
    //       chart: {
    //         width: 370,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 1225,
    //     options: {
    //       chart: {
    //         width: 355,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 1200,
    //     options: {
    //       chart: {
    //         width: 400,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 1000,
    //     options: {
    //       chart: {
    //         width: 380,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 950,
    //     options: {
    //       chart: {
    //         width: 360,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 900,
    //     options: {
    //       chart: {
    //         width: 800,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 899,
    //     options: {
    //       chart: {
    //         width: 400,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 500,
    //     options: {
    //       chart: {
    //         width: 350,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 400,
    //     options: {
    //       chart: {
    //         width: 300,
    //         height: 400,
    //         type: 'treemap'
    //       }
    //     }
    //   }
    // ],
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false
      }
    }
  };

  // const treeCountDisplay = (text, op) => {
  //   return [
  //     text,
  //     `${op.value}/${salesProductInfo && salesProductInfo.total ? salesProductInfo?.total : 0}`
  //     // `${op.value}/10}`
  //   ];
  // };

  useEffect(() => {
    const data = [];

    if (salesProductInfo && salesProductInfo.product_info_count !== 0) {
      data.push({
        x: 'Product and pricing Info',
        y:
          salesProductInfo && salesProductInfo.product_info_count
            ? salesProductInfo.product_info_count
            : 0
      });
    }
    if (salesProductInfo && salesProductInfo.competitor_count !== 0) {
      data.push({
        x: 'Organisation and Person mention',
        y:
          salesProductInfo && salesProductInfo.competitor_count
            ? salesProductInfo.competitor_count
            : 0
      });
    }
    if (salesProductInfo && salesProductInfo.Buying_count !== 0) {
      data.push({
        x: 'Buying Intent',
        y: salesProductInfo && salesProductInfo.Buying_count ? salesProductInfo.Buying_count : 0
      });
    }
    if (salesProductInfo && salesProductInfo.followup_count !== 0) {
      data.push({
        x: 'Follow ups',
        y: salesProductInfo && salesProductInfo.followup_count ? salesProductInfo.followup_count : 0
      });
    }
    // console.log('data salesprodutinfo', salesProductInfo);
    // console.log('data salesprodutinfo 1', data);
    setTreeMapData(data);
  }, [salesProductInfo]);

  // console.log('treemap', treeMapData);

  const mapSeries = [
    {
      data: treeMapData
    }
  ];

  const [intension, setIntension] = useState([]);
  const [positive, setPositive] = useState([]);
  const [negative, setNegative] = useState([]);
  const [neutral, setNeutral] = useState([]);
  // const [mixed, setMixed] = useState([]);

  const intensionData = () => {
    const positive = [];
    const negative = [];
    const neutral = [];
    // const mixed = [];
    intensionCount?.map((el) => {
      setIntension(Object.keys(el));
      Object.values(el).map((e) => {
        positive.push(e.POSITIVE);
        negative.push(e.NEGATIVE);
        neutral.push(e.NEUTRAL);
        // mixed.push(e.MIXED);
        return e;
      });
      return el;
    });
    setPositive(positive);
    setNegative(negative);
    setNeutral(neutral);
    // setMixed(mixed);
  };

  useEffect(() => {
    if (intensionCount) {
      intensionData();
    }
  }, [intensionCount]);

  // <------------------------------------------------------>

  const dateFormat = [
    { name: 'One Week', id: 'one_week' },
    { name: 'One Month', id: 'one_month' },
    // { name: 'Six Month', id: 'six_month' },
    // { name: 'One Year', id: 'one_year' },
    { name: 'Customize', id: 'customized' }
  ];

  // Integrate download csv file.
  const downloadCSV = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/download_salesdata?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&campaign_id=${formik.values.campaign}`;
    }
    await API.get(url, config)
      .then((res) => {
        console.log('download csv res --', res);
        FileSaver.saveAs(
          `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.final_csv}`,
          res.data.final_csv.split('/').pop()
        );
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  const handleDialog = () => {
    setOpen(!open);
  };

  const handleKeyInsightsPopup = () => {
    setKeyInsightsOpen(!keyInsightsOpen);
    setPopupPage(1);
  };

  const handleTree = () => {
    setTest(!test);
  };
  const handleMap = () => {
    setMap(!map);
  };

  const handleAlert = () => {
    setAlert(false);
  };

  const pieOptions = {
    chart: {
      width: '100%',
      type: 'pie'
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        console.log('w.config', w.config.labels[seriesIndex]);

        return (
          '<div id="arrow_box">' +
          '<span>' +
          w.config.labels[seriesIndex] +
          ':' +
          series[seriesIndex] +
          '/' +
          (series[0] + series[1] + series[2]) +
          '</span>' +
          '</div>'
        );
      }
    },
    labels: ['In Progress', 'Closed Won', 'Closed Lost'],
    legend: {
      position: 'bottom'
    }
  };

  const pieSeries = [dealStatus?.in_progress, dealStatus?.closed_won, dealStatus?.closed_lost];

  useEffect(() => {
    if (
      dealStatus &&
      dealStatus?.in_progress === 0 &&
      dealStatus?.closed_won === 0 &&
      dealStatus?.closed_lost === 0
    ) {
      setDealStatusShow(false);
    } else {
      setDealStatusShow(true);
    }
  }, [dealStatus]);

  console.log('formik val', formik.values);

  // filter for approve leads and rejecte leads
  const filterref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState(null);
  const [filtered, setFilteredCalls] = useState(null);

  const handleFilterSelect = (newFilter) => {
    setFilter(newFilter);
  };

  useEffect(() => {
    let filteredCalls;

    if (filter === 'approved') {
      filteredCalls = getAudioList?.results?.filter((call) => call.lead_status === 'approved');
      setFilteredCalls(filteredCalls);
    } else if (filter === 'rejected') {
      filteredCalls = getAudioList?.results?.filter((call) => call.lead_status === 'rejected');
      setFilteredCalls(filteredCalls);
    } else if (filter === 'all' || filter === null) {
      filteredCalls = getAudioList?.results;
      setFilteredCalls(filteredCalls);
    }
    // setFilteredCalls(getAudioList?.all_audios);
  }, [getAudioList, filter]);

  console.log('filteredCalls', filtered);

  const [searchText, setSearchText] = useState('');

  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  const displayedOptions = useMemo(
    () => organisationList?.filter((option) => containsText(option.organisation_name, searchText)),
    [searchText]
  );

  const KEY_WORDS_TABLE_HEAD = [
    { id: 'Agent', numeric: false, disablePadding: false, label: 'Agent' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    // {
    //   id: 'words',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Words',
    //   alignCenter: true
    // },
    { id: 'view', numeric: false, disablePadding: false, label: 'View', alignCenter: true }

    // { id: 'Action', numeric: false, disablePadding: false, label: 'Action', alignCenter: true }
  ];

  function formatNumber(num) {
    if (num >= 1e9) {
      return (
        <Tip title={num} followCursor placement="top">
          <Typography
            style={{
              color: '#000',
              fontStyle: 'bold',
              fontSize: '2.4rem'
            }}
          >
            {(num / 1e9).toFixed(1) + 'B'}
          </Typography>
        </Tip>
      );
    } else if (num >= 1e6) {
      return (
        <Tip title={num} followCursor placement="top">
          <Typography
            style={{
              color: '#000',
              fontStyle: 'bold',
              fontSize: '2.4rem'
            }}
          >
            {(num / 1e6).toFixed(1) + 'M'}
          </Typography>
        </Tip>
      );
    } else if (num >= 1e3) {
      return (
        <Tip title={num} followCursor placement="top">
          <Typography
            style={{
              color: '#000',
              fontStyle: 'bold',
              fontSize: '2.4rem'
            }}
          >
            {(num / 1e3).toFixed(1) + 'K'}
          </Typography>
        </Tip>
      );
    } else {
      return num.toString();
    }
  }

  return (
    <>
      <Grid container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4 }}>
        <Typography variant="h4" gutterBottom>
          Revenue Intelligence
        </Typography>
        {/* <Hidden smUp> */}
        {/* <GetAppIcon
          onClick={(e) => downloadCSV()}
          // disabled={getAudioList.length > 0 ? false : true}
          style={{
            cursor: 'pointer',
            color: '#3892CF'
          }}
        /> */}
        <Button
          onClick={(e) => downloadCSV()}
          disabled={
            formik.values.customer === 'all' ||
            formik.values.customer === null ||
            formik.values.customer === ''
          }
          sx={{
            backgroundColor: '#3892CF',
            '&:hover': {
              backgroundColor: '#3892CF'
            }
          }}
          variant="contained"
          to="#"
          size="small"
          startIcon={<Icon icon={download} />}
        >
          Download
        </Button>
        {/* </Hidden> */}
      </Grid>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid
            container
            sx={{
              // marginBottom: 5,
              backgroundColor: '#f4f4f4',
              display: 'flex',
              justifyContent: 'space-between',
              padding: 1
            }}
            // spacing={2}
          >
            <Grid item xs={12} sm={5.7} md={2.5}>
              {userDetails.role !== 'Super_Admin' ? (
                <>
                  <h5>
                    <b>Select Organisation *</b>
                  </h5>
                  <Selectbox
                    required
                    fullWidth
                    // label={userDetails.organisation_name}
                    size="small"
                    defaultValue="all"
                    disabled
                  >
                    <MenuItem value="all" disabled>
                      {userDetails.organisation_name}
                    </MenuItem>
                  </Selectbox>
                </>
              ) : (
                <>
                  {/* <FormControl fullWidth>
                    <h5>
                      <b>Select Organisation *</b>
                    </h5>
                    <Selectbox
                      required
                      // labelId="Organisation-select-label"
                      // label="Select Organisation"
                      size="small"
                      id="select-organisation"
                      value={test2}
                      {...getFieldProps('organisation')}
                      style={{ border: '0.8px solid #7a7872' }}
                      onChange={(e) => {
                        setTest2(e.target.value);
                        setTest1(null);
                        setTest3(null);
                      }}
                      error={Boolean(touched.organisation && errors.organisation)}
                      helperText={touched.organisation && errors.organisation}
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                      <MenuItem value="all" disabled style={{ display: 'none' }}>
                        All
                      </MenuItem>
                      {organisationList
                        ? organisationList.map((element) => {
                            const { organisation_id, organisation_name } = element;
                            return (
                              <MenuItem key={organisation_id} value={organisation_id}>
                                {organisation_name}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Selectbox>
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {touched.organisation && errors.organisation}
                    </FormHelperText>
                  </FormControl> */}
                  <h5>
                    <b>Select Organisation *</b>
                  </h5>
                  <Select
                    //   onChange={chnageSelect}
                    styles={customStyles}
                    onChange={(e) => {
                      formik.setFieldValue('organisation', e.value);
                      setTest2(e);
                      setTest1(null);
                      setTest3(null);
                    }}
                    options={organisationList?.map((element) => ({
                      value: element.organisation_id,
                      label: element.organisation_name
                    }))}
                    value={test2}
                    defaultValue={{ value: 'all', label: 'All' }}
                    style={{ color: '#000' }}
                    components={{ Control: CustomControlOrg }}
                    placeholder={
                      <div
                        style={{
                          display: 'flex',
                          columnGap: 10,
                          alignItems: 'center',
                          color: '#000',
                          fontWeight: '400'
                        }}
                      >
                        {/* <img src={CorporateFareIcon} width={20} alt="" /> */}

                        {/* Select Organisation * */}
                      </div>
                    }
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={5.7} md={2.5}>
              {/* <FormControl fullWidth>
                <h5>
                  <b>Select Customer *</b>
                </h5>
                <Selectbox
                  required
                  // labelId="Customer-select-label"
                  // label="Select Customer"
                  size="small"
                  id="select-customer"
                  name="customer"
                  style={{ border: '0.8px solid #7a7872' }}
                  onBlur={formik.handleBlur}
                  value={test1}
                  error={Boolean(touched.customer && errors.customer)}
                  helperText={touched.customer && errors.customer}
                  onChange={(e) => {
                    setTest1(e.target.value);
                    setCustomerValue(e.target.value);
                    setTest3(null);
                  }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  <MenuItem value="all" disabled style={{ display: 'none' }}>
                    All
                  </MenuItem>
                  {customerList.length !== 0 ? (
                    customerList.map((element) => {
                      const { customer_id, customer_organisation_name } = element;
                      return (
                        <MenuItem key={customer_id} value={customer_id}>
                          {customer_organisation_name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>
                      <i>No Customer Available</i>
                    </MenuItem>
                  )}
                </Selectbox>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.customer && errors.customer}
                </FormHelperText>
              </FormControl> */}
              {userDetails.role === 'QA' ||
              userDetails.role === 'Manager' ||
              userDetails.role === 'Customer_Admin' ? (
                <>
                  <h5>
                    <b>Select Customer *</b>
                  </h5>
                  <Selectbox required fullWidth size="small" defaultValue="all" disabled>
                    <MenuItem value="all" disabled>
                      {userDetails.customer_name}
                    </MenuItem>
                  </Selectbox>
                </>
              ) : (
                <>
                  <h5>
                    <b>Select Customer *</b>
                  </h5>
                  <Select
                    //   onChange={chnageSelect}
                    styles={customStyles}
                    onChange={(e) => {
                      setTest1(e);
                      setCustomerValue(e.value);
                      setTest3(null);
                    }}
                    options={customerList?.map((element) => ({
                      value: element.customer_id,
                      label: element.customer_organisation_name
                    }))}
                    value={test1}
                    defaultValue={{ value: 'all', label: 'All' }}
                    style={{ color: '#000' }}
                    components={{ Control: CustomControlCustomer }}
                    placeholder={
                      <div
                        style={{
                          display: 'flex',
                          columnGap: 10,
                          alignItems: 'center',
                          color: '#000',
                          fontWeight: '400'
                        }}
                      >
                        {/* <img src={CorporateFareIcon} width={20} alt="" /> */}

                        {/* Select Organisation * */}
                      </div>
                    }
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={5.7} md={2.5}>
              {/* <FormControl fullWidth>
                <h5>
                  <b>Select Campaign</b>
                </h5>
                <Selectbox
                  required
                  // labelId="Customer-select-label"
                  // label="Select Customer"
                  size="small"
                  id="select-customer"
                  name="campaign"
                  style={{ border: '0.8px solid #7a7872' }}
                  onBlur={formik.handleBlur}
                  value={test3}
                  error={Boolean(touched.campaign && errors.campaign)}
                  helperText={touched.campaign && errors.campaign}
                  onChange={(e) => {
                    setCampaignValue(e.target.value);
                  }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  <MenuItem value="all" disabled style={{ display: 'none' }}>
                    All
                  </MenuItem>
                  <MenuItem key="clear" value="clear">
                    Clear
                  </MenuItem>
                  {campaignList.length !== 0 ? (
                    campaignList.map((element) => {
                      const { campaign_id, campaign_name } = element;
                      return (
                        <MenuItem key={campaign_id} value={campaign_id}>
                          {campaign_name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>
                      <i>No Campaign Available</i>
                    </MenuItem>
                  )}
                </Selectbox>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.campaign && errors.campaign}
                </FormHelperText>
              </FormControl> */}
              <h5>
                <b>Select Campaign (Optional)</b>
              </h5>
              <Select
                //   onChange={chnageSelect}
                styles={customStyles}
                onChange={(e) => {
                  setCampaignValue(e);
                }}
                options={campaignList?.map((element) => ({
                  value: element.campaign_id,
                  label: element.campaign_name
                }))}
                value={test3}
                defaultValue={{ value: 'all', label: 'All' }}
                style={{ color: '#000' }}
                components={{ Control: CustomControlCampaign }}
                placeholder={
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      alignItems: 'center',
                      color: '#000',
                      fontWeight: '400'
                    }}
                  >
                    {/* <img src={CorporateFareIcon} width={20} alt="" /> */}

                    {/* Select Organisation * */}
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12} sm={5.7} md={2.5}>
              {/* <FormControl fullWidth>
                <h5>
                  <b>Select Date *</b>
                </h5>
                <Selectbox
                  required
                  // labelId="Date-select-label"
                  // label="Select Format"
                  id="select-format"
                  size="small"
                  // name="format"
                  style={{ border: '0.8px solid #7a7872' }}
                  {...getFieldProps('format')}
                  // onBlur={formik.handleBlur}
                  error={Boolean(touched.format && errors.format)}
                  helperText={touched.format && errors.format}
                  // onChange={(e) => setDateValue(e.target.value)}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {dateFormat.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.name}
                    </MenuItem>
                  ))}
                </Selectbox>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.format && errors.format}
                </FormHelperText>
              </FormControl> */}
              <h5>
                <b>Select Date *</b>
              </h5>
              <Select
                //   onChange={chnageSelect}
                styles={customStyles}
                onChange={(e) => {
                  formik.setFieldValue('format', e.value);
                  setTest4(e);
                }}
                options={dateFormat?.map((element) => ({
                  value: element.id,
                  label: element.name
                }))}
                value={test4}
                style={{ color: '#000' }}
                components={{ Control: CustomControlDate }}
                placeholder={
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      alignItems: 'center',
                      color: '#000',
                      fontWeight: '400'
                    }}
                  >
                    {/* <img src={CorporateFareIcon} width={20} alt="" /> */}

                    {/* Select Organisation * */}
                  </div>
                }
                isSearchable={false}
                isClearable={false}
              />
            </Grid>

            {formik.values.format !== 'customized' && (
              <Hidden mdDown>
                <Grid
                  item
                  xs={12}
                  sm={5.7}
                  md={0.7}
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    type="submit"
                    // onClick={() => setStoredFormik(formik.values)}
                    className={classes.submitButton}
                    sx={{ marginBottom: '6px' }}
                    size="medium"
                    variant="contained"
                    disabled={formik.values.customer === 'all' || formik.values.customer === null}
                  >
                    Submit
                  </Button>
                </Grid>
              </Hidden>
            )}
          </Grid>
          {formik.values.format === 'customized' && (
            <Grid container style={{ backgroundColor: '#f4f4f4' }}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 13,
                  paddingLeft: 8,
                  paddingRight: 9
                }}
              >
                <Grid item xs={12} sm={5.7} md={5.2}>
                  <h5 style={{ marginBottom: '-10px' }}>
                    <b>Start Date *</b>
                  </h5>
                  <DatePicker
                    id="datepicker"
                    selected={checkInDate}
                    onChange={handleCheckInDate}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomInput />}
                    placeholderText="Start date"
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                  />
                  <FormHelperText sx={{ marginLeft: '10px', color: '#d32f2f' }}>
                    {touched.startDate && errors.startDate}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={5.7} md={5.2}>
                  <h5 style={{ marginBottom: '-10px' }}>
                    <b>End Date *</b>
                  </h5>
                  <DatePicker
                    selected={checkOutDate}
                    maxDate={new Date()}
                    minDate={checkInDate}
                    onChange={handleCheckOutDate}
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomInput />}
                    placeholderText="End date"
                    error={Boolean(touched.endDate && errors.endDate)}
                    helperText={touched.endDate && errors.endDate}

                    // disabled
                  />
                  <FormHelperText sx={{ marginLeft: '10px', color: '#d32f2f' }}>
                    {touched.endDate && errors.endDate}
                  </FormHelperText>
                </Grid>
                <Hidden mdDown>
                  <Grid
                    item
                    xs={12}
                    sm={5.7}
                    md={0.7}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      padding: 8
                    }}
                  >
                    <Button
                      type="submit"
                      className={classes.submitButton}
                      size="medium"
                      variant="contained"
                      disabled={formik.values.customer === 'all' || formik.values.customer === null}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          )}
          <Hidden mdUp>
            <Grid
              item
              xs={12}
              sm={5.7}
              md={0.7}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                padding: 8
              }}
            >
              <Button
                type="submit"
                // onClick={(e) => getGraphData()}
                className={classes.submitButton}
                size="medium"
                variant="contained"
                disabled={formik.values.customer === 'all' || formik.values.customer === null}
              >
                Submit
              </Button>
            </Grid>
          </Hidden>
        </Form>
      </FormikProvider>

      <Grid container style={{ marginTop: 20 }}>
        <Grid
          container
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10,
            padding: 8
          }}
        >
          <Grid item xs={12} sm={5.7} md={1.9} className={classes.cards}>
            <Card
              style={{
                height: 170,
                boxShadow: 0,
                textAlign: 'center'
              }}
            >
              <div
                style={{
                  // borderBottom: '1px solid #f1f3f4',
                  padding: 10,
                  backgroundColor: '#3892CF'
                }}
              >
                <Typography style={{ color: '#fff' }} variant="h7">
                  <b>Total Calls</b>
                </Typography>
              </div>
              <Grid container style={{ width: '100%', height: '100%' }}>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <CallIcon style={{ color: '#60AAD9', marginBottom: 50 }} fontSize="large" />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography
                    // variant="h3"
                    style={{
                      color: '#000',
                      fontStyle: 'bold',
                      marginBottom: 50,
                      fontSize: '2.4rem'
                    }}
                  >
                    {salesProductInfo && salesProductInfo.total
                      ? formatNumber(salesProductInfo?.total)
                      : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={5.7} md={1.9} className={classes.cards}>
            <Card
              style={{
                height: 170,
                boxShadow: 0,
                textAlign: 'center'
              }}
            >
              <div
                style={{
                  // borderBottom: '1px solid #f1f3f4',
                  padding: 10,
                  backgroundColor: '#3892CF'
                }}
              >
                <Typography style={{ color: '#fff' }} variant="h7">
                  <b>Positive Leads</b>
                </Typography>
              </div>
              <Grid container style={{ width: '100%', height: '100%' }}>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <PhoneForwardedIcon
                    style={{ color: '#60AAD9', marginBottom: 50 }}
                    fontSize="large"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography
                    // variant="h3"
                    style={{
                      color: '#000',
                      fontStyle: 'bold',
                      marginBottom: 50,
                      fontSize: '2.4rem'
                    }}
                  >
                    {salesProductInfo && salesProductInfo.lead_count
                      ? formatNumber(salesProductInfo?.lead_count)
                      : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={5.7} md={1.9} className={classes.cards}>
            <Card
              style={{
                height: 170,
                boxShadow: 0,
                textAlign: 'center'
              }}
            >
              <div
                style={{
                  // borderBottom: '1px solid #f1f3f4',
                  padding: 10,
                  backgroundColor: '#3892CF'
                }}
              >
                <Typography style={{ color: '#fff' }} variant="h7">
                  <b>Non Positive Calls</b>
                </Typography>
              </div>
              <Grid container style={{ width: '100%', height: '100%' }}>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <PhoneForwardedIcon
                    style={{ color: '#60AAD9', marginBottom: 50 }}
                    fontSize="large"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography
                    // variant="h3"
                    style={{
                      color: '#000',
                      fontStyle: 'bold',
                      marginBottom: 50,
                      fontSize: '2.4rem'
                    }}
                  >
                    {salesProductInfo && salesProductInfo?.total && salesProductInfo.lead_count
                      ? formatNumber(salesProductInfo?.total - salesProductInfo?.lead_count)
                      : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={5.7} md={1.9} className={classes.cards}>
            <Card
              style={{
                height: 170,
                boxShadow: 0,
                textAlign: 'center'
              }}
            >
              <div
                style={{
                  // borderBottom: '1px solid #f1f3f4',
                  padding: 10,
                  backgroundColor: '#3892CF'
                }}
              >
                <Typography style={{ color: '#fff' }} variant="h7">
                  <b>Conversion</b>
                </Typography>
              </div>
              <Grid container style={{ width: '100%', height: '100%' }}>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <DoneAllIcon style={{ color: '#60AAD9', marginBottom: 50 }} fontSize="large" />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography
                    // variant="h3"
                    style={{
                      color: '#000',
                      fontStyle: 'bold',
                      marginBottom: 50,
                      fontSize: '2.4rem'
                    }}
                  >
                    {salesProductInfo && salesProductInfo.conversion_count
                      ? formatNumber(salesProductInfo?.conversion_count)
                      : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={5.7} md={1.9} className={classes.cards}>
            <Card
              style={{
                height: 170,
                boxShadow: 0,
                textAlign: 'center'
              }}
            >
              <div
                style={{
                  // borderBottom: '1px solid #f1f3f4',
                  padding: 10,
                  backgroundColor: '#3892CF'
                }}
              >
                <Typography style={{ color: '#fff' }} variant="h7">
                  <b>Final Stage Call</b>
                </Typography>
              </div>
              <Grid container style={{ width: '100%', height: '100%' }}>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <ApprovalIcon style={{ color: '#60AAD9', marginBottom: 50 }} fontSize="large" />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography
                    // variant="h3"
                    style={{
                      color: '#000',
                      fontStyle: 'bold',
                      marginBottom: 50,
                      fontSize: '2.4rem'
                    }}
                  >
                    {salesProductInfo && salesProductInfo.final_stage
                      ? formatNumber(salesProductInfo?.final_stage)
                      : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={5.7} md={1.9} className={classes.cards}>
            <Card
              style={{
                height: 170,
                boxShadow: 0,
                textAlign: 'center'
              }}
            >
              <div
                style={{
                  // borderBottom: '1px solid #f1f3f4',
                  padding: 10,
                  backgroundColor: '#3892CF'
                }}
              >
                <Typography style={{ color: '#fff' }} variant="h7">
                  <b>Win Percentage</b>
                </Typography>
              </div>
              <Grid container style={{ width: '100%', height: '100%' }}>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <PercentIcon style={{ color: '#60AAD9', marginBottom: 50 }} fontSize="large" />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography
                    // variant="h3"
                    style={{
                      color: '#000',
                      fontStyle: 'bold',
                      marginBottom: 50,
                      fontSize: '2.4rem'
                    }}
                  >
                    {salesProductInfo && salesProductInfo.win_percent
                      ? salesProductInfo?.win_percent.toFixed(0)
                      : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between', padding: 2 }}
        >
          <Grid container item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* <Hidden smUp>
                <Grid item xs={12} md={7}>
                  <Card
                    sx={{
                      // width: '500px',
                      height: 400
                      // width: 600
                    }}
                  >
                    <div
                      style={{
                        padding: 15,
                        borderBottom: '1px solid #f1f3f4',
                        backgroundColor: '#F4F4F4'
                      }}
                    >
                      <b>Key Insights</b>
                    </div>
                    <div
                      style={{
                        height: '100%'
                      }}
                    >
                      {salesProductInfo !== null &&
                      Object.keys(salesProductInfo).length > 0 &&
                      treeMapData.length > 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'

                            // padding: '7px 33px',
                            // marginTop: -15
                          }}
                        >
                          <ReactApexChart
                            options={mapOptions}
                            series={mapSeries}
                            type="treemap"
                            // height={300}
                            // width="100%"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            width: '100%',
                            height: '80%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <span style={{ fontSize: 20 }}>
                            <b>No Data Available</b>
                          </span>
                        </div>
                      )}
                    </div>
                  </Card>
                </Grid>
              </Hidden> */}
            {/* <Hidden smDown> */}
            <Grid item xs={12} md={5}>
              <Card
                sx={{
                  // width: '500px',
                  marginTop: '20px',
                  height: 500
                  // width: 600
                }}
              >
                <div
                  style={{
                    padding: 15,
                    borderBottom: '1px solid #f1f3f4',
                    backgroundColor: '#F4F4F4'
                  }}
                >
                  <b>Key Insights</b>
                </div>
                <div
                  style={{
                    height: '100%'
                  }}
                >
                  {salesProductInfo !== null &&
                  Object.keys(salesProductInfo).length > 0 &&
                  treeMapData.length > 0 ? (
                    <div
                      style={{
                        height: '85%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'

                        // padding: '7px 33px',
                        // marginTop: -15
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          // height: '100%',
                          maxWidth: '100%'
                          // maxHeight: '100%'
                        }}
                      >
                        <ReactApexChart
                          options={mapOptions}
                          series={mapSeries}
                          type="treemap"
                          // height="100%"
                          // width="100%"
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: '80%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <span style={{ fontSize: 20 }}>
                        <b>No Data Available</b>
                      </span>
                    </div>
                  )}
                </div>
              </Card>
            </Grid>
            {/* </Hidden> */}
            <Grid item xs={12} md={6.7}>
              {getAudioList && getAudioList.results && getAudioList.results.length !== 0 ? (
                <Card sx={{ height: 500, marginTop: '20px' }}>
                  {/* <div style={{ height: '100%' }}> */}
                  {/* <div style={{ maxHeight: 500, overflowY: 'auto' }}> */}
                  {/* {getAudioList &&
                  getAudioList.all_audios &&
                  getAudioList.all_audios.length !== 0 ? ( */}
                  <>
                    <TableContainer style={{ maxHeight: 500, overflowY: 'auto' }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow
                            sx={{
                              backgroundColor: '#F4F4F4'
                            }}
                          >
                            <TableCell style={{ padding: 15, backgroundColor: '#F4F4F4' }}>
                              <b>Identified Leads</b>
                            </TableCell>
                            <TableCell style={{ padding: 15, backgroundColor: '#F4F4F4' }}>
                              <b>Status</b>
                            </TableCell>
                            <TableCell
                              style={{
                                padding: 15,
                                backgroundColor: '#F4F4F4'
                                // display: 'flex',
                                // justifyContent: 'center',
                                // alignItems: 'center'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                {filtered ? (
                                  <>
                                    <FilterAltIcon
                                      ref={filterref}
                                      style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                      fontSize="small"
                                      onClick={() => setIsOpen(!isOpen)}
                                    />
                                    <Menu
                                      open={isOpen}
                                      anchorEl={filterref.current}
                                      onClose={() => setIsOpen(false)}
                                      PaperProps={{
                                        sx: { width: 120, maxWidth: '100%', paddingLeft: 1 }
                                      }}
                                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    >
                                      <FormControl
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Divider />
                                        <RadioGroup
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          defaultValue={null}
                                          onChange={(e) => handleFilterSelect(e.target.value)}
                                          name="radio-buttons-group"
                                        >
                                          <FormControlLabel
                                            value="all"
                                            control={<Radio onClick={() => setIsOpen(false)} />}
                                            label="both"
                                          />
                                          <Divider />
                                          <FormControlLabel
                                            value="approved"
                                            control={<Radio onClick={() => setIsOpen(false)} />}
                                            label="approved"
                                          />
                                          <Divider />
                                          <FormControlLabel
                                            value="rejected"
                                            control={<Radio onClick={() => setIsOpen(false)} />}
                                            label="rejected"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Menu>
                                  </>
                                ) : null}
                                <b>Actions</b>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {filtered
                            ?.sort((a, b) => {
                              if (a.lead_status === 'approved') {
                                return -1; // move a to the beginning of the array
                              }
                              if (b.lead_status === 'approved') {
                                return 1; // move b to the beginning of the array
                              }
                              return 0; // keep the same order
                            })
                            .map((res, index) => {
                              // if (res.s3url !== '') {
                              return (
                                <TableRow>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    // style={{
                                    //   width: '100%'
                                    // }}
                                    align="left"
                                  >
                                    <Tip
                                      title={
                                        res?.s3url.split('/').pop().split('#')[0].split('?')[0]
                                      }
                                      followCursor
                                      placement="top"
                                    >
                                      <div style={{ marginBottom: 1 }}>
                                        <audio
                                          src={
                                            res.lead_status === 'approved'
                                              ? process.env.REACT_APP_ON_PREMISE === 'true'
                                                ? `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.s3url}`
                                                : res.s3url
                                              : ''
                                          }
                                          controls
                                          id="transAdio"
                                        >
                                          {/* <source
                                            // src={res.lead_status === 'approved' ? res.s3url : ''}
                                            
                                            type="audio/mp3"
                                          /> */}
                                          <track
                                            default
                                            kind="captions"
                                            srcLang="en"
                                            src="Positive lead"
                                          />
                                        </audio>
                                      </div>
                                    </Tip>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Chip
                                      label={res.deal_status !== '' ? res.deal_status : 'no deal'}
                                      sx={{ color: '#3892CF' }}
                                    />
                                  </TableCell>
                                  <TableCell align="right">
                                    <PositiveWordsMenu
                                      // positiveLeads={positiveLeads}
                                      setLeadStatusUpdate={setLeadStatusUpdate}
                                      leadStatusUpdate={leadStatusUpdate}
                                      getSalesProductInfo={getSalesProductInfo}
                                      getAudioListData={getIdentifiedListFilter}
                                      setAlert={setAlert}
                                      setAlertType={setAlertType}
                                      setAlertContent={setAlertContent}
                                      res={res}
                                      getAudioList={getAudioList}
                                      index={index}
                                      formik={formik}
                                      storedData={storedData}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                              // }
                              // return null;
                            })}
                        </TableBody>
                        {/* ) : (
                          <div
                            style={{
                              // width: '100%',
                              // height: '80%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        )} */}
                      </Table>
                      <Stack
                        spacing={2}
                        sx={{
                          padding: 2,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'end',
                          position: 'sticky',
                          bottom: 0, // or 'bottom: 0' if you want it to stick to the bottom
                          backgroundColor: 'white', // Add a background color if needed
                          zIndex: 1000
                        }}
                      >
                        <Pagination
                          classes={{
                            root: classes.ul
                          }}
                          count={getAudioList ? Math.ceil(getAudioList?.count / 10) : 1}
                          page={page}
                          onChange={handleChangePage}
                          showFirstButton
                          showLastButton
                        />
                      </Stack>
                    </TableContainer>
                  </>
                  {/* ) : (
                    <div
                      style={{
                        width: '100%',
                        height: '80%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <span style={{ fontSize: 20 }}>
                        <b>No Data Available</b>
                      </span>
                    </div>
                  )} */}
                  {/* </div> */}
                  {/* </div> */}

                  {/* </ResponsiveContainer> */}
                </Card>
              ) : (
                <Card sx={{ height: 500, marginTop: '20px' }}>
                  <div
                    style={{
                      padding: 15,
                      borderBottom: '1px solid #f1f3f4',
                      backgroundColor: '#F4F4F4',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <b>Identified Leads</b>
                    <b>Status</b>
                    <b>Actions</b>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '80%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <span style={{ fontSize: 20 }}>
                      <b>No Data Available</b>
                    </span>
                  </div>
                </Card>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4.2}>
            <Card
              sx={{
                // width: '475px',
                height: 400,
                marginTop: '20px'
                // marginTop: '19px'
                // marginLeft: 25,
                // backgroundColor: '#b1ddfc'
              }}
            >
              <div style={{ height: '100%' }}>
                <div
                  style={{
                    padding: 15,
                    borderBottom: '1px solid #f1f3f4',
                    backgroundColor: '#F4F4F4'
                  }}
                >
                  <b>Deal</b>
                </div>
                {dealStatus !== null && dealStatusShow ? (
                  <div
                    style={{
                      // height: '100%',
                      // width: '100%',
                      display: 'flex',
                      justifyContent: 'center'
                      // alignItems: 'center'
                    }}
                  >
                    <ReactApexChart
                      options={pieOptions}
                      series={pieSeries}
                      type="pie"
                      // height={400}
                      width={380}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: '80%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <span style={{ fontSize: 20 }}>
                      <b>No Data Available</b>
                    </span>
                  </div>
                )}
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} md={7.5}>
            <Card
              sx={{
                // width: '475px',
                height: 400,
                marginTop: '20px',
                // overflow: 'auto',
                position: 'relative'
                // marginLeft: 25,
                // backgroundColor: '#b1ddfc'
              }}
            >
              <div
                style={{
                  padding: 15,
                  borderBottom: '1px solid #f1f3f4',
                  backgroundColor: '#F4F4F4',
                  position: 'sticky'
                }}
              >
                <b>Task Status Table</b>
              </div>
              {agentTaskList.length > 0 ? (
                <Paper sx={{ width: '100%' }}>
                  <TableContainer sx={{ maxHeight: 345, overflowY: 'auto' }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: '600' }}>Agent</TableCell>
                          <TableCell style={{ fontWeight: '600' }}>Deal Name</TableCell>
                          <TableCell style={{ fontWeight: '600' }}>Deal Stage</TableCell>
                          <TableCell style={{ fontWeight: '600' }}>Task Name</TableCell>
                          <TableCell style={{ fontWeight: '600' }}>Created Date</TableCell>
                          <TableCell style={{ fontWeight: '600' }}>Task Status</TableCell>
                          <TableCell style={{ fontWeight: '600' }}>Aging</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{}}>
                        {agentTaskList.map((res) => {
                          console.log('agentTaskList innn', res);
                          return (
                            <TableRow>
                              <TableCell>{res.agent_name}</TableCell>
                              <TableCell>{res.deal_name}</TableCell>
                              <TableCell>{res.deal_stage}</TableCell>
                              <TableCell>{res.task_name}</TableCell>
                              <TableCell>{moment(res.created_date).format('DD/MM/YYYY')}</TableCell>
                              <TableCell>{res.task_status}</TableCell>
                              <TableCell>
                                {res.aging === 'no aging' ? '-' : `${Math.round(res.aging)} hrs`}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {/* <img src={TreeMap} width={150} height={150} alt="no contracts" /> */}
                  <span style={{ fontSize: 20 }}>
                    <b>No Data Available</b>
                  </span>
                </div>
              )}
            </Card>
          </Grid>
        </Grid>

        <Container>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleDialog}
            open={open}
            title="Transcript"
          >
            <Container
              style={{
                padding: 20
              }}
            >
              <div>
                {transcriptList[indexForTrans]?.split('\n').map((item) => (
                  <div>
                    <Typography>
                      <Highlighter
                        highlightClassName="YourHighlightClass"
                        highlightStyle={{ backgroundColor: 'rgba(82, 242, 125, 0.55)' }}
                        searchWords={highlightWords}
                        autoEscape
                        textToHighlight={item}
                      />
                    </Typography>
                  </div>
                ))}
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginY: '5px' }}>
                <Button
                  variant="contained"
                  onClick={(e) => handleDialog()}
                  style={{ marginRight: 5 }}
                >
                  Close
                </Button>
              </div>
            </Container>
          </CustomFormModel>
        </Container>
        <Container>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleTree}
            open={test}
            title="Call details"
          >
            <Container
              style={{
                padding: 20
              }}
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}>
                    <TableCell>Date</TableCell>
                    <TableCell align="left">Agent</TableCell>
                    <TableCell align="left">File Name</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="center">Audio</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {callDetails &&
                    Object.keys(callDetails).map((el) =>
                      callDetails[el].map((item, index) => (
                        <TableRow>
                          <TableCell>{moment(el, 'YYYY-MM-DD').format('DD/MM/YYYY')}</TableCell>
                          <TableCell>{item.agent_name}</TableCell>
                          <TableCell align="left">
                            {item.s3url.split('/').pop().split('#')[0].split('?')[0].length > 10 ? (
                              <Tip
                                title={item.s3url.split('/').pop().split('#')[0].split('?')[0]}
                                followCursor
                                placement="top"
                              >
                                <Typography
                                  sx={{ cursor: 'pointer', fontSize: '14px' }}
                                >{` ${item.s3url
                                  .split('/')
                                  .pop()
                                  .split('#')[0]
                                  .split('?')[0]
                                  .slice(0, 10)}.....`}</Typography>
                                {/* <span style={{ fontSize: 12 }}>{value.slice(0, 10)}.....</span> */}
                              </Tip>
                            ) : (
                              <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>
                                {item.s3url.split('/').pop().split('#')[0].split('?')[0]}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell align="left">{item.call_status}</TableCell>
                          <TableCell align="left">
                            <Box
                              style={{
                                width: '75%',
                                display: 'flex',
                                // justifyContent: 'center'
                                marginLeft: '50px'
                              }}
                            >
                              <audio
                                controls
                                id={`transAdio${index}`}
                                preload="auto"
                                src={
                                  process.env.REACT_APP_ON_PREMISE === 'true'
                                    ? `${process.env.REACT_APP_SERVER_MEDIA_URL}${item.s3url}`
                                    : item.s3url
                                }
                              >
                                <source
                                  src={
                                    process.env.REACT_APP_ON_PREMISE === 'true'
                                      ? `${process.env.REACT_APP_SERVER_MEDIA_URL}${item.s3url}`
                                      : item.s3url
                                  }
                                  type="audio/mp3"
                                />
                                <track default kind="captions" srcLang="en" />
                              </audio>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}

                  {/* <TableRow>
                    <TableCell>2</TableCell>
                    <TableCell>Rajesh.wav</TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginY: '5px' }}>
                <Button
                  variant="contained"
                  onClick={(e) => handleTree()}
                  style={{ marginRight: 5 }}
                >
                  Close
                </Button>
              </div>
            </Container>
          </CustomFormModel>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleMap}
            open={map}
            title="map details"
          >
            <Container
              style={{
                padding: 20
              }}
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}>
                    {/* <TableCell>Date</TableCell> */}
                    <TableCell align="left">sr.no</TableCell>
                    <TableCell align="left">Agent</TableCell>
                    <TableCell align="left">File Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {callDetails &&
                      Object.keys(callDetails).map((el) =>
                        callDetails[el].map((item, index) => ( */}
                  <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>agent name</TableCell>
                    <TableCell align="left">
                      {'utkarsh.wav'.length > 5 ? (
                        <Tip title="utkarsh.wav" followCursor placement="top">
                          <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>
                            utka....
                          </Typography>
                        </Tip>
                      ) : (
                        <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>
                          filename
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                  {/* ))
                      )} */}
                </TableBody>
              </Table>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginY: '5px' }}>
                <Button variant="contained" onClick={(e) => handleMap()} style={{ marginRight: 5 }}>
                  Close
                </Button>
              </div>
            </Container>
          </CustomFormModel>
        </Container>
        <CustomFormModel
          fullScreen={fullScreen}
          handleDialog={handleKeyInsightsPopup}
          open={keyInsightsOpen}
          title={
            // filteredUnpleasant && date
            true && `${insightName} Key Insights Data Details`
            // ||
            // (filteredTone && toneDate && 'Tone Data Details') ||
            // (filteredSentiment && typeof dataIndex === 'number' && 'Sentiments Data Details') ||
            // (filteredDeadAir && deadAirDate && 'DeadAir Data Details')
          }
        >
          <Container
            style={{
              padding: 0
              // marginTop: '-30px'
            }}
          >
            {!loading ? (
              <TableContainer sx={{ minWidth: 800, maxHeight: 360 }}>
                <Table stickyHeader aria-label="sticky table">
                  {true && (
                    // filteredUnpleasant && date
                    <UserListHead headLabel={KEY_WORDS_TABLE_HEAD} />
                  )}

                  {popupDataForInsights && popupDataForInsights?.results.length > 0 && (
                    <TableBody>
                      {popupDataForInsights.results.map((row, index) => {
                        const {
                          agent_name,
                          s3url,
                          input_id,
                          request_id,
                          org_id,
                          customer_id,
                          agent_id
                        } = row;

                        return (
                          <>
                            <TableRow hover key={1} tabIndex={-1} role="checkbox">
                              <TableCell>{agent_name}</TableCell>
                              <TableCell>
                                {moment(new Date(row.original_date), 'YYYY-MM-DD').format(
                                  'DD/MM/YYYY'
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <Link
                                  underline="hover"
                                  sx={{ cursor: 'pointer', color: '#3892CF' }}
                                  onClick={() => {
                                    navigate('/dashboard/workspace/Upload/Results', {
                                      state: {
                                        path: '/dashboard/app/sales',
                                        storedInfo: storedData
                                      }
                                    });
                                    setinputId(input_id);
                                    setinputOrgId(org_id);
                                    setrequestId(request_id);
                                    setinputOrgId(org_id);
                                    setrequestId(request_id);
                                    setUploadedAudio(
                                      decodeURI(s3url.split('/').pop().split('#')[0].split('?')[0])
                                    );
                                    setAudioS3(s3url);
                                    setProcess(formik.values.process);
                                    setcustomerId(customer_id);
                                    setOriginalDate(
                                      moment(new Date(row.original_date), 'YYYY-MM-DD').format(
                                        'DD/MM/YYYY'
                                      )
                                    );
                                    setAgentName(agent_name);
                                    setAgentLastName('');
                                    setAgentId(agent_id);
                                  }}
                                >
                                  <Tip
                                    title={decodeURI(
                                      s3url.split('/').pop().split('#')[0].split('?')[0]
                                    )}
                                    followCursor
                                    placement="top"
                                  >
                                    <Typography sx={{ fontWeight: 600 }}>
                                      {`${decodeURI(
                                        s3url.split('/').pop().split('#')[0].split('?')[0]
                                      ).slice(0, 14)}.....`}
                                    </Typography>
                                  </Tip>
                                </Link>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            ) : (
              <>
                {loading ? (
                  <Box
                    sx={{
                      height: 350,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <CircularProgress sx={{ color: '#3892CF' }} />
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    Data Not Available
                  </Box>
                )}
              </>
            )}
            {!loading && (
              <Stack
                spacing={2}
                sx={{
                  padding: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end'
                }}
              >
                <Pagination
                  classes={{
                    root: classes.ul
                  }}
                  count={popupDataForInsights ? Math.ceil(popupDataForInsights?.count / 10) : 1}
                  page={popupPage}
                  onChange={handleChangePageForpopup}
                  showFirstButton
                  showLastButton
                />
              </Stack>
            )}
          </Container>
        </CustomFormModel>
        <Notification
          message={alertContent}
          open={alert}
          severity={alertType}
          onClose={handleAlert}
        />
      </Grid>

      {loading && (
        <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress sx={{ color: '#3892CF' }} />
          </Box>
        </Backdrop>
      )}
    </>
  );
}

function PositiveWordsMenu({
  // positiveLeads,
  setLeadStatusUpdate,
  leadStatusUpdate,
  getAudioListData,
  getSalesProductInfo,
  setAlert,
  setAlertType,
  setAlertContent,
  res,
  getAudioList,
  index,
  formik,
  storedData
}) {
  // usecontext for navigation
  const {
    setinputId,
    setinputOrgId,
    setrequestId,
    setUploadedAudio,
    setuploadStatus,
    setAudioS3,
    uploadStatus,
    setProcess,
    setcustomerId,
    setOriginalDate,
    setAgentId,
    setAgentName,
    setAgentLastName,
    kpi,
    setKpi
  } = useContext(inputContex);
  console.log('res po positive', res);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  console.log('getAudioList m', getAudioList);

  const token = localStorage.getItem('token').toString();
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  const handleApprove = (request_id) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const payload = {
      request_id,
      status: 'approved'
    };

    API.post('/secure/lead_status', payload, config)
      .then((response) => {
        console.log('approve api', response);
        setAnchorEl(null);

        if (formik.values.customer === 'all') {
          setLeadStatusUpdate(leadStatusUpdate + 1);
        } else {
          getSalesProductInfo();
          getAudioListData();
        }

        setAlert(true);
        setAlertContent(`${response.data.message}`);
        setAlertType('success');
      })
      .catch((err) => {
        console.log('approve api', err);
        setAlert(true);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
      });
  };

  const handleReject = (request_id) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const payload = {
      request_id,
      status: 'rejected'
    };
    API.post('/secure/lead_status', payload, config)
      .then((response) => {
        setAnchorEl(null);
        // positiveLeads();

        if (formik.values.customer === 'all') {
          setLeadStatusUpdate(leadStatusUpdate + 1);
        } else {
          getSalesProductInfo();
          getAudioListData();
        }
        setAlert(true);
        setAlertContent(`${response.data.message}`);
        setAlertType('success');
      })
      .catch((err) => {
        setAlert(true);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
      });
  };
  const filterref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={filterref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={filterref.current}
        onClose={(event) => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} disabled={res.lead_status === 'approved'}>
          <ListItemText
            primary="Approve"
            primaryTypographyProps={{ variant: 'body2' }}
            onClick={() => {
              handleApprove(res.request_id);
              setIsOpen(false);
            }}
          />
        </MenuItem>

        <MenuItem
          sx={{ color: 'text.secondary' }}
          disabled={res.lead_status === 'rejected' || res.deal_status === ''}
        >
          <ListItemText
            primary="Reject"
            primaryTypographyProps={{ variant: 'body2' }}
            onClick={() => {
              handleReject(res.request_id);
              setIsOpen(false);
            }}
          />
        </MenuItem>

        <MenuItem to="#" sx={{ color: 'text.secondary' }} disabled={res.lead_status === 'rejected'}>
          <ListItemText
            primary="View"
            primaryTypographyProps={{ variant: 'body2' }}
            onClick={() => {
              navigate('/dashboard/workspace/Upload/Results', {
                state: {
                  path: '/dashboard/app/sales',
                  request_id: res.request_id,
                  customer_id: res.customer_id,
                  agent_id: res.agent_id,
                  org_id: res.org_id,
                  input_id: res.input_id,
                  positive_lead: res.positive_lead,
                  getAudioList: getAudioList,
                  idx: index,
                  // path: 'all_audios',
                  salesformik: formik.values,
                  storedInfo: storedData
                }
              });
              setinputId(res.input_id);
              setinputOrgId(res.org_id);
              setrequestId(res.request_id);
              setUploadedAudio(res.s3url);
              setAudioS3(res.s3url);
              setProcess();
              setcustomerId(res.customer_id);
              setOriginalDate(
                moment(new Date(res.original_date), 'YYYY-MM-DD').format('DD/MM/YYYY')
              );
              setKpi();
              setAgentName(res.agent_name);
              setAgentLastName();
              setAgentId(res.agent_id);
            }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  submitButton: {
    // marginTop: '23px',
    width: '100%',
    backgroundColor: '#3892CF',
    '&:hover': {
      backgroundColor: '#3892CF'
    }
    // [theme.breakpoints.down('md')]: {
    //   marginTop: 5
    // }
  },
  downloadIconGrid: {
    display: 'flex',
    alignItems: 'flex-end'
    // [theme.breakpoints.down('md')]: {
    //   justifyContent: 'flex-start',
    //   marginTop: 5
    // }
  },
  // cards: {
  //   [theme.breakpoints.down('md')]: {
  //     padding: 5
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     padding: 10
  //   }
  // },
  menuPaper: {
    maxHeight: 200
  }
}));
