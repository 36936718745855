import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import {
  Box,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  Card,
  Pagination,
  Stack
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import Highlighter from 'react-highlight-words';

const getAudioFileName = (fileName) => {
  if (fileName === null) {
    return '';
  }
  const nameArr = fileName.split('/');
  return nameArr[nameArr.length - 1];
};

const List = [
  {
    input_id: 226,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023075014.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023075014.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-10',
    request_start_time: '2023-10-10T07:50:14.651000Z',
    created_date: '2023-10-10T07:50:14.652000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-10T07:50:17.435000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 222,
    status: 'Process Completed',
    reason: null,
    transcript: 'Lorem ipsum dolor  , consectetur  elit. Sed nec in sapien  sagittis'
  },
  {
    input_id: 225,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023064738.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023064738.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-10',
    request_start_time: '2023-10-10T06:47:38.229000Z',
    created_date: '2023-10-10T06:47:38.230000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-10T06:47:41.202000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 221,
    status: 'Process Completed',
    reason: null,
    transcript: 'psum dolor sit amet, consectetur adipiscing . Sed nec  in sapien iaculis sagittis'
  },
  {
    input_id: 224,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023061619.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023061619.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-10',
    request_start_time: '2023-10-10T06:16:19.052000Z',
    created_date: '2023-10-10T06:16:19.053000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-10T06:16:22.192000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 220,
    status: 'Process Completed',
    reason: null,
    transcript: 'Lorem sit amet, consectetur  elit. Sed nec in sapien iaculis sagittis'
  },
  {
    input_id: 222,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_09102023141107.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_09102023141107.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-09',
    request_start_time: '2023-10-09T14:11:06.947000Z',
    created_date: '2023-10-09T14:11:06.948000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-09T14:11:10.081000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 218,
    status: 'Process Completed',
    reason: null,
    transcript: 'Lorem ipsum , consectetur adipiscing elit. risus in sapien iaculis sagittis'
  },
  {
    input_id: 226,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023075014.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023075014.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-10',
    request_start_time: '2023-10-10T07:50:14.651000Z',
    created_date: '2023-10-10T07:50:14.652000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-10T07:50:17.435000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 222,
    status: 'Process Completed',
    reason: null,
    transcript: 'Lorem ipsum dolor  , consectetur  elit. Sed nec in sapien  sagittis'
  },
  {
    input_id: 225,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023064738.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023064738.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-10',
    request_start_time: '2023-10-10T06:47:38.229000Z',
    created_date: '2023-10-10T06:47:38.230000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-10T06:47:41.202000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 221,
    status: 'Process Completed',
    reason: null,
    transcript: 'psum dolor sit amet, consectetur adipiscing . Sed nec  in sapien iaculis sagittis'
  },
  {
    input_id: 224,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023061619.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023061619.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-10',
    request_start_time: '2023-10-10T06:16:19.052000Z',
    created_date: '2023-10-10T06:16:19.053000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-10T06:16:22.192000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 220,
    status: 'Process Completed',
    reason: null,
    transcript: 'Lorem sit amet, consectetur  elit. Sed nec in sapien iaculis sagittis'
  },
  {
    input_id: 222,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_09102023141107.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_09102023141107.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-09',
    request_start_time: '2023-10-09T14:11:06.947000Z',
    created_date: '2023-10-09T14:11:06.948000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-09T14:11:10.081000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 218,
    status: 'Process Completed',
    reason: null,
    transcript: 'Lorem ipsum , consectetur adipiscing elit. risus in sapien iaculis sagittis'
  },
  {
    input_id: 226,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023075014.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023075014.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-10',
    request_start_time: '2023-10-10T07:50:14.651000Z',
    created_date: '2023-10-10T07:50:14.652000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-10T07:50:17.435000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 222,
    status: 'Process Completed',
    reason: null,
    transcript: 'Lorem ipsum dolor  , consectetur  elit. Sed nec in sapien  sagittis'
  },
  {
    input_id: 225,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023064738.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023064738.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-10',
    request_start_time: '2023-10-10T06:47:38.229000Z',
    created_date: '2023-10-10T06:47:38.230000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-10T06:47:41.202000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 221,
    status: 'Process Completed',
    reason: null,
    transcript: 'psum dolor sit amet, consectetur adipiscing . Sed nec  in sapien iaculis sagittis'
  },
  {
    input_id: 224,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023061619.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023061619.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-10',
    request_start_time: '2023-10-10T06:16:19.052000Z',
    created_date: '2023-10-10T06:16:19.053000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-10T06:16:22.192000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 220,
    status: 'Process Completed',
    reason: null,
    transcript: 'Lorem sit amet, consectetur  elit. Sed nec in sapien iaculis sagittis'
  },
  {
    input_id: 222,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_09102023141107.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_09102023141107.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-09',
    request_start_time: '2023-10-09T14:11:06.947000Z',
    created_date: '2023-10-09T14:11:06.948000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-09T14:11:10.081000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 218,
    status: 'Process Completed',
    reason: null,
    transcript: 'Lorem ipsum , consectetur adipiscing elit. risus in sapien iaculis sagittis'
  },
  {
    input_id: 226,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023075014.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023075014.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-10',
    request_start_time: '2023-10-10T07:50:14.651000Z',
    created_date: '2023-10-10T07:50:14.652000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-10T07:50:17.435000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 222,
    status: 'Process Completed',
    reason: null,
    transcript: 'Lorem ipsum dolor  , consectetur  elit. Sed nec in sapien  sagittis'
  },
  {
    input_id: 225,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023064738.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023064738.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-10',
    request_start_time: '2023-10-10T06:47:38.229000Z',
    created_date: '2023-10-10T06:47:38.230000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-10T06:47:41.202000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 221,
    status: 'Process Completed',
    reason: null,
    transcript: 'psum dolor sit amet, consectetur adipiscing . Sed nec  in sapien iaculis sagittis'
  },
  {
    input_id: 224,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023061619.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_10102023061619.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-10',
    request_start_time: '2023-10-10T06:16:19.052000Z',
    created_date: '2023-10-10T06:16:19.053000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-10T06:16:22.192000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 220,
    status: 'Process Completed',
    reason: null,
    transcript: 'Lorem sit amet, consectetur  elit. Sed nec in sapien iaculis sagittis'
  },
  {
    input_id: 222,
    customer_id: 7,
    domain_id: 1,
    agent_id: 32,
    input_file: null,
    s3url:
      '/media/s3%3A/test-audios-garima/uipath/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_09102023141107.mp3',
    aws_services: false,
    custom_ai_services: true,
    s3filepath:
      '/media/s3%3A/test-audios-garima/voices/4/7/None/32/0N536AP12P2RREV9D0VDAHFNR403HHQ6_09102023141107.mp3',
    processes: ['Automation'],
    organisation_id: 4,
    organisation_name: 'Tata',
    original_date: '2023-10-09',
    request_start_time: '2023-10-09T14:11:06.947000Z',
    created_date: '2023-10-09T14:11:06.948000Z',
    created_by: 'intelliconvo@mailinator.com',
    modified_by: '',
    modified_date: '2023-10-09T14:11:10.081000Z',
    customer_organisation_name: 'TataTwo',
    kpi_services: ['tone', 'unpleasant', 'dead_air', 'sentiments'],
    dashboard_services: ['collections', 'sales', 'compliance'],
    first_name: 'mahindra',
    last_name: 'dhoni',
    request_id: 218,
    status: 'Process Completed',
    reason: null,
    transcript: 'Lorem ipsum , consectetur adipiscing elit. risus in sapien iaculis sagittis'
  }
];

function Search() {
  // states

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [transcriptList, setTranscriptList] = useState(List);
  const [showQueryMessage, setShowQueryMessage] = useState(false);

  console.log('transcriptList', transcriptList);

  const useStyles = makeStyles(() => ({
    ul: {
      '& .MuiPaginationItem-root': {
        '&.Mui-selected': {
          background: '#3892CF',
          color: 'white'
          // borderRadius: '50%',
        }
      }
    }
  }));

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ marginBottom: 3 }}>
        Transcript Search
      </Typography>
      <Card>
        <OutlinedInput
          sx={{
            // width: '30%',
            margin: '11px 11px 5px',
            '& fieldset': {
              borderWidth: `1px !important`,
              borderColor: `#dce0e4 !important`
            }
          }}
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
            setPage(1);
            // List.filter((el) => el.transcript.find(e.target.value));
            if (e.target.value.length === 0) {
              setTranscriptList([]);
              setShowQueryMessage(false);
            } else {
              const newList = List.filter((item) =>
                item.transcript.toLowerCase().includes(e.target.value.toLowerCase())
              );
              if (newList.length > 0) {
                setTranscriptList(newList);
              } else {
                setTranscriptList([]);
                setShowQueryMessage(true);
              }
            }
          }}
          // placeholder={placeholder}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
        {query.length > 0 && (
          <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 10px 10px' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 10 }}>
              <b>{transcriptList.length}&nbsp;</b>
            </Typography>
            <Typography sx={{ fontSize: '14px', color: 'grey' }}>results Found</Typography>
          </div>
        )}
        <TableContainer>
          <Table>
            <TableHead sx={{ backgroundColor: '#edeff1' }}>
              <TableRow>
                {/* <TableCell
                  align="center"
                  style={{
                    whiteSpace: 'nowrap',
                    maxWidth: '80px', // Adjust the max width as needed
                    overflow: 'hidden',
                    textOverflow: 'ellipsis' // Add ellipsis for truncation
                  }}
                >
                  Request Id
                </TableCell> */}
                <TableCell
                  align="center"
                  style={{
                    whiteSpace: 'nowrap',
                    maxWidth: '80px', // Adjust the max width as needed
                    overflow: 'hidden',
                    textOverflow: 'ellipsis' // Add ellipsis for truncation
                  }}
                >
                  Agent Name
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    whiteSpace: 'nowrap',
                    maxWidth: '80px', // Adjust the max width as needed
                    overflow: 'hidden',
                    textOverflow: 'ellipsis' // Add ellipsis for truncation
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    whiteSpace: 'nowrap',
                    maxWidth: '80px', // Adjust the max width as needed
                    overflow: 'hidden',
                    textOverflow: 'ellipsis' // Add ellipsis for truncation
                  }}
                >
                  File Name
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    whiteSpace: 'nowrap',
                    maxWidth: '80px', // Adjust the max width as needed
                    overflow: 'hidden',
                    textOverflow: 'ellipsis' // Add ellipsis for truncation
                  }}
                >
                  Transcript
                </TableCell>
              </TableRow>
            </TableHead>
            {transcriptList && (
              <TableBody>
                {transcriptList.slice((page - 1) * 10, (page - 1) * 10 + 10).map((el) => (
                  <>
                    <TableRow
                    // sx={{
                    //   '&:hover': {
                    //     boxShadow: '0 0 11px rgba(33,33,33,.2)',
                    //     transform: 'scale3d(1.05, 1.05, 1)',
                    //     cursor: 'pointer'
                    //   }
                    // }}
                    >
                      {/* <TableCell
                        align="center"
                        style={{
                          whiteSpace: 'nowrap',
                          maxWidth: '80px', // Adjust the max width as needed
                          overflow: 'hidden',
                          textOverflow: 'ellipsis' // Add ellipsis for truncation
                        }}
                      >
                        {el.request_id}
                      </TableCell> */}
                      <TableCell
                        align="center"
                        style={{
                          whiteSpace: 'nowrap',
                          maxWidth: '80px', // Adjust the max width as needed
                          overflow: 'hidden',
                          textOverflow: 'ellipsis' // Add ellipsis for truncation
                        }}
                        title={`${el.first_name} ${el.last_name}`}
                      >
                        {el.first_name} {el.last_name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          whiteSpace: 'nowrap',
                          maxWidth: '80px', // Adjust the max width as needed
                          overflow: 'hidden',
                          textOverflow: 'ellipsis' // Add ellipsis for truncation
                        }}
                      >
                        {moment(el.original_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          whiteSpace: 'nowrap',
                          maxWidth: '80px', // Adjust the max width as needed
                          overflow: 'hidden',
                          textOverflow: 'ellipsis' // Add ellipsis for truncation
                        }}
                        title={getAudioFileName(el.s3url)}
                      >
                        {getAudioFileName(el.s3url)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          whiteSpace: 'nowrap',
                          maxWidth: '200px', // Adjust the max width as needed
                          overflow: 'hidden',
                          textOverflow: 'ellipsis' // Add ellipsis for truncation
                        }}
                        title={el.transcript}
                      >
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          // highlightStyle={{ backgroundColor: '#ff6666' }}
                          // highlightStyle={{
                          //   backgroundColor: `${concatData.map((item) => {
                          //     const isUnpleasant = Object.keys(
                          //       getUnpleasantWord.customer_data
                          //     ).find((ele) => ele === item);
                          //     console.log('isUnpleasant ----', isUnpleasant);
                          //     // if (isUnpleasant) {
                          //     //   return '#ff6666';
                          //     // }
                          //     // return 'blue';
                          //     return isUnpleasant;
                          //   })}`
                          //     ? '#ff6666'
                          //     : 'blue'
                          // }}
                          searchWords={query.split(' ')}
                          autoEscape
                          textToHighlight={el.transcript}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {transcriptList.length > 0 && (
          <Stack
            spacing={2}
            sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
          >
            <Pagination
              classes={{
                root: classes.ul
              }}
              count={Math.ceil(transcriptList.length / 10)}
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
        )}

        {transcriptList.length === 0 && showQueryMessage ? (
          <div
            style={{
              margin: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography sx={{ marginBottom: 1, fontSize: 18 }}>
              <h4>Not Found</h4>
            </Typography>
            <Typography sx={{ color: 'grey' }}>No results found for "{query}"</Typography>
            <Typography sx={{ color: 'grey' }}>
              Try checking for typos or using complete words.
            </Typography>
          </div>
        ) : (
          transcriptList.length === 0 &&
          !showQueryMessage && (
            <div
              style={{
                margin: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img src="https://minimals.cc/assets/icons/empty/ic_content.svg" alt="not found" />
              <Typography
                sx={{
                  margin: '8px 0px 0px',
                  fontWeight: 700,
                  // lineHeight: 1.55556,
                  fontSize: 21,
                  fontFamily: '"Public Sans", sans-serif',
                  color: 'rgb(145, 158, 171)',
                  textAlign: 'center'
                }}
              >
                No Data
              </Typography>
            </div>
          )
        )}
      </Card>
    </>
  );
}

export default Search;
