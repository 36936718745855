import PropTypes from 'prop-types';
// material
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
// ----------------------------------------------------------------------

UserListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func
};

export default function UserListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  handleDialog
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const showToolTip = (value) => {
    if (value.length > 10) {
      return (
        <Tooltip title={value} followCursor placement="top">
          <TableCell align="left" sx={{ cursor: 'pointer' }}>
            <span>{value.slice(0, 10)}.....</span>
          </TableCell>
        </Tooltip>
      );
    }
    return <TableCell align="left">{value}</TableCell>;
  };

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : (headCell.alignCenter && 'center') || 'left'}
            sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa', whiteSpace: 'nowrap' }}
            // style={{
            //   whiteSpace: 'nowrap',
            //   maxWidth: '80px', // Adjust the max width as needed
            //   overflow: 'hidden',
            //   textOverflow: 'ellipsis' // Add ellipsis for truncation
            // }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
