/* eslint-disable prefer-arrow-callback */
/* eslint-disable react/no-this-in-sfc */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useFormik } from 'formik';
// material
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Container, Stack, Typography } from '@mui/material';
// Components
import Page from '../../components/Page';
import API from '../../E2E/axios.util';
import { CustomFormModel } from '../../components/custom-form-model/CustomFormModel';
import DomainForm from './DomainForm';
import DomainDataModel from './DomainDataModel';
import { Notification } from '../../components/Notification/Notification';

export default function Domain() {
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [open, setOpen] = useState();
  const [update, setUpdate] = useState(0);
  const [keywordList, setKeywordList] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const userDetails = JSON.parse(localStorage.getItem('user'));

  const DomainSchema = Yup.object().shape({
    organisation: Yup.string().required('This field is required.'),
    customerOrganisation: Yup.string().required('This field is required.'),
    domainCategories: Yup.array().of(
      Yup.string()
        .required('This field is required.')
        // .min(3, 'Category must be at least 3 characters.')
        // .max(15, 'Category must not exceed 15 characters')
        .test('unique-category', 'Category should be unique.', function (value) {
          const categories = formik.values.domainCategories.map((category) =>
            category?.toLowerCase()
          );
          const lowercaseValue = value?.toLowerCase();

          return categories.filter((category) => category === lowercaseValue).length === 1;
        })
    ),
    // requestSentences: Yup.array().of(
    //   Yup.object({
    //     sentence: Yup.array()
    //       .min(1, 'This field is required.')
    //       .of(Yup.string().required('This field is required.')),
    //     Category: Yup.string()
    //       .required('This field is required.')
    //       .min(3, 'Category must be at least 3 characters.')
    //       .max(15, 'Category must not exceed 15 characters')
    //       .test('unique-category', 'Category should be unique.', function (value) {
    //         const categories = formik.values.requestSentences.map((sentence) =>
    //           sentence.Category?.toLowerCase()
    //         );
    //         const lowercaseValue = value?.toLowerCase();

    //         return categories.filter((category) => category === lowercaseValue).length === 1;
    //       })
    //   })
    // ),
    // complaintSentences: Yup.array().of(
    //   Yup.object({
    //     sentence: Yup.array()
    //       .min(1, 'This field is required.')
    //       .of(Yup.string().required('This field is required.')),
    //     Category: Yup.string()
    //       .required('This field is required.')
    //       .min(3, 'Category must be at least 3 characters.')
    //       .max(15, 'Category must not exceed 15 characters')
    //       .test('unique-category', 'Category should be unique.', function (value) {
    //         const categories = formik.values.complaintSentences.map((sentence) =>
    //           sentence.Category?.toLowerCase()
    //         );
    //         const lowercaseValue = value?.toLowerCase();

    //         return categories.filter((category) => category === lowercaseValue).length === 1;
    //       })
    //   })
    // ),
    domain: Yup.object()
      .shape({
        // Ensure there is at least one key in the object
      })
      .required('This field is required.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      organisation: `${userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : ''}`,
      customerOrganisation: userDetails.role === 'QA' ? userDetails.customer_id : '',
      domain: '',
      domainCategories: ['']
    },
    validationSchema: DomainSchema,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        // processes: formik.values.process,
        org_id: formik.values.organisation,
        customer_id: formik.values.customerOrganisation,
        domain_id: formik.values.domain.domain_id,
        domain_name: formik.values.domain.domain_name,
        categories: formik.values.domainCategories
        // requestSentences: formik.values.requestSentences,
        // complaintSentences: formik.values.complaintSentences
      };
      await API.post('/secure/add_domain_category', data, config)
        .then((res) => {
          formik.resetForm();
          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          handleDialog();
          setUpdate(update + 1);
        })
        .catch((err) => {
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });

  const handleDialog = () => {
    setOpen(!open);
    formik.resetForm();
  };

  const handleAlert = () => {
    setAlert(false);
  };

  return (
    <Page title="Domain">
      <>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Domain Category {keywordList && keywordList.count && `(${keywordList.count})`}
          </Typography>
          {userDetails.role !== 'Manager' && (
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={handleDialog}
              sx={{
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              New Domain Category
            </Button>
          )}
        </Stack>
        <Container>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleDialog}
            open={open}
            title="Add Domain Category"
          >
            <DomainForm handleDialog={handleDialog} formik={formik} />
          </CustomFormModel>
        </Container>
        <DomainDataModel
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          handleDialog={handleDialog}
          formik={formik}
          isUpdated={update}
          updateComponent={setUpdate}
          keywordList={keywordList}
          setKeywordList={setKeywordList}
        />
      </>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}
