/* eslint-disable   no-unneeded-ternary */
import React, { useState, useRef, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { FormikProvider, Form } from 'formik';
import {
  Grid,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Container,
  FormHelperText,
  Alert,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { LoadingButton } from '@mui/lab';
import LinearProgress, { LinearProgressProps as props } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import DatePicker from 'react-datepicker';
import './UploadForm.css';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import API from '../../E2E/axios.util';
import { Notification } from '../../components/Notification/Notification';
// components

// ----------------------------------------------------------------------

const UploadForm = ({ formik, handleDialog, uploaded }) => {
  const inputRef = useRef(null);
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const [userAudio, setuserAudio] = useState({ File_Name: '', Data: '' });

  const userDetails = JSON.parse(localStorage.getItem('user'));

  /* ----- Creating state for customer, process and agent ------ */
  const [customerList, setcustomerList] = useState();
  const [processList, setprocessList] = useState();
  const [categoryList, setCategoryList] = useState();
  const [disposition, setDisposition] = useState();
  const [campaignList, setcampaignList] = useState([]);
  const [agentList, setagentList] = useState();
  const [OrganisationList, setOrganisationList] = useState();
  const [shownCustomer, setShownCustomer] = useState();
  const [checkInDate, setCheckInDate] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');

  const handleCancel = () => {
    handleDialog();
    formik.resetForm();
  };
  const handleAlert = () => {
    setAlert(false);
  };

  const handleAudioFileChange = (event, fileName) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (fileReader.readyState === 2) {
        const file = e.target.result;
        setuserAudio({ File_Name: fileName.name, Data: file });
        formik.setFieldValue(`uploadFile`, { File_Name: fileName.name, Data: file });
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/list_of_customer?organisation_id=${formik.values.organisationId}`,
      config
    )
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.organisationId !== '') {
      getCustomerList();
    } else {
      setcustomerList([]);
    }
  }, [formik.values.organisationId]);

  const getProccesList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `secure/process_list?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisationId}`,
        config
      );
    }
    function getCategory() {
      return API.get(
        `/secure/get_categories?customer_id=${formik.values.customer}&org_id=${formik.values.organisationId}`,
        config
      );
    }
    Promise.all([getProcess(), getCategory()])
      .then(([processData, categoryData]) => {
        setprocessList(processData.data.processes);
        setCategoryList(categoryData.data.categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCampaignList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getCampaign() {
      return API.get(
        `secure/get_campaign?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisationId}`,
        config
      );
    }
    Promise.all([getCampaign()])
      .then(([processData]) => {
        setcampaignList(processData.data.campaign_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.customer !== '') {
      getProccesList();
      getCampaignList();
    } else {
      setprocessList([]);
      setcampaignList([]);
    }
  }, [formik.values.customer]);

  const getAgentList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getRole() {
      return API.get(
        `secure/list_of_agent?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisationId}&processes=${formik.values.process}`,
        config
      );
    }
    Promise.all([getRole()])
      .then(([agentData]) => {
        setagentList(agentData.data.agent_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log(formik.values.process, 'formik.values.process');
    if (formik.values.process.length !== 0) {
      getAgentList();
    } else {
      setagentList([]);
    }
  }, [formik.values.process]);

  const setCustomerValue = (value) => {
    setShownCustomer(value);
    formik.setFieldValue('campaign', null);
    formik.setFieldValue('process', []);
    formik.setFieldValue('agent', '');
    formik.setFieldValue('domainId', value.domain_id);
    formik.setFieldValue('customer', value.customer_id);
    // setcampaignList([]);
  };

  const handleProcess = (processValue) => {
    formik.setFieldValue('agent', '');
    formik.setFieldValue('process', [processValue]);
  };
  const handleCampaign = (campaign) => {
    formik.setFieldValue('campaign', campaign);
  };

  // ---------------------------------------------
  const [audios, setAudios] = useState(formik.values.uploadFile);
  const audioChange = (e) => {
    for (let i = 0; i < e.target.files.length; i += 1) {
      const file_type = e.target.files[i].type.substring(0, 6);
      const file_size = e.target.files[i].size / 1024;
      if (file_type !== 'audio/') {
        setAlertContent('Invalid file');
        setAlertType('error');
        setAlert(true);
        return;
      }
      if (file_size < 50) {
        inputRef.current.value = null;
        setAlertContent('File size should be greater than 50kb');
        setAlertType('error');
        setAlert(true);
        return;
      }
    }

    // console.log('e.target.files --------->', e.target.files);

    for (
      let i = 0;
      i < process.env.REACT_APP_FILEUPLOAD_LIMIT - formik.values.uploadFile.length;
      // i < e.target.files.length;
      i += 1
    ) {
      // Closure to capture the file information.
      (function (file) {
        if (file && file.size / 1024 > 50) {
          const reader = new FileReader();
          const fileName = file.name;

          reader.onload = function (upload) {
            const img = upload.target.result;
            if (!formik.values.uploadFile.includes(img)) {
              setAudios((prevData) => [...prevData, { File_Name: fileName, Data: img }]);
            }
          };
          reader.readAsDataURL(file);
        }
      })(e.target.files[i]);
    }

    if (
      e.target.files.length >
      process.env.REACT_APP_FILEUPLOAD_LIMIT - formik.values.uploadFile.length
    ) {
      setAlertContent(`Maximum ${process.env.REACT_APP_FILEUPLOAD_LIMIT} files can uploaded`);
      setAlertType('error');
      setAlert(true);
    }
  };

  useEffect(() => {
    formik.setFieldValue('uploadFile', audios);
    inputRef.current.value = null;
  }, [audios]);

  const audioRemove = (index) => {
    let images1 = Array.from(formik.values.uploadFile);
    images1 = images1.filter((img) => images1.indexOf(img) !== index);
    setAudios(images1);
  };

  const setDateFormat = (date) => {
    const formatStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return formatStartDate;
  };

  const CustomInput = React.forwardRef((props, ref) => (
    <div style={{ position: 'relative' }}>
      <input {...props} ref={ref} />
      <CalendarMonthIcon
        onClick={props.onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '10px',
          // pointerEvents: 'none',
          cursor: 'pointer',
          zIndex: 100
        }}
      />
    </div>
  ));

  const handleCheckInDate = (date) => {
    setCheckInDate(date);
    const start_date = setDateFormat(date);
    formik.setFieldValue('createDate', start_date);
  };

  // console.warn('audio file ---', formik.values.inputFile);
  // console.log('formik.values.uploadFile', formik.values.uploadFile.length);

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));
  const classes = useStyles();
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={6}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Select
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  value={formik.values.organisation}
                  onChange={(e) => {
                    formik.setFieldValue('campaign', '');
                    formik.setFieldValue('process', []);
                    formik.setFieldValue('agent', '');
                    formik.setFieldValue('customer', '');
                    setShownCustomer('');
                    formik.setFieldValue('organisationId', e.target.value);
                  }}
                  error={Boolean(touched.organisationId && errors.organisationId)}
                  helperText={touched.organisationId && errors.organisationId}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {OrganisationList
                    ? OrganisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.organisationId && errors.organisationId}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' ||
            userDetails.role === 'Agent' ||
            userDetails.role === 'Customer_Admin' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Select
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  value={shownCustomer ? shownCustomer : ''}
                  error={Boolean(touched.customer && errors.customer)}
                  helperText={touched.customer && errors.customer}
                  onChange={(e) => {
                    setCustomerValue(e.target.value);
                  }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {customerList
                    ? customerList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={element}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.customer && errors.customer}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          {userDetails?.Submenus.includes('Call_analysis') &&
            process.env.REACT_APP_TO_SHOW_QRC === 'true' && (
              <Grid item xs={6}>
                {userDetails.role === 'Agent' ? (
                  <TextField
                    required
                    fullWidth
                    label="Select Process"
                    defaultValue={userDetails.processes[0]}
                    disabled
                  />
                ) : (
                  <FormControl fullWidth>
                    <InputLabel id="process-select-label">Select Disposition</InputLabel>
                    <Select
                      labelId="process-select-label"
                      label="Select Disposition"
                      id="select-process"
                      name="disposition"
                      value={disposition}
                      error={Boolean(touched.disposition && errors.disposition)}
                      helperText={touched.disposition && errors.disposition}
                      onChange={(e) => {
                        formik.setFieldValue('disposition', e.target.value);
                        setDisposition(e.target.value);
                      }}
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                      {categoryList
                        ? categoryList.map((element) => (
                            <MenuItem key={element} value={element}>
                              {element}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {touched.disposition && errors.disposition}
                    </FormHelperText>
                  </FormControl>
                )}
              </Grid>
            )}
          {userDetails.Submenus.includes('Revenue_intelligence') && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="campaign-select-label">Select Campaign</InputLabel>
                <Select
                  labelId="campaign-select-label"
                  label="Select Campaign (Optional)"
                  id="select-campaign"
                  name="campaign"
                  value={formik.values.campaign}
                  error={Boolean(touched.campaign && errors.campaign)}
                  helperText={touched.campaign && errors.campaign}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                  // onChange={(e) => handleCampaign(e.target.value)}
                >
                  {campaignList.length > 0 ? (
                    campaignList.map((element) => {
                      const { campaign_id, campaign_name } = element;
                      return (
                        <MenuItem
                          key={campaign_id}
                          value={campaign_id}
                          onClick={() => formik.setFieldValue('campaign', campaign_id)}
                        >
                          {campaign_name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>
                      <i>No Campaign Available</i>
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.campaign && errors.campaign}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={6}>
            <FormControl fullWidth>
              <DatePicker
                id="datepicker"
                selected={checkInDate}
                onChange={handleCheckInDate}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomInput />}
                placeholderText="Select Date *"
                error={Boolean(touched.createDate && errors.createDate)}
                helperText={touched.createDate && errors.createDate}
              />
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.createDate && errors.createDate}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'Agent' ? (
              <TextField
                required
                fullWidth
                label="Select Process"
                defaultValue={userDetails.processes[0]}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="process-select-label">
                  Select Process
                </InputLabel>
                <Select
                  required
                  labelId="process-select-label"
                  label="Select Process"
                  id="select-process"
                  name="process"
                  value={formik.values.process}
                  error={Boolean(touched.process && errors.process)}
                  helperText={touched.process && errors.process}
                  onChange={(e) => handleProcess(e.target.value)}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {processList
                    ? processList.map((element) => (
                        <MenuItem key={element} value={element}>
                          {element}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.process && errors.process}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>

          <Grid item xs={6}>
            {userDetails.role === 'Agent' ? (
              <TextField
                required
                fullWidth
                label="Select Agent"
                defaultValue={`${userDetails.first_name} ${userDetails.last_name}`}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="process-select-label">
                  Select Agent
                </InputLabel>
                <Select
                  required
                  labelId="process-select-label"
                  label="Select Agent"
                  id="select-process"
                  value={formik.values.agent}
                  // {...getFieldProps('agent')}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    const selectedQA = agentList.find(
                      (element) => element.user_id === selectedValue
                    );

                    if (selectedQA) {
                      // const { qa_name, manager_name } = selectedQA;
                      formik.setFieldValue('agent', selectedValue);
                      // formik.setFieldValue('qa_name', 'test_qa');
                      // formik.setFieldValue('manager_name', 'test_manager');
                    }
                  }}
                  error={Boolean(touched.agent && errors.agent)}
                  helperText={touched.agent && errors.agent}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {agentList
                    ? agentList.map((element) => {
                        const { user_id, first_name, last_name, empcode } = element;
                        return (
                          <MenuItem key={user_id} value={user_id}>
                            {empcode} - {first_name} {last_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.agent && errors.agent}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {formik.values.uploadFile.length < 20 ? (
              <LoadingButton
                fullWidth
                variant="contained"
                // size="large"
                sx={{
                  height: 50,
                  marginTop: 1,
                  backgroundColor: '#3892CF',
                  '&:hover': {
                    backgroundColor: '#3892CF'
                  }
                }}
                onClick={() => {
                  inputRef.current?.click();
                }}
              >
                {!userAudio.File_Name ? 'Upload Audio' : userAudio.File_Name}
                <input
                  type="file"
                  hidden
                  accept="audio/*"
                  multiple
                  ref={inputRef}
                  onChange={(event) => {
                    // handleAudioFileChange(event, event.currentTarget.files[0]);
                    console.log('files data ----', event);
                    audioChange(event);
                  }}
                  required
                />
              </LoadingButton>
            ) : (
              <LoadingButton
                onClick={() => {
                  setAlertContent('Maximum File Upload limit is 20');
                  setAlertType('error');
                  setAlert(true);
                }}
                fullWidth
                variant="contained"
                // size="large"
                sx={{
                  height: 50,
                  marginTop: 1,
                  backgroundColor: '#3892CF',
                  '&:hover': {
                    backgroundColor: '#3892CF'
                  }
                }}
              >
                {!userAudio.File_Name ? 'Upload Audio' : userAudio.File_Name}
              </LoadingButton>
            )}
          </Grid>
          <br />
          {formik.values.uploadFile.map((data, key) => {
            console.log('images inside map ------', data);
            return (
              <Alert
                severity="info"
                key={key}
                icon={false}
                sx={{ marginLeft: '23px', marginY: '5px' }}
                onClose={() => {
                  audioRemove(key);
                }}
              >
                {data.File_Name}
              </Alert>
            );
          })}
          {/* {formik.values.uploadFile.map((data, key) => (
            <Grid item xs={6}>
              <Alert
                severity="info"
                key={key}
                icon={false}
                sx={{ marginY: '10px' }}
                onClose={() => {
                  audioRemove(key);
                }}
              >
                {data.File_Name}
              </Alert>
            </Grid>
          ))} */}
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleCancel}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={isSubmitting}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Submit
          </LoadingButton>
          {isSubmitting && (
            <Grid style={{ marginLeft: '5px', display: 'flex', justifyContent: 'flex-end' }}>
              <LinearProgressWithLabel variant="determinate" value={uploaded} />
            </Grid>
          )}
        </Container>
        <Notification
          message={alertContent}
          open={alert}
          severity={alertType}
          onClose={handleAlert}
        />
      </Form>
    </FormikProvider>
  );
};

export default UploadForm;

function LinearProgressWithLabel(props, value) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {/* <Box sx={{ width: '50%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box> */}
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
