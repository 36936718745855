import * as Yup from 'yup';
import { useState, useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import API from '../../../E2E/axios.util';
import { Notification } from '../../Notification/Notification';
import inputContex from '../../../Context/UploadContext/inputContext';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const { userData, setuserDetails } = useContext(inputContex);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address.')
      .trim()
      .matches(/^[\w-.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, 'Email must be a valid email address.')
      .max(120, 'Email must be at most 120 characters long.') // Limit email length to 254 characters
      .required('Email is required.'),

    password: Yup.string()
      .max(50, 'Password must be at most 50 characters long.') // Limit password length to 128 characters
      .required('Password is required.')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      await handleRequest(formik);
    }
  });

  const handleRequest = async (formik) => {
    const data = {
      email: formik.values.email,
      password: formik.values.password
    };
    console.log(data);
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8;application/json'
    };
    await API.post('auth/user/login', data, headers)
      .then((res) => {
        // console.log('res--', res);

        if (res.data.token) {
          if (res.data.role !== 'Super_Admin') {
            // API For Tenure updation on each login
            const config = {
              headers: { Authorization: `Bearer ${res.data.token}` }
            };
            API.get(`/secure/tenure_update`, config);
          }
          localStorage.setItem('token', res.data.token);
          setuserDetails(res.data);
          localStorage.setItem('userId', res.data.user_id);
          localStorage.setItem('user', JSON.stringify(res.data));

          if (process.env.REACT_APP_TO_SHOW_CALLDASHBOARD === 'true') {
            if (res.data.role === 'Agent') {
              navigate('/dashboard/workspace/upload', { replace: true });
            } else if (
              res.data.role === 'Customer_Admin' &&
              res.data.Submenus.includes('Call_analysis')
            ) {
              navigate('/dashboard/app/call', { replace: true });
            } else if (
              res.data.role === 'Customer_Admin' &&
              res.data.Submenus.includes('Revenue_intelligence')
            ) {
              navigate('/dashboard/app/sales', { replace: true });
            } else if (
              res.data.role === 'Customer_Admin' &&
              res.data.Submenus.includes('Collection')
            ) {
              navigate('/dashboard/app/collections', { replace: true });
            } else if (
              res.data.role === 'Customer_Admin' &&
              res.data.Submenus.includes('Compliance')
            ) {
              navigate('/dashboard/app/compliance', { replace: true });
            } else if (res.data.role === 'Manager' && res.data.Submenus.includes('Collection')) {
              navigate('/dashboard/app/collections', { replace: true });
            } else if (res.data.role === 'Manager' && res.data.Submenus.includes('Call_analysis')) {
              navigate('/dashboard/app/call', { replace: true });
            } else if (
              res.data.role === 'Manager' &&
              res.data.Submenus.includes('Revenue_intelligence')
            ) {
              navigate('/dashboard/app/sales', { replace: true });
            } else if (res.data.role === 'Manager' && res.data.Submenus.includes('Compliance')) {
              navigate('/dashboard/app/compliance', { replace: true });
            } else if (
              res.data.role === 'Sales_Admin' &&
              res.data.Submenus.includes('Revenue_intelligence')
            ) {
              navigate('/dashboard/app/sales', { replace: true });
            } else if (
              res.data.role === 'Collection_Admin' &&
              res.data.Submenus.includes('Call_analysis')
            ) {
              navigate('/dashboard/app/call', { replace: true });
            } else if (
              res.data.role === 'QA' &&
              res.data.Submenus.includes('Revenue_intelligence')
            ) {
              navigate('/dashboard/app/sales', { replace: true });
            } else if (res.data.role === 'QA' && res.data.Submenus.includes('Call_analysis')) {
              navigate('/dashboard/app/call', { replace: true });
            } else if (res.data.role === 'QA' && res.data.Submenus.includes('Collection')) {
              navigate('/dashboard/app/collections', { replace: true });
            } else if (res.data.role === 'QA' && res.data.Submenus.includes('Compliance')) {
              navigate('/dashboard/app/compliance', { replace: true });
            } else if (res.data.role === 'Guest' && res.data.Submenus.includes('Upload')) {
              navigate('/dashboard/workspace/upload', { replace: true });
            } else {
              navigate('/dashboard/app/call', { replace: true });
            }
          }
        } else {
          setAlertContent('Internal Server Error');
          setAlertType('error');
          setAlert(true);
        }
      })
      .then(() => {
        setAlertContent(`Success: Welcome Back`);
        setAlertType('success');
        setAlert(true);
      })
      // .then((res) => {
      //   if (process.env.REACT_APP_TO_SHOW_CALLDASHBOARD === 'true') {
      //     // console.log('----------------------inside login if');
      //     if (res.data.role === 'Agent') {
      //       navigate('/dashboard/admin/task', { replace: true });
      //     } else navigate('/dashboard/call', { replace: true });
      //   } else {
      //     navigate('/dashboard/app', { replace: true });
      //   }
      // })
      .catch((err) => {
        setAlertContent(
          `Please check your email and password : ${
            err.response.data.message ? err.response.data.message : 'Internal Server Error'
          }`
        );
        setAlert(true);
        setAlertType('error');
      });
  };
  const handleAlert = () => {
    setAlert(false);
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, handleBlur } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const isEdge = /Edge/.test(navigator.userAgent);
  // console.log('ischrome', isEdge);
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <div>
            <h5>
              <b>Email *</b>
            </h5>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              // label="Email address"
              // onBlur={handleBlur}
              {...getFieldProps('email')}
              inputProps={{ maxLength: 120 }}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </div>
          <div>
            <h5>
              <b>Password *</b>
            </h5>
            <TextField
              fullWidth
              autoComplete="off"
              type={showPassword ? 'text' : 'password'}
              // label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {navigator.userAgent.includes('Chrome') && (
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </div>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps('remember')}
                style={{
                  color: '#3892CF',
                  '&:hover': {
                    color: '#3892CF'
                  }
                }}
                checked={values.remember}
              />
            }
            label="Remember me"
          />

          <Link
            variant="subtitle2"
            sx={{ cursor: 'pointer', color: '#3892CF' }}
            onClick={() => navigate('/ChangePassword')}
          >
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          style={{
            backgroundColor: '#3892CF',
            '&:hover': {
              backgroundColor: '#3892CF'
            }
          }}
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </FormikProvider>
  );
}
