/* eslint-disable arrow-body-style */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';
import { useState, useEffect } from 'react';

import { useFormik, Form, FormikProvider } from 'formik';

import { Stack, TextField, Box, Container, Collapse } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs'; // Import the dayjs library
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { makeStyles } from '@mui/styles';

import { LoadingButton } from '@mui/lab';
import 'react-phone-input-2/lib/style.css';
import API from '../../E2E/axios.util';
import { Notification } from '../../components/Notification/Notification';
// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  datePicker: {
    '& .MuiMobileDatePicker-datePickerView .MuiPickersBasePicker-container': {
      fontSize: '14px' // Adjust the font size
    },
    '& .MuiInputBase-input': {
      width: '48rem',
      height: '30px', // Adjust the height
      padding: '5px 10px' // Adjust the padding
    },
    '& .MuiInputLabel-root': {
      textAlign: 'center' // Center align the label text
    }
  }
}));
const ScoreForm = ({
  handleDialog,
  agentDetails,
  inputId,
  trackId,
  callDate,
  audioDuration,
  agentCallDetails,
  update,
  setUpdate,
  setAlertContent,
  setAlert,
  setAlertType
}) => {
  const index = trackId.lastIndexOf('.');
  const stringWithoutExtension = trackId.substring(0, index);
  const callTrackId = stringWithoutExtension.slice(0, 32);
  // const agentCallDate = moment(callDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
  const agentDateOfJoining = moment(agentDetails?.date_of_joining, 'DD-MM-YYYY').format(
    'YYYY-MM-DD'
  );
  console.log('callTrackId', callTrackId);
  console.log('trackId', trackId);
  // console.log('agentCallDate', dayjs(agentCallDate));
  const [isAgentDetailsExpanded, setIsAgentDetailsExpanded] = useState(false);
  const [isCallDetailsExpanded, setIsCallDetailsExpanded] = useState(false);
  const [isImprovementDetailsExpanded, setIsImprovementDetailsExpanded] = useState(false);
  const [selectedDateOfJoining, setSelectedDateOfJoining] = useState(
    agentDateOfJoining ? dayjs(agentDateOfJoining) : null
  );
  const [selectedCallDate, setSelectedCallDate] = useState(
    agentDetails ? dayjs(agentDetails.call_date) : null
  );
  const [selectedEvalDate, setSelectedEvalDate] = useState(
    agentDetails ? dayjs(agentDetails.eval_date) : null
  );

  // Alert state
  // const [alert, setAlert] = useState(false);
  // const [alertContent, setAlertContent] = useState('');
  // const [alertType, setAlertType] = useState('');

  // formik initialisation
  const scoreFormSchema = Yup.object().shape({
    dateOfJoining: Yup.string().required('This field is required.'),
    // callTrackId: Yup.string().required('This field is required.'),
    tenure: Yup.string().required('This field is required.'),
    bucket: Yup.string().required('This field is required.'),
    language: Yup.string().required('This field is required.'),
    callDate: Yup.string().required('This field is required.'),
    evalDate: Yup.string().required('This field is required.'),
    week: Yup.string().required('This field is required.'),
    typeOfCall: Yup.string().required('This field is required.'),
    accountDetails: Yup.string().required('This field is required.'),
    status: Yup.string().required('This field is required.'),
    interactionId: Yup.string().required('This field is required.'),
    callDuration: Yup.string().required('This field is required.'),
    callDurationBucket: Yup.string().required('This field is required.'),
    customerTagging: Yup.string().required('This field is required.'),
    // qrcType: Yup.string().required('This field is required.'),
    attribute: Yup.string().required('This field is required.'),
    subAttribute: Yup.string().required('This field is required.'),
    genesysDisposition: Yup.string().required('This field is required.'),
    callSummary: Yup.string().required('This field is required.'),
    bestPractices: Yup.string().required('This field is required.'),
    improvementAreas: Yup.string().required('This field is required.')
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      input_id: inputId,
      callTrackId: callTrackId ? callTrackId : trackId ? trackId : '',
      dateOfJoining: agentDetails ? agentDetails.date_of_joining : '',
      tenure: agentDetails ? agentDetails.tenure : '',
      bucket: agentDetails ? agentDetails.bucket_month : '',
      language: agentDetails ? agentDetails.language : '',
      callDate: agentDetails
        ? agentDetails.call_date
        : callDate
        ? moment(callDate, 'DD-MM-YYYY').format('DD-MM-YYYY')
        : '',
      evalDate: agentDetails ? agentDetails.eval_date : '',
      week: agentCallDetails ? agentCallDetails.week : '',
      typeOfCall: agentCallDetails ? agentCallDetails.type_of_call_recording : '',
      accountDetails: agentCallDetails ? agentCallDetails.acc_details_mobile_number : '',
      status: agentCallDetails ? agentCallDetails.call_status : '',
      interactionId: callTrackId ? callTrackId : trackId ? trackId : '',
      callDuration: audioDuration ? audioDuration : '',
      callDurationBucket: agentCallDetails ? agentCallDetails.call_duration_bucket : '',
      customerTagging: agentCallDetails ? agentCallDetails.customer_tagging : '',
      // qrcType: agentCallDetails ? agentCallDetails.qrc_type : '',
      attribute: agentCallDetails ? agentCallDetails.attribute : '',
      subAttribute: agentCallDetails ? agentCallDetails.sub_attribute : '',
      genesysDisposition: agentCallDetails ? agentCallDetails.genesys_disposition : [],
      callSummary: agentCallDetails ? agentCallDetails.call_summary : '',
      bestPractices: agentCallDetails ? agentCallDetails.best_practices_on_call : '',
      improvementAreas: agentCallDetails ? agentCallDetails.area_of_improvement : ''
    },
    // validationSchema: scoreFormSchema,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      if (agentCallDetails) {
        const data = {
          id: agentCallDetails.call_details_id,
          input_id: inputId,
          call_track_id: formik.values.callTrackId,
          date_of_joining: formik.values.dateOfJoining,
          tenure: formik.values.tenure,
          bucket: formik.values.bucket,
          language: agentDetails ? agentDetails.language : '',
          call_date: formik.values.callDate,
          eval_date: formik.values.evalDate,
          week: formik.values.week,
          type_of_call_recording: formik.values.typeOfCall,
          acc_details_mobile_number: formik.values.accountDetails,
          call_status: formik.values.status,
          interaction_id: formik.values.interactionId,
          call_duration: formik.values.callDuration,
          call_duration_bucket: formik.values.callDurationBucket,
          customer_tagging: formik.values.customerTagging,
          // qrc_type: formik.values.qrcType,
          attribute: formik.values.attribute,
          sub_attribute: formik.values.subAttribute,
          genesys_disposition: formik.values.genesysDisposition,
          call_summary: formik.values.callSummary,
          best_practices_on_call: formik.values.bestPractices,
          area_of_improvement: formik.values.improvementAreas
        };
        console.log('data ---------------------', data);
        await API.put('/secure/update_call_details', data, config)
          .then((res) => {
            formik.resetForm();
            handleDialog();
            setUpdate(update + 1);
            setAlertContent(`Success: ${res.data.message}`);
            setAlertType('success');
            setAlert(true);
          })
          .catch((err) => {
            console.log('error----', err);
            setAlertContent(`${err.response.data.message}`);
            setAlertType('error');
            setAlert(true);
          });
      } else {
        const data = {
          input_id: inputId,
          call_track_id: formik.values.callTrackId,
          date_of_joining: formik.values.dateOfJoining,
          tenure: formik.values.tenure,
          bucket: formik.values.bucket,
          language: agentDetails ? agentDetails.language : '',
          call_date: formik.values.callDate,
          eval_date: formik.values.evalDate,
          week: formik.values.week,
          type_of_call_recording: formik.values.typeOfCall,
          acc_details_mobile_number: formik.values.accountDetails,
          call_status: formik.values.status,
          interaction_id: formik.values.interactionId,
          call_duration: formik.values.callDuration,
          call_duration_bucket: formik.values.callDurationBucket,
          customer_tagging: formik.values.customerTagging,
          // qrc_type: formik.values.qrcType,
          attribute: formik.values.attribute,
          sub_attribute: formik.values.subAttribute,
          genesys_disposition: formik.values.genesysDisposition,
          call_summary: formik.values.callSummary,
          best_practices_on_call: formik.values.bestPractices,
          area_of_improvement: formik.values.improvementAreas
        };
        console.log('data ---------------------', data);
        await API.post('/secure/agent_form_call_details', data, config)
          .then((res) => {
            formik.resetForm();
            handleDialog();
            setUpdate(update + 1);
            setAlertContent(`Success: ${res.data.message}`);
            setAlertType('success');
            setAlert(true);
          })
          .catch((err) => {
            console.log('error----', err);
            setAlertContent(`${err.response.data.message}`);
            setAlertType('error');
            setAlert(true);
          });
      }
    }
  });

  // ----------------------------------------------
  const handleCancel = () => {
    handleDialog();
    formik.resetForm();
  };
  // handle Alerts cancel
  const handleAlert = () => {
    setAlert(false);
  };

  return (
    <Container maxWidth="md">
      <Box
        style={{
          //   marginTop: 70,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Box>
          <div>
            <Stack
              onClick={() => {
                setIsAgentDetailsExpanded(!isAgentDetailsExpanded);
                if (isAgentDetailsExpanded === false) {
                  setIsCallDetailsExpanded(false);
                  setIsImprovementDetailsExpanded(false);
                }
              }}
              style={{
                color: 'Black',
                backgroundColor: '#edeff3',
                '&:hover': {
                  backgroundColor: '#edeff3'
                },
                marginBottom: 15,
                height: 45,
                borderRadius: 3,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >
              <p
                style={{
                  marginLeft: 10,
                  color: '#3892CF',
                  fontSize: 15,
                  fontFamily: 'sans-serif',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <AddIcon />
                &nbsp;Agent Details
              </p>
              {isAgentDetailsExpanded ? (
                <ArrowDropUpIcon size={20} htmlColor="#3892CF" titleAccess="Collapse" />
              ) : (
                <ArrowDropDownIcon size={20} htmlColor="#3892CF" titleAccess="Collapse" />
              )}
            </Stack>
          </div>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={2}>
                <Collapse in={isAgentDetailsExpanded}>
                  <>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      sx={{ marginBottom: 2 }}
                    >
                      <TextField
                        name="callTrackId"
                        required
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Call Track ID"
                        error={Boolean(formik.touched.callTrackId && formik.errors.callTrackId)}
                        helperText={formik.touched.callTrackId && formik.errors.callTrackId}
                        // onChange={(e) => formik.setFieldValue('callTrackId', e.target.value)}
                        disabled
                        value={formik.values.callTrackId}
                        size="small"
                      />
                      <TextField
                        name="agentName"
                        fullWidth
                        label="Agent name"
                        value={agentDetails?.agent_name}
                        disabled
                        size="small"
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      sx={{ marginBottom: 2 }}
                    >
                      <TextField
                        name="managerName"
                        fullWidth
                        label="Team Leader"
                        value={agentDetails?.manager_name}
                        size="small"
                        disabled
                      />
                      <TextField
                        name="qaName"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="QA Name"
                        value={agentDetails?.qa_name}
                        size="small"
                        disabled
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      sx={{ marginBottom: 2 }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Date Of Joining *"
                          required
                          disabled
                          value={selectedDateOfJoining}
                          format="DD/MM/YYYY"
                          onChange={(date) => {
                            setSelectedDateOfJoining(date);
                            formik.setFieldValue('dateOfJoining', dayjs(date).format('DD-MM-YYYY'));
                            console.log('Selected date:', dayjs(date).format('DD-MM-YYYY'));
                          }}
                          slotProps={{
                            textField: {
                              size: 'small',
                              fullWidth: true,
                              error: Boolean(
                                formik.touched.dateOfJoining && formik.errors.dateOfJoining
                              ),
                              helperText:
                                formik.touched.dateOfJoining && formik.errors.dateOfJoining
                            }
                          }}
                        />
                      </LocalizationProvider>
                      <TextField
                        name="tenure"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Tenure (In Months)"
                        onChange={(e) => formik.setFieldValue('tenure', e.target.value)}
                        value={formik.values.tenure}
                        error={Boolean(formik.touched.tenure && formik.errors.tenure)}
                        helperText={formik.touched.tenure && formik.errors.tenure}
                        size="small"
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      sx={{ marginBottom: 2 }}
                    >
                      <TextField
                        name="bucket"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Bucket"
                        onChange={(e) => formik.setFieldValue('bucket', e.target.value)}
                        value={formik.values.bucket}
                        error={Boolean(formik.touched.bucket && formik.errors.bucket)}
                        helperText={formik.touched.bucket && formik.errors.bucket}
                        size="small"
                      />
                      <TextField
                        name="language"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Language"
                        value={agentDetails?.language}
                        disabled
                        size="small"
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      sx={{ marginBottom: 2 }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Call Date *"
                          required
                          value={selectedCallDate}
                          format="DD/MM/YYYY"
                          onChange={(date) => {
                            setSelectedCallDate(date);
                            formik.setFieldValue('callDate', dayjs(date).format('DD-MM-YYYY'));
                            console.log('Selected date:', dayjs(date).format('DD-MM-YYYY'));
                          }}
                          slotProps={{
                            textField: {
                              size: 'small',
                              fullWidth: true,
                              error: Boolean(formik.touched.callDate && formik.errors.callDate),
                              helperText: formik.touched.callDate && formik.errors.callDate
                            }
                          }}
                          disabled
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Eval Date *"
                          required
                          value={selectedEvalDate}
                          format="DD/MM/YYYY"
                          onChange={(date) => {
                            setSelectedEvalDate(date);
                            formik.setFieldValue('evalDate', dayjs(date).format('DD-MM-YYYY'));
                            // console.log('Selected date:', dayjs(date).format('DD-MM-YYYY'));
                          }}
                          slotProps={{
                            textField: {
                              size: 'small',
                              fullWidth: true,
                              disabled: true,
                              error: Boolean(formik.touched.evalDate && formik.errors.evalDate),
                              helperText: formik.touched.evalDate && formik.errors.evalDate
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </>
                </Collapse>
                <Stack
                  onClick={() => {
                    setIsCallDetailsExpanded(!isCallDetailsExpanded);
                    if (isCallDetailsExpanded === false) {
                      setIsAgentDetailsExpanded(false);
                      setIsImprovementDetailsExpanded(false);
                    }
                  }}
                  style={{
                    color: 'Black',
                    backgroundColor: '#edeff3',
                    '&:hover': {
                      backgroundColor: '#edeff3'
                    },
                    // marginBottom: 5,
                    height: 45,
                    borderRadius: 3,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                >
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3892CF',
                      fontSize: 15,
                      fontFamily: 'sans-serif',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <AddIcon />
                    &nbsp;Call Details
                  </p>
                  {isCallDetailsExpanded ? (
                    <ArrowDropUpIcon size={20} htmlColor="#3892CF" titleAccess="Collapse" />
                  ) : (
                    <ArrowDropDownIcon size={20} htmlColor="#3892CF" titleAccess="Collapse" />
                  )}
                </Stack>
                <Collapse in={isCallDetailsExpanded}>
                  <>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      sx={{ marginBottom: 2 }}
                    >
                      <TextField
                        name="week"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Week"
                        onChange={(e) => formik.setFieldValue('week', e.target.value)}
                        value={formik.values.week}
                        error={Boolean(formik.touched.week && formik.errors.week)}
                        helperText={formik.touched.week && formik.errors.week}
                        size="small"
                      />
                      <TextField
                        name="typeOfCall"
                        fullWidth
                        label="Type Of Call Recording"
                        onChange={(e) => formik.setFieldValue('typeOfCall', e.target.value)}
                        value={formik.values.typeOfCall}
                        error={Boolean(formik.touched.typeOfCall && formik.errors.typeOfCall)}
                        helperText={formik.touched.typeOfCall && formik.errors.typeOfCall}
                        size="small"
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      sx={{ marginBottom: 2 }}
                    >
                      <TextField
                        name="accountDetails"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Account Details Fetched"
                        onChange={(e) => formik.setFieldValue('accountDetails', e.target.value)}
                        value={formik.values.accountDetails}
                        error={Boolean(
                          formik.touched.accountDetails && formik.errors.accountDetails
                        )}
                        helperText={formik.touched.accountDetails && formik.errors.accountDetails}
                        size="small"
                      />
                      <TextField
                        name="status"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Status"
                        onChange={(e) => formik.setFieldValue('status', e.target.value)}
                        value={formik.values.status}
                        error={Boolean(formik.touched.status && formik.errors.status)}
                        helperText={formik.touched.status && formik.errors.status}
                        size="small"
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      sx={{ marginBottom: 2 }}
                    >
                      <TextField
                        name="interactionId"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Interaction ID/ SR Number"
                        onChange={(e) => formik.setFieldValue('interactionId', e.target.value)}
                        value={formik.values.interactionId}
                        error={Boolean(formik.touched.interactionId && formik.errors.interactionId)}
                        helperText={formik.touched.interactionId && formik.errors.interactionId}
                        size="small"
                        disabled
                      />
                      <TextField
                        name="callDuration"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Call Duration (AHT in HH:MM:SS)"
                        onChange={(e) => formik.setFieldValue('callDuration', e.target.value)}
                        value={formik.values.callDuration}
                        error={Boolean(formik.touched.callDuration && formik.errors.callDuration)}
                        helperText={formik.touched.callDuration && formik.errors.callDuration}
                        disabled
                        size="small"
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      sx={{ marginBottom: 2 }}
                    >
                      <TextField
                        name="callDurationBucket"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Call Duration Bucket"
                        onChange={(e) => formik.setFieldValue('callDurationBucket', e.target.value)}
                        value={formik.values.callDurationBucket}
                        error={Boolean(
                          formik.touched.callDurationBucket && formik.errors.callDurationBucket
                        )}
                        helperText={
                          formik.touched.callDurationBucket && formik.errors.callDurationBucket
                        }
                        size="small"
                      />
                      <TextField
                        name="customerTagging"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Customer / Non - Customer Tagging"
                        onChange={(e) => formik.setFieldValue('customerTagging', e.target.value)}
                        value={formik.values.customerTagging}
                        error={Boolean(
                          formik.touched.customerTagging && formik.errors.customerTagging
                        )}
                        helperText={formik.touched.customerTagging && formik.errors.customerTagging}
                        size="small"
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      sx={{ marginBottom: 2 }}
                    >
                      {/* <TextField
                        name="qrcType"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="QRC Type"
                        onChange={(e) => formik.setFieldValue('qrcType', e.target.value)}
                        value={formik.values.qrcType}
                        error={Boolean(formik.touched.qrcType && formik.errors.qrcType)}
                        helperText={formik.touched.qrcType && formik.errors.qrcType}
                        size="small"
                      /> */}
                      <TextField
                        name="attribute"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Attribute"
                        onChange={(e) => formik.setFieldValue('attribute', e.target.value)}
                        value={formik.values.attribute}
                        error={Boolean(formik.touched.attribute && formik.errors.attribute)}
                        helperText={formik.touched.attribute && formik.errors.attribute}
                        size="small"
                      />
                      <TextField
                        name="subAttribute"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Sub-Attribute"
                        onChange={(e) => formik.setFieldValue('subAttribute', e.target.value)}
                        value={formik.values.subAttribute}
                        error={Boolean(formik.touched.subAttribute && formik.errors.subAttribute)}
                        helperText={formik.touched.subAttribute && formik.errors.subAttribute}
                        size="small"
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      sx={{ marginBottom: 2, width: '49%' }}
                    >
                      <TextField
                        name="genesysDisposition"
                        fullWidth
                        autoComplete="off"
                        type="text"
                        label="Genesys Disposition"
                        onChange={(e) =>
                          formik.setFieldValue('genesysDisposition', e.target.value.split(','))
                        }
                        value={formik.values.genesysDisposition.join(',')}
                        error={Boolean(
                          formik.touched.genesysDisposition && formik.errors.genesysDisposition
                        )}
                        helperText={
                          formik.touched.genesysDisposition && formik.errors.genesysDisposition
                        }
                        size="small"
                      />
                    </Stack>
                  </>
                </Collapse>
                <Stack
                  onClick={() => {
                    setIsImprovementDetailsExpanded(!isImprovementDetailsExpanded);
                    if (isImprovementDetailsExpanded === false) {
                      setIsAgentDetailsExpanded(false);
                      setIsCallDetailsExpanded(false);
                    }
                  }}
                  style={{
                    color: 'Black',
                    backgroundColor: '#edeff3',
                    '&:hover': {
                      backgroundColor: '#edeff3'
                    },
                    marginBottom: 15,
                    height: 45,
                    borderRadius: 3,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                >
                  <p
                    style={{
                      marginLeft: 10,
                      color: '#3892CF',
                      fontSize: 15,
                      fontFamily: 'sans-serif',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <AddIcon />
                    &nbsp;Improvement Details
                  </p>
                  {isImprovementDetailsExpanded ? (
                    <ArrowDropUpIcon size={20} htmlColor="#3892CF" titleAccess="Collapse" />
                  ) : (
                    <ArrowDropDownIcon size={20} htmlColor="#3892CF" titleAccess="Collapse" />
                  )}
                </Stack>
                <Collapse in={isImprovementDetailsExpanded}>
                  <>
                    <Stack
                      direction={{ xs: 'row', sm: 'column' }}
                      spacing={2}
                      sx={{ marginBottom: 2 }}
                    >
                      <TextField
                        name="callSummary"
                        fullWidth
                        multiline
                        rows={2}
                        label="Call Summary "
                        onChange={(e) => formik.setFieldValue('callSummary', e.target.value)}
                        value={formik.values.callSummary}
                        error={Boolean(formik.touched.callSummary && formik.errors.callSummary)}
                        helperText={formik.touched.callSummary && formik.errors.callSummary}
                      />
                      <TextField
                        name="bestPractices"
                        fullWidth
                        multiline
                        rows={2}
                        label="Best Practice on Call"
                        onChange={(e) => formik.setFieldValue('bestPractices', e.target.value)}
                        value={formik.values.bestPractices}
                        error={Boolean(formik.touched.bestPractices && formik.errors.bestPractices)}
                        helperText={formik.touched.bestPractices && formik.errors.bestPractices}
                      />
                      <TextField
                        name="improvementAreas"
                        fullWidth
                        multiline
                        rows={2}
                        label="Areas of Improvement"
                        onChange={(e) => formik.setFieldValue('improvementAreas', e.target.value)}
                        value={formik.values.improvementAreas}
                        error={Boolean(
                          formik.touched.improvementAreas && formik.errors.improvementAreas
                        )}
                        helperText={
                          formik.touched.improvementAreas && formik.errors.improvementAreas
                        }
                      />
                    </Stack>
                  </>
                </Collapse>

                <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton
                    variant="contained"
                    onClick={handleCancel}
                    style={{
                      marginRight: 5,
                      backgroundColor: '#3892CF',
                      '&:hover': {
                        backgroundColor: '#3892CF'
                      }
                    }}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={formik.isSubmitting}
                    style={{
                      marginRight: 5,
                      backgroundColor: '#3892CF',
                      '&:hover': {
                        backgroundColor: '#3892CF'
                      }
                    }}
                  >
                    {agentCallDetails ? 'Update' : 'Submit'}
                  </LoadingButton>
                </Container>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Box>
      {/* <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      /> */}
    </Container>
  );
};

export default ScoreForm;
