/* eslint-disable  arrow-body-style */
/* eslint-disable  prefer-arrow-callback */

import React, { useState, useEffect, useContext, useRef } from 'react';
import TimelinePlugin from 'wavesurfer.js/src/plugin/timeline';
import wavesurfer from 'wavesurfer.js';
// import { FileContext } from './fileContext';
import './CSS/AudioChart.css';
import { Icon } from '@iconify/react';

import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  CircularProgress,
  InputLabel,
  Select,
  FormControl,
  Button
} from '@mui/material';
import Play from '@mui/icons-material/PlayCircle';
import Pause from '@mui/icons-material/PauseCircleFilled';
import Replay from '@mui/icons-material/ReplayCircleFilled';
import roundDownload from '@iconify/icons-ic/round-download';
import playSpeed from '@iconify/icons-mdi/play-speed';
import Replay10Icon from '@mui/icons-material/Replay10';
import Forward30Icon from '@mui/icons-material/Forward30';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import RegionsPlugin from 'wavesurfer.js/src/plugin/regions';
// import MinimapPlugin from 'wavesurfer.js/src/plugin/minimap';
// import { Box } from 'devextreme-react';

import FileSaver from 'file-saver';

const AudioWaveform = ({
  fileURL,
  isAudioPlay,
  deadAirData,
  toneData,
  setAudioDuration,
  getAudioFileName
}) => {
  const wavesurferRef = useRef(null);
  const timelineRef = useRef(null);
  const minimapRef = useRef(null);
  const audioRef = useRef(null);
  const sliderRef = useRef(null);
  const ref = useRef(null);
  // console.log('audio is playing', isAudioPlay);
  // fetch file url from the context
  //   const { fileURL } = useContext(FileContext);

  // crate an instance of the wavesurfer
  const [wavesurferObj, setWavesurferObj] = useState();
  const [progress, setProgress] = useState();
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [playing, setPlaying] = useState(false); // to keep track whether audio is currently playing or not
  const [duration, setDuration] = useState(0); // duration is used to set the default region of selection for trimming the audio
  const [currentTime, setCurrentTime] = useState('00:00'); // duration is used to set the default region of selection for trimming the audio
  const [sliderValue, setSliderValue] = useState(0);
  const [hoveredTime, setHoveredTime] = useState(null);
  const [audioLoadFailed, setAudioLoadFailed] = useState(false); // to keep track if audio loading failed
  const [loading, setLoading] = useState(true); // to keep track if audio loading failed

  // create the waveform inside the correct component
  useEffect(() => {
    if (wavesurferRef.current && !wavesurferObj) {
      setWavesurferObj(
        wavesurfer.create({
          container: '#waveform',
          scrollParent: true,
          autoCenter: true,
          cursorColor: 'black',
          loopSelection: true,
          waveColor: '#69207F',
          progressColor: '#c185d3',
          responsive: true,
          // hideScrollbar: true,
          normalize: true,
          regionsMinLength: 10,
          plugins: [
            TimelinePlugin.create({
              container: '#wave-timeline'
            }),
            RegionsPlugin.create({})
            // MinimapPlugin.create({ container: '#wave-minimap' })
          ]
        })
      );
    }
  }, [wavesurferRef, wavesurferObj]);

  // once the file URL is ready, load the file to produce the waveform
  useEffect(() => {
    if (fileURL && wavesurferObj) {
      wavesurferObj.load(fileURL);
    }
  }, [wavesurferObj]);

  useEffect(() => {
    if (wavesurferObj) {
      const handleAudioLoadError = () => {
        setAudioLoadFailed(true);
      };

      wavesurferObj.on('error', handleAudioLoadError);

      return () => {
        wavesurferObj.un('error', handleAudioLoadError);
      };
    }
  }, [wavesurferObj]);

  useEffect(() => {
    if (wavesurferObj) {
      // once the waveform is ready, play the audio
      wavesurferObj.on('ready', () => {
        // wavesurferObj.play();
        setPlaying(false);
        setLoading(false);
        const totalDuration = wavesurferObj.getDuration();
        const minutes = Math.floor(totalDuration / 60);
        const seconds = Math.floor(totalDuration % 60);
        // Format the time as MM:SS
        const formattedDuration = `${padZero(minutes)}:${padZero(seconds)}`;
        setAudioDuration(formattedDuration);
        setDuration(formattedDuration);
      });

      // once audio starts playing, set the state variable to true
      wavesurferObj.on('play', () => {
        setPlaying(true);
      });

      // once audio starts playing, set the state variable to false
      wavesurferObj.on('finish', () => {
        setPlaying(false);
      });

      wavesurferObj.on('loading', (percentage) => {
        setProgress(percentage);
      });

      wavesurferObj.on('audioprocess', (time) => {
        // Convert the current time to minutes and seconds
        const currentTime = wavesurferObj.getCurrentTime();
        const minutes = Math.floor(currentTime / 60);
        const seconds = Math.floor(currentTime % 60);
        // For setting up slider
        const duration = wavesurferObj.getDuration();
        const percentage = currentTime / duration;
        setSliderValue(percentage);
        // Format the time as MM:SS
        const formattedTime = `${padZero(minutes)}:${padZero(seconds)}`;
        setCurrentTime(formattedTime);
      });

      // if multiple regions are created, then remove all the previous regions so that only 1 is present at any given time
      // wavesurferObj.on('region-updated', (region) => {
      //   const regions = region.wavesurfer.regions.list;
      //   const keys = Object.keys(regions);
      //   if (keys.length > 1) {
      //     regions[keys[0]].remove();
      //   }
      // });
    }
  }, [wavesurferObj]);

  // when the duration of the audio is available, set the length of the region depending on it, so as to not exceed the total lenght of the audio
  useEffect(() => {
    if (duration && wavesurferObj && deadAirData) {
      // add a region with multiple length
      // console.log('deadAirdata.time', deadAirData);
      deadAirData.dead_air?.time?.map((element) => {
        wavesurferObj.addRegion({
          drag: false,
          resize: false,
          start: element[0], // time in seconds
          end: element[1], // time in seconds
          color: 'rgba(212, 208, 197, 0.55)' // color of the selected region, light hue of purple
        });
        return element;
      });
      deadAirData.tone_details?.agent_tone_details?.high.map((item) =>
        wavesurferObj.addRegion({
          drag: false,
          resize: false,
          start: item.time[0] / 1000, // time in seconds
          end: item.time[1] / 1000, // time in seconds
          color: 'rgba(247, 45, 72, 0.55)' // color of the selected region, light hue of purple
        })
      );
      deadAirData.tone_details?.agent_tone_details?.medium.map((item) =>
        wavesurferObj.addRegion({
          drag: false,
          resize: false,
          start: item.time[0] / 1000, // time in seconds
          end: item.time[1] / 1000, // time in seconds
          color: 'rgba(136, 132, 216, 0.55)' // color of the selected region, light hue of purple
        })
      );
      deadAirData.tone_details?.agent_tone_details?.low.map((item) =>
        wavesurferObj.addRegion({
          drag: false,
          resize: false,
          start: item.time[0] / 1000, // time in seconds
          end: item.time[1] / 1000, // time in seconds
          color: 'rgba(130, 202, 157, 0.55)' // color of the selected region, light hue of purple
        })
      );

      // add a region with default length
      // wavesurferObj.addRegion({
      //   drag: false,
      //   start: deadAirData.time[0][0], // time in seconds
      //   end: deadAirData.time[0][1], // time in seconds
      //   color: 'hsla(0, 92%, 59%, 50%)' // color of the selected region, light hue of purple
      // });
      // wavesurferObj.addRegion({
      //   drag: false,
      //   start: deadAirData.time[1][0], // time in seconds
      //   end: deadAirData.time[1][1], // time in seconds
      //   color: 'hsla(0, 92%, 59%, 50%)' // color of the selected region, light hue of purple
      // });
    }
  }, [duration, wavesurferObj, toneData]);

  const handleSliderChange = (e) => {
    const newPercentage = parseFloat(e.target.value);
    setSliderValue(newPercentage);
    const newTime = newPercentage * wavesurferObj.getDuration();
    wavesurferObj.seekTo(newTime / wavesurferObj.getDuration());

    const currentTime = wavesurferObj.getCurrentTime();
    const minutes = Math.floor(currentTime / 60);
    const seconds = Math.floor(currentTime % 60);

    // Format the time as MM:SS
    const formattedTime = `${padZero(minutes)}:${padZero(seconds)}`;
    setCurrentTime(formattedTime);
    // console.log('formattedTime', formattedTime);
    // wavesurferObj.seekTo(newTime);
  };

  const handleSliderHover = (e) => {
    const rect = sliderRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const { width } = rect;
    const hoveredPercentage = offsetX / width;
    const hoverTime = (hoveredPercentage * wavesurferObj.getDuration()).toFixed(2); // Format timestamp

    setHoveredTime(hoverTime);
  };

  const handlePlayPause = (e) => {
    if (wavesurferObj) {
      wavesurferObj.playPause();
      setPlaying(!playing);
    }
  };
  const handleForward = (e) => {
    if (wavesurferObj) {
      const currentTime = wavesurferObj.getCurrentTime();

      // Forward by 10 seconds (you can adjust this value)
      let newTime = currentTime + 30;

      // Make sure the new time is within the bounds of the audio
      if (newTime > wavesurferObj.getDuration()) {
        newTime = wavesurferObj.getDuration();
      }

      const minutes = Math.floor(newTime / 60);
      const seconds = Math.floor(newTime % 60);

      // Format the time as MM:SS
      const formattedTime = `${padZero(minutes)}:${padZero(seconds)}`;
      setCurrentTime(formattedTime);

      const duration = wavesurferObj.getDuration();
      const percentage = newTime / duration;
      setSliderValue(percentage);
      // Seek to the new time
      wavesurferObj.skip(30);
    }
  };
  const handleRewind = (e) => {
    if (wavesurferObj) {
      const currentTime = wavesurferObj.getCurrentTime();

      // Forward by 10 seconds (you can adjust this value)
      let newTime = currentTime - 10;

      // Make sure the new time is within the bounds of the audio
      if (newTime > wavesurferObj.getDuration()) {
        newTime = wavesurferObj.getDuration();
      }
      const minutes = Math.floor(newTime / 60);
      const seconds = Math.floor(newTime % 60);

      // Format the time as MM:SS
      const formattedTime = `${padZero(minutes)}:${padZero(seconds)}`;
      setCurrentTime(seconds <= 0 ? '00:00' : formattedTime);

      const duration = wavesurferObj.getDuration();
      const percentage = newTime / duration;
      setSliderValue(percentage);

      // Seek to the new time
      wavesurferObj.skip(-10);
    }
  };

  useEffect(() => {
    if (wavesurferObj) {
      wavesurferObj.setPlaybackRate(playbackSpeed);
    }
  }, [playbackSpeed]);

  const handleReload = (e) => {
    // stop will return the audio to 0s, then play it again
    wavesurferObj.stop();
    wavesurferObj.play();
    setPlaying(true); // to toggle the play/pause button icon
  };

  useEffect(() => {
    handlePlayPause();
  }, [isAudioPlay]);

  const audio = useRef();

  useEffect(() => {
    audio.current = new Audio(wavesurferObj);
    // audio.current.play();
  }, [wavesurferObj]);

  useEffect(
    () => () => {
      if (wavesurferObj) {
        wavesurferObj.stop();
      }
    },
    [wavesurferObj]
  );
  function padZero(number) {
    return (number < 10 ? '0' : '') + number;
  }
  // console.log('audioLoadFailed', audioLoadFailed);
  const playbackRateList = [
    { name: 'Normal', value: 1 },
    { name: '1.25X', value: 1.25 },
    { name: '1.75X', value: 1.75 },
    { name: '2X', value: 2 }
  ];

  // function formatTime(time) {
  //   const minutes = Math.floor(time / 60);
  //   const seconds = Math.floor(time % 60);
  //   return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  // }
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSubMenu, setShowSubMenu] = useState(false);

  const handlePlaybackMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setShowSubMenu(!showSubMenu); // Toggle the submenu state
    // setIsOpen(false);
  };

  const downloadAudioFile = () => {
    if (fileURL && wavesurferObj) {
      // console.log('fileUrl', getAudioFileName(fileURL));
      FileSaver.saveAs(fileURL, getAudioFileName(fileURL));
    }
  };

  return (
    <section style={{ marginTop: 10, marginLeft: 30, height: 230 }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {audioLoadFailed ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 10,
              position: 'relative'
              // backgroundColor: 'yellow'
            }}
          >
            <span style={{ fontSize: 20, position: 'absolute', top: 50 }}>
              <b>No Data Available</b>
            </span>
          </div>
        ) : (
          <>{loading && <CircularProgress sx={{ marginTop: 8, color: '#3892CF' }} />}</>
        )}
      </div>

      <div ref={wavesurferRef} id="waveform" />
      <div style={{ marginBottom: '15px' }} ref={timelineRef} id="wave-timeline" />
      {!loading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}
        >
          <Replay10Icon
            sx={{
              color: '#0075ff',
              '&:hover': {
                color: '#3892CF'
              },
              cursor: 'pointer'
            }}
            onClick={handleRewind}
          />
          {playing ? (
            <Pause
              sx={{
                color: '#0075ff',
                '&:hover': {
                  color: '#3892CF'
                },
                cursor: 'pointer'
              }}
              ref={audioRef}
              onClick={handlePlayPause}
            />
          ) : (
            <Play
              sx={{
                color: '#0075ff',
                '&:hover': {
                  color: '#3892CF'
                },
                cursor: 'pointer'
              }}
              ref={audioRef}
              onClick={handlePlayPause}
            />
          )}
          <Forward30Icon
            sx={{
              color: '#0075ff',
              '&:hover': {
                color: '#3892CF'
              },
              cursor: 'pointer'
            }}
            onClick={handleForward}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <b
              style={{
                padding: '4px'
              }}
            >
              {currentTime}/{duration}
            </b>
            <input
              type="range"
              min="0"
              max="1"
              step="0.001"
              value={sliderValue}
              style={{ cursor: 'pointer' }}
              // onMouseMove={handleSliderHover}
              ref={sliderRef}
              onChange={handleSliderChange}
            />
          </div>
          <Replay
            sx={{
              color: '#0075ff',
              '&:hover': {
                color: '#3892CF'
              },
              cursor: 'pointer'
            }}
            onClick={handleReload}
          />
          <IconButton ref={ref} onClick={() => setIsOpen(true)}>
            <Icon icon={moreVerticalFill} width={20} height={20} />
          </IconButton>
          <Menu
            open={isOpen}
            anchorEl={ref.current}
            onClose={() => setIsOpen(false)}
            PaperProps={{
              sx: { width: 200, maxWidth: '100%' }
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem sx={{ color: 'text.secondary' }} onClick={downloadAudioFile}>
              <ListItemIcon>
                <Icon icon={roundDownload} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Download" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>

            <MenuItem sx={{ color: 'text.secondary' }} onClick={handlePlaybackMenu}>
              <ListItemIcon>
                <Icon icon={playSpeed} width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Playback Speed"
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </MenuItem>
          </Menu>
          <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
              setShowSubMenu(false);
            }}
            PaperProps={{
              sx: { width: 200, maxWidth: '100%' }
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {playbackRateList
              ? playbackRateList.map((element, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={element.value}
                      selected={playbackSpeed === element.value}
                      onClick={() => {
                        setPlaybackSpeed(element.value);
                        setAnchorEl(null);
                        setShowSubMenu(false);
                      }}
                    >
                      {element.name}
                    </MenuItem>
                  );
                })
              : null}
          </Menu>
          {/* <Button style={{ color: 'black' }} size="small" title="reload" onClick={handleForward}>
          <Replay />
        </Button>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">Playback Speed</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            label="Playback Speed"
            size="small"
            onChange={(e) => {
              // handlePlaybackSpeed(e.target.value);
              setPlaybackSpeed(e.target.value);
            }}
            value={playbackSpeed}
          >
            {playbackRateList
              ? playbackRateList.map((element, index) => {
                  return (
                    <MenuItem key={index} value={element.value}>
                      {element.name}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
        </FormControl>
        <Button
          color="primary"
          style={{ color: 'black' }}
          title="Download"
          size="small"
          onClick={() => handleDownload()}
        >
          <DownloadIcon />
        </Button> */}
        </div>
      )}

      {/* <div ref={minimapRef} id="wave-minimap" /> */}
    </section>
  );
};

export default AudioWaveform;
