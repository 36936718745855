import React, { useState, useEffect } from 'react';

import { FormikProvider, Form, FieldArray, ErrorMessage } from 'formik';

import {
  Grid,
  Select as Selectbox,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Container
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import 'react-datepicker/dist/react-datepicker.css';
import API from '../../E2E/axios.util';

export const DomainEditForm = ({
  formik,
  setAlertContent,
  setAlertType,
  setAlert,
  isUpdated,
  updateComponent,
  handleDialog,
  Details
}) => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const { getFieldProps, errors, touched } = formik;

  const [domainId, setkeywordId] = useState(Details.domain_category_id);
  const [organisationId, setorganisationId] = useState(Details.organisation_id);
  const [customerOrgId, setcustomerOrgId] = useState(Details.customer_id);
  const [processArr, setprocessArr] = useState(Details.categories);
  const [domainName, setDomainName] = useState(Details.domain_name);

  const [organisationList, setorganisationList] = useState([]);
  const [customerOrgList, setcustomerOrgList] = useState([]);
  const [processList, setprocessList] = useState([]);
  const [loading, setloading] = useState(false);

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setorganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
    formik.setFieldValue(`domainCategories`, Details.categories);
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${organisationId}`, config)
      .then((res) => {
        setcustomerOrgList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (organisationId !== null) {
      getCustomerList();
    } else {
      setcustomerOrgList([]);
    }
  }, [organisationId]);

  const getProcessList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    await API.get(
      `/secure/get_domain_info?customer_id=${customerOrgId}&org_id=${organisationId}`,
      config
    )
      .then((res) => {
        setprocessList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (customerOrgId) {
      getProcessList();
    } else {
      setprocessList();
    }
  }, [customerOrgId]);

  const handleCancel = () => {
    handleDialog();
  };

  const handleSubmit = async () => {
    setloading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = {
      // id: domainId,
      // organisation_id: organisationId,
      // customer_id: customerOrgId,
      // domain: domainName,
      categories: formik.values.domainCategories
    };
    await API.put(`/secure/update_domain_categories?domain_cat_id=${domainId}`, data, config)
      .then((res) => {
        setloading(false);
        setAlertContent(`Success : ${res.data.message}`);
        setAlert(true);
        setAlertType('success');
        handleDialog();
      })
      .then(() => {
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        setloading(false);
        setAlertContent(`${err.response.data.message}`);
        setAlert(true);
        setAlertType('error');
        handleDialog();
      });
  };

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));
  const handleDomain = (domain) => {
    formik.setFieldValue('domain', domain);
  };

  const classes = useStyles();

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Selectbox
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  disabled
                  defaultValue={organisationId}
                  onChange={(e) => setorganisationId(e.target.value)}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {organisationList
                    ? organisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Selectbox
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  defaultValue={customerOrgId}
                  disabled
                  onChange={(e) => {
                    setcustomerOrgId(e.target.value);
                  }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {customerOrgList
                    ? customerOrgList.map((element) => {
                        const { customer_id, customer_organisation_name, dashboard_services } =
                          element;
                        return (
                          <MenuItem key={customer_id} value={customer_id}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="process-select-label">
                Select Domain
              </InputLabel>
              <Selectbox
                required
                labelId="domain-select-label"
                label="Select Domain"
                id="select-domain"
                value={Details.domain_id}
                disabled
                // error={Boolean(touched.domain && errors.domain)}
                // helperText={touched.domain && errors.domain}
                onChange={(e) => handleDomain(e.target.value)}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {processList ? (
                  <MenuItem value={processList.domain_id}>{processList.domain_name}</MenuItem>
                ) : (
                  <MenuItem>No domain found</MenuItem>
                )}
              </Selectbox>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FieldArray
              name="domainCategories"
              render={(arrayHelpers) => (
                <>
                  {formik.values.domainCategories.map((row, index) => {
                    console.log('row ---', row);
                    console.log('index--', index);
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '5px'
                        }}
                      >
                        <TextField
                          required
                          fullWidth
                          label="Domain Category"
                          {...getFieldProps(`domainCategories[${index}]`)}
                          error={Boolean(
                            touched &&
                              touched.domainCategories &&
                              touched.domainCategories[index] &&
                              touched.domainCategories[index] &&
                              errors &&
                              errors.domainCategories &&
                              errors.domainCategories[index] &&
                              errors.domainCategories[index]
                          )}
                          helperText={
                            touched &&
                            touched.domainCategories &&
                            touched.domainCategories[index] &&
                            touched.domainCategories[index] &&
                            errors &&
                            errors.domainCategories &&
                            errors.domainCategories[index] &&
                            errors.domainCategories[index]
                          }
                        />
                        {index === formik.values.domainCategories.length - 1 ? (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: `${index > 0 ? 'visible' : 'hidden'}`
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: 'hidden'
                              }}
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            />
          </Grid>
          {/* <Grid item xs={12}>
              <FieldArray
                name="sentences"
                render={(arrayHelpers) => (
                  <>
                    {formik.values.sentences.map((row, index) => {
                      console.log('row ---', row);
                      // console.log('index--', index);
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '5px'
                          }}
                        >
                          <TextField
                            defaultValue={Object.values(row)[0]}
                            required
                            fullWidth
                            label="Sentence"
                            {...getFieldProps(`sentences[${index}].sentence`)}
                          />
                          {index === formik.values.sentences.length - 1 ? (
                            <>
                              <RemoveCircleIcon
                                sx={{
                                  color: 'white',
                                  backgroundColor: 'blue',
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                  marginX: '5px',
                                  visibility: `${index > 0 ? 'visible' : 'hidden'}`
                                }}
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                              <AddCircleIcon
                                sx={{
                                  color: 'white',
                                  backgroundColor: 'blue',
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                  marginX: '5px'
                                }}
                                onClick={() => {
                                  arrayHelpers.push('');
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <RemoveCircleIcon
                                sx={{
                                  color: 'white',
                                  backgroundColor: 'blue',
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                  marginX: '5px'
                                }}
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                              <AddCircleIcon
                                sx={{
                                  color: 'white',
                                  backgroundColor: 'blue',
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                  marginX: '5px',
                                  visibility: 'hidden'
                                }}
                                onClick={() => {
                                  arrayHelpers.push('');
                                }}
                              />
                            </>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
              />
            </Grid> */}
          {/* <Grid item xs={12}>
              <FieldArray
                name="sentences"
                render={(arrayHelpers) => (
                  <>
                    {formik.values.sentences.map((row, index) => {
                      console.log('row ---', row);
                      console.log('index--', index);
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '5px'
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={8}>
                              <TextField
                                required
                                fullWidth
                                multiline
                                rows={2}
                                label="Sentences"
                                name={`sentences[${index}].sentence`}
                                value={row.sentence ? row.sentence.toString() : ''}
                                onBlur={formik.handleBlur}
                                // {...getFieldProps(`keywords[${index}].words`)}
                                onChange={(e) => {
                                  // console.warn('keywords --------------', e.target.value);
                                  formik.setFieldValue(
                                    `sentences[${index}].sentence`,
                                    e.target.value.split(',')
                                  );
                                }}
                                error={Boolean(
                                  touched &&
                                    touched.sentences &&
                                    touched.sentences[index] &&
                                    touched.sentences[index].sentence &&
                                    errors &&
                                    errors.sentences &&
                                    errors.sentences[index] &&
                                    errors.sentences[index].sentence
                                )}
                                helperText={<ErrorMessage name={`sentences[${index}].sentence`} />}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl fullWidth>
                                <TextField
                                  required
                                  fullWidth
                                  label="Select Category"
                                  name={`sentences[${index}].Category`}
                                  value={row.Category ? row.Category.toString() : ''}
                                  onBlur={formik.handleBlur}
                                  // {...getFieldProps(`keywords[${index}].words`)}
                                  onChange={(e) => {
                                    // console.warn('keywords --------------', e.target.value);
                                    formik.setFieldValue(
                                      `sentences[${index}].Category`,
                                      e.target.value
                                    );
                                  }}
                                  error={Boolean(
                                    touched &&
                                      touched.sentences &&
                                      touched.sentences[index] &&
                                      touched.sentences[index].Category &&
                                      errors &&
                                      errors.sentences &&
                                      errors.sentences[index] &&
                                      errors.sentences[index].Category
                                  )}
                                  helperText={
                                    touched &&
                                    touched.sentences &&
                                    touched.sentences[index] &&
                                    touched.sentences[index].Category &&
                                    errors &&
                                    errors.sentences &&
                                    errors.sentences[index] &&
                                    errors.sentences[index].Category
                                  }
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
  
                          {index === formik.values.sentences.length - 1 ? (
                            <>
                              <RemoveCircleIcon
                                sx={{
                                  color: 'white',
                                  backgroundColor: '#3892CF',
                                  '&:hover': {
                                    backgroundColor: '#3892CF'
                                  },
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                  marginX: '5px',
                                  visibility: `${index > 0 ? 'visible' : 'hidden'}`
                                }}
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                              <AddCircleIcon
                                sx={{
                                  color: 'white',
                                  backgroundColor: '#3892CF',
                                  '&:hover': {
                                    backgroundColor: '#3892CF'
                                  },
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                  marginX: '5px'
                                }}
                                onClick={() => {
                                  arrayHelpers.push('');
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <RemoveCircleIcon
                                sx={{
                                  color: 'white',
                                  backgroundColor: '#3892CF',
                                  '&:hover': {
                                    backgroundColor: '#3892CF'
                                  },
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                  marginX: '5px'
                                }}
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                              <AddCircleIcon
                                sx={{
                                  color: 'white',
                                  backgroundColor: '#3892CF',
                                  '&:hover': {
                                    backgroundColor: '#3892CF'
                                  },
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                  marginX: '5px',
                                  visibility: 'hidden'
                                }}
                                onClick={() => {
                                  arrayHelpers.push('');
                                }}
                              />
                            </>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
              />
            </Grid> */}
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
            onClick={handleCancel}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            type="submit"
            onClick={!errors.domainCategories ? handleSubmit : null}
            loading={loading}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Update
          </LoadingButton>
        </Container>
      </Form>
    </FormikProvider>
  );
};
