/* eslint-disable  arrow-body-style */
/* eslint-disable  no-unused-expressions */
/* eslint-disable   no-nested-ternary */
/* eslint-disable   no-unneeded-ternary */
/* eslint-disable   no-else-return */

import React, { useState, useRef, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import eyeIcon from '@iconify/icons-mdi/eye';
import {
  Card,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TablePagination,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Container,
  Stack,
  Typography,
  Rating,
  Tooltip as Tip,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  Grid,
  FormHelperText,
  Select as SelectBox,
  Link,
  LinearProgress,
  Box,
  Tabs,
  Tab,
  Button
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Select, { components } from 'react-select';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import edit20Filled from '@iconify/icons-fluent/edit-20-filled';
import editFill from '@iconify/icons-eva/edit-fill';
import Pagination from '@mui/material/Pagination';

import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { useFormik, FormikProvider, Form } from 'formik';
import { saveAs } from 'file-saver';

import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import Scrollbar from '../components/Scrollbar';
import API from '../E2E/axios.util';
import { UserListHead } from '../components/_dashboard/user';
import { Notification } from '../components/Notification/Notification';
import Waveform from './Waveform';
import Page from '../components/Page';
import inputContex from '../Context/UploadContext/inputContext';

const OPPORTUNITY_TABLE_HEAD = [
  { id: 'filename', numeric: false, disablePadding: false, label: 'Improvement' },
  {
    id: 'feedback',
    numeric: false,
    disablePadding: false,
    label: 'Assigned Date',
    alignCenter: true
  },
  {
    id: 'feedback',
    numeric: false,
    disablePadding: false,
    label: 'Completed Date',
    alignCenter: true
  },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', alignCenter: true },
  { id: 'comment', numeric: false, disablePadding: false, label: 'Comment', alignCenter: true },
  { id: 'ageing', numeric: false, disablePadding: false, label: 'Aging', alignCenter: true },
  { id: '', numeric: false, disablePadding: false, label: ' ' }
];

export function ComplianceFeedback() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [updateFeedback, setUpdateFeedback] = useState();
  const [feedbackList, setFeedbackList] = useState([]);
  const [overallFeedbackList, setOverallFeedbackList] = useState([]);
  const [feedbackCount, setFeedbackCount] = useState();
  const [loading, setLoading] = useState(false);
  const [audioPop, setAudio] = useState(false);
  const [improvementOpen, setImprovementOpen] = useState(false);
  const [improvementDetails, setImprovementDetails] = useState();
  const [tabValue, setTabValue] = useState(1);
  const [tabValueForStatus, setTabValueForStatus] = useState(1);
  const [tabLoading, setTabLoading] = useState(false);
  const [scoreDetails, setScoreDetails] = useState();
  const [aiScore, setAIScore] = useState(0);

  const [max_page_count, setMax_page_count] = useState(1);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [audioUrl, setAudioUrl] = useState();
  const [goodParam, setGoodparam] = useState();
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);

  const [updatedDateFormat, setUpdatedDateFormat] = useState({
    value: 'one_week',
    label: 'One Week'
  });

  const customStyles = {
    option: (base, state) => ({
      ...base,
      fontWeight: state.isSelected ? '500' : 'normal',
      maxHeight: 35
    }),
    control: (base, state) => ({
      ...base,
      fontWeight: '500'
      // border: 'none'
      // maxHeight: 35
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: '2px',
      maxHeight: 38,
      overflow: 'auto'
    })
  };

  const dateFormat = [
    { name: 'One Week', id: 'one_week' },
    { name: 'One Month', id: 'one_month' },
    // { name: 'Six Month', id: 'six_month' },
    // { name: 'One Year', id: 'one_year' },
    { name: 'Customize', id: 'customized' }
  ];
  const navigate = useNavigate();
  const {
    setinputId,
    setinputOrgId,
    setrequestId,
    setUploadedAudio,
    setuploadStatus,
    setAudioS3,
    uploadStatus,
    setProcess,
    setcustomerId,
    setOriginalDate,
    setAgentId,
    setAgentName,
    setAgentLastName,
    kpi,
    setKpi
  } = useContext(inputContex);

  // upload data table

  const userDetails = JSON.parse(localStorage.getItem('user'));

  let UPLOAD_TABLE_HEAD = [];

  userDetails.role === 'Agent'
    ? (UPLOAD_TABLE_HEAD = [
        // { id: 'orgName', numeric: false, disablePadding: false, label: 'Organisation' },
        { id: 'customerName', numeric: false, disablePadding: false, label: 'Customer' },
        { id: 'agentName', numeric: false, disablePadding: false, label: 'Agent' },
        // { id: 'status', numeric: false, disablePadding: false, label: 'Feedback Status' },
        // { id: 'reason', numeric: false, disablePadding: false, label: 'Feedback Reason' },
        { id: 'score', numeric: false, disablePadding: false, label: 'Score' },
        // { id: 'feedback', numeric: false, disablePadding: false, label: 'Feedback' },
        { id: 'callDate', numeric: false, disablePadding: false, label: 'Call Date' },
        { id: 'auditDate', numeric: false, disablePadding: false, label: 'Audit Date' },
        { id: 'auditDate', numeric: false, disablePadding: false, label: 'Feedback Given Date' },
        { id: 'manager', numeric: false, disablePadding: false, label: 'Manager Name' },
        { id: 'qa', numeric: false, disablePadding: false, label: 'QA Name' },
        {
          id: 'filename',
          numeric: false,
          disablePadding: false,
          label: 'View Call',
          alignCenter: true
        },
        { id: 'summary', numeric: false, disablePadding: false, label: 'Summary' },
        { id: 'rating', numeric: false, disablePadding: false, label: 'Rating', alignCenter: true }
        // { id: 'menuItem', numeric: false, disablePadding: false, label: '', alignCenter: true }
      ])
    : (UPLOAD_TABLE_HEAD = [
        // { id: 'orgName', numeric: false, disablePadding: false, label: 'Organisation' },
        { id: 'customerName', numeric: false, disablePadding: false, label: 'Customer' },
        { id: 'agentName', numeric: false, disablePadding: false, label: 'Agent' },
        //         { id: 'reason', numeric: false, disablePadding: false, label: 'Feedback Reason' },
        { id: 'score', numeric: false, disablePadding: false, label: 'Score' },
        // { id: 'feedback', numeric: false, disablePadding: false, label: 'Feedback' },
        { id: 'callDate', numeric: false, disablePadding: false, label: 'Call Date' },
        { id: 'auditDate', numeric: false, disablePadding: false, label: 'Audit Date' },
        { id: 'auditDate', numeric: false, disablePadding: false, label: 'Feedback Given Date' },
        { id: 'manager', numeric: false, disablePadding: false, label: 'Manager Name' },
        { id: 'qa', numeric: false, disablePadding: false, label: 'QA Name' },
        {
          id: 'filename',
          numeric: false,
          disablePadding: false,
          label: 'View Call',
          alignCenter: true
        },
        { id: 'summary', numeric: false, disablePadding: false, label: 'Summary' },
        { id: 'rating', numeric: false, disablePadding: false, label: 'Rating', alignCenter: true }
        // { id: 'menuItem', numeric: false, disablePadding: false, label: '', alignCenter: true }
      ]);

  // console.log('upload table', UPLOAD_TABLE_HEAD);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const handleAlert = () => {
    setAlert(false);
  };

  const formatDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = `${inputDate.getFullYear()}-${(inputDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${inputDate.getDate().toString().padStart(2, '0')}`;

    return formattedDate;
  };

  let status;
  if (tabValueForStatus === 1) {
    status = 'Pending';
  } else if (tabValueForStatus === 2) {
    status = 'Acknowledge';
  } else if (tabValueForStatus === 3) {
    status = 'Calibrate';
  }
  const getFeedbackList = () => {
    setLoading(true);
    let url = `/secure/list_feedback?agent_status=${status}&page=${page}&date=${updatedDateFormat.value}`;
    if (updatedDateFormat.value === 'customized') {
      url += `&start_date=${formatDate(fromDate)}&end_date=${formatDate(toDate)}`;
    }
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    API.get(url, config)
      .then((res) => {
        console.log('res', res);
        setOverallFeedbackList(res.data.results);
        setMax_page_count(res.data.count);
        // setFeedbackList(res.data.results.filter((el) => el.agent_action === null));
        // setTabValueForStatus(1);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
        setAlert(true);
        setAlertContent(`${err.response?.data.message}`);
        setOverallFeedbackList([]);
        setAlertType('error');
        setLoading(false);
      });
  };
  const getFeedbackListCount = () => {
    let url = `/secure/count_list_feedback?date=${updatedDateFormat.value}`;
    if (updatedDateFormat.value === 'customized') {
      url += `&start_date=${formatDate(fromDate)}&end_date=${formatDate(toDate)}`;
    }
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    API.get(url, config)
      .then((res) => {
        setFeedbackCount(res.data);
      })
      .catch((err) => {
        // console.log('err', err);
        setAlert(true);
        setAlertContent(`${err.response?.data.message}`);
        // setOverallFeedbackList([]);
        setAlertType('error');
        // setLoading(false);
      });
  };

  useEffect(() => {
    if (updatedDateFormat.value !== 'customized') {
      getFeedbackList();
      getFeedbackListCount();
      console.log('hell');
    } else if (updatedDateFormat.value === 'customized' && toDate !== null) {
      getFeedbackList();
      getFeedbackListCount();
      console.log('bell');
    }
  }, [tabValueForStatus, page, updatedDateFormat, toDate]);
  console.log('setOverallFeedbackList', overallFeedbackList);
  console.log('tabValue', tabValue);
  console.log('tabValueForStatus', tabValueForStatus);

  // const statusValue=if(tabValueForStatus===1)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAudioDialog = () => {
    setAudio(!audioPop);
  };
  const handleImprovementDialog = () => {
    setTabValue(1);
    setImprovementOpen(!improvementOpen);
  };
  const handleScoreDetailAPI = (value) => {
    setTabLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    API.get(`/secure/agent_script_scorecard?input_id=${value}`, config)
      .then((res) => {
        setScoreDetails(res.data);
        const AIParameters = [];
        res.data.ai_scoring_details
          .filter((el) => el.aiMode === true)
          .map((el) => AIParameters.push(el.Category));
        let averageAI = 0;
        let maxWeightageAI = 0;

        const filterForAIParameters = res.data.ai_scoring_details.filter(
          (item) => AIParameters.includes(item.Category) && item.score !== 'NA'
        );

        filterForAIParameters.map((el) => (averageAI += Number(el.score === 'NA' ? 0 : el.score)));
        filterForAIParameters.map((el) => (maxWeightageAI += Number(el.weightage)));
        // console.log('filterForAIParameters', averageAI, maxWeightageAI);
        setAIScore(((averageAI.toFixed(0) / maxWeightageAI) * 100).toFixed(0));

        setTabLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
        setTabLoading(false);
      });
  };

  const handlePerformanceAPI = (value) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    API.get(`/secure/get_feedback_performance?feedback_id=${value}`, config).then((res) => {
      // console.log('performanceres', res);
      setGoodparam(res.data.summary);
    });
  };
  // useEffect(() => {
  //   handlePerformanceAPI();
  // });
  const getAudio = (id) => {
    const url = `/secure/feedback_s3path?id=${id}`;
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    API.get(url, config)
      .then((res) => {
        setAudioUrl(res.data.s3url);
      })
      .catch((err) => {
        console.log('err', err);
        setAlert(true);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
      });
  };

  const audioStyles = makeStyles((theme) => {
    return {
      // root: {
      //   [theme.breakpoints.down('sm')]: {
      //     width: '100%'
      //   }
      // },
      loopIcon: {
        color: '#3f51b5',
        '&.selected': {
          color: '#0921a9'
        },
        '&:hover': {
          color: '#7986cb'
        }
        // [theme.breakpoints.down('sm')]: {
        //   display: 'none'
        // }
      },
      playIcon: {
        color: '#fffff',
        '&:hover': {
          color: '#ff4081'
        }
      },
      replayIcon: {
        color: '#e6e600'
      },
      pauseIcon: {
        color: '#0099ff'
      },
      volumeIcon: {
        color: 'rgba(0, 0, 0, 0.54)'
      },
      volumeSlider: {
        color: 'black'
      },
      progressTime: {
        color: 'rgba(0, 0, 0, 0.54)'
      },
      mainSlider: {
        color: '#3f51b5',
        '& .MuiSlider-rail': {
          color: '#7986cb'
        },
        '& .MuiSlider-track': {
          color: '#3f51b5'
        },
        '& .MuiSlider-thumb': {
          color: '#303f9f'
        }
      }
    };
  });
  // function for date
  const changedDate = (date) => {
    const newdate = new Date(date);
    const formatStartDate = `${newdate.getDate()}/${
      newdate.getMonth() + 1
    }/${newdate.getFullYear()}`;
    return formatStartDate;
  };

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            variant="determinate"
            color={props.value > 80 ? 'success' : props.value > 50 ? 'warning' : 'error'}
            {...props}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  // Tabs Styling and functions
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTabs-indicator': {
        backgroundColor: '#1D9FD9' // change this to your desired color
      }
    },
    tab: {
      '&.Mui-selected': {
        color: '#1D9FD9' // change this to your desired color
      }
    },
    menuScoreCard: {
      maxHeight: 150,
      maxWidth: 50
    },
    datePicker: {
      '& .MuiTextField-root': {
        width: 170
        // marginBottom: 1
        // height: 10
      },
      '& .MuiInputLabel-shrink': {
        color: '#3892CF' // Set your desired color for the shrunk label
      },
      '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#3892CF'
      }
    }
  }));

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleTabChangeForStatus = (event, newValue) => {
    setPage(1);
    setTabValueForStatus(newValue);
    // if (newValue === 1) {
    //   // setFeedbackList(overallFeedbackList.filter((el) => el.agent_action === null));
    //   setOverallFeedbackList(overallFeedbackList);
    // }
    // if (newValue === 2) {
    //   setFeedbackList(
    //     overallFeedbackList.filter((el) => el.agent_action === 'Acknowledge').reverse()
    //   );
    // }
    // if (newValue === 3) {
    //   setFeedbackList(
    //     overallFeedbackList.filter((el) => el.agent_action === 'Calibrate').reverse()
    //   );
    // }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  // TabPanel.propTypes = {
  //   children: PropTypes.node,
  //   index: PropTypes.number.isRequired,
  //   value: PropTypes.number.isRequired
  // };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const [agentAction, setAgentAction] = useState();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: ''
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string()
        .matches(
          /^\S+[A-Za-z\s]{2,3000}$/,
          'Reason should be in between 3 to 30000 character and it should not start with space and does not contain special character and number.'
        )
        .max(3000, 'Comment should be 3 to 3000 character long.')
        .required('This field is required.')
    }),
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        agent_action: agentAction,
        reason: formik.values.reason,
        id: improvementDetails.feedback_id
      };
      console.log('data ---------------------', data);
      await API.put('/secure/update_feedback_status', data, config)
        .then((res) => {
          formik.resetForm();
          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          getFeedbackList();
          getFeedbackListCount();
          handleDialogForReason();
        })
        // .then(updateComponent(isUpdated + 1))
        .catch((err) => {
          console.log('error----', err);
          formik.resetForm();
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const handleDialogForReason = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };
  const handleCancel = () => {
    handleDialogForReason();
    formik.resetForm();
  };

  // const handleFeedbackStatus = (status) => {
  //   const token = localStorage.getItem('token');
  //   const config = {
  //     headers: { Authorization: `Bearer ${token}` }
  //   };
  //   const data = {
  //     agent_action: status,
  //     reason: null,
  //     id: inputDetails.feedback_id
  //   };
  //   console.log('data ---------------------', data);
  //   API.put('/secure/update_feedback_status', data, config)
  //     .then((res) => {
  //       formik.resetForm();
  //       setAlertContent(`Success: ${res.data.message}`);
  //       setAlertType('success');
  //       setAlert(true);
  //       getFeedbackList();
  //     })
  //     // .then(updateComponent(isUpdated + 1))
  //     .catch((err) => {
  //       formik.resetForm();
  //       console.log('error----', err);
  //       setAlertContent(`${err.response.data.message}`);
  //       setAlertType('error');
  //       setAlert(true);
  //     });
  // };
  const downloadHandler = () => {
    // console.log('download clicked');
    // if (process.env.REACT_APP_ON_PREMISE) {
    //   saveAs(`${process.env.REACT_APP_SERVER_MEDIA_URL}${downloadUrl}`, 'feedbacks_csv');
    // } else saveAs(`${downloadUrl}`, 'feedbacks_csv');
    saveAs(`${process.env.REACT_APP_SERVER_MEDIA_URL}${downloadUrl}`, downloadUrl.split('/').pop());
  };

  function getDownloadUrl() {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/download_feedbacks_csv?agent_status=${status}&date=${updatedDateFormat.value}`;
    if (updatedDateFormat.value === 'customized') {
      url += `&start_date=${formatDate(fromDate)}&end_date=${formatDate(toDate)}`;
    }
    API.get(url, config).then((res) => {
      console.log(res);
      setDownloadUrl(res.data.download_url);
    });
  }
  useEffect(() => {
    if (updatedDateFormat.value !== 'customized') {
      getDownloadUrl();
      console.log('hell');
    } else if (updatedDateFormat.value === 'customized' && toDate !== null) {
      getDownloadUrl();
      console.log('bell');
    }
  }, [status, updatedDateFormat, toDate]);

  const CustomControlDate = ({ children, ...rest }) => {
    return (
      <components.Control {...rest}>
        <CalendarMonthIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
        {children}
      </components.Control>
    );
  };

  // console.log('downloadUrl', downloadUrl);
  return (
    <Page title="Feedback">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Feedback {feedbackCount && feedbackCount.total_count && `(${feedbackCount.total_count})`}
        </Typography>
        <Grid
          container
          spacing={0.8}
          // md={updatedDateFormat.label === 'Customize' ? 8 : 4}
          md={9}
          style={{
            display: 'flex',
            flexDirection: 'row',
            // justifyContent: 'space-between',
            justifyContent: 'end',
            alignItems: 'center',
            padding: 1
          }}
        >
          {!loading && (
            <div
              style={{
                display: 'flex',
                width: updatedDateFormat.label === 'Customize' ? '90%' : '43%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Select
                styles={customStyles}
                onChange={(e) => {
                  setUpdatedDateFormat(e);
                  setFromDate(null);
                  setToDate(null);
                  // getReportDetails(e.value);
                }}
                options={dateFormat?.map((element) => ({
                  value: element.id,
                  label: element.name
                }))}
                value={updatedDateFormat}
                components={{ Control: CustomControlDate }}
                placeholder={
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      alignItems: 'center',
                      color: '#000',
                      fontWeight: '400'
                    }}
                  />
                }
                isSearchable={false}
                isClearable={false}
              />
              {updatedDateFormat.label === 'Customize' && (
                <div style={{ marginBottom: '8px' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <div className={classes.datePicker}>
                        <DatePicker
                          label="From"
                          format="DD/MM/YYYY"
                          maxDate={dayjs()}
                          value={fromDate}
                          onChange={(newValue) => {
                            setFromDate(newValue);
                            setToDate(null);
                          }}
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              )}
              {updatedDateFormat.label === 'Customize' && (
                <div style={{ marginBottom: '8px' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <div className={classes.datePicker}>
                        <DatePicker
                          label="To"
                          format="DD/MM/YYYY"
                          disabled={fromDate === null}
                          minDate={fromDate && fromDate}
                          maxDate={dayjs()}
                          value={toDate}
                          onChange={(newValue) => {
                            setToDate(newValue);
                            setPage(1);
                            // getReportDetails('customized', newValue);
                          }}
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              )}
              <Button
                onClick={downloadHandler}
                variant="contained"
                to="#"
                disabled={!downloadUrl}
                sx={{
                  backgroundColor: '#107c41',
                  '&:hover': {
                    backgroundColor: '#3AB170'
                  }
                }}
              >
                Download
              </Button>
            </div>
          )}
        </Grid>
        {/* <div
          style={{
            dislay: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          <Select
            styles={customStyles}
            onChange={(e) => {
              setUpdatedDateFormat(e);
              setFromDate(null);
              setToDate(null);
              // getReportDetails(e.value);
            }}
            options={dateFormat?.map((element) => ({
              value: element.id,
              label: element.name
            }))}
            value={updatedDateFormat}
            components={{ Control: CustomControlDate }}
            placeholder={
              <div
                style={{
                  display: 'flex',
                  columnGap: 10,
                  alignItems: 'center',
                  color: '#000',
                  fontWeight: '400'
                }}
              />
            }
          />
          {updatedDateFormat.label === 'Customize' && (
            <div style={{ marginBottom: '8px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <div className={classes.datePicker}>
                    <DatePicker
                      label="From"
                      format="DD/MM/YYYY"
                      maxDate={dayjs()}
                      value={fromDate}
                      onChange={(newValue) => setFromDate(newValue)}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </div>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          )}
          {updatedDateFormat.label === 'Customize' && (
            <div style={{ marginBottom: '8px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <div className={classes.datePicker}>
                    <DatePicker
                      label="To"
                      format="DD/MM/YYYY"
                      disabled={fromDate === null}
                      minDate={fromDate && fromDate}
                      maxDate={dayjs()}
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(newValue);
                        setPage(0);
                        // getReportDetails('customized', newValue);
                      }}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </div>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          )}
          <Button
            onClick={downloadHandler}
            variant="contained"
            to="#"
            disabled={!downloadUrl}
            sx={{
              backgroundColor: '#107c41',
              '&:hover': {
                backgroundColor: '#3AB170'
              }
            }}
          >
            Download
          </Button>
        </div> */}
      </Stack>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValueForStatus}
          onChange={handleTabChangeForStatus}
          aria-label="basic tabs example"
          variant="scrollable"
          className={classes.root}
        >
          <Tab
            label={`Pending ${feedbackCount ? `(${feedbackCount.pending_count})` : ''}`}
            value={1}
            {...a11yProps(1)}
            className={classes.tab}
          />
          <Tab
            label={`Acknowledge ${feedbackCount ? `(${feedbackCount.acknowledge_count})` : ''}`}
            value={2}
            {...a11yProps(2)}
            className={classes.tab}
          />
          <Tab
            label={`Calibrated ${feedbackCount ? `(${feedbackCount.calibrated_count})` : ''}`}
            value={3}
            {...a11yProps(3)}
            className={classes.tab}
          />
        </Tabs>
      </Box>
      <Card>
        <Scrollbar sx={{ cursor: 'pointer' }}>
          <TableContainer>
            <Table>
              <UserListHead headLabel={UPLOAD_TABLE_HEAD} rowCount={page} />
              {!loading && overallFeedbackList.length > 0 ? (
                <TableBody>
                  <>
                    {overallFeedbackList
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((el) => (
                        <TableRow hover key={1} tabIndex={-1} role="checkbox">
                          {/* <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            align="center"
                            title={el.organisation_name}
                          >
                            {el.organisation_name}
                          </TableCell> */}
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            align="center"
                            title={el.customer_organisation_name}
                          >
                            {el.customer_organisation_name}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            align="center"
                            title={`${el.first_name} ${el.last_name}`}
                          >
                            {el.first_name} {el.last_name}
                          </TableCell>
                          {/* <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            align="center"
                            title="Feedback Status"
                          >
                            Feedback Status
                          </TableCell> */}
                          {/* <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            align="center"
                            title={el.feedback_desc}
                          >
                            {el.feedback_desc}
                          </TableCell> */}
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            align="center"
                            title={el.final_score}
                          >
                            {el.final_score ? el.final_score : 'NA'}
                          </TableCell>
                          {/* <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            align="center"
                            title={el.feedback_desc}
                          >
                            {el.feedback_desc}
                          </TableCell> */}
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '100px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            align="center"
                            title={changedDate(el.original_date)}
                          >
                            {changedDate(el.original_date)}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            align="center"
                            title={changedDate(el.eval_date)}
                          >
                            {el.eval_date ? changedDate(el.eval_date) : `-`}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            align="center"
                            title={changedDate(el.created_date)}
                          >
                            {el.created_date ? changedDate(el.created_date) : `-`}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '100px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            align="center"
                            title={el.manager_name}
                          >
                            {el.manager_name}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            align="center"
                            title={el.qa_name}
                          >
                            {el.qa_name}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '150px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            align="center"
                            title={decodeURI(el.s3url.split('/').pop().split('#')[0].split('?')[0])}
                          >
                            <Link
                              underline="hover"
                              sx={{ color: '#3892CF' }}
                              onClick={() => {
                                navigate('/dashboard/workspace/Upload/Results', {
                                  state: {
                                    path: '/dashboard/feedback/rating'
                                  }
                                });
                                setinputId(el.input_id);
                                setinputOrgId(el.organisation_id);
                                setrequestId();
                                setUploadedAudio(el.s3url);
                                setAudioS3(el.s3url);
                                setProcess();
                                setcustomerId(el.customer_id);
                                setrequestId(el.request_id);
                                setOriginalDate(changedDate(el.original_date));
                                // setKpi(inputDetails.kpi_services);
                                setAgentName(el.first_name);
                                setAgentLastName(el.last_name);
                                setAgentId(el.agent_id);
                              }}
                            >
                              {decodeURI(el.s3url.split('/').pop().split('#')[0].split('?')[0])}
                            </Link>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="normal" align="center">
                            {/* {Object.keys(el.good_parameter).length > 0 ||
                            Object.keys(el.improvement_parameter).length > 0 ? ( */}
                            <IconButton
                              onClick={() => {
                                setImprovementDetails(el);
                                handleScoreDetailAPI(el.input_id);
                                handlePerformanceAPI(el.feedback_id);
                                handleImprovementDialog();
                              }}
                            >
                              <Icon icon={eyeIcon} width={20} height={20} />
                            </IconButton>
                            {/* ) : ( '-' )} */}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="normal" align="center">
                            <Rating
                              name="half-rating-read"
                              defaultValue={el.rating}
                              precision={0.5}
                              readOnly
                            />
                          </TableCell>
                          {/* {userDetails.role === 'agent' && ( */}
                          {/* <TableCell>
                            <UserMoreMenuForFeedback
                              isUpdated={updateFeedback}
                              updateComponent={setUpdateFeedback}
                              getFeedbackList={getFeedbackList}
                              setAlert={setAlert}
                              setAlertContent={setAlertContent}
                              setAlertType={setAlertType}
                              inputDetails={el}
                            />
                          </TableCell> */}
                          {/* )} */}
                        </TableRow>
                      ))}
                  </>
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    {loading ? (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <CircularProgress sx={{ color: '#3892CF' }} />
                      </TableCell>
                    ) : (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        Data Not Found
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <Stack
          spacing={2}
          sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
        >
          <Pagination
            // count={overallFeedbackList ? Math.ceil((overallFeedbackList * 10) / 10) : 1}
            count={Math.ceil(max_page_count / 10)}
            page={page}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </Stack>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          rowsPerPage={rowsPerPage}
          count={feedbackList.length && feedbackList.length}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        <CustomFormModel
          fullScreen={fullScreen}
          handleDialog={handleAudioDialog}
          open={audioPop}
          height={50}
          title="Audio Play"
        >
          {audioUrl ? (
            <div>
              <audio
                src={
                  process.env.REACT_APP_ON_PREMISE === 'true'
                    ? `${process.env.REACT_APP_SERVER_MEDIA_URL}${audioUrl}`
                    : audioUrl
                }
                controls
              >
                <track default kind="captions" srcLang="en" src="" />
              </audio>
              {/* <Audio
                src={audioUrl}
                useStyles={audioStyles}
                autoPlay={false}
                rounded
                elevation={3}
                width="100%"
                download
              /> */}
            </div>
          ) : (
            <CircularProgress sx={{ color: '#3892CF' }} />
          )}
          {/* <Waveform url={audioPlay} /> */}
        </CustomFormModel>

        <CustomFormModel
          fullScreen={fullScreen}
          handleDialog={handleImprovementDialog}
          open={improvementOpen}
          height={400}
          title="Summary"
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <Typography>
                AI Average Score: <b>{improvementDetails?.ai_score}%</b>
              </Typography>
              {improvementDetails && improvementDetails?.manual_score !== null && (
                <Typography>
                  Manual Average Score:
                  <b>{Number(improvementDetails?.manual_score).toFixed(0)}%</b>
                </Typography>
              )}
            </div>
            <div>
              <Button
                variant="contained"
                disabled={
                  userDetails.role === 'QA' ||
                  userDetails.role === 'Customer_Admin' ||
                  userDetails.role === 'Manager'
                    ? tabValueForStatus === 1 || tabValueForStatus === 2
                    : userDetails.role === 'Agent'
                    ? tabValueForStatus === 2 || tabValueForStatus === 3
                    : false
                }
                onClick={() => {
                  setAgentAction('Acknowledge');
                  handleDialogForReason();
                }}
                sx={{
                  backgroundColor: '#3892CF',
                  '&:hover': {
                    backgroundColor: '#3892CF'
                  },
                  margin: '2px'
                }}
              >
                Acknowledge
              </Button>
              <Button
                variant="contained"
                disabled={
                  userDetails.role === 'QA' ||
                  userDetails.role === 'Customer_Admin' ||
                  userDetails.role === 'Manager'
                    ? true
                    : userDetails.role === 'Agent'
                    ? tabValueForStatus === 2 || tabValueForStatus === 3
                    : false
                }
                onClick={() => {
                  setAgentAction('Calibrate');
                  handleDialogForReason();
                }}
                sx={{
                  backgroundColor: '#3892CF',
                  '&:hover': {
                    backgroundColor: '#3892CF'
                  },
                  margin: '2px'
                }}
              >
                Calibrate
              </Button>
            </div>
          </div>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              variant="scrollable"
              className={classes.root}
            >
              <Tab label="Feedback" value={1} {...a11yProps(1)} className={classes.tab} />
              <Tab label="Summary" value={2} {...a11yProps(2)} className={classes.tab} />
              <Tab label="Score Details" value={3} {...a11yProps(3)} className={classes.tab} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={3} padding={2}>
              <Grid item xs={6}>
                <TextField
                  // required
                  fullWidth
                  label="Organisation Name"
                  defaultValue={improvementDetails?.organisation_name}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  // required
                  fullWidth
                  label="Customer Name"
                  defaultValue={improvementDetails?.customer_organisation_name}
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  // required
                  fullWidth
                  label="Agent Name"
                  defaultValue={`${improvementDetails?.first_name} ${improvementDetails?.last_name}`}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    border: '1px solid RGB(220 224 228)',
                    borderRadius: '5px',
                    height: 54,
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                  }}
                >
                  <Rating
                    name="half-rating-read"
                    defaultValue={improvementDetails?.rating}
                    precision={0.5}
                    readOnly
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    // required
                    fullWidth
                    label=" Feedback"
                    rows={2}
                    multiline
                    disabled
                    defaultValue={improvementDetails?.feedback_desc}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            {goodParam ? (
              <>
                <Grid container spacing={3} sx={{ marginBottom: 2 }}>
                  <Grid item xs={12} md={6} sm={12}>
                    <h4 style={{ padding: '0.5rem' }}>Good Parameters</h4>
                    <Card sx={{ height: 350 }}>
                      {Object.keys(goodParam.good_parameter).length > 0 ? (
                        <TableContainer sx={{ maxHeight: 340, overflowY: 'auto', width: 382 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <>
                              <TableHead sx={{ backgroundColor: '#808080' }}>
                                <TableRow>
                                  <TableCell>
                                    <b>Parameter</b>
                                  </TableCell>
                                  <TableCell align="center">
                                    <b>Insights</b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.keys(goodParam.good_parameter).map((item, index) => (
                                  <TableRow key={item}>
                                    <TableCell
                                      align="center"
                                      style={{
                                        whiteSpace: 'nowrap',
                                        maxWidth: '100px', // Adjust the max width as needed
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis' // Add ellipsis for truncation
                                      }}
                                      title={item}
                                    >
                                      {item}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: '100%', // Adjust the width as needed
                                        overflow: 'hidden'
                                      }}
                                    >
                                      <LinearProgressWithLabel
                                        value={goodParam.good_parameter[item]}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </>
                          </Table>
                        </TableContainer>
                      ) : (
                        <div
                          style={{
                            width: '100%',
                            height: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <span style={{ fontSize: 20 }}>
                            <b>No Good Parameters Available</b>
                          </span>
                        </div>
                      )}
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <h4 style={{ padding: '0.5rem' }}>Good Parameters Reason</h4>
                    <Card sx={{ height: 350, padding: 1, maxHeight: 350, overflowY: 'scroll' }}>
                      {goodParam.good_reason.length > 0 ? (
                        <Typography>
                          {/* {goodParam.good_reason.substring(0, goodParam.good_reason.length - 1)}. */}

                          {goodParam.good_reason}
                        </Typography>
                      ) : (
                        <div
                          style={{
                            width: '100%',
                            height: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <span style={{ fontSize: 20 }}>
                            <b>No Reason</b>
                          </span>
                        </div>
                      )}
                    </Card>
                  </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ marginBottom: 2 }}>
                  <Grid item xs={12} md={6} sm={12}>
                    <h4 style={{ padding: '0.5rem' }}>Need Improvement Parameters</h4>
                    <Card sx={{ height: 350 }}>
                      {Object.keys(goodParam.improvement_parameter).length > 0 ? (
                        <TableContainer sx={{ maxHeight: 340, overflowY: 'auto', width: 382 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <>
                              <TableHead sx={{ backgroundColor: '#808080' }}>
                                <TableRow>
                                  <TableCell>
                                    <b>Parameter</b>
                                  </TableCell>
                                  <TableCell align="center">
                                    <b>Insights</b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.keys(goodParam.improvement_parameter).map((item, index) => (
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      style={{
                                        whiteSpace: 'nowrap',
                                        maxWidth: '100px', // Adjust the max width as needed
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis' // Add ellipsis for truncation
                                      }}
                                      title={item}
                                    >
                                      {item}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: '100%', // Adjust the width as needed
                                        overflow: 'hidden'
                                      }}
                                    >
                                      <LinearProgressWithLabel
                                        value={goodParam.improvement_parameter[item]}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </>
                          </Table>
                        </TableContainer>
                      ) : (
                        <div
                          style={{
                            width: '100%',
                            height: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <span style={{ fontSize: 20 }}>
                            <b>No Improvement Parameters Available</b>
                          </span>
                        </div>
                      )}
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <h4 style={{ padding: '0.5rem' }}>Improvement Reason</h4>
                    <Card sx={{ height: 350, padding: 1, maxHeight: 350, overflowY: 'scroll' }}>
                      {goodParam.improvement_reason.length > 0 ? (
                        <Typography>
                          {goodParam.improvement_reason.substring(
                            0,
                            goodParam.improvement_reason.length - 1
                          )}
                          .
                        </Typography>
                      ) : (
                        <div
                          style={{
                            width: '100%',
                            height: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <span style={{ fontSize: 20 }}>
                            <b>No Reason</b>
                          </span>
                        </div>
                      )}
                    </Card>
                  </Grid>
                </Grid>
              </>
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '90%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <span style={{ fontSize: 20 }}>
                  <b>No Data Available</b>
                </span>
              </div>
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            {scoreDetails &&
            scoreDetails.review_scoring_details &&
            scoreDetails.review_scoring_details.length > 0 ? (
              <TableContainer sx={{ maxHeight: 340, overflowY: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                  <>
                    <TableHead sx={{ backgroundColor: '#808080' }}>
                      <TableRow>
                        <TableCell>
                          <b>Sr.No</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Parameter</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>AI Score</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Review Score</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scoreDetails.review_scoring_details.map((el, index) => (
                        <TableRow>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell
                            align="center"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '300px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            title={el.Category}
                          >
                            {el.Category}
                          </TableCell>
                          {scoreDetails.ai_scoring_details[index].score === 'NA' ? (
                            <TableCell align="center">NA</TableCell>
                          ) : scoreDetails.ai_scoring_details[index].weightage === 0 ? (
                            <TableCell align="center">0</TableCell>
                          ) : (
                            <TableCell align="center">
                              {scoreDetails.ai_scoring_details[index].score === null
                                ? 0
                                : scoreDetails.ai_scoring_details[index].score}
                              /{scoreDetails.ai_scoring_details[index].weightage}
                            </TableCell>
                          )}
                          {el.score === 'NA' ? (
                            <TableCell align="center">NA</TableCell>
                          ) : el.weightage === 0 ? (
                            <TableCell align="center">0</TableCell>
                          ) : (
                            <TableCell align="center">
                              {el.score === null ? 0 : el.score}/{el.weightage}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </>
                </Table>
              </TableContainer>
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '90%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <span style={{ fontSize: 20 }}>
                  <b>No Data Available</b>
                </span>
              </div>
            )}
          </TabPanel>
          <Notification
            message={alertContent}
            open={alert}
            severity={alertType}
            onClose={handleAlert}
          />
        </CustomFormModel>
        <CustomFormModel
          fullScreen={fullScreen}
          handleDialog={handleDialogForReason}
          open={openEdit}
          height={150}
          title="Add Reason"
        >
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    required
                    fullWidth
                    label="Reason"
                    {...getFieldProps('reason')}
                    error={Boolean(touched.reason && errors.reason)}
                    helperText={touched.reason && errors.reason}
                  />
                </FormControl>
              </Grid>
              <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  variant="contained"
                  onClick={handleCancel}
                  style={{
                    marginRight: 5,
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                >
                  Cancel
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  style={{
                    marginRight: 5,
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                >
                  Submit
                </LoadingButton>
              </Container>
            </Form>
          </FormikProvider>
        </CustomFormModel>
        <Notification
          message={alertContent}
          open={alert}
          severity={alertType}
          onClose={handleAlert}
        />
      </Card>
    </Page>
  );
}
function UserMoreMenuForFeedback({
  isUpdated,
  updateComponent,
  getFeedbackList,
  setAlert,
  setAlertContent,
  setAlertType,
  inputDetails
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(null);

  // formik for feedback reject reason
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reasonForReject: inputDetails.agent_reason ? inputDetails.agent_reason : ''
    },
    validationSchema: Yup.object().shape({
      reasonForReject: Yup.string()
        .matches(
          /^\S+[A-Za-z\s]{2,3000}$/,
          'Reason should be in between 3 to 30000 character and it should not start with space and does not contain special character and number.'
        )
        .max(3000, 'Comment should be 3 to 3000 character long.')
        .required('This field is required.')
    }),
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        agent_action: 'Reject',
        reason: formik.values.reasonForReject,
        id: inputDetails.feedback_id
      };
      console.log('data ---------------------', data);
      await API.put('/secure/update_feedback_status', data, config)
        .then((res) => {
          formik.resetForm();
          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          getFeedbackList();
          handleDialogForRejectReason();
        })
        // .then(updateComponent(isUpdated + 1))
        .catch((err) => {
          console.log('error----', err);
          formik.resetForm();
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const handleDialogForRejectReason = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };
  const handleCancel = () => {
    handleDialogForRejectReason();
    formik.resetForm();
  };

  const handleFeedbackStatus = (status) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = {
      agent_action: status,
      reason: null,
      id: inputDetails.feedback_id
    };
    console.log('data ---------------------', data);
    API.put('/secure/update_feedback_status', data, config)
      .then((res) => {
        formik.resetForm();
        setAlertContent(`Success: ${res.data.message}`);
        setAlertType('success');
        setAlert(true);
        getFeedbackList();
      })
      // .then(updateComponent(isUpdated + 1))
      .catch((err) => {
        formik.resetForm();
        console.log('error----', err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialogForRejectReason}
        open={openEdit}
        height={150}
        title="Add Reason Of Reject"
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  required
                  fullWidth
                  label="Reason"
                  {...getFieldProps('reasonForReject')}
                  error={Boolean(touched.reasonForReject && errors.reasonForReject)}
                  helperText={touched.reasonForReject && errors.reasonForReject}
                />
              </FormControl>
            </Grid>
            <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton
                variant="contained"
                onClick={handleCancel}
                style={{
                  marginRight: 5,
                  backgroundColor: '#3892CF',
                  '&:hover': {
                    backgroundColor: '#3892CF'
                  }
                }}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                variant="contained"
                onClick={handleSubmit}
                loading={isSubmitting}
                style={{
                  marginRight: 5,
                  backgroundColor: '#3892CF',
                  '&:hover': {
                    backgroundColor: '#3892CF'
                  }
                }}
              >
                Update
              </LoadingButton>
            </Container>
          </Form>
        </FormikProvider>
      </CustomFormModel>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={() => handleFeedbackStatus('Acknowledge')}
          selected={inputDetails.agent_action === 'Acknowledge'}
        >
          {/* <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon> */}
          <ListItemText primary="Acknowledge" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={() => handleFeedbackStatus('Calibrate')}
          selected={inputDetails.agent_action === 'Calibrate'}
        >
          {/* <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon> */}
          <ListItemText primary="Calibrate" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={handleDialogForRejectReason}
          selected={inputDetails.agent_action === 'Reject'}
        >
          <ListItemText primary="Reject" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}

export function ComplianceOpportunity() {
  const [opportunity, setOpportunity] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [opportunityTable, setOpportunityList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(0);
  const [updatedDateFormat, setUpdatedDateFormat] = useState({
    value: 'one_week',
    label: 'One Week'
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  function getOpportunityList() {
    setLoading(true);
    const url = `/secure/list_opportunity_agent`;
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    API.get(url, config)
      .then((res) => {
        setOpportunityList(res.data.opportunity_list);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
        setAlert(true);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
        setLoading(false);
      });
  }

  useEffect(() => {
    getOpportunityList();
  }, [update]);

  const handleOpportunityEdit = () => {
    setEditOpen(!editOpen);
    setIsOpen(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAlert = () => {
    setAlert(false);
  };

  const changedDate = (date) => {
    const newdate = new Date(date);
    const formatStartDate = `${newdate.getDate()}-${
      newdate.getMonth() + 1
    }-${newdate.getFullYear()}`;
    return formatStartDate;
  };

  // function for ageing showning
  function formatTime(hours) {
    if (hours < 1 / 60) {
      return `${Math.floor(hours * 3600)} sec`;
    } else if (hours < 1) {
      return `${Math.floor(hours * 60)} min`;
    } else if (hours < 24) {
      return `${Math.floor(hours)} hr`;
    } else if (hours < 24 * 7) {
      return `${Math.floor(hours / 24)} day`;
    } else if (hours < 24 * 7 * 4) {
      return `${Math.floor(hours / (24 * 7))} week`;
    } else if (hours < 24 * 7 * 4 * 4) {
      return `${Math.floor(hours / (24 * 7 * 4))} month`;
    } else if (hours < 24 * 7 * 4 * 4 * 12) {
      return `${Math.floor(hours / (24 * 7 * 4 * 4))} yr`;
    } else {
      return 'more than yr';
    }
  }

  const dateFormat = [
    { name: 'One Week', id: 'one_week' },
    { name: 'One Month', id: 'one_month' },
    // { name: 'Six Month', id: 'six_month' },
    // { name: 'One Year', id: 'one_year' },
    { name: 'Customize', id: 'customized' }
  ];
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTabs-indicator': {
        backgroundColor: '#1D9FD9' // change this to your desired color
      }
    },
    tab: {
      '&.Mui-selected': {
        color: '#1D9FD9' // change this to your desired color
      }
    },
    menuScoreCard: {
      maxHeight: 150,
      maxWidth: 50
    },
    datePicker: {
      '& .MuiTextField-root': {
        width: 170
        // marginBottom: 1
        // height: 10
      },
      '& .MuiInputLabel-shrink': {
        color: '#3892CF' // Set your desired color for the shrunk label
      },
      '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#3892CF'
      }
    }
  }));
  const classes = useStyles();
  const CustomControlDate = ({ children, ...rest }) => {
    return (
      <components.Control {...rest}>
        <CalendarMonthIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
        {children}
      </components.Control>
    );
  };

  return (
    <Page title="Goals">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Goals
        </Typography>

        {/* <Grid
          container
          spacing={0.8}
          // md={updatedDateFormat.label === 'Customize' ? 8 : 4}
          md={9}
          style={{
            display: 'flex',
            flexDirection: 'row',
            // justifyContent: 'space-between',
            justifyContent: 'end',
            alignItems: 'center',
            padding: 1
          }}
        >
          {!loading && (
            <div
              style={{
                display: 'flex',
                width: updatedDateFormat.label === 'Customize' ? '90%' : '43%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Select
                styles={{
                  option: (base, state) => ({
                    ...base,
                    fontWeight: state.isSelected ? '500' : 'normal',
                    maxHeight: 35
                  }),
                  control: (base, state) => ({
                    ...base,
                    fontWeight: '500'
                    // border: 'none'
                    // maxHeight: 35
                  }),
                  valueContainer: (base, state) => ({
                    ...base,
                    padding: '2px',
                    maxHeight: 38,
                    overflow: 'auto'
                  })
                }}
                // onChange={(e) => {
                //   setUpdatedDateFormat(e);
                //   setFromDate(null);
                //   setToDate(null);
                //   setPage(1);
                //   // getReportDetails(e.value);
                // }}
                options={dateFormat?.map((element) => ({
                  value: element.id,
                  label: element.name
                }))}
                value={updatedDateFormat}
                components={{ Control: CustomControlDate }}
                placeholder={
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      alignItems: 'center',
                      color: '#000',
                      fontWeight: '400'
                    }}
                  />
                }
              />
              {updatedDateFormat.label === 'Customize' && (
                <div style={{ marginBottom: '8px' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <div className={classes.datePicker}>
                        <DatePicker
                          label="From"
                          format="DD/MM/YYYY"
                          // maxDate={dayjs()}
                          // value={fromDate}
                          // onChange={(newValue) => setFromDate(newValue)}
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              )}
              {updatedDateFormat.label === 'Customize' && (
                <div style={{ marginBottom: '8px' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <div className={classes.datePicker}>
                        <DatePicker
                          label="To"
                          format="DD/MM/YYYY"
                          // disabled={fromDate === null}
                          // minDate={fromDate && fromDate}
                          // maxDate={dayjs()}
                          // value={toDate}
                          // onChange={(newValue) => {
                          //   setToDate(newValue);
                          //   setPage(1);
                          //   getReportDetails('customized', newValue);
                          // }}
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              )}
            </div>
          )}
        </Grid> */}
      </Stack>
      <Card>
        <Scrollbar sx={{ cursor: 'pointer' }}>
          <TableContainer>
            <Table>
              <UserListHead headLabel={OPPORTUNITY_TABLE_HEAD} />
              {!loading && opportunityTable.length > 0 ? (
                <TableBody>
                  {opportunityTable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((el) => (
                      <TableRow>
                        <TableCell>
                          {el.imporvement_desc.length > 15 ? (
                            <Tip title={el.imporvement_desc} placement="top">
                              <div>{el.imporvement_desc.slice(0, 15)}...</div>
                            </Tip>
                          ) : (
                            <div>{el.imporvement_desc}</div>
                          )}
                        </TableCell>
                        <TableCell align="center">{changedDate(el.created_date)}</TableCell>
                        <TableCell align="center">
                          {el.completed_date ? changedDate(el.completed_date) : '-'}
                        </TableCell>
                        <TableCell align="center">{el.opportunity_status}</TableCell>
                        <TableCell align="center">
                          {el.comment_desc.length > 15 ? (
                            <Tip title={el.comment_desc} placement="top">
                              <div>{el.comment_desc.slice(0, 15)}...</div>
                            </Tip>
                          ) : (
                            <div>{el.comment_desc}</div>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {el.ageing ? `${formatTime(el.ageing)} ago` : '-'}
                        </TableCell>
                        <TableCell>
                          {/* <IconButton
                            onClick={() => {
                              handleOpportunityEdit();
                              setEditOpen(el);
                            }}
                          >
                            <Icon icon={moreVerticalFill} width={20} height={20} />
                          </IconButton> */}
                          <UserMoreMenu
                            // formik={formik}
                            isUpdated={update}
                            updateComponent={setUpdate}
                            setAlert={setAlert}
                            setAlertContent={setAlertContent}
                            setAlertType={setAlertType}
                            // inputId={}
                            inputDetails={el}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    {loading ? (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <CircularProgress sx={{ color: '#3892CF' }} />
                      </TableCell>
                    ) : (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        Data Not Found
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          rowsPerPage={rowsPerPage}
          count={opportunityTable && opportunityTable.length}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}

function OpportunityEditForm({
  inputDetails,
  handleDialog,
  updateComponent,
  isUpdated,
  setAlert,
  setAlertContent,
  setAlertType
}) {
  // use formik for task .
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: userDetails.role === 'QA' ? userDetails.customer_id : inputDetails.customer_id,
      organisationId: `${
        userDetails.role !== 'Super_Admin'
          ? userDetails.organisation_id
          : inputDetails.organisation_id
      }`,
      agent: inputDetails.agent_id,
      comment: inputDetails.comment_desc,
      improvement: inputDetails.imporvement_desc,
      status: inputDetails.opportunity_status
    },
    validationSchema: Yup.object().shape({
      customer: Yup.string().required('This field is required.'),
      organisationId: Yup.string().required('This field is required.'),
      agent: Yup.string().required('This field is required.'),
      improvement: Yup.string()
        .matches(
          /^\S+[A-Za-z\s]{2,50}$/,
          'Improvement should be in between 3 to 50 character and it should not start with space and does not contain special character and number.'
        )
        .max(50, 'Improvement should be 3 to 50 character long.')
        .required('This field is required.'),
      comment: Yup.string()
        .matches(
          /^\S+[A-Za-z\s]{2,50}$/,
          'Comment should be in between 3 to 50 character and it should not start with space and does not contain special character and number.'
        )
        .max(50, 'Comment should be 3 to 50 character long.')
        .required('This field is required.'),
      status: Yup.string().required('This field is required.')
    }),
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        customer_id: formik.values.customer,
        user_id: formik.values.agent,
        organisation_id: formik.values.organisationId,
        improvement: formik.values.improvement,
        comment: formik.values.comment,
        opportunity_status: formik.values.status,
        id: inputDetails.opportunity_id
      };
      // console.log('data ---------------------', data);
      await API.put('/secure/update_opportunity', data, config)
        .then((res) => {
          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          handleDialog();
          updateComponent(isUpdated + 1);
        })
        // .then(updateComponent(isUpdated + 1))
        .catch((err) => {
          console.log('error----', err);
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const [userAudio, setuserAudio] = useState({ File_Name: '', Data: '' });

  /* ----- Creating state for customer, process and agent ------ */
  const [customerList, setcustomerList] = useState();
  const [processList, setprocessList] = useState();
  const [agentList, setagentList] = useState();
  const [OrganisationList, setOrganisationList] = useState();
  const [checkInDate, setCheckInDate] = useState(null);
  // const [alert, setAlert] = useState(false);
  // const [alertContent, setAlertContent] = useState('');
  // const [alertType, setAlertType] = useState('');

  const statusList = [
    { status_id: 'completed', status_name: 'Completed' },
    { status_id: 'assigned', status_name: 'Assigned' }
  ];

  const handleCancel = () => {
    handleDialog();
    formik.resetForm();
  };
  const handleAlert = () => {
    setAlert(false);
  };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/list_of_customer?organisation_id=${formik.values.organisationId}`,
      config
    )
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.organisationId !== '') {
      getCustomerList();
    } else {
      setcustomerList([]);
    }
  }, [formik.values.organisationId]);

  const getAgentList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getRole() {
      return API.get(
        `secure/list_of_agent?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisationId}`,
        config
      );
    }
    Promise.all([getRole()])
      .then(([agentData]) => {
        setagentList(agentData.data.agent_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (formik.values.customer !== '') {
      getAgentList();
    } else {
      setagentList([]);
    }
  }, [formik.values.customer]);

  const setCustomerValue = (value) => {
    formik.setFieldValue('customer', value.customer_id);
  };

  console.log('first', formik.values);

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));
  const classes = useStyles();

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={6}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <SelectBox
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  {...getFieldProps('organisationId')}
                  error={Boolean(touched.organisationId && errors.organisationId)}
                  helperText={touched.organisationId && errors.organisationId}
                  disabled={userDetails.role !== 'Super_Admin'}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {OrganisationList
                    ? OrganisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </SelectBox>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.organisationId && errors.organisationId}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <SelectBox
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  {...getFieldProps('customer')}
                  error={Boolean(touched.customer && errors.customer)}
                  helperText={touched.customer && errors.customer}
                  disabled={userDetails.role !== 'Super_Admin'}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                  // onChange={(e) => setCustomerValue(e.target.value)}
                >
                  {customerList
                    ? customerList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={customer_id}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </SelectBox>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.customer && errors.customer}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="agent-select-label">
                Select Agent
              </InputLabel>
              <SelectBox
                required
                labelId="agent-select-label"
                label="Select Agent"
                id="select-agent"
                {...getFieldProps('agent')}
                error={Boolean(touched.agent && errors.agent)}
                helperText={touched.agent && errors.agent}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                disabled={userDetails.role !== 'Super_Admin'}
              >
                {agentList
                  ? agentList.map((element) => {
                      const { user_id, first_name, last_name, empcode } = element;
                      return (
                        <MenuItem key={user_id} value={user_id}>
                          {empcode} - {first_name} {last_name}
                        </MenuItem>
                      );
                    })
                  : null}
              </SelectBox>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.agent && errors.agent}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                required
                fullWidth
                label="Goal"
                {...getFieldProps('improvement')}
                error={Boolean(touched.improvement && errors.improvement)}
                helperText={touched.improvement && errors.improvement}
                disabled={userDetails.role !== 'Super_Admin'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="task-improvement-label">
                Status
              </InputLabel>
              <SelectBox
                required
                labelId="task-improvement-label"
                label="Goal Status"
                id="select-improvement"
                {...getFieldProps('status')}
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
                // onChange={(e) => setDeal(e.target.value)}
              >
                {statusList
                  ? statusList.map((element) => {
                      const { status_id, status_name } = element;
                      return (
                        <MenuItem key={status_name} value={status_name}>
                          {status_name}
                        </MenuItem>
                      );
                    })
                  : null}
              </SelectBox>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.status && errors.status}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                required
                fullWidth
                label="Comments"
                {...getFieldProps('comment')}
                error={Boolean(touched.comment && errors.comment)}
                helperText={touched.comment && errors.comment}
                disabled={userDetails.role !== 'Super_Admin'}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleCancel}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={isSubmitting}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Update
          </LoadingButton>
        </Container>

        {/* <Notification
          message={alertContent}
          open={alert}
          severity={alertType}
          onClose={handleAlert}
        /> */}
      </Form>
    </FormikProvider>
  );
}

function UserMoreMenu({
  // formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  // inputId,
  inputDetails
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(null);

  const handleDialog = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };

  // const handleDeleteInput = async () => {
  //   const token = localStorage.getItem('token');
  //   const config = {
  //     headers: { Authorization: `Bearer ${token}` }
  //   };
  //   await API.delete(`/secure/deactivate_campaign?id=${inputId}`, config)
  //     .then((res) => {
  //       setAlertContent(`Success : Campaign deleted successfully.`);
  //       setAlertType('success');
  //       setAlert(true);
  //     })
  //     .then(() => {
  //       updateComponent(isUpdated + 1);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setAlertContent(`${err.response.data.message}`);
  //       setAlertType('error');
  //       setAlert(true);
  //     });
  // };

  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={openEdit}
        height={300}
        title="Update Goal"
      >
        <OpportunityEditForm
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          isUpdated={isUpdated}
          updateComponent={updateComponent}
          handleDialog={handleDialog}
          inputDetails={inputDetails}
        />
      </CustomFormModel>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem to="#" sx={{ color: 'text.secondary' }} onClick={handleDialog}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
