import { useState, useEffect, useContext } from 'react';
import ReactApexChart from 'react-apexcharts';

import { useTheme, styled } from '@mui/material/styles';
import {
  Card,
  CardHeader,
  useMediaQuery,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Container
} from '@mui/material';
import { UserListHead, UserListToolbar } from '../../../components/_dashboard/user';
import { CustomFormModel } from '../../../components/custom-form-model/CustomFormModel';
import inputContex from '../../../Context/UploadContext/inputContext';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

// const CHART_DATA = [4344, 5435, 1443, 4443];

export default function CustomerSentiment({ data, handleDialog }) {
  const { customerSentimentIndex, setCustomerSentimentIndex } = useContext(inputContex);
  const [test, setTest] = useState(false);
  // console.log('data-----', data);
  useEffect(() => {
    if (test) {
      handleDialog();
      setTest(false);
    }
  }, [test]);

  const theme = useTheme();

  const LABEL_TOTAL = {
    show: true,
    label: 'Total',
    color: theme.palette.text.secondary,
    ...theme.typography.subtitle2
  };

  const LABEL_VALUE = {
    offsetY: 8,
    color: theme.palette.text.primary,
    ...theme.typography.h3
  };

  const sentimentOptions = {
    chart: {
      width: 380,
      type: 'donut',
      events: {
        dataPointSelection: (config, event, chartContext) => {
          console.log('config----', config);
          console.log('event----', event);
          console.log('chartContext----', chartContext.dataPointIndex);
          setCustomerSentimentIndex(chartContext.dataPointIndex);
          // if (chartContext.dataPointIndex === 2) {
          setTest(true);
          // }
        }
      }
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: LABEL_VALUE,
            total: LABEL_TOTAL
          }
        }
      }
    },
    colors: ['#02b6c9', '#02cf5b', '#f75e5e'],
    labels: ['Neutral', 'Positive', 'Negative'],
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    fill: {
      type: 'gradient'
    },
    legend: {
      onItemHover: {
        highlightDataSeries: false
      },
      horizontalAlign: 'center',
      position: 'bottom'
    },
    tooltip: {
      fillSeriesColor: false
    }
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     options: {
    //       chart: {
    //         width: 200
    //       },
    //       legend: {
    //         position: 'bottom'
    //       }
    //     }
    //   }
    // ]
  };

  return (
    <>
      <Card>
        <ChartWrapperStyle dir="ltr">
          <ReactApexChart
            style={{ border: '1 px solid red' }}
            type="donut"
            series={data}
            options={sentimentOptions}
            height={370}
          />
        </ChartWrapperStyle>
      </Card>
    </>
  );
}
