/* eslint-disable prefer-arrow-callback */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useFormik } from 'formik';
// material
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Container, Stack, Typography } from '@mui/material';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
// Components
import Page from '../../components/Page';
import API from '../../E2E/axios.util';
import { CustomFormModel } from '../../components/custom-form-model/CustomFormModel';
import DispositionForm from './DispositionForm';
import DispositionDataModel from './DispositionDataModel';
import { Notification } from '../../components/Notification/Notification';

export default function Domain() {
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [open, setOpen] = useState();
  const [dispositionList, setDispositionList] = useState();
  const [update, setUpdate] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const userDetails = JSON.parse(localStorage.getItem('user'));

  const DomainSchema = Yup.object().shape({
    organisation: Yup.string().required('This field is required.'),
    customerOrganisation: Yup.string().required('This field is required.'),
    domain: Yup.string().required('This field is required.'),
    querySentences: Yup.array().of(
      Yup.object({
        Category: Yup.string()
          .required('This field is required.')
          .min(3, 'Category must be at least 3 characters.')
          .max(50, 'Category must not exceed 50 characters')
          .test('unique-category', 'Category should be unique.', function (value) {
            const categories = formik.values.querySentences.map((sentence) =>
              sentence.Category?.toLowerCase()
            );
            const lowercaseValue = value?.toLowerCase();

            return categories.filter((category) => category === lowercaseValue).length === 1;
          }),
        subCategory: Yup.array().of(
          Yup.object({
            sentence: Yup.array()
              .min(1, 'This field is required.')
              .of(Yup.string().required('This field is required.')),
            disposition: Yup.string().required('This field is required.')
          })
        )
      })
    ),
    requestSentences: Yup.array().of(
      Yup.object({
        subCategory: Yup.array().of(
          Yup.object({
            sentence: Yup.array()
              .min(1, 'This field is required.')
              .of(Yup.string().required('This field is required.')),
            disposition: Yup.string().required('This field is required.')
          })
        ),
        Category: Yup.string()
          .required('This field is required.')
          .min(3, 'Category must be at least 3 characters.')
          .max(50, 'Category must not exceed 50 characters')
          .test('unique-category', 'Category should be unique.', function (value) {
            const categories = formik.values.requestSentences.map((sentence) =>
              sentence.Category?.toLowerCase()
            );
            const lowercaseValue = value?.toLowerCase();

            return categories.filter((category) => category === lowercaseValue).length === 1;
          })
      })
    ),
    complaintSentences: Yup.array().of(
      Yup.object({
        subCategory: Yup.array().of(
          Yup.object({
            sentence: Yup.array()
              .min(1, 'This field is required.')
              .of(Yup.string().required('This field is required.')),
            disposition: Yup.string().required('This field is required.')
          })
        ),
        Category: Yup.string()
          .required('This field is required.')
          .min(3, 'Category must be at least 3 characters.')
          .max(50, 'Category must not exceed 50 characters')
          .test('unique-category', 'Category should be unique.', function (value) {
            const categories = formik.values.complaintSentences.map((sentence) =>
              sentence.Category?.toLowerCase()
            );
            const lowercaseValue = value?.toLowerCase();

            return categories.filter((category) => category === lowercaseValue).length === 1;
          })
      })
    )
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      organisation: `${userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : ''}`,
      customerOrganisation: userDetails.role === 'QA' ? userDetails.customer_id : '',
      domain: '',
      querySentences: [
        {
          Category: '',
          subCategory: [{ disposition: '', sentence: [''] }]
        }
      ],
      requestSentences: [
        {
          Category: '',
          subCategory: [{ disposition: '', sentence: [''] }]
        }
      ],
      complaintSentences: [
        {
          Category: '',
          subCategory: [{ disposition: '', sentence: [''] }]
        }
      ]
    },
    validationSchema: DomainSchema,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        // processes: formik.values.process,
        org_id: formik.values.organisation,
        customer_id: formik.values.customerOrganisation,
        processes: formik.values.domain,
        querySentences: formik.values.querySentences,
        requestSentences: formik.values.requestSentences,
        complaintSentences: formik.values.complaintSentences
      };
      await API.post('/secure/add_qrc_disposition', data, config)
        .then((res) => {
          formik.resetForm();
          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          handleDialog();
          setUpdate(update + 1);
        })
        .catch((err) => {
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });

  const handleDialog = () => {
    setCurrentLevel(0);
    setOpen(!open);
    // formik.resetForm();
  };

  const handleAlert = () => {
    setAlert(false);
  };

  // const generateDocx = () => {
  //   // Create a new document
  //   const doc = new Document();
  //   doc.creator = 'Your Name';

  //   // Add content to the document
  //   doc.addSection({
  //     properties: {},
  //     children: [
  //       new Paragraph({
  //         children: [
  //           new TextRun('Hello,'),
  //           new TextRun({
  //             text: ' World!',
  //             bold: true
  //           })
  //         ]
  //       })
  //     ]
  //   });

  //   // Generate the DOCX content
  //   Packer.toBuffer(doc).then((buffer) => {
  //     // Download the document
  //     saveAs(new Blob([buffer]), 'generated-document.docx');
  //   });
  // };

  return (
    <Page title="disposition">
      <>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Disposition {dispositionList && `(${dispositionList.count})`}
          </Typography>
          {/* <Button onClick={generateDocx}>Generate DOCX</Button> */}
          {(userDetails.role !== 'Manager' || userDetails.role !== 'Customer_Admin') && (
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={handleDialog}
              sx={{
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              New Disposition
            </Button>
          )}
        </Stack>
        <Container>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleDialog}
            open={open}
            title={
              currentLevel === 0
                ? 'Add Intents'
                : currentLevel === 1
                ? 'Add Categories'
                : currentLevel === 2
                ? 'Add Sub-Categories'
                : ''
            }
          >
            <DispositionForm
              setUpdate={setUpdate}
              update={update}
              currentLevel={currentLevel}
              setCurrentLevel={setCurrentLevel}
              handleDialog={handleDialog}
              formik={formik}
            />
          </CustomFormModel>
        </Container>
        <DispositionDataModel
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          handleDialog={handleDialog}
          formik={formik}
          isUpdated={update}
          updateComponent={setUpdate}
          dispositionList={dispositionList}
          setDispositionList={setDispositionList}
          currentLevel={currentLevel}
          setCurrentLevel={setCurrentLevel}
        />
      </>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}
