import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { FormikProvider, Form, FieldArray } from 'formik';
import {
  Grid,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Container,
  FormHelperText,
  Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import makeStyles from '@mui/styles/makeStyles';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import API from '../../E2E/axios.util';

// -------------------------------------------------------
// const categoryList = [
//   { name: 'Unpleasant', id: 'Unpleasant' },
//   { name: 'Lead Identifiers', id: 'Lead_Identifier' }
// ];

export default function KeywordForm({ formik, handleDialog }) {
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const [organisationList, setorganisationList] = useState([]);
  const [customerOrgList, setcustomerOrgList] = useState([]);
  const [processList, setprocessList] = useState([]);
  const [processName, setprocessName] = useState();
  const [customerName, setCustomerName] = useState();
  const [services, setServices] = useState([]);

  const categoryList = [];
  if (services.includes('sales')) {
    categoryList.push({ name: 'Lead Identifiers', id: 'Lead_Identifier' });
  } else if (services.includes('collections')) {
    categoryList.push({ name: 'Unpleasant', id: 'Unpleasant' });
  }

  const userDetails = JSON.parse(localStorage.getItem('user'));

  const handleCancel = () => {
    handleDialog();
    formik.resetForm();
  };

  const handleProcess = (processValue, index) => {
    // console.log('first---', index);
    formik.setFieldValue('process[0]', processValue);
    setprocessName(processValue);
  };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setorganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${formik.values.organisation}`, config)
      .then((res) => {
        setcustomerOrgList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.organisation !== '') {
      getCustomerList();
    } else {
      setcustomerOrgList([]);
    }
  }, [formik.values.organisation]);

  const getProcessList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `/secure/process_list?customer_id=${formik.values.customerOrganisation}&organisation_id=${formik.values.organisation}`,
        config
      );
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.customerOrganisation) {
      getProcessList();
    } else {
      setprocessList([]);
    }
  }, [formik.values.customerOrganisation]);

  // -----------------------------------------------------------
  // handlecustomer values.

  const handleCustomerChange = (e) => {
    setCustomerName(e.customer_organisation_name);
    formik.setFieldValue('customerOrganisation', e.customer_id);
    setServices(e.dashboard_services);
  };

  // console.warn('formik.values.keywords -->', formik.values.keywords);
  // console.warn('formik,values.sentence ------>', formik.values.sentences);

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));

  const classes = useStyles();

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Select
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  {...getFieldProps('organisation')}
                  error={Boolean(touched.organisation && errors.organisation)}
                  helperText={touched.organisation && errors.organisation}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {organisationList
                    ? organisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.organisation && errors.organisation}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' || userDetails.role === 'Customer_Admin' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Select
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  // {...getFieldProps('customerOrganisation')}
                  value={customerName}
                  onChange={(e) => handleCustomerChange(e.target.value)}
                  error={Boolean(touched.customerOrganisation && errors.customerOrganisation)}
                  helperText={touched.customerOrganisation && errors.customerOrganisation}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {customerOrgList
                    ? customerOrgList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={element}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.customerOrganisation && errors.customerOrganisation}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="process-select-label">
                Select Process
              </InputLabel>
              <Select
                required
                labelId="process-select-label"
                label="Select Process"
                id="select-process"
                // onBlur={formik.handleBlur}
                // {...getFieldProps('process[0]')}
                error={Boolean(touched.process && errors.process)}
                helperText={touched.process && errors.process}
                onChange={(e) => handleProcess(e.target.value)}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {processList
                  ? processList.map((element, index) => {
                      const process = Object.values(element)[0];
                      return (
                        <MenuItem key={element} value={element}>
                          {element}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.process && errors.process}
              </FormHelperText>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              required
              multiline
              rows={2}
              label="Keywords"
              {...getFieldProps('keywords')}
              error={Boolean(touched.keywords && errors.keywords)}
              helperText={touched.keywords && errors.keywords}
            />
          </Grid> */}
          <Grid item xs={12}>
            <FieldArray
              name="keywords"
              render={(arrayHelpers) => (
                <>
                  {formik.values.keywords.map((row, index) => {
                    console.log('row ---', row);
                    console.log('index--', index);
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '5px'
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={8}>
                            <TextField
                              required
                              fullWidth
                              multiline
                              rows={2}
                              label="Keywords"
                              name="keywords"
                              onBlur={formik.handleBlur}
                              // {...getFieldProps(`keywords[${index}].words`)}
                              onChange={(e) => {
                                // console.warn('keywords --------------', e.target.value);
                                formik.setFieldValue(
                                  `keywords[${index}].words`,
                                  e.target.value.split(',')
                                );
                              }}
                              error={Boolean(
                                touched &&
                                  touched.keywords &&
                                  touched.keywords[index] &&
                                  touched.keywords[index].words &&
                                  errors &&
                                  errors.keywords &&
                                  errors.keywords[index] &&
                                  errors.keywords[index].words
                              )}
                              helperText={
                                touched &&
                                touched.keywords &&
                                touched.keywords[index] &&
                                touched.keywords[index].words &&
                                errors &&
                                errors.keywords &&
                                errors.keywords[index] &&
                                errors.keywords[index].words
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              required
                              fullWidth
                              label="Select Category"
                              name="keywords"
                              onBlur={formik.handleBlur}
                              // {...getFieldProps(`keywords[${index}].words`)}
                              onChange={(e) => {
                                // console.warn('keywords --------------', e.target.value);
                                formik.setFieldValue(`keywords[${index}].Category`, e.target.value);
                              }}
                              error={Boolean(
                                touched &&
                                  touched.keywords &&
                                  touched.keywords[index] &&
                                  touched.keywords[index].Category &&
                                  errors &&
                                  errors.keywords &&
                                  errors.keywords[index] &&
                                  errors.keywords[index].Category
                              )}
                              helperText={
                                touched &&
                                touched.keywords &&
                                touched.keywords[index] &&
                                touched.keywords[index].Category &&
                                errors &&
                                errors.keywords &&
                                errors.keywords[index] &&
                                errors.keywords[index].Category
                              }
                            />
                          </Grid>

                          {/* <Grid item xs={4}>
                            <FormControl fullWidth>
                              <InputLabel required id="category-select-label">
                                Select Category
                              </InputLabel>
                              <Select
                                required
                                labelId="category-select-label"
                                label="Select Category"
                                id="select-Category"
                                {...getFieldProps(`keywords[${index}].Category`)}
                                error={Boolean(
                                  touched &&
                                    touched.keywords &&
                                    touched.keywords[index] &&
                                    touched.keywords[index].Category &&
                                    errors &&
                                    errors.keywords &&
                                    errors.keywords[index] &&
                                    errors.keywords[index].Category
                                )}
                                helperText={
                                  touched &&
                                  touched.keywords &&
                                  touched.keywords[index] &&
                                  touched.keywords[index].Category &&
                                  errors &&
                                  errors.keywords &&
                                  errors.keywords[index] &&
                                  errors.keywords[index].Category
                                }
                              >
                                {categoryList.map((element) => (
                                  <MenuItem key={element.id} value={element.id}>
                                    {element.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText sx={{ color: '#d32f2f' }}>
                                {touched &&
                                  touched.keywords &&
                                  touched.keywords[index] &&
                                  touched.keywords[index].Category &&
                                  errors &&
                                  errors.keywords &&
                                  errors.keywords[index] &&
                                  errors.keywords[index].Category}
                              </FormHelperText>
                            </FormControl>
                          </Grid> */}
                        </Grid>

                        {index === formik.values.keywords.length - 1 ? (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: `${index > 0 ? 'visible' : 'hidden'}`
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.push({
                                  words: [''],
                                  Category: ''
                                });
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: 'hidden'
                              }}
                              onClick={() => {
                                arrayHelpers.push({
                                  words: [''],
                                  Category: ''
                                });
                              }}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FieldArray
              name="sentences"
              render={(arrayHelpers) => (
                <>
                  {formik.values.sentences.map((row, index) => {
                    console.log('row ---', row);
                    console.log('index--', index);
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '5px'
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={8}>
                            <TextField
                              required
                              fullWidth
                              multiline
                              rows={2}
                              label="Sentence"
                              // {...getFieldProps(`sentences[${index}].sentence`)}
                              onChange={(e) => {
                                // console.warn('keywords --------------', e.target.value);
                                formik.setFieldValue(
                                  `sentences[${index}].sentence`,
                                  e.target.value.split(',')
                                );
                              }}
                              error={Boolean(
                                touched &&
                                  touched.sentences &&
                                  touched.sentences[index] &&
                                  touched.sentences[index].sentence &&
                                  errors &&
                                  errors.sentences &&
                                  errors.sentences[index] &&
                                  errors.sentences[index].sentence
                              )}
                              helperText={
                                touched &&
                                touched.sentences &&
                                touched.sentences[index] &&
                                touched.sentences[index].sentence &&
                                errors &&
                                errors.sentences &&
                                errors.sentences[index] &&
                                errors.sentences[index].sentence
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl fullWidth>
                              //  <InputLabel required id="category-select-label">
                              //   Select Category
                              // </InputLabel>
                              // <Select
                              //   required
                              //   labelId="category-select-label"
                              //   label="Select Category"
                              //   id="select-Category"
                              //   {...getFieldProps(`sentences[${index}].Category`)}
                              //   error={Boolean(
                              //     touched &&
                              //       touched.sentences &&
                              //       touched.sentences[index] &&
                              //       touched.sentences[index].Category &&
                              //       errors &&
                              //       errors.sentences &&
                              //       errors.sentences[index] &&
                              //       errors.sentences[index].Category
                              //   )}
                              //   helperText={
                              //     touched &&
                              //     touched.sentences &&
                              //     touched.sentences[index] &&
                              //     touched.sentences[index].Category &&
                              //     errors &&
                              //     errors.sentences &&
                              //     errors.sentences[index] &&
                              //     errors.sentences[index].Category
                              //   }
                              // >
                              //   {categoryList.map((element) => (
                              //     <MenuItem key={element} value={element}>
                              //       {element}
                              //     </MenuItem>
                              //   ))}
                              // </Select> 
                              <TextField
                                required
                                fullWidth
                                label="Select Category"
                                name="sentences"
                                onBlur={formik.handleBlur}
                                // {...getFieldProps(`keywords[${index}].words`)}
                                onChange={(e) => {
                                  // console.warn('keywords --------------', e.target.value);
                                  formik.setFieldValue(
                                    `sentences[${index}].Category`,
                                    e.target.value
                                  );
                                }}
                                error={Boolean(
                                  touched &&
                                    touched.sentences &&
                                    touched.sentences[index] &&
                                    touched.sentences[index].Category &&
                                    errors &&
                                    errors.sentences &&
                                    errors.sentences[index] &&
                                    errors.sentences[index].Category
                                )}
                                helperText={
                                  touched &&
                                  touched.sentences &&
                                  touched.sentences[index] &&
                                  touched.sentences[index].Category &&
                                  errors &&
                                  errors.sentences &&
                                  errors.sentences[index] &&
                                  errors.sentences[index].Category
                                }
                              />
                            //   <FormHelperText sx={{ color: '#d32f2f' }}>
                            //     {touched &&
                            //       touched.sentences &&
                            //       touched.sentences[index] &&
                            //       touched.sentences[index].Category &&
                            //       errors &&
                            //       errors.sentences &&
                            //       errors.sentences[index] &&
                            //       errors.sentences[index].Category}
                            //   </FormHelperText> 
                            // </FormControl>
                          </Grid>
                        </Grid>

                        {index === formik.values.sentences.length - 1 ? (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: `${index > 0 ? 'visible' : 'hidden'}`
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.push({
                                  sentence: [''],
                                  Category: ''
                                });
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: 'hidden'
                              }}
                              onClick={() => {
                                arrayHelpers.push({
                                  sentence: [''],
                                  Category: ''
                                });
                              }}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            />
          </Grid> */}
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleCancel}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            // loading={isSubmitting}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Add
          </LoadingButton>
        </Container>
      </Form>
    </FormikProvider>
  );
}
