/* eslint-disable   no-unneeded-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Stack,
  Typography,
  Grid,
  Tabs,
  Tab,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Button,
  TablePagination,
  Paper,
  Backdrop
} from '@mui/material';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select, { components } from 'react-select';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { Icon } from '@iconify/react';
// import downloadOutlined from '@iconify/icons-ant-design/download-outlined';
import makeStyles from '@mui/styles/makeStyles';
import download from '@iconify/icons-eva/download-fill';
import CallIcon from '@mui/icons-material/Call';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import { saveAs } from 'file-saver';
import Page from '../components/Page';
import API from '../E2E/axios.util';

export default function DailyReports() {
  // Subtract one day
  const yesterday = dayjs().subtract(1, 'day');

  const defaultDate = yesterday.format('YYYY-MM-DD');

  const userDetails = JSON.parse(localStorage.getItem('user'));
  const [data, setData] = useState();
  const [parameter, setParameter] = useState();
  const [date, setDate] = useState(defaultDate);
  const [customerId, setCustomerId] = useState(
    userDetails.role !== 'Super_Admin' ? userDetails.customer_id : null
  );
  const [orgId, setOrgId] = useState(
    userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : null
  );
  const [customer, setCustomer] = useState(
    userDetails.role === 'Super_Admin'
      ? { value: 'all', label: 'All' }
      : { value: userDetails.customer_id, label: userDetails.customer_name }
  );
  const [organisation, setOrganisation] = useState(
    userDetails.role === 'Super_Admin'
      ? { value: 'all', label: 'All' }
      : { value: userDetails.organisation_id, label: userDetails.organisation_name }
  );
  const [OrganisationList, setOrganisationList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [downloadDetails, setDownloadDetails] = useState();
  const [loading, setLoading] = useState(false);

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTabs-indicator': {
        backgroundColor: '#1D9FD9' // change this to your desired color
      }
    },
    tab: {
      '&.Mui-selected': {
        color: '#1D9FD9' // change this to your desired color
      }
    },
    menuScoreCard: {
      maxHeight: 150,
      maxWidth: 50
    },
    datePicker: {
      '& .MuiTextField-root': {
        width: 160,
        marginBottom: 1
        // height: 10
      },
      '& .MuiInputLabel-shrink': {
        color: '#3892CF' // Set your desired color for the shrunk label
      },
      '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#3892CF'
      }
    }
    // cards: {
    //   [theme.breakpoints.down('md')]: {
    //     padding: 10
    //   },
    //   [theme.breakpoints.down('sm')]: {
    //     padding: 20
    //   }
    // }
  }));
  const classes = useStyles();

  const customStyles = {
    option: (base, state) => ({
      ...base,
      fontWeight: state.isSelected ? '500' : 'normal',
      maxHeight: 35
    }),
    control: (base, state) => ({
      ...base,
      fontWeight: '500'
      // border: 'none'
      // maxHeight: 35
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: '2px',
      maxHeight: 38,
      overflow: 'auto'
    })
  };

  const token = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  const getReportDetails = async () => {
    setLoading(true);
    // const super_admin_url = `/secure/daily_call_report?date=${date}`;
    // const others_url = `/secure/daily_call_report?customer_id=${customerId}&date=${date}&org_id=${orgId}`;
    await API.get(
      `/secure/daily_call_report?customer_id=${customerId}&date=${date}&org_id=${orgId}`,
      config
    )
      .then((res) => {
        setData(res.data['Daily report data']);
        setParameter(res.data['Daily report data'].parameters);
        setDownloadDetails(res.data['file path']);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setData();
        setParameter();
        setDownloadDetails();
        setLoading(false);
      });
  };

  const setDateFormat = (newValue) => {
    setDate(dayjs(newValue).format('YYYY-MM-DD'));
  };
  const getOrganisationList = async () => {
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  useEffect(() => {
    if (customerId && orgId && date) {
      getReportDetails();
    }
  }, [date, customerId]);

  const getCustomerList = async () => {
    await API.get(`/secure/list_of_customer?organisation_id=${orgId}`, config)
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (orgId) {
      getCustomerList();
    }
  }, [orgId]);

  const downloadHandler = () => {
    if (downloadDetails) {
      saveAs(
        `${process.env.REACT_APP_SERVER_MEDIA_URL}${downloadDetails}`,
        `daily_call_report_${dayjs(date).format('DD-MM-YYYY')}.xlsx`
      );
    }
  };

  return (
    <Page title="Daily Report">
      <>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
          mb={5}
        >
          <Grid
            container
            sm={12}
            md={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              alignItems: 'center',
              columnGap: '5px'
            }}
          >
            <Grid item xs={3} md={2.5}>
              <Typography variant="h4">Daily Report</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Select
                isDisabled={userDetails.role !== 'Super_Admin'}
                styles={customStyles}
                onChange={(e) => {
                  setCustomer({ value: '', label: '' });
                  setCustomerId();
                  setOrgId(e.value);
                  setOrganisation(e);
                }}
                options={OrganisationList?.map((element) => ({
                  value: element.organisation_id,
                  label: element.organisation_name
                }))}
                value={organisation}
                placeholder={
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      alignItems: 'center',
                      color: '#000',
                      fontWeight: '400'
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={2.5}>
              <Select
                isDisabled={userDetails.role === 'QA' || userDetails.role === 'Manager'}
                styles={customStyles}
                onChange={(e) => {
                  setCustomerId(e.value);
                  setCustomer(e);
                }}
                options={customerList?.map((element) => ({
                  value: element.customer_id,
                  label: element.customer_organisation_name
                }))}
                value={customer}
                placeholder={
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      alignItems: 'center',
                      color: '#000',
                      fontWeight: '400'
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={2} md={2.2}>
              <div style={{ marginTop: '-8px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <div className={classes.datePicker}>
                      <DatePicker
                        label="Choose date"
                        format="DD/MM/YYYY"
                        maxDate={dayjs()}
                        value={dayjs(date)}
                        onChange={(newValue) => setDateFormat(newValue)}
                        slotProps={{ textField: { size: 'small' } }}
                      />
                    </div>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid xs={2} md={2}>
              <Button
                variant="contained"
                onClick={downloadHandler}
                to="#"
                disabled={!downloadDetails || loading}
                sx={{
                  backgroundColor: '#107c41',
                  '&:hover': {
                    backgroundColor: '#3AB170'
                  }
                }}
              >
                Download Report
              </Button>
            </Grid>
          </Grid>
        </Stack>

        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginBottom: 15
          }}
        >
          {/* <Grid item xs={12} sm={6} md={5} className={classes.cards}>
            <Card
              style={{
                height: 200,
                boxShadow: 0,
                textAlign: 'center'
              }}
            >
              <div
                style={{
                  // borderBottom: '1px solid #f1f3f4',
                  padding: 10,
                  backgroundColor: '#3892CF'
                }}
              >
                <Typography style={{ color: '#fff' }} variant="h7">
                  <b>Total Calls Uploaded</b>
                </Typography>
              </div>
              <Grid container style={{ width: '100%', height: '100%' }}>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <CallIcon style={{ color: '#60AAD9', marginBottom: 50 }} fontSize="large" />
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography
                    // variant="h3"
                    style={{
                      color: '#000',
                      fontStyle: 'bold',
                      marginBottom: 50,
                      fontSize: '2.4rem'
                    }}
                  >
                    {data ? data['Total Calls Uploaded'] : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid> */}
          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            className={classes.cards}
            style={{ paddingLeft: 20, paddingRight: 20 }}
          >
            <Card
              style={{
                height: 250,
                boxShadow: 0,
                textAlign: 'center'
              }}
            >
              <div
                style={{
                  // borderBottom: '1px solid #f1f3f4',
                  padding: 10,
                  backgroundColor: '#3892CF'
                }}
              >
                <Typography style={{ color: '#fff' }} variant="h7">
                  <b>Total Calls Processed</b>
                </Typography>
              </div>
              <Grid container style={{ width: '100%', height: '100%' }}>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <CallIcon style={{ color: '#60AAD9', marginBottom: 50 }} fontSize="large" />
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography
                    // variant="h3"
                    style={{
                      color: '#000',
                      fontStyle: 'bold',
                      marginBottom: 50,
                      fontSize: '2.4rem'
                    }}
                  >
                    {data ? data['Total Calls Processed'] : 0}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card sx={{ height: '400px' }}>
              <div
                style={{
                  padding: 10,
                  borderBottom: '1px solid #f1f3f4',
                  backgroundColor: '#F4F4F4',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <b>Overall Agent Sentiment</b>
              </div>
              {data && !loading ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableCell
                        style={{ fontWeight: '600', backgroundColor: '#f8f6fa' }}
                        align="center"
                      >
                        Details
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: '600', backgroundColor: '#f8f6fa' }}
                        align="center"
                      >
                        Total Calls
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: '600', backgroundColor: '#f8f6fa' }}
                        align="center"
                      >
                        Percentage (%)
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">Positive</TableCell>
                        <TableCell align="center">{data['Agent Positive Calls'][0]}</TableCell>
                        <TableCell align="center">{data['Agent Positive Calls'][1]}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Negative</TableCell>
                        <TableCell align="center">{data['Agent Negative Calls'][0]}</TableCell>
                        <TableCell align="center">{data['Agent Negative Calls'][1]}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Neutral</TableCell>
                        <TableCell align="center">{data['Agent Neutral Calls'][0]}</TableCell>
                        <TableCell align="center">{data['Agent Neutral Calls'][1]}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: 20 }}>
                    <b>No Data Available</b>
                  </span>
                </div>
              )}
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card sx={{ height: '400px' }}>
              <div
                style={{
                  padding: 10,
                  borderBottom: '1px solid #f1f3f4',
                  backgroundColor: '#F4F4F4',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <b>Overall Customer Sentiment</b>
              </div>
              {data && !loading ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableCell
                        style={{ fontWeight: '600', backgroundColor: '#f8f6fa' }}
                        align="center"
                      >
                        Details
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: '600', backgroundColor: '#f8f6fa' }}
                        align="center"
                      >
                        Total Calls
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: '600', backgroundColor: '#f8f6fa' }}
                        align="center"
                      >
                        Percentage (%)
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">Positive</TableCell>
                        <TableCell align="center">{data['Customer Positive Calls'][0]}</TableCell>
                        <TableCell align="center">{data['Customer Positive Calls'][1]}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Negative</TableCell>
                        <TableCell align="center">{data['Customer Negative Calls'][0]}</TableCell>
                        <TableCell align="center">{data['Customer Negative Calls'][1]}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Neutral</TableCell>
                        <TableCell align="center">{data['Customer Neutral Calls'][0]}</TableCell>
                        <TableCell align="center">{data?.['Customer Neutral Calls'][1]}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: 20 }}>
                    <b>No Data Available</b>
                  </span>
                </div>
              )}
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card style={{ height: '400px' }}>
              <div
                style={{
                  padding: 10,
                  borderBottom: '1px solid #f1f3f4',
                  backgroundColor: '#F4F4F4',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <b>Parameter</b>
              </div>
              {parameter && !loading ? (
                <TableContainer sx={{ maxHeight: 350, overflow: 'auto' }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableCell
                        style={{ fontWeight: '600', backgroundColor: '#f8f6fa' }}
                        align="left"
                      >
                        Parameter
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: '600', backgroundColor: '#f8f6fa' }}
                        align="center"
                      >
                        Total Number Of Calls
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: '600', backgroundColor: '#f8f6fa' }}
                        align="center"
                      >
                        Call Percentage (%)
                      </TableCell>
                    </TableHead>

                    <TableBody>
                      {Object.keys(parameter).map((el) => (
                        <TableRow>
                          <TableCell align="left">{el}</TableCell>
                          <TableCell align="center">{parameter[el][0]}</TableCell>
                          <TableCell align="center">{parameter[el][1]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: 20 }}>
                    <b>No Data Available</b>
                  </span>
                </div>
              )}
            </Card>
          </Grid>
        </Grid>
      </>
      {loading && (
        <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress sx={{ color: '#3892CF' }} />
          </Box>
        </Backdrop>
      )}
    </Page>
  );
}
