import React, { useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import API from '../../E2E/axios.util';

export const ProfileDetails = ({
  orgData,
  userData,
  roleData,
  setAlert,
  setAlertContent,
  setAlertType,
  updateComponent,
  isUpdated
}) => {
  const [checkChange, setcheckChange] = useState(true);
  const [orgName, setorgName] = useState('');
  const [roleName, setroleName] = useState('');

  // console.log(orgName);

  const ProfileSchemma = Yup.object().shape({
    first_name: Yup.string()
      .matches(
        /^\S+[A-Za-z\s]{2,15}$/,
        'First name should in between 3 to 30 character and it should not start with space and does not contain special character and number.'
      )
      .max(30, 'First name should be 3 to 30 character long.')
      .required('This field is required.'),
    last_name: Yup.string()
      .matches(
        /^\S+[A-Za-z\s]{2,15}$/,
        'Last name should in between 3 to 30 character and it should not start with space and does not contain special character and number.'
      )
      .max(30, 'Last name should be 3 to 30 character long.')
      .required('This field is required.'),
    // email: Yup.string().email('Invalid Email address.').required('This field is required.'),
    // mobile: Yup.number().min(10, 'Must be more than 10 characters').nullable()
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, 'Contact Number should be 10 digit long.')
      .nullable()
    // role_id: Yup.string().required('This field is required.'),
    // organisation_id: Yup.string().required('This field is required.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      // email: userData?.email,
      mobile: userData?.mobile,
      organisation_id: userData?.organisation_id,
      role_id: userData?.role_id
    },
    validationSchema: ProfileSchemma,
    onSubmit: async () => {
      const { values } = formik;
      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        // email: values.email,
        mobile: values.mobile === '' ? null : values.mobile,
        // organisation_id: values.organisation_id,
        // role_id: values.role_id,
        id: userData?.user_id
      };
      const token = localStorage.getItem('token').toString();
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      await API.put('/secure/update_userprofile', data, config)
        .then((res) => {
          setAlertContent(`Success : ${res.data.message}`);
          setAlert(true);
          setAlertType('success');
          updateComponent(isUpdated + 1);
        })
        .catch((err) => {
          setAlertContent(`Error : ${err.response.data.message}`);
          setAlert(true);
          setAlertType('error');
        });
    }
  });

  const validateVolunteerRejx = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <form autoComplete="off">
      <Card>
        <CardHeader title="Basic Info" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="First name"
                name="firstName"
                key={userData?.first_name}
                required
                variant="outlined"
                // {...getFieldProps('first_name')}
                value={formik.values.first_name}
                error={Boolean(touched.first_name && errors.first_name)}
                helperText={touched.first_name && errors.first_name}
                onChange={(e) => {
                  setcheckChange(false);
                  formik.setFieldValue('first_name', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                name="lastName"
                key={userData?.last_name}
                required
                variant="outlined"
                value={formik.values.last_name}
                // {...getFieldProps('last_name')}
                error={Boolean(touched.last_name && errors.last_name)}
                helperText={touched.last_name && errors.last_name}
                onChange={(e) => {
                  setcheckChange(false);
                  formik.setFieldValue('last_name', e.target.value);
                }}
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                key={userData?.email}
                label="Email Address"
                name="email"
                required
                variant="outlined"
                value={formik.values.email}
                // {...getFieldProps('user_email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                onChange={(e) => {
                  setcheckChange(false);
                  formik.setFieldValue('email', e.target.value);
                }}
              />
            </Grid> */}
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                key={userData?.mobile}
                label="Phone Number"
                name="phone"
                type="number"
                variant="outlined"
                value={formik.values.mobile}
                onKeyPress={validateVolunteerRejx}
                // {...getFieldProps('user_mobile')}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
                onChange={(e) => {
                  setcheckChange(false);
                  formik.setFieldValue('mobile', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="organisation-select-label">Organisation</InputLabel>
                <Select
                  key={formik.values.organisation_id}
                  required
                  labelId="organisation-select-label"
                  label="Organisation"
                  id="select-org"
                  value={formik.values.organisation_id}
                  error={Boolean(touched.organisation_id && errors.organisation_id)}
                  helperText={touched.organisation_id && errors.organisation_id}
                  onChange={(e) => {
                    // setorgName(e.target.value);
                    formik.setFieldValue('organisation_id', e.target.value);
                    setcheckChange(false);
                  }}
                  disabled
                >
                  {orgData &&
                    orgData?.map((data) => (
                      <MenuItem key={data.organisation_id} value={data.organisation_id}>
                        {data.organisation_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  label="Role"
                  id="select-role"
                  value={formik.values.role_id}
                  key={formik.values.role_id}
                  error={Boolean(touched.role_id && errors.role_id)}
                  helperText={touched.role_id && errors.role_id}
                  onChange={(e) => {
                    // setroleName(e.target.value);
                    formik.setFieldValue('role_id', e.target.value);
                    setcheckChange(false);
                  }}
                  disabled
                >
                  {roleData &&
                    roleData?.map((data) => (
                      <MenuItem key={data.role_id} value={data.role_id}>
                        {data.role_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <LoadingButton
            // color="primary"
            variant="contained"
            disabled={checkChange}
            onClick={handleSubmit}
            style={{ backgroundColor: checkChange ? '#e5e8eb' : '#3892CF' }}
            loading={isSubmitting}
          >
            Save details
          </LoadingButton>
        </Box>
      </Card>
    </form>
  );
};
