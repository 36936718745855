import React, { useRef, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { filter } from 'lodash';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import refreshIcon from '@iconify/icons-mdi/refresh';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TablePagination,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Pagination
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
// component
import API from '../E2E/axios.util';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import { OrganisationForm, OrganisationEditForm } from '../components/custom-form-model/CRUDForms';
import { Notification } from '../components/Notification/Notification';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: '#3892CF',
        color: 'white'
        // borderRadius: '50%',
      }
    }
  }
}));

const ORGINIZATION_TABLE_HEAD = [
  { id: 'orgId', label: 'Organisation Id', alignRight: false },
  { id: 'name', label: 'Organisation Name', alignRight: false },
  { id: 'mobile', label: 'Contact No', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'city', label: 'City', alignRight: false },
  { id: 'state', label: 'State', alignRight: false },
  { id: 'country', label: 'Country', alignRight: false },
  { id: '' }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Organisation() {
  const classes = useStyles();
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [open, setOpen] = useState();
  const [update, setUpdate] = useState(0);
  const [orgData, setOrgData] = useState();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const OrganisationSchema = Yup.object().shape({
    OrganisationName: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{2,50}$/,
        'Organisation should not start with blank space and not have special character and number.'
      )
      .max(50, 'Organisation name should be in between 3 to 50 character.')
      .required('This field is required.'),
    OrganisationEmail: Yup.string()
      .email('Email must be a valid email address.')
      .matches(/^[\w-.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, 'Email must be a valid email address.')
      .required('This field is required.'),
    OrganisationMobile: Yup.string()
      .matches(/^((\+91-?)|\+)?[0-9][0-9]{9}$/, 'Invalid contact number.')
      .nullable()
      .required('This field is required.'),
    OrganisationAddress: Yup.string()
      .matches(
        /^\S+[A-Za-z0-9\s#,.-]{2,500}$/,
        'Address length should be 3 to 500 character long and it should not start with blank space.'
      )
      .max(500, 'Maximum 500 characters allowed.')
      .required('This field is required.'),
    OrganisationCountry: Yup.string().required('This field is required.'),
    OrganisationState: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{1,50}$/,
        'State name should not start with blank space and not have special character and number.'
      )
      .max(30, 'State name should be 2 to 30 character long.')
      .required('This field is required.'),
    OrganisationCity: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{1,50}$/,
        'City name should not start with blank space and not have special character and number.'
      )
      .max(30, 'City name should be 2 to 30 character long. ')
      .required('This field is required.'),
    OrganisationPincode: Yup.string()
      .matches(/^[0-9]{5,11}$/, 'Invalid pincode.')
      .max(6, 'Pincode should be 5 or 6 digits')
      .nullable()
      .required('This field is required.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      OrganisationName: '',
      OrganisationEmail: '',
      OrganisationMobile: null,
      OrganisationState: '',
      OrganisationAddress: '',
      OrganisationCountry: '',
      OrganisationCity: '',
      OrganisationPincode: ''
      // AwsServices: false,
      // // NvidiaServices: false,
      // CustomAiServices: false
    },
    validationSchema: OrganisationSchema,
    onSubmit: async () => {
      console.log('API called');
      const token = localStorage.getItem('token').toString();
      const data = {
        name: formik.values.OrganisationName,
        email: formik.values.OrganisationEmail,
        mobile: formik.values.OrganisationMobile,
        address: formik.values.OrganisationAddress,
        country: formik.values.OrganisationCountry,
        city: formik.values.OrganisationCity,
        state: formik.values.OrganisationState,
        pincode: formik.values.OrganisationPincode
        // aws_services: formik.values.AwsServices,
        // // nvidia_services: formik.values.NvidiaServices,
        // custom_ai_services: formik.values.CustomAiServices
      };
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await API.post('/secure/add_organisation', data, config)
        .then((res) => {
          console.log('response---', res);
          formik.resetForm();
          setAlertContent(`Success : ${res.data.message}`);
          setAlert(true);
          setAlertType('success');
          handleDialog();
        })
        .then(() => {
          setUpdate(update + 1);
        })
        .catch((err) => {
          console.log('error--', err);
          setAlertContent(`Error : ${err.response.data.message}`);
          setAlert(true);
          setAlertType('error');
        });
    }
  });

  const handleAlert = () => {
    setAlert(false);
  };

  const handleDialog = () => {
    setOpen(!open);
    formik.resetForm();
  };

  return (
    <Page title="Organisation">
      <>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Organisations {orgData && `(${orgData.count})`}
          </Typography>
          <Button
            variant="contained"
            to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleDialog}
            sx={{
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            New Organisation
          </Button>
        </Stack>
        <Container>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleDialog}
            formik={formik}
            open={open}
            title="Add New Organisation"
          >
            <OrganisationForm formik={formik} handleDialog={handleDialog} />
          </CustomFormModel>
        </Container>
        <OrganisationDataModel
          formik={formik}
          isUpdated={update}
          updateComponent={setUpdate}
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          orgData={orgData}
          setOrgData={setOrgData}
        />
      </>
      <Notification
        message={alertContent || 'Organisation list fetch succesfully'}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}

const OrganisationDataModel = ({
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  formik,
  orgData,
  setOrgData
}) => {
  const classes = useStyles();
  const [searchOrg, setSearchOrg] = useState();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [query, setQuery] = useState();

  // useEffect(() => {
  //   handleGetOrg();
  // }, []);

  useEffect(() => {
    if (!query) {
      handleGetOrg();
    }
  }, [isUpdated, page]);

  const handleGetOrg = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      // await API.get('/secure/list_organisation', config)
      await API.get(`/secure/get_organisation?page=${page}`, config)

        .then((res) => {
          console.log('Org_list-', res.data);
          setOrgData(res.data);
          // setAlertContent(`Success : Organisation list fetched successfully.`);
          setAlertType('success');
          setAlert(true);
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
          setLoading(false);
        });
    } else {
      setAlertContent(`Error : Authentication Failed Due to Token Expired.`);
      setAlertType('error');
      setAlert(true);
      setLoading(false);
    }
  };

  console.log('pageee', page);

  const handleChangePage = (event, newPage) => {
    console.log('pageee newpageee', newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orgData?.results?.length) : 0;
  // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orgData.organisation_list.length) : 0;

  const handleSearch = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await API.get(`/secure/organisation_search?query=${query}`, config)
        .then((res) => {
          setSearchOrg(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    if (query?.length > 0) {
      handleSearch();
    }
    console.log('query', query);
  }, [debouncedQuery, isUpdated, page]);

  return (
    <Card>
      <UserListToolbar
        numSelected={selected.length}
        placeholder="Search"
        onFilterName={(e) => {
          // setLoading(true);
          setQuery(e.target.value);
          if (query?.length > 0) {
            setPage(1);
          }
        }}
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={ORGINIZATION_TABLE_HEAD}
              rowCount={page}
              numSelected={selected.length}
            />
            {(query && !loading && searchOrg && (
              <TableBody>
                {searchOrg.results
                  // .filter((ele) => ele.organisation_id !== 256)
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      organisation_id,
                      organisation_name,
                      organisation_mobile,
                      organisation_email,
                      organisation_city,
                      organisation_state,
                      organisation_country
                    } = row;
                    return (
                      <TableRow hover key={organisation_id} tabIndex={-1} role="checkbox">
                        <TableCell align="center">{organisation_id}</TableCell>
                        <TableCell component="th" scope="row" padding="normal">
                          {organisation_name}
                        </TableCell>
                        <TableCell align="left">{organisation_mobile}</TableCell>
                        <TableCell align="left">{organisation_email}</TableCell>
                        <TableCell align="left">{organisation_city}</TableCell>
                        <TableCell align="left">{organisation_state}</TableCell>
                        <TableCell align="left">{organisation_country}</TableCell>

                        <TableCell align="right">
                          <UserMoreMenu
                            formik={formik}
                            currentId={organisation_id}
                            organisationDetail={row}
                            setAlertContent={setAlertContent}
                            setAlertType={setAlertType}
                            setAlert={setAlert}
                            isUpdated={isUpdated}
                            updateComponent={updateComponent}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
              </TableBody>
            )) ||
              // : (
              //   <TableBody>
              //     <TableRow>
              //       <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
              //         <CircularProgress sx={{ color: '#3892CF' }} />
              //       </TableCell>
              //     </TableRow>
              //   </TableBody>
              // )
              (!loading && orgData && orgData.results.length > 0 ? (
                <TableBody>
                  {
                    // orgData.organisation_list
                    orgData?.results
                      // .filter((ele) => ele.organisation_id !== 256)
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          organisation_id,
                          organisation_name,
                          organisation_mobile,
                          organisation_email,
                          organisation_city,
                          organisation_state,
                          organisation_country
                        } = row;
                        return (
                          <TableRow hover key={organisation_id} tabIndex={-1} role="checkbox">
                            <TableCell align="center">{organisation_id}</TableCell>
                            <TableCell component="th" scope="row" padding="normal">
                              {organisation_name}
                            </TableCell>
                            <TableCell align="left">{organisation_mobile}</TableCell>
                            <TableCell align="left">{organisation_email}</TableCell>
                            <TableCell align="left">{organisation_city}</TableCell>
                            <TableCell align="left">{organisation_state}</TableCell>
                            <TableCell align="left">{organisation_country}</TableCell>

                            <TableCell align="right">
                              <UserMoreMenu
                                formik={formik}
                                currentId={organisation_id}
                                organisationDetail={row}
                                setAlertContent={setAlertContent}
                                setAlertType={setAlertType}
                                setAlert={setAlert}
                                isUpdated={isUpdated}
                                updateComponent={updateComponent}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                  }
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    {loading ? (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <CircularProgress sx={{ color: '#3892CF' }} />
                      </TableCell>
                    ) : (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        Data Not Found
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              ))}
          </Table>
        </TableContainer>
      </Scrollbar>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        rowsPerPage={rowsPerPage}
        // count={!loading && orgData?.organisation_list ? orgData?.organisation_list?.length : 0}
        count={!loading && orgData ? orgData?.count : 0}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <Stack
        spacing={2}
        sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
      >
        <Pagination
          classes={{
            root: classes.ul
          }}
          sx={{
            '& .Mui-selected': {
              backgroundColor: 'blue', // Change the background color for selected button
              '&:hover': {
                backgroundColor: 'red' // Change the background color on hover for selected button
              }
            }
          }}
          count={
            // orgData ? Math.ceil(orgData.count / 10) : 1
            query && searchOrg ? Math.ceil(searchOrg.count / 10) : Math.ceil(orgData?.count / 10)
          }
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Stack>
    </Card>
  );
};

function UserMoreMenu({
  formik,
  currentId,
  organisationDetail,
  setAlertContent,
  setAlertType,
  setAlert,
  isUpdated,
  updateComponent
}) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(openEdit);

  const handleDialog = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };

  const handleDeleteOrganisation = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.delete(`/secure/deactivate_organisation?id=${currentId}`, config)
      .then((res) => {
        console.log('delete Organisation res-', res);
        setAlertContent(`Success : Organisation deleted successfully.`);
        setAlertType('success');
        setAlert(true);
      })
      .then(() => {
        updateComponent(isUpdated + 1);
        setIsOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={openEdit}
        title="Update Organisation"
      >
        <OrganisationEditForm
          formik={formik}
          organisationDetail={organisationDetail}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          setAlert={setAlert}
          isUpdated={isUpdated}
          updateComponent={updateComponent}
          handleDialog={handleDialog}
        />
      </CustomFormModel>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem to="#" sx={{ color: 'text.secondary' }} onClick={handleDialog}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDeleteOrganisation}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
