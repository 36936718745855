/* eslint-disable use-isnan */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-key */
/* eslint-disable no-return-await */
/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-template */
/* eslint-disable  no-useless-concat */
import * as Yup from 'yup';
import chroma from 'chroma-js';
import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import moment from 'moment';
import {
  Grid,
  Box,
  Modal,
  Typography,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardHeader,
  Button,
  Container,
  Link,
  Stack,
  Select as Selectbox,
  InputLabel,
  FormControl,
  Menu,
  Chip,
  ListSubheader,
  ListItemText,
  MenuItem,
  FormHelperText,
  TextField,
  IconButton,
  Tooltip as Tip,
  CircularProgress,
  Hidden,
  Pagination,
  TablePagination,
  InputAdornment,
  ListItemIcon,
  Backdrop,
  Checkbox
} from '@mui/material';
import { circularProgressClasses } from '@mui/material/CircularProgress';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';
import Select, { components } from 'react-select';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  ResponsiveContainer,
  Label,
  Cell,
  PieChart,
  Pie
} from 'recharts';

import {
  CircularGauge,
  Scale,
  RangeContainer,
  Range,
  Tooltip as GaugeTooltip,
  Font,
  SubvalueIndicator,
  Text,
  ValueIndicator,
  Title as GaugeTitle
} from 'devextreme-react/circular-gauge';
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  CommonAxisSettings,
  Export,
  Margin,
  Format,
  Legend as ExtremeLegend,
  dataSource,
  SeriesTemplate,
  Title,
  handleMouseLeave,
  ValueAxis,
  Tooltip as ExtremeTooltip
} from 'devextreme-react/chart';
import { merge } from 'lodash';
import { makeStyles } from '@mui/styles';
import PortraitIcon from '@mui/icons-material/Portrait';
import LoopIcon from '@mui/icons-material/Loop';
import CallIcon from '@mui/icons-material/Call';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PercentIcon from '@mui/icons-material/Percent';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import DatePicker from 'react-datepicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@mui/material/styles';

import { useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import fileDownload from '@iconify/icons-fa-solid/file-download';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import plusFill from '@iconify/icons-eva/plus-fill';
import ReactApexChart from 'react-apexcharts';
import FileSaver, { saveAs } from 'file-saver';
import { Tabs, Placeholder } from 'rsuite';

// Components
import './CSS/RsuiteTabs.css';
import OpportunityForm from '../layouts/Compliance/OpportunityForm';
import OpportunityEditForm from '../layouts/Compliance/OpportunityEditForm';
import './CSS/ArrowBox.css';
import API from '../E2E/axios.util';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import { Notification } from '../components/Notification/Notification';
import { BaseOptionChart } from '../components/charts';
import Page from '../components/Page';
import inputContex from '../Context/UploadContext/inputContext';
import ComparativeAnalysis from '../components/ComparativeAnalysis';
import StackBar from '../components/charts/StackBar';

import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
import SentimentGraph from '../components/charts/SentimentGraph';
import BarChartUBI from '../components/charts/BarChartUBI';

// import DealForm from 'src/layouts/Deal/DealForm';

// import TreeMap from '../assets/TreeMap.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 500,
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 5,
  p: 4
};

// <---------------------------------------------------------->

//  <---------------------------------------------------------->

const customStyles = {
  option: (base, state) => ({
    ...base,
    fontWeight: state.isSelected ? '500' : 'normal',
    maxHeight: 35
  }),
  control: (base, state) => ({
    ...base,
    fontWeight: '500',
    border: 'none'
    // maxHeight: 35
  }),
  valueContainer: (base, state) => ({
    ...base,
    padding: '2px',
    maxHeight: 38,
    overflow: 'auto'
  })
};
const CustomControlOrg = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <CorporateFareIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );
};
const CustomControlCustomer = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <PortraitIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );
};
const CustomControlProcess = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <LoopIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );
};
const CustomControlAgent = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <SupportAgentIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );
};

const CustomControlDate = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <CalendarMonthIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );
};

// -------------------------------------------------------------------

export default function ComplianceDashboard() {
  // usecontext for navigation
  const {
    setinputId,
    setinputOrgId,
    setrequestId,
    setUploadedAudio,

    setAudioS3,

    setProcess,
    setcustomerId,
    setOriginalDate,
    setAgentId,
    setAgentName,
    setAgentLastName,
    globalToneValue,
    setGlobalToneValue,
    globalDate,
    globalBarValue,
    globalUpdate
  } = useContext(inputContex);
  const classes = useStyles();

  const locationData = useLocation();
  console.log('locationData', locationData);

  const KEY_WORDS_TABLE_HEAD = [
    { id: 'Agent', numeric: false, disablePadding: false, label: 'Agent' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    // {
    //   id: 'words',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Words',
    //   alignCenter: true
    // },
    { id: 'view', numeric: false, disablePadding: false, label: 'View', alignCenter: true }

    // { id: 'Action', numeric: false, disablePadding: false, label: 'Action', alignCenter: true }
  ];

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [compliantOpen, setCompliantOpen] = React.useState(false);
  // console.log('compliantOpen', compliantOpen);
  const [opportunity, setOpportunity] = React.useState(false);
  const [organisationList, setOrganisationList] = useState([]);
  const [customerList, setCustomerList] = useState([]);

  const [organisation, setOrganisation] = useState(
    locationData &&
      locationData.state &&
      locationData.state.initialData?.newValues?.newOrganisation !== null
      ? locationData.state?.initialData?.newValues?.newOrganisation
      : { value: 'all', label: 'All' }
  );
  const [defaultCustomer, setdefaultCustomer] = useState(
    locationData &&
      locationData.state &&
      locationData?.state?.initialData.newValues?.newCustomer !== null
      ? locationData?.state?.initialData.newValues?.newCustomer
      : { value: 'all', label: 'All' }
  );

  const [defaultProcess, setdefaultProcess] = useState(
    locationData &&
      locationData.state &&
      locationData?.state?.initialData.newValues?.newProcess.length > 0
      ? locationData.state?.initialData.newValues?.newProcess
      : []
  );
  const [defaultAgent, setdefaultAgent] = useState(
    locationData &&
      locationData.state &&
      locationData?.state?.initialData.newValues?.newAgent !== null
      ? locationData.state?.initialData.newValues?.newAgent
      : []
  );
  const [campaignIds, setCampaignId] = useState(
    locationData && locationData.state ? locationData.state?.initialData.newValues?.agentIds : []
  );
  const [defaultDate, setDefaultDate] = useState(
    locationData &&
      locationData.state &&
      locationData.state?.initialData.newValues?.newDate !== null
      ? locationData.state?.initialData.newValues?.newDate
      : { value: 'one_week', label: 'One Week' }
  );

  const [loading, setLoading] = useState();
  const [loadingForCount, setLoadingForCount] = useState(false);
  const [loadingForScriptScore, setLoadingForScriptScore] = useState(false);
  const [campaignList, setcampaignList] = useState([]);
  const [popupDataForCompliance, setPopupDataForCompliance] = useState();
  const [pageForPopup, setPageForPopup] = useState();
  const [rowsPerPageForPopup, setRowsPerPageForPopup] = useState(10);
  const [tabValue, setTabValue] = useState(
    locationData.state && locationData.state.initialData.storeTabValue
      ? locationData.state.initialData.storeTabValue
      : '5'
  );

  const handleChangePageForPopup = (event, newPage) => {
    setPopupPage(newPage);
  };
  const handleChangePageForSentTone = (event, newPage) => {
    setPageForPopup(newPage);
  };

  const handleChangeRowsPerPageForPopup = (event) => {
    // console.log('rowschangingvalue', typeof event.target.value);
    setRowsPerPageForPopup(event.target.value);
    setPageForPopup(0);
  };

  // console.log('popupDataForCompliance', popupDataForCompliance);
  const [shownComplianceName, setShownComplianceName] = useState('Info');
  const [processList, setprocessList] = useState([]);

  const [salesProductInfo, setSalesProductInfo] = useState(
    locationData.state && locationData.state.initialData.storedSalesProductInfo
      ? locationData.state.initialData.storedSalesProductInfo
      : null
  );
  const [aiAccuracyInfo, setAIAccuracyInfoInfo] = useState(
    locationData.state && locationData.state.initialData.storedAccuracyInfo
      ? locationData.state.initialData.storedAccuracyInfo
      : null
  );
  // locationData.state && locationData.state.initialData.storedSalesProductInfo
  //   ? locationData.state.initialData.storedSalesProductInfo
  //   : null
  const [comparativeAnalysisCards, setComparativeAnalysisCards] = useState(
    locationData.state && locationData.state.initialData.storedComparativeInfo
      ? locationData.state.initialData.storedComparativeInfo
      : null
  );
  // const [positiveLeadWords, setPositiveLeadWords] = useState([]);
  const [scoreCardInfo, setScoreCardInfo] = useState(
    locationData.state && locationData.state.initialData.storedCardInfo
      ? locationData.state.initialData.storedCardInfo
      : null
  );
  const [opportunityInfo, setOpportunityInfo] = useState(
    locationData.state && locationData.state.initialData.storedOpportunityInfo
      ? locationData.state.initialData.storedOpportunityInfo
      : []
  );
  const [trendChartDetails, setTrendChartDetails] = useState(
    locationData.state && locationData.state.initialData.storedTrendChartDetails
      ? locationData.state.initialData.storedTrendChartDetails
      : []
  );
  console.log('setTrendChartDetails', trendChartDetails);
  const [dealStatus, setDealStatus] = useState(
    locationData.state && locationData.state.initialData.storedDealStatus
      ? locationData.state.initialData.storedDealStatus
      : null
  );
  const [overallScoreInfo, setOverallScoreInfo] = useState(
    locationData.state && locationData.state.initialData.storedOverallScoreInfo
      ? locationData.state.initialData.storedOverallScoreInfo
      : null
  );

  // const [getAudioList, setGetAudioList] = React.useState(
  //   locationData.state && locationData.state.initialData.storedGetAudioList
  //     ? locationData.state.initialData.storedGetAudioList
  //     : []
  // );
  const [toneData, setToneData] = React.useState(
    locationData.state && locationData.state.initialData.toneData
      ? locationData.state.initialData.toneData
      : []
  );
  const [sentimentData, setSentimentData] = React.useState(
    locationData.state && locationData.state.initialData.sentimentData
      ? locationData.state.initialData.sentimentData
      : []
  );
  const [tenureData, setTenureData] = React.useState(
    locationData.state && locationData.state.initialData.tenureData
      ? locationData.state.initialData.tenureData
      : []
  );
  const [feedbackData, setFeedbackData] = React.useState(
    locationData.state && locationData.state.initialData.feedbackData
      ? locationData.state.initialData.feedbackData
      : []
  );

  const [showDownloadButton, setShowDownloadButton] = useState(
    locationData.state && locationData.state.initialData.defaultDownload
      ? locationData.state.initialData.defaultDownload
      : false
  );
  const [agentGraphData, setAgentGraphData] = useState(
    locationData.state && locationData.state.initialData.storedAgentGraphData
      ? locationData.state.initialData.storedAgentTrendData
      : []
  );
  const [agentSummaryData, setAgentSummaryData] = useState(
    locationData.state && locationData.state.initialData.storedAgentGraphData
      ? locationData.state.initialData.storedAgentSummaryData
      : []
  );

  const [page, setPage] = React.useState(0);
  const [scoreCardPage, setScoreCardPage] = React.useState(1);
  const [pageValue, setPageValue] = React.useState();

  const [downloadScriptStart, setDownloadScriptStart] = React.useState(false);
  // console.log('scoreCardPage', scoreCardPage);
  const [countForPages, setCountForPages] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [scoreCardLoading, setScoreCardLoading] = React.useState(false);

  // for search
  const [searchQueryList, setSearchQueryList] = useState('');
  const [maxRecordCount, setMaxRecordCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  console.log('searchQueryList', searchQueryList);

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  // const debouncedQueryList = useDebounce(searchQueryList, 500);

  // status
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');

  const [update, setUpdate] = useState(0);
  const [searchUpdate, setSearchUpdate] = useState(0);

  // function for checking all values inside array
  function checkArrayValues(arr) {
    // Check if all elements are 0
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== 0) {
        return arr;
      }
    }
    return [];
  }

  const trendData = trendChartDetails ? Object.keys(trendChartDetails) : [];
  const trendSeries = trendChartDetails ? Object.values(trendChartDetails) : [];
  console.log('trendData', trendSeries);
  // Pagination

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangePageForScorecard = (event, newPage) => {
    setSearchQueryList('');
    setScoreCardPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // creating function for fetching default dashboard api
  const weekBeforeDate = new Date(Date.now() - 604800000);
  const presentDate = new Date(Date.now());
  const setWeekBeforeDate = `${weekBeforeDate.getFullYear()}-${
    weekBeforeDate.getMonth() + 1
  }-${weekBeforeDate.getDate()}`;
  const setPresentDate = `${presentDate.getFullYear()}-${
    presentDate.getMonth() + 1
  }-${presentDate.getDate()}`;
  const [checkInDate, setCheckInDate] = useState(
    locationData.state ? new Date(locationData.state.initialData.startDate) : weekBeforeDate
  );
  const [checkOutDate, setCheckOutDate] = useState(
    locationData.state ? new Date(locationData.state.initialData.endDate) : presentDate
  );

  // Calculate 3 months from the check-in date
  const maxDateFromCheckIn = new Date(checkInDate).setMonth(new Date(checkInDate).getMonth() + 3);

  // Determine the final maxDate
  const maxDate = maxDateFromCheckIn < new Date() ? maxDateFromCheckIn : new Date();

  const setDateFormat = (date) => {
    const formatStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return formatStartDate;
  };

  const handleCheckInDate = (date) => {
    // console.log('Date-----', typeof Date());
    // console.log('date-----', date);
    setCheckInDate(date);
    setCheckOutDate('');
    formik.setFieldValue('endDate', '');
    const start_date = setDateFormat(date);
    formik.setFieldValue('startDate', start_date);
  };

  const handleCheckOutDate = (date) => {
    setCheckOutDate(date);
    const start_date = setDateFormat(date);
    formik.setFieldValue('endDate', start_date);
  };

  const CustomInput = React.forwardRef((props, ref) => (
    <div style={{ position: 'relative' }}>
      <input {...props} ref={ref} />
      <CalendarMonthIcon
        onClick={props.onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '10px',
          // pointerEvents: 'none',
          cursor: 'pointer',
          zIndex: 0
        }}
      />
    </div>
  ));

  // <-------------------------------------------------->

  // crate an instance of the wavesurfer

  const salesCardDummy = {
    totalCalls: 200,
    positiveCalls: 100,
    dummyCritical: 20
  };
  const [salesCards, setSalesCards] = useState(salesCardDummy);
  console.log('salesCardDummy', salesCards.totalCalls);
  useEffect(() => {
    setSalesCards(salesCardDummy);
  }, []);
  // const productInfoinLocal = JSON.parse(localStorage.getItem('productInfo'));
  // console.log('productInfoinLocal', productInfoinLocal);

  // User details
  const userDetails = JSON.parse(localStorage.getItem('user'));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const ComplianceSchema = Yup.object().shape({
    organisation: Yup.string().nullable().required('This field is required.'),
    customer: Yup.string().nullable().required('This field is required.'),
    format: Yup.string().nullable().required('This field is required.'),
    process: Yup.array()
      .nullable()
      .min(1, 'This field is required.')
      .required('This field is required.'),
    // campaign: Yup.Array().nullable().required('This field is required.'),
    startDate: Yup.date().required('This field is required.'),
    endDate: Yup.date().required('This field is required.')
  });

  const token = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  // const [improvementCallsCount, setImprovementCallsCount] = useState(0);
  // const [goodCallsCount, setGoodCallsCount] = useState(0);
  // const [goodCallsList, setGoodCallsList] = useState([]);
  // const [improvementCallsList, setImprovementCallsList] = useState([]);

  function getScriptCardInfo() {
    let url = `/secure/default_script_adherence_cards?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}&processes=${formik.values.process}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign) {
      url += `&user_id=${formik.values.campaign}`;
    }

    API.get(url, config)
      .then((res) => {
        // localStorage.setItem('productInfo', JSON.stringify(res.data.count));
        setSalesProductInfo(res.data.count);
        setAIAccuracyInfoInfo(res.data.result1);
        localStorage.setItem('storedCountInfo', JSON.stringify(res.data.count));
        // setAlert(true);
        // setAlertContent(`${res.data.message}`);
        // setAlertContent(`Welcome to the Sales Dashboard.`);
        // setAlertType('success');
      })
      .catch((err) => {
        console.log('err', err);
        setSalesProductInfo(null);
        setAIAccuracyInfoInfo();
        setAlert(true);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
      });
  }
  function getComparativeAnalysisCardsData() {
    let url = `/secure/compliance_cards_comparison?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}&processes=${formik.values.process}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign) {
      url += `&user_id=${formik.values.campaign}`;
    }

    API.get(url, config)
      .then((res) => {
        // localStorage.setItem('productInfo', JSON.stringify(res.data.count));
        setComparativeAnalysisCards(res.data);
        // localStorage.setItem('storedCountInfo', JSON.stringify(res.data.count));
        // setAlert(true);
        // setAlertContent(`${res.data.message}`);
        // setAlertContent(`Welcome to the Sales Dashboard.`);
        // setAlertType('success');
      })
      .catch((err) => {
        console.log('err', err);
        setComparativeAnalysisCards();
        setAlert(true);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
      });
  }

  const [popupPage, setPopupPage] = useState(1);
  const [updateStatusForCardPopup, setUpdateStatusForCardPopup] = useState(0);

  function getAudioListData(statusForScore) {
    setLoadingForCount(true);
    let url = `/secure/script_details?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}&processes=${formik.values.process}&flag=${statusForScore}&page=${popupPage}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&user_id=${formik.values.campaign}`;
    }

    API.get(url, config)
      .then((res) => {
        setPopupDataForCompliance(res.data);
        setCompliantOpen(true);
        // setGetAudioList(res.data);
        // setScoreCard(res.data);
        setLoadingForCount(false);
        // localStorage.setItem('storedScriptInfo', JSON.stringify(res.data));
        // localStorage.setItem('audioList', JSON.stringify(res.data));
        // setAlert(true);
        // setAlertContent(`${res.data.message}`);
        // setAlertType('success');
      })
      .catch((err) => {
        console.log('ErrorForMultipleData', err);
        setPopupDataForCompliance();
        setLoadingForCount(false);
        // setAlert(true);
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
      });
  }

  function getScordCardList() {
    setLoadingForScriptScore(true);
    let url = `/secure/default_script_scorecard?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}&processes=${formik.values.process}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&user_id=${formik.values.campaign}`;
    }

    API.get(url, config)
      .then((res) => {
        setScoreCardInfo(res.data);
        setLoadingForScriptScore(false);
        localStorage.setItem('storedScoreCardInfo', JSON.stringify(res.data));
        // localStorage.setItem('agentTaskList', JSON.stringify(res.data.agent_tasks));

        // console.log('res setAgentTaskList', res.data);
        // setAlert(true);
        // setAlertContent(`${res.data.message}`);
        // setAlertType('success');
      })
      .catch((err) => {
        console.log('err', err);
        setScoreCardInfo();
        setLoadingForScriptScore(false);
        // setAlert(true);
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
      });
  }
  function getComparativeAnalysis() {
    setLoadingForScriptScore(true);
    let url = `/secure/default_script_scorecard_comparison?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}&processes=${formik.values.process}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&user_id=${formik.values.campaign}`;
    }

    API.get(url, config)
      .then((res) => {
        setComparativeAnalysis(res.data.percentage_improvement);
        setLoadingForScriptScore(false);
        // localStorage.setItem('storedScoreCardInfo', JSON.stringify(res.data));
        // localStorage.setItem('agentTaskList', JSON.stringify(res.data.agent_tasks));

        // console.log('res setAgentTaskList', res.data);
        // setAlert(true);
        // setAlertContent(`${res.data.message}`);
        // setAlertType('success');
      })
      .catch((err) => {
        console.log('err', err);
        setComparativeAnalysis();
        setLoadingForScriptScore(false);
        // setAlert(true);
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
      });
  }
  function getOpportunityList() {
    let url = `/secure/list_opportunity_dashboard?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}&processes=${formik.values.process}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign) {
      url += `&user_id=${formik.values.campaign}`;
    }

    API.get(url, config)
      .then((res) => {
        // setShowAddGoal(true);
        setOpportunityInfo(res.data.opportunity_list);
        localStorage.setItem('storedOpportunityInfo', JSON.stringify(res.data.opportunity_list));
      })
      .catch((err) => {
        console.log('err', err);
        setOpportunityInfo([]);
        // setAlert(true);
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
      });
  }
  function getTrendChartDetails() {
    let url = `/secure/compliance_score?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}&processes=${formik.values.process}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&user_id=${formik.values.campaign}`;
    }

    API.get(url, config)
      .then((res) => {
        setTrendChartDetails(res.data.percentage);
        localStorage.setItem('storedGraphInfo', JSON.stringify(res.data.percentage));
      })
      .catch((err) => {
        console.log('err', err);
        setTrendChartDetails([]);
        // setAlert(true);
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
      });
  }
  function getScoreTrendDetails() {
    let url = `/secure/filter_trend_scoring?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}&processes=${formik.values.process}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&user_id=${formik.values.campaign}`;
    }

    API.get(url, config)
      .then((res) => {
        setScoreTrendData(res.data.scoring_percentage);
        // localStorage.setItem('storedGraphInfo', JSON.stringify(res.data.percentage));
      })
      .catch((err) => {
        console.log('err', err);
        setScoreTrendData();
        // setAlert(true);
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
      });
  }

  function getDealStatus() {
    let url = `/secure/opportunity_status?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}&processes=${formik.values.process}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign) {
      url += `&user_id=${formik.values.campaign}`;
    }

    API.get(url, config)
      .then((res) => {
        if (
          (res.data.opportunity_status.completed === 0 &&
            res.data.opportunity_status.Assigned === 0) ||
          Object.keys(res.data.opportunity_status).length === 0
        ) {
          setDealStatus(null);
        } else {
          setDealStatus(res.data.opportunity_status);
          localStorage.setItem(
            'storedOpportunityStatusInfo',
            JSON.stringify(res.data.opportunity_status)
          );
          console.log('res deal status', res.data);
        }
        // console.log('res deal status 1', res.data.deal_status);
        // setAlert(true);
        // setAlertContent(`${res.data.message}`);
        // setAlertType('success');
      })
      .catch((err) => {
        setDealStatus(null);
        console.log('err', err);
      });
  }

  function getQaScoreCard() {
    setScoreCardLoading(true);
    let url1 = `/secure/qa_scorecard?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}&processes=${formik.values.process}&page=${scoreCardPage}`;

    if (formik.values.format === 'customized') {
      url1 += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url1 += `&user_id=${formik.values.campaign}`;
    }

    API.get(url1, config)
      .then((res1) => {
        const data1 = res1.data.results;
        // const data2 = res2.data.agent_call_details;
        // const mergedList = data1.map((item1) => {
        //   const matchingItem2 = data2.find((item2) => item2.input_id === item1.input_id);
        //   return { ...item1, ...matchingItem2 };
        // });
        setOverallScoreInfo(data1);
        setScoreCardLoading(false);
        setCountForPages(res1.data.count);
        localStorage.setItem('storedOverallScoreInfo', JSON.stringify(data1));
        // Do something with the data from both requests
      })
      .catch((err) => {
        setOverallScoreInfo();
        setScoreCardLoading(false);
        console.log('err', err);
        console.log('Error:', err);
      });
    // const url2 = `/secure/dashboard_agent_call_details`;

    // Promise.all([API.get(url1, config), API.get(url2, config)])
    //   .then(([res1, res2]) => {
    //     const data1 = res1.data.results;
    //     const data2 = res2.data.agent_call_details;
    //     const mergedList = data1.map((item1) => {
    //       const matchingItem2 = data2.find((item2) => item2.input_id === item1.input_id);
    //       return { ...item1, ...matchingItem2 };
    //     });
    //     setOverallScoreInfo(mergedList);
    //     setScoreCardLoading(false);
    //     setCountForPages(res1.data.count);
    //     localStorage.setItem('storedOverallScoreInfo', JSON.stringify(mergedList));
    //     // Do something with the data from both requests
    //   })
    //   .catch((err) => {
    //     setOverallScoreInfo();
    //     setScoreCardLoading(false);
    //     console.log('err', err);
    //     console.log('Error:', err);
    //   });
  }

  // console.log('test2---------', test2);

  // useEffect For Default Dashboard
  useEffect(() => {
    if (searchQueryList === '' && tabValue === '1') {
      let url = '';
      if (showDownloadButton === false) {
        url = `/secure/default_qa_scorecard?date=${defaultDate.value}&page=${scoreCardPage}`;
        if (userDetails.role === 'Customer_Admin') {
          url += `&org_id=${userDetails.organisation_id}`;
        } else if (
          userDetails.role === 'QA' ||
          userDetails.role === 'Manager' ||
          userDetails.role === 'QA_admin'
        ) {
          url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
        }

        setScoreCardLoading(true);
        // const url2 = `/secure/dashboard_agent_call_details`;
        API.get(url, config)
          .then((res1) => {
            const data1 = res1.data.results;
            // const data2 = res2.data.agent_call_details;
            // const mergedList = data1.map((item1) => {
            //   const matchingItem2 = data2.find((item2) => item2.input_id === item1.input_id);
            //   return { ...item1, ...matchingItem2 };
            // });
            setOverallScoreInfo(data1);
            setScoreCardLoading(false);
            setCountForPages(res1.data.count);
            localStorage.setItem('storedOverallScoreInfo', JSON.stringify(data1));
          })
          .catch((err) => {
            setOverallScoreInfo();
            setScoreCardLoading(false);
            console.log('err', err);
          });
        // const url2 = `/secure/dashboard_agent_call_details`;
        // Promise.all([API.get(url, config), API.get(url2, config)])
        //   .then(([res1, res2]) => {
        //     const data1 = res1.data.results;
        //     const data2 = res2.data.agent_call_details;
        //     const mergedList = data1.map((item1) => {
        //       const matchingItem2 = data2.find((item2) => item2.input_id === item1.input_id);
        //       return { ...item1, ...matchingItem2 };
        //     });
        //     setOverallScoreInfo(mergedList);
        //     setScoreCardLoading(false);
        //     setCountForPages(res1.data.count);
        //     localStorage.setItem('storedOverallScoreInfo', JSON.stringify(mergedList));
        //   })
        //   .catch((err) => {
        //     setOverallScoreInfo();
        //     setScoreCardLoading(false);
        //     console.log('err', err);
        //   });
      }
      //  else {
      //   url = `/secure/qa_scorecard?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}&processes=${formik.values.process}&page=${scoreCardPage}`;
      //   if (formik.values.format === 'customized') {
      //     url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
      //   }
      //   if (formik.values.campaign !== null && formik.values.campaign !== '') {
      //     url += `&user_id=${formik.values.campaign}`;
      //   }
      //   setScoreCardLoading(true);
      //   // const url2 = `/secure/dashboard_agent_call_details`;
      //   API.get(url, config)
      //     .then((res1) => {
      //       const data1 = res1.data.results;
      //       // const data2 = res2.data.agent_call_details;
      //       // const mergedList = data1.map((item1) => {
      //       //   const matchingItem2 = data2.find((item2) => item2.input_id === item1.input_id);
      //       //   return { ...item1, ...matchingItem2 };
      //       // });
      //       setOverallScoreInfo(data1);
      //       setScoreCardLoading(false);
      //       setCountForPages(res1.data.count);
      //       localStorage.setItem('storedOverallScoreInfo', JSON.stringify(data1));
      //     })
      //     .catch((err) => {
      //       setOverallScoreInfo();
      //       setScoreCardLoading(false);
      //       console.log('err', err);
      //     });
      // }
    }
  }, [scoreCardPage, searchQueryList, tabValue]);

  // this is for search api call
  const handleSearch = () => {
    setScoreCardPage(1);
    setScoreCardLoading(true);
    let url = `/secure/search_qa_scorecard?query=${searchQueryList}&date=${defaultDate.value}`;
    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&user_id=${formik.values.campaign}`;
    }
    // if (showDownloadButton === true) {
    // const url2 = `/secure/dashboard_agent_call_details`;
    API.get(url, config)
      .then((res1) => {
        const data1 = res1.data.results;
        setOverallScoreInfo(data1);
        setScoreCardLoading(false);
        setCountForPages(res1.data.count);
        setSearchUpdate(searchUpdate + 1);
        localStorage.setItem('storedOverallScoreInfo', JSON.stringify(data1));
      })
      .catch((err) => {
        setOverallScoreInfo();
        setScoreCardLoading(false);
        // console.log('err', err);
      });
    // const url2 = `/secure/dashboard_agent_call_details`;
    // Promise.all([API.get(url, config), API.get(url2, config)])
    //   .then(([res1, res2]) => {
    //     const data1 = res1.data.results;
    //     const data2 = res2.data.agent_call_details;
    //     const mergedList = data1.map((item1) => {
    //       const matchingItem2 = data2.find((item2) => item2.input_id === item1.input_id);
    //       return { ...item1, ...matchingItem2 };
    //     });
    //     setOverallScoreInfo(mergedList);
    //     setScoreCardLoading(false);
    //     setCountForPages(res1.data.count);
    //     setSearchUpdate(searchUpdate + 1);
    //     localStorage.setItem('storedOverallScoreInfo', JSON.stringify(mergedList));
    //   })
    //   .catch((err) => {
    //     setOverallScoreInfo();
    //     setScoreCardLoading(false);
    //     // console.log('err', err);
    //   });
    // }
  };

  useEffect(() => {
    if (showDownloadButton === false && tabValue === '1') {
      // setLoadingForCount(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
        // onUploadProgress: (data) => {
        //   setUploaded(Math.round((data.loaded / data.total) * 100));
        // }
      };
      let url = `/secure/default_script_adherence_cards?date=${defaultDate.value}&flag=All`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          // console.log('ressss', res);
          console.log('resssssss', res.data);
          setSalesProductInfo(res.data.count);
          setAIAccuracyInfoInfo(res.data.result1);
          localStorage.setItem('storedCountInfo', JSON.stringify(res.data.count));
          // setLoadingForCount(false);
          // setAgentTaskList(res?.data?.all_audios?.agent_task);
        })
        .catch((err) => {
          console.log('ErrorForMultipleData', err);
          // setLoadingForCount(false);
        });
    }
  }, [tabValue]);

  useEffect(() => {
    if (showDownloadButton === false && tabValue === '1') {
      // setLoadingForCount(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
        // onUploadProgress: (data) => {
        //   setUploaded(Math.round((data.loaded / data.total) * 100));
        // }
      };
      let url = `/secure/default_compliance_cards_comparison?date=${defaultDate.value}`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          // console.log('ressss', res);
          // console.log('resssssss', res.data);
          setComparativeAnalysisCards(res.data);
          // localStorage.setItem('storedCountInfo', JSON.stringify(res.data.count));
          // setLoadingForCount(false);
          // setAgentTaskList(res?.data?.all_audios?.agent_task);
        })
        .catch((err) => {
          console.log('ErrorForMultipleData', err);
          setComparativeAnalysisCards();
          // setLoadingForCount(false);
        });
    }
  }, [tabValue]);

  useEffect(() => {
    if (showDownloadButton === false && tabValue === '1') {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let url = `/secure/default_compliance_score?date=${defaultDate.value}`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          // console.log('ressss', res);
          console.log('resssssss', res.data);
          setTrendChartDetails(res.data.percentage);
          localStorage.setItem('storedGraphInfo', JSON.stringify(res.data.percentage));
          // setAgentTaskList(res?.data?.all_audios?.agent_task);
        })
        .catch((err) => console.log('err', err));
    }
  }, [tabValue]);

  // useEffect(() => {
  //   if (showDownloadButton === false) {
  //     const token = localStorage.getItem('token');
  //     const config = {
  //       headers: { Authorization: `Bearer ${token}` }
  //       // onUploadProgress: (data) => {
  //       //   setUploaded(Math.round((data.loaded / data.total) * 100));
  //       // }
  //     };
  //     let url = `/secure/default_opportunity_status?date=${defaultDate.value}`;

  //     if (userDetails.role === 'Customer_Admin') {
  //       url += `&org_id=${userDetails.organisation_id}`;
  //     } else if (userDetails.role === 'QA' || userDetails.role === 'Manager') {
  //       url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
  //     }
  //     API.get(url, config)
  //       .then((res) => {
  //         if (Object.keys(res.data.opportunity_status).length === 0) {
  //           setDealStatus(null);
  //         } else {
  //           setDealStatus(res.data.opportunity_status);
  //         }
  //         localStorage.setItem(
  //           'storedOpportunityStatusInfo',
  //           JSON.stringify(res.data.opportunity_status)
  //         );
  //       })
  //       .catch((err) => console.log('err', err));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (showDownloadButton === false) {
  //     const token = localStorage.getItem('token');
  //     const config = {
  //       headers: { Authorization: `Bearer ${token}` }
  //     };
  //     let url = `/secure/default_list_opportunity_dashboard?date=${defaultDate.value}`;

  //     if (userDetails.role === 'Customer_Admin') {
  //       url += `&org_id=${userDetails.organisation_id}`;
  //     } else if (userDetails.role === 'QA' || userDetails.role === 'Manager') {
  //       url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
  //     }
  //     API.get(url, config)
  //       .then((res) => {
  //         setOpportunityInfo(res.data.opportunity_list);
  //         localStorage.setItem('storedOpportunityInfo', JSON.stringify(res.data.opportunity_list));
  //       })
  //       .catch((err) => {
  //         console.log('err', err);
  //       });
  //   }
  // }, []);

  const [scoreStatus, setScoreStatus] = useState();

  useEffect(() => {
    if (showDownloadButton === false && tabValue === '1' && scoreStatus) {
      setLoadingForCount(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let url = `/secure/default_script_details?date=one_week&flag=${scoreStatus}&page=${popupPage}`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          setPopupDataForCompliance(res.data);
          setCompliantOpen(true);
          // setGetAudioList(res.data);
          setLoadingForCount(false);
          // localStorage.setItem('storedScriptInfo', JSON.stringify(res.data));
          // localStorage.setItem(
          //   'newValues',
          //   JSON.stringify({
          //     newOrganisation: { value: 'all', label: 'All' },
          //     newCustomer: { value: 'all', label: 'All' },
          //     newAgent: [],
          //     newProcess: { value: 'all', label: 'All' },
          //     newDate: { value: 'one_week', label: 'One Week' },
          //     agentIds: []
          //   })
          // );
        })
        .catch((err) => {
          console.log('ErrorForMultipleData', err);
          setPopupDataForCompliance();
          setLoadingForCount(false);
        });
    }
  }, [tabValue, scoreStatus, popupPage, updateStatusForCardPopup]);

  // useEffect(() => {
  //   if (showDownloadButton === false && tabValue === '1') {
  //     setScoreCardLoading(true);
  //     const token = localStorage.getItem('token');
  //     const config = {
  //       headers: { Authorization: `Bearer ${token}` }
  //     };

  //     let url = `/secure/default_qa_scorecard?date=${defaultDate.value}&page=${scoreCardPage}`;

  //     if (userDetails.role === 'Customer_Admin') {
  //       url += `&org_id=${userDetails.organisation_id}`;
  //     } else if (userDetails.role === 'QA' || userDetails.role === 'Manager') {
  //       url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
  //     }
  //     const url2 = `/secure/dashboard_agent_call_details`;
  //     Promise.all([API.get(url, config), API.get(url2, config)])
  //       .then(([res1, res2]) => {
  //         const data1 = res1.data.results;
  //         const data2 = res2.data.agent_call_details;

  //         const mergedList = data1.map((item1) => {
  //           const matchingItem2 = data2.find((item2) => item2.input_id === item1.input_id);
  //           return { ...item1, ...matchingItem2 };
  //         });
  //         setOverallScoreInfo(mergedList);
  //         setScoreCardLoading(false);
  //         setCountForPages(res1.data.count);
  //         localStorage.setItem('storedOverallScoreInfo', JSON.stringify(mergedList));
  //         // Do something with the data from both requests
  //       })
  //       .catch((err) => {
  //         setOverallScoreInfo();
  //         setScoreCardLoading(false);
  //         console.log('err', err);
  //         console.log('Error:', err);
  //       });
  //   }
  // }, [scoreCardPage, tabValue]);

  useEffect(() => {
    if (showDownloadButton === false && tabValue === '1') {
      setLoadingForScriptScore(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let url = `/secure/default_script_scorecard?date=${defaultDate.value}&flag=All`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          setScoreCardInfo(res.data);
          setLoadingForScriptScore(false);
          localStorage.setItem('storedScoreCardInfo', JSON.stringify(res.data));
        })
        .catch((err) => {
          console.log('err', err);
          setLoadingForScriptScore(false);
        });
    }
  }, [tabValue]);

  const [comparativeAnalysis, setComparativeAnalysis] = useState();
  const [feedbackStatus, setFeedbackStatus] = useState('All');

  useEffect(() => {
    if (showDownloadButton === false && tabValue === '1') {
      setLoadingForScriptScore(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let url = `/secure/default_script_scorecard_comparison?date=${defaultDate.value}&flag=All`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          setComparativeAnalysis(res.data.percentage_improvement);
          setLoadingForScriptScore(false);
          // localStorage.setItem('storedScoreCardInfo', JSON.stringify(res.data));
        })
        .catch((err) => {
          console.log('err', err);
          setComparativeAnalysis();
          setLoadingForScriptScore(false);
        });
    }
  }, [tabValue]);

  // default Dashboard api for tone and sentiment

  useEffect(() => {
    if (showDownloadButton === false && tabValue === '5') {
      setLoadingForCount(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
        // onUploadProgress: (data) => {
        //   setUploaded(Math.round((data.loaded / data.total) * 100));
        // }
      };
      let url = `/secure/default_dashboard?date=${defaultDate.value}&flag=agent`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          const filteredObject = Object.fromEntries(
            Object.entries(res.data.tone_graph).filter(
              ([date, data]) => Object.keys(data).length > 0
            )
          );
          const dates = Object.keys(filteredObject).filter(
            (item) =>
              // console.log('item ---', item);
              // console.log('dummyTones ---', dummyTone);
              // console.log('dummyTones[item]---', dummyTone.dates[item]);
              res.data.tone_graph[item].total !== 0
          );
          const graphData = [];
          const arrOfObj = [];

          dates.forEach((date) => {
            const d1 = new Date(date);
            const newObj = {
              createdAt: d1,
              name: date
            };
            arrOfObj.push(newObj);
          });

          const sortObj = arrOfObj.sort((a, b) => a.createdAt - b.createdAt);

          sortObj.forEach((data) => {
            // console.log('data ----------', data);
            // console.log('dummyTone.dates[data.name]-----', dummyTone.dates[data.name].total);
            const graphObj = {
              dates: data.name,
              high: res.data.tone_graph[data.name].high,
              medium: res.data.tone_graph[data.name].medium,
              low: res.data.tone_graph[data.name].low
              // data:
              //   dummyTone.dates[data.name].total === 0
              //     ? {
              //         total: 0
              //       }
              //     : dummyTone.dates[data.name],
              // total: dummyTone.dates[data.name].total ? dummyTone.dates[data.name].total : 0
            };
            graphData.push(graphObj);
          });
          // console.log('graphData for tone ---------', graphData);
          setToneData(graphData);
          setLoadingForCount(false);

          // setAgentTaskList(res?.data?.all_audios?.agent_task);
        })
        .catch((err) => {
          console.log('ErrorForMultipleData', err);
          setToneData();
          setLoadingForCount(false);
        });
    }
  }, [tabValue]);
  useEffect(() => {
    if (showDownloadButton === false && tabValue === '3') {
      setLoadingForCount(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
        // onUploadProgress: (data) => {
        //   setUploaded(Math.round((data.loaded / data.total) * 100));
        // }
      };
      let url = `/secure/summary_and_trend_feedback?date=${defaultDate.value}&flag=default`;
      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          setFeedbackData(res.data);
          setLoadingForCount(false);
        })
        .catch((err) => {
          console.log('ErrorForMultipleData', err);
          setFeedbackData();
          setLoadingForCount(false);
        });
    }
  }, [tabValue]);
  useEffect(() => {
    if (showDownloadButton === false && tabValue === '5') {
      setLoadingForCount(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
        // onUploadProgress: (data) => {
        //   setUploaded(Math.round((data.loaded / data.total) * 100));
        // }
      };
      let url = `/secure/sentiment_default_dashboard?date=${defaultDate.value}&flag=agent`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          const filteredObject = Object.fromEntries(
            Object.entries(res.data.sentiment_graph).filter(
              ([date, data]) => Object.keys(data).length > 0
            )
          );
          setSentimentData(filteredObject);
          setLoadingForCount(false);
        })
        .catch((err) => {
          console.log('ErrorForMultipleData', err);
          setSentimentData();
          setLoadingForCount(false);
        });
    }
  }, [tabValue]);

  useEffect(() => {
    if (showDownloadButton === false && tabValue === '5') {
      setLoadingForCount(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
        // onUploadProgress: (data) => {
        //   setUploaded(Math.round((data.loaded / data.total) * 100));
        // }
      };
      let url = `/secure/tenure_bucketwise_score?date=${defaultDate.value}&flag=default`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          setTenureData(res.data);
        })
        .catch((err) => {
          console.log('ErrorForMultipleData', err);
          setTenureData();
          setLoadingForCount(false);
        });
    }
  }, [tabValue]);

  const [feedbackTrendData, setFeedbackTrendData] = useState();

  useEffect(() => {
    if (showDownloadButton === false && tabValue === '3') {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
        // onUploadProgress: (data) => {
        //   setUploaded(Math.round((data.loaded / data.total) * 100));
        // }
      };
      let url = `/secure/trend_feedback?date=${defaultDate.value}&feedback_status=${feedbackStatus}&flag=default`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          setFeedbackTrendData(res.data);
        })
        .catch((err) => {
          console.log('ErrorForMultipleData', err);
          setFeedbackTrendData();
        });
    }
  }, [feedbackStatus, tabValue]);

  const [performanceData, setPerformanceData] = useState(
    locationData.state && locationData.state.initialData.storedPerformanceData
      ? locationData.state.initialData.storedPerformanceData
      : []
  );

  const [performanceMergeData, setPerformanceMergeData] = useState(
    locationData.state && locationData.state.initialData.storedImprovementData
      ? locationData.state.initialData.storedImprovementData
      : null
  );

  useEffect(() => {
    if (showDownloadButton === false && tabValue === '2') {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let url = `/secure/default_top_and_bottom_performance_agent?date=${defaultDate.value}`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          setPerformanceData(res.data);
        })
        .catch((err) => {
          setPerformanceData();
          // console.log(err);
        });
    }
  }, [tabValue]);
  useEffect(() => {
    if (showDownloadButton === false && tabValue === '2') {
      setLoadingForCount(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let url = `/secure/default_top_and_bottom_performance_agent_comparison?date=${defaultDate.value}`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          setPerformanceMergeData(res.data.comparison_previous_week);
          setLoadingForCount(false);
        })
        .catch((err) => {
          setPerformanceMergeData();
          setLoadingForCount(false);
          // console.log(err);
        });
    }
  }, [tabValue]);
  // default api for agent performance
  useEffect(() => {
    if (showDownloadButton === false && tabValue === '1') {
      setLoadingForCount(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let url = `/secure/default_parameter_graph?date=${defaultDate.value}`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          console.log('graphdata', res.data.Agent_performance_percentage);
          setAgentGraphData(res.data.Agent_performance_percentage);
          const combinedData = Object.keys(res.data.summary_agent_performance_percentage).map(
            (parameter) => ({
              parameter,
              score_percentage: res.data.summary_agent_performance_percentage[parameter]
            })
          );
          // console.log('combinedData', res);
          setAgentSummaryData(combinedData);

          const seriesData = res.data.Agent_performance_percentage
            ? res.data.Agent_performance_percentage.map((item) => Object.values(item)[0])
            : [];
          let categories = [];

          // Find the first non-empty object in seriesData and get its keys
          const nonEmptyObject = seriesData.find((obj) => Object.keys(obj).length > 0);
          if (nonEmptyObject) {
            categories = Object.keys(nonEmptyObject);
          }
          setSelectedCategories(
            categories.length > 5
              ? [categories[0], categories[1], categories[2], categories[3], categories[4]]
              : categories.length < 5 && categories.length > 0
              ? [categories[0]]
              : []
          );
          setLoadingForCount(false);
        })
        .catch((err) => {
          setAgentGraphData();
          setAgentSummaryData();
          setLoadingForCount(false);
        });
    }
  }, [tabValue]);

  const [scoreTrendData, setScoreTrendData] = useState(
    locationData.state && locationData.state.initialData.scoreTrendData
      ? locationData.state.initialData.scoreTrendData
      : null
  );
  useEffect(() => {
    if (showDownloadButton === false && tabValue === '1') {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let url = `/secure/default_trend_scoring?date=${defaultDate.value}`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          setScoreTrendData(res.data.scoring_percentage);
        })
        .catch((err) => setScoreTrendData());
    }
  }, [tabValue]);

  const [HitAll, setHitAll] = useState(
    locationData.state && locationData.state.initialData.HitAll
      ? locationData.state.initialData.HitAll
      : 0
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      organisation: `${
        userDetails.role !== 'Super_Admin' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA' ||
        userDetails.role === 'QA_admin'
          ? userDetails.organisation_id
          : organisation?.value
          ? organisation.value
          : ''
      }`,
      customer:
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'Customer_Admin' ||
        userDetails.role === 'QA_admin'
          ? userDetails.customer_id
          : defaultCustomer
          ? defaultCustomer.value
          : null,
      process:
        defaultProcess && defaultProcess.length > 0 ? defaultProcess.map((el) => el.value) : [],
      format: defaultDate ? defaultDate.value : 'one_week',
      // campaign: locationData.state
      //   ? locationData.state?.initialData?.newValues?.agentIds
      //   : campaignIds,
      campaign: campaignIds,
      startDate: locationData.state
        ? locationData.state.initialData.startDate
        : setDateFormat(checkInDate),
      endDate: locationData.state
        ? locationData.state.initialData.endDate
        : checkOutDate
        ? setDateFormat(checkOutDate)
        : ''
    },
    validationSchema: ComplianceSchema,
    onSubmit: async () => {
      setScoreCardPage(1);
      setHitAll(HitAll + 1);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      localStorage.setItem('storedFormik', JSON.stringify(formik.values));
      const newValues = {
        newOrganisation: organisation,
        newCustomer: defaultCustomer,
        newProcess: defaultProcess,
        newAgent: defaultAgent,
        newDate: defaultDate,
        agentIds: formik.values.campaign
        // newCustomer: test1,
        // newCompaign: test3,
        // newFormat: test4
      };
      // localStorage.setItem('newValues', JSON.stringify(newValues));
      // setShowAddGoal(true);
      setShowDownloadButton(true);
    }
  });
  useEffect(() => {
    if (HitAll !== 0) {
      if (tabValue === '1') {
        Promise.all([
          // positiveLeads();
          getScriptCardInfo(),
          getComparativeAnalysisCardsData(),
          getScordCardList(),
          getComparativeAnalysis(),
          getTrendChartDetails(),
          getScoreTrendDetails(),
          get_agent_paramerter_graph_data()
        ]);
      }
      if (tabValue === '2') {
        getFilterAgentComparison();
      }
      if (tabValue === '3') {
        Promise.all([handleFeedbackAPI(), handleFeedbackTrendAPI()]);
      }
      if (tabValue === '4') {
        // positiveLeads();
        // getFilterAgentPerformance();
        Promise.all([getDealStatus(), getOpportunityList()]);
        // getSentiments();
      }
      if (tabValue === '5') {
        Promise.all([handleToneAPI(), handleSentimentAPI(), handleTenureAPI()]);
      }
    }
  }, [HitAll, tabValue]);
  useEffect(() => {
    if (HitAll !== 0) {
      if (searchQueryList === '' && tabValue === '1') {
        getQaScoreCard();
      }
    }
  }, [HitAll, tabValue, scoreCardPage, searchQueryList]);
  useEffect(() => {
    if (HitAll !== 0 && scoreStatus) {
      if (tabValue === '1') {
        getAudioListData(scoreStatus);
      }
    }
  }, [scoreStatus, popupPage, updateStatusForCardPopup]);

  useEffect(() => {
    if (
      formik.values.campaign?.length > 0 &&
      formik.values.campaign !== '' &&
      formik.values.campaign !== 'all' &&
      tabValue === '4'
    ) {
      getOpportunityList();
      getDealStatus();
    }
  }, [update, tabValue]);

  // useEffect(() => {
  //   if (
  //     formik.values.campaign?.length > 0 &&
  //     formik.values.campaign !== '' &&
  //     formik.values.campaign !== 'all'
  //   ) {
  //     getDealStatus();
  //   }
  // }, [update]);

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  // <--------------------------------------------------------->

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
        // setOrganisation(res.data.organisation_list[0].organisation_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      // `/secure/list_of_customer?organisation_id=${formik.values.organisation}&services=["sales"]`,
      `/secure/dashboard_customer?organisation_id=${formik.values.organisation}&services=compliance`,
      config
    )
      .then((res) => {
        setCustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      formik.values.organisation !== '' &&
      formik.values.organisation !== null &&
      formik.values.organisation !== undefined &&
      formik.values.organisation !== 'all'
    ) {
      // if (JSON.parse(localStorage.getItem('formik'))?.customer && state.state !== null) {
      //   formik.setFieldValue('customer', JSON.parse(localStorage.getItem('formik'))?.customer);
      // }

      getCustomerList();
    } else {
      setCustomerList([]);
    }
  }, [formik.values.organisation]);

  const getCampaign = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/list_of_agent?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisation}&processes=${formik.values.process}`,
      // http://65.1.226.0:8060/secure/list_of_agent?customer_id=29&organisation_id=7
      config
    )
      .then((res) => {
        console.log('res compaign', res.data);
        setcampaignList(res.data.agent_list);
        // setdefaultAgent(res.data.agent_list[0].user_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (formik.values.process.length > 0) {
      if (formik.values.process !== 'all') {
        getCampaign();
      } else {
        setcampaignList([]);
      }
    } else {
      setcampaignList([]);
    }
  }, [formik.values.process]);
  // useEffect(() => {
  //   if (
  //     formik.values.organisation &&
  //     formik.values.customer &&
  //     formik.values.customer !== '' &&
  //     formik.values.customer !== 'all'
  //   ) {
  //     getCampaign();
  //   } else {
  //     setcampaignList([]);
  //   }
  // }, [formik.values.customer]);

  const setCustomerValue = (e) => {
    console.log('checkfuctionrun');
    setdefaultCustomer(e);
    formik.setFieldValue('customer', e.value);
    setAgentId([]);
  };

  // <---------------------------------------------------------------->

  // <------------------------------------------------------>

  const dateFormat = [
    { name: 'One Week', id: 'one_week' },
    { name: 'One Month', id: 'one_month' },
    // { name: 'Six Month', id: 'six_month' },
    // { name: 'One Year', id: 'one_year' },
    { name: 'Customize', id: 'customized' }
  ];

  // Integrate download csv file.
  const downloadCSV = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/download_salesdata?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&user_id=${formik.values.campaign}`;
    }
    await API.get(url, config)
      .then((res) => {
        console.log('download csv res --', res);
        FileSaver.saveAs(
          `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.final_csv}`,
          res.data.final_csv.split('/').pop()
        );
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`Select all fields`);
        setAlertType('error');
        setAlert(true);
      });
  };

  const handleDialog = () => {
    setOpen(!open);
  };
  const handleCompliantPopup = () => {
    setCompliantOpen(!compliantOpen);
    // setPageForPopup(1);
  };
  const handleOpportunityEdit = () => {
    setOpportunity(!opportunity);
  };

  const handleAlert = () => {
    setAlert(false);
  };

  const pieOptions = {
    chart: {
      width: '100%',
      type: 'pie'
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        console.log('w.config', w.config.labels[seriesIndex]);
        return (
          '<div id="arrow_box">' +
          '<span>' +
          w.config.labels[seriesIndex] +
          ':' +
          series[seriesIndex] +
          '/' +
          (series[0] + series[1]) +
          '</span>' +
          '</div>'
        );
      }
    },
    labels: ['Assigned', 'Completed'],
    legend: {
      position: 'bottom'
    }
  };
  const pieSeries = dealStatus === null ? [0, 0] : [dealStatus.Assigned, dealStatus.completed];
  console.log('pieSeries', pieSeries);
  // useEffect(() => {
  //   if (
  //     dealStatus &&
  //     dealStatus?.in_progress === 0 &&
  //     dealStatus?.closed_won === 0 &&
  //     dealStatus?.closed_lost === 0
  //   ) {
  //     setDealStatusShow(false);
  //   } else {
  //     setDealStatusShow(true);
  //   }
  // }, [dealStatus]);
  // useEffect(() => {
  //   console.log('formik val uttu', formik.values);
  // }, [formik.values]);
  // multi process function
  const handleProcessChange = (selectedOptions) => {
    setdefaultAgent([]);
    setAgentId([]);
    setCampaignId([]);
    formik.setFieldValue('campaign', []);
    if (selectedOptions.some((option) => option.value === 'all')) {
      // const processes = processList.map((element) => {
      //   // console.log('element', element);
      //   return element;
      // });
      // const processString = processes.join(',');
      // let processArray = processString.split(',').map(Number);
      // if (processString === '') {
      //   processArray = [];
      // }
      // console.log('numbersArrayone', numbersArray);
      // console.log('agentList', agentList);
      formik.setFieldValue('process', processList);
      setdefaultProcess(
        processList.map((element) => ({
          value: element,
          label: element
        }))
      );
    } else {
      const processes = selectedOptions.map((element) => {
        // console.log('element', element);
        return element.value;
      });
      // console.log('userIds', userIds);
      // const processString = processes.join(',');
      // // console.log('userIdsString', userIdsString);
      // // console.log('selectedOptions', selectedOptions);
      // let processArray = processString.split(',').map(Number);
      // if (selectedOptions.length === 0) {
      //   processArray = [];
      // }
      // console.log('numbersArray', numbersArray);

      formik.setFieldValue('process', processes);

      setdefaultProcess(selectedOptions);
    }
  };

  const handleProcess = (e) => {
    localStorage.removeItem('storedFormik');
    setdefaultProcess(e);
    formik.setFieldValue('process', e.value);
  };
  const getProcessList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `/secure/process_list?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisation}`,
        config
      );
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
        // setdefaultProcess(processData.data.processes[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log('formik.values.process', formik.values.process);
  // const getProcessList = async () => {
  //   const token = localStorage.getItem('token');
  //   const config = {
  //     headers: { Authorization: `Bearer ${token}` }
  //   };
  //   function getProcess() {
  //     return API.get(
  //       `/secure/process_list?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisation}`,
  //       config
  //     );
  //   }
  //   Promise.all([getProcess()])
  //     .then(([processData]) => {
  //       setdefaultProcess(processData.data.processes[0]);
  //       setprocessList(processData.data.processes);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  useEffect(() => {
    if (formik.values.customer !== null && formik.values.customer !== 'all') {
      // formik.setFieldValue('process', null);
      // formik.setFieldValue('agent', null);
      // setoutput([]);
      // setprocessList([]);
      getProcessList();
    } else {
      setprocessList([]);
    }
  }, [formik.values.customer]);

  const changedDate = (date) => {
    const newdate = new Date(date);
    const formatStartDate = `${newdate.getDate()}-${
      newdate.getMonth() + 1
    }-${newdate.getFullYear()}`;
    return formatStartDate;
  };

  const CHART_DATA = [
    {
      data: trendSeries.length > 0 && trendSeries
    }
  ];
  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    tooltip: {
      marker: { show: false },
      y: {
        // formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => null
        }
      }
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '28%', borderRadius: 2 }
    },
    xaxis: {
      tickPlacement: 'between',
      type: 'category',
      categories: trendData.length > 0 && trendData
      // title: {
      //   text: 'Date',
      //   style: {
      //     fontSize: '14px',
      //     fontFamily: 'Helvetica, Arial, sans-serif',
      //     fontWeight: 600
      //   }
      // }
    },
    yaxis: {
      title: {
        text: 'Compliance %',
        style: {
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600
        }
      }
    }
  });

  // ----------------------
  // represent the Percentage at center of chart
  function CenterTemplate(gauge) {
    console.log('gaugevalue', gauge);

    return (
      <svg>
        <rect y="0" x="0" width="200" height="200" fill="transparent" />

        <g transform="translate(50 0)">
          <rect x="0" y="0" width="100" height="70" rx="8" fill="#f2f2f2" />
          <text textAnchor="middle" y="27" x="50" fill="#000" fontSize="20">
            <tspan x="50">{gauge.value()}</tspan>
            <tspan x="50" dy="30">
              %
            </tspan>
          </text>
        </g>

        <g transform="translate(43 140)">
          <rect className="description" x="0" y="0" width="114" height="56" rx="8" fill="#fff" />
          <text textAnchor="start" y="23" x="15" fill="#000" fontSize="12">
            <tspan x="15">Capacity: 10 kg</tspan>
            <tspan x="15" dy="20">
              Graduation: 10 g
            </tspan>
          </text>
        </g>
      </svg>
    );
  }
  // const format = {
  //   type: 'percentage',
  //   precision: 4
  // };

  const scoreCardParameters = [
    'Interaction ID',
    'E-Code',
    'Agent Name',
    'TL Name',
    'QA Name',
    'DOJ',
    'Call Duration',
    'Tenure (in Months)',
    'Bucket',
    'Language',
    'Call Date',
    'Eval date',
    'Week',
    'Attribute',
    'Sub-Attribute',
    'Genesys Disposition'
  ];
  const QRCParameters = [
    'Interaction ID',
    'E-Code',
    'Agent Name',
    'TL Name',
    'QA Name',
    'DOJ',
    'Call Duration',
    'Tenure (in Months)',
    'Bucket',
    'Language',
    'Call Date',
    'Eval date',
    'Week',
    'QRC Type',
    'Attribute',
    'Sub-Attribute',
    'Genesys Disposition'
  ];

  const parameterList = [];

  const scoreCardData = {
    scoring_details: [
      {
        description: ['check if call drops'],
        Category: 'Soft Skill',
        Type: 'Boolean',
        Parameter: 'Call Drop',
        score_value: 'Yes',
        score: '0'
      },
      {
        description: ['Too Fast'],
        Category: 'Soft Skill',
        Type: 'Multivalue',
        Value: ['High', 'Medium', 'Low'],
        Parameter: 'Rate of speech',
        score_value: 'Medium',
        score: '0'
      },
      {
        description: ['call on hold'],
        Category: 'Soft Skill',
        Type: 'Boolean',
        Parameter: 'Hold procedure',
        score_value: 'No',
        score: '5'
      },
      {
        description: [
          'repeat',
          ' come again',
          'explain once again',
          ' Um',
          ' Uh',
          ' Verbal fillers'
        ],
        Category: 'Soft Skill',
        Type: 'Multivalue',
        Value: ['Average', 'Good', 'Excellent'],
        Parameter: 'Clarity of speech',
        score_value: 'Good',
        score: '5'
      },
      {
        description: ['Hello am i audible', ' Please respond'],
        Category: 'Soft Skill',
        Type: 'Boolean',
        Parameter: 'Call avoidance',
        score_value: 'No',
        score: '3'
      },
      {
        description: ['Sir/Madam'],
        Category: 'Soft Skill',
        Type: 'Boolean',
        Parameter: 'Salutation',
        score_value: 'Yes',
        score: '2'
      },
      {
        description: ['check system'],
        Category: 'Soft Skill',
        Type: 'Boolean',
        Parameter: 'System downtime',
        score_value: 'No',
        score: '4'
      },
      {
        description: ['Ask & Understand for Query before Verification?'],
        Category: 'Soft Skill',
        Type: 'Boolean',
        Parameter: 'Query Understanding',
        score_value: 'Yes',
        score: '4'
      },
      {
        description: ['Seek/Inform Permission/Reason for Security Check'],
        Category: 'Soft Skill',
        Type: 'Boolean',
        Parameter: 'Verification - Reason / Permission',
        score_value: 'Yes',
        score: '4'
      },
      {
        description: ["Minimum relevant Security Check Done : Verify Caller's Identity (FATAL)"],
        Category: 'Critical Skill',
        Type: 'Boolean',
        Parameter: 'Verification / Security Check / Authentication',
        score_value: 'No',
        score: '3'
      },
      {
        description: [
          'Tone: Uses pleasant tone that reflects warmth/enthusiasm throughout the call with clarity of speech'
        ],
        Category: 'Critical Skill',
        Type: 'Boolean',
        Parameter: 'Tone & Voice Modulation',
        score_value: 'Yes',
        score: '3'
      }
    ]
  };

  // download csv for qa scorecard
  const [downloadStart, setDownloadStart] = React.useState(false);
  const [taskId, setTaskId] = React.useState();

  const handleDefaultDownloadCsv = async (value) => {
    setDownloadStart(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/default_download_call_details?date=one_week&csv_for=${value}`;

    if (userDetails.role === 'Customer_Admin') {
      url += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    await API.get(url, config)
      .then((res) => {
        // FileSaver.saveAs(
        //   `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.final_csv}`,
        //   'score_details.csv'
        // );
        // setDownloadStart(false);
        setTaskId(res.data.task_id);
        // setAlertContent(`Success: ${res.data.message}`);
        // setAlertType('success');
        // setAlert(true);
      })
      .catch((err) => {
        // console.log(err);
        setDownloadStart(false);
        console.log('error----', err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  const downloadFile = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/check_async_task?task_id=${taskId}`;

    if (userDetails.role === 'Customer_Admin') {
      url += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }

    try {
      const res = await API.get(url, config);
      if (res.data.message === 'Downloaded Successfully!') {
        FileSaver.saveAs(
          `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.file_path}`,
          res.data.file_path.split('/').pop()
        );
        setDownloadStart(false);
        setAlertContent(`Success: ${res.data.message}`);
        setAlertType('success');
        setAlert(true);
      } else {
        // If the message is not 'Download Successfully', make another request
        setTimeout(downloadFile, 2000); // You can adjust the delay between requests
      }
    } catch (err) {
      setDownloadStart(false);
      console.log('error----', err);
      setAlertContent(`${err.response.data.message}`);
      setAlertType('error');
      setAlert(true);
    }
  };

  useEffect(() => {
    if (downloadStart) {
      downloadFile();
    }
  }, [taskId]);

  const handleFilterDownloadCsv = async (value) => {
    setDownloadStart(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    // let url = `/secure/download_dashboard_call_details?customer_id=4&date=one_week&org_id=3&processes=scoreAutomation&user_id=7`;
    let url = `/secure/download_dashboard_call_details?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}&processes=${formik.values.process}&csv_for=${value}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&user_id=${formik.values.campaign}`;
    }

    await API.get(url, config)
      .then((res) => {
        // FileSaver.saveAs(
        //   `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.final_csv}`,
        //   'score_details.csv'
        // );
        // setDownloadStart(false);
        setTaskId(res.data.task_id);
        setAlertContent(`Success: ${res.data.message}`);
        setAlertType('success');
        setAlert(true);
      })
      .catch((err) => {
        // console.log(err);
        setDownloadStart(false);
        console.log('error----', err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  // download csv for script card

  const handleDownloadScriptCsv = () => {
    if (scoreCardInfo && scoreCardInfo.file_path) {
      setDownloadScriptStart(true);
      FileSaver.saveAs(
        `${process.env.REACT_APP_SERVER_MEDIA_URL}${scoreCardInfo.file_path}`,
        scoreCardInfo.file_path.split('/').pop()
      );
      setDownloadScriptStart(false);
    }
  };

  // multiple agent functionality

  const handleAgentChange = (selectedOptions) => {
    console.log('selectedOptions', selectedOptions);
    if (selectedOptions.some((option) => option.value === 'all')) {
      const userIds = campaignList.map((element) => {
        console.log('element', element);
        return element.user_id;
      });
      const userIdsString = userIds.join(',');
      let numbersArray = userIdsString.split(',').map(Number);
      if (userIdsString === '') {
        numbersArray = [];
      }
      // console.log('numbersArrayone', numbersArray);
      // console.log('userIds', userIdsString);
      formik.setFieldValue('campaign', numbersArray);
      setCampaignId(numbersArray);
      setdefaultAgent(
        campaignList.map((element) => ({
          value: element.user_id,
          label: `${element.first_name} ${element.last_name}`
        }))
      );
    } else {
      const userIds = selectedOptions.map((element) => {
        console.log('element', element);
        return element.value;
      });
      console.log('userIds', userIds);
      const userIdsString = userIds.join(',');
      // console.log('userIdsString', userIdsString);
      // console.log('selectedOptions', selectedOptions);
      let numbersArray = userIdsString.split(',').map(Number);
      if (selectedOptions.length === 0) {
        numbersArray = [];
      }
      console.log('numbersArray', numbersArray);
      setdefaultAgent(selectedOptions);
      formik.setFieldValue('campaign', numbersArray);
      setCampaignId(numbersArray);
    }
  };

  // format Date
  function formatDate(inputDate) {
    const dateObject = new Date(inputDate);

    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear();

    return `${day}-${month}-${year}`;
  }

  // ------------------------------------------
  // function for ageing showning
  function formatTime(hours) {
    if (hours < 1 / 60) {
      return `${Math.floor(hours * 3600)} sec`;
    } else if (hours < 1) {
      return `${Math.floor(hours * 60)} min`;
    } else if (hours < 24) {
      return `${Math.floor(hours)} hr`;
    } else if (hours < 24 * 7) {
      return `${Math.floor(hours / 24)} day`;
    } else if (hours < 24 * 7 * 4) {
      return `${Math.floor(hours / (24 * 7))} week`;
    } else if (hours < 24 * 7 * 4 * 4) {
      return `${Math.floor(hours / (24 * 7 * 4))} month`;
    } else if (hours < 24 * 7 * 4 * 4 * 12) {
      return `${Math.floor(hours / (24 * 7 * 4 * 4))} yr`;
    } else {
      return 'more than yr';
    }
  }

  // circular progress bar function

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          color={props.value > 80 ? 'success' : props.value > 50 ? 'warning' : 'error'}
          {...props}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  // downloadCsv Function
  console.log('overallScoreInfo', overallScoreInfo);
  function convertJsonToCsv(data) {
    // Extract headers from the first object in the array
    const headers = Object.keys(data[0]);

    // Create an array of rows, where each row is an array of values
    const rows = data.map((item) => headers.map((header) => item[header]));

    // Create a CSV string
    const csv = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

    return csv;
  }
  const handleDownloadCSVFromUI = () => {
    // Convert tableData to CSV format
    if (overallScoreInfo.length > 0) {
      const csvData = convertJsonToCsv(overallScoreInfo);

      // To download the CSV file
      const blob = new Blob([csvData], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'data.csv';
      link.click();
    }
  };

  // for opening download menu
  const filterref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  // function for return value in K ,M B
  function formatNumber(num) {
    if (num >= 1e9) {
      return (
        <Tip title={num} followCursor placement="top">
          <Typography
            style={{
              color: '#000',
              fontStyle: 'bold',
              fontSize: '2.4rem'
            }}
          >
            {(num / 1e9).toFixed(1) + 'B'}
          </Typography>
        </Tip>
      );
    } else if (num >= 1e6) {
      return (
        <Tip title={num} followCursor placement="top">
          <Typography
            style={{
              color: '#000',
              fontStyle: 'bold',
              fontSize: '2.4rem'
            }}
          >
            {(num / 1e6).toFixed(1) + 'M'}
          </Typography>
        </Tip>
      );
    } else if (num >= 1e3) {
      return (
        <Tip title={num} followCursor placement="top">
          <Typography
            style={{
              color: '#000',
              fontStyle: 'bold',
              fontSize: '2.4rem'
            }}
          >
            {(num / 1e3).toFixed(1) + 'K'}
          </Typography>
        </Tip>
      );
    } else {
      return num?.toString();
    }
  }

  // api for perticuler agent perforformance paramerter trend graph
  const [selectedCategories, setSelectedCategories] = useState(
    locationData.state && locationData.state.initialData.storedSelectedCategories
      ? locationData.state.initialData.storedSelectedCategories
      : []
  );

  const get_agent_paramerter_graph_data = async () => {
    setLoadingForCount(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/filter_parameter_graph?org_id=${formik.values.organisation}&customer_id=${formik.values.customer}&user_id=${formik.values.campaign}&date=${formik.values.format}&processes=${formik.values.process}`;
    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(url, config)
      .then((res) => {
        console.log('graphdata', res.data['Agent performance percentage']);
        setAgentGraphData(res.data.Agent_performance_percentage);
        const combinedData = Object.keys(res.data.summary_agent_performance_percentage).map(
          (parameter) => ({
            parameter,
            score_percentage: res.data.summary_agent_performance_percentage[parameter]
          })
        );
        // console.log('combinedData', res);
        setAgentSummaryData(combinedData);
        const seriesData = res.data.Agent_performance_percentage
          ? res.data.Agent_performance_percentage.map((item) => Object.values(item)[0])
          : [];
        let categories = [];

        // Find the first non-empty object in seriesData and get its keys
        const nonEmptyObject = seriesData.find((obj) => Object.keys(obj).length > 0);
        if (nonEmptyObject) {
          categories = Object.keys(nonEmptyObject);
        }
        setSelectedCategories(
          categories.length > 5
            ? [categories[0], categories[1], categories[2], categories[3], categories[4]]
            : categories.length < 5 && categories.length > 0
            ? [categories[0]]
            : []
        );
        setLoadingForCount(false);
      })
      .catch((err) => {
        console.log(err);
        setAgentGraphData();
        setLoadingForCount(false);
      });
  };
  // console.log('agentgraph_data', agentGraphData);
  // useEffect(() => {
  //   get_agent_paramerter_graph_data();
  // }, []);

  const nonEmptyArray =
    agentGraphData?.length > 0
      ? agentGraphData.filter((item) => Object.keys(item)[0] !== undefined)
      : [];

  // Convert to the desired object format
  const convertedObject = Object.assign({}, ...nonEmptyArray);

  // Filter out dates with no parameters
  const datesWithData = convertedObject
    ? Object.keys(convertedObject).filter((date) => Object.keys(convertedObject[date]).length > 0)
    : [];

  // Extract parameters from the first date with data
  const parameters = datesWithData.length > 0 ? Object.keys(convertedObject[datesWithData[0]]) : [];

  // Prepare series data
  const series = parameters.map((parameter) => ({
    name: parameter,
    data: datesWithData.map((date) => convertedObject[date][parameter] || 0) // Replace undefined with 0
  }));
  // console.log('series', series);
  // Prepare options

  const generateColorGradient = (numBars) => {
    return chroma.scale(['#FF5733', '#33FF57']).mode('lch').colors(numBars);
  };

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false // Disable zooming to prevent cutting off the graph
      },
      dropShadow: {
        enabled: false
      },
      offsetY: 10, // Adjust the top padding
      offsetX: 10
    },
    colors: generateColorGradient(parameters.length),
    plotOptions: {
      bar: {
        // horizontal: false,
        borderRadius: 10,
        dataLabels: {
          enabled: false // Set this to false to hide data labels
        },
        // horizontal: true,
        columnWidth: '20%'
        // barHeight: '20%'
      }
    },
    xaxis: {
      categories: datesWithData
    },
    yaxis: {
      title: {
        text: 'parameter %'
      },
      // min: 0,
      // max: 100,
      labels: {
        formatter: function (value) {
          return Math.round(value);
        }
      }
    },
    legend: {
      position: 'right',
      offsetY: 0
    },
    fill: {
      opacity: 2
    }
  };

  const dates = agentGraphData ? agentGraphData.map((item) => Object.keys(item)[0]) : [];
  const seriesData = agentGraphData ? agentGraphData.map((item) => Object.values(item)[0]) : [];

  const agentTrendData =
    seriesData &&
    seriesData.map((data, index) => {
      const date = dates[index];
      const dataPoint = { date };
      Object.entries(data).forEach(([key, value]) => {
        dataPoint[key] = value;
      });
      return dataPoint;
    });

  // chart for agent trend
  const uniqueCategories = new Set();

  seriesData.forEach((entry) => {
    Object.keys(entry).forEach((category) => uniqueCategories.add(category));
  });

  // Convert Set to Array
  const categories = Array.from(uniqueCategories);
  // let categories = [];

  // // Find the first non-empty object in seriesData and get its keys
  // const nonEmptyObject = seriesData.find((obj) => Object.keys(obj).length > 0);
  // if (nonEmptyObject) {
  //   categories = Object.keys(nonEmptyObject);
  // }
  const hideLegendItems = (items) => {
    const returnLegends = items.filter((el) => selectedCategories.includes(el.text));
    return returnLegends;
  };

  const series1 =
    categories &&
    categories.map((category) => ({
      valueField: category,
      name: category,
      type: 'spline'
    }));

  const [visibleSeriesIndex, setVisibleSeriesIndex] = useState(0);
  const [chartTypeForCategory, setChartTypeForCategory] = useState('summary');
  const [chartTypeForFeedback, setChartTypeForFeedback] = useState('summary');
  const [chartTypeForScore, setChartTypeForScore] = useState('summary');

  const shortenLabel = (label) => {
    const maxLength = 8; // Set the maximum length you want
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  };
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      console.log('payload[0]', payload);

      // const words = Object.keys(payload[0].payload);
      return (
        <Card className="custom-tooltip" sx={{ padding: '10px' }}>
          {/* <p className="intro">{data === 'total' ? '' : `Date:${data}`}</p> */}
          <Typography className="intro" style={{ color: 'grey' }}>
            {data.parameter}
          </Typography>
          <Typography className="intro" style={{ color: 'grey' }}>
            {data.score_percentage}
          </Typography>
        </Card>
      );
    }
    return null;
  };

  // CSS for the circular legend marker
  const legendIconStyle = {
    width: '10px', // Adjust the size as needed
    height: '10px', // Adjust the size as needed
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '5px' // Adjust spacing
  };

  // Apply a cursive font-family to the Legend
  const legendTextStyle = {
    fontFamily: 'arial',
    fontWeight: 'bold',
    fontSize: 12
  };

  const CustomLegend = (e) => (
    <div
      style={{
        fontFamily: 'arial',
        fontWeight: 'bold',
        fontSize: 12,
        display: 'flex',
        justifyContent: 'center',
        marginTop: 25
      }}
    >
      {e.payload.map((entry, index) => (
        <li key={index} style={{ listStyleType: 'none' }}>
          <span
            className="legend-icon"
            style={{ ...legendIconStyle, backgroundColor: entry.color }}
          />
          <span style={legendTextStyle}>Parameter</span>
        </li>
      ))}
    </div>
  );

  // const [trendChartData, setTrendChartData] = useState();

  const scoreValues = scoreTrendData
    ? Object.values(scoreTrendData).filter((value) => typeof value === 'number')
    : [];

  const keysArray = scoreTrendData && Object.keys(scoreTrendData);
  const overallAverageScoreSummary =
    scoreTrendData &&
    Object.keys(scoreTrendData).length > 0 &&
    Object.values(scoreTrendData).reduce((sum, value) => sum + value, 0) /
      Object.keys(scoreTrendData).length;

  const chartOptionsLineGraph = {
    // title: { text: 'Trend chart' },
    xaxis: {
      categories: keysArray,
      labels: {
        formatter(val) {
          // const index = val.indexOf('/');
          // console.log('keysArray', typeof val);
          // If '/' is found, return the substring before it
          if (val && val.includes('/')) {
            const index = val.indexOf('/');
            return val.substring(0, index);
          }

          // If '/' is not found, return the entire string
          return val;
        }
      }
      // title: {
      //   text: 'Date'
      // }
    },
    yaxis: {
      title: {
        text: 'Score',
        style: {
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600
        }
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        }
      },
      min: 10,
      max: 100
    },

    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true, // Set to false to remove the download tool
          selection: false, // Set to false to remove the selection tool
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: []
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'Date',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            }
          },
          svg: {
            filename: undefined
          },
          png: {
            filename: undefined
          }
        },
        autoSelected: 'zoom'
      }
    },
    grid: {
      show: false
    }
  };

  const [agentPerformanceOpen, setAgentPerformanceOpen] = useState(false);
  const [agentPerformanceSummary, setAgentPerformanceSummary] = useState([]);
  const [agentName, setAgentPerformerName] = useState('');

  const handleAgentAnalysis = async (agentDetails) => {
    // console.log('agentDetails', agentDetails);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/agent_performance_drilldown?org_id=${agentDetails.organisation_id_id}&customer_id=${agentDetails.customer_id_id}&agent_id=${agentDetails.agent_id_id}&call_count=${agentDetails.call_count}&date=${formik.values.format}`;
    if (agentDetails.processes) {
      url += `&processes=${agentDetails.processes}`;
    }
    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(url, config)
      .then((res) => {
        const combinedData = Object.keys(res.data.data).map((parameter) => ({
          parameter,
          score_percentage: res.data.data[parameter]
        }));
        // console.log('combinedData', res);
        setAgentPerformanceSummary(combinedData);
        setAgentPerformerName(agentDetails.agent_name);
      })
      .catch((err) => {
        console.log(err);
        setAgentPerformanceSummary([]);
        setAgentPerformerName('');
      });
  };

  const handleCategoryChange = (event) => {
    // const { value } = event.target;
    setSelectedCategories(event.target.value);
    // console.log('hello multiSelect', event.target.value);
  };

  const handleDialogForAgentPerformance = () => {
    setAgentPerformanceOpen(!agentPerformanceOpen);
  };

  // tone related data API
  const handleToneAPI = async () => {
    setLoadingForCount(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/tone_graph?customer_id=${formik.values.customer}&processes=${formik.values.process}&date=${formik.values.format}&org_id=${formik.values.organisation}&flag=agent`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&user_id=${formik.values.campaign}`;
    }
    await API.get(url, config)
      .then((res) => {
        const filteredObject = Object.fromEntries(
          Object.entries(res.data.dates).filter(([date, data]) => Object.keys(data).length > 0)
        );
        const dates = Object.keys(filteredObject).filter(
          (item) =>
            // console.log('item ---', item);
            // console.log('dummyTones ---', dummyTone);
            // console.log('dummyTones[item]---', dummyTone.dates[item]);
            res.data.dates[item].total !== 0
        );
        const graphData = [];
        const arrOfObj = [];

        dates.forEach((date) => {
          const d1 = new Date(date);
          const newObj = {
            createdAt: d1,
            name: date
          };
          arrOfObj.push(newObj);
        });

        const sortObj = arrOfObj.sort((a, b) => a.createdAt - b.createdAt);

        sortObj.forEach((data) => {
          // console.log('data ----------', data);
          // console.log('dummyTone.dates[data.name]-----', dummyTone.dates[data.name].total);
          const graphObj = {
            dates: data.name,
            high: res.data.dates[data.name].high,
            medium: res.data.dates[data.name].medium,
            low: res.data.dates[data.name].low
            // data:
            //   dummyTone.dates[data.name].total === 0
            //     ? {
            //         total: 0
            //       }
            //     : dummyTone.dates[data.name],
            // total: dummyTone.dates[data.name].total ? dummyTone.dates[data.name].total : 0
          };
          graphData.push(graphObj);
        });
        // console.log('graphData for tone ---------', graphData);
        setToneData(graphData);
        setLoadingForCount(false);
      })
      .catch((err) => {
        console.log(err);
        setToneData();
        setLoadingForCount(false);
        // setgraphDataForTone();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };
  const handleSentimentAPI = async () => {
    // setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/sentiment_graph?customer_id=${formik.values.customer}&processes=${formik.values.process}&date=${formik.values.format}&org_id=${formik.values.organisation}&flag=agent`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&user_id=${formik.values.campaign}`;
    }
    await API.get(url, config)
      .then((res) => {
        const filteredObject = Object.fromEntries(
          Object.entries(res.data.dates).filter(([date, data]) => Object.keys(data).length > 0)
        );
        // console.log('filteredObject', filteredObject);
        // }
        setSentimentData(filteredObject);
      })
      .catch((err) => {
        console.log(err);
        setSentimentData();
        // setLoading(false);
        // setgraphDataForTone();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };
  const handleTenureAPI = async () => {
    // setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/tenure_bucketwise_score?customer_id=${formik.values.customer}&processes=${formik.values.process}&date=${formik.values.format}&org_id=${formik.values.organisation}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&user_id=${formik.values.campaign}`;
    }
    await API.get(url, config)
      .then((res) => {
        setTenureData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setTenureData();
        // setLoading(false);
        // setgraphDataForTone();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };
  const handleFeedbackAPI = async () => {
    // setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/trend_feedback?customer_id=${formik.values.customer}&processes=${formik.values.process}&date=${formik.values.format}&org_id=${formik.values.organisation}&feedback_status=${feedbackStatus}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&user_id=${formik.values.campaign}`;
    }
    await API.get(url, config)
      .then((res) => {
        setFeedbackTrendData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setFeedbackTrendData();
        // setLoading(false);
        // setgraphDataForTone();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };
  const handleFeedbackTrendAPI = async () => {
    setLoadingForCount(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/summary_and_trend_feedback?&customer_id=${formik.values.customer}&processes=${formik.values.process}&date=${formik.values.format}&org_id=${formik.values.organisation}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    if (formik.values.campaign !== null && formik.values.campaign !== '') {
      url += `&user_id=${formik.values.campaign}`;
    }
    await API.get(url, config)
      .then((res) => {
        setFeedbackData(res.data);
        setLoadingForCount(false);
      })
      .catch((err) => {
        console.log(err);
        setFeedbackData();
        setLoadingForCount(false);
        // setgraphDataForTone();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };
  const getFilterAgentPerformance = async () => {
    // setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/top_and_bottom_performance_agent?customer_id=${formik.values.customer}&processes=${formik.values.process}&date=${formik.values.format}&org_id=${formik.values.organisation}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    await API.get(url, config)

      .then((res) => {
        setPerformanceData(res.data);
      })
      .catch((err) => {
        setPerformanceData();
        // console.log(err);
      });
  };
  const getFilterAgentComparison = async () => {
    setLoadingForCount(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/filter_top_and_bottom_performance_agent_comparison?customer_id=${formik.values.customer}&processes=${formik.values.process}&date=${formik.values.format}&org_id=${formik.values.organisation}`;

    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    await API.get(url, config)

      .then((res) => {
        setPerformanceMergeData(res.data.comparison_previous_data);
        const obj = { data: res.data.current_data };
        setPerformanceData(obj);
        setLoadingForCount(false);
      })
      .catch((err) => {
        setPerformanceMergeData();
        setPerformanceData();
        setLoadingForCount(false);
        // console.log(err);
      });
  };

  // popup chart api
  const [callList, setCallList] = useState();

  const [labelShown, setLabel] = useState('');
  const [callListOpen, setCallListOpen] = useState(false);
  const [downloadPath, setDownloadPath] = useState('');

  const handleTonePopup = (toneValue, toneChartDate) => {
    setLoadingForCount(true);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const newToneUrl = `/secure/new_tone_graph?customer_id=${formik.values.customer}&user_id=${formik.values.campaign}&date=${defaultDate.value}&start_date=${toneChartDate}&tone_value=${toneValue}&flag=agent&page=${pageForPopup}`;
    const defaultToneUrl = `/secure/new_default_dashboard?start_date=${toneChartDate}&chart_type=tone&flag=agent&tone_value=${toneValue}&page=${pageForPopup}`;
    API.get(showDownloadButton === false ? defaultToneUrl : newToneUrl, config)
      .then((newToneRes) => {
        // setPageForPopup(0);
        if (showDownloadButton === false) {
          setCallList(newToneRes.data);
        } else {
          setCallList(newToneRes.data);
        }
        setLabel(toneValue);
        setDownloadPath(newToneRes.data.results.file_path);
        setCallListOpen(true);
        setLoadingForCount(false);
        // setFilteredTone(
        //   newToneRes.data.tone_graph[moment(toneDate, 'DD/MM/YYYY').format('YYYY-MM-DD')]
        // );
        // handleDialog();
        // setLoading(false);
      })
      // .then(() => {
      //   handleDialog();
      //   setLoading(false);
      // })
      .catch((err) => {
        console.log('error', err.response?.data.message);
        setLoadingForCount(false);
        // setAlertContent(`${err.response?.data.message}`);
        // setLoading(false);
        // setAlertType('error');
        // setAlert(true);
      });
  };

  const handleSentimentPopup = async (sentimentDate, dataIndex) => {
    setLoadingForCount(true);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const sentiment_value =
      (dataIndex === 0 && 'positive') ||
      (dataIndex === 1 && 'negative') ||
      (dataIndex === 2 && 'neutral');

    const sentimentUrl = `/secure/new_sentiment_graph?customer_id=${formik.values.customer}&user_id=${formik.values.campaign}&date=${defaultDate.value}&start_date=${sentimentDate}&flag=agent&sentiment_value=${sentiment_value}&page=${pageForPopup}`;
    const defaultSentimentUrl = `/secure/new_default_dashboard?start_date=${sentimentDate}&chart_type=sentiment&flag=agent&sentiment_value=${sentiment_value}&page=${pageForPopup}`;

    await API.get(showDownloadButton === false ? defaultSentimentUrl : sentimentUrl, config)
      .then((res) => {
        // setPageForPopup(0);
        if (showDownloadButton === false) {
          setCallList(res.data);
        } else {
          setCallList(res.data);
        }
        setLabel(sentiment_value);
        setDownloadPath(res.data.results.file_path);
        setCallListOpen(true);
        setLoadingForCount(false);
        // setLoading(false);
      })
      .catch((err) => {
        setLoadingForCount(false);
        setPageForPopup();
        // setLoading(false);
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };

  useEffect(() => {
    if (pageForPopup && globalDate) {
      if (globalBarValue === 0 || globalBarValue === 1 || globalBarValue === 2) {
        handleSentimentPopup(globalDate, globalBarValue);
      } else if (
        globalBarValue === 'high' ||
        globalBarValue === 'medium' ||
        globalBarValue === 'low'
      ) {
        handleTonePopup(globalBarValue, globalDate);
      }
    }
  }, [pageForPopup, globalUpdate]);

  const handleSummaryTonePopup = (toneValue) => {
    setLoadingForCount(true);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let defaultUrl = `/secure/default_summary_HML?date=${defaultDate.value}&flag=agent&tone_value=${toneValue}&page=${pageForPopup}`;
    if (defaultDate.value === 'customized') {
      defaultUrl += `start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    if (userDetails.role === 'Customer_Admin') {
      defaultUrl += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      defaultUrl += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    let filterUrl = `/secure/filter_HML_list?date=${defaultDate.value}&customer_id=${formik.values.customer}&user_id=${formik.values.campaign}&org_id=${formik.values.organisation}&flag=agent&tone_value=${toneValue}&page=${pageForPopup}`;
    if (defaultDate.value === 'customized') {
      filterUrl += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    //   formik.values.agent
    // }&start_date=${moment(toneDate, 'DD/MM/YYYY').format(
    //   'YYYY-MM-DD'
    // )}&tone_value=${toneValue}&flag=${toggleValue}`;
    API.get(showDownloadButton === false ? defaultUrl : filterUrl, config)
      .then((newToneRes) => {
        // setShowToneColumnName()
        setLabel(toneValue);
        setCallList(newToneRes.data);
        setDownloadPath(newToneRes.data.results.file_path);
        setCallListOpen(true);
        setLoadingForCount(false);
        // setToneDate(formik.values.startDate);
        // setDataIndex(null);
        // handleDialog();
        // setLoading(false);
        // setFilteredTone(
        // console.log('newToneRes.data.tone_graph', newToneRes.data.tone_graph);
        //   newToneRes.data.tone_graph[moment(toneDate, 'DD/MM/YYYY').format('YYYY-MM-DD')]
        // );
        // handleDialog();
        // setLoading(false);
      })
      // .then(() => {
      //   handleDialog();
      //   setLoading(false);
      // })
      .catch((err) => {
        console.log('error', err.response?.data.message);
        setLoadingForCount(false);
        // setAlertContent(`${err.response?.data.message}`);
        // setLoading(false);
        // setAlertType('error');
        // setAlert(true);
      });
  };

  const handleSummarySentimentPopup = async (sentiment_value) => {
    // setLoading(true);
    setLoadingForCount(true);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let defaultUrl = `/secure/default_sentiments_list?date=${defaultDate.value}&flag=agent&sentiment_value=${sentiment_value}&page=${pageForPopup}`;
    if (defaultDate.value === 'customized') {
      defaultUrl += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    if (userDetails.role === 'Customer_Admin') {
      defaultUrl += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      defaultUrl += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    let filterUrl = `/secure/filter_sentiments_list?date=${defaultDate.value}&customer_id=${formik.values.customer}&user_id=${formik.values.campaign}&org_id=${formik.values.organisation}&flag=agent&sentiment_value=${sentiment_value}&page=${pageForPopup}`;
    if (defaultDate.value === 'customized') {
      filterUrl += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    // const filterUrl = `/secure/filter_sentiments_list?org_id=${formik.values.organisation}&customer_id=${formik.values.customer}&user_id=${formik.values.agent}&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}&flag=${toggleValue}&sentiment_value=${sentiment_value}`;
    // console.log(initialData.agent);
    await API.get(showDownloadButton === false ? defaultUrl : filterUrl, config)
      .then((res) => {
        // setNewSentimentData(res.data.list_of_calls);

        setCallList(res.data);
        setLabel(sentiment_value);
        setDownloadPath(res.data.results.file_path);
        setCallListOpen(true);
        setLoadingForCount(false);
        // setToneDate(null);
        // setDataIndex(1);
        // setSentDate(moment(formik.values.startDate, 'YYYY-M-D').format('DD/MM/YYYY'));
        // handleDialog();
        // setLoading(false);
      })
      .catch((err) => {
        setLoadingForCount(false);
        // setLoading(false);
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };

  useEffect(() => {
    if (pageForPopup && globalToneValue) {
      if (globalToneValue === 'high' || globalToneValue === 'medium' || globalToneValue === 'low') {
        handleSummaryTonePopup(globalToneValue);
      } else if (
        globalToneValue === 'positive' ||
        globalToneValue === 'negative' ||
        globalToneValue === 'neutral'
      ) {
        handleSummarySentimentPopup(globalToneValue);
      }
    }
  }, [globalToneValue, pageForPopup]);

  const handleDialogForCalls = () => {
    setGlobalToneValue();
    setCallListOpen(!callListOpen);
    setPageForPopup();
  };

  const downloadGraphPopupDetails = async () => {
    FileSaver.saveAs(
      `${process.env.REACT_APP_SERVER_MEDIA_URL}${downloadPath}`,
      downloadPath.split('/').pop()
    );
  };

  // Pie chart Data

  const pieChartData =
    feedbackData &&
    feedbackData?.feedback_counts_summary &&
    Object.keys(feedbackData?.feedback_counts_summary).length > 0
      ? [
          { name: 'Pending', value: feedbackData.feedback_counts_summary.pending_count },
          { name: 'Acknowledge', value: feedbackData.feedback_counts_summary.acknowledge_count },
          { name: 'Calibrated', value: feedbackData.feedback_counts_summary.calibrate_count }
        ]
      : [];
  const COLORS = ['#0482D5', '#67A8D4', '#B2BFC8'];
  const pieSummary = [
    { value: 'Pending', type: 'square', color: '#0482D5' },
    { value: 'Acknowledge', type: 'square', color: '#67A8D4' },
    { value: 'Calibrated', type: 'square', color: '#B2BFC8' }
  ];

  // trend Data for Feedback

  const datesForFeedback = feedbackData
    ? feedbackData?.feedback_counts_trend?.map((el) => Object.keys(el)[0])
    : [];

  // console.log('datesForFeedback', datesForFeedback);
  function extractCounts(countType) {
    return feedbackData?.feedback_counts_trend?.map((item) => {
      const date = Object.keys(item)[0];
      const feedback = item[date];
      return feedback[countType] || 0;
    });
  }

  const tickAmount =
    feedbackData && feedbackData?.feedback_counts_trend?.length > 0
      ? Math.max(
          ...extractCounts('pending_count').concat(
            extractCounts('acknowledge_count'),
            extractCounts('calibrate_count')
          )
        )
      : 0;

  const seriesForFeedback = [
    {
      name: 'Pending',
      type: 'bar',
      data: extractCounts('pending_count')
    },
    {
      name: 'Acknowledge',
      type: 'bar',
      data: extractCounts('acknowledge_count')
    },
    {
      name: 'Calibrated',
      type: 'bar',
      data: extractCounts('calibrate_count')
    }
  ];

  const optionsForFeedback = {
    chart: {
      toolbar: {
        show: true
      },
      events: {
        // dataPointSelection: (event, chartContext, config) => {
        //   // console.log('guru', config);
        //   setToneDate(graphData[config.dataPointIndex].dates);
        //   if (setDate) {
        //     setDate(null);
        //   }
        //   setDataIndex(null);
        //   if (setDeadAirDate) {
        //     setDeadAirDate(null);
        //   }
        //   if (defaultDashboardData) {
        //     newDefaultTone(
        //       getType(config.seriesIndex),
        //       'tone',
        //       graphData[config.dataPointIndex].dates
        //     );
        //   } else {
        //     handleTonePopup(getType(config.seriesIndex), graphData[config.dataPointIndex].dates);
        //   }
        // }
      },
      type: 'bar',
      width: 700,
      height: 450
      // stacked: true

      // stackType: '100%'
    },
    colors: ['#0482D5', '#67A8D4', '#B2BFC8', 'grey'],

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40%',
        cursor: 'pointer'
        // barHeight: '20%'
      }
    },
    dataLabels: {
      enabled: false,
      // textAnchor: 'start',
      style: {
        fontSize: '11px',
        colors: ['white']
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: '#000',
        opacity: 0.45
      }

      // offsetX: 0
    },
    stroke: {
      width: [0, 0, 0, 2],
      colors: ['grey']
    },
    title: {
      text: ''
    },
    xaxis: {
      categories: datesForFeedback,
      labels: {
        formatter(val) {
          const index = val.indexOf('/');

          // If '/' is found, return the substring before it
          if (index !== -1) {
            return val.substring(0, index);
          }

          // If '/' is not found, return the entire string
          return val;
        }
      },
      title: {
        text: undefined
      }
    },
    yaxis: {
      // min: 0,
      // max: tickAmount, // Adding a buffer to the max value for better visualization
      // tickAmount: tickAmount / 5,
      title: {
        text: 'Feedback Count',
        offsetX: 0,
        offsetY: 10,
        style: {
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 650,
          cssClass: 'apexcharts-xaxis-title'
        }
      },
      labels: {
        formatter: function (value) {
          return Math.round(value);
        }
      }
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val} Calls`;
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      // onItemHover: {
      //   highlightDataSeries: false
      // },
      position: 'bottom',
      horizontalAlign: 'center',
      // offsetX: 40,
      onItemClick: {
        toggleDataSeries: ({ seriesIndex }) => console.log('seriesIndex', seriesIndex)
      }
    }
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value
  }) => {
    // console.log('indexxxx', index);
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN) + 1;
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <Tip title={`${value} feedbacks`} arrow>
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
          style={{
            // cursor: 'pointer',
            border: 'none', // Change the border style when clicked
            outline: 'none'
          }}
        >
          {percent === 0 ? null : `${(percent * 100).toFixed(1)}%`}
        </text>
      </Tip>
    );
  };

  return (
    <Page title="Dashboard">
      <Grid container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4 }}>
        <div>
          <Tabs activeKey={tabValue} defaultActiveKey="5" onSelect={(e) => setTabValue(e)}>
            <Tabs.Tab eventKey="5" title="Agent Call Insights" />
            <Tabs.Tab eventKey="1" title="Compliance Score" />

            <Tabs.Tab eventKey="2" title="Top and Bottom Performer" />

            <Tabs.Tab eventKey="3" title="Feedback" />

            {/* <Tabs.Tab eventKey="4" title="Coaching" /> */}
          </Tabs>

          {/* Compliance Dashboard */}
        </div>
        {/* <Button
          // onClick={downloadCsv}
          // disabled
          sx={{
            backgroundColor: '#3892CF',
            '&:hover': {
              backgroundColor: '#3892CF'
            }
          }}
          variant="contained"
          to="#"
          size="small"
          startIcon={<Icon icon={fileDownload} color="#ffff" width={30} height={30} />}
        >
          Download Report
        </Button> */}
      </Grid>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid
            container
            sx={{
              // marginBottom: 5,
              backgroundColor: '#f4f4f4',
              display: 'flex',
              justifyContent: 'space-between',
              padding: 1
            }}
            // spacing={2}
          >
            <Grid item xs={12} sm={12} md={2}>
              {userDetails.role !== 'Super_Admin' ? (
                <>
                  <Typography style={{ fontSize: '14px' }}>
                    <b>Select Organisation *</b>
                  </Typography>
                  <Selectbox
                    required
                    fullWidth
                    // label={userDetails.organisation_name}
                    size="small"
                    defaultValue="all"
                    disabled
                  >
                    <MenuItem value="all" disabled>
                      {userDetails.organisation_name}
                    </MenuItem>
                  </Selectbox>
                </>
              ) : (
                <>
                  {/* <FormControl fullWidth>
                    <h5>
                      <b>Select Organisation *</b>
                    </h5>
                    <Selectbox
                      required
                      // labelId="Organisation-select-label"
                      // label="Select Organisation"
                      size="small"
                      id="select-organisation"
                      // value={organisation}
                      {...getFieldProps('organisation')}
                      style={{ border: '0.8px solid #7a7872' }}
                      onChange={(e) => {
                        localStorage.removeItem('storedFormik');
                        setOrganisation(e.target.value);
                        setdefaultAgent(null);
                        setdefaultCustomer(null);
                        setdefaultProcess(null);
                        formik.setFieldValue('customer', null);
                        formik.setFieldValue('process', null);
                        formik.setFieldValue('campaign', null);
                      }}
                      error={Boolean(touched.organisation && errors.organisation)}
                      helperText={touched.organisation && errors.organisation}
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                    
                      {organisationList
                        ? organisationList.map((element) => {
                            const { organisation_id, organisation_name } = element;
                            return (
                              <MenuItem key={organisation_id} value={organisation_id}>
                                {organisation_name}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Selectbox>
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {touched.organisation && errors.organisation}
                    </FormHelperText>
                  </FormControl> */}
                  <Typography style={{ fontSize: '14px' }}>
                    <b>Select Organisation *</b>
                  </Typography>
                  <Select
                    //   onChange={chnageSelect}
                    styles={customStyles}
                    onChange={(e) => {
                      localStorage.removeItem('storedFormik');
                      setOrganisation(e);
                      setdefaultAgent([]);
                      setdefaultCustomer(null);
                      setdefaultProcess([]);
                      formik.setFieldValue('organisation', e.value);
                      formik.setFieldValue('customer', null);
                      formik.setFieldValue('process', []);
                      setCampaignId([]);
                    }}
                    options={organisationList?.map((element) => ({
                      value: element.organisation_id,
                      label: element.organisation_name
                    }))}
                    value={organisation}
                    // defaultValue={{ value: 'all', label: 'All' }}
                    style={{ color: '#000' }}
                    components={{ Control: CustomControlOrg }}
                    placeholder={
                      <div
                        style={{
                          display: 'flex',
                          columnGap: 10,
                          alignItems: 'center',
                          color: '#000',
                          fontWeight: '400'
                        }}
                      >
                        {/* <img src={CorporateFareIcon} width={20} alt="" /> */}

                        {/* Select Organisation * */}
                      </div>
                    }
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={5.7} md={2}>
              {/* <FormControl fullWidth>
                <h5>
                  <b>Select Customer *</b>
                </h5>
                <Selectbox
                  required
                  // labelId="Customer-select-label"
                  // label="Select Customer"
                  size="small"
                  id="select-customer"
                  name="customer"
                  style={{ border: '0.8px solid #7a7872' }}
                  onBlur={formik.handleBlur}
                  value={defaultCustomer}
                  error={Boolean(touched.customer && errors.customer)}
                  helperText={touched.customer && errors.customer}
                  onChange={(e) => {
                    localStorage.removeItem('storedFormik');
                    // settest1(e.target.value);
                    console.log('check');
                    setdefaultProcess(null);
                    setdefaultAgent(null);
                    formik.setFieldValue('campaign', null);
                    formik.setFieldValue('process', null);
                    setprocessList([]);
                    setCustomerValue(e.target.value);
                  }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {customerList.length !== 0 ? (
                    customerList.map((element) => {
                      const { customer_id, customer_organisation_name } = element;
                      return (
                        <MenuItem key={customer_id} value={customer_id}>
                          {customer_organisation_name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>
                      <i>No Customer Available</i>
                    </MenuItem>
                  )}
                </Selectbox>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.customer && errors.customer}
                </FormHelperText>
              </FormControl> */}
              {userDetails.role === 'QA' ||
              userDetails.role === 'Manager' ||
              userDetails.role === 'Customer_Admin' ||
              userDetails.role === 'QA_admin' ? (
                <>
                  <Typography style={{ fontSize: '14px' }}>
                    <b>Select Customer *</b>
                  </Typography>
                  <Selectbox required fullWidth size="small" defaultValue="all" disabled>
                    <MenuItem value="all" disabled>
                      {userDetails.customer_name}
                    </MenuItem>
                  </Selectbox>
                </>
              ) : (
                <>
                  <Typography style={{ fontSize: '14px' }}>
                    <b>Select Customer *</b>
                  </Typography>
                  <Select
                    //   onChange={chnageSelect}
                    styles={customStyles}
                    // defaultValue={{ value: 'all', label: 'All' }}
                    onChange={(e) => {
                      localStorage.removeItem('storedFormik');
                      // settest1(e.target.value);
                      console.log('check');
                      setdefaultProcess([]);
                      setdefaultAgent([]);
                      setAgentId([]);
                      formik.setFieldValue('process', []);
                      setprocessList([]);
                      setCustomerValue(e);
                    }}
                    options={customerList?.map((element) => ({
                      value: element.customer_id,
                      label: element.customer_organisation_name
                    }))}
                    value={defaultCustomer}
                    // defaultValue={{ value: 'all', label: 'All' }}
                    style={{ color: '#000' }}
                    components={{ Control: CustomControlCustomer }}
                    placeholder={
                      <div
                        style={{
                          display: 'flex',
                          columnGap: 10,
                          alignItems: 'center',
                          color: '#000',
                          fontWeight: '400'
                        }}
                      >
                        {/* <img src={CorporateFareIcon} width={20} alt="" /> */}

                        {/* Select Organisation * */}
                      </div>
                    }
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={5.7} md={2}>
              <Typography style={{ fontSize: '14px' }}>
                <b>Select Process *</b>
              </Typography>
              {/* <div style={{ width: '200px', maxHeight: 35 }}> */}
              <Select
                styles={customStyles}
                onChange={handleProcessChange}
                components={{ Control: CustomControlProcess }}
                isMulti
                options={
                  processList
                    ? [
                        { value: 'all', label: 'All' },
                        ...processList.map((element) => ({
                          value: element,
                          label: element
                        }))
                      ]
                    : [{ value: 'all', label: 'All' }]
                }
                value={defaultProcess}
                style={{ color: '#000' }}
                placeholder={
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      alignItems: 'center',
                      color: '#353535',
                      fontWeight: '500'
                    }}
                  >
                    {/* <img src={CorporateFareIcon} width={20} alt="" /> */}
                    {/* <SupportAgentIcon width={20} /> */}
                    {userDetails.role !== 'Super_Admin'
                      ? formik.values.customer === null || formik.values.customer === 'all'
                        ? 'All'
                        : ''
                      : formik.values.organisation === '' || formik.values.organisation === 'all'
                      ? 'All'
                      : ''}
                  </div>
                }
              />
              {/* </div> */}
            </Grid>
            {/* <Grid item xs={12} sm={5.7} md={2}>
              <h5>
                <b>Select Process *</b>
              </h5>
              <Select
                styles={customStyles}
                onChange={(e) => handleProcess(e)}
                components={{ Control: CustomControlProcess }}
                options={processList?.map((element) => ({
                  value: element,
                  label: element
                }))}
                value={defaultProcess}
                // defaultValue={{ value: 'all', label: 'All' }}
                // value={selectDoc}
                style={{ color: '#000' }}
                placeholder={
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      alignItems: 'center',
                      color: '#000',
                      fontWeight: '400'
                    }}
                  >
                  </div>
                }
              />
            </Grid> */}
            <Grid item xs={12} sm={5.7} md={2}>
              <Typography style={{ fontSize: '14px' }}>
                <b>Select Agent *</b>
              </Typography>
              <Select
                styles={customStyles}
                // onChange={(e) => {
                //   console.log('eeeeeee', e);
                //   formik.setFieldValue('campaign', e.value);
                //   setdefaultAgent(e);
                // }}
                isMulti
                onChange={handleAgentChange}
                components={{ Control: CustomControlAgent }}
                // options={campaignList?.map((element) => ({
                //   value: element.user_id,
                //   label: `${element.first_name} ${element.last_name}`
                // }))}
                options={
                  campaignList
                    ? [
                        { value: 'all', label: 'All' },
                        ...campaignList.map((element) => ({
                          value: element.user_id,
                          label: `${element.first_name} ${element.last_name}`
                        }))
                      ]
                    : [{ value: 'all', label: 'All' }]
                }
                value={defaultAgent}
                style={{ color: '#000' }}
                placeholder={
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      alignItems: 'center',
                      color: '#353535',
                      fontWeight: '500'
                    }}
                  >
                    {userDetails.role !== 'Super_Admin'
                      ? formik.values.process === null || formik.values.process === 'all'
                        ? 'All'
                        : ''
                      : formik.values.organisation === '' || formik.values.organisation === 'all'
                      ? 'All'
                      : ''}
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12} sm={5.7} md={2}>
              <Typography style={{ fontSize: '14px' }}>
                <b>Select Date *</b>
              </Typography>
              <Select
                //   onChange={chnageSelect}
                styles={customStyles}
                onChange={(e) => {
                  formik.setFieldValue('date', e.value);
                  setDefaultDate(e);
                }}
                options={dateFormat?.map((element) => ({
                  value: element.id,
                  label: element.name
                }))}
                value={defaultDate}
                // defaultValue={{ value: 'all', label: 'All' }}
                style={{ color: '#000' }}
                components={{ Control: CustomControlDate }}
                placeholder={
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      alignItems: 'center',
                      color: '#000',
                      fontWeight: '400'
                    }}
                  >
                    {/* <img src={CorporateFareIcon} width={20} alt="" /> */}

                    {/* Select Organisation * */}
                  </div>
                }
                isSearchable={false}
                isClearable={false}
              />
            </Grid>
            {formik.values.format !== 'customized' && (
              <Hidden mdDown>
                <Grid
                  item
                  xs={12}
                  sm={5.7}
                  md={0.7}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  <LoadingButton
                    loading={formik.isSubmitting}
                    type="submit"
                    className={classes.submitButton}
                    sx={{ marginTop: '15px' }}
                    size="medium"
                    variant="contained"
                    disabled={formik.values.campaign?.length === 0}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Hidden>
            )}
          </Grid>
          {formik.values.format === 'customized' && (
            <Grid container style={{ backgroundColor: '#f4f4f4' }}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 13,
                  paddingLeft: 8,
                  paddingRight: 9
                }}
              >
                <Grid item xs={12} sm={5.7} md={5.2}>
                  <h5 style={{ marginBottom: '-10px' }}>
                    <b>Start Date *</b>
                  </h5>
                  <DatePicker
                    className="my-datepicker-class"
                    selected={checkInDate}
                    onChange={handleCheckInDate}
                    // minDate={new Date().setMonth(new Date().getMonth() - 3)}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomInput />}
                    placeholderText="Start date"
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                  />
                  <FormHelperText sx={{ marginLeft: '10px', color: '#d32f2f' }}>
                    {touched.startDate && errors.startDate}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={5.7} md={5.2}>
                  <h5 style={{ marginBottom: '-10px' }}>
                    <b>End Date *</b>
                  </h5>
                  <DatePicker
                    className="my-datepicker-class"
                    selected={checkOutDate}
                    maxDate={maxDate}
                    minDate={checkInDate}
                    onChange={handleCheckOutDate}
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomInput />}
                    placeholderText="End date"
                    error={Boolean(touched.endDate && errors.endDate)}
                    helperText={touched.endDate && errors.endDate}

                    // disabled
                  />
                  <FormHelperText sx={{ marginLeft: '10px', color: '#d32f2f' }}>
                    {touched.endDate && errors.endDate}
                  </FormHelperText>
                </Grid>
                <Hidden mdDown>
                  <Grid
                    item
                    xs={12}
                    sm={5.7}
                    md={0.7}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      type="submit"
                      className={classes.submitButton}
                      // sx={{ marginBottom: '6px' }}
                      disabled={formik.values.campaign.length === 0}
                      size="medium"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          )}
          <Hidden mdUp>
            <Grid
              item
              xs={12}
              sm={5.7}
              md={0.7}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                padding: 8
              }}
            >
              <LoadingButton
                loading={formik.isSubmitting}
                type="submit"
                // onClick={(e) => getGraphData()}
                disabled={formik.values.campaign?.length === 0}
                className={classes.submitButton}
                size="medium"
                variant="contained"
              >
                Submit
              </LoadingButton>
            </Grid>
          </Hidden>
        </Form>
      </FormikProvider>

      {!loading ? (
        <Grid container style={{ marginTop: 1 }}>
          {tabValue === '1' && (
            <Grid
              container
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                // marginBottom: 7,
                padding: 3
              }}
            >
              <Grid item xs={12} sm={5.7} md={2.3} className={classes.cards}>
                <Card
                  style={{
                    height: 100,
                    boxShadow: 0,
                    textAlign: 'center',
                    // padding: '2px',
                    borderRadius: '5px'
                  }}
                >
                  <div
                    style={{
                      // borderBottom: '1px solid #f1f3f4',
                      padding: 5,
                      backgroundColor: '#3892CF'
                    }}
                  >
                    <Typography style={{ color: '#fff' }} variant="h7">
                      <b>Total Calls Processed</b>
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '70%',
                      padding: '4px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <CallIcon style={{ color: '#60AAD9' }} fontSize="large" />
                    </div>
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',

                          fontSize: '2.2rem'
                        }}
                      >
                        {salesProductInfo && salesProductInfo.total
                          ? formatNumber(salesProductInfo?.total)
                          : 0}
                      </Typography>
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',

                          fontSize: '2.2rem'
                        }}
                      >
                        <ComparativeAnalysis
                          style={{ height: '15px', width: '15px' }}
                          arrow={
                            comparativeAnalysisCards &&
                            comparativeAnalysisCards.processed_calls_actual
                          }
                          count={
                            comparativeAnalysisCards &&
                            comparativeAnalysisCards.processed_calls_improvement
                          }
                        />
                      </Typography>
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} sm={5.7} md={2.3} className={classes.cards}>
                <Card
                  sx={{
                    borderRadius: '5px',
                    height: 100,
                    textAlign: 'center',
                    '&:hover': {
                      boxShadow:
                        salesProductInfo &&
                        salesProductInfo.ai_calls > 0 &&
                        !loadingForCount &&
                        '0 0 11px rgba(33,33,33,.2)',
                      transform:
                        salesProductInfo &&
                        salesProductInfo.ai_calls > 0 &&
                        !loadingForCount &&
                        'scale3d(1.05, 1.05, 1)',
                      cursor:
                        salesProductInfo &&
                        salesProductInfo.ai_calls > 0 &&
                        !loadingForCount &&
                        'pointer'
                    }
                  }}
                  onClick={() => {
                    if (salesProductInfo && salesProductInfo.ai_calls > 0 && !loadingForCount) {
                      setPopupPage(1);
                      setScoreStatus('ai');
                      setShownComplianceName('AI Audited Calls');
                      setUpdateStatusForCardPopup(updateStatusForCardPopup + 1);
                      // setPopupDataForCompliance(getAudioList?.scored_calls);
                      // handleCompliantPopup();
                    }
                  }}
                >
                  <div
                    style={{
                      // borderBottom: '1px solid #f1f3f4',
                      padding: 5,
                      backgroundColor: '#3892CF'
                    }}
                  >
                    <Typography style={{ color: '#fff' }} variant="h7">
                      <b>AI Audited Calls</b>
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '70%',
                      padding: '4px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <CallIcon style={{ color: '#60AAD9' }} fontSize="large" />
                    </div>
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',

                          fontSize: '2.2rem'
                        }}
                      >
                        {loadingForCount ? (
                          <CircularProgress sx={{ color: '#3892CF' }} size={25} thickness={4} />
                        ) : salesProductInfo && salesProductInfo.ai_calls ? (
                          formatNumber(salesProductInfo?.ai_calls)
                        ) : (
                          0
                        )}
                      </Typography>
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',

                          fontSize: '2.2rem'
                        }}
                      >
                        <ComparativeAnalysis
                          style={{ height: '15px', width: '15px' }}
                          arrow={
                            comparativeAnalysisCards && comparativeAnalysisCards.scored_calls_actual
                          }
                          count={
                            comparativeAnalysisCards &&
                            comparativeAnalysisCards.scored_calls_improvement
                          }
                        />
                      </Typography>
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} sm={5.7} md={2.3} className={classes.cards}>
                <Card
                  sx={{
                    borderRadius: '5px',
                    height: 100,
                    textAlign: 'center',
                    '&:hover': {
                      boxShadow:
                        salesProductInfo &&
                        salesProductInfo?.manual_calls > 0 &&
                        !loadingForCount &&
                        '0 0 11px rgba(33,33,33,.2)',
                      transform:
                        salesProductInfo &&
                        salesProductInfo?.manual_calls > 0 &&
                        !loadingForCount &&
                        'scale3d(1.05, 1.05, 1)',
                      cursor:
                        salesProductInfo &&
                        salesProductInfo?.manual_calls > 0 &&
                        !loadingForCount &&
                        'pointer'
                    }
                  }}
                  onClick={() => {
                    if (
                      salesProductInfo &&
                      salesProductInfo?.manual_calls > 0 &&
                      !loadingForCount
                    ) {
                      setPopupPage(1);
                      setScoreStatus('manual');
                      setShownComplianceName('Manual Audited Calls');
                      setUpdateStatusForCardPopup(updateStatusForCardPopup + 1);
                      // setPopupDataForCompliance(getAudioList?.manual_audited_calls);
                      // handleCompliantPopup();
                    }
                  }}
                >
                  <div
                    style={{
                      // borderBottom: '1px solid #f1f3f4',
                      padding: 5,
                      backgroundColor: '#3892CF'
                    }}
                  >
                    <Typography style={{ color: '#fff' }} variant="h7">
                      <b>Manual Audited Calls</b>
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '70%',
                      padding: '4px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <CallIcon style={{ color: '#60AAD9' }} fontSize="large" />
                    </div>
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',

                          fontSize: '2.2rem'
                        }}
                      >
                        {loadingForCount ? (
                          <CircularProgress sx={{ color: '#3892CF' }} size={25} thickness={4} />
                        ) : salesProductInfo && salesProductInfo?.manual_calls ? (
                          formatNumber(salesProductInfo?.manual_calls)
                        ) : (
                          0
                        )}
                      </Typography>
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',
                          fontSize: '2.2rem'
                        }}
                      >
                        <ComparativeAnalysis
                          style={{ height: '15px', width: '15px' }}
                          arrow={
                            comparativeAnalysisCards && comparativeAnalysisCards.manual_calls_actual
                          }
                          count={
                            comparativeAnalysisCards &&
                            comparativeAnalysisCards.manual_calls_improvement
                          }
                        />
                      </Typography>
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} sm={5.7} md={2.3} className={classes.cards}>
                <Card
                  sx={{
                    borderRadius: '5px',
                    height: 100,
                    textAlign: 'center',
                    '&:hover': {
                      boxShadow:
                        salesProductInfo &&
                        salesProductInfo?.good_calls > 0 &&
                        !loadingForCount &&
                        '0 0 11px rgba(33,33,33,.2)',
                      transform:
                        salesProductInfo &&
                        salesProductInfo?.good_calls > 0 &&
                        !loadingForCount &&
                        'scale3d(1.05, 1.05, 1)',
                      cursor:
                        salesProductInfo &&
                        salesProductInfo?.good_calls > 0 &&
                        !loadingForCount &&
                        'pointer'
                    }
                  }}
                  onClick={() => {
                    if (salesProductInfo && salesProductInfo?.good_calls > 0 && !loadingForCount) {
                      setPopupPage(1);
                      setScoreStatus('good');
                      setShownComplianceName('Good Call Info');
                      setUpdateStatusForCardPopup(updateStatusForCardPopup + 1);
                      // setPopupDataForCompliance(getAudioList?.good_calls);
                      // handleCompliantPopup();
                    }
                  }}
                >
                  <div
                    style={{
                      // borderBottom: '1px solid #f1f3f4',
                      padding: 5,
                      backgroundColor: '#3892CF'
                    }}
                  >
                    <Typography style={{ color: '#fff' }} variant="h7">
                      <b>Good Calls</b>
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '70%',
                      padding: '4px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <CallIcon style={{ color: '#60AAD9' }} fontSize="large" />
                    </div>
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',

                          fontSize: '2.2rem'
                        }}
                      >
                        {loadingForCount ? (
                          <CircularProgress sx={{ color: '#3892CF' }} size={25} thickness={4} />
                        ) : salesProductInfo && salesProductInfo?.good_calls ? (
                          formatNumber(salesProductInfo?.good_calls)
                        ) : (
                          0
                        )}
                      </Typography>
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',

                          fontSize: '2.2rem'
                        }}
                      >
                        <ComparativeAnalysis
                          style={{ height: '15px', width: '15px' }}
                          arrow={
                            comparativeAnalysisCards && comparativeAnalysisCards.good_calls_actual
                          }
                          count={
                            comparativeAnalysisCards &&
                            comparativeAnalysisCards.goodcalls_count_improvement
                          }
                        />
                      </Typography>
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} sm={5.7} md={2.3} className={classes.cards}>
                <Card
                  sx={{
                    borderRadius: '5px',
                    height: 100,
                    textAlign: 'center',
                    '&:hover': {
                      boxShadow:
                        salesProductInfo &&
                        salesProductInfo?.improvement_calls > 0 &&
                        !loadingForCount &&
                        '0 0 11px rgba(33,33,33,.2)',
                      transform:
                        salesProductInfo &&
                        salesProductInfo?.improvement_calls > 0 &&
                        !loadingForCount &&
                        'scale3d(1.05, 1.05, 1)',
                      cursor:
                        salesProductInfo &&
                        salesProductInfo?.improvement_calls > 0 &&
                        !loadingForCount &&
                        'pointer'
                    }
                  }}
                  onClick={() => {
                    if (
                      salesProductInfo &&
                      salesProductInfo?.improvement_calls > 0 &&
                      !loadingForCount
                    ) {
                      setPopupPage(1);
                      setScoreStatus('bad');
                      setShownComplianceName('Improvement Calls Info');
                      setUpdateStatusForCardPopup(updateStatusForCardPopup + 1);
                      // setPopupDataForCompliance(getAudioList?.bad_calls);
                      // handleCompliantPopup();
                    }
                  }}
                >
                  <div
                    style={{
                      // borderBottom: '1px solid #f1f3f4',
                      padding: 5,
                      backgroundColor: '#3892CF'
                    }}
                  >
                    <Typography style={{ color: '#fff' }} variant="h7">
                      <b>Improvement Calls</b>
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '70%',
                      padding: '4px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <CallIcon style={{ color: '#60AAD9' }} fontSize="large" />
                    </div>
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',

                          fontSize: '2.2rem'
                        }}
                      >
                        {loadingForCount ? (
                          <CircularProgress sx={{ color: '#3892CF' }} size={25} thickness={4} />
                        ) : salesProductInfo && salesProductInfo?.improvement_calls ? (
                          formatNumber(salesProductInfo?.improvement_calls)
                        ) : (
                          0
                        )}
                      </Typography>
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',

                          fontSize: '2.2rem'
                        }}
                      >
                        <ComparativeAnalysis
                          style={{ height: '15px', width: '15px' }}
                          arrow={
                            comparativeAnalysisCards && comparativeAnalysisCards.bad_calls_actual
                          }
                          count={
                            comparativeAnalysisCards &&
                            comparativeAnalysisCards.badcalls_count_improvement
                          }
                        />
                      </Typography>
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          )}
          {/* <Grid
            container
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 10,
              padding: 8
            }}
          >
            <Grid item xs={12} sm={5.7} md={3.5} className={classes.cards}>
              <Card
                style={{
                  height: 170,
                  boxShadow: 0,
                  textAlign: 'center'
                }}
              >
                <div
                  style={{
                    // borderBottom: '1px solid #f1f3f4',
                    padding: 10,
                    backgroundColor: '#3892CF'
                  }}
                >
                  <Typography style={{ color: '#fff' }} variant="h7">
                    <b>Total Average Score</b>
                  </Typography>
                </div>
                <Grid container style={{ width: '100%', height: '100%' }}>
                  <Grid
                    item
                    xs={6}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <PercentIcon style={{ color: '#60AAD9', marginBottom: 50 }} fontSize="large" />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <Typography
                      // variant="h3"
                      style={{
                        color: '#000',
                        fontStyle: 'bold',
                        marginBottom: 50,
                        fontSize: '2.4rem'
                      }}
                    >
                      {salesProductInfo && salesProductInfo.total_avg_score
                        ? salesProductInfo?.total_avg_score
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid> */}
          <Grid
            container
            spacing={1}
            sx={{ marginTop: '2px', display: 'flex', justifyContent: 'space-between' }}
          >
            {tabValue === '1' && (
              <>
                <Grid item xs={12} md={4.2}>
                  <Card
                    sx={{
                      width: '100%',
                      height: 270
                    }}
                  >
                    {/* <div
                  style={{
                    padding: 15,
                    borderBottom: '1px solid #f1f3f4',
                    backgroundColor: '#F4F4F4'
                  }}
                >
                  <b>Scoring Percentage</b>
                </div> */}
                    <div
                      style={{
                        padding: 10,
                        borderBottom: '1px solid #f1f3f4',
                        backgroundColor: '#F4F4F4',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <b>Scoring Percentage</b>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Selectbox
                          size="small"
                          value={chartTypeForScore}
                          onChange={(e) => setChartTypeForScore(e.target.value)}
                          // MenuProps={{ classes: { paper: classes.menuPaper } }}
                        >
                          <MenuItem value="summary">Summary</MenuItem>
                          <MenuItem value="trend">Trend</MenuItem>
                        </Selectbox>
                      </div>
                    </div>

                    {/* <ResponsiveContainer width="100%" height={280}> */}
                    {chartTypeForScore === 'summary' && (
                      <Grid
                        container
                        spacing={1}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        <Grid item md={12}>
                          {overallAverageScoreSummary ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <>
                                <CircularGauge
                                  id="gauge"
                                  value={
                                    overallAverageScoreSummary
                                      ? Number(overallAverageScoreSummary.toFixed(2))
                                      : 0
                                  }
                                  subvalues={[overallAverageScoreSummary.toFixed(2)]}
                                >
                                  <Scale startValue={0} endValue={100} tickInterval={20} />
                                  <RangeContainer width={5}>
                                    <Range startValue={0} endValue={60} color="RGB(255 83 83)" />
                                    <Range startValue={60} endValue={80} color="RGB(255 250 107)" />
                                    <Range startValue={90} endValue={100} color="RGB(87 255 111)" />
                                    <Range startValue={80} endValue={90} color="RGB(40 255 111)" />
                                  </RangeContainer>
                                  <ValueIndicator color="#03a9f4" />
                                  <SubvalueIndicator type="textCloud" />
                                </CircularGauge>
                                <Typography>
                                  <b>Overall Score</b>
                                </Typography>
                              </>
                            </div>
                          ) : (
                            <div
                              style={{
                                width: '100%',
                                height: '80%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '90px'
                              }}
                            >
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available</b>
                              </span>
                            </div>
                          )}
                        </Grid>
                        {/* <Grid item md={6}>
                          {salesProductInfo && salesProductInfo.total_avg_score ? (
                            <div
                              style={{
                                height: '100%',
                                width: '50%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <>
                                <CircularGauge
                                  id="gauge"
                                  value={aiAccuracyInfo && aiAccuracyInfo.average_maximum_score}
                                  subvalues={[
                                    aiAccuracyInfo && aiAccuracyInfo.average_maximum_score
                                  ]}
                                >
                                  <Scale startValue={0} endValue={100} tickInterval={20} />
                                  <RangeContainer width={5}>
                                    <Range startValue={0} endValue={60} color="RGB(255 83 83)" />
                                    <Range startValue={60} endValue={80} color="RGB(255 250 107)" />
                                    <Range startValue={90} endValue={100} color="RGB(87 255 111)" />
                                    <Range startValue={80} endValue={90} color="RGB(40 255 111)" />
                                  </RangeContainer>
                                  <ValueIndicator color="#03a9f4" />

                                  <SubvalueIndicator type="textCloud" />
                                </CircularGauge>
                                <Typography>
                                  <b>AI Score</b>
                                </Typography>
                              </>
                            </div>
                          ) : (
                            <div
                              style={{
                                width: '100%',
                                height: '80%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available</b>
                              </span>
                            </div>
                          )}
                        </Grid>
                        <Grid item md={12}>
                          {salesProductInfo && salesProductInfo.total_avg_score ? (
                            <>
                              <CircularGauge
                                id="gauge"
                                value={aiAccuracyInfo && aiAccuracyInfo.average_score}
                                subvalues={[aiAccuracyInfo && aiAccuracyInfo.average_score]}
                              >
                                <Scale startValue={0} endValue={100} tickInterval={20} />
                                <RangeContainer width={5}>
                                  <Range startValue={0} endValue={60} color="RGB(255 83 83)" />
                                  <Range startValue={60} endValue={80} color="RGB(255 250 107)" />
                                  <Range startValue={90} endValue={100} color="RGB(87 255 111)" />
                                  <Range startValue={80} endValue={90} color="RGB(40 255 111)" />
                                </RangeContainer>
                                <ValueIndicator color="#03a9f4" />

                                <SubvalueIndicator type="textCloud" />
                              </CircularGauge>
                              <Typography>
                                <b>Manual Score</b>
                              </Typography>
                            </>
                          ) : (
                            <div
                              style={{
                                width: '100%',
                                height: '80%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available</b>
                              </span>
                            </div>
                          )}
                        </Grid> */}
                      </Grid>
                    )}
                    {chartTypeForScore === 'trend' && (
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          padding: '5px',
                          // marginTop: '20px',
                          marginRight: '20px'
                        }}
                      >
                        {overallAverageScoreSummary ? (
                          <ReactApexChart
                            type="line"
                            series={[
                              {
                                name: 'Score',
                                data: scoreValues
                              }
                            ]}
                            options={chartOptionsLineGraph}
                            height={215}
                          />
                        ) : (
                          <div
                            style={{
                              width: '100%',
                              height: '80%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    {/* </ResponsiveContainer> */}
                  </Card>
                </Grid>
                <Grid item xs={12} md={7.8} lg={7.8} sm={12}>
                  <Card
                    sx={{
                      width: '100%',
                      height: 270
                    }}
                  >
                    <div
                      style={{
                        padding: 19,
                        borderBottom: '1px solid #f1f3f4',
                        backgroundColor: '#F4F4F4',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <b>AI vs Manual Score for Manually Audited calls</b>
                    </div>

                    {/* <ResponsiveContainer width="100%" height={280}> */}

                    {salesProductInfo ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        {/* {salesProductInfo && salesProductInfo.total_avg_score ? ( */}
                        <div
                          style={{
                            height: '100%',
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <>
                            <CircularGauge
                              id="gauge"
                              value={aiAccuracyInfo && aiAccuracyInfo.average_maximum_score}
                              subvalues={[aiAccuracyInfo && aiAccuracyInfo.average_maximum_score]}
                            >
                              <Scale startValue={0} endValue={100} tickInterval={20} />
                              <RangeContainer width={5}>
                                <Range startValue={0} endValue={60} color="RGB(255 83 83)" />
                                <Range startValue={60} endValue={80} color="RGB(255 250 107)" />
                                <Range startValue={90} endValue={100} color="RGB(87 255 111)" />
                                <Range startValue={80} endValue={90} color="RGB(40 255 111)" />
                              </RangeContainer>
                              <ValueIndicator color="#03a9f4" />
                              {/* <GaugeTitle text="AI Score" verticalAlignment="bottom">
                          <Font size={15} />
                          <Margin top={5} />
                        </GaugeTitle> */}
                              <SubvalueIndicator type="textCloud" />
                              {/* <GaugeTooltip
                      enabled
                      cornerRadius={15}
                      color="#cce6ff"
                      border={{ visible: false }}
                    >
                      <Font size={20} color="#3399ff" weight={500} />
                    </GaugeTooltip> */}
                            </CircularGauge>
                            <Typography>
                              <b>AI Score</b>
                            </Typography>
                          </>
                        </div>
                        {/* ) : (
                          <div
                            style={{
                              width: '100%',
                              height: '80%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        )} */}

                        <div
                          style={{
                            height: '100%',
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          {/* {salesProductInfo && salesProductInfo.total_avg_score ? ( */}
                          <>
                            <CircularGauge
                              id="gauge"
                              value={aiAccuracyInfo && aiAccuracyInfo.average_score}
                              subvalues={[aiAccuracyInfo && aiAccuracyInfo.average_score]}
                            >
                              <Scale startValue={0} endValue={100} tickInterval={20} />
                              <RangeContainer width={5}>
                                <Range startValue={0} endValue={60} color="RGB(255 83 83)" />
                                <Range startValue={60} endValue={80} color="RGB(255 250 107)" />
                                <Range startValue={90} endValue={100} color="RGB(87 255 111)" />
                                <Range startValue={80} endValue={90} color="RGB(40 255 111)" />
                              </RangeContainer>
                              <ValueIndicator color="#03a9f4" />
                              {/* <GaugeTitle text="Manual Score" verticalAlignment="bottom">
                          <Font size={15} />
                          <Margin top={5} />
                        </GaugeTitle> */}
                              <SubvalueIndicator type="textCloud" />
                              {/* <GaugeTooltip
                      enabled
                      cornerRadius={15}
                      color="#cce6ff"
                      border={{ visible: false }}
                    >
                      <Font size={20} color="#3399ff" weight={500} />
                    </GaugeTooltip> */}
                            </CircularGauge>
                            <Typography>
                              <b>Manual Score</b>
                            </Typography>
                          </>
                          {/* ) : (
                            <div
                              style={{
                                width: '100%',
                                height: '80%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available</b>
                              </span>
                            </div>
                          )} */}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '80%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <span style={{ fontSize: 20 }}>
                          <b>No Data Available</b>
                        </span>
                      </div>
                    )}

                    {/* </ResponsiveContainer> */}
                  </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Card
                    sx={{
                      width: '100%',
                      height: 500
                    }}
                  >
                    <div
                      style={{
                        padding: 10,
                        borderBottom: '1px solid #f1f3f4',
                        backgroundColor: '#F4F4F4',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <b>Parameterwise Score</b>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {/* <FormControl className={classes.formControl}> */}
                        {chartTypeForCategory === 'trend' && (
                          <FormControl className={classes.formControl}>
                            {/* <InputLabel labelId="Mutiple-select-label">
                              Select Categories
                            </InputLabel> */}
                            <Selectbox
                              // labelId="Mutiple-select-label"
                              // label="Select Categories"
                              id="select-category"
                              multiple
                              value={selectedCategories}
                              onChange={(e) => handleCategoryChange(e)}
                              renderValue={(selectedCategories) => selectedCategories.join(', ')}
                              MenuProps={{ classes: { paper: classes.menuPaper } }}
                            >
                              {categories.length > 0 &&
                                categories.map((category, index) => (
                                  <MenuItem key={index} value={category}>
                                    {/* <ListItemIcon> */}
                                    <Checkbox checked={selectedCategories?.includes(category)} />
                                    {/* </ListItemIcon> */}
                                    <ListItemText
                                      primary={category}
                                      className={classes.listItemText}
                                      title={category}
                                    />
                                  </MenuItem>
                                ))}
                            </Selectbox>
                          </FormControl>
                        )}
                        <Selectbox
                          size="small"
                          value={chartTypeForCategory}
                          onChange={(e) => setChartTypeForCategory(e.target.value)}
                        >
                          <MenuItem value="summary">Summary</MenuItem>
                          <MenuItem value="trend">Trend</MenuItem>
                        </Selectbox>
                        {/* </FormControl> */}
                        {/* <InputLabel required labelId="Mutiple-select-label">
                      Select Categories
                    </InputLabel> */}
                      </div>
                    </div>
                    {chartTypeForCategory === 'trend' && (
                      <>
                        {agentGraphData && selectedCategories.length > 0 ? (
                          <>
                            <div
                              style={{
                                marginLeft: '0px',
                                padding: '8px',
                                borderBottom: '1px solid #f1f3f4',
                                backgroundColor: '#F4F4F4',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'Left'
                              }}
                            />

                            <Chart
                              dataSource={agentTrendData}
                              // onLegendClick={({ target: series }) => {
                              //   // console.log({ series });
                              //   setVisibleSeriesIndex(series.index);
                              //   // if (series.isVisible()) {
                              //   //   series.hide();
                              //   // } else {
                              //   //   series.show();
                              //   // }
                              // }}
                            >
                              {/* <CommonSeriesSettings argumentField="date" /> */}
                              {/* <CommonAxisSettings /> */}

                              {series1.map((s, index) => (
                                <Series
                                  argumentField="date"
                                  key={index}
                                  {...s}
                                  visible={selectedCategories.includes(s.name)}
                                />
                              ))}
                              <ArgumentAxis
                                label={{
                                  customizeText: (info) => {
                                    const index = info.valueText.indexOf('/');

                                    // If '/' is found, return the substring before it
                                    if (index !== -1) {
                                      return info.valueText.substring(0, index);
                                    }

                                    // If '/' is not found, return the entire string
                                    return info.valueText;
                                  }
                                }}
                              >
                                <Label wordWrap="none" overlappingBehavior="rotate" />
                              </ArgumentAxis>
                              <Margin bottom={10} />
                              <ValueAxis name="yAxis">
                                <Title text="Parameter Score" />
                              </ValueAxis>

                              {/* <ArgumentAxis allowDecimals={false}>
                                <Label
                                  visible
                                  render={(data) => {
                                    <text
                                      className="template-text"
                                      x="30"
                                      y="59"
                                      textAnchor="middle"
                                    >
                                      hello
                                    </text>;
                                  }}
                                />
                              </ArgumentAxis> */}
                              <ExtremeLegend visible customizeItems={hideLegendItems} />
                              {/* <Export enabled /> */}
                              <ExtremeTooltip
                                enabled
                                customizeTooltip={(pointInfo) => {
                                  return {
                                    text: `${pointInfo.seriesName}: ${pointInfo.valueText}`
                                  };
                                }}
                              />
                              {/* <ExtremeTooltip enabled /> */}
                            </Chart>
                          </>
                        ) : (
                          <div
                            style={{
                              width: '100%',
                              height: '90%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        )}
                      </>
                    )}
                    {chartTypeForCategory === 'summary' && (
                      <>
                        {agentSummaryData && agentSummaryData.length > 0 ? (
                          <ResponsiveContainer width="100%" height="85%">
                            <BarChart
                              data={agentSummaryData}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20
                              }}
                            >
                              <XAxis
                                dataKey="parameter"
                                fontFamily="Roboto, sans-serif"
                                angle={-40} // Rotate labels vertically
                                reversed
                                fontWeight="bold"
                                tick={{
                                  fontFamily: 'cursive, sans-serif',
                                  fontSize: 10,
                                  fontWeight: 'bold'
                                }}
                                tickLine={false}
                                tickFormatter={shortenLabel}
                              />
                              <YAxis
                                label={{
                                  value: 'Score Percentage',
                                  angle: -90,
                                  offset: 100, // Adjust this negative value to move the label towards the bottom
                                  position: 'insideBottom',
                                  fontWeight: 'bold',
                                  color: 'grey'
                                }}
                                domain={[0, 100]}
                                tick={{
                                  fontFamily: 'cursive, sans-serif',
                                  fontSize: 10,
                                  fontWeight: 'bold'
                                }}
                                tickLine={false}
                                fontWeight="bold"
                              />
                              <Tooltip content={CustomTooltip} />
                              <ReferenceLine y={75} label="" stroke="red" strokeDasharray="3 3" />

                              <Bar
                                // key={index}
                                barSize={agentSummaryData && agentSummaryData.length > 25 ? 20 : 25}
                                dataKey="score_percentage"
                                // onClick={(e) => handlePopupForSummary(e)}
                                stackId="a"
                                // onClick={(e) => console.log('guruu')}
                                // fill={(data) => {
                                //   console.log('guruu', data);
                                // }}
                                style={{ opacity: 0.9, borderRadius: 5 }}
                              >
                                {agentSummaryData.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={entry.score_percentage > 75 ? '#3892cf' : 'red'}
                                  />
                                ))}
                              </Bar>
                              <Legend content={<CustomLegend />} />
                            </BarChart>
                          </ResponsiveContainer>
                        ) : (
                          <div
                            style={{
                              width: '100%',
                              height: '90%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Card sx={{ height: 410 }}>
                    <>
                      <div
                        style={{
                          padding: 15,
                          borderBottom: '1px solid #f1f3f4',
                          backgroundColor: '#F4F4F4',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <b>Script Adherence Scorecard</b>
                        {downloadScriptStart ? (
                          <LoadingButton
                            loading
                            style={{
                              backgroundColor: '#fffff',
                              '&:hover': {
                                backgroundColor: '#fffff'
                              }
                            }}
                          />
                        ) : (
                          scoreCardInfo &&
                          scoreCardInfo.file_path &&
                          scoreCardInfo.count.length > 0 &&
                          !loadingForScriptScore && (
                            <IconButton
                              onClick={handleDownloadScriptCsv}
                              style={{
                                backgroundColor: '#039452'
                              }}
                            >
                              <Icon
                                icon={fileDownload}
                                color="#ffff"
                                sx={{
                                  '&:hover': {
                                    backgroundColor: '#3892CF'
                                  }
                                }}
                                width={30}
                                height={30}
                              />
                            </IconButton>
                          )
                        )}
                      </div>
                      {scoreCardInfo && scoreCardInfo.count.length > 0 && !loadingForScriptScore ? (
                        <Paper sx={{ width: '100%' }}>
                          <TableContainer sx={{ maxHeight: 340, overflow: 'auto' }}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center" style={{ fontWeight: '600' }}>
                                    Sr.No
                                  </TableCell>
                                  <TableCell
                                    style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
                                    align="center"
                                  >
                                    Script
                                  </TableCell>
                                  {/* <TableCell
                                    style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
                                    align="center"
                                  >
                                    Marked
                                  </TableCell> */}
                                  {/* <TableCell
                                    style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
                                    align="center"
                                  >
                                    Not Marked
                                  </TableCell> */}
                                  <TableCell
                                    style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
                                    align="center"
                                  >
                                    AI Percentage
                                  </TableCell>
                                  {/* <TableCell
                                style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
                                align="center"
                              >
                                AI Percentage
                              </TableCell> */}
                                  <TableCell
                                    style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
                                    align="center"
                                  >
                                    Manual Percentage
                                  </TableCell>
                                  <TableCell
                                    style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
                                    align="center"
                                  >
                                    Improvement
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {scoreCardInfo.count.map((el, index) => (
                                  <TableRow
                                    sx={{
                                      '& .MuiTableCell-root': {
                                        padding: '4px'
                                      }
                                    }}
                                  >
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        whiteSpace: 'nowrap',
                                        maxWidth: '100px', // Adjust the max width as needed
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                      }}
                                      title={el.category}
                                    >
                                      {el.category}
                                    </TableCell>
                                    {/* <TableCell align="center">{el.mark_down}</TableCell> */}
                                    {/* <TableCell align="center">{el.non_mark_down}</TableCell> */}
                                    <TableCell align="center">
                                      {el.percentage && el.percentage !== 0 ? (
                                        <CircularProgressWithLabel
                                          value={Math.ceil(el.percentage)}
                                        />
                                      ) : (
                                        0
                                      )}
                                    </TableCell>
                                    {/* <TableCell align="center">
                                  {el.percentage_ai ? (
                                    <CircularProgressWithLabel
                                      value={Math.ceil(el.percentage_ai)}
                                    />
                                  ) : (
                                    0
                                  )}
                                </TableCell> */}
                                    <TableCell align="center">
                                      {el.manual_sore ? (
                                        <CircularProgressWithLabel
                                          value={Math.ceil(el.manual_sore)}
                                        />
                                      ) : (
                                        0
                                      )}
                                    </TableCell>
                                    <TableCell align="left">
                                      <div>
                                        <ComparativeAnalysis
                                          arrow={
                                            comparativeAnalysis && comparativeAnalysis[el?.category]
                                              ? comparativeAnalysis[el.category] > 0
                                                ? 'up'
                                                : comparativeAnalysis[el.category] === 0
                                                ? 'zero'
                                                : comparativeAnalysis[el.category] < 0
                                                ? 'down'
                                                : ''
                                              : 'zero'
                                          }
                                          count={Math.ceil(
                                            comparativeAnalysis && comparativeAnalysis[el?.category]
                                              ? comparativeAnalysis[el.category].toFixed(1)
                                              : 0
                                          )}
                                          style={{ height: '15px', width: '15px' }}
                                        />
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      ) : (
                        <div
                          style={{
                            width: '100%',
                            height: '80%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          {!loadingForScriptScore ? (
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          ) : (
                            <CircularProgress sx={{ color: '#3892CF' }} />
                          )}
                        </div>
                      )}
                    </>
                  </Card>
                </Grid>
              </>
            )}
            {tabValue === '2' && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Card sx={{ height: 370, width: '100%' }}>
                    <div
                      style={{
                        padding: 15,
                        borderBottom: '1px solid #f1f3f4',
                        backgroundColor: '#F4F4F4',
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <b>Top 10 Performers</b>
                    </div>
                    {performanceData && performanceData?.data?.top_10.length > 0 ? (
                      <TableContainer sx={{ maxHeight: 300, overflowX: 'hidden' }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="left"
                                sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                              >
                                Agent Name
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                              >
                                Score (%)
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                              >
                                Improvement
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {performanceData?.data.top_10.map((el, index) => (
                              <TableRow
                                onClick={() => {
                                  handleAgentAnalysis(el);
                                  handleDialogForAgentPerformance();
                                }}
                                sx={{
                                  '& .MuiTableCell-root': {
                                    padding: '3px'
                                  },

                                  cursor: 'pointer',
                                  '&:hover': {
                                    boxShadow: '0 0 11px rgba(33,33,33,.2)',
                                    transform: 'scale3d(1.01, 1, 1)'
                                  }
                                }}
                              >
                                <TableCell
                                  align="left"
                                  sx={{
                                    height: '5px'
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start'
                                    }}
                                  >
                                    {el.agent_name}
                                  </div>
                                </TableCell>
                                <TableCell align="center" sx={{ height: '5px' }}>
                                  {Math.ceil(el.average_score)}
                                </TableCell>
                                <TableCell align="center" sx={{ height: '5px' }}>
                                  <ComparativeAnalysis
                                    style={{ height: '15px', width: '15px' }}
                                    arrow={
                                      performanceMergeData
                                        ? performanceMergeData?.top_10[index]?.improvements > 0
                                          ? 'up'
                                          : performanceMergeData?.top_10[index]?.improvements < 0
                                          ? 'down'
                                          : 'zero'
                                        : 'zero'
                                    }
                                    count={
                                      performanceMergeData
                                        ? performanceMergeData?.top_10[index]?.improvements
                                        : 0
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '80%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <span style={{ fontSize: 20 }}>
                          <b>No Data Available</b>
                        </span>
                      </div>
                    )}
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Card sx={{ height: 370, width: '100%' }}>
                    <div
                      style={{
                        padding: 15,
                        borderBottom: '1px solid #f1f3f4',
                        backgroundColor: '#F4F4F4',
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <b>Bottom 10 Performers</b>
                    </div>
                    {performanceData && performanceData?.data?.bottom_10.length > 0 ? (
                      <TableContainer sx={{ maxHeight: 300, overflowX: 'hidden' }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="left"
                                sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                              >
                                Agent Name
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                              >
                                Score (%)
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                              >
                                Improvement
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {performanceData?.data?.bottom_10.map((el, index) => (
                              <TableRow
                                sx={{
                                  '& .MuiTableCell-root': {
                                    padding: '3px'
                                  },
                                  cursor: 'pointer',
                                  '&:hover': {
                                    boxShadow: '0 0 11px rgba(33,33,33,.2)',
                                    transform: 'scale3d(1.01, 1, 1)'
                                  }
                                }}
                                onClick={() => {
                                  handleAgentAnalysis(el);
                                  handleDialogForAgentPerformance();
                                }}
                              >
                                <TableCell align="left">
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start'
                                    }}
                                  >
                                    {el.agent_name}
                                  </div>
                                </TableCell>
                                <TableCell align="center">{Math.ceil(el.average_score)}</TableCell>
                                <TableCell align="center">
                                  <ComparativeAnalysis
                                    style={{ height: '15px', width: '15px' }}
                                    arrow={
                                      performanceMergeData
                                        ? performanceMergeData?.bottom_10[index]?.improvements > 0
                                          ? 'up'
                                          : performanceMergeData?.bottom_10[index]?.improvements < 0
                                          ? 'down'
                                          : 'zero'
                                        : 'zero'
                                    }
                                    count={
                                      performanceMergeData
                                        ? performanceMergeData?.bottom_10[index]?.improvements
                                        : 0
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '80%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <span style={{ fontSize: 20 }}>
                          <b>No Data Available</b>
                        </span>
                      </div>
                    )}
                  </Card>
                </Grid>
              </Grid>
            )}
            {tabValue === '1' && (
              <Grid item xs={12} md={12} style={{ paddingTop: 20 }}>
                <Card
                  sx={{
                    width: '100%',
                    height: 625
                  }}
                >
                  <div
                    style={{
                      padding: 15,
                      borderBottom: '1px solid #f1f3f4',
                      backgroundColor: '#F4F4F4',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <b>Score Card</b>
                    {!scoreCardLoading && (
                      <TextField
                        variant="outlined"
                        sx={{
                          '& fieldset': { border: 'none' },
                          borderRadius: 2
                        }}
                        name="search"
                        // label="Search"
                        placeholder="Search with Interaction Id ....."
                        size="small"
                        value={searchQueryList}
                        onChange={(e) => {
                          setSearchQueryList(e.target.value);
                          setMaxRecordCount(0);
                          setPageCount(1);
                        }}
                        style={{
                          width: '80%',
                          backgroundColor: '#fff',
                          paddingTop: 5,
                          paddingBottom: 5,
                          paddingRight: 15
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                variant="contained"
                                startIcon={<SearchOutlinedIcon />}
                                disabled={!searchQueryList && searchQueryList === ''}
                                onClick={handleSearch}
                                size="small"
                                sx={{
                                  backgroundColor: '#3892CF',
                                  '&:hover': {
                                    backgroundColor: '#3892CF'
                                  }
                                }}
                              >
                                Search
                              </Button>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}

                    {downloadStart ? (
                      <LoadingButton
                        loading
                        style={{
                          backgroundColor: '#fffff',
                          '&:hover': {
                            backgroundColor: '#fffff'
                          }
                        }}
                      />
                    ) : (
                      // <IconButton
                      //   onClick={() => {
                      //     if (showDownloadButton === false) {
                      //       handleDefaultDownloadCsv();
                      //     } else if (showDownloadButton === true) {
                      //       handleFilterDownloadCsv();
                      //     }
                      //   }}
                      //   // onClick={handleDownloadCSVFromUI}
                      //   style={{
                      //     backgroundColor: '#039452'
                      //   }}
                      // >
                      //   <Icon icon={fileDownload} color="#ffff" width={30} height={30} />
                      // </IconButton>
                      <>
                        {overallScoreInfo && overallScoreInfo.length > 0 && !scoreCardLoading ? (
                          <IconButton
                            ref={filterref}
                            title={
                              defaultDate.value === 'six_month' || defaultDate.value === 'one_year'
                                ? 'Select Three or less than three months date'
                                : 'Download'
                            }
                            disabled={
                              defaultDate.value === 'six_month' || defaultDate.value === 'one_year'
                            }
                            onClick={() => {
                              setIsOpen(!isOpen);
                            }}
                          >
                            <Icon icon={moreVerticalFill} width={20} height={20} />
                          </IconButton>
                        ) : null}
                        {/* <FilterAltIcon
                        ref={filterref}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        fontSize="small"
                        onClick={() => setIsOpen(!isOpen)}
                      /> */}
                        <Menu
                          open={isOpen}
                          anchorEl={filterref.current}
                          onClose={() => setIsOpen(false)}
                          PaperProps={{
                            sx: { width: 150, maxWidth: '100%' }
                          }}
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                          <MenuItem
                            value="both"
                            // selected={playbackSpeed === 'both'}
                            onClick={() => {
                              if (showDownloadButton === false) {
                                handleDefaultDownloadCsv('both ');
                              } else if (showDownloadButton === true) {
                                handleFilterDownloadCsv('both');
                              }
                              setIsOpen(false);
                            }}
                          >
                            CSV for Both
                          </MenuItem>
                          <MenuItem
                            value="ai"
                            // selected={playbackSpeed === 'ai'}
                            onClick={() => {
                              if (showDownloadButton === false) {
                                handleDefaultDownloadCsv('ai');
                              } else if (showDownloadButton === true) {
                                handleFilterDownloadCsv('ai');
                              }
                              setIsOpen(false);
                            }}
                          >
                            CSV for AI
                          </MenuItem>
                          <MenuItem
                            value="manual"
                            onClick={() => {
                              if (showDownloadButton === false) {
                                handleDefaultDownloadCsv('manual');
                              } else if (showDownloadButton === true) {
                                handleFilterDownloadCsv('manual');
                              }
                              setIsOpen(false);
                            }}
                          >
                            CSV for Manual
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </div>
                  <>
                    {overallScoreInfo && overallScoreInfo.length > 0 ? (
                      <>
                        {scoreCardLoading ? (
                          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 27 }}>
                            <CircularProgress sx={{ color: '#3892CF' }} />
                          </Box>
                        ) : (
                          <div>
                            <TableContainer sx={{ maxHeight: 470, minWidth: 1000 }}>
                              <Table stickyHeader aria-label="sticky table">
                                <>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          whiteSpace: 'nowrap',
                                          maxWidth: '130px',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          fontWeight: '600',
                                          backgroundColor: '#f8f6fa',
                                          zIndex: 1,
                                          position: 'sticky',
                                          left: 0 // Adjust the value based on your layout
                                        }}
                                        align="center"
                                      >
                                        Sr.No
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          whiteSpace: 'nowrap',
                                          maxWidth: '130px',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          fontWeight: '600',
                                          backgroundColor: '#f8f6fa',
                                          zIndex: 1,
                                          position: 'sticky',
                                          left: 3 // Adjust the value based on your layout
                                        }}
                                        align="center"
                                      >
                                        Call Track ID
                                      </TableCell>
                                      {process.env.REACT_APP_TO_SHOW_QRC === 'true'
                                        ? QRCParameters.map((el) => (
                                            <TableCell
                                              style={{
                                                fontWeight: '600',
                                                backgroundColor: '#f8f6fa',
                                                whiteSpace: 'nowrap',
                                                zIndex: 0
                                              }}
                                              align="center"
                                            >
                                              {el.length > 20 ? (
                                                <Tip title={el} followCursor placement="top">
                                                  <p style={{ fontWeight: '600' }}>
                                                    {el.slice(0, 20)}...
                                                  </p>
                                                </Tip>
                                              ) : (
                                                el
                                              )}
                                            </TableCell>
                                          ))
                                        : scoreCardParameters.map((el) => (
                                            <TableCell
                                              style={{
                                                fontWeight: '600',
                                                backgroundColor: '#f8f6fa',
                                                whiteSpace: 'nowrap',
                                                zIndex: 0
                                              }}
                                              align="center"
                                            >
                                              {el.length > 20 ? (
                                                <Tip title={el} followCursor placement="top">
                                                  <p style={{ fontWeight: '600' }}>
                                                    {el.slice(0, 20)}...
                                                  </p>
                                                </Tip>
                                              ) : (
                                                el
                                              )}
                                            </TableCell>
                                          ))}
                                      {Array.from(
                                        new Set(
                                          overallScoreInfo.flatMap((obj) =>
                                            Object.keys(obj.parameter_score)
                                          )
                                        )
                                      ).map((el) => (
                                        <TableCell
                                          style={{
                                            fontWeight: '600',
                                            backgroundColor: '#f8f6fa',
                                            whiteSpace: 'nowrap',
                                            zIndex: 0
                                          }}
                                          align="center"
                                        >
                                          {el.length > 20 ? (
                                            <Tip title={el} followCursor placement="top">
                                              <p style={{ fontWeight: '600' }}>
                                                {el.slice(0, 20)}...
                                              </p>
                                            </Tip>
                                          ) : (
                                            el
                                          )}
                                        </TableCell>
                                      ))}
                                      <TableCell
                                        style={{
                                          fontWeight: '600',
                                          backgroundColor: '#f8f6fa',
                                          whiteSpace: 'nowrap',
                                          zIndex: 0
                                        }}
                                        align="center"
                                      >
                                        Reason
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: '600',
                                          backgroundColor: '#f8f6fa',
                                          whiteSpace: 'nowrap',
                                          zIndex: 0
                                        }}
                                        align="center"
                                      >
                                        Call Status
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: '600',
                                          backgroundColor: '#f8f6fa',
                                          whiteSpace: 'nowrap',
                                          zIndex: 0
                                        }}
                                        align="center"
                                      >
                                        AI Average Score
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: '600',
                                          backgroundColor: '#f8f6fa',
                                          whiteSpace: 'nowrap',
                                          zIndex: 0
                                        }}
                                        align="center"
                                      >
                                        Manual Average Score
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: '600',
                                          backgroundColor: '#f8f6fa',
                                          whiteSpace: 'nowrap',
                                          zIndex: 0
                                        }}
                                        align="center"
                                      >
                                        Agent Tone
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: '600',
                                          backgroundColor: '#f8f6fa',
                                          whiteSpace: 'nowrap',
                                          zIndex: 0
                                        }}
                                        align="center"
                                      >
                                        Customer Tone
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: '600',
                                          backgroundColor: '#f8f6fa',
                                          whiteSpace: 'nowrap',
                                          zIndex: 0
                                        }}
                                        align="center"
                                      >
                                        Agent Sentiment
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: '600',
                                          backgroundColor: '#f8f6fa',
                                          whiteSpace: 'nowrap',
                                          zIndex: 0
                                        }}
                                        align="center"
                                      >
                                        Customer Sentiment
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: '600',
                                          backgroundColor: '#f8f6fa',
                                          whiteSpace: 'nowrap',
                                          zIndex: 0
                                        }}
                                        align="center"
                                      >
                                        <TableRow sx={{ height: '10px' }}>
                                          <TableCell
                                            align="center"
                                            sx={{
                                              backgroundColor: '#f8f6fa',
                                              fontWeight: '600',
                                              height: '10px',
                                              border: 'none'
                                            }}
                                          >
                                            Agent Tone
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              backgroundColor: '#f8f6fa',
                                              height: '10px',
                                              border: 'none'
                                            }}
                                          >
                                            <TableCell
                                              sx={{
                                                backgroundColor: '#f8f6fa',
                                                height: '10px',
                                                border: 'none',
                                                fontWeight: '600'
                                              }}
                                            >
                                              High
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                backgroundColor: '#f8f6fa',
                                                height: '10px',
                                                border: 'none',
                                                fontWeight: '600'
                                              }}
                                            >
                                              Medium
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                backgroundColor: '#f8f6fa',
                                                height: '10px',
                                                border: 'none',
                                                fontWeight: '600'
                                              }}
                                            >
                                              Low
                                            </TableCell>
                                          </TableCell>
                                        </TableRow>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: '600',
                                          backgroundColor: '#f8f6fa',
                                          whiteSpace: 'nowrap',
                                          zIndex: 0
                                        }}
                                        align="center"
                                      >
                                        <TableRow sx={{ height: '10px' }}>
                                          <TableCell
                                            align="center"
                                            sx={{
                                              backgroundColor: '#f8f6fa',
                                              fontWeight: '600',
                                              height: '10px',
                                              border: 'none'
                                            }}
                                          >
                                            Customer Tone
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              backgroundColor: '#f8f6fa',
                                              height: '10px',
                                              border: 'none'
                                            }}
                                          >
                                            <TableCell
                                              sx={{
                                                backgroundColor: '#f8f6fa',
                                                height: '10px',
                                                border: 'none',
                                                fontWeight: '600'
                                              }}
                                            >
                                              High
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                backgroundColor: '#f8f6fa',
                                                height: '10px',
                                                border: 'none',
                                                fontWeight: '600'
                                              }}
                                            >
                                              Medium
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                backgroundColor: '#f8f6fa',
                                                height: '10px',
                                                border: 'none',
                                                fontWeight: '600'
                                              }}
                                            >
                                              Low
                                            </TableCell>
                                          </TableCell>
                                        </TableRow>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: '600',
                                          backgroundColor: '#f8f6fa',
                                          whiteSpace: 'nowrap',
                                          zIndex: 0
                                        }}
                                        align="center"
                                      >
                                        <TableRow sx={{ height: '10px' }}>
                                          <TableCell
                                            align="center"
                                            sx={{
                                              backgroundColor: '#f8f6fa',
                                              fontWeight: '600',
                                              height: '10px',
                                              border: 'none'
                                            }}
                                          >
                                            Agent Sentiment
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              backgroundColor: '#f8f6fa',
                                              height: '10px',
                                              border: 'none'
                                            }}
                                          >
                                            <TableCell
                                              sx={{
                                                backgroundColor: '#f8f6fa',
                                                height: '10px',
                                                border: 'none',
                                                fontWeight: '600'
                                              }}
                                            >
                                              Positive
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                backgroundColor: '#f8f6fa',
                                                height: '10px',
                                                border: 'none',
                                                fontWeight: '600'
                                              }}
                                            >
                                              Negative
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                backgroundColor: '#f8f6fa',
                                                height: '10px',
                                                border: 'none',
                                                fontWeight: '600'
                                              }}
                                            >
                                              Neutral
                                            </TableCell>
                                          </TableCell>
                                        </TableRow>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: '600',
                                          backgroundColor: '#f8f6fa',
                                          whiteSpace: 'nowrap',
                                          zIndex: 0
                                        }}
                                        align="center"
                                      >
                                        <TableRow sx={{ height: '10px' }}>
                                          <TableCell
                                            align="center"
                                            sx={{
                                              backgroundColor: '#f8f6fa',
                                              fontWeight: '600',
                                              height: '10px',
                                              border: 'none'
                                            }}
                                          >
                                            Customer Sentiment
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              backgroundColor: '#f8f6fa',
                                              height: '10px',
                                              border: 'none'
                                            }}
                                          >
                                            <TableCell
                                              sx={{
                                                backgroundColor: '#f8f6fa',
                                                height: '10px',
                                                border: 'none',
                                                fontWeight: '600'
                                              }}
                                            >
                                              Positive
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                backgroundColor: '#f8f6fa',
                                                height: '10px',
                                                border: 'none',
                                                fontWeight: '600'
                                              }}
                                            >
                                              Negative
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                backgroundColor: '#f8f6fa',
                                                height: '10px',
                                                border: 'none',
                                                fontWeight: '600'
                                              }}
                                            >
                                              Neutral
                                            </TableCell>
                                          </TableCell>
                                        </TableRow>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {overallScoreInfo.map((el, index) => {
                                      console.log('checkel', el);
                                      return (
                                        <TableRow
                                          key={index}
                                          sx={{
                                            '& .MuiTableCell-root': {
                                              padding: '8px'
                                            }
                                          }}
                                        >
                                          <TableCell
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '130px',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              backgroundColor: '#f8f6fa',
                                              // zIndex: 1,
                                              position: 'sticky',
                                              left: 0 // Adjust the value based on your layout
                                            }}
                                          >
                                            {index + 1}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '400px',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              backgroundColor: '#f8f6fa',
                                              // zIndex: 1,
                                              position: 'sticky',
                                              left: 4 // Adjust the value based on your layout
                                            }}
                                            title={el.call_track_id}
                                          >
                                            <Link
                                              underline="hover"
                                              sx={{ cursor: 'pointer', color: '#3892CF' }}
                                              onClick={() => {
                                                navigate('/dashboard/workspace/Upload/Results', {
                                                  state: {
                                                    path: '/dashboard/app/compliance',
                                                    HitAll,
                                                    feedbackData,
                                                    scoreTrendData,
                                                    storeTabValue: tabValue,
                                                    storedImprovementData: performanceMergeData,
                                                    storedPerformanceData: performanceData,
                                                    storedAccuracyInfo: aiAccuracyInfo,
                                                    storedSelectedCategories: selectedCategories,
                                                    storedAgentTrendData: agentGraphData,
                                                    storedAgentSummaryData: agentSummaryData,
                                                    toneData: toneData,
                                                    sentimentData: sentimentData,
                                                    storedComparativeInfo: comparativeAnalysisCards,
                                                    storedAgentGraphData: agentGraphData,
                                                    dateFormat: formik.values.format,
                                                    startDate: formik.values.startDate,
                                                    endDate: formik.values.endDate,
                                                    storedCardInfo: scoreCardInfo,
                                                    storedOpportunityInfo: opportunityInfo,
                                                    storedTrendChartDetails: trendChartDetails,
                                                    storedDealStatus: dealStatus,
                                                    storedSalesProductInfo: salesProductInfo,
                                                    // storedGetAudioList: getAudioList,
                                                    storedOverallScoreInfo: overallScoreInfo,
                                                    defaultDownload: showDownloadButton,
                                                    newValues: {
                                                      newOrganisation: organisation,
                                                      newCustomer: defaultCustomer,
                                                      newProcess: defaultProcess,
                                                      newAgent: defaultAgent,
                                                      newDate: defaultDate,
                                                      agentIds: formik.values.campaign
                                                    }
                                                  }
                                                });
                                                setinputId(el.input_id);
                                                setinputOrgId(el.organisation_id);
                                                setrequestId(el.request_id);
                                                setUploadedAudio(el.interaction_id);
                                                setAudioS3(el.interaction_id);
                                                setProcess(); // You should set the process here if needed
                                                setcustomerId(el.customer_id);
                                                setOriginalDate(formatDate(el.Call_Date));
                                                setAgentName(el.agent_name);
                                                setAgentLastName('');
                                                setAgentId(el.agent_id);
                                              }}
                                            >
                                              <Typography sx={{ fontWeight: 600 }}>
                                                {el.call_track_id ? el.call_track_id : '-'}
                                              </Typography>
                                            </Link>
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '130px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.interaction_id}
                                          >
                                            {el.interaction_id ? el.interaction_id : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.empcode}
                                          >
                                            {el.empcode ? el.empcode : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.agent_name}
                                          >
                                            {el.agent_name ? el.agent_name : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.tl_name}
                                          >
                                            {el.tl_name ? el.tl_name : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.qa_name}
                                          >
                                            {el.qa_name ? el.qa_name : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.date_of_joining}
                                          >
                                            {el.date_of_joining ? el.date_of_joining : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.Call_Duration}
                                          >
                                            {el.Call_Duration ? el.Call_Duration : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '130px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.tenure}
                                          >
                                            {el.tenure ? el.tenure : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '130px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.bucket_month}
                                          >
                                            {el.bucket_month ? el.bucket_month : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.language}
                                          >
                                            {el.language ? el.language : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.Call_Date}
                                          >
                                            {el.Call_Date ? formatDate(el.Call_Date) : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={
                                              el.eval_date
                                                ? moment(el.eval_date, 'YYYY-MM-DD').format(
                                                    'DD-MM-YYYY'
                                                  )
                                                : '-'
                                            }
                                          >
                                            {el.eval_date
                                              ? moment(el.eval_date, 'YYYY-MM-DD').format(
                                                  'DD-MM-YYYY'
                                                )
                                              : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.week}
                                          >
                                            {el.week ? el.week : '-'}
                                          </TableCell>
                                          {process.env.REACT_APP_TO_SHOW_QRC === 'true' && (
                                            <TableCell
                                              align="center"
                                              style={{
                                                whiteSpace: 'nowrap',
                                                maxWidth: '120px', // Adjust the max width as needed
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis' // Add ellipsis for truncation
                                              }}
                                              title={
                                                el.test_qrc_type &&
                                                el.test_qrc_type.length > 0 &&
                                                el.test_qrc_type.join(',')
                                              }
                                            >
                                              {el.test_qrc_type && el.test_qrc_type.length > 0
                                                ? el.test_qrc_type.join(',')
                                                : '-'}
                                            </TableCell>
                                          )}
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.attribute}
                                          >
                                            {el.attribute ? el.attribute : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.sub_attribute}
                                          >
                                            {el.sub_attribute ? el.sub_attribute : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={
                                              el.test_genesys_disposition &&
                                              el.test_genesys_disposition.length > 0 &&
                                              el.test_genesys_disposition.join(',')
                                            }
                                          >
                                            {el.test_genesys_disposition &&
                                            el.test_genesys_disposition.length > 0
                                              ? el.test_genesys_disposition.join(',')
                                              : '-'}
                                          </TableCell>
                                          {Array.from(
                                            new Set(
                                              overallScoreInfo.flatMap((obj) =>
                                                Object.keys(obj.parameter_score)
                                              )
                                            )
                                          ).map((parameter, index) => {
                                            return (
                                              <TableCell
                                                align="center"
                                                style={{
                                                  whiteSpace: 'nowrap',
                                                  maxWidth: '120px', // Adjust the max width as needed
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis' // Add ellipsis for truncation
                                                }}
                                                title={el.parameter_score[parameter]}
                                              >
                                                {el.parameter_score[parameter] &&
                                                el.parameter_score[parameter] !== undefined
                                                  ? el.parameter_score[parameter]
                                                  : '-'}
                                              </TableCell>
                                            );
                                          })}
                                          {/* <TableCell
                                          align="center"
                                          style={{
                                            whiteSpace: 'nowrap',
                                            maxWidth: '120px', // Adjust the max width as needed
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis' // Add ellipsis for truncation
                                          }}
                                          title={el['Call Opening Script']}
                                        >
                                          {el['Call Opening Script'] !== null &&
                                          el['Call Opening Script'] !== undefined
                                            ? el['Call Opening Script']
                                            : '-'}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{
                                            whiteSpace: 'nowrap',
                                            maxWidth: '120px', // Adjust the max width as needed
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis' // Add ellipsis for truncation
                                          }}
                                          title={el['Introduction of Company']}
                                        >
                                          {el['Introduction of Company']
                                            ? el['Introduction of Company']
                                            : '-'}
                                        </TableCell>
                                        <TableCell align="center">
                                          {el['Identification of Right Party Contact (RPC)']
                                            ? el['Identification of Right Party Contact (RPC)']
                                            : '-'}
                                        </TableCell>
                                        <TableCell align="center">
                                          {el['Reaching RPC'] ? el['Reaching RPC'] : '-'}
                                        </TableCell>
                                        <TableCell align="center">
                                          {el['Purpose of Call'] ? el['Purpose of Call'] : '-'}
                                        </TableCell>
                                        <TableCell align="center">
                                          {el['Comprehending the Customer']
                                            ? el['Comprehending the Customer']
                                            : '-'}
                                        </TableCell>
                                        <TableCell align="center">
                                          {el['Effective Probing'] ? el['Effective Probing'] : '-'}
                                        </TableCell>
                                        <TableCell align="center">
                                          {el['Creating Urgency'] ? el['Creating Urgency'] : '-'}
                                        </TableCell>
                                        <TableCell align="center">
                                          {el['Objection Handling']
                                            ? el['Objection Handling']
                                            : '-'}
                                        </TableCell>
                                        <TableCell align="center">
                                          {el['Script Adherence'] ? el['Script Adherence'] : '-'}
                                        </TableCell>
                                        <TableCell align="center">
                                          {el['Call Control'] ? el['Call Control'] : '-'}
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell> */}
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.reason}
                                          >
                                            {el.reason ? el.reason : '-'}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              maxWidth: '120px', // Adjust the max width as needed
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                                            }}
                                            title={el.call_status}
                                          >
                                            {el.call_status ? el.call_status : '-'}
                                          </TableCell>
                                          {/* <TableCell align="center">
                                          {el.average_score ? el.average_score : '-'}
                                        </TableCell> */}
                                          <TableCell align="center">
                                            {el.ai_score ? el.ai_score : '-'}
                                          </TableCell>
                                          <TableCell align="center">
                                            {el.manual_score ? el.manual_score : '-'}
                                          </TableCell>
                                          <TableCell align="center">
                                            {el.agent_tone ? el.agent_tone : '-'}
                                          </TableCell>
                                          <TableCell align="center">
                                            {el.customer_tone ? el.customer_tone : '-'}
                                          </TableCell>
                                          <TableCell align="center">
                                            {el.agent_sentiment ? el.agent_sentiment : '-'}
                                          </TableCell>
                                          <TableCell align="center">
                                            {el.customer_sentiment ? el.customer_sentiment : '-'}
                                          </TableCell>
                                          <TableCell align="center">
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-evenly',
                                                alignItems: 'center'
                                              }}
                                            >
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  border: 'none'
                                                }}
                                              >
                                                {el.agent_high_tone}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  border: 'none'
                                                }}
                                              >
                                                {el.agent_medium_tone}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  border: 'none'
                                                }}
                                              >
                                                {el.agent_low_tone}
                                              </TableCell>
                                            </div>
                                          </TableCell>
                                          <TableCell align="center">
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-evenly'
                                              }}
                                            >
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  border: 'none'
                                                }}
                                              >
                                                {el.customert_high_tone}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  border: 'none'
                                                }}
                                              >
                                                {el.customer_medium_tone}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  border: 'none'
                                                }}
                                              >
                                                {el.customer_low_tone}
                                              </TableCell>
                                            </div>
                                          </TableCell>
                                          <TableCell align="center">
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between'
                                              }}
                                            >
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  border: 'none'
                                                }}
                                              >
                                                {el.agent_positive_sentiment}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  border: 'none'
                                                }}
                                              >
                                                {el.agent_negative_sentiment}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  border: 'none'
                                                }}
                                              >
                                                {el.agent_neutral_sentiment}
                                              </TableCell>
                                            </div>
                                          </TableCell>
                                          <TableCell align="center">
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between'
                                              }}
                                            >
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  border: 'none'
                                                }}
                                              >
                                                {el.customer_positive_sentiment}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  border: 'none'
                                                }}
                                              >
                                                {el.customer_negative_sentiment}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  border: 'none'
                                                }}
                                              >
                                                {el.customer_neutral_sentiment}
                                              </TableCell>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </>
                              </Table>
                            </TableContainer>
                            <Stack
                              spacing={2}
                              sx={{
                                padding: 2,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'end',
                                alignItems: 'center'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <TextField
                                  type="number"
                                  placeholder="Search Page"
                                  size="small"
                                  color="info"
                                  value={pageValue}
                                  onChange={(e) => setPageValue(e.target.value)}
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                      if (Number(pageValue) <= Math.ceil(countForPages / 10)) {
                                        setSearchQueryList('');
                                        setScoreCardPage(Number(pageValue));
                                        setPageValue();
                                      }
                                    }
                                  }}
                                  sx={{
                                    width: 120
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          edge="end"
                                          size="small"
                                          onClick={() => {
                                            if (
                                              Number(pageValue) <= Math.ceil(countForPages / 10)
                                            ) {
                                              setSearchQueryList('');
                                              setScoreCardPage(Number(pageValue));
                                              setPageValue();
                                            }
                                          }}
                                          sx={{
                                            '&:hover': {
                                              backgroundColor: 'lightblue'
                                            },
                                            borderRadius: '10px'
                                          }}
                                        >
                                          <SearchOutlinedIcon style={{ color: '#3892CF' }} />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                                <Pagination
                                  classes={{
                                    root: classes.ul
                                  }}
                                  count={Math.ceil(countForPages / 10)}
                                  page={scoreCardPage}
                                  onChange={handleChangePageForScorecard}
                                  showFirstButton
                                  showLastButton
                                />
                              </div>
                            </Stack>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {scoreCardLoading ? (
                          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 27 }}>
                            <CircularProgress sx={{ color: '#3892CF' }} />
                          </Box>
                        ) : (
                          <div
                            style={{
                              width: '100%',
                              height: '90%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </>
                </Card>
              </Grid>
            )}
            {tabValue === '4' && (
              <Grid item xs={12} md={4.8}>
                <Card
                  sx={{
                    height: 400,
                    marginTop: '20px'
                  }}
                >
                  <div style={{ height: '100%' }}>
                    <div
                      style={{
                        padding: 15,
                        borderBottom: '1px solid #f1f3f4',
                        backgroundColor: '#F4F4F4'
                      }}
                    >
                      <b>Goal status</b>
                    </div>
                    {dealStatus !== null ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <ReactApexChart
                          options={pieOptions}
                          series={pieSeries}
                          type="pie"
                          width={380}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '80%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <span style={{ fontSize: 20 }}>
                          <b>No Data Available</b>
                        </span>
                      </div>
                    )}
                  </div>
                </Card>
              </Grid>
            )}
            {tabValue === '4' && (
              <Grid item xs={12} md={7}>
                <Card
                  sx={{
                    // width: '500px',
                    marginTop: '20px',
                    height: 400
                    // width: 600
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: 15,
                      borderBottom: '1px solid #f1f3f4',
                      backgroundColor: '#F4F4F4'
                    }}
                  >
                    <b>Coaching Goals</b>

                    <Button
                      variant="contained"
                      startIcon={<Icon icon={plusFill} />}
                      onClick={handleDialog}
                      size="small"
                      sx={{
                        backgroundColor: '#3892CF',
                        '&:hover': {
                          backgroundColor: '#3892CF'
                        }
                      }}
                      disabled={showDownloadButton === false}
                    >
                      Add Goal
                    </Button>
                  </div>
                  <div
                    style={{
                      height: '100%'
                    }}
                  >
                    {opportunityInfo?.length > 0 ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'

                          // padding: '7px 33px',
                          // marginTop: -15
                        }}
                      >
                        <Paper sx={{ width: '100%' }}>
                          <TableContainer sx={{ maxHeight: 295, overflowY: 'auto' }}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ fontWeight: '600' }}>Goal</TableCell>
                                  <TableCell style={{ fontWeight: '600' }}>Date</TableCell>
                                  <TableCell style={{ fontWeight: '600' }}>Status</TableCell>
                                  <TableCell style={{ fontWeight: '600' }}>Comment</TableCell>
                                  <TableCell align="center" style={{ fontWeight: '600' }}>
                                    Aging
                                  </TableCell>
                                  <TableCell style={{ fontWeight: '600' }}> </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {opportunityInfo
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((res) => {
                                    return (
                                      <TableRow>
                                        <TableCell>
                                          {res.imporvement_desc.length > 15 ? (
                                            <Tip title={res.imporvement_desc} placement="top">
                                              <div>{res.imporvement_desc.slice(0, 15)}...</div>
                                            </Tip>
                                          ) : (
                                            <div>{res.imporvement_desc}</div>
                                          )}
                                        </TableCell>

                                        <TableCell>
                                          {moment(
                                            changedDate(res.created_date),
                                            'DD-MM-YYYY'
                                          ).format('DD/MM/YYYY')}
                                        </TableCell>
                                        <TableCell>{res.opportunity_status}</TableCell>
                                        <TableCell>
                                          {res.comment_desc.length > 10 ? (
                                            <Tip title={res.comment_desc} placement="top">
                                              <div>{res.comment_desc.slice(0, 10)}...</div>
                                            </Tip>
                                          ) : (
                                            <div>{res.comment_desc}</div>
                                          )}
                                        </TableCell>
                                        <TableCell align="center">
                                          {res.ageing === null
                                            ? '-'
                                            : `${formatTime(res.ageing)} ago`}
                                        </TableCell>
                                        <TableCell align="right">
                                          <UserMoreMenu
                                            formik={formik}
                                            isUpdated={update}
                                            updateComponent={setUpdate}
                                            setAlert={setAlert}
                                            setAlertContent={setAlertContent}
                                            setAlertType={setAlertType}
                                            // opportunityId={compliance_id}
                                            Details={res}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={opportunityInfo.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Paper>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '80%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <span style={{ fontSize: 20 }}>
                          <b>No Data Available</b>
                        </span>
                      </div>
                    )}
                  </div>
                </Card>
              </Grid>
            )}
            {tabValue === '5' && (
              <Grid item xs={12} md={6}>
                <StackBar
                  // downloadChart={downloadChart}
                  graphData={toneData && toneData}
                  // handleDialog={handleDialog}
                  // setDate={setDate}
                  // handleTonePopup={handleTonePopup}
                  setPageForPopup={setPageForPopup}
                  // newDefaultTone={getNewDefaultDashboard}
                  // setDeadAirDate={setDeadAirDate}
                  // filteredTone={filteredTone}
                  // toneData={toneData}
                  // defaultDashboardData={defaultDashboardData}
                  // handleSummaryTonePopup={!pageForPopup ? handleSummaryTonePopup : null}
                />
              </Grid>
            )}
            {tabValue === '5' && (
              <Grid item xs={12} md={6}>
                <SentimentGraph
                  // downloadChart={downloadChart}
                  sentimentData={sentimentData && sentimentData}
                  // handleSentimentPopup={handleSentimentPopup}
                  setPageForPopup={setPageForPopup}
                  // setDate={setDate}
                  // handleDialog={handleDialog}
                  // setDeadAirDate={setDeadAirDate}

                  // // defaultDashboardData={defaultSentimentData}
                  // newDefaultTone={getNewDefaultDashboard}
                  // handleSummarySentimentPopup={!pageForPopup ? handleSummarySentimentPopup : null}
                />
              </Grid>
            )}
            {tabValue === '5' && (
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    // width: '500px',
                    marginTop: '20px',
                    height: 400
                    // width: 600
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: 15,
                      borderBottom: '1px solid #f1f3f4',
                      backgroundColor: '#F4F4F4'
                    }}
                  >
                    <b>Tenure Bucket Wise Quality Score</b>
                  </div>
                  <BarChartUBI data={tenureData?.counts_summary} />
                </Card>
              </Grid>
            )}
            {tabValue === '3' && (
              <Grid
                container
                item
                spacing={0.4}
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  // marginBottom: 7,
                  padding: 3
                }}
              >
                <Grid item xs={12} sm={5.8} md={2.9} className={classes.cards}>
                  <Card
                    style={{
                      height: 100,
                      boxShadow: 0,
                      textAlign: 'center',
                      // padding: '2px',
                      borderRadius: '5px'
                    }}
                  >
                    <div
                      style={{
                        // borderBottom: '1px solid #f1f3f4',
                        padding: 5,
                        backgroundColor: '#3892CF'
                      }}
                    >
                      <Typography style={{ color: '#fff' }} variant="h7">
                        <b>Feedback Given</b>
                      </Typography>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: '70%',
                        padding: '4px',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                      }}
                    >
                      <div>
                        <CallIcon style={{ color: '#60AAD9' }} fontSize="large" />
                      </div>
                      <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        <Typography
                          // variant="h3"
                          style={{
                            color: '#000',
                            fontStyle: 'bold',

                            fontSize: '2.2rem'
                          }}
                        >
                          {feedbackData &&
                          feedbackData.feedback_counts_summary &&
                          pieChartData.length > 0
                            ? formatNumber(feedbackData.feedback_counts_summary.total_feedback)
                            : 0}
                        </Typography>
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={5.8} md={2.9} className={classes.cards}>
                  <Card
                    style={{
                      height: 100,
                      boxShadow: 0,
                      textAlign: 'center',
                      // padding: '2px',
                      borderRadius: '5px'
                    }}
                  >
                    <div
                      style={{
                        // borderBottom: '1px solid #f1f3f4',
                        padding: 5,
                        backgroundColor: '#3892CF'
                      }}
                    >
                      <Typography style={{ color: '#fff' }} variant="h7">
                        <b>Feedback Pending</b>
                      </Typography>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: '70%',
                        padding: '4px',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                      }}
                    >
                      <div>
                        <CallIcon style={{ color: '#60AAD9' }} fontSize="large" />
                      </div>
                      <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        <Typography
                          // variant="h3"
                          style={{
                            color: '#000',
                            fontStyle: 'bold',

                            fontSize: '2.2rem'
                          }}
                        >
                          {loadingForCount ? (
                            <CircularProgress sx={{ color: '#3892CF' }} size={25} thickness={4} />
                          ) : feedbackData &&
                            feedbackData.feedback_counts_summary &&
                            pieChartData.length > 0 ? (
                            formatNumber(feedbackData.feedback_counts_summary.pending_count)
                          ) : (
                            0
                          )}
                        </Typography>
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={5.8} md={2.9} className={classes.cards}>
                  <Card
                    style={{
                      height: 100,
                      boxShadow: 0,
                      textAlign: 'center',
                      // padding: '2px',
                      borderRadius: '5px'
                    }}
                  >
                    <div
                      style={{
                        // borderBottom: '1px solid #f1f3f4',
                        padding: 5,
                        backgroundColor: '#3892CF'
                      }}
                    >
                      <Typography style={{ color: '#fff' }} variant="h7">
                        <b>Feedback Acknowledge</b>
                      </Typography>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: '70%',
                        padding: '4px',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                      }}
                    >
                      <div>
                        <CallIcon style={{ color: '#60AAD9' }} fontSize="large" />
                      </div>
                      <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        <Typography
                          // variant="h3"
                          style={{
                            color: '#000',
                            fontStyle: 'bold',

                            fontSize: '2.2rem'
                          }}
                        >
                          {loadingForCount ? (
                            <CircularProgress sx={{ color: '#3892CF' }} size={25} thickness={4} />
                          ) : feedbackData &&
                            feedbackData.feedback_counts_summary &&
                            pieChartData.length > 0 ? (
                            formatNumber(feedbackData.feedback_counts_summary.acknowledge_count)
                          ) : (
                            0
                          )}
                        </Typography>
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={5.8} md={2.9} className={classes.cards}>
                  <Card
                    style={{
                      height: 100,
                      boxShadow: 0,
                      textAlign: 'center',
                      // padding: '2px',
                      borderRadius: '5px'
                    }}
                  >
                    <div
                      style={{
                        // borderBottom: '1px solid #f1f3f4',
                        padding: 5,
                        backgroundColor: '#3892CF'
                      }}
                    >
                      <Typography style={{ color: '#fff' }} variant="h7">
                        <b>Feedback Calibrated</b>
                      </Typography>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: '70%',
                        padding: '4px',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                      }}
                    >
                      <div>
                        <CallIcon style={{ color: '#60AAD9' }} fontSize="large" />
                      </div>
                      <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        <Typography
                          // variant="h3"
                          style={{
                            color: '#000',
                            fontStyle: 'bold',

                            fontSize: '2.2rem'
                          }}
                        >
                          {loadingForCount ? (
                            <CircularProgress sx={{ color: '#3892CF' }} size={25} thickness={4} />
                          ) : feedbackData &&
                            feedbackData.feedback_counts_summary &&
                            pieChartData.length > 0 ? (
                            formatNumber(feedbackData.feedback_counts_summary?.calibrate_count)
                          ) : (
                            0
                          )}
                        </Typography>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            )}
            {tabValue === '3' && (
              <Grid item xs={12} md={5}>
                <Card sx={{ height: 365 }}>
                  {/* <Grid container spacing={10}> */}
                  {/* <Grid item xs={12} md={6}>
          <CardHeader title="Tone" />
        </Grid> */}
                  {/* <Grid item xs={12} md={6}>
          {toneData && (
            <Button
              style={{ marginTop: 20, marginLeft: '7rem' }}
              onClick={(e) => downloadChart('tone_graph')}
              variant="primary"
              startIcon={<Icon style={{ marginLeft: 10 }} icon={download} />}
            />
          )}
        </Grid> */}
                  {/* </Grid> */}
                  <div
                    style={{
                      padding: 10,
                      borderBottom: '1px solid #f1f3f4',
                      backgroundColor: '#F4F4F4',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <b>Feedback Status</b>

                    {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Selectbox
                        size="small"
                        value={chartTypeForFeedback}
                        onChange={(e) => setChartTypeForFeedback(e.target.value)}
                        // MenuProps={{ classes: { paper: classes.menuPaper } }}
                      >
                        <MenuItem value="summary">Summary</MenuItem>
                        <MenuItem value="trend">Trend</MenuItem>
                      </Selectbox>
                    </div> */}
                  </div>
                  {/* {chartTypeForFeedback === 'trend' && (
                    <ResponsiveContainer width="95%" height={280}>
                      {feedbackData && feedbackData.feedback_counts_trend ? (
                        <ReactApexChart
                          options={optionsForFeedback}
                          series={seriesForFeedback}
                          type="bar"
                          height={400}
                        />
                      ) : (
                        <div
                          style={{
                            width: '110%',
                            height: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <span style={{ fontSize: 20 }}>
                            <b>No Feedback Calls</b>
                          </span>
                        </div>
                      )}
                    </ResponsiveContainer>
                  )} */}
                  {/* {chartTypeForFeedback === 'summary' && ( */}
                  <>
                    {pieChartData?.length > 0 ? (
                      <ResponsiveContainer width="100%" height="80%">
                        <PieChart width={500} height={400}>
                          <Pie
                            data={pieChartData}
                            cx="50%"
                            cy="50%"
                            // labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius="95%"
                            // fill="#0088FE"
                            dataKey="value"
                          >
                            {pieChartData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                                style={{ outline: 'none', stroke: 'none' }}
                                // onClick={() => handleSummaryTonePopup(entry.name.toLowerCase())}
                              />
                            ))}
                          </Pie>
                          <Legend
                            payload={pieSummary.map((item, index) => ({
                              type: 'square',
                              value: `${item.value}`,
                              color: item.color
                            }))}
                            // onClick={handleSummaryLegendClick}
                            // wrapperStyle={{ fontWeight: 'bold' }}
                            layout="horizontal"
                            align="center"
                            horizontalAlign="center"
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '80%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <span style={{ fontSize: 20 }}>
                          <b>No Feedback Calls</b>
                        </span>
                      </div>
                    )}
                  </>
                  {/* )} */}
                </Card>
              </Grid>
            )}
            {tabValue === '3' && (
              <Grid item xs={12} md={7}>
                <Card
                  sx={{
                    // width: '500px',
                    // marginTop: '20px',
                    height: 365
                    // width: 600
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: 15,
                      borderBottom: '1px solid #f1f3f4',
                      backgroundColor: '#F4F4F4'
                    }}
                  >
                    <b>Overall Feedback Trend</b>

                    {/* <Selectbox
                      size="small"
                      value={feedbackStatus}
                      onChange={(e) => setFeedbackStatus(e.target.value)}
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Acknowledge">Acknowledge</MenuItem>
                      <MenuItem value="Calibrated">Calibrated</MenuItem>
                    </Selectbox> */}
                  </div>
                  <div
                    style={{
                      height: '100%'
                    }}
                  >
                    <ResponsiveContainer width="95%" height={280}>
                      {feedbackData &&
                      feedbackData.feedback_counts_trend &&
                      pieChartData.length > 0 ? (
                        <ReactApexChart
                          options={optionsForFeedback}
                          series={seriesForFeedback}
                          type="bar"
                          height={400}
                        />
                      ) : (
                        <div
                          style={{
                            width: '110%',
                            height: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <span style={{ fontSize: 20 }}>
                            <b>No Feedback Calls</b>
                          </span>
                        </div>
                      )}
                    </ResponsiveContainer>
                  </div>
                </Card>
              </Grid>
            )}
          </Grid>
          <Container>
            <CustomFormModel
              fullScreen={fullScreen}
              handleDialog={handleDialog}
              open={open}
              height={300}
              title="Add Goal"
            >
              <OpportunityForm
                setAlertContent={setAlertContent}
                setAlert={setAlert}
                setAlertType={setAlertType}
                handleDialog={handleDialog}
                dashboardFormik={formik}
                isUpdated={update}
                updateComponent={setUpdate}
              />
            </CustomFormModel>
            <CustomFormModel
              fullScreen={fullScreen}
              handleDialog={handleCompliantPopup}
              open={compliantOpen}
              title={shownComplianceName}
            >
              <Container>
                <TableContainer sx={{ minWidth: 800, maxHeight: 360 }}>
                  {loadingForCount ? (
                    <div
                      style={{
                        width: '100%',
                        height: 360,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CircularProgress sx={{ color: '#3892CF' }} size={34} thickness={4} />
                    </div>
                  ) : (
                    <Table stickyHeader aria-label="sticky table">
                      {true && (
                        // filteredUnpleasant && date
                        <UserListHead headLabel={KEY_WORDS_TABLE_HEAD} />
                      )}
                      {popupDataForCompliance &&
                        popupDataForCompliance.results &&
                        popupDataForCompliance.results.length > 0 && (
                          <TableBody>
                            {popupDataForCompliance.results
                              // .slice(
                              //   pageForPopup * rowsPerPageForPopup,
                              //   pageForPopup * rowsPerPageForPopup + rowsPerPageForPopup
                              // )
                              .map((row, index) => {
                                const {
                                  agent_name,
                                  s3url,
                                  input_id,
                                  request_id,
                                  org_id,
                                  customer_id,
                                  agent_id,
                                  date
                                } = row;

                                const shortS3url = decodeURI(
                                  s3url.split('/').pop().split('#')[0].split('?')[0]
                                ).slice(0, 14);

                                const handleRowClick = () => {
                                  navigate('/dashboard/workspace/Upload/Results', {
                                    state: {
                                      path: '/dashboard/app/compliance',
                                      HitAll,
                                      feedbackData,
                                      scoreTrendData,
                                      storeTabValue: tabValue,
                                      storedImprovementData: performanceMergeData,
                                      storedPerformanceData: performanceData,
                                      storedAccuracyInfo: aiAccuracyInfo,
                                      storedSelectedCategories: selectedCategories,
                                      storedAgentTrendData: agentGraphData,
                                      storedAgentSummaryData: agentSummaryData,
                                      toneData: toneData,
                                      sentimentData: sentimentData,
                                      storedAgentGraphData: agentGraphData,
                                      storedComparativeInfo: comparativeAnalysisCards,
                                      dateFormat: formik.values.format,
                                      startDate: formik.values.startDate,
                                      endDate: formik.values.endDate,
                                      storedCardInfo: scoreCardInfo,
                                      storedOpportunityInfo: opportunityInfo,
                                      storedTrendChartDetails: trendChartDetails,
                                      storedDealStatus: dealStatus,
                                      storedSalesProductInfo: salesProductInfo,
                                      // storedGetAudioList: getAudioList,
                                      storedOverallScoreInfo: overallScoreInfo,
                                      defaultDownload: showDownloadButton,
                                      newValues: {
                                        newOrganisation: organisation,
                                        newCustomer: defaultCustomer,
                                        newProcess: defaultProcess,
                                        newAgent: defaultAgent,
                                        newDate: defaultDate,
                                        agentIds: formik.values.campaign
                                      }
                                    }
                                  });

                                  setinputId(input_id);
                                  setinputOrgId(org_id);
                                  setrequestId(request_id);
                                  setUploadedAudio(
                                    decodeURI(
                                      s3url
                                        .split('/')
                                        .pop()
                                        .split('#')[0]
                                        .split('?')[0]
                                        .split('?')[0]
                                    )
                                  );
                                  setAudioS3(s3url);
                                  setProcess(); // You should set the process here if needed
                                  setcustomerId(customer_id);
                                  setOriginalDate(moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'));
                                  setAgentName(agent_name);
                                  setAgentLastName('');
                                  setAgentId(agent_id);
                                };

                                return (
                                  <TableRow hover key={index} tabIndex={-1} role="checkbox">
                                    <TableCell>{agent_name}</TableCell>
                                    <TableCell>
                                      {moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      title={decodeURI(
                                        s3url?.split('/').pop().split('#')[0].split('?')[0]
                                      )}
                                    >
                                      <Link
                                        underline="hover"
                                        sx={{ cursor: 'pointer', color: '#3892CF' }}
                                        onClick={handleRowClick}
                                      >
                                        <Typography sx={{ fontWeight: 600 }}>
                                          {`${decodeURI(
                                            s3url?.split('/').pop().split('#')[0].split('?')[0]
                                          ).slice(0, 14)}.....`}
                                        </Typography>
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        )}
                    </Table>
                  )}
                </TableContainer>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {popupDataForCompliance && !loadingForCount && (
                    <Pagination
                      classes={{
                        root: classes.ul
                      }}
                      count={Math.ceil(popupDataForCompliance.count / 10)}
                      page={popupPage}
                      onChange={handleChangePageForPopup}
                      showFirstButton
                      showLastButton
                    />
                  )}
                </div>

                {/* <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  rowsPerPage={rowsPerPageForPopup}
                  component="div"
                  count={Math.ceil(popupDataForCompliance.length)}
                  onRowsPerPageChange={handleChangeRowsPerPageForPopup}
                  page={pageForPopup}
                  onPageChange={handleChangePageForPopup}
                /> */}
              </Container>
            </CustomFormModel>
            <CustomFormModel
              fullScreen={fullScreen}
              open={agentPerformanceOpen}
              title="Agent Score Analysis"
              handleDialog={handleDialogForAgentPerformance}
            >
              {agentPerformanceSummary.length > 0 ? (
                <>
                  <Typography sx={{ marginBottom: 2 }}>
                    <b>Agent Name: {agentName}</b>
                  </Typography>
                  <ResponsiveContainer width="100%" height="89%">
                    <BarChart
                      data={agentPerformanceSummary}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20
                      }}
                    >
                      <XAxis
                        dataKey="parameter"
                        fontFamily="Roboto, sans-serif"
                        angle={-40} // Rotate labels vertically
                        reversed
                        fontWeight="bold"
                        tick={{
                          fontFamily: 'cursive, sans-serif',
                          fontSize: 10,
                          fontWeight: 'bold'
                        }}
                        tickLine={false}
                        tickFormatter={shortenLabel}
                      />
                      <YAxis
                        label={{
                          value: 'Score Percentage',
                          angle: -90,
                          offset: 100, // Adjust this negative value to move the label towards the bottom
                          position: 'insideBottom',
                          fontWeight: 'bold',
                          color: 'grey'
                        }}
                        domain={[0, 100]}
                        tick={{
                          fontFamily: 'cursive, sans-serif',
                          fontSize: 10,
                          fontWeight: 'bold'
                        }}
                        tickLine={false}
                        fontWeight="bold"
                      />
                      <Tooltip content={CustomTooltip} />

                      <Bar
                        // key={index}
                        barSize={
                          agentPerformanceSummary && agentPerformanceSummary.length > 25 ? 20 : 25
                        }
                        dataKey="score_percentage"
                        // onClick={(e) => handlePopupForSummary(e)}
                        stackId="a"
                        // onClick={(e) => console.log('guruu')}
                        // fill={(data) => {
                        //   console.log('guruu', data);
                        // }}
                        style={{ opacity: 0.9, borderRadius: 5 }}
                      >
                        {agentPerformanceSummary.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={entry.score_percentage > 80 ? '#3892cf' : 'red'}
                          />
                        ))}
                      </Bar>
                      <Legend content={<CustomLegend />} />
                    </BarChart>
                  </ResponsiveContainer>
                </>
              ) : (
                <>No data</>
              )}
            </CustomFormModel>
            <CustomFormModel
              fullScreen={fullScreen}
              open={callListOpen}
              title={`${labelShown} Calls`}
              handleDialog={handleDialogForCalls}
              downloadButton
              downloadFunction={downloadGraphPopupDetails}
            >
              <Container>
                <TableContainer sx={{ minWidth: 800, maxHeight: 350 }}>
                  {loadingForCount ? (
                    <div
                      style={{
                        width: '100%',
                        height: 360,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CircularProgress sx={{ color: '#3892CF' }} size={34} thickness={4} />
                    </div>
                  ) : (
                    <Table stickyHeader aria-label="sticky table">
                      <UserListHead
                        headLabel={[
                          {
                            id: 'Agent',
                            numeric: false,
                            disablePadding: false,
                            // alignCenter: true,
                            label: 'Agent'
                          },
                          {
                            id: 'date',
                            numeric: false,
                            disablePadding: false,
                            // alignCenter: true,
                            label: 'Date'
                          },
                          {
                            id: 'value',
                            numeric: false,
                            disablePadding: false,
                            alignCenter: true,
                            label: 'Value'
                          },
                          {
                            id: 'url',
                            numeric: false,
                            disablePadding: false,
                            alignCenter: true,
                            label: 'Call Name'
                          }

                          // { id: 'Action', numeric: false, disablePadding: false, label: 'Action', alignCenter: true }
                        ]}
                      />

                      {callList &&
                        callList.results &&
                        callList.results.list_of_calls &&
                        callList.results.list_of_calls.length > 0 && (
                          <TableBody>
                            {callList.results.list_of_calls
                              // .filter((el) => el.sentiments?.NEGATIVE?.toFixed(2) >= 25)
                              // .slice(
                              //   pageForPopup * rowsPerPageForPopup,
                              //   pageForPopup * rowsPerPageForPopup + rowsPerPageForPopup
                              // )
                              .map((row, index) => {
                                const {
                                  agent_name,
                                  s3url,
                                  sentiments,
                                  tone,
                                  input_id,
                                  request_id,
                                  org_id,
                                  customer_id,
                                  agent_id
                                } = row;

                                return (
                                  <TableRow hover key={1} tabIndex={-1} role="checkbox">
                                    <TableCell align="left">{agent_name}</TableCell>
                                    <TableCell align="left">
                                      {row.original_date
                                        ? moment(row.original_date, 'YYYY-MM-DD').format(
                                            'DD/MM/YYYY'
                                          )
                                        : ''}
                                    </TableCell>
                                    <TableCell align="center">
                                      <>
                                        {/* <Badge
                                          badgeContent={sentiments?.POSITIVE?.toFixed(2)}
                                          color="info"
                                          sx={{ marginX: 1 }}
                                        >
                                          <Chip
                                            label="Positive"
                                            sx={{ backgroundColor: '#65f0a6' }}
                                          />
                                        </Badge>
                                        <Badge
                                          badgeContent={sentiments?.NEGATIVE?.toFixed(2)}
                                          color="info"
                                          sx={{ marginX: 1 }}
                                        >
                                          <Chip
                                            label="Negative"
                                            sx={{ backgroundColor: '#f75e5e' }}
                                          />
                                        </Badge>
                                        <Badge
                                          badgeContent={sentiments?.NEUTRAL?.toFixed(2)}
                                          color="info"
                                          sx={{ marginX: 1 }}
                                        >
                                          <Chip
                                            label="Neutral"
                                            sx={{ backgroundColor: '#affaeb' }}
                                          />
                                        </Badge> */}
                                        <Chip
                                          label={labelShown}
                                          sx={{
                                            backgroundColor:
                                              labelShown === 'positive'
                                                ? '#65f0a6'
                                                : labelShown === 'negative'
                                                ? '#f75e5e'
                                                : labelShown === 'neutral'
                                                ? '#affaeb'
                                                : labelShown === 'high'
                                                ? '#f75e5e'
                                                : labelShown === 'medium'
                                                ? '#00c49f'
                                                : labelShown === 'low'
                                                ? '#ffbb28'
                                                : '#fffff'
                                          }}
                                        />
                                      </>
                                    </TableCell>

                                    <TableCell align="center">
                                      <Link
                                        underline="hover"
                                        sx={{ cursor: 'pointer', color: '#3892CF' }}
                                        onClick={() => {
                                          navigate('/dashboard/workspace/Upload/Results', {
                                            state: {
                                              path: '/dashboard/app/compliance',
                                              HitAll,
                                              feedbackData,
                                              scoreTrendData,
                                              storeTabValue: tabValue,
                                              storedImprovementData: performanceMergeData,
                                              storedPerformanceData: performanceData,
                                              storedAccuracyInfo: aiAccuracyInfo,
                                              storedSelectedCategories: selectedCategories,
                                              storedAgentTrendData: agentGraphData,
                                              storedAgentSummaryData: agentSummaryData,
                                              toneData: toneData,
                                              sentimentData: sentimentData,
                                              storedComparativeInfo: comparativeAnalysisCards,
                                              storedAgentGraphData: agentGraphData,
                                              dateFormat: formik.values.format,
                                              startDate: formik.values.startDate,
                                              endDate: formik.values.endDate,
                                              storedCardInfo: scoreCardInfo,
                                              storedOpportunityInfo: opportunityInfo,
                                              storedTrendChartDetails: trendChartDetails,
                                              storedDealStatus: dealStatus,
                                              storedSalesProductInfo: salesProductInfo,
                                              // storedGetAudioList: getAudioList,
                                              storedOverallScoreInfo: overallScoreInfo,
                                              defaultDownload: showDownloadButton,
                                              newValues: {
                                                newOrganisation: organisation,
                                                newCustomer: defaultCustomer,
                                                newProcess: defaultProcess,
                                                newAgent: defaultAgent,
                                                newDate: defaultDate,
                                                agentIds: formik.values.campaign
                                              }
                                            }
                                          });
                                          setinputId(input_id);
                                          setinputOrgId(org_id);
                                          setrequestId(request_id);
                                          setUploadedAudio(
                                            decodeURI(
                                              s3url
                                                .split('/')
                                                .pop()
                                                .split('#')[0]
                                                .split('?')[0]
                                                .split('?')[0]
                                            )
                                          );
                                          setAudioS3(s3url);
                                          setProcess(); // You should set the process here if needed
                                          setcustomerId(customer_id);
                                          setOriginalDate(
                                            moment(row.original_date, 'YYYY-MM-DD').format(
                                              'DD/MM/YYYY'
                                            )
                                          );
                                          setAgentName(agent_name);
                                          setAgentLastName('');
                                          setAgentId(agent_id);
                                        }}
                                      >
                                        <Tip
                                          title={decodeURI(
                                            s3url?.split('/').pop().split('#')[0].split('?')[0]
                                          )}
                                          followCursor
                                          placement="top"
                                        >
                                          <Typography sx={{ fontWeight: 600 }}>
                                            {`${decodeURI(
                                              s3url?.split('/').pop().split('#')[0].split('?')[0]
                                            ).slice(0, 14)}.....`}
                                          </Typography>
                                        </Tip>
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        )}
                      {callList &&
                        callList.results &&
                        callList.results.tone_graph &&
                        callList.results.tone_graph.length > 0 && (
                          <TableBody>
                            {callList.results.tone_graph
                              // .filter((el) => el.sentiments?.NEGATIVE?.toFixed(2) >= 25)
                              // .slice(
                              //   pageForPopup * rowsPerPageForPopup,
                              //   pageForPopup * rowsPerPageForPopup + rowsPerPageForPopup
                              // )
                              .map((row, index) => {
                                const {
                                  agent_name,
                                  s3url,
                                  sentiments,
                                  tone,
                                  input_id,
                                  request_id,
                                  org_id,
                                  customer_id,
                                  agent_id
                                } = row;

                                return (
                                  <TableRow hover key={1} tabIndex={-1} role="checkbox">
                                    <TableCell align="left">{agent_name}</TableCell>
                                    <TableCell align="left">
                                      {row.original_date
                                        ? moment(row.original_date, 'YYYY-MM-DD').format(
                                            'DD/MM/YYYY'
                                          )
                                        : ''}
                                    </TableCell>
                                    <TableCell align="center">
                                      <>
                                        {/* <Badge
                                          badgeContent={sentiments?.POSITIVE?.toFixed(2)}
                                          color="info"
                                          sx={{ marginX: 1 }}
                                        >
                                          <Chip
                                            label="Positive"
                                            sx={{ backgroundColor: '#65f0a6' }}
                                          />
                                        </Badge>
                                        <Badge
                                          badgeContent={sentiments?.NEGATIVE?.toFixed(2)}
                                          color="info"
                                          sx={{ marginX: 1 }}
                                        >
                                          <Chip
                                            label="Negative"
                                            sx={{ backgroundColor: '#f75e5e' }}
                                          />
                                        </Badge>
                                        <Badge
                                          badgeContent={sentiments?.NEUTRAL?.toFixed(2)}
                                          color="info"
                                          sx={{ marginX: 1 }}
                                        >
                                          <Chip
                                            label="Neutral"
                                            sx={{ backgroundColor: '#affaeb' }}
                                          />
                                        </Badge> */}
                                        <Chip
                                          label={labelShown}
                                          sx={{
                                            backgroundColor:
                                              labelShown === 'positive'
                                                ? '#65f0a6'
                                                : labelShown === 'negative'
                                                ? '#f75e5e'
                                                : labelShown === 'neutral'
                                                ? '#affaeb'
                                                : labelShown === 'high'
                                                ? '#f75e5e'
                                                : labelShown === 'medium'
                                                ? '#00c49f'
                                                : labelShown === 'low'
                                                ? '#ffbb28'
                                                : '#fffff'
                                          }}
                                        />
                                      </>
                                    </TableCell>

                                    <TableCell align="center">
                                      <Link
                                        underline="hover"
                                        sx={{ cursor: 'pointer', color: '#3892CF' }}
                                        onClick={() => {
                                          navigate('/dashboard/workspace/Upload/Results', {
                                            state: {
                                              path: '/dashboard/app/compliance',
                                              HitAll,
                                              feedbackData,
                                              scoreTrendData,
                                              storeTabValue: tabValue,
                                              storedImprovementData: performanceMergeData,
                                              storedPerformanceData: performanceData,
                                              storedAccuracyInfo: aiAccuracyInfo,
                                              storedSelectedCategories: selectedCategories,
                                              storedAgentTrendData: agentGraphData,
                                              storedAgentSummaryData: agentSummaryData,
                                              toneData: toneData,
                                              sentimentData: sentimentData,
                                              storedComparativeInfo: comparativeAnalysisCards,
                                              storedAgentGraphData: agentGraphData,
                                              dateFormat: formik.values.format,
                                              startDate: formik.values.startDate,
                                              endDate: formik.values.endDate,
                                              storedCardInfo: scoreCardInfo,
                                              storedOpportunityInfo: opportunityInfo,
                                              storedTrendChartDetails: trendChartDetails,
                                              storedDealStatus: dealStatus,
                                              storedSalesProductInfo: salesProductInfo,
                                              // storedGetAudioList: getAudioList,
                                              storedOverallScoreInfo: overallScoreInfo,
                                              defaultDownload: showDownloadButton,
                                              newValues: {
                                                newOrganisation: organisation,
                                                newCustomer: defaultCustomer,
                                                newProcess: defaultProcess,
                                                newAgent: defaultAgent,
                                                newDate: defaultDate,
                                                agentIds: formik.values.campaign
                                              }
                                            }
                                          });
                                          setinputId(input_id);
                                          setinputOrgId(org_id);
                                          setrequestId(request_id);
                                          setUploadedAudio(
                                            decodeURI(
                                              s3url
                                                .split('/')
                                                .pop()
                                                .split('#')[0]
                                                .split('?')[0]
                                                .split('?')[0]
                                            )
                                          );
                                          setAudioS3(s3url);
                                          setProcess(); // You should set the process here if needed
                                          setcustomerId(customer_id);
                                          setOriginalDate(
                                            moment(row.original_date, 'YYYY-MM-DD').format(
                                              'DD/MM/YYYY'
                                            )
                                          );
                                          setAgentName(agent_name);
                                          setAgentLastName('');
                                          setAgentId(agent_id);
                                        }}
                                      >
                                        <Tip
                                          title={decodeURI(
                                            s3url?.split('/').pop().split('#')[0].split('?')[0]
                                          )}
                                          followCursor
                                          placement="top"
                                        >
                                          <Typography sx={{ fontWeight: 600 }}>
                                            {`${decodeURI(
                                              s3url?.split('/').pop().split('#')[0].split('?')[0]
                                            ).slice(0, 14)}.....`}
                                          </Typography>
                                        </Tip>
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        )}
                    </Table>
                  )}
                </TableContainer>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {callList && !loadingForCount && (
                    <Pagination
                      classes={{
                        root: classes.ul
                      }}
                      count={Math.ceil(callList.count / 10)}
                      page={pageForPopup}
                      onChange={handleChangePageForSentTone}
                      showFirstButton
                      showLastButton
                    />
                  )}
                </div>
                {/* <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  rowsPerPage={rowsPerPageForPopup}
                  component="div"
                  count={Math.ceil(callList && callList.length)}
                  onRowsPerPageChange={handleChangeRowsPerPageForPopup}
                  page={pageForPopup}
                  onPageChange={handleChangePageForPopup}
                /> */}

                {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginY: '5px' }}>
                    <Button
                      variant="contained"
                      onClick={(e) => handleDialog()}
                      style={{ marginRight: 5 }}
                    >
                      Close
                    </Button>
                  </div> */}
              </Container>
            </CustomFormModel>
            {loadingForCount && (
              <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress sx={{ color: '#3892CF' }} />
                </Box>
              </Backdrop>
            )}
          </Container>

          <Notification
            message={alertContent}
            open={alert}
            severity={alertType}
            onClose={handleAlert}
          />
        </Grid>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress sx={{ color: '#3892CF' }} />
        </Box>
      )}
    </Page>
  );
}

function UserMoreMenu({
  updateComponent,
  isUpdated,
  Details,
  formik,
  setAlert,
  setAlertContent,
  setAlertType
}) {
  // console.log('res po positive', res);

  // console.log('getAudioList m', getAudioList);

  const token = localStorage.getItem('token').toString();
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const filterref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);

  const handleDialog = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };

  const handleDelete = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.delete(`/secure/delete_opportunity?id=${Details.opportunity_id}`, config)
      .then((res) => {
        console.log('delete opportunity res-', res);
        setAlertContent(`Success : Goal deleted successfully.`);
        setAlertType('success');
        setAlert(true);
      })
      .then(() => {
        setIsOpen(false);
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };
  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={openEdit}
        title="Update Goal"
      >
        <OpportunityEditForm
          dashboardFormik={formik}
          isUpdated={isUpdated}
          updateComponent={updateComponent}
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          // inputId={inputId}
          inputDetails={Details}
          handleDialog={handleDialog}
        />
      </CustomFormModel>
      <IconButton ref={filterref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={filterref.current}
        onClose={(event) => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDialog}>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            handleDelete();
          }}
          // disabled={res.lead_status === 'approved'}
        >
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: '5px',
    width: 200
  },

  listItemText: {
    // Add your custom styles here to manage the width or overflow of the text
    whiteSpace: 'nowrap', // Prevent text from wrapping
    overflow: 'hidden', // Hide overflowing text
    textOverflow: 'ellipsis', // Show ellipsis for truncated text
    maxWidth: '130px' // Set maximum width to prevent excessively wide text
  },
  submitButton: {
    // marginTop: '23px',
    width: '100%',
    backgroundColor: '#3892CF',
    '&:hover': {
      backgroundColor: '#3892CF'
    }
    // [theme.breakpoints.down('md')]: {
    //   marginTop: 5
    // }
  },
  downloadIconGrid: {
    display: 'flex',
    alignItems: 'flex-end'
    // [theme.breakpoints.down('md')]: {
    //   justifyContent: 'flex-start',
    //   marginTop: 5
    // }
  },
  // cards: {
  //   [theme.breakpoints.down('md')]: {
  //     padding: 10
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     padding: 20
  //   }
  // },
  menuPaper: {
    maxHeight: 200,
    maxWidth: 150
  },
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: '#3892CF',
        color: 'white'
        // borderRadius: '50%',
      }
    }
  }
}));
