/* eslint-disable prefer-template */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable  no-useless-concat */
import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { styled, useTheme } from '@mui/material/styles';
import Highlighter from 'react-highlight-words';
import {
  Grid,
  Typography,
  Card,
  Paper as Paperbox,
  Button,
  Container,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  Table,
  Tooltip,
  TableHead,
  IconButton,
  Menu,
  MenuItem,
  ListItemText
} from '@mui/material';
import {
  CircularGauge,
  Scale,
  RangeContainer,
  Range,
  Tooltip as GaugeTooltip,
  Font
} from 'devextreme-react/circular-gauge';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import eyeIcon from '@iconify/icons-mdi/eye';
import { LoadingButton } from '@mui/lab';
import arrowLeft from '@iconify/icons-mdi/arrow-left';
import { Icon } from '@iconify/react';

import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import PlusIcon from '@mui/icons-material/Add';
import { useNavigate, useLocation } from 'react-router-dom';
import API from '../E2E/axios.util';
import DealForm from '../layouts/Deal/DealForm';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import { Notification } from '../components/Notification/Notification';
import './CSS/ArrowBox.css';
import Waveform from './Waveform';
import Feedbackform from '../layouts/Compliance/FeedbackForm';
import FeedbackEditForm from '../layouts/Compliance/FeedbackEditForm';

function ComplianceTracker() {
  const state = useLocation();
  const {
    getAudioList,
    input_id,
    request_id,
    idx,
    path,
    customer_id,
    agent_id,
    org_id,
    dateFormat,
    startDate,
    endDate,
    process,
    storedCardInfo,
    storedDealStatus,
    storedOpportunityInfo,
    storedTrendChartDetails,
    storedGetAudioList,
    storedSalesProductInfo,
    newValues
  } = state.state;
  console.log('getAudioList parsad ', getAudioList);
  console.log('input_id parsad', input_id);
  // console.log('positive_leads parsad', positive_leads);
  console.log('request_id parsad', request_id);
  console.log('idx parsad', idx);
  console.log('path parsad', path);
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const [open, setOpen] = useState();
  const [transcriptOpen, settranscriptOpen] = useState();
  const [scriptOpen, setScriptOpen] = useState();
  const [sentimentOpen, setsentimentOpen] = useState();
  const [audioDuration, setAudioDuration] = useState('');
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [inputId, setinputId] = useState(input_id);
  const [requestId, setrequestId] = useState(request_id);
  const [dataIndex, setDataIndex] = React.useState(idx === null ? 0 : idx);
  const [isApproved, setIsApproved] = React.useState(true);
  const [alignment, setAlignment] = React.useState(isApproved === true ? 'approve' : 'reject');
  const [approvedValue, setApprovedValue] = React.useState('');
  const [url, setUrl] = useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [urlIndex, setUrlIndex] = React.useState([]);
  const [categoryQuestion, setCategoryQuestion] = React.useState([]);
  const [feedbackData, setFeedbackData] = React.useState([]);
  console.log('feedbackDAta', feedbackData);
  console.log('url[dataIndex].Categories[el]', categoryQuestion);

  useEffect(() => {
    console.log('path 0', path);
    if (path === 'compliant_info') {
      setUrl(getAudioList.compliant_info);
    } else if (path === 'non_compliant_info') {
      setUrl(getAudioList.non_compliant_info);
    } else if (path === 'compitators') {
      setUrl(getAudioList.compitators);
    } else if (path === 'buying_info') {
      setUrl(getAudioList.buying_info);
    } else if (path === 'followup') {
      setUrl(getAudioList.followup);
    }
  }, [getAudioList]);

  console.log('path url', url);

  useEffect(() => {
    if (url !== null) {
      if (url[dataIndex].lead_status === 'approved') {
        setApproved(true);
        setApprovedValue('Approve');
      } else if (url[dataIndex].lead_status === 'rejected') {
        setApproved(false);
        setApprovedValue('Reject');
      }
    }
  }, [getAudioList, dataIndex]);

  console.log('dataindex', dataIndex);

  // status
  const [update, setUpdate] = useState(0);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');

  const handleAlert = () => {
    setAlert(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const DealSchema = Yup.object().shape({
    customer: Yup.string().required('This field is required.'),
    // process: Yup.array().min(1, 'This field is required.'),
    organisationId: Yup.string().required('This field is required.'),
    agent: Yup.string().required('This field is required.'),
    feedBack: Yup.string()
      .max(80, 'feedback should be 2 to 80 character long.')
      .matches(/[^\s*].*[^\s*]/g, 'This field cannot contain only blankspaces.')
      .required('This field is required.')
    // dealStage: Yup.string().required('This field is required.'),
    // dealStatus: Yup.string().required('This field is required.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: userDetails.role === 'QA' ? userDetails.customer_id : customer_id,
      organisationId: `${
        userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : org_id
      }`,

      agent: agent_id,
      feedBack: '',
      rating: 3
    },
    validationSchema: DealSchema,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
        // onUploadProgress: (data) => {
        //   setUploaded(Math.round((data.loaded / data.total) * 100));
        // }
      };
      const data = {
        customer_id: formik.values.customer,
        user_id: formik.values.agent,
        // processes: formik.values.process,
        organisation_id: formik.values.organisationId,
        feedback: formik.values.feedBack,
        rating: formik.values.rating,
        input_id: inputId
      };
      console.log('data ---------------------', data);
      await API.post('/secure/add_feedback', data, config)
        .then((res) => {
          formik.resetForm();
          // handleRunProcess(res.data.id);

          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          handleDialog();
          setUpdate(update + 1);
        })
        .catch((err) => {
          console.log('error----', err);
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });

  console.log('formiknew', formik);

  const handleDialog = () => {
    setOpen(!open);
    formik.resetForm();
  };
  const handleTranscriptDialog = () => {
    settranscriptOpen(!transcriptOpen);
    formik.resetForm();
  };
  const handleScriptDialog = () => {
    setScriptOpen(!scriptOpen);
  };

  const TextCut = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + '...';
    }
    return text;
  };
  const scoreCardInfo = [
    { feedback: 'good progress', rating: '4' },
    { feedback: 'good progress', rating: '5' },
    { feedback: 'good progress', rating: '3' }
  ];

  // Function for list feedback
  const getFeedbackList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/single_call_feedback?input_id=${inputId}`, config)
      .then((res) => {
        setFeedbackData(res.data.feedback_list);
      })
      .catch((err) => {
        setFeedbackData([]);
        console.log(err);
      });
  };

  useEffect(() => {
    getFeedbackList();
  }, [inputId, update]);

  // --------------------------------------------
  return (
    <div style={{}}>
      <Grid item xs={6}>
        <Paperbox elevation={0} style={{ marginBottom: 10, display: 'flex' }}>
          <LoadingButton
            startIcon={<Icon icon={arrowLeft} />}
            variant="text"
            size="large"
            onClick={() => {
              navigate('/dashboard/app/compliance', {
                state: {
                  organisation: org_id,
                  dateFormat: dateFormat,
                  startDate: startDate,
                  endDate: endDate,
                  customer: customer_id,
                  agent: agent_id,
                  process: [process],
                  scoreCardInfo: storedCardInfo,
                  opportunityInfo: storedOpportunityInfo,
                  trendChartDetails: storedTrendChartDetails,
                  dealStatus: storedDealStatus,
                  audioList: storedGetAudioList,
                  productInfo: storedSalesProductInfo,
                  newValues: newValues
                }
              });
            }}
            style={{ color: '#3399ff' }}
          >
            Back
          </LoadingButton>
        </Paperbox>
      </Grid>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography role="presentation" textAlign="left" variant="h4" sx={{ marginBottom: 2 }}>
          Script Details
        </Typography>

        <Button
          variant="contained"
          startIcon={<PlusIcon />}
          onClick={handleDialog}
          style={{
            marginRight: 5,
            backgroundColor: alignment !== 'approve' ? '#DCDCDC' : '#3892CF',
            '&:hover': {
              backgroundColor: '#3892CF'
            }
          }}
        >
          Add Feedback
        </Button>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={2} sm={6} style={{ padding: 5 }}>
          <Card
            sx={{
              height: 350,
              overflowY: 'auto'
            }}
          >
            <div
              style={{
                padding: 15,
                borderBottom: '1px solid #f1f3f4',
                backgroundColor: '#F4F4F4',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <b>Audio</b>
            </div>

            {url !== null &&
              url.map((res, index) => {
                console.log(
                  'decode',
                  decodeURI(res.s3url.split('/').pop().split('#')[0].split('?')[0])
                );
                return (
                  <TableContainer
                    // component={Paper}
                    style={{ overflowX: 'hidden' }}
                  >
                    <Table style={{ minWidth: 300 }} aria-label="simple table" overflow="hidden">
                      <TableBody>
                        <TableRow
                          onClick={() => {
                            setDataIndex(index);
                            setinputId(res.input_id);
                            setrequestId(res.request_id);
                            setAlignment('approve');
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <TableCell style={{ padding: 2, display: 'flex' }}>
                            <CheckIcon sx={{ color: '#3892CF' }} />
                            <Tooltip
                              title={decodeURI(
                                res.s3url.split('/').pop().split('#')[0].split('?')[0]
                              )}
                              placement="top"
                            >
                              <Typography
                                style={{ color: dataIndex === index ? '#3892CF' : '#000' }}
                              >
                                {TextCut(
                                  decodeURI(res.s3url.split('/').pop().split('#')[0].split('?')[0]),
                                  17
                                )}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              })}
          </Card>
        </Grid>
        {url !== null ? (
          <Grid item xs={12} md={5} sm={6} style={{ padding: 5 }}>
            <Card
              sx={{
                height: 350
              }}
            >
              <div
                style={{
                  padding: 15,
                  borderBottom: '1px solid #f1f3f4',
                  backgroundColor: '#F4F4F4'
                }}
              >
                <b>Audio Visualisation</b>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {url !== null && url.length !== 0 ? (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Waveform url={url[dataIndex].s3url} dialog={handleTranscriptDialog} />
                  </div>
                ) : null}
              </div>
            </Card>
          </Grid>
        ) : null}
        {url !== null ? (
          <Grid item xs={12} md={5} sm={6} style={{ padding: 5 }}>
            <Card
              sx={{
                height: 350
              }}
            >
              <div
                style={{
                  padding: 15,
                  borderBottom: '1px solid #f1f3f4',
                  backgroundColor: '#F4F4F4'
                }}
              >
                <b>Questions Asked</b>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 295,
                  overflowY: 'auto'
                }}
              >
                <TableContainer>
                  <Table>
                    <TableHead sx={{ backgroundColor: 'RGB(250 250 250)' }}>
                      <TableRow>
                        <TableCell>
                          <b>Sr.no</b>
                        </TableCell>
                        <TableCell>
                          <b>Category</b>
                        </TableCell>
                        <TableCell>
                          <b>Questions</b>
                        </TableCell>
                        <TableCell>
                          <b>Asked?</b>
                        </TableCell>
                        <TableCell>
                          <b />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(url[dataIndex].Categories).map((el, index) => (
                        <TableRow>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{el}</TableCell>
                          <TableCell align="center">
                            <>
                              <Tooltip
                                title={`${categoryQuestion?.join().slice(0, 25)}...`}
                                placement="top"
                              >
                                <IconButton
                                  onMouseOver={() =>
                                    setCategoryQuestion(url[dataIndex]?.Categories_questions[el])
                                  }
                                  onClick={(event) => {
                                    handleScriptDialog();
                                  }}
                                >
                                  <Icon icon={eyeIcon} width={20} height={20} />
                                </IconButton>
                              </Tooltip>
                              {/* <Popper open={Boolean(anchorE3)} anchorEl={anchorE3}>
                                <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                                  The content of the Popper.
                                </Box>
                              </Popper> */}
                              {/* {categoryQuestion?.length > 0 ? (
                                <Menu
                                  open={Boolean(anchorE3)}
                                  anchorEl={anchorE3}
                                  onClose={(event) => setAnchorE3(null)}
                                  PaperProps={{
                                    sx: {
                                      width: 200,
                                      maxWidth: '100%',
                                      maxHeight: 150,
                                      overflowY: 'auto'
                                    }
                                  }}
                                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                  {categoryQuestion.map((element, index) => (
                                    <MenuItem sx={{ color: 'text.secondary' }}>
                                      <Typography>{element}</Typography>
                                    </MenuItem>
                                  ))}
                                </Menu>
                              ) : null} */}
                            </>
                          </TableCell>
                          <TableCell align="center">
                            {url[dataIndex].Categories[el].length > 0 ? (
                              <div
                                style={{
                                  width: '50px',
                                  height: '25px',
                                  color: '#fff',
                                  borderRadius: '7px',
                                  backgroundColor: 'green',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                Yes
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: '50px',
                                  height: '25px',
                                  color: '#fff',
                                  borderRadius: '7px',
                                  backgroundColor: 'red',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                No
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <>
                              <IconButton
                                onClick={(event) => {
                                  setUrlIndex(url[dataIndex].Categories[el]);
                                  setAnchorE2(event.currentTarget);
                                }}
                              >
                                <Icon icon={moreVerticalFill} width={20} height={20} />
                              </IconButton>

                              {urlIndex.length > 0 ? (
                                <Menu
                                  open={Boolean(anchorE2)}
                                  anchorEl={anchorE2}
                                  onClose={(event) => setAnchorE2(null)}
                                  PaperProps={{
                                    sx: {
                                      width: 200,
                                      maxWidth: '100%',
                                      maxHeight: 150,
                                      overflowY: 'auto'
                                    }
                                  }}
                                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                  {urlIndex.map((element, index) => (
                                    <MenuItem sx={{ color: 'text.secondary' }}>
                                      <audio
                                        src={
                                          process.env.REACT_APP_ON_PREMISE === 'true'
                                            ? `${process.env.REACT_APP_SERVER_MEDIA_URL}${element.url[0]}`
                                            : element.url[0]
                                        }
                                        controls
                                      >
                                        <track default kind="captions" srcLang="en" />
                                      </audio>
                                    </MenuItem>
                                  ))}
                                </Menu>
                              ) : null}
                            </>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Card>
          </Grid>
        ) : null}
        {url !== null ? (
          <Grid item xs={12} md={4} sm={6} style={{ padding: 5 }}>
            <Card
              sx={{
                width: '100%',
                height: 360
              }}
            >
              <div
                style={{
                  padding: 15,
                  borderBottom: '1px solid #f1f3f4',
                  backgroundColor: '#F4F4F4'
                }}
              >
                <b>Compliant Percentage</b>
              </div>

              {/* <ResponsiveContainer width="100%" height={280}> */}
              <CircularGauge
                id="gauge"
                // value={60}
                value={url[dataIndex].percentage}
              >
                <Scale startValue={0} endValue={100} tickInterval={20} />
                <RangeContainer width={10}>
                  <Range startValue={0} endValue={60} color="RGB(255 83 83)" />
                  <Range startValue={60} endValue={80} color="RGB(255 250 107)" />
                  <Range startValue={90} endValue={100} color="RGB(87 255 111)" />
                  <Range startValue={80} endValue={90} color="RGB(40 255 111)" />
                </RangeContainer>
                <GaugeTooltip enabled cornerRadius={15} color="#cce6ff" border={{ visible: false }}>
                  <Font size={20} color="#3399ff" weight={500} />
                </GaugeTooltip>
              </CircularGauge>
              {/* </ResponsiveContainer> */}
            </Card>
          </Grid>
        ) : null}
        <Grid item xs={12} md={8} sm={6} style={{ padding: 5 }}>
          <Card sx={{ height: 360 }}>
            <>
              <div
                style={{
                  padding: 15,
                  borderBottom: '1px solid #f1f3f4',
                  backgroundColor: '#F4F4F4',
                  position: 'sticky'
                }}
              >
                <b>Feedback</b>
              </div>
              {feedbackData.length > 0 ? (
                <Paperbox sx={{ width: '100%' }}>
                  <TableContainer sx={{ maxHeight: 300, overflowY: 'auto' }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: '600' }}>Sr.No</TableCell>
                          <TableCell style={{ fontWeight: '600' }} align="center">
                            Feedback
                          </TableCell>
                          <TableCell style={{ fontWeight: '600' }} align="center">
                            Rating
                          </TableCell>
                          <TableCell style={{ fontWeight: '600' }} align="center" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {feedbackData.map((el, index) => (
                          <TableRow>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell align="center">{el.feedback_desc}</TableCell>
                            <TableCell align="center">{el.rating}</TableCell>

                            <TableCell align="right">
                              <UserMoreMenu
                                update={update}
                                setUpdate={setUpdate}
                                // setAlert={setAlert}
                                // setAlertContent={setAlertContent}
                                // setAlertType={setAlertType}
                                // opportunityId={compliance_id}
                                Details={el}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paperbox>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: 20 }}>
                    <b>No Feedback Available</b>
                  </span>
                </div>
              )}
            </>
          </Card>
        </Grid>
      </Grid>
      <Container>
        <CustomFormModel
          fullScreen={fullScreen}
          handleDialog={handleDialog}
          open={open}
          title="Add Feedback"
        >
          <Feedbackform handleDialog={handleDialog} formik={formik} />
        </CustomFormModel>
        {url !== null ? (
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleTranscriptDialog}
            open={transcriptOpen}
            title="Transcript"
          >
            <Paperbox
              // elevation={16}
              style={{
                marginTop: 10,
                padding: '2rem',
                height: 360,
                overflow: 'auto',
                backgroundColor: '#fafafa'
              }}
            >
              {url[dataIndex]?.transcript?.split('\n')?.map((data) => (
                <div>
                  <Highlighter
                    highlightClassName="YourHighlightClass"
                    searchWords={[]}
                    autoEscape
                    textToHighlight={data}
                  />
                </div>
              ))}
            </Paperbox>
          </CustomFormModel>
        ) : null}
        {categoryQuestion?.length > 0 ? (
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleScriptDialog}
            open={scriptOpen}
            title="Script"
          >
            <Paperbox
              // elevation={16}
              style={{
                marginTop: 10,
                padding: '2rem',
                height: 360,
                overflow: 'auto',
                backgroundColor: '#fafafa'
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sr.No</TableCell>
                      <TableCell>Question</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categoryQuestion.map((el, index) => (
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{el}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paperbox>
          </CustomFormModel>
        ) : null}
      </Container>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </div>
  );
}

export default ComplianceTracker;

function UserMoreMenu({ Details, update, setUpdate }) {
  // console.log('res po positive', res);

  // console.log('getAudioList m', getAudioList);

  const token = localStorage.getItem('token').toString();
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const filterref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);

  const handleDialog = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };

  const handleDelete = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.delete(`/secure/delete_opportunity?id=${Details.opportunity_id}`, config)
      .then((res) => {
        console.log('delete opportunity res-', res);
        // setAlertContent(`Success : Organisation deleted successfully.`);
        // setAlertType('success');
        // setAlert(true);
      })
      .then(() => {
        setIsOpen(false);
        // updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        console.log(err);
        // setAlertContent(`${err.response.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };
  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={openEdit}
        title="Update Feedback"
      >
        <FeedbackEditForm
          // dashboardFormik={formik}
          isUpdated={update}
          updateComponent={setUpdate}
          // setAlert={setAlert}
          // setAlertContent={setAlertContent}
          // setAlertType={setAlertType}
          // inputId={inputId}
          inputDetails={Details}
          handleDialog={handleDialog}
        />
      </CustomFormModel>
      <IconButton ref={filterref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={filterref.current}
        onClose={(event) => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDialog}>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {/* <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            handleDelete();
          }}
          // disabled={res.lead_status === 'approved'}
        >
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
      </Menu>
    </>
  );
}
