import React, { useState, useEffect, useRef, useContext } from 'react';
import './CampaignForm.css';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import moment from 'moment';
import {
  Card,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TablePagination,
  Stack,
  Pagination,
  Menu,
  MenuItem,
  IconButton,
  Button,
  ListItemIcon,
  ListItemText,
  Box,
  LinearProgress,
  Typography,
  Collapse,
  TableHead,
  Tooltip as Tip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import editFill from '@iconify/icons-eva/edit-fill';
import eyeIcon from '@iconify/icons-mdi/eye';
import cogs from '@iconify/icons-mdi/cogs';
import cogPlay from '@iconify/icons-mdi/cog-play';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
// components
import useWebSocket from '../../hooks/useWebSocket';
import { CampaignEditForm } from '../../components/custom-form-model/CRUDForms';
import inputContex from '../../Context/UploadContext/inputContext';
import { CustomFormModel } from '../../components/custom-form-model/CustomFormModel';
import Scrollbar from '../../components/Scrollbar';
import API from '../../E2E/axios.util';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: '#3892CF',
        color: 'white'
        // borderRadius: '50%',
      }
    }
  }
}));
const UPLOAD_TABLE_HEAD = [
  {
    id: 'campaignId',
    numeric: false,
    disablePadding: false,
    label: 'Campaign ID',
    alignCenter: true
  },
  { id: 'organisation', numeric: false, disablePadding: false, label: 'Organisation' },
  {
    id: 'Customer',
    numeric: false,
    disablePadding: false,
    label: 'Customer'
  },
  { id: 'campaignName', numeric: false, disablePadding: false, label: 'Campaign' },

  { id: 'product', numeric: false, disablePadding: false, label: 'Product' },
  {
    id: 'startdate',
    numeric: false,
    disablePadding: false,
    label: 'Start Date',
    alignCenter: true
  },
  { id: 'enddate', numeric: false, disablePadding: false, label: 'End Date', alignCenter: true },
  { id: '' }
];

export const CampaignDataModel = ({
  handleDialog,
  formik,
  setAlert,
  setAlertContent,
  setAlertType,
  isUpdated,
  updateComponent,
  uploadDataList,
  setuploadDataList
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState();
  const [uploadSearch, setUploadSearch] = useState();
  console.log(page, 'page');
  console.log(rowsPerPage, 'rowsPerPage');
  const userDetails = JSON.parse(localStorage.getItem('user'));

  const handleChangePage = (event, newPage) => {
    console.log('newPage ----', newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUploadData = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/campaign_list?page=${page}`, config)
      .then((res) => {
        setuploadDataList(res.data);
        setAlertContent(`Success: Campaign list fetch successfully.`);
        setAlertType('success');
        setLoading(false);
        setAlert(true);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
        setLoading(false);
      });
  };

  const handleSearch = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`secure/upload_search?page=${page}&max_rows=10&query=${query}`, config)
      .then((res) => {
        setUploadSearch(res.data);
        console.log('search', res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUploadData();
  }, [isUpdated, page]);
  useEffect(() => {
    if (query) {
      handleSearch();
      // console.log('query', query);
    }
  }, [isUpdated, page]);

  return (
    <Card>
      {/* <Box sx={{ display: 'flex' }}>
        <UserListToolbar
          placeholder="Search by name"
          onFilterName={(e) => setQuery(e.target.value)}
        />
        {query && (
          <IconButton
            color="primary"
            aria-label="search"
            component="label"
            onClick={handleSearch}
            sx={{ marginTop: '25px', height: '50px', width: '50px' }}
          >
            <SearchOutlinedIcon />
          </IconButton>
        )}
      </Box> */}
      <Scrollbar sx={{ cursor: 'pointer' }}>
        <TableContainer>
          <Table>
            <UserListHead headLabel={UPLOAD_TABLE_HEAD} rowCount={page} />
            {!loading && uploadDataList && uploadDataList.results.length > 0 ? (
              <TableBody>
                {uploadDataList.results
                  // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      campaign_id,
                      org_name,
                      customer_organisation_name,
                      campaign_name,
                      product,
                      campaign_startdate,
                      campaign_enddate
                    } = row;
                    return (
                      <>
                        <TableRow hover key={1} tabIndex={-1} role="checkbox">
                          <TableCell align="center">{campaign_id}</TableCell>
                          <TableCell align="left">{org_name}</TableCell>
                          <TableCell component="th" scope="row" padding="normal">
                            {customer_organisation_name}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="normal">
                            {campaign_name.toString()}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="normal">
                            {product}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="normal" align="center">
                            {moment(campaign_startdate, 'YYYY-M-D').format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="normal" align="center">
                            {moment(campaign_enddate, 'YYYY-M-D').format('DD/MM/YYYY')}
                          </TableCell>
                          {userDetails.role !== 'Manager' && (
                            <TableCell align="right">
                              <UserMoreMenu
                                formik={formik}
                                isUpdated={isUpdated}
                                updateComponent={updateComponent}
                                setAlert={setAlert}
                                setAlertContent={setAlertContent}
                                setAlertType={setAlertType}
                                inputId={campaign_id}
                                inputDetails={row}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  {loading ? (
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <CircularProgress sx={{ color: '#3892CF' }} />
                    </TableCell>
                  ) : (
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      Data Not Found
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <Stack
        spacing={2}
        sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
      >
        <Pagination
          classes={{
            root: classes.ul
          }}
          count={uploadDataList ? Math.ceil(uploadDataList?.count / 10) : 1}
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Stack>
      {/* {!loading && uploadSearch && uploadDataList ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          rowsPerPage={rowsPerPage}
          count={query ? uploadSearch.max_record_count : uploadDataList.campaign_list.length}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          rowsPerPage={rowsPerPage}
          count={uploadDataList?.campaign_list ? uploadDataList?.campaign_list?.length : 0}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )} */}
    </Card>
  );
};

function UserMoreMenu({
  formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  inputId,
  inputDetails
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(null);

  const handleDialog = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };

  const handleDeleteInput = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.delete(`/secure/deactivate_campaign?id=${inputId}`, config)
      .then((res) => {
        setAlertContent(`Success : Campaign deleted successfully.`);
        setAlertType('success');
        setAlert(true);
      })
      .then(() => {
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={openEdit}
        title="Update Campaign "
      >
        <CampaignEditForm
          formik={formik}
          isUpdated={isUpdated}
          updateComponent={updateComponent}
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          inputId={inputId}
          inputDetails={inputDetails}
          handleDialog={handleDialog}
        />
      </CustomFormModel>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem to="#" sx={{ color: 'text.secondary' }} onClick={handleDialog}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDeleteInput}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
