/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label
} from 'recharts';
import {
  Container,
  Typography,
  Card,
  Select as Selectbox,
  TableRow,
  TableHead,
  TableBody,
  Table,
  TableContainer,
  TableCell,
  Badge,
  Chip,
  Link,
  Tooltip as Tip,
  MenuItem
} from '@mui/material';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import download from '@iconify/icons-eva/download-fill';
import { Icon } from '@iconify/react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { CustomFormModel } from '../custom-form-model/CustomFormModel';

function getRandomColor() {
  // Generate random values for red, green, and blue channels
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  // Convert the values to hexadecimal
  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');

  // Combine the channels into a color hex code
  const color = `rgb(${r}, ${g}, ${b})`;

  return color;
}

// Example usage
// var randomColor = getRandomColor();
// console.log(randomColor);

// const formattedData = convertedGraphDataForCategory.map((dataObj) => {
//   const newdate = Object.keys(dataObj)[0];
//   const values = dataObj[newdate];

//   return {
//     date: newdate,
//     ...values
//   };
// });

// console.log('formattedData', formattedData);

const transformData = (data) => {
  // Create an object to hold the transformed data
  const transformedData = {};

  // Iterate over the data
  data.forEach((item) => {
    // Get the date and category values
    const [date, category] = Object.entries(item)[0];

    // Iterate over the category values
    Object.entries(category).forEach(([key, value]) => {
      // If the category doesn't exist in the transformed data, create it
      if (!transformedData[key]) {
        transformedData[key] = [];
      }

      // Push the value for the corresponding date
      transformedData[key].push({
        date,
        [key]: parseInt(value, 10)
      });
    });
  });
  console.log('transformedData', transformedData);
  // Return the transformed data
  return Object.entries(transformedData).map(([key, values]) => ({
    name: `${key}`,
    values
  }));
};

const Category = [
  {
    agent_id: 689,
    agent_name: 'lavanya dhakare',
    word_count: {
      बैंक: 28,
      बात: 1,
      लोन: 8,
      हाँ: 2,
      कॉल: 2,
      आपको: 6,
      नहीं: 17,
      नाम: 2,
      पेमेंट: 5,
      डेट: 1
    },
    s3url:
      's3://intelliconvo-qa/uipath/245/682/None/689/AVELBIPOBD4ONBJ99K92AFKK8G0B08QF_2023-03-18_13-12-11_Aakash Sharma_21062023055905_21062023132123.mp3',
    input_id: 8713,
    request_id: 8046,
    org_id: 245,
    customer_id: 682,
    processes: ['Billing']
  },
  {
    agent_id: 689,
    agent_name: 'lavanya dhakare',
    word_count: {
      बैंक: 8,
      बात: 14,
      अमाउंट: 2,
      लोन: 3,
      हाँ: 1,
      पेंडिंग: 1,
      कॉल: 6,
      आपको: 15,
      जी: 4,
      नहीं: 27,
      नाम: 2,
      क्यों: 8,
      काम: 1,
      बोल: 23,
      पेमेंट: 9,
      दिन: 16
    },
    s3url: 's3://intelliconvo-qa/uipath/245/682/None/689/Rajesh_21062023130439.wav',
    input_id: 8712,
    request_id: 8045,
    org_id: 245,
    customer_id: 682,
    processes: ['Billing']
  }
];

const StackBarForCategory = ({
  chartType,
  setChartType,
  categoryData,
  handlePopup,
  handlePopupForSummary,
  defaultCategoryData,
  downloadChart
}) => {
  const [categoryDate, setCategoryDate] = React.useState();
  const [barName, setBarName] = React.useState('');
  const datesObject = categoryData ? categoryData : defaultCategoryData ? defaultCategoryData : {};

  // Convert the keys (dates) to an array and sort it
  const sortedDates = Object.keys(datesObject).sort();
  // console.log('sortedDates', sortedDates);
  // Create a new object with the sorted keys
  const sortedDatesObject = {};
  sortedDates.forEach((date) => {
    sortedDatesObject[date] = datesObject[date];
  });

  // console.log('categoryDate', categoryDate);
  // console.log('sortedDatesObject', sortedDatesObject);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      // console.log('payload', payload);
      const data = payload[0].payload.date;
      let tooltipDate = '';
      const index = data.indexOf('/');

      // If '/' is found, return the substring before it
      if (index !== -1) {
        tooltipDate = data.substring(0, index);
      } else {
        tooltipDate = data;
      }

      // If '/' is not found, return the entire string

      // console.log('payload[0]', payload.find);
      const shownTip = payload.find((p) => p.dataKey === barName);
      // console.log('shownTip', shownTip);
      setCategoryDate(data);
      // const words = Object.keys(payload[0].payload);
      return (
        <>
          {shownTip ? (
            <Card
              className="custom-tooltip"
              sx={{ padding: '10px', border: `1px solid ${shownTip.fill}` }}
            >
              {/* <p className="intro">{data === 'total' ? '' : `Date:${data}`}</p> */}
              <Typography className="intro" style={{ color: 'grey' }}>
                {tooltipDate}
              </Typography>
              <Typography
                className="intro"
                style={{ color: 'grey' }}
              >{`${shownTip.dataKey} : ${shownTip.value}`}</Typography>
            </Card>
          ) : null}
        </>
      );
    }
    return null;
  };

  const CustomTooltipForSummary = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      // console.log('payload[0]', payload);

      // const words = Object.keys(payload[0].payload);
      return (
        <Card className="custom-tooltip" sx={{ padding: '10px' }}>
          {/* <p className="intro">{data === 'total' ? '' : `Date:${data}`}</p> */}
          <Typography className="intro" style={{ color: 'grey' }}>
            {data.category}
          </Typography>
          <Typography className="intro" style={{ color: 'grey' }}>
            {data.count}
          </Typography>
        </Card>
      );
    }
    return null;
  };

  const convertedGraphDataForCategory = Object.entries(sortedDatesObject).map(([date, data]) => ({
    [date]: data
  }));

  const data = transformData(convertedGraphDataForCategory);
  console.log('convertedGraphDataForCategory', data);
  const formattedData = convertedGraphDataForCategory.map((dataObj) => {
    const newdate = Object.keys(dataObj)[0];
    const values = dataObj[newdate];

    return {
      date: newdate,
      ...values
    };
  });

  const getCategoryVsCountChartData = () => {
    const overallCounts = {};

    // Iterate over the array
    formattedData.forEach((item) => {
      // Iterate over each parameter in the item
      Object.keys(item).forEach((parameter) => {
        // Skip the "date" parameter
        if (parameter !== 'date') {
          // Add the parameter count to the overallCounts object
          overallCounts[parameter] = (overallCounts[parameter] || 0) + item[parameter];
        }
      });
    });

    // Transform the overallCounts object into an array of objects for Recharts
    return Object.keys(overallCounts).map((category) => ({
      category,
      count: overallCounts[category]
    }));
  };

  const chartDataForSummary = getCategoryVsCountChartData();

  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState();
  const handleDialog = () => {
    // console.log('dialogBox---', open);
    setOpen(!open);
  };

  const colors = [
    '#FFA500', // Orange
    '#FF69B4', // Hot Pink
    '#00CED1', // Dark Turquoise
    '#7B68EE', // Medium Slate Blue
    '#32CD32', // Lime Green
    '#FF4500', // Orange Red
    '#9370DB', // Medium Purple
    '#20B2AA', // Light Sea Green
    '#48D1CC', // Medium Turquoise
    '#9370DB', // Medium Purple
    '#800000', // Maroon
    '#008080', // Teal
    '#9932CC', // Dark Orchid
    '#8B0000', // Dark Red
    '#8A2BE2', // Blue Violet
    '#00CED1', // Dark Turquoise
    '#DC143C', // Crimson
    '#8B4513', // Saddle Brown
    '#FF69B4', // Hot Pink
    '#808000', // Olive
    '#B8860B', // Dark Goldenrod
    '#696969', // Dim Gray
    '#483D8B', // Dark Slate Blue
    '#800080', // Purple
    '#32CD32', // Lime Green
    '#2E8B57', // Sea Green
    '#20B2AA', // Light Sea Green
    '#48D1CC', // Medium Turquoise
    '#D2691E', // Chocolate
    '#E9967A', // Dark Salmon
    '#7FFF00' // Chartreuse
  ];
  // Array of fixed colors
  let colorIndex = 0; // Index to track the current color

  function getNextColor() {
    const color = colors[colorIndex];
    colorIndex = (colorIndex + 1) % colors.length; // Increment the index and wrap around
    return color;
  }

  // Custom CSS class for the Legend
  const legendStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'right',
    listStyleType: 'none'
  };

  // CSS for the circular legend marker
  const legendIconStyle = {
    width: '10px', // Adjust the size as needed
    height: '10px', // Adjust the size as needed
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '5px' // Adjust spacing
  };

  // Apply a cursive font-family to the Legend
  const legendTextStyle = {
    fontFamily: 'arial',
    fontWeight: 'bold',
    fontSize: 12
  };

  const CustomLegend = (e) => (
    <div style={legendStyle}>
      {e.payload.map((entry, index) => (
        <li
          key={index}
          style={{
            listStyleType: 'none',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <span
            className="legend-icon"
            style={{ ...legendIconStyle, backgroundColor: entry.color }}
          />

          <span style={legendTextStyle}>
            {/* {entry.datakey?.length > 8 ? ( */}
            <div>
              <Tip title={entry.dataKey} followCursor placement="top">
                {`${entry.dataKey.substring(0, 7)}...`}
              </Tip>
            </div>
            {/* ) : (
              entry.dataKey
            )} */}
          </span>
        </li>
      ))}
    </div>
  );
  const CustomLegendForSummary = (e) => (
    <div
      style={{
        fontFamily: 'arial',
        fontWeight: 'bold',
        fontSize: 12,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      {e.payload.map((entry, index) => (
        <li key={index} style={{ listStyleType: 'none' }}>
          <span
            className="legend-icon"
            style={{ ...legendIconStyle, backgroundColor: entry.color }}
          />
          <span style={legendTextStyle}>Category</span>
        </li>
      ))}
    </div>
  );
  // const [hoveredLabel, setHoveredLabel] = React.useState(null);
  const shortenLabel = (label) => {
    const maxLength = 8; // Set the maximum length you want
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  };

  return (
    <Card sx={{ height: 515 }}>
      <div
        style={{
          padding: 10,
          borderBottom: '1px solid #f1f3f4',
          backgroundColor: '#F4F4F4',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <b>Category</b>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Selectbox
            size="small"
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
            // MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            <MenuItem value="summary">Summary</MenuItem>
            <MenuItem value="trend">Trend</MenuItem>
          </Selectbox>
          {datesObject && (
            <LoadingButton
              // style={{ marginTop: 20, marginLeft: '7rem' }}
              disabled={formattedData.length <= 0}
              size="small"
              // loading={downloadStart}
              onClick={(e) => downloadChart('keywords')}
              variant="primary"
            >
              <Icon icon={download} />
            </LoadingButton>
          )}
        </div>
      </div>
      {/* {defaultCategoryData || categoryData ? ( */}
      {chartType === 'trend' && (
        <>
          {formattedData && formattedData.length > 0 ? (
            <ResponsiveContainer width="100%" height="86%">
              <BarChart
                data={formattedData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis
                  dataKey="date"
                  fontFamily="Roboto, sans-serif"
                  angle={-35} // Rotate labels vertically
                  reversed
                  fontWeight="bold"
                  tick={{
                    fontFamily: 'cursive, sans-serif',
                    fontSize: 10,
                    fontWeight: 'bold'
                  }}
                  tickLine={false}
                  // onMouseEnter={(value) => setHoveredLabel(value)}
                  tickFormatter={(value) => shortenLabel(value)}
                >
                  {/* <Label
              value={hoveredLabel} // Display full label in the Label component
              position="insideTop"
              fontSize={12}
              fill="#8884d8"
            /> */}
                </XAxis>
                <YAxis
                  // dataKey="values"
                  label={{
                    value: 'Count',
                    angle: -90,
                    position: 'insideLeft',
                    fontWeight: 'bold',
                    color: 'grey'
                  }}
                  tick={{ fontFamily: 'cursive, sans-serif', fontSize: 10, fontWeight: 'bold' }}
                  tickLine={false}
                  fontWeight="bold"
                />

                {data.map((entry, index) => (
                  <>
                    <Tooltip content={<CustomTooltip props={entry.name} />} />
                    {/* <Tooltip /> */}
                    <Bar
                      key={index}
                      barSize={25}
                      dataKey={entry.name}
                      onClick={() => {
                        console.log('entry.name', `${entry.name}`);
                        handlePopup(categoryDate, entry.name);
                      }}
                      stackId="a"
                      fill={getNextColor()}
                      onMouseOver={() => {
                        setBarName(entry.name);
                      }}
                      style={{ opacity: 0.9, borderRadius: 5, cursor: 'pointer' }}
                    />
                  </>
                ))}
                <Legend
                  content={<CustomLegend />}
                  layout="vertical"
                  align="right"
                  verticalAlign="center"
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <div
              style={{
                width: '100%',
                height: '80%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <span style={{ fontSize: 20 }}>
                <b>No Category</b>
              </span>
            </div>
          )}
        </>
      )}
      {chartType === 'summary' && (
        <>
          {chartDataForSummary && chartDataForSummary.length > 0 ? (
            <ResponsiveContainer width="100%" height="86%">
              <BarChart
                data={chartDataForSummary}
                margin={{
                  right: 30,
                  left: 20
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis
                  dataKey="category"
                  fontFamily="Roboto, sans-serif"
                  angle={-40} // Rotate labels vertically
                  height={50}
                  reversed
                  fontWeight="bold"
                  tick={{
                    fontFamily: 'cursive, sans-serif',
                    fontSize: 9,
                    fontWeight: 'bold'
                  }}
                  tickLine={false}
                  tickFormatter={shortenLabel}
                >
                  {/* <Label
            value="Months"
            offset={-3}
            position="insideBottom"
            fontSize={12}
            fontWeight="bold"
            color="grey"
            style={{ zIndex: 1000 }}
          /> */}
                </XAxis>
                <YAxis
                  // dataKey="values"
                  label={{
                    value: 'Count',
                    angle: -90,
                    position: 'insideLeft',
                    fontWeight: 'bold',
                    color: 'grey'
                  }}
                  tick={{ fontFamily: 'cursive, sans-serif', fontSize: 10, fontWeight: 'bold' }}
                  tickLine={false}
                  fontWeight="bold"
                />
                <Tooltip content={CustomTooltipForSummary} />
                {/* <Tooltip /> */}
                <Bar
                  // key={index}
                  barSize={25}
                  dataKey="count"
                  onClick={(e) => handlePopupForSummary(e)}
                  stackId="a"
                  fill={getNextColor()}
                  onMouseOver={(e) => {
                    // console.log(e);
                    // setBarName(entry.name);
                  }}
                  style={{ opacity: 0.9, borderRadius: 5, cursor: 'pointer' }}
                />
                <Legend content={<CustomLegendForSummary />} />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <div
              style={{
                width: '100%',
                height: '80%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <span style={{ fontSize: 20 }}>
                <b>No Category</b>
              </span>
            </div>
          )}
        </>
      )}
      {/* </Card> */}
      <CustomFormModel
        fullScreen={fullScreen}
        open={open}
        title="Category Details"
        handleDialog={handleDialog}
      >
        <Container
          style={{
            padding: 10
          }}
        >
          <TableContainer sx={{ minWidth: '100%', maxHeight: 390 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}>
                    Agent Name
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}>
                    Date
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}>
                    Words
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}>
                    File Name
                  </TableCell>
                </TableRow>
              </TableHead>
              {Category && (
                <TableBody>
                  {Category.map((row, index) => {
                    const {
                      agent_name,
                      word_count,
                      s3url,
                      input_id,
                      request_id,
                      org_id,
                      customer_id,
                      agent_id
                    } = row;
                    // console.log('org_id', org_id, customer_id, agent_id);
                    // console.log('word_count', word_count);
                    const arr = [];
                    if (word_count) {
                      arr.push(word_count);
                    } else {
                      arr.push({});
                    }

                    return (
                      <>
                        <TableRow hover key={1} tabIndex={-1} role="checkbox">
                          {/* <TableCell align="left">{agent_name}</TableCell> */}
                          {arr.map((element, index) => {
                            const obj = element;
                            const array = Object.keys(element).filter(
                              (item) => element[item] !== 0
                            );
                            // console.warn('element ---', element);
                            // console.warn('array ----', array);
                            if (array.length > 0) {
                              return <TableCell align="left">{agent_name}</TableCell>;
                            }
                            return null;
                          })}
                          {arr.map((element, index) => {
                            const obj = element;
                            const array = Object.keys(element).filter(
                              (item) => element[item] !== 0
                            );
                            // console.warn('element ---', element);
                            // console.warn('array ----', array);
                            if (array.length > 0) {
                              return (
                                <>
                                  <TableCell>21/06/2023</TableCell>
                                  <TableCell component="th" scope="row" align="center">
                                    {array.map((unpWord) => (
                                      <Badge badgeContent={obj[unpWord]} color="primary">
                                        <Chip label={unpWord} />
                                      </Badge>
                                    ))}
                                  </TableCell>
                                  <TableCell align="center">
                                    <Link underline="hover" sx={{ cursor: 'pointer' }}>
                                      <Tip
                                        title={decodeURI(
                                          s3url.split('/').pop().split('#')[0].split('?')[0]
                                        )}
                                        followCursor
                                        placement="top"
                                      >
                                        <Typography sx={{ fontWeight: 600 }}>
                                          {`${decodeURI(
                                            s3url.split('/').pop().split('#')[0].split('?')[0]
                                          ).slice(0, 14)}.....`}
                                        </Typography>
                                      </Tip>
                                    </Link>
                                  </TableCell>
                                </>
                              );
                            }
                            return null;
                          })}
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Container>
      </CustomFormModel>
    </Card>
  );
};

export default StackBarForCategory;
