import React from 'react';
import { Typography } from '@mui/material';
import { ComposedChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer, LabelList } from 'recharts';

function BarChartUBI({ data }) {
  // const ChartData = [
  //   { name: '(Blank)', AI_Average_Score: 100, Manual_Average_Score: 89.13 },
  //   {
  //     name: '61 to 180 Days',
  //     AI_Average_Score: 76.28,
  //     Manual_Average_Score: 89.13
  //   },
  //   {
  //     name: '0.5 to 1 Year',
  //     AI_Average_Score: 74.13,
  //     Manual_Average_Score: 92.31
  //   },
  //   { name: '1 to 2 Years', AI_Average_Score: 77.03, Manual_Average_Score: 89.66 },
  //   { name: '> 2 Years', AI_Average_Score: 73.73, Manual_Average_Score: 85.35 }
  // ];
  const renderLabelList = (props) => {
    const { x, y, width, height, value } = props;

    return (
      <g>
        {/* <rect
          x={x + width * 0.96 - 15} // Adjust this value to set horizontal padding
          y={y} // Adjust this value to set vertical padding
          width={50} // Adjust this value to set the width based on padding
          height={22} // Adjust this value to set the height based on padding
          fill="white" // Background color for padding effect
        /> */}
        <text
          x={x + width * 0.9}
          y={y + 7}
          fill="black"
          textAnchor="middle"
          dominantBaseline="middle"
          fontWeight="bold"
          fontSize="11px"
        >
          {value}
        </text>
      </g>
    );
  };
  const renderYaxisLabel = (props) => {
    const { x, y, stroke, payload } = props;
    // function truncateText(text, maxLength) {
    //   if (text.length > maxLength) {
    //     return `${text.substring(0, 5)}...`;
    //   }
    //   return text;
    // }

    const text = payload.value;

    return (
      <g transform={`translate(${x},${y})`}>
        <title>{text}</title> {/* Tooltip with the full text */}
        {/* <text
          x={-8}
          y={0}
          dy=".3em"
          textAnchor="end"
          dominantBaseline="inherit"
          fill="#666"
          fontWeight="bold"
          fontSize="10px"
        > */}
        <text
          x={-2}
          y={0}
          // dy={16}
          textAnchor="end"
          dominantBaseline="inherit"
          fill="#666"
          fontWeight="bold"
          fontSize="10px"
        >
          {text.length > 15 ? `${text.substring(0, 15)}...` : text}
        </text>
      </g>
    );
  };
  function CustomLegend({ payload }) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{ display: 'flex', alignItems: 'center', marginRight: '10px', padding: '5px' }}
          >
            <div
              style={{
                width: '10px', // Adjust the width to set the circle size
                height: '10px', // Adjust the height to set the circle size
                backgroundColor: entry.color,
                borderRadius: '50%',
                marginRight: '5px'
              }}
            />
            <Typography style={{ fontSize: '13px' }}>{entry.value}</Typography>
          </div>
        ))}
      </div>
    );
  }

  return (
    <ResponsiveContainer width="100%" height="85%">
      {data && data.length > 0 ? (
        <ComposedChart
          layout="vertical"
          // width={500}
          // height={250}
          data={data}
          margin={{
            top: 5,
            // right: 5,
            bottom: 5,
            left: 35
          }}
          barGap={0} // Set barGap to 0 to remove gap between bars
          // barCategoryGap={0}
        >
          {/* <CartesianGrid stroke="#f5f5f5" /> */}
          <XAxis hide type="number" />
          <YAxis
            tickLine={false}
            axisLine={false}
            tick={renderYaxisLabel}
            interval={0}
            // tick={{ stroke: 'black', strokeWidth: 1 }}
            dataKey="name"
            type="category"
            scale="auto"
          />
          {/* <Tooltip /> */}
          <Legend verticalAlign="top" align="left" content={<CustomLegend />} />

          {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
          <Bar dataKey="ai_average_score" barSize={20} fill="#4a90e2">
            <LabelList dataKey="ai_average_score" content={renderLabelList} />
          </Bar>
          <Bar dataKey="average_manual_score" barSize={20} fill="#162fbc">
            <LabelList dataKey="average_manual_score" content={renderLabelList} />
          </Bar>
          {/* <Line dataKey="uv" stroke="#ff7300" /> */}
        </ComposedChart>
      ) : (
        <div
          style={{
            width: '100%',
            height: '90%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <span style={{ fontSize: 20 }}>
            <b>No Data Available</b>
          </span>
        </div>
      )}
    </ResponsiveContainer>
  );
}

export default BarChartUBI;
