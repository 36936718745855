/* eslint-disable   no-unneeded-ternary */
/* eslint-disable   no-nested-ternary */
import * as Yup from 'yup';
import { filter, update } from 'lodash';
import { Icon } from '@iconify/react';
import { useFormik, FormikProvider, Form } from 'formik';
import { useState, useRef, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import uploadLoop from '@iconify/icons-line-md/upload-loop';
import downloadLoop from '@iconify/icons-line-md/download-loop';
import refreshIcon from '@iconify/icons-mdi/refresh';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import PasswordIcon from '@mui/icons-material/Password';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import VerifiedIcon from '@mui/icons-material/Verified';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// material
import {
  InputAdornment,
  Card,
  Table,
  Stack,
  Pagination,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Skeleton,
  TablePagination,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Tabs,
  Tab,
  Grid,
  Box,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import Lottie from 'react-lottie';
import FileSaver from 'file-saver';
import removeIcon from '@iconify/icons-mdi/remove';
// components
import API from '../E2E/axios.util';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserEditForm, UserForm, UploadUserVoice } from '../components/custom-form-model/CRUDForms';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import { SmallCustomFormModel } from '../components/custom-form-model/SmallCustomFormModel';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
import { Notification } from '../components/Notification/Notification';
import UploadGif from '../components/animate/animations/upload.json';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#1D9FD9' // change this to your desired color
    }
  },
  tab: {
    '&.Mui-selected': {
      color: '#1D9FD9' // change this to your desired color
    }
  },
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: '#3892CF',
        color: 'white'
        // borderRadius: '50%',
      }
    }
  }
}));

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function User() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [userSearchData, setuserSearchData] = useState();
  const [OrgData, setOrgData] = useState(null);
  const [RoleData, setRoleData] = useState(null);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [isUpdate, setIsUpdate] = useState(0);
  const [userSearch, setUserSearch] = useState();
  const [query, setQuery] = useState();
  const [userRoleName, setUserRoleName] = useState(null);
  const [tabRoleValue, setTabRoleValue] = useState('Customer_Admin');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  /* ------ Creating state for getUser api ------ */
  const [userList, setuserList] = useState();
  const [userCount, setuserCount] = useState();

  const userDetails = JSON.parse(localStorage.getItem('user'));

  let USER_TABLE_HEAD;

  if (tabRoleValue !== 'Agent') {
    USER_TABLE_HEAD = [
      { id: 'userId', label: 'User ID', alignRight: false },
      tabRoleValue !== 'Guest' && { id: 'employeeId', label: 'Employe ID', alignRight: false },
      { id: 'date', label: 'Created Date', alignRight: false },
      { id: 'user_name', label: 'User Name', alignRight: false },
      { id: 'Organisation', label: 'Organisation', alignRight: false },
      { id: 'customer', label: 'Customer', alignRight: false },
      // { id: 'role', label: 'Role', alignRight: false },
      { id: 'email', label: 'Email', alignRight: false },
      { id: 'process', label: 'Process', alignRight: false },
      { id: 'editmenu' }
    ];
  } else {
    USER_TABLE_HEAD = [
      { id: 'userId', label: 'User ID', alignRight: false },
      { id: 'employeeId', label: 'Employe ID', alignRight: false },
      { id: 'date', label: 'Created Date', alignRight: false },
      { id: 'user_name', label: 'User Name', alignRight: false },
      { id: 'Organisation', label: 'Organisation', alignRight: false },
      { id: 'customer', label: 'Customer', alignRight: false },
      // { id: 'role', label: 'Role', alignRight: false },
      { id: 'qa_id', label: 'QA', alignRight: false },
      { id: 'manager_id', label: 'Manager', alignRight: false },
      { id: 'email', label: 'Email', alignRight: false },
      { id: 'process', label: 'Process', alignRight: false },
      { id: 'editmenu' },
      { id: 'upload' }
    ];
  }

  // let initialValues = {
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   process: [],
  //   organisation: `${userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : ''}`,
  //   role: null,
  //   contactNumber: null,
  //   userVoice: '',
  //   userPic: '',
  //   customer: null,
  //   employeeId: '',
  //   language: '',
  //   qa_id: null,
  //   manager_id: null
  // };

  const UserSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{3,29}$/,
        'First name should be 3 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'First name should be 3 to 30 character long.')
      .required('This field is required.'),
    lastName: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{3,29}$/,
        'Last name should be 3 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'Last name should be 3 to 30 character long.')
      .required('This field is required.'),
    email: Yup.string()
      .email('Email must be a valid email address.')
      .matches(/^[\w-.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, 'Email must be a valid email address.')
      .required('This field is required.'),
    organisation: Yup.string().nullable().required('This field is required.'),
    role: Yup.string().nullable().required('This field is required.'),
    process: Yup.array().min(1, 'This field is required.'),
    customer: Yup.string().nullable().required('This field is required.'),
    contactNumber: Yup.string()
      .matches(/^((\+91-?)|\+)?[0-9][0-9]{9}$/, 'Invalid contact number.')
      .nullable(),
    employeeId: Yup.string()
      .max(20, 'ID should be 2 to 20 character long.')
      .matches(/^[a-zA-Z0-9]+$/, 'Employe ID should only contain numbers and alphabets.')
      .required('This field is required.'),
    language: Yup.string().required('This field is required.'),
    dateOfJoining: Yup.string().required('This field is required.')
  });

  const userAgentSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{3,29}$/,
        'First name should be 3 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'First name should be 3 to 30 character long.')
      .required('This field is required.'),
    lastName: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{3,29}$/,
        'Last name should be 3 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'Last name should be 3 to 30 character long.')
      .required('This field is required.'),
    email: Yup.string()
      .email('Email must be a valid email address.')
      .matches(/^[\w-.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, 'Email must be a valid email address.')
      .required('This field is required.'),
    organisation: Yup.string().nullable().required('This field is required.'),
    role: Yup.string().nullable().required('This field is required.'),
    process: Yup.array().min(1, 'This field is required.'),
    customer: Yup.string().nullable().required('This field is required.'),
    contactNumber: Yup.string()
      .matches(/^((\+91-?)|\+)?[0-9][0-9]{9}$/, 'Invalid contact number.')
      .nullable(),
    employeeId: Yup.string()
      .max(20, 'ID should be 2 to 20 character long.')
      .matches(/^[a-zA-Z0-9]+$/, 'Employe ID should only contain numbers and alphabets.')
      .required('This field is required.'),
    language: Yup.string().required('This field is required.'),
    dateOfJoining: Yup.string().required('This field is required.'),
    qa_id: Yup.string().nullable().required('This field is required.'),
    manager_id: Yup.string().nullable().required('This field is required.')
  });

  // useEffect(() => {
  //   if (userRoleName === 'Agent') {
  //     initialValues = {
  //       ...initialValues,
  //       qa_id: null,
  //       manager_id: null
  //     };
  //   } else {
  //     const { qa_id, manager_id, ...restValues } = initialValues;
  //     initialValues = restValues;
  //   }
  // }, [userRoleName]);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      process: [],
      organisation: `${userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : ''}`,
      role: null,
      contactNumber: null,
      userVoice: [],
      userPic: '',
      customer:
        userDetails.role === 'Guest' ||
        userDetails.role === 'QA_admin' ||
        userDetails.role === 'QA' ||
        userDetails.role === 'Agent' ||
        userDetails.role === 'Customer_Admin'
          ? userDetails.customer_id
          : null,
      employeeId: '',
      language: '',
      dateOfJoining: '',
      qa_id: userDetails.role === 'QA' ? userDetails.user_id : null,
      qa_name: userDetails.role === 'QA' ? `${userDetails.first_name}${userDetails.last_name}` : '',
      manager_name: '',
      manager_id: null
    },
    validationSchema: userRoleName === 'Agent' ? userAgentSchema : UserSchema,
    onSubmit: async () => {
      const token = localStorage.getItem('token').toString();
      const data = {
        first_name: formik.values.firstName,
        last_name: formik.values.lastName,
        email: formik.values.email,
        organisation_id: formik.values.organisation,
        role_id: formik.values.role,
        mobile: formik.values.contactNumber,
        customer_id: formik.values.customer,
        profile_picture: formik.values.userPic,
        voice_sample: formik.values.userVoice,
        processes: formik.values.process,
        employeeID: formik.values.employeeId,
        language: formik.values.language,
        qa_id: formik.values.qa_id,
        qa_name: formik.values.qa_name,
        manager_name: formik.values.manager_name,
        manager_id: formik.values.manager_id,
        date_of_joining: formik.values.dateOfJoining
      };

      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      await API.post('/secure/register_user', data, config)
        .then((res) => {
          formik.resetForm();
          setUserRoleName(null);
          setAlertContent(`Success : ${res.data.message}`);
          setAlert(true);
          setAlertType('success');
          setIsUpdate(isUpdate + 1);
          handleDialog();
        })
        .catch((err) => {
          setAlertContent(`Error : ${err.response.data.message}`);
          setAlert(true);
          setAlertType('error');
        });
    }
  });
  const formikExcel = useFormik({
    initialValues: {
      userExcel: []
    },
    onSubmit: async () => {
      const token = localStorage.getItem('token').toString();
      const data = {
        user_excel: formikExcel.values.userExcel
      };

      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      await API.post('/secure/upload_user_excel', data, config)
        .then((res) => {
          formikExcel.resetForm();
          setAlertContent(`Success : ${res.data.message}`);
          setAlert(true);
          setAlertType('success');
          setIsUpdate(isUpdate + 1);
          handleDialogForExcel();
        })
        .catch((err) => {
          setAlertContent(`Error : ${err.response.data.message}`);
          setAlert(true);
          setAlertType('error');
        });
    }
  });

  const handleGetUser = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_user?role_name=${tabRoleValue}&page=${page}`, config)
      .then((res) => {
        // setPage(1);
        setuserList(res.data);
        setAlertContent(`Success: User list fetch successfully.`);
        setAlertType('success');
        setAlert(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log('errrrrrao', err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
        setLoading(false);
      });
  };
  const handleGetUserCount = async () => {
    // setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/count_list_user`, config)
      .then((res) => {
        // setPage(1);
        setuserCount(res.data.count_of_users);
        setAlertContent(`Success: ${res.data.message}.`);
        setAlertType('success');
        setAlert(true);
        // setLoading(false);
      })
      .catch((err) => {
        console.log('errrrrrao', err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
        // setLoading(false);
      });
  };

  useEffect(() => {
    if (query?.length === 0 || !query) {
      handleGetUser();
      handleGetUserCount();
    }
  }, [isUpdate, tabRoleValue, query, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDialog = () => {
    formik.resetForm();
    setOpen(!open);
  };
  const handleDialogForExcel = () => {
    formikExcel.resetForm();
    setQaExcel({
      File_Name: 'Upload File',
      Data: null
    });
    setuserExcel({
      File_Name: 'Upload File',
      Data: null
    });
    setUploadOpen(!uploadOpen);
  };

  const handleAlert = () => {
    setAlert(false);
  };

  const handleSearch = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/user_search?role_name=${tabRoleValue}&page=${page}&query=${query}`,
      config
    )
      .then((res) => {
        setUserSearch(res.data);
        setLoading(false);
        console.log('search', res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    if (query?.length > 0) {
      handleSearch();
    }
    console.log('query', query);
  }, [debouncedQuery, isUpdate, tabRoleValue, page]);

  // tabs
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setuserList();
    setValue(newValue);
    setPage(1);
    console.log('newValue', newValue);
    if (newValue === 0) {
      setTabRoleValue('Customer_Admin');
    } else if (newValue === 1) {
      if (userDetails.role === 'QA') {
        setTabRoleValue('Agent');
      } else {
        setTabRoleValue('Manager');
      }
    } else if (newValue === 2) {
      if (userDetails.role === 'QA') {
        setTabRoleValue('QA');
      } else {
        setTabRoleValue('Agent');
      }
    } else if (newValue === 3) {
      setTabRoleValue('QA');
    } else if (newValue === 5) {
      setTabRoleValue('Guest');
    } else if (newValue === 4) {
      setTabRoleValue('QA_admin');
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const showToolTip = (value) => {
    if (value && value.length > 10) {
      return (
        <Tooltip title={value} followCursor placement="top">
          <TableCell align="left" sx={{ cursor: 'pointer' }}>
            <span>{value.slice(0, 10)}.....</span>
          </TableCell>
        </Tooltip>
      );
    }
    return <TableCell align="left">{value}</TableCell>;
  };

  const showToolProcessTip = (processes) => {
    if (processes.length > 2) {
      return (
        <Tooltip
          title={processes.map((element) => (
            <span>{element}, </span>
          ))}
          followCursor
          placement="top"
        >
          <TableCell align="left" sx={{ cursor: 'pointer' }}>
            <span>
              {processes[0]}, {processes[1]} .....
            </span>
          </TableCell>
        </Tooltip>
      );
    }
    return (
      <TableCell align="left">
        {processes.map((process) => (
          <span>{process} </span>
        ))}
      </TableCell>
    );
  };
  const uploadOptions = {
    loop: true,
    autoplay: true,
    animationData: UploadGif,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const [loadingForExcelUpload, setLoadingForExcelUpload] = useState(false);
  const handleExcelUpload = async () => {
    setLoadingForExcelUpload(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = {
      flag: QaExcel.Data === null ? 'agent' : userExcel.Data === null ? 'others' : '',
      bulkFile: formikExcel.values.userExcel
    };

    await API.post('/secure/add_bulk_user', data, config)
      .then((res) => {
        formikExcel.resetForm();
        setLoadingForExcelUpload(false);
        setAlertContent(`Success : ${res.data.message}`);
        setAlert(true);
        setAlertType('success');
        handleDialogForExcel();
      })
      .catch((err) => {
        setLoadingForExcelUpload(false);
        setAlertContent(`${err.response.data.message}`);
        setAlert(true);
        setAlertType('error');
      });
  };
  const handleSampleDownload = (value) => {
    // setLoadingForExcelUpload(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    API.get('/secure/bulk_download_sample', config)
      .then((res) => {
        formikExcel.resetForm();

        if (value === 'other') {
          FileSaver.saveAs(
            `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.sample_data.others_sample_file_path}`,
            res.data.sample_data.others_sample_file_path.split('/').pop()
          );
        } else if (value === 'agent') {
          FileSaver.saveAs(
            `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.sample_data.agent_sample_file_path}`,
            res.data.sample_data.agent_sample_file_path.split('/').pop()
          );
        }
        setAlertContent(`Success : ${res.data.message}`);
        setAlert(true);
        setAlertType('success');
      })
      .catch((err) => {
        setAlertContent(`${err.response.data.message}`);
        setAlert(true);
        setAlertType('error');
      });
  };
  const [Note, setNote] = useState();
  useEffect(() => {
    handleNote();
  }, []);

  const handleNote = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    API.get('/secure/bulk_download_sample', config)
      .then((res) => {
        setNote(res.data.sample_data.roles);
      })
      .catch((err) => {
        setAlertContent(`${err.response.data.message}`);
        setAlert(true);
        setAlertType('error');
      });
  };
  const [userExcel, setuserExcel] = useState({
    File_Name: 'Upload File',
    Data: null
  });
  const [QaExcel, setQaExcel] = useState({
    File_Name: 'Upload File',
    Data: null
  });
  const inputRef = useRef();
  const agentRef = useRef();
  const handleExcelFileChange = (name, event, fileName) => {
    // console.log('encountered');
    const file = event.target.files[0];
    if (file && (name === 'qa' || name === 'agent')) {
      console.log('fileReader', file);

      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const fileData = e.target.result;

        if (name === 'qa') {
          setQaExcel({ File_Name: fileName.name, Data: fileData });
          formikExcel.setFieldValue(`userExcel`, { File_Name: fileName.name, Data: fileData });
        }

        if (name === 'agent') {
          setuserExcel({ File_Name: fileName.name, Data: fileData });
          formikExcel.setFieldValue(`userExcel`, { File_Name: fileName.name, Data: fileData });
        }
      };

      fileReader.readAsDataURL(file);
    }
  };

  const changedDate = (date) => {
    const newdate = new Date(date);
    const formatStartDate = `${newdate.getDate()}-${
      newdate.getMonth() + 1
    }-${newdate.getFullYear()}`;
    return formatStartDate;
  };

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Users {userCount && `(${userCount?.total_user})`}
          </Typography>
          <div
            style={{
              width: 300,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              alignItems: 'center'
            }}
          >
            {userDetails.role === 'Customer_Admin' && (
              <Button
                variant="contained"
                onClick={handleDialogForExcel}
                to="#"
                startIcon={<Icon icon={uploadLoop} />}
                sx={{
                  backgroundColor: '#107c41',
                  '&:hover': {
                    backgroundColor: '#3AB170'
                  },
                  marginRight: '10px'
                }}
              >
                Upload Excel
              </Button>
            )}
            <Button
              variant="contained"
              onClick={handleDialog}
              to="#"
              startIcon={<Icon icon={plusFill} />}
              sx={{
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              New User
            </Button>
          </div>
        </Stack>
        <Tabs
          style={{ padding: 2, marginBottom: '8px' }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={classes.root}
        >
          <Tab
            label={`Customer Admin ${userCount ? `(${userCount?.users_count_cust_ad})` : ''}`}
            {...a11yProps(0)}
            className={classes.tab}
          />
          {userDetails.role !== 'QA' && (
            <Tab
              label={`Manager ${userCount ? `(${userCount?.users_count_manager})` : ''}`}
              {...a11yProps(1)}
              className={classes.tab}
            />
          )}

          <Tab
            label={`Agent ${userCount ? `(${userCount?.users_count_agent})` : ''}`}
            {...a11yProps(2)}
            className={classes.tab}
          />
          <Tab
            label={`QA ${userCount ? `(${userCount?.users_count_qa})` : ''}`}
            {...a11yProps(3)}
            className={classes.tab}
          />
          <Tab
            label={`QA Admin ${userCount ? `(${userCount?.users_count_qa_ad})` : ''}`}
            {...a11yProps(5)}
            className={classes.tab}
          />
          {userDetails.role === 'Super_Admin' && (
            <Tab
              label={`Guest ${userCount ? `(${userCount?.users_count_guest})` : ''}`}
              {...a11yProps(4)}
              className={classes.tab}
            />
          )}
        </Tabs>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            placeholder="Search"
            onFilterName={(e) => {
              // setLoading(true);
              setQuery(e.target.value);
              if (query?.length > 0) {
                setPage(1);
              }
            }}
          />
          <Container>
            <CustomFormModel
              fullScreen={fullScreen}
              handleDialog={handleDialog}
              formik={formik}
              open={open}
              title="Add New User"
            >
              <UserForm
                props={formik}
                handleDialog={handleDialog}
                userRoleName={userRoleName}
                setUserRoleName={setUserRoleName}
              />
            </CustomFormModel>
            <CustomFormModel
              // fullScreen={fullScreen}
              handleDialog={handleDialogForExcel}
              open={uploadOpen}
              title="Upload Excel For Users"
            >
              <FormikProvider
                values={formikExcel}
                // validationSchema={validationSchema}
                // onSubmit={formikExcel.handleSubmit}
              >
                <form>
                  {Note && (
                    <Typography
                      sx={{
                        fontSize: 14,
                        mb: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <b>Note</b>: Use Role Id's as follows for reference. Agent-
                      <b>{Note.Agent}</b>, QA-
                      <b>{Note.QA}</b>, Manager-
                      <b>{Note.Manager}</b>
                    </Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: 200,
                          border: '1px dashed black',
                          padding: 10,
                          paddingLeft: 30,
                          paddingRight: 30,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {/* <Lottie options={uploadOptions} height={170} width={155} /> */}
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                            marginBottom: 4
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => handleSampleDownload('other')}
                            to="#"
                            startIcon={<Icon icon={downloadLoop} />}
                            sx={{
                              backgroundColor: '#107c41',
                              '&:hover': {
                                backgroundColor: '#3AB170'
                              }
                            }}
                          >
                            Sample
                          </Button>
                        </div>
                        <Typography variant="body2" align="center" sx={{ mb: 3 }}>
                          <b style={{ color: 'red', fontSize: '14px' }}>
                            Click here to Upload QA,Manager Excel
                          </b>
                        </Typography>
                        <div
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <LoadingButton
                            variant="contained"
                            disabled={userExcel.Data !== null}
                            onClick={() => {
                              inputRef.current?.click();
                            }}
                            style={{
                              width: 350,
                              height: 53,
                              backgroundColor: userExcel.Data !== null ? '#E0E0E0' : '#3892CF',
                              '&:hover': {
                                backgroundColor: userExcel.Data !== null ? '#E0E0E0' : '#3892CF'
                              }

                              // '&:hover': {
                              //   backgroundColor: '#3892CF'
                              // }
                            }}
                          >
                            {!QaExcel.File_Name ? `Upload File` : `${QaExcel.File_Name}`}
                            <input
                              type="file"
                              // hidden
                              style={{ display: 'none' }}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              // multiple
                              ref={inputRef}
                              onChange={(event) => {
                                console.log('encountered');
                                handleExcelFileChange('qa', event, event.currentTarget.files[0]);
                              }}
                            />
                          </LoadingButton>
                          {QaExcel.File_Name !== 'Upload File' && (
                            <IconButton
                              onClick={() => {
                                setQaExcel({
                                  File_Name: 'Upload File',
                                  Data: null
                                });
                                formikExcel.setFieldValue(`userExcel`, {
                                  File_Name: 'Upload File',
                                  Data: null
                                });
                                inputRef.current.value = null;
                              }}
                            >
                              <Icon icon={removeIcon} sx={{ color: '#fffff' }} />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: 200,
                          border: '1px dashed black',
                          // padding: 10,
                          paddingLeft: 30,
                          paddingRight: 30,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                            marginBottom: 4
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => handleSampleDownload('agent')}
                            to="#"
                            startIcon={<Icon icon={downloadLoop} />}
                            sx={{
                              backgroundColor: '#107c41',
                              '&:hover': {
                                backgroundColor: '#3AB170'
                              }
                            }}
                          >
                            Sample
                          </Button>
                        </div>
                        {/* <Lottie options={uploadOptions} height={170} width={155} /> */}
                        <Typography variant="body2" align="center" sx={{ mb: 5 }}>
                          <b style={{ color: 'red', fontSize: '14px' }}>
                            Click here to Upload Agent Excel
                          </b>
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <LoadingButton
                            variant="contained"
                            disabled={QaExcel.Data !== null}
                            onClick={() => {
                              agentRef.current?.click();
                            }}
                            style={{
                              width: 350,
                              height: 53,
                              backgroundColor: QaExcel.Data !== null ? '#E0E0E0' : '#3892CF',
                              '&:hover': {
                                backgroundColor: QaExcel.Data !== null ? '#E0E0E0' : '#3892CF'
                              }
                            }}
                          >
                            {!userExcel.File_Name ? `Upload File` : `${userExcel.File_Name}`}
                            <input
                              type="file"
                              hidden
                              accept="excel/*"
                              // multiple
                              ref={agentRef}
                              onChange={(event) => {
                                handleExcelFileChange('agent', event, event.currentTarget.files[0]);
                              }}
                            />
                          </LoadingButton>
                          {userExcel.File_Name !== 'Upload File' && (
                            <IconButton
                              onClick={() => {
                                setuserExcel({
                                  File_Name: 'Upload File',
                                  Data: null
                                });
                                formikExcel.setFieldValue(`userExcel`, {
                                  File_Name: 'Upload File',
                                  Data: null
                                });
                                agentRef.current.value = null;
                              }}
                            >
                              <Icon icon={removeIcon} sx={{ color: '#fffff' }} />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton
                      variant="contained"
                      onClick={handleDialogForExcel}
                      style={{
                        marginRight: 5,
                        backgroundColor: '#3892CF',
                        '&:hover': {
                          backgroundColor: '#3892CF'
                        }
                      }}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      variant="contained"
                      // type="submit"
                      onClick={handleExcelUpload}
                      loading={loadingForExcelUpload}
                      style={{
                        marginRight: 5,
                        backgroundColor:
                          userExcel.Data === null && QaExcel.Data === null ? '#E0E0E0' : '#3892CF',
                        '&:hover': {
                          backgroundColor:
                            userExcel.Data === null && QaExcel.Data === null ? '#E0E0E0' : '#3892CF'
                        }
                        // backgroundColor: '#3892CF',
                        // '&:hover': {
                        //   backgroundColor: '#3892CF'
                        // }
                      }}
                      disabled={userExcel.Data === null && QaExcel.Data === null}
                    >
                      Upload
                    </LoadingButton>
                  </Container>
                </form>
              </FormikProvider>
            </CustomFormModel>
          </Container>

          <Scrollbar>
            <TableContainer sx={{ minWidth: '100%' }}>
              <Table>
                <UserListHead headLabel={USER_TABLE_HEAD} rowCount={page} />
                {(query && !loading && userSearch && (
                  <TableBody>
                    {userSearch.results
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          user_id,
                          organisation_name,
                          customer_organisation_name,
                          first_name,
                          last_name,
                          user_email,
                          user_mobile,
                          role_name,
                          processes,
                          empcode,
                          customer_first_name,
                          customer_last_name,
                          created_date,
                          qa_name,
                          manager_name
                        } = row;
                        return (
                          <TableRow hover key={1} tabIndex={-1} role="checkbox">
                            <TableCell align="center">{user_id}</TableCell>
                            {/* <TableCell align="left">{empcode}</TableCell> */}
                            {showToolTip(empcode)}
                            <TableCell align="left">
                              {created_date ? changedDate(created_date) : ''}
                            </TableCell>
                            <TableCell align="left">
                              {first_name} {last_name}
                            </TableCell>

                            {showToolTip(organisation_name)}
                            <TableCell align="left">{customer_organisation_name}</TableCell>
                            {/* <TableCell align="left">{role_name}</TableCell> */}
                            {tabRoleValue === 'Agent' && (
                              <TableCell align="left">{qa_name}</TableCell>
                            )}
                            {tabRoleValue === 'Agent' && (
                              <TableCell align="left">{manager_name}</TableCell>
                            )}

                            {showToolTip(user_email)}
                            {/* {showToolTip(user_mobile)} */}
                            {showToolProcessTip(processes)}

                            <TableCell align="right">
                              <UserMoreMenu
                                tabRoleValue={tabRoleValue}
                                formik={formik}
                                isUpdated={isUpdate}
                                updateComponent={setIsUpdate}
                                setAlert={setAlert}
                                setAlertContent={setAlertContent}
                                setAlertType={setAlertType}
                                userId={user_id}
                                userDetails={row}
                              />
                            </TableCell>
                            {tabRoleValue === 'Agent' && (
                              <TableCell
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: 50,
                                  height: '100%'
                                }}
                              >
                                <UserVoiceMenu
                                  formik={formik}
                                  isUpdated={isUpdate}
                                  updateComponent={setIsUpdate}
                                  setAlert={setAlert}
                                  setAlertContent={setAlertContent}
                                  setAlertType={setAlertType}
                                  userId={user_id}
                                  userDetails={row}
                                  tabRoleValue={tabRoleValue}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                )) ||
                  (!loading && userList && userList.results.length > 0 ? (
                    <TableBody>
                      {userList.results
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const {
                            user_id,
                            organisation_name,
                            customer_organisation_name,
                            first_name,
                            last_name,
                            user_email,
                            user_mobile,
                            role_name,
                            processes,
                            empcode,
                            customer_first_name,
                            customer_last_name,
                            created_date,
                            qa_name,
                            manager_name
                          } = row;
                          return (
                            <TableRow hover key={1} tabIndex={-1} role="checkbox">
                              <TableCell align="center">{user_id}</TableCell>
                              {/* <TableCell align="left">{empcode}</TableCell> */}
                              {showToolTip(empcode)}
                              <TableCell align="left">
                                {created_date ? changedDate(created_date) : ''}
                              </TableCell>

                              {/* <TableCell align="left">
                                {first_name} {last_name}
                              </TableCell> */}
                              {showToolTip(`${first_name} ${last_name}`)}
                              {showToolTip(organisation_name)}
                              <TableCell align="left">{customer_organisation_name}</TableCell>
                              {/* <TableCell align="left">{role_name}</TableCell> */}
                              {tabRoleValue === 'Agent' && (
                                <TableCell align="left">{qa_name}</TableCell>
                              )}
                              {tabRoleValue === 'Agent' && (
                                <TableCell align="left">{manager_name}</TableCell>
                              )}

                              {showToolTip(user_email)}
                              {/* {showToolTip(user_mobile)} */}
                              {showToolProcessTip(processes)}

                              <TableCell align="right">
                                <UserMoreMenu
                                  tabRoleValue={tabRoleValue}
                                  formik={formik}
                                  isUpdated={isUpdate}
                                  updateComponent={setIsUpdate}
                                  setAlert={setAlert}
                                  setAlertContent={setAlertContent}
                                  setAlertType={setAlertType}
                                  userId={user_id}
                                  userDetails={row}
                                />
                              </TableCell>
                              {tabRoleValue === 'Agent' && (
                                <TableCell
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 50,
                                    height: '100%'
                                  }}
                                >
                                  <UserVoiceMenu
                                    formik={formik}
                                    isUpdated={isUpdate}
                                    updateComponent={setIsUpdate}
                                    setAlert={setAlert}
                                    setAlertContent={setAlertContent}
                                    setAlertType={setAlertType}
                                    userId={user_id}
                                    userDetails={row}
                                    tabRoleValue={tabRoleValue}
                                  />
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        {loading ? (
                          <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                            <CircularProgress sx={{ color: '#3892CF' }} />
                          </TableCell>
                        ) : (
                          <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                            Data Not Found
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          </Scrollbar>
          <Stack
            spacing={2}
            sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
          >
            <Pagination
              classes={{
                root: classes.ul
              }}
              count={
                query && userSearch
                  ? Math.ceil(userSearch.count / 10)
                  : Math.ceil(userList?.count / 10)
              }
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Stack>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            // count={!loading && userList?.user_list ? userList?.user_list?.length : 0}
            count={
              query
                ? userSearch
                  ? Math.ceil(userSearch.users?.length)
                  : 1
                : userList
                ? Math.ceil(userList.user_list?.length)
                : 1
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
      <Notification
        message={alertContent || 'User list fetch succesfully'}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}

function UserMoreMenu({
  tabRoleValue,
  formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  userId,
  userDetails
}) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(null);

  const handleDialog = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };

  const handleChangePasswordDialog = async (id) => {
    console.log('password changed', id);
    setPasswordOpen(!passwordOpen);
    setIsOpen(false);
  };

  const handleUserDelete = async (id) => {
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.delete(`/secure/deactivate_user?id=${id}`, config)
      .then((res) => {
        setAlertContent('Success: User Deleted Successfully.');
        setAlertType('success');
        setAlert(true);
      })
      .then(() => {
        updateComponent(isUpdated + 1);
        setIsOpen(false);
      })
      .catch((err) => {
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={openEdit}
        title="Update User"
      >
        <UserEditForm
          tabRoleValue={tabRoleValue}
          formik={formik}
          isUpdated={isUpdated}
          updateComponent={updateComponent}
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          userDetails={userDetails}
          handleDialog={handleDialog}
          userId={userId}
        />
      </CustomFormModel>

      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleChangePasswordDialog}
        open={passwordOpen}
        title="Change Password"
        // height="200"
      >
        <Box sx={{ marginTop: 5 }}>
          <ChangedPassword
            tabRoleValue={tabRoleValue}
            formik={formik}
            isUpdated={isUpdated}
            updateComponent={updateComponent}
            setAlert={setAlert}
            setAlertContent={setAlertContent}
            setAlertType={setAlertType}
            userDetails={userDetails}
            handleDialog={handleChangePasswordDialog}
            userId={userId}
          />
        </Box>
      </CustomFormModel>

      <IconButton ref={ref} onClick={() => setIsOpen(true)} disabled={userId === 1}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={handleDialog}
          disabled={userDetails.role_name === 'Guest'}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }} onClick={(e) => handleUserDelete(userId)}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {/* {userDetails.role === 'Super_Admin' ? ( */}
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={(e) => handleChangePasswordDialog(userId)}
        >
          <ListItemIcon>
            <PasswordIcon />
          </ListItemIcon>
          <ListItemText primary="Change Password" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {/* ) : (
          ' null '
        )} */}
      </Menu>
    </>
  );
}

function UserVoiceMenu({
  formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  userId,
  userDetails,
  tabRoleValue
}) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(null);

  const handleDialog = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };

  const handleUserDelete = async (id) => {
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.delete(`/secure/deactivate_user?id=${id}`, config)
      .then((res) => {
        setAlertContent('Success: User Deleted Successfully.');
        setAlertType('success');
        setAlert(true);
      })
      .then(() => {
        updateComponent(isUpdated + 1);
        setIsOpen(false);
      })
      .catch((err) => {
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  return (
    <>
      <SmallCustomFormModel
        // fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={isOpen}
        title="Upload User Voice"
      >
        <UploadUserVoice
          formik={formik}
          isUpdated={isUpdated}
          updateComponent={updateComponent}
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          userDetails={userDetails}
          handleDialog={handleDialog}
          userId={userId}
        />
      </SmallCustomFormModel>

      {/* <CustomFormModel
fullScreen={fullScreen}
handleDialog={handleDialog}
formik={formik}
open={open}
title="Upload User Voice"
>
<UploadUserAudio
  props={formik}
  handleDialog={handleDialog}
  userRoleName={userRoleName}
  setUserRoleName={setUserRoleName}
/>
</CustomFormModel> */}

      {tabRoleValue === 'Agent' && userDetails?.filelist?.length > 0 ? (
        <IconButton>
          <Tooltip title="Voice Recognised succesfully">
            <CheckCircleOutlineIcon style={{ color: '#0ecf0e' }} disabled />
          </Tooltip>
        </IconButton>
      ) : (
        tabRoleValue === 'Agent' && (
          <IconButton ref={ref} onClick={() => setIsOpen(true)} disabled={userId === 1}>
            <FileUploadIcon
              style={{
                cursor: 'pointer',
                color: '#3892CF'
              }}
              width={20}
              height={20}
            />
          </IconButton>
        )
      )}

      {/* <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem to="#" sx={{ color: 'text.secondary' }} onClick={handleDialog}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }} onClick={(e) => handleUserDelete(userId)}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu> */}
    </>
  );
}

export const ChangedPassword = ({
  handleDialog,
  tabRoleValue,
  setAlert,
  setAlertContent,
  setAlertType,
  userDetails,
  userId
}) => {
  const PassSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        'Your password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters.'
      )
      .required('Password is required.'),
    confirmpassword: Yup.string()
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        'Your password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters.'
      )
      .required('Password is required.')
      .oneOf([Yup.ref('password')], 'Both password need to be the same.')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmpassword: ''
    },
    validationSchema: PassSchema,
    onSubmit: async () => {
      await handleRequest(formik);
    }
  });

  const handleRequest = async (formik) => {
    // console.log('passed changes user', `${userId}`);
    const data = {
      new_password: formik.values.password,
      user_id: userId || JSON.parse(localStorage.getItem('user')).user_id
      // confirmpassword:formik.values.confirmpassword,
    };
    // console.log('dataa', dataa);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.post('/secure/change_user_password', data, config)
      .then((res) => {
        setAlertContent(res.data.message);
        setAlertType('success');
        setAlert(true);
        formik.resetForm();
        // navigate('/login');
        handleDialog();
      })
      .catch((error) => {
        // setAlertContent(error.response.data.message);
        // setAlertType('error');
        // setAlert(true);
      });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <Grid Container align="center">
      <Grid item>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ maxWidth: '400px' }}>
            <TextField
              fullWidth
              required
              size="small"
              name="password"
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              // type="password"
              label="New password"
              {...getFieldProps('password')}
              value={formik.values.password}
              variant="outlined"
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {/* <Icon icon={showPassword ? <Visibility /> : <VisibilityOff />} /> */}
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Box sx={{ mt: 3, mb: 5 }}>
              <TextField
                fullWidth
                required
                name="confirmpassword"
                size="small"
                type={showConfirmPassword ? 'text' : 'password'}
                // type="password"
                label="Confirm password"
                value={formik.values.confirmpassword}
                variant="outlined"
                {...getFieldProps('confirmpassword')}
                error={Boolean(touched.confirmpassword && errors.confirmpassword)}
                helperText={touched.confirmpassword && errors.confirmpassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              Change Password
            </Button>
            {/* <Notification
              message={alertContent ? alertContent : `Success : Data Fetched Successfully.`}
              open={alert}
              severity={alertType}
              onClose={handleAlert}
            /> */}
          </Form>
        </FormikProvider>
      </Grid>
    </Grid>
  );
};
