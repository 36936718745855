import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import arrowLeft from '@iconify/icons-mdi/arrow-left';
import {
  Box,
  Container,
  Typography,
  Paper as Paperbox,
  Grid,
  Tabs,
  Tab,
  Card,
  CardHeader,
  Skeleton,
  TextField,
  Stack
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { grey } from '@mui/material/colors';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import API from '../E2E/axios.util';
import { Notification } from '../components/Notification/Notification';
import inputContext from '../Context/UploadContext/inputContext';

export default function Action() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const { inputId } = useContext(inputContext);
  const [actionData, setactionData] = useState();
  const [actionArray, setactionArray] = useState(null);
  const [loading, setLoading] = useState(false);

  let dataForAction;

  if (inputId) {
    sessionStorage.setItem('actionData', JSON.stringify([inputId]));
    dataForAction = JSON.parse(sessionStorage.getItem('actionData'));
  } else {
    dataForAction = JSON.parse(sessionStorage.getItem('actionData'));
  }

  const getAction = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/generate_actions?id=${dataForAction[0]}`, config)
      .then((res) => {
        setactionData(res.data.result);
        setactionArray(res.data.result.actions);
        setAlertContent(`Success: Action data fetch successfully.`);
        setAlertType('success');
        setAlert(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAction();
  }, []);

  // handle click event of the Add button
  const handleAddClick = () => {
    setactionArray([...actionArray, '']);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...actionArray];
    list.splice(index, 1);
    setactionArray(list);
  };

  const handleAlert = () => {
    setAlert(false);
  };

  return (
    <Page
      title="Actions"
      style={{
        height: '100%',
        width: '100%',
        display: 'flex'
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Paperbox elevation={0} style={{ marginBottom: 10 }}>
              <LoadingButton
                startIcon={<Icon icon={arrowLeft} />}
                variant="text"
                color="primary"
                size="large"
                onClick={() => navigate('/dashboard/workspace/upload', { replace: true })}
              >
                Back
              </LoadingButton>
            </Paperbox>
          </Grid>
        </Grid>
        <Card>
          <CardHeader title="Actions" />
          <Box sx={{ p: 3, pb: 1, margin: '5px' }} dir="ltr">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box
                  style={{
                    padding: '2rem',
                    height: 400,
                    overflow: 'auto',
                    backgroundColor: '#fafafa'
                  }}
                >
                  <Typography variant="h6" component="h2" sx={{ marginBottom: '0.5rem' }}>
                    Conversation between Agent and Client
                  </Typography>
                  {actionData ? (
                    actionData?.action_frames.map((item, index) => {
                      const speaker = Object.keys(item);
                      return (
                        <>
                          <Typography variant="body2" component="p">
                            <strong>{speaker[0]} :</strong> {item[speaker[0]]}
                          </Typography>
                          <Typography variant="body2" component="p">
                            <strong>{speaker[2]} :</strong> {item[speaker[2]]}
                          </Typography>
                        </>
                      );
                    })
                  ) : (
                    <Stack spacing={1}>
                      <Skeleton variant="rectangular" width="100%" height={500} />
                    </Stack>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box style={{ padding: '2rem', height: 400, overflow: 'auto' }}>
                  <Typography variant="h6">Select Action</Typography>
                  {actionArray ? (
                    actionArray.map((action, index) => {
                      console.log('API action ---', action);
                      return (
                        <Box
                          sx={{
                            marginY: '0.5rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <Checkbox />
                          <TextField
                            fullWidth
                            multiline
                            rows={3}
                            size="small"
                            defaultValue={action}
                            // onChange={(e, index) => }
                          />
                          <Box
                            sx={{ color: 'text.secondary', paddingX: '0.5rem', cursor: 'pointer' }}
                            onClick={(index) => handleRemoveClick(index)}
                          >
                            <Icon icon={trash2Outline} width={24} height={24} />
                          </Box>
                        </Box>
                      );
                    })
                  ) : (
                    <Stack spacing={1}>
                      <Skeleton variant="rectangular" width="100%" height={500} />
                    </Stack>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                  }}
                >
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    title="Add Row"
                    onClick={handleAddClick}
                    disabled={loading}
                  >
                    <AddIcon />
                  </Fab>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Box
          sx={{
            padding: '1 rem',
            marginY: '1rem',
            height: 50,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          <LoadingButton variant="contained" style={{ marginRight: 5 }}>
            Submit
          </LoadingButton>
        </Box>
      </Container>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}
