import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  subtitle
} from '@mui/material';
import Chip from '@mui/material/Chip';

export const ProfileHeader = ({ orgData, userData, roleData }) => {
  console.log('first', userData);
  let organisation;
  let role;

  if (orgData && userData && roleData) {
    organisation = orgData?.find((data) => data.organisation_id === userData.organisation_id);
    role = roleData?.find((data) => data.role_id === userData.role_id);
  }

  const userDetails = JSON.parse(localStorage.getItem('user'));

  return (
    <Card>
      <CardContent
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Avatar
            src={
              userDetails.profile_pic !== 'profile picture not given'
                ? `${process.env.REACT_APP_SERVER_MEDIA_URL}${userDetails.profile_pic}`
                : '/static/mock-images/avatars/avatar_23.jpg'
            }
            sx={{
              height: 64,
              mb: 2,
              width: 64,
              borderRadius: 3
            }}
          />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: 2
            }}
          >
            <Typography color="textPrimary" gutterBottom variant="h6">
              {userData?.first_name} {userData?.last_name}
            </Typography>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="subtitle"
              style={{ textAlign: 'center' }}
            >
              {organisation?.organisation_name}
            </Typography>
          </Box>
        </Box>
        <Chip label={role?.role_name} sx={{ color: '#3892CF' }} />
      </CardContent>
      <Divider />
    </Card>
  );
};
