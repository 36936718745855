/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import Select, { components } from 'react-select';
// material
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
// components
import Page from '../components/Page';
import API from '../E2E/axios.util';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import { SmallCustomFormModel } from '../components/custom-form-model/SmallCustomFormModel';
import UploadForm from '../layouts/Upload/UploadForm';
import UploadFormGuest from '../layouts/Upload/UploadFormGuest';
import { UploadDataModel } from '../layouts/Upload/UploadDataModel';
import { Notification } from '../components/Notification/Notification';
import useWebSocket from '../hooks/useWebSocket';
import inputContex from '../Context/UploadContext/inputContext';
import DrawerFilters from '../components/FilterButton';

// ----------------------------------------------------------------------

export default function UploadContent() {
  const locationData = useLocation();
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState();
  const [update, setUpdate] = useState(0);
  const [uploadDataList, setuploadDataList] = useState(
    locationData &&
      locationData.state &&
      locationData.state.storedInfo &&
      locationData.state.storedInfo
      ? locationData.state.storedInfo.uploadDataList
      : null
  );
  const [uploadDataCount, setUploadDataCount] = useState(
    locationData &&
      locationData.state &&
      locationData.state.storedInfo &&
      locationData.state.storedInfo
      ? locationData.state.storedInfo.uploadDataCount
      : null
  );
  const [uploaded, setUploaded] = useState(null);
  const [filter, setFilter] = useState(
    locationData &&
      locationData.state &&
      locationData.state.storedInfo &&
      locationData.state.storedInfo.filterValues
      ? locationData.state.storedInfo.filterValues.filter
      : false
  );
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // Context API
  const { uploadStatus, setuploadStatus } = useContext(inputContex);

  const userDetails = JSON.parse(localStorage.getItem('user'));

  console.log('userDetails', userDetails);

  const UploadSchemma = Yup.object().shape({
    // uploadFile: Yup.object().required('Upload Source File.'),
    customer: Yup.string().required('This field is required.'),
    process: Yup.array().min(1, 'This field is required.'),
    organisationId: Yup.string().required('This field is required.'),
    agent: Yup.string().required('This field is required.'),
    // campaign: Yup.string().required('This field is required.'),
    createDate: Yup.date().required('This field is required.')
    // disposition: Yup.string().required('This field is required.')
    // .max(new Date(), intl.formatMessage({ id: 'start_date.error.max' }))
  });

  function getTodayDate() {
    const today = new Date();

    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are zero-based, so we add 1
    const day = today.getDate();

    // Pad the month and day with leading zeros if needed
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    // Construct the formatted date string
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

    return formattedDate;
  }

  const todayDate = getTodayDate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      uploadFile: [],
      customer:
        userDetails.role === 'Guest' ||
        userDetails.role === 'QA' ||
        userDetails.role === 'Agent' ||
        userDetails.role === 'Customer_Admin'
          ? userDetails.customer_id
          : '',
      process:
        userDetails.role === 'Guest' || userDetails.role === 'Agent' ? userDetails.processes : [],
      organisationId: `${
        userDetails.role === 'Guest' ||
        userDetails.role !== 'Super_Admin' ||
        userDetails.role === 'Agent'
          ? userDetails.organisation_id
          : ''
      }`,
      domainId:
        userDetails.role === 'Guest' ||
        userDetails.role === 'QA' ||
        userDetails.role === 'Agent' ||
        userDetails.role === 'Customer_Admin'
          ? userDetails.domain_id
          : '',
      agent:
        userDetails.role === 'Guest' || userDetails.role === 'Agent' ? userDetails.user_id : '',
      qa_name:
        userDetails.role === 'Guest' || userDetails.role === 'Agent' ? userDetails.qa_name : '',
      manager_name:
        userDetails.role === 'Guest' || userDetails.role === 'Agent'
          ? userDetails.manager_name
          : '',
      createDate: userDetails.role === 'Guest' ? todayDate : '',
      disposition: '',
      campaign: null
    },
    validationSchema: userDetails.role === 'Guest' ? null : UploadSchemma,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        onUploadProgress: (data) => {
          setUploaded(Math.round((data.loaded / data.total) * 100));
          // console.log(Math.round((data.loaded / data.total) * 100));
          // console.log('dataUpload', data);
        }
      };
      const data = {
        inputFile: formik.values.uploadFile,
        customer_id: formik.values.customer,
        user_id: formik.values.agent,
        processes: formik.values.process,
        organisation_id: formik.values.organisationId,
        domain_id: formik.values.domainId,
        original_date: formik.values.createDate,
        campaign_id: formik.values.campaign,
        disposition: formik.values.disposition
        // qa_name: formik.values.qa_name,
        // manager_name: formik.values.manager_name
      };
      console.log('data ---------------------', data);
      await API.post('/secure/add_input', data, config)
        .then((res) => {
          formik.resetForm();
          // handleRunProcess(res.data.id);
          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          handleDialog();
          setUpdate(update + 1);
        })
        .catch((err) => {
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });

  // const handleRunProcess = async (inputId) => {
  //   inputId.forEach((element) => {
  //     const token = localStorage.getItem('token');
  //     const config = {
  //       headers: { Authorization: `Bearer ${token}` }
  //     };
  //     API.get(`/secure/generate_caption?id=${element}`, config)
  //       .then((res) => {
  //         setAlertContent(`${res.data.message}`);
  //         setAlertType('success');
  //         setAlert(true);
  //         handleDialog();
  //         setUpdate(update + 1);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setAlertContent(`${err.response.data.message}`);
  //         setAlertType('error');
  //         setAlert(true);
  //       });
  //   });
  // };

  const handleAlert = () => {
    setAlert(false);
  };

  const handleDialog = () => {
    setOpen(!open);
    formik.resetForm();
  };

  const useStyles = makeStyles((theme) => ({
    datePicker: {
      '& .MuiTextField-root': {
        width: 170
        // marginBottom: 1
        // height: 10
      },
      '& .MuiInputLabel-shrink': {
        color: '#3892CF' // Set your desired color for the shrunk label
      },
      '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#3892CF'
      }
    }
  }));

  const classes = useStyles();

  const customStyles = {
    option: (base, state) => ({
      ...base,
      fontWeight: state.isSelected ? '500' : 'normal',
      maxHeight: 35
    }),
    control: (base, state) => ({
      ...base,
      fontWeight: '500'
      // border: 'none'
      // maxHeight: 35
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: '2px',
      maxHeight: 38,
      overflow: 'auto'
    })
  };

  const [updatedDateFormat, setUpdatedDateFormat] = useState(
    locationData?.state?.storedInfo?.format
      ? locationData?.state?.storedInfo?.format
      : {
          value: 'one_week',
          label: 'One Week'
        }
  );
  const [fromDate, setFromDate] = useState(
    locationData?.state?.storedInfo?.startDate
      ? dayjs(locationData?.state?.storedInfo?.startDate)
      : null
  );
  const [page, setPage] = useState(
    locationData?.state?.storedInfo?.pageNo ? locationData?.state?.storedInfo?.pageNo : 1
  );
  const [toDate, setToDate] = useState(
    locationData?.state?.storedInfo?.endDate
      ? dayjs(locationData?.state?.storedInfo?.endDate)
      : null
  );

  const [dateSort, setDateSort] = useState(
    locationData?.state?.storedInfo?.dateSort ? locationData?.state?.storedInfo?.dateSort : 'desc'
  );
  const [sortForDate, setSortForDate] = useState(
    locationData?.state?.storedInfo?.sortForDate
      ? locationData?.state?.storedInfo?.sortForDate
      : false
  );
  const [sortForDuration, setSortForDuration] = useState(
    locationData?.state?.storedInfo?.sortForDuration
      ? locationData?.state?.storedInfo?.sortForDuration
      : false
  );
  const [durationSort, setDurationSort] = useState(
    locationData?.state?.storedInfo?.durationSort
      ? locationData?.state?.storedInfo?.durationSort
      : 'desc'
  );
  const [sortForProcessDate, setSortForProcessDate] = useState(
    locationData?.state?.storedInfo?.sortForProcessDate
      ? locationData?.state?.storedInfo?.sortForProcessDate
      : true
  );
  const [processSort, setProcessDateSort] = useState(
    locationData?.state?.storedInfo?.processSort
      ? locationData?.state?.storedInfo?.processSort
      : 'desc'
  );

  console.log('locationData', locationData);
  const [storedDataForFilter, setStoredDataForFilter] = useState(
    locationData &&
      locationData.state &&
      locationData.state.storedInfo &&
      locationData.state.storedInfo.filterValues
      ? locationData.state.storedInfo.filterValues
      : null
  );

  const dateFormat = [
    { name: 'One Week', id: 'one_week' },
    { name: 'One Month', id: 'one_month' },
    // { name: 'Six Month', id: 'six_month' },
    // { name: 'One Year', id: 'one_year' },
    { name: 'Customize', id: 'customized' }
  ];

  const CustomControlDate = ({ children, ...rest }) => {
    return (
      <components.Control {...rest}>
        <CalendarMonthIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
        {children}
      </components.Control>
    );
  };

  return (
    <Page title={userDetails.role === 'Agent' ? 'Calls' : 'Upload Content'}>
      <>
        <Grid container>
          <Grid item md={3} sm={3} xs={12}>
            <Typography variant="h4" gutterBottom>
              {userDetails.role === 'Agent' ? 'All Calls' : 'Upload Content'}
              {/* <Typography>
                {filter && updatedDateFormat.value === 'one_week'
                  ? 'Last 7 days calls'
                  : updatedDateFormat.value === 'one_month'
                  ? 'last one month calls'
                  : updatedDateFormat.value === 'six_month'
                  ? 'last six months calls'
                  : updatedDateFormat.value === 'one_year'
                  ? 'last year calls'
                  : updatedDateFormat.value === 'customized'
                  ? `${fromDate} to ${toDate} calls`
                  : null}
              </Typography> */}
            </Typography>
          </Grid>
          <Grid
            container
            item
            md={9}
            sm={9}
            xs={12}
            sx={{
              display: 'flex',
              // width: updatedDateFormat.label === 'Customize' ? '60%' : '28%',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
            spacing={1}
          >
            {!filter && (
              <>
                <Grid item>
                  <Select
                    styles={customStyles}
                    onChange={(e) => {
                      setUpdatedDateFormat(e);
                      setPage(1);
                      setFromDate(null);
                      setToDate(null);
                      // getReportDetails(e.value);
                    }}
                    options={dateFormat?.map((element) => ({
                      value: element.id,
                      label: element.name
                    }))}
                    value={updatedDateFormat}
                    isSearchable={false}
                    components={{ Control: CustomControlDate }}
                    placeholder={
                      <div
                        style={{
                          display: 'flex',
                          columnGap: 10,
                          alignItems: 'center',
                          color: '#000',
                          fontWeight: '400'
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item>
                  {updatedDateFormat.label === 'Customize' && (
                    <div style={{ marginBottom: '8px' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <div className={classes.datePicker}>
                            <DatePicker
                              label="From"
                              format="DD/MM/YYYY"
                              maxDate={dayjs()}
                              value={fromDate}
                              onChange={(newValue) => {
                                setToDate(null);
                                setFromDate(newValue);
                              }}
                              slotProps={{ textField: { size: 'small' } }}
                            />
                          </div>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  )}
                </Grid>
                <Grid item>
                  {updatedDateFormat.label === 'Customize' && (
                    <div style={{ marginBottom: '8px' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <div className={classes.datePicker}>
                            <DatePicker
                              label="To"
                              format="DD/MM/YYYY"
                              disabled={fromDate === null}
                              minDate={fromDate}
                              maxDate={dayjs()}
                              value={toDate}
                              onChange={(newValue) => {
                                setToDate(newValue);
                                // setPage(0);
                                // getReportDetails('customized', newValue);
                              }}
                              slotProps={{ textField: { size: 'small' } }}
                            />
                          </div>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  )}
                </Grid>
              </>
            )}
            {(userDetails?.Submenus.includes('Call_analysis') ||
              userDetails?.Submenus.includes('Compliance')) && (
              <Grid item>
                <DrawerFilters
                  uploadDataList={uploadDataList}
                  setuploadDataList={setuploadDataList}
                  filter={filter}
                  setFilter={setFilter}
                  setLoading={setLoading}
                  page={page}
                  setPage={setPage}
                  setAlert={setAlert}
                  setAlertType={setAlertType}
                  setAlertContent={setAlertContent}
                  setDateSort={setDateSort}
                  setDurationSort={setDurationSort}
                  dateSort={dateSort}
                  durationSort={durationSort}
                  sortForDate={sortForDate}
                  setSortForDate={setSortForDate}
                  sortForDuration={sortForDuration}
                  setSortForDuration={setSortForDuration}
                  sortForProcessDate={sortForProcessDate}
                  setSortForProcessDate={setSortForProcessDate}
                  processSort={processSort}
                  setProcessDateSort={setProcessDateSort}
                  storedDataForFilter={storedDataForFilter}
                  setStoredDataForFilter={setStoredDataForFilter}
                />
              </Grid>
            )}
            <Grid item>
              {userDetails.role !== 'Manager' && (
                <Button
                  variant="contained"
                  startIcon={<Icon icon={plusFill} />}
                  onClick={handleDialog}
                  disabled={userDetails.role === 'Agent'}
                  sx={{
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                >
                  Upload Data
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <UploadDataModel
          handleDialog={handleDialog}
          formik={formik}
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          isUpdated={update}
          updateComponent={setUpdate}
          updatedDateFormat={updatedDateFormat}
          fromDate={fromDate}
          toDate={toDate}
          page={page}
          setPage={setPage}
          filter={filter}
          setFilter={setFilter}
          uploadDataList={uploadDataList}
          setuploadDataList={setuploadDataList}
          uploadDataCount={uploadDataCount}
          setUploadDataCount={setUploadDataCount}
          loading={loading}
          setLoading={setLoading}
          setDateSort={setDateSort}
          setDurationSort={setDurationSort}
          dateSort={dateSort}
          durationSort={durationSort}
          sortForDate={sortForDate}
          setSortForDate={setSortForDate}
          sortForDuration={sortForDuration}
          setSortForDuration={setSortForDuration}
          sortForProcessDate={sortForProcessDate}
          setSortForProcessDate={setSortForProcessDate}
          processSort={processSort}
          setProcessDateSort={setProcessDateSort}
          storedDataForFilter={storedDataForFilter}
          setStoredDataForFilter={setStoredDataForFilter}
        />
        <Container>
          {userDetails.role === 'Guest' ? (
            <SmallCustomFormModel
              // fullScreen={fullScreen}
              handleDialog={handleDialog}
              open={open}
              title="Upload Content"
            >
              <UploadFormGuest handleDialog={handleDialog} formik={formik} uploaded={uploaded} />{' '}
            </SmallCustomFormModel>
          ) : (
            <CustomFormModel
              fullScreen={fullScreen}
              handleDialog={handleDialog}
              open={open}
              title="Upload Content"
            >
              <UploadForm handleDialog={handleDialog} formik={formik} uploaded={uploaded} />
            </CustomFormModel>
          )}
        </Container>
      </>
      <Notification
        message={alertContent || 'Upload list fetch successfully.'}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}
