// import * as htmlToImage from 'html-to-image';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { jsPDF as JSPdf } from 'jspdf';

export const handleDownload = (id, name, download) => {
  const input = document.getElementById(id);
  html2canvas(input, {
    dpi: 144,
    scale: 2
  }).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new JSPdf();
    if (download === 'png') {
      FileSaver.saveAs(imgData, `${name}.png`);
    } else if (download === 'pdf') {
      //   FileSaver.saveAs(blob, `${name}.pdf`);
      pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
      // pdf.output('dataurlnewwindow');
      pdf.save(`${name}.pdf`);
    }
  });
};
