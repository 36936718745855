/* eslint-disable use-isnan */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-key */
/* eslint-disable no-return-await */
/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-template */
/* eslint-disable  no-useless-concat */
import * as Yup from 'yup';
import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import moment from 'moment';
import {
  Grid,
  Box,
  Modal,
  Typography,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardHeader,
  Button,
  Container,
  Link,
  Stack,
  Select as Selectbox,
  InputLabel,
  FormControl,
  Menu,
  ListSubheader,
  ListItemText,
  MenuItem,
  FormHelperText,
  TextField,
  IconButton,
  Tooltip as Tip,
  CircularProgress,
  Hidden
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
  Cell
} from 'recharts';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Select, { components } from 'react-select';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import PortraitIcon from '@mui/icons-material/Portrait';
import LoopIcon from '@mui/icons-material/Loop';

import DatePicker from 'react-datepicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import './CSS/ArrowBox.css';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import API from '../E2E/axios.util';

import Page from '../components/Page';

// import DealForm from 'src/layouts/Deal/DealForm';

// import TreeMap from '../assets/TreeMap.png';

//  <---------------------------------------------------------->

const customStyles = {
  option: (base, state) => ({
    ...base,
    fontWeight: state.isSelected ? '500' : 'normal',
    maxHeight: 35
  }),
  control: (base, state) => ({
    ...base,
    fontWeight: '500',
    border: 'none'
    // maxHeight: 35
  }),
  valueContainer: (base, state) => ({
    ...base,
    padding: '2px',
    maxHeight: 38,
    overflow: 'auto'
  })
};
const CustomControlOrg = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <CorporateFareIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );
};
const CustomControlCustomer = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <PortraitIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );
};
const CustomControlProcess = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <LoopIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );
};
const CustomControlAgent = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <SupportAgentIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );
};

const CustomControlDate = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <CalendarMonthIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );
};

// -------------------------------------------------------------------

export default function AgentDashboard() {
  const useStyles = makeStyles(() => ({
    submitButton: {
      width: '100%',
      backgroundColor: '#3892CF',
      '&:hover': {
        backgroundColor: '#3892CF'
      }
      // [theme.breakpoints.down('md')]: {
      //   marginTop: 5
      // }
    }
  }));
  const classes = useStyles();

  // States Required For Formik
  const [organisationList, setOrganisationList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [organisation, setOrganisation] = useState({ value: 'all', label: 'All' });
  const [defaultCustomer, setdefaultCustomer] = useState({ value: 'all', label: 'All' });
  const [defaultProcess, setdefaultProcess] = useState([]);
  const [defaultAgent, setdefaultAgent] = useState([]);
  const [agentIds, setAgentId] = useState([]);
  const [defaultDate, setDefaultDate] = useState({ value: 'one_week', label: 'One Week' });
  const [agentList, setAgentList] = useState([]);
  const [processList, setprocessList] = useState([]);

  // States for Graphs,tables,charts
  const [performanceData, setPerformanceData] = useState();
  // creating function for fetching default dashboard api
  const weekBeforeDate = new Date(Date.now() - 604800000);
  const presentDate = new Date(Date.now());
  const setWeekBeforeDate = `${weekBeforeDate.getFullYear()}-${
    weekBeforeDate.getMonth() + 1
  }-${weekBeforeDate.getDate()}`;
  const setPresentDate = `${presentDate.getFullYear()}-${
    presentDate.getMonth() + 1
  }-${presentDate.getDate()}`;
  const [checkInDate, setCheckInDate] = useState(weekBeforeDate);
  const [checkOutDate, setCheckOutDate] = useState(presentDate);
  const [popupData, setPopupData] = useState([]);
  console.log('popuData', popupData);
  const [agentName, setAgentName] = useState('');

  const setDateFormat = (date) => {
    const formatStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return formatStartDate;
  };

  const handleCheckInDate = (date) => {
    setCheckInDate(date);
    setCheckOutDate('');
    formik.setFieldValue('endDate', '');
    const start_date = setDateFormat(date);
    formik.setFieldValue('startDate', start_date);
  };

  const handleCheckOutDate = (date) => {
    setCheckOutDate(date);
    const start_date = setDateFormat(date);
    formik.setFieldValue('endDate', start_date);
  };

  const CustomInput = React.forwardRef((props, ref) => (
    <div style={{ position: 'relative' }}>
      <input {...props} ref={ref} />
      <CalendarMonthIcon
        onClick={props.onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '10px',
          cursor: 'pointer',
          zIndex: 0
        }}
      />
    </div>
  ));

  // <-------------------------------------------------->

  const userDetails = JSON.parse(localStorage.getItem('user'));

  const AgentDashboardSchema = Yup.object().shape({
    organisation: Yup.string().nullable().required('This field is required.'),
    // customer: Yup.string().nullable().required('This field is required.'),
    // format: Yup.string().nullable().required('This field is required.'),
    // process: Yup.string().required('This field is required.').nullable(),
    // agent: Yup.Array().nullable().required('This field is required.'),
    startDate: Yup.date().required('This field is required.'),
    endDate: Yup.date().required('This field is required.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      organisation: `${
        userDetails.role !== 'Super_Admin' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA'
          ? userDetails.organisation_id
          : organisation?.value
          ? organisation.value
          : ''
      }`,
      customer:
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'Customer_Admin'
          ? userDetails.customer_id
          : defaultCustomer
          ? defaultCustomer.value
          : null,
      process: defaultProcess.length > 0 ? defaultProcess.map((el) => el.value) : [],
      format: defaultDate ? defaultDate.value : 'one_week',
      // agent: agentIds,
      startDate: setWeekBeforeDate,
      endDate: setPresentDate
    },
    validationSchema: AgentDashboardSchema,
    onSubmit: async () => {
      console.log('values', formik.values);
      getPerformerData();
    }
  });

  // Default API for Top and bottom performer

  useEffect(() => {
    if (
      formik.values.organisation === 'all' ||
      formik.values.customer === 'all' ||
      userDetails.role === 'Customer_Admin' ||
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager'
    ) {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let url = `/secure/default_top_and_bottom_performance_agent?date=${formik.values.format}`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (userDetails.role === 'QA' || userDetails.role === 'Manager') {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      API.get(url, config)
        .then((res) => {
          setPerformanceData(res.data);
        })
        .catch((err) => {
          setPerformanceData();
          // console.log(err);
        });
    }
  }, []);

  // APIs After Submit
  const getPerformerData = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let url = `/secure/top_and_bottom_performance_agent?customer_id=${formik.values.customer}&date=${formik.values.format}&org_id=${formik.values.organisation}&processes=${formik.values.process}`;
    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(url, config)
      .then((res) => {
        setPerformanceData(res.data);
      })
      .catch((err) => {
        setPerformanceData();
        console.log(err);
      });
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  // <--------------------------------------------------------->

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${formik.values.organisation}`, config)
      .then((res) => {
        setCustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      formik.values.organisation !== '' &&
      formik.values.organisation !== null &&
      formik.values.organisation !== undefined &&
      formik.values.organisation !== 'all'
    ) {
      getCustomerList();
    } else {
      setCustomerList([]);
    }
  }, [formik.values.organisation]);

  // const getAgentList = async () => {
  //   const token = localStorage.getItem('token');
  //   const config = {
  //     headers: { Authorization: `Bearer ${token}` }
  //   };
  //   await API.get(
  //     `/secure/list_of_agent?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisation}&processes=${formik.values.process}`,
  //     config
  //   )
  //     .then((res) => {
  //       console.log('res compaign', res.data);
  //       setAgentList(res.data.agent_list);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   if (
  //     formik.values.process !== '' &&
  //     formik.values.process !== null &&
  //     formik.values.process !== undefined &&
  //     formik.values.process !== 'all'
  //   ) {
  //     getAgentList();
  //   } else {
  //     setAgentList([]);
  //   }
  // }, [formik.values.process]);

  const setCustomerValue = (e) => {
    setdefaultCustomer(e);
    formik.setFieldValue('customer', e.value);
  };

  const dateFormat = [
    { name: 'One Week', id: 'one_week' },
    { name: 'One Month', id: 'one_month' },
    // { name: 'Six Month', id: 'six_month' },
    // { name: 'One Year', id: 'one_year' },
    { name: 'Customize', id: 'customized' }
  ];
  // handle multiple processes
  const handleProcessChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === 'all')) {
      // const processes = processList.map((element) => {
      //   // console.log('element', element);
      //   return element;
      // });
      // const processString = processes.join(',');
      // let processArray = processString.split(',').map(Number);
      // if (processString === '') {
      //   processArray = [];
      // }
      // console.log('numbersArrayone', numbersArray);
      // console.log('agentList', agentList);
      formik.setFieldValue('process', processList);
      setdefaultProcess(
        processList.map((element) => ({
          value: element,
          label: element
        }))
      );
    } else {
      const processes = selectedOptions.map((element) => {
        // console.log('element', element);
        return element.value;
      });
      // console.log('userIds', userIds);
      // const processString = processes.join(',');
      // // console.log('userIdsString', userIdsString);
      // // console.log('selectedOptions', selectedOptions);
      // let processArray = processString.split(',').map(Number);
      // if (selectedOptions.length === 0) {
      //   processArray = [];
      // }
      // console.log('numbersArray', numbersArray);

      formik.setFieldValue('process', processes);

      setdefaultProcess(selectedOptions);
    }
  };

  // const handleProcess = (e) => {
  //   setdefaultProcess(e);
  //   formik.setFieldValue('process', e.value);
  // };
  const getProcessList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `/secure/process_list?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisation}`,
        config
      );
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.customer !== null && formik.values.customer !== 'all') {
      getProcessList();
    } else {
      setprocessList([]);
    }
  }, [formik.values.customer]);

  // multiple agent functionality

  const handleAgentChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === 'all')) {
      const userIds = agentList.map((element) => {
        return element.user_id;
      });
      const userIdsString = userIds.join(',');
      let numbersArray = userIdsString.split(',').map(Number);
      if (userIdsString === '') {
        numbersArray = [];
      }
      formik.setFieldValue('agent', numbersArray);
      setAgentId(numbersArray);
      setdefaultAgent(
        agentList.map((element) => ({
          value: element.user_id,
          label: `${element.first_name} ${element.last_name}`
        }))
      );
    } else {
      const userIds = selectedOptions.map((element) => {
        return element.value;
      });

      const userIdsString = userIds.join(',');
      let numbersArray = userIdsString.split(',').map(Number);
      if (selectedOptions.length === 0) {
        numbersArray = [];
      }
      setdefaultAgent(selectedOptions);
      formik.setFieldValue('agent', numbersArray);
      setAgentId(numbersArray);
    }
  };
  // ------------------------------------------------------------------
  // handle agent score analysis
  const handleAgentAnalysis = async (agentDetails) => {
    // console.log('agentDetails', agentDetails);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/agent_performance_drilldown?org_id=${agentDetails.organisation_id_id}&customer_id=${agentDetails.customer_id_id}&agent_id=${agentDetails.agent_id_id}&call_count=${agentDetails.call_count}&date=${formik.values.format}`;
    if (agentDetails.processes) {
      url += `&processes=${agentDetails.processes}`;
    }
    if (formik.values.format === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(url, config)
      .then((res) => {
        const combinedData = Object.keys(res.data.data).map((parameter) => ({
          parameter,
          score_percentage: res.data.data[parameter]
        }));
        // console.log('combinedData', res);
        setPopupData(combinedData);
        setAgentName(agentDetails.agent_name);
      })
      .catch((err) => {
        console.log(err);
        setPopupData([]);
        setAgentName('');
      });
  };
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      console.log('payload[0]', payload);

      // const words = Object.keys(payload[0].payload);
      return (
        <Card className="custom-tooltip" sx={{ padding: '10px' }}>
          {/* <p className="intro">{data === 'total' ? '' : `Date:${data}`}</p> */}
          <Typography className="intro" style={{ color: 'grey' }}>
            {data.parameter}
          </Typography>
          <Typography className="intro" style={{ color: 'grey' }}>
            {data.score_percentage}
          </Typography>
        </Card>
      );
    }
    return null;
  };
  // CSS for the circular legend marker
  const legendIconStyle = {
    width: '10px', // Adjust the size as needed
    height: '10px', // Adjust the size as needed
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '5px' // Adjust spacing
  };

  // Apply a cursive font-family to the Legend
  const legendTextStyle = {
    fontFamily: 'arial',
    fontWeight: 'bold',
    fontSize: 12
  };
  const CustomLegend = (e) => (
    <div
      style={{
        fontFamily: 'arial',
        fontWeight: 'bold',
        fontSize: 12,
        display: 'flex',
        justifyContent: 'center',
        marginTop: 25
      }}
    >
      {e.payload.map((entry, index) => (
        <li key={index} style={{ listStyleType: 'none' }}>
          <span
            className="legend-icon"
            style={{ ...legendIconStyle, backgroundColor: entry.color }}
          />
          <span style={legendTextStyle}>Parameter</span>
        </li>
      ))}
    </div>
  );

  const barColors = ['#1f77b4', '#ff7f0e', '#2ca02c'];
  // -------------------------------------
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleDialog = () => {
    setOpen(!open);
  };

  const shortenLabel = (label) => {
    const maxLength = 8; // Set the maximum length you want
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  };

  return (
    <Page title="Dashboard">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h4" gutterBottom>
          Agent Performance Dashboard
        </Typography>
      </Stack>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid
            container
            sx={{
              backgroundColor: '#f4f4f4',
              display: 'flex',
              justifyContent: 'space-between',
              padding: 1
            }}
          >
            <Grid item xs={12} sm={12} md={2.5}>
              {userDetails.role !== 'Super_Admin' ? (
                <>
                  <h5>
                    <b>Select Organisation *</b>
                  </h5>
                  <Selectbox required fullWidth size="small" defaultValue="all" disabled>
                    <MenuItem value="all" disabled>
                      {userDetails.organisation_name}
                    </MenuItem>
                  </Selectbox>
                </>
              ) : (
                <>
                  <h5>
                    <b>Select Organisation *</b>
                  </h5>
                  <Select
                    styles={customStyles}
                    onChange={(e) => {
                      setOrganisation(e);

                      setdefaultCustomer(null);
                      setdefaultProcess([]);
                      formik.setFieldValue('organisation', e.value);
                      formik.setFieldValue('customer', null);
                      formik.setFieldValue('process', []);
                    }}
                    options={organisationList?.map((element) => ({
                      value: element.organisation_id,
                      label: element.organisation_name
                    }))}
                    value={organisation}
                    style={{ color: '#000' }}
                    components={{ Control: CustomControlOrg }}
                    placeholder={
                      <div
                        style={{
                          display: 'flex',
                          columnGap: 10,
                          alignItems: 'center',
                          color: '#000',
                          fontWeight: '400'
                        }}
                      />
                    }
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={5.7} md={2.5}>
              {userDetails.role === 'QA' ||
              userDetails.role === 'Manager' ||
              userDetails.role === 'Customer_Admin' ? (
                <>
                  <h5>
                    <b>Select Customer *</b>
                  </h5>
                  <Selectbox required fullWidth size="small" defaultValue="all" disabled>
                    <MenuItem value="all" disabled>
                      {userDetails.customer_name}
                    </MenuItem>
                  </Selectbox>
                </>
              ) : (
                <>
                  <h5>
                    <b>Select Customer *</b>
                  </h5>
                  <Select
                    styles={customStyles}
                    onChange={(e) => {
                      setdefaultProcess([]);
                      formik.setFieldValue('process', []);
                      setprocessList([]);
                      setCustomerValue(e);
                    }}
                    options={customerList?.map((element) => ({
                      value: element.customer_id,
                      label: element.customer_organisation_name
                    }))}
                    value={defaultCustomer}
                    style={{ color: '#000' }}
                    components={{ Control: CustomControlCustomer }}
                    placeholder={
                      <div
                        style={{
                          display: 'flex',
                          columnGap: 10,
                          alignItems: 'center',
                          color: '#000',
                          fontWeight: '400'
                        }}
                      />
                    }
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={5.7} md={2.5}>
              <h5>
                <b>Select Process *</b>
              </h5>
              {/* <div style={{ width: '200px', maxHeight: 35 }}> */}
              <Select
                styles={customStyles}
                onChange={handleProcessChange}
                components={{ Control: CustomControlProcess }}
                isMulti
                options={
                  processList
                    ? [
                        { value: 'all', label: 'All' },
                        ...processList.map((element) => ({
                          value: element,
                          label: element
                        }))
                      ]
                    : [{ value: 'all', label: 'All' }]
                }
                value={defaultProcess}
                style={{ color: '#000' }}
                placeholder={
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      alignItems: 'center',
                      color: '#353535',
                      fontWeight: '500'
                    }}
                  >
                    {/* <img src={CorporateFareIcon} width={20} alt="" /> */}
                    {/* <SupportAgentIcon width={20} /> */}
                    {userDetails.role !== 'Super_Admin'
                      ? formik.values.customer === null || formik.values.customer === 'all'
                        ? 'All'
                        : ''
                      : formik.values.organisation === '' || formik.values.organisation === 'all'
                      ? 'All'
                      : ''}
                  </div>
                }
              />
              {/* </div> */}
            </Grid>
            {/* <Grid item xs={12} sm={5.7} md={2.5}>
              <h5>
                <b>Select Process *</b>
              </h5>
              <Select
                styles={customStyles}
                onChange={(e) => handleProcess(e)}
                components={{ Control: CustomControlProcess }}
                options={processList?.map((element) => ({
                  value: element,
                  label: element
                }))}
                value={defaultProcess}
                style={{ color: '#000' }}
                placeholder={
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      alignItems: 'center',
                      color: '#000',
                      fontWeight: '400'
                    }}
                  />
                }
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={5.7} md={2}>
                <h5>
                  <b>Select Agent *</b>
                </h5>
                <Select
                  styles={customStyles}
                  isMulti
                  onChange={handleAgentChange}
                  components={{ Control: CustomControlAgent }}
                  options={
                    agentList
                      ? [
                          { value: 'all', label: 'All' },
                          ...agentList.map((element) => ({
                            value: element.user_id,
                            label: `${element.first_name} ${element.last_name}`
                          }))
                        ]
                      : [{ value: 'all', label: 'All' }]
                  }
                  value={defaultAgent}
                  style={{ color: '#000' }}
                  placeholder={
                    <div
                      style={{
                        display: 'flex',
                        columnGap: 10,
                        alignItems: 'center',
                        color: '#353535',
                        fontWeight: '500'
                      }}
                    >
                      {userDetails.role !== 'Super_Admin'
                        ? formik.values.process === null || formik.values.process === 'all'
                          ? 'All'
                          : ''
                        : formik.values.organisation === '' || formik.values.organisation === 'all'
                        ? 'All'
                        : ''}
                    </div>
                  }
                />
              </Grid> */}
            <Grid item xs={12} sm={5.7} md={2.5}>
              <h5>
                <b>Select Date *</b>
              </h5>
              <Select
                styles={customStyles}
                onChange={(e) => {
                  formik.setFieldValue('date', e.value);
                  setDefaultDate(e);
                }}
                options={dateFormat?.map((element) => ({
                  value: element.id,
                  label: element.name
                }))}
                value={defaultDate}
                style={{ color: '#000' }}
                components={{ Control: CustomControlDate }}
                placeholder={
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      alignItems: 'center',
                      color: '#000',
                      fontWeight: '400'
                    }}
                  />
                }
              />
            </Grid>
            {formik.values.format !== 'customized' && (
              <Hidden mdDown>
                <Grid
                  item
                  xs={12}
                  sm={5.7}
                  md={0.7}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    type="submit"
                    className={classes.submitButton}
                    sx={{ marginTop: '15px' }}
                    size="medium"
                    variant="contained"
                    disabled={
                      formik.values.process?.length === 0 ||
                      formik.values.process === 'all' ||
                      formik.values.process === null
                    }
                  >
                    Submit
                  </Button>
                </Grid>
              </Hidden>
            )}
          </Grid>
          {formik.values.format === 'customized' && (
            <Grid container style={{ backgroundColor: '#f4f4f4' }}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 13,
                  paddingLeft: 8,
                  paddingRight: 9
                }}
              >
                <Grid item xs={12} sm={5.7} md={5.2}>
                  <h5 style={{ marginBottom: '-10px' }}>
                    <b>Start Date *</b>
                  </h5>
                  <DatePicker
                    className="my-datepicker-class"
                    selected={checkInDate}
                    onChange={handleCheckInDate}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomInput />}
                    placeholderText="Start date"
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                  />
                  <FormHelperText sx={{ marginLeft: '10px', color: '#d32f2f' }}>
                    {touched.startDate && errors.startDate}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={5.7} md={5.2}>
                  <h5 style={{ marginBottom: '-10px' }}>
                    <b>End Date *</b>
                  </h5>
                  <DatePicker
                    className="my-datepicker-class"
                    selected={checkOutDate}
                    maxDate={new Date()}
                    minDate={checkInDate}
                    onChange={handleCheckOutDate}
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomInput />}
                    placeholderText="End date"
                    error={Boolean(touched.endDate && errors.endDate)}
                    helperText={touched.endDate && errors.endDate}

                    // disabled
                  />
                  <FormHelperText sx={{ marginLeft: '10px', color: '#d32f2f' }}>
                    {touched.endDate && errors.endDate}
                  </FormHelperText>
                </Grid>
                <Hidden mdDown>
                  <Grid
                    item
                    xs={12}
                    sm={5.7}
                    md={0.7}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      type="submit"
                      className={classes.submitButton}
                      disabled={
                        formik.values.process?.length === 0 ||
                        formik.values.process === 'all' ||
                        formik.values.process === null
                      }
                      size="medium"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          )}
          <Hidden mdUp>
            <Grid
              item
              xs={12}
              sm={5.7}
              md={0.7}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                padding: 8
              }}
            >
              <Button
                type="submit"
                disabled={
                  formik.values.process?.length === 0 ||
                  formik.values.process === 'all' ||
                  formik.values.process === null
                }
                className={classes.submitButton}
                size="medium"
                variant="contained"
              >
                Submit
              </Button>
            </Grid>
          </Hidden>
        </Form>
      </FormikProvider>

      <Grid container spacing={2} sx={{ marginTop: 3 }}>
        <Grid item xs={12} sm={12} md={6}>
          <Card sx={{ height: 420, width: '100%' }}>
            <div
              style={{
                padding: 15,
                borderBottom: '1px solid #f1f3f4',
                backgroundColor: '#F4F4F4',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <b>Top 10 Performers</b>
            </div>
            {performanceData && performanceData.data.top_10.length > 0 ? (
              <TableContainer sx={{ maxHeight: 350, overflowX: 'hidden' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Agent Name
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Score (%)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {performanceData?.data.top_10.map((el) => (
                      <TableRow
                        onClick={() => {
                          handleAgentAnalysis(el);
                          handleDialog();
                        }}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            boxShadow: '0 0 11px rgba(33,33,33,.2)',
                            transform: 'scale3d(1.01, 1, 1)'
                          }
                        }}
                      >
                        <TableCell align="left">{el.agent_name}</TableCell>
                        <TableCell align="center">{Math.ceil(el.average_score)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '80%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <span style={{ fontSize: 20 }}>
                  <b>No Data Available</b>
                </span>
              </div>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Card sx={{ height: 420, width: '100%' }}>
            <div
              style={{
                padding: 15,
                borderBottom: '1px solid #f1f3f4',
                backgroundColor: '#F4F4F4',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <b>Bottom 10 Performers</b>
            </div>
            {performanceData && performanceData.data.bottom_10.length > 0 ? (
              <TableContainer sx={{ maxHeight: 350, overflowX: 'hidden' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Agent Name
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Score (%)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {performanceData?.data.bottom_10.map((el) => (
                      <TableRow
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            boxShadow: '0 0 11px rgba(33,33,33,.2)',
                            transform: 'scale3d(1.01, 1, 1)'
                          }
                        }}
                        onClick={() => {
                          handleAgentAnalysis(el);
                          handleDialog();
                        }}
                      >
                        <TableCell align="left">{el.agent_name}</TableCell>
                        <TableCell align="center">{Math.ceil(el.average_score)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '80%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <span style={{ fontSize: 20 }}>
                  <b>No Data Available</b>
                </span>
              </div>
            )}
          </Card>
        </Grid>
      </Grid>
      <Container>
        <CustomFormModel
          fullScreen={fullScreen}
          open={open}
          title="Agent Score Analysis"
          handleDialog={handleDialog}
        >
          {popupData.length > 0 ? (
            <>
              <Typography sx={{ marginBottom: 2 }}>
                <b>Agent Name: {agentName}</b>
              </Typography>
              <ResponsiveContainer width="100%" height="89%">
                <BarChart
                  data={popupData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20
                  }}
                >
                  <XAxis
                    dataKey="parameter"
                    fontFamily="Roboto, sans-serif"
                    angle={-40} // Rotate labels vertically
                    reversed
                    fontWeight="bold"
                    tick={{
                      fontFamily: 'cursive, sans-serif',
                      fontSize: 10,
                      fontWeight: 'bold'
                    }}
                    tickLine={false}
                    tickFormatter={shortenLabel}
                  />
                  <YAxis
                    label={{
                      value: 'Score Percentage',
                      angle: -90,
                      offset: 100, // Adjust this negative value to move the label towards the bottom
                      position: 'insideBottom',
                      fontWeight: 'bold',
                      color: 'grey'
                    }}
                    domain={[0, 100]}
                    tick={{ fontFamily: 'cursive, sans-serif', fontSize: 10, fontWeight: 'bold' }}
                    tickLine={false}
                    fontWeight="bold"
                  />
                  <Tooltip content={CustomTooltip} />

                  <Bar
                    // key={index}
                    barSize={popupData && popupData.length > 25 ? 20 : 25}
                    dataKey="score_percentage"
                    // onClick={(e) => handlePopupForSummary(e)}
                    stackId="a"
                    // onClick={(e) => console.log('guruu')}
                    // fill={(data) => {
                    //   console.log('guruu', data);
                    // }}
                    style={{ opacity: 0.9, borderRadius: 5 }}
                  >
                    {popupData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={entry.score_percentage > 80 ? '#3892cf' : 'red'}
                      />
                    ))}
                  </Bar>
                  <Legend content={<CustomLegend />} />
                </BarChart>
              </ResponsiveContainer>
            </>
          ) : (
            <>No data</>
          )}
        </CustomFormModel>
      </Container>
    </Page>
  );
}
