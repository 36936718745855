import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ComparativeAnalysis = ({ arrow, count, style }) =>
  arrow && (arrow === 'up' || arrow === 'zero') ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '3px',
        color: 'green',
        fontSize: '15px'
      }}
    >
      (<ArrowUpwardIcon sx={{ color: 'green', ...style }} />
      <span style={{ color: 'green', fontSize: '15px' }}>{count?.toFixed(1)}%</span>)
    </div>
  ) : (
    arrow === 'down' && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '3px',
          color: 'red',
          fontSize: '15px'
        }}
      >
        (<ArrowDownwardIcon sx={{ color: 'red', ...style }} />
        <span style={{ color: 'red', fontSize: style ? '15px' : '20px' }}>
          {count?.toFixed(1)}%
        </span>
        )
      </div>
    )
  );

export default ComparativeAnalysis;
