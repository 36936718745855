import * as React from 'react';
import { Chip, Stack } from '@mui/material';
import Page from '../../../components/Page';

export default function Keywords(data) {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  const keyword_data = data.data;
  console.log('keyword data -----', keyword_data);
  // console.log('key ----', Object.keys(keyword_data));

  return (
    <Page>
      {keyword_data && Object.keys(keyword_data)?.length > 0 ? (
        Object.keys(keyword_data).map((key, index) => (
          <Chip
            key={index}
            title={`${key} : ${keyword_data[key]}`}
            label={`${key} : ${keyword_data[key]}`}
            variant="filled"
            style={{
              backgroundColor: keyword_data[key] === 0 ? '#ff6666' : '#023972',
              color: keyword_data[key] === 0 ? 'Primary' : '#ffff',
              margin: '5px',
              width: 200
            }}
            size="medium"
          />
        ))
      ) : (
        <div
          style={{
            width: '90%',
            height: 200,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          No Keywords Found
        </div>
      )}
    </Page>
  );
}
