import {
  Card,
  Stack,
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
  Grid,
  Container,
  Pagination,
  CircularProgress
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select, { components } from 'react-select';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import API from '../../E2E/axios.util';
import TableComponent from '../TableComponent/TableComponent';

const date = null; // Placeholder for the date variable

const updatedDateFormat = { label: 'One Week' }; // Placeholder for updatedDateFormat
const dateFormat = [
  { id: 'one_week', name: 'One Week' },
  { name: 'One Month', id: 'one_month' },
  { id: 'customized', name: 'Customize' }
]; // Placeholder for dateFormat
const CustomControlDate = ({ children, ...rest }) => (
  <components.Control {...rest}>
    <CalendarMonthIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
    {children}
  </components.Control>
);

export default function UBITabs() {
  const [value, setValue] = useState(0);
  const [tabName, setTabName] = useState('tl');
  const [tablecolData, setTableColData] = useState([
    'tl_name',
    'ai_count',
    'average_ai_score',
    'manual_count',
    'average_manual_score'
  ]);
  const [defaultSelectDate, setDefaultSelectDate] = useState('one_week');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState();
  const [tableDataValue, setTableDataValue] = useState([]);
  const [pageCount, setPageCount] = useState();

  const [defaultDate, setDefaultDate] = useState({
    value: 'one_week',
    label: 'One Week'
  });

  const formatDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = `${inputDate.getFullYear()}-${(inputDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${inputDate.getDate().toString().padStart(2, '0')}`;
    return formattedDate;
  };

  const [downloadDetails, setDownloadDetails] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getScoreReportAPI = async (flag) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const initialUrl = `/secure/rolewise_quality_score?date=${defaultDate.value}&flag=${tabName}&page=${page}`;
    const datedUrl = `/secure/rolewise_quality_score?date=${
      defaultDate.value
    }&start_date=${formatDate(fromDate)}&end_date=${formatDate(
      toDate
    )}&flag=${tabName}&page=${page}`;
    if (defaultDate.value !== 'customized') {
      setLoading(true);
      await API.get(initialUrl, config)
        .then((res) => {
          setPageCount(res.data.count);
          setTableDataValue(res.data.results.data);
          setDownloadDetails(res.data.results.file_path);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setDownloadDetails();
        });
    } else if (defaultDate.value === 'customized' && toDate) {
      setLoading(true);
      await API.get(datedUrl, config)
        .then((res) => {
          setPageCount(res.data.count);
          setTableDataValue(res.data.results.data);
          setDownloadDetails(res.data.results.file_path);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setDownloadDetails();
        });
    }
  };

  useEffect(() => {
    if (defaultDate.value === 'customized') {
      getScoreReportAPI('tl');
    } else {
      getScoreReportAPI('tl');
    }
  }, [defaultDate, tabName, toDate, page]);

  const handleChange = (event, newValue) => {
    setPage(1);
    setValue(newValue);

    if (newValue === 0) {
      setTabName('tl');
      const managerColSeq = [
        'tl_name',
        'ai_count',
        'average_ai_score',
        'manual_count',
        'average_manual_score'
      ];
      setTableColData(managerColSeq);
    } else if (newValue === 1) {
      setTabName('agent');
      const agentColSeq = [
        'agent_name',
        'ai_count',
        'manual_count',
        'high_tone',
        'medium_tone',
        'low_tone',
        // 'emp_code',
        'average_ai_score',
        'average_manual_score'
      ];
      setTableColData(agentColSeq);
    } else if (newValue === 2) {
      setTabName('qa');
      const qaColSeq = [
        'qa_name',
        'ai_count',
        'manual_count',
        'average_ai_score',
        'average_manual_score'
      ];
      setTableColData(qaColSeq);
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTabs-indicator': {
        backgroundColor: '#1D9FD9' // change this to your desired color
      }
    },
    tab: {
      '&.Mui-selected': {
        color: '#1D9FD9' // change this to your desired color
      }
    },
    ul: {
      '& .MuiPaginationItem-root': {
        '&.Mui-selected': {
          background: '#3892CF',
          color: 'white'
        }
      }
    }
  }));
  const classes = useStyles();

  const customStyles = {
    option: (base, state) => ({
      ...base,
      fontWeight: state.isSelected ? '500' : 'normal',
      maxHeight: 35
    }),
    control: (base, state) => ({
      ...base,
      fontWeight: '500'
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: '2px',
      maxHeight: 38,
      overflow: 'auto'
    })
  };

  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Score Report
        </Typography>
        <Grid
          container
          spacing={0.8}
          md={9}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
            padding: 1
          }}
        >
          <div
            style={{
              display: 'flex',
              width: defaultDate.label === 'Customize' ? '90%' : '43%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Select
              styles={customStyles}
              onChange={(e) => {
                setPage(1);
                setDefaultDate(e);
                setFromDate(null);
                setToDate(null);
              }}
              options={dateFormat.map((element) => ({
                value: element.id,
                label: element.name
              }))}
              value={defaultDate}
              components={{ Control: CustomControlDate }}
              placeholder={
                <div
                  style={{
                    display: 'flex',
                    columnGap: 10,
                    alignItems: 'center',
                    color: '#000',
                    fontWeight: '400'
                  }}
                />
              }
              isSearchable={false}
              isClearable={false}
            />
            {defaultDate.label === 'Customize' && (
              <div style={{ marginBottom: '8px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <div className={classes.datePicker}>
                      <DatePicker
                        label="From"
                        format="DD/MM/YYYY"
                        maxDate={dayjs()}
                        value={fromDate}
                        onChange={(newValue) => {
                          setFromDate(newValue);
                          setToDate(null);
                        }}
                        slotProps={{ textField: { size: 'small' } }}
                      />
                    </div>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            )}
            {defaultDate.label === 'Customize' && (
              <div style={{ marginBottom: '8px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <div className={classes.datePicker}>
                      <DatePicker
                        label="To"
                        format="DD/MM/YYYY"
                        disabled={fromDate === null}
                        minDate={fromDate && fromDate}
                        maxDate={dayjs()}
                        value={toDate}
                        onChange={(newValue) => {
                          setToDate(newValue);
                        }}
                        slotProps={{ textField: { size: 'small' } }}
                      />
                    </div>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            )}
            <Button
              variant="contained"
              to="#"
              disabled={!downloadDetails}
              onClick={() => {
                if (downloadDetails) {
                  saveAs(
                    `${process.env.REACT_APP_SERVER_MEDIA_URL}${downloadDetails}`,
                    'Usage_Report.xlsx'
                  );
                }
              }}
              sx={{
                backgroundColor: '#107c41',
                '&:hover': {
                  backgroundColor: '#3AB170'
                }
              }}
            >
              Download Report
            </Button>
          </div>
        </Grid>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Tabs
          style={{ padding: 2, marginBottom: '8px' }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={classes.root}
        >
          <Tab label="Manager" className={classes.tab} />
          <Tab label="Agent" className={classes.tab} />
          <Tab label="QA" className={classes.tab} />
        </Tabs>
      </Stack>
      {!loading ? (
        <TableComponent
          TableinputData={tableDataValue}
          // tabName={tabName}
          tablecolData={tablecolData}
        />
      ) : (
        <Card
          sx={{
            width: '100%',
            height: 250,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress sx={{ color: '#3892CF' }} />
        </Card>
      )}
      <Stack
        spacing={2}
        sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
      >
        <Pagination
          classes={{
            root: classes.ul
          }}
          count={Math.ceil(pageCount ? pageCount / 10 : 0)}
          color="primary"
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Stack>
    </div>
  );
}
