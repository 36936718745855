/* eslint-disable   arrow-body-style */
/* eslint-disable   object-shorthand */
/* eslint-disable   no-nested-ternary */

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AspectRatio from '@mui/joy/AspectRatio';
import Drawer from '@mui/joy/Drawer';
import Button from '@mui/joy/Button';
import Avatar from '@mui/joy/Avatar';
import ListItemContent from '@mui/joy/ListItemContent';

import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails, { accordionDetailsClasses } from '@mui/joy/AccordionDetails';
import AccordionSummary, { accordionSummaryClasses } from '@mui/joy/AccordionSummary';

// import TapAndPlayRoundedIcon from '@mui/icons-material/TapAndPlayRounded';
// import EditNotificationsRoundedIcon from '@mui/icons-material/EditNotificationsRounded';
// import AdUnitsRoundedIcon from '@mui/icons-material/AdUnitsRounded';
// import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
// import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AbcIcon from '@mui/icons-material/Abc';
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
// import AccessibilityNewRoundedIcon from '@mui/icons-material/AccessibilityNewRounded';
import PercentIcon from '@mui/icons-material/Percent';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import FilterListIcon from '@mui/icons-material/FilterList';

import Checkbox from '@mui/joy/Checkbox';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import ModalClose from '@mui/joy/ModalClose';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { Select as JoySelect } from '@mui/joy';
import Option from '@mui/joy/Option';

import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Stack from '@mui/joy/Stack';

import makeStyles from '@mui/styles/makeStyles';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PortraitIcon from '@mui/icons-material/Portrait';
import LoopIcon from '@mui/icons-material/Loop';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid, Select as Selectbox, MenuItem } from '@mui/material';
import dayjs from 'dayjs';

import Select, { components } from 'react-select';

import Sheet from '@mui/joy/Sheet';
import Switch from '@mui/joy/Switch';
import Typography from '@mui/joy/Typography';
import TuneIcon from '@mui/icons-material/TuneRounded';

import Done from '@mui/icons-material/Done';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';

import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID
} from '@mui/material/styles';

// pages
import API from '../E2E/axios.util';

const materialTheme = materialExtendTheme();

export default function DrawerFilters({
  setuploadDataList,
  page,
  setPage,
  filter,
  setFilter,
  setLoading,
  setAlert,
  setAlertType,
  setAlertContent,
  dateSort,
  durationSort,
  sortForDate,
  sortForDuration,
  processSort,
  sortForProcessDate,
  storedDataForFilter,
  setStoredDataForFilter
}) {
  // const locationData = useLocation();
  // console.log('locationData', locationData);
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const [fromDate, setFromDate] = useState(
    storedDataForFilter?.fromDate ? dayjs(storedDataForFilter.fromDate) : null
  );
  console.log('newValue', fromDate);
  const [toDate, setToDate] = useState(
    storedDataForFilter?.toDate ? dayjs(storedDataForFilter.toDate) : null
  );
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState(
    storedDataForFilter ? storedDataForFilter.categories : []
  );
  const [AIScore, setAIScore] = useState(
    storedDataForFilter ? storedDataForFilter.AIScore : 'ai_score'
  );
  const [manualScore, setManualScore] = useState(
    storedDataForFilter ? storedDataForFilter.manualScore : 'manual_score'
  );
  const [durationValue, setDurationValue] = useState(
    storedDataForFilter ? storedDataForFilter.durationValue : 'duration_gte'
  );
  const [durationInput, setDurationInput] = useState(
    storedDataForFilter ? storedDataForFilter.durationInput : null
  );
  const [negativeSent, setNegativeSent] = useState(
    storedDataForFilter ? storedDataForFilter.negativeSent : false
  );
  const [positiveSent, setPositiveSent] = useState(
    storedDataForFilter ? storedDataForFilter.positiveSent : false
  );
  const [neutralSent, setNeutralSent] = useState(
    storedDataForFilter ? storedDataForFilter.neutralSent : false
  );
  const [highChecked, setHighChecked] = useState(
    storedDataForFilter ? storedDataForFilter.highChecked : false
  );
  const [mediumChecked, setMediumChecked] = useState(
    storedDataForFilter ? storedDataForFilter.mediumChecked : false
  );
  const [lowChecked, setLowChecked] = useState(
    storedDataForFilter ? storedDataForFilter.lowChecked : false
  );
  const [AIValue, setAIValue] = useState(storedDataForFilter ? storedDataForFilter.AIValue : null);
  const [manualValue, setManualValue] = useState(
    storedDataForFilter ? storedDataForFilter.manualValue : null
  );

  // console.log('categories', categories);

  const formatDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = `${inputDate.getFullYear()}-${(inputDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${inputDate.getDate().toString().padStart(2, '0')}`;

    return formattedDate;
  };

  const [organisationList, setOrganisationList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [processList, setProcessList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [updatedDateFormat, setUpdatedDateFormat] = useState(
    storedDataForFilter
      ? storedDataForFilter.updatedDateFormat
      : {
          value: 'one_week',
          label: 'One Week'
        }
  );
  const [organisation, setOrganisation] = useState(
    userDetails.role === 'Customer_Admin' ||
      userDetails.role === 'QA' ||
      userDetails.role === 'Agent' ||
      userDetails.role === 'QA_admin'
      ? {
          value: userDetails.organisation_id,
          label: userDetails.organisation_name
        }
      : storedDataForFilter
      ? storedDataForFilter.organisation
      : null
  );

  const [customers, setCustomers] = useState(
    userDetails.role === 'Customer_Admin' ||
      userDetails.role === 'QA' ||
      userDetails.role === 'Agent' ||
      userDetails.role === 'QA_admin'
      ? {
          value: userDetails.customer_id,
          label: userDetails.customer_name
        }
      : storedDataForFilter
      ? storedDataForFilter.customers
      : null
  );
  const [process, setProcess] = useState(
    userDetails.role === 'Agent'
      ? [{ value: userDetails.processes[0], label: userDetails.processes[0] }]
      : storedDataForFilter
      ? storedDataForFilter.process
      : null
  );
  const [payloadProcess, setPayloadProcess] = useState(
    userDetails.role === 'Agent'
      ? userDetails.processes[0]
      : storedDataForFilter
      ? storedDataForFilter.payloadProcess
      : []
  );
  const [payloadAgent, setPayloadAgent] = useState(
    userDetails.role === 'Agent'
      ? [userDetails.user_id]
      : storedDataForFilter
      ? storedDataForFilter.payloadAgent
      : []
  );
  const [agents, setAgents] = useState(
    userDetails.role === 'Agent'
      ? [
          {
            value: userDetails.user_id,
            label: `${userDetails.first_name} ${userDetails.last_name}`
          }
        ]
      : storedDataForFilter
      ? storedDataForFilter.agents
      : null
  );

  const dateFormat = [
    { name: 'One Week', id: 'one_week' },
    { name: 'One Month', id: 'one_month' },
    // { name: 'Six Month', id: 'six_month' },
    // { name: 'One Year', id: 'one_year' },
    { name: 'Customize', id: 'customized' }
  ];

  const payload = {
    organisation_id: organisation ? organisation.value : null,
    customer_id: customers ? customers.value : null,
    processes: payloadProcess,
    user_id: payloadAgent,
    // positive_sentiment: positiveSent === true ? 'POSITIVE' : null,
    // negative_sentiment: negativeSent === true ? 'NEGATIVE' : null,
    // neutral_sentiment: neutralSent === true ? 'NEUTRAL' : null,
    sentiment: positiveSent
      ? 'POSITIVE'
      : negativeSent
      ? 'NEGATIVE'
      : neutralSent
      ? 'NEUTRAL'
      : null,
    tone: highChecked ? 'high' : mediumChecked ? 'medium' : lowChecked ? 'low' : null,
    // high_tone: amenities.includes(0) ? 'high' : null,
    // medium_tone: amenities.includes(1) ? 'medium' : null,
    // low_tone: amenities.includes(2) ? 'low' : null,
    date: updatedDateFormat ? updatedDateFormat.value : null,
    start_date: updatedDateFormat.label === 'Customize' ? formatDate(fromDate) : null,
    end_date: updatedDateFormat.label === 'Customize' ? formatDate(toDate) : null,
    ai_score_gte: AIScore === 'ai_score_gte' ? (AIValue === '' ? null : AIValue) : null,
    ai_score_lte: AIScore === 'ai_score_lte' ? (AIValue === '' ? null : AIValue) : null,
    ai_score: AIScore === 'ai_score' ? (AIValue === '' ? null : AIValue) : null,
    manual_score_gte:
      manualScore === 'manual_score_gte' ? (manualValue === '' ? null : manualValue) : null,
    manual_score_lte:
      manualScore === 'manual_score_lte' ? (manualValue === '' ? null : manualValue) : null,
    manual_score: manualScore === 'manual_score' ? (manualValue === '' ? null : manualValue) : null,
    page: page,
    keyword_category: categories && categories.length > 0 ? categories : null,
    call_duration_gte:
      durationValue === 'duration_gte' ? (durationInput === '' ? null : durationInput) : null,
    call_duration_lte:
      durationValue === 'duration_lte' ? (durationInput === '' ? null : durationInput) : null
  };
  // handle clear filter function

  const handleClearFilter = () => {
    setOrganisation(
      userDetails.role === 'Customer_Admin' ||
        userDetails.role === 'QA' ||
        userDetails.role === 'Agent'
        ? {
            value: userDetails.organisation_id,
            label: userDetails.organisation_name
          }
        : null
    );
    setCustomers(
      userDetails.role === 'Customer_Admin' ||
        userDetails.role === 'QA' ||
        userDetails.role === 'Agent'
        ? {
            value: userDetails.customer_id,
            label: userDetails.customer_name
          }
        : null
    );
    setAgents(
      userDetails.role === 'Agent'
        ? [
            {
              value: userDetails.user_id,
              label: `${userDetails.first_name} ${userDetails.last_name}`
            }
          ]
        : null
    );
    setProcess(
      userDetails.role === 'Agent'
        ? [{ value: userDetails.processes[0], label: userDetails.processes[0] }]
        : null
    );
    setPayloadAgent(userDetails.role === 'Agent' ? [userDetails.user_id] : []);
    setPayloadProcess(userDetails.role === 'Agent' ? userDetails.processes[0] : []);

    setCategories([]);
    setUpdatedDateFormat({ value: 'one_week', label: 'One Week' });
    setFromDate(null);
    setToDate(null);
    setPositiveSent(false);
    setNegativeSent(false);
    setNeutralSent(false);
    setHighChecked(false);
    setMediumChecked(false);
    setLowChecked(false);
    setAIScore('ai_score');
    setAIValue('');
    setDurationInput('');
    setDurationValue('duration_gte');
    setManualScore('manual_score');
    setManualValue('');
    setExpanded(false);
  };

  function constructPayload(data) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      // Only add the key-value pair to the payload if the value is not null
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  // console.log('payload', payload);

  const useStyles = makeStyles((theme) => ({
    datePicker: {
      '& .MuiTextField-root': {
        width: 180
        // marginBottom: 1
        // height: 10
      },
      '& .MuiInputLabel-shrink': {
        color: '#3892CF' // Set your desired color for the shrunk label
      },
      '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#3892CF'
      }
    }
  }));

  const classes = useStyles();
  const CustomControlDate = ({ children, ...rest }) => (
    <components.Control {...rest}>
      <CalendarMonthIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
      {children}
    </components.Control>
  );

  const CustomControlOrg = ({ children, ...rest }) => {
    return (
      <components.Control {...rest}>
        <CorporateFareIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
        {children}
      </components.Control>
    );
  };
  const CustomControlCustomer = ({ children, ...rest }) => {
    return (
      <components.Control {...rest}>
        <PortraitIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
        {children}
      </components.Control>
    );
  };
  const CustomControlProcess = ({ children, ...rest }) => {
    return (
      <components.Control {...rest}>
        <LoopIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
        {children}
      </components.Control>
    );
  };
  const CustomControlAgent = ({ children, ...rest }) => {
    return (
      <components.Control
        style={{
          border: 'green'
        }}
        {...rest}
      >
        <SupportAgentIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
        {children}
      </components.Control>
    );
  };

  const customStyles = {
    option: (base, state) => ({
      ...base,
      fontWeight: state.isSelected ? '500' : 'normal'
      // maxHeight: 35
    }),
    control: (base, state) => ({
      ...base,
      fontWeight: '500'

      // border: 'none'
      // maxHeight: 35
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: '2px',
      maxHeight: 38,
      overflow: 'auto'
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: '150px',
      overflowY: 'auto'
      // zIndex: 10000
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 })
  };

  const aiScoreValues = [
    { value: 'ai_score_gte', label: '>=' },
    { value: 'ai_score_lte', label: '<=' },
    { value: 'ai_score', label: '==' }
  ];
  const manualScoreValues = [
    { value: 'manual_score_gte', label: '>=' },
    { value: 'manual_score_lte', label: '<=' },
    { value: 'manual_score', label: '==' }
  ];
  const durationList = [
    { value: 'duration_gte', label: '>=' },
    { value: 'duration_lte', label: '<=' }
  ];

  // handle organisation change function

  const handleOrgChange = (organisation) => {
    setOrganisation(organisation);
    setCustomers(null);
    setProcess(null);
    setPayloadProcess([]);
    setAgents(null);
  };
  const handleCustomerChange = (customer) => {
    setCustomers(customer);
    setProcess(null);
    setPayloadProcess([]);
    setAgents(null);
  };
  const handleProcessChange = (selectedOptions) => {
    setAgents(null);
    if (selectedOptions.some((option) => option.value === 'all')) {
      setPayloadProcess(processList);
      setProcess(
        processList.map((element) => ({
          value: element,
          label: element
        }))
      );
    } else {
      const processes = selectedOptions.map((element) => {
        return element.value;
      });
      setPayloadProcess(processes);
      setProcess(selectedOptions);
    }
  };

  // console.log('agents', agents);

  const handleAgentChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === 'all')) {
      const agentIds = agentList.map((element) => {
        // console.log('element', element);
        return element.user_id;
      });
      setPayloadAgent(agentIds);
      setAgents(
        agentList.map((element) => ({
          value: element.user_id,
          label: `${element.first_name} ${element.last_name}`
        }))
      );
    } else {
      const agentIds = selectedOptions.map((element) => {
        return element.value;
      });
      setPayloadAgent(agentIds);
      setAgents(selectedOptions);
    }
  };

  // API's
  const token = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const getOrganisationList = async () => {
    await API.get('/secure/list_organisation', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCustomerList = async () => {
    await API.get(`/secure/list_of_customer?organisation_id=${organisation?.value}`, config)

      .then((res) => {
        setCustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProcessList = async () => {
    await API.get(
      `/secure/process_list?customer_id=${customers?.value}&organisation_id=${organisation?.value}`,
      config
    )

      .then((res) => {
        setProcessList(res.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCategoryList = async () => {
    await API.get(`/secure/get_unique_agent_keywords?customer_id=${customers?.value}`, config)

      .then((res) => {
        setCategoryList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAgentList = async () => {
    await API.get(
      `secure/list_of_agent?customer_id=${customers.value}&organisation_id=${organisation.value}&processes=${payloadProcess}`,
      config
    )

      .then((res) => {
        setAgentList(res.data.agent_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFilterResult = async () => {
    // Convert payload to query parameters
    setLoading(true);
    const queryParams = new URLSearchParams(constructPayload(payload)).toString();
    let url = `secure/get_filter_data?${queryParams}`;
    if (sortForDate) {
      url += `&sort_by_date=${
        dateSort === 'asec' ? 'original_date' : dateSort === 'desc' ? '-original_date' : null
      }`;
    }
    if (sortForProcessDate) {
      url += `&sort_processed_date=${
        processSort === 'asec'
          ? 'processed_date'
          : processSort === 'desc'
          ? '-processed_date'
          : null
      }`;
    }
    if (sortForDuration) {
      url += `&duration_sort=${durationSort}`;
    }
    await API.get(url, config)

      .then((res) => {
        setFilter(true);
        setStoredDataForFilter({
          organisation,
          customers,
          agents,
          process,
          payloadAgent,
          payloadProcess,
          updatedDateFormat,
          toDate: toDate ? formatDate(toDate) : null,
          fromDate: toDate ? formatDate(fromDate) : null,
          categories,
          AIScore,
          manualScore,
          durationValue,
          durationInput,
          negativeSent,
          positiveSent,
          neutralSent,
          highChecked,
          mediumChecked,
          lowChecked,
          AIValue,
          manualValue,
          dateSort,
          durationSort,
          sortForDate,
          sortForDuration,
          processSort,
          sortForProcessDate,
          filter
        });
        setuploadDataList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        // setFilter(false);
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  useEffect(() => {
    if (organisation?.value !== undefined) {
      getCustomerList();
    }
  }, [organisation]);

  useEffect(() => {
    if (customers?.value !== undefined) {
      getProcessList();
      getCategoryList();
    }
  }, [customers]);

  useEffect(() => {
    if (process !== null) {
      getAgentList();
    }
  }, [process]);
  useEffect(() => {
    if (filter === true) {
      if (
        organisation &&
        customers &&
        agents &&
        agents.length > 0 &&
        process &&
        payloadProcess.length > 0
      ) {
        getFilterResult();
      }
    }
  }, [page, filter, dateSort, durationSort, processSort]);

  //  handleChange for Accordians
  const [expanded, setExpanded] = useState(null);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  // date formatting function

  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider>
        <Button
          variant="outlined"
          color={filter ? 'primary' : 'neutral'}
          startDecorator={<TuneIcon />}
          onClick={() => setOpen(true)}
        >
          Filters
        </Button>
        <Drawer
          variant="temporary"
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}
          // slotProps={{
          //   content: {
          //     sx: {
          //       bgcolor: 'transparent',
          //       p: { md: 3, sm: 0 },
          //       boxShadow: 'none'
          //     }
          //   }
          // }}
        >
          <Sheet
            sx={{
              borderRadius: 'md',
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              height: '100%',
              overflow: 'auto'
            }}
          >
            <DialogTitle>Filters</DialogTitle>
            <ModalClose onClick={() => setOpen(false)} />
            <Divider sx={{ mt: 'auto' }} />
            <DialogContent sx={{ gap: 2 }}>
              <AccordionGroup
                variant="plain"
                transition="0.2s"
                sx={{
                  maxWidth: 400,
                  borderRadius: 'md',
                  [`& .${accordionDetailsClasses.content}.${accordionDetailsClasses.expanded}`]: {
                    paddingBlock: '1rem'
                  },
                  [`& .${accordionSummaryClasses.button}`]: {
                    paddingBlock: '1rem'
                  }
                }}
              >
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary>
                    <Avatar color="primary">
                      <FilterListIcon />
                    </Avatar>
                    <ListItemContent>
                      <Typography level="title-md">Select Admin Filters</Typography>
                      <Typography level="body-sm">Select Filters as per process,agent</Typography>
                    </ListItemContent>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack spacing={1.5}>
                      <FormControl orientation="horizontal">
                        <Grid
                          container
                          sacing={1}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: 1,
                            backgroundColor: '#f4f4f4'
                          }}
                        >
                          <Grid item xs={12} sm={5.5} md={5.5}>
                            {userDetails.role !== 'Super_Admin' ? (
                              <>
                                <h5>
                                  <b>Select Organisation *</b>
                                </h5>
                                <Selectbox
                                  required
                                  fullWidth
                                  size="small"
                                  defaultValue="all"
                                  disabled
                                >
                                  <MenuItem value="all" disabled>
                                    {userDetails.organisation_name}
                                  </MenuItem>
                                </Selectbox>
                              </>
                            ) : (
                              <>
                                <h5>
                                  <b>Select Organisation *</b>
                                </h5>
                                <Select
                                  menuPortalTarget={document.body}
                                  menuPosition="fixed"
                                  //   onChange={chnageSelect}
                                  styles={customStyles}
                                  onChange={(e) => handleOrgChange(e)}
                                  options={organisationList?.map((element) => ({
                                    value: element.organisation_id,
                                    label: element.organisation_name
                                  }))}
                                  value={organisation}
                                  style={{ color: '#000' }}
                                  components={{ Control: CustomControlOrg }}
                                  placeholder={
                                    <div
                                      style={{
                                        display: 'flex',
                                        columnGap: 10,
                                        alignItems: 'center',
                                        color: '#000',
                                        fontWeight: '400'
                                      }}
                                    >
                                      All
                                    </div>
                                  }
                                />
                              </>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={5.5} md={5.5}>
                            {userDetails.role === 'QA' ||
                            userDetails.role === 'Manager' ||
                            userDetails.role === 'Customer_Admin' ||
                            userDetails.role === 'QA_admin' ||
                            userDetails.role === 'Agent' ? (
                              <>
                                <h5>
                                  <b>Select Customer *</b>
                                </h5>
                                <Selectbox
                                  required
                                  fullWidth
                                  size="small"
                                  defaultValue="all"
                                  disabled
                                >
                                  <MenuItem value="all" disabled>
                                    {userDetails.customer_name}
                                  </MenuItem>
                                </Selectbox>
                              </>
                            ) : (
                              <>
                                <h5>
                                  <b>Select Customer *</b>
                                </h5>
                                <div>
                                  <Select
                                    styles={customStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    // defaultValue={{ value: 'all', label: 'All' }}
                                    onChange={(e) => handleCustomerChange(e)}
                                    value={customers}
                                    options={customerList?.map((element) => ({
                                      value: element.customer_id,
                                      label: element.customer_organisation_name
                                    }))}
                                    components={{ Control: CustomControlCustomer }}
                                    style={{ color: '#000' }}
                                    placeholder={
                                      <div
                                        style={{
                                          display: 'flex',
                                          columnGap: 10,
                                          alignItems: 'center',
                                          color: '#000',
                                          fontWeight: '400'
                                        }}
                                      >
                                        All
                                      </div>
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <h5>
                              <b>Select Process *</b>
                            </h5>
                            {/* <div style={{ width: '200px', maxHeight: 35 }}> */}
                            {userDetails.role === 'Agent' ? (
                              <Selectbox
                                required
                                fullWidth
                                size="small"
                                defaultValue="all"
                                disabled
                              >
                                <MenuItem value="all" disabled>
                                  {userDetails.processes[0]}
                                </MenuItem>
                              </Selectbox>
                            ) : (
                              <Select
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                menuPosition="fixed"
                                onChange={handleProcessChange}
                                components={{ Control: CustomControlProcess }}
                                isMulti
                                options={
                                  processList
                                    ? [
                                        { value: 'all', label: 'All' },
                                        ...processList.map((element) => ({
                                          value: element,
                                          label: element
                                        }))
                                      ]
                                    : [{ value: 'all', label: 'All' }]
                                }
                                value={process}
                                style={{ color: '#000' }}
                                placeholder={
                                  <div
                                    style={{
                                      display: 'flex',
                                      columnGap: 10,
                                      alignItems: 'center',
                                      color: '#000',
                                      fontWeight: '400'
                                    }}
                                  >
                                    All
                                  </div>
                                }
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <h5>
                              <b>Select Agent *</b>
                            </h5>
                            {/* <div style={{ width: '200px', maxHeight: 35 }}> */}
                            {userDetails.role === 'Agent' ? (
                              <Selectbox
                                required
                                fullWidth
                                size="small"
                                defaultValue="all"
                                disabled
                              >
                                <MenuItem value="all" disabled>
                                  {userDetails.first_name} {userDetails.last_name}
                                </MenuItem>
                              </Selectbox>
                            ) : (
                              <Select
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                menuPosition="fixed"
                                onChange={handleAgentChange}
                                components={{ Control: CustomControlAgent }}
                                isMulti
                                options={
                                  agentList
                                    ? [
                                        { value: 'all', label: 'All' },
                                        ...agentList.map((element) => ({
                                          value: element.user_id,
                                          label: `${element.first_name} ${element.last_name}`
                                        }))
                                      ]
                                    : [{ value: 'all', label: 'All' }]
                                }
                                value={agents}
                                style={{ color: '#000' }}
                                placeholder={
                                  <div
                                    style={{
                                      display: 'flex',
                                      columnGap: 10,
                                      alignItems: 'center',
                                      color: '#000',
                                      fontWeight: '400'
                                    }}
                                  >
                                    All
                                  </div>
                                }
                              />
                            )}
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Stack>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary>
                    <Avatar color="warning">
                      <DateRangeIcon />
                    </Avatar>
                    <ListItemContent>
                      <Typography level="title-md">Date Range</Typography>
                      <Typography level="body-sm">Select customised date range</Typography>
                    </ListItemContent>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack spacing={1.5}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Select
                          styles={customStyles}
                          sx={{ marginBottom: '10px' }}
                          menuPortalTarget={document.body}
                          menuPosition="fixed"
                          onChange={(e) => {
                            setUpdatedDateFormat(e);
                            // setPage(1);
                            // setFromDate(null);
                            // setToDate(null);
                            // getReportDetails(e.value);
                          }}
                          options={dateFormat?.map((element) => ({
                            value: element.id,
                            label: element.name
                          }))}
                          value={updatedDateFormat}
                          isSearchable={false}
                          components={{ Control: CustomControlDate }}
                          placeholder={
                            <div
                              style={{
                                display: 'flex',
                                columnGap: 10,
                                alignItems: 'center',
                                color: '#000',
                                fontWeight: '400'
                              }}
                            />
                          }
                        />
                      </Grid>
                    </Stack>
                    <Grid item xs={12} sm={12} md={12}>
                      {updatedDateFormat.label === 'Customize' && (
                        <div style={{ marginBottom: '8px' }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                              <div className={classes.datePicker}>
                                <DatePicker
                                  sx={{ width: 300 }}
                                  label="From"
                                  format="DD/MM/YYYY"
                                  maxDate={dayjs()}
                                  value={fromDate}
                                  onChange={(newValue) => {
                                    setToDate(null);
                                    setFromDate(newValue);
                                  }}
                                  slotProps={{ textField: { size: 'small' } }}
                                />
                              </div>
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      {updatedDateFormat.label === 'Customize' && (
                        <div style={{ marginBottom: '8px' }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                              <div className={classes.datePicker}>
                                <DatePicker
                                  sx={{ width: 300 }}
                                  label="To"
                                  format="DD/MM/YYYY"
                                  disabled={fromDate === null}
                                  minDate={fromDate}
                                  maxDate={dayjs()}
                                  value={toDate}
                                  onChange={(newValue) => {
                                    setToDate(newValue);
                                    // setPage(0);
                                    // getReportDetails('customized', newValue);
                                  }}
                                  slotProps={{ textField: { size: 'small' } }}
                                />
                              </div>
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {userDetails?.Submenus.includes('Call_analysis') && (
                  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary>
                      <Avatar color="success">
                        <InsertEmoticonIcon />
                      </Avatar>
                      <ListItemContent>
                        <Typography level="title-md">Sentiments</Typography>
                        <Typography level="body-sm">Select Sentiments Related Calls</Typography>
                      </ListItemContent>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack spacing={1.5}>
                        <FormControl orientation="horizontal" sx={{ gap: 1 }}>
                          {/* <EmailRoundedIcon fontSize="xl2" sx={{ mx: 1 }} /> */}
                          <FormLabel>Positive</FormLabel>
                          <Switch
                            checked={positiveSent}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setNegativeSent(false);
                                setNeutralSent(false);
                              }
                              setPositiveSent(e.target.checked);
                            }}
                            size="sm"
                          />
                        </FormControl>

                        <FormControl orientation="horizontal" sx={{ gap: 1 }}>
                          {/* <MessageRoundedIcon fontSize="xl2" sx={{ mx: 1 }} /> */}
                          <FormLabel>Negative</FormLabel>
                          <Switch
                            checked={negativeSent}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setPositiveSent(false);
                                setNeutralSent(false);
                              }
                              setNegativeSent(e.target.checked);
                            }}
                            size="sm"
                          />
                        </FormControl>

                        <FormControl orientation="horizontal" sx={{ gap: 1 }}>
                          {/* <AdUnitsRoundedIcon fontSize="xl2" sx={{ mx: 1 }} /> */}
                          <FormLabel>Neutral</FormLabel>
                          <Switch
                            checked={neutralSent}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setPositiveSent(false);
                                setNegativeSent(false);
                              }
                              setNeutralSent(e.target.checked);
                            }}
                            size="sm"
                          />
                        </FormControl>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                )}

                {userDetails?.Submenus.includes('Call_analysis') && (
                  <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary>
                      <Avatar color="danger">
                        <GraphicEqIcon />
                      </Avatar>
                      <ListItemContent>
                        <Typography level="title-md">Tone</Typography>
                        <Typography level="body-sm">Select Tone Related Calls</Typography>
                      </ListItemContent>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid containspacing={1.5}>
                        <div role="group" aria-labelledby="rank">
                          <List
                            orientation="horizontal"
                            size="sm"
                            sx={{
                              '--List-gap': '17px',

                              '--ListItem-radius': '20px',
                              paddingLeft: '20px'
                            }}
                          >
                            <ListItem>
                              <AspectRatio
                                variant={highChecked ? 'solid' : 'outlined'}
                                color={highChecked ? 'primary' : 'neutral'}
                                ratio={1}
                                sx={{ width: 20, borderRadius: 20, ml: -0.5, mr: 0.75 }}
                              >
                                <div>{highChecked && <Done fontSize="md" />}</div>
                              </AspectRatio>
                              <Checkbox
                                size="sm"
                                color="neutral"
                                disableIcon
                                overlay
                                label="High"
                                variant="outlined"
                                checked={highChecked}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setMediumChecked(false);
                                    setLowChecked(false);
                                  }
                                  setHighChecked(e.target.checked);
                                }}
                                slotProps={{
                                  action: {
                                    sx: {
                                      '&:hover': {
                                        bgcolor: 'transparent'
                                      }
                                    }
                                  }
                                }}
                              />
                            </ListItem>
                            <ListItem>
                              <AspectRatio
                                variant={mediumChecked ? 'solid' : 'outlined'}
                                color={mediumChecked ? 'primary' : 'neutral'}
                                ratio={1}
                                sx={{ width: 20, borderRadius: 20, ml: -0.5, mr: 0.75 }}
                              >
                                <div>{mediumChecked && <Done fontSize="md" />}</div>
                              </AspectRatio>
                              <Checkbox
                                size="sm"
                                color="neutral"
                                disableIcon
                                overlay
                                label="Medium"
                                variant="outlined"
                                checked={mediumChecked}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setHighChecked(false);
                                    setLowChecked(false);
                                  }
                                  setMediumChecked(e.target.checked);
                                }}
                                slotProps={{
                                  action: {
                                    sx: {
                                      '&:hover': {
                                        bgcolor: 'transparent'
                                      }
                                    }
                                  }
                                }}
                              />
                            </ListItem>
                            <ListItem>
                              <AspectRatio
                                variant={lowChecked ? 'solid' : 'outlined'}
                                color={lowChecked ? 'primary' : 'neutral'}
                                ratio={1}
                                sx={{ width: 20, borderRadius: 20, ml: -0.5, mr: 0.75 }}
                              >
                                <div>{lowChecked && <Done fontSize="md" />}</div>
                                {/* <div>{<Done fontSize="md" />}</div> */}
                              </AspectRatio>
                              <Checkbox
                                size="sm"
                                color="neutral"
                                disableIcon
                                overlay
                                label="Low"
                                variant="outlined"
                                checked={lowChecked}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setMediumChecked(false);
                                    setHighChecked(false);
                                  }
                                  setLowChecked(e.target.checked);
                                }}
                                slotProps={{
                                  action: {
                                    sx: {
                                      '&:hover': {
                                        bgcolor: 'transparent'
                                      }
                                    }
                                  }
                                }}
                              />
                            </ListItem>
                          </List>
                        </div>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
                {customers && (
                  <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary>
                      <Avatar color="primary">
                        <AbcIcon />
                      </Avatar>
                      <ListItemContent>
                        <Typography level="title-md">Keyword Categories</Typography>
                        <Typography level="body-sm">
                          Select keyword related category calls
                        </Typography>
                      </ListItemContent>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack spacing={1.5}>
                        <div role="group" aria-labelledby="rank">
                          <List
                            orientation="horizontal"
                            size="sm"
                            sx={{
                              '--List-gap': '17px',

                              '--ListItem-radius': '20px'
                            }}
                          >
                            <Grid container spacing={1}>
                              {categoryList.map((item, index) => {
                                const selected = categories.includes(item);
                                return (
                                  <ListItem key={item} sx={{ margin: '4px' }}>
                                    <AspectRatio
                                      variant={selected ? 'solid' : 'outlined'}
                                      color={selected ? 'primary' : 'neutral'}
                                      ratio={1}
                                      sx={{ width: 20, borderRadius: 20, ml: -0.5, mr: 0.75 }}
                                    >
                                      <div>{selected && <Done fontSize="md" />}</div>
                                    </AspectRatio>
                                    <Checkbox
                                      size="sm"
                                      color="neutral"
                                      disableIcon
                                      overlay
                                      label={item}
                                      variant="outlined"
                                      checked={selected}
                                      onChange={(event) =>
                                        setCategories((prev) => {
                                          const set = new Set([...prev, item]);
                                          if (!event.target.checked) {
                                            set.delete(item);
                                          }

                                          return [...set];
                                        })
                                      }
                                      slotProps={{
                                        action: {
                                          sx: {
                                            '&:hover': {
                                              bgcolor: 'transparent'
                                            }
                                          }
                                        }
                                      }}
                                    />
                                  </ListItem>
                                );
                              })}
                            </Grid>
                          </List>
                        </div>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                )}

                {userDetails?.Submenus.includes('Compliance') && (
                  <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary>
                      <Avatar color="neutral">
                        <PercentIcon />
                      </Avatar>
                      <ListItemContent>
                        <Typography level="title-md">Score</Typography>
                        <Typography level="body-sm">Select Scored Calls</Typography>
                      </ListItemContent>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          // sm={aiScoreValue === 'Customize' ? 5.5 : 8}
                          // md={aiScoreValue === 'Customize' ? 5.5 : 8}
                        >
                          <FormLabel>Select AI Score (in %)</FormLabel>

                          <Input
                            placeholder="AI Score"
                            type="number"
                            value={AIValue}
                            onChange={(e) => setAIValue(e.target.value)}
                            onKeyDown={(e) => {
                              // Prevent the default action if the user tries to input the decimal point
                              if (AIScore !== 'ai_score' && (e.key === '.' || e.key === '-')) {
                                e.preventDefault();
                              } else if (e.key === '-') {
                                e.preventDefault();
                              }
                            }}
                            onWheel={() => document.activeElement.blur()}
                            // startDecorator={{ dollar: '$', baht: '฿', yen: '¥' }[currency]}
                            startDecorator={
                              <>
                                <JoySelect
                                  variant="plain"
                                  value={AIScore}
                                  onChange={(_, value) => {
                                    setAIValue('');
                                    setAIScore(value);
                                  }}
                                  slotProps={{
                                    listbox: {
                                      variant: 'outlined'
                                    }
                                  }}
                                  sx={{ ml: -1.5, '&:hover': { bgcolor: 'transparent' } }}
                                >
                                  {aiScoreValues?.map((element) => (
                                    <Option value={element.value}>{element.label}</Option>
                                  ))}
                                </JoySelect>
                                <Divider orientation="vertical" />
                              </>
                            }
                            sx={{ width: 300 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <FormLabel>Select Manual Score (in %)</FormLabel>
                          <Input
                            placeholder="Manual Score"
                            type="number"
                            value={manualValue}
                            onChange={(e) => setManualValue(e.target.value)}
                            onKeyDown={(e) => {
                              // Prevent the default action if the user tries to input the decimal point
                              if (
                                manualScore !== 'manual_score' &&
                                (e.key === '.' || e.key === '-')
                              ) {
                                e.preventDefault();
                              } else if (e.key === '-') {
                                e.preventDefault();
                              }
                            }}
                            onWheel={() => document.activeElement.blur()}
                            startDecorator={
                              <>
                                <JoySelect
                                  variant="plain"
                                  value={manualScore}
                                  onChange={(_, value) => {
                                    setManualValue('');
                                    setManualScore(value);
                                  }}
                                  slotProps={{
                                    listbox: {
                                      variant: 'outlined'
                                    }
                                  }}
                                  sx={{ ml: -1.5, '&:hover': { bgcolor: 'transparent' } }}
                                >
                                  {manualScoreValues?.map((element) => (
                                    <Option value={element.value}>{element.label}</Option>
                                  ))}
                                </JoySelect>
                                <Divider orientation="vertical" />
                              </>
                            }
                            sx={{ width: 300 }}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                  <AccordionSummary>
                    <Avatar color="primary">
                      <SpatialAudioOffIcon />
                    </Avatar>
                    <ListItemContent>
                      <Typography level="title-md">Call Duration</Typography>
                      <Typography level="body-sm">Select Call Durationwise Calls</Typography>
                    </ListItemContent>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12}>
                        <FormLabel>Select Call Duration (in mins)</FormLabel>

                        <Input
                          placeholder="Call Duration"
                          type="number"
                          value={durationInput}
                          onChange={(e) => setDurationInput(e.target.value)}
                          onKeyDown={(e) => {
                            // Prevent the default action if the user tries to input the decimal point
                            if (e.key === '.' || e.key === '-') {
                              e.preventDefault();
                            }
                          }}
                          onWheel={() => document.activeElement.blur()}
                          // startDecorator={{ dollar: '$', baht: '฿', yen: '¥' }[currency]}
                          startDecorator={
                            <>
                              <JoySelect
                                variant="plain"
                                value={durationValue}
                                onChange={(_, value) => setDurationValue(value)}
                                slotProps={{
                                  listbox: {
                                    variant: 'outlined'
                                  }
                                }}
                                sx={{ ml: -1.5, '&:hover': { bgcolor: 'transparent' } }}
                              >
                                {durationList?.map((element) => (
                                  <Option value={element.value}>{element.label}</Option>
                                ))}
                              </JoySelect>
                              <Divider orientation="vertical" />
                            </>
                          }
                          sx={{ width: 300 }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </AccordionGroup>
            </DialogContent>

            <Divider sx={{ mt: 'auto' }} />
            <Stack direction="row" justifyContent="space-between" useFlexGap spacing={1}>
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => {
                  handleClearFilter();
                  setPage(1);
                  setStoredDataForFilter();
                  setFilter(false);
                  setOpen(false);
                }}
              >
                Clear
              </Button>
              <Button
                onClick={() => {
                  setPage(1);
                  if (
                    organisation &&
                    customers &&
                    agents &&
                    agents.length > 0 &&
                    process &&
                    payloadProcess.length > 0
                  ) {
                    getFilterResult();
                    setOpen(false);
                  } else {
                    setAlert(true);
                    setAlertType('error');
                    setAlertContent('Select Admin Filters to view the results.');
                  }
                }}
              >
                Apply
              </Button>
            </Stack>
          </Sheet>
        </Drawer>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}
