import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Divider from '@mui/material/Divider';
import arrowLeft from '@iconify/icons-mdi/arrow-left';
import {
  Card,
  FormControl,
  Stack,
  Button,
  Container,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Select as Selectbox,
  FormHelperText
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik, FormikProvider, Form } from 'formik';
import Page from '../components/Page';
import { Notification } from '../components/Notification/Notification';
import API from '../E2E/axios.util';

function Feedbackform() {
  const user = JSON.parse(localStorage.getItem('user'));
  const dataForFeedback = JSON.parse(sessionStorage.getItem('cockpitData'));
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');

  // <------------------------------------------------------->
  // formik for validation and submission.

  const FeedbackSchema = Yup.object().shape({
    feedback: Yup.string().required('This field is required.'),
    action: Yup.string().required('This field is required.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      feedback: '',
      action: ''
    },
    validationSchema: FeedbackSchema,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        input_id: dataForFeedback[0],
        organisation_id: dataForFeedback[1],
        customer_id: dataForFeedback[6],
        user_id: dataForFeedback[8],
        feedback: formik.values.feedback,
        actions: formik.values.action
      };
      console.log('data', data);
      await API.post('secure/add_feedback', data, config)
        .then((res) => {
          formik.resetForm();
          setAlertContent(res.data.message);
          setAlertType('success');
          setAlert(true);
        })
        .catch((err) => {
          formik.resetForm();
          setAlertContent(
            `Please check your email and password : ${
              err.response.data.message ? err.response.data.message : 'Internal Server Error'
            }`
          );
          setAlert(true);
          setAlertType('error');
        });
    }
  });
  // console.log(values);
  // console.log(errors);

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleBlur } = formik;

  const handleAlert = () => {
    setAlert(false);
  };

  const todayDate = `${new Date().getDate()}-${
    new Date().getMonth() + 1
  }-${new Date().getFullYear()}`;
  const navigate = useNavigate();
  return (
    <>
      <Page title="Feedback">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <LoadingButton
              startIcon={<Icon icon={arrowLeft} />}
              variant="text"
              // color="primary"
              style={{
                color: '#3892CF',
                '&:hover': {
                  color: '#3892CF'
                }
              }}
              size="large"
              onClick={() => navigate('/dashboard/workspace/upload/results', { replace: true })}
            >
              Back
            </LoadingButton>
          </Stack>
        </Container>
        {/* <Container>
          <FeedbackSearch sx={{ display: { xs: 'none', sm: 'block' } }} />
        </Container> */}
      </Page>
      <Box>
        <Container>
          <Box sx={{ padding: '3px' }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                {/* <Card> */}
                <Typography
                  role="presentation"
                  // textAlign="center"
                  // float="center"
                  variant="h4"
                  mt="2"
                >
                  Feedback Form
                </Typography>
                <br />
                <Divider />
                <div
                  style={{
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'center'
                    padding: 2
                  }}
                >
                  <Typography Variant="h1" gutterBottom ml="4">
                    {`Agent Name:  ${dataForFeedback[9]} ${
                      dataForFeedback[10] ? dataForFeedback[10] : ''
                    }`}
                  </Typography>
                  <Typography display="block" Variant="h1" gutterBottom>
                    {`Feedback Date: ${todayDate}`}
                  </Typography>
                  <Typography Variant="h1" gutterBottom>
                    {`Reviewed By:  ${user.first_name} ${user.last_name}`}
                  </Typography>
                </div>
                <Divider />
                <Grid
                  container
                  spacing={3}
                  sx={{
                    mt: 2
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'center'
                  }}
                  md={12}
                >
                  <Grid item md={12}>
                    <TextField
                      // size="small"
                      label="Feedback"
                      rows={2}
                      multiline
                      variant="outlined"
                      fullWidth
                      value={formik.feedback}
                      onBlur={handleBlur}
                      required
                      {...getFieldProps('feedback')}
                      error={Boolean(touched.feedback && errors.feedback)}
                      helperText={touched.feedback && errors.feedback}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      // size="small"
                      label="Action"
                      multiline
                      rows={2}
                      variant="outlined"
                      fullWidth
                      value={formik.action}
                      onBlur={handleBlur}
                      name="action"
                      required
                      {...getFieldProps('action')}
                      error={Boolean(touched.action && errors.action)}
                      helperText={touched.action && errors.action}
                    />
                  </Grid>
                </Grid>
                <Typography sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{
                      // color: '#fff',
                      backgroundColor: '#3892CF',
                      '&:hover': {
                        backgroundColor: '#3892CF'
                      }
                    }}
                  >
                    Submit
                  </Button>
                </Typography>
                <br />
                {/* </Card> */}
              </Form>
            </FormikProvider>
          </Box>
        </Container>
        <Notification
          message={alertContent}
          open={alert}
          severity={alertType}
          onClose={handleAlert}
        />
      </Box>
    </>
  );
}

export default Feedbackform;
