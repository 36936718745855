import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Stack,
  Typography,
  Grid,
  Tabs,
  Tab,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import { saveAs } from 'file-saver';
import Page from '../components/Page';
import API from '../E2E/axios.util';
import AppWidgetSummary from '../components/charts/AppWidgetSummary';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default function Usage() {
  const [value, setValue] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [month, setMonth] = useState();
  console.log('month', month);
  const [usageDetails, setUsageDetails] = useState();
  const [downloadDetails, setDownloadDetails] = useState();
  console.log('download details ----------', downloadDetails);
  const [loading, setLoading] = useState();
  const [sum, setSum] = useState();
  const [total, setTotal] = useState();
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }
  const handleChange = (event, newValue) => {
    console.log('newValue', newValue);
    setValue(newValue);
  };

  const getUsageDetails = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`secure/usage_details`, config)
      .then((res) => {
        setUsageDetails(res.data);
        setDownloadDetails(res.data.download_details);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // setAlertContent('Default Data API Failed');
        // setAlertType('error');
        // setAlert(true);
      });
  };
  // const downloadUsageDetails = async () => {
  //   const token = localStorage.getItem('token');
  //   const config = {
  //     headers: { Authorization: `Bearer ${token}` }
  //   };
  //   await API.get(`secure/download_usage_details`, config)
  //     .then((res) => {
  //       setDownloadDetails(res.data.download_details);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoading(false);
  //       // setAlertContent('Default Data API Failed');
  //       // setAlertType('error');
  //       // setAlert(true);
  //     });
  // };

  useEffect(() => {
    getUsageDetails();
  }, []);
  // <--------------------------------------------------------------------------------------->
  // dummy usage details
  // const usage = [
  //   { _id: { month: 12, year: 2022, organisation_id: 4, customer_id: 5 }, processed_time: 12968 },
  //   {
  //     _id: { month: 1, year: 2023, organisation_id: 81, customer_id: 185 },
  //     processed_time: 64840
  //   },
  //   { _id: { month: 1, year: 2023, organisation_id: 77, customer_id: 169 }, processed_time: 12968 }
  // ];
  // const arr = [];
  // const obj = {};
  // const newObj = {};
  // usage.map((el) => {
  //   obj.org_name = el._id.organisation_id;
  //   obj.customer_name = el._id.customer_id;
  //   obj.processed_time = el.processed_time / 60;
  //   arr.push({ obj });
  //   return obj;
  // });
  // console.log('first', arr);
  // const dummyUsage = {
  //   Jan22: [
  //     {
  //       org_name: 'ConnectCorp',
  //       customer_name: 'Accenture',
  //       process_count: '10',
  //       processed_time: '55'
  //     },
  //     {
  //       org_name: 'ConnectCorp',
  //       customer_name: 'Siemens',
  //       process_count: '5',
  //       processed_time: '30'
  //     },
  //     {
  //       org_name: 'shyenaTechTest',
  //       customer_name: 'Tata',
  //       process_count: '12',
  //       processed_time: '60'
  //     },
  //     {
  //       org_name: 'Shyena',
  //       customer_name: 'Cogni',
  //       process_count: '15',
  //       processed_time: '90'
  //     },
  //     {
  //       org_name: 'Shyena',
  //       customer_name: 'Tcs',
  //       process_count: '4',
  //       processed_time: '20'
  //     },
  //     {
  //       org_name: 'ConnectCorp',
  //       customer_name: 'Infy',
  //       process_count: '6',
  //       processed_time: '30'
  //     },
  //     {
  //       org_name: 'STY',
  //       customer_name: 'Infy',
  //       process_count: '12',
  //       processed_time: '60'
  //     }
  //   ],
  //   Feb22: [
  //     {
  //       org_name: 'ConnectCorp',
  //       customer_name: 'Accenture',
  //       process_count: '11',
  //       processed_time: '55'
  //     },
  //     {
  //       org_name: 'ConnectCorp',
  //       customer_name: 'Siemens',
  //       process_count: '3',
  //       processed_time: '15'
  //     },
  //     {
  //       org_name: 'shyenaTechTest',
  //       customer_name: 'Tata',
  //       process_count: '5',
  //       processed_time: '25'
  //     },
  //     {
  //       org_name: 'Shyena',
  //       customer_name: 'Cogni',
  //       process_count: '9',
  //       processed_time: '45'
  //     },
  //     {
  //       org_name: 'Shyena',
  //       customer_name: 'Tcs',
  //       process_count: '4',
  //       processed_time: '20'
  //     },
  //     {
  //       org_name: 'ConnectCorp',
  //       customer_name: 'Infy',
  //       process_count: '10',
  //       processed_time: '50'
  //     },
  //     {
  //       org_name: 'STY',
  //       customer_name: 'Infy',
  //       process_count: '5',
  //       processed_time: '30'
  //     }
  //   ],
  //   Mar22: [
  //     {
  //       org_name: 'ConnectCorp',
  //       customer_name: 'Accenture',
  //       process_count: '11',
  //       processed_time: '55'
  //     },
  //     {
  //       org_name: 'ConnectCorp',
  //       customer_name: 'Siemens',
  //       process_count: '14',
  //       processed_time: '85'
  //     },
  //     {
  //       org_name: 'shyenaTechTest',
  //       customer_name: 'Tata',
  //       process_count: '14',
  //       processed_time: '90'
  //     },
  //     {
  //       org_name: 'Shyena',
  //       customer_name: 'Cogni',
  //       process_count: '8',
  //       processed_time: '40'
  //     },
  //     {
  //       org_name: 'Shyena',
  //       customer_name: 'Tcs',
  //       process_count: '11',
  //       processed_time: '55'
  //     },
  //     {
  //       org_name: 'ConnectCorp',
  //       customer_name: 'Infy',
  //       process_count: '14',
  //       processed_time: '90'
  //     },
  //     {
  //       org_name: 'STY',
  //       customer_name: 'Infy',
  //       process_count: '13',
  //       processed_time: '65'
  //     }
  //   ],
  //   Apr22: [
  //     {
  //       org_name: 'ConnectCorp',
  //       customer_name: 'Accenture',
  //       process_count: '4',
  //       processed_time: '20'
  //     },
  //     {
  //       org_name: 'ConnectCorp',
  //       customer_name: 'Siemens',
  //       process_count: '4',
  //       processed_time: '20'
  //     },
  //     {
  //       org_name: 'shyenaTechTest',
  //       customer_name: 'Tata',
  //       process_count: '11',
  //       processed_time: '90'
  //     },
  //     {
  //       org_name: 'Shyena',
  //       customer_name: 'Cogni',
  //       process_count: '14',
  //       processed_time: '100'
  //     },
  //     {
  //       org_name: 'Shyena',
  //       customer_name: 'Tcs',
  //       process_count: '3',
  //       processed_time: '12'
  //     },
  //     {
  //       org_name: 'ConnectCorp',
  //       customer_name: 'Infy',
  //       process_count: '14',
  //       processed_time: '80'
  //     },
  //     {
  //       org_name: 'STY',
  //       customer_name: 'Infy',
  //       process_count: '5',
  //       processed_time: '50'
  //     }
  //   ]
  // };
  // <-------------------------------------------------------------------->
  useEffect(() => {
    if (usageDetails?.usage_details) {
      const checkMonth = Object.keys(usageDetails.usage_details).filter(
        (el, index) => index === tabIndex
      )[0];
      setMonth(checkMonth);
    }
  }, [usageDetails, tabIndex]);

  useEffect(() => {
    let sum = 0;
    usageDetails?.usage_details[
      Object.keys(usageDetails.usage_details).filter((el, index) => index === tabIndex)[0]
      // ].map((element) => (sum += (element.processed_time / 3600).toFixed(2)));
    ]?.map((element) => {
      console.log(element.processed_time);
      sum += element.processed_time;
      return sum;
    });
    setSum(sum);
  }, [usageDetails, tabIndex]);

  // usageDetails.usage_details[
  //   Object.keys(usageDetails.usage_details).filter((el, index) => index === tabIndex)[0]
  // ].map((element) => console.log((element.processed_time / 60).toFixed(2)));

  // console.log(dummyUsage[tabs[0]]);
  // console.log('tabs', tabs);
  // const setTotalProcessTime = (element) => {
  //   setTotal(element);
  // };

  const header = ['Firstname', 'Lastname', 'Age'];
  const body2 = [
    ['Edison', 'Padilla', 14],
    ['Cheila', 'Rodriguez', 56]
  ];

  // function handleDownloadExcel() {
  //   const opt = 'downloadExcel Method';

  //   downloadExcel({
  //     fileName: `react-export-table-to-excel - ${opt}`,
  //     sheet: 'react-export-table-to-excel',
  //     tablePayload: {
  //       header,
  //       // accept two different data structures
  //       body: body2
  //     }
  //   });
  // }

  return (
    <Page title="Usage">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Usage Report
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              aria-label="action tabs example"
            >
              {usageDetails &&
                Object.keys(usageDetails.usage_details)?.map((el, index) => (
                  <Tab
                    label={el.split('_').join('-')}
                    value={index}
                    {...a11yProps(tabIndex)}
                    onClick={() => {
                      setTabIndex(index);
                      setMonth(el);
                    }}
                  />
                ))}
            </Tabs>
          </Grid>
          <TabPanel value={value} index={tabIndex}>
            <Grid container spacing={3}>
              <Grid item md={2.8}>
                <Card
                  sx={{
                    backgroundColor: 'rgba(230, 180, 35, 0.4)',
                    borderRadius: '2px'
                  }}
                >
                  <Typography
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >{`Subscription Plan : Standard `}</Typography>
                </Card>
                {/* <AppWidgetSummary
                  title={`Standard:${3000}min`}
                  total="Subscription "
                  color="warning"
                  bgColor="rgba(218,165,32,0.4)"
                  icon="ic:baseline-credit-card"
                /> */}
              </Grid>
              <Grid item md={2.8}>
                <Card
                  sx={{
                    backgroundColor: 'rgba(250, 83, 80,0.55)',
                    borderRadius: '2px'
                  }}
                >
                  <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                    Total hrs : 50 hrs
                  </Typography>
                </Card>
                {/* <AppWidgetSummary
                  title={`${sum / 1000}min`}
                  total="Mins Consumed"
                  color="secondary"
                  bgColor="rgba(250, 83, 80,0.55)"
                  icon="entypo:back-in-time"
                /> */}
              </Grid>
              <Grid item md={2.8}>
                <Card
                  sx={{
                    backgroundColor: 'rgba(250, 83, 80,0.3)',
                    borderRadius: '2px'
                  }}
                >
                  <Typography
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >{`Consumed Time : ${sum?.toFixed(2)} hr`}</Typography>
                </Card>
                {/* <AppWidgetSummary
                  title={`${3000 - sum / 1000}min`}
                  total="Mins Available"
                  color="info"
                  bgColor="rgba(250, 83, 80,0.3)"
                  icon="material-symbols:avg-time-outline"
                /> */}
              </Grid>
              <Grid item md={2.8}>
                <Card
                  sx={{
                    backgroundColor: 'rgba(250, 176, 92, 0.4)',
                    borderRadius: '2px'
                  }}
                >
                  <Typography
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >{`Available Time : ${(50 - sum)?.toFixed(2)} hr`}</Typography>
                </Card>
                {/* <AppWidgetSummary
                  title={`${3000 - sum / 1000}min`}
                  total="Mins Available"
                  color="info"
                  bgColor="rgba(250, 83, 80,0.3)"
                  icon="material-symbols:avg-time-outline"
                /> */}
              </Grid>
              <Grid item md={0.8}>
                <DownloadIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={() =>
                    usageDetails &&
                    saveAs(
                      `${process.env.REACT_APP_SERVER_MEDIA_URL}${
                        downloadDetails[Object.keys(downloadDetails).find((el) => el === month)]
                      }`,
                      `${month}usage.csv`
                    )
                  }
                />
              </Grid>
            </Grid>
            <TableContainer sx={{ minWidth: 1000, maxHeight: 450, marginTop: 3 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}>
                      Org Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                    >
                      Customer Org Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                    >
                      Total files Processed
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                    >
                      Total time Processed (hr.)
                    </TableCell>
                  </TableRow>
                </TableHead>

                {!loading &&
                usageDetails &&
                usageDetails.usage_details[
                  Object.keys(usageDetails.usage_details).filter(
                    (el, index) => index === tabIndex
                  )[0]
                ] ? (
                  <TableBody>
                    {usageDetails &&
                      usageDetails.usage_details[
                        Object.keys(usageDetails.usage_details).filter(
                          (el, index) => index === tabIndex
                        )[0]
                      ].map((element) => (
                        <TableRow>
                          <TableCell align="left">{element.org_name}</TableCell>
                          <TableCell align="center">{element.customer_org_name}</TableCell>
                          <TableCell align="center">{element.total_files}</TableCell>
                          <TableCell align="center">
                            {/* {`${Math.floor(element.processed_time / 1000 / 60)}.${
                              Math.floor(element.processed_time / 1000) % 60
                            }`} */}
                            {element.processed_time}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      {loading ? (
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <CircularProgress sx={{ color: '#3892CF' }} />
                        </TableCell>
                      ) : (
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          Process Not found
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </TabPanel>
        </Grid>
      </Container>
    </Page>
  );
}
