/* eslint-disable  no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { FormikProvider, Form, FieldArray } from 'formik';
import {
  Grid,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Container,
  FormHelperText,
  Box,
  FormControlLabel,
  Avatar
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LoadingButton, LocalizationProvider, DatePicker } from '@mui/lab';
import uploadOutline from '@iconify/icons-mdi/upload-outline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Notification } from '../../components/Notification/Notification';
import API from '../../E2E/axios.util';

// ----------------------------------------------------------

export default function CustomerForm({ formik, handleDialog }) {
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleBlur } = formik;
  const [FileData, setFileData] = useState({ name: '', data: null });
  const [audioFile, setaudioFile] = useState({ name: '', data: null });

  const inputRef = React.useRef(null);
  const inputAudioRef = React.useRef(null);

  const [organisationList, setorganisationList] = useState();
  const [domainList, setdomainList] = useState();
  const [selected, setSelected] = useState([]);
  const [customerLanguage, setCustomerLanguage] = useState([]);
  const [selectDashboard, setSelectDashboard] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');

  const [salesChecked, setsalesChecked] = useState(false);
  const [collectionsChecked, setcollectionsChecked] = useState(false);

  // const [selectedOption, setSelectedOption] = useState([]);
  // console.log('SUCCESS', selectedOption);
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const options = ['tone', 'unpleasant', 'dead_air', 'sentiments'];
  const dashboards = [
    { name: 'Revenue Intelligence', id: 'sales' },
    { name: 'Call Analysis', id: 'collections' },
    { name: 'Compliance', id: 'compliance' },
    { name: 'Collections', id: 'collections_2' }
  ];
  // const options = [
  //   { label: 'Tone', value: 'Tone' },
  //   { label: 'Unpleasant', value: 'Unpleasant' },
  //   { label: 'Dead air', value: 'Dead_Air' },
  //   { label: 'Sentiments', value: 'Sentiments' },
  //   { label: 'Key Word', value: 'Key_words' },
  //   { label: 'Word cloud', value: 'Word_cloud' }
  // ];

  // const aws = formik.values.AwsServices;
  // // const nvidia = formik.values.NvidiaServices;
  // const customAi = formik.values.CustomAiServices;
  // console.log(aws, customAi);

  // useEffect(() => {
  //   // if (aws || nvidia === true) {
  //   //   setDisable(true);
  //   // } else if (nvidia || customAi === true) {
  //   //   setDisable(true);
  //   // } else
  //   if (aws || customAi === true) {
  //     setDisable(true);
  //   } else if (aws || customAi === false) {
  //     setDisable(false);
  //   }
  // }, [aws, customAi]);

  // <---------------------------------------------------------->
  const useStyles = makeStyles((theme) => ({
    formControl: {
      // margin: theme.spacing(1),
      width: 300
    },
    indeterminateColor: {
      color: '#f50057'
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    menuPaper: {
      maxHeight: 200
    }
  }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    variant: 'menu'
  };

  const classes = useStyles();
  const isAllSelected = options.length > 0 && selected.length === options.length;
  const check = options.map((el) => {
    console.log(selected.indexOf(el), 'selected.indexOf(option)');
    return el;
  });
  const handleChange = (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === options.length ? [] : options);
      formik.setFieldValue('customerServices', selected.length === options.length ? [] : options);
      return;
    }
    formik.setFieldValue('customerServices', value);
    setSelected(value);
  };
  const languageList = ['Hindi', 'English', 'Marathi', 'Gujrati', 'Arabic', 'Telugu', 'Oriya'];

  const isAllLanguageSelected =
    languageList.length > 0 && customerLanguage.length === languageList.length;
  // const check = options.map((el) => {
  //   console.log(selected.indexOf(el), 'selected.indexOf(option)');
  //   return el;
  // });
  const handleLanguageChange = (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setCustomerLanguage(customerLanguage.length === languageList.length ? [] : languageList);
      formik.setFieldValue(
        'language',
        customerLanguage.length === languageList.length ? [] : languageList
      );
      return;
    }
    formik.setFieldValue('language', value);
    setCustomerLanguage(value);
  };
  const newDash = dashboards.map((el) => el.name);
  const newDashId = dashboards.map((el) => el.id);
  // console.log(selectDashboard.indexOf(newDash), 'jhhk');
  const dashboardAllSelected = newDash.length > 0 && selectDashboard.length === newDash.length;
  const handleDashboardChange = (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setSelectDashboard(selectDashboard.length === newDash.length ? [] : newDash);
      formik.setFieldValue(
        'dashboardServices',
        selectDashboard.length === newDashId.length ? [] : newDashId
      );
      // formik.setFieldValue('AwsServices', false);
      // formik.setFieldValue('', false);
      return;
    }
    console.log('dashboardServicesValue', value);
    const dashboardServices = [];
    if (value.includes('Call Analysis')) {
      dashboardServices.push('collections');
    }
    if (value.includes('Revenue Intelligence')) {
      dashboardServices.push('sales');
    }
    if (value.includes('Compliance')) {
      dashboardServices.push('compliance');
    }
    if (value.includes('Collections')) {
      dashboardServices.push('collections_2');
    }
    formik.setFieldValue('dashboardServices', dashboardServices);
    // formik.setFieldValue('AwsServices', false);
    // formik.setFieldValue('CustomAiServices', false);
    setSelectDashboard(value);
  };
  const handleServiceChange = (event) => {
    console.log('first-----', [event.target.value]);
    formik.setFieldValue('dashboardServices', [event.target.value]);
    // setcollectionsChecked(false);
    // setsalesChecked(false);
    // formik.setFieldValue('AwsServices', false);
    // formik.setFieldValue('CustomAiServices', false);
  };
  // console.log('awsformik', formik.values.AwsServices);
  // console.log('customformik', formik.values.CustomAiServices);
  // <--------------------------------------------------------------------------->
  console.log('formikdashboardServices---', formik.values.dashboardServices);
  const handleCancel = () => {
    handleDialog();
    formik.resetForm();
  };
  const handleFileChange = (event, fileName) => {
    const fileReader = new FileReader();
    console.log('event-------', event);
    for (let i = 0; i < event.target.files.length; i += 1) {
      const file_type = event.target.files[i].type.substring(0, 6);
      if (file_type !== 'image/') {
        setAlertContent('Invalid file');
        setAlertType('error');
        setAlert(true);
        return;
      }
    }

    fileReader.onload = (e) => {
      if (fileReader.readyState === 2) {
        const file = e.target.result;
        setFileData({ name: fileName.name, data: file });
        formik.setFieldValue(`logo`, { name: fileName.name, data: file });
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };

  const handleFileName = (fileName) => {
    if (fileName.length > 10) {
      return fileName.slice(0, 20);
    }
    return fileName;
  };

  const handleAudioFileName = (fileName) => {
    if (fileName.length > 20) {
      return fileName.slice(0, 20);
    }
    return fileName;
  };

  const handleOrganisationList = () => {
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getOrganisation() {
      return API.get(`secure/list_organisation?page=1&max_rows=10`, config);
    }
    function getDomain() {
      return API.get(`secure/list_domain?page=1&max_rows=10`, config);
    }
    Promise.all([getOrganisation(), getDomain()])
      .then(([orgData, domainData]) => {
        setorganisationList(orgData.data.organisation_list);
        setdomainList(domainData.data.domain_list);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };
  useEffect(() => {
    handleOrganisationList();
  }, []);
  const handleAlert = () => {
    setAlert(false);
  };
  console.warn('formik.values.dashboardServices --', formik.values.dashboardServices);
  console.log('selectDashboard', selectDashboard);
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Select
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  {...getFieldProps('customerOrganisation')}
                  error={Boolean(touched.customerOrganisation && errors.customerOrganisation)}
                  helperText={touched.customerOrganisation && errors.customerOrganisation}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {organisationList
                    ? organisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.customerOrganisation && errors.customerOrganisation}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Customer Organisation Name"
              {...getFieldProps('customerOrgName')}
              error={Boolean(touched.customerOrgName && errors.customerOrgName)}
              helperText={touched.customerOrgName && errors.customerOrgName}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              {/* <InputLabel required id="Dashboards-select-label">
                Select Dashboards
              </InputLabel>
              <Select
                required
                labelId="Dashboards-select-label"
                label="Select Dashboards"
                id="select-dashboards"
                // {...getFieldProps('dashboardServices')}
                onChange={handleServiceChange}
                error={Boolean(touched.dashboardServices && errors.dashboardServices)}
                helperText={touched.dashboardServices && errors.dashboardServices}
              >
                {dashboards
                  ? dashboards.map((element) => (
                      // const { organisation_id, organisation_name } = element;
                      <MenuItem key={element.id} value={element.id}>
                        {element.name}
                      </MenuItem>
                    ))
                  : null}
              </Select> */}
              <InputLabel required labelId="Mutiple-select-label">
                Select Dashboards
              </InputLabel>
              <Select
                labelId="Mutiple-select-label"
                label="Select Dashboard"
                id="select-dashboard"
                name="dashboardServices"
                multiple
                value={selectDashboard}
                onChange={handleDashboardChange}
                renderValue={(selectDashboard) => selectDashboard.join(', ')}
                MenuProps={MenuProps}
                onBlur={handleBlur}
                error={Boolean(touched.dashboardServices && errors.dashboardServices)}
                helperText={touched.dashboardServices && errors.dashboardServices}
              >
                <MenuItem
                  value="all"
                  classes={{
                    // root: isAllSelected ? classes.selectedAll : ''
                    root: dashboardAllSelected ? classes.selectedAll : ''
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={dashboardAllSelected}
                      indeterminate={
                        selectDashboard.length > 0 && selectDashboard.length < newDash.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
                </MenuItem>
                {dashboards.map((dashboard) => (
                  <MenuItem key={dashboard.id} value={dashboard.name}>
                    <ListItemIcon>
                      <Checkbox checked={selectDashboard.indexOf(dashboard.name) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={dashboard.name} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.dashboardServices && errors.dashboardServices}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FieldArray
              name="process"
              render={(arrayHelpers) => (
                <>
                  {formik.values.process.map((row, index) => {
                    console.log('row ---', row);
                    console.log('index--', index);
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '5px'
                        }}
                      >
                        <TextField
                          required
                          fullWidth
                          label="Process"
                          {...getFieldProps(`process[${index}]`)}
                          error={Boolean(
                            touched &&
                              touched.process &&
                              touched.process[index] &&
                              touched.process[index] &&
                              errors &&
                              errors.process &&
                              errors.process[index] &&
                              errors.process[index]
                          )}
                          helperText={
                            touched &&
                            touched.process &&
                            touched.process[index] &&
                            touched.process[index] &&
                            errors &&
                            errors.process &&
                            errors.process[index] &&
                            errors.process[index]
                          }
                        />
                        {index === formik.values.process.length - 1 ? (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: `${index > 0 ? 'visible' : 'hidden'}`
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: 'hidden'
                              }}
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="First Name"
              {...getFieldProps('customerFirstName')}
              error={Boolean(touched.customerFirstName && errors.customerFirstName)}
              helperText={touched.customerFirstName && errors.customerFirstName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Last Name"
              {...getFieldProps('customerLastName')}
              error={Boolean(touched.customerLastName && errors.customerLastName)}
              helperText={touched.customerLastName && errors.customerLastName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Email"
              {...getFieldProps('customerEmail')}
              error={Boolean(touched.customerEmail && errors.customerEmail)}
              helperText={touched.customerEmail && errors.customerEmail}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Mobile"
              {...getFieldProps('customerMobile')}
              error={Boolean(touched.customerMobile && errors.customerMobile)}
              helperText={touched.customerMobile && errors.customerMobile}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="Organisation-select-label">
                Business Domain
              </InputLabel>
              <Select
                required
                labelId="Organisation-select-label"
                label="Business Domain"
                id="select-business"
                {...getFieldProps('businessDomain')}
                error={Boolean(touched.businessDomain && errors.businessDomain)}
                helperText={touched.businessDomain && errors.businessDomain}
              >
                {domainList
                  ? domainList.map((domain) => {
                      const { domain_id, domain_name } = domain;
                      return (
                        <MenuItem key={domain_id} value={domain_id}>
                          {domain_name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.businessDomain && errors.businessDomain}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      color: '#3892CF',
                      '&:hover': {
                        color: '#3892CF'
                      }
                    }}
                  />
                }
                label="Disposition Enable"
                onChange={(e) => {
                  formik.setFieldValue('dispositionEnable', e.target.checked);
                  if (e.target.checked === false) {
                    formik.setFieldValue('dispositionLevel', '');
                  }
                }}
              />
            </div>
          </Grid>
          {formik.values.dispositionEnable && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel required id="disposition-select-label">
                  Select Disposition Level
                </InputLabel>
                <Select
                  required
                  labelId="disposition-select-label"
                  label="Select Disposition Level"
                  id="select-level"
                  {...getFieldProps('dispositionLevel')}
                  error={Boolean(touched.dispositionLevel && errors.dispositionLevel)}
                  helperText={touched.dispositionLevel && errors.dispositionLevel}
                >
                  <MenuItem value="level_1">Level 1</MenuItem>
                  <MenuItem value="level_2">Level 2</MenuItem>
                  <MenuItem value="level_3">Level 3</MenuItem>
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.dispositionLevel && errors.dispositionLevel}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={6}>
            {/* <MultiSelect selected={selectedOption} onChange={setSelectedOption} options={options} /> */}
            <FormControl fullWidth>
              <InputLabel required labelId="Mutiple-select-label">
                Select KPI's
              </InputLabel>
              <Select
                required
                labelId="Mutiple-select-label"
                label="Select Process"
                id="select-process"
                name="customerServices"
                multiple
                value={selected}
                onChange={handleChange}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                onBlur={handleBlur}
                error={Boolean(touched.customerServices && errors.customerServices)}
                helperText={touched.customerServices && errors.customerServices}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : ''
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={selected.length > 0 && selected.length < options.length}
                    />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
                </MenuItem>
                {options.map((option) => (
                  <MenuItem key={option} value={option}>
                    <ListItemIcon>
                      <Checkbox checked={selected.indexOf(option) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.customerServices && errors.customerServices}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {/* <MultiSelect selected={selectedOption} onChange={setSelectedOption} options={options} /> */}
            <FormControl fullWidth>
              <InputLabel required labelId="Mutiple-select-label">
                Select Language's
              </InputLabel>
              <Select
                required
                labelId="Mutiple-select-label"
                label="Select Language"
                id="select-language"
                name="customerServices"
                multiple
                value={customerLanguage}
                onChange={handleLanguageChange}
                renderValue={(customerLanguage) => customerLanguage.join(', ')}
                MenuProps={MenuProps}
                onBlur={handleBlur}
                error={Boolean(touched.language && errors.language)}
                helperText={touched.language && errors.language}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllLanguageSelected ? classes.selectedAll : ''
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllLanguageSelected}
                      indeterminate={
                        customerLanguage.length > 0 && customerLanguage.length < languageList.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
                </MenuItem>
                {languageList.map((option) => (
                  <MenuItem key={option} value={option}>
                    <ListItemIcon>
                      <Checkbox checked={customerLanguage.indexOf(option) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.language && errors.language}
              </FormHelperText>
            </FormControl>
          </Grid>

          {/* <Grid item xs={6}>
            <div>
              {formik.values.dashboardServices.includes('collections') ? (
                <FormControlLabel
                  control={<Checkbox checked={collectionsChecked} />}
                  label="Custom AI Service"
                  onChange={(e) => {
                    formik.setFieldValue('CustomAiServices', e.target.checked);
                    setcollectionsChecked(e.target.checked);
                    formik.setFieldValue('AwsServices', false);
                  }}
                />
              ) : (
                formik.values.dashboardServices.includes('sales') && (
                  <FormControlLabel
                    control={<Checkbox checked={salesChecked} />}
                    label="AWS service"
                    onChange={(e) => {
                      formik.setFieldValue('AwsServices', e.target.checked);
                      setsalesChecked(e.target.checked);
                      formik.setFieldValue('CustomAiServices', false);
                    }}
                  />
                )
              )}
            </div>
          </Grid> */}
          <Grid item xs={6}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      color: formik.values.CustomAiServices === true ? 'grey' : '#3892CF',
                      '&:hover': {
                        color: '#3892CF'
                      }
                    }}
                  />
                }
                label="AWS service"
                onChange={(e) => {
                  formik.setFieldValue('AwsServices', e.target.checked);
                }}
                disabled={formik.values.CustomAiServices}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      color: formik.values.AwsServices === true ? 'grey' : '#3892CF',
                      '&:hover': {
                        color: '#3892CF'
                      }
                    }}
                  />
                }
                label="Custom AI Service"
                onChange={(e) => {
                  formik.setFieldValue('CustomAiServices', e.target.checked);
                }}
                disabled={formik.values.AwsServices}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div>
              <LoadingButton
                fullWidth
                required
                size="medium"
                variant="contained"
                startIcon={<Icon icon={uploadOutline} />}
                sx={{
                  height: 50,
                  // borderRadius: 5,
                  marginBottom: 5,
                  backgroundColor: '#3892CF',
                  '&:hover': {
                    backgroundColor: '#3892CF'
                  }
                }}
                onClick={() => {
                  inputRef.current?.click();
                }}
              >
                {!FileData.name ? 'Upload Logo' : handleFileName(FileData.name)}
                {FileData.data ? (
                  <Avatar src={FileData.data} alt="photoURL" sx={{ marginLeft: 2 }} />
                ) : null}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  multiple
                  ref={inputRef}
                  onChange={(event) => {
                    handleFileChange(event, event.currentTarget.files[0]);
                  }}
                  required
                />
              </LoadingButton>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: 10 }}
          >
            <LoadingButton
              variant="contained"
              // size="small"
              onClick={handleCancel}
              style={{
                marginRight: 5,
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              variant="contained"
              // size="small"
              onClick={handleSubmit}
              loading={isSubmitting}
              style={{
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              Add
            </LoadingButton>
          </Grid>
        </Grid>
        <Notification
          message={alertContent}
          open={alert}
          severity={alertType}
          onClose={handleAlert}
        />
      </Form>
    </FormikProvider>
  );
}
