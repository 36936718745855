import React, { useState, useEffect, useRef, useContext } from 'react';
import * as Yup from 'yup';
import './DealForm.css';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, FormikProvider, Form } from 'formik';
import { Icon } from '@iconify/react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import {
  Card,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TablePagination,
  Menu,
  MenuItem,
  IconButton,
  Button,
  ListItemIcon,
  ListItemText,
  Box,
  LinearProgress,
  Typography,
  Collapse,
  TableHead,
  Tooltip as Tip,
  InputLabel,
  FormControl,
  TextField,
  Container,
  FormHelperText,
  Grid,
  Select as Selectbox,
  Stack,
  Pagination
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import editFill from '@iconify/icons-eva/edit-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
// components
import { DealEditForm } from '../../components/custom-form-model/CRUDForms';
import TaskForm from './TaskForm';
import inputContex from '../../Context/UploadContext/inputContext';
import { CustomFormModel } from '../../components/custom-form-model/CustomFormModel';
import Scrollbar from '../../components/Scrollbar';
import API from '../../E2E/axios.util';
import { UserListHead } from '../../components/_dashboard/user';
import Page from '../../components/Page';
import { Notification } from '../../components/Notification/Notification';
import useWebSocket from '../../hooks/useWebSocket';

const UPLOAD_TABLE_HEAD = [
  {
    id: 'agent',
    numeric: false,
    disablePadding: false,
    label: 'Agent'
  },
  { id: 'dealName', numeric: false, disablePadding: false, label: 'Deal Name' },
  { id: 'dealStage', numeric: false, disablePadding: false, label: 'Deal Stage' },
  { id: 'taskName', numeric: false, disablePadding: false, label: 'Task Name' },
  { id: 'taskStatus', numeric: false, disablePadding: false, label: 'Task Status' },
  { id: '', label: '' }
];

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: '#3892CF',
        color: 'white'
        // borderRadius: '50%',
      }
    }
  }
}));

const UPLOADFILE_TABLE_HEAD = [
  { id: 'filename', numeric: false, disablePadding: false, label: 'File Name' },
  { id: 'organisation', numeric: false, disablePadding: false, label: '' },
  { id: 'customer', numeric: false, disablePadding: false, label: '' },
  { id: 'process', numeric: false, disablePadding: false, label: '' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' }
];

export default function AgentTask({ handleDialog, formik }) {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [update, setUpdate] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [uploadDataList, setuploadDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState();
  const [uploadSearch, setUploadSearch] = useState();
  const [newOpen, setNewOpen] = useState();

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');

  const handleAlert = () => {
    setAlert(false);
  };

  const handleNewDialog = () => {
    setNewOpen(!newOpen);
  };
  console.log(page, 'page');
  console.log(rowsPerPage, 'rowsPerPage');
  const userDetails = JSON.parse(localStorage.getItem('user'));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangePage = (event, newPage) => {
    console.log('newPage ----', newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUploadData = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/task_list?page=${page}`, config)
      // await API.get(`/secure/list_input?page=${page + 1}&max_rows=10`, config)
      .then((res) => {
        setuploadDataList(res.data);
        setAlertContent(`Success: Task list fetch successfully.`);
        setAlertType('success');
        setAlert(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
        setLoading(false);
      });
  };

  const handleSearch = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`secure/upload_search?page=${page + 1}&max_rows=10&query=${query}`, config)
      .then((res) => {
        setUploadSearch(res.data);
        console.log('search', res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const [message] = useWebSocket('task');
  useEffect(() => {
    getUploadData();
  }, [update, message, page]);

  // useEffect(() => {
  //   if (query) {
  //     handleSearch();
  //   }
  // }, [isUpdated, page]);

  const showTip = (value) => {
    if (value.length > 10) {
      return (
        <Tip title={value} followCursor placement="top">
          <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>{` ${value.slice(
            0,
            10
          )}.....`}</Typography>
          {/* <span style={{ fontSize: 12 }}>{value.slice(0, 10)}.....</span> */}
        </Tip>
      );
    }
    return <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>{value}</Typography>;
  };

  return (
    <Page title="Task">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Task
        </Typography>
        {/* <Button variant="contained" startIcon={<Icon icon={plusFill} />} onClick={handleNewDialog}>
          Add Task
        </Button> */}
      </Stack>

      <Card>
        {/* <Box sx={{ display: 'flex' }}>
        <UserListToolbar
          placeholder="Search by name"
          onFilterName={(e) => setQuery(e.target.value)}
        />
        {query && (
          <IconButton
            color="primary"
            aria-label="search"
            component="label"
            onClick={handleSearch}
            sx={{ marginTop: '25px', height: '50px', width: '50px' }}
          >
            <SearchOutlinedIcon />
          </IconButton>
        )}
      </Box> */}
        <Scrollbar sx={{ cursor: 'pointer' }}>
          <TableContainer>
            <Table>
              <UserListHead headLabel={UPLOAD_TABLE_HEAD} rowCount={page} />
              {!loading && uploadDataList && uploadDataList.results.length > 0 ? (
                <TableBody>
                  {uploadDataList.results
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        task_id,
                        org_name,
                        org_id,
                        customer_id,
                        customer_organisation_name,
                        agent_id,
                        first_name,
                        last_name,
                        deal_name,
                        deal_stage,
                        deal_status,
                        task_name,
                        task_status,
                        purpose,
                        created_date,
                        created_by
                      } = row;
                      return (
                        <>
                          <TableRow hover key={1} tabIndex={-1} role="checkbox">
                            <TableCell component="th" scope="row" padding="normal">
                              {first_name} {last_name}
                            </TableCell>
                            <TableCell component="th" scope="row" padding="normal">
                              {deal_name}
                            </TableCell>
                            <TableCell component="th" scope="row" padding="normal">
                              {deal_stage}
                            </TableCell>
                            <TableCell component="th" scope="row" padding="normal">
                              {task_name}
                            </TableCell>
                            <TableCell component="th" scope="row" padding="normal">
                              {task_status}
                            </TableCell>

                            {userDetails.role !== 'Manager' && (
                              <TableCell align="right">
                                <UserMoreMenu
                                  // formik={formik}
                                  isUpdated={update}
                                  updateComponent={setUpdate}
                                  setAlert={setAlert}
                                  setAlertContent={setAlertContent}
                                  setAlertType={setAlertType}
                                  inputId={task_id}
                                  inputDetails={row}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    {loading ? (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <CircularProgress sx={{ color: '#3892CF' }} />
                      </TableCell>
                    ) : (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        No Tasks Found
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <Stack
          spacing={2}
          sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
        >
          <Pagination
            classes={{
              root: classes.ul
            }}
            count={uploadDataList ? Math.ceil(uploadDataList?.count / 10) : 1}
            page={page}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </Stack>
        {/* {!loading && uploadSearch && uploadDataList ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            rowsPerPage={rowsPerPage}
            count={uploadDataList && uploadDataList.task_list.length}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            rowsPerPage={rowsPerPage}
            count={uploadDataList?.task_list ? uploadDataList?.task_list?.length : 0}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )} */}
        <Notification
          message={alertContent}
          open={alert}
          severity={alertType}
          onClose={handleAlert}
        />
      </Card>
    </Page>
  );
}

function UserMoreMenu({
  // formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  inputId,
  inputDetails
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const [taskOpen, setTaskOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(null);

  const handleDialog = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };

  const handleNewDialog = () => {
    setTaskOpen(!taskOpen);
    setIsOpen(false);
  };

  const handleDeleteInput = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.delete(`/secure/delete_task?id=${inputId}`, config)
      .then((res) => {
        setAlertContent(`Success :  Task deleted successfully.`);
        setAlertType('success');
        setAlert(true);
      })
      .then(() => {
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={openEdit}
        title="Update Task "
      >
        <TaskEditForm
          // formik={formik}
          isUpdated={isUpdated}
          updateComponent={updateComponent}
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          inputId={inputId}
          inputDetails={inputDetails}
          handleDialog={handleDialog}
        />
      </CustomFormModel>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleNewDialog}
        open={taskOpen}
        title="Task Details"
      >
        <TaskForm
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          handleDialog={handleNewDialog}
          inputDetails={inputDetails}
          isUpdated={isUpdated}
          updateComponent={updateComponent}
        />
      </CustomFormModel>

      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem to="#" sx={{ color: 'text.secondary' }} onClick={handleDialog}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem to="#" sx={{ color: 'text.secondary' }} onClick={handleNewDialog}>
          <ListItemIcon>
            <Icon icon={plusFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Add Task" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDeleteInput}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}

function TaskEditForm({
  // formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  inputId,
  inputDetails,
  handleDialog
}) {
  const inputRef = useRef(null);
  const [userAudio, setuserAudio] = useState({ File_Name: '', Data: '' });
  const {
    task_id,
    org_name,
    organisation_id,
    customer_id,
    customer_organisation_name,
    agent_id,
    first_name,
    last_name,
    deal_name,
    deal_stage,
    task_name,
    task_status,
    created_date,
    created_by
  } = inputDetails;

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));
  const classes = useStyles();
  const [customerID, setcustomerID] = useState(customer_id);
  const [organisationId, setorganisationId] = useState(organisation_id);
  const [domainId, setdomainId] = useState();
  const [agentId, setagentId] = useState(agent_id);
  const [TaskName, settaskName] = useState(task_name);
  const [TaskStatus, settaskStatus] = useState(task_status);
  const [taskId, settaskId] = useState(task_id);
  // console.log('------first', dealName);
  // const [dealId, setdealId] = useState(deal_id);
  const [dealStage, setdealStage] = useState(deal_stage);
  // const [dealStatus, setdealStatus] = useState(deal_status);
  const [inputProcess, setinputProcess] = useState();
  console.log('inputProcess---', inputProcess);
  const [createdDate, setcreatedDate] = useState();

  const [loading, setloading] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem('user'));

  /* ----- Creating state for customer, process and agent ------ */
  const [OrganisationList, setOrganisationList] = useState();
  const [customerList, setcustomerList] = useState();
  const [processList, setprocessList] = useState();
  console.log('inputProcess2----', processList);
  const [agentList, setagentList] = useState();

  // use formik for task .
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // customer: '',
      // organisationId: `${userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : ''}`,
      // agent: '',
      taskName: TaskName,
      taskStatus: TaskStatus
      // inputId: '',
      // deal_id
    },
    validationSchema: Yup.object().shape({
      // customer: Yup.string().required('This field is required.'),
      // organisationId: Yup.string().required('This field is required.'),
      // agent: Yup.string().required('This field is required.'),
      taskName: Yup.string()
        .matches(
          /^\S+[A-Za-z\s]{2,50}$/,
          'Task should be in between 3 to 50 character and it should not start with space and does not contain special character and number.'
        )
        .max(50, 'Task should be 3 to 50 character long.')
        .required('This field is required.'),
      taskStatus: Yup.string().required('This field is required.')
    }),
    onSubmit: async function handleEditUpload() {
      setloading(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        // id: input_id,
        // inputFile: formik.values.uploadFile,
        // customer_id: customerID,
        agent_id: agentId,
        // organisation_id: organisationId,
        task_name: formik.values.taskName,
        task_status: formik.values.taskStatus,
        id: taskId
        // domain_id: domainId,
        // original_date: createdDate
      };
      await API.put('/secure/update_task', data, config)
        .then((res) => {
          setloading(false);
          setAlertContent(`Success : ${res.data.message}`);
          setAlert(true);
          setAlertType('success');
          handleDialog();
        })
        .then(() => {
          updateComponent(isUpdated + 1);
        })
        .catch((err) => {
          setloading(false);
          setAlertContent(`${err.response.data.message}`);
          setAlert(true);
          setAlertType('error');
          handleDialog();
        });
    }
  });

  const { handleSubmit, isSubmitting } = formik;

  // const handleEditUpload = async () => {
  //   setloading(true);
  //   const token = localStorage.getItem('token');
  //   const config = {
  //     headers: { Authorization: `Bearer ${token}` }
  //   };
  //   const data = {
  //     // id: input_id,
  //     // inputFile: formik.values.uploadFile,
  //     // customer_id: customerID,
  //     // user_id: agentId,
  //     // organisation_id: organisationId,
  //     deal_name: dealName,
  //     deal_stage: dealStage,
  //     deal_status: dealStatus,
  //     id: dealId
  //     // domain_id: domainId,
  //     // original_date: createdDate
  //   };
  //   await API.put('/secure/update_deal', data, config)
  //     .then((res) => {
  //       setloading(false);
  //       setAlertContent(`Success : ${res.data.message}`);
  //       setAlert(true);
  //       setAlertType('success');
  //       handleDialog();
  //     })
  //     .then(() => {
  //       updateComponent(isUpdated + 1);
  //     })
  //     .catch((err) => {
  //       setloading(false);
  //       setAlertContent(`${err.response.data.message}`);
  //       setAlert(true);
  //       setAlertType('error');
  //       handleDialog();
  //     });
  // };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomer = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${organisationId}`, config)
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (organisationId) {
      getCustomer();
    } else {
      setcustomerList([]);
    }
  }, [organisationId]);

  const getProccesList = async (customerId) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(`/secure/process_list?customer_id=${customerId}`, config);
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAgentList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getRole() {
      return API.get(
        `secure/list_of_agent?customer_id=${customerID}&organisation_id=${organisationId}`,
        config
      );
    }
    Promise.all([getRole()])
      .then(([agentData]) => {
        setagentList(agentData.data.agent_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (customerID !== null) {
      // getProccesList(customerID);
      getAgentList();
    } else {
      // setprocessList(null);
      setagentList([]);
    }
  }, [customerID]);

  const setCustomerValue = (value) => {
    setcustomerID(value);
    const orgId = customerList.filter((element) => element.customer_id === value);
    console.log('orgId ---', orgId);
    setorganisationId(orgId[0].organisation_id);
    setdomainId(orgId[0].domain_id);
    setprocessList(null);
  };

  const setDateFormat = (date) => {
    const formatStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return formatStartDate;
  };

  const CustomInput = React.forwardRef((props, ref) => (
    <div style={{ position: 'relative' }}>
      <input {...props} ref={ref} />
      <CalendarMonthIcon
        onClick={props.onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '10px',
          // pointerEvents: 'none',
          cursor: 'pointer',
          zIndex: 5
        }}
      />
    </div>
  ));

  const handleCheckInDate = (date) => {
    const start_date = setDateFormat(date);
    setcreatedDate(start_date);
  };

  const taskStatusList = [
    { taskStatus_id: 'done', taskStatus_name: 'Done' },
    { taskStatus_id: 'pending', taskStatus_name: 'Pending' },
    { taskStatus_id: 'inProgress', taskStatus_name: 'In Progress' }
  ];

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={6}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              // <TextField
              //   required
              //   fullWidth
              //   label="Organisation Name"
              //   defaultValue={organisationId}
              //   disabled
              // />
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Selectbox
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  defaultValue={organisationId}
                  // {...formik.getFieldProps('organisationId)
                  onChange={(e) => setorganisationId(e.target.value)}
                  disabled
                >
                  {OrganisationList
                    ? OrganisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Selectbox
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  defaultValue={customerID}
                  // {...formik.getFieldProps('customer')}
                  onChange={(e) => setCustomerValue(e.target.value)}
                  disabled
                >
                  {customerList
                    ? customerList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={customer_id}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          {/* <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="process-select-label">
                Select Process
              </InputLabel>
              <Selectbox
                required
                labelId="process-select-label"
                label="Select Process"
                id="select-process"
                // {...formik.getFieldProps('process')}
                // defaultValue={inputProcess[0]}
                onChange={(e) => setinputProcess([e.target.value])}
              >
                {processList
                  ? processList.map((element) => (
                      <MenuItem key={element} value={element}>
                        {element}
                      </MenuItem>
                    ))
                  : null}
              </Selectbox>
            </FormControl>
          </Grid> */}
          <Grid item xs={6}>
            {/* <TextField required fullWidth label="Agnet Name" defaultValue={agentId} disabled /> */}
            {userDetails.role === 'Agent' ? (
              <FormControl fullWidth>
                <InputLabel required id="agent-select-label">
                  Select Agent
                </InputLabel>
                <Selectbox
                  required
                  labelId="agent-select-label"
                  label="Select Agent"
                  id="select-agent"
                  disabled
                  defaultValue={agentId}
                  onChange={(e) => {
                    setagentId(e.target.value);
                    formik.setFieldValue('agent', e.target.value);
                  }}
                >
                  {agentList
                    ? agentList.map((element) => {
                        const { user_id, first_name, last_name, empcode } = element;
                        return (
                          <MenuItem key={user_id} value={user_id}>
                            {empcode} - {first_name} {last_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="agent-select-label">
                  Select Agent
                </InputLabel>
                <Selectbox
                  required
                  labelId="agent-select-label"
                  label="Select Agent"
                  id="select-agent"
                  defaultValue={agentId}
                  onChange={(e) => {
                    setagentId(e.target.value);
                    formik.setFieldValue('agent', e.target.value);
                  }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {agentList
                    ? agentList.map((element) => {
                        const { user_id, first_name, last_name, empcode } = element;
                        return (
                          <MenuItem key={user_id} value={user_id}>
                            {empcode} - {first_name} {last_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Selectbox>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Task Name"
              defaultValue={TaskName}
              onChange={(e) => {
                settaskName(e.target.value);
                formik.setFieldValue('taskName', TaskName);
              }}
              error={Boolean(formik.touched.taskName && formik.errors.taskName)}
              helperText={formik.touched.taskName && formik.errors.taskName}
            />
            {/* <Field
              as={TextField}
              label="Deal Name"
              name="dealName"
              // value={dealName}
              // onChange={(e) => console.log(e.target.value)}
              fullWidth
              error={formik.errors.dealName && formik.touched.dealName}
              size="big"
              helperText={<ErrorMessage name="dealName" />}
              required
              sx={{ marginBottom: '1.5%' }}
            /> */}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="taskStatus-select-label">
                Select Task Status
              </InputLabel>
              <Selectbox
                required
                labelId="taskStatus-select-label"
                label="Select Task Status"
                id="select-task"
                defaultValue={TaskStatus}
                error={Boolean(formik.touched.taskStatus && formik.errors.taskStatus)}
                helperText={formik.touched.taskStatus && formik.errors.taskStatus}
                onChange={(e) => {
                  settaskStatus(e.target.value);
                  formik.setFieldValue('taskStatus', e.target.value);
                }}
              >
                {taskStatusList
                  ? taskStatusList.map((element) => {
                      const { taskStatus_id, taskStatus_name } = element;
                      return (
                        <MenuItem key={taskStatus_name} value={taskStatus_name}>
                          {taskStatus_name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Selectbox>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {formik.touched.taskStatus && formik.errors.taskStatus}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleDialog}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Update
          </LoadingButton>
        </Container>
      </Form>
    </FormikProvider>
  );
}
