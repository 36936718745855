import React, { useState, useRef, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { FormikProvider, Form } from 'formik';
import {
  Grid,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Container,
  FormHelperText,
  Alert,
  Typography,
  Rating,
  FormControlLabel
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { LoadingButton } from '@mui/lab';
import LinearProgress, { LinearProgressProps as props } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import DatePicker from 'react-datepicker';
// import './DealForm.css';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import API from '../../E2E/axios.util';
import { Notification } from '../../components/Notification/Notification';
// components

// ----------------------------------------------------------------------

const FeedbackForm = ({ formik, handleDialog, store }) => {
  const inputRef = useRef(null);
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const [userAudio, setuserAudio] = useState({ File_Name: '', Data: '' });

  const userDetails = JSON.parse(localStorage.getItem('user'));

  /* ----- Creating state for customer, process and agent ------ */
  const [customerList, setcustomerList] = useState();
  const [processList, setprocessList] = useState();
  const [agentList, setagentList] = useState();
  const [OrganisationList, setOrganisationList] = useState();
  const [checkInDate, setCheckInDate] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [value, setValue] = useState(3);

  const dealStageList = [
    { dealStage_id: 'briefing_complete', dealStage_name: 'Briefing Complete' },
    { dealStage_id: 'decision_awaited', dealStage_name: ' Decision Awaited' },
    { dealStage_id: 'contract_sent', dealStage_name: 'Contract Sent' },
    { dealStage_id: 'deal_won', dealStage_name: 'Deal Won' },
    { dealStage_id: 'deal_lost', dealStage_name: 'Deal Lost' }
  ];
  const dealStatusList = [
    { dealStatus_id: 'in_progress', dealStatus_name: 'In-progress' },
    { dealStatus_id: 'closed_won', dealStatus_name: 'Closed Won' },
    { dealStatus_id: 'closed_lost', dealStatus_name: 'Closed Lost' }
  ];

  const handleCancel = () => {
    handleDialog();
    formik.resetForm();
  };
  const handleAlert = () => {
    setAlert(false);
  };

  const handleAudioFileChange = (event, fileName) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (fileReader.readyState === 2) {
        const file = e.target.result;
        setuserAudio({ File_Name: fileName.name, Data: file });
        formik.setFieldValue(`uploadFile`, { File_Name: fileName.name, Data: file });
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/list_of_customer?organisation_id=${formik.values.organisationId}`,
      config
    )
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.organisationId !== '') {
      getCustomerList();
    } else {
      setcustomerList([]);
    }
  }, [formik.values.organisationId]);

  const getProccesList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `secure/process_list?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisationId}`,
        config
      );
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAgentList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getRole() {
      return API.get(
        `secure/list_of_agent?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisationId}`,
        config
      );
    }
    Promise.all([getRole()])
      .then(([agentData]) => {
        setagentList(agentData.data.agent_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (formik.values.customer !== '') {
      // getProccesList();
      getAgentList();
    } else {
      // setprocessList([]);
      setagentList([]);
    }
  }, [formik.values.customer]);
  // useEffect(() => {
  //   console.log(formik.values.process, 'formik.values.process');
  //   if (formik.values.process.length > 0) {
  //     getAgentList();
  //   } else {
  //     setagentList([]);
  //   }
  // }, [formik.values.process]);

  const setCustomerValue = (value) => {
    formik.setFieldValue('customer', value.customer_id);
    formik.setFieldValue('domainId', value.domain_id);
  };

  const handleProcess = (processValue) => {
    formik.setFieldValue('process', [processValue]);
  };

  // ---------------------------------------------
  const [audios, setAudios] = useState(formik.values.uploadFile);

  const setDateFormat = (date) => {
    const formatStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return formatStartDate;
  };

  useEffect(() => {
    if (store) {
      console.log('second', store);
      // formik.setFieldValue('requestId', store.request_id);
      formik.setFieldValue('inputId', store.input_id);
    }
  }, []);

  console.log('first', formik.values);

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));
  const classes = useStyles();

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={6}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Select
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  {...getFieldProps('organisationId')}
                  error={Boolean(touched.organisationId && errors.organisationId)}
                  helperText={touched.organisationId && errors.organisationId}
                  disabled
                >
                  {OrganisationList
                    ? OrganisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.organisationId && errors.organisationId}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Select
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  {...getFieldProps('customer')}
                  error={Boolean(touched.customer && errors.customer)}
                  helperText={touched.customer && errors.customer}
                  disabled
                  // onChange={(e) => setCustomerValue(e.target.value)}
                >
                  {customerList
                    ? customerList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={customer_id}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.customer && errors.customer}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          {/* <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="process-select-label">
                Select Process
              </InputLabel>
              <Select
                required
                labelId="process-select-label"
                label="Select Process"
                id="select-process"
                name="process"
                error={Boolean(touched.process && errors.process)}
                helperText={touched.process && errors.process}
                onChange={(e) => handleProcess(e.target.value)}
              >
                {processList
                  ? processList.map((element) => (
                      <MenuItem key={element} value={element}>
                        {element}
                      </MenuItem>
                    ))
                  : null}
              </Select>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.process && errors.process}
              </FormHelperText>
            </FormControl>
          </Grid> */}
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="agent-select-label">
                Select Agent
              </InputLabel>
              <Select
                required
                labelId="agent-select-label"
                label="Select Agent"
                id="select-agent"
                disabled
                {...getFieldProps('agent')}
                error={Boolean(touched.agent && errors.agent)}
                helperText={touched.agent && errors.agent}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {agentList
                  ? agentList.map((element) => {
                      const { user_id, first_name, last_name, empcode } = element;
                      return (
                        <MenuItem key={user_id} value={user_id}>
                          {empcode} - {first_name} {last_name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.agent && errors.agent}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {/* <InputLabel id="agent-select-label">Rating</InputLabel> */}
            <Box
              sx={{
                border: '1px solid RGB(220 224 228)',
                borderRadius: '5px',
                height: 54,
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center'
              }}
            >
              <Rating
                name="size-large"
                value={value}
                size="large"
                onChange={(e) => {
                  console.log('ratingfield', e.target.value);
                  setValue(e.target.value);
                  formik.setFieldValue('rating', e.target.value);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                fullWidth
                label=" Feedback"
                rows={2}
                multiline
                {...getFieldProps('feedBack')}
                error={Boolean(touched.feedBack && errors.feedBack)}
                helperText={touched.feedBack && errors.feedBack}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleCancel}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={isSubmitting}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Submit
          </LoadingButton>
        </Container>
        <Notification
          message={alertContent}
          open={alert}
          severity={alertType}
          onClose={handleAlert}
        />
      </Form>
    </FormikProvider>
  );
};

export default FeedbackForm;

// function LinearProgressWithLabel(props, value) {
//   return (
//     <Box sx={{ display: 'flex', alignItems: 'center' }}>
//       {/* <Box sx={{ width: '50%', mr: 1 }}>
//         <LinearProgress variant="determinate" {...props} />
//       </Box> */}
//       <Box sx={{ minWidth: 35 }}>
//         <Typography variant="body2" color="text.secondary">{`${Math.round(
//           props.value
//         )}%`}</Typography>
//       </Box>
//     </Box>
//   );
// }
