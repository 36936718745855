import React, { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
// material
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import API from '../E2E/axios.util';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import CampaignForm from '../layouts/Campaign/CampaignForm';
import { CampaignDataModel } from '../layouts/Campaign/CampaignDataModel';
import { Notification } from '../components/Notification/Notification';
import useWebSocket from '../hooks/useWebSocket';
import inputContex from '../Context/UploadContext/inputContext';

// ----------------------------------------------------------------------

export default function UploadContent() {
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [open, setOpen] = useState();
  const [uploadDataList, setuploadDataList] = useState();
  const [update, setUpdate] = useState(0);
  const [uploaded, setUploaded] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // Context API
  const { uploadStatus, setuploadStatus } = useContext(inputContex);

  const userDetails = JSON.parse(localStorage.getItem('user'));

  const UploadSchemma = Yup.object().shape({
    customer: Yup.string().required('This field is required.'),
    organisationId: Yup.string().required('This field is required.'),
    campaignName: Yup.array().of(
      Yup.string()
        .matches(
          /^\S+[A-Za-z\s]{2,50}$/,
          'Campaign should be in between 3 to 30 character long and it should not start with space and does not contain special character and number.'
        )
        .max(30, 'Campaign should be in between 3 to 30 character long.')
        .required('This field is required.')
    ),
    campaignProduct: Yup.string()
      .matches(
        /^\S+[A-Za-z\s]{2,50}$/,
        'Product should not start with space and does not contain special character and number.'
      )
      .max(30, 'Product name should be in between 3 to 30 character long.')
      .required('This field is required.'),
    startDate: Yup.date().required('This field is required.'),
    endDate: Yup.date().required('This field is required.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: userDetails.role === 'QA' ? userDetails.customer_id : '',
      organisationId: `${userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : ''}`,
      campaignName: [''],
      campaignProduct: '',
      startDate: '',
      endDate: ''
    },
    validationSchema: UploadSchemma,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        onUploadProgress: (data) => {
          setUploaded(Math.round((data.loaded / data.total) * 100));
        }
      };
      const data = {
        customer_id: formik.values.customer,
        organisation_id: formik.values.organisationId,
        campaign_name: formik.values.campaignName,
        product: formik.values.campaignProduct,
        campaign_startdate: formik.values.startDate,
        campaign_enddate: formik.values.endDate
      };
      console.log('data ---------------------', data);
      await API.post('/secure/add_campaign', data, config)
        .then((res) => {
          formik.resetForm();
          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          handleDialog();
          setUpdate(update + 1);
        })
        .catch((err) => {
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });

  const handleAlert = () => {
    setAlert(false);
  };

  const handleDialog = () => {
    setOpen(!open);
    formik.resetForm();
  };

  return (
    <Page title="Campaign details">
      <>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Campaigns {uploadDataList ? `(${uploadDataList.count})` : ''}
          </Typography>
          {userDetails.role !== 'Manager' && (
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={handleDialog}
              sx={{
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              Add Campaign
            </Button>
          )}
        </Stack>
        <Container>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleDialog}
            open={open}
            title="Add Campaign"
          >
            <CampaignForm handleDialog={handleDialog} formik={formik} uploaded={uploaded} />
          </CustomFormModel>
        </Container>
        <CampaignDataModel
          handleDialog={handleDialog}
          formik={formik}
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          isUpdated={update}
          updateComponent={setUpdate}
          uploadDataList={uploadDataList}
          setuploadDataList={setuploadDataList}
        />
      </>
      <Notification
        message={alertContent || 'Campaign list fetch successfully.'}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}
