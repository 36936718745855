import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import API from '../../../E2E/axios.util';
import API from '../../E2E/axios.util';
// import { Notification } from '../../Notification/Notification';
import { Notification } from '../../components/Notification/Notification';

// ----------------------------------------------------------------------

export default function SetPasswordForm() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const location = useLocation().pathname.toString();
  // console.log(location.split('/'));
  const token = location.split('/');

  const ForgetSchema = Yup.object().shape({
    password: Yup.string()
      .required('This field is required.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    confirmpassword: Yup.string()
      .required('This field is required.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmpassword: ''
    },
    validationSchema: ForgetSchema,
    onSubmit: async () => {
      await (formik.values.password === formik.values.confirmpassword
        ? handleRequest(formik)
        : handleError());
    }
  });

  const handleRequest = async (formik) => {
    const data = {
      password: formik.values.password,
      confirmPassword: formik.values.confirmpassword
    };
    // const headers = {
    //   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8;application/json'
    // };
    const config = {
      headers: { Authorization: `Bearer ${token[2]}` }
    };
    await API.post('auth/reseting_password_done', data, config)
      .then((res) => {
        console.log('forget password', res.data.message);
        formik.resetForm();
      })
      .then(() => {
        setAlertContent(`Success: Password set successfully.`);
        setAlertType('success');
        setAlert(true);
      })
      .catch((err) => {
        setAlertContent(
          `Please check your password: ${
            err.response.data.message ? err.response.data.message : 'Internal Server Error'
          }`
        );
        setAlert(true);
        setAlertType('error');
      });
  };
  const handleAlert = () => {
    setAlert(false);
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((showConfirm) => !showConfirm);
  };
  const handleError = () => {
    setAlertContent('Please check your both passwords.');
    setAlert(true);
    setAlertType('error');
  };

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        // onSubmit={
        //   formik.values.password === formik.values.confirmpassword ? handleSubmit : handleError
        // }
      >
        <Stack spacing={3} sx={{ marginBottom: 3 }}>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password *"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            autoComplete="confirm-password"
            type={showConfirmPassword ? 'text' : 'password'}
            label="Confirm Password *"
            {...getFieldProps('confirmpassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowConfirmPassword} edge="end">
                    <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmpassword && errors.confirmpassword)}
            helperText={touched.confirmpassword && errors.confirmpassword}
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          style={{
            backgroundColor: '#3892CF',
            '&:hover': {
              backgroundColor: '#3892CF'
            }
          }}
        >
          RESET PASSWORD
        </LoadingButton>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
          <Link
            variant="subtitle2"
            sx={{ cursor: 'pointer', color: '#3892CF' }}
            onClick={() => navigate('/Login', { replace: true })}
          >
            Sign In
          </Link>
        </Stack>
      </Form>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </FormikProvider>
  );
}
