import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Tooltip
} from '@mui/material';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    // color: 'yellow',
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: '#3892CF'
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active, sidebarCollapse }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);
  console.log('item', item);
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    // color: 'primary.main',
    color: '#3892CF',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    // color: 'text.primary',
    // color: 'primary.main',
    color: '#3892CF',
    fontWeight: 'fontWeightMedium'
  };
  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          disablePadding
          sx={{
            display: !sidebarCollapse && 'flex',
            // justifyContent: 'center',
            alignItems: 'center',
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          {sidebarCollapse ? (
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          ) : (
            <Tooltip title={title} followCursor placement="top">
              <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            </Tooltip>
          )}
          {sidebarCollapse && <ListItemText disableTypography primary={title} />}
          {info && info}
          {sidebarCollapse && (
            <Box
              component={Icon}
              icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
              sx={{ width: 16, height: 16, ml: 1 }}
            />
          )}
        </ListItemStyle>

        <Collapse in={open} timeout={650} unmountOnExit>
          <List component="div" style={{ padding: 10 }}>
            {children.map((item) => {
              const { title, path, icon } = item;
              const isActiveSub = active(path);
              if (title) {
                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle)
                    }}
                  >
                    {sidebarCollapse ? (
                      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                    ) : (
                      <Tooltip title={title} followCursor placement="top">
                        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                      </Tooltip>
                    )}
                    {sidebarCollapse && <ListItemText disableTypography primary={title} />}
                  </ListItemStyle>
                );
              }
              return null;
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <>
      {item && (
        <ListItemStyle
          component={RouterLink}
          to={path}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </ListItemStyle>
      )}
    </>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, sidebarCollapse, ...other }) {
  const { pathname } = useLocation();
  console.log('navconfig', navConfig);
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  return (
    <Box
      sx={{
        display: !sidebarCollapse && 'flex',
        flexDirection: !sidebarCollapse && 'column',
        alignItems: !sidebarCollapse && 'center',
        justifyContent: !sidebarCollapse && 'center'
      }}
      {...other}
    >
      <List disablePadding>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} sidebarCollapse={sidebarCollapse} />
        ))}
      </List>
    </Box>
  );
}
