/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  FormHelperText,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  FormControlLabel,
  Container
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import makeStyles from '@mui/styles/makeStyles';
import { SettingsSystemDaydreamRounded } from '@mui/icons-material';

const ParameterItem = ({
  index,
  languageList,
  arrayHelpers,
  formik,
  parameterList,
  categoryList,
  row,
  setItsNewParameter,
  itsNewParameter,
  handleDialog,
  setParameter,
  update
}) => {
  const [aiMode, setAIMode] = useState(false);
  const { touched, errors } = formik;
  const FieldType = [{ name: 'Boolean' }, { name: 'Multivalue' }];
  const handleParameter = (e, index) => {
    formik.setFieldValue(`keywords[${index}].Category`, e.target.value);
    const selectedValue = e.target.value;
    const selectedParameter = parameterList.find((param) => param.name === selectedValue);
    if (selectedParameter) {
      formik.setFieldValue(`keywords[${index}].weightage`, selectedParameter.max_score);
    }
  };
  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200,
      maxWidth: 80
    }
  }));

  const classes = useStyles();

  const IOSSwitch = styled((props) => (
    <Switch
      //   onChange={onChange}
      //   focusVisibleClassName=".Mui-focusVisible"
      //   disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#3892CF',
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  }));

  // useEffect(() => {
  //   const formikLanguage = languageList.map((el) => ({
  //     language: el,
  //     phrases: ['']
  //   }));
  //   formik.setFieldValue(`keywords[${index}].description`, formikLanguage);
  // }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // alignItems: 'center',
        marginTop: '-20px'
      }}
    >
      <Grid item xs={3} sx={{ marginBottom: '10px' }}>
        <FormControlLabel
          sx={{ marginLeft: 1, marginTop: 2 }}
          control={
            <IOSSwitch
              sx={{ marginRight: 1 }}
              onChange={(e) => {
                formik.setFieldValue(`keywords[${index}].aiMode`, e.target.checked);
              }}
              checked={
                formik.values.keywords[index]?.aiMode
                  ? formik.values.keywords[index]?.aiMode
                  : false
              }
            />
          }
          label="AI Mode"
        />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <TextField
              required
              fullWidth
              label="Select Parameter"
              name={`keywords[${index}].Category`}
              disabled={update}
              value={formik.values.keywords[index]?.Category}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              onChange={(e) => handleParameter(e, index)}
              onBlur={formik.handleBlur}
              // {...getFieldProps(`keywords[${index}].Category`)}
              error={Boolean(
                touched &&
                  touched.keywords &&
                  touched.keywords[index] &&
                  touched.keywords[index].Category &&
                  errors &&
                  errors.keywords &&
                  errors.keywords[index] &&
                  errors.keywords[index].Category
              )}
              helperText={
                touched &&
                touched.keywords &&
                touched.keywords[index] &&
                touched.keywords[index].Category &&
                errors &&
                errors.keywords &&
                errors.keywords[index] &&
                errors.keywords[index].Category
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel required id="category-select-label">
              Select Category
            </InputLabel>
            <Select
              required
              labelId="category-select-label"
              label="Select Category"
              id="select-Category"
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              disabled={update}
              {...formik.getFieldProps(`keywords[${index}].Parameter`)}
              error={Boolean(
                touched &&
                  touched.keywords &&
                  touched.keywords[index] &&
                  touched.keywords[index].Parameter &&
                  errors &&
                  errors.keywords &&
                  errors.keywords[index] &&
                  errors.keywords[index].Parameter
              )}
              helperText={
                touched &&
                touched.keywords &&
                touched.keywords[index] &&
                touched.keywords[index].Parameter &&
                errors &&
                errors.keywords &&
                errors.keywords[index] &&
                errors.keywords[index].Parameter
              }
            >
              {categoryList.map((element) => (
                <MenuItem key={element} value={element}>
                  {element}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {touched &&
                touched.keywords &&
                touched.keywords[index] &&
                touched.keywords[index].Parameter &&
                errors &&
                errors.keywords &&
                errors.keywords[index] &&
                errors.keywords[index].Parameter}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel required id="category-select-label">
              Select Type
            </InputLabel>
            <Select
              required
              labelId="category-select-label"
              label="Select Type"
              id="select-Category"
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              {...formik.getFieldProps(`keywords[${index}].Type`)}
              disabled={update}
              error={Boolean(
                touched &&
                  touched.keywords &&
                  touched.keywords[index] &&
                  touched.keywords[index].Type &&
                  errors &&
                  errors.keywords &&
                  errors.keywords[index] &&
                  errors.keywords[index].Type
              )}
              helperText={
                touched &&
                touched.keywords &&
                touched.keywords[index] &&
                touched.keywords[index].Type &&
                errors &&
                errors.keywords &&
                errors.keywords[index] &&
                errors.keywords[index].Type
              }
            >
              {FieldType.map((element) => (
                <MenuItem key={element.name} value={element.name}>
                  {element.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {touched &&
                touched.keywords &&
                touched.keywords[index] &&
                touched.keywords[index].Type &&
                errors &&
                errors.keywords &&
                errors.keywords[index] &&
                errors.keywords[index].Type}
            </FormHelperText>
          </FormControl>
        </Grid>
        {/* {formik.values.keywords[index].Type === 'Multivalue' && ( */}
        <Grid item xs={3}>
          <TextField
            required
            fullWidth
            label="Weightage"
            name={`keywords[${index}].weightage`}
            onBlur={formik.handleBlur}
            // {...getFieldProps(`keywords[${index}].words`)}
            value={formik.values.keywords[index]?.weightage}
            disabled={update}
            onChange={(e) =>
              formik.setFieldValue(`keywords[${index}].weightage`, Number(e.target.value))
            }
            // disabled
            InputLabelProps={{
              shrink:
                formik.values.keywords[index]?.weightage ||
                formik.values.keywords[index]?.weightage === 0
                  ? true
                  : false
            }}
            // InputProps={{ disableUnderline: true }}
            // onChange={(e) => {
            //   // console.warn('keywords --------------', e.target.value);
            //   formik.setFieldValue(
            //     `keywords[${index}].Value`,
            //     e.target.value.split(',')
            //   );
            // }}
            error={Boolean(
              touched &&
                touched.keywords &&
                touched.keywords[index] &&
                touched.keywords[index].weightage &&
                errors &&
                errors.keywords &&
                errors.keywords[index] &&
                errors.keywords[index].weightage
            )}
            helperText={
              touched &&
              touched.keywords &&
              touched.keywords[index] &&
              touched.keywords[index].weightage &&
              errors &&
              errors.keywords &&
              errors.keywords[index] &&
              errors.keywords[index].weightage
            }
          />
        </Grid>

        {languageList.map((el, langIndex) => (
          <Grid item xs={12} md={12} sm={12}>
            <TextField
              required
              fullWidth
              multiline
              rows={1}
              label={`${el} Phrases`}
              // name="keywords"
              onBlur={formik.handleBlur}
              // {...getFieldProps(`keywords[${index}].words`)}
              name={`keywords[${index}].description[${langIndex}].phrases`}
              value={formik.values?.keywords[index]?.description[langIndex]?.phrases?.toString()}
              onChange={(e) => {
                // console.warn('keywords --------------', e.target.value);
                formik.setFieldValue(
                  `keywords[${index}].description[${langIndex}].phrases`,
                  e.target.value.split(',')
                );
              }}
              error={Boolean(
                touched &&
                  touched.keywords &&
                  touched.keywords[index] &&
                  touched.keywords[index].description &&
                  touched.keywords[index].description[langIndex] &&
                  touched.keywords[index].description[langIndex].phrases &&
                  errors &&
                  errors.keywords &&
                  errors.keywords[index] &&
                  errors.keywords[index].description &&
                  errors.keywords[index].description[langIndex] &&
                  errors.keywords[index].description[langIndex].phrases
              )}
              helperText={
                touched &&
                touched.keywords &&
                touched.keywords[index] &&
                touched.keywords[index].description &&
                touched.keywords[index].description[langIndex] &&
                touched.keywords[index].description[langIndex].phrases &&
                errors &&
                errors.keywords &&
                errors.keywords[index] &&
                errors.keywords[index].description &&
                errors.keywords[index].description[langIndex] &&
                errors.keywords[index].description[langIndex].phrases
              }
            />
          </Grid>
        ))}

        {/* )} */}
      </Grid>
      <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
        {/* <LoadingButton
          variant="contained"
          onClick={() => formik.resetForm()}
          style={{
            marginRight: 5,
            backgroundColor: '#3892CF',
            '&:hover': {
              backgroundColor: '#3892CF'
            }
          }}
        >
          Reset
        </LoadingButton> */}
        <LoadingButton
          variant="contained"
          onClick={() => {
            if (
              Object.keys(formik.errors).length === 0 ||
              formik.errors.keywords[index] === undefined
            ) {
              setParameter(formik.values.keywords);
              handleDialog();
              // formik.handleSubmit();
            } else {
              formik.handleSubmit();
            }
          }}
          // loading={isSubmitting}
          style={{
            marginRight: 5,
            backgroundColor: '#3892CF',
            '&:hover': {
              backgroundColor: '#3892CF'
            }
          }}
        >
          Add
        </LoadingButton>
      </Container>

      {/* {index === formik.values.keywords.length - 1 ? (
        <>
          <RemoveCircleIcon
            sx={{
              color: 'white',
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              },
              borderRadius: '50%',
              cursor: 'pointer',
              marginX: '5px',
              visibility: `${index > 0 ? 'visible' : 'hidden'}`
            }}
            onClick={() => {
              arrayHelpers.remove(index);
            }}
          />
          <AddCircleIcon
            sx={{
              color: 'white',
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              },
              borderRadius: '50%',
              cursor: 'pointer',
              marginX: '5px'
            }}
            onClick={() => {
              arrayHelpers.push({
                description: [''],
                Category: '',
                Parameter: '',
                Type: ''
              });
            }}
          />
        </>
      ) : (
        <>
          <RemoveCircleIcon
            sx={{
              color: 'white',
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              },
              borderRadius: '50%',
              cursor: 'pointer',
              marginX: '5px'
            }}
            onClick={() => {
              arrayHelpers.remove(index);
            }}
          />
          <AddCircleIcon
            sx={{
              color: 'white',
              backgroundColor: 'blue',
              borderRadius: '50%',
              cursor: 'pointer',
              marginX: '5px',
              visibility: 'hidden'
            }}
            onClick={() => {
              arrayHelpers.push({
                description: [''],
                Category: '',
                Parameter: '',
                Type: ''
              });
            }}
          />
        </>
      )} */}
    </div>
  );
};

export default ParameterItem;
