/* eslint-disable   no-nested-ternary */
/* eslint-disable   no-unneeded-ternary */
/* eslint-disable   no-else-return */
/* eslint-disable    no-lone-blocks */
/* eslint-disable-next-line no-inner-declarations */

import React, { useEffect, useContext, useState, useRef } from 'react';
import * as Yup from 'yup';
import vttToJson from 'vtt-2-json';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import fileDownload from '@iconify/icons-fa-solid/file-download';
import arrowLeft from '@iconify/icons-mdi/arrow-left';
import VerifiedIcon from '@mui/icons-material/Verified';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

// import AddIcon from '@mui/icons-material/Add';
import { useFormik, FormikProvider, Form } from 'formik';
// material
import {
  Box,
  Container,
  Typography,
  Paper as Paperbox,
  Grid,
  Tabs,
  Tab,
  Card,
  CardHeader,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  useMediaQuery,
  Button,
  Chip,
  Tooltip as Tip,
  Checkbox,
  TextField,
  Link,
  IconButton,
  Menu,
  MenuItem,
  FormHelperText,
  Select,
  InputLabel,
  FormControl,
  ListItemText,
  Rating,
  ToggleButtonGroup,
  ToggleButton,
  Modal,
  Collapse,
  Switch,
  FormControlLabel
} from '@mui/material';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import { LoadingButton } from '@mui/lab';
import PlusIcon from '@mui/icons-material/Add';

import eyeIcon from '@iconify/icons-mdi/eye';
// material
import { useTheme, styled } from '@mui/material/styles';
import { merge } from 'lodash';
//

import Highlighter from 'react-highlight-words';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  ComposedChart,
  LineChart,
  Legend,
  Cell,
  PieChart as PieChartForQRC,
  Pie,
  LabelList
} from 'recharts';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import LockIcon from '@mui/icons-material/Lock';
import {
  CircularGauge,
  Scale,
  RangeContainer,
  Range,
  Tooltip as GaugeTooltip,
  Font
} from 'devextreme-react/circular-gauge';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { saveAs } from 'file-saver';

// components
// import { grey } from '@mui/material/colors';
import './CSS/Cockpit.css';
import { BaseOptionChart } from '../components/charts';
import API from '../E2E/axios.util';
import Keywords from '../layouts/Upload/Graphs/Keywords';
import Phrases from '../layouts/Upload/Graphs/Phrases';
import Page from '../components/Page';
import PieChart from '../layouts/Upload/Graphs/PieChart';
import PieChartSentiment from '../layouts/Upload/Graphs/PieChartSentiment';
import { Notification } from '../components/Notification/Notification';
import Wordcloud from '../layouts/Upload/Graphs/Wordcloud';
import inputContext from '../Context/UploadContext/inputContext';
import AudioWaveform from './AudioWaveform';
import AudioWaveformNew from './AudioWaveformNew';
import { UserListHead } from '../components/_dashboard/user';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import { SmallCustomFormModel } from '../components/custom-form-model/SmallCustomFormModel';
import BarChart from '../layouts/Upload/Graphs/BarChart';
import CustomerSentiment from '../layouts/Upload/Graphs/CustomerSentiment';
import AgentSentiment from '../layouts/Upload/Graphs/AgentSentiment';
import FeedbackForm from '../layouts/Compliance/FeedbackForm';
import ScoreForm from '../layouts/Compliance/ScoreForm';
import DealForm from '../layouts/Deal/DealForm';

// const color = grey[500];

// ----------------------------------------------------------------------

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
function a12yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    // backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    backgroundColor: theme.palette.mode === 'light' ? '#cce6ff' : '#308fe8'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: theme.palette.mode === 'light' ? '#3399ff' : '#308fe8'
  }
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          color={props.value > 80 ? 'success' : props.value > 50 ? 'warning' : 'error'}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#1D9FD9' // change this to your desired color
    }
  },
  tab: {
    '&.Mui-selected': {
      color: '#1D9FD9' // change this to your desired color
    }
  },
  menuScoreCard: {
    maxHeight: 150,
    maxWidth: 50
  }
}));

export default function Cockpit() {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const classes = useStyles();
  const locationData = useLocation();
  console.log('locationData', locationData);
  const navigate = useNavigate();
  const [defaultState, setDefaultState] = React.useState('TRANSCRIPT');
  const [jsonTranscript, setJsonTranscript] = React.useState();
  const audioElement = React.useRef(null);

  const [activeTab, setActiveTab] = React.useState(0);
  const [agentPhraseTab, setAgentPhraseTab] = React.useState(0);
  const [customerPhraseTab, setCustomerPhraseTab] = React.useState(0);
  const [valueWaveform, setValueWaveform] = React.useState(1);
  const [audioplay, setAudioplay] = React.useState(false);
  const [customerBar, setCustomerBar] = React.useState(true);
  const [agentBar, setAgentBar] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const [audioDuration, setAudioDuration] = React.useState();
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [isReviewScoreExpanded, setIsReviewScoreExpanded] = React.useState(false);

  const {
    inputId,
    inputOrgId,
    setinputOrgId,
    requestId,
    uploadedAudio,
    fileProcess,
    customerId,
    setcustomerId,
    audioS3,
    originalDate,
    toneIndex,
    customerSentimentIndex,
    agentSentimentIndex,
    kpi,
    agentId,
    agentName,
    agentLastName,
    setAgentName,
    setAgentLastName
  } = useContext(inputContext);

  // Creating state for api
  const [transcriptData, setTranscriptData] = React.useState();
  const [summaryData, setSummaryData] = React.useState();
  const [entityrelationSummary, setentityrelationSummary] = React.useState();
  const [entityrelationTranscript, setentityrelationTranscript] = React.useState();
  const [keywordSummary, setkeywordSummary] = React.useState();
  const [keywordTranscript, setkeywordTranscript] = React.useState();
  const [summarySentiments, setsummarySentiments] = React.useState();
  const [summarySentimentsOverall, setsummarySentimentsOverall] = React.useState();
  const [transcriptSentiments, settranscriptSentiments] = React.useState();
  const [transcriptSentimentsOverall, settranscriptSentimentsOverall] = React.useState();
  const [wordCloudForSummary, setwordCloudForSummary] = React.useState();
  const [wordCloudForTranscript, setwordCloudForTranscript] = React.useState();
  const [transcript_media, settranscript_media] = React.useState();

  const [get_sentimentanalysis_speakers, setGet_sentimentanalysis_speakers] = React.useState();

  const [getUnpleasantWord, setgetUnpleasantWord] = React.useState();
  const [toneData, setToneData] = React.useState();
  const [sentimentData, setSentimentData] = React.useState();
  const [uniqueTranscriptData, setUniqueTranscriptData] = React.useState();
  // console.log('uniqueTranscriptData', uniqueTranscriptData);
  const [energyValuesData, setEnergyValuesData] = React.useState();
  // console.log('sentiment', sentimentData);
  // console.log('energyValuesData', energyValuesData);
  const [agentTonePercentage, setagentTonePercentage] = React.useState();
  const [agentSentimentPercentage, setagentSentimentPercentage] = React.useState();
  const [deadAirData, setdeadAirData] = React.useState();
  const [customerTonePercentage, setcustomerTonePercentage] = React.useState();
  const [customerSentimentPercentage, setcustomerSentimentPercentage] = React.useState();
  // console.log('customerSentimentPercentage', customerSentimentPercentage);
  const [s3Url, setS3Url] = React.useState();
  const [dashboardServices, setDashboardServices] = React.useState([]);
  // console.log('dashboardServices', dashboardServices);
  const [value, setValue] = React.useState(5);
  console.log('valueforTabs', value);
  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState('');
  // console.log('notification-----', alertContent);
  const [alertType, setAlertType] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [customerOpen, setCustomerOpen] = React.useState(false);
  const [toneOpen, setToneOpen] = React.useState(false);
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);
  const [scoreFormOpen, setScoreFormOpen] = React.useState(false);
  const [snippetOpen, setSnippetOpen] = React.useState(false);
  const [reasonOpen, setReasonOpen] = React.useState(false);
  const [snippetPlay, setSnippetPlay] = React.useState();
  const [sentimentOpen, setSentimentOpen] = React.useState(false);
  const [agentSentimentOpen, setAgentSentimentOpen] = React.useState(false);
  const [QRCOpen, setQRCOpen] = React.useState(false);
  const [fixTone, setFixToneData] = React.useState(false);
  const [similarSearchData, setSimilarSearchData] = React.useState();
  const [keywordTrackData, setKeywordTrackData] = React.useState();
  const [collectionData, setCollectionData] = React.useState();
  const [concatData, setConcatData] = React.useState([]);
  console.log('concatData', concatData);
  const [collectionConcatData, setCollectionConcatData] = React.useState([]);
  const [cockpitSalesData, setCockpitSalesData] = React.useState(null);
  const [scoreCardData, setScoreCardData] = React.useState();
  const [salesIntentSentence, setSalesIntentSentence] = React.useState([]);
  const [salesIntentOpen, setSalesIntentOpen] = React.useState(false);
  const [salesIntentName, setSalesIntentName] = React.useState('');

  const [categoriesToExclude, setCategoriesToExclude] = React.useState([]);

  const [anchorE0, setAnchorE0] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorE3, setAnchorE3] = React.useState(null);
  const [anchorE4, setAnchorE4] = React.useState(null);
  const [anchorE5, setAnchorE5] = React.useState(null);
  const [anchorE6, setAnchorE6] = React.useState(null);
  const [feedback, setFeedback] = React.useState([]);
  const [complianceInfo, setComplianceInfo] = React.useState(null);
  const [categoryQuestion, setCategoryQuestion] = React.useState([]);
  const [updateFeedback, setUpdateFeedback] = React.useState(0);
  const [urlIndex, setUrlIndex] = React.useState([]);
  const [scriptOpen, setScriptOpen] = useState();
  const [agentTaskList, setAgentTaskList] = useState([]);
  const [phraseData, setPhraseData] = useState();
  const [QRCData, setQRCData] = useState();
  const [dataLevel, setDataLevel] = useState();
  const [isQueryDetailsExpanded, setQueryDetailsExpanded] = useState(false);
  const [dispositionData, setDispositionData] = useState();
  const [categoryName, setCategoryName] = useState();
  const [parameterName, setParameterName] = useState('');
  const [QRCList, setQRCList] = useState();
  // console.log('phraseData', phraseData);
  const [isApproved, setIsApproved] = React.useState(true);
  const [alignment, setAlignment] = React.useState();
  const [openCreateDealModel, setOpenCreateDealModel] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [isPlanUpgraded, setIsPlanUpgraded] = useState(userDetails.upgrade_flag);
  const [agentCallDetails, setAgentCallDetails] = useState();
  const [updateAgentCallDetails, setUpdateAgentCallDetails] = useState(0);
  const handleToggle = (event, newAlignment) => {
    // console.log('userDetails', userDetails);
    // console.log('event.newAlignment', event.target.value);
    setAlignment(event.target.value);

    // // setApprovedValue(event.target.value);
    // console.log('event.target.value', event.target.value);
    if (event.target.value === 'approve' && newAlignment !== null) {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const payload = {
        request_id: dataForCockpit[2],
        status: 'approved'
      };

      // console.log('payload', payload);

      API.post('/secure/lead_status', payload, config)
        .then((res) => {
          // console.log('approve api', res);

          setAlert(true);
          setAlertContent(`${res.data.message}`);
          setAlertType('success');
        })
        .catch((err) => {
          setAlert(true);
          setAlertContent(`${err.response?.data.message}`);
          setAlertType('error');
        });
      // for (
      //   let i = 0;
      //   i < locationData.state.initialData.storedInfo.audioListInfo.all_audios.length;
      //   i++
      // ) {
      //   // console.log('uploadDataList.results[i].input_id', uploadDataList.results[i].input_id);
      //   // console.log('insideMessage', message.message.input_id);
      //   if (
      //     locationData.state.initialData.storedInfo.audioListInfo.all_audios[i].request_id ===
      //     dataForCockpit[2]
      //   ) {
      //     // console.log('message.input_id', message.message.input_id);
      //     locationData.state.initialData.storedInfo.audioListInfo.all_audios[i].lead_status =
      //       'approved';
      //     break;
      //   }
      // }
      setIsApproved(true);
    } else if (newAlignment !== null) {
      setIsApproved(false);

      // console.log('success');
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const payload = {
        request_id: dataForCockpit[2],
        status: 'rejected'
      };
      API.post('/secure/lead_status', payload, config)
        .then((res) => {
          setAlert(true);
          setAlertContent(`${res.data.message}`);
          setAlertType('success');
        })
        .catch((err) => {
          setAlert(true);
          setAlertContent(`${err.response?.data.message}`);
          setAlertType('error');
        });
      // for (
      //   let i = 0;
      //   i < locationData.state.initialData.storedInfo.audioListInfo.all_audios.length;
      //   i++
      // ) {
      //   // console.log('uploadDataList.results[i].input_id', uploadDataList.results[i].input_id);
      //   // console.log('insideMessage', message.message.input_id);
      //   if (
      //     locationData.state.initialData.storedInfo.audioListInfo.all_audios[i].request_id ===
      //     dataForCockpit[2]
      //   ) {
      //     // console.log('message.input_id', message.message.input_id);
      //     locationData.state.initialData.storedInfo.audioListInfo.all_audios[i].lead_status =
      //       'rejected';
      //     break;
      //   }
      // }
    }
  };

  const snippetRef = React.createRef();
  // console.log('toneFixResponse', fixTone);
  // console.log('concatData------', concatData);
  // console.log('SimilarSearchData-----------', similarSearchData);
  // console.log('KPIData-------', kpi);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDialog = () => {
    // console.log('\n\n\n handleDialog is called -----');
    // console.log('open inside handleDialog --', open);
    setOpen(!open);
  };
  const handleCustomerDialog = () => {
    // console.log('\n\n\n handleDialog is called -----');
    // console.log('open inside handleDialog --', open);
    setCustomerOpen(!customerOpen);
  };
  const handleToneDialog = () => {
    // console.log('\n\n\n handleDialog is called -----');
    // console.log('open inside handleDialog --', open);
    setToneOpen(!toneOpen);
  };
  const handleFeedbackDialog = () => {
    setFeedbackOpen(!feedbackOpen);
    if (feedbackOpen === true) {
      formik.resetForm();
    }
  };
  // dialog popup for Score forms
  const handleScoreFormDialog = () => {
    setScoreFormOpen(!scoreFormOpen);
    if (scoreFormOpen === true) {
      formik.resetForm();
    }
  };
  const handleSalesIntentDialog = () => {
    setSalesIntentOpen(!salesIntentOpen);
  };

  // Modal Popup for snippet
  const handleSnippetOpen = () => {
    setSnippetOpen(true);
  };
  const handleSnippetClose = () => {
    setSnippetOpen(false);
  };
  // Modal Popup for reason
  const handleReasonOpen = () => {
    setReasonOpen(true);
  };
  const handleReasonClose = () => {
    setReasonOpen(false);
  };

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '1px solid #000',
    boxShadow: 24,
    p: 4
  };
  const styleModalForReason = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 300,
    // overflow: 'auto',
    bgcolor: 'background.paper',
    // border: '1px solid #000',
    boxShadow: 24,
    paddingLeft: 2
    // paddingRight: 2
  };

  // ----------------------------------------------
  const handleSentimentDialog = () => {
    // console.log('\n\n\n handleDialog is called -----');
    // console.log('open inside handleDialog --', open);
    setSentimentOpen(!sentimentOpen);
  };
  const handleAgentSentimentDialog = () => {
    // console.log('\n\n\n handleDialog is called -----');
    // console.log('open inside handleDialog --', open);
    setAgentSentimentOpen(!agentSentimentOpen);
  };
  const handleDialogForQRC = () => {
    // console.log('\n\n\n handleDialog is called -----');
    // console.log('open inside handleDialog --', open);
    setQRCOpen(!QRCOpen);
  };

  const handleUpgradePlanModal = () => {
    setUpgradeModal(!upgradeModal);
  };
  const TONE_POPUP = [
    { id: 'file', numeric: false, disablePadding: false, label: 'Audio file' },
    {
      id: 'Duration',
      numeric: false,
      disablePadding: false,
      label: 'Duration',
      alignCenter: true
    },
    { id: 'Audio', numeric: false, disablePadding: false, label: 'Audio', alignCenter: true }
  ];
  const TRANSCRIPT_POPUP = [
    { id: 'word', numeric: false, disablePadding: false, label: 'Word' },
    {
      id: 'Duration',
      numeric: false,
      disablePadding: false,
      label: 'Duration',
      alignCenter: true
    },
    { id: 'Audio', numeric: false, disablePadding: false, label: 'Audio', alignCenter: true }
  ];

  //  ---------------------------------------------------------------------------

  // Integrating API-------------------------------

  // deadAir chart integration

  // const deadAirDummy = {
  //   time: [
  //     [0.514, 1.69],
  //     [41.306, 42.376]
  //   ],
  //   duration: [1.18, 1.07]
  // };

  // const Hello = deadAirData.map((nested) => {
  //   console.log('nested', nested);
  //   return nested.map((element) => {
  //     console.log('element', element);
  //     return element;
  //   });
  // });
  // console.log('HELLO', Hello);

  // const newData = [deadAirData.time[0][0], deadAirData.time[0][1]];

  const CHART_HEIGHT = 372;
  const LEGEND_HEIGHT = 72;
  // console.log('input iddddd', inputId);
  const ChartWrapperStyle = styled('div')(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(5),
    '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
    '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
      overflow: 'visible'
    },
    '& .apexcharts-legend': {
      height: LEGEND_HEIGHT,
      alignContent: 'center',
      position: 'relative !important',
      borderTop: `solid 1px ${theme.palette.divider}`,
      top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
    }
  }));

  // console.log('datadd', newData[0]);

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      theme.palette.error.main,
      theme.palette.primary.main,
      // theme.palette.info.main,
      theme.palette.warning.main
    ],
    labels: ['Start Time', 'End Time', 'Dead Air Duration'],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    Tip: {
      fillSeriesColor: true
      // custom: () =>
      //   `<ul>
      //   <li> Start Time: ${newData[0]} sec </li>
      //   <li> End Time: ${newData[1]} sec </li>
      //   <li> Duration: ${newData[2]} sec </li>
      //   </ul>`
    },
    plotOptions: {
      pie: { customScale: 1, donut: { labels: { show: true } } }
    }
  });

  let dataForCockpit;

  if (inputId) {
    sessionStorage.setItem(
      'cockpitData',
      JSON.stringify([
        inputId,
        inputOrgId,
        requestId,
        uploadedAudio,
        audioS3,
        fileProcess,
        customerId,
        originalDate,
        agentId,
        agentName,
        agentLastName
      ])
    );
    dataForCockpit = JSON.parse(sessionStorage.getItem('cockpitData'));
    // console.log('dataForCockpit', dataForCockpit);
  } else {
    dataForCockpit = JSON.parse(sessionStorage.getItem('cockpitData'));
    // console.log('dataForCockpit', dataForCockpit);
  }

  const handleRequest = (inputId, inputOrgId, requestId, processName, customerId) => {
    setLoading(true);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    // function getTranscript() {
    //   return API.get(`/secure/get_transcript?input_id=${inputId}&request_id=${requestId}`, config);
    // }
    // function getSummary() {
    //   return API.get(
    //     `/secure/get_summary?input_id=${inputId}&organisation_id=${inputOrgId}&request_id=${requestId}`,
    //     config
    //   );
    // }
    // function get_entityrelation_summary() {
    //   return API.get(
    //     `/secure/get_entityrelation_summary?input_id=${inputId}&organisation_id=${inputOrgId}&request_id=${requestId}`,
    //     config
    //   );
    // }
    // function get_entityrelation_transcript() {
    //   return API.get(
    //     `/secure/get_entityrelation_transcript?input_id=${inputId}&organisation_id=${inputOrgId}&request_id=${requestId}`,
    //     config
    //   );
    // }
    // function get_keyword_summary() {
    //   return API.get(
    //     `/secure/get_keyword_summary?input_id=${inputId}&organisation_id=${inputOrgId}&request_id=${requestId}`,
    //     config
    //   );
    // }
    // function get_keyword_transcript() {
    //   return API.get(
    //     `/secure/get_keyword_transcript?input_id=${inputId}&organisation_id=${inputOrgId}&request_id=${requestId}`,
    //     config
    //   );
    // }
    // function get_sentimentanalysis_summary() {
    //   return API.get(
    //     `/secure/get_sentimentanalysis_summary?input_id=${inputId}&organisation_id=${inputOrgId}&request_id=${requestId}`,
    //     config
    //   );
    // }
    // function get_sentimentanalysis_summary_overall() {
    //   return API.get(
    //     `/secure/get_sentimentanalysis_summary_overall?input_id=${inputId}&organisation_id=${inputOrgId}&request_id=${requestId}`,
    //     config
    //   );
    // }
    // function get_sentimentanalysis_transcript() {
    //   return API.get(
    //     `/secure/get_sentimentanalysis_transcript?input_id=${inputId}&organisation_id=${inputOrgId}&&request_id=${requestId}`,
    //     config
    //   );
    // }
    // function get_sentimentanalysis_transcript_overall() {
    //   return API.get(
    //     `/secure/get_sentimentanalysis_transcript_overall?input_id=${inputId}&organisation_id=${inputOrgId}&request_id=${requestId}`,
    //     config
    //   );
    // }
    // function get_summary_wordCloud() {
    //   return API.get(
    //     `/secure/get_wordcloud_summary?input_id=${inputId}&organisation_id=${inputOrgId}&request_id=${requestId}`,
    //     config
    //   );
    // }
    // function get_transcript_wordCloud() {
    //   return API.get(
    //     `/secure/get_wordcloud_transcript?input_id=${inputId}&organisation_id=${inputOrgId}&request_id=${requestId}`,
    //     config
    //   );
    // }
    // function getTranscript_media() {
    //   return API.get(
    //     `/secure/transcript_media?input_id=${inputId}&organisation_id=${inputOrgId}&request_id=${requestId}`,
    //     config
    //   );
    // }
    // function get_sentimentanalysis_speakers() {
    //   return API.get(
    //     `/secure/get_sentimentanalysis_speakers?input_id=${inputId}&organisation_id=${inputOrgId}&request_id=${requestId}`,
    //     config
    //   );
    // }
    function get_agent_UnpleasantWords() {
      return API.get(
        `/secure/get_unplesent_words?input_id=${inputId}&org_id=${inputOrgId}&request_id=${requestId}&customer_id=${customerId}`,
        config
      );
    }
    function get_tone() {
      return API.get(
        `/secure/get_tone?input_id=${inputId}&org_id=${inputOrgId}&request_id=${requestId}&customer_id=${customerId}`,
        config
      );
    }
    function get_dead_air() {
      return API.get(
        `/secure/get_dead_air?input_id=${inputId}&org_id=${inputOrgId}&request_id=${requestId}&customer_id=${customerId}`,
        config
      );
    }
    function get_sentiments() {
      return API.get(
        `/secure/get_sentiments?input_id=${inputId}&org_id=${inputOrgId}&request_id=${requestId}&customer_id=${customerId}`,
        config
      );
    }
    function get_energyValues() {
      return API.get(
        `/secure/get_energy_values?input_id=${inputId}&org_id=${inputOrgId}&request_id=${requestId}&customer_id=${customerId}`,
        config
      );
    }
    function get_tone_fix_range() {
      return API.get(
        `/secure/get_tone_fix_range?input_id=${inputId}&org_id=${inputOrgId}&request_id=${requestId}&customer_id=${customerId}`,
        config
      );
    }
    function get_similar_search_words() {
      return API.get(
        `/secure/similar_search_words?input_id=${inputId}&org_id=${inputOrgId}&request_id=${requestId}&customer_id=${customerId}`,
        config
      );
    }
    Promise.all([
      // getTranscript(),
      // getSummary(),
      // get_entityrelation_summary(),
      // get_entityrelation_transcript(),
      // get_keyword_summary(),
      // get_keyword_transcript(),
      // get_sentimentanalysis_summary(),
      // get_sentimentanalysis_summary_overall(),
      // get_sentimentanalysis_transcript(),
      // get_sentimentanalysis_transcript_overall(),
      // get_summary_wordCloud(),
      // get_transcript_wordCloud(),
      // getTranscript_media(),
      // get_sentimentanalysis_speakers()
      // get_agent_UnpleasantWords(),
      // get_tone(),
      get_dead_air(),
      // get_sentiments(),
      get_energyValues(),
      get_tone_fix_range(),
      get_similar_search_words()
    ])
      .then(
        ([
          // transcriptResponse,
          // summaryResponse,
          // summaryEntity,
          // transcriptEntity,
          // keywordSummary,
          // keywordTranscript,
          // summarySentiments,
          // summaryOverallSentiments,
          // transcriptSentiments,
          // transcriptSentimentsOverall,
          // summaryWordCloud,
          // transcriptWordCloud,
          // transcriptMedia
          // sentimentAnalysisSpeaker,
          // unpleasantResponse,
          // toneResponse,
          deadAirResponse,
          // sentimentResponse,
          energyValuesResponse,
          toneFixResponse,
          similarSearchResponse
        ]) => {
          // setTranscriptData(transcriptResponse.data);
          // setSummaryData(summaryResponse.data);
          // setentityrelationSummary(summaryEntity.data);
          // setentityrelationTranscript(transcriptEntity.data);
          // setkeywordSummary(keywordSummary.data);
          // setkeywordTranscript(keywordTranscript.data);
          // setsummarySentiments(summarySentiments.data);
          // setsummarySentimentsOverall(summaryOverallSentiments.data);
          // settranscriptSentiments(transcriptSentiments.data);
          // settranscriptSentimentsOverall(transcriptSentimentsOverall.data);
          // setwordCloudForSummary(summaryWordCloud.data);
          // setwordCloudForTranscript(transcriptWordCloud.data);
          // settranscript_media(transcriptMedia.data);
          // setGet_sentimentanalysis_speakers(sentimentAnalysisSpeaker.data);
          // setgetUnpleasantWord(unpleasantResponse.data);
          // setS3Url(unpleasantResponse.data.s3url);
          // setToneData(toneResponse.data);
          setdeadAirData(deadAirResponse.data);
          // setSentimentData(sentimentResponse.data);
          setEnergyValuesData(energyValuesResponse.data);
          setFixToneData(toneFixResponse.data);
          setSimilarSearchData(similarSearchResponse.data);
          setLoading(false);
        }
      )
      .catch((err) => {
        console.log(err.response.data.message);
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
        setLoading(false);
      });
  };
  const handleAlert = () => {
    setAlert(false);
  };
  // console.log('keywordtrackdata', keywordTrackData);
  // const handleS3url = (inputId) => {

  // };

  useEffect(() => {
    handleRequest(
      dataForCockpit[0],
      dataForCockpit[1],
      dataForCockpit[2],
      dataForCockpit[5],
      dataForCockpit[6]
    );
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    API.get(`/secure/s3_url_path?input_id=${dataForCockpit[0]}`, config)
      .then((res) => {
        setS3Url(res.data.message.S3url);
      })
      .catch((err) => console.log('err', err));
  }, []);

  // Calling get_tone API click
  // const callToneByClick = async (inputId, inputOrgId, requestId, customerId) => {
  //   const token = localStorage.getItem('token').toString();
  //   const config = {
  //     headers: { Authorization: `Bearer ${token}` }
  //   };
  //   console.log('datacalled', inputId);
  //   await API.get(
  //     `/secure/get_tone?input_id=${inputId}&org_id=${inputOrgId}&request_id=${requestId}&customer_id=${customerId}`,
  //     config
  //   )
  //     .then((res) => {
  //       setToneData(res.data);
  //     })
  //     .catch((err) => {
  //       console.log('err --', err);
  //     });
  // };

  // useEffect(() => {
  //   if (toneIndex.isAgent || toneIndex.isCustomer) {
  //     callToneByClick(inputId, inputOrgId, requestId, customerId);
  //   }
  // }, [toneIndex]);

  // -------------------- Storing Transcipt and Summary Data -------------------
  const entityArr = [];
  const entitySum = [];
  const entity = entityrelationTranscript?.entityrelation_transcript?.results.map((data) => {
    if (data.entity !== '') {
      entityArr.push(data.render);
      // console.warn('entityArr ---', entityArr);
    }
    return entityArr;
  });

  const entitySummary = entityrelationSummary?.entityrelation_summary?.results.map((data) => {
    if (data.entity !== '') {
      entitySum.push(data.render);
    }
    return entitySum;
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selectedValue, setSelectedValue] = React.useState('Yes');

  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };

  // ----------------------------------------------------------

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // console.log(
    //   'eventhandleTabChange',
    //   keywordTrackData?.agent_keywords[Object.keys(keywordTrackData?.agent_keywords)[newValue]]
    // );
  };
  const handleAgentTabChangeForPhrase = (event, newValue) => {
    setAgentPhraseTab(newValue);
  };
  const handleCustomerTabChangeForPhrase = (event, newValue) => {
    setCustomerPhraseTab(newValue);
  };

  /* ------------- Display subtiltle ---------------- */
  const track = transcriptData?.transcript?.result?.transcript;

  const extractTrack = (transcriptvtt) => {
    vttToJson(transcriptvtt)
      .then((data) => {
        // console.log(data);
        setJsonTranscript(data);
      })
      .catch((error) => {
        console.log('Error While Conversion', error);
      });
  };

  const displayCue = () => {
    console.log('Event Fired');
    const audioSpans = document.getElementsByClassName('audioTracksSpans');
    for (let i = 0; i < audioSpans.length; i += 1) {
      const time = Number(audioSpans[i].id) / 1000;
      console.log('Track Time', time);
      if (time < audioElement.current.currentTime) {
        if (i > 0) {
          audioSpans[i - 1].style.backgroundColor = 'white ';
        }
        audioSpans[i].style.backgroundColor = 'green';
      }
    }
  };

  // ------------------------------------------------------------------------

  const convertToneintoPercentage = (data) => {
    // console.log('data ----------->', data);
    const agentData = data.agent_data;
    const customerData = data.customer_data;

    // console.log('agentData ----', agentData);

    const agentHighTone = agentData.high === undefined ? 0 : Math.round(agentData.high);
    const agentMediumTone = agentData.medium === undefined ? 0 : Math.round(agentData.medium);
    const agentLowTone = agentData.low === undefined ? 0 : Math.round(agentData.low);
    const totalAgentTone = agentHighTone + agentMediumTone + agentLowTone;

    const customerHighTone = customerData.high === undefined ? 0 : Math.round(customerData.high);
    const customerMediumTone =
      customerData.medium === undefined ? 0 : Math.round(customerData.medium);
    const customerLowTone = customerData.low === undefined ? 0 : Math.round(customerData.low);
    const totalCustomerTone = customerHighTone + customerMediumTone + customerLowTone;

    if (totalCustomerTone === 0) {
      const customerToneInPercentage = [0, 0, 0];
      setcustomerTonePercentage(customerToneInPercentage);
    } else {
      const customerToneInPercentage = [
        Math.round((customerHighTone / totalCustomerTone) * 100),
        Math.round((customerMediumTone / totalCustomerTone) * 100),
        Math.round((customerLowTone / totalCustomerTone) * 100)
      ];

      setcustomerTonePercentage(customerToneInPercentage);
    }

    if (totalAgentTone === 0) {
      const agentToneInPercentage = [0, 0, 0];
      setagentTonePercentage(agentToneInPercentage);
    } else {
      const agentToneInPercentage = [
        Math.round((agentHighTone / totalAgentTone) * 100),
        Math.round((agentMediumTone / totalAgentTone) * 100),
        Math.round((agentLowTone / totalAgentTone) * 100)
      ];
      setagentTonePercentage(agentToneInPercentage);
    }
  };

  const convertSentimentintoPercentage = (data) => {
    // console.log('data ----------->', data);
    const agentData = data.agent_sentiments;
    const customerData = data.customer_sentiments;

    // console.log('agentData ----', agentData);

    const agentNeutral = agentData.NEUTRAL === undefined ? 0 : Math.round(agentData.NEUTRAL);
    const agentPositive = agentData.POSITIVE === undefined ? 0 : Math.round(agentData.POSITIVE);
    const agentNegative = agentData.NEGATIVE === undefined ? 0 : Math.round(agentData.NEGATIVE);
    const totalAgentSentiment = agentNeutral + agentPositive + agentNegative;

    const customerNeutral =
      customerData.NEUTRAL === undefined ? 0 : Math.round(customerData.NEUTRAL);
    const customerPositive =
      customerData.POSITIVE === undefined ? 0 : Math.round(customerData.POSITIVE);
    const customerNegative =
      customerData.NEGATIVE === undefined ? 0 : Math.round(customerData.NEGATIVE);
    const totalCustomerSentiment = customerNeutral + customerPositive + customerNegative;

    if (totalCustomerSentiment === 0) {
      const customerSentimentInPercentage = [0, 0, 0];
      setcustomerSentimentPercentage(customerSentimentInPercentage);
    } else {
      const customerSentimentInPercentage = [
        Math.round((customerNeutral / totalCustomerSentiment) * 100),
        Math.round((customerPositive / totalCustomerSentiment) * 100),
        Math.round((customerNegative / totalCustomerSentiment) * 100)
      ];

      setcustomerSentimentPercentage(customerSentimentInPercentage);
    }

    if (totalAgentSentiment === 0) {
      const agentSentimentInPercentage = [0, 0, 0];
      setagentSentimentPercentage(agentSentimentInPercentage);
    } else {
      const agentSentimentInPercentage = [
        Math.round((agentNeutral / totalAgentSentiment) * 100),
        Math.round((agentPositive / totalAgentSentiment) * 100),
        Math.round((agentNegative / totalAgentSentiment) * 100)
      ];
      setagentSentimentPercentage(agentSentimentInPercentage);
    }
  };

  useEffect(() => {
    if (toneData) {
      convertToneintoPercentage(toneData);
    }
    if (sentimentData) {
      convertSentimentintoPercentage(sentimentData);
    }
  }, [toneData, sentimentData]);

  // --------------------------------------------------------------------

  useEffect(() => {
    if (transcriptData?.transcript?.result?.transcript) {
      extractTrack(transcriptData?.transcript?.result?.transcript);
    }
  }, [transcriptData?.transcript?.result?.transcript]);

  // useEffect(() => {
  //   console.log('transcriptData ----', transcriptData);
  // }, [jsonTranscript]);

  // console.log('Audio file ---', dataForCockpit[3]);
  const getAudioFileName = (fileName) => {
    if (fileName === null) {
      return '';
    }
    const nameArr = decodeURI(fileName.split('/').pop().split('#')[0].split('?')[0]);
    return nameArr;
  };
  const showTip = (value) => {
    if (value.length > 25) {
      return (
        <Tip title={value} followCursor placement="top">
          <Typography sx={{ fontWeight: 600 }}>
            {`Audio File Name: ${value.slice(0, 25)}.....`}
          </Typography>
          {/* <span style={{ fontSize: 12 }}>{value.slice(0, 10)}.....</span> */}
        </Tip>
      );
    }
    return <Typography sx={{ fontWeight: 600 }}>{`Audio File Name: ${value}`}</Typography>;
  };

  // Creating state for checkbox
  const [isCorrectionRequired, setIsCorrectionRequired] = React.useState([]);

  // console.log('isCorrectionRequired ----', isCorrectionRequired);

  // Creating state for to store energy graph data for agent.
  const [agentEnergyData, setAgentEnergyData] = React.useState([]);
  // console.log('agentEnergyData', agentEnergyData);
  // Creating state for to store energy graph data for customer.
  const [customerEnergyData, setCustomerEnergyData] = React.useState([]);
  // console.log('Customer Tone Analysis', customerEnergyData);

  useEffect(() => {
    if (energyValuesData?.agent_energy_values && energyValuesData?.customer_energy_values) {
      console.warn('energyValuesData ---------', energyValuesData);
      const agentArr = [];
      energyValuesData.agent_energy_values.maxOfenergyval.map((ele, index) => {
        const agentObj = {
          name: Math.round(
            (energyValuesData.agent_energy_values.meanOfTimestamp[index] / 1000).toFixed(2)
          ),
          agent: ele.toFixed(2)
          // agent: toneData?.aws_services ? ele.toFixed(2) : (ele * 10).toFixed(2)
        };
        agentArr.push(agentObj);
        return ele;
      });
      // console.warn('graph data ----', arr);
      setAgentEnergyData(agentArr);
      const customerArr = [];
      energyValuesData.customer_energy_values.maxOfenergyval.map((ele, index) => {
        const customerObj = {
          name: Math.round(
            (energyValuesData.customer_energy_values.meanOfTimestamp[index] / 1000).toFixed(2)
          ),
          customer: ele.toFixed(2)
          // customer: toneData?.aws_services ? ele.toFixed(2) : (ele * 10).toFixed(2)
        };
        customerArr.push(customerObj);
        return ele;
      });
      // console.warn('graph data ----', arr);
      setCustomerEnergyData(customerArr);
    }
  }, [energyValuesData]);
  const AgentTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      // const data = payload[0].payload.total;
      // console.log('payload[0].payload.', payload[0].payload);
      return (
        <Card
          className="custom-tooltip"
          sx={{ padding: '10px', backgroundColor: 'rgba(235, 235, 235, 0.97)' }}
        >
          <p className="intro">{`${payload[0].payload.name} sec`}</p>
          <p className="intro">{`Agent: ${payload[0].payload.agent}`} </p>
        </Card>
      );
    }
    return null;
  };
  const CustomerTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      // const data = payload[0].payload.total;
      // console.log('payload[0].payload.', payload[0].payload);
      return (
        <Card
          className="custom-tooltip"
          sx={{ padding: '10px', backgroundColor: 'rgba(235, 235, 235, 0.97)' }}
        >
          <p className="intro">{`${payload[0].payload.name} sec`}</p>
          <p className="intro">{`Customer: ${payload[0].payload.customer}`} </p>
        </Card>
      );
    }
    return null;
  };

  // const similar_search_words = {
  //   agent_similar_words: [
  //     { word: 'किडनी', matching_words: [{ किडनी: '1' }, { कीड: '0.987' }, { किडे: '0.957' }] }
  //   ],
  //   customer_similar_words: [
  //     { word: 'किडनी', matching_words: [{ किडनी: '1' }, { कीड: '0.987' }, { किडे: '0.957' }] }
  //   ]
  // };
  // useEffect(() => {
  //   const similarSearchArr = [];
  //   if (similarSearchData) {
  //     similarSearchData.customer_similar_words.map((el, index) => similarSearchArr.push(el.word));
  //   }
  //   if (getUnpleasantWord && similarSearchData) {
  //     const concatArray = Object.keys(getUnpleasantWord.customer_data).concat(similarSearchArr);
  //     setConcatData(concatArray);
  //     console.log('concatArray', concatArray);
  //   }
  // }, [similarSearchData, getUnpleasantWord]);
  useEffect(() => {
    const keywordsArray = [];
    const collectionArray = [];
    if (keywordTrackData) {
      Object.keys(keywordTrackData).forEach((category) => {
        Object.keys(keywordTrackData[category]).forEach((keywords) => {
          keywordsArray.push(...Object.keys(keywordTrackData[category][keywords]));
        });
      });
    }
    if (collectionData && Object.keys(collectionData.agent_phrases).length > 0) {
      setCollectionConcatData(Object.values(collectionData.agent_phrases).flat());
      if (Object.keys(collectionData?.agent_phrases).length > 0) {
        Object.keys(collectionData.agent_phrases).map((el) => {
          if (Object.hasOwnProperty.call(collectionData?.agent_phrases, el)) {
            collectionArray.push(...collectionData.agent_phrases[el]);
          }
          return el;
        });
      }
    }
    console.log('collectionArray..', collectionArray);
    console.log('collectionArray..', keywordsArray);
    setConcatData(keywordsArray.concat(collectionArray));
  }, [keywordTrackData, collectionData]);
  // useEffect(() => {
  //   if (collectionData && Object.keys(collectionData.agent_phrases).length > 0) {
  //     setCollectionConcatData(Object.values(collectionData.agent_phrases).flat());
  //     // const collectionArray = [];
  //     // if (Object.keys(collectionData?.agent_phrases).length > 0) {
  //     //   Object.keys(collectionData.agent_phrases).map((el) => {
  //     //     if (Object.hasOwnProperty.call(collectionData?.agent_phrases, el)) {
  //     //       collectionArray.push(...collectionData.agent_phrases[el]);
  //     //     }
  //     //     // collectionArray.push(collectionData.agent_phrases[el].join(','));
  //     //     return el;
  //     //   });
  //     //   console.log('collectionArray..', collectionArray);

  //     //   setCollectionConcatData(collectionArray);
  //     //   // console.log('keywordsArray', keywordsArray);
  //     // }
  //   }
  // }, [collectionData]);

  // formik for feedback form for collections.

  const [update, setUpdate] = React.useState(0);

  const FeedbackSchema = Yup.object().shape({
    customer: Yup.string().required('This field is required.'),
    // process: Yup.array().min(1, 'This field is required.'),
    organisationId: Yup.string().required('This field is required.'),
    agent: Yup.string().required('This field is required.'),
    feedBack: Yup.string()
      .test('valid-feedback-chars', 'Invalid characters in feedback.', (value) => {
        if (!/^[a-zA-Z0-9.,!?'"() \t\n\r-]*$/.test(value)) {
          return false; // Indicates an error
        }
        return true; // No error
      })
      .test('minimum-length', 'Feedback is too short.', (value) => {
        if (value && value.length < 3) {
          return false; // Indicates an error
        }
        return true; // No error
      })
      .test('no-blank-space', 'Feedback cannot be just blank spaces.', (value) => {
        if (/^\s*$/.test(value)) {
          return false; // Indicates an error
        }
        return true; // No error
      })
      .test(
        'no-leading-trailing-space',
        'Feedback cannot start or end with a blank space.',
        (value) => {
          if (value && (value.startsWith(' ') || value.endsWith(' '))) {
            return false; // Indicates an error
          }
          return true; // No error
        }
      )
      .max(3000, 'Feedback cannot exceed 3000 characters.')
      .required('Feedback is required.')
    // .min(3, 'Feedback should be 3 to 3000 character long.')
    // .matches(/^[a-zA-Z0-9 .,!?'"() \t\n\r-]*$/, 'This field cannot contain only blankspaces.')
    // .max(3000, 'Feedback should be 3 to 3000 character long.')
    // .required('Feedback is required')
    // dealStage: Yup.string().required('This field is required.'),
    // dealStatus: Yup.string().required('This field is required.')
  });

  const createDealSchema = Yup.object().shape({
    customer: Yup.string().required('This field is required.'),
    // process: Yup.array().min(1, 'This field is required.'),
    organisationId: Yup.string().required('This field is required.'),
    agent: Yup.string().required('This field is required.'),
    dealName: Yup.string()
      .max(30, 'Deal name should be 2 to 30 character long.')
      .required('This field is required.'),
    dealStage: Yup.string().required('This field is required.'),
    dealStatus: Yup.string().required('This field is required.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: userDetails.role === 'QA' ? userDetails.customer_id : dataForCockpit[6],
      organisationId: `${
        userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : dataForCockpit[1]
      }`,

      agent: dataForCockpit[8],
      feedBack: '',
      rating: 3
    },
    validationSchema: FeedbackSchema,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
        // onUploadProgress: (data) => {
        //   setUploaded(Math.round((data.loaded / data.total) * 100));
        // }
      };
      const data = {
        customer_id: formik.values.customer,
        user_id: formik.values.agent,
        // processes: formik.values.process,
        organisation_id: formik.values.organisationId,
        feedback: formik.values.feedBack,
        rating: formik.values.rating,
        input_id: dataForCockpit[0]
      };
      // console.log('data ---------------------', data);
      await API.post('/secure/add_feedback', data, config)
        .then((res) => {
          formik.resetForm();
          // handleRunProcess(res.data.id);

          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          handleFeedbackDialog();
          API.get(`secure/new_cockpit_feedback?input_id=${dataForCockpit[0]}`, config).then(
            (res) => {
              setFeedback(res.data.feedback);
            }
          );
        })
        .catch((err) => {
          console.log('error----', err);
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
      // onUploadProgress: (data) => {
      //   setUploaded(Math.round((data.loaded / data.total) * 100));
      // }
    };
    API.get(`secure/new_cockpit_sales_data?input_id=${dataForCockpit[0]}`, config)
      .then((res) => {
        // console.log('ressss', res);
        setCockpitSalesData(res?.data?.all_audios);
        if (res.data.all_audios?.lead_status === 'rejected') {
          setAlignment('reject');
        }
        if (res.data.all_audios?.lead_status === 'approved') {
          setAlignment('approve');
        }
        // setAgentTaskList(res?.data?.all_audios?.agent_task);
      })
      .catch((err) => console.log('err', err));
  }, [isApproved]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
      // onUploadProgress: (data) => {
      //   setUploaded(Math.round((data.loaded / data.total) * 100));
      // }
    };
    API.get(
      `/secure/get_tone?input_id=${dataForCockpit[0]}&org_id=${dataForCockpit[1]}&request_id=${dataForCockpit[2]}&customer_id=${dataForCockpit[6]}`,
      config
    )
      .then((res) => {
        // console.log('ressss', res);
        setToneData(res.data);
        // setAgentTaskList(res?.data?.all_audios?.agent_task);
      })
      .catch((err) => console.log('err', err));
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
      // onUploadProgress: (data) => {
      //   setUploaded(Math.round((data.loaded / data.total) * 100));
      // }
    };
    API.get(
      `/secure/get_sentiments?input_id=${dataForCockpit[0]}&org_id=${dataForCockpit[1]}&request_id=${dataForCockpit[2]}&customer_id=${dataForCockpit[6]}`,
      config
    )
      .then((res) => {
        // console.log('ressss', res);
        setSentimentData(res.data);
        // setAgentTaskList(res?.data?.all_audios?.agent_task);
      })
      .catch((err) => console.log('err', err));
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    API.get(`/secure/get_transcript?input_id=${dataForCockpit[0]}`, config)
      .then((res) => {
        // console.log('ressss', res);
        setUniqueTranscriptData(res.data);
        setDashboardServices(res.data.dashboard_services);
        setValue(
          res.data.dashboard_services.includes('collections')
            ? 5
            : res.data.dashboard_services.includes('sales')
            ? 8
            : res.data.dashboard_services.includes('compliance')
            ? 7
            : 5
        );
      })
      .catch((err) => console.log('err', err));
  }, []);
  const [updateScoreValue, setUpdateScoreValue] = useState(0);
  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    API.get(`/secure/agent_script_scorecard?input_id=${dataForCockpit[0]}`, config)
      .then((res) => {
        setScoreCardData(res.data);
        setTraining_call_toggle(res.data.toggle);
        const objectFromArray = res.data.checkedCategoery.reduce((acc, currentValue) => {
          acc[currentValue] = true;
          return acc;
        }, {});
        setCheckedParameters(objectFromArray);
        const AIParameters = [];
        res.data.ai_scoring_details
          .filter((el) => el.aiMode === true)
          .map((el) => AIParameters.push(el.Category));

        setCategoriesToExclude(AIParameters);
        const modifiedArray = res.data.review_scoring_details.map((item) => ({
          Category: String(item.Category),
          score_value: String(item.score_value),

          score:
            res.data.flag !== 'Edited'
              ? AIParameters.includes(item.Category)
                ? item.score
                : 'NA'
              : item.score,
          remark: String(item.remark),
          weightage: item.weightage,
          phrases: item.phrases ? item.phrases : ''
        }));

        setScoreValues(modifiedArray);
        let average = 0;
        let maxWeightage = 0;
        let averageCompare = 0;
        let maxWeightageCompare = 0;
        let averageAI = 0;
        let maxWeightageAI = 0;

        const filterNAParameters = modifiedArray.filter((item) => item.score !== 'NA');
        const filterNAParametersForCompare = res.data.review_scoring_details.filter(
          (item) => item.aiMode === true
        );
        // console.log('filterNAParameters', filterNAParameters);
        // console.log('filterNAParametersForCompare', filterNAParametersForCompare);
        const filterForAIParameters = res.data.ai_scoring_details.filter(
          (item) => AIParameters.includes(item.Category) && item.score !== 'NA'
        );

        filterNAParameters.map((el) => (average += Number(el.score)));
        filterNAParameters.map((el) => (maxWeightage += Number(el.weightage)));
        filterNAParametersForCompare.map((el) => (averageCompare += Number(el.score)));
        filterNAParametersForCompare.map((el) => (maxWeightageCompare += Number(el.weightage)));
        filterForAIParameters.map((el) => (averageAI += Number(el.score === 'NA' ? 0 : el.score)));
        filterForAIParameters.map((el) => (maxWeightageAI += Number(el.weightage)));
        setAIAverageScore(res.data.ai_score_per ? res.data.ai_score_per : 0);
        setAverageScore(res.data.manual_score_per ? res.data.manual_score_per : 0);
        setNFScore(res.data.manual_nf_score ? res.data.manual_nf_score : 0);
        setCompareAverageScore(
          ((averageCompare.toFixed(0) / maxWeightageCompare) * 100).toFixed(0)
        );
        // if (
        //   ((average.toFixed(0) / maxWeightage) * 100).toFixed(0) === 'NaN' ||
        //   average === undefined
        // ) {
        //   setAverageScore(res.data.manual_score_per ? res.data.manual_score_per.toFixed(0) : 0);
        //   setNFScore(res.data.manual_nf_score ? res.data.manual_nf_score.toFixed(0) : 0);
        // } else {
        //   setAverageScore(res.data.manual_score_per ? res.data.manual_score_per.toFixed(0) : 0);
        //   setNFScore(res.data.manual_nf_score ? res.data.manual_nf_score.toFixed(0) : 0);
        // }
      })
      .catch((err) => console.log('err', err));
  }, [updateScoreValue]);

  useEffect(() => {
    if (process.env.REACT_APP_TO_SHOW_PHRASES === 'true') {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      API.get(`/secure/keywords_phrases_tracking?input_id=${dataForCockpit[0]}`, config)
        .then((res) => {
          setCollectionData(res.data);
        })
        .catch((err) => console.log('err', err));
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_TO_SHOW_KEYWORDS === 'true') {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      API.get(`/secure/kewyords_tracking?input_id=${dataForCockpit[0]}`, config)
        .then((res) => {
          setKeywordTrackData(res.data);
        })
        .catch((err) => console.log('err', err));
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    API.get(`secure/new_cockpit_tasks?input_id=${dataForCockpit[0]}`, config)
      .then((res) => {
        // console.log('ressss', res);

        setAgentTaskList(res?.data?.agent_tasks);
      })
      .catch((err) => console.log('err', err));
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    API.get(`secure/new_cockpit_feedback?input_id=${dataForCockpit[0]}`, config)
      .then((res) => {
        // console.log('ressss', res);

        setFeedback(res.data.feedback);
      })
      .catch((err) => console.log('err', err));
  }, [updateFeedback]);

  // console.log('feedback', feedback);
  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    API.get(`secure/new_cockpit_script_data?input_id=${dataForCockpit[0]}`, config)
      .then((res) => {
        // console.log('new_cockpit_script_data', res);
        setComplianceInfo(res.data);
      })
      .catch((err) => console.log(err));
  }, [updateScoreValue]);

  // console.log('complianceInfo', complianceInfo);

  // API Call for phrases
  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    API.get(`secure/get_phrases_result?input_id=${dataForCockpit[0]}`, config)
      .then((res) => {
        // console.log('ressss', res);
        setPhraseData(res.data);
      })
      .catch((err) => console.log('err', err));
  }, []);
  // API Call for QRC Data
  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
      // onUploadProgress: (data) => {
      //   setUploaded(Math.round((data.loaded / data.total) * 100));
      // }
    };
    API.get(
      `/secure/get_disposition?input_id=${dataForCockpit[0]}&org_id=${dataForCockpit[1]}&request_id=${dataForCockpit[2]}&customer_id=${dataForCockpit[6]}`,
      config
    )
      .then((res) => {
        // console.log('ressss', res);
        setQRCData(res.data.disposition);
        setDispositionData(res.data.disposition);
        setCategoryName(Object.keys(res.data.disposition)[0]);
        setDataLevel(res.data.level);
        // setAgentTaskList(res?.data?.all_audios?.agent_task);
      })
      .catch((err) => console.log('err', err));
  }, []);

  const handleScriptDialog = () => {
    setScriptOpen(!scriptOpen);
  };

  const handleCreateDeal = () => {
    setOpenCreateDealModel(!openCreateDealModel);
    createDealformik.resetForm();
  };

  const createDealformik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: userDetails.role === 'QA' ? userDetails.customer_id : customerId,
      // process: [],
      organisationId: `${
        userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : inputOrgId
      }`,
      // domainId: '',
      agent: agentId,
      dealName: '',
      dealStage: '',
      dealStatus: '',
      dealReason: ''
    },
    validationSchema: createDealSchema,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        customer_id: createDealformik.values.customer,
        agent_id: createDealformik.values.agent,
        // processes: createDealformik.values.process,
        org_id: createDealformik.values.organisationId,
        deal_name: createDealformik.values.dealName,
        deal_stage: createDealformik.values.dealStage,
        deal_status: createDealformik.values.dealStatus,
        reason: createDealformik.values.dealReason,
        input_id: inputId
      };
      // console.log('data ---------------------', data);
      await API.post('/secure/register_deal', data, config)
        .then((res) => {
          createDealformik.resetForm();
          // handleRunProcess(res.data.id);
          navigate('/dashboard/admin/deal', { replace: true });
          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          handleCreateDeal();
          // setUpdate(update + 1);
        })
        .catch((err) => {
          console.log('error----', err);
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });

  const handleUpgradePlan = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = {
      upgrade: true,
      user_id: userDetails.user_id
    };

    API.post('/secure/upgrade_request', data, config)
      .then((res) => {
        setIsPlanUpgraded(true);
        setAlertContent(`Success: ${res.data.message}`);
        setAlertType('success');
        setAlert(true);
      })
      .catch((err) => {
        console.log('error----', err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  const AudioPlayer = ({ audioSrc, startTime, endTime }) => {
    const audioRef = React.useRef(null);

    const playSnippet = () => {
      const audio = audioRef.current;

      if (audio) {
        audio.currentTime = startTime;
        audio.play();

        setTimeout(() => {
          audio.pause();
        }, (endTime - startTime) * 1000);
      }
    };

    return (
      <div>
        <audio ref={audioRef} src={audioSrc} onPlay={playSnippet} controls>
          {/* <source src={audioSrc} type="audio/mp3" /> */}
          <track default kind="captions" srcLang="en" />
        </audio>
      </div>
    );
  };

  // console.log('toneData', toneData);

  const [scoreValues, setScoreValues] = useState([]);
  console.log('scoreValues', scoreValues);

  const [averageScore, setAverageScore] = useState();
  const [NFScore, setNFScore] = useState();
  const [aiAverageScore, setAIAverageScore] = useState(0);
  const [compareAverageScore, setCompareAverageScore] = useState(0);
  // console.log('averagescore', averageScore);

  const handleBooleanTypeChange = (index, value) => {
    setScoreValues((prevScoreValues) => {
      const updatedScoreValues = [...prevScoreValues];
      updatedScoreValues[index] = { ...updatedScoreValues[index], score_value: value };
      return updatedScoreValues;
    });
  };

  const handleMultivalueTypeChange = (index, value) => {
    setScoreValues((prevScoreValues) => {
      const updatedScoreValues = [...prevScoreValues];
      updatedScoreValues[index] = { ...updatedScoreValues[index], score_value: value };
      return updatedScoreValues;
    });
  };

  const handleScoreChange = (index, value) => {
    setScoreValues((prevScoreValues) => {
      const updatedScoreValues = [...prevScoreValues];
      updatedScoreValues[index] = { ...updatedScoreValues[index], score: value };
      return updatedScoreValues;
    });
  };
  const handleRemarkChange = (index, value) => {
    setScoreValues((prevScoreValues) => {
      const updatedScoreValues = [...prevScoreValues];
      updatedScoreValues[index] = { ...updatedScoreValues[index], remark: value };
      return updatedScoreValues;
    });
  };

  const handlePhraseChange = (index, value) => {
    setScoreValues((prevScoreValues) => {
      const updatedScoreValues = [...prevScoreValues];
      updatedScoreValues[index] = { ...updatedScoreValues[index], phrases: value };
      return updatedScoreValues;
    });
  };

  const [checkedParameters, setCheckedParameters] = useState({});

  const handleCheckboxChange = (parameter) => {
    setCheckedParameters((prevCheckedParameters) => ({
      ...prevCheckedParameters,
      [parameter]: !prevCheckedParameters[parameter]
    }));
  };

  const handleScoreCardSubmit = () => {
    const responseToBackend = scoreCardData.ai_scoring_details.map((el, index) => ({
      ...el,
      score_value:
        scoreValues[index]?.score_value !== 'undefined' ? scoreValues[index]?.score_value : '',
      score: scoreValues[index]?.score ? scoreValues[index].score : 0,
      remark: scoreValues[index]?.remark !== 'undefined' ? scoreValues[index]?.remark : '',
      phrases: scoreValues[index]?.phrases !== 'undefined' ? scoreValues[index]?.phrases : '',
      checkedValue:
        scoreValues[index]?.selected !== 'undefined' ? scoreValues[index]?.selected : 'isChecked'
    }));
    let average = 0;
    let maxWeightage = 0;
    let averageCompare = 0;
    let maxWeightageCompare = 0;

    const filterNAParameters = responseToBackend
      .filter((item) => item.score !== 'NA')
      .filter((item) => item.score !== 'NF')
      .filter((item) => item.score !== 'F');

    const filterNAParametersForCompare = filterNAParameters.filter((item) => item.aiMode === true);

    filterNAParameters.map((el) => (average += Number(el.score)));
    filterNAParameters.map((el) => (maxWeightage += Number(el.weightage)));
    filterNAParametersForCompare.map((el) => (averageCompare += Number(el.score)));
    filterNAParametersForCompare.map((el) => (maxWeightageCompare += Number(el.weightage)));

    setScoreValues(responseToBackend);
    setCompareAverageScore(((averageCompare.toFixed(0) / maxWeightageCompare) * 100).toFixed(0));

    let manualAverageScore = '0';
    // console.log(
    //   'hellllo',
    //   responseToBackend.filter((el) => el.score === 'F')
    // );
    if (responseToBackend.filter((el) => el.score === 'F').length > 0) {
      manualAverageScore = '0';
    } else if (((average.toFixed(0) / maxWeightage) * 100).toFixed(0) !== 'NaN') {
      manualAverageScore = `${((average.toFixed(0) / maxWeightage) * 100).toFixed(0)}`;
    } else {
      manualAverageScore = '0';
    }

    setAverageScore(manualAverageScore);

    let manualNFScore = '0';
    if (((average.toFixed(0) / maxWeightage) * 100).toFixed(0) !== 'NaN') {
      manualNFScore = `${((average.toFixed(0) / maxWeightage) * 100).toFixed(0)}`;
    } else {
      manualNFScore = '0';
    }
    setNFScore(manualNFScore);

    // setAverageScore(`${((average.toFixed(0) / (filterNAcalls.length * 5)) * 100).toFixed(0)} `);
    // console.log('responseToBackend', responseToBackend);
    const token = localStorage.getItem('token');

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const payload = {
      input_id: dataForCockpit[0],
      average_score: manualAverageScore,
      manual_nf_score: manualNFScore,
      scoring_details: responseToBackend,
      toggle: training_call_toggle,
      checkedCategoery: training_call_toggle
        ? Object.keys(checkedParameters).filter((el) => checkedParameters[el] === true)
        : []
    };

    // console.log('pyloaddd', payload);

    function validateArray(array) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].score === '' || array[i].score === 'undefined' || array[i].score === null) {
          return false; // Validation failed
        }
      }
      return true; // Validation successful
    }

    const isValid = validateArray(responseToBackend);
    if (isValid) {
      API.put('/secure/agent_score', payload, config)
        .then((res) => {
          // console.log('approve api', res);
          setUpdateScoreValue(updateScoreValue + 1);
          setAlert(true);
          setAlertContent(`${res.data.message}`);
          setAlertType('success');
        })
        .catch((err) => {
          setAlert(true);
          setAlertContent(`${err.response?.data.message}`);
          setAlertType('error');
        });
    } else {
      setAlert(true);
      setAlertContent('Score all the Parameters.');
      setAlertType('error');
    }
  };

  /* ----------------------------------------------------------------- */
  // Generate MenuItems As per Weightage
  const generateMenuItems = (count) => {
    const menuItems = [];

    for (let i = 0; i <= count; i++) {
      menuItems.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }

    return menuItems;
  };
  // -------------------------------------------------
  // get agent call details
  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    API.get(`/secure/get_call_details?input_id=${dataForCockpit[0]}`, config)
      .then((res) => {
        setAgentCallDetails(res.data.agent_call_details);
      })
      .catch((err) => console.log('err', err));
  }, [updateAgentCallDetails]);
  // -------------------------------------------------

  // handle Download CSV API
  const [downloadStart, setDownloadStart] = useState(false);
  const downloadRef = useRef();
  const [downloadOpen, setDownloadMenuOpen] = useState(false);

  const handleDownloadCsv = async (value) => {
    setDownloadStart(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/download_agent_call_details?input_id=${dataForCockpit[0]}&csv_for=${value}`,
      config
    )
      .then((res) => {
        saveAs(
          `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.final_csv}`,
          'call_details.csv'
        );
        setDownloadStart(false);
        setAlertContent(`Success: ${res.data.message}`);
        setAlertType('success');
        setAlert(true);
      })
      .catch((err) => {
        // console.log(err);
        setDownloadStart(false);
        console.log('error----', err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };
  //-------------------------------
  // phrase data dummy response
  const agentPhrasesDummyData = {
    category1: [
      {
        time: [41622, 41622],
        url: [
          '/app/media/sftp_transfer/voices/9/17/None/41/customai/126/Data/Output/Banking/126/M6JIJVJG9T0S35HNEGQS8D5110087M6H_2023-07-07_10-19-33_m_xXXVcX3/agent/10.wav'
        ],
        Content: ['आपका नामने जी']
      },
      {
        time: [41622, 41622],
        url: [
          '/app/media/sftp_transfer/voices/9/17/None/41/customai/126/Data/Output/Banking/126/M6JIJVJG9T0S35HNEGQS8D5110087M6H_2023-07-07_10-19-33_m_xXXVcX3/agent/10.wav'
        ],
        Content: ['आपका नामने जी']
      }
    ],
    category2: [
      {
        time: [41622, 41622],
        url: [
          '/app/media/sftp_transfer/voices/9/17/None/41/customai/126/Data/Output/Banking/126/M6JIJVJG9T0S35HNEGQS8D5110087M6H_2023-07-07_10-19-33_m_xXXVcX3/agent/10.wav'
        ],
        Content: ['आपका नामने जी']
      }
    ]
  };
  const pieChartData =
    dataLevel === 'level_3' &&
    Object.keys(QRCData).length > 0 &&
    (QRCData.Query.count !== 0 || QRCData.Request.count !== 0 || QRCData.Complaint.count !== 0)
      ? [
          { name: 'Query', value: QRCData.Query.count },
          { name: 'Request', value: QRCData.Request.count },
          { name: 'Complaint', value: QRCData.Complaint.count }
        ]
      : [];

  const COLORS = ['#0088FE', '#00C49F', '#FF3F3F'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    value,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        onClick={() => setDispositionData(QRCData[pieChartData[index].name])}
        style={{ cursor: 'pointer' }}
      >
        {(percent * 100).toFixed(0) > 0 && (
          <>
            {`${value.toFixed(0)}`}
            &nbsp;
            {pieChartData[index].name}
          </>
        )}
      </text>
    );
  };

  // ----------------------------
  // handle Collapse for different categories
  const [expandedCategories, setExpandedCategories] = useState({});

  const handleToggleExpand = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  const [training_call_toggle, setTraining_call_toggle] = useState(false);

  const handleToggleChange = () => {
    setTraining_call_toggle((prevValue) => !prevValue);
  };

  // -----------------------------
  return (
    <Page
      title="Analysis"
      style={{
        height: '100%',
        width: '100%',
        display: 'flex'
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paperbox elevation={0} style={{ marginBottom: 10, display: 'flex' }}>
              {/* {locationData.state.format && (
                <LoadingButton
                  startIcon={<Icon icon={arrowLeft} />}
                  variant="text"
                  // color="primary"
                  style={{
                    color: '#3892CF',
                    '&:hover': {
                      color: '#3892CF'
                    }
                  }}
                  size="large"
                  onClick={() =>
                    navigate('/dashboard/workspace/upload', {
                      state: { storedInfo: locationData.state }
                    })
                  }
                >
                  Back
                </LoadingButton>
              )} */}
              {/* {locationData.state && locationData.state.path === '/feedback/rating' && (
                <LoadingButton
                  startIcon={<Icon icon={arrowLeft} />}
                  variant="text"
                  // color="primary"
                  style={{
                    color: '#3892CF',
                    '&:hover': {
                      color: '#3892CF'
                    }
                  }}
                  size="large"
                  onClick={() => navigate('/dashboard/feedback/rating', { replace: true })}
                >
                  Back
                </LoadingButton>
              )} */}
              {/* {locationData.state &&
                (locationData.state.salesformik || locationData.state.storedInfo) && (
                  <LoadingButton
                    startIcon={<Icon icon={arrowLeft} />}
                    variant="text"
                    // color="primary"
                    style={{
                      color: '#3892CF',
                      '&:hover': {
                        color: '#3892CF'
                      }
                    }}
                    size="large"
                    onClick={() =>
                      navigate('/dashboard/app/sales', {
                        state: { initialData: locationData.state }
                      })
                    }
                  >
                    Back
                  </LoadingButton>
                )} */}
              {locationData.state && locationData.state.path && (
                <LoadingButton
                  startIcon={<Icon icon={arrowLeft} />}
                  variant="text"
                  // color="primary"
                  style={{
                    color: '#3892CF',
                    '&:hover': {
                      color: '#3892CF'
                    }
                  }}
                  size="large"
                  onClick={() => {
                    {
                      navigate(locationData.state.path, {
                        state:
                          locationData.state.path === '/dashboard/workspace/Upload'
                            ? { storedInfo: locationData.state }
                            : { initialData: locationData.state }
                      });
                    }
                  }}
                >
                  Back
                </LoadingButton>
              )}
              {/* {locationData.state && locationData.state.navigateCards && (
                <LoadingButton
                  startIcon={<Icon icon={arrowLeft} />}
                  variant="text"
                  // color="primary"
                  style={{
                    color: '#3892CF',
                    '&:hover': {
                      color: '#3892CF'
                    }
                  }}
                  size="large"
                  onClick={() =>
                    navigate('/dashboard/app/call', { state: { initialData: locationData.state } })
                  }
                >
                  Back
                </LoadingButton>
                // <Link
                //   onClick={() =>
                //     navigate('/dashboard/call', { state: { initialData: locationData.state } })
                //   }
                //   underline="hover"
                //   sx={{ cursor: 'pointer', color: '#3892CF' }}
                // >
                //   <Typography
                //     sx={{
                //       fontWeight: 600,
                //       marginLeft: '15px',
                //       marginTop: '12px',
                //       color: '#3892CF',
                //       '&:hover': {
                //         color: '#3892CF'
                //       }
                //     }}
                //   >
                //     Go to Dashboard
                //   </Typography>
                // </Link>
              )}
              {locationData.state && locationData.state.path === '/feedback/training_calls' && (
                <LoadingButton
                  startIcon={<Icon icon={arrowLeft} />}
                  variant="text"
                  // color="primary"
                  style={{
                    color: '#3892CF',
                    '&:hover': {
                      color: '#3892CF'
                    }
                  }}
                  size="large"
                  onClick={() => navigate('/dashboard/feedback/training_calls', { replace: true })}
                >
                  Back
                </LoadingButton>
              )} */}
            </Paperbox>
          </Grid>

          <Grid item xs={6}>
            <Paperbox
              elevation={0}
              style={{ marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}
            >
              {userDetails.role !== 'Agent' &&
                (dashboardServices.includes('collections') ||
                  dashboardServices.includes('compliance')) && (
                  <LoadingButton
                    variant="contained"
                    startIcon={<PlusIcon />}
                    style={{
                      backgroundColor: userDetails.role === 'Guest' ? '#E0E0E0' : '#3892CF',
                      '&:hover': {
                        backgroundColor: userDetails.role === 'Guest' ? '#E0E0E0' : '#3892CF'
                      },
                      marginRight: '25px'
                    }}
                    size="small"
                    onClick={handleFeedbackDialog}
                    disabled={userDetails.role === 'Guest'}
                    // onClick={() => {
                    //   navigate('/dashboard/workspace/upload/Results/feedback');
                    //   setAgentName(agentName);
                    //   setAgentLastName(agentLastName);
                    //   setcustomerId(customerId);
                    //   setinputOrgId(inputOrgId);
                    // }}
                  >
                    Add Feedback
                  </LoadingButton>
                )}

              {(userDetails?.role !== 'Agent' || userDetails?.role !== 'Guest') &&
                userDetails?.Submenus.includes('Script') && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={training_call_toggle}
                        onChange={handleToggleChange}
                        sx={{
                          fontSize: '16px',
                          width: 38,
                          height: 22,
                          padding: 0,
                          '& .MuiSwitch-switchBase': {
                            padding: 0,
                            // margin: 2,
                            transitionDuration: '300ms',
                            '&.Mui-checked': {
                              transform: 'translateX(16px)',
                              color: '#fff',
                              '& + .MuiSwitch-track': {
                                backgroundColor:
                                  theme.palette.mode === 'dark' ? '#2ECA45' : '#3892CF',
                                opacity: 1,
                                border: 0
                              },
                              '&.Mui-disabled + .MuiSwitch-track': {
                                opacity: 0.5
                              }
                            },
                            '&.Mui-focusVisible .MuiSwitch-thumb': {
                              color: '#33cf4d',
                              border: '6px solid #fff'
                            },
                            '&.Mui-disabled .MuiSwitch-thumb': {
                              color:
                                theme.palette.mode === 'light'
                                  ? theme.palette.grey[100]
                                  : theme.palette.grey[600]
                            },
                            '&.Mui-disabled + .MuiSwitch-track': {
                              opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
                            }
                          },
                          '& .MuiSwitch-thumb': {
                            boxSizing: 'border-box',
                            width: 22,
                            height: 22
                          },
                          '& .MuiSwitch-track': {
                            borderRadius: 26 / 2,
                            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
                            opacity: 1,
                            transition: theme.transitions.create(['background-color'], {
                              duration: 500
                            })
                          }
                        }}
                      />
                    }
                    label="Training Call"
                  />
                )}
            </Paperbox>
          </Grid>
        </Grid>
        <Card>
          <CardHeader
            title="Results"
            // subheader="Summary & Transcript"
            // action={
            //   <Grid container spacing={2}>
            //     <Grid item>
            //       <Typography
            //         onClick={() => setDefaultState('TRANSCRIPT')}
            //         color={defaultState === 'TRANSCRIPT' ? 'primary' : `${color}`}
            //         sx={{ cursor: 'pointer' }}
            //       >
            //         Transcript
            //       </Typography>
            //     </Grid>
            //     <Grid item>
            //       <Typography
            //         onClick={() => setDefaultState('SUMMARY')}
            //         color={defaultState === 'SUMMARY' ? 'primary' : `${color}`}
            //         sx={{ cursor: 'pointer' }}
            //       >
            //         Summary
            //       </Typography>
            //     </Grid>
            //   </Grid>
            // }
          />
          <Box dir="ltr">
            {process.env.REACT_APP_SHOW_HINDI_TRANSCRIPT === 'false' ? (
              <Box>
                {true ? (
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Paperbox
                        elevation={16}
                        style={{
                          cursor: 'pointer',
                          padding: '1rem',
                          height: 360,
                          overflow: 'auto',
                          backgroundColor: '#fafafa'
                        }}
                        // onClick={() => handleDialog()}
                      >
                        {defaultState === 'SUMMARY' ? (
                          <>
                            <Typography variant="h6" component="h2">
                              Summary
                            </Typography>
                            {/* <Typography variant="body2" component="p">
                              {summaryData?.summary?.results?.summary_text}
                            </Typography> */}
                          </>
                        ) : (
                          <>
                            <Typography variant="h6" component="h2">
                              Transcript
                            </Typography>
                            {/* {jsonTranscript?.map((transcript) => (
                              <Typography id="track" variant="body2" component="p">
                                <span
                                  className="audioTracksSpans"
                                  id={transcript.start}
                                  style={{ fontWeight: 600 }}
                                >
                                  {transcript.part}
                                </span>
                              </Typography>
                            ))} */}
                            {/* {toneData.transcript.split('\n').map((data) => ( */}
                            {/* <Typography variant="body2" component="p"> */}
                            {/* </Typography> */}
                            {/* ))} */}
                            {/* {toneData ? (
                              toneData.transcript.split('\n').map((data) => (
                                <div>
                                  <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    // highlightStyle={{ backgroundColor: '#ff6666' }}
                                    // highlightStyle={{
                                    //   backgroundColor: `${concatData.map((item) => {
                                    //     const isUnpleasant = Object.keys(
                                    //       getUnpleasantWord.customer_data
                                    //     ).find((ele) => ele === item);
                                    //     console.log('isUnpleasant ----', isUnpleasant);
                                    //     // if (isUnpleasant) {
                                    //     //   return '#ff6666';
                                    //     // }
                                    //     // return 'blue';
                                    //     return isUnpleasant;
                                    //   })}`
                                    //     ? '#ff6666'
                                    //     : 'blue'
                                    // }}
                                    searchWords={concatData}
                                    autoEscape
                                    textToHighlight={data}
                                  />
                                </div>
                              ))
                            ) : */}
                            {uniqueTranscriptData ? (
                              uniqueTranscriptData.transcript.split('\n').map((data) => (
                                <div
                                  style={{
                                    fontFamily: 'sans-serif',
                                    fontSize: '15px',
                                    fontWeight: 1,
                                    color: '#333'
                                  }}
                                >
                                  {/* {concatData.length > 0 && ( */}
                                  <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    // highlightStyle={{
                                    //   backgroundColor: `${concatData.map((item) => {
                                    //     const isUnpleasant = Object.keys(
                                    //       getUnpleasantWord.customer_data
                                    //     ).find((ele) => ele === item);
                                    //     console.log('isUnpleasant ----', isUnpleasant);
                                    //     // if (isUnpleasant) {
                                    //     //   return '#ff6666';
                                    //     // }
                                    //     // return 'blue';
                                    //     return isUnpleasant;
                                    //   })}`
                                    //     ? '#ff6666'
                                    //     : 'blue'
                                    searchWords={concatData}
                                    autoEscape
                                    textToHighlight={data}
                                  />
                                  {/* )} */}
                                  {locationData?.state?.highlightWords && (
                                    <Highlighter
                                      highlightClassName="YourHighlightClass"
                                      highlightStyle={{ backgroundColor: '#37f713' }}
                                      searchWords={[...locationData?.state?.highlightWords]}
                                      autoEscape
                                      textToHighlight={data}
                                    />
                                  )}
                                </div>
                              ))
                            ) : (
                              <div
                                style={{
                                  width: '80%',
                                  height: '80%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <span style={{ fontSize: 20 }}>
                                  <b>No Data Available</b>
                                </span>
                              </div>
                            )}
                            {/* ))} */}
                            {/* <Typography variant="body2" component="p">
                              {toneData.transcript}
                            </Typography> */}
                          </>
                        )}
                      </Paperbox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        style={{
                          borderRadius: '5px'
                          // backgroundColor: '#f5f5f5'
                        }}
                        width="100%"
                        height="360px"
                      >
                        {/* <audio
                          controls
                          style={{ marginTop: 140, marginLeft: 100 }}
                          id="transAdio"
                          onTimeUpdate={displayCue}
                          ref={audioElement}
                        >
                          <source
                            src={`${process.env.REACT_APP_SERVER_APP_URL}${dataForCockpit[3]}`}
                            type="audio/mp3"
                          />
                          <track default kind="captions" srcLang="en" src={track} />
                        </audio> */}
                        <Grid item xs={12}>
                          {showTip(getAudioFileName(dataForCockpit[4]))}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography sx={{ fontWeight: 600 }}>
                            Upload date: {dataForCockpit[7]}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography sx={{ fontWeight: 600 }}>
                            Audio Duration: {audioDuration}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={12}>
                          <Tabs
                            value={valueWaveform}
                            onChange={handleChangeWave}
                            // indicatorColor="primary"
                            // textColor="primary"
                            variant="fullWidth"
                            aria-label="action tabs example"
                          >
                            <Tab label="Fixed" value={1} {...a12yProps(1)} />
                            <Tab label="Personalise" value={2} {...a12yProps(2)} />
                          </Tabs>
                        </Grid> */}
                        {/* <TabPanel value={valueWaveform} index={1}>
                          {!loading ? (
                            <>
                              <AudioWaveformNew
                                fileURL={s3Url}
                                // fileURL={`${process.env.REACT_APP_SERVER_APP_URL}${dataForCockpit[3]}`}
                                isAudioPlay={audioplay}
                                toneFix={fixTone}
                              />
                              <Grid
                                container
                                item
                                spacing={1}
                                style={{
                                  marginTop: '10px',
                                  marginBottom: 5,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center'
                                }}
                              >
                                <h5
                                  style={{
                                    padding: '3px'
                                  }}
                                >
                                  DeadAir
                                  <div
                                    style={{
                                      display: 'inline-block',
                                      width: '10px',
                                      height: '10px',
                                      border: '1px solid rgba(0, 0, 0, .2)',
                                      backgroundColor: 'rgba(212, 208, 197, 0.55)'
                                    }}
                                  />
                                </h5>

                                <h5
                                  style={{
                                    padding: '3px'
                                  }}
                                >
                                  HighTone
                                  <div
                                    style={{
                                      display: 'inline-block',
                                      width: '10px',
                                      height: '10px',
                                      border: '1px solid rgba(0, 0, 0, .2)',
                                      backgroundColor: 'hsla(0, 92%, 59%, 55%)'
                                    }}
                                  />
                                </h5>

                                <h5
                                  style={{
                                    padding: '3px'
                                  }}
                                >
                                  MediumTone
                                  <div
                                    style={{
                                      display: 'inline-block',
                                      width: '10px',
                                      height: '10px',
                                      border: '1px solid rgba(0, 0, 0, .2)',
                                      backgroundColor: 'rgba(136, 132, 216, 0.55)'
                                    }}
                                  />
                                </h5>

                                <h5
                                  style={{
                                    padding: '3px'
                                  }}
                                >
                                  LowTone
                                  <div
                                    style={{
                                      display: 'inline-block',
                                      width: '10px',
                                      height: '10px',
                                      border: '1px solid rgba(0, 0, 0, .2)',
                                      backgroundColor: 'rgba(130, 202, 157, 0.55)'
                                    }}
                                  />
                                </h5>
                              </Grid>
                            </>
                          ) : (
                            <CircularProgress sx={{ color: '#3892CF' }} />
                          )}
                        </TabPanel> */}
                        {/* <TabPanel value={valueWaveform} index={2}> */}
                        <div style={{ margin: 2 }}>
                          {s3Url ? (
                            <AudioWaveform
                              fileURL={
                                process.env.REACT_APP_ON_PREMISE === 'true'
                                  ? `${process.env.REACT_APP_SERVER_MEDIA_URL}${s3Url}`
                                  : s3Url
                              }
                              // fileURL={`${process.env.REACT_APP_SERVER_APP_URL}${dataForCockpit[3]}`}
                              setAudioDuration={setAudioDuration}
                              isAudioPlay={audioplay}
                              deadAirData={deadAirData}
                              getAudioFileName={getAudioFileName}
                            />
                          ) : (
                            <div
                              style={{
                                width: '100%',
                                height: 250,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                                // marginTop: 10,
                                // position: 'relative'
                                // backgroundColor: 'yellow'
                              }}
                            >
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available</b>
                              </span>
                            </div>
                          )}
                        </div>
                        <Box
                          style={{
                            margin: '25px',
                            color: 'black'
                          }}
                        >
                          <Grid
                            container
                            item
                            spacing={1}
                            style={{
                              marginBottom: 5,
                              padding: 4,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center'
                            }}
                          >
                            {/* <h5
                              style={{
                                padding: '6px'
                              }}
                            >
                              DeadAir
                              <div
                                style={{
                                  display: 'inline-block',
                                  width: '10px',
                                  height: '10px',
                                  border: '1px solid rgba(0, 0, 0, .2)',
                                  backgroundColor: 'rgba(212, 208, 197, 0.55)',
                                  marginLeft: '3px'
                                }}
                              />
                            </h5> */}

                            <h5
                              style={{
                                padding: '6px'
                              }}
                            >
                              HighTone
                              <div
                                style={{
                                  display: 'inline-block',
                                  width: '10px',
                                  height: '10px',
                                  border: '1px solid rgba(0, 0, 0, .2)',
                                  backgroundColor: 'hsla(0, 92%, 59%, 55%)',
                                  marginLeft: '3px'
                                }}
                              />
                            </h5>
                            <h5
                              style={{
                                padding: '6px'
                              }}
                            >
                              MediumTone
                              <div
                                style={{
                                  display: 'inline-block',
                                  width: '10px',
                                  height: '10px',
                                  border: '1px solid rgba(0, 0, 0, .2)',
                                  backgroundColor: 'rgba(136, 132, 216, 0.55)',
                                  marginLeft: '3px'
                                }}
                              />
                            </h5>

                            <h5
                              style={{
                                padding: '6px'
                              }}
                            >
                              LowTone
                              <div
                                style={{
                                  display: 'inline-block',
                                  width: '10px',
                                  height: '10px',
                                  border: '1px solid rgba(0, 0, 0, .2)',
                                  backgroundColor: 'rgba(130, 202, 157, 0.55)',
                                  marginLeft: '3px'
                                }}
                              />
                            </h5>
                          </Grid>
                        </Box>
                        {/* </TabPanel> */}
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Stack spacing={1}>
                    <Skeleton variant="text" />
                    <Skeleton variant="rectangular" width="100%" height={360} />
                  </Stack>
                )}
              </Box>
            ) : (
              <Box>
                {transcriptData ? (
                  <>
                    <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                      <Box
                        style={{
                          borderRadius: '5px',
                          textAlign: 'center'
                        }}
                        width="100%"
                      >
                        <audio controls id="transAdio" onTimeUpdate={displayCue} ref={audioElement}>
                          <source
                            src={
                              process.env.REACT_APP_ON_PREMISE === 'true'
                                ? `${process.env.REACT_APP_SERVER_MEDIA_URL}${s3Url}`
                                : s3Url
                            }
                            // src={`${process.env.REACT_APP_SERVER_APP_URL}${dataForCockpit[3]}`}
                            type="audio/mp3"
                          />
                          <track default kind="captions" srcLang="en" src={track} />
                        </audio>
                      </Box>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Paperbox
                          elevation={16}
                          style={{
                            padding: '2rem',
                            height: 360,
                            overflow: 'auto',
                            backgroundColor: '#fafafa'
                          }}
                        >
                          {defaultState === 'SUMMARY' ? (
                            <>
                              <Typography variant="h6" component="h2">
                                Summary
                              </Typography>
                              <Typography variant="body2" component="p">
                                {summaryData?.summary?.results?.summary_text}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography variant="h6" component="h2">
                                Transcript
                              </Typography>
                              {jsonTranscript?.map((transcript) => (
                                <Typography id="track" variant="body2" component="p">
                                  <span
                                    className="audioTracksSpans"
                                    id={transcript.start}
                                    style={{ fontWeight: 600 }}
                                  >
                                    {transcript.part}
                                  </span>
                                </Typography>
                              ))}
                            </>
                          )}
                        </Paperbox>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paperbox
                          elevation={16}
                          style={{
                            padding: '2rem',
                            height: 360,
                            overflow: 'auto',
                            backgroundColor: '#fafafa'
                          }}
                        >
                          {defaultState === 'SUMMARY' ? (
                            <>
                              <Typography variant="h6" component="h2">
                                Summary
                              </Typography>
                              <Typography variant="body2" component="p">
                                {summaryData?.summary?.results?.summary_text}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography variant="h6" component="h2">
                                Transcript
                              </Typography>
                              {jsonTranscript?.map((transcript) => (
                                <Typography id="track" variant="body2" component="p">
                                  <span
                                    className="audioTracksSpans"
                                    id={transcript.start}
                                    style={{ fontWeight: 600 }}
                                  >
                                    {transcript.part}
                                  </span>
                                </Typography>
                              ))}
                            </>
                          )}
                        </Paperbox>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Stack spacing={1}>
                    <Skeleton variant="text" />
                    <Skeleton variant="rectangular" width="100%" height={360} />
                  </Stack>
                )}
              </Box>
            )}

            <Box sx={{ width: '100%', marginTop: 5, minHeight: '600px', overflow: 'auto' }}>
              <Box style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 5 }}>
                {userDetails.role === 'Guest' && (
                  <Button
                    disabled={isPlanUpgraded}
                    variant="contained"
                    style={{
                      backgroundColor: isPlanUpgraded ? '#E0E0E0' : '#3892CF',
                      color: isPlanUpgraded && '#AFAFAF',
                      '&:hover': {
                        backgroundColor: isPlanUpgraded ? '#E0E0E0' : '#3892CF'
                      }
                    }}
                    // size="small"
                    onClick={(e) => setUpgradeModal(true)}
                    // style={{ marginRight: 5 }}
                  >
                    {isPlanUpgraded ? `Upgrade Requested` : `Upgrade Plan`}
                  </Button>
                )}
              </Box>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  className={classes.root}
                >
                  {/* <Tab label="Sentiment Analysis" {...a11yProps(0)} />
                  <Tab label="Keywords" {...a11yProps(1)} />
                  <Tab label="Entity Relation" {...a11yProps(2)} />
                  <Tab label="Word Cloud" {...a11yProps(3)} /> */}

                  {/* {process.env.REACT_APP_TO_SHOW_KEYWORDS === 'true' && (
                    <Tab label="Keywords" value={1} {...a11yProps(1)} className={classes.tab} />
                  )} */}
                  {process.env.REACT_APP_TO_SHOW_ENTITY === 'true' && (
                    <Tab
                      label="Entity Relation"
                      value={2}
                      {...a11yProps(2)}
                      className={classes.tab}
                    />
                  )}
                  {process.env.REACT_APP_TO_SHOW_WORDCLOUD === 'true' &&
                    dashboardServices.includes('sales') && (
                      <Tab label="Word Cloud" value={3} {...a11yProps(3)} className={classes.tab} />
                    )}
                  {/* {process.env.REACT_APP_TO_SHOW_TONE === 'true' && (
                    <Tab label="Unpleasant" value={4} {...a11yProps(4)} className={classes.tab} />
                  )} */}

                  {process.env.REACT_APP_TO_SHOW_UNPLEASANT === 'true' &&
                    dashboardServices.includes('collections') && (
                      <Tab label="Tone" value={5} {...a11yProps(5)} className={classes.tab} />
                    )}
                  {process.env.REACT_APP_TO_SHOW_QRC === 'true' &&
                    (userDetails?.Submenus.includes('Call_analysis') ||
                      dashboardServices.includes('collections')) && (
                      <Tab
                        label="Disposition"
                        value={17}
                        {...a11yProps(17)}
                        className={classes.tab}
                      />
                    )}
                  {process.env.REACT_APP_TO_SHOW_SENTIMENTS === 'true' &&
                    dashboardServices.includes('collections') && (
                      <Tab label="Sentiment" value={6} {...a11yProps(6)} className={classes.tab} />
                    )}
                  {true && dashboardServices.includes('sales') && (
                    <Tab
                      label="Word Analysis"
                      value={8}
                      {...a11yProps(8)}
                      className={classes.tab}
                    />
                  )}
                  {dashboardServices.includes('compliance') && (
                    <Tab
                      label="Script - Compliance"
                      value={7}
                      {...a11yProps(7)}
                      className={classes.tab}
                    />
                  )}
                  {dashboardServices.includes('compliance') && (
                    <Tab label="Score Card" value={14} {...a11yProps(14)} className={classes.tab} />
                  )}
                  {dashboardServices.includes('compliance') && (
                    <Tab
                      label="Call Insights"
                      value={16}
                      {...a11yProps(16)}
                      className={classes.tab}
                    />
                  )}
                  {dashboardServices.includes('sales') && (
                    <Tab label="Revenue" value={9} {...a11yProps(9)} className={classes.tab} />
                  )}
                  {(dashboardServices.includes('collections') ||
                    dashboardServices.includes('compliance')) && (
                    <Tab label="Feedback" value={10} {...a11yProps(10)} className={classes.tab} />
                  )}
                  {true && dashboardServices.includes('sales') && (
                    <Tab
                      label="Task Status Table"
                      value={11}
                      {...a11yProps(11)}
                      className={classes.tab}
                    />
                  )}
                  {process.env.REACT_APP_TO_SHOW_KEYWORDS === 'true' &&
                    dashboardServices.includes('collections') && (
                      <Tab
                        label="Keyword Tracker"
                        value={12}
                        {...a11yProps(12)}
                        className={classes.tab}
                      />
                    )}
                  {process.env.REACT_APP_TO_SHOW_PHRASES === 'true' &&
                    dashboardServices.includes('collections') && (
                      <Tab
                        label="Phrases Tracker"
                        value={18}
                        {...a11yProps(18)}
                        className={classes.tab}
                      />
                    )}

                  {/* {true && dashboardServices.includes('collections') && (
                    <Tab
                      label="Phrases Tracker"
                      value={15}
                      {...a11yProps(15)}
                      className={classes.tab}
                    />
                  )} */}
                </Tabs>
              </Box>

              {/* {toneData && sentimentData && getUnpleasantWord ? ( */}
              <Box>
                {/* <TabPanel value={value} index={0}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>Customer Sentiments</h4>
                        <PieChart
                          data={
                            defaultState === 'SUMMARY'
                              ? summarySentimentsOverall.sentimentanalysis_summary_overall
                              : transcriptSentimentsOverall.sentimentanalysis_transcript_overall
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>Agent Sentiments</h4>

                        <PieChart
                          data={
                            defaultState === 'SUMMARY'
                              ? summarySentimentsOverall.sentimentanalysis_summary_overall
                              : transcriptSentimentsOverall.sentimentanalysis_transcript_overall
                          }
                        />
                      </Grid>
                    </Grid>
                  </TabPanel> */}

                {/* <TabPanel value={value} index={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>Customer Keywords</h4>
                        <Keywords
                          data={
                            defaultState === 'SUMMARY'
                              ? keywordSummary?.keyword_summary
                              : keywordTranscript?.keyword_transcript
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>Agent Keywords</h4>

                        <Keywords
                          data={
                            defaultState === 'SUMMARY'
                              ? keywordSummary?.keyword_summary
                              : keywordTranscript?.keyword_transcript
                          }
                        />
                      </Grid>
                    </Grid>
                  </TabPanel> */}

                {/* <TabPanel value={value} index={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>Customer</h4>
                        <Paperbox
                          elevation={16}
                          style={{
                            padding: '2rem',
                            height: 600,
                            overflow: 'auto'
                          }}
                        >
                          {defaultState === 'SUMMARY'
                            ? entitySum.map((data) => (
                                <div dangerouslySetInnerHTML={{ __html: data }} />
                              ))
                            : entityArr.map((data) => (
                                <div dangerouslySetInnerHTML={{ __html: data }} />
                              ))}
                        </Paperbox>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>Agent</h4>
                        <Paperbox
                          elevation={16}
                          style={{
                            padding: '2rem',
                            height: 600,
                            overflow: 'auto'
                          }}
                        >
                          {defaultState === 'SUMMARY'
                            ? entitySum.map((data) => (
                                <div dangerouslySetInnerHTML={{ __html: data }} />
                              ))
                            : entityArr.map((data) => (
                                <div dangerouslySetInnerHTML={{ __html: data }} />
                              ))}
                        </Paperbox>
                      </Grid>
                    </Grid>
                  </TabPanel> */}

                {/* <TabPanel value={value} index={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>Customer</h4>
                        <Paperbox
                          elevation={16}
                          style={{
                            padding: '2rem',
                            height: 600,
                            overflow: 'auto'
                          }}
                        >
                          <WordCloud
                            data={
                              defaultState === 'SUMMARY'
                                ? wordCloudForSummary?.wordcloud_summary?.results
                                : wordCloudForTranscript?.wordcloud_transcript?.results
                            }
                          />
                        </Paperbox>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>Agent</h4>
                        <Paperbox
                          elevation={16}
                          style={{
                            padding: '2rem',
                            height: 600,
                            overflow: 'auto'
                          }}
                        >
                          <WordCloud
                            data={
                              defaultState === 'SUMMARY'
                                ? wordCloudForSummary?.wordcloud_summary?.results
                                : wordCloudForTranscript?.wordcloud_transcript?.results
                            }
                          />
                        </Paperbox>
                      </Grid>
                    </Grid>
                  </TabPanel> */}

                <TabPanel value={value} index={4}>
                  {userDetails.role === 'Guest' ? (
                    <Grid container>
                      {' '}
                      <Card
                        style={{
                          minHeight: 300,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          backgroundColor: '#2a2c310d'
                        }}
                      >
                        {isPlanUpgraded ? (
                          <>
                            <span
                              style={{
                                fontWeight: '700',
                                fontSize: 20,
                                marginTop: 5,
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <VerifiedIcon
                                style={{
                                  color: '#0ecf0e',
                                  marginRight: 5,
                                  fontSize: 25
                                }}
                              />
                              Thank you for your interest in upgrading. Our team will contact you
                              soon.
                            </span>
                          </>
                        ) : (
                          <>
                            <LockIcon
                              style={{ height: 40, width: 40, color: '#A8A8A8', marginBottom: 5 }}
                            />
                            <span style={{ fontWeight: '700', fontSize: 20, marginTop: 5 }}>
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: '#3892CF',
                                  '&:hover': {
                                    backgroundColor: '#3892CF'
                                  }
                                }}
                                // size="small"
                                onClick={(e) => setUpgradeModal(true)}
                                // style={{ marginRight: 5 }}
                              >
                                Upgrade
                              </Button>{' '}
                              to premium plan to unlock this functionality.
                            </span>
                          </>
                        )}
                      </Card>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <Typography
                          onClick={() => handleCustomerDialog()}
                          style={{ width: '234', cursor: 'pointer' }}
                        >
                          <h4 style={{ padding: '0.5rem' }}>Customer Unpleasant Word</h4>
                        </Typography>
                        {getUnpleasantWord &&
                        getUnpleasantWord.customer_data &&
                        Object.keys(getUnpleasantWord.customer_data).length > 1 ? (
                          <Keywords data={getUnpleasantWord.customer_data} />
                        ) : (
                          <Card sx={{ height: 350, width: 450 }}>
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available</b>
                              </span>
                            </div>
                          </Card>
                        )}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography
                          onClick={() => handleDialog()}
                          style={{ width: '198', cursor: 'pointer' }}
                        >
                          <h4 style={{ padding: '0.5rem' }}>Agent Unpleasant Word</h4>
                        </Typography>
                        {getUnpleasantWord?.agent_data &&
                        Object.keys(getUnpleasantWord?.agent_data).length > 1 ? (
                          <Keywords data={getUnpleasantWord.agent_data} />
                        ) : (
                          <Card sx={{ height: 350, width: 450 }}>
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available</b>
                              </span>
                            </div>
                          </Card>
                        )}
                      </Grid>
                      {/* <Grid item xs={12} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>Similar Customer Unpleasant Word</h4>
                        {similarSearchData &&
                          similarSearchData.customer_similar_words.map((el, index) =>
                            el.matching_words.map((newEl) => (
                              <Chip
                                key={index}
                                label={`${el.word} : ${Object.keys(newEl)}`}
                                variant="filled"
                                style={{
                                  backgroundColor: '#fccd6f',
                                  color: 'Primary',
                                  margin: '5px',
                                  width: 200
                                }}
                                size="medium"
                              />
                            ))
                          )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <h4 style={{ padding: '0.5rem' }}> Similar Agent Unpleasant Word</h4>
                        {similarSearchData &&
                          similarSearchData.agent_similar_words.map((el, index) =>
                            el.matching_words.map((newEl) => (
                              <Chip
                                key={index}
                                label={`${el.word} : ${Object.keys(newEl)}`}
                                variant="filled"
                                style={{
                                  backgroundColor: '#fccd6f',
                                  color: 'Primary',
                                  margin: '5px',
                                  width: 200
                                }}
                                size="medium"
                              />
                            ))
                          )}
                      </Grid> */}
                    </Grid>
                  )}
                </TabPanel>

                <TabPanel value={value} index={5}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <h4 style={{ padding: '0.5rem' }}>Agent Tone Analysis</h4>
                      {/* <PieChart data={agentTonePercentage} /> */}

                      {agentEnergyData.length > 0 ? (
                        <Card>
                          <ResponsiveContainer width="100%" height={500}>
                            <LineChart
                              width="100%"
                              height={500}
                              data={agentEnergyData}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5
                              }}
                            >
                              {/* <CartesianGrid strokeDasharray="3 3" /> */}
                              <XAxis
                                dataKey="name"
                                // label={{
                                //   value: 'Time in sec',
                                //   // angle: -90
                                //   position: 'insideRight'
                                // }}
                              />
                              <YAxis
                                label={{
                                  // value: toneData?.aws_services ? 'Voices in DB' : 'Energy Values',
                                  value: 'Energy Values',
                                  angle: -90,
                                  position: 'insideLeft'
                                }}
                                fontSize={14}
                                type="number"
                                // domain={[0, toneData?.aws_services ? 100 : 3]}
                                // domain={[0, 3]}
                                allowDataOverflow
                              />
                              <ReferenceLine
                                y={energyValuesData?.agent_mid}
                                label="mid"
                                strokeDasharray="5 5"
                                stroke="rgba(250, 50, 53, 0.4)"
                              />
                              <ReferenceLine
                                y={energyValuesData?.agent_third_quartile}
                                label="Third_quartile"
                                strokeDasharray="5 5"
                                stroke="rgba(250, 50, 53, 0.4)"
                              />
                              <Tooltip content={<AgentTooltip />} />
                              {/* <Legend /> */}
                              <Line
                                type="monotone"
                                dataKey="agent"
                                stroke="rgba(247, 57, 85, 0.85)"
                                activeDot={{ r: 8 }}
                                dot={false}
                              />
                              {/* <Line type="monotone" dataKey="customer" stroke="#82ca9d" /> */}
                            </LineChart>
                          </ResponsiveContainer>
                          <Box sx={{ marginY: '10px', textAlign: 'center' }}>Time in sec</Box>
                        </Card>
                      ) : (
                        <Card sx={{ height: 350 }}>
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        </Card>
                      )}
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <h4 style={{ padding: '0.5rem' }}>Customer Tone Analysis</h4>
                      {customerEnergyData.length > 0 ? (
                        <Card>
                          {/* <PieChart data={agentTonePercentage} /> */}
                          <ResponsiveContainer width="100%" height={500}>
                            <LineChart
                              width="100%"
                              height={500}
                              data={customerEnergyData}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5
                              }}
                            >
                              {/* <CartesianGrid strokeDasharray="3 3" /> */}
                              <XAxis
                                dataKey="name"
                                // type="number"
                                // domain={[0, 60]}
                                // label={{
                                //   value: 'Time in sec',
                                //   // angle: -90
                                //   position: 'outsideRight'
                                // }}
                              />
                              <YAxis
                                label={{
                                  // value: toneData?.aws_services ? 'Voices in DB' : 'Energy Values',
                                  value: 'Energy Values',
                                  angle: -90,
                                  position: 'insideLeft'
                                }}
                                fontSize={14}
                                type="number"
                                // domain={[0, toneData?.aws_services ? 100 : 3]}
                                // domain={[0, 3]}
                                allowDataOverflow
                              />
                              <ReferenceLine
                                y={energyValuesData?.customer_mid}
                                label="mid"
                                strokeDasharray="5 5"
                                stroke="rgba(250, 50, 53, 0.6)"
                              />
                              <ReferenceLine
                                y={energyValuesData?.customer_third_quartile}
                                label="Third_quartile"
                                strokeDasharray="5 5"
                                stroke="rgba(250, 50, 53, 0.6)"
                              />

                              <Tooltip content={<CustomerTooltip />} />
                              {/* <Legend /> */}
                              <Line
                                type="monotone"
                                dataKey="customer"
                                stroke="rgba(14, 98, 232, 0.85)"
                                activeDot={{ r: 8 }}
                                dot={false}
                              />
                              {/* <Line type="monotone" dataKey="customer" stroke="#82ca9d" /> */}
                            </LineChart>
                          </ResponsiveContainer>
                          <Box sx={{ marginY: '10px', textAlign: 'center' }}>Time in sec</Box>
                        </Card>
                      ) : (
                        <Card sx={{ height: 350 }}>
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        </Card>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: 2 }}>
                      <h4 style={{ padding: '0.5rem' }}>Customer Tone Analysis</h4>
                      {/* <PieChart data={customerTonePercentage} /> */}
                      {toneData ? (
                        <BarChart
                          customerBar={customerBar}
                          handleDialog={handleToneDialog}
                          // data={customerTonePercentage}
                          data={
                            toneData?.customer_tone_details
                              ? [
                                  (
                                    (toneData?.customer_tone_details.high.length /
                                      (toneData?.customer_tone_details.high.length +
                                        toneData?.customer_tone_details.medium.length +
                                        toneData?.customer_tone_details.low.length)) *
                                    100
                                  ).toFixed(0),
                                  (
                                    (toneData?.customer_tone_details.medium.length /
                                      (toneData?.customer_tone_details.high.length +
                                        toneData?.customer_tone_details.medium.length +
                                        toneData?.customer_tone_details.low.length)) *
                                    100
                                  ).toFixed(0),
                                  (
                                    (toneData?.customer_tone_details.low.length /
                                      (toneData?.customer_tone_details.high.length +
                                        toneData?.customer_tone_details.medium.length +
                                        toneData?.customer_tone_details.low.length)) *
                                    100
                                  ).toFixed(0)
                                ]
                              : [0, 0, 0]
                          }
                          open={toneOpen}
                        />
                      ) : (
                        <Card sx={{ height: 350 }}>
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        </Card>
                      )}
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ padding: 2 }}>
                      <h4 style={{ padding: '0.5rem' }}>Agent Tone Analysis</h4>
                      {/* <PieChart data={agentTonePercentage} /> */}
                      {toneData ? (
                        <BarChart
                          agentBar={agentBar}
                          handleDialog={handleToneDialog}
                          // data={agentTonePercentage}
                          data={
                            toneData?.agent_tone_details
                              ? [
                                  (
                                    (toneData?.agent_tone_details.high.length /
                                      (toneData?.agent_tone_details.high.length +
                                        toneData?.agent_tone_details.medium.length +
                                        toneData?.agent_tone_details.low.length)) *
                                    100
                                  ).toFixed(0),
                                  (
                                    (toneData?.agent_tone_details.medium.length /
                                      (toneData?.agent_tone_details.high.length +
                                        toneData?.agent_tone_details.medium.length +
                                        toneData?.agent_tone_details.low.length)) *
                                    100
                                  ).toFixed(0),
                                  (
                                    (toneData?.agent_tone_details.low.length /
                                      (toneData?.agent_tone_details.high.length +
                                        toneData?.agent_tone_details.medium.length +
                                        toneData?.agent_tone_details.low.length)) *
                                    100
                                  ).toFixed(0)
                                ]
                              : [0, 0, 0]
                          }
                          open={toneOpen}
                        />
                      ) : (
                        <Card sx={{ height: 350 }}>
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        </Card>
                      )}
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <h4 style={{ padding: '0.5rem' }}>Customer Sentiment Analysis</h4>
                      {sentimentData &&
                      Object.getOwnPropertyNames(sentimentData?.customer_sentiments).length !==
                        0 ? (
                        <CustomerSentiment
                          data={customerSentimentPercentage}
                          handleDialog={handleSentimentDialog}
                          open={sentimentOpen}
                        />
                      ) : (
                        <Card sx={{ height: 350 }}>
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        </Card>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <h4 style={{ padding: '0.5rem' }}>Agent Sentiment Analysis</h4>
                      {sentimentData &&
                      Object.getOwnPropertyNames(sentimentData.agent_sentiments).length !== 0 ? (
                        <AgentSentiment
                          data={agentSentimentPercentage}
                          handleDialog={handleAgentSentimentDialog}
                          open={agentSentimentOpen}
                        />
                      ) : (
                        <Card sx={{ height: 350 }}>
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        </Card>
                      )}
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={7}>
                  {userDetails.role === 'Guest' ? (
                    <Card
                      style={{
                        minHeight: 300,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: '#2a2c310d'
                      }}
                    >
                      {isPlanUpgraded ? (
                        <>
                          <span
                            style={{
                              fontWeight: '700',
                              fontSize: 20,
                              marginTop: 5,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <VerifiedIcon
                              style={{
                                color: '#0ecf0e',
                                marginRight: 5,
                                fontSize: 25
                              }}
                            />{' '}
                            Thank you for your interest in upgrading. Our team will contact you
                            soon.
                          </span>
                        </>
                      ) : (
                        <>
                          <LockIcon
                            style={{ height: 40, width: 40, color: '#A8A8A8', marginBottom: 5 }}
                          />
                          <span style={{ fontWeight: '700', fontSize: 20, marginTop: 5 }}>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                }
                              }}
                              // size="small"
                              onClick={(e) => setUpgradeModal(true)}
                              // style={{ marginRight: 5 }}
                            >
                              Upgrade
                            </Button>{' '}
                            to premium plan to unlock this functionality.
                          </span>
                        </>
                      )}
                    </Card>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>Compliance</h4>
                        <Card style={{ height: 400 }}>
                          {complianceInfo &&
                          Object.keys(complianceInfo?.compliant_info).length > 0 &&
                          complianceInfo.compliant_info.compliance_percentage !== 0 ? (
                            <CircularGauge
                              id="gauge1"
                              // value={60}
                              value={
                                complianceInfo &&
                                Object.keys(complianceInfo?.compliant_info).length > 0
                                  ? complianceInfo.compliant_info.compliance_percentage
                                  : 0
                              }
                            >
                              <Scale startValue={0} endValue={100} tickInterval={20} />
                              <RangeContainer width={10}>
                                <Range startValue={0} endValue={60} color="RGB(255 83 83)" />
                                <Range startValue={60} endValue={80} color="RGB(255 250 107)" />
                                <Range startValue={90} endValue={100} color="RGB(87 255 111)" />
                                <Range startValue={80} endValue={90} color="RGB(40 255 111)" />
                              </RangeContainer>
                              <GaugeTooltip
                                enabled
                                cornerRadius={15}
                                color="#cce6ff"
                                border={{ visible: false }}
                              >
                                <Font size={20} color="#3399ff" weight={500} />
                              </GaugeTooltip>
                            </CircularGauge>
                          ) : complianceInfo &&
                            Object.keys(complianceInfo?.non_compliant_info).length > 0 ? (
                            <CircularGauge
                              id="gauge1"
                              // value={60}
                              value={
                                complianceInfo &&
                                Object.keys(complianceInfo?.non_compliant_info).length > 0
                                  ? complianceInfo.non_compliant_info.compliance_percentage
                                  : 0
                              }
                            >
                              <Scale startValue={0} endValue={100} tickInterval={20} />
                              <RangeContainer width={10}>
                                <Range startValue={0} endValue={60} color="RGB(255 83 83)" />
                                <Range startValue={60} endValue={80} color="RGB(255 250 107)" />
                                <Range startValue={90} endValue={100} color="RGB(87 255 111)" />
                                <Range startValue={80} endValue={90} color="RGB(40 255 111)" />
                              </RangeContainer>
                              <GaugeTooltip
                                enabled
                                cornerRadius={15}
                                color="#cce6ff"
                                border={{ visible: false }}
                              >
                                <Font size={20} color="#3399ff" weight={500} />
                              </GaugeTooltip>
                            </CircularGauge>
                          ) : (
                            <div
                              style={{
                                width: '100%',
                                height: '80%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available</b>
                              </span>
                            </div>
                          )}
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>Questions Asked</h4>
                        <Card style={{ height: 400 }}>
                          {complianceInfo &&
                          Object.keys(complianceInfo?.compliant_info).length > 0 &&
                          Object.keys(complianceInfo.compliant_info.Categories).length > 0 ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                height: 395,
                                overflowY: 'auto'
                              }}
                            >
                              <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead sx={{ backgroundColor: 'RGB(250 250 250)' }}>
                                    <TableRow>
                                      <TableCell>
                                        <b>Sr.no</b>
                                      </TableCell>
                                      <TableCell>
                                        <b>Parameter</b>
                                      </TableCell>
                                      <TableCell>
                                        <b>Phrases</b>
                                      </TableCell>
                                      <TableCell>
                                        <b>Asked?</b>
                                      </TableCell>
                                      <TableCell>
                                        <b />
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {complianceInfo.compliant_info.Categories &&
                                      Object.keys(complianceInfo.compliant_info.Categories).map(
                                        (el, index) => (
                                          <TableRow>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              padding="normal"
                                              style={{
                                                whiteSpace: 'nowrap',
                                                maxWidth: '80px', // Adjust the max width as needed
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis' // Add ellipsis for truncation
                                              }}
                                              align="center"
                                              title={el}
                                            >
                                              {el}
                                            </TableCell>
                                            <TableCell align="left">
                                              <>
                                                <Tip
                                                  title={`${categoryQuestion
                                                    ?.join()
                                                    .slice(0, 25)}...`}
                                                  placement="top"
                                                >
                                                  <IconButton
                                                    onMouseOver={() => {
                                                      const array = [];
                                                      complianceInfo?.compliant_info.Categories[
                                                        el
                                                      ].map((el) => array.push(el.Content[0]));
                                                      setCategoryQuestion(array);
                                                    }}
                                                    onClick={(event) => {
                                                      setParameterName(el);
                                                      handleScriptDialog();
                                                    }}
                                                  >
                                                    <Icon icon={eyeIcon} width={20} height={20} />
                                                  </IconButton>
                                                </Tip>
                                              </>
                                            </TableCell>
                                            <TableCell align="center">
                                              {complianceInfo?.compliant_info?.na_parameters[el] ? (
                                                <div
                                                  style={{
                                                    width: '50px',
                                                    height: '25px',
                                                    color: '#fff',
                                                    borderRadius: '7px',
                                                    backgroundColor: '#f7b900',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                  }}
                                                >
                                                  NA
                                                </div>
                                              ) : complianceInfo?.compliant_info.Categories[el]
                                                  .length > 0 ? (
                                                <div
                                                  style={{
                                                    width: '50px',
                                                    height: '25px',
                                                    color: '#fff',
                                                    borderRadius: '7px',
                                                    backgroundColor: 'green',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                  }}
                                                >
                                                  Yes
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    width: '50px',
                                                    height: '25px',
                                                    color: '#fff',
                                                    borderRadius: '7px',
                                                    backgroundColor: 'red',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                  }}
                                                >
                                                  No
                                                </div>
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              <>
                                                <IconButton
                                                  onClick={(event) => {
                                                    setUrlIndex(
                                                      complianceInfo?.compliant_info.Categories[el]
                                                    );
                                                    setAnchorE0(event.currentTarget);
                                                  }}
                                                >
                                                  <Icon
                                                    icon={moreVerticalFill}
                                                    width={20}
                                                    height={20}
                                                  />
                                                </IconButton>

                                                {urlIndex.length > 0 ? (
                                                  <Menu
                                                    open={Boolean(anchorE0)}
                                                    anchorEl={anchorE0}
                                                    onClose={(event) => setAnchorE0(null)}
                                                    PaperProps={{
                                                      sx: {
                                                        width: 200,
                                                        maxWidth: '100%',
                                                        maxHeight: 150,
                                                        overflowY: 'auto'
                                                      }
                                                    }}
                                                    anchorOrigin={{
                                                      vertical: 'top',
                                                      horizontal: 'right'
                                                    }}
                                                    transformOrigin={{
                                                      vertical: 'top',
                                                      horizontal: 'right'
                                                    }}
                                                  >
                                                    {urlIndex.map((element, index) => (
                                                      <MenuItem sx={{ color: 'text.secondary' }}>
                                                        <audio
                                                          src={
                                                            process.env.REACT_APP_ON_PREMISE ===
                                                            'true'
                                                              ? `${
                                                                  process.env
                                                                    .REACT_APP_SERVER_MEDIA_URL
                                                                }${element.url[0].replace(
                                                                  '/app',
                                                                  ''
                                                                )}`
                                                              : element.url[0]
                                                          }
                                                          controls
                                                        >
                                                          <track
                                                            default
                                                            kind="captions"
                                                            srcLang="en"
                                                          />
                                                        </audio>
                                                      </MenuItem>
                                                    ))}
                                                  </Menu>
                                                ) : null}
                                              </>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          ) : complianceInfo &&
                            Object.keys(complianceInfo.non_compliant_info).length > 0 &&
                            Object.keys(complianceInfo.non_compliant_info.Categories).length > 0 ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                height: 395,
                                overflowY: 'auto'
                              }}
                            >
                              <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead sx={{ backgroundColor: 'RGB(250 250 250)' }}>
                                    <TableRow>
                                      <TableCell>
                                        <b>Sr.no</b>
                                      </TableCell>
                                      <TableCell>
                                        <b>Parameters</b>
                                      </TableCell>
                                      <TableCell>
                                        <b>Phrases</b>
                                      </TableCell>
                                      <TableCell>
                                        <b>Asked?</b>
                                      </TableCell>
                                      <TableCell>
                                        <b />
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {complianceInfo.non_compliant_info.Categories &&
                                      Object.keys(complianceInfo.non_compliant_info.Categories).map(
                                        (el, index) => (
                                          <TableRow>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{el}</TableCell>
                                            <TableCell align="left">
                                              <>
                                                <Tip
                                                  title={`${categoryQuestion
                                                    ?.join()
                                                    .slice(0, 25)}...`}
                                                  placement="top"
                                                >
                                                  <IconButton
                                                    onMouseOver={() => {
                                                      const array = [];
                                                      complianceInfo?.non_compliant_info.Categories[
                                                        el
                                                      ].map((el) => array.push(el.Content[0]));
                                                      setCategoryQuestion(array);
                                                    }}
                                                    onClick={(event) => {
                                                      setParameterName(el);
                                                      handleScriptDialog();
                                                    }}
                                                  >
                                                    <Icon icon={eyeIcon} width={20} height={20} />
                                                  </IconButton>
                                                </Tip>
                                              </>
                                            </TableCell>
                                            <TableCell align="center">
                                              {complianceInfo.non_compliant_info.na_parameters[
                                                el
                                              ] ? (
                                                <div
                                                  style={{
                                                    width: '50px',
                                                    height: '25px',
                                                    color: '#fff',
                                                    borderRadius: '7px',
                                                    backgroundColor: '#f7b900',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                  }}
                                                >
                                                  NA
                                                </div>
                                              ) : complianceInfo?.non_compliant_info.Categories[el]
                                                  .length > 0 ? (
                                                <div
                                                  style={{
                                                    width: '50px',
                                                    height: '25px',
                                                    color: '#fff',
                                                    borderRadius: '7px',
                                                    backgroundColor: 'green',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                  }}
                                                >
                                                  Yes
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    width: '50px',
                                                    height: '25px',
                                                    color: '#fff',
                                                    borderRadius: '7px',
                                                    backgroundColor: 'red',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                  }}
                                                >
                                                  No
                                                </div>
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              <>
                                                <IconButton
                                                  onClick={(event) => {
                                                    setUrlIndex(
                                                      complianceInfo?.non_compliant_info.Categories[
                                                        el
                                                      ]
                                                    );
                                                    setAnchorE0(event.currentTarget);
                                                  }}
                                                >
                                                  <Icon
                                                    icon={moreVerticalFill}
                                                    width={20}
                                                    height={20}
                                                  />
                                                </IconButton>

                                                {urlIndex.length > 0 ? (
                                                  <Menu
                                                    open={Boolean(anchorE0)}
                                                    anchorEl={anchorE0}
                                                    onClose={(event) => setAnchorE0(null)}
                                                    PaperProps={{
                                                      sx: {
                                                        width: 200,
                                                        maxWidth: '100%',
                                                        maxHeight: 150,
                                                        overflowY: 'auto'
                                                      }
                                                    }}
                                                    anchorOrigin={{
                                                      vertical: 'top',
                                                      horizontal: 'right'
                                                    }}
                                                    transformOrigin={{
                                                      vertical: 'top',
                                                      horizontal: 'right'
                                                    }}
                                                  >
                                                    {urlIndex.map((element, index) => (
                                                      <MenuItem sx={{ color: 'text.secondary' }}>
                                                        <audio
                                                          src={
                                                            process.env.REACT_APP_ON_PREMISE ===
                                                            'true'
                                                              ? `${
                                                                  process.env
                                                                    .REACT_APP_SERVER_MEDIA_URL
                                                                }${element.url[0].replace(
                                                                  '/app',
                                                                  ''
                                                                )}`
                                                              : element.url[0]
                                                          }
                                                          controls
                                                        >
                                                          <track
                                                            default
                                                            kind="captions"
                                                            srcLang="en"
                                                          />
                                                        </audio>
                                                      </MenuItem>
                                                    ))}
                                                  </Menu>
                                                ) : null}
                                              </>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          ) : (
                            <div
                              style={{
                                width: '100%',
                                height: '80%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available</b>
                              </span>
                            </div>
                          )}

                          {categoryQuestion?.length > 0 ? (
                            <CustomFormModel
                              fullScreen={fullScreen}
                              handleDialog={handleScriptDialog}
                              open={scriptOpen}
                              title={`${parameterName} Phrases`}
                            >
                              <Paperbox
                                // elevation={16}
                                style={{
                                  marginTop: 10,
                                  padding: '2rem',
                                  height: 360,
                                  overflow: 'auto',
                                  backgroundColor: '#fafafa'
                                }}
                              >
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Sr.No</TableCell>
                                        <TableCell>Phrase</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {categoryQuestion.map((el, index) => (
                                        <TableRow>
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell>{el}</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Paperbox>
                            </CustomFormModel>
                          ) : null}
                        </Card>
                      </Grid>
                    </Grid>
                  )}
                </TabPanel>

                <TabPanel value={value} index={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <h4 style={{ padding: '0.5rem' }}>Word Analysis</h4>
                      <Card>
                        {cockpitSalesData !== null &&
                        cockpitSalesData.wordcloud &&
                        cockpitSalesData.wordcloud.length !== 0 ? (
                          <Wordcloud data={cockpitSalesData.wordcloud} />
                        ) : (
                          <div
                            style={{
                              width: '100%',
                              height: 300,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        )}
                      </Card>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={9}>
                  {userDetails.role === 'Guest' ? (
                    <Card
                      style={{
                        minHeight: 300,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: '#2a2c310d'
                      }}
                    >
                      {isPlanUpgraded ? (
                        <>
                          <span
                            style={{
                              fontWeight: '700',
                              fontSize: 20,
                              marginTop: 5,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <VerifiedIcon
                              style={{
                                color: '#0ecf0e',
                                marginRight: 5,
                                fontSize: 25
                              }}
                            />{' '}
                            Thank you for your interest in upgrading. Our team will contact you
                            soon.
                          </span>
                        </>
                      ) : (
                        <>
                          {' '}
                          <LockIcon
                            style={{ height: 40, width: 40, color: '#A8A8A8', marginBottom: 5 }}
                          />
                          <span style={{ fontWeight: '700', fontSize: 20, marginTop: 5 }}>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                }
                              }}
                              // size="small"
                              onClick={(e) => setUpgradeModal(true)}
                              // style={{ marginRight: 5 }}
                            >
                              Upgrade
                            </Button>{' '}
                            to premium plan to unlock this functionality.
                          </span>
                        </>
                      )}
                    </Card>
                  ) : (
                    <>
                      {' '}
                      <Grid
                        container
                        spacing={3}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          paddingLeft: 20,
                          paddingTop: 10,
                          alignItems: 'center'
                        }}
                      >
                        {dashboardServices &&
                          dashboardServices.includes('sales') &&
                          userDetails.role !== 'Agent' && (
                            <>
                              <ToggleButtonGroup
                                value={alignment}
                                // exclusive
                                onChange={handleToggle}
                                aria-label="Platform"
                                style={{
                                  marginTop: 5,
                                  marginBottom: 5,
                                  width: 200,
                                  paddingLeft: 8,
                                  color: '#3892CF'
                                }}
                              >
                                <ToggleButton
                                  value="approve"
                                  style={{ color: alignment === 'approve' ? '#3892CF' : 'grey' }}
                                >
                                  Approve
                                </ToggleButton>
                                <ToggleButton
                                  value="reject"
                                  style={{ color: alignment === 'reject' ? '#3892CF' : 'grey' }}
                                >
                                  Reject
                                </ToggleButton>
                              </ToggleButtonGroup>
                              <Button
                                variant="contained"
                                startIcon={<PlusIcon />}
                                onClick={handleCreateDeal}
                                disabled={alignment !== 'approve'}
                                style={{
                                  marginRight: 5,
                                  backgroundColor: alignment !== 'approve' ? '#DCDCDC' : '#3892CF',
                                  '&:hover': {
                                    backgroundColor: '#3892CF'
                                  },
                                  height: 40
                                }}
                              >
                                Create deal
                              </Button>
                            </>
                          )}
                        <CustomFormModel
                          fullScreen={fullScreen}
                          handleDialog={handleCreateDeal}
                          open={openCreateDealModel}
                          title="Add Deal"
                        >
                          <DealForm handleDialog={handleCreateDeal} formik={createDealformik} />
                        </CustomFormModel>
                      </Grid>
                      <Grid container spacing={3}>
                        {/* <Grid item xs={12} md={6}>
                          <h4 style={{ padding: '0.5rem' }}>Likelihood to buy</h4>
                          <Card sx={{ height: 350, paddingLeft: 3, paddingRight: 3 }}>
                            {(cockpitSalesData !== null &&
                              cockpitSalesData?.high_sentiments?.length !== 0) ||
                            cockpitSalesData?.low_sentiments?.length !== 0 ? (
                              <>
                                {cockpitSalesData !== null && cockpitSalesData.high_sentiments && (
                                  <Grid item xs={12} style={{ marginTop: 25, padding: 5 }}>
                                    <span style={{ fontWeight: '400', fontSize: 14 }}>
                                      Hot Lead (
                                      {cockpitSalesData !== null && cockpitSalesData.high_sentiments
                                        ? cockpitSalesData.high_sentiments.length
                                        : 0}
                                      )
                                    </span>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <div style={{ width: '97%' }}>
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={
                                            cockpitSalesData !== null &&
                                            cockpitSalesData.high_sentiments
                                              ? cockpitSalesData.high_sentiments.length
                                              : 0
                                          }
                                        />
                                      </div>
                                      <IconButton
                                        onClick={() => {
                                          setSalesIntentSentence(
                                            cockpitSalesData?.high_sentiments.map(
                                              (element) => element.Content[0]
                                            )
                                          );
                                          setSalesIntentName('Hot Leads');
                                          handleSalesIntentDialog();
                                        }}
                                      >
                                        <Icon icon={eyeIcon} width={20} height={20} />
                                      </IconButton>

                                      <IconButton
                                        onClick={(event) => setAnchorEl(event.currentTarget)}
                                      >
                                        <Icon icon={moreVerticalFill} width={20} height={20} />
                                      </IconButton>
                                      {cockpitSalesData?.high_sentiments.length > 0 ? (
                                        <Menu
                                          open={Boolean(anchorEl)}
                                          anchorEl={anchorEl}
                                          onClose={(event) => setAnchorEl(null)}
                                          PaperProps={{
                                            sx: {
                                              width: 200,
                                              maxWidth: '100%',
                                              maxHeight: 200,
                                              overflowY: 'auto'
                                            }
                                          }}
                                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                          }}
                                        >
                                          {cockpitSalesData?.high_sentiments.map(
                                            (element, index) => (
                                              <MenuItem sx={{ color: 'text.secondary' }}>
                                                <audio
                                                  ref={snippetRef}
                                                  src={
                                                    process.env.REACT_APP_ON_PREMISE === 'true'
                                                      ? `${
                                                          process.env.REACT_APP_SERVER_MEDIA_URL
                                                        }${element.url.replace('/app', '')}`
                                                      : element.url
                                                  }
                                                  controls
                                                >
                                                  <track default kind="captions" srcLang="en" />
                                                </audio>
                                              </MenuItem>
                                            )
                                          )}
                                        </Menu>
                                      ) : null}
                                    </div>
                                  </Grid>
                                )}
                                {cockpitSalesData !== null && cockpitSalesData.mid_sentiments && (
                                  <Grid item xs={12} style={{ marginTop: 10, padding: 5 }}>
                                    <span style={{ fontWeight: '400', fontSize: 14 }}>
                                      Warm Lead (
                                      {cockpitSalesData !== null && cockpitSalesData.mid_sentiments
                                        ? cockpitSalesData.mid_sentiments.length
                                        : 0}
                                      )
                                    </span>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <div style={{ width: '97%' }}>
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={
                                            cockpitSalesData !== null &&
                                            cockpitSalesData.mid_sentiments
                                              ? cockpitSalesData.mid_sentiments.length
                                              : 0
                                          }
                                        />
                                      </div>
                                      <IconButton
                                        onClick={() => {
                                          setSalesIntentSentence(
                                            cockpitSalesData?.mid_sentiments.map(
                                              (element) => element.Content[0]
                                            )
                                          );
                                          setSalesIntentName('Warm Leads');
                                          handleSalesIntentDialog();
                                        }}
                                      >
                                        <Icon icon={eyeIcon} width={20} height={20} />
                                      </IconButton>

                                      <IconButton
                                        onClick={(event) => setAnchorE3(event.currentTarget)}
                                      >
                                        <Icon icon={moreVerticalFill} width={20} height={20} />
                                      </IconButton>
                                      {cockpitSalesData?.mid_sentiments.length > 0 ? (
                                        <Menu
                                          open={Boolean(anchorE3)}
                                          anchorEl={anchorE3}
                                          onClose={(event) => setAnchorE3(null)}
                                          PaperProps={{
                                            sx: {
                                              width: 200,
                                              maxWidth: '100%',
                                              maxHeight: 200,
                                              overflowY: 'auto'
                                            }
                                          }}
                                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                          }}
                                        >
                                          {cockpitSalesData?.mid_sentiments.map(
                                            (element, index) => (
                                              <MenuItem sx={{ color: 'text.secondary' }}>
                                                <audio
                                                  ref={snippetRef}
                                                  src={
                                                    process.env.REACT_APP_ON_PREMISE === 'true'
                                                      ? `${
                                                          process.env.REACT_APP_SERVER_MEDIA_URL
                                                        }${element.url.replace('/app', '')}`
                                                      : element.url
                                                  }
                                                  controls
                                                >
                                                  <track default kind="captions" srcLang="en" />
                                                </audio>
                                              </MenuItem>
                                            )
                                          )}
                                        </Menu>
                                      ) : null}
                                    </div>
                                  </Grid>
                                )}
                                {cockpitSalesData !== null && cockpitSalesData.low_sentiments && (
                                  <Grid item xs={12} style={{ marginTop: 10, padding: 5 }}>
                                    <span style={{ fontWeight: '400', fontSize: 14 }}>
                                      Cold Lead (
                                      {cockpitSalesData !== null && cockpitSalesData.low_sentiments
                                        ? cockpitSalesData.low_sentiments.length
                                        : 0}
                                      )
                                    </span>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <div style={{ width: '97%' }}>
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={
                                            cockpitSalesData !== null &&
                                            cockpitSalesData.low_sentiments
                                              ? cockpitSalesData.low_sentiments.length
                                              : 0
                                          }
                                        />
                                      </div>
                                      <IconButton
                                        onClick={() => {
                                          setSalesIntentSentence(
                                            cockpitSalesData?.low_sentiments.map(
                                              (element) => element.Content[0]
                                            )
                                          );
                                          setSalesIntentName('Cold Leads');
                                          handleSalesIntentDialog();
                                        }}
                                      >
                                        <Icon icon={eyeIcon} width={20} height={20} />
                                      </IconButton>

                                      <IconButton
                                        onClick={(event) => setAnchorE2(event.currentTarget)}
                                      >
                                        <Icon icon={moreVerticalFill} width={20} height={20} />
                                      </IconButton>
                                      {cockpitSalesData?.low_sentiments.length > 0 ? (
                                        <Menu
                                          open={Boolean(anchorE2)}
                                          anchorEl={anchorE2}
                                          onClose={(event) => setAnchorE2(null)}
                                          PaperProps={{
                                            sx: {
                                              width: 200,
                                              maxWidth: '100%',
                                              maxHeight: 200,
                                              overflowY: 'auto'
                                            }
                                          }}
                                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                          }}
                                        >
                                          {cockpitSalesData?.low_sentiments.map(
                                            (element, index) => (
                                              <MenuItem sx={{ color: 'text.secondary' }}>
                                                <audio
                                                  ref={snippetRef}
                                                  src={
                                                    process.env.REACT_APP_ON_PREMISE === 'true'
                                                      ? `${
                                                          process.env.REACT_APP_SERVER_MEDIA_URL
                                                        }${element.url.replace('/app', '')}`
                                                      : element.url
                                                  }
                                                  controls
                                                >
                                                  <track default kind="captions" srcLang="en" />
                                                </audio>
                                              </MenuItem>
                                            )
                                          )}
                                        </Menu>
                                      ) : null}
                                    </div>
                                  </Grid>
                                )}
                              </>
                            ) : (
                              <div
                                style={{
                                  width: '100%',
                                  height: 300,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <span style={{ fontSize: 20 }}>
                                  <b>No Data Available</b>
                                </span>
                              </div>
                            )}
                          </Card>
                        </Grid> */}
                        <Grid item xs={12} md={6}>
                          <h4 style={{ padding: '0.5rem' }}>Win Probablity</h4>
                          <Card>
                            {cockpitSalesData !== null &&
                            cockpitSalesData.probability_score &&
                            cockpitSalesData.probability_score.length !== 0 ? (
                              // salesProductInfo && salesProductInfo.overall_compliant_percentage
                              <CircularGauge
                                id="gauge1"
                                value={cockpitSalesData.probability_score.toFixed(2)}
                                // value={
                                //   salesProductInfo && salesProductInfo.overall_compliant_percentage
                                //     ? salesProductInfo?.overall_compliant_percentage
                                //     : 0
                                // }
                              >
                                <Scale startValue={0} endValue={100} tickInterval={20} />
                                <RangeContainer width={10}>
                                  <Range startValue={0} endValue={60} color="RGB(255 83 83)" />
                                  <Range startValue={60} endValue={80} color="RGB(255 250 107)" />
                                  <Range startValue={90} endValue={100} color="RGB(87 255 111)" />
                                  <Range startValue={80} endValue={90} color="RGB(40 255 111)" />
                                </RangeContainer>
                                <GaugeTooltip
                                  enabled
                                  cornerRadius={15}
                                  color="#cce6ff"
                                  border={{ visible: false }}
                                >
                                  <Font size={20} color="#3399ff" weight={500} />
                                </GaugeTooltip>
                              </CircularGauge>
                            ) : (
                              <div
                                style={{
                                  width: '100%',
                                  height: 300,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <span style={{ fontSize: 20 }}>
                                  <b>No Data Available</b>
                                </span>
                              </div>
                            )}
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <h4 style={{ padding: '0.5rem' }}>Lead Insights</h4>
                          {!cockpitSalesData?.product_info &&
                          !cockpitSalesData?.competitor_intent &&
                          !cockpitSalesData?.Buying_intent &&
                          !cockpitSalesData?.followup_intent ? (
                            <Card sx={{ height: 350 }}>
                              <div
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <span style={{ fontSize: 20 }}>
                                  <b>No Data Available</b>
                                </span>
                              </div>
                            </Card>
                          ) : (
                            <Card
                              sx={{
                                height: 350,
                                paddingLeft: 3,
                                paddingRight: 3
                              }}
                            >
                              <Grid item xs={12} style={{ marginTop: 8, padding: 5 }}>
                                {cockpitSalesData?.product_info ? (
                                  <>
                                    <span style={{ fontWeight: '400', fontSize: 14 }}>
                                      Product And Pricing (
                                      {cockpitSalesData !== null && cockpitSalesData.product_info
                                        ? cockpitSalesData.product_info
                                        : 0}
                                      )
                                    </span>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <div style={{ width: '97%' }}>
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={
                                            cockpitSalesData !== null &&
                                            cockpitSalesData.product_info
                                              ? cockpitSalesData.product_info
                                              : 0
                                          }
                                        />
                                      </div>

                                      <IconButton
                                        onClick={() => {
                                          setSalesIntentSentence(
                                            cockpitSalesData?.product_snippet.map(
                                              (element) => element.Content[0]
                                            )
                                          );
                                          setSalesIntentName('Product Information');
                                          handleSalesIntentDialog();
                                        }}
                                      >
                                        <Icon icon={eyeIcon} width={20} height={20} />
                                      </IconButton>

                                      {/* <>
                                        <IconButton
                                          onClick={(event) => setAnchorE3(event.currentTarget)}
                                        >
                                          <Icon icon={moreVerticalFill} width={20} height={20} />
                                        </IconButton>
                                        {cockpitSalesData?.product_snippet.length > 0 ? (
                                          <Menu
                                            open={Boolean(anchorE3)}
                                            anchorEl={anchorE3}
                                            onClose={(event) => setAnchorE3(null)}
                                            PaperProps={{
                                              sx: {
                                                width: 200,
                                                maxWidth: '100%',
                                                maxHeight: 200,
                                                overflowY: 'auto'
                                              }
                                            }}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'right'
                                            }}
                                          >
                                            {cockpitSalesData?.product_snippet.map(
                                              (element, index) => (
                                                <MenuItem sx={{ color: 'text.secondary' }}>
                                                  <audio
                                                    ref={snippetRef}
                                                    src={
                                                      process.env.REACT_APP_ON_PREMISE === 'true'
                                                        ? `${
                                                            process.env.REACT_APP_SERVER_MEDIA_URL
                                                          }${element.url.replace('/app', '')}`
                                                        : element.url
                                                    }
                                                    controls
                                                  >
                                                    <track default kind="captions" srcLang="en" />
                                                  </audio>
                                                </MenuItem>
                                              )
                                            )}
                                          </Menu>
                                        ) : null}
                                      </> */}
                                    </div>
                                  </>
                                ) : null}
                              </Grid>

                              <Grid item xs={12} style={{ marginTop: 8, padding: 5 }}>
                                {cockpitSalesData?.competitor_intent ? (
                                  <>
                                    <span style={{ fontWeight: '400', fontSize: 14 }}>
                                      Organisation and Person mention (
                                      {cockpitSalesData !== null &&
                                      cockpitSalesData.competitor_intent
                                        ? cockpitSalesData.competitor_intent
                                        : 0}
                                      )
                                    </span>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <div style={{ width: '97%' }}>
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={
                                            cockpitSalesData !== null &&
                                            cockpitSalesData.competitor_intent
                                              ? cockpitSalesData.competitor_intent
                                              : 0
                                          }
                                        />
                                      </div>
                                      <IconButton
                                        onClick={() => {
                                          setSalesIntentSentence(
                                            cockpitSalesData?.competitor_snippet.map(
                                              (element) => element.Content[0]
                                            )
                                          );
                                          setSalesIntentName('Competitor Information');
                                          handleSalesIntentDialog();
                                        }}
                                      >
                                        <Icon icon={eyeIcon} width={20} height={20} />
                                      </IconButton>
                                      {/* <>
                                        <IconButton
                                          onClick={(event) => setAnchorE4(event.currentTarget)}
                                        >
                                          <Icon icon={moreVerticalFill} width={20} height={20} />
                                        </IconButton>
                                        {cockpitSalesData?.competitor_snippet.length > 0 ? (
                                          <Menu
                                            open={Boolean(anchorE4)}
                                            anchorEl={anchorE4}
                                            onClose={(event) => setAnchorE4(null)}
                                            PaperProps={{
                                              sx: {
                                                width: 200,
                                                maxWidth: '100%',
                                                maxHeight: 200,
                                                overflowY: 'auto'
                                              }
                                            }}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'right'
                                            }}
                                          >
                                            {cockpitSalesData?.competitor_snippet.map(
                                              (element, index) => (
                                                <MenuItem sx={{ color: 'text.secondary' }}>
                                                  <audio
                                                    ref={snippetRef}
                                                    src={
                                                      process.env.REACT_APP_ON_PREMISE === 'true'
                                                        ? `${
                                                            process.env.REACT_APP_SERVER_MEDIA_URL
                                                          }${element.url.replace('/app', '')}`
                                                        : element.url
                                                    }
                                                    controls
                                                  >
                                                    <track default kind="captions" srcLang="en" />
                                                  </audio>
                                                </MenuItem>
                                              )
                                            )}
                                          </Menu>
                                        ) : null}
                                      </> */}
                                    </div>
                                  </>
                                ) : null}
                              </Grid>

                              <Grid item xs={12} style={{ marginTop: 8, padding: 5 }}>
                                {cockpitSalesData?.Buying_intent ? (
                                  <>
                                    <span style={{ fontWeight: '400', fontSize: 14 }}>
                                      Buying Intent (
                                      {cockpitSalesData.Buying_intent
                                        ? cockpitSalesData.Buying_intent
                                        : 0}
                                      )
                                    </span>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <div style={{ width: '97%' }}>
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={
                                            cockpitSalesData.Buying_intent
                                              ? cockpitSalesData.Buying_intent
                                              : 0
                                          }
                                        />
                                      </div>
                                      <IconButton
                                        onClick={() => {
                                          setSalesIntentSentence(
                                            cockpitSalesData?.buying_snippet.map(
                                              (element) => element.Content[0]
                                            )
                                          );
                                          setSalesIntentName('Buying Information');
                                          handleSalesIntentDialog();
                                        }}
                                      >
                                        <Icon icon={eyeIcon} width={20} height={20} />
                                      </IconButton>
                                      {/* <>
                                        <IconButton
                                          onClick={(event) => setAnchorE6(event.currentTarget)}
                                        >
                                          <Icon icon={moreVerticalFill} width={20} height={20} />
                                        </IconButton>

                                        {cockpitSalesData.buying_snippet ? (
                                          <Menu
                                            open={Boolean(anchorE6)}
                                            anchorEl={anchorE6}
                                            onClose={(event) => setAnchorE6(null)}
                                            PaperProps={{
                                              sx: {
                                                width: 200,
                                                maxWidth: '100%',
                                                maxHeight: 200,
                                                overflowY: 'auto'
                                              }
                                            }}
                                            anchorOrigin={{
                                              vertical: 'top',
                                              horizontal: 'right'
                                            }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'right'
                                            }}
                                          >
                                            {cockpitSalesData.buying_snippet.map(
                                              (element, index) => (
                                                <MenuItem sx={{ color: 'text.secondary' }}>
                                                  <audio
                                                    ref={snippetRef}
                                                    src={
                                                      process.env.REACT_APP_ON_PREMISE === 'true'
                                                        ? `${
                                                            process.env.REACT_APP_SERVER_MEDIA_URL
                                                          }${element.url.replace('/app', '')}`
                                                        : element.url
                                                    }
                                                    controls
                                                  >
                                                    <track default kind="captions" srcLang="en" />
                                                  </audio>
                                                </MenuItem>
                                              )
                                            )}
                                          </Menu>
                                        ) : null}
                                      </> */}
                                    </div>
                                  </>
                                ) : null}
                              </Grid>

                              <Grid item xs={12} style={{ marginTop: 8, padding: 5 }}>
                                {cockpitSalesData?.followup_intent ? (
                                  <>
                                    <span style={{ fontWeight: '400', fontSize: 14 }}>
                                      Follow ups (
                                      {cockpitSalesData.followup_intent
                                        ? cockpitSalesData.followup_intent
                                        : 0}
                                      )
                                    </span>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <div style={{ width: '97%' }}>
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={
                                            cockpitSalesData.followup_intent
                                              ? cockpitSalesData.followup_intent
                                              : 0
                                          }
                                        />
                                      </div>
                                      <IconButton
                                        onClick={() => {
                                          setSalesIntentSentence(
                                            cockpitSalesData?.followup_snippet.map(
                                              (element) => element.Content[0]
                                            )
                                          );
                                          setSalesIntentName('Followup Information');
                                          handleSalesIntentDialog();
                                        }}
                                      >
                                        <Icon icon={eyeIcon} width={20} height={20} />
                                      </IconButton>
                                      {/* <>
                                        <IconButton
                                          onClick={(event) => setAnchorE5(event.currentTarget)}
                                        >
                                          <Icon icon={moreVerticalFill} width={20} height={20} />
                                        </IconButton>
                                        {cockpitSalesData.followup_snippet ? (
                                          <Menu
                                            open={Boolean(anchorE5)}
                                            anchorEl={anchorE5}
                                            onClose={(event) => setAnchorE5(null)}
                                            PaperProps={{
                                              sx: {
                                                width: 200,
                                                maxWidth: '100%',
                                                maxHeight: 200,
                                                overflowY: 'auto'
                                              }
                                            }}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'right'
                                            }}
                                          >
                                            {cockpitSalesData.followup_snippet.map(
                                              (element, index) => (
                                                <MenuItem sx={{ color: 'text.secondary' }}>
                                                  <audio
                                                    ref={snippetRef}
                                                    src={
                                                      process.env.REACT_APP_ON_PREMISE === 'true'
                                                        ? `${
                                                            process.env.REACT_APP_SERVER_MEDIA_URL
                                                          }${element.url.replace('/app', '')}`
                                                        : element.url
                                                    }
                                                    controls
                                                  >
                                                    <track default kind="captions" srcLang="en" />
                                                  </audio>
                                                </MenuItem>
                                              )
                                            )}
                                          </Menu>
                                        ) : null}
                                      </> */}
                                    </div>
                                  </>
                                ) : null}
                              </Grid>

                              <CustomFormModel
                                fullScreen={fullScreen}
                                handleDialog={handleSalesIntentDialog}
                                open={salesIntentOpen}
                                title={`${salesIntentName}`}
                              >
                                <Paperbox
                                  // elevation={16}
                                  style={{
                                    marginTop: 10,
                                    padding: '2rem',
                                    height: 360,
                                    overflow: 'auto',
                                    backgroundColor: '#fafafa'
                                  }}
                                >
                                  <TableContainer>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Sr.No</TableCell>
                                          <TableCell>Phrase</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {salesIntentSentence.map((el, index) => (
                                          <TableRow>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{el}</TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Paperbox>
                              </CustomFormModel>
                            </Card>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </TabPanel>

                <TabPanel value={value} index={10}>
                  {userDetails.role === 'Guest' ? (
                    <Card
                      style={{
                        minHeight: 300,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: '#2a2c310d'
                      }}
                    >
                      {isPlanUpgraded ? (
                        <>
                          <span
                            style={{
                              fontWeight: '700',
                              fontSize: 20,
                              marginTop: 5,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <VerifiedIcon
                              style={{
                                color: '#0ecf0e',
                                marginRight: 5,
                                fontSize: 25
                              }}
                            />{' '}
                            Thank you for your interest in upgrading. Our team will contact you
                            soon.
                          </span>
                        </>
                      ) : (
                        <>
                          {' '}
                          <LockIcon
                            style={{ height: 40, width: 40, color: '#A8A8A8', marginBottom: 5 }}
                          />
                          <span style={{ fontWeight: '700', fontSize: 20, marginTop: 5 }}>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                }
                              }}
                              // size="small"
                              onClick={(e) => setUpgradeModal(true)}
                              // style={{ marginRight: 5 }}
                            >
                              Upgrade
                            </Button>{' '}
                            to premium plan to unlock this functionality.
                          </span>
                        </>
                      )}
                    </Card>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <h4 style={{ padding: '0.5rem' }}>Feedback</h4>
                        <Card>
                          {feedback && feedback.length > 0 ? (
                            <Paperbox sx={{ width: '100%' }}>
                              <TableContainer sx={{ maxHeight: 300, overflowY: 'auto' }}>
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell style={{ fontWeight: '600' }}>Sr.No</TableCell>
                                      <TableCell style={{ fontWeight: '600' }} align="center">
                                        Feedback
                                      </TableCell>
                                      <TableCell style={{ fontWeight: '600' }} align="center">
                                        Rating
                                      </TableCell>
                                      <TableCell style={{ fontWeight: '600' }} align="center">
                                        Action
                                      </TableCell>
                                      <TableCell style={{ fontWeight: '600' }} align="center">
                                        Reason
                                      </TableCell>
                                      {userDetails.role !== 'Agent' && (
                                        <TableCell style={{ fontWeight: '600' }} align="center" />
                                      )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {feedback.map((el, index) => (
                                      <TableRow>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="normal"
                                          style={{
                                            whiteSpace: 'nowrap',
                                            maxWidth: '80px', // Adjust the max width as needed
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis' // Add ellipsis for truncation
                                          }}
                                          align="center"
                                          title={el.feedback}
                                        >
                                          {el.feedback ? el.feedback : ''}
                                        </TableCell>
                                        {/* <TableCell align="center">
                                          {el.feedback ? el.feedback : ''}
                                        </TableCell> */}
                                        <TableCell align="center">
                                          {el.rating ? el.rating : ''}
                                        </TableCell>
                                        <TableCell align="center">
                                          {el.agent_action ? el.agent_action : '-'}
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="normal"
                                          style={{
                                            whiteSpace: 'nowrap',
                                            maxWidth: '80px', // Adjust the max width as needed
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis' // Add ellipsis for truncation
                                          }}
                                          align="center"
                                          title={el.reason}
                                        >
                                          {el.reason ? el.reason : '-'}
                                        </TableCell>

                                        {userDetails.role !== 'Agent' && (
                                          <TableCell align="right">
                                            <FeedbackEdit
                                              setFeedback={setFeedback}
                                              feedback={feedback}
                                              Details={el}
                                              inputId={dataForCockpit[0]}
                                              setUpdateFeedback={setUpdateFeedback}
                                              updateFeedback={updateFeedback}
                                            />
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Paperbox>
                          ) : (
                            <div
                              style={{
                                width: '100%',
                                height: 300,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              {/* <img src={TreeMap} width={150} height={150} alt="no contracts" /> */}
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available</b>
                              </span>
                            </div>
                          )}
                        </Card>
                      </Grid>
                    </Grid>
                  )}
                </TabPanel>

                <TabPanel value={value} index={11}>
                  {userDetails.role === 'Guest' ? (
                    <Card
                      style={{
                        minHeight: 300,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: '#2a2c310d'
                      }}
                    >
                      {isPlanUpgraded ? (
                        <>
                          <span
                            style={{
                              fontWeight: '700',
                              fontSize: 20,
                              marginTop: 5,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <VerifiedIcon
                              style={{
                                color: '#0ecf0e',
                                marginRight: 5,
                                fontSize: 25
                              }}
                            />{' '}
                            Thank you for your interest in upgrading. Our team will contact you
                            soon.
                          </span>
                        </>
                      ) : (
                        <>
                          {' '}
                          <LockIcon
                            style={{ height: 40, width: 40, color: '#A8A8A8', marginBottom: 5 }}
                          />
                          <span style={{ fontWeight: '700', fontSize: 20, marginTop: 5 }}>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                }
                              }}
                              // size="small"
                              onClick={(e) => setUpgradeModal(true)}
                              // style={{ marginRight: 5 }}
                            >
                              Upgrade
                            </Button>{' '}
                            to premium plan to unlock this functionality.
                          </span>
                        </>
                      )}
                    </Card>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <h4 style={{ padding: '0.5rem' }}>Task Status Table</h4>
                        <Card>
                          {agentTaskList.length > 0 ? (
                            <Paperbox sx={{ width: '100%' }}>
                              <TableContainer sx={{ maxHeight: 345, overflowY: 'auto' }}>
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell style={{ fontWeight: '600' }}>Agent</TableCell>
                                      <TableCell style={{ fontWeight: '600' }}>Deal Name</TableCell>
                                      <TableCell style={{ fontWeight: '600' }}>
                                        Deal Stage
                                      </TableCell>
                                      <TableCell style={{ fontWeight: '600' }}>Task Name</TableCell>
                                      <TableCell style={{ fontWeight: '600' }}>
                                        Created Date
                                      </TableCell>
                                      <TableCell style={{ fontWeight: '600' }}>
                                        Task Status
                                      </TableCell>
                                      <TableCell style={{ fontWeight: '600' }}>Aging</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody style={{}}>
                                    {agentTaskList.map((res) => {
                                      console.log('agentTaskList innn', res);
                                      return (
                                        <TableRow>
                                          <TableCell>{res.agent_name}</TableCell>
                                          <TableCell>{res.deal_name}</TableCell>
                                          <TableCell>{res.deal_stage}</TableCell>
                                          <TableCell>{res.task_name}</TableCell>
                                          <TableCell>
                                            {moment(res.created_date).format('YYYY-MM-DD')}
                                          </TableCell>
                                          <TableCell>{res.task_status}</TableCell>
                                          <TableCell>
                                            {res.aging === 'no aging'
                                              ? '-'
                                              : `${Math.round(res.aging)} hrs`}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Paperbox>
                          ) : (
                            <div
                              style={{
                                width: '100%',
                                height: 400,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              {/* <img src={TreeMap} width={150} height={150} alt="no contracts" /> */}
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available</b>
                              </span>
                            </div>
                          )}
                        </Card>
                      </Grid>
                    </Grid>
                  )}
                </TabPanel>

                <TabPanel value={value} index={12}>
                  {userDetails.role === 'Guest' ? (
                    <Card
                      style={{
                        minHeight: 300,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: '#2a2c310d'
                      }}
                    >
                      {isPlanUpgraded ? (
                        <>
                          <span
                            style={{
                              fontWeight: '700',
                              fontSize: 20,
                              marginTop: 5,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <VerifiedIcon
                              style={{
                                color: '#0ecf0e',
                                marginRight: 5,
                                fontSize: 25
                              }}
                            />{' '}
                            Thank you for your interest in upgrading. Our team will contact you
                            soon.
                          </span>
                        </>
                      ) : (
                        <>
                          {' '}
                          <LockIcon
                            style={{ height: 40, width: 40, color: '#A8A8A8', marginBottom: 5 }}
                          />
                          <span style={{ fontWeight: '700', fontSize: 20, marginTop: 5 }}>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                }
                              }}
                              // size="small"
                              onClick={(e) => setUpgradeModal(true)}
                              // style={{ marginRight: 5 }}
                            >
                              Upgrade
                            </Button>{' '}
                            to premium plan to unlock this functionality.
                          </span>
                        </>
                      )}
                    </Card>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        {/* <h4 style={{ padding: '0.5rem' }}>Categories</h4>
                      <Card style={{ padding: 20, minHeight: 300 }}> */}
                        <Grid item xs={12}>
                          {keywordTrackData?.agent_keywords &&
                            keywordTrackData?.customer_keywords &&
                            Object.keys(keywordTrackData?.agent_keywords).length > 0 && (
                              <Tabs
                                value={activeTab}
                                onChange={handleTabChange}
                                variant="scrollable"
                                className={classes.root}
                              >
                                {[
                                  ...new Set([
                                    ...Object.keys(keywordTrackData?.agent_keywords),
                                    ...Object.keys(keywordTrackData?.customer_keywords)
                                  ])
                                ].map((ele, index) => (
                                  <Tab
                                    label={ele}
                                    sx={{
                                      borderRight:
                                        index !==
                                        [
                                          ...new Set([
                                            ...Object.keys(keywordTrackData?.agent_keywords),
                                            ...Object.keys(keywordTrackData?.customer_keywords)
                                          ])
                                        ].length -
                                          1
                                          ? '1px solid gray'
                                          : 'none',

                                      backgroundColor: activeTab === index ? '#eef7ff' : 'inherit'
                                    }}
                                    className={classes.tab}
                                  />
                                ))}
                              </Tabs>
                            )}
                        </Grid>
                        {keywordTrackData ? (
                          <Grid container xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Grid item xs={12} md={6}>
                              <Typography
                                // onClick={() => handleCustomerDialog()}
                                style={{
                                  width: '234'
                                  // , cursor: 'pointer'
                                }}
                              >
                                <h4 style={{ padding: '0.5rem' }}>Agent Category</h4>
                              </Typography>
                              {Object.getOwnPropertyNames(keywordTrackData?.agent_keywords)
                                .length !== 0 ? (
                                <Keywords
                                  data={
                                    keywordTrackData?.agent_keywords[
                                      Object.keys(keywordTrackData?.agent_keywords)[activeTab]
                                    ]
                                  }
                                />
                              ) : (
                                <div
                                  style={{
                                    width: '90%',
                                    height: 400,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                >
                                  {/* <img src={TreeMap} width={150} height={150} alt="no contracts" /> */}
                                  <span style={{ fontSize: 20 }}>
                                    <b>No Data Available</b>
                                  </span>
                                </div>
                              )}
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Typography
                                // onClick={() => handleDialog()}
                                style={{
                                  width: '198'
                                  // , cursor: 'pointer'
                                }}
                              >
                                <h4 style={{ padding: '0.5rem' }}>Customer Category</h4>
                              </Typography>
                              {Object.getOwnPropertyNames(keywordTrackData?.customer_keywords)
                                .length !== 0 ? (
                                <Phrases
                                  data={
                                    keywordTrackData?.customer_keywords[
                                      Object.keys(keywordTrackData?.customer_keywords)[activeTab]
                                    ]
                                  }
                                />
                              ) : (
                                <div
                                  style={{
                                    width: '90%',
                                    height: 400,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                >
                                  {/* <img src={TreeMap} width={150} height={150} alt="no contracts" /> */}
                                  <span style={{ fontSize: 20 }}>
                                    <b>No Data Available</b>
                                  </span>
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        ) : (
                          <div
                            style={{
                              width: '100%',
                              height: 300,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        )}
                        {/* </Card> */}
                      </Grid>
                    </Grid>
                  )}
                </TabPanel>
                <TabPanel value={value} index={18}>
                  {userDetails.role === 'Guest' ? (
                    <Card
                      style={{
                        minHeight: 300,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: '#2a2c310d'
                      }}
                    >
                      {isPlanUpgraded ? (
                        <>
                          <span
                            style={{
                              fontWeight: '700',
                              fontSize: 20,
                              marginTop: 5,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <VerifiedIcon
                              style={{
                                color: '#0ecf0e',
                                marginRight: 5,
                                fontSize: 25
                              }}
                            />{' '}
                            Thank you for your interest in upgrading. Our team will contact you
                            soon.
                          </span>
                        </>
                      ) : (
                        <>
                          {' '}
                          <LockIcon
                            style={{ height: 40, width: 40, color: '#A8A8A8', marginBottom: 5 }}
                          />
                          <span style={{ fontWeight: '700', fontSize: 20, marginTop: 5 }}>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                }
                              }}
                              // size="small"
                              onClick={(e) => setUpgradeModal(true)}
                              // style={{ marginRight: 5 }}
                            >
                              Upgrade
                            </Button>{' '}
                            to premium plan to unlock this functionality.
                          </span>
                        </>
                      )}
                    </Card>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Grid item xs={12}>
                          {collectionData?.agent_phrases &&
                            Object.keys(collectionData?.agent_phrases).length > 0 && (
                              <Tabs
                                value={activeTab}
                                onChange={handleTabChange}
                                variant="scrollable"
                                className={classes.root}
                              >
                                {Object.keys(collectionData?.agent_phrases)
                                  .filter((cat) => collectionData?.agent_phrases[cat].length > 0)
                                  .map((ele, index) => (
                                    <Tab
                                      label={ele}
                                      sx={{
                                        borderRight:
                                          index !==
                                          Object.keys(collectionData?.agent_phrases).length - 1
                                            ? '1px solid gray'
                                            : 'none',

                                        backgroundColor: activeTab === index ? '#eef7ff' : 'inherit'
                                      }}
                                      className={classes.tab}
                                    />
                                  ))}
                              </Tabs>
                            )}
                        </Grid>
                        {collectionData ? (
                          <Grid container xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Grid item xs={12} md={6}>
                              <Typography
                                // onClick={() => handleCustomerDialog()}
                                style={{
                                  width: '234'
                                  // , cursor: 'pointer'
                                }}
                              >
                                <h4 style={{ padding: '0.5rem' }}>Phrases</h4>
                              </Typography>
                              {Object.getOwnPropertyNames(collectionData?.agent_phrases).length !==
                              0 ? (
                                <Phrases
                                  data={
                                    collectionData?.agent_phrases[
                                      Object.keys(collectionData?.agent_phrases).filter(
                                        (cat) => collectionData?.agent_phrases[cat].length > 0
                                      )[activeTab]
                                    ]
                                  }
                                />
                              ) : (
                                <Card
                                  style={{
                                    width: '90%',
                                    height: 400,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                >
                                  {/* <img src={TreeMap} width={150} height={150} alt="no contracts" /> */}
                                  <span style={{ fontSize: 20 }}>
                                    <b>No Data Available</b>
                                  </span>
                                </Card>
                              )}
                            </Grid>
                          </Grid>
                        ) : (
                          <div
                            style={{
                              width: '100%',
                              height: 300,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span style={{ fontSize: 20 }}>
                              <b>No Data Available</b>
                            </span>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </TabPanel>
                <TabPanel value={value} index={13}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Card
                        style={{
                          minHeight: 300,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          backgroundColor: '#2a2c310d'
                        }}
                      >
                        {isPlanUpgraded ? (
                          <>
                            <span
                              style={{
                                fontWeight: '700',
                                fontSize: 20,
                                marginTop: 5,
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <VerifiedIcon
                                style={{
                                  color: '#0ecf0e',
                                  marginRight: 5,
                                  fontSize: 25
                                }}
                              />{' '}
                              Thank you for your interest in upgrading. Our team will contact you
                              soon.
                            </span>
                          </>
                        ) : (
                          <>
                            {' '}
                            <LockIcon
                              style={{ height: 40, width: 40, color: '#A8A8A8', marginBottom: 5 }}
                            />
                            <span style={{ fontWeight: '700', fontSize: 20, marginTop: 5 }}>
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: '#3892CF',
                                  '&:hover': {
                                    backgroundColor: '#3892CF'
                                  }
                                }}
                                // size="small"
                                onClick={(e) => setUpgradeModal(true)}
                                // style={{ marginRight: 5 }}
                              >
                                Upgrade
                              </Button>{' '}
                              to premium plan to unlock this functionality.
                            </span>
                          </>
                        )}
                      </Card>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={14}>
                  {userDetails.role === 'Guest' ? (
                    <Card
                      style={{
                        minHeight: 300,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: '#2a2c310d'
                      }}
                    >
                      {isPlanUpgraded ? (
                        <>
                          <span
                            style={{
                              fontWeight: '700',
                              fontSize: 20,
                              marginTop: 5,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <VerifiedIcon
                              style={{
                                color: '#0ecf0e',
                                marginRight: 5,
                                fontSize: 25
                              }}
                            />{' '}
                            Thank you for your interest in upgrading. Our team will contact you
                            soon.
                          </span>
                        </>
                      ) : (
                        <>
                          <LockIcon
                            style={{ height: 40, width: 40, color: '#A8A8A8', marginBottom: 5 }}
                          />
                          <span style={{ fontWeight: '700', fontSize: 20, marginTop: 5 }}>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                }
                              }}
                              // size="small"
                              onClick={(e) => setUpgradeModal(true)}
                              // style={{ marginRight: 5 }}
                            >
                              Upgrade
                            </Button>{' '}
                            to premium plan to unlock this functionality.
                          </span>
                        </>
                      )}
                    </Card>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: 5
                          }}
                        >
                          <h4 style={{ padding: '0.5rem' }}>
                            {scoreCardData?.flag === 'Edited'
                              ? 'Updated Score Card'
                              : 'AI Score Card'}
                          </h4>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row'
                            }}
                          >
                            {/* {downloadStart ? (
                              <LoadingButton
                                loading
                                style={{
                                  backgroundColor: '#fffff',
                                  '&:hover': {
                                    backgroundColor: '#fffff'
                                  }
                                }}
                              />
                            ) : ( */}

                            {/* <IconButton
                                onClick={handleDownloadCsv}
                                // disabled={!agentCallDetails}
                                style={{ backgroundColor: '#039452' }}
                              >
                                <Icon icon={fileDownload} color="#ffff" width={30} height={30} />
                              </IconButton> */}
                            <>
                              <IconButton
                                ref={downloadRef}
                                title="Download Options"
                                onClick={() => setDownloadMenuOpen(!downloadOpen)}
                                style={{ backgroundColor: '#039452' }}
                              >
                                <Icon icon={fileDownload} color="#ffff" width={30} height={30} />
                              </IconButton>

                              <Menu
                                open={downloadOpen}
                                anchorEl={downloadRef.current}
                                onClose={() => setDownloadMenuOpen(false)}
                                PaperProps={{
                                  sx: { width: 150, maxWidth: '100%' }
                                }}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                              >
                                <MenuItem
                                  value="both"
                                  // selected={playbackSpeed === 'both'}
                                  onClick={() => {
                                    handleDownloadCsv('both');
                                    setDownloadMenuOpen(false);
                                  }}
                                >
                                  CSV for Both
                                </MenuItem>
                                <MenuItem
                                  value="ai"
                                  // selected={playbackSpeed === 'ai'}
                                  onClick={() => {
                                    handleDownloadCsv('ai');
                                    setDownloadMenuOpen(false);
                                  }}
                                >
                                  CSV for AI
                                </MenuItem>
                                <MenuItem
                                  value="manual"
                                  onClick={() => {
                                    handleDownloadCsv('manual');
                                    setDownloadMenuOpen(false);
                                  }}
                                >
                                  CSV for Manual
                                </MenuItem>
                              </Menu>
                            </>

                            {/* )} */}

                            {userDetails.role !== 'Agent' && (
                              <Button
                                variant="contained"
                                startIcon={<PlusIcon />}
                                style={{
                                  marginLeft: '10px',
                                  backgroundColor:
                                    userDetails.role === 'Guest' ||
                                    !dashboardServices?.includes('compliance')
                                      ? '#E0E0E0'
                                      : '#3892CF',
                                  '&:hover': {
                                    backgroundColor:
                                      userDetails.role === 'Guest' ||
                                      !dashboardServices?.includes('compliance')
                                        ? '#E0E0E0'
                                        : '#3892CF'
                                  }
                                }}
                                size="small"
                                onClick={handleScoreFormDialog}
                                disabled={
                                  userDetails.role === 'Guest' ||
                                  !dashboardServices?.includes('compliance')
                                }
                              >
                                Agent & Call Details
                              </Button>
                            )}
                          </div>
                        </div>
                        {scoreCardData ? (
                          <Card>
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  height: 400,
                                  overflowY: 'auto'
                                }}
                              >
                                <TableContainer>
                                  <Table stickyHeader aria-label="sticky table">
                                    <>
                                      <TableHead sx={{ backgroundColor: 'RGB(250 250 250)' }}>
                                        <TableRow>
                                          <TableCell>
                                            <b>Sr.no</b>
                                          </TableCell>
                                          <TableCell>
                                            <b>Parameters</b>
                                          </TableCell>
                                          <TableCell>
                                            <b>Category</b>
                                          </TableCell>
                                          <TableCell>
                                            <b>Phrases</b>
                                          </TableCell>
                                          {/* <TableCell>
                                            <b>Matched Phrases</b>
                                          </TableCell> */}
                                          <TableCell align="center">
                                            <b>AI Score</b>
                                          </TableCell>
                                          <TableCell align="center">
                                            <b>Review Score</b>
                                          </TableCell>
                                          <TableCell align="center">
                                            <b>Remarks</b>
                                          </TableCell>
                                          <TableCell align="center">
                                            <b>Missing Phrases</b>
                                          </TableCell>
                                          {training_call_toggle && (
                                            <TableCell align="center">
                                              <b>Training Call</b>
                                            </TableCell>
                                          )}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {scoreCardData &&
                                          scoreCardData.ai_scoring_details.map((el, index) => (
                                            <TableRow
                                              key={index}
                                              sx={{
                                                backgroundColor:
                                                  scoreValues[index]?.score === 'F'
                                                    ? '#FF7683'
                                                    : 'white'
                                              }}
                                            >
                                              <TableCell>{index + 1}</TableCell>
                                              <TableCell>
                                                {el.Category?.length > 15 ? (
                                                  <Tip
                                                    title={el.Category}
                                                    followCursor
                                                    placement="top"
                                                  >
                                                    <p>{el.Category.slice(0, 15)}...</p>
                                                  </Tip>
                                                ) : (
                                                  <p>{el.Category}</p>
                                                )}
                                              </TableCell>
                                              <TableCell>{el.Parameter}</TableCell>
                                              <TableCell align="left">
                                                <>
                                                  <Tip
                                                    title={`${categoryQuestion
                                                      ?.join()
                                                      .slice(0, 25)}...`}
                                                    placement="top"
                                                  >
                                                    <IconButton
                                                      onMouseOver={() =>
                                                        setCategoryQuestion(el.description)
                                                      }
                                                      onClick={(event) => {
                                                        setParameterName(el.Category);
                                                        handleScriptDialog();
                                                      }}
                                                    >
                                                      <Icon icon={eyeIcon} width={20} height={20} />
                                                    </IconButton>
                                                  </Tip>
                                                </>
                                              </TableCell>
                                              {/* <TableCell align="center">
                                                <>
                                                  {(complianceInfo &&
                                                    Object.keys(complianceInfo.compliant_info)
                                                      .length > 0 &&
                                                    Object.keys(
                                                      complianceInfo.compliant_info.Categories
                                                    ).length > 0 &&
                                                    complianceInfo?.compliant_info.Categories[
                                                      el.Category
                                                    ].length > 0) ||
                                                  (complianceInfo &&
                                                    Object.keys(complianceInfo.non_compliant_info)
                                                      .length > 0 &&
                                                    Object.keys(
                                                      complianceInfo.non_compliant_info.Categories
                                                    ).length > 0 &&
                                                    complianceInfo?.non_compliant_info.Categories[
                                                      el.Category
                                                    ].length > 0) ? (
                                                    <IconButton
                                                      onClick={() => {
                                                        if (
                                                          complianceInfo &&
                                                          Object.keys(complianceInfo.compliant_info)
                                                            .length > 0 &&
                                                          Object.keys(
                                                            complianceInfo.compliant_info.Categories
                                                          ).length > 0
                                                        ) {
                                                          const array = [];
                                                          complianceInfo?.compliant_info.Categories[
                                                            el.Category
                                                          ].map((el) => array.push(el.Content[0]));
                                                          console.log('matchedPhrases', array);
                                                          setCategoryQuestion(array);
                                                          handleScriptDialog();
                                                        } else if (
                                                          complianceInfo &&
                                                          Object.keys(
                                                            complianceInfo.non_compliant_info
                                                          ).length > 0 &&
                                                          Object.keys(
                                                            complianceInfo.non_compliant_info
                                                              .Categories
                                                          ).length > 0
                                                        ) {
                                                          const array = [];
                                                          complianceInfo?.non_compliant_info.Categories[
                                                            el.Category
                                                          ].map((el) => array.push(el.Content[0]));
                                                          setCategoryQuestion(array);
                                                          console.log('matchedPhrases', array);
                                                          handleScriptDialog();
                                                        }
                                                      }}
                                                    >
                                                      <Icon icon={eyeIcon} width={20} height={20} />
                                                    </IconButton>
                                                  ) : (
                                                    '-'
                                                  )}
                                                </>
                                              </TableCell> */}

                                              {el.Parameter === 'Soft Skill' && (
                                                <TableCell>
                                                  <FormControl style={{ minWidth: 120 }}>
                                                    <InputLabel
                                                      required
                                                      id="Organisation-select-label"
                                                    >
                                                      Score
                                                    </InputLabel>
                                                    <Select
                                                      required
                                                      labelId="Organisation-select-label"
                                                      label="Score"
                                                      id="Organisation-select-label"
                                                      // value="1"
                                                      disabled
                                                      MenuProps={{
                                                        classes: { paper: classes.menuScoreCard }
                                                      }}
                                                      defaultValue={
                                                        el.aiMode === true
                                                          ? el.score
                                                            ? el.score
                                                            : 0
                                                          : 'NA'
                                                      }
                                                      // onChange={(e) => {
                                                      //   setRatingValue(e.target.value);
                                                      //   handleScoreChange(index, e.target.value);
                                                      // }}
                                                    >
                                                      {generateMenuItems(el?.weightage)}
                                                      <MenuItem value="NA">NA</MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                </TableCell>
                                              )}
                                              {el.Parameter === 'Critical Skill' && (
                                                <TableCell>
                                                  <FormControl style={{ minWidth: 120 }}>
                                                    <InputLabel
                                                      required
                                                      id="Organisation-select-label"
                                                    >
                                                      Score
                                                    </InputLabel>
                                                    <Select
                                                      required
                                                      labelId="Organisation-select-label"
                                                      label="Score"
                                                      id="Organisation-select-label"
                                                      // value="1"
                                                      disabled
                                                      MenuProps={{
                                                        classes: { paper: classes.menuScoreCard }
                                                      }}
                                                      defaultValue={
                                                        el.aiMode === true
                                                          ? el.score
                                                            ? el.score
                                                            : 0
                                                          : 'NA'
                                                      }
                                                      // onChange={(e) => {
                                                      //   setRatingValue(e.target.value);
                                                      //   handleScoreChange(index, e.target.value);
                                                      // }}
                                                    >
                                                      <MenuItem value="F">Fatal</MenuItem>
                                                      <MenuItem value="NF">Non Fatal</MenuItem>
                                                      {generateMenuItems(el?.weightage)}
                                                      <MenuItem value="NA">NA</MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                </TableCell>
                                              )}
                                              <TableCell>
                                                <FormControl style={{ minWidth: 120 }}>
                                                  <InputLabel
                                                    required
                                                    id="Organisation-select-label"
                                                  >
                                                    Score
                                                  </InputLabel>
                                                  <Select
                                                    required
                                                    labelId="Rating-select-label"
                                                    label="Score"
                                                    id="select-rating"
                                                    disabled={userDetails.role === 'Agent'}
                                                    value={
                                                      scoreValues.length > 0 &&
                                                      scoreValues[index]?.score !== 'undefined'
                                                        ? scoreValues[index]?.score
                                                          ? scoreValues[index]?.score
                                                          : 0
                                                        : 'NA'
                                                    }
                                                    MenuProps={{
                                                      classes: { paper: classes.menuScoreCard }
                                                    }}
                                                    // defaultValue={el?.score}
                                                    onChange={(e) => {
                                                      // setRatingValue(e.target.value);
                                                      handleScoreChange(index, e.target.value);
                                                    }}
                                                  >
                                                    {el.Parameter === 'Critical Skill' && (
                                                      <MenuItem value="F">Fatal</MenuItem>
                                                    )}
                                                    {el.Parameter === 'Critical Skill' && (
                                                      <MenuItem value="NF">Non Fatal</MenuItem>
                                                    )}
                                                    {generateMenuItems(el?.weightage)}
                                                    <MenuItem value="NA">NA</MenuItem>
                                                  </Select>
                                                </FormControl>
                                              </TableCell>
                                              <TableCell align="center">
                                                {userDetails.role !== 'Agent' ? (
                                                  <FormControl style={{ minWidth: 120 }}>
                                                    <TextField
                                                      // label="Remarks"
                                                      // key={index}
                                                      value={
                                                        scoreValues.length > 0 &&
                                                        scoreValues[index]?.remark !== 'undefined'
                                                          ? scoreValues[index]?.remark
                                                          : ''
                                                      }
                                                      multiline
                                                      rows={1}
                                                      defaultValue={el?.remark}
                                                      onChange={(e) => {
                                                        // setRemarkValue(e.target.value);
                                                        handleRemarkChange(index, e.target.value);
                                                      }}
                                                    />
                                                  </FormControl>
                                                ) : userDetails.role === 'Agent' &&
                                                  scoreValues[index]?.remark.length > 0 ? (
                                                  <FormControl style={{ minWidth: 120 }}>
                                                    <TextField
                                                      // label="Remarks"
                                                      // key={index}
                                                      value={
                                                        scoreValues.length > 0 &&
                                                        scoreValues[index]?.remark !== 'undefined'
                                                          ? scoreValues[index]?.remark
                                                          : ''
                                                      }
                                                      multiline
                                                      rows={1}
                                                      disabled
                                                      defaultValue={el?.remark}
                                                      onChange={(e) => {
                                                        // setRemarkValue(e.target.value);
                                                        handleRemarkChange(index, e.target.value);
                                                      }}
                                                    />
                                                  </FormControl>
                                                ) : (
                                                  '-'
                                                )}
                                              </TableCell>
                                              <TableCell align="center">
                                                {/* {userDetails.role !== 'Agent' ? ( */}
                                                <FormControl style={{ minWidth: 160 }}>
                                                  <TextField
                                                    // label="Remarks"
                                                    // key={index}
                                                    value={
                                                      scoreValues.length > 0 &&
                                                      scoreValues[index]?.phrases !== 'undefined'
                                                        ? scoreValues[index]?.phrases
                                                        : ''
                                                    }
                                                    multiline
                                                    rows={1}
                                                    // defaultValue={el?.phrases}
                                                    onChange={(e) => {
                                                      // setRemarkValue(e.target.value);
                                                      handlePhraseChange(index, e.target.value);
                                                    }}
                                                  />
                                                </FormControl>
                                                {/* ) : userDetails.role === 'Agent' &&
                                                    scoreValues[index]?.remark.length > 0 ? (
                                                    <FormControl style={{ minWidth: 160 }}>
                                                      <TextField
                                                        // label="Remarks"
                                                        // key={index}
                                                        value={
                                                          scoreValues.length > 0 &&
                                                          scoreValues[index]?.remark !== 'undefined'
                                                            ? scoreValues[index]?.remark
                                                            : ''
                                                        }
                                                        multiline
                                                        rows={1}
                                                        disabled
                                                        defaultValue={el?.remark}
                                                        onChange={(e) => {
                                                          setRemarkValue(e.target.value);
                                                          handleRemarkChange(index, e.target.value);
                                                        }}
                                                      />
                                                    </FormControl>
                                                  ) : (
                                                    '-'
                                                  )} */}
                                              </TableCell>

                                              {/* <TableCell>
                                                <>
                                                  <IconButton
                                                    onClick={(event) => {
                                                      setUrlIndex(DummyScorecard.Categories[el]);
                                                      setAnchorE0(event.currentTarget);
                                                    }}
                                                  >
                                                    <Icon
                                                      icon={moreVerticalFill}
                                                      width={20}
                                                      height={20}
                                                    />
                                                  </IconButton>

                                                  {urlIndex.length > 0 ? (
                                                    <Menu
                                                      open={Boolean(anchorE0)}
                                                      anchorEl={anchorE0}
                                                      onClose={(event) => setAnchorE0(null)}
                                                      PaperProps={{
                                                        sx: {
                                                          width: 200,
                                                          maxWidth: '100%',
                                                          maxHeight: 150,
                                                          overflowY: 'auto'
                                                        }
                                                      }}
                                                      anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right'
                                                      }}
                                                      transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right'
                                                      }}
                                                    >
                                                      {urlIndex.map((element, index) => (
                                                        <MenuItem
                                                          sx={{
                                                            color: 'text.secondary'
                                                          }}
                                                        >
                                                          <audio src={element.url[0]} controls>
                                                            <track
                                                              default
                                                              kind="captions"
                                                              srcLang="en"
                                                            />
                                                          </audio>
                                                        </MenuItem>
                                                      ))}
                                                    </Menu>
                                                  ) : null}
                                                </>
                                              </TableCell> */}
                                              {training_call_toggle && (
                                                // <TableCell align="center">
                                                //   <Checkbox
                                                //     sx={{
                                                //       '&.Mui-checked': {
                                                //         color: '#3892CF'
                                                //       }
                                                //     }}
                                                //     checked={isCheckedd}
                                                //     onChange={(el) =>
                                                //       handleCheckboxChange(
                                                //         index,
                                                //         el.target.checked
                                                //       )
                                                //     }
                                                //   />
                                                // </TableCell>

                                                <TableCell key={index} align="center">
                                                  <Checkbox
                                                    sx={{
                                                      '&.Mui-checked': {
                                                        color: '#3892CF'
                                                      },
                                                      '&:hover': {
                                                        color: '#3892CF'
                                                      }
                                                    }}
                                                    checked={
                                                      checkedParameters[el.Category] === true
                                                    }
                                                    onChange={() =>
                                                      handleCheckboxChange(el.Category)
                                                    }
                                                    disabled={userDetails.role === 'Agent'}
                                                  />
                                                </TableCell>
                                              )}
                                            </TableRow>
                                          ))}
                                      </TableBody>
                                    </>
                                  </Table>
                                </TableContainer>
                              </div>

                              <Stack
                                sx={{
                                  padding: 2,
                                  marginTop: 2,

                                  // alignItems: 'center',
                                  backgroundColor: '#f4f4f4'
                                }}
                              >
                                {/* {scoreCardData.flag === 'Not Edited' && ( */}
                                <Grid
                                  container
                                  spacing={1}
                                  style={{
                                    // width: 920,

                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center'
                                  }}
                                >
                                  {/* <Grid item md={3}> */}
                                  <Typography>
                                    AI Average Score : <b>{aiAverageScore} %</b>
                                  </Typography>
                                  {/* </Grid> */}
                                  {/* <Grid item md={3}> */}
                                  <Typography>
                                    AI Non Fatal Score :
                                    <b>
                                      {scoreCardData?.nf_score
                                        ? scoreCardData?.nf_score.toFixed(2)
                                        : 0}
                                      %
                                    </b>
                                  </Typography>
                                  {/* </Grid> */}
                                  {/* <Grid item md={3}> */}
                                  <Typography>
                                    Manual Average Score : <b>{averageScore} %</b>
                                  </Typography>
                                  {/* </Grid> */}
                                  {/* <Grid item md={3}> */}
                                  <Typography>
                                    Manual Non Fatal Score : <b>{NFScore} %</b>
                                  </Typography>
                                  {/* </Grid> */}
                                </Grid>
                                {/* )} */}
                                {/* <div>
                                  {scoreCardData.flag === 'Edited' && (
                                    <div
                                      style={{
                                        width: 920,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-evenly'
                                      }}
                                    >
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography>AI Average Score : </Typography>
                                        <b>{aiAverageScore} %</b>
                                      </div>
                                    
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography>Manual Average Score : </Typography>
                                        <b>{averageScore} %</b>
                                      </div>
                                    </div>
                                  )}
                                </div> */}
                              </Stack>

                              {userDetails.role !== 'Agent' && (
                                <Stack
                                  // spacing={1}
                                  sx={{
                                    paddingRight: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'end',
                                    margin: 1
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: '#3892CF',
                                      '&:hover': {
                                        backgroundColor: '#3892CF'
                                      }
                                    }}
                                    // size="small"

                                    onClick={handleScoreCardSubmit}
                                    // style={{ marginRight: 5 }}
                                  >
                                    Submit
                                  </Button>
                                </Stack>
                              )}
                            </>

                            {categoryQuestion?.length > 0 ? (
                              <CustomFormModel
                                fullScreen={fullScreen}
                                handleDialog={handleScriptDialog}
                                open={scriptOpen}
                                title={`${parameterName} Phrases`}
                              >
                                <Paperbox
                                  // elevation={16}
                                  style={{
                                    marginTop: 10,
                                    padding: '2rem',
                                    height: 360,
                                    overflow: 'auto',
                                    backgroundColor: '#fafafa'
                                  }}
                                >
                                  <TableContainer>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Sr.No</TableCell>
                                          <TableCell>Phrase</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {categoryQuestion.map((el, index) => (
                                          <TableRow>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{el}</TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Paperbox>
                              </CustomFormModel>
                            ) : null}
                          </Card>
                        ) : (
                          <Card style={{ height: 513 }}>
                            <div
                              style={{
                                width: '100%',
                                height: '80%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available</b>
                              </span>
                            </div>
                          </Card>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </TabPanel>
                <TabPanel value={value} index={15}>
                  {userDetails.role === 'Guest' ? (
                    <Card
                      style={{
                        minHeight: 300,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: '#2a2c310d'
                      }}
                    >
                      {isPlanUpgraded ? (
                        <>
                          <span
                            style={{
                              fontWeight: '700',
                              fontSize: 20,
                              marginTop: 5,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <VerifiedIcon
                              style={{
                                color: '#0ecf0e',
                                marginRight: 5,
                                fontSize: 25
                              }}
                            />{' '}
                            Thank you for your interest in upgrading. Our team will contact you
                            soon.
                          </span>
                        </>
                      ) : (
                        <>
                          <LockIcon
                            style={{ height: 40, width: 40, color: '#A8A8A8', marginBottom: 5 }}
                          />
                          <span style={{ fontWeight: '700', fontSize: 20, marginTop: 5 }}>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                }
                              }}
                              // size="small"
                              onClick={(e) => setUpgradeModal(true)}
                              // style={{ marginRight: 5 }}
                            >
                              Upgrade
                            </Button>
                            to premium plan to unlock this functionality.
                          </span>
                        </>
                      )}
                    </Card>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Grid container xs={12} spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Typography
                              style={{
                                width: '234'
                              }}
                            >
                              <h4 style={{ padding: '0.5rem' }}>Agent Phrases</h4>
                            </Typography>
                            <Card
                              style={{
                                width: '100%',
                                height: 400,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              {phraseData &&
                              phraseData.agent_phrase &&
                              Object.keys(phraseData.agent_phrase).length > 0 ? (
                                <Grid item xs={12} md={12}>
                                  <Tabs
                                    value={agentPhraseTab}
                                    onChange={handleAgentTabChangeForPhrase}
                                    variant="scrollable"
                                    className={classes.root}
                                  >
                                    {Object.keys(phraseData.agent_phrase).map((ele, index) => (
                                      <Tab
                                        label={ele}
                                        sx={{
                                          borderRight:
                                            index !==
                                            Object.keys(phraseData.agent_phrase).length - 1
                                              ? '1px solid gray'
                                              : 'none',

                                          backgroundColor:
                                            agentPhraseTab === index ? '#eef7ff' : 'inherit'
                                        }}
                                        className={classes.tab}
                                      />
                                    ))}
                                  </Tabs>
                                  <div
                                    style={{
                                      maxHeight: '340px',
                                      overflowY: 'auto',
                                      overflowX: 'hidden'
                                    }}
                                  >
                                    {phraseData.agent_phrase[
                                      Object.keys(phraseData.agent_phrase)[agentPhraseTab]
                                    ]?.map((key, index) => (
                                      // <div
                                      //   style={{
                                      //     display: 'flex',
                                      //     flexDirection: 'row',
                                      //     justifyContent: 'center',
                                      //     alignItems: 'center',
                                      //     width: '100%',
                                      //     margin: '5px'
                                      //   }}
                                      // >
                                      <Chip
                                        key={index}
                                        label={`${key.Content[0]}`}
                                        variant="filled"
                                        style={{
                                          backgroundColor: '#3892CF',
                                          color: '#ffff',
                                          margin: '5px',
                                          width: 200,
                                          cursor: 'pointer'
                                        }}
                                        size="medium"
                                        onClick={() => {
                                          setSnippetPlay(key.url);
                                          handleSnippetOpen();
                                        }}
                                      />
                                      // </div>
                                    ))}
                                  </div>
                                </Grid>
                              ) : (
                                <span style={{ fontSize: 20 }}>
                                  <b>No Data Available</b>
                                </span>
                              )}

                              {/* <div>
                                  {Object.keys(agentPhrasesDummyData).map((category) => (
                                    <div key={category}>
                                      <h2>{category}</h2>
                                      {agentPhrasesDummyData[category].map((item, index) => (
                                        <div key={index}>
                                          <p>Time: {item.time.join(' - ')}</p>
                                          <p>URL: {item.url[0]}</p>
                                          <p>Content: {item.Content[0]}</p>
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </div> */}
                            </Card>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Typography
                              style={{
                                width: '198'
                              }}
                            >
                              <h4 style={{ padding: '0.5rem' }}>Customer Phrases</h4>
                            </Typography>

                            <Card
                              style={{
                                width: '100%',
                                height: 400,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              {phraseData &&
                              phraseData.customer_phrase &&
                              Object.keys(phraseData.customer_phrase).length > 0 ? (
                                <Grid item xs={12} md={12}>
                                  <Tabs
                                    value={customerPhraseTab}
                                    onChange={handleCustomerTabChangeForPhrase}
                                    variant="scrollable"
                                    className={classes.root}
                                  >
                                    {Object.keys(phraseData.customer_phrase).map((ele, index) => (
                                      <Tab
                                        label={ele}
                                        sx={{
                                          borderRight:
                                            index !==
                                            Object.keys(phraseData.customer_phrase).length - 1
                                              ? '1px solid gray'
                                              : 'none',

                                          backgroundColor:
                                            customerPhraseTab === index ? '#eef7ff' : 'inherit'
                                        }}
                                        className={classes.tab}
                                      />
                                    ))}
                                  </Tabs>
                                  <div
                                    style={{
                                      maxHeight: '340px',
                                      overflowY: 'auto',
                                      overflowX: 'hidden'
                                    }}
                                  >
                                    {phraseData.customer_phrase[
                                      Object.keys(phraseData.customer_phrase)[customerPhraseTab]
                                    ].map((key, index) => (
                                      // <div
                                      //   style={{
                                      //     display: 'flex',
                                      //     flexDirection: 'row',
                                      //     justifyContent: 'center',
                                      //     alignItems: 'center',
                                      //     width: '100%',
                                      //     margin: '5px'
                                      //   }}
                                      // >
                                      <Chip
                                        key={index}
                                        label={`${key.Content[0]}`}
                                        variant="filled"
                                        style={{
                                          backgroundColor: '#3892CF',
                                          color: '#ffff',
                                          margin: '5px',
                                          width: 200
                                        }}
                                        size="medium"
                                        onClick={() => {
                                          setSnippetPlay(key.url);
                                          handleSnippetOpen();
                                        }}
                                      />

                                      // {/* <div className="audio-container">
                                      //   <audio src={key.url} controls>
                                      //     <source
                                      //       src={key.url}
                                      //       // src={`${process.env.REACT_APP_SERVER_APP_URL}${dataForCockpit[3]}`}
                                      //       type="audio/mp3"
                                      //     />
                                      //     <track default kind="captions" srcLang="en" />
                                      //   </audio>
                                      // </div> */}
                                      // </div>
                                    ))}
                                  </div>
                                </Grid>
                              ) : (
                                <span style={{ fontSize: 20 }}>
                                  <b>No Data Available</b>
                                </span>
                              )}
                            </Card>
                          </Grid>
                        </Grid>

                        {/* </Card> */}
                      </Grid>
                    </Grid>
                  )}
                </TabPanel>
                <TabPanel value={value} index={16}>
                  {userDetails.role === 'Guest' ? (
                    <Card
                      style={{
                        minHeight: 300,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: '#2a2c310d'
                      }}
                    >
                      {isPlanUpgraded ? (
                        <>
                          <span
                            style={{
                              fontWeight: '700',
                              fontSize: 20,
                              marginTop: 5,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <VerifiedIcon
                              style={{
                                color: '#0ecf0e',
                                marginRight: 5,
                                fontSize: 25
                              }}
                            />
                            Thank you for your interest in upgrading. Our team will contact you
                            soon.
                          </span>
                        </>
                      ) : (
                        <>
                          {' '}
                          <LockIcon
                            style={{ height: 40, width: 40, color: '#A8A8A8', marginBottom: 5 }}
                          />
                          <span style={{ fontWeight: '700', fontSize: 20, marginTop: 5 }}>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                }
                              }}
                              // size="small"
                              onClick={(e) => setUpgradeModal(true)}
                              // style={{ marginRight: 5 }}
                            >
                              Upgrade
                            </Button>
                            to premium plan to unlock this functionality.
                          </span>
                        </>
                      )}
                    </Card>
                  ) : (
                    <>
                      <Grid
                        container
                        spacing={3}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          // paddingLeft: 20,
                          paddingTop: 10,
                          alignItems: 'center'
                        }}
                      >
                        {dashboardServices && dashboardServices.includes('compliance') && (
                          <>
                            <Button
                              variant="contained"
                              onClick={handleReasonOpen}
                              title={`Score : ${averageScore}`}
                              // disabled={alignment !== 'approve'}
                              style={{
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                height: 40
                              }}
                            >
                              Call Summary
                            </Button>
                          </>
                        )}
                        {/* <CustomFormModel
                          fullScreen={fullScreen}
                          handleDialog={handleCreateDeal}
                          open={openCreateDealModel}
                          title="Add Deal"
                        >
                          <DealForm handleDialog={handleCreateDeal} formik={createDealformik} />
                        </CustomFormModel> */}
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <h4 style={{ padding: '0.5rem' }}>Good Parameters</h4>
                          <Card sx={{ height: 350, paddingLeft: 3, paddingRight: 3 }}>
                            {scoreCardData && scoreValues.length > 0 ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  height: 320,
                                  overflowY: 'auto'
                                }}
                              >
                                {scoreCardData?.review_scoring_details.filter(
                                  (el, index) => (el?.score / el?.weightage) * 100 >= 80
                                ).length > 0 ? (
                                  <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                      <>
                                        <TableHead sx={{ backgroundColor: '#808080' }}>
                                          <TableRow>
                                            {/* <TableCell>
                                        <b>Sr.no</b>
                                      </TableCell> */}
                                            <TableCell>
                                              <b>Parameter</b>
                                            </TableCell>
                                            <TableCell align="center">
                                              <b>Insights</b>
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {scoreCardData &&
                                            scoreCardData?.review_scoring_details.map(
                                              (el, index) =>
                                                (el?.score / el?.weightage) * 100 >= 80 && (
                                                  <TableRow key={index}>
                                                    {/* <TableCell>{index + 1}</TableCell> */}

                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        whiteSpace: 'nowrap',
                                                        maxWidth: '100px', // Adjust the max width as needed
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis' // Add ellipsis for truncation
                                                      }}
                                                      title={el.Category}
                                                    >
                                                      {el.Category ? el.Category : '-'}
                                                    </TableCell>
                                                    {/* <TableCell>{el.score}</TableCell> */}
                                                    <TableCell
                                                      style={{
                                                        width: '100%', // Adjust the width as needed
                                                        overflow: 'hidden'
                                                      }}
                                                    >
                                                      <LinearProgressWithLabel
                                                        value={(el?.score / el?.weightage) * 100}
                                                      />

                                                      {/* <div>{(el?.score / el?.weightage) * 100}%</div> */}
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                      </>
                                    </Table>
                                  </TableContainer>
                                ) : (
                                  <div
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    No Parameter Found
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: '100%',
                                  height: 300,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <span style={{ fontSize: 20 }}>
                                  <b>No Data Available</b>
                                </span>
                              </div>
                            )}
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <h4 style={{ padding: '0.5rem' }}>Need Improvement Parameters</h4>
                          <Card sx={{ height: 350, paddingLeft: 3, paddingRight: 3 }}>
                            {scoreCardData && scoreValues.length > 0 ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  height: 320,
                                  overflowY: 'auto'
                                }}
                              >
                                {scoreCardData?.review_scoring_details.filter(
                                  (el, index) => (el?.score / el?.weightage) * 100 < 80
                                ).length > 0 ? (
                                  <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                      <>
                                        <TableHead sx={{ backgroundColor: '#808080' }}>
                                          <TableRow>
                                            {/* <TableCell>
                                            <b>Sr.no</b>
                                          </TableCell> */}
                                            <TableCell>
                                              <b>Parameter</b>
                                            </TableCell>
                                            <TableCell align="center">
                                              <b>Insights</b>
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {scoreCardData &&
                                            scoreCardData?.review_scoring_details.map(
                                              (el, index) =>
                                                (el?.score / el?.weightage) * 100 < 80 && (
                                                  <TableRow key={index}>
                                                    {/* <TableCell>{index + 1}</TableCell> */}

                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        whiteSpace: 'nowrap',
                                                        maxWidth: '100px', // Adjust the max width as needed
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis' // Add ellipsis for truncation
                                                      }}
                                                      title={el.Category}
                                                    >
                                                      {el.Category ? el.Category : '-'}
                                                    </TableCell>
                                                    {/* <TableCell>{el.score}</TableCell> */}
                                                    <TableCell
                                                      style={{
                                                        width: '100%', // Adjust the width as needed
                                                        overflow: 'hidden'
                                                      }}
                                                    >
                                                      <LinearProgressWithLabel
                                                        value={(el?.score / el?.weightage) * 100}
                                                      />
                                                      {/* <div>{(el?.score / el?.weightage) * 100}%</div> */}
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                      </>
                                    </Table>
                                  </TableContainer>
                                ) : (
                                  <div
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    No Parameter Found
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: '100%',
                                  height: 300,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <span style={{ fontSize: 20 }}>
                                  <b>No Data Available</b>
                                </span>
                              </div>
                            )}
                          </Card>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </TabPanel>
                <TabPanel value={value} index={17}>
                  <Grid container spacing={2}>
                    {dataLevel && dataLevel === 'level_3' && (
                      <Grid item xs={6} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>Call Type</h4>
                        {QRCData && pieChartData?.length > 0 ? (
                          <Card sx={{ height: 450 }}>
                            <ResponsiveContainer width="100%" height={450}>
                              <PieChartForQRC>
                                <Pie
                                  data={pieChartData}
                                  cx="50%"
                                  cy="50%"
                                  labelLine={false}
                                  label={renderCustomizedLabel}
                                  outerRadius={180}
                                  fill="#8884d8"
                                  dataKey="value"
                                >
                                  {pieChartData.map((entry, index) => (
                                    <Cell
                                      onClick={() => {
                                        setDispositionData(QRCData[entry.name]);
                                        setCategoryName(entry.name);
                                        // handleDialogForQRC();
                                      }}
                                      key={`cell-${index}`}
                                      fill={COLORS[index % COLORS.length]}
                                      style={{ cursor: 'pointer', outline: 'none', border: 'none' }}
                                    />
                                  ))}
                                </Pie>
                                <Legend />
                              </PieChartForQRC>
                            </ResponsiveContainer>
                          </Card>
                        ) : (
                          <Card sx={{ height: 450 }}>
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available</b>
                              </span>
                            </div>
                          </Card>
                        )}
                      </Grid>
                    )}
                    {dataLevel && dataLevel === 'level_3' && categoryName && (
                      <Grid item xs={6} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>{categoryName} Disposition</h4>
                        {QRCData &&
                        dispositionData &&
                        Object.keys(dispositionData.chunks).length > 0 ? (
                          <Card sx={{ height: 450, overflow: 'scroll' }}>
                            {Object.keys(dispositionData.chunks).length > 0 && (
                              <div>
                                {Object.keys(dispositionData.chunks)
                                  .filter(
                                    (el) => Object.keys(dispositionData.chunks[el]).length !== 0
                                  )
                                  .map((category) => (
                                    <Grid item xs={12} style={{ marginTop: 8, padding: 5 }}>
                                      <Stack
                                        onClick={() => handleToggleExpand(category)}
                                        style={{
                                          color: 'Black',
                                          backgroundColor: '#edeff3',
                                          '&:hover': {
                                            backgroundColor: '#edeff3'
                                          },
                                          // marginBottom: 5,
                                          height: 45,
                                          borderRadius: 3,
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          cursor: 'pointer'
                                        }}
                                      >
                                        <p
                                          style={{
                                            marginLeft: 10,
                                            color: '#3892CF',
                                            fontSize: 15,
                                            fontFamily: 'sans-serif',
                                            display: 'flex',
                                            alignItems: 'center'
                                          }}
                                        >
                                          &nbsp;{category}
                                        </p>
                                        {expandedCategories[category] ? (
                                          <ArrowDropUpIcon
                                            size={20}
                                            htmlColor="#3892CF"
                                            titleAccess="Collapse"
                                          />
                                        ) : (
                                          <ArrowDropDownIcon
                                            size={20}
                                            htmlColor="#3892CF"
                                            titleAccess="Collapse"
                                          />
                                        )}
                                      </Stack>
                                      <Collapse in={expandedCategories[category]}>
                                        <TableContainer>
                                          <Table>
                                            <TableHead
                                              sx={{
                                                fontWeight: 'bold',
                                                backgroundColor: '#f8f6fa',
                                                whiteSpace: 'nowrap'
                                              }}
                                            >
                                              {/* <TableCell align="center">Sr.No</TableCell> */}
                                              <TableCell align="center">Disposition</TableCell>
                                              {/* <TableCell align="center">Content</TableCell>
                                            <TableCell align="center">Action</TableCell> */}
                                            </TableHead>
                                            <TableBody>
                                              {Object.keys(dispositionData.chunks[category]).map(
                                                (disposition, index) => (
                                                  <TableRow>
                                                    {/* <TableCell align="center">1</TableCell> */}
                                                    <TableCell align="center">
                                                      {disposition}
                                                    </TableCell>
                                                    {/* <TableCell
                                                    align="center"
                                                    style={{
                                                      whiteSpace: 'nowrap',
                                                      maxWidth: '100px', // Adjust the max width as needed
                                                      overflow: 'hidden',
                                                      textOverflow: 'ellipsis' // Add ellipsis for truncation
                                                    }}
                                                    title={dispositionData.chunks[category][
                                                      disposition
                                                    ]
                                                      .map((el) => el.Content[0])
                                                      .join(',')}
                                                  >
                                                    {dispositionData.chunks[category][disposition]
                                                      .map((el) => el.Content)
                                                      .join(',')}
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    <>
                                                      <IconButton
                                                        onClick={(event) =>
                                                          setAnchorE3(event.currentTarget)
                                                        }
                                                      >
                                                        <Icon
                                                          icon={moreVerticalFill}
                                                          width={20}
                                                          height={20}
                                                        />
                                                      </IconButton>
                                                      {dispositionData.chunks[category][disposition]
                                                        .length > 0 ? (
                                                        <Menu
                                                          open={Boolean(anchorE3)}
                                                          anchorEl={anchorE3}
                                                          onClose={(event) => setAnchorE3(null)}
                                                          PaperProps={{
                                                            sx: {
                                                              width: 200,
                                                              maxWidth: '100%',
                                                              maxHeight: 200,
                                                              overflowY: 'auto'
                                                            }
                                                          }}
                                                          anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right'
                                                          }}
                                                          transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right'
                                                          }}
                                                        >
                                                          {dispositionData.chunks[category][
                                                            disposition
                                                          ].map((element, index) => (
                                                            <MenuItem
                                                              sx={{ color: 'text.secondary' }}
                                                            >
                                                              <audio
                                                                ref={snippetRef}
                                                                src={
                                                                  process.env
                                                                    .REACT_APP_ON_PREMISE === 'true'
                                                                    ? `${
                                                                        process.env
                                                                          .REACT_APP_SERVER_MEDIA_URL
                                                                      }${element.url[0].replace(
                                                                        '/app',
                                                                        ''
                                                                      )}`
                                                                    : element
                                                                }
                                                                controls
                                                              >
                                                                <track
                                                                  default
                                                                  kind="captions"
                                                                  srcLang="en"
                                                                />
                                                              </audio>
                                                            </MenuItem>
                                                          ))}
                                                        </Menu>
                                                      ) : null}
                                                    </>
                                                  </TableCell> */}
                                                  </TableRow>
                                                )
                                              )}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </Collapse>
                                    </Grid>
                                  ))}
                              </div>
                            )}
                          </Card>
                        ) : (
                          <Card sx={{ height: 450 }}>
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available For {categoryName} disposition</b>
                              </span>
                              {/* <Typography sx={{ color: 'grey' }}>
                                Try with selecting another QRC from pie chart
                              </Typography> */}
                            </div>
                          </Card>
                        )}
                      </Grid>
                    )}
                    {dataLevel && dataLevel === 'level_2' && categoryName && (
                      <Grid item xs={6} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>{categoryName} Disposition</h4>
                        {QRCData && dispositionData ? (
                          <Card sx={{ height: 450, overflow: 'scroll' }}>
                            {Object.keys(dispositionData).length > 0 && (
                              <div>
                                {Object.keys(dispositionData)
                                  .filter((el) => Object.keys(dispositionData).length !== 0)
                                  .map((category) => (
                                    <Grid item xs={12} style={{ marginTop: 8, padding: 5 }}>
                                      <Stack
                                        onClick={() => handleToggleExpand(category)}
                                        style={{
                                          color: 'Black',
                                          backgroundColor: '#edeff3',
                                          '&:hover': {
                                            backgroundColor: '#edeff3'
                                          },
                                          // marginBottom: 5,
                                          height: 45,
                                          borderRadius: 3,
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          cursor: 'pointer'
                                        }}
                                      >
                                        <p
                                          style={{
                                            marginLeft: 10,
                                            color: '#3892CF',
                                            fontSize: 15,
                                            fontFamily: 'sans-serif',
                                            display: 'flex',
                                            alignItems: 'center'
                                          }}
                                        >
                                          &nbsp;{category}
                                        </p>
                                        {expandedCategories[category] ? (
                                          <ArrowDropUpIcon
                                            size={20}
                                            htmlColor="#3892CF"
                                            titleAccess="Collapse"
                                          />
                                        ) : (
                                          <ArrowDropDownIcon
                                            size={20}
                                            htmlColor="#3892CF"
                                            titleAccess="Collapse"
                                          />
                                        )}
                                      </Stack>
                                      <Collapse in={expandedCategories[category]}>
                                        <TableContainer>
                                          <Table>
                                            <TableHead
                                              sx={{
                                                fontWeight: 'bold',
                                                backgroundColor: '#f8f6fa',
                                                whiteSpace: 'nowrap'
                                              }}
                                            >
                                              {/* <TableCell align="center">Sr.No</TableCell> */}
                                              <TableCell align="center">Category</TableCell>
                                              {/* <TableCell align="center">Content</TableCell>
                                            <TableCell align="center">Action</TableCell> */}
                                            </TableHead>
                                            <TableBody>
                                              {dispositionData[categoryName].map(
                                                (disposition, index) => (
                                                  <TableRow>
                                                    <TableCell align="center">
                                                      {disposition}
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                              )}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </Collapse>
                                    </Grid>
                                  ))}
                              </div>
                            )}
                          </Card>
                        ) : (
                          <Card sx={{ height: 450 }}>
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available For {categoryName} disposition</b>
                              </span>
                              {/* <Typography sx={{ color: 'grey' }}>
                                Try with selecting another QRC from pie chart
                              </Typography> */}
                            </div>
                          </Card>
                        )}
                      </Grid>
                    )}
                    {dataLevel && dataLevel === 'level_1' && (
                      <Grid item xs={6} md={6}>
                        <h4 style={{ padding: '0.5rem' }}>{categoryName} Disposition</h4>
                        {QRCData && dispositionData && categoryName ? (
                          <Card sx={{ height: 450, overflow: 'scroll' }}>
                            <Grid item xs={12} style={{ marginTop: 8, padding: 5 }}>
                              <TableContainer>
                                <Table>
                                  <TableHead
                                    sx={{
                                      fontWeight: 'bold',
                                      backgroundColor: '#f8f6fa',
                                      whiteSpace: 'nowrap'
                                    }}
                                  >
                                    <TableCell align="center">Sr. No</TableCell>
                                    <TableCell align="center">Sentences</TableCell>
                                  </TableHead>
                                  <TableBody>
                                    {QRCData[categoryName].map((sentence, index) => (
                                      <TableRow>
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="center">{sentence}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Card>
                        ) : (
                          <Card sx={{ height: 450 }}>
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <span style={{ fontSize: 20 }}>
                                <b>No Data Available For {categoryName} disposition</b>
                              </span>
                              {/* <Typography sx={{ color: 'grey' }}>
                                Try with selecting another QRC from pie chart
                              </Typography> */}
                            </div>
                          </Card>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </TabPanel>
              </Box>
              {/* ) : (
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width="100%" height={500} />
                </Stack>
              )} */}
              {/* <Stack spacing={1}>
                <Skeleton variant="rectangular" width="100%" height={500} />
              </Stack> */}
            </Box>
          </Box>
          {/* <Grid container spacing={3}>
            <Grid item xs={3}>
              <Paperbox elevation={0} style={{ margin: 10 }}>
                <LoadingButton
                  variant="contained"
                  // color="primary"
                  onClick={() => navigate('/dashboard/workspace/Upload', { replace: true })}
                  style={{
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                >
                  Back
                </LoadingButton>
              </Paperbox>
            </Grid>
          </Grid> */}
          <CustomFormModel
            fullScreen={fullScreen}
            button
            open={open}
            title="Unpleasant Word Details"
            handleDialog={handleDialog}
          >
            <Container
              style={{
                padding: 10
              }}
            >
              <TableContainer sx={{ minWidth: '100%', maxHeight: 390 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Word
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: '#f8f6fa',
                          // border: '2px solid red',
                          width: '200px'
                        }}
                      >
                        Duration(sec)
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                          >
                            Start Time
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                          >
                            End Time
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Audio
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Correction Needed
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Correct Word
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {toneData
                    ? Object?.keys(toneData?.agent_words_details)?.map((key, index) => (
                        <TableBody>
                          <>
                            {/* {key.length !== 0 && ( */}
                            {toneData?.agent_words_details[key].map((el) => (
                              <TableRow hover key={1} tabIndex={-1} role="checkbox">
                                <TableCell align="left"> {key}</TableCell>

                                <TableCell align="right">
                                  {/* <Box
                                    sx={{
                                      // width: '100%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      marginBottom: '2px'
                                    }}
                                  >
                                    <Chip
                                      label={`Start Time-${(el.time[0] / 1000).toFixed(2)}`}
                                      // label="Start Time - 10"
                                      color="primary"
                                      size="small"
                                    />
                                    <Chip
                                      label={`End Time-${(el.time[1] / 1000).toFixed(2)}`}
                                      // label="End Time - 20"
                                      color="primary"
                                      size="small"
                                    />
                                  </Box> */}
                                  <TableRow>
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                      {`${(el.time[0] / 1000 / 60).toFixed(0)}:${(
                                        (el.time[0] / 1000) %
                                        60
                                      ).toFixed(0)}`}
                                    </TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                      {`${(el.time[1] / 1000 / 60).toFixed(0)}:${(
                                        (el.time[1] / 1000) %
                                        60
                                      ).toFixed(0)}`}
                                    </TableCell>
                                  </TableRow>
                                </TableCell>

                                <TableCell align="center">
                                  {/* {toneData.agent_words_details[key].map((el) => ( */}
                                  <Box
                                    style={{
                                      width: '60%',
                                      display: 'flex',
                                      margin: 'auto'
                                    }}
                                  >
                                    <audio controls id={`transAdio${index}`}>
                                      <source
                                        src={
                                          process.env.REACT_APP_ON_PREMISE === 'true'
                                            ? `${
                                                process.env.REACT_APP_SERVER_MEDIA_URL
                                              }${el.url.replace('/app', '')}`
                                            : el.url
                                        }
                                        type="audio/mp3"
                                      />
                                      <track default kind="captions" srcLang="en" />
                                    </audio>
                                  </Box>

                                  {/* ))} */}
                                </TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                  <Checkbox
                                    key={index}
                                    onChange={(e) => {
                                      // console.log('checked -----', e.target.checked);
                                      setIsCorrectionRequired([
                                        ...isCorrectionRequired,
                                        { data: e.target.checked, index }
                                      ]);
                                    }}
                                  />
                                </TableCell>

                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                  <TextField variant="standard" />
                                </TableCell>
                              </TableRow>
                            ))}
                            {/* )} */}
                          </>
                        </TableBody>
                      ))
                    : null}
                </Table>
              </TableContainer>
              {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginY: '5px' }}>
                <Button
                  variant="contained"
                  onClick={(e) => handleDialog()}
                  style={{ marginRight: 5 }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => handleDialog()}
                  style={{ marginRight: 5 }}
                >
                  Save
                </Button>
              </div> */}
            </Container>
          </CustomFormModel>
          <CustomFormModel
            fullScreen={fullScreen}
            button
            open={customerOpen}
            title="Customer unpleasant Word Details"
            handleDialog={handleCustomerDialog}
          >
            <Container
              style={{
                padding: 10
              }}
            >
              <TableContainer sx={{ minWidth: '100%', maxHeight: 390 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Word
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: '#f8f6fa',
                          width: '200px'
                        }}
                      >
                        Duration(sec)
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                          >
                            Start Time
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                          >
                            End Time
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Audio
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Correction Needed
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Correct Word
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {toneData
                    ? Object?.keys(toneData?.customer_words_details)?.map((key, index) => (
                        <TableBody>
                          <>
                            {toneData?.customer_words_details[key].map((el) => (
                              <TableRow hover key={1} tabIndex={-1} role="checkbox">
                                <TableCell align="left"> {key}</TableCell>

                                <TableCell align="right">
                                  <TableRow>
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                      {`${(el.time[0] / 1000 / 60).toFixed(0)}:${(
                                        (el.time[0] / 1000) %
                                        60
                                      ).toFixed(0)}`}
                                    </TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                      {`${(el.time[1] / 1000 / 60).toFixed(0)}:${(
                                        (el.time[1] / 1000) %
                                        60
                                      ).toFixed(0)}`}
                                    </TableCell>
                                  </TableRow>
                                </TableCell>

                                <TableCell align="center">
                                  <Box
                                    style={{
                                      width: '60%',
                                      display: 'flex',
                                      margin: 'auto'
                                    }}
                                  >
                                    <audio controls id={`transAdio${index}`}>
                                      <source
                                        src={
                                          process.env.REACT_APP_ON_PREMISE === 'true'
                                            ? `${
                                                process.env.REACT_APP_SERVER_MEDIA_URL
                                              }${el.url.replace('/app', '')}`
                                            : el.url
                                        }
                                        type="audio/mp3"
                                      />
                                      <track default kind="captions" srcLang="en" />
                                    </audio>
                                  </Box>
                                </TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                  <Checkbox
                                    key={index}
                                    onChange={(e) => {
                                      console.log('checked -----', e.target.checked);
                                      setIsCorrectionRequired([
                                        ...isCorrectionRequired,
                                        { data: e.target.checked, index }
                                      ]);
                                    }}
                                  />
                                </TableCell>

                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                  <TextField variant="standard" />
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        </TableBody>
                      ))
                    : null}
                </Table>
              </TableContainer>
            </Container>
          </CustomFormModel>
          <CustomFormModel
            fullScreen={fullScreen}
            open={sentimentOpen}
            title={
              (customerSentimentIndex === 0 && 'Neutral sentiment details') ||
              (customerSentimentIndex === 1 && 'Positive sentiment Details') ||
              (customerSentimentIndex === 2 && 'Negative sentiment Details')
            }
            handleDialog={handleSentimentDialog}
          >
            <Container
              style={{
                padding: 10
              }}
            >
              <TableContainer sx={{ minWidth: '100%', maxHeight: 390 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead sx={{ border: '2px solid red' }}>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Sr.No
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Sentences
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Sentiment
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {customerSentimentIndex === 0 && (
                    <TableBody>
                      <>
                        {sentimentData &&
                          sentimentData.customer_sentiments_sentences &&
                          sentimentData.customer_sentiments_sentences.NEUTRAL.map((key, index) => (
                            <TableRow hover key={1} tabIndex={-1} role="checkbox">
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">{key}</TableCell>
                              <TableCell align="right">Neutral</TableCell>
                            </TableRow>
                          ))}
                      </>
                    </TableBody>
                  )}
                  {customerSentimentIndex === 1 && (
                    <TableBody>
                      <>
                        {sentimentData &&
                          sentimentData.customer_sentiments_sentences &&
                          sentimentData.customer_sentiments_sentences.POSITIVE.map((key, index) => (
                            <TableRow hover key={1} tabIndex={-1} role="checkbox">
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">{key}</TableCell>
                              <TableCell align="right">Positive</TableCell>
                            </TableRow>
                          ))}
                      </>
                    </TableBody>
                  )}
                  {customerSentimentIndex === 2 && (
                    <TableBody>
                      <>
                        {sentimentData &&
                          Object.keys(sentimentData.customer_sentiments_sentences).length > 0 &&
                          sentimentData.customer_sentiments_sentences.NEGATIVE.map((key, index) => (
                            <TableRow hover key={1} tabIndex={-1} role="checkbox">
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">{key}</TableCell>
                              <TableCell align="right">Negative</TableCell>
                            </TableRow>
                          ))}
                      </>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Container>
          </CustomFormModel>
          <CustomFormModel
            fullScreen={fullScreen}
            open={agentSentimentOpen}
            title={
              (agentSentimentIndex === 0 && 'Neutral sentiment details') ||
              (agentSentimentIndex === 1 && 'Positive sentiment Details') ||
              (agentSentimentIndex === 2 && 'Negative sentiment Details')
            }
            handleDialog={handleAgentSentimentDialog}
          >
            <Container
              style={{
                padding: 10
              }}
            >
              <TableContainer sx={{ minWidth: '100%', maxHeight: 390 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead sx={{ border: '2px solid red' }}>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Sr.No
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Sentences
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Sentiment
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {agentSentimentIndex === 0 && (
                    <TableBody>
                      <>
                        {sentimentData &&
                          sentimentData.agent_sentiments_sentences &&
                          sentimentData.agent_sentiments_sentences.NEUTRAL.map((key, index) => (
                            <TableRow hover key={1} tabIndex={-1} role="checkbox">
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">{key}</TableCell>
                              <TableCell align="right">Neutral</TableCell>
                            </TableRow>
                          ))}
                      </>
                    </TableBody>
                  )}
                  {agentSentimentIndex === 1 && (
                    <TableBody>
                      <>
                        {sentimentData &&
                          sentimentData.agent_sentiments_sentences &&
                          sentimentData.agent_sentiments_sentences.POSITIVE.map((key, index) => (
                            <TableRow hover key={1} tabIndex={-1} role="checkbox">
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">{key}</TableCell>
                              <TableCell align="right">Positive</TableCell>
                            </TableRow>
                          ))}
                      </>
                    </TableBody>
                  )}
                  {agentSentimentIndex === 2 && (
                    <TableBody>
                      <>
                        {sentimentData &&
                          sentimentData.agent_sentiments_sentences &&
                          sentimentData.agent_sentiments_sentences.NEGATIVE.map((key, index) => (
                            <TableRow hover key={1} tabIndex={-1} role="checkbox">
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">{key}</TableCell>
                              <TableCell align="right">Negative</TableCell>
                            </TableRow>
                          ))}
                      </>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Container>
          </CustomFormModel>
          <CustomFormModel
            fullScreen={fullScreen}
            open={toneOpen}
            title={
              (toneIndex.data === 0 && 'High tone Details') ||
              (toneIndex.data === 1 && 'Medium tone Details') ||
              (toneIndex.data === 2 && 'Low tone Details')
            }
            handleDialog={handleToneDialog}
          >
            <Container
            // style={{
            //   padding: 20
            // }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {showTip(getAudioFileName(dataForCockpit[4]))}
              </Box>
              <TableContainer sx={{ minWidth: 800, maxHeight: 350 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: '#f8f6fa',
                          // border: '2px solid red',
                          width: '200px'
                        }}
                      >
                        Duration(sec)
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              fontWeight: 'bold',
                              backgroundColor: '#f8f6fa',
                              borderBottom: 'none'
                            }}
                          >
                            Start Time
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontWeight: 'bold',
                              backgroundColor: '#f8f6fa',
                              borderBottom: 'none'
                            }}
                          >
                            End Time
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Audio
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {toneIndex.data === 0 && toneIndex.isAgent && (
                    <TableBody>
                      <>
                        {toneData?.agent_tone_details.high?.map((item, index) => (
                          <TableRow hover key={1} tabIndex={-1} role="checkbox">
                            <TableCell align="right">
                              {/* <Box
                                sx={{
                                  // width: '100%',
                                  display: 'flex',
                                  // justifyContent: 'space-between',
                                  marginBottom: '2px',
                                  marginLeft: 5
                                }}
                              >
                                <Chip
                                  label={`Start Time-${(item.time[0] / 1000).toFixed(2)}`}
                                  color="primary"
                                  size="small"
                                />
                                <Chip
                                  label={`End Time-${(item.time[1] / 1000).toFixed(2)}`}
                                  color="primary"
                                  size="small"
                                />
                              </Box> */}
                              <TableRow>
                                <TableCell
                                  align="center"
                                  sx={{ fontWeight: 'bold', borderBottom: 'none' }}
                                >
                                  {`${(item.time[0] / 1000).toFixed(2)}`}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ fontWeight: 'bold', borderBottom: 'none' }}
                                >
                                  {`${(item.time[1] / 1000).toFixed(2)}`}
                                </TableCell>
                              </TableRow>
                            </TableCell>
                            <TableCell align="center">
                              <Box
                                style={{
                                  width: '75%',
                                  display: 'flex',
                                  marginLeft: '135px'
                                }}
                              >
                                <audio
                                  controls
                                  id={`transAdio${index}`}
                                  preload="auto"
                                  src={
                                    process.env.REACT_APP_ON_PREMISE === 'true'
                                      ? `${
                                          process.env.REACT_APP_SERVER_MEDIA_URL
                                        }${item.url.replace('/app', '')}`
                                      : item.url
                                  }
                                  // src={
                                  //   process.env.REACT_APP_ON_PREMISE === 'true'
                                  //     ? `${
                                  //         process.env.REACT_APP_SERVER_MEDIA_URL
                                  //       }${item.url.substring(item.url.indexOf('/media'))}`
                                  //     : item.url
                                  // }
                                >
                                  <source
                                    src={
                                      process.env.REACT_APP_ON_PREMISE === 'true'
                                        ? `${
                                            process.env.REACT_APP_SERVER_MEDIA_URL
                                          }${item.url.replace('/app', '')}`
                                        : item.url
                                    }
                                    type="audio/mp3"
                                  />
                                  <track default kind="captions" srcLang="en" />
                                </audio>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    </TableBody>
                  )}
                  {toneIndex.data === 1 && toneIndex.isAgent && (
                    <TableBody>
                      <>
                        {toneData?.agent_tone_details.medium?.map((item, index) => (
                          <TableRow hover key={1} tabIndex={-1} role="checkbox">
                            <TableCell align="center">
                              <TableRow>
                                <TableCell
                                  align="center"
                                  sx={{ fontWeight: 'bold', borderBottom: 'none' }}
                                >
                                  {`${(item.time[0] / 1000).toFixed(2)}`}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ fontWeight: 'bold', borderBottom: 'none' }}
                                >
                                  {`${(item.time[1] / 1000).toFixed(2)}`}
                                </TableCell>
                              </TableRow>
                            </TableCell>
                            <TableCell align="right">
                              <Box
                                style={{
                                  width: '75%',
                                  display: 'flex',
                                  marginLeft: '135px'
                                }}
                              >
                                <audio
                                  controls
                                  id={`transAdio${index}`}
                                  preload="auto"
                                  src={
                                    process.env.REACT_APP_ON_PREMISE === 'true'
                                      ? `${
                                          process.env.REACT_APP_SERVER_MEDIA_URL
                                        }${item.url.replace('/app', '')}`
                                      : item.url
                                  }
                                >
                                  <source
                                    src={
                                      process.env.REACT_APP_ON_PREMISE === 'true'
                                        ? `${
                                            process.env.REACT_APP_SERVER_MEDIA_URL
                                          }${item.url.replace('/app', '')}`
                                        : item.url
                                    }
                                    type="audio/mp3"
                                  />
                                  <track default kind="captions" srcLang="en" />
                                </audio>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    </TableBody>
                  )}
                  {toneIndex.data === 0 && toneIndex.isCustomer && (
                    <TableBody>
                      <>
                        {toneData?.customer_tone_details.high?.map((item, index) => (
                          <TableRow hover key={1} tabIndex={-1} role="checkbox">
                            <TableCell align="center">
                              <TableRow>
                                <TableCell
                                  align="center"
                                  sx={{ fontWeight: 'bold', borderBottom: 'none' }}
                                >
                                  {`${(item.time[0] / 1000).toFixed(2)}`}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ fontWeight: 'bold', borderBottom: 'none' }}
                                >
                                  {`${(item.time[1] / 1000).toFixed(2)}`}
                                </TableCell>
                              </TableRow>
                            </TableCell>
                            <TableCell align="center">
                              <Box
                                style={{
                                  // marginLeft: '9rem',
                                  width: '50%',
                                  display: 'flex',
                                  marginLeft: '135px'
                                }}
                              >
                                {/* <AudioPlayer
                                  audioSrc={s3Url}
                                  startTime={item.time[0] / 1000}
                                  endTime={item.time[1] / 1000}
                                /> */}
                                <audio
                                  controls
                                  id={`transAdio${index}`}
                                  preload="auto"
                                  // src={`${s3Url}#t=${item.time[0] / 1000},${item.time[1] / 1000}`}
                                  src={
                                    process.env.REACT_APP_ON_PREMISE === 'true'
                                      ? `${
                                          process.env.REACT_APP_SERVER_MEDIA_URL
                                        }${item.url.replace('/app', '')}`
                                      : item.url
                                  }
                                >
                                  <source
                                    src={
                                      process.env.REACT_APP_ON_PREMISE === 'true'
                                        ? `${
                                            process.env.REACT_APP_SERVER_MEDIA_URL
                                          }${item.url.replace('/app', '')}`
                                        : item.url
                                    }
                                    // src={`${s3Url}#t=${item.time[0] / 1000},${item.time[1] / 1000}`}
                                    type="audio/mp3"
                                  />
                                  <track default kind="captions" srcLang="en" />
                                </audio>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    </TableBody>
                  )}
                  {toneIndex.data === 1 && toneIndex.isCustomer && (
                    <TableBody>
                      <>
                        {toneData?.customer_tone_details.medium?.map((item, index) => (
                          <TableRow hover key={1} tabIndex={-1} role="checkbox">
                            {/* <TableCell align="left">
                              {getAudioFileName(dataForCockpit[3])}
                            </TableCell> */}
                            <TableCell align="center">
                              {/* {dead_time?.map((item) => ( */}
                              {/* <Box
                                sx={{
                                  // width: '100%',
                                  display: 'flex',
                                  // justifyContent: 'space-between',
                                  marginBottom: '2px',
                                  marginLeft: 5
                                }}
                              >
                                <Chip
                                  label={`Start Time-${(item.time[0] / 1000).toFixed(2)}`}
                                  color="primary"
                                  size="small"
                                />
                                <Chip
                                  label={`End Time-${(item.time[1] / 1000).toFixed(2)}`}
                                  color="primary"
                                  size="small"
                                />
                              </Box> */}
                              <TableRow>
                                <TableCell
                                  align="center"
                                  sx={{ fontWeight: 'bold', borderBottom: 'none' }}
                                >
                                  {`${(item.time[0] / 1000).toFixed(2)}`}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ fontWeight: 'bold', borderBottom: 'none' }}
                                >
                                  {`${(item.time[1] / 1000).toFixed(2)}`}
                                </TableCell>
                              </TableRow>
                              {/* ))} */}
                            </TableCell>
                            <TableCell align="center">
                              <Box
                                style={{
                                  width: '75%',
                                  display: 'flex',
                                  marginLeft: '135px'
                                }}
                              >
                                <audio
                                  controls
                                  id={`transAdio${index}`}
                                  preload="auto"
                                  src={
                                    process.env.REACT_APP_ON_PREMISE === 'true'
                                      ? `${
                                          process.env.REACT_APP_SERVER_MEDIA_URL
                                        }${item.url.replace('/app', '')}`
                                      : item.url
                                  }
                                >
                                  <source
                                    src={
                                      process.env.REACT_APP_ON_PREMISE === 'true'
                                        ? `${
                                            process.env.REACT_APP_SERVER_MEDIA_URL
                                          }${item.url.replace('/app', '')}`
                                        : item.url
                                    }
                                    type="audio/mp3"
                                  />
                                  <track default kind="captions" srcLang="en" />
                                </audio>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    </TableBody>
                  )}
                  {
                    // toneIndex === 2 && null
                    // <TableBody>
                    //   <>
                    //     {toneData?.tone_details.low?.map((item, index) => (
                    //       <TableRow hover key={1} tabIndex={-1} role="checkbox">
                    //         <TableCell align="left">
                    //           {getAudioFileName(dataForCockpit[3])}
                    //         </TableCell>
                    //         <TableCell align="center">
                    //           {/* {dead_time?.map((item) => ( */}
                    //           <Box
                    //             sx={{
                    //               // width: '100%',
                    //               display: 'flex',
                    //               // justifyContent: 'space-between',
                    //               marginBottom: '2px',
                    //               marginLeft: 5
                    //             }}
                    //           >
                    //           <Chip
                    //           label={`Start Time-${(item.time[0] / 1000).toFixed(2)}`}
                    //           color="primary"
                    //           size="small"
                    //           />
                    //           <Chip
                    //           label={`End Time-${(item.time[1] / 1000).toFixed(2)}`}
                    //           color="primary"
                    //           size="small"
                    //           />
                    //           </Box>
                    //           {/* ))} */}
                    //         </TableCell>
                    //         <TableCell align="right">
                    //           <Box
                    //             style={{
                    //               width: '75%',
                    //               display: 'flex',
                    //               margin: 'auto'
                    //             }}
                    //           >
                    //             <audio
                    //               controls
                    //               id={`transAdio${index}`}
                    //               preload="auto"
                    //               src={`${s3Url}#t=${item.time[0]},${item.time[1]}`}
                    //             >
                    //               <source
                    //                 src={`${s3Url}#t=${item.time[0]},${item.time[1]}`}
                    //                 type="audio/mp3"
                    //               />
                    //               <track default kind="captions" srcLang="en" />
                    //             </audio>
                    //           </Box>
                    //         </TableCell>
                    //       </TableRow>
                    //     ))}
                    //   </>
                    // </TableBody>
                  }
                </Table>
              </TableContainer>
              {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginY: '5px' }}>
                <Button
                  variant="contained"
                  onClick={(e) => handleToneDialog()}
                  style={{ marginRight: 5 }}
                >
                  Close
                </Button>
              </div> */}
            </Container>
          </CustomFormModel>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleFeedbackDialog}
            open={feedbackOpen}
            title="Add Feedback"
          >
            <FeedbackForm handleDialog={handleFeedbackDialog} formik={formik} />
          </CustomFormModel>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleScoreFormDialog}
            open={scoreFormOpen}
            title="Agent & Call Details"
          >
            <ScoreForm
              handleDialog={handleScoreFormDialog}
              agentDetails={complianceInfo?.agent_call_details}
              inputId={dataForCockpit[0]}
              trackId={getAudioFileName(dataForCockpit[4])}
              callDate={dataForCockpit[7]}
              audioDuration={audioDuration}
              update={updateAgentCallDetails}
              setUpdate={setUpdateAgentCallDetails}
              agentCallDetails={agentCallDetails}
              setAlertContent={setAlertContent}
              setAlertType={setAlertType}
              setAlert={setAlert}
            />
          </CustomFormModel>
          <SmallCustomFormModel
            fullScreen={fullScreen}
            open={upgradeModal}
            title="Upgrade Plan"
            handleDialog={handleUpgradePlanModal}
          >
            <Container
              style={{
                padding: 10
              }}
            >
              <Typography variant="h6" style={{ marginTop: -10 }}>
                {isPlanUpgraded ? (
                  <>
                    Thank you for considering our upgrade! Our team will reach out to you soon to
                    provide more information and assist you further.
                  </>
                ) : (
                  'Would you like to upgrade your plan to access additional features?'
                )}
              </Typography>
              <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
                {' '}
                {!isPlanUpgraded && (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#3892CF',
                      '&:hover': {
                        backgroundColor: '#3892CF'
                      }
                    }}
                    // size="small"
                    onClick={handleUpgradePlan}
                    // style={{ marginRight: 5 }}
                  >
                    Proceed
                  </Button>
                )}
              </Box>
            </Container>
          </SmallCustomFormModel>
          {/* Modal for audio Popup */}
          <Modal
            open={snippetOpen}
            onClose={handleSnippetClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModal}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Snippet
              </Typography>
              {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </Typography> */}
              {snippetPlay ? (
                <audio
                  src={
                    process.env.REACT_APP_ON_PREMISE === 'true'
                      ? `${process.env.REACT_APP_SERVER_MEDIA_URL}${snippetPlay.replace(
                          '/app',
                          ''
                        )}`
                      : snippetPlay
                  }
                  controls
                >
                  <source
                    src={
                      process.env.REACT_APP_ON_PREMISE === 'true'
                        ? `${process.env.REACT_APP_SERVER_MEDIA_URL}${snippetPlay.replace(
                            '/app',
                            ''
                          )}`
                        : snippetPlay
                    }
                    // src={`${process.env.REACT_APP_SERVER_APP_URL}${dataForCockpit[3]}`}
                    type="audio/mp3"
                  />
                  <track default kind="captions" srcLang="en" />
                </audio>
              ) : (
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  No Snippet Found
                </Typography>
              )}
            </Box>
          </Modal>
          <Modal
            open={reasonOpen}
            // onClose={handleReasonClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModalForReason}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  margin: '2px'
                }}
              >
                <CloseIcon
                  sx={{
                    cursor: 'pointer',
                    color: '#3892CF',
                    border: '2px solid #3892CF',
                    // padding: '10px 20px',
                    // backgroundColor: 'transparent',
                    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                    '&:hover': {
                      backgroundColor: '#3892CF',
                      color: '#fff',
                      borderColor: '#3892CF'
                    }
                  }}
                  size="small"
                  onClick={handleReasonClose}
                />
              </div>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ maxHeight: 270, maxWidth: 600, overflowY: 'scroll' }}
              >
                {scoreCardData && scoreCardData.ai_feedback ? (
                  scoreCardData.ai_feedback.split('\n').map((el) => <Typography>{el}</Typography>)
                ) : (
                  <div>
                    {scoreValues.length > 0 &&
                      (averageScore >= 80
                        ? `Person is Good in ${scoreValues
                            .filter((item) => (item.score / item.weightage) * 100 >= 80)
                            .map((item) => item.Category)
                            .join(',')}`
                        : averageScore <= 80 &&
                          `Person Needs to improve in ${scoreValues
                            .filter((item) => (item.score / item.weightage) * 100 < 80)
                            .map((item) => item.Category)
                            .join(',')}`)}
                  </div>
                )}
              </Typography>
            </Box>
          </Modal>
          <Modal
            open={QRCOpen}
            // onClose={handleReasonClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModalForReason}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  margin: '2px'
                }}
              >
                <CloseIcon
                  sx={{
                    cursor: 'pointer',
                    color: '#3892CF',
                    border: '2px solid #3892CF',
                    // padding: '10px 20px',
                    // backgroundColor: 'transparent',
                    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                    '&:hover': {
                      backgroundColor: '#3892CF',
                      color: '#fff',
                      borderColor: '#3892CF'
                    }
                  }}
                  size="small"
                  onClick={handleDialogForQRC}
                />
              </div>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ maxHeight: 113, maxWidth: 600, overflowY: 'scroll' }}
              >
                {QRCList?.length > 0 && QRCList.join(',')}
              </Typography>
            </Box>
          </Modal>
        </Card>
      </Container>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}

function FeedbackEdit({
  Details,
  feedbackUpdate,
  setUpdateFeedback,
  setFeedback,
  feedback,
  inputId
}) {
  // console.log('res po positive', res);

  // console.log('getAudioList m', getAudioList);

  const token = localStorage.getItem('token').toString();
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const filterref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);

  const handleDialog = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };

  const handleDelete = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.delete(`/secure/delete_opportunity?id=${Details.opportunity_id}`, config)
      .then((res) => {
        console.log('delete opportunity res-', res);
        // setAlertContent(`Success : Organisation deleted successfully.`);
        // setAlertType('success');
        // setAlert(true);
      })
      .then(() => {
        setIsOpen(false);
        // updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        console.log(err);
        // setAlertContent(`${err.response.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };
  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={openEdit}
        title="Update Feedback"
      >
        <FeedbackEditForm
          // dashboardFormik={formik}
          // isUpdated={update}
          // updateComponent={setUpdate}
          // setAlert={setAlert}
          // setAlertContent={setAlertContent}
          // setAlertType={setAlertType}
          inputId={inputId}
          feedback={feedback}
          setFeedback={setFeedback}
          inputDetails={Details}
          handleDialog={handleDialog}
          feedbackUpdate={feedbackUpdate}
          setUpdateFeedback={setUpdateFeedback}
        />
      </CustomFormModel>
      <IconButton ref={filterref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={filterref.current}
        onClose={(event) => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDialog}>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {/* <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            handleDelete();
          }}
          // disabled={res.lead_status === 'approved'}
        >
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
      </Menu>
    </>
  );
}

const FeedbackEditForm = ({
  handleDialog,
  //   dashboardFormik,
  // isUpdated,
  // updateComponent,
  inputId,
  feedback,
  setFeedback,
  inputDetails,
  setUpdateFeedback,
  updateFeedback
}) => {
  // console.log('inputDetails', inputDetails);
  // use formik for task .
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: userDetails.role === 'QA' ? userDetails.customer_id : inputDetails.customer_id,
      organisationId: `${
        userDetails.role !== 'Super_Admin'
          ? userDetails.organisation_id
          : inputDetails.organisation_id
      }`,
      agent: inputDetails.agent_id,
      feedback: inputDetails.feedback,
      rating: inputDetails.rating
    },
    validationSchema: Yup.object().shape({
      // customer: Yup.string().required('This field is required.'),
      // organisationId: Yup.string().required('This field is required.'),
      //   agent: Yup.string().required('This field is required.'),
      feedback: Yup.string()
        .test('valid-feedback-chars', 'Invalid characters in feedback.', (value) => {
          if (!/^[a-zA-Z0-9.,!?'"() \t\n\r-]*$/.test(value)) {
            return false; // Indicates an error
          }
          return true; // No error
        })
        .test('minimum-length', 'Feedback is too short.', (value) => {
          if (value && value.length < 3) {
            return false; // Indicates an error
          }
          return true; // No error
        })
        .test('no-blank-space', 'Feedback cannot be just blank spaces.', (value) => {
          if (/^\s*$/.test(value)) {
            return false; // Indicates an error
          }
          return true; // No error
        })
        .test(
          'no-leading-trailing-space',
          'Feedback cannot start or end with a blank space.',
          (value) => {
            if (value && (value.startsWith(' ') || value.endsWith(' '))) {
              return false; // Indicates an error
            }
            return true; // No error
          }
        )
        .max(3000, 'Feedback cannot exceed 3000 characters.')
        .required('Feedback is required.')
      // .matches(
      //   /^\S+[A-Za-z\s]{2,3000}$/,
      //   'Feedback should be in between 3 to 3000 character and it should not start with space and does not contain special character and number.'
      // )
      // .max(3000, 'feedback should be 3 to 3000 character long.')
      // .required('This field is required.')
      //   comment: Yup.string()
      //     .matches(
      //       /^\S+[A-Za-z\s]{2,50}$/,
      //       'Comment should be in between 3 to 50 character and it should not start with space and does not contain special character and number.'
      //     )
      //     .max(50, 'Comment should be 3 to 50 character long.')
      //     .required('This field is required.'),
      //   status: Yup.string().required('This field is required.')
    }),
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        // customer_id: dashboardFormik.values.customer,
        // user_id: dashboardFormik.values.campaign,
        // organisation_id: dashboardFormik.values.organisation,
        feedback_desc: formik.values.feedback,
        rating: formik.values.rating,
        id: inputDetails.feedback_id
        // opportunity_status: formik.values.status,
        // id: inputDetails.opportunity_id
        // input_id,
        // deal_id
      };
      // console.log('data ---------------------', data);
      await API.put('/secure/update_feedback', data, config)
        .then((res) => {
          formik.resetForm();
          // handleRunProcess(res.data.id);
          //   navigate('/dashboard/admin/task');
          // setAlertContent(`Success: ${res.data.message}`);
          // setAlertType('success');
          // setAlert(true);
          handleDialog();
          setUpdateFeedback(updateFeedback + 1);
          API.get(`secure/new_cockpit_feedback?input_id=${inputId}`, config).then((res) => {
            setFeedback(res.data.feedback);
          });
          // updateComponent(isUpdated + 1);
        })
        .catch((err) => {
          console.log('error----', err);
          //   setAlertContent(`${err.response.data.message}`);
          //   setAlertType('error');
          //   setAlert(true);
        });
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const [userAudio, setuserAudio] = useState({ File_Name: '', Data: '' });

  /* ----- Creating state for customer, process and agent ------ */
  const [customerList, setcustomerList] = useState();
  const [processList, setprocessList] = useState();
  const [agentList, setagentList] = useState();
  const [OrganisationList, setOrganisationList] = useState();
  const [checkInDate, setCheckInDate] = useState(null);
  // const [alert, setAlert] = useState(false);
  // const [alertContent, setAlertContent] = useState('');
  // const [alertType, setAlertType] = useState('');

  const statusList = [
    { status_id: 'completed', status_name: 'Completed' },
    { status_id: 'assigned', status_name: 'Assigned' }
  ];

  const handleCancel = () => {
    handleDialog();
    formik.resetForm();
  };
  // const handleAlert = () => {
  //   setAlert(false);
  // };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/list_of_customer?organisation_id=${formik.values.organisationId}`,
      config
    )
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.organisationId !== '') {
      getCustomerList();
    } else {
      setcustomerList([]);
    }
  }, [formik.values.organisationId]);

  const getAgentList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getRole() {
      return API.get(
        `secure/list_of_agent?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisationId}`,
        config
      );
    }
    Promise.all([getRole()])
      .then(([agentData]) => {
        setagentList(agentData.data.agent_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (formik.values.customer !== '') {
      getAgentList();
    } else {
      setagentList([]);
    }
  }, [formik.values.customer]);

  const setCustomerValue = (value) => {
    formik.setFieldValue('customer', value.customer_id);
  };

  // console.log('first', formik.values);

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));
  const classes = useStyles();

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={6}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Select
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  {...getFieldProps('organisationId')}
                  error={Boolean(touched.organisationId && errors.organisationId)}
                  helperText={touched.organisationId && errors.organisationId}
                  disabled
                >
                  {OrganisationList
                    ? OrganisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.organisationId && errors.organisationId}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Select
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  {...getFieldProps('customer')}
                  error={Boolean(touched.customer && errors.customer)}
                  helperText={touched.customer && errors.customer}
                  disabled
                  // onChange={(e) => setCustomerValue(e.target.value)}
                >
                  {customerList
                    ? customerList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={customer_id}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.customer && errors.customer}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="agent-select-label">
                Select Agent
              </InputLabel>
              <Select
                required
                labelId="agent-select-label"
                label="Select Agent"
                id="select-agent"
                {...getFieldProps('agent')}
                error={Boolean(touched.agent && errors.agent)}
                helperText={touched.agent && errors.agent}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                disabled
              >
                {agentList
                  ? agentList.map((element) => {
                      const { user_id, first_name, last_name, empcode } = element;
                      return (
                        <MenuItem key={user_id} value={user_id}>
                          {empcode} - {first_name} {last_name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.agent && errors.agent}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {/* <InputLabel id="agent-select-label">Rating</InputLabel> */}
            <Box
              sx={{
                border: '1px solid RGB(220 224 228)',
                borderRadius: '5px',
                height: 54,
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center'
              }}
            >
              <Rating
                name="size-large"
                defaultValue={inputDetails.rating}
                size="large"
                onChange={(e) => {
                  // console.log('ratingfield', e.target.value);
                  formik.setFieldValue('rating', e.target.value);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                fullWidth
                label=" Feedback"
                rows={2}
                multiline
                {...getFieldProps('feedback')}
                error={Boolean(touched.feedback && errors.feedback)}
                helperText={touched.feedback && errors.feedback}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleCancel}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={isSubmitting}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Submit
          </LoadingButton>
        </Container>
        {/* <Notification
          message={alertContent}
          open={alert}
          severity={alertType}
          onClose={handleAlert}
        /> */}
      </Form>
    </FormikProvider>
  );
};
