/* eslint-disable   arrow-body-style */
import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { FormikProvider, Form, FieldArray } from 'formik';
import {
  Grid,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Container,
  FormHelperText,
  Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import makeStyles from '@mui/styles/makeStyles';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import API from '../../E2E/axios.util';

// -------------------------------------------------------
// const categoryList = [
//   { name: 'Unpleasant', id: 'Unpleasant' },
//   { name: 'Lead Identifiers', id: 'Lead_Identifier' }
// ];

export default function DomainForm({ formik, handleDialog }) {
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const [organisationList, setorganisationList] = useState([]);
  const [customerOrgList, setcustomerOrgList] = useState([]);
  const [domainList, setDomainList] = useState();
  const [customerName, setCustomerName] = useState();
  const [services, setServices] = useState([]);

  const categoryList = [];
  if (services.includes('sales')) {
    categoryList.push({ name: 'Lead Identifiers', id: 'Lead_Identifier' });
  } else if (services.includes('collections')) {
    categoryList.push({ name: 'Unpleasant', id: 'Unpleasant' });
  }

  const userDetails = JSON.parse(localStorage.getItem('user'));

  const handleCancel = () => {
    handleDialog();
    formik.resetForm();
  };

  const handleDomain = (domain) => {
    formik.setFieldValue('domain', domain);
  };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setorganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${formik.values.organisation}`, config)
      .then((res) => {
        setcustomerOrgList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.organisation !== '') {
      setCustomerName();
      formik.setFieldValue('customerOrganisation', '');
      formik.setFieldValue('domain', '');
      getCustomerList();
    } else {
      setcustomerOrgList([]);
    }
  }, [formik.values.organisation]);

  const getDomainList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/get_domain_info?customer_id=${formik.values.customerOrganisation}&org_id=${formik.values.organisation}`,
      config
    )
      .then((res) => {
        setDomainList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.customerOrganisation) {
      formik.setFieldValue('domain', '');
      getDomainList();
    } else {
      setDomainList();
    }
  }, [formik.values.customerOrganisation]);

  // -----------------------------------------------------------
  // handlecustomer values.

  const handleCustomerChange = (e) => {
    setCustomerName(e.customer_organisation_name);
    formik.setFieldValue('customerOrganisation', e.customer_id);
    setServices(e.dashboard_services);
  };

  // console.warn('formik.values.keywords -->', formik.values.keywords);
  // console.warn('formik,values.sentence ------>', formik.values.sentences);

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));

  const classes = useStyles();

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Select
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  {...getFieldProps('organisation')}
                  error={Boolean(touched.organisation && errors.organisation)}
                  helperText={touched.organisation && errors.organisation}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {organisationList
                    ? organisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.organisation && errors.organisation}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Select
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  // {...getFieldProps('customerOrganisation')}
                  value={customerName}
                  onChange={(e) => handleCustomerChange(e.target.value)}
                  error={Boolean(touched.customerOrganisation && errors.customerOrganisation)}
                  helperText={touched.customerOrganisation && errors.customerOrganisation}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {customerOrgList
                    ? customerOrgList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={element}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.customerOrganisation && errors.customerOrganisation}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="domain-select-label">
                Select Domain
              </InputLabel>
              <Select
                required
                labelId="domain-select-label"
                label="Select Domain"
                id="select-domain"
                value={formik.values.domain}
                error={Boolean(touched.domain && errors.domain)}
                helperText={touched.domain && errors.domain}
                onChange={(e) => handleDomain(e.target.value)}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {domainList ? (
                  <MenuItem value={domainList}>{domainList.domain_name}</MenuItem>
                ) : (
                  <MenuItem>No domain found</MenuItem>
                )}
              </Select>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.domain && errors.domain}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FieldArray
              name="domainCategories"
              render={(arrayHelpers) => (
                <>
                  {formik.values.domainCategories.map((row, index) => {
                    console.log('row ---', row);
                    console.log('index--', index);
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '5px'
                        }}
                      >
                        <TextField
                          required
                          fullWidth
                          label="Domain Category"
                          {...getFieldProps(`domainCategories[${index}]`)}
                          error={Boolean(
                            touched &&
                              touched.domainCategories &&
                              touched.domainCategories[index] &&
                              touched.domainCategories[index] &&
                              errors &&
                              errors.domainCategories &&
                              errors.domainCategories[index] &&
                              errors.domainCategories[index]
                          )}
                          helperText={
                            touched &&
                            touched.domainCategories &&
                            touched.domainCategories[index] &&
                            touched.domainCategories[index] &&
                            errors &&
                            errors.domainCategories &&
                            errors.domainCategories[index] &&
                            errors.domainCategories[index]
                          }
                        />
                        {index === formik.values.domainCategories.length - 1 ? (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: `${index > 0 ? 'visible' : 'hidden'}`
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: 'hidden'
                              }}
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            />
          </Grid>
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleCancel}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            // loading={isSubmitting}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Add
          </LoadingButton>
        </Container>
      </Form>
    </FormikProvider>
  );
}
