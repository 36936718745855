import React, { useState, useRef, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { FormikProvider, Form } from 'formik';
import {
  Grid,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Container,
  FormHelperText,
  Alert,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Lottie from 'react-lottie';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { LoadingButton } from '@mui/lab';
import LinearProgress, { LinearProgressProps as props } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import DatePicker from 'react-datepicker';
import './UploadForm.css';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import API from '../../E2E/axios.util';
import { Notification } from '../../components/Notification/Notification';
import UploadGif from '../../components/animate/animations/upload.json';
// components

// ----------------------------------------------------------------------

const uploadOptions = {
  loop: true,
  autoplay: true,
  animationData: UploadGif,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const UploadFormGuest = ({ formik, handleDialog, uploaded }) => {
  const inputRef = useRef(null);
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const [userAudio, setuserAudio] = useState({ File_Name: '', Data: '' });

  const userDetails = JSON.parse(localStorage.getItem('user'));

  /* ----- Creating state for customer, process and agent ------ */
  const [customerList, setcustomerList] = useState();
  const [processList, setprocessList] = useState();
  const [campaignList, setcampaignList] = useState([]);
  const [agentList, setagentList] = useState();
  const [OrganisationList, setOrganisationList] = useState();
  const [checkInDate, setCheckInDate] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');

  const handleCancel = () => {
    handleDialog();
    formik.resetForm();
  };
  const handleAlert = () => {
    setAlert(false);
  };

  const handleAudioFileChange = (event, fileName) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (fileReader.readyState === 2) {
        const file = e.target.result;
        setuserAudio({ File_Name: fileName.name, Data: file });
        formik.setFieldValue(`uploadFile`, { File_Name: fileName.name, Data: file });
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/list_of_customer?organisation_id=${formik.values.organisationId}`,
      config
    )
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.organisationId !== '') {
      getCustomerList();
    } else {
      setcustomerList([]);
    }
  }, [formik.values.organisationId]);

  const getProccesList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `secure/process_list?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisationId}`,
        config
      );
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCampaignList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getCampaign() {
      return API.get(
        `secure/get_campaign?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisationId}`,
        config
      );
    }
    Promise.all([getCampaign()])
      .then(([processData]) => {
        setcampaignList(processData.data.campaign_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.customer !== '') {
      getProccesList();
      getCampaignList();
    } else {
      setprocessList([]);
      setcampaignList([]);
    }
  }, [formik.values.customer]);

  const getAgentList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getRole() {
      return API.get(
        `secure/list_of_agent?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisationId}&processes=${formik.values.process}`,
        config
      );
    }
    Promise.all([getRole()])
      .then(([agentData]) => {
        setagentList(agentData.data.agent_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log(formik.values.process, 'formik.values.process');
    if (formik.values.process.length !== 0) {
      getAgentList();
    } else {
      setagentList([]);
    }
  }, [formik.values.process]);

  const setCustomerValue = (value) => {
    formik.setFieldValue('customer', value.customer_id);
    formik.setFieldValue('domainId', value.domain_id);
    formik.setFieldValue('campaign', null);
    // setcampaignList([]);
  };

  const handleProcess = (processValue) => {
    formik.setFieldValue('process', [processValue]);
  };
  const handleCampaign = (campaign) => {
    formik.setFieldValue('campaign', campaign);
  };

  // ---------------------------------------------
  const [audios, setAudios] = useState(formik.values.uploadFile);
  const audioChange = (e) => {
    for (let i = 0; i < e.target.files.length; i += 1) {
      const file_type = e.target.files[i].type.substring(0, 6);
      if (file_type !== 'audio/') {
        setAlertContent('Invalid file');
        setAlertType('error');
        setAlert(true);
        return;
      }
    }

    // console.log('e.target.files --------->', e.target.files);

    for (
      let i = 0;
      i < process.env.REACT_APP_FILEUPLOAD_LIMIT_FOR_GUEST - formik.values.uploadFile.length;
      // i < e.target.files.length;
      i += 1
    ) {
      // Closure to capture the file information.
      (function (file) {
        const reader = new FileReader();
        const fileName = file.name;

        reader.onload = function (upload) {
          const img = upload.target.result;
          if (!formik.values.uploadFile.includes(img)) {
            setAudios((prevData) => [...prevData, { File_Name: fileName, Data: img }]);
          }
        };
        reader.readAsDataURL(file);
      })(e.target.files[i]);
    }

    if (
      e.target.files.length >
      process.env.REACT_APP_FILEUPLOAD_LIMIT_FOR_GUEST - formik.values.uploadFile.length
    ) {
      setAlertContent(
        `Maximum ${process.env.REACT_APP_FILEUPLOAD_LIMIT_FOR_GUEST} files can uploaded`
      );
      setAlertType('error');
      setAlert(true);
    }
  };

  useEffect(() => {
    formik.setFieldValue('uploadFile', audios);
  }, [audios]);

  const audioRemove = (index) => {
    let images1 = Array.from(formik.values.uploadFile);
    images1 = images1.filter((img) => images1.indexOf(img) !== index);
    setAudios(images1);
  };

  const setDateFormat = (date) => {
    const formatStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return formatStartDate;
  };

  const CustomInput = React.forwardRef((props, ref) => (
    <div style={{ position: 'relative' }}>
      <input {...props} ref={ref} />
      <CalendarMonthIcon
        onClick={props.onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '10px',
          // pointerEvents: 'none',
          cursor: 'pointer',
          zIndex: 100
        }}
      />
    </div>
  ));

  const handleCheckInDate = (date) => {
    setCheckInDate(date);
    const start_date = setDateFormat(date);
    formik.setFieldValue('createDate', start_date);
  };

  // console.warn('audio file ---', formik.values.inputFile);
  // console.log('formik.values.uploadFile', formik.values.uploadFile.length);

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));
  const classes = useStyles();
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={1} padding={1}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              style={{
                width: '60%',
                border: '1px dashed black',
                padding: 10,
                paddingLeft: 30,
                paddingRight: 30
              }}
            >
              <Lottie options={uploadOptions} height={150} width={150} />
              {formik.values.uploadFile.length < 5 ? (
                <LoadingButton
                  fullWidth
                  variant="contained"
                  // size="large"
                  sx={{
                    height: 50,
                    marginTop: 1,
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                >
                  {!userAudio.File_Name ? 'Upload Audio' : userAudio.File_Name}
                  <input
                    type="file"
                    hidden
                    accept="audio/*"
                    multiple
                    ref={inputRef}
                    onChange={(event) => {
                      // handleAudioFileChange(event, event.currentTarget.files[0]);
                      console.log('files data ----', event);
                      audioChange(event);
                    }}
                    required
                  />
                </LoadingButton>
              ) : (
                <LoadingButton
                  onClick={() => {
                    setAlertContent('Maximum File Upload limit is 20');
                    setAlertType('error');
                    setAlert(true);
                  }}
                  fullWidth
                  variant="contained"
                  // size="large"
                  sx={{
                    height: 50,
                    marginTop: 1,
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                >
                  {!userAudio.File_Name ? 'Upload Audio' : userAudio.File_Name}
                </LoadingButton>
              )}
            </Box>
          </Grid>
          <br />
          {formik.values.uploadFile.map((data, key) => {
            console.log('images inside map ------', data);
            return (
              <Alert
                severity="info"
                key={key}
                icon={false}
                sx={{ marginLeft: '23px', marginY: '3px' }}
                onClose={() => {
                  audioRemove(key);
                }}
              >
                {data.File_Name}
              </Alert>
            );
          })}
          {/* {formik.values.uploadFile.map((data, key) => (
            <Grid item xs={6}>
              <Alert
                severity="info"
                key={key}
                icon={false}
                sx={{ marginY: '10px' }}
                onClose={() => {
                  audioRemove(key);
                }}
              >
                {data.File_Name}
              </Alert>
            </Grid>
          ))} */}
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleCancel}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={isSubmitting}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Submit
          </LoadingButton>
          {isSubmitting && (
            <Grid style={{ marginLeft: '5px', display: 'flex', justifyContent: 'flex-end' }}>
              <LinearProgressWithLabel variant="determinate" value={uploaded} />
            </Grid>
          )}
        </Container>
        <Notification
          message={alertContent}
          open={alert}
          severity={alertType}
          onClose={handleAlert}
        />
      </Form>
    </FormikProvider>
  );
};

export default UploadFormGuest;

function LinearProgressWithLabel(props, value) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {/* <Box sx={{ width: '50%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box> */}
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
