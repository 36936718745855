import { React, useState } from 'react';
import './tableComponent.css';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Grid,
  Card,
  Stack,
  Pagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from '@mui/material';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import Scrollbar from '../Scrollbar';

function DatewiseProgressTable({ TableinputData, tablecolData, loading }) {
  const columnHeadings = {
    call_date: 'Date',
    ai_count: 'AI Count',
    average_ai_score: 'Average AI Score',
    average_manual_score: 'Average Manual Score',
    manual_count: 'Manual Count'
  };

  const getProgressBarClass = (value) => {
    if (value >= 75) {
      return 'progress-bar-fill-high';
    }
    if (value >= 50) {
      return 'progress-bar-fill-medium';
    }
    return 'progress-bar-fill-low';
  };

  const changedDate = (date) => {
    console.log('date format', date);
    const formatDate = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const newdate = new Date(formatDate);

    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return newdate.toLocaleDateString(undefined, options);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const renderCellContent = (colName, item) => {
    if (colName.includes('date') || colName.includes('Date')) {
      return formatDate(item[colName]);
    }
    if (colName.includes('score')) {
      return (
        <div className="progress-bar">
          <div
            className={`progress-bar-fill ${getProgressBarClass(item[colName])}`}
            style={{ width: `${item[colName]}%` }}
          >
            {item[colName]}%
          </div>
        </div>
      );
    }
    return item[colName];
  };

  // pagination

  const useStyles = makeStyles((theme) => ({
    // root: {
    //   '& .MuiTabs-indicator': {
    //     backgroundColor: '#1D9FD9' // change this to your desired color
    //   }
    // },
    // tab: {
    //   '&.Mui-selected': {
    //     color: '#1D9FD9' // change this to your desired color
    //   }
    // },
    ul: {
      '& .MuiPaginationItem-root': {
        '&.Mui-selected': {
          background: '#3892CF',
          color: 'white'
          // borderRadius: '50%',
        }
      }
    },
    menuScoreCard: {
      maxHeight: 150,
      maxWidth: 50
    },
    datePicker: {
      '& .MuiTextField-root': {
        width: 170
        // marginBottom: 1
        // height: 10
      },
      '& .MuiInputLabel-shrink': {
        color: '#3892CF' // Set your desired color for the shrunk label
      },
      '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#3892CF'
      }
    }
  }));
  const classes = useStyles();

  const [page, setPage] = useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid item xs={12} md={12}>
      <Card>
        {TableinputData && TableinputData.length > 0 && !loading ? (
          <Scrollbar>
            <TableContainer
              sx={{ width: '100%', maxHeight: 450, overflowY: 'auto', overflowX: 'off' }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tablecolData.map((colName, index) => (
                      <TableCell
                        key={index}
                        align="center"
                        sx={{
                          color: '#1F2937',
                          fontWeight: 'bold',
                          fontFamily: 'Public Sans, sans-serif',
                          backgroundColor: '#f8f6fa'
                        }}
                      >
                        {columnHeadings[colName] || colName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TableinputData.map((item, index) => (
                    <TableRow key={index} className="tablecontent">
                      {tablecolData.map((colName, colIndex) => (
                        <TableCell key={colIndex} align="center">
                          {renderCellContent(colName, item)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 300
            }}
          >
            {loading ? (
              <CircularProgress sx={{ color: '#3892CF' }} />
            ) : (
              <Typography> Data Not Found</Typography>
            )}
          </div>
        )}
      </Card>
    </Grid>
  );
}

export default DatewiseProgressTable;
