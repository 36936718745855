import { useEffect, useState } from 'react';

/**
 * Common hook for web socket, it handles socket connection, message, open, close events
 * @type {number}
 */
let timeout = 250;
const useWebSocket = (inputId) => {
  const [websocket, setWebSocket] = useState(null);
  const [message, setMessage] = useState(null);
  /**
   * @function connect
   * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
   */
  const connect = () => {
    // console.log('Provided Key: ', inputId);
    const ws = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET}ws/chat/${inputId}/`);
    let connectInterval;

    // websocket onopen event listener
    ws.onopen = () => {
      console.log('Connection established');
      setWebSocket(ws);
      timeout = 250; // reset timer to 250 on open of websocket connection
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };

    ws.onmessage = (evt) => {
      console.warn('Message from server ', evt.data);
      // listen to data sent from the websocket server
      const message = JSON.parse(evt.data);
      setMessage(message);
    };

    // websocket onclose event listener
    ws.onclose = (e) => {
      timeout += timeout;
      connectInterval = setTimeout(checkConnection, Math.min(10000, timeout));
      console.log('Connection closed, trying to reconnect in ', timeout, 'ms');
    };

    // websocket onerror event listener
    ws.onerror = (err) => {
      console.log(err);
      ws.close();
    };
  };

  /**
   * utilised by the @function connect to check if the connection is close, if so attempts to reconnect
   */
  const checkConnection = () => {
    if (!websocket || websocket.readyState === WebSocket.CLOSED) connect();
  };

  useEffect(() => {
    connect();

    return () => {
      if (websocket) {
        websocket.close();
      }
    };
  }, []);

  return [message];
};

export default useWebSocket;
