/* eslint-disable  no-unneeded-ternary */
/* eslint-disable  prefer-arrow-callback */
import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useNavigate } from 'react-router-dom';
import { FormikProvider, Form, FieldArray, useFormik } from 'formik';
import {
  Grid,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
  TextField,
  Container,
  Button,
  FormHelperText,
  Box,
  Tooltip,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import arrowLeft from '@iconify/icons-mdi/arrow-left';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import makeStyles from '@mui/styles/makeStyles';
import { CloseRounded } from '@mui/icons-material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import API from '../../E2E/axios.util';
import ParameterItem from './ParameterItem';
import { CustomFormModel } from '../../components/custom-form-model/CustomFormModel';
import { Notification } from '../../components/Notification/Notification';

// -------------------------------------------------------
// const categoryList = [
//   { name: 'Unpleasant', id: 'Unpleasant' },
//   { name: 'Lead Identifiers', id: 'Lead_Identifier' }
// ];

export default function KeywordForm() {
  const [organisationList, setorganisationList] = useState([]);
  const [customerOrgList, setcustomerOrgList] = useState([]);
  const [processList, setprocessList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [processName, setprocessName] = useState();
  const [customerName, setCustomerName] = useState();
  const [services, setServices] = useState([]);
  const [open, setOpen] = useState(false);
  const [parameters, setParameters] = useState([]);
  console.log('parameters', parameters);
  const [paramIndex, setParamIndex] = useState();
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  // const [parameterShown, setShownParameter] = useState('');

  const parameterList = [
    { name: 'Greeting & Introduced Self', type: 'Multivalue', max_score: 3 },
    { name: 'Introduction of Company', type: 'Multivalue', max_score: 0 },
    { name: 'Identification of Right Party Contact (RPC)', type: 'Multivalue', max_score: 0 },
    { name: 'Reaching RPC', type: 'Multivalue', max_score: 3 },
    { name: 'Purpose of Call', type: 'Boolean', max_score: 1 },
    { name: 'Comprehending the Customer', type: 'Boolean', max_score: 5 },
    { name: 'Effective Probing', type: 'Multivalue', max_score: 7 },
    { name: 'Creating Urgency', type: 'Multivalue', max_score: 10 },
    { name: 'Objection Handling', type: 'Multivalue', max_score: 10 },
    { name: 'Script Adherence', type: 'Multivalue', max_score: 10 },
    { name: 'Call Control', type: 'Boolean', max_score: 5 },
    { name: 'Complete and Correct Information (Minor Impact)', type: 'Multivalue', max_score: 8 },
    { name: 'Reason for Delay (RFD)', type: 'Multivalue', max_score: 5 },
    { name: 'Wrong Information/False Commitment', type: 'Multivalue', max_score: 0 },
    { name: 'Summarization', type: 'Multivalue', max_score: 2 },
    { name: 'Thanking the Customer', type: 'Multivalue', max_score: 1 },
    { name: 'Disposition', type: 'Boolean', max_score: 0 },
    { name: 'Active Listening', type: 'Boolean', max_score: 4 },
    { name: 'Empathy', type: 'Multivalue', max_score: 2 },
    { name: 'Confidence', type: 'Multivalue', max_score: 4 },
    { name: 'Professional', type: 'Boolean', max_score: 4 },
    { name: 'Language and Grammar', type: 'Boolean', max_score: 4 },
    { name: 'Clarity of Speech', type: 'Boolean', max_score: 4 },
    { name: 'Tone and Voice Modulation', type: 'Boolean', max_score: 4 },
    { name: 'Hold Procedure', type: 'Boolean', max_score: 4 },
    { name: 'Avoid being Rude and Unprofessional', type: 'Boolean', max_score: 0 },
    { name: 'Call avoidance', type: 'Boolean', max_score: 0 },
    { name: 'Verification / Security Check / Authentication', type: 'Boolean', max_score: 0 },
    { name: 'System downtime', type: 'Boolean', max_score: 0 },
    { name: 'Confirm KYC details before closing', type: 'Boolean', max_score: 0 }
  ];

  const categoryList = ['Soft Skill', 'Critical Skill'];
  const FieldType = [{ name: 'Boolean' }, { name: 'Multivalue' }];
  //   if (services.includes('sales')) {
  //     categoryList.push({ name: 'Lead Identifiers', id: 'Lead_Identifier' });
  //   } else if (services.includes('collections')) {
  //     categoryList.push({ name: 'Unpleasant', id: 'Unpleasant' });
  //   }

  const userDetails = JSON.parse(localStorage.getItem('user'));

  const KeywordSchema = Yup.object().shape({
    organisation: Yup.string().required('This field is required.'),
    customerOrganisation: Yup.string().required('This field is required.'),
    // sentences: Yup.array().of(
    //   Yup.object({
    //     sentence: Yup.string().required('This field is required.'),
    //     Category: Yup.string().required('This field is required.')
    //   })
    // ),
    // keywords: Yup.array().of(Yup.string().required('This field is required.'))
    process: Yup.string().required('This field is required.'),
    // process: Yup.string().required('This field is required.'),
    keywords: Yup.array()
      .of(
        Yup.object({
          description: Yup.array().of(
            Yup.object({
              // language: Yup.string().required('This field is required.'),
              phrases: Yup.array().of(
                Yup.string()
                  .max(500, 'Max 500 alphabets are allowed')
                  .required('This field is required.')
              )
            })
          ),
          Category: Yup.string()
            .required('This field is required.')
            .test('unique-category', 'Category should be unique.', function (value) {
              const categories = formik.values.keywords.map((keyword) =>
                keyword.Category?.toLowerCase()
              );
              const lowercaseValue = value?.toLowerCase();

              return categories.filter((category) => category === lowercaseValue).length === 1;
            }),
          Parameter: Yup.string().required('This field is required.'),
          weightage: Yup.string().required('This field is required.'),
          Type: Yup.string().required('This field is required.')
        })
      )
      .min(1, 'This field is required.')
    // keywordCategory: Yup.array().of(Yup.string().required('This field is required.')),
    // sentenceCategory: Yup.array().of(Yup.string().required('This field is required.'))
  });

  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      organisation: `${userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : ''}`,
      customerOrganisation:
        userDetails.role === 'QA' || userDetails.role === 'Customer_Admin'
          ? userDetails.customer_id
          : '',
      process: '',
      keywords: [
        // {
        //   description: [{ language: '', phrases: [''] }],
        //   Parameter: '',
        //   Category: '',
        //   Type: '',
        //   Value: [''],
        //   weightage: '',
        //   aiMode: false
        // }
      ]
      // sentences: [
      //   {
      //     sentence: '',
      //     Category: ''
      //   }
      // ]
    },
    validationSchema: KeywordSchema,
    onSubmit: async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        processes: formik.values.process,
        organisation_id: formik.values.organisation,
        customer_id: formik.values.customerOrganisation,
        // sentences: formik.values.sentences,
        questions: formik.values.keywords
      };
      await API.post('/secure/add_compliance', data, config)
        .then((res) => {
          // formik.resetForm();
          // setParameters([]);
          // setCustomerName('');
          // // handleDialog();
          navigate('/dashboard/workspace/compliance');
          setAlertContent(`${res.data.message}`);
          setAlertType('success');
          setAlert(true);
        })
        .catch((err) => {
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
        });
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const handleDialog = () => {
    if (open === true) {
      if (Object.keys(formik.errors).length > 0) {
        formik.setFieldValue('keywords', parameters);
      }
    }
    setOpen(!open);

    // formik.resetForm();
  };

  const handleCancel = () => {
    // handleDialog();
    setCustomerName();
    formik.resetForm();
    setParameters([]);
  };
  const handleAlert = () => {
    setAlert(false);
  };

  const handleProcess = (processValue, index) => {
    // console.log('first---', index);
    formik.setFieldValue('process', processValue);
    setprocessName(processValue);
  };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setorganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/list_of_customer?organisation_id=${formik.values.organisation}`, config)
      .then((res) => {
        setcustomerOrgList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.organisation !== '') {
      getCustomerList();
    } else {
      setcustomerOrgList([]);
    }
  }, [formik.values.organisation]);

  const getProcessList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `/secure/process_list?customer_id=${formik.values.customerOrganisation}&organisation_id=${formik.values.organisation}`,
        config
      );
    }
    function getLanguage() {
      return API.get(
        `/secure/language_list?customer_id=${formik.values.customerOrganisation}`,
        config
      );
    }
    Promise.all([getProcess(), getLanguage()])
      .then(([processData, languageRes]) => {
        setprocessList(processData.data.processes);
        setLanguageList(languageRes.data.language_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.customerOrganisation) {
      getProcessList();
    } else {
      setprocessList([]);
    }
  }, [formik.values.customerOrganisation]);

  // -----------------------------------------------------------
  // handlecustomer values.

  const handleCustomerChange = (e) => {
    setCustomerName(e.customer_organisation_name);
    formik.setFieldValue('customerOrganisation', e.customer_id);
    setServices(e.dashboard_services);
  };
  const handleParameter = (e, index) => {
    // setShownParameter(e.target.value);
    formik.setFieldValue(`keywords[${index}].Category`, e.target.value);
    const selectedValue = e.target.value;
    const selectedParameter = parameterList.find((param) => param.name === selectedValue);
    if (selectedParameter) {
      formik.setFieldValue(`keywords[${index}].weightage`, selectedParameter.max_score);
    }
  };

  console.log('formik.values', formik);

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200,
      maxWidth: 80
    }
  }));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <FormikProvider value={formik}>
      <Stack flexDirection="initial" alignItems="center" mb={5}>
        <LoadingButton
          startIcon={<Icon icon={arrowLeft} />}
          variant="contained"
          // color="primary"
          style={{
            marginRight: 40,
            backgroundColor: '#3892CF',
            '&:hover': {
              backgroundColor: '#3892CF'
            }
          }}
          size="small"
          onClick={() => navigate('/dashboard/workspace/compliance')}
        >
          Back
        </LoadingButton>
        <Typography variant="h4" gutterBottom>
          Add Script
        </Typography>
      </Stack>

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Select
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  {...getFieldProps('organisation')}
                  error={Boolean(touched.organisation && errors.organisation)}
                  helperText={touched.organisation && errors.organisation}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {organisationList
                    ? organisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.organisation && errors.organisation}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' || userDetails.role === 'Customer_Admin' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Select
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  // name="customerOrganisation"
                  {...getFieldProps('customerOrganisation')}
                  value={customerName}
                  onChange={(e) => handleCustomerChange(e.target.value)}
                  error={Boolean(touched.customerOrganisation && errors.customerOrganisation)}
                  helperText={touched.customerOrganisation && errors.customerOrganisation}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {customerOrgList
                    ? customerOrgList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={element}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.customerOrganisation && errors.customerOrganisation}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel required id="process-select-label">
                Select Process
              </InputLabel>
              <Select
                required
                labelId="process-select-label"
                label="Select Process"
                id="select-process"
                // name="process"
                {...getFieldProps('process')}
                error={Boolean(touched.process && errors.process)}
                value={formik.values.process}
                helperText={touched.process && errors.process}
                onChange={(e) => handleProcess(e.target.value)}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {processList
                  ? processList.map((element, index) => {
                      const process = Object.values(element)[0];
                      return (
                        <MenuItem key={element} value={element}>
                          {element}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
              <FormHelperText sx={{ color: '#d32f2f' }}>
                {touched.process && errors.process}
              </FormHelperText>
            </FormControl>
          </Grid>
          {parameters.length > 0 &&
            parameters.map((el, index) => (
              <>
                {el.Category.length > 0 && (
                  <Grid
                    item
                    sm={1.2}
                    xs={1.2}
                    md={1.2}
                    lg={1.2}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        formik.setFieldValue(`keywords[${index}]`, el);
                        setParamIndex(index);
                        handleDialog();
                      }}
                      sx={{
                        backgroundColor: '#3892CF',
                        marginRight: '5px',
                        '&:hover': {
                          backgroundColor: '#3892CF'
                        }
                      }}
                      size="small"
                    >
                      {el.Category.length > 4 ? (
                        <Tooltip title={el.Category} followCursor placement="top">
                          {`${el.Category.substring(0, 5)}...`}
                        </Tooltip>
                      ) : (
                        el.Category
                      )}
                    </Button>
                    <CloseRounded
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        parameters.splice(index, 1);
                        formik.setFieldValue(`keywords`, parameters);
                      }}
                    />
                  </Grid>
                )}
              </>
            ))}
          <Grid
            item
            sm={2.5}
            xs={2.5}
            md={2.5}
            lg={2.5}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Button
              variant="contained"
              size="small"
              disabled={formik.values.process.length === 0 && formik.values.process[0] !== ''}
              startIcon={<Icon icon={plusFill} />}
              onClick={() => {
                const formikLanguage = languageList.map((el) => ({
                  language: el,
                  phrases: ['']
                }));
                formik.setFieldValue(
                  `keywords[${formik.values.keywords.length}].description`,
                  formikLanguage
                );
                formik.setFieldValue(`keywords[${formik.values.keywords.length}].aiMode`, false);
                formik.setFieldValue(`keywords[${formik.values.keywords.length}].Category`, '');
                formik.setFieldValue(`keywords[${formik.values.keywords.length}].Parameter`, '');
                formik.setFieldValue(`keywords[${formik.values.keywords.length}].Type`, '');
                formik.setFieldValue(`keywords[${formik.values.keywords.length}].weightage`, '');
                setParamIndex(formik.values.keywords.length);
                handleDialog();
              }}
              sx={{
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
            >
              Add Parameter
            </Button>
          </Grid>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleDialog}
            open={open}
            title="Add Parameter Info"
          >
            <Grid item xs={12}>
              {/* <FieldArray
                name="keywords"
                // subscription={{}}
                render={(arrayHelpers) => (
                  <> */}
              {/* {formik.values.keywords.map((keyword, index) => ( */}
              {
                <ParameterItem
                  key={paramIndex}
                  index={paramIndex}
                  languageList={languageList}
                  // arrayHelpers={arrayHelpers}
                  formik={formik}
                  parameterList={parameterList} // replace with your actual parameterList
                  categoryList={categoryList} // replace with your actual categoryList
                  handleDialog={handleDialog}
                  setParameter={setParameters}
                />
              }
              {/* ))} */}
              {/* </>
                )}
              /> */}
            </Grid>
          </CustomFormModel>
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleCancel}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Reset
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={() => {
              // handleSubmit();
              if (formik.values.keywords.length > 0) {
                handleSubmit();
              } else {
                handleSubmit();
                setAlert(true);
                setAlertType('error');
                setAlertContent('Please Add At least One Parameter');
              }
            }}
            loading={isSubmitting}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Submit
          </LoadingButton>
        </Container>
      </Form>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </FormikProvider>
  );
}
