/* eslint-disable prefer-template */
import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  MenuItem,
  Menu,
  ListItemText,
  Typography,
  Tooltip,
  List
} from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/RemoveCircle';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Badge from '@mui/material/Badge';
import BellIcon from '@mui/icons-material/Notifications';
import { useNavigate, useLocation } from 'react-router-dom';
// components
import API from '../../E2E/axios.util';
import { MHidden } from '../../components/@material-extend';
import useWebSocket from '../../hooks/useWebSocket';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 90;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile

  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  },
  height: 54
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 1)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [update, setUpdate] = useState(0);
  // ---------------------------------------------------
  // websocket integration

  const [message] = useWebSocket('task');
  console.log('websocket message', message);
  // const dummydata = {
  //   count: 3,
  //   tasks: [{ task_name: 'task1' }, { task_name: 'task2' }, { task_name: 'task3' }]
  // };
  // const [Notification, setNotification] = useState();
  // console.log('notify---', Notification);
  const [countuse, setcountuse] = useState(0);
  // const [countuse, setcountuse] = useState(Notification?.task_count);
  const [tasksUse, settasksUse] = useState([]);
  // const [tasksUse, settasksUse] = useState([
  //   { task_name: 'task1' },
  //   { task_name: 'task2' },
  //   { task_name: 'task3' }
  // ]);
  const navigate = useNavigate();
  const handleNotifications = () => {
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    function getNotification() {
      return API.get(`secure/task_count`, config);
    }
    Promise.all([getNotification()])
      .then(([notificationData]) => {
        setcountuse(notificationData.data.task_count);
        settasksUse(notificationData.data.tasks);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };
  useEffect(() => {
    handleNotifications();
  }, [update, message]);

  // function for read  tasks
  const handleEditNotification = async (task_id) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const data = {
      id: task_id,
      read_status: true
    };
    await API.put('/secure/task_read', data, config)
      .then((res) => {
        console.log('res', res);
        // setloading(false);
        // setAlertContent(`Success : ${res.data.message}`);
        // setAlert(true);
        // setAlertType('success');
        // handleDialog();
      })
      .then(() => {
        setUpdate(update + 1);
      })
      .catch((err) => {
        console.log('error', err);
        // setloading(false);
        // setAlertContent(`${err.response.data.message}`);
        // setAlert(true);
        // setAlertType('error');
        // handleDialog();
      });
  };

  const TextCut = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + '...';
    }
    return text;
  };
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const location = useLocation();
  // console.log('location', location);
  // ------------------------------------------------------
  return (
    <RootStyle>
      {/* <ArrowCircleLeftIcon
        color="primary"
        sx={{ position: 'relative', top: '4rem', zIndex: 5, cursor: 'pointer' }}
        onClick={(e) => console.log('arrow clicked')}
      /> */}
      <ToolbarStyle>
        {/* <MHidden width="lgUp"> */}
        {userDetails.role === 'Agent' && userDetails?.customer_logo !== 'logo not given' ? (
          <Stack direction="row">
            <MHidden width="lgDown">
              <img
                src={`${process.env.REACT_APP_SERVER_MEDIA_URL}${userDetails?.customer_logo}`}
                alt="Not Found"
              />
            </MHidden>
          </Stack>
        ) : null}
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <MHidden width="lgUp">
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Icon icon={menu2Fill} />
            </IconButton>
          </MHidden>
        </Stack>
        {/* </MHidden> */}

        {/* <Box sx={{ flexGrow: 1 }} /> */}

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1
          }}
        >
          {/* {location.pathname !== '/dashboard/call' ? (
            <Badge
              badgeContent={countuse}
              color="warning"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              sx={{ marginRight: 2 }}
            >
              <BellIcon
                ref={ref}
                sx={{ color: '#3892CF', cursor: 'pointer' }}
                onClick={() => setIsOpen(true)}
              />
            </Badge>
          ) : null}
          <Menu
            open={isOpen}
            anchorEl={ref.current}
            onClose={() => setIsOpen(false)}
            PaperProps={{
              sx: { width: 290, maxWidth: '100%', height: 250 }
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <b style={{ display: 'flex', justifyContent: 'center' }}>Pending Tasks</b>
            {tasksUse.length > 0 ? (
              tasksUse.map((el, index) => (
                <MenuItem
                  sx={{ color: 'text.secondary', display: 'flex' }}
                  onClick={() => {
                    navigate('/dashboard/admin/task', { replace: true });
                    setIsOpen(false);
                  }}
                >
                  <b style={{ fontSize: '14px' }}>{`${el.agent_name}  :  `} </b>

                  {el.task_name.length > 20 ? (
                    <Tooltip title={el.task_name} placement="top">
                      <ListItemText
                        primary={TextCut(el.task_name, 20)}
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </Tooltip>
                  ) : (
                    <ListItemText
                      primary={el.task_name}
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  )}
                  <Tooltip title="mark as read" placement="top">
                    <ArrowCircleRightIcon
                      color="primary"
                      onClick={(event) => {
                        const newTask = tasksUse.filter(
                          (element) => element.task_name !== el.task_name
                        );
                        handleEditNotification(el.task_id);
                        // console.log('first', index);
                        // settasksUse(tasksUse.filter((element) => element.task_name !== el.task_name));
                        // console.log('second', newTask);
                        setcountuse(newTask.length);
                        settasksUse(newTask);
                      }}
                    />
                  </Tooltip>
                </MenuItem>
              ))
            ) : (
              <MenuItem>
                <ListItemText
                  primary="No Pending Tasks Found"
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </MenuItem>
            )}
          </Menu> */}
          {location.pathname.includes('/dashboard/app/sales') ? <NotificationsPopover /> : null}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
