// ----------------------------------------------------------------------
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

const userData = JSON.parse(localStorage.getItem('user'));

const accountDisplayName = `${userData && userData.first_name ? userData.first_name : ''}  ${
  userData && userData.last_name ? userData.last_name : ''
}`;
console.log('accountDisplayName --', accountDisplayName);
const accountEmail = userData && userData.email ? userData.email : '';

const account = {
  displayName: accountDisplayName,
  email: accountEmail,
  photoURL: '/static/mock-images/avatars/avatar_23.jpg'
};

export default account;
