import { merge } from 'lodash';
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../../components/charts';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

// const CHART_DATA = [4344, 5435, 1443, 4443];

export default function PieChart(data) {
  const theme = useTheme();
  const [percentage, setPercentage] = useState([]);

  // const chart_details = data.data.results;
  const chart_details = ['High', 'Medium', 'Low'];
  // const chart_data = data.data.results.percentage;
  const chart_data = data;

  // const new_data = [
  //   parseInt(chart_data[0].toFixed(), 10),
  //   parseInt(chart_data[1].toFixed(), 10),
  //   parseInt(chart_data[2].toFixed(), 10)
  // ];

  // const checkData = 100 - (new_data[0] + new_data[1] + new_data[2]);

  // if (checkData > 0) {
  //   new_data.splice(2, 1, new_data[2] + checkData);
  // } else if (checkData < 0) {
  //   new_data.splice(2, 1, new_data[2] + checkData);
  // }

  // const convertToneintoPercentage = (data) => {
  //   const HighTone = data.high[0] === undefined ? 0 : Math.round(data.high[0]);
  //   const MediumTone = data.medium[0] === undefined ? 0 : Math.round(data.medium[0]);
  //   const LowTone = data.low[0] === undefined ? 0 : Math.round(data.low[0]);
  //   const totalTone = HighTone + MediumTone + LowTone;

  //   if (totalTone === 0) {
  //     const ToneInPercentage = [0, 0, 0];
  //     setPercentage(ToneInPercentage);
  //   } else {
  //     const ToneInPercentage = [
  //       (HighTone / totalTone) * 100,
  //       (MediumTone / totalTone) * 100,
  //       (LowTone / totalTone) * 100
  //     ];
  //     setPercentage(ToneInPercentage);
  //   }
  // };

  // useEffect(() => {
  //   setPercentage([]);
  //   convertToneintoPercentage(data.data);
  // }, []);

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      '#f72d48',
      '#8884d8',
      '#82ca9d'
      // theme.palette.primary.main,
      // // theme.palette.info.main,
      // theme.palette.warning.main
    ],
    // colors: [
    //   theme.palette.error.main,
    //   theme.palette.primary.main,
    //   // theme.palette.info.main,
    //   theme.palette.warning.main
    // ],
    labels: chart_details,
    stroke: { colors: [theme.palette.background.paper] },
    legend: {
      onItemHover: {
        highlightDataSeries: false
      },
      floating: true,
      horizontalAlign: 'center'
    },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false
    },
    plotOptions: {
      pie: { customScale: 1, donut: { labels: { show: true } } }
    }
  });

  return (
    <Card>
      {/* <CardHeader title={`Overall Sentiments: ${chart_details.Overall_Sentiment}`} /> */}
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="donut" series={chart_data.data} options={chartOptions} height={280} />
      </ChartWrapperStyle>
    </Card>
  );
}
