/* eslint-disable arrow-body-style */
/* eslint-disable no-unneeded-ternary */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Stack,
  Typography,
  Grid,
  Tabs,
  Tab,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Button,
  TablePagination,
  Pagination
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Select, { components } from 'react-select';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { Icon } from '@iconify/react';
// import downloadOutlined from '@iconify/icons-ant-design/download-outlined';
import makeStyles from '@mui/styles/makeStyles';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import { saveAs } from 'file-saver';
import moment from 'moment';
import DatewiseProgressTable from '../components/TableComponent/DatewiseProgressTable';
import TableComponent from '../components/TableComponent/TableComponent';
import Page from '../components/Page';
import API from '../E2E/axios.util';
import Scrollbar from '../components/Scrollbar';
import AppWidgetSummary from '../components/charts/AppWidgetSummary';
import { Notification } from '../components/Notification/Notification';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default function Report() {
  const [value, setValue] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [pageScore, setPageScore] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [showCloseFilter, setShowCloseFilter] = useState(false);

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');

  const [dateWiseReportDetails, setDateWiseReportDetails] = useState();
  const [downloadDetails, setDownloadDetails] = useState();
  const [downloadScoreDetails, setDownloadScoreDetails] = useState();
  //   console.log('agentwise', agentWiseReportDetails);
  // console.log('datewise', dateWiseReportDetails);

  const [loading, setLoading] = useState();
  const [loadingForScore, setLoadingForScore] = useState();
  const useStyles = makeStyles((theme) => ({
    // root: {
    //   '& .MuiTabs-indicator': {
    //     backgroundColor: '#1D9FD9' // change this to your desired color
    //   }
    // },
    // tab: {
    //   '&.Mui-selected': {
    //     color: '#1D9FD9' // change this to your desired color
    //   }
    // },
    ul: {
      '& .MuiPaginationItem-root': {
        '&.Mui-selected': {
          background: '#3892CF',
          color: 'white'
          // borderRadius: '50%',
        }
      }
    },
    menuScoreCard: {
      maxHeight: 150,
      maxWidth: 50
    },
    datePicker: {
      '& .MuiTextField-root': {
        width: 170
        // marginBottom: 1
        // height: 10
      },
      '& .MuiInputLabel-shrink': {
        color: '#3892CF' // Set your desired color for the shrunk label
      },
      '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#3892CF'
      }
    }
  }));

  const classes = useStyles();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const handleReportDetails = (data) => {
    setDateWiseReportDetails(data);
    setDownloadDetails(data.results['file path']);
  };

  const formatDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = `${inputDate.getFullYear()}-${(inputDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${inputDate.getDate().toString().padStart(2, '0')}`;

    return formattedDate;
  };

  const [updatedDateFormat, setUpdatedDateFormat] = useState({
    value: 'one_week',
    label: 'One Week'
  });

  const getReportDetails = async (value, date) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const initialUrl = `/secure/datewise_call_report?date=${
      value ? value : 'one_week'
    }&page=${page}`;
    // const updatedUrl = /secure/datewise_call_report?date=${value};
    // const datedUrl = `/secure/datewise_call_report?date=customized&start_date=${formatDate(
    //   fromDate
    // )}&end_date=${formatDate(date)}`;
    const datedUrl = `/secure/datewise_call_report?date=customized&start_date=${formatDate(
      fromDate
    )}&end_date=${formatDate(date)}&page=${page}`;
    if (value !== 'customized') {
      setLoading(true);
      await API.get(initialUrl, config)
        .then((res) => {
          handleReportDetails(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setDateWiseReportDetails();
          setDownloadDetails();
          // setAlertContent('No data for that range.');
          // setAlertType('error');
          // setAlert(true);
        });
    }
    if (value === 'customized' && date) {
      setLoading(true);
      await API.get(datedUrl, config)
        .then((res) => {
          handleReportDetails(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setDateWiseReportDetails();
          setDownloadDetails();
          // setAlertContent('No data for that range.');
          // setAlertType('error');
          // setAlert(true);
        });
    }
  };

  useEffect(() => {
    if (updatedDateFormat.value === 'customized') {
      getReportDetails(updatedDateFormat.value, toDate);
    } else {
      getReportDetails(updatedDateFormat.value);
    }
  }, [page, updatedDateFormat]);

  const [tableData, setTableData] = useState([]);

  const [tablecolData, setTableColData] = useState([
    'call_date',
    'ai_count',
    'average_ai_score',
    'manual_count',
    'average_manual_score'
  ]);
  const [pageValue, setPageValue] = useState();

  const dateOverAllQualityScore = async (value, date) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const initialUrl = `/secure/overall_quality_score?date=${
      value ? value : 'one_week'
    }&page=${pageScore}`;
    const datedUrl = `/secure/overall_quality_score?date=${
      value ? value : 'one_week'
    }&start_date=${formatDate(fromDate)}&end_date=${formatDate(date)}&page=${pageScore}`;
    if (value !== 'customized') {
      setLoadingForScore(true);
      await API.get(initialUrl, config)
        .then((res) => {
          setPageCount(res.data.count);
          setTableData(res.data.results.data);
          setDownloadScoreDetails(res.data.results.file_path);

          const ColSeq = [
            'call_date',
            'ai_count',
            'average_ai_score',
            'manual_count',
            'average_manual_score'
          ];
          setTableColData(ColSeq);
          setLoadingForScore(false);
        })
        .catch((err) => {
          setLoadingForScore(false);

          // setAlertContent('No data for that range.');
          // setAlertType('error');
          // setAlert(true);
        });
    }
    if (value === 'customized' && date) {
      setLoadingForScore(true);
      await API.get(datedUrl, config)
        .then((res) => {
          setPageCount(res.data.count);
          setTableData(res.data.results.data);
          setDownloadScoreDetails(res.data.results.file_path);
          setLoadingForScore(false);
        })
        .catch((err) => {
          setLoadingForScore(false);

          // setAlertContent('No data for that range.');
          // setAlertType('error');
          // setAlert(true);
        });
    }
  };

  useEffect(() => {
    if (updatedDateFormat.value === 'customized') {
      dateOverAllQualityScore(updatedDateFormat.value, toDate);
    } else {
      dateOverAllQualityScore(updatedDateFormat.value);
    }
  }, [pageScore, updatedDateFormat]);

  const changedDate = (date) => {
    console.log('date format', date);
    const formatDate = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const newdate = new Date(formatDate);

    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return newdate.toLocaleDateString(undefined, options);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangePageScore = (event, newPage) => {
    setPageScore(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleAlert = () => {
    setAlert(false);
  };

  const customStyles = {
    option: (base, state) => ({
      ...base,
      fontWeight: state.isSelected ? '500' : 'normal',
      maxHeight: 35
    }),
    control: (base, state) => ({
      ...base,
      fontWeight: '500'
      // border: 'none'
      // maxHeight: 35
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: '2px',
      maxHeight: 38,
      overflow: 'auto'
    })
  };
  const dateFormat = [
    { name: 'One Week', id: 'one_week' },
    { name: 'One Month', id: 'one_month' },
    // { name: 'Six Month', id: 'six_month' },
    { name: 'Customize', id: 'customized' }
  ];

  const CustomControlDate = ({ children, ...rest }) => {
    return (
      <components.Control {...rest}>
        <CalendarMonthIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
        {children}
      </components.Control>
    );
  };
  console.log('data coming datewise', dateWiseReportDetails);

  const [agentWiseReportDetails, setAgentWiseReportDetails] = useState();
  // <--------------------------------------------------------------------------------------->

  return (
    <Page title="Usage">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Datewise Report
          </Typography>
          <Grid
            container
            spacing={0.8}
            // md={updatedDateFormat.label === 'Customize' ? 8 : 4}
            md={9}
            style={{
              display: 'flex',
              flexDirection: 'row',
              // justifyContent: 'space-between',
              justifyContent: 'end',
              alignItems: 'center',
              padding: 1
            }}
          >
            {!loading && (
              <div
                style={{
                  display: 'flex',
                  width: updatedDateFormat.label === 'Customize' ? '90%' : '43%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Select
                  styles={customStyles}
                  onChange={(e) => {
                    setUpdatedDateFormat(e);
                    setFromDate(null);
                    setToDate(null);
                    // getReportDetails(e.value);
                    setPage(1);
                    setPageScore(1);
                  }}
                  options={dateFormat?.map((element) => ({
                    value: element.id,
                    label: element.name
                  }))}
                  value={updatedDateFormat}
                  components={{ Control: CustomControlDate }}
                  placeholder={
                    <div
                      style={{
                        display: 'flex',
                        columnGap: 10,
                        alignItems: 'center',
                        color: '#000',
                        fontWeight: '400'
                      }}
                    />
                  }
                  isSearchable={false}
                  isClearable={false}
                />
                {updatedDateFormat.label === 'Customize' && (
                  <div style={{ marginBottom: '8px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <div className={classes.datePicker}>
                          <DatePicker
                            label="From"
                            format="DD/MM/YYYY"
                            maxDate={dayjs()}
                            value={fromDate}
                            onChange={(newValue) => {
                              setFromDate(newValue);
                              setToDate(null);
                            }}
                            slotProps={{ textField: { size: 'small' } }}
                          />
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                )}
                {updatedDateFormat.label === 'Customize' && (
                  <div style={{ marginBottom: '8px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <div className={classes.datePicker}>
                          <DatePicker
                            label="To"
                            format="DD/MM/YYYY"
                            disabled={fromDate === null}
                            minDate={fromDate && fromDate}
                            maxDate={dayjs()}
                            value={toDate}
                            onChange={(newValue) => {
                              setToDate(newValue);
                              setPage(1);
                              getReportDetails('customized', newValue);
                              dateOverAllQualityScore('customized', newValue);
                            }}
                            slotProps={{ textField: { size: 'small' } }}
                          />
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                )}
                <Button
                  variant="contained"
                  to="#"
                  disabled={!downloadDetails}
                  onClick={() => {
                    if (downloadDetails) {
                      saveAs(
                        `${process.env.REACT_APP_SERVER_MEDIA_URL}${downloadDetails}`,
                        downloadDetails.split('/').pop()
                      );
                    }
                  }}
                  sx={{
                    backgroundColor: '#107c41',
                    '&:hover': {
                      backgroundColor: '#3AB170'
                    }
                  }}
                >
                  Download Report
                </Button>
              </div>
            )}
          </Grid>
        </Stack>
        <Grid>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 1028, maxHeight: 450 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Processed Date
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Total Calls Processed
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        QA Scored Calls
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Total Agents
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Call Duration(Min.)
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                      >
                        Call Duration(Hr.)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {/* // Date Wise Processed */}

                  {dateWiseReportDetails &&
                  dateWiseReportDetails.results['Date Wise Processed'] &&
                  dateWiseReportDetails.results['Date Wise Processed'].length > 0 &&
                  !loading ? (
                    <TableBody>
                      {dateWiseReportDetails.results['Date Wise Processed'].map((el, index) => (
                        <TableRow>
                          <TableCell align="center">{changedDate(el.date)}</TableCell>
                          {console.log('date hai bhidu', el.date)}
                          <TableCell
                            align="center"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '130px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                            title={el['Processed Dates'].map((date, i) => date)}
                          >
                            {changedDate(el['Processed Dates'].map((date, i) => date).join(','))}
                          </TableCell>
                          <TableCell align="center">{el['Processed Calls']}</TableCell>
                          <TableCell align="center">{el['QA Scored Calls']}</TableCell>
                          <TableCell align="center">{el['Total Agents']}</TableCell>
                          <TableCell align="center">
                            {el['Total Call Duration Min'].toFixed(2)}
                          </TableCell>
                          <TableCell align="center">
                            {el['Total Call Duration Hr'].toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        {loading ? (
                          <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                            <CircularProgress sx={{ color: '#3892CF' }} />
                          </TableCell>
                        ) : (
                          <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                            Data Not found
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                rowsPerPage={rowsPerPage}
                count={dateWiseReportDetails ? dateWiseReportDetails.length : 0}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            <Stack
              spacing={2}
              sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
            >
              <Pagination
                classes={{
                  root: classes.ul
                }}
                count={Math.ceil(dateWiseReportDetails ? dateWiseReportDetails.count / 10 : 0)}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                color="primary"
                page={page}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </Stack>
          </Card>
        </Grid>
        <Stack direction="column">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ paddingTop: '50px', paddingBottom: '20px' }}
            >
              Datewise Score Report
            </Typography>
            <Grid>
              <Button
                variant="contained"
                to="#"
                disabled={!downloadScoreDetails}
                onClick={() => {
                  if (downloadScoreDetails) {
                    saveAs(
                      `${process.env.REACT_APP_SERVER_MEDIA_URL}${downloadScoreDetails}`,
                      downloadScoreDetails.split('/').pop()
                    );
                  }
                }}
                sx={{
                  marginTop: '47px',
                  backgroundColor: '#107c41',
                  '&:hover': {
                    backgroundColor: '#3AB170'
                  }
                }}
              >
                Download Report
              </Button>
            </Grid>
          </div>

          <DatewiseProgressTable
            TableinputData={tableData}
            tablecolData={tablecolData}
            // scoreProgress={tableData}
            loading={loadingForScore}
          />
          <Stack
            spacing={2}
            sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
          >
            <Pagination
              classes={{
                root: classes.ul
              }}
              color="primary"
              count={Math.ceil(pageCount ? pageCount / 10 : 0)}
              page={pageScore}
              onChange={handleChangePageScore}
              showFirstButton
              showLastButton
            />
          </Stack>
        </Stack>
      </Container>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}
