import React from 'react';
// material
import { Box, Container, Typography, Grid, Card } from '@mui/material';
import { useFormik } from 'formik';
// components
import API from '../E2E/axios.util';
import { Notification } from '../components/Notification/Notification';
import Page from '../components/Page';
import { ProfileHeader } from '../layouts/Profile/ProfileHeader';
import { ProfileDetails } from '../layouts/Profile/ProfileDetails';
// import { DashboardLayout } from '../components/dashboard-layout';
import { ChangedPassword } from './User';
// ----------------------------------------------------------------------

export default function Profile() {
  // const user = JSON.parse(localStorage.getItem('user'));
  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState('');
  const [alertType, setAlertType] = React.useState('');
  const [orgData, setorgData] = React.useState(null);
  const [roleData, setroleData] = React.useState(null);
  const [userData, setuserData] = React.useState(null);
  const [update, setUpdate] = React.useState(0);
  console.log('orgData', orgData);
  console.log('roleData', roleData);
  React.useEffect(() => {
    handleRequest();
  }, []);

  React.useEffect(() => {
    handleRequest();
  }, [update]);

  const handleRequest = () => {
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getOrg() {
      return API.get('secure/list_organisation?page=1&max_rows=10', config);
    }
    function getRole() {
      return API.get('/secure/role_list?page=1&max_rows=10', config);
    }
    function getUser() {
      return API.get('/secure/get_userprofile', config);
    }
    Promise.all([getOrg(), getRole(), getUser()])
      .then(([orgData, roleData, userData]) => {
        console.log('first', orgData);
        setorgData(orgData.data);
        setroleData(roleData.data);
        setuserData(userData.data);
        setAlertContent('Successfully Loaded.');
        setAlertType('success');
        setAlert(true);
      })
      .catch((err) => {
        setAlertContent(err.response.data.message);
        setAlertType('error');
        setAlert(true);
        console.log(err.response.data.message);
      });
  };

  const handleAlert = () => {
    setAlert(false);
  };

  return (
    <Page title="Profile">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome to Profile</Typography>
        </Box>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <ProfileHeader
                orgData={orgData?.organisation_list}
                userData={userData?.profile_list[0]}
                roleData={roleData?.roles}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <ProfileDetails
                orgData={orgData?.organisation_list}
                userData={userData?.profile_list[0]}
                roleData={roleData?.roles}
                setAlert={setAlert}
                setAlertContent={setAlertContent}
                setAlertType={setAlertType}
                isUpdated={update}
                updateComponent={setUpdate}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Card style={{ padding: '5%' }}>
                <Box sx={{ pb: 5 }}>
                  <Typography variant="h4">Change Password</Typography>
                </Box>
                <ChangedPassword
                  setAlert={setAlert}
                  setAlertContent={setAlertContent}
                  setAlertType={setAlertType}
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}
