import React, { useState, useEffect, useRef, useContext } from 'react';
import './DealForm.css';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Icon } from '@iconify/react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Card,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TablePagination,
  Stack,
  Pagination,
  Menu,
  MenuItem,
  IconButton,
  Button,
  ListItemIcon,
  ListItemText,
  Box,
  LinearProgress,
  Typography,
  Collapse,
  TableHead,
  Tooltip as Tip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import editFill from '@iconify/icons-eva/edit-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import eyeIcon from '@iconify/icons-mdi/eye';
import cogs from '@iconify/icons-mdi/cogs';
import cogPlay from '@iconify/icons-mdi/cog-play';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
// components
import useWebSocket from '../../hooks/useWebSocket';
import { DealEditForm } from '../../components/custom-form-model/CRUDForms';
import TaskForm from './TaskForm';
import inputContex from '../../Context/UploadContext/inputContext';
import { CustomFormModel } from '../../components/custom-form-model/CustomFormModel';
import Scrollbar from '../../components/Scrollbar';
import API from '../../E2E/axios.util';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: '#3892CF',
        color: 'white'
        // borderRadius: '50%',
      }
    }
  }
}));
const UPLOAD_TABLE_HEAD = [
  { id: 'organisation', numeric: false, disablePadding: false, label: 'Organisation' },
  {
    id: 'Customer',
    numeric: false,
    disablePadding: false,
    label: 'Customer'
  },
  { id: 'filename', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'customer', numeric: false, disablePadding: false, label: 'Deal Name' },
  { id: 'agent', numeric: false, disablePadding: false, label: 'Deal Stage' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: '' }
];

const UPLOADFILE_TABLE_HEAD = [
  { id: 'filename', numeric: false, disablePadding: false, label: 'File Name' },
  { id: 'organisation', numeric: false, disablePadding: false, label: '' },
  { id: 'customer', numeric: false, disablePadding: false, label: '' },
  { id: 'process', numeric: false, disablePadding: false, label: '' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' }
];

export const DealDataModel = ({
  handleDialog,
  formik,
  setAlert,
  setAlertContent,
  setAlertType,
  isUpdated,
  updateComponent
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [uploadDataList, setuploadDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [isCollapse, setisCollapse] = useState(false);
  const [statusCheck, setstatusCheck] = useState(0);
  const [query, setQuery] = useState();
  const [uploadSearch, setUploadSearch] = useState();
  console.log(page, 'page');
  console.log(rowsPerPage, 'rowsPerPage');
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const { uploadStatus, setuploadStatus } = useContext(inputContex);

  const handleChangePage = (event, newPage) => {
    console.log('newPage ----', newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUploadData = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/deal_list?page=${page}`, config)
      // await API.get(`/secure/list_input?page=${page + 1}&max_rows=10`, config)
      .then((res) => {
        setuploadDataList(res.data);
        setAlertContent(`Success: Deal list fetch successfully.`);
        setAlertType('success');
        setLoading(false);
        setAlert(true);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
        setLoading(false);
      });
  };

  const getUploadStatusData = async () => {
    // setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`/secure/deal_list`, config)
      // await API.get(`/secure/list_input?page=${page + 1}&max_rows=10`, config)
      .then((res) => {
        setuploadDataList(res.data);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  const changedDate = (date) => {
    const newdate = new Date(date);
    const formatStartDate = `${newdate.getDate()}-${
      newdate.getMonth() + 1
    }-${newdate.getFullYear()}`;
    return formatStartDate;
  };

  const handleSearch = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(`secure/upload_search?page=${page}&max_rows=10&query=${query}`, config)
      .then((res) => {
        setUploadSearch(res.data);
        console.log('search', res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  console.log('query', query);
  useEffect(() => {
    getUploadData();
  }, [isUpdated, page]);
  useEffect(() => {
    if (query) {
      handleSearch();
      // console.log('query', query);
    }
  }, [isUpdated, page]);

  const showTip = (value) => {
    if (value.length > 10) {
      return (
        <Tip title={value} followCursor placement="top">
          <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>{` ${value.slice(
            0,
            10
          )}.....`}</Typography>
          {/* <span style={{ fontSize: 12 }}>{value.slice(0, 10)}.....</span> */}
        </Tip>
      );
    }
    return <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>{value}</Typography>;
  };

  return (
    <Card>
      {/* <Box sx={{ display: 'flex' }}>
        <UserListToolbar
          placeholder="Search by name"
          onFilterName={(e) => setQuery(e.target.value)}
        />
        {query && (
          <IconButton
            color="primary"
            aria-label="search"
            component="label"
            onClick={handleSearch}
            sx={{ marginTop: '25px', height: '50px', width: '50px' }}
          >
            <SearchOutlinedIcon />
          </IconButton>
        )}
      </Box> */}
      <Scrollbar sx={{ cursor: 'pointer' }}>
        <TableContainer>
          <Table>
            <UserListHead headLabel={UPLOAD_TABLE_HEAD} rowCount={page} />
            {!loading && uploadDataList && uploadDataList.results.length > 0 ? (
              <TableBody>
                {uploadDataList.results
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      deal_id,
                      org_name,
                      organisation_id,
                      customer_id,
                      customer_organisation_name,
                      agent_id,
                      first_name,
                      last_name,
                      deal_name,
                      deal_stage,
                      deal_status,
                      reason,
                      input_id,
                      purpose,
                      created_date,
                      created_by
                    } = row;
                    return (
                      <>
                        <TableRow hover key={1} tabIndex={-1} role="checkbox">
                          <TableCell align="left">{org_name}</TableCell>
                          <TableCell component="th" scope="row" padding="normal">
                            {customer_organisation_name}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="normal">
                            {first_name} {last_name}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="normal">
                            {deal_name}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="normal">
                            {deal_stage}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="normal">
                            {deal_status}
                          </TableCell>

                          {userDetails.role !== 'Manager' && (
                            <TableCell align="right">
                              <UserMoreMenu
                                formik={formik}
                                isUpdated={isUpdated}
                                updateComponent={updateComponent}
                                setAlert={setAlert}
                                setAlertContent={setAlertContent}
                                setAlertType={setAlertType}
                                inputId={deal_id}
                                inputDetails={row}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  {loading ? (
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <CircularProgress sx={{ color: '#3892CF' }} />
                    </TableCell>
                  ) : (
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      Data Not Found
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <Stack
        spacing={2}
        sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
      >
        <Pagination
          classes={{
            root: classes.ul
          }}
          count={uploadDataList ? Math.ceil(uploadDataList?.count / 10) : 1}
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Stack>
      {/* {!loading && uploadSearch && uploadDataList ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          rowsPerPage={rowsPerPage}
          count={uploadDataList && uploadDataList.deal_list.length}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          rowsPerPage={rowsPerPage}
          count={uploadDataList?.deal_list ? uploadDataList?.deal_list?.length : 0}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )} */}
    </Card>
  );
};

function UserMoreMenu({
  formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  inputId,
  inputDetails
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const [taskOpen, setTaskOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(null);

  const handleDialog = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };
  const handleTaskDialog = () => {
    setTaskOpen(!taskOpen);
    setIsOpen(false);
  };

  const handleDeleteInput = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.delete(`/secure/deactivate_deal?id=${inputId}`, config)
      .then((res) => {
        setAlertContent(`Success : Upload record deleted successfully.`);
        setAlertType('success');
        setAlert(true);
      })
      .then(() => {
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={openEdit}
        title="Update Deal Data"
      >
        <DealEditForm
          formik={formik}
          isUpdated={isUpdated}
          updateComponent={updateComponent}
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          inputId={inputId}
          inputDetails={inputDetails}
          handleDialog={handleDialog}
        />
      </CustomFormModel>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem to="#" sx={{ color: 'text.secondary' }} onClick={handleDialog}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleTaskDialog}>
          <ListItemIcon>
            <Icon icon={plusFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Add Task" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDeleteInput}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>

      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleTaskDialog}
        open={taskOpen}
        title="Task Details"
      >
        <TaskForm handleDialog={handleTaskDialog} inputDetails={inputDetails} />
      </CustomFormModel>
    </>
  );
}
