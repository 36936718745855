import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Drawer as MobileDrawer,
  Typography,
  Divider,
  Avatar,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  Tooltip
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

// import sidebarConfig from './SidebarConfig';
import IconButton from '@mui/material/IconButton';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import peopleFill from '@iconify/icons-eva/people-fill';
import usageFill from '@iconify/icons-mdi/calendar-month';
import accountWrench from '@iconify/icons-mdi/account-wrench';
import officeBuilding from '@iconify/icons-mdi/office-building';
import folderUpload from '@iconify/icons-mdi/folder-upload';
import personFeedback28Filled from '@iconify/icons-fluent/person-feedback-28-filled';
import viewDashboard from '@iconify/icons-mdi/view-dashboard';
import accountTie from '@iconify/icons-mdi/account-tie';
import alphabets from '@iconify/icons-mdi/alphabetical-variant';
import chart from '@iconify/icons-mdi/chart-bar';
import pie from '@iconify/icons-mdi/chart-pie';
import chartTree from '@iconify/icons-mdi/chart-tree';
import taskIcon from '@iconify/icons-mdi/calendar-task';
import documentExport from '@iconify/icons-carbon/document-export';
import CarbonAlignVerticalBottom from '@iconify/icons-carbon/align-vertical-bottom';
import filesFill from '@iconify/icons-mdi/file-multiple';
import grid from '@iconify/icons-ic/round-grid-view';
import logout from '@iconify/icons-mdi/logout';
import roundCampaign from '@iconify/icons-ic/round-campaign';
import outlineDateRange from '@iconify/icons-ic/outline-date-range';
import dealIcon from '@iconify/icons-mdi/deal';
import formOutline from '@iconify/icons-mdi/form-outline';
import roundCategory from '@iconify/icons-ic/round-category';
import categoryPlus from '@iconify/icons-mdi/category-plus';
import searchIcon from '@iconify/icons-mdi/search';
import star12Filled from '@iconify/icons-fluent/star-12-filled';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import reportData from '@iconify/icons-carbon/report-data';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import DashboardNavbar from './DashboardNavbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_DRAWER_WIDTH = 80;

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    borderRadius: 10,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 15px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 15px)`
  }
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  // -------------------------- Sidebar Menu ----------------------------
  const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

  const userDetails = JSON.parse(localStorage.getItem('user'));
  // console.log('local data ---', userDetails);
  let role;
  if (userDetails) {
    role = userDetails.role;
  }

  console.log('role -----------------', role);

  const sidebarConfigSuperAdmin = [
    userDetails?.Menus.includes('Dashboard') && {
      title: 'Dashboard',
      path: '/dashboard/app',
      icon: getIcon(viewDashboard),
      children:
        process.env.REACT_APP_TO_SHOW_CALLDASHBOARD !== 'true'
          ? [
              {
                title: 'Call Analysis',
                path: '/dashboard/app/call',
                icon: getIcon(chart)
              },
              {
                title: 'Revenue Intelligence',
                path: '/dashboard/app/sales',
                icon: getIcon(CarbonAlignVerticalBottom)
              },
              {
                title: 'Compliance',
                path: '/dashboard/app/compliance',
                icon: getIcon(chartTree)
              },
              {
                title: 'Collections',
                path: '/dashboard/app/collections',
                icon: getIcon(pie)
              },
              {
                title: 'Training Needs',
                path: '/dashboard/app/training',
                icon: getIcon(chartTree)
              }
            ]
          : [
              userDetails?.Submenus.includes('Call_analysis') && {
                title: 'Call Analysis',
                path: '/dashboard/app/call',
                icon: getIcon(chart)
              },
              userDetails?.Submenus.includes('Revenue_intelligence') && {
                title: 'Revenue Intelligence',
                path: '/dashboard/app/sales',
                icon: getIcon(CarbonAlignVerticalBottom)
              },
              userDetails?.Submenus.includes('Compliance') && {
                title: 'Compliance',
                path: '/dashboard/app/compliance',
                icon: getIcon(chartTree)
              },
              userDetails?.Submenus.includes('Collection') && {
                title: 'Collections',
                path: '/dashboard/app/collections',
                icon: getIcon(pie)
              }
              // userDetails?.Submenus.includes('Collection') && {
              //   title: 'Collections',
              //   path: '/dashboard/collections',
              //   icon: getIcon('mdi:collection')
              // }
            ]
    },
    userDetails.Menus.includes('Workspace') && {
      title: 'Workspace',
      path: '/dashboard/workspace',
      icon: getIcon(grid),
      children: [
        // true && {
        //   title: 'Search',
        //   path: '/dashboard/workspace/search',
        //   icon: getIcon(searchIcon)
        // },
        userDetails?.Submenus.includes('Upload') && {
          title: 'Upload',
          path: '/dashboard/workspace/upload',
          icon: getIcon(folderUpload)
        },
        userDetails?.Submenus.includes('Keywords') && {
          title: 'Key Words',
          path: '/dashboard/workspace/keywords',
          icon: getIcon(alphabets)
        },
        userDetails?.Submenus.includes('Script') && {
          title: 'Script',
          path: '/dashboard/workspace/compliance',
          icon: getIcon(formOutline)
        },
        userDetails?.Submenus.includes('Call_analysis') &&
          process.env.REACT_APP_TO_SHOW_QRC === 'true' &&
          userDetails.role !== 'Customer_Admin' &&
          userDetails.role !== 'QA_admin' && {
            title: 'Disposition',
            path: '/dashboard/workspace/disposition',
            icon: getIcon(roundCategory)
          }
      ]
    },
    userDetails?.Menus.includes('Administration') && {
      title: 'Administration',
      path: '/dashboard/admin',
      icon: getIcon(accountWrench),
      children: [
        userDetails?.Submenus.includes('Organisation') && {
          title: 'Organisation',
          path: '/dashboard/admin/Organisation',
          icon: getIcon(officeBuilding)
        },
        userDetails?.Submenus.includes('Customer') && {
          title: 'Customer',
          path: '/dashboard/admin/customer',
          icon: getIcon(accountTie)
        },
        // userDetails?.Submenus.includes('Call_analysis') &&
        //   process.env.REACT_APP_TO_SHOW_QRC === 'true' && {
        //     title: 'Disposition Category',
        //     path: '/dashboard/admin/domain',
        //     icon: getIcon(categoryPlus)
        //   },
        userDetails?.Submenus.includes('User') && {
          title: 'Users',
          path: '/dashboard/admin/user',
          icon: getIcon(peopleFill)
        },
        userDetails?.Submenus.includes('Campaign') && {
          title: 'Campaign',
          path: '/dashboard/admin/campaign',
          icon: getIcon(roundCampaign)
        },
        userDetails?.Submenus.includes('Deal') && {
          title: 'Deal',
          path: '/dashboard/admin/deal',
          icon: getIcon(dealIcon)
        },
        userDetails?.Submenus.includes('Task') && {
          title: 'Task',
          path: '/dashboard/admin/task',
          icon: getIcon(taskIcon)
        }
        // userDetails?.Submenus.includes('Usage') && {
        //   title: 'Usage',
        //   path: '/dashboard/admin/usage',
        //   icon: getIcon(usageFill)
        // }
      ]
    },
    userDetails?.Submenus.includes('Report') && {
      title: 'Reports',
      path: '/dashboard/report',
      icon: getIcon(filesFill),
      children: [
        userDetails?.Submenus.includes('Report') && {
          title: 'Agent Wise Report',
          path: '/dashboard/report/agentwise',
          icon: getIcon(peopleFill)
        },
        userDetails?.Submenus.includes('Report') && {
          title: 'Date Wise Report',
          path: '/dashboard/report/datewise',
          icon: getIcon(outlineDateRange)
        },
        userDetails?.Submenus.includes('Report') && {
          title: 'Daily Report',
          path: '/dashboard/report/dailyreport',
          icon: getIcon(documentExport)
        },
        userDetails?.Submenus.includes('Report') && {
          title: 'Score Report',
          path: '/dashboard/report/scorereport',
          icon: getIcon(reportData)
        }
      ]
    },
    userDetails?.Menus.includes('Feedback') && {
      title: 'Feedback',
      path: '/dashboard/feedback',
      icon: getIcon(personFeedback28Filled),
      children: [
        userDetails?.Submenus.includes('Goal') && {
          title: 'Goals',
          path: '/dashboard/feedback/opportunity',
          icon: getIcon(taskIcon)
        },
        userDetails?.Submenus.includes('Rating') && {
          title: 'Call Rating',
          path: '/dashboard/feedback/rating',
          icon: getIcon(star12Filled)
        },
        userDetails?.Submenus.includes('Script') && {
          title: 'Training Calls',
          path: '/dashboard/feedback/training_calls',
          icon: <img src="/static/agentTraining.png" alt="Training Call" width={22} height={22} />
        }
      ]
    }
  ];

  const sidebarConfigCustomerAdmin = [
    userDetails?.Menus.includes('Dashboard') && {
      title: 'Dashboard',
      path: '/dashboard/app',
      icon: getIcon(viewDashboard),
      children:
        process.env.REACT_APP_TO_SHOW_CALLDASHBOARD !== 'true'
          ? [
              {
                title: 'Call Analysis',
                path: '/dashboard/app/call',
                icon: getIcon(chart)
              },
              {
                title: 'Revenue Intelligence',
                path: '/dashboard/app/sales',
                icon: getIcon(CarbonAlignVerticalBottom)
              },
              {
                title: 'Compliance',
                path: '/dashboard/app/compliance',
                icon: getIcon(chartTree)
              },
              {
                title: 'Collections',
                path: '/dashboard/app/collections',
                icon: getIcon(pie)
              },
              {
                title: 'Training Needs',
                path: '/dashboard/app/training',
                icon: getIcon(chartTree)
              }
            ]
          : [
              userDetails?.Submenus.includes('Call_analysis') && {
                title: 'Call Analysis',
                path: '/dashboard/app/call',
                icon: getIcon(chart)
              },
              userDetails?.Submenus.includes('Revenue_intelligence') && {
                title: 'Revenue Intelligence',
                path: '/dashboard/app/sales',
                icon: getIcon(CarbonAlignVerticalBottom)
              },
              userDetails?.Submenus.includes('Compliance') && {
                title: 'Compliance',
                path: '/dashboard/app/compliance',
                icon: getIcon(chartTree)
              },
              userDetails?.Submenus.includes('Collection') && {
                title: 'Collections',
                path: '/dashboard/app/collections',
                icon: getIcon(pie)
              }
              // userDetails?.Submenus.includes('Collection') && {
              //   title: 'Collections',
              //   path: '/dashboard/collections',
              //   icon: getIcon('mdi:collection')
              // }
            ]
    },
    userDetails.Menus.includes('Workspace') && {
      title: 'Workspace',
      path: '/dashboard/workspace',
      icon: getIcon(grid),
      children: [
        userDetails?.Submenus.includes('Upload') && {
          title: 'Upload',
          path: '/dashboard/workspace/upload',
          icon: getIcon(folderUpload)
        },
        userDetails?.Submenus.includes('Keywords') && {
          title: 'Key Words',
          path: '/dashboard/workspace/keywords',
          icon: getIcon(alphabets)
        },
        userDetails?.Submenus.includes('Script') && {
          title: 'Script',
          path: '/dashboard/workspace/compliance',
          icon: getIcon(formOutline)
        },
        userDetails?.Submenus.includes('Call_analysis') &&
          process.env.REACT_APP_TO_SHOW_QRC === 'true' &&
          userDetails.role !== 'Customer_Admin' &&
          userDetails.role !== 'QA_admin' && {
            title: 'Disposition',
            path: '/dashboard/workspace/disposition',
            icon: getIcon(roundCategory)
          }
      ]
    },
    userDetails?.Menus.includes('Administration') && {
      title: 'Administration',
      path: '/dashboard/admin',
      icon: getIcon(accountWrench),
      children: [
        userDetails?.Submenus.includes('Organisation') && {
          title: 'Organisation',
          path: '/dashboard/admin/Organisation',
          icon: getIcon(officeBuilding)
        },
        userDetails?.Submenus.includes('Customer') && {
          title: 'Customer',
          path: '/dashboard/admin/customer',
          icon: getIcon(accountTie)
        },
        // userDetails?.Submenus.includes('Call_analysis') &&
        //   process.env.REACT_APP_TO_SHOW_QRC === 'true' && {
        //     title: 'Disposition Category',
        //     path: '/dashboard/admin/domain',
        //     icon: getIcon(categoryPlus)
        //   },
        userDetails?.Submenus.includes('User') && {
          title: 'Users',
          path: '/dashboard/admin/user',
          icon: getIcon(peopleFill)
        },
        userDetails?.Submenus.includes('Campaign') && {
          title: 'Campaign',
          path: '/dashboard/admin/campaign',
          icon: getIcon(roundCampaign)
        },
        userDetails?.Submenus.includes('Deal') && {
          title: 'Deal',
          path: '/dashboard/admin/deal',
          icon: getIcon(dealIcon)
        },
        userDetails?.Submenus.includes('Task') && {
          title: 'Task',
          path: '/dashboard/admin/task',
          icon: getIcon(taskIcon)
        }
        // userDetails?.Submenus.includes('Usage') && {
        //   title: 'Usage',
        //   path: '/dashboard/admin/usage',
        //   icon: getIcon(usageFill)
        // }
      ]
    },
    userDetails?.Submenus.includes('Report') && {
      title: 'Reports',
      path: '/dashboard/report',
      icon: getIcon(filesFill),
      children: [
        userDetails?.Submenus.includes('Report') && {
          title: 'Agent Wise Report',
          path: '/dashboard/report/agentwise',
          icon: getIcon(peopleFill)
        },
        userDetails?.Submenus.includes('Report') && {
          title: 'Date Wise Report',
          path: '/dashboard/report/datewise',
          icon: getIcon(outlineDateRange)
        },
        userDetails?.Submenus.includes('Report') && {
          title: 'Daily Report',
          path: '/dashboard/report/dailyreport',
          icon: getIcon(documentExport)
        },
        userDetails?.Submenus.includes('Report') && {
          title: 'Score Report',
          path: '/dashboard/report/scorereport',
          icon: getIcon(reportData)
        }
      ]
    },
    userDetails?.Menus.includes('Feedback') && {
      title: 'Feedback',
      path: '/dashboard/feedback',
      icon: getIcon(personFeedback28Filled),
      children: [
        userDetails?.Submenus.includes('Goal') && {
          title: 'Goals',
          path: '/dashboard/feedback/opportunity',
          icon: getIcon(taskIcon)
        },
        userDetails?.Submenus.includes('Rating') && {
          title: 'Call Rating',
          path: '/dashboard/feedback/rating',
          icon: getIcon(star12Filled)
        },
        userDetails?.Submenus.includes('Script') && {
          title: 'Training Calls',
          path: '/dashboard/feedback/training_calls',
          icon: <img src="/static/agentTraining.png" alt="Training Call" width={22} height={22} />
        }
      ]
    }
  ];

  const sidebarConfigManager = [
    userDetails?.Menus.includes('Dashboard') && {
      title: 'Dashboard',
      path: '/dashboard/app',
      icon: getIcon(viewDashboard),
      children:
        process.env.REACT_APP_TO_SHOW_CALLDASHBOARD !== 'true'
          ? [
              {
                title: 'Call Analysis',
                path: '/dashboard/app/call',
                icon: getIcon(chart)
              },
              {
                title: 'Revenue Intelligence',
                path: '/dashboard/app/sales',
                icon: getIcon(CarbonAlignVerticalBottom)
              },
              {
                title: 'Compliance',
                path: '/dashboard/app/compliance',
                icon: getIcon(chartTree)
              },
              {
                title: 'Collections',
                path: '/dashboard/app/collections',
                icon: getIcon(pie)
              },
              {
                title: 'Training Needs',
                path: '/dashboard/app/training',
                icon: getIcon(chartTree)
              }
            ]
          : [
              userDetails?.Submenus.includes('Call_analysis') && {
                title: 'Call Analysis',
                path: '/dashboard/app/call',
                icon: getIcon(chart)
              },
              userDetails?.Submenus.includes('Revenue_intelligence') && {
                title: 'Revenue Intelligence',
                path: '/dashboard/app/sales',
                icon: getIcon(CarbonAlignVerticalBottom)
              },
              userDetails?.Submenus.includes('Compliance') && {
                title: 'Compliance',
                path: '/dashboard/app/compliance',
                icon: getIcon(chartTree)
              },
              userDetails?.Submenus.includes('Collection') && {
                title: 'Collections',
                path: '/dashboard/app/collections',
                icon: getIcon(pie)
              }
              // userDetails?.Submenus.includes('Collection') && {
              //   title: 'Collections',
              //   path: '/dashboard/collections',
              //   icon: getIcon('mdi:collection')
              // }
            ]
    },
    userDetails.Menus.includes('Workspace') && {
      title: 'Workspace',
      path: '/dashboard/workspace',
      icon: getIcon(grid),
      children: [
        userDetails?.Submenus.includes('Upload') && {
          title: 'Upload',
          path: '/dashboard/workspace/upload',
          icon: getIcon(folderUpload)
        },
        userDetails?.Submenus.includes('Keywords') && {
          title: 'Key Words',
          path: '/dashboard/workspace/keywords',
          icon: getIcon(alphabets)
        },
        userDetails?.Submenus.includes('Script') && {
          title: 'Script',
          path: '/dashboard/workspace/compliance',
          icon: getIcon(formOutline)
        }
      ]
    },
    userDetails?.Menus.includes('Administration') && {
      title: 'Administration',
      path: '/dashboard/admin',
      icon: getIcon(accountWrench),
      children: [
        userDetails?.Submenus.includes('Organisation') && {
          title: 'Organisation',
          path: '/dashboard/admin/Organisation',
          icon: getIcon(officeBuilding)
        },
        userDetails?.Submenus.includes('Customer') && {
          title: 'Customer',
          path: '/dashboard/admin/customer',
          icon: getIcon(accountTie)
        },
        userDetails?.Submenus.includes('User') && {
          title: 'Users',
          path: '/dashboard/admin/user',
          icon: getIcon(peopleFill)
        },
        userDetails?.Submenus.includes('Campaign') && {
          title: 'Campaign',
          path: '/dashboard/admin/campaign',
          icon: getIcon(roundCampaign)
        },
        userDetails?.Submenus.includes('Deal') && {
          title: 'Deal',
          path: '/dashboard/admin/deal',
          icon: getIcon(dealIcon)
        },
        userDetails?.Submenus.includes('Task') && {
          title: 'Task',
          path: '/dashboard/admin/task',
          icon: getIcon(taskIcon)
        }
        // userDetails?.Submenus.includes('Usage') && {
        //   title: 'Usage',
        //   path: '/dashboard/admin/usage',
        //   icon: getIcon(usageFill)
        // }
      ]
    },
    userDetails?.Submenus.includes('Report') && {
      title: 'Reports',
      path: '/dashboard/report',
      icon: getIcon(filesFill),
      children: [
        userDetails?.Submenus.includes('Report') && {
          title: 'Agent Wise Report',
          path: '/dashboard/report/agentwise',
          icon: getIcon(peopleFill)
        },
        userDetails?.Submenus.includes('Report') && {
          title: 'Date Wise Report',
          path: '/dashboard/report/datewise',
          icon: getIcon(outlineDateRange)
        },
        userDetails?.Submenus.includes('Report') && {
          title: 'Daily Report',
          path: '/dashboard/report/dailyreport',
          icon: getIcon(documentExport)
        },
        userDetails?.Submenus.includes('Report') && {
          title: 'Score Report',
          path: '/dashboard/report/scorereport',
          icon: getIcon(reportData)
        }
      ]
    },
    userDetails?.Menus.includes('Feedback') && {
      title: 'Feedback',
      path: '/dashboard/feedback',
      icon: getIcon(personFeedback28Filled),
      children: [
        userDetails?.Submenus.includes('Goal') && {
          title: 'Goals',
          path: '/dashboard/feedback/opportunity',
          icon: getIcon(taskIcon)
        },
        userDetails?.Submenus.includes('Rating') && {
          title: 'Call Rating',
          path: '/dashboard/feedback/rating',
          icon: getIcon(star12Filled)
        },
        userDetails?.Submenus.includes('Script') && {
          title: 'Training Calls',
          path: '/dashboard/feedback/training_calls',
          icon: <img src="/static/agentTraining.png" alt="Training Call" width={22} height={22} />
        }
      ]
    }
  ];

  const sidebarConfigAgent = [
    userDetails?.Menus.includes('Dashboard') && {
      title: 'Dashboard',
      path: '/dashboard/app',
      icon: getIcon(viewDashboard),
      children:
        process.env.REACT_APP_TO_SHOW_CALLDASHBOARD !== 'true'
          ? [
              {
                title: 'Compliance',
                path: '/dashboard/app/compliance',
                icon: getIcon(chart)
              },
              {
                title: 'Collections',
                path: '/dashboard/app/collections',
                icon: getIcon(pie)
              },
              {
                title: 'Training Needs',
                path: '/dashboard/app/training',
                icon: getIcon(chartTree)
              },
              {
                title: 'Call Analysis',
                path: '/dashboard/app/call',
                icon: getIcon(chartTree)
              }
            ]
          : [
              userDetails?.Submenus.includes('Call_analysis') && {
                title: 'Call Analysis',
                path: '/dashboard/app/call',
                icon: getIcon(chartTree)
              },
              userDetails?.Submenus.includes('Revenue_intelligence') && {
                title: 'Revenue Intelligence',
                path: '/dashboard/app/sales',
                icon: getIcon(CarbonAlignVerticalBottom)
              },
              userDetails?.Submenus.includes('Compliance') && {
                title: 'Compliance',
                path: '/dashboard/app/compliance',
                icon: getIcon(chart)
              },
              userDetails?.Submenus.includes('Collection') && {
                title: 'Collections',
                path: '/dashboard/collections',
                icon: getIcon(pie)
              }
            ]
    },
    userDetails.Menus.includes('Workspace') && {
      title: 'Workspace',
      path: '/dashboard/workspace',
      icon: getIcon(grid),
      children: [
        userDetails?.Submenus.includes('Upload') && {
          title: 'Processed Call',
          path: '/dashboard/workspace/upload',
          icon: getIcon(folderUpload)
        },
        userDetails?.Submenus.includes('Keywords') && {
          title: 'Key Words',
          path: '/dashboard/workspace/keywords',
          icon: getIcon(alphabets)
        },
        userDetails?.Submenus.includes('Script') && {
          title: 'Script',
          path: '/dashboard/workspace/compliance',
          icon: getIcon(formOutline)
        }
      ]
    },
    userDetails?.Menus.includes('Administration') && {
      title: 'Administration',
      path: '/dashboard/admin',
      icon: getIcon(accountWrench),
      children: [
        userDetails?.Submenus.includes('Organisation') && {
          title: 'Organisation',
          path: '/dashboard/admin/Organisation',
          icon: getIcon(officeBuilding)
        },
        userDetails?.Submenus.includes('Customer') && {
          title: 'Customer',
          path: '/dashboard/admin/customer',
          icon: getIcon(accountTie)
        },
        userDetails?.Submenus.includes('User') && {
          title: 'Users',
          path: '/dashboard/admin/user',
          icon: getIcon(peopleFill)
        },
        userDetails?.Submenus.includes('Campaign') && {
          title: 'Campaign',
          path: '/dashboard/admin/campaign',
          icon: getIcon(roundCampaign)
        },
        userDetails?.Submenus.includes('Deal') && {
          title: 'Deal',
          path: '/dashboard/admin/deal',
          icon: getIcon(dealIcon)
        },
        userDetails?.Submenus.includes('Task') && {
          title: 'Task',
          path: '/dashboard/admin/task',
          icon: getIcon(taskIcon)
        }
        // userDetails?.Submenus.includes('Usage') && {
        //   title: 'Usage',
        //   path: '/dashboard/admin/usage',
        //   icon: getIcon(usageFill)
        // }
      ]
    },
    userDetails?.Menus.includes('Feedback') && {
      title: 'Feedback',
      path: '/dashboard/feedback',
      icon: getIcon(personFeedback28Filled),
      children: [
        userDetails?.Submenus.includes('Goal') && {
          title: 'Goals',
          path: '/dashboard/feedback/opportunity',
          icon: getIcon(taskIcon)
        },
        userDetails?.Submenus.includes('Rating') && {
          title: 'Call Rating',
          path: '/dashboard/feedback/rating',
          icon: getIcon(star12Filled)
        },
        userDetails?.dashboard_service.includes('compliance') && {
          title: 'Training Calls',
          path: '/dashboard/feedback/training_calls',
          icon: <img src="/static/agentTraining.png" alt="Training Call" width={22} height={22} />
        }
      ]
    }
  ];

  const sidebarConfigQa = [
    userDetails?.Menus.includes('Dashboard') && {
      title: 'Dashboard',
      path: '/dashboard/app',
      icon: getIcon(viewDashboard),
      children:
        process.env.REACT_APP_TO_SHOW_CALLDASHBOARD !== 'true'
          ? [
              {
                title: 'Compliance',
                path: '/dashboard/app/compliance',
                icon: getIcon(chart)
              },
              {
                title: 'Collections',
                path: '/dashboard/app/collections',
                icon: getIcon(pie)
              },
              {
                title: 'Training Needs',
                path: '/dashboard/app/training',
                icon: getIcon(chartTree)
              },
              {
                title: 'Call Analysis',
                path: '/dashboard/app/call',
                icon: getIcon(chartTree)
              }
            ]
          : [
              userDetails?.Submenus.includes('Call_analysis') && {
                title: 'Call Analysis',
                path: '/dashboard/app/call',
                icon: getIcon(chartTree)
              },
              userDetails?.Submenus.includes('Revenue_intelligence') && {
                title: 'Revenue Intelligence',
                path: '/dashboard/app/sales',
                icon: getIcon(CarbonAlignVerticalBottom)
              },
              userDetails?.Submenus.includes('Compliance') && {
                title: 'Compliance',
                path: '/dashboard/app/compliance',
                icon: getIcon(chart)
              },
              userDetails?.Submenus.includes('Collection') && {
                title: 'Collections',
                path: '/dashboard/app/collections',
                icon: getIcon(pie)
              }
              // userDetails?.Submenus.includes('Collection') && {
              //   title: 'Collections',
              //   path: '/dashboard/collections',
              //   icon: getIcon('mdi:collection')
              // }
            ]
    },
    userDetails.Menus.includes('Workspace') && {
      title: 'Workspace',
      path: '/dashboard/workspace',
      icon: getIcon(grid),
      children: [
        userDetails?.Submenus.includes('Upload') && {
          title: 'Upload',
          path: '/dashboard/workspace/upload',
          icon: getIcon(folderUpload)
        },
        userDetails?.Submenus.includes('Keywords') && {
          title: 'Key Words',
          path: '/dashboard/workspace/keywords',
          icon: getIcon(alphabets)
        },
        userDetails?.Submenus.includes('Script') && {
          title: 'Script',
          path: '/dashboard/workspace/compliance',
          icon: getIcon(formOutline)
        }
      ]
    },
    userDetails?.Menus.includes('Administration') && {
      title: 'Administration',
      path: '/dashboard/admin',
      icon: getIcon(accountWrench),
      children: [
        userDetails?.Submenus.includes('Organisation') && {
          title: 'Organisation',
          path: '/dashboard/admin/Organisation',
          icon: getIcon(officeBuilding)
        },
        userDetails?.Submenus.includes('Customer') && {
          title: 'Customer',
          path: '/dashboard/admin/customer',
          icon: getIcon(accountTie)
        },
        userDetails?.Submenus.includes('User') && {
          title: 'Users',
          path: '/dashboard/admin/user',
          icon: getIcon(peopleFill)
        },
        userDetails?.Submenus.includes('Campaign') && {
          title: 'Campaign',
          path: '/dashboard/admin/campaign',
          icon: getIcon(roundCampaign)
        },
        userDetails?.Submenus.includes('Deal') && {
          title: 'Deal',
          path: '/dashboard/admin/deal',
          icon: getIcon(dealIcon)
        },
        userDetails?.Submenus.includes('Task') && {
          title: 'Task',
          path: '/dashboard/admin/task',
          icon: getIcon(taskIcon)
        }
        // userDetails?.Submenus.includes('Usage') && {
        //   title: 'Usage',
        //   path: '/dashboard/admin/usage',
        //   icon: getIcon(usageFill)
        // }
      ]
    },
    userDetails?.Submenus.includes('Report') && {
      title: 'Reports',
      path: '/dashboard/report',
      icon: getIcon(filesFill),
      children: [
        userDetails?.Submenus.includes('Report') && {
          title: 'Agent Wise Report',
          path: '/dashboard/report/agentwise',
          icon: getIcon(peopleFill)
        },
        userDetails?.Submenus.includes('Report') && {
          title: 'Date Wise Report',
          path: '/dashboard/report/datewise',
          icon: getIcon(outlineDateRange)
        },
        userDetails?.Submenus.includes('Report') && {
          title: 'Daily Report',
          path: '/dashboard/report/dailyreport',
          icon: getIcon(documentExport)
        },
        userDetails?.Submenus.includes('Report') && {
          title: 'Score Report',
          path: '/dashboard/report/scorereport',
          icon: getIcon(reportData)
        }
      ]
    },
    userDetails?.Menus.includes('Feedback') && {
      title: 'Feedback',
      path: '/dashboard/feedback',
      icon: getIcon(personFeedback28Filled),
      children: [
        userDetails?.Submenus.includes('Goal') && {
          title: 'Goals',
          path: '/dashboard/feedback/opportunity',
          icon: getIcon(taskIcon)
        },
        userDetails?.Submenus.includes('Rating') && {
          title: 'Call Rating',
          path: '/dashboard/feedback/rating',
          icon: getIcon(star12Filled)
        },
        userDetails?.Submenus.includes('Script') && {
          title: 'Training Calls',
          path: '/dashboard/feedback/training_calls',
          icon: <img src="/static/agentTraining.png" alt="Training Call" width={22} height={22} />
        }
      ]
    }
  ];

  const sidebarConfigGuest = [
    userDetails?.Menus.includes('Dashboard') && {
      title: 'Dashboard',
      path: '/dashboard/app',
      icon: getIcon(viewDashboard),
      children:
        process.env.REACT_APP_TO_SHOW_CALLDASHBOARD !== 'true'
          ? [
              {
                title: 'Compliance',
                path: '/dashboard/app/compliance',
                icon: getIcon(chart)
              },
              {
                title: 'Collections',
                path: '/dashboard/app/collections',
                icon: getIcon(pie)
              },
              {
                title: 'Training Needs',
                path: '/dashboard/app/training',
                icon: getIcon(chartTree)
              },
              {
                title: 'Call Analysis',
                path: '/dashboard/app/call',
                icon: getIcon(chartTree)
              }
            ]
          : [
              userDetails?.Submenus.includes('Call_analysis') && {
                title: 'Call Analysis',
                path: '/dashboard/app/call',
                icon: getIcon(chartTree)
              },
              userDetails?.Submenus.includes('Revenue_intelligence') && {
                title: 'Revenue Intelligence',
                path: '/dashboard/app/sales',
                icon: getIcon(CarbonAlignVerticalBottom)
              },
              userDetails?.Submenus.includes('Compliance') && {
                title: 'Compliance',
                path: '/dashboard/app/compliance',
                icon: getIcon(chart)
              },
              userDetails?.Submenus.includes('Collection') && {
                title: 'Collections',
                path: '/dashboard/collections',
                icon: getIcon(pie)
              }
            ]
    },
    userDetails.Menus.includes('Workspace') && {
      title: 'Workspace',
      path: '/dashboard/workspace',
      icon: getIcon(grid),
      children: [
        userDetails?.Submenus.includes('Upload') && {
          title: 'Upload',
          path: '/dashboard/workspace/upload',
          icon: getIcon(folderUpload)
        },
        userDetails?.Submenus.includes('Keywords') && {
          title: 'Key Words',
          path: '/dashboard/workspace/keywords',
          icon: getIcon(alphabets)
        },
        userDetails?.Submenus.includes('Script') && {
          title: 'Script',
          path: '/dashboard/workspace/compliance',
          icon: getIcon(formOutline)
        }
      ]
    },
    userDetails?.Menus.includes('Administration') && {
      title: 'Administration',
      path: '/dashboard/admin',
      icon: getIcon(accountWrench),
      children: [
        userDetails?.Submenus.includes('Organisation') && {
          title: 'Organisation',
          path: '/dashboard/admin/Organisation',
          icon: getIcon(officeBuilding)
        },
        userDetails?.Submenus.includes('Customer') && {
          title: 'Customer',
          path: '/dashboard/admin/customer',
          icon: getIcon(accountTie)
        },
        userDetails?.Submenus.includes('User') && {
          title: 'Users',
          path: '/dashboard/admin/user',
          icon: getIcon(peopleFill)
        },
        userDetails?.Submenus.includes('Campaign') && {
          title: 'Campaign',
          path: '/dashboard/admin/campaign',
          icon: getIcon(roundCampaign)
        },
        userDetails?.Submenus.includes('Deal') && {
          title: 'Deal',
          path: '/dashboard/admin/deal',
          icon: getIcon(dealIcon)
        },
        userDetails?.Submenus.includes('Task') && {
          title: 'Task',
          path: '/dashboard/admin/task',
          icon: getIcon(taskIcon)
        }
        // userDetails?.Submenus.includes('Usage') && {
        //   title: 'Usage',
        //   path: '/dashboard/admin/usage',
        //   icon: getIcon(usageFill)
        // }
      ]
    },
    userDetails?.Menus.includes('Feedback') && {
      title: 'Feedback',
      path: '/dashboard/feedback',
      icon: getIcon(personFeedback28Filled),
      children: [
        userDetails?.Submenus.includes('Goal') && {
          title: 'Goals',
          path: '/dashboard/feedback/opportunity',
          icon: getIcon(taskIcon)
        },
        userDetails?.Submenus.includes('Rating') && {
          title: 'Call Rating',
          path: '/dashboard/feedback/rating',
          icon: getIcon(star12Filled)
        }
      ]
    }
  ];

  let sidebarConfig;

  console.log('role', role);
  if (role === 'Super_Admin') {
    sidebarConfig = sidebarConfigSuperAdmin;
  } else if (role === 'Customer_Admin') {
    sidebarConfig = sidebarConfigCustomerAdmin;
  } else if (role === 'Manager') {
    sidebarConfig = sidebarConfigManager;
  } else if (role === 'Agent') {
    sidebarConfig = sidebarConfigAgent;
  } else if (role === 'QA') {
    sidebarConfig = sidebarConfigQa;
  } else if (role === 'Guest') {
    sidebarConfig = sidebarConfigGuest;
  } else {
    sidebarConfig = sidebarConfigSuperAdmin;
  }

  console.log('sidebarconfig--', sidebarConfig);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  // const [check, setcheck] = useState(0);

  const [sidebarOpen, setSidebar] = useState(isOpenSidebar);

  const handleLogout = () => {
    // setcheck(check + 1);
    localStorage.clear();
    navigate('/Login', { replace: true });
  };

  useEffect(() => {
    if (sidebarOpen) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      {sidebarOpen ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Logo />

          <Box
            style={{
              backgroundColor: '#3892CF',
              height: 60,
              width: 60,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
            onClick={() => setSidebar(false)}
          >
            <ArrowBack sx={{ color: 'white', cursor: 'pointer' }} />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: '#3892CF',
            width: '100%',
            height: 60,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
          onClick={() => setSidebar(true)}
        >
          <IconButton>
            <Icon icon={menu2Fill} style={{ color: '#ffff' }} />
          </IconButton>
        </Box>
      )}
      <Divider sx={{ marginBottom: 2 }} />
      <Scrollbar
        sx={{
          height: '100%',
          overflowX: 'hidden',
          '& .simplebar-content': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: !sidebarOpen && 'center'
          }
        }}
      >
        {/* {userDetails?.customer_logo !== 'logo not given' ? (
        <Box sx={{ mb: 5, mx: 2.5 }}> */}
        {/* <AccountStyle> */}
        {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
        {/* <Box sx={{ ml: 2 }}>
            <img
              src={`${process.env.REACT_APP_SERVER_APP_URL}${userDetails?.customer_logo}`}
              alt="Not Found"
            /> */}
        {/* <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {userDetails.first_name} {userDetails.last_name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {account.role}
            </Typography> */}
        {/* </Box> */}
        {/* </AccountStyle> */}
        {/* </Box>
      ) : null} */}

        <NavSection navConfig={sidebarConfig} sidebarCollapse={sidebarOpen} />
        <Box sx={{ height: 32 }} onClick={handleLogout}>
          <ListItemStyle>
            {sidebarOpen ? (
              <ListItemIconStyle>
                <Icon icon={logout} width={22} height={22} />
              </ListItemIconStyle>
            ) : (
              <Tooltip title="Logout" followCursor placement="top">
                <ListItemIconStyle>
                  <Icon icon={logout} width={22} height={22} />
                </ListItemIconStyle>
              </Tooltip>
            )}

            {sidebarOpen && <ListItemText disableTypography primary="Logout" />}
          </ListItemStyle>
        </Box>
      </Scrollbar>
    </>
  );

  return (
    // <RootStyle>
    <>
      <DashboardNavbar onOpenSidebar={() => setSidebar(true)} />
      <MHidden width="lgDown">
        <Drawer
          open={sidebarOpen}
          // onClose={onCloseSidebar}
          variant="permanent"
          // PaperProps={{
          //   sx: {
          //     width: sidebarOpen === true ? DRAWER_WIDTH : COLLAPSE_DRAWER_WIDTH
          //   }
          // }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgUp">
        {sidebarOpen && (
          <MobileDrawer
            open={sidebarOpen}
            onClose={() => setSidebar(false)}
            variant="persistant"
            PaperProps={{
              sx: {
                width: sidebarOpen === true ? DRAWER_WIDTH : COLLAPSE_DRAWER_WIDTH
              }
            }}
          >
            {renderContent}
          </MobileDrawer>
        )}
      </MHidden>
    </>
    // </RootStyle>
  );
}
