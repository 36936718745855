import React from 'react';
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  Box,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

export function CustomFormModel(props) {
  const { children } = props;
  return (
    <Dialog
      // PaperProps={{
      //   style: {
      //     zIndex: 5
      //   }
      // }}
      open={props.open}
      // onClose={props.handleDialog}
      fullScreen={props.fullScreen}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle color="#5048E5">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            sx={{
              color: '#3892CF',
              '&:hover': {
                color: '#3892CF'
              }
            }}
          >
            {props.title}
          </Typography>
          <div>
            {props.button ? (
              <LoadingButton
                variant="contained"
                color="primary"
                sx={{
                  marginBottom: 3.5,
                  backgroundColor: '#3892CF',
                  '&:hover': {
                    backgroundColor: '#3892CF'
                  }
                }}
              >
                Save
              </LoadingButton>
            ) : null}
            {props.downloadButton && (
              <LoadingButton
                variant="contained"
                onClick={() => {
                  if (props.downloadFunction) {
                    if (props.setDownloadChart) {
                      props.setDownloadChart('keywords_category');
                    }
                    props.downloadFunction();
                  }
                }}
                to="#"
                loading={props.downloadStart}
                sx={{
                  marginBottom: 3.5,
                  marginRight: 1.5,
                  backgroundColor: '#107c41',
                  '&:hover': {
                    backgroundColor: '#3AB170'
                  }
                }}
              >
                Download
              </LoadingButton>
            )}
            <CloseIcon
              sx={{
                fontSize: '40px',
                cursor: 'pointer',
                color: '#3892CF',
                '&:hover': {
                  color: '#3892CF'
                }
              }}
              onClick={(e) => props.handleDialog()}
            />
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
        <Box
          style={{
            padding: 5,
            height: props.height ? props.height : 400
          }}
        >
          {children}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
