import React from 'react';

export default function Training() {
  return (
    <div>
      {/* <p>
        hello मेरी बात hello क्या मेरी बात sir दोनों से हो रही है hello हाँ आवाज़ आ रही है थोड़ा सा जी
        बात हो है है सोलह ka जी बात कर रहे हैं आगे ये बोलिए ना यहाँ की बात कर मैं जो finance किया थे
        गाड़ी loan के समय call किया था मैंने हाँ बोलिए payment bharti है क्या का card में sir आपको
        दिमाग ख़राब को जमा करने आ गया hyphen appointment हो या कहाँ हो गए और sir बात तसल्ली एक तो
        कितना payment बात आवाज़ में भरा मुझे तो payment पंद्रह रुपए मेरी बात ये आप आप call उसका अभी
        बाकी हो जाएगा तो payment card में जमा करके अभी आ रहा ऐसा बोले जानना तो के बता रहे हो तो time
        करते है बहुत बहुत तो यहाँ पे भर में cyber बार call करेंगे payment है तो करेंगे call आपको
        बिल्कुल अगर कोई नहीं call करेगा कोई नहीं पड़ेगा तो कौन साल payment नहीं जमा जमा हुआ है
        payment नहीं जी payment पिछला बाकी तो नहीं हुआ आता है मैंने करवाती पहली बात तो पहली बात तो
        पैसा मैंने बाकी है आवाज़ करने की ज़रूरत नहीं है तेरा पे समझ बात ये है पंद्रह अगर से branch बात
        किए है तो आवाज़ जरा नीचे बात कर सकते हो मैं आवाज़ पड़ा में हर महीना जमा कर रहे है आप बोले मैं
        बाहर वाले कोई भी मुझे कोई फ़र्क नहीं पड़ रहा भाड़ मैंने जमा करता band बाकी उसके बारे बात कर
        रहा हूं मैं. उसके लिए call किया मेरे वो अभी jam होगा वो जमा होगा तो फिर आवाज़ की बढ़ा के बात
        कर रहे हो बात तो कल को बनाने तो नहीं करेगा. पहली बात तो पैसा भरी है फिर बोलिए आप कोई बोलने
        का
      </p> */}
      training
    </div>
  );
}
