/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import {
  Card,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TablePagination,
  Skeleton,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Box,
  Chip,
  Stack,
  Pagination
} from '@mui/material';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
// Component
import Scrollbar from '../../components/Scrollbar';
import { CustomFormModel } from '../../components/custom-form-model/CustomFormModel';
import API from '../../E2E/axios.util';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
import { DomainEditForm } from './DomainEditForm';

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: '#3892CF',
        color: 'white'
        // borderRadius: '50%',
      }
    }
  }
}));

const KEYWORDS_TABLE_HEAD = [
  {
    id: 'OrgName',
    numeric: false,
    disablePadding: false,
    label: 'Organisation Name',
    alignCenter: true
  },
  {
    id: 'customerName',
    numeric: false,
    disablePadding: false,
    label: 'Customer Name',
    alignCenter: true
  },
  {
    id: 'Created Date',
    numeric: false,
    disablePadding: false,
    label: 'Created Date',
    alignCenter: true
  },
  {
    id: 'domain',
    numeric: false,
    disablePadding: false,
    label: 'Domain',
    alignCenter: true
  },
  {
    id: 'Domain Category',
    numeric: false,
    disablePadding: false,
    label: 'Domain Category',
    alignCenter: true
  },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', alignCenter: true },
  { id: '' }
];

export default function DomainDataModel({
  setAlert,
  setAlertContent,
  setAlertType,
  handleDialog,
  formik,
  isUpdated,
  updateComponent,
  keywordList,
  setKeywordList
}) {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState();
  const [keywordSearch, setKeywordSearch] = useState();

  const userDetails = JSON.parse(localStorage.getItem('user'));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Function to call list api for keywords
  const handleGetDomain = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await API.get(`/secure/list_domain_categories?page=${page}`, config)
        .then((res) => {
          setKeywordList(res.data);
          setAlertContent(`Success: ${res.data.message}`);
          setAlertType('success');
          setAlert(true);
          setLoading(false);
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAlertContent(`${err.response.data.message}`);
          setAlertType('error');
          setAlert(true);
          setLoading(false);
        });
    } else {
      setAlertContent(`Error : Authentication Failed Due to Token Expired.`);
      setAlertType('error');
      setAlert(true);
      setLoading(false);
    }
  };

  //   Function to show tooltip
  const showToolTip = (value) => {
    if (value.length > 10) {
      return (
        <Tooltip title={value} followCursor placement="top">
          <TableCell align="left" sx={{ cursor: 'pointer' }}>
            <span>{value.slice(0, 10)}.....</span>
          </TableCell>
        </Tooltip>
      );
    }
    return <TableCell align="left">{value}</TableCell>;
  };

  const showToolProcessTip = (processes) => {
    const wordsview = processes.map((el) => el.words[0]);

    return (
      <TableCell
        align="center"
        style={{
          whiteSpace: 'nowrap',
          maxWidth: '20px', // Adjust the max width as needed
          overflow: 'hidden',
          textOverflow: 'ellipsis' // Add ellipsis for truncation
        }}
        title={wordsview.join()}
      >
        <span>{wordsview.join()}</span>
      </TableCell>
    );

    // }
    // processes.map((el) => {
    //   console.log('processes', el.words.join());
    //   return el;
    // });
    // return (
    //   <TableCell align="left">
    //     {processes.map((process) => (
    //       <span>{process.words.join()} </span>
    //     ))}
    //   </TableCell>
    // );
  };

  const showToolSentenceTip = (processes) => {
    if (processes.length > 2) {
      return (
        <Tooltip
          title={processes.map((element) => (
            <span>{Object.values(element)[0]}, </span>
          ))}
          followCursor
          placement="top"
        >
          <TableCell align="left" sx={{ cursor: 'pointer' }}>
            <div>
              {Object.values(processes[0])[0]}, {Object.values(processes[1])[0]} .....
            </div>
            <div>{Object.values(processes[1])[0]} .....</div>
          </TableCell>
        </Tooltip>
      );
    }
    return (
      <TableCell align="left">
        {processes.map((process) => (
          <div>{Object.values(process)[0]} </div>
        ))}
      </TableCell>
    );
  };

  useEffect(() => {
    if (!query) {
      handleGetDomain();
    }
  }, [isUpdated, page]);

  const handleSearch = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await API.get(`/secure/keywords_search?page=${page}&query=${query}`, config)
        .then((res) => {
          setKeywordSearch(res.data);
          // if (res.data.keywords.length > 0) {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    if (query?.length > 0) {
      handleSearch();
    }
    console.log('query', query);
  }, [debouncedQuery, isUpdated, page]);

  const changedDate = (date) => {
    const newdate = new Date(date);
    const formatStartDate = `${newdate.getDate()}-${
      newdate.getMonth() + 1
    }-${newdate.getFullYear()}`;
    return formatStartDate;
  };

  return (
    <Card>
      {/* <UserListToolbar
        placeholder="Search"
        onFilterName={(e) => {
          setQuery(e.target.value);
          if (query?.length > 0) {
            setPage(1);
          }
        }}
      /> */}
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead headLabel={KEYWORDS_TABLE_HEAD} rowCount={page} />
            {(query && !loading && keywordSearch && (
              <TableBody>
                {keywordSearch.results
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      categories,
                      created_by,
                      created_date,
                      customer_id,
                      domain_category_id,
                      domain_id,
                      modified_by,
                      modified_date,
                      organisation_id,
                      status
                    } = row;

                    return (
                      <TableRow hover key={1} tabIndex={-1} role="checkbox">
                        <TableCell
                          component="th"
                          scope="row"
                          padding="normal"
                          align="center"
                          style={{
                            whiteSpace: 'nowrap',
                            maxWidth: '80px', // Adjust the max width as needed
                            overflow: 'hidden',
                            textOverflow: 'ellipsis' // Add ellipsis for truncation
                          }}
                          title={organisation_id}
                        >
                          {organisation_id}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            whiteSpace: 'nowrap',
                            maxWidth: '80px', // Adjust the max width as needed
                            overflow: 'hidden',
                            textOverflow: 'ellipsis' // Add ellipsis for truncation
                          }}
                          title={customer_id}
                        >
                          {customer_id}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            whiteSpace: 'nowrap',
                            maxWidth: '80px', // Adjust the max width as needed
                            overflow: 'hidden',
                            textOverflow: 'ellipsis' // Add ellipsis for truncation
                          }}
                        >
                          {moment(changedDate(modified_date), 'D-M-YYYY').format('DD/MM/YYYY')}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            whiteSpace: 'nowrap',
                            maxWidth: '80px', // Adjust the max width as needed
                            overflow: 'hidden',
                            textOverflow: 'ellipsis' // Add ellipsis for truncation
                          }}
                          title={categories.join()}
                        >
                          {categories.join()}
                        </TableCell>

                        <TableCell align="center">
                          <Chip label={status} sx={{ color: '#3892CF' }} />
                        </TableCell>

                        {userDetails.role !== 'Manager' && (
                          <TableCell align="right">
                            <UserMoreMenu
                              formik={formik}
                              isUpdated={isUpdated}
                              updateComponent={updateComponent}
                              setAlert={setAlert}
                              setAlertContent={setAlertContent}
                              setAlertType={setAlertType}
                              keywordId={domain_category_id}
                              Details={row}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            )) ||
              // :
              // (
              //   <TableBody>
              //     {!loading && !keywordSearch?.results.length > 0 ? (
              //       'Keywords Not Found'
              //     ) : (
              //       <CircularProgress />
              //     )}
              //   </TableBody>
              // )
              (!loading && keywordList && keywordList.results.length > 0 ? (
                <TableBody>
                  {keywordList.results
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        categories,
                        created_by,
                        created_date,
                        customer_id,
                        domain_category_id,
                        domain_id,
                        modified_by,
                        modified_date,
                        organisation_id,
                        domain_name,
                        organisation_name,
                        customer_name,
                        status
                      } = row;

                      return (
                        <TableRow hover key={1} tabIndex={-1} role="checkbox">
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            align="center"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            title={organisation_name}
                          >
                            {organisation_name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            title={customer_name}
                          >
                            {customer_name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                          >
                            {moment(changedDate(created_date), 'D-M-YYYY').format('DD/MM/YYYY')}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            title={domain_name}
                          >
                            {domain_name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                            title={categories.join()}
                          >
                            {categories.join()}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: '80px', // Adjust the max width as needed
                              overflow: 'hidden',
                              textOverflow: 'ellipsis' // Add ellipsis for truncation
                            }}
                          >
                            <Chip label={status} sx={{ color: '#3892CF' }} />
                          </TableCell>
                          {/* <TableCell align="left">
                          {sentences.map((sentence) => (
                            <div>{Object.values(sentence)[0]} </div>
                          ))}
                        </TableCell> */}

                          {userDetails.role !== 'Manager' && (
                            <TableCell align="center">
                              <UserMoreMenu
                                formik={formik}
                                isUpdated={isUpdated}
                                updateComponent={updateComponent}
                                setAlert={setAlert}
                                setAlertContent={setAlertContent}
                                setAlertType={setAlertType}
                                keywordId={domain_category_id}
                                Details={row}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    {!loading ? (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        Domain Category Not Found
                      </TableCell>
                    ) : (
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <CircularProgress sx={{ color: '#3892CF' }} />
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              ))}
          </Table>
        </TableContainer>
      </Scrollbar>
      <Stack
        spacing={2}
        sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
      >
        <Pagination
          classes={{
            root: classes.ul
          }}
          count={
            query && keywordSearch
              ? Math.ceil(keywordSearch.count / 10)
              : keywordList
              ? Math.ceil(keywordList.count / 10)
              : 1
          }
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Stack>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        rowsPerPage={rowsPerPage}
        count={!loading && keywordList?.keyword_list ? keywordList?.keyword_list?.length : 0}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Card>
  );
}

function UserMoreMenu({
  formik,
  isUpdated,
  updateComponent,
  setAlert,
  setAlertContent,
  setAlertType,
  keywordId,
  Details
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(openEdit);

  const handleDialog = () => {
    setEditOpen(!openEdit);
    setIsOpen(false);
  };

  const handleDelete = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = {
      id: keywordId,
      status: Details.status === 'Active' ? 'Inactive' : 'Active'
    };
    await API.put(`/secure/domain_categories_status_update`, data, config)
      .then((res) => {
        console.log('delete Organisation res-', res);
        setAlertContent(`${res.data.message}`);
        setAlertType('success');
        setAlert(true);
      })
      .then(() => {
        setIsOpen(false);
        updateComponent(isUpdated + 1);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={openEdit}
        title="Update Domain Category"
      >
        <DomainEditForm
          formik={formik}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          setAlert={setAlert}
          isUpdated={isUpdated}
          updateComponent={updateComponent}
          handleDialog={handleDialog}
          Details={Details}
        />
      </CustomFormModel>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDialog}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {Details.status === 'Active' ? (
          <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDelete}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Inactive" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ) : (
          <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDelete}>
            <ListItemIcon>
              <div
                style={{
                  width: '13px',
                  height: '13px',
                  borderRadius: '50%',
                  backgroundColor: '#02A902'
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Active" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
