/* eslint-disable   no-lonely-if */
/* eslint-disable   prefer-template */
/* eslint-disable   no-else-return */
import React, { useContext, useState } from 'react';
import {
  BarChart,
  ComposedChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  Area,
  ResponsiveContainer,
  Brush,
  PieChart,
  Pie,
  Sector,
  LabelList
} from 'recharts';
import moment from 'moment';
import {
  Card,
  CardHeader,
  Grid,
  Button,
  Select,
  InputLabel,
  MenuItem,
  Tooltip as Tip,
  Typography
} from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import Chart, {
  ArgumentAxis,
  Series,
  ZoomAndPan,
  Legend as ExtremeLegend,
  Tooltip as ExtremeTooltip,
  ScrollBar,
  CommonSeriesSettings,
  Export,
  Title,
  ValueAxis
} from 'devextreme-react/chart';
import { Icon } from '@iconify/react';
import download from '@iconify/icons-eva/download-fill';
import inputContex from '../../Context/UploadContext/inputContext';

function StackBar({
  graphData,
  handleDialog,
  setDate,
  handleTonePopup,
  setDeadAirDate,
  downloadChart,
  filteredTone,
  toneData,
  newDefaultTone,
  defaultDashboardData,
  handleSummaryTonePopup,
  setPageForPopup
}) {
  const {
    toneDate,
    setToneDate,
    setDataIndex,
    setGlobalToneValue,
    setGlobalDate,
    setGlobalBarValue,
    updateGlobal,
    setUpdateGlobal,
    pieUpdateGlobal,
    setPieUpdateGlobal
  } = useContext(inputContex);
  const [open, setOpen] = useState(false);
  // const [high, setHigh] = useState();
  const [chartType, setChartType] = useState('summary');

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload.dates;

      setToneDate(data);
      setDate(null);
      setDataIndex(null);
      setDeadAirDate(null);
      const words = Object.keys(payload[0].payload);
      return (
        <Card className="custom-tooltip" sx={{ padding: '10px' }}>
          <p className="intro">{data === 'total' ? '' : `Date:${data}`}</p>
          {words.map((element, index) => {
            if (element !== 'dates') {
              return <p className="intro">{`${element} : ${payload[0].payload[element]} calls`}</p>;
            }
            return null;
          })}
        </Card>
      );
    }
    return null;
  };
  const format = [
    { value: 'High Tone', type: 'square', color: 'RGB(0 113 193)' },
    { value: 'Medium Tone', type: 'square', color: 'RGB(146 200 255)' },
    { value: 'Low Tone', type: 'square', color: 'RGB(204 230 255)' }
  ];

  let totalHigh = 0;
  let totalMedium = 0;
  let totalLow = 0;

  if (graphData) {
    // Iterate over the array and accumulate the totals
    graphData.forEach((item) => {
      totalHigh += parseFloat(item.high);
      totalMedium += parseFloat(item.medium);
      totalLow += parseFloat(item.low);
    });
  }

  // const highPercentage = (totalHigh / (totalHigh + totalMedium + totalLow)) * 100;
  // const mediumPercentage = (totalMedium / (totalHigh + totalMedium + totalLow)) * 100;
  // const lowPercentage = (totalLow / (totalHigh + totalMedium + totalLow)) * 100;

  const pieChartData = [
    { name: 'High', value: totalHigh },
    { name: 'Medium', value: totalMedium },
    { name: 'Low', value: totalLow }
  ];
  // const [summaryChartData, setSummaryChartData] = useState(pieChartData);

  // const handleSummaryLegendClick = (entry) => {
  //   // console.log('guruu', entry);
  //   const newData = pieChartData.filter((item) => item.name === entry.value); // Filter data based on legend clicked
  //   setSummaryChartData(newData);
  // };

  // const renderLegend = (props) => {
  //   const { payload } = props;

  //   return (
  //     <ul>
  //       {payload.map((entry, index) => (
  //         <li key={`item-${index}`}>{entry.value}</li>
  //       ))}
  //     </ul>
  //   );
  // };

  const pieSummary = [
    { value: 'High Tone', type: 'square', color: '#f75e5e' },
    { value: 'Medium Tone', type: 'square', color: '#00C49F' },
    { value: 'Low Tone', type: 'square', color: '#FFBB28' }
  ];

  const COLORS = ['#f75e5e', '#00C49F', '#FFBB28'];

  const RADIAN = Math.PI / 180;
  function formatNumber(num) {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B';
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + 'K';
    } else {
      return num.toString();
    }
  }
  const renderLabelList = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
      <g>
        <text
          x={x + width * 1.07}
          y={y + 11}
          fill="black"
          textAnchor="middle"
          dominantBaseline="middle"
          fontWeight="bold"
          fontSize="11px"
        >
          {value}
        </text>
      </g>
    );
  };
  const renderYaxisLabel = (props) => {
    const { x, y, stroke, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={25} textAnchor="end" fill="#666" fontWeight="bold" fontSize="10px">
          {payload.value}
        </text>
      </g>
    );
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value
  }) => {
    // console.log('indexxxx', index);
    const lineRadius = innerRadius + (outerRadius - innerRadius); // Adjust the multiplier to place the label line
    const lineX = cx + lineRadius * Math.cos(-midAngle * RADIAN);
    const lineY = cy + lineRadius * Math.sin(-midAngle * RADIAN);

    const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const color = COLORS[index % COLORS.length];

    return (
      <Tip title={`${value} calls`} arrow>
        {/* <line x1={cx} y1={cy} x2={lineX} y2={lineY} stroke={color} strokeWidth={2} />
        <line x1={lineX} y1={lineY} x2={x} y2={y} stroke={color} strokeWidth={2} /> */}
        <text
          x={x}
          y={y}
          fill={color}
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
          style={{
            cursor: 'pointer',
            border: 'none', // Change the border style when clicked
            outline: 'none'
          }}
        >
          {value > 0 && `${formatNumber(value)} calls (${(percent * 100).toFixed(0)}%)`}
        </text>
      </Tip>
    );
  };
  const shortenLabel = (label) => {
    const maxLength = 8; // Set the maximum length you want
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  };

  const visualRange = { startValue: 300, endValue: 500 };

  const high = [];
  const medium = [];
  const low = [];

  graphData?.map((element, index) => {
    high.push(element.high.toFixed(1));
    medium.push(element.medium.toFixed(1));
    low.push(element.low.toFixed(1));
    return element;
  });

  const series = [
    {
      name: 'High',
      type: 'bar',
      data: high
    },
    {
      name: 'Medium',
      type: 'bar',
      data: medium
    },
    {
      name: 'Low',
      type: 'bar',
      data: low
    },
    {
      name: 'High Tone Trend',
      type: 'line',
      data: high
    }
  ];
  // console.log('graphData---', series);
  const getType = (index) => {
    if (index === 0) {
      return 'high';
    }
    if (index === 1) {
      return 'medium';
    }
    if (index === 2) {
      return 'low';
    }
  };
  const options = {
    chart: {
      id: `basic-bar${Math.random()}`,
      toolbar: {
        show: true,
        tools: {
          download: true, // Set to false to remove the download tool
          selection: false, // Set to false to remove the selection tool
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: []
        },
        export: {
          csv: {
            filename: 'tone_result',
            columnDelimiter: ',',
            headerCategory: 'Date',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            }
          },
          svg: {
            filename: undefined
          },
          png: {
            filename: undefined
          }
        },
        autoSelected: 'zoom'
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          // console.log('guru', config);
          setGlobalToneValue();
          setToneDate(graphData[config.dataPointIndex].dates);
          setDataIndex(null);
          if (setDate) {
            setDate(null);
          }
          if (setDeadAirDate) {
            setDeadAirDate(null);
          }
          if (defaultDashboardData) {
            if (setGlobalDate) {
              setGlobalDate(graphData[config.dataPointIndex].dates);
            }
            if (setGlobalBarValue) {
              setGlobalBarValue(getType(config.seriesIndex));
            }
            if (setPageForPopup) {
              setPageForPopup(1);
            }

            if (newDefaultTone) {
              newDefaultTone(
                getType(config.seriesIndex),
                'tone',
                graphData[config.dataPointIndex].dates
              );
            }
            setUpdateGlobal(updateGlobal + 1);
          } else {
            if (setGlobalDate) {
              setGlobalDate(graphData[config.dataPointIndex].dates);
            }
            if (setGlobalBarValue) {
              setGlobalBarValue(getType(config.seriesIndex));
            }
            if (setPageForPopup) {
              setPageForPopup(1);
            }
            if (handleTonePopup) {
              handleTonePopup(getType(config.seriesIndex), graphData[config.dataPointIndex].dates);
            }
            setUpdateGlobal(updateGlobal + 1);
          }
        }
      },
      type: 'line',
      width: 700,
      height: 450
      // stacked: true

      // stackType: '100%'
    },
    colors: ['#f75e5e', '#00c49f', '#ffbb28', 'grey'],

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40%',
        cursor: 'pointer'
        // startingShape: 'rounded',
        // endingShape: 'rounded',
        // colors: {
        //   backgroundBarColors: ['#eee'],
        //   backgroundBarOpacity: 1,
        //   backgroundBarRadius: 9
        // }
        // barHeight: '20%'
      }
    },
    dataLabels: {
      enabled: false,
      // textAnchor: 'start',
      style: {
        fontSize: '11px',
        colors: ['white']
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: '#000',
        opacity: 0.45
      }

      // offsetX: 0
    },
    stroke: {
      width: [0, 0, 0, 2],
      colors: ['grey']
    },
    title: {
      text: ''
    },
    xaxis: {
      categories: graphData && graphData.length > 0 && graphData.map((el) => el.dates),
      labels: {
        formatter(val) {
          const index = val.indexOf('/');

          // If '/' is found, return the substring before it
          if (index !== -1) {
            return val.substring(0, index);
          }

          // If '/' is not found, return the entire string
          return val;
        }
      },
      title: {
        text: undefined
      }
    },
    yaxis: {
      title: {
        text: 'Call Count',
        offsetX: 0,
        offsetY: 10,
        style: {
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 650,
          cssClass: 'apexcharts-xaxis-title'
        }
      },
      labels: {
        formatter(val) {
          return `${val.toFixed(0)}`;
        }
      }
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val} Calls`;
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      // onItemHover: {
      //   highlightDataSeries: false
      // },
      position: 'bottom',
      horizontalAlign: 'center',
      // offsetX: 40,
      onItemClick: {
        toggleDataSeries: ({ seriesIndex }) => console.log('seriesIndex', seriesIndex)
      }
    }
  };

  return (
    <Card sx={{ height: 365 }}>
      {/* <Grid container spacing={10}> */}
      {/* <Grid item xs={12} md={6}>
          <CardHeader title="Tone" />
        </Grid> */}
      {/* <Grid item xs={12} md={6}>
          {toneData && (
            <Button
              style={{ marginTop: 20, marginLeft: '7rem' }}
              onClick={(e) => downloadChart('tone_graph')}
              variant="primary"
              startIcon={<Icon style={{ marginLeft: 10 }} icon={download} />}
            />
          )}
        </Grid> */}
      {/* </Grid> */}
      <div
        style={{
          padding: 10,
          borderBottom: '1px solid #f1f3f4',
          backgroundColor: '#F4F4F4',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <b>Tone</b>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Select
            size="small"
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
            // MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            <MenuItem value="summary">Summary</MenuItem>
            <MenuItem value="trend">Trend</MenuItem>
          </Select>

          {toneData && (
            <Button
              // style={{ marginTop: 20, marginLeft: '7rem' }}
              disabled={totalHigh + totalMedium + totalLow === 0}
              size="small"
              onClick={(e) => downloadChart('tone_graph')}
              variant="primary"
            >
              <Icon icon={download} />
            </Button>
          )}
        </div>
      </div>
      {chartType === 'trend' && (
        <ResponsiveContainer width="100%" height="83%">
          {series[0].data.length > 0 && series[1].data.length > 0 && series[2].data.length > 0 ? (
            <ReactApexChart options={options} series={series} type="bar" />
          ) : (
            <div
              style={{
                width: '110%',
                height: '90%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <span style={{ fontSize: 20 }}>
                <b>No High Tone Calls</b>
              </span>
            </div>
          )}
        </ResponsiveContainer>
        // <>
        //   {graphData?.length > 0 ? (
        //     <ResponsiveContainer width="100%" height={450}>
        //       <BarChart
        //         // width={500}
        //         // height={300}
        //         data={graphData}
        //         margin={{
        //           top: 20,
        //           right: 30,
        //           left: 20,
        //           bottom: 5
        //         }}
        //       >
        //         {/* <CartesianGrid strokeDasharray="3 3" /> */}
        //         <XAxis
        //           dataKey="dates"
        //           fontFamily="Roboto, sans-serif"
        //           // fontSize={12}
        //           reversed
        //           fontWeight="bold"
        //           // tick={{stroke: 'red', strokeWidth: 2}}
        //           tick={{
        //             fontFamily: 'cursive, sans-serif',
        //             fontSize: 10,
        //             fontWeight: 100,
        //             strokeWidth: 2
        //           }}
        //           tickLine={false}
        //           angle={-38}
        //           tickFormatter={(value) => shortenLabel(value)}
        //           // axisLine={false}
        //           // angle={-40}
        //           // interval={5}
        //         />
        //         <YAxis
        //           label={{
        //             value: 'Call Count',
        //             angle: -90,
        //             position: 'insideLeft',
        //             fontWeight: 'bold',
        //             color: 'grey'
        //           }}
        //           tickLine={false}
        //           // axisLine={false}
        //           tick={{ fontFamily: 'cursive, sans-serif', fontSize: 10, fontWeight: 'bold' }}
        //           fontSize={14}
        //           fontWeight="bold"
        //         />
        //         <Tooltip content={<CustomTooltip />} />
        //         <Legend
        //           payload={format.map((item, index) => ({
        //             type: 'square',
        //             value: `${item.value}`,
        //             color: item.color
        //           }))}
        //           wrapperStyle={{ fontWeight: 'bold' }}
        //           layout="horizontal"
        //           align="right"
        //           horizontalAlign="center"
        //         />

        //         <Bar
        //           dataKey="high"
        //           barSize={30}
        //           stackId="a"
        //           fill="RGB(0 113 193)"
        //           style={{ cursor: 'pointer' }}
        //           onClick={(e) => {
        //             if (defaultDashboardData) {
        //               newDefaultTone('high', 'tone', toneDate);
        //             } else {
        //               handleTonePopup('high');
        //             }
        //           }}
        //         />
        //         <Bar
        //           dataKey="medium"
        //           // barSize={50}
        //           stackId="a"
        //           fill="RGB(146 200 255)"
        //           style={{ cursor: 'pointer' }}
        //           onClick={(e) => {
        //             if (defaultDashboardData) {
        //               newDefaultTone('medium', 'tone', toneDate);
        //             } else {
        //               handleTonePopup('medium');
        //             }
        //           }}
        //         />
        //         <Bar
        //           dataKey="low"
        //           // barSize={50}
        //           stackId="a"
        //           fill="RGB(204 230 255)"
        //           style={{ cursor: 'pointer' }}
        //           onClick={(e) => {
        //             if (defaultDashboardData) {
        //               newDefaultTone('low', 'tone', toneDate);
        //             } else {
        //               handleTonePopup('low');
        //             }
        //           }}
        //         />
        //         {/* <Area type="monotone" dataKey="high" fill="#8884d8" stroke="#8884d8" />
        //   <Line type="monotone" dataKey="high" stroke="#ff7300" /> */}
        //       </BarChart>
        //     </ResponsiveContainer>
        //   ) : (
        //     // <Chart id="chart" dataSource={graphData} onPointClick={(e) => console.log('hitt', e)}>
        //     //   <CommonSeriesSettings argumentField="dates" type="stackedbar" />
        //     //   <Series valueField="high" name="High" color="#0088fe" barWidth={30} />
        //     //   <Series valueField="medium" name="Medium" color="#00c49f" barWidth={30} />
        //     //   <Series valueField="low" name="Low" color="#ffbb28" barWidth={30} />
        //     //   <ArgumentAxis defaultVisualRange={visualRange} />
        //     //   <ValueAxis position="left">
        //     //     <Title text="Call Count" fontSize={14} />
        //     //   </ValueAxis>
        //     //   <ZoomAndPan argumentAxis="both" />
        //     //   {/* <ExtremeLegend
        //     //     verticalAlignment="bottom"
        //     //     horizontalAlignment="center"
        //     //     itemTextPosition="top"
        //     //   /> */}
        //     //   <Export />
        //     //   <ScrollBar visible position="bottom" />
        //     //   <ExtremeTooltip enabled location="edge" />
        //     // </Chart>
        //     <div
        //       style={{
        //         width: '100%',
        //         height: '80%',
        //         display: 'flex',
        //         flexDirection: 'column',
        //         justifyContent: 'center',
        //         alignItems: 'center'
        //       }}
        //     >
        //       <span style={{ fontSize: 20 }}>
        //         <b>No High Tone Calls</b>
        //       </span>
        //     </div>
        //   )}
        // </>
      )}
      {chartType === 'summary' && (
        <>
          {graphData?.length > 0 ? (
            <ResponsiveContainer width="100%" height="83%">
              <PieChart>
                <Pie
                  data={pieChartData}
                  cx="50%"
                  cy="50%"
                  // labelLine={false}
                  // label={renderCustomizedLabel}
                  outerRadius="95%"
                  // fill="#0088FE"
                  dataKey="value"
                >
                  {pieChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                      style={{ cursor: 'pointer', outline: 'none', stroke: 'none' }}
                      onClick={() => {
                        setGlobalDate();
                        setGlobalBarValue();
                        if (setGlobalToneValue) {
                          setGlobalToneValue(entry.name.toLowerCase());
                        }
                        if (setPageForPopup) {
                          setPageForPopup(1);
                        }
                        if (setPieUpdateGlobal) {
                          setPieUpdateGlobal(pieUpdateGlobal + 1);
                        }
                        if (handleSummaryTonePopup) {
                          handleSummaryTonePopup(entry.name.toLowerCase());
                        }
                      }}
                    />
                  ))}
                </Pie>
                <Legend
                  layout="vertical"
                  verticalAlign="top"
                  align="right"
                  formatter={(value, entry) => `${value}: ${entry.payload.value} calls`}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <div
              style={{
                width: '100%',
                height: '80%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <span style={{ fontSize: 20 }}>
                <b>No High Tone Calls</b>
              </span>
            </div>
          )}
        </>
      )}
    </Card>
  );
}

export default StackBar;
