import * as Yup from 'yup';
import React, { useState, useEffect, useRef } from 'react';
import { filter } from 'lodash';
import refreshIcon from '@iconify/icons-mdi/refresh';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import {
  Stack,
  TextField,
  useMediaQuery,
  Button,
  Grid,
  Card,
  Container,
  Typography,
  Checkbox,
  TableRow,
  Skeleton,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TablePagination
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import API from '../E2E/axios.util';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { RoleEditForm } from '../components/custom-form-model/CRUDForms';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import Page from '../components/Page';
import { Notification } from '../components/Notification/Notification';
import Label from '../components/Label';

const ROLES_TABLE_HEAD = [
  { id: 'name', label: 'Roles Name', alignRight: false },
  { id: 'description', label: 'Roles Description', alignRight: false },
  { id: 'is_default', label: 'Default', alignRight: false },
  { id: 'is_admin', label: 'Admin', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Roles() {
  const navigate = useNavigate();
  const [open, setOpen] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [update, setUpdate] = useState(0);

  const delayMsg = () => {
    setTimeout(() => {
      setUpdate(update + 1);
    }, 1000);
  };

  const RolesSchema = Yup.object().shape({
    name: Yup.string()
      .matches(
        /^\S+[A-Za-z\s_]{2,15}$/,
        'Role name should be 3 to 15 character long and it should not start with blank space and not have special character and number.'
      )
      .required('This field is required.'),
    description: Yup.string()
      .matches(
        /^\S+[A-Za-z\s!@)(+=,._-]{2,50}$/,
        'Description length should be 3 to 50 character long and it should not start with blank space and not have special character and number.'
      )
      .required('This field is required.'),
    is_default: Yup.boolean(),
    is_admin: Yup.boolean(),
    is_active: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      is_default: false,
      is_admin: false,
      is_active: false
    },
    validationSchema: RolesSchema,
    onSubmit: () => {
      // onChangeHandler();
      console.log('Add Role button has been clicked');
    }
  });

  const onChangeHandler = async () => {
    const token = localStorage.getItem('token').toString();
    const data = {
      name: formik.values.name,
      description: formik.values.description,
      is_default: formik.values.is_default,
      is_admin: formik.values.is_admin
    };
    console.log(data);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.post('secure/add_role', data, config)
      .then((res) => {
        console.log(res.data);
        setAlertContent(`Success : ${res.data.message}`);
        setAlert(true);
        setAlertType('success');
        // setUpdate(update + 1);
        delayMsg();
        handleDialog();
      })
      .then(() => {
        formik.resetForm();
      })
      .catch((err) => {
        console.log(err.response);
        setAlertContent(`Error : ${err.response.data.message}`);
        setAlert(true);
        setAlertType('error');
        // setUpdate(true);
      });
  };

  const handleAlert = () => {
    setAlert(false);
  };

  const handleDialog = () => {
    setOpen(!open);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - RolesData.length) : 0;

  // //const filteredUsers = applySortFilter(RolesData, getComparator(order, orderBy), filterName);

  // const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Roles">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Roles
          </Typography>
          <Button
            variant="contained"
            to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleDialog}
          >
            New Roles
          </Button>
        </Stack>
        <Container>
          <CustomFormModel
            fullScreen={fullScreen}
            handleDialog={handleDialog}
            formik={formik}
            open={open}
            title="Add new Roles"
          >
            <RolesForm props={formik} handleDialog={handleDialog} onSubmit={onChangeHandler} />
          </CustomFormModel>
        </Container>
        <RolesDataModel
          handleDialog={handleDialog}
          formik={formik}
          isUpdated={update}
          updateComponent={setUpdate}
          setAlert={setAlert}
          setAlertContent={setAlertContent}
          setAlertType={setAlertType}
          delayMsg={delayMsg}
        />
      </Container>
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}

const RolesDataModel = ({
  formik,
  setAlert,
  setAlertContent,
  setAlertType,
  isUpdated,
  updateComponent,
  delayMsg
}) => {
  const [roleData, setRoleData] = useState();
  const [roleSearchData, setroleSearchData] = useState();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [isRoleFound, setisRoleFound] = useState(false);
  // const isInitialMount = useRef(true);

  useEffect(() => {
    handleRoleGet();
  }, [isUpdated]);

  useEffect(() => {
    if (filterName) {
      handleRoleSearch(filterName);
    }
  }, [filterName]);

  const handleRoleGet = async () => {
    setLoading(true);
    const token = localStorage.getItem('token').toString();
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      await API.get('/secure/role_list?page=1&max_rows=10', config)
        .then((res) => {
          setRoleData(res.data);
          setAlertContent(`Success : Role list fetched successfully.`);
          setAlertType('success');
          setAlert(true);
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAlertContent(`Error : ${err}`);
          setAlertType('error');
          setAlert(true);
          setLoading(false);
        });
    } else {
      setAlertContent(`Error : Authentication Failed Due to Token Expired.`);
      setAlertType('error');
      setAlert(true);
      setLoading(false);
    }
  };

  const handleRoleSearch = async (filterName) => {
    setLoading(true);
    const token = localStorage.getItem('token');
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await API.get(`/secure/role_search?query=${filterName}`, config)
        .then((res) => {
          console.log('Search Data', res.data);
          sessionStorage.setItem('roleSearchData', JSON.stringify(res.data));
        })
        .then(() => {
          setroleSearchData(JSON.parse(sessionStorage.getItem('roleSearchData')));
          console.log('Data Stored');
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAlertContent(`Error : Authentication Failed Due to Token Expired.`);
      setAlertType('error');
      setAlert(true);
      setLoading(false);
    }
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = roleData.roles.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const clearFilterName = (event) => {
    setFilterName('');
    updateComponent(isUpdated + 1);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - roleData.roles.length) : 0;

  return (
    <Card>
      <UserListToolbar
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
        clearFilterName={clearFilterName}
        placeholder="Search Roles"
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={ROLES_TABLE_HEAD}
              rowCount={page}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />

            <TableBody>
              <TableRow
                hover
                // key={role_id}
                tabIndex={-1}
                role="checkbox"
                // selected={isItemSelected}
                // aria-checked={isItemSelected}
              >
                <TableCell component="th" scope="row" padding="normal">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography variant="subtitle2" noWrap>
                      Super Admin
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="left">Super Admin can access eveerything</TableCell>
                <TableCell align="left">Yes</TableCell>
                <TableCell align="left">Yes</TableCell>
                <TableCell align="left">
                  <Label variant="ghost" color="success">
                    Active
                  </Label>
                </TableCell>

                <TableCell align="right">
                  <UserMoreMenu />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        rowsPerPage={rowsPerPage}
        count={5}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

function UserMoreMenu({
  formik,
  currentId,
  currentName,
  currentDescription,
  currentIsDefault,
  currentIsadmin,
  setAlertContent,
  setAlertType,
  setAlert,
  isUpdated,
  updateComponent,
  filterName,
  handleRoleSearch,
  delayMsg
}) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(openEdit);

  const handleDialog = () => {
    setEditOpen(!openEdit);
  };

  const handleRoleDelete = async (id) => {
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.delete(`secure/deactivate_role?id=${id}`, config)
      .then((res) => {
        console.log(res.data);
        setAlertContent(`Roles Deleted Successfully.`);
        setAlert(true);
        setAlertType('success');
        sessionStorage.clear();
      })
      .then(() => {
        setIsOpen(false);
        sessionStorage.clear();
        if (filterName) {
          handleRoleSearch(filterName);
        } else {
          // updateComponent(isUpdated + 1);
          delayMsg();
        }
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`Error : ${err.response.data.statusText}`);
        setAlert(true);
        setAlertType('error');
        setIsOpen(false);
      });
  };
  return (
    <>
      <CustomFormModel
        fullScreen={fullScreen}
        handleDialog={handleDialog}
        open={openEdit}
        title="Update Roles"
      >
        <RoleEditForm
          formik={formik}
          isUpdated={isUpdated}
          updateComponent={updateComponent}
          handleDialog={handleDialog}
          Id={currentId}
          Name={currentName}
          Description={currentDescription}
          Isdefault={currentIsDefault}
          Isadmin={currentIsadmin}
          setAlert={setAlert}
          setAlertType={setAlertType}
          setAlertContent={setAlertContent}
          filterName={filterName}
          handleRoleSearch={handleRoleSearch}
          delayMsg={delayMsg}
        />
      </CustomFormModel>

      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem to="#" sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}

function RolesForm({ props, handleDialog, onSubmit }) {
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = props;

  return (
    <FormikProvider value={props}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Role name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Roles Description"
              {...getFieldProps('description')}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
          </Grid>

          <Grid item xs={6}>
            <Field type="checkbox" style={{ width: 40, height: 20 }} name="is_default" />
            Default &nbsp;
            <Field type="checkbox" style={{ width: 40, height: 20 }} name="is_admin" />
            Admin &nbsp;
            <Field type="checkbox" style={{ width: 40, height: 20 }} name="is_active" />
            Is Active &nbsp;
          </Grid>
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton variant="contained" onClick={handleDialog} style={{ marginRight: 5 }}>
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={isSubmitting}
            style={{ marginRight: 5 }}
          >
            Add
          </LoadingButton>
        </Container>
      </Form>
    </FormikProvider>
  );
}
