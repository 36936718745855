/* eslint-disable   no-nested-ternary */
import { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Dashboard from './pages/Dashboard';
import User from './pages/User';
import Organisation from './pages/Organisation';
import Roles from './pages/Roles';
import NotFound from './pages/Page404';
import UploadContent from './pages/UploadContent';
import Cockpit from './pages/Cockpit';
import ForgetPassword from './pages/ForgetPassword';
import SetPassword from './pages/SetPassword';
import Customer from './pages/Customer';
import Domain from './layouts/Domain/Domain';
import Disposition from './layouts/Disposition/Disposition';
import Action from './pages/Action';
import UploadKeywords from './pages/UploadKeywords';
import Training from './pages/Training';
import ComplianceDashboard from './pages/ComplianceDashboard';
import AgentPerformance from './pages/AgentPerformance';
import CallAnalysis from './pages/CallAnalysis';
import Collections from './pages/Collections';
import CollectionTracker from './pages/CollectionTracker';
import Usage from './pages/Usage';
import AgentReport from './pages/AgentWiseReport';
import DateReport from './pages/DateWiseReport';
import DaileyReport from './pages/DaileyReport';
import SalesAnalysis from './pages/SalesAnalysis';
import Feedbackform from './pages/FeedbackForm';
import SalesTracker from './pages/SalesTracker';
import Deal from './pages/Deal';
import Campaign from './pages/Campaign';
import Compliance from './pages/Compliance';
import Search from './pages/Search';
import AgentTask from './layouts/Deal/AgentTask';
import ComplianceTracker from './pages/ComplianceTracker';
import ComplianceForm from './layouts/Compliance/ComplianceForm';
import ComplianceUpdate from './layouts/Compliance/ComplianceUpdate';
import { ComplianceFeedback, ComplianceOpportunity } from './pages/ComplianceOpportunity';
import { RegisterForm } from './components/authentication/register';
import GoodCalls from './pages/GoodCalls';
import UBIDashboard from './components/UBI/UBIDashboard';
// ----------------------------------------------------------------------

export default function Router() {
  const user = JSON.parse(localStorage.getItem('user'));
  console.log('userrrrrrr', user);

  const SUPER_ADMIN_ROUTES = [
    {
      path: '/Login',
      element: <Login />
    },
    {
      path: '/ChangePassword',
      element: <ForgetPassword />
    },
    {
      path: '/register',
      element: <RegisterForm />
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app/compliance', element: <ComplianceDashboard /> },
        { path: 'app/compliance/track', element: <ComplianceTracker /> },
        { path: 'app/agent', element: <AgentPerformance /> },
        { path: 'app/training', element: <Training /> },
        { path: 'app/call', element: <CallAnalysis /> },
        { path: 'app/sales', element: <SalesAnalysis /> },
        { path: 'app/collections', element: <Collections /> },
        { path: 'app/sales/track', element: <SalesTracker /> },
        { path: 'app/collection/track', element: <CollectionTracker /> },
        {
          path: 'admin',
          children: [
            { path: '/dashboard/admin/organisation', element: <Organisation /> },
            { path: '/dashboard/admin/user', element: <User /> },
            { path: '/dashboard/admin/roles', element: <Roles /> },
            { path: '/dashboard/admin/customer', element: <Customer /> },
            process.env.REACT_APP_TO_SHOW_QRC === 'true' && {
              path: '/dashboard/admin/domain',
              element: <Domain />
            },
            { path: '/dashboard/admin/campaign', element: <Campaign /> },
            { path: '/dashboard/admin/usage', element: <Usage /> },
            { path: '/dashboard/admin/deal', element: <Deal /> },
            { path: '/dashboard/admin/task', element: <AgentTask /> },
            { path: '/dashboard/admin/feedback', element: <ComplianceFeedback /> }
          ]
        },
        {
          path: 'report',
          children: [
            { path: '/dashboard/report/agentwise', element: <AgentReport /> },
            { path: '/dashboard/report/datewise', element: <DateReport /> },
            { path: '/dashboard/report/dailyreport', element: <DaileyReport /> },
            { path: '/dashboard/report/scorereport', element: <UBIDashboard /> }
          ]
        },
        {
          path: 'feedback',
          children: [
            { path: '/dashboard/feedback/rating', element: <ComplianceFeedback /> },
            { path: '/dashboard/feedback/opportunity', element: <ComplianceOpportunity /> },
            { path: '/dashboard/feedback/training_calls', element: <GoodCalls /> }
          ]
        },
        {
          path: 'workspace',
          children: [
            { path: '/dashboard/workspace/upload', element: <UploadContent /> },
            { path: '/dashboard/workspace/Upload/Results', element: <Cockpit /> },
            { path: '/dashboard/workspace/Upload/Results/feedback', element: <Feedbackform /> },
            { path: '/dashboard/workspace/Upload/Action', element: <Action /> },
            { path: '/dashboard/workspace/keywords', element: <UploadKeywords /> },
            {
              path: '/dashboard/workspace/compliance',
              element: <Compliance />
            },
            { path: '/dashboard/workspace/compliance/add', element: <ComplianceForm /> },
            { path: '/dashboard/workspace/compliance/update', element: <ComplianceUpdate /> },
            process.env.REACT_APP_TO_SHOW_QRC === 'true' && {
              path: '/dashboard/workspace/disposition',
              element: <Disposition />
            }
            // { path: '/dashboard/workspace/search', element: <Search /> }
          ]
        },
        { path: '/dashboard/profile', element: <Profile /> },
        { path: '/dashboard/permission', element: <Disposition /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/Login" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ];

  const CUSTOMER_ADMIN_ROUTES = [
    {
      path: '/Login',
      element: <Login />
    },
    {
      path: '/ChangePassword',
      element: <ForgetPassword />
    },
    {
      path: '/register',
      element: <RegisterForm />
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app/call" replace /> },
        { path: 'app/compliance', element: <ComplianceDashboard /> },
        { path: 'app/compliance/track', element: <ComplianceTracker /> },
        { path: 'app/agent', element: <AgentPerformance /> },
        { path: 'app/training', element: <Training /> },
        { path: 'app/call', element: <CallAnalysis /> },
        { path: 'app/sales', element: <SalesAnalysis /> },
        { path: 'app/collections', element: <Collections /> },
        { path: 'app/sales/track', element: <SalesTracker /> },
        { path: 'app/collection/track', element: <CollectionTracker /> },
        {
          path: 'admin',
          children: [
            // { path: '/dashboard/admin/organisation', element: <Organisation /> },
            { path: '/dashboard/admin/user', element: <User /> },
            { path: '/dashboard/admin/roles', element: <Roles /> },
            { path: '/dashboard/admin/domain', element: <Domain /> },
            { path: '/dashboard/admin/customer', element: <Customer /> },
            { path: '/dashboard/admin/campaign', element: <Campaign /> },
            { path: '/dashboard/admin/usage', element: <Usage /> },
            { path: '/dashboard/admin/deal', element: <Deal /> },
            { path: '/dashboard/admin/task', element: <AgentTask /> },
            { path: '/dashboard/admin/feedback', element: <ComplianceFeedback /> }
          ]
        },
        {
          path: 'report',
          children: [
            { path: '/dashboard/report/agentwise', element: <AgentReport /> },
            { path: '/dashboard/report/datewise', element: <DateReport /> },
            { path: '/dashboard/report/dailyreport', element: <DaileyReport /> },
            { path: '/dashboard/report/scorereport', element: <UBIDashboard /> }
          ]
        },
        {
          path: 'feedback',
          children: [
            { path: '/dashboard/feedback/rating', element: <ComplianceFeedback /> },
            { path: '/dashboard/feedback/opportunity', element: <ComplianceOpportunity /> },
            { path: '/dashboard/feedback/training_calls', element: <GoodCalls /> }
          ]
        },
        {
          path: 'workspace',
          children: [
            { path: '/dashboard/workspace/upload', element: <UploadContent /> },
            { path: '/dashboard/workspace/Upload/Results', element: <Cockpit /> },
            { path: '/dashboard/workspace/Upload/Results/feedback', element: <Feedbackform /> },
            { path: '/dashboard/workspace/Upload/Action', element: <Action /> },
            { path: '/dashboard/workspace/keywords', element: <UploadKeywords /> },
            { path: '/dashboard/workspace/compliance/add', element: <ComplianceForm /> },
            { path: '/dashboard/workspace/compliance/update', element: <ComplianceUpdate /> },
            { path: '/dashboard/workspace/compliance', element: <Compliance /> },
            { path: '/dashboard/workspace/disposition', element: <Disposition /> }
          ]
        },
        { path: '/dashboard/profile', element: <Profile /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/Login" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ];

  const MANAGER_ROUTES = [
    {
      path: '/Login',
      element: <Login />
    },
    {
      path: '/ChangePassword',
      element: <ForgetPassword />
    },
    {
      path: '/register',
      element: <RegisterForm />
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app/call" replace /> },
        { path: 'app/compliance', element: <ComplianceDashboard /> },
        { path: 'app/compliance/track', element: <ComplianceTracker /> },
        { path: 'app/agent', element: <AgentPerformance /> },
        { path: 'app/training', element: <Training /> },
        { path: 'app/call', element: <CallAnalysis /> },
        { path: 'app/sales', element: <SalesAnalysis /> },
        { path: 'app/collections', element: <Collections /> },
        { path: 'app/sales/track', element: <SalesTracker /> },
        { path: 'app/collection/track', element: <CollectionTracker /> },
        {
          path: 'admin',
          children: [
            // { path: '/dashboard/admin/organisation', element: <Organisation /> },
            // { path: '/dashboard/admin/user', element: <User /> },
            // { path: '/dashboard/admin/roles', element: <Roles /> },
            // { path: '/dashboard/admin/customer', element: <Customer /> },
            // { path: '/dashboard/admin/campaign', element: <Campaign /> },
            // { path: '/dashboard/admin/usage', element: <Usage /> },
            { path: '/dashboard/admin/deal', element: <Deal /> },
            { path: '/dashboard/admin/task', element: <AgentTask /> }
            // { path: '/dashboard/admin/feedback', element: <ComplianceFeedback /> }
          ]
        },
        {
          path: 'report',
          children: [
            { path: '/dashboard/report/agentwise', element: <AgentReport /> },
            { path: '/dashboard/report/datewise', element: <DateReport /> },
            { path: '/dashboard/report/dailyreport', element: <DaileyReport /> },
            { path: '/dashboard/report/scorereport', element: <UBIDashboard /> }
          ]
        },
        {
          path: 'feedback',
          children: [
            { path: '/dashboard/feedback/rating', element: <ComplianceFeedback /> },
            { path: '/dashboard/feedback/opportunity', element: <ComplianceOpportunity /> },
            { path: '/dashboard/feedback/training_calls', element: <GoodCalls /> }
          ]
        },
        // {
        //   path: 'workspace',
        //   children: [
        //     { path: '/dashboard/workspace/upload', element: <UploadContent /> },
        { path: '/dashboard/workspace/Upload/Results', element: <Cockpit /> },
        { path: '/dashboard/workspace/Upload/Results/feedback', element: <Feedbackform /> },
        //     { path: '/dashboard/workspace/Upload/Action', element: <Action /> },
        //     { path: '/dashboard/workspace/keywords', element: <UploadKeywords /> },
        //     { path: '/dashboard/workspace/compliance', element: <Compliance /> }
        //   ]
        // },
        { path: '/dashboard/profile', element: <Profile /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/Login" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ];
  const QA_ROUTES = [
    {
      path: '/Login',
      element: <Login />
    },
    {
      path: '/ChangePassword',
      element: <ForgetPassword />
    },
    {
      path: '/register',
      element: <RegisterForm />
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app/call" replace /> },
        { path: 'app/compliance', element: <ComplianceDashboard /> },
        { path: 'app/compliance/track', element: <ComplianceTracker /> },
        { path: 'app/agent', element: <AgentPerformance /> },
        { path: 'app/training', element: <Training /> },
        { path: 'app/call', element: <CallAnalysis /> },
        { path: 'app/sales', element: <SalesAnalysis /> },
        { path: 'app/collections', element: <Collections /> },
        { path: 'app/sales/track', element: <SalesTracker /> },
        { path: 'app/collection/track', element: <CollectionTracker /> },
        {
          path: 'admin',
          children: [
            // { path: '/dashboard/admin/organisation', element: <Organisation /> },
            // { path: '/dashboard/admin/user', element: <User /> },
            // { path: '/dashboard/admin/roles', element: <Roles /> },
            // { path: '/dashboard/admin/customer', element: <Customer /> },
            { path: '/dashboard/admin/domain', element: <Domain /> },
            { path: '/dashboard/admin/campaign', element: <Campaign /> },
            // { path: '/dashboard/admin/usage', element: <Usage /> },
            { path: '/dashboard/admin/deal', element: <Deal /> },
            { path: '/dashboard/admin/task', element: <AgentTask /> },
            { path: '/dashboard/admin/feedback', element: <ComplianceFeedback /> }
          ]
        },
        {
          path: 'report',
          children: [
            { path: '/dashboard/report/agentwise', element: <AgentReport /> },
            { path: '/dashboard/report/datewise', element: <DateReport /> },
            { path: '/dashboard/report/dailyreport', element: <DaileyReport /> },
            { path: '/dashboard/report/scorereport', element: <UBIDashboard /> }
          ]
        },
        {
          path: 'feedback',
          children: [
            { path: '/dashboard/feedback/rating', element: <ComplianceFeedback /> },
            { path: '/dashboard/feedback/opportunity', element: <ComplianceOpportunity /> },
            { path: '/dashboard/feedback/training_calls', element: <GoodCalls /> }
          ]
        },
        {
          path: 'workspace',
          children: [
            { path: '/dashboard/workspace/upload', element: <UploadContent /> },
            { path: '/dashboard/workspace/Upload/Results', element: <Cockpit /> },
            { path: '/dashboard/workspace/Upload/Results/feedback', element: <Feedbackform /> },
            { path: '/dashboard/workspace/Upload/Action', element: <Action /> },
            { path: '/dashboard/workspace/keywords', element: <UploadKeywords /> },
            { path: '/dashboard/workspace/compliance/add', element: <ComplianceForm /> },
            { path: '/dashboard/workspace/compliance/update', element: <ComplianceUpdate /> },
            { path: '/dashboard/workspace/compliance', element: <Compliance /> }
          ]
        },
        { path: '/dashboard/profile', element: <Profile /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/Login" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ];
  const QA_ADMIN_ROUTES = [
    {
      path: '/Login',
      element: <Login />
    },
    {
      path: '/ChangePassword',
      element: <ForgetPassword />
    },
    {
      path: '/register',
      element: <RegisterForm />
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app/call" replace /> },
        { path: 'app/compliance', element: <ComplianceDashboard /> },
        { path: 'app/compliance/track', element: <ComplianceTracker /> },
        { path: 'app/agent', element: <AgentPerformance /> },
        { path: 'app/training', element: <Training /> },
        { path: 'app/call', element: <CallAnalysis /> },
        { path: 'app/sales', element: <SalesAnalysis /> },
        { path: 'app/collections', element: <Collections /> },
        { path: 'app/sales/track', element: <SalesTracker /> },
        { path: 'app/collection/track', element: <CollectionTracker /> },
        {
          path: 'admin',
          children: [
            // { path: '/dashboard/admin/organisation', element: <Organisation /> },
            // { path: '/dashboard/admin/user', element: <User /> },
            // { path: '/dashboard/admin/roles', element: <Roles /> },
            // { path: '/dashboard/admin/customer', element: <Customer /> },
            { path: '/dashboard/admin/domain', element: <Domain /> },
            { path: '/dashboard/admin/campaign', element: <Campaign /> },
            // { path: '/dashboard/admin/usage', element: <Usage /> },
            { path: '/dashboard/admin/deal', element: <Deal /> },
            { path: '/dashboard/admin/task', element: <AgentTask /> },
            { path: '/dashboard/admin/feedback', element: <ComplianceFeedback /> }
          ]
        },
        {
          path: 'report',
          children: [
            { path: '/dashboard/report/agentwise', element: <AgentReport /> },
            { path: '/dashboard/report/datewise', element: <DateReport /> },
            { path: '/dashboard/report/dailyreport', element: <DaileyReport /> },
            { path: '/dashboard/report/scorereport', element: <UBIDashboard /> }
          ]
        },
        {
          path: 'feedback',
          children: [
            { path: '/dashboard/feedback/rating', element: <ComplianceFeedback /> },
            { path: '/dashboard/feedback/opportunity', element: <ComplianceOpportunity /> },
            { path: '/dashboard/feedback/training_calls', element: <GoodCalls /> }
          ]
        },
        {
          path: 'workspace',
          children: [
            { path: '/dashboard/workspace/upload', element: <UploadContent /> },
            { path: '/dashboard/workspace/Upload/Results', element: <Cockpit /> },
            { path: '/dashboard/workspace/Upload/Results/feedback', element: <Feedbackform /> },
            { path: '/dashboard/workspace/Upload/Action', element: <Action /> },
            { path: '/dashboard/workspace/keywords', element: <UploadKeywords /> },
            { path: '/dashboard/workspace/compliance/add', element: <ComplianceForm /> },
            { path: '/dashboard/workspace/compliance/update', element: <ComplianceUpdate /> },
            { path: '/dashboard/workspace/compliance', element: <Compliance /> }
          ]
        },
        { path: '/dashboard/profile', element: <Profile /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/Login" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ];
  const AGENT_ROUTES = [
    {
      path: '/Login',
      element: <Login />
    },
    {
      path: '/ChangePassword',
      element: <ForgetPassword />
    },
    {
      path: '/register',
      element: <RegisterForm />
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/dashboard/sales" replace /> },
        // { path: 'app', element: <ComplianceDashboard /> },
        // { path: 'app/track', element: <ComplianceTracker /> },
        // { path: 'agent', element: <AgentPerformance /> },
        // { path: 'training', element: <Training /> },
        // { path: 'call', element: <CallAnalysis /> },
        // { path: 'sales', element: <SalesAnalysis /> },
        // { path: 'collections', element: <Collections /> },
        // { path: 'sales/track', element: <SalesTracker /> },
        // { path: 'collection/track', element: <CollectionTracker /> },
        {
          path: 'admin',
          children: [
            // { path: '/dashboard/admin/organisation', element: <Organisation /> },
            // { path: '/dashboard/admin/user', element: <User /> },
            // { path: '/dashboard/admin/roles', element: <Roles /> },
            // { path: '/dashboard/admin/customer', element: <Customer /> },
            // { path: '/dashboard/admin/campaign', element: <Campaign /> },
            // { path: '/dashboard/admin/usage', element: <Usage /> },
            { path: '/dashboard/admin/deal', element: <Deal /> },
            { path: '/dashboard/admin/task', element: <AgentTask /> },
            { path: '/dashboard/admin/feedback', element: <ComplianceFeedback /> }
          ]
        },
        {
          path: 'feedback',
          children: [
            { path: '/dashboard/feedback/rating', element: <ComplianceFeedback /> },
            { path: '/dashboard/feedback/opportunity', element: <ComplianceOpportunity /> },
            { path: '/dashboard/feedback/training_calls', element: <GoodCalls /> }
          ]
        },
        {
          path: 'workspace',
          children: [
            { path: '/dashboard/workspace/upload', element: <UploadContent /> },
            { path: '/dashboard/workspace/Upload/Results', element: <Cockpit /> }
            // { path: '/dashboard/workspace/Upload/Results/feedback', element: <Feedbackform /> },
            // { path: '/dashboard/workspace/Upload/Action', element: <Action /> },
            // { path: '/dashboard/workspace/keywords', element: <UploadKeywords /> },
            // { path: '/dashboard/workspace/compliance', element: <Compliance /> }
          ]
        },
        { path: '/dashboard/profile', element: <Profile /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/Login" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ];
  const GUEST_ROUTES = [
    {
      path: '/Login',
      element: <Login />
    },
    {
      path: '/ChangePassword',
      element: <ForgetPassword />
    },
    {
      path: '/register',
      element: <RegisterForm />
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/dashboard/sales" replace /> },
        // { path: 'app', element: <ComplianceDashboard /> },
        // { path: 'app/track', element: <ComplianceTracker /> },
        // { path: 'agent', element: <AgentPerformance /> },
        // { path: 'training', element: <Training /> },
        // { path: 'call', element: <CallAnalysis /> },
        // { path: 'sales', element: <SalesAnalysis /> },
        // { path: 'collections', element: <Collections /> },
        // { path: 'sales/track', element: <SalesTracker /> },
        // { path: 'collection/track', element: <CollectionTracker /> },
        // {
        //   path: 'admin',
        //   children: [
        //     // { path: '/dashboard/admin/organisation', element: <Organisation /> },
        //     // { path: '/dashboard/admin/user', element: <User /> },
        //     // { path: '/dashboard/admin/roles', element: <Roles /> },
        //     // { path: '/dashboard/admin/customer', element: <Customer /> },
        //     // { path: '/dashboard/admin/campaign', element: <Campaign /> },
        //     // { path: '/dashboard/admin/usage', element: <Usage /> },
        //     { path: '/dashboard/admin/deal', element: <Deal /> },
        //     { path: '/dashboard/admin/task', element: <AgentTask /> },
        //     { path: '/dashboard/admin/feedback', element: <ComplianceFeedback /> }
        //   ]
        // },
        // {
        //   path: 'feedback',
        //   children: [
        //     { path: '/dashboard/feedback/rating', element: <ComplianceFeedback /> },
        //     { path: '/dashboard/feedback/opportunity', element: <ComplianceOpportunity /> }
        //   ]
        // },
        {
          path: 'workspace',
          children: [
            { path: '/dashboard/workspace/upload', element: <UploadContent /> },
            { path: '/dashboard/workspace/Upload/Results', element: <Cockpit /> }
            // { path: '/dashboard/workspace/Upload/Results/feedback', element: <Feedbackform /> },
            // { path: '/dashboard/workspace/Upload/Action', element: <Action /> },
            // { path: '/dashboard/workspace/keywords', element: <UploadKeywords /> },
            // { path: '/dashboard/workspace/compliance', element: <Compliance /> }
          ]
        },
        { path: '/dashboard/profile', element: <Profile /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/Login" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ];

  const UNAUTH_USER_ROUTES = [
    {
      path: '/Login',
      element: <Login />
    },
    {
      path: '/ChangePassword',
      element: <ForgetPassword />
    },
    {
      path: '/register',
      element: <RegisterForm />
    },
    {
      path: '/setPassword/:token',
      element: <SetPassword />
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/Login" /> },
        { path: '/register', element: <Navigate to="/register" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ];

  return useRoutes(
    user
      ? user.role === 'Super_Admin'
        ? SUPER_ADMIN_ROUTES
        : user.role === 'Customer_Admin'
        ? CUSTOMER_ADMIN_ROUTES
        : user.role === 'Manager'
        ? MANAGER_ROUTES
        : user.role === 'QA'
        ? QA_ROUTES
        : user.role === 'QA_admin'
        ? QA_ADMIN_ROUTES
        : user.role === 'Agent'
        ? AGENT_ROUTES
        : user.role === 'Guest'
        ? GUEST_ROUTES
        : UNAUTH_USER_ROUTES
      : UNAUTH_USER_ROUTES
  );
}
