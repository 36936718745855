import React from 'react';
import InputContex from './inputContext';

const InputState = (props) => {
  const [inputId, setinputId] = React.useState();
  const [inputOrgId, setinputOrgId] = React.useState();
  const [requestId, setrequestId] = React.useState();
  const [uploadStatus, setuploadStatus] = React.useState();
  const [uploadedAudio, setUploadedAudio] = React.useState();
  const [userData, setuserDetails] = React.useState();
  const [fileProcess, setProcess] = React.useState();
  const [customerId, setcustomerId] = React.useState();
  const [audioS3, setAudioS3] = React.useState();
  const [originalDate, setOriginalDate] = React.useState();
  const [services, setServices] = React.useState();
  const [toneDate, setToneDate] = React.useState();
  const [agentId, setAgentId] = React.useState();
  const [agentName, setAgentName] = React.useState();
  const [agentLastName, setAgentLastName] = React.useState();
  const [dataIndex, setDataIndex] = React.useState(null);
  const [toneIndex, setToneIndex] = React.useState({
    data: null,
    isAgent: false,
    isCustomer: false
  });
  const [customerSentimentIndex, setCustomerSentimentIndex] = React.useState(null);
  const [agentSentimentIndex, setAgentSentimentIndex] = React.useState(null);
  const [kpi, setKpi] = React.useState();
  const [globalToneValue, setGlobalToneValue] = React.useState();
  const [globalDate, setGlobalDate] = React.useState();
  const [globalBarValue, setGlobalBarValue] = React.useState();
  const [updateGlobal, setUpdateGlobal] = React.useState(0);
  const [pieUpdateGlobal, setPieUpdateGlobal] = React.useState(0);

  return (
    <InputContex.Provider
      value={{
        inputId,
        setinputId,
        inputOrgId,
        setinputOrgId,
        requestId,
        setrequestId,
        uploadedAudio,
        setUploadedAudio,
        uploadStatus,
        setuploadStatus,
        userData,
        setuserDetails,
        fileProcess,
        setProcess,
        customerId,
        setcustomerId,
        audioS3,
        setAudioS3,
        originalDate,
        setOriginalDate,
        toneDate,
        setToneDate,
        dataIndex,
        setDataIndex,
        toneIndex,
        setToneIndex,
        customerSentimentIndex,
        setCustomerSentimentIndex,
        agentSentimentIndex,
        setAgentSentimentIndex,
        agentId,
        setAgentId,
        agentName,
        setAgentName,
        agentLastName,
        setAgentLastName,
        kpi,
        setKpi,
        globalToneValue,
        setGlobalToneValue,
        globalDate,
        setGlobalDate,
        globalBarValue,
        setGlobalBarValue,
        updateGlobal,
        setUpdateGlobal,
        pieUpdateGlobal,
        setPieUpdateGlobal
      }}
    >
      {props.children}
    </InputContex.Provider>
  );
};

export default InputState;
