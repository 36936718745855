/* eslint-disable   no-nested-ternary */
/* eslint-disable   arrow-body-style */
/* eslint-disable   no-unneeded-ternary */
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable no-useless-escape */
/* eslint-disable object-shorthand */
import * as Yup from 'yup';
import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik, FormikProvider, Form } from 'formik';
import moment from 'moment';
// import Select from '@mui/material/Select';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PortraitIcon from '@mui/icons-material/Portrait';
import LoopIcon from '@mui/icons-material/Loop';
import eyeIcon from '@iconify/icons-mdi/eye';
import Select, { components } from 'react-select';
import {
  Button,
  Container,
  Stack,
  Typography,
  Grid,
  Card,
  CardHeader,
  Box,
  TextField,
  Select as Selectbox,
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  Alert,
  TableRow,
  TableHead,
  TableBody,
  Table,
  TableContainer,
  TablePagination,
  CircularProgress,
  TableCell,
  Badge,
  Chip,
  Link,
  Tooltip as Tip,
  Hidden,
  Backdrop,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Menu,
  Pagination
} from '@mui/material';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  BarChart,
  Bar,
  Cell,
  PieChart,
  Pie,
  Label
} from 'recharts';
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Title as ExtremeTitle,
  Label as ExtremeLabel,
  Tooltip as ExtremeTooltip,
  Format,
  Legend as ExtremeLegend,
  Export,
  ValueAxis,
  TickInterval,
  ArgumentAxis,
  ScrollBar,
  ZoomAndPan
} from 'devextreme-react/chart';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { alpha, styled, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { LoadingButton } from '@mui/lab';
// Components
import CallIcon from '@mui/icons-material/Call';
import DatePicker from 'react-datepicker';
import './CSS/CallAnalysis.css';
import './CSS/RsuiteTabs.css';
import 'react-datepicker/dist/react-datepicker.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Play from '@mui/icons-material/PlayCircle';
import { Icon } from '@iconify/react';
import download from '@iconify/icons-eva/download-fill';
import FileSaver, { saveAs } from 'file-saver';
import { Tabs, Placeholder } from 'rsuite';
import SentimentGraph from '../components/charts/SentimentGraph';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
import Page from '../components/Page';
import API from '../E2E/axios.util';
import { Notification } from '../components/Notification/Notification';
import { AppConversionRates } from '../components/_dashboard/app';
import Linegraph from '../components/_dashboard/CallAnalysis/Linegraph';
// import Waterfall from '../components/charts/Waterfall';
import inputContext from '../Context/UploadContext/inputContext';
import { CustomFormModel } from '../components/custom-form-model/CustomFormModel';
import StackBar from '../components/charts/StackBar';
import StackBarForCategory from '../components/charts/StackBarForCategory';
import AppWidgetSummary from '../components/charts/AppWidgetSummary';
import ComparativeAnalysis from '../components/ComparativeAnalysis';
import { handleDownload } from '../utils/downloadFeature';
import BarChartUBI from '../components/charts/BarChartUBI';
import TableComponent from '../components/TableComponent/TableComponent';
import CollectionBarChart from '../components/charts/CollectionChart';

// import Backdrop from 'src/theme/overrides/Backdrop';

const RootStyle = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(3, 0),
  borderRadius: 'none'
}));

export default function CallAnalysis({ props }) {
  const { globalToneValue, globalDate, globalBarValue, updateGlobal, pieUpdateGlobal } =
    useContext(inputContext);
  const navigate = useNavigate();
  const locationData = useLocation();
  // console.log('locationData', locationData);
  const percentage = 79;
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  // creating function for fetching default dashboard api
  const weekBeforeDate = new Date(Date.now() - 604800000);
  const presentDate = new Date(Date.now());
  // const [setWeekBeforeDate, setNewWeekBeforeDate] = useState('2023-12-01');
  const [setWeekBeforeDate, setNewWeekBeforeDate] = useState(
    `${weekBeforeDate.getFullYear()}-${weekBeforeDate.getMonth() + 1}-${weekBeforeDate.getDate()}`
  );
  // const [setPresentDate, setNewPresentDate] = useState('2024-02-02');
  const [setPresentDate, setNewPresentDate] = useState(
    `${presentDate.getFullYear()}-${presentDate.getMonth() + 1}-${presentDate.getDate()}`
  );
  const [checkInDate, setCheckInDate] = useState(
    locationData.state?.initialData
      ? new Date(locationData.state.initialData.formikValues.startDate)
      : weekBeforeDate
  );
  const [checkOutDate, setCheckOutDate] = useState(
    locationData.state?.initialData
      ? new Date(locationData.state.initialData.formikValues.endDate)
      : presentDate
  );

  // Calculate 3 months from the check-in date
  const maxDateFromCheckIn = new Date(checkInDate).setMonth(new Date(checkInDate).getMonth() + 3);

  // Determine the final maxDate
  const maxDate = maxDateFromCheckIn < new Date() ? maxDateFromCheckIn : new Date();

  const dateFormat = [
    { name: 'One Week', id: 'one_week' },
    { name: 'One Month', id: 'one_month' },
    // { name: 'Six Month', id: 'six_month' },
    // { name: 'One Year', id: 'one_year' },
    { name: 'Customize', id: 'customized' }
  ];

  const [defaultDate, setDefaultDate] = useState(
    locationData &&
      locationData.state &&
      locationData.state.initialData.navigateDefaultDate !== null
      ? locationData.state.initialData.navigateDefaultDate
      : { value: 'one_week', label: 'One Week' }
  );
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [OrganisationList, setOrganisationList] = useState();
  const [customerList, setcustomerList] = useState();
  const [agentList, setagentList] = useState();
  const [processList, setprocessList] = useState();

  const [dispositionList, setDispositionList] = useState([]);

  const [toggleValue, setToggleValue] = useState(
    locationData.state?.initialData
      ? locationData.state.initialData.navigateToggleValue
      : 'customer'
  );

  const [toneData, setToneData] = useState(
    locationData.state?.initialData && locationData.state.initialData.navigateTone
  );

  const [escalationNavLocation, setEscalationNavLocation] = useState(
    locationData.state?.initialData && locationData.state.initialData.navigateEscalationNavLocation
  );
  const [sentimentData, setSentimentData] = useState(
    locationData.state?.initialData && locationData.state.initialData.navigateSentiment
  );
  const [categoryData, setCategoryData] = useState(
    locationData.state?.initialData && locationData.state.initialData.navigateCategoryData
  );
  const [collectionData, setCollectionData] = useState(
    locationData.state?.initialData && locationData.state.initialData.collectionData
  );
  const [QRCData, setQRCData] = useState(
    locationData.state?.initialData ? locationData.state.initialData.navigateQRCData : []
  );

  const [newSummaryDispositionGraph, setNewSummaryDispositionGraph] = useState([]);

  const [trendDispositionGraph, setTrendDispositionGraphData] = useState([]);

  const [allCallsList, setAllCallsList] = useState();

  const [OverallQRCData, setOverallQRCData] = useState(
    locationData.state?.initialData ? locationData.state.initialData.navigateOverallQRCData : {}
  );
  const [QRCChartData, setQRCChartData] = useState(
    locationData.state?.initialData ? locationData.state.initialData.QRCChartData : []
  );
  const [QRCTableData, setQRCTableData] = useState(
    locationData.state?.initialData ? locationData.state.initialData.QRCTableData : []
  );

  const [unpleasantData, setunpleasantData] = useState(
    locationData.state?.initialData && locationData.state.initialData.navigateUnpleasant
  );
  const [deadAir, setdeadAir] = useState(
    locationData.state?.initialData && locationData.state.initialData.navigateDeadAir
  );

  const [options, setoptions] = useState([]);
  const [label, setLabel] = useState([]);
  const [selected, setSelected] = useState([]);
  const [date, setDate] = useState();
  const [deadAirDate, setDeadAirDate] = useState();
  const [loading, setLoading] = useState();
  const [chartTypeForCategory, setChartTypeForCategory] = useState('summary');
  const [chartTypeForQRC, setChartTypeForQRC] = useState('summary');
  const [filterForDispositionChartType, setFilterForDispositionChartType] = useState('Summary');
  const [dispositionType, setDispositionType] = useState(
    locationData.state?.initialData ? locationData.state.initialData.navigateDispositionType : 'All'
  );
  const [level1Intent, setIntentType] = useState(
    locationData.state?.initialData ? locationData.state.initialData.intentType : []
  );

  const [customerLevel, setCustomerLevel] = useState(
    locationData.state?.initialData ? locationData.state.initialData.customerLevel : 'level_1'
  );

  console.log('customerLevel', customerLevel);

  const [filterForQRC, setFilterForQRC] = useState('All');

  const [shownQRCDetails, setShownQRCDetails] = useState('');
  const [QRCList, setQRCList] = useState([]);
  const [keywordsGraphData, setKeywordsGraphData] = useState();
  const [collectionPopupData, setCollectionPopupData] = useState();
  const [cardData, setCardData] = useState(
    locationData.state?.initialData && locationData.state.initialData.navigateCards
  );
  const [analysisData, setAnalysisData] = useState(
    locationData.state?.initialData && locationData.state.initialData.analysisData
  );
  // console.log('cardData', cardData);

  // Creating state to store the headerName
  const [headerName, setHeaderName] = useState();
  // console.log('date ----', date);

  const { toneDate, setToneDate, dataIndex, setDataIndex } = useContext(inputContext);

  const [graphDataForTone, setgraphDataForTone] = useState();
  const [graphDataForSentiment, setgraphDataForSentiment] = useState();
  const [graphDataForUnpleasant, setgraphDataForUnpleasant] = useState();
  const [graphDataForDeadAir, setgraphDataForDeadAir] = useState();
  const [initialData, setInitialData] = useState({ label: '' });
  // console.log('initialData----', initialData);

  // Creating state for Popup data
  const [newUnpleasantData, setNewUnpleasantData] = useState(
    locationData.state?.initialData && locationData.state.initialData.navigateUnpleasantPop
  );
  const [newDeadAirData, setNewDeadAirData] = useState(
    locationData.state?.initialData && locationData.state.initialData.navigateDeadAirPop
  );
  const [newToneData, setNewToneData] = useState(
    locationData.state?.initialData && locationData.state.initialData.navigateTonePop
  );
  const [newSentimentData, setNewSentimentData] = useState(
    locationData.state?.initialData && locationData.state.initialData.navigateSentimentPop
  );
  const [newCategoryData, setNewCategoryData] = useState(
    locationData.state?.initialData ? locationData.state.initialData.navigateSentimentPop : []
  );
  const [showDownloadButton, setShowDownloadButton] = useState(
    locationData.state?.initialData ? locationData.state.initialData.showDownloadButton : false
  );
  const [shownCategory, setShownCategory] = useState();
  // console.log('newCategoryData', newCategoryData);

  // Creating state for defaultDashboard
  const [defaultDashboardData, setDefaultDashboardData] = useState();
  const [newDefaultDashboardData, setNewDefaultDashboardData] = useState();
  const [defaultCategoryData, setDefaultCategoryData] = useState();
  const [tabValue, setTabValue] = useState('1');
  // const [defaultCardData, setDefaultCardData] = useState();
  // console.log('defaultDashboardData----------', defaultDashboardData);

  // Creating state to store filtered data.
  const [filteredUnpleasant, setFilteredUnpleasant] = useState();
  // console.log('filteredUnpleasant ---', filteredUnpleasant);
  useEffect(() => {
    // if (date && newUnpleasantData) {
    //   // console.log('dateUnpleasant ------', date);
    //   const formatDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    //   // console.log('formatDate', formatDate);
    //   const arr = Object.keys(newUnpleasantData);
    //   // console.log('unpleasantArr', arr);
    //   const data = arr.find((element) => element === formatDate);
    //   // console.log('date --', data);
    //   console.log('newUnpleasantData', newUnpleasantData);
    //   // console.log('newUnpleasantData[data] ----', newUnpleasantData[data]);
    //   if (newUnpleasantData[data]?.total !== 0) {
    //     // console.log('render unpleasant ');
    //     // console.log('render unpleasant ', newUnpleasantData[data]);
    //     setFilteredUnpleasant(newUnpleasantData[data]);
    //     handleDialog();
    //     setLoading(false);
    //   }
    // } else
    if (date && newDefaultDashboardData?.unpleasant_graph) {
      // console.log('dateUnpleasant ------', date);
      const formatDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
      // console.log('newDefaultDashboardData', newDefaultDashboardData.unpleasant_graph);
      // console.log({ formatDate });
      // const arr = newDefaultDashboardData.unpleasant_graph[`${formatDate}`];
      // console.log('unpleasantArr', arr);
      // const data = arr.find((element) => element === formatDate);
      // console.log('date --', data);
      // console.log('newUnpleasantData', newUnpleasantData);
      // console.log('newUnpleasantData[data] ----', newUnpleasantData[data]);
      if (newDefaultDashboardData.unpleasant_graph[formatDate]?.total !== 0) {
        // console.log('render unpleasant ');
        // console.log('render unpleasant ', newUnpleasantData[data]);
        setFilteredUnpleasant(newDefaultDashboardData.unpleasant_graph[formatDate]);
        handleDialog();
        setLoading(false);
      }
    }
  }, [newUnpleasantData, newDefaultDashboardData]);

  // Creating state to store filtered tone data.
  const [filteredTone, setFilteredTone] = useState();
  const [toneLabel, setToneLabel] = useState('');
  const [sentimentLabel, setSentimentLabel] = useState('');
  const [downloadPath, setDownloadPath] = useState();
  useEffect(() => {
    if (toneDate && newToneData) {
      // console.log('toneDate ---', toneDate);
      // console.log('typeOf-----', typeof toneDate);
      // console.log('newToneData ---', newToneData);
      const formatDate = toneDate;
      // console.log('formatdate', formatDate);
      const arr = Object.keys(newToneData);
      // console.log('second----', arr);
      const data = arr?.find((element) => element === formatDate);
      // console.log('first-----', data);
      if (newToneData.length > 0) {
        setFilteredTone(newToneData);
        handleDialog();
        setLoading(false);
        // if (newToneData[data].filter((el) => el.agent_tone.high / 1000 > 15).length > 0) {
      } else {
        setAlertContent(`No Calls Found`);
        setAlertType('error');
        setAlert(true);
        setLoading(false);
      }

      // console.log('filteredData --', newToneData[data]);
    } else if (toneDate && newDefaultDashboardData) {
      // console.log('toneDate ---', toneDate);
      // console.log('typeOf-----', typeof toneDate);
      // console.log('newToneData ---', newToneData);
      const formatDate = toneDate;
      // console.log('formatdate', formatDate);
      const arr = Object.keys(newDefaultDashboardData.tone_graph);
      // console.log('second----', arr);
      const data = arr?.find((element) => element === formatDate);
      // console.log('first-----', data);
      if (newDefaultDashboardData.tone_graph[data]?.total !== 0) {
        setFilteredTone(newDefaultDashboardData.tone_graph[data]);
        if (
          // newDefaultDashboardData.tone_graph[data].filter((el) => el.agent_tone.high / 1000 > 15)
          newDefaultDashboardData.tone_graph[data].length > 0
        ) {
          handleDialog();
          setLoading(false);
        } else {
          setAlertContent(`No Calls Found`);
          setAlertType('error');
          setAlert(true);
          setLoading(false);
        }
      }

      // console.log('filteredData --', newToneData[data]);
    }
  }, [newToneData, newDefaultDashboardData]);
  // creating state and function for tablePagination
  const [pageForPopup, setPageForPopup] = useState(0);
  const [rowsPerPageForPopup, setRowsPerPageForPopup] = useState(10);

  const handleChangePageForPopup = (event, newPage) => {
    setPageForPopup(newPage);
  };
  const handleChangePagePopup = (event, newPage) => {
    setPagePopup(newPage);
  };
  const handleChangePageCollection = (event, newPage) => {
    setPagePopupForCollection(newPage);
  };

  const handleChangeRowsPerPageForPopup = (event) => {
    // console.log('rowschangingvalue', typeof event.target.value);
    setRowsPerPageForPopup(event.target.value);
    setPageForPopup(0);
  };

  // creating state to store sentiment
  const [filteredSentiment, setFilteredSentiment] = useState();
  // console.log('filteredpopupsent', filteredSentiment);
  const [sentDate, setSentDate] = useState();
  // console.log('checkdatesent', sentDate);
  // useEffect(() => {
  //   if (typeof dataIndex === 'number' && dataIndex >= 0 && newSentimentData) {
  //     // console.log('dataIndex in filter -', dataIndex);
  //     const sentimentDate = Object.keys(newSentimentData)?.filter(
  //       (date) => sentimentData[date]?.TOTAL !== 0
  //     );
  //     // console.log('6 --', sentimentDate[6]);
  //     // console.log('sentiments date -', sentimentDate[dataIndex]);
  //     setSentDate(moment(sentimentDate[dataIndex], 'YYYY-M-D').format('DD/MM/YYYY'));
  //     // const data = sentimentDate.find((element, index) => index === dataIndex && index);
  //     console.log('sentimentDate-------', sentimentDate[dataIndex]);
  //     // console.log('dataindex----', sentimentDate[dataIndex]);
  //     const sentimentArr = newSentimentData[sentimentDate[dataIndex]];
  //     // console.log('sentimentArr ---', sentimentArr);
  //     // console.log(
  //     //   'newSentimentData[sentimentDate[dataIndex]]?.total',
  //     //   newSentimentData[sentimentDate[dataIndex]]
  //     // );

  //     // if (newSentimentData[sentimentDate[dataIndex]]?.total !== 0) {
  //     setFilteredSentiment(sentimentArr);
  //     // }
  //   } else if (typeof dataIndex === 'number' && dataIndex >= 0 && newDefaultDashboardData) {
  //     console.log('dataIndex in filter -', newDefaultDashboardData.sentiment_graph);
  //     const sentimentDate = Object.keys(newDefaultDashboardData.sentiment_graph)?.filter(
  //       (date) => defaultDashboardData.sentiment_graph[date]?.TOTAL !== 0
  //     );
  //     console.log('6 --', dataIndex);

  //     setSentDate(moment(sentimentDate[dataIndex], 'YYYY-M-D').format('DD/MM/YYYY'));
  //     // const data = sentimentDate.find((element, index) => index === dataIndex && index);
  //     // console.log('sentimentDate-------', data);
  //     // console.log('dataindex----', sentimentDate[dataIndex]);
  //     const sentimentArr = newDefaultDashboardData.sentiment_graph[sentimentDate[dataIndex]];
  //     // console.log('sentimentArr ---', sentimentArr);
  //     // console.log(
  //     //   'newSentimentData[sentimentDate[dataIndex]]?.total',
  //     //   newSentimentData[sentimentDate[dataIndex]]
  //     // );

  //     // if (newSentimentData[sentimentDate[dataIndex]]?.total !== 0) {
  //     setFilteredSentiment(sentimentArr);
  //     // }
  //   }
  // }, [dataIndex]);

  // Creating state to store filtered tone data.
  const [filteredDeadAir, setFilteredDeadAir] = useState();
  useEffect(() => {
    if (deadAirDate && newDeadAirData) {
      // console.log('inside filtered dead air function.');
      // console.log('deadAirDate ---', deadAirDate);
      // console.log('newDeadAirData ---', newDeadAirData);
      const formatDate = moment(deadAirDate, 'DD/MM/YYYY').format('YYYY-M-D');
      const arr = Object.keys(newDeadAirData);
      const data = arr.find((element) => element === formatDate);
      if (newDeadAirData[data]?.total !== 0) {
        setFilteredDeadAir(newDeadAirData[data]);
      }
      // console.log('filteredData --', newDeadAirData[data]);
    }
  }, [deadAirDate]);

  const BarTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload.dates;

      // console.log('presentfilterUnpleasant', data);
      setDate(data);
      setToneDate(null);
      setDataIndex(null);
      setDeadAirDate(null);

      const words = Object.keys(payload[0].payload.data);
      return (
        <Card className="custom-tooltip" sx={{ padding: '10px' }}>
          <p className="intro">{data === 'total' ? '' : `Date:${data}`}</p>
          {words.map((element) => {
            if (payload[0].payload.data[element] !== 0) {
              return <p className="intro">{`${element} : ${payload[0].payload.data[element]}`}</p>;
            }
            return null;
          })}
        </Card>
      );
    }
    return null;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      // const data = payload[0].payload;
      // console.log('payload.data', data);
      const { date } = payload[0].payload;
      const data = payload[0].payload;
      // console.log('tooltipdata', data);
      return (
        <Card className="custom-tooltip" sx={{ padding: '10px' }}>
          {date && <Typography className="intro">{`Date : ${date} `}</Typography>}
          {Object.keys(data)
            .filter((element) => element !== 'date')
            .map((el) => (
              <Typography className="intro">{`${el} : ${data[el]} calls`}</Typography>
            ))}
          {/* <p style={{ color: '#0088FE' }} className="intro">{`Query : ${data[0].Query} calls `}</p>
          <p
            style={{ color: '#00C49F' }}
            className="intro"
          >{`Request : ${data[0].Request} calls`}</p>
          <p
            style={{ color: '#FF3F3F' }}
            className="intro"
          >{`Complaint : ${data[0].Complaint} calls`}</p> */}
        </Card>
      );
    }
    return null;
  };

  const CustomLegendForKeyword = (e) => (
    <div
      style={{
        fontFamily: 'arial',
        fontWeight: 'bold',
        fontSize: 12,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      {e.payload.map((entry, index) => (
        <li key={index} style={{ listStyleType: 'none' }}>
          <span
            className="legend-icon"
            style={{
              width: '10px', // Adjust the size as needed
              height: '10px', // Adjust the size as needed
              borderRadius: '50%',
              display: 'inline-block',
              marginRight: '5px', // Adjust spacing
              backgroundColor: entry.color
            }}
          />
          <span
            style={{
              fontFamily: 'arial',
              fontWeight: 'bold',
              fontSize: 12
            }}
          >
            Keyword
          </span>
        </li>
      ))}
    </div>
  );
  const CustomTooltipForKeywords = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      console.log('payload[0]', payload);

      // const words = Object.keys(payload[0].payload);
      return (
        <Card className="custom-tooltip" sx={{ padding: '10px' }}>
          {/* <p className="intro">{data === 'total' ? '' : `Date:${data}`}</p> */}
          <Typography className="intro" style={{ color: 'grey' }}>
            {data.keyword}
          </Typography>
          <Typography className="intro" style={{ color: 'grey' }}>
            {data.count}
          </Typography>
        </Card>
      );
    }
    return null;
  };
  const legendIconStyle = {
    width: '10px', // Adjust the size as needed
    height: '10px', // Adjust the size as needed
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '5px' // Adjust spacing
  };

  // Custom CSS class for the Legend
  const legendStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    listStyleType: 'none'
  };

  // CSS for the circular legend marker

  const legendTextStyle = {
    fontFamily: 'arial',
    fontWeight: 'bold',
    fontSize: 12
  };

  const CustomLegend = (e) => (
    <div style={legendStyle}>
      {e.payload.map((entry, index) => (
        <li key={index} style={{ listStyleType: 'none' }}>
          <span
            className="legend-icon"
            style={{ ...legendIconStyle, backgroundColor: entry.color }}
          />
          <span style={legendTextStyle}>{entry.dataKey}</span>
        </li>
      ))}
    </div>
  );

  const CustomLegendForTrendDispositon = (e) => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'right',
        listStyleType: 'none'
      }}
    >
      {e.payload.map((entry, index) => (
        <li
          key={index}
          style={{
            listStyleType: 'none',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <span
            className="legend-icon"
            style={{
              width: '10px', // Adjust the size as needed
              height: '10px', // Adjust the size as needed
              borderRadius: '50%',
              display: 'inline-block',
              marginRight: '5px', // Adjust spacing
              backgroundColor: convertUrlColor(entry.color)
            }}
          />

          <span style={legendTextStyle}>
            {/* {entry.datakey?.length > 8 ? ( */}
            <div>
              <Tip title={entry.dataKey} followCursor placement="top">
                {`${entry.dataKey.substring(0, 7)}...`}
              </Tip>
            </div>
            {/* ) : (
              entry.dataKey
            )} */}
          </span>
        </li>
      ))}
    </div>
  );
  const [open, setOpen] = useState();
  const [categoryDate, setCategoryDate] = useState();
  const [categoryDetailsOpen, setCategoryDetailsOpen] = useState();
  const [keywordDetailsOpen, setKeywordsDetailsOpen] = useState();
  const [collectionPopupOpen, setCollectionPopupOpen] = useState();
  const [keyWordlistOpen, setKeyWordlistOpen] = useState(false);
  const [keywordListData, setKeywordListData] = useState([]);
  const [QRCOpen, setOpenQRC] = useState();
  const [shownSentences, setShownSentences] = useState([]);
  const [isDispositionSentenceOpen, setIsDispositionSentenceOpen] = useState();

  const [subscriptionOpen, setSubscriptionOpen] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Table Head Name of filtered unpleasant word
  const UNPLEASANT_TABLE_HEAD = [
    { id: 'Agent', numeric: false, disablePadding: false, label: 'Agent' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    {
      id: 'Keyword',
      numeric: false,
      disablePadding: false,
      label: 'Keywords',
      alignCenter: true
    },
    { id: 'view', numeric: false, disablePadding: false, label: 'View', alignCenter: true }

    // { id: 'Action', numeric: false, disablePadding: false, label: 'Action', alignCenter: true }
  ];

  // Table head name of filtered tone.
  const TONE_TABLE_HEAD = [
    { id: 'Agent', numeric: false, disablePadding: false, label: 'Agent' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    {
      id: 'Tone',
      numeric: false,
      disablePadding: false,
      label: 'Tone Details',
      alignCenter: true
    },
    { id: 'view', numeric: false, disablePadding: false, label: 'View', alignCenter: true }
  ];
  const SENTIMENT_TABLE_HEAD = [
    { id: 'Agent', numeric: false, disablePadding: false, label: 'Agent' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    {
      id: 'Sentiment',
      numeric: false,
      disablePadding: false,
      label: 'Sentiment Details',
      alignCenter: true
    },
    { id: 'View', numeric: false, disablePadding: false, label: 'View', alignCenter: true }
  ];
  const DEADAIR_TABLE_HEAD = [
    { id: 'Agent', numeric: false, disablePadding: false, label: 'Agent' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    {
      id: 'DeadAir',
      numeric: false,
      disablePadding: false,
      label: 'Total Dead Air',
      alignCenter: true
    },
    {
      id: 'Duration',
      numeric: false,
      disablePadding: false,
      label: 'Duration',
      alignCenter: true
    },
    { id: 'View', numeric: false, disablePadding: false, label: 'View', alignCenter: true }
  ];
  const UserSchema = Yup.object().shape({
    organisation: Yup.string().nullable().required('This field is required.'),
    process: Yup.array()
      .nullable()
      .min(1, 'This field is required.')
      .required('This field is required.'),
    customer: Yup.string().nullable().required('This field is required.'),
    agent: Yup.array()
      .nullable()
      .min(1, 'This field is required.')
      .required('This field is required.'),
    startDate: Yup.date().required('This field is required.'),
    endDate: Yup.date().required('This field is required.')
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      organisation: `${
        userDetails.role !== 'Super_Admin'
          ? userDetails.organisation_id
          : locationData.state?.initialData
          ? locationData.state.initialData.formikValues.organisation?.value
          : ''
      }`,
      customer:
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'Customer_Admin' ||
        userDetails.role === 'QA_admin'
          ? userDetails.customer_id
          : locationData.state?.initialData
          ? locationData.state.initialData.formikValues.customer?.value
          : null,
      process: locationData.state?.initialData
        ? locationData.state.initialData.formikValues.process?.map((el) => el.value)
        : [],
      agent: locationData.state?.initialData
        ? locationData.state.initialData.formikValues.agentIds
        : [],
      startDate: locationData.state?.initialData
        ? locationData.state.initialData.formikValues.startDate
        : setWeekBeforeDate,
      endDate: locationData.state?.initialData
        ? locationData.state.initialData.formikValues.endDate
        : setPresentDate
    },
    validationSchema: UserSchema,
    onSubmit: () => {
      handleAllAPI();
      setInitialData(selectedCallType);
      setShowDownloadButton(true);
      const levels = customerList.find(
        (customer) => customer.customer_id === formik.values.customer
      );
      setCustomerLevel(
        levels.level_1 ? 'level_1' : levels.level_2 ? 'level_2' : levels.level_3 ? 'level_3' : ''
      );
    }
  });

  // console.log('formikvalues', formik);

  const handleUnpleasantAPI = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/unplesant_graph?customer_id=${formik.values.customer}&processes=${formik.values.process}&user_id=${formik.values.agent}&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}&org_id=${formik.values.organisation}`,
      config
    )
      .then((res) => {
        setunpleasantData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setgraphDataForUnpleasant();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };

  const handleToneAPI = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/tone_graph?customer_id=${formik.values.customer}&processes=${formik.values.process}&user_id=${formik.values.agent}&date=${defaultDate.value}&org_id=${formik.values.organisation}&flag=${toggleValue}`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(url, config)
      .then((res) => {
        setToneData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setToneData();
        setLoading(false);
        setgraphDataForTone();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };

  const handleDeadAirAPI = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/dead_air_graph?customer_id=${formik.values.customer}&processes=${formik.values.process}&user_id=${formik.values.agent}&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}&org_id=${formik.values.organisation}`,
      config
    )
      .then((res) => {
        setdeadAir(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setgraphDataForDeadAir();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };

  const handleSentimentAPI = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/sentiment_graph?customer_id=${formik.values.customer}&processes=${formik.values.process}&user_id=${formik.values.agent}&date=${defaultDate.value}&org_id=${formik.values.organisation}&flag=${toggleValue}`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(url, config)
      .then((res) => {
        // if (res.data?.dates) {
        const filteredObject = Object.fromEntries(
          Object.entries(res.data.dates).filter(([date, data]) => Object.keys(data).length > 0)
        );
        // console.log('filteredObject', filteredObject);
        // }
        setSentimentData(filteredObject);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSentimentData();
        setDefaultSentimentData();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };

  const handleCardAPI = async () => {
    // setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/collection_cards?customer_id=${formik.values.customer}&processes=${formik.values.process}&user_id=${formik.values.agent}&date=${defaultDate.value}&org_id=${formik.values.organisation}&flag=${toggleValue}`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(url, config)
      .then((res) => {
        setCardData(res.data);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
        setCardData({ processed_calls: 0, sentiment_count: 0, unplz_count: 0, tone_count: 0 });
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };
  const handleAnalysisAPI = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/collection_cards_comparison?customer_id=${formik.values.customer}&processes=${formik.values.process}&user_id=${formik.values.agent}&date=${defaultDate.value}&org_id=${formik.values.organisation}&flag=${toggleValue}`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(url, config)
      .then((res) => {
        setAnalysisData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setAnalysisData();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };

  const handleCategoryAPI = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/keywords_graph?customer_id=${formik.values.customer}&processes=${formik.values.process}&user_id=${formik.values.agent}&date=${defaultDate.value}&org_id=${formik.values.organisation}&flag=${toggleValue}`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(
      // `/secure/keywords_graph?customer_id=529&processes=Billing&org_id=199&user_id=523&start_date=2023-05-1&end_date=2023-05-3`,
      url,
      config
    )

      .then((res) => {
        // removes the object with no data
        const data = res?.data?.dates;
        Object.keys(data).forEach((key) => {
          if (Object.keys(data[key]).length === 0) {
            delete data[key];
          }
        });

        setCategoryData(data);
        if (Object.keys(data).length === 0) {
          setDefaultCategoryData();
        }

        setLoading(false);
      })
      .catch((err) => {
        setDefaultCategoryData();
        setCategoryData();
        console.log(err);
        setLoading(false);

        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };
  const handleCollectionAPI = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/collection_categories?customer_id=${formik.values.customer}&processes=${formik.values.process}&user_id=${formik.values.agent}&date=${defaultDate.value}&org_id=${formik.values.organisation}`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(
      // `/secure/keywords_graph?customer_id=529&processes=Billing&org_id=199&user_id=523&start_date=2023-05-1&end_date=2023-05-3`,
      url,
      config
    )

      .then((res) => {
        // removes the object with no data
        const data = res?.data?.collection_category_count;
        // Object.keys(data).forEach((key) => {
        //   if (Object.keys(data[key]).length === 0) {
        //     delete data[key];
        //   }
        // });

        setCollectionData(data);
        if (Object.keys(data).length === 0) {
          setCollectionData();
        }

        setLoading(false);
      })
      .catch((err) => {
        setCollectionData();

        console.log(err);
        setLoading(false);

        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };
  const handleQRCAPI = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/disposition_upto_level_1?customer_id=${formik.values.customer}&processes=${formik.values.process}&user_id=${formik.values.agent}&date=${defaultDate.value}&org_id=${formik.values.organisation}&qrc_type=${selectedCallType.label}`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(url, config)
      .then((res) => {
        if (selectedCallType.value === 'all') {
          const outputData = Object.entries(res.data.classification_graph).map(
            ([date, values]) => ({
              date: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
              ...values
              // date: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
              // Query: (values.Query / (values.Query + values.Request + values.Complaint)) * 100 || 0,
              // Request:
              //   (values.Request / (values.Query + values.Request + values.Complaint)) * 100 || 0,
              // Complaint:
              //   (values.Complaint / (values.Query + values.Request + values.Complaint)) * 100 || 0
            })
          );

          const filteredArray = outputData.filter((item) => {
            // Get an array of all values from the item
            const values = Object.values(item);
            // Filter out the item if all values are zero
            return !values.every((value) => value === 0);
          });

          // console.log('outputData', filteredArray);

          const summaryQRC = outputData.reduce((acc, entry) => {
            Object.entries(entry).forEach(([key, value]) => {
              if (key !== 'date') {
                acc[key] = (acc[key] || 0) + value;
              }
            });
            return acc;
          }, {});
          setOverallQRCData(summaryQRC);
          setQRCData(filteredArray);
          setCallCategories(Object.keys(summaryQRC));
        } else if (selectedCallType.value !== 'all') {
          const outputData =
            Object.keys(res.data.classification_graph).length > 0
              ? Object.entries(res.data.classification_graph).map(([date, data]) => ({
                  date: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                  ...data
                }))
              : [];
          // console.log('outputData', outputData);
          const summaryQRC = outputData.reduce((acc, entry) => {
            Object.entries(entry).forEach(([key, value]) => {
              if (key !== 'date') {
                acc[key] = (acc[key] || 0) + value;
              }
            });
            return acc;
          }, {});
          setOverallQRCData(summaryQRC);
          setQRCData(outputData);
          setCallCategories(Object.keys(summaryQRC));
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setQRCData([]);
        setOverallQRCData();
        setLoading(false);
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };
  const handleQRCChartAPI = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/qrc_call_type_count?customer_id=${formik.values.customer}&processes=${formik.values.process}&user_id=${formik.values.agent}&date=${defaultDate.value}&org_id=${formik.values.organisation}`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(url, config)
      .then((res) => {
        const output = res.data?.qrc_call_type_data.reduce((acc, item) => {
          if (item.ai_score !== 0 || item.manual_score !== 0) {
            acc.push({
              name: item.category,
              ai_average_score: parseFloat(item.ai_score.toFixed(2)), // Round ai_score to 2 decimal places
              average_manual_score: parseFloat(item.manual_score.toFixed(2)) // Round manual_score to 2 decimal places
            });
          }
          return acc;
        }, []);
        // const output = res.data?.qrc_call_type_data.map((item) => ({
        //   name: item.category,
        //   AI_Average_Score: parseFloat(item.ai_score.toFixed(2)),
        //   Manual_Average_Score: parseFloat(item.manual_score.toFixed(2))
        // }));
        setQRCChartData(output);
        setQRCTableData(res.data.qrc_call_type_data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setQRCChartData([]);
        setQRCTableData([]);
        setLoading(false);
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };
  const handleDispositionAPI = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/disposition_upto_level_2_or_3?customer_id=${formik.values.customer}&user_id=${formik.values.agent}&date=${defaultDate.value}&processes=${formik.values.process}&org_id=${formik.values.organisation}&intent_type=${filterForQRC}&sub_category=${dispositionType}`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(url, config)
      // await API.get(
      //   `/secure/filter_disposition_details?customer_id=${formik.values.customer}&user_id=${formik.values.agent}&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}&org_id=${formik.values.organisation}&intent_type=${filterForQRC}&sub_category=${dispositionType}`,
      //   config
      // )
      .then((res) => {
        // console.log('res.data.disposition', res.data.disposition);
        const convertedData = Object.entries(res.data.disposition).flatMap(([date, items]) =>
          items.map((item) => {
            const newItem = {
              date: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
              org_id: item.org_id,
              customer_id: item.customer_id,
              agent_id: item.agent_id,
              agent_name: item.agent_name,
              input_id: item.input_id,
              request_id: item.request_id,
              s3url: item.s3url,
              processes: item.processes,
              original_date: item.original_date,
              disposition: item.disposition
            };
            Object.entries(item).forEach(([key, value]) => {
              if (key !== 's3url') {
                newItem[key] = value;
              }
            });
            newItem[item.s3url.split('/').pop()] = 1;
            return newItem;
          })
        );
        setTrendDispositionGraphData(convertedData);

        const allCalls = [];
        Object.values(res.data.disposition).forEach((calls) => {
          calls.forEach((call) => {
            const fileName = call.s3url.split('/').pop();
            allCalls.push(fileName);
          });
        });

        // setDispositionBarData(allDispositions);
        setAllCallsList(allCalls);

        // console.log('overallCategoryCount', summaryDisposition);

        setLoading(false);
      })
      .catch((err) => {
        setTrendDispositionGraphData([]);

        setLoading(false);
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };
  const handleFilterDispositionAPI = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/disposition_summary_level_2_or_3?customer_id=${formik.values.customer}&user_id=${formik.values.agent}&date=${defaultDate.value}&processes=${formik.values.process}&org_id=${formik.values.organisation}&sub_category=${dispositionType}&intent_type=${filterForQRC}`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(url, config)
      .then((res) => {
        const { category_count } = res.data;
        const finalData = Object.keys(category_count).map((el) => {
          return { category: el, count: category_count[el] };
        });
        setNewSummaryDispositionGraph(finalData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setNewSummaryDispositionGraph([]);
        setLoading(false);
      });
  };

  const handleAllAPI = async () => {
    if (tabValue === '1') {
      await Promise.all([
        handleSentimentAPI(),
        handleToneAPI(),
        handleCardAPI()
        // handleAnalysisAPI()
      ]);
    }
    if (tabValue === '2') {
      if (process.env.REACT_APP_TO_SHOW_QRC === 'true') {
        await Promise.all([
          handleQRCAPI(),
          getDispositionList(),
          handleDispositionAPI(),
          handleFilterDispositionAPI(),
          handleQRCChartAPI()
        ]);
      }
    }
    if (tabValue === '3') {
      handleCategoryAPI();
    }
    if (tabValue === '4') {
      handleCollectionAPI();
    }

    // setDispositionList(customerDispostionList);
    // setDispositionType('All');
    setDefaultDashboardData();
    // setgraphDataForTone([]);
  };

  const handleUnpleasantPopup = async () => {
    setLoading(true);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const newUnpleasantUrl = `/secure/new_unplesant_graph?user_id=${
      formik.values.agent
    }&start_date=${moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')}`;
    // console.log(initialData.agent);
    await API.get(newUnpleasantUrl, config)
      .then((res) => {
        setNewUnpleasantData(res.data.unplesant_graph);
      })
      .catch((err) => {
        setLoading(false);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };
  const handleSentimentPopup = async (sentimentDate, dataIndex) => {
    setLoading(true);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const sentiment_value =
      (dataIndex === 0 && 'positive') ||
      (dataIndex === 1 && 'negative') ||
      (dataIndex === 2 && 'neutral');
    const Url = `/secure/new_sentiment_graph?customer_id=${formik.values.customer}&user_id=${formik.values.agent}&date=${defaultDate.value}&start_date=${sentimentDate}&flag=${toggleValue}&sentiment_value=${sentiment_value}&page=${pagePopup}`;
    // console.log(initialData.agent);
    await API.get(Url, config)
      .then((res) => {
        // setNewSentimentData(res.data.sentiment_graph);
        setFilteredSentiment(res.data.results.list_of_calls);
        setSentimentLabel(sentiment_value);
        setDownloadPath(res.data.results.file_path);
        setOverallCount(res.data.count);
        setSentDate(sentimentDate);
        setOpen(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  // console.log('categoryData aaaaaa', categoryData);
  const handleCategoryPopup = async (date, category) => {
    setLoading(true);
    setShownCategory(category);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const Url = `/secure/new_keywords_graph?customer_id=${formik.values.customer}&user_id=${formik.values.agent}&date=${defaultDate.value}&start_date=${date}&category=${category}&flag=${toggleValue}`;

    // const Url = `/secure/new_keywords_graph?user_id=523&start_date=2023-05-1&category=positive`;
    // console.log('dateweeee', date);
    // const defaultUrl = `/secure/new_default_category_graph?start_date=2023-5-1&category=positive`;
    let defaultUrl = `/secure/new_default_category_graph?start_date=${date}&category=${category}&flag=${toggleValue}`;
    if (userDetails.role === 'Customer_Admin') {
      defaultUrl += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      defaultUrl += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    // console.log(initialData.agent);
    await API.get(categoryData ? Url : defaultUrl, config)
      .then((res) => {
        setCategoryDate(date);
        setToneDate(null);
        setNewCategoryData(res.data.dates);
        setDownloadPath(res.data.file_path);
        handleCategoryDialog();
        // console.log('Object.values(res.data.dates)', Object.values(res.data.dates)[0]);
        // setFilteredSentiment(res.data.sentiment_graph[sentimentDate]);
        // setSentDate(moment(sentimentDate, 'YYYY-M-D').format('DD/MM/YYYY'));
        // handleDialog();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  const handleKeywordPopupForSummary = async (payload) => {
    setLoading(true);
    setShownCategory(payload.category);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    // /secure/category_keyword_count_graph?customer_id=5&org_id=4&start_date=2023-11-17&end_date=2023-11-24&flag=agent&cat=Banking
    let defaultUrl = `/secure/default_category_keyword_count_graph?date=${defaultDate.value}&cat=${payload.category}&flag=${toggleValue}`;
    if (defaultDate.value === 'customized') {
      defaultUrl += `&start_date=${setWeekBeforeDate}&end_date=${setPresentDate}`;
    }
    if (userDetails.role === 'Customer_Admin') {
      defaultUrl += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      defaultUrl += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    let filterUrl = `/secure/category_keyword_count_graph?customer_id=${formik.values.customer}&date=${defaultDate.value}&user_id=${formik.values.agent}&cat=${payload.category}&flag=${toggleValue}`;
    if (defaultDate.value === 'customized') {
      filterUrl += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    // const Url = `/secure/new_keywords_graph?user_id=523&start_date=2023-05-1&category=positive`;
    // console.log('dateweeee', date);
    // const defaultUrl = `/secure/new_default_category_graph?start_date=2023-5-1&category=positive`;
    // let defaultUrl = `/secure/new_default_category_graph?start_date=${date}&category=${category}&flag=${toggleValue}`;
    // if (userDetails.role === 'Customer_Admin') {
    //   defaultUrl += `&org_id=${userDetails.organisation_id}`;
    // } else if (userDetails.role === 'QA' || userDetails.role === 'Manager') {
    //   defaultUrl += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    // }
    // console.log(initialData.agent);
    await API.get(defaultDashboardData ? defaultUrl : filterUrl, config)
      .then((res) => {
        const graphDataForKeywords = Object.keys(res.data.ckg[payload.category]).map((keyword) => ({
          keyword,
          count: res.data.ckg[payload.category][keyword]
        }));
        console.log('graphDataForKeywords', graphDataForKeywords);
        setKeywordsGraphData(graphDataForKeywords);
        handleDialogForSummary();
        // console.log('keywordsgraphdata', res.data.ckg[payload.category]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };
  const handleCollectionPopup = async (payload) => {
    setLoading(true);
    setShownCategory(payload.name);
    // console.log('payloadee', payload);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    // /secure/category_keyword_count_graph?customer_id=5&org_id=4&start_date=2023-11-17&end_date=2023-11-24&flag=agent&cat=Banking
    let defaultUrl = `/secure/combined_categories_details?date=${defaultDate.value}&flag=All&category=${payload.name}&page=${pagePopupForCollection}`;
    if (defaultDate.value === 'customized') {
      defaultUrl += `&start_date=${setWeekBeforeDate}&end_date=${setPresentDate}`;
    }
    if (userDetails.role === 'Customer_Admin') {
      defaultUrl += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      defaultUrl += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    let filterUrl = `/secure/combined_categories_details?customer_id=${formik.values.customer}&date=${defaultDate.value}&user_id=${formik.values.agent}&org_id=${formik.values.organisation}&processes=${formik.values.process}&category=${payload.name}&page=${pagePopupForCollection}`;
    if (defaultDate.value === 'customized') {
      filterUrl += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    await API.get(defaultDashboardData ? defaultUrl : filterUrl, config)
      .then((res) => {
        setCollectionPopupData(res.data);
        setDownloadPath(res.data.results.file_path);
        // handleDialogForCollection();
        // console.log('keywordsgraphdata', res.data.ckg[payload.category]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };
  const downloadCSVForCategoryDetails = async () => {
    setDownloadStart(true);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    // /secure/category_keyword_count_graph?customer_id=5&org_id=4&start_date=2023-11-17&end_date=2023-11-24&flag=agent&cat=Banking
    let defaultUrl = `/secure/default_keyword_data?date=${defaultDate.value}&category_keyword=${shownCategory}&flag=${toggleValue}`;
    if (defaultDate.value === 'customized') {
      defaultUrl += `&start_date=${setWeekBeforeDate}&end_date=${setPresentDate}`;
    }
    if (userDetails.role === 'Customer_Admin') {
      defaultUrl += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      defaultUrl += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    let filterUrl = `/secure/download_a_keyword_data?org_id=${formik.values.organisation}&customer_id=${formik.values.customer}&processes=${formik.values.process}&date=${defaultDate.value}&user_id=${formik.values.agent}&category_keyword=${shownCategory}&flag=${toggleValue}`;
    if (defaultDate.value === 'customized') {
      filterUrl += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }

    await API.get(defaultDashboardData ? defaultUrl : filterUrl, config)
      .then((res) => {
        setTaskId(res.data.task_id);
        setDownloadStart(false);
      })
      .catch((err) => {
        setDownloadStart(false);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  const [showKeyWord, setShowKeyWord] = useState();
  const handleKeywordCountListAPi = async (keyword, categary) => {
    setShowKeyWord(keyword);
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let defaulturl = `/secure/default_keyword_count_list?date=${defaultDate.value}&keyword=${keyword}&flag=${toggleValue}&cat=${categary}`;
    if (defaultDate.value === 'customized') {
      defaulturl += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    if (userDetails.role === 'Customer_Admin') {
      defaulturl += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      defaulturl += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    let url = `/secure/filter_keyword_count_list?date=${defaultDate.value}&user_id=${formik.values.agent}&customer_id=${formik.values.customer}&keyword=${keyword}&flag=${toggleValue}&org_id=${formik.values.organisation}&cat=${categary}`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(defaultDashboardData ? defaulturl : url, config)
      .then((res) => {
        console.log(res);
        setKeywordListData(res.data.keyword_graph);
        setDownloadPath(res.data.file_path);
        handleKeywordListPopup();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setKeywordListData();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
      });
  };

  const [pagePopup, setPagePopup] = useState();
  const [pagePopupForCollection, setPagePopupForCollection] = useState(1);
  const [overallCount, setOverallCount] = useState();

  const handleTonePopup = (toneValue, toneChartDate) => {
    setLoading(true);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const newToneUrl = `/secure/new_tone_graph?customer_id=${formik.values.customer}&user_id=${formik.values.agent}&date=${defaultDate.value}&start_date=${toneChartDate}&tone_value=${toneValue}&flag=${toggleValue}&page=${pagePopup}`;
    API.get(newToneUrl, config)
      .then((newToneRes) => {
        setToneLabel(toneValue);
        setFilteredTone(newToneRes.data.results.list_of_calls);
        setOverallCount(newToneRes.data.count);
        setDownloadPath(newToneRes.data.results.file_path);
        setOpen(true);
        setLoading(false);
        // setNewToneData(newToneRes.data.results.tone_graph);
        // setFilteredTone(
        //   newToneRes.data.tone_graph[moment(toneDate, 'DD/MM/YYYY').format('YYYY-MM-DD')]
        // );
        // handleDialog();
        // setLoading(false);
      })
      // .then(() => {
      //   handleDialog();
      //   setLoading(false);
      // })
      .catch((err) => {
        console.log('error', err.response?.data.message);
        setLoading(false);
        setAlertContent(`${err.response?.data.message}`);
        setLoading(false);
        setAlertType('error');
        setAlert(true);
      });
  };

  useEffect(() => {
    if (shownCategory) {
      handleCollectionPopup({ name: shownCategory });
    }
  }, [pagePopupForCollection]);

  const handleSummaryTonePopup = (toneValue) => {
    setLoading(true);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let defaultUrl = `/secure/default_summary_HML?date=${defaultDate.value}&flag=${toggleValue}&tone_value=${toneValue}&page=${pagePopup}`;
    if (defaultDate.value === 'customized') {
      defaultUrl += `start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    if (userDetails.role === 'Customer_Admin') {
      defaultUrl += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      defaultUrl += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    let filterUrl = `/secure/filter_HML_list?date=${defaultDate.value}&customer_id=${formik.values.customer}&user_id=${formik.values.agent}&org_id=${formik.values.organisation}&flag=${toggleValue}&tone_value=${toneValue}&page=${pagePopup}`;
    if (defaultDate.value === 'customized') {
      filterUrl += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    //   formik.values.agent
    // }&start_date=${moment(toneDate, 'DD/MM/YYYY').format(
    //   'YYYY-MM-DD'
    // )}&tone_value=${toneValue}&flag=${toggleValue}`;
    API.get(defaultDashboardData ? defaultUrl : filterUrl, config)
      .then((newToneRes) => {
        // setShowToneColumnName()
        setToneLabel(toneValue);
        setFilteredTone(newToneRes.data.results.list_of_calls);
        setOverallCount(newToneRes.data.count);
        setDownloadPath(newToneRes.data.results.file_path);
        setToneDate(formik.values.startDate);
        setDataIndex(null);
        setOpen(true);
        setLoading(false);
        // setFilteredTone(
        // console.log('newToneRes.data.tone_graph', newToneRes.data.tone_graph);
        //   newToneRes.data.tone_graph[moment(toneDate, 'DD/MM/YYYY').format('YYYY-MM-DD')]
        // );
        // handleDialog();
        // setLoading(false);
      })
      // .then(() => {
      //   handleDialog();
      //   setLoading(false);
      // })
      .catch((err) => {
        console.log('error', err.response?.data.message);
        setAlertContent(`${err.response?.data.message}`);
        setLoading(false);
        setAlertType('error');
        setAlert(true);
      });
  };

  const handleSummarySentimentPopup = async (sentiment_value) => {
    setLoading(true);
    const token = localStorage.getItem('token').toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let defaultUrl = `/secure/default_sentiments_list?date=${defaultDate.value}&flag=${toggleValue}&sentiment_value=${sentiment_value}&page=${pagePopup}`;
    if (defaultDate.value === 'customized') {
      defaultUrl += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    if (userDetails.role === 'Customer_Admin') {
      defaultUrl += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      defaultUrl += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    let filterUrl = `/secure/filter_sentiments_list?date=${defaultDate.value}&customer_id=${formik.values.customer}&user_id=${formik.values.agent}&org_id=${formik.values.organisation}&flag=${toggleValue}&sentiment_value=${sentiment_value}&page=${pagePopup}`;
    if (defaultDate.value === 'customized') {
      filterUrl += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    // const filterUrl = `/secure/filter_sentiments_list?org_id=${formik.values.organisation}&customer_id=${formik.values.customer}&user_id=${formik.values.agent}&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}&flag=${toggleValue}&sentiment_value=${sentiment_value}`;
    // console.log(initialData.agent);
    await API.get(defaultDashboardData ? defaultUrl : filterUrl, config)
      .then((res) => {
        // setNewSentimentData(res.data.list_of_calls);
        setFilteredSentiment(res.data.results.list_of_calls);
        setOverallCount(res.data.count);
        setSentimentLabel(sentiment_value);
        setDownloadPath(res.data.results.file_path);
        setToneDate(null);
        setDataIndex(1);
        setSentDate(moment(formik.values.startDate, 'YYYY-M-D').format('DD/MM/YYYY'));
        setOpen(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };
  useEffect(() => {
    if (pagePopup && globalToneValue) {
      if (globalToneValue === 'high' || globalToneValue === 'medium' || globalToneValue === 'low') {
        handleSummaryTonePopup(globalToneValue);
      } else if (
        globalToneValue === 'positive' ||
        globalToneValue === 'negative' ||
        globalToneValue === 'neutral'
      ) {
        handleSummarySentimentPopup(globalToneValue);
      }
    }
  }, [pieUpdateGlobal, pagePopup]);
  // const handleQRCPopup = (type, date) => {
  //   setLoading(true);
  //   const token = localStorage.getItem('token').toString();
  //   const config = {
  //     headers: { Authorization: `Bearer ${token}` }
  //   };
  //   const Url = `/secure/filter_call_classification?customer_id=${formik.values.customer}&user_id=${formik.values.agent}&start_date=${date}&call_category=${type}`;
  //   API.get(Url, config)
  //     .then((res) => {
  //       setQRCList(res.data.intent_list[date]);
  //       handleDialogForQRCList();
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setAlertContent(`${err.response?.data.message}`);
  //       setLoading(false);
  //       setAlertType('error');
  //       setAlert(true);
  //     });
  // };

  // <-------------------------------------------------------------->

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDefaultDashboard = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let url = `/secure/default_dashboard?date=${defaultDate.value}&flag=${toggleValue}`;
    // let url = `/secure/default_dashboard?start_date=2023-12-1&end_date=2024-01-18&flag=${toggleValue}`;

    if (userDetails.role === 'Customer_Admin') {
      url += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    await API.get(url, config)
      .then((res) => {
        setDefaultDashboardData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDefaultDashboardData();
        setgraphDataForTone([]);
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
        setLoading(false);
      });
  };

  const getNewSummaryDispositionData = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let url = `/secure/disposition_summary_level_2_or_3?intent_type=${filterForQRC}&date=${defaultDate.value}&sub_category=${dispositionType}&flag=All`;

    if (userDetails.role === 'Customer_Admin') {
      url += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    await API.get(url, config)
      .then((res) => {
        const { category_count } = res.data;
        const finalData = Object.keys(category_count).map((el) => {
          return { category: el, count: category_count[el] };
        });
        setNewSummaryDispositionGraph(finalData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setNewSummaryDispositionGraph([]);
        setLoading(false);
      });
  };
  const getDefaultDispositionData = async () => {
    if (process.env.REACT_APP_TO_SHOW_QRC === 'true') {
      setLoading(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      let url = `/secure/disposition_upto_level_2_or_3?date=${defaultDate.value}&intent_type=${filterForQRC}&sub_category=${dispositionType}&flag=All`;
      // let url = `/secure/default_dashboard?start_date=2023-12-1&end_date=2024-01-18&flag=${toggleValue}`;

      if (userDetails.role === 'Customer_Admin') {
        url += `&org_id=${userDetails.organisation_id}`;
      } else if (
        userDetails.role === 'QA' ||
        userDetails.role === 'Manager' ||
        userDetails.role === 'QA_admin'
      ) {
        url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
      }
      await API.get(url, config)
        .then((res) => {
          // console.log('res.data.disposition', res.data.disposition);
          const convertedData = Object.entries(res.data.disposition).flatMap(([date, items]) =>
            items.map((item) => {
              const newItem = {
                date: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                org_id: item.org_id,
                customer_id: item.customer_id,
                agent_id: item.agent_id,
                agent_name: item.agent_name,
                input_id: item.input_id,
                request_id: item.request_id,
                s3url: item.s3url,
                processes: item.processes,
                original_date: item.original_date,
                disposition: item.disposition
              };
              Object.entries(item).forEach(([key, value]) => {
                if (key !== 's3url') {
                  newItem[key] = value;
                }
              });
              newItem[item.s3url.split('/').pop()] = 1;
              return newItem;
            })
          );
          setTrendDispositionGraphData(convertedData);

          const allCalls = [];
          Object.values(res.data.disposition).forEach((calls) => {
            calls.forEach((call) => {
              const fileName = call.s3url.split('/').pop();
              allCalls.push(fileName);
            });
          });

          // setDispositionBarData(allDispositions);
          setAllCallsList(allCalls);

          setLoading(false);
        })
        .catch((err) => {
          setTrendDispositionGraphData([]);

          setLoading(false);
          // setAlertContent(`${err.response?.data.message}`);
          // setAlertType('error');
          // setAlert(true);
        });
    }
  };

  useEffect(() => {
    if (
      !toneData &&
      !categoryData &&
      !sentimentData &&
      formik.values.agent.length === 0 &&
      dispositionList.length > 0 &&
      dispositionType &&
      process.env.REACT_APP_TO_SHOW_QRC === 'true'
    ) {
      getDefaultDispositionData();
      getNewSummaryDispositionData();
    } else if (
      dispositionType &&
      dispositionList?.length > 0 &&
      process.env.REACT_APP_TO_SHOW_QRC === 'true'
    ) {
      handleDispositionAPI();
      handleFilterDispositionAPI();
    }
    // }
  }, [dispositionList, filterForQRC, dispositionType]);
  const [defaultSentimentData, setDefaultSentimentData] = useState();
  const [allEscalationValue, setAllEscalationValue] = useState();
  const [escalationValue, setEscalationValue] = useState('All');
  const [escalationSelectValue, setEscalationSelectValue] = useState();
  // console.log('new select val:', escalationSelectValue);
  // console.log('left side val:', allEscalationValue);
  const getDefaultSentimentData = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/sentiment_default_dashboard?date=${defaultDate.value}&flag=${toggleValue}`;
    if (userDetails.role === 'Customer_Admin') {
      url += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    await API.get(url, config)
      .then((res) => {
        const filteredObject = Object.fromEntries(
          Object.entries(res.data.sentiment_graph).filter(
            ([date, data]) => Object.keys(data).length > 0
          )
        );
        setDefaultSentimentData(filteredObject);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDefaultSentimentData();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
        setLoading(false);
      });
  };
  const getEscalationDefaultCategory = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    // let url = `/secure/sentiment_default_dashboard?date=${defaultDate.value}&flag=${toggleValue}`;
    let url = `/secure/default_categories_details?date=one_week&category=${escalationValue}`;
    if (userDetails.role === 'Customer_Admin') {
      url += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    await API.get(url, config)
      .then((res) => {
        // const filteredObject = Object.fromEntries(
        //   Object.entries(res.data.sentiment_graph).filter(
        //     ([date, data]) => Object.keys(data).length > 0
        //   )
        // );
        // console.log('escalation fraud response', res.data);
        setEscalationSelectValue(res.data.categories_call_details);
        // setDefaultSentimentData(filteredObject);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setDefaultSentimentData();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
        // setLoading(false);
      });
  };
  const getEscalationDataAll = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/escalation_categories?date=one_week&flag=All`;
    if (userDetails.role === 'Customer_Admin') {
      url += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    await API.get(url, config)
      .then((res) => {
        // const filteredObject = Object.fromEntries(
        //   Object.entries(res.data.sentiment_graph).filter(
        //     ([date, data]) => Object.keys(data).length > 0
        //   )
        // );
        console.log('escalation all data response', res);
        setAllEscalationValue(res.data.escalation_category_count);
        // setEscalationDataSum()res.data.escalation_category_count)

        // setDefaultSentimentData(filteredObject);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setDefaultSentimentData();
        setAllEscalationValue();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
        // setLoading(false);
      });
  };

  const getNewDefaultDashboard = async (value, chartType, date) => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const sentiment_value =
      (value === 0 && 'positive') || (value === 1 && 'negative') || (value === 2 && 'neutral');
    let url = `/secure/new_default_dashboard?start_date=${date}&chart_type=${chartType}&flag=${toggleValue}&page=${pagePopup}`;
    if (chartType === 'tone') {
      url += `&tone_value=${value}`;
    }
    if (chartType === 'sentiment') {
      url += `&sentiment_value=${sentiment_value}`;
    }
    await API.get(url, config)
      .then((res) => {
        setDownloadPath(res.data.results.file_path);
        // console.log('date------', res.data);
        if (date && chartType === 'sentiment') {
          setSentDate(moment(date, 'YYYY-M-D').format('DD/MM/YYYY'));
          setSentimentLabel(sentiment_value);
          setFilteredSentiment(res.data.results.tone_graph);
          setOverallCount(res.data.count);
          setOpen(true);
          setLoading(false);
        } else if (chartType === 'tone') {
          setToneLabel(value);
          // setToneDate((date, 'YYYY-M-D').format('DD/MM/YYYY'));
          setFilteredTone(res.data.results.tone_graph);
          setOverallCount(res.data.count);
          setOpen(true);
          setLoading(false);
        }
        // setNewUnpleasantData();
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
        setAlert(true);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (pagePopup && defaultDashboardData && globalDate) {
      if (globalBarValue === 'high' || globalBarValue === 'medium' || globalBarValue === 'low') {
        getNewDefaultDashboard(globalBarValue, 'tone', globalDate);
      } else if (globalBarValue === 0 || globalBarValue === 1 || globalBarValue === 2) {
        getNewDefaultDashboard(globalBarValue, 'sentiment', globalDate);
      }
    } else if (pagePopup && globalDate) {
      if (globalBarValue === 'high' || globalBarValue === 'medium' || globalBarValue === 'low') {
        handleTonePopup(globalBarValue, globalDate);
      } else if (globalBarValue === 0 || globalBarValue === 1 || globalBarValue === 2) {
        handleSentimentPopup(globalDate, globalBarValue);
      }
    }
  }, [pagePopup, updateGlobal]);

  const getPopupDataForQRCPieChart = async (type) => {
    setLoading(true);
    setShownQRCDetails(type);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let defaultUrl = `/secure/default_summary_classification_list?date=${defaultDate.value}&intent_type=${filterForQRC}&sub_category=${dispositionType}&disposition=${type}`;
    // let defaultUrl = `/secure/default_summary_classification_list?start_date=2023-12-01&end_date=2024-01-18&call_category=${type}`;
    if (userDetails.role === 'Customer_Admin') {
      defaultUrl += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      defaultUrl += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    let filterUrl = `/secure/filter_summary_classification_list?date=${defaultDate.value}&customer_id=${formik.values.customer}&user_id=${formik.values.agent}&org_id=${formik.values.organisation}&intent_type=${filterForQRC}&sub_category=${dispositionType}&disposition=${type}`;
    if (defaultDate.value === 'customized') {
      filterUrl += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(defaultDashboardData ? defaultUrl : filterUrl, config)
      .then((res) => {
        setQRCList(res.data.classification_graph);

        handleDialogForQRCList();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
        setAlert(true);
        setLoading(false);
      });
  };
  const getPopupDataForQRC = async (type, date) => {
    setLoading(true);
    setShownQRCDetails(type);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let defaultUrl = `/secure/new_call_classification?start_date=${date}&intent_type=${filterForQRC}&sub_category=${dispositionType}&disposition=${type}`;
    if (userDetails.role === 'Customer_Admin') {
      defaultUrl += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      defaultUrl += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    const filterUrl = `/secure/filter_call_classification?customer_id=${formik.values.customer}&user_id=${formik.values.agent}&start_date=${date}&intent_type=${filterForQRC}&sub_category=${dispositionType}&disposition=${type}`;

    await API.get(defaultDashboardData ? defaultUrl : filterUrl, config)
      .then((res) => {
        // console.log('QRCCategory', res);
        // if (selectedCallType.value === 'all') {
        setQRCList(res.data.intent_list[date]);
        // } else {
        //   setQRCList([
        //     {
        //       agent_id: 446,
        //       agent_name: 'Ratan Tata',
        //       disposition: 'Credit Card Pin Generation',
        //       s3url:
        //         'sftp_transfer/frontend/20/70/None/446/1OB16031AP6FN8UNB3KPU9VVLS07H6I1_2023-11-01_18-59-_39ycIHG.mp3',
        //       input_id: 4448,
        //       request_id: 4325,
        //       org_id: 20,
        //       customer_id: 70,
        //       processes: ['One'],
        //       original_date: '2023-12-31T00:00:00'
        //     },
        //     {
        //       agent_id: 355,
        //       agent_name: 'Suresh Pal',
        //       disposition: 'Credit Card Pin Generation',
        //       s3url:
        //         'sftp_transfer/frontend/20/70/None/355/M6JIJVJG9T0S35HNEGQS8D51100A0UJR_2023-07-12_04-06-_TLMwlJl.mp3',
        //       input_id: 4450,
        //       request_id: 4327,
        //       org_id: 20,
        //       customer_id: 70,
        //       processes: ['One'],
        //       original_date: '2024-01-03T00:00:00'
        //     },
        //     {
        //       agent_id: 355,
        //       agent_name: 'Suresh Pal',
        //       disposition: 'Credit Card Pin Generation',
        //       s3url:
        //         'sftp_transfer/frontend/20/70/None/355/M6JIJVJG9T0S35HNEGQS8D51100ADRFL_2023-07-12_15-07-_UAe4wRH.mp3',
        //       input_id: 4451,
        //       request_id: 4328,
        //       org_id: 20,
        //       customer_id: 70,
        //       processes: ['One'],
        //       original_date: '2024-01-03T00:00:00'
        //     },
        //     {
        //       agent_id: 355,
        //       agent_name: 'Suresh Pal',
        //       disposition: 'Credit Card Pin Generation',
        //       s3url:
        //         'sftp_transfer/frontend/20/70/None/355/M6JIJVJG9T0S35HNEGQS8D51100CB1F0_2023-07-17_12-11-_PbU9zMR.mp3',
        //       input_id: 4452,
        //       request_id: 4329,
        //       org_id: 20,
        //       customer_id: 70,
        //       processes: ['One'],
        //       original_date: '2024-01-03T00:00:00'
        //     },
        //     {
        //       agent_id: 355,
        //       agent_name: 'Suresh Pal',
        //       disposition: 'Credit Card Pin Generation',
        //       s3url:
        //         'sftp_transfer/frontend/20/70/None/355/M6JIJVJG9T0S35HNEGQS8D51100ADRFL_2023-07-12_15-07-_VFmD7Du.mp3',
        //       input_id: 4453,
        //       request_id: 4330,
        //       org_id: 20,
        //       customer_id: 70,
        //       processes: ['One'],
        //       original_date: '2024-01-04T00:00:00'
        //     },
        //     {
        //       agent_id: 446,
        //       agent_name: 'Ratan Tata',
        //       disposition: 'Credit Card Pin Generation',
        //       s3url:
        //         'sftp_transfer/frontend/20/70/None/446/M6JIJVJG9T0S35HNEGQS8D511009G5BL_2023-07-10_21-22-_v00n4so.mp3',
        //       input_id: 4454,
        //       request_id: 4331,
        //       org_id: 20,
        //       customer_id: 70,
        //       processes: ['One'],
        //       original_date: '2024-01-04T00:00:00'
        //     },
        //     {
        //       agent_id: 468,
        //       agent_name: 'Manisha More',
        //       disposition: 'Credit Card Pin Generation',
        //       s3url:
        //         'sftp_transfer/frontend/20/70/None/468/1OB16031AP6FN8UNB3KPU9VVLS09NPSO_mp3_04012024080824.mp3',
        //       input_id: 4462,
        //       request_id: 4339,
        //       org_id: 20,
        //       customer_id: 70,
        //       processes: ['One'],
        //       original_date: '2024-01-04T00:00:00'
        //     },
        //     {
        //       agent_id: 468,
        //       agent_name: 'Manisha More',
        //       disposition: 'Credit Card Pin Generation',
        //       s3url:
        //         'sftp_transfer/frontend/20/70/None/468/1OB16031AP6FN8UNB3KPU9VVLS090N2U_mp3_04012024080824.mp3',
        //       input_id: 4464,
        //       request_id: 4341,
        //       org_id: 20,
        //       customer_id: 70,
        //       processes: ['One'],
        //       original_date: '2024-01-04T00:00:00'
        //     }
        //   ]);
        // }
        handleDialogForQRCList();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
        setAlert(true);
        setLoading(false);
      });
  };

  const getDefaultCardData = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let url = `/secure/default_collection_cards?date=${defaultDate.value}&flag=${toggleValue}`;

    if (userDetails.role === 'Customer_Admin') {
      url += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }

    await API.get(url, config)
      .then((res) => {
        setCardData(res.data);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setCardData({ processed_calls: 0, sentiment_count: 0, unplz_count: 0, tone_count: 0 });
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
        setLoading(false);
      });
  };
  const getDefaultAnalysisData = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let url = `/secure/default_collection_cards_comparison?date=${defaultDate.value}&flag=${toggleValue}`;

    if (userDetails.role === 'Customer_Admin') {
      url += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }

    await API.get(url, config)
      .then((res) => {
        setAnalysisData(res.data);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAnalysisData();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
        setLoading(false);
      });
  };
  const getDefaultCategoryData = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    // let url = `/secure/default_keywords_graph?customer_id=529&start_date=2023-05-1&end_date=2023-05-3`;
    let url = `/secure/default_keywords_graph?date=${defaultDate.value}&flag=${toggleValue}`;

    if (userDetails.role === 'Customer_Admin') {
      url += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    await API.get(url, config)
      .then((res) => {
        // removes the object with no data
        const data = res?.data?.dates;

        Object.keys(data).forEach((key) => {
          if (Object.keys(data[key]).length === 0) {
            delete data[key];
          }
        });

        if (Object.keys(data).length === 0) {
          setDefaultCategoryData();
        } else {
          setDefaultCategoryData(data);
        }

        // console.log('Object.values(res.data.dates)[0]', Object.values(res.data.dates));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDefaultCategoryData();
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
        setAlert(true);
        setLoading(false);
      });
  };
  const getDefaultCollectionData = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    // let url = `/secure/default_keywords_graph?customer_id=529&start_date=2023-05-1&end_date=2023-05-3`;
    let url = `/secure/collection_categories?date=${defaultDate.value}&flag=All`;

    if (userDetails.role === 'Customer_Admin') {
      url += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    await API.get(url, config)
      .then((res) => {
        // removes the object with no data
        const data = res?.data?.collection_category_count;

        if (Object.keys(data).length === 0) {
          setCollectionData();
        } else {
          setCollectionData(data);
        }

        console.log('datadata', data);

        // console.log('Object.values(res.data.dates)[0]', Object.values(res.data.dates));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setCollectionData();
        // setAlertContent(`${err.response?.data.message}`);
        // setAlertType('error');
        // setAlert(true);
        setLoading(false);
      });
  };
  const getDefaultQRCData = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    // let url = `/secure/default_classification_graph?start_date=2023-12-1&end_date=2024-01-18`;
    let url = `/secure/disposition_upto_level_1?date=${defaultDate.value}&flag=All`;

    if (userDetails.role === 'Customer_Admin') {
      url += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    await API.get(url, config)
      .then((res) => {
        if (selectedCallType.value === 'all') {
          const outputData = Object.entries(res.data.classification_graph).map(
            ([date, values]) => ({
              date: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
              ...values
              // date: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
              // Query: (values.Query / (values.Query + values.Request + values.Complaint)) * 100 || 0,
              // Request:
              //   (values.Request / (values.Query + values.Request + values.Complaint)) * 100 || 0,
              // Complaint:
              //   (values.Complaint / (values.Query + values.Request + values.Complaint)) * 100 || 0
            })
          );

          const filteredArray = outputData.filter((item) => {
            // Get an array of all values from the item
            const values = Object.values(item);
            // Filter out the item if all values are zero
            return !values.every((value) => value === 0);
          });

          // console.log('outputData', filteredArray);

          const summaryQRC = outputData.reduce((acc, entry) => {
            Object.entries(entry).forEach(([key, value]) => {
              if (key !== 'date') {
                acc[key] = (acc[key] || 0) + value;
              }
            });
            return acc;
          }, {});
          setOverallQRCData(summaryQRC);
          setQRCData(filteredArray);
          setCallCategories(Object.keys(summaryQRC));
        } else if (selectedCallType.value !== 'all') {
          const outputData =
            Object.keys(res.data.classification_graph).length > 0
              ? Object.entries(res.data.classification_graph).map(([date, data]) => ({
                  date: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                  ...data
                }))
              : [];
          // console.log('outputData', outputData);
          const summaryQRC = outputData.reduce((acc, entry) => {
            Object.entries(entry).forEach(([key, value]) => {
              if (key !== 'date') {
                acc[key] = (acc[key] || 0) + value;
              }
            });
            return acc;
          }, {});
          setOverallQRCData(summaryQRC);
          setQRCData(outputData);
          setCallCategories(Object.keys(summaryQRC));
        }
        setLoading(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setQRCData([]);
        setOverallQRCData();
        setAlertContent(`${err.response?.data.message}`);
        setAlertType('error');
        setAlert(true);
        setLoading(false);
      });
  };
  const getDefaultQRCCountData = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    // let url = `/secure/default_classification_graph?start_date=2023-12-1&end_date=2024-01-18`;
    let url = `/secure/qrc_call_type_count?date=${defaultDate.value}&flag=All`;

    if (userDetails.role === 'Customer_Admin') {
      url += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    await API.get(url, config)
      .then((res) => {
        const output = res.data?.qrc_call_type_data.reduce((acc, item) => {
          if (item.ai_score !== 0 || item.manual_score !== 0) {
            acc.push({
              name: item.category,
              ai_average_score: parseFloat(item.ai_score.toFixed(2)), // Round ai_score to 2 decimal places
              average_manual_score: parseFloat(item.manual_score.toFixed(2)) // Round manual_score to 2 decimal places
            });
          }
          return acc;
        }, []);
        // const output = res.data?.qrc_call_type_data.map((item) => ({
        //   name: item.category,
        //   AI_Average_Score: parseFloat(item.ai_score.toFixed(2)),
        //   Manual_Average_Score: parseFloat(item.manual_score.toFixed(2))
        // }));
        setQRCChartData(output);
        setQRCTableData(res.data.qrc_call_type_data);
        // console.log('QRCData', output);
        setLoading(false);
      })
      .catch((err) => {
        setQRCChartData([]);
        setQRCTableData([]);
        console.log(err);

        setLoading(false);
      });
  };
  // <----------------------------------------------------------->

  useEffect(() => {
    // if (!defaultDashboardData && !toneData && !categoryData && !sentimentData) {
    // getDefaultDashboard();
    getOrganisationList();
    // if (
    //   !locationData.state ||
    //   locationData.state.initialData?.formikValues.organisation.value === 'all' ||
    //   locationData.state.initialData?.formikValues.customer.value === 'all' ||
    //   locationData.state.initialData === null
    // ) {
    // getNewDefaultDashboard();
    // handleCategoryAPI();
    if (!toneData && !categoryData && !sentimentData && formik.values.agent.length === 0) {
      // if (process.env.REACT_APP_TO_SHOW_QRC === 'true') {
      //   getDefaultDispositionList();
      // }
      if (tabValue === '3') {
        getDefaultCategoryData();
      }
      if (tabValue === '4') {
        getDefaultCollectionData();
      }
      if (tabValue === '1') {
        getDefaultSentimentData();
        getDefaultDashboard();
        getDefaultCardData();
        // getDefaultAnalysisData();
      }
      if (tabValue === '2') {
        getDefaultQRCData();
        getDefaultQRCCountData();
      }
    } else {
      handleAllAPI();
      setInitialData(selectedCallType);
    }
    // }
  }, [toggleValue, tabValue]);
  // }, [toggleValue, setPresentDate]);
  // }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      // `/secure/list_of_customer?organisation_id=${formik.values.organisation}&services=["collections"]`,
      `/secure/dashboard_customer?organisation_id=${formik.values.organisation}&services=collections`,
      config
    )
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log('customer List Api called');
    if (formik.values.organisation !== '') {
      if (formik.values.organisation !== 'all') {
        getCustomerList();
      } else {
        setcustomerList([]);
      }
    } else {
      setcustomerList([]);
    }
  }, [formik.values.organisation]);

  const getProcessList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `/secure/process_list?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisation}`,
        config
      );
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDispositionList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/disposition_category_subcategory_info?customer_id=${formik.values.customer}&user_id=${formik.values.agent}&date=${defaultDate.value}&processes=${formik.values.process}&org_id=${formik.values.organisation}&flag=Not All`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    function getDisposition() {
      return API.get(url, config);
    }
    Promise.all([getDisposition()])
      .then(([dispositionData]) => {
        // Extract all lists from the object
        const allLists = Object.values(dispositionData.data.intent_list);
        setIntentType(Object.keys(dispositionData.data.intent_list));
        // Flatten the array and remove duplicates
        const mergedList = [...new Set(allLists.flat())];
        // mergedList.unshift('All');
        setDispositionList(mergedList);
        // setDispositionType('All');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDefaultDispositionList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/get_category_subcategory_info?date=one_week&flag=All`;
    if (userDetails.role === 'Customer_Admin') {
      url += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      url += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    function getDisposition() {
      return API.get(url, config);
    }
    Promise.all([getDisposition()])
      .then(([dispositionData]) => {
        // Extract all lists from the object
        const allLists = Object.values(dispositionData.data.intent_list);

        // Flatten the array and remove duplicates
        const mergedList = [...new Set(allLists.flat())];
        // mergedList.unshift('All');
        setDispositionList(mergedList);
        // setDispositionType('All');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.customer !== null) {
      if (formik.values.customer !== 'all') {
        getProcessList();
      } else {
        setprocessList([]);
        // setDispositionList([]);
      }
    } else {
      setprocessList([]);
      // setDispositionList([]);
    }
  }, [formik.values.customer]);

  //  download csv file
  const [chartDownload, setchartDownload] = useState('');
  const downloadChart = async (params) => {
    setDownloadStart(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/download_a_graph_data?customer_id=${formik.values.customer}&processes=${formik.values.process}&date=${defaultDate.value}&user_id=${formik.values.agent}&org_id=${formik.values.organisation}&chart_type=${params}&flag=${toggleValue}`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(url, config)
      .then((res) => {
        setchartDownload(params);
        setTaskId(res.data.task_id);
        setDownloadStart(false);
        setAlertContent(`Success: ${res.data.message}`);
        setAlertType('success');
        setAlert(true);
        // saveAs(`${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.final_csv}`, 'csvFile.xlsx');
        // console.log('data', res.data);
      })
      .catch((err) => {
        setDownloadStart(false);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
        console.log(err);
      });
  };

  // download csv for overall
  const [downloadStart, setDownloadStart] = React.useState(false);
  const [taskId, setTaskId] = React.useState();

  const downloadCsv = async () => {
    setDownloadStart(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let url = `/secure/download_csv_to_local?customer_id=${formik.values.customer}&processes=${formik.values.process}&user_id=${formik.values.agent}&date=${defaultDate.value}&org_id=${formik.values.organisation}&flag=${toggleValue}`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    await API.get(url, config)
      .then((res) => {
        setchartDownload('');
        setTaskId(res.data.task_id);
        setDownloadStart(false);
        setAlertContent(`Success: ${res.data.message}`);
        setAlertType('success');
        setAlert(true);
        // saveAs(`${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.final_csv}`, 'csvFile.xlsx');
        // console.log('data', res.data);
      })
      .catch((err) => {
        setDownloadStart(false);
        setAlertContent(`${err.response.data.message}`);
        setAlertType('error');
        setAlert(true);
      });
  };

  const downloadFile = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const url = `/secure/check_async_task?task_id=${taskId}`;

    try {
      const res = await API.get(url, config);
      if (res.data.message === 'Downloaded Successfully!') {
        if (chartDownload === 'unpleasant_graph') {
          saveAs(
            `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.file_path}`,
            'unpleasant_graph_data.xlsx'
          );
        } else if (chartDownload === 'dead_air_graph') {
          saveAs(
            `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.file_path}`,
            'dead_air_graph_data.xlsx'
          );
        } else if (chartDownload === 'tone_graph') {
          saveAs(
            `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.file_path}`,
            'tone_graph_data.xlsx'
          );
        } else if (chartDownload === 'sentiment_graph') {
          saveAs(
            `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.file_path}`,
            'sentiment_graph_data.xlsx'
          );
        } else if (chartDownload === 'keywords') {
          saveAs(
            `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.file_path}`,
            'category_details.xlsx'
          );
        } else if (chartDownload === 'keywords_category') {
          saveAs(
            `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.file_path}`,
            'keywords_category_details'
          );
        } else {
          saveAs(
            `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.file_path}`,
            'call_analysis_report.xlsx'
          );
        }
        // saveAs(
        //   `${process.env.REACT_APP_SERVER_MEDIA_URL}${res.data.file_path}`,
        //   'graph_details.csv'
        // );
        setDownloadStart(false);
        setAlertContent(`Success: ${res.data.message}`);
        setAlertType('success');
        setAlert(true);
      } else {
        // If the message is not 'Download Successfully', make another request
        setTimeout(downloadFile, 2000); // You can adjust the delay between requests
      }
    } catch (err) {
      setDownloadStart(false);
      console.log('error----', err);
      setAlertContent(`${err.response.data.message}`);
      setAlertType('error');
      setAlert(true);
    }
  };
  const downloadGraphPopupDetails = async () => {
    FileSaver.saveAs(
      `${process.env.REACT_APP_SERVER_MEDIA_URL}${downloadPath}`,
      downloadPath.split('/').pop()
    );
  };

  useEffect(() => {
    if (downloadStart) {
      downloadFile();
    }
  }, [taskId]);

  // ---------------------------------------------------------------

  const getAgentList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getRole() {
      return API.get(
        `secure/list_of_agent?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisation}&processes=${formik.values.process}`,
        config
      );
    }
    Promise.all([getRole()])
      .then(([agentData]) => {
        setagentList(agentData.data.agent_list);
        createFilterOptions(agentData.data.agent_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [output, setoutput] = React.useState(['all']);

  const handleChange = (event) => {
    setoutput(event.target.value);
    formik.setFieldValue('agent', event.target.value);
  };
  // console.log(formik.values.agent, 'formik.values.agent');
  useEffect(() => {
    // console.log('output ---------', output);
    setSelected(output);
  }, [output]);
  // select multiple agents
  const createFilterOptions = (value) => {
    const arr = [];
    const arr2 = [];
    value.map((process) => {
      // console.log('value', value);
      const label1 = [`${process.first_name} ${process.last_name}`];
      const arr1 = [process.user_id];
      arr.push(arr1);
      arr2.push(label1);

      return process;
    });
    // arr.push({ value: '', label: 'All agent' });

    let allno = '';

    arr.forEach((el, i) => {
      if (i !== 0) allno += `,${el}`;
      else allno += `${el}`;
    });
    arr.unshift([allno]);
    setoptions(arr);
    if (allno.length > 1) {
      arr2.unshift('Select All');
      setLabel(arr2);
    }
  };

  useEffect(() => {
    if (formik.values.process.length > 0) {
      if (formik.values.process !== 'all') {
        getAgentList();
      } else {
        setagentList([]);
      }
    } else {
      setagentList([]);
    }
  }, [formik.values.process]);

  // useEffect(() => {
  //   if (agentList) {
  //     const obj = {
  //       user_id: 'none',
  //       first_name: 'All',
  //       last_name: 'Agent'
  //     };
  //     const arr = agentList;
  //     arr.push(obj);
  //     setnewAgentList(arr);
  //   }
  // }, [agentList]);

  const handleProcess = (processValue) => {
    // console.log('processvalue -----', processValue);
    formik.setFieldValue('process', processValue);
    // setnewAgentSelect([]);
    // setoutput([]);
    // formik.setFieldValue('agent', null);
  };

  // console.log('formik.values.process', formik.values.process);

  const setCustomerValue = (value) => {
    formik.setFieldValue('customer', value);
    setprocessList([]);
  };

  const handleDialog = () => {
    console.log('dialogBox---', open);
    setOpen(!open);
    setPageForPopup(0);
  };
  const handleCategoryDialog = () => {
    setCategoryDetailsOpen(!categoryDetailsOpen);
    setPageForPopup(0);
  };
  const handleDialogForSummary = () => {
    setKeywordsDetailsOpen(!keywordDetailsOpen);
  };
  const handleDialogForCollection = () => {
    setCollectionPopupOpen(!collectionPopupOpen);
  };
  const handleDialogForQRCList = () => {
    setOpenQRC(!QRCOpen);
  };
  const handleSubscription = () => {
    setSubscriptionOpen(!subscriptionOpen);
    console.log('first');
  };
  const handleKeywordListPopup = () => {
    setPageForPopup(0);
    setKeyWordlistOpen(!keyWordlistOpen);
  };

  // <----------------------------------------------------------->
  // dummy data for subscription model
  const dummySubscription = [
    {
      org_name: 'ConnectCorp',
      customer_name: 'Accenture',
      process_count: '10',
      processed_time: '60',
      rem_time: '440',
      allocated_time: '500'
    },
    {
      org_name: 'ConnectCorp',
      customer_name: 'Siemens',
      process_count: '10',
      processed_time: '60',
      rem_time: '440',
      allocated_time: '500'
    },
    {
      org_name: 'shyenaTechTest',
      customer_name: 'Tata',
      process_count: '10',
      processed_time: '60',
      rem_time: '440',
      allocated_time: '500'
    },
    {
      org_name: 'Shyena',
      customer_name: 'Cogni',
      process_count: '10',
      processed_time: '60',
      rem_time: '440',
      allocated_time: '500'
    },
    {
      org_name: 'Shyena',
      customer_name: 'Tcs',
      process_count: '10',
      processed_time: '60',
      rem_time: '440',
      allocated_time: '500'
    },
    {
      org_name: 'ConnectCorp',
      customer_name: 'Infy',
      process_count: '10',
      processed_time: '60',
      rem_time: '440',
      allocated_time: '500'
    },
    {
      org_name: 'STY',
      customer_name: 'Infy',
      process_count: '10',
      processed_time: '60',
      rem_time: '440',
      allocated_time: '500'
    }
  ];
  // const handleDashboardTone = async () => {
  //   const token = localStorage.getItem('token');
  //   const config = {
  //     headers: { Authorization: `Bearer ${token}` }
  //   };
  //   await API.get('/secure/list_user', config)
  //     .then((res) => {
  //       setuserList(res.data);
  //       setAlertContent(`Success: User list fetch successfully.`);
  //       setAlertType('success');
  //       setAlert(true);
  //     })
  //     .then(() => {
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setAlertContent(`${err}`);
  //       setAlertType('error');
  //       setAlert(true);
  //       setLoading(false);
  //     });
  // };

  // const handleDashboardUnpleasant = async () => {
  //   const token = localStorage.getItem('token');
  //   const config = {
  //     headers: { Authorization: `Bearer ${token}` }
  //   };
  //   await API.get('/secure/list_user', config)
  //     .then((res) => {
  //       setuserList(res.data);
  //       setAlertContent(`Success: User list fetch successfully.`);
  //       setAlertType('success');
  //       setAlert(true);
  //     })
  //     .then(() => {
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setAlertContent(`${err}`);
  //       setAlertType('error');
  //       setAlert(true);
  //       setLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   if (
  //     formik.values.organisation &&
  //     formik.values.customer &&
  //     // formik.values.agent &&
  //     formik.values.process &&
  //     formik.values.startDate &&
  //     formik.values.endDate
  //   ) {
  //     handleDashboardAPI();
  //     // handleDashboardTone();
  //     // handleDashboardUnpleasant()
  //   }
  // }, [formik.values.startDate, formik.values.endDate, formik.values.agent]);

  const setDateFormat = (date) => {
    const formatStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return formatStartDate;
  };

  const roundOff = (num, places) => {
    const x = 10 ** places;
    console.log('roundOff ---->', Math.round(num * x) / x);
    return Math.round(num * x) / x;
  };

  const handleCheckInDate = (date) => {
    console.log('Date-----', typeof Date());
    console.log('date-----', date);
    setCheckInDate(date);
    setCheckOutDate('');
    formik.setFieldValue('endDate', '');
    const start_date = setDateFormat(date);
    formik.setFieldValue('startDate', start_date);
    // setNewWeekBeforeDate(start_date);
  };

  const handleCheckOutDate = (date) => {
    setCheckOutDate(date);
    console.log('checkdatechange');
    const start_date = setDateFormat(date);
    formik.setFieldValue('endDate', start_date);
    // setNewPresentDate(start_date);
  };

  const CustomInput = React.forwardRef((props, ref) => (
    <div style={{ position: 'relative' }}>
      <input {...props} ref={ref} />
      <CalendarMonthIcon
        onClick={props.onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '10px',
          // pointerEvents: 'none',
          cursor: 'pointer',
          zIndex: 0
        }}
      />
    </div>
  ));

  // ------------------------------------------------------------------------

  const toneGraphData = (dummyTone) => {
    // const dates = Object.keys(dummyTone.dates);
    const filteredObject = Object.fromEntries(
      Object.entries(dummyTone.dates).filter(([date, data]) => Object.keys(data).length > 0)
    );
    const dates = Object.keys(filteredObject).filter(
      (item) =>
        // console.log('item ---', item);
        // console.log('dummyTones ---', dummyTone);
        // console.log('dummyTones[item]---', dummyTone.dates[item]);
        dummyTone.dates[item].total !== 0
    );
    const graphData = [];
    const arrOfObj = [];

    dates.forEach((date) => {
      const d1 = new Date(date);
      const newObj = {
        createdAt: d1,
        name: date
      };
      arrOfObj.push(newObj);
    });

    const sortObj = arrOfObj.sort((a, b) => a.createdAt - b.createdAt);

    sortObj.forEach((data) => {
      // console.log('data ----------', data);
      // console.log('dummyTone.dates[data.name]-----', dummyTone.dates[data.name].total);
      const graphObj = {
        dates: data.name,
        high: dummyTone.dates[data.name].high,
        medium: dummyTone.dates[data.name].medium,
        low: dummyTone.dates[data.name].low
        // data:
        //   dummyTone.dates[data.name].total === 0
        //     ? {
        //         total: 0
        //       }
        //     : dummyTone.dates[data.name],
        // total: dummyTone.dates[data.name].total ? dummyTone.dates[data.name].total : 0
      };
      graphData.push(graphObj);
    });
    // console.log('graphData for tone ---------', graphData);
    setgraphDataForTone(graphData);
  };
  // <--------------------------------------------------------->
  // chart data for default dashboard

  const defaultToneData = (dummyTone) => {
    // const dates = Object.keys(dummyTone.tone_graph);
    const filteredObject = Object.fromEntries(
      Object.entries(dummyTone.tone_graph).filter(([date, data]) => Object.keys(data).length > 0)
    );
    const dates = Object.keys(filteredObject).filter(
      (item) =>
        // console.log('item ---', item);
        // console.log('dummyTones ---', dummyTone);
        // console.log('dummyTones[item]---', dummyTone.dates[item]);
        dummyTone.tone_graph[item].total !== 0
    );
    const graphData = [];
    const arrOfObj = [];

    dates.forEach((date) => {
      const d1 = new Date(date);
      const newObj = {
        createdAt: d1,
        name: date
      };
      arrOfObj.push(newObj);
    });

    const sortObj = arrOfObj.sort((a, b) => a.createdAt - b.createdAt);

    sortObj.forEach((data) => {
      console.log('data ----------', data);
      // console.log('dummyTone.dates[data.name]-----', dummyTone.dates[data.name].total);
      const graphObj = {
        dates: data.name,
        high: dummyTone.tone_graph[data.name].high,
        medium: dummyTone.tone_graph[data.name].medium,
        low: dummyTone.tone_graph[data.name].low
      };
      graphData.push(graphObj);
    });
    // console.log('graphData for tone ---------', graphData);
    setgraphDataForTone(graphData);
  };

  // <-------------------------------------------------------->

  // const sortObj = arrOfObj.sort((a, b) => a.createdAt - b.createdAt);

  // sortObj.forEach((data) => {
  //   const graphObj = {
  //     dates: data.name.split('-').reverse().join('/'),
  //     data:
  //       dummySentiment.dates[data.name].TOTAL === 0
  //         ? {
  //             total: 0
  //           }
  //         : dummySentiment.dates[data.name],
  //     total: dummySentiment.dates[data.name].TOTAL ? dummySentiment.dates[data.name].TOTAL : 0
  //   };
  //   graphData.push(graphObj);
  // });
  // console.log('graphData for tone ---------', graphData);
  // setgraphDataForSentiment(graphData);

  const sentimentGraphData = (dummySentiment) => {
    const dates = Object.keys(dummySentiment.dates);
    const graphData = [];
    const arrOfObj = [];

    dates.forEach((date) => {
      const d1 = new Date(date);
      const newObj = {
        createdAt: d1,
        name: date
      };
      arrOfObj.push(newObj);
    });

    const sortObj = arrOfObj.sort((a, b) => a.createdAt - b.createdAt);

    sortObj.forEach((data) => {
      const graphObj = {
        dates: data.name.split('-').reverse().join('/'),
        data:
          dummySentiment.dates[data.name].TOTAL === 0
            ? {
                total: 0
              }
            : dummySentiment.dates[data.name],
        total: dummySentiment.dates[data.name].TOTAL ? dummySentiment.dates[data.name].TOTAL : 0
      };
      graphData.push(graphObj);
    });
    console.log('graphData for tone ---------', graphData);
    setgraphDataForSentiment(graphData);
  };
  // <---------------------------------------------------------------------------->

  // <-------------------------------------------------------->

  // <---------------------------------------------------------------------------->

  const unpleasantGraphData = (dummyTone) => {
    const dates = Object.keys(dummyTone.dates).filter(
      (item) =>
        // console.log('item ---', item);
        // console.log('dummyTones ---', dummyTone);
        // console.log('dummyTones[item]---', dummyTone.dates[item]);
        dummyTone.dates[item].total !== 0
    );
    const graphData = [];
    const arrOfObj = [];

    dates.forEach((date) => {
      const d1 = new Date(date);
      const newObj = {
        createdAt: d1,
        name: date
      };
      arrOfObj.push(newObj);
    });

    const sortObj = arrOfObj.sort((a, b) => a.createdAt - b.createdAt);

    sortObj.forEach((data) => {
      const graphObj = {
        dates: moment(data.name, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        data:
          dummyTone.dates[data.name] === 0
            ? {
                total: 0
              }
            : dummyTone.dates[data.name],
        total: dummyTone.dates[data.name].total ? dummyTone.dates[data.name].total : 0
      };
      graphData.push(graphObj);
    });
    console.log('graphData for unpleasant word ----', graphData);
    setgraphDataForUnpleasant(graphData);
  };

  // <------------------------------------------------------>
  // deault unpleasant data formating
  const defaultUnpleasantData = (unpleasant) => {
    const dates = Object.keys(unpleasant.unpleasant_graph).filter(
      (item) =>
        // console.log('item ---', item);
        // console.log('dummyTones ---', dummyTone);
        // console.log('dummyTones[item]---', dummyTone.dates[item]);
        unpleasant.unpleasant_graph[item].total !== 0
    );
    const graphData = [];
    const arrOfObj = [];

    dates.forEach((date) => {
      const d1 = new Date(date);
      const newObj = {
        createdAt: d1,
        name: date
      };
      arrOfObj.push(newObj);
    });

    const sortObj = arrOfObj.sort((a, b) => a.createdAt - b.createdAt);

    sortObj.forEach((data) => {
      const graphObj = {
        dates: moment(data.name, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        data:
          unpleasant.unpleasant_graph[data.name] === 0
            ? {
                total: 0
              }
            : unpleasant.unpleasant_graph[data.name],
        total: unpleasant.unpleasant_graph[data.name].total
          ? unpleasant.unpleasant_graph[data.name].total
          : 0
      };
      graphData.push(graphObj);
    });
    console.log('graphData for unpleasant word ----', graphData);
    setgraphDataForUnpleasant(graphData);
  };

  const deadAirGraphData = (dummyTone) => {
    // const dates = Object.keys(dummyTone.dates);
    const dates = Object.keys(dummyTone.dates).filter(
      (item) =>
        // console.log('item ---', item);
        // console.log('dummyTones ---', dummyTone);
        // console.log('dummyTones[item]---', dummyTone.dates[item]);
        dummyTone.dates[item].total !== 0
    );
    // console.log(dates, 'dates...');
    const graphData = [];
    const arrOfObj = [];

    dates.forEach((date) => {
      const d1 = new Date(date);
      const newObj = {
        createdAt: d1,
        name: date
      };
      arrOfObj.push(newObj);
    });

    const sortObj = arrOfObj.sort((a, b) => a.createdAt - b.createdAt);

    sortObj.forEach((data) => {
      // console.log('data ----------', data);
      // console.log('dummyTone.dates[data.name]-----', dummyTone.dates[data.name].total);
      const graphObj = {
        dates: moment(data.name, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        data:
          dummyTone.dates[data.name].total === 0
            ? {
                total: 0
              }
            : dummyTone.dates[data.name],
        total: dummyTone.dates[data.name].total ? dummyTone.dates[data.name].total : 0
      };
      graphData.push(graphObj);
    });
    // console.log('graphData for deadAir ----', graphData);
    setgraphDataForDeadAir(graphData);
  };

  // default dead Air data formatting
  const defaultDeadAirData = (dummyTone) => {
    // const dates = Object.keys(dummyTone.dead_air_graph);
    const dates = Object.keys(dummyTone.dead_air_graph).filter(
      (item) =>
        // console.log('item ---', item);
        // console.log('dummyTones ---', dummyTone);
        // console.log('dummyTones[item]---', dummyTone.dates[item]);
        dummyTone.dead_air_graph[item].total !== 0
    );
    // console.log(dates, 'dates...');
    const graphData = [];
    const arrOfObj = [];

    dates.forEach((date) => {
      const d1 = new Date(date);
      const newObj = {
        createdAt: d1,
        name: date
      };
      arrOfObj.push(newObj);
    });

    const sortObj = arrOfObj.sort((a, b) => a.createdAt - b.createdAt);

    sortObj.forEach((data) => {
      // console.log('data ----------', data);
      // console.log('dummyTone.dates[data.name]-----', dummyTone.dates[data.name].total);
      const graphObj = {
        dates: moment(data.name, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        data:
          dummyTone.dead_air_graph[data.name].total === 0
            ? {
                total: 0
              }
            : dummyTone.dead_air_graph[data.name],
        total: dummyTone.dead_air_graph[data.name].total
          ? dummyTone.dead_air_graph[data.name].total
          : 0
      };
      graphData.push(graphObj);
    });
    // console.log('graphData for deadAir ----', graphData);
    setgraphDataForDeadAir(graphData);
  };

  useEffect(() => {
    if (toneData) {
      toneGraphData(toneData);
    }
  }, [toneData]);
  // <-------------------------------------------------->
  // useeffect for default tone data generation
  useEffect(() => {
    if (defaultDashboardData) {
      defaultToneData(defaultDashboardData);
      // defaultUnpleasantData(defaultDashboardData);
      // defaultDeadAirData(defaultDashboardData);
    }
  }, [defaultDashboardData]);

  useEffect(() => {
    if (unpleasantData) {
      unpleasantGraphData(unpleasantData);
    }
  }, [unpleasantData]);

  useEffect(() => {
    if (deadAir) {
      deadAirGraphData(deadAir);
    }
  }, [deadAir]);

  // useEffect(() => {
  //   if (sentimentData) {
  //     sentimentGraphData(sentimentData);
  //   }
  // }, [sentimentData]);

  // ------------------------------------------------------------------------
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleAlert = () => {
    setAlert(false);
  };

  // useEffect(() => {
  //   if (filteredUnpleasant) {
  //     const newWords = [];
  //     filteredUnpleasant.map((element) => {
  //       console.log('mapping unpleasant ----', element);
  //       console.log('element.wordcount', element.word_count);
  //       // console.log('first', element.word_count);
  //       if (element.word_count) {
  //         newWords.push(element.word_count);
  //       } else {
  //         newWords.push({});
  //       }
  //       console.log(element);
  //       return element;
  //     });
  //     setWords(newWords);
  //   }
  // }, [filteredUnpleasant]);

  // if (filteredUnpleasant && date) {
  //   setHeaderName('Unpleasant Report');
  // } else if (filteredTone && toneDate) {
  //   setHeaderName('Tone Details');
  // }

  const {
    setinputId,
    setinputOrgId,
    setrequestId,
    setUploadedAudio,
    setAudioS3,
    setProcess,
    setcustomerId,
    setOriginalDate,
    setAgentName,
    setAgentLastName,
    setAgentId
  } = useContext(inputContext);
  // multiselect agent new dummy
  // ----------------------------------------------------------
  const [newAgentSelect, setnewAgentSelect] = useState(
    locationData.state?.initialData ? locationData.state.initialData.formikValues.agent : ['All']
  );
  const useStyles = makeStyles((theme) => ({
    ul: {
      '& .MuiPaginationItem-root': {
        '&.Mui-selected': {
          background: '#3892CF',
          color: 'white'
          // borderRadius: '50%',
        }
      }
    },
    formControl: {
      // margin: theme.spacing(1),
      width: 200
    },
    indeterminateColor: {
      color: '#f50057'
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    submitButton: {
      width: '100%',
      backgroundColor: '#3892CF',
      '&:hover': {
        backgroundColor: '#3892CF'
      }
      // [theme.breakpoints.down('md')]: {
      //   marginTop: '21px'
      // }
    },
    menuPaper: {
      maxHeight: 200
    }
    // cards: {
    //   [theme.breakpoints.down('md')]: {
    //     padding: 10
    //   },
    //   [theme.breakpoints.down('sm')]: {
    //     padding: 20
    //   }
    // }
  }));
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 198
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    variant: 'menu'
  };
  const classes = useStyles();
  const newAgent = agentList && agentList.map((el) => el.user_id);
  const firstName = agentList && agentList.map((el) => el.first_name);
  // console.log(selectDashboard.indexOf(newDash), 'jhhk');
  const agentAllSelected = newAgent?.length > 0 && newAgentSelect?.length === newAgent?.length;
  // const handleAgentChange = (event) => {
  //   console.log('event--------', event);
  //   const { value } = event.target;
  //   if (value[value.length - 1] === 'all') {
  //     // setnewAgentSelect(newAgentSelect.length === newAgent.length ? [] : firstName);
  //     setnewAgentSelect(newAgentSelect.length === newAgent.length ? [] : newAgent);
  //     formik.setFieldValue('agent', newAgentSelect.length === newAgent.length ? [] : newAgent);
  //     return;
  //   }
  //   // const newVal = [];
  //   // const newName = [];
  //   // value.map((el) => {
  //   //   newVal.push(el.id);
  //   //   newName.push(el.name);
  //   //   // setnewAgentSelect(newVal);
  //   //   return newVal;
  //   // });
  //   formik.setFieldValue('agent', value);
  //   setnewAgentSelect(value);
  //   console.log('agentValue----', value);
  // };
  const [selectedAgents, setSelectedAgents] = useState(
    locationData.state?.initialData ? locationData.state.initialData.formikValues.agent : []
  );
  const [selectedCallType, setSelectedCallType] = useState({ value: 'all', label: 'All' });
  // const [callTypeList, setCallTypeList] = useState([{ value: 'all', label: 'All' }]);
  const [callCategories, setCallCategories] = useState([]);

  // const callCategories =
  //   selectedCallType.value === 'all'
  //     ? ['Query', 'Request', 'Complaint']
  //     : ['Credit Card', 'Debit Card', 'Bank Saving', 'Loan'];
  const [processName, setProcessName] = useState(
    locationData.state?.initialData ? locationData.state.initialData.formikValues.process : []
  );
  const [customerName, setCustomerName] = useState(
    userDetails.role === 'QA' ||
      userDetails.role === 'Customer_Admin' ||
      userDetails.role === 'QA_admin'
      ? userDetails.customer_id
      : locationData.state?.initialData
      ? locationData.state.initialData.formikValues.customer
      : { value: 'all', label: 'All' }
  );
  const [organisationName, setOrganisationName] = useState(
    userDetails.role !== 'Super_Admin'
      ? userDetails.organisation_id
      : locationData.state?.initialData
      ? locationData.state.initialData.formikValues.organisation
      : { value: 'all', label: 'All' }
  );

  const setAllEmpty = (e) => {
    console.log('organisation', e.value);
    formik.setFieldValue('organisation', e.value);
    setOrganisationName(e);
    setCustomerName(null);
    setProcessName([]);
    setSelectedAgents([]);
    setcustomerList([]);
    setprocessList([]);
    formik.setFieldValue('process', []);
    formik.setFieldValue('agent', []);
    formik.setFieldValue('customer', null);
  };

  // useEffect(() => {
  //   if (selectedAgents.length > 0) {
  //     setCallTypeList([
  //       { value: 'all', label: 'All' },
  //       { value: 'query', label: 'Query' },
  //       { value: 'request', label: 'Request' },
  //       { value: 'complaint', label: 'Complaint' }
  //     ]);
  //   } else setCallTypeList([{ value: 'all', label: 'All' }]);
  // }, [selectedAgents]);

  const handleAgentChange = (selectedOptions) => {
    // console.log('selectedOptions', selectedOptions);
    if (selectedOptions.some((option) => option.value === 'all')) {
      const userIds = agentList.map((element) => {
        // console.log('element', element);
        return element.user_id;
      });
      const userIdsString = userIds.join(',');
      let numbersArray = userIdsString.split(',').map(Number);
      if (userIdsString === '') {
        numbersArray = [];
      }
      // console.log('numbersArrayone', numbersArray);
      // console.log('agentList', agentList);
      formik.setFieldValue('agent', numbersArray);
      setSelectedAgents(
        agentList.map((element) => ({
          value: element.user_id,
          label: `${element.first_name} ${element.last_name}`
        }))
      );
    } else {
      const userIds = selectedOptions.map((element) => {
        // console.log('element', element);
        return element.value;
      });
      // console.log('userIds', userIds);
      const userIdsString = userIds.join(',');
      // console.log('userIdsString', userIdsString);
      // console.log('selectedOptions', selectedOptions);
      let numbersArray = userIdsString.split(',').map(Number);
      if (selectedOptions.length === 0) {
        numbersArray = [];
      }
      // console.log('numbersArray', numbersArray);

      formik.setFieldValue('agent', numbersArray);

      setSelectedAgents(selectedOptions);
    }
  };
  const handleProcessChange = (selectedOptions) => {
    // console.log('selectedOptions', selectedOptions);
    setSelectedAgents([]);
    formik.setFieldValue('agent', []);
    if (selectedOptions.some((option) => option.value === 'all')) {
      // const processes = processList.map((element) => {
      //   // console.log('element', element);
      //   return element;
      // });
      // const processString = processes.join(',');
      // let processArray = processString.split(',').map(Number);
      // if (processString === '') {
      //   processArray = [];
      // }
      // console.log('numbersArrayone', numbersArray);
      // console.log('agentList', agentList);
      formik.setFieldValue('process', processList);
      setProcessName(
        processList.map((element) => ({
          value: element,
          label: element
        }))
      );
    } else {
      const processes = selectedOptions.map((element) => {
        // console.log('element', element);
        return element.value;
      });
      // console.log('userIds', userIds);
      // const processString = processes.join(',');
      // // console.log('userIdsString', userIdsString);
      // // console.log('selectedOptions', selectedOptions);
      // let processArray = processString.split(',').map(Number);
      // if (selectedOptions.length === 0) {
      //   processArray = [];
      // }
      // console.log('numbersArray', numbersArray);

      formik.setFieldValue('process', processes);

      setProcessName(selectedOptions);
    }
  };
  console.log('formik', formik.values);

  // ----------------------------------------------------------

  const customStyles = {
    option: (base, state) => ({
      ...base,
      fontWeight: state.isSelected ? '500' : 'normal'
    }),
    control: (base, state) => ({
      ...base,
      fontWeight: '500',
      border: 'none',
      maxHeight: 35
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: '2px',
      maxHeight: 38,
      overflow: 'auto'
    })
  };

  const CustomControlOrg = ({ children, ...rest }) => {
    return (
      <components.Control {...rest}>
        <CorporateFareIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
        {children}
      </components.Control>
    );
  };
  const CustomControlCustomer = ({ children, ...rest }) => {
    return (
      <components.Control {...rest}>
        <PortraitIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
        {children}
      </components.Control>
    );
  };
  const CustomControlProcess = ({ children, ...rest }) => {
    return (
      <components.Control {...rest}>
        <LoopIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
        {children}
      </components.Control>
    );
  };
  const CustomControlAgent = ({ children, ...rest }) => {
    return (
      <components.Control
        style={{
          border: 'green'
        }}
        {...rest}
      >
        <SupportAgentIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
        {children}
      </components.Control>
    );
  };
  const CustomControlDate = ({ children, ...rest }) => {
    return (
      <components.Control {...rest}>
        <CalendarMonthIcon style={{ marginLeft: '5px', marginRight: '5px' }} />
        {children}
      </components.Control>
    );
  };

  const handleToggle = (e) => {
    setToggleValue(e.target.value);
  };
  // console.log('toneDataSentDataCatData', toneData, sentimentData, categoryData);
  function formatNumber(num) {
    if (num >= 1e9) {
      return (
        <Tip title={num} followCursor placement="top">
          <Typography
            style={{
              color: '#000',
              fontStyle: 'bold',
              fontSize: '2.4rem'
            }}
          >
            {(num / 1e9).toFixed(1) + 'B'}
          </Typography>
        </Tip>
      );
    } else if (num >= 1e6) {
      return (
        <Tip title={num} followCursor placement="top">
          <Typography
            style={{
              color: '#000',
              fontStyle: 'bold',
              fontSize: '2.4rem'
            }}
          >
            {(num / 1e6).toFixed(1) + 'M'}
          </Typography>
        </Tip>
      );
    } else if (num >= 1e3) {
      return (
        <Tip title={num} followCursor placement="top">
          <Typography
            style={{
              color: '#000',
              fontStyle: 'bold',
              fontSize: '2.4rem'
            }}
          >
            {(num / 1e3).toFixed(1) + 'K'}
          </Typography>
        </Tip>
      );
    } else {
      return num.toString();
    }
  }
  // const optionsForBucket = {
  //   chart: {
  //     height: 280,
  //     type: 'radialBar'
  //   },
  //   plotOptions: {
  //     radialBar: {
  //       dataLabels: {
  //         total: {
  //           show: false,
  //           label: 'TOTAL'
  //         }
  //       }
  //     }
  //   },
  //   labels: ['Query', 'Request', 'Complaint']
  // };
  // const seriesForBucket = OverallQRCData
  //   ? [
  //       OverallQRCData.Query.toFixed(2),
  //       OverallQRCData.Request.toFixed(2),
  //       OverallQRCData.Complaint.toFixed(2)
  //     ]
  //   : [];

  // const pieChartData = OverallQRCData
  //   ? [
  //       { name: 'Query', value: OverallQRCData.Query },
  //       { name: 'Request', value: OverallQRCData.Request },
  //       { name: 'Complaint', value: OverallQRCData.Complaint }
  //     ]
  //   : [];

  // const dummyQRC = {
  //   '2024-01-01': {
  //     'Credit Card Pin Generation': 3,
  //     'Credit Card Queries': 1,
  //     'Credit Card Hosting': 2
  //   },
  //   '2024-01-02': {
  //     'Credit Card Pin Generation': 4,
  //     'Credit Card Queries': 0,
  //     'Credit Card Hosting': 0
  //   },
  //   '2024-01-03': {
  //     'Credit Card Pin Generation': 2,
  //     'Credit Card Queries': 1,
  //     'Credit Card Hosting': 1
  //   },
  //   '2024-01-04': {
  //     'Credit Card Pin Generation': 2,
  //     'Credit Card Queries': 3,
  //     'Credit Card Hosting': 3
  //   },
  //   '2024-01-05': {
  //     'Credit Card Pin Generation': 4,
  //     'Credit Card Queries': 0,
  //     'Credit Card Hosting': 3
  //   },
  //   '2024-01-06': {
  //     'Credit Card Pin Generation': 3,
  //     'Credit Card Queries': 2,
  //     'Credit Card Hosting': 0
  //   },
  //   '2024-01-08': {
  //     'Credit Card Pin Generation': 3,
  //     'Credit Card Queries': 1,
  //     'Credit Card Hosting': 2
  //   }
  // };
  // // Convert to overall category vs count
  // const overallCategoryCount = {};

  // Object.keys(dummyQRC).forEach((date) => {
  //   const categories = Object.keys(dummyQRC[date]);

  //   categories.forEach((category) => {
  //     if (!overallCategoryCount[category]) {
  //       overallCategoryCount[category] = 0;
  //     }

  //     overallCategoryCount[category] += dummyQRC[date][category];
  //   });
  // });
  // const overallDispositon = Object.keys(overallCategoryCount).map((category) => ({
  //   category,
  //   count: overallCategoryCount[category]
  // }));
  // console.log('overallCategoryCount', overallDispositon);
  // const updatedQRC = Object.entries(dummyQRC).map(([date, values]) => ({
  //   date: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
  //   ...values
  // }));
  // const summaryUpdateQRC = updatedQRC?.reduce((acc, entry) => {
  //   Object.entries(entry).forEach(([key, value]) => {
  //     if (key !== 'date') {
  //       acc[key] = (acc[key] || 0) + value;
  //     }
  //   });
  //   return acc;
  // }, {});

  // const dummyCategories = Object.keys(summaryUpdateQRC);

  const [pieChartData, setPieChartData] = useState();
  const [dispositionIndex, setDispositionIndex] = useState();

  const getDispositionWithIntent = async (entry, intentIndex) => {
    console.log('entry', entry);
    setLoading(true);
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    // const intentType =
    //   intentIndex === 0
    //     ? 'Query'
    //     : intentIndex === 1
    //     ? 'Request'
    //     : intentIndex === 2
    //     ? 'Complaint'
    //     : intentIndex === 'all'
    //     ? 'All'
    //     : null;

    let defaultUrl = `/secure/disposition_summary_level_2_or_3?date=${defaultDate.value}&intent_type=${entry.name}&&sub_category=${dispositionType}&flag=All`;
    let url = `/secure/disposition_summary_level_2_or_3?customer_id=${formik.values.customer}&user_id=${formik.values.agent}&date=${defaultDate.value}&processes=${formik.values.process}&org_id=${formik.values.organisation}&sub_category=${dispositionType}&intent_type=${entry.name}`;
    if (defaultDate.value === 'customized') {
      url += `&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}`;
    }
    if (userDetails.role === 'Customer_Admin') {
      defaultUrl += `&org_id=${userDetails.organisation_id}`;
    } else if (
      userDetails.role === 'QA' ||
      userDetails.role === 'Manager' ||
      userDetails.role === 'QA_admin'
    ) {
      defaultUrl += `&org_id=${userDetails.organisation_id}&customer_id=${userDetails.customer_id}`;
    }
    await API.get(
      !toneData &&
        !categoryData &&
        !sentimentData &&
        formik.values.agent.length === 0 &&
        dispositionList.length > 0 &&
        dispositionType &&
        process.env.REACT_APP_TO_SHOW_QRC === 'true'
        ? defaultUrl
        : url,
      config
    )
      .then((res) => {
        const { category_count } = res.data;
        const finalData = Object.keys(category_count).map((el) => {
          return { category: el, count: category_count[el] };
        });
        setNewSummaryDispositionGraph(finalData);
        setFilterForQRC(entry.name);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setNewSummaryDispositionGraph([]);
        setLoading(false);
      });
  };

  function getPieChart(data) {
    const chartData = [];
    Object.keys(data).map((el) => chartData.push({ name: el, value: data[el] }));
    setPieChartData(chartData);
  }
  useEffect(() => {
    if (OverallQRCData) {
      getPieChart(OverallQRCData);
    }
  }, [OverallQRCData]);

  const COLORS = [
    '#4E79A7', // Blue
    '#F28E2B', // Orange
    // '#E15759', // Red
    '#76B7B2', // Teal
    '#59A14F', // Green
    '#EDC949', // Yellow
    '#AF7AA1', // Purple
    '#FF9DA7', // Pink
    '#9C755F', // Brown
    '#BAB0AC' // Gray
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        // dy={-8}
        // lengthAdjust="spacingAndGlyphs"
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        style={{ outline: 'none', stroke: 'none' }}
      >
        {value > 0 && (
          <>
            {/* {(index === 0 && 'Query') || (index === 1 && 'Request') || (index === 2 && 'Complaint')} */}
            {/* {pieChartData[index].name} &nbsp; */}
            {`${value.toFixed(0)} calls (${(percent * 100).toFixed(0)}%)`}
          </>
        )}
      </text>
    );
  };
  // ----------------------------------------------------------
  const shortenLabel = (label) => {
    const maxLength = 8; // Set the maximum length you want
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  };

  // console.log('REACT_APP_TO_SHOW_QRC', process.env.REACT_APP_TO_SHOW_QRC);
  // -------------------------------------------------
  const colors = [
    '#6da5e0', // Medium Blue
    '#e6b796', // Medium Orange
    '#7ccd7c', // Medium Green
    '#af8faf', // Medium Purple
    '#d96666',
    '#8B4513',
    '#9400D3',
    '#00FF7F',
    '#1E90FF',
    '#FF8C00',
    '#CD5C5C',
    '#9932CC',
    '#008000',
    '#6A5ACD',
    '#FF6347',
    '#00FA9A',
    '#9932CC',
    '#8B0000',
    '#32CD32',
    '#8A2BE2',
    '#FF6347',
    '#00FF7F',
    '#4169E1',
    '#FFD700',
    '#8B008B',
    '#228B22',
    '#FF1493',
    '#00FFFF',
    '#FF4500',
    '#87CEEB',
    '#FFD700',
    '#FF6347',
    '#800000',
    '#00FF00',

    '#FF4500',
    '#8B008B',
    '#00FF7F',
    '#008080',
    '#8B4513',
    '#2F4F4F'
  ];
  // Array of fixed colors
  let colorIndex = 0; // Index to track the current color

  function getNextColor() {
    // function getRandomColor() {
    // const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    // }
    const color = colors[colorIndex];
    colorIndex = (colorIndex + 1) % colors.length; // Increment the index and wrap around
    return `url(#${color})`;
  }

  function convertUrlColor(urlColor) {
    // Extract the color from the URL
    const color = urlColor.replace(/^url\(#|[\)\"]$/g, '');

    // If the color starts with two hashes, remove one
    if (color.startsWith('##')) {
      return `#${color.substring(1)}`;
    }

    return color;
  }
  // ----------------------------------------------
  // handle disposition sentences popup

  const handleDispositonSentences = (row) => {
    // console.log('handleDispositonSentences', row);
    setShownSentences(row.disposition);
    setIsDispositionSentenceOpen(true);
  };
  // ------------------------------------------------
  // ----------------------------------------------
  // handle customizeXAxisLabel

  const customizeXAxisLabel = (arg) => {
    if (arg.value.length > 4) {
      return arg.value.substring(0, 4) + '...';
    }
    return arg.value;
  };

  const handleLabelHover = (event) => {
    const label = event.target;
    const fullName = label.textContent;
    // Show tooltip or popover with the full name
    // For example, you can use a library like Tooltip.js or Popper.js
    // Here's a basic example using the title attribute for a simple tooltip:
    label.setAttribute('title', fullName);
  };
  const customizeTooltip = (arg) => {
    console.log('arg', arg);
    return {
      text: `<b>${arg.argument}:  ${arg.value} calls</b>`
    };

    // return {
    //   text: `${arg.argumentText} \n \n <b>Disposition:</b> ${arg.seriesName}`
    // };
  };
  const customizeTooltipTrend = (arg) => {
    console.log('arg', arg);
    return {
      text: `<b>Disposition:  ${arg.point.data.disposition.join(',')}</b>`
    };

    // return {
    //   text: `${arg.argumentText} \n \n <b>Disposition:</b> ${arg.seriesName}`
    // };
  };

  // function for formatting Audio duration

  function formattedDuration(duration) {
    // Split the input duration using colon as the delimiter
    const [minutes, seconds] = duration.split(':').map(Number);

    // Convert minutes and seconds to two-digit strings
    const formattedMinutes = String(minutes).padStart(1, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    // Return the formatted time as mm:ss
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  console.log('QRCDataChart', QRCData);
  // const QRCSeries = [
  //   {
  //     name: 'Query',
  //     type: 'bar',
  //     data: high
  //   },
  //   {
  //     name: 'Request',
  //     type: 'bar',
  //     data: medium
  //   },
  //   {
  //     name: 'Complaint',
  //     type: 'bar',
  //     data: low
  //   }
  // {
  //   name: 'High Tone Trend',
  //   type: 'line',
  //   data: high
  // }
  // ];
  // console.log('graphData---', series);
  // const getType = (index) => {
  //   if (index === 0) {
  //     return 'high';
  //   }
  //   if (index === 1) {
  //     return 'medium';
  //   }
  //   if (index === 2) {
  //     return 'low';
  //   }
  // };
  // const QRCOptions = {
  //   chart: {
  //     toolbar: {
  //       show: true
  //     },
  //     events: {
  //       // dataPointSelection: (event, chartContext, config) => {
  //       //   // console.log('guru', config);
  //       //   setToneDate(graphData[config.dataPointIndex].dates);
  //       //   setDate(null);
  //       //   setDataIndex(null);
  //       //   setDeadAirDate(null);
  //       //   if (defaultDashboardData) {
  //       //     newDefaultTone(
  //       //       getType(config.seriesIndex),
  //       //       'tone',
  //       //       graphData[config.dataPointIndex].dates
  //       //     );
  //       //   } else {
  //       //     handleTonePopup(getType(config.seriesIndex), graphData[config.dataPointIndex].dates);
  //       //   }
  //       // }
  //     },
  //     type: 'line',
  //     width: 700,
  //     height: 450
  //     // stacked: true

  //     // stackType: '100%'
  //   },
  //   colors: ['#f75e5e', '#00c49f', '#ffbb28', 'grey'],

  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //       columnWidth: '40%',
  //       cursor: 'pointer'
  //       // barHeight: '20%'
  //     }
  //   },
  //   dataLabels: {
  //     enabled: false,
  //     // textAnchor: 'start',
  //     style: {
  //       fontSize: '11px',
  //       colors: ['white']
  //     },
  //     dropShadow: {
  //       enabled: false,
  //       top: 1,
  //       left: 1,
  //       blur: 1,
  //       color: '#000',
  //       opacity: 0.45
  //     }

  //     // offsetX: 0
  //   },
  //   stroke: {
  //     width: [0, 0, 0, 2],
  //     colors: ['grey']
  //   },
  //   title: {
  //     text: ''
  //   },
  //   xaxis: {
  //     categories: graphData && graphData.length > 0 && graphData.map((el) => el.dates),
  //     labels: {
  //       formatter(val) {
  //         return moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY');
  //       }
  //     },
  //     title: {
  //       text: undefined
  //     }
  //   },
  //   yaxis: {
  //     title: {
  //       text: 'Call Count',
  //       offsetX: 0,
  //       offsetY: 10,
  //       style: {
  //         fontSize: '12px',
  //         fontFamily: 'Helvetica, Arial, sans-serif',
  //         fontWeight: 650,
  //         cssClass: 'apexcharts-xaxis-title'
  //       }
  //     },
  //     labels: {
  //       formatter(val) {
  //         return `${val.toFixed(0)}`;
  //       }
  //     }
  //   },
  //   tooltip: {
  //     y: {
  //       formatter(val) {
  //         return `${val} Calls`;
  //       }
  //     }
  //   },
  //   fill: {
  //     opacity: 1
  //   },
  //   legend: {
  //     // onItemHover: {
  //     //   highlightDataSeries: false
  //     // },
  //     position: 'bottom',
  //     horizontalAlign: 'center',
  //     // offsetX: 40,
  //     onItemClick: {
  //       toggleDataSeries: ({ seriesIndex }) => console.log('seriesIndex', seriesIndex)
  //     }
  //   }
  // };

  const refDownloadOtions = useRef(null);
  const [downloadOptionsOpen, setDownloadOptionsOpen] = useState(false);

  // ------------------------------------------------

  return (
    <Page title="Dashboard" id="call_analysis">
      {/* <Button
          variant="outlined"
          color="error"
          sx={{ marginLeft: 104 }}
          onClick={() => handleSubscription()}
          disabled={loading}
        >
          Subscription Details
        </Button> */}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-between'
            // // marginBottom: 4,
            // padding: 4
          }}
        >
          <Typography variant="h4" gutterBottom>
            <Tabs defaultActiveKey="1" onSelect={(e) => setTabValue(e)}>
              <Tabs.Tab eventKey="1" title="Call Intelligence" />

              {process.env.REACT_APP_TO_SHOW_QRC === 'true' && (
                <Tabs.Tab eventKey="2" title="Call Drivers" />
              )}

              {/* {<Tabs.Tab eventKey="3" title="Category" />} */}
              <Tabs.Tab eventKey="4" title="Collections Info" />
            </Tabs>
          </Typography>
          <div>
            <LoadingButton
              onClick={downloadCsv}
              disabled={!showDownloadButton}
              sx={{
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                },
                marginRight: '5px'
              }}
              // loading={downloadStart}
              variant="contained"
              to="#"
              size="medium"
              startIcon={<Icon icon={download} />}
            >
              Download
            </LoadingButton>
            <IconButton ref={refDownloadOtions} onClick={() => setDownloadOptionsOpen(true)}>
              <Icon icon={moreVerticalFill} width={20} height={20} />
            </IconButton>

            <Menu
              open={downloadOptionsOpen}
              anchorEl={refDownloadOtions.current}
              onClose={() => setDownloadOptionsOpen(false)}
              PaperProps={{
                sx: { width: 200, maxWidth: '100%' }
              }}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem
                to="#"
                sx={{ color: 'text.secondary' }}
                onClick={() => handleDownload('call_analysis', 'call_analysis_report', 'png')}
              >
                <ListItemText
                  primary="Download PNG"
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </MenuItem>
              <MenuItem
                sx={{ color: 'text.secondary' }}
                onClick={() => handleDownload('call_analysis', 'call_analysis_report', 'pdf')}
              >
                <ListItemText
                  primary="Download PDF"
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </MenuItem>
            </Menu>
            {/* <LoadingButton
              sx={{
                backgroundColor: '#3892CF',
                '&:hover': {
                  backgroundColor: '#3892CF'
                }
              }}
              onClick={() => handleDownload('call_analysis', 'call_analysis_report', 'pdf')}
              // loading={downloadStart}
              variant="contained"
              to="#"
              size="medium"
              startIcon={<Icon icon={download} />}
            >
              Download Pdf
            </LoadingButton> */}
          </div>
        </Grid>
      </Stack>

      {/* {!loading ? ( */}
      <>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 1,
                backgroundColor: '#f4f4f4'
              }}
            >
              <Grid
                item
                xs={12}
                sm={5.7}
                md={process.env.REACT_APP_TO_SHOW_QRC === 'true' ? 1.7 : 2}
              >
                {userDetails.role !== 'Super_Admin' ? (
                  <>
                    <h5>
                      <b>Select Organisation *</b>
                    </h5>
                    <Selectbox required fullWidth size="small" defaultValue="all" disabled>
                      <MenuItem value="all" disabled>
                        {userDetails.organisation_name}
                      </MenuItem>
                    </Selectbox>
                  </>
                ) : (
                  <>
                    <h5>
                      <b>Select Organisation *</b>
                    </h5>
                    <Select
                      //   onChange={chnageSelect}
                      styles={customStyles}
                      onChange={setAllEmpty}
                      options={OrganisationList?.map((element) => ({
                        value: element.organisation_id,
                        label: element.organisation_name
                      }))}
                      value={organisationName}
                      defaultValue={{ value: 'all', label: 'All' }}
                      style={{ color: '#000' }}
                      components={{ Control: CustomControlOrg }}
                      placeholder={
                        <div
                          style={{
                            display: 'flex',
                            columnGap: 10,
                            alignItems: 'center',
                            color: '#000',
                            fontWeight: '400'
                          }}
                        >
                          {/* <img src={CorporateFareIcon} width={20} alt="" /> */}

                          {/* Select Organisation * */}
                        </div>
                      }
                    />
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={5.7}
                md={process.env.REACT_APP_TO_SHOW_QRC === 'true' ? 1.7 : 2}
              >
                {userDetails.role === 'QA' ||
                userDetails.role === 'Manager' ||
                userDetails.role === 'Customer_Admin' ||
                userDetails.role === 'QA_admin' ? (
                  <>
                    <h5>
                      <b>Select Customer *</b>
                    </h5>
                    <Selectbox required fullWidth size="small" defaultValue="all" disabled>
                      <MenuItem value="all" disabled>
                        {userDetails.customer_name}
                      </MenuItem>
                    </Selectbox>
                  </>
                ) : (
                  <>
                    <h5>
                      <b>Select Customer *</b>
                    </h5>
                    <div>
                      <Select
                        //   onChange={chnageSelect}
                        styles={customStyles}
                        defaultValue={{ value: 'all', label: 'All' }}
                        onChange={(e) => {
                          setCustomerName(e);
                          setCustomerValue(e.value);
                          setProcessName([]);
                          setSelectedAgents([]);
                          formik.setFieldValue('process', []);
                          formik.setFieldValue('agent', []);
                          console.log('e customer', e);
                        }}
                        value={customerName}
                        options={customerList?.map((element) => ({
                          value: element.customer_id,
                          label: element.customer_organisation_name
                        }))}
                        // value={selectDoc}
                        components={{ Control: CustomControlCustomer }}
                        style={{ color: '#000' }}
                        placeholder={
                          <div
                            style={{
                              display: 'flex',
                              columnGap: 10,
                              alignItems: 'center',
                              color: '#000',
                              fontWeight: '400'
                            }}
                          >
                            {/* <img src={CorporateFareIcon} width={20} alt="" /> */}
                            {/* <PortraitIcon width={20} />
                    Select Customer * */}
                          </div>
                        }
                      />
                    </div>
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={5.7}
                md={process.env.REACT_APP_TO_SHOW_QRC === 'true' ? 1.7 : 2}
              >
                <h5>
                  <b>Select Process *</b>
                </h5>
                {/* <div style={{ width: '200px', maxHeight: 35 }}> */}
                <Select
                  styles={customStyles}
                  onChange={handleProcessChange}
                  components={{ Control: CustomControlProcess }}
                  isMulti
                  options={
                    processList
                      ? [
                          { value: 'all', label: 'All' },
                          ...processList.map((element) => ({
                            value: element,
                            label: element
                          }))
                        ]
                      : [{ value: 'all', label: 'All' }]
                  }
                  value={processName}
                  style={{ color: '#000' }}
                  placeholder={
                    <div
                      style={{
                        display: 'flex',
                        columnGap: 10,
                        alignItems: 'center',
                        color: '#353535',
                        fontWeight: '500'
                      }}
                    >
                      {/* <img src={CorporateFareIcon} width={20} alt="" /> */}
                      {/* <SupportAgentIcon width={20} /> */}
                      {userDetails.role !== 'Super_Admin'
                        ? formik.values.customer === null || formik.values.customer === 'all'
                          ? 'All'
                          : ''
                        : formik.values.organisation === '' || formik.values.organisation === 'all'
                        ? 'All'
                        : ''}
                    </div>
                  }
                />
                {/* </div> */}
              </Grid>
              {/* <Grid
                item
                xs={12}
                sm={5.7}
                md={process.env.REACT_APP_TO_SHOW_QRC === 'true' ? 1.7 : 2.8}
              >
                <h5>
                  <b>Select Process *</b>
                </h5>
                <Select
                  styles={customStyles}
                  onChange={(e) => {
                    handleProcess(e.value);
                    setProcessName(e);
                    setSelectedAgents([]);
                    formik.setFieldValue('agent', []);
                  }}
                  components={{ Control: CustomControlProcess }}
                  options={processList?.map((element) => ({
                    value: element,
                    label: element
                  }))}
                  value={processName}
                  defaultValue={{ value: 'all', label: 'All' }}
                  // value={selectDoc}
                  style={{ color: '#000' }}
                  placeholder={
                    <div
                      style={{
                        display: 'flex',
                        columnGap: 10,
                        alignItems: 'center',
                        color: '#000',
                        fontWeight: '400'
                      }}
                    ></div>
                  }
                />
              </Grid> */}
              <Grid
                item
                xs={12}
                sm={5.7}
                md={process.env.REACT_APP_TO_SHOW_QRC === 'true' ? 1.7 : 2}
              >
                <h5>
                  <b>Select Agent *</b>
                </h5>
                {/* <div style={{ width: '200px', maxHeight: 35 }}> */}
                <Select
                  styles={customStyles}
                  onChange={handleAgentChange}
                  components={{ Control: CustomControlAgent }}
                  isMulti
                  options={
                    agentList
                      ? [
                          { value: 'all', label: 'All' },
                          ...agentList.map((element) => ({
                            value: element.user_id,
                            label: `${element.first_name} ${element.last_name}`
                          }))
                        ]
                      : [{ value: 'all', label: 'All' }]
                  }
                  value={selectedAgents}
                  style={{ color: '#000' }}
                  placeholder={
                    <div
                      style={{
                        display: 'flex',
                        columnGap: 10,
                        alignItems: 'center',
                        color: '#353535',
                        fontWeight: '500'
                      }}
                    >
                      {/* <img src={CorporateFareIcon} width={20} alt="" /> */}
                      {/* <SupportAgentIcon width={20} /> */}
                      {userDetails.role !== 'Super_Admin'
                        ? formik.values.process === null || formik.values.process === 'all'
                          ? 'All'
                          : ''
                        : formik.values.organisation === '' || formik.values.organisation === 'all'
                        ? 'All'
                        : ''}
                    </div>
                  }
                />
                {/* </div> */}
              </Grid>
              {/* {process.env.REACT_APP_TO_SHOW_QRC === 'true' && (
                <Grid item xs={12} sm={5.7} md={1.7}>
                  <h5>
                    <b>Select Call Type *</b>
                  </h5>
                  <Select
                    styles={customStyles}
                    onChange={(e) => setSelectedCallType(e)}
                    components={{ Control: CustomControlAgent }}
                    options={callTypeList}
                    value={selectedCallType}
                    style={{ color: '#000' }}
                    placeholder={
                      <div
                        style={{
                          display: 'flex',
                          columnGap: 10,
                          alignItems: 'center',
                          color: '#353535',
                          fontWeight: '500'
                        }}
                      >
                        {userDetails.role !== 'Super_Admin'
                          ? formik.values.process === null || formik.values.process === 'all'
                            ? 'All'
                            : ''
                          : formik.values.organisation === '' ||
                            formik.values.organisation === 'all'
                          ? 'All'
                          : ''}
                      </div>
                    }
                  />
                </Grid>
              )} */}
              <Grid
                Grid
                item
                xs={12}
                sm={5.7}
                md={process.env.REACT_APP_TO_SHOW_QRC === 'true' ? 1.7 : 2}
              >
                <h5>
                  <b>Select Date *</b>
                </h5>
                <Select
                  //   onChange={chnageSelect}
                  styles={customStyles}
                  onChange={(e) => {
                    formik.setFieldValue('date', e.value);
                    setDefaultDate(e);
                  }}
                  options={dateFormat?.map((element) => ({
                    value: element.id,
                    label: element.name
                  }))}
                  value={defaultDate}
                  // defaultValue={{ value: 'all', label: 'All' }}
                  style={{ color: '#000' }}
                  components={{ Control: CustomControlDate }}
                  placeholder={
                    <div
                      style={{
                        display: 'flex',
                        columnGap: 10,
                        alignItems: 'center',
                        color: '#000',
                        fontWeight: '400'
                      }}
                    >
                      {/* <img src={CorporateFareIcon} width={20} alt="" /> */}

                      {/* Select Organisation * */}
                    </div>
                  }
                  isSearchable={false}
                  isClearable={false}
                />
              </Grid>

              {defaultDate.value === 'customized' && (
                <Grid item xs={12} sm={5.7} md={5}>
                  <h5 style={{ marginBottom: '-10px' }}>
                    <b>Start Date *</b>
                  </h5>
                  <DatePicker
                    className="my-datepicker-class"
                    selected={checkInDate}
                    onChange={handleCheckInDate}
                    // minDate={new Date().setMonth(new Date().getMonth() - 3)}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomInput />}
                    placeholderText="Start date"
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                    // style={{ backgroundColor: 'blue', border: 'none' }}
                  />
                  <FormHelperText sx={{ marginLeft: '10px', color: '#d32f2f' }}>
                    {touched.startDate && errors.startDate}
                  </FormHelperText>
                </Grid>
              )}
              {defaultDate.value === 'customized' && (
                <Grid item xs={12} sm={5.7} md={5}>
                  <h5 style={{ marginBottom: '-10px' }}>
                    <b>End date *</b>
                  </h5>
                  <DatePicker
                    className="my-datepicker-class"
                    selected={checkOutDate}
                    maxDate={maxDate}
                    minDate={checkInDate}
                    onChange={handleCheckOutDate}
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomInput />}
                    placeholderText="End date"
                    error={Boolean(touched.endDate && errors.endDate)}
                    helperText={touched.endDate && errors.endDate}
                    // disabled
                  />
                  <FormHelperText sx={{ marginLeft: '10px', color: '#d32f2f' }}>
                    {touched.endDate && errors.endDate}
                  </FormHelperText>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={3}
                md={1}
                // sx={{
                //   marginTop: '20px'
                //   // marginLeft: '0px'
                // }}
              >
                <Button
                  type="submit"
                  disabled={!formik.values.agent?.length > 0}
                  className={classes.submitButton}
                  // onClick={handleDashboardAPI}
                  sx={{ marginTop: defaultDate.value === 'customized' ? '10px' : '21px' }}
                  size="medium"
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
        {/* {!loading ? ( */}
        <Grid container spacing={3} xs={12} sx={{ marginTop: '1rem' }}>
          {/* <Grid container spacing={2} sx={{ marginLeft: '10px' }}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                // cardWidth={245}
                title="Total Calls Processed"
                total={cardData ? cardData.processed_calls : 0}
                color="primary"
                bgColor="rgba(56, 50, 160, 0.15)"
                icon="ic:baseline-phone"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                // cardWidth={245}
                title="Unpleasant Calls"
                total={cardData ? cardData.unplz_count : 0}
                color="error"
                bgColor="rgba(208, 121, 208, 0.15)"
                icon={PhoneCheck}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                // cardWidth={245}
                title="Negative Sentiment Calls"
                total={cardData ? cardData.sentiment_count : 0}
                color="info"
                bgColor="rgba(11, 121, 208, 0.15)"
                icon="fluent:people-error-16-filled"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                // cardWidth={245}
                title="High Tone Calls"
                total={cardData ? cardData.tone_count : 0}
                color="error"
                bgColor="rgba(252, 106, 108, 0.20)"
                icon="ph:speaker-simple-high-duotone"
              />
            </Grid>
          </Grid> */}
          {tabValue === '1' && (
            <Grid container spacing={2} sx={{ marginLeft: '10px' }}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.cards}
                style={{ paddingLeft: 20, paddingRight: 20 }}
              >
                <Card
                  style={{
                    height: 100,
                    boxShadow: 0,
                    textAlign: 'center',
                    // padding: '2px',
                    borderRadius: '5px'
                  }}
                >
                  <div
                    style={{
                      // borderBottom: '1px solid #f1f3f4',
                      padding: 5,
                      backgroundColor: '#3892CF'
                    }}
                  >
                    <Typography style={{ color: '#fff' }} variant="h7">
                      <b>Total Calls Processed</b>
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '70%',
                      padding: '4px',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <CallIcon style={{ color: '#60AAD9' }} fontSize="large" />
                    </div>
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',

                          fontSize: '2.4rem'
                        }}
                      >
                        {cardData ? formatNumber(cardData?.processed_calls) : 0}

                        {/* <ArrowUpwardIcon sx={{ marginLeft: 5, color: 'green' }} />
                      <span style={{ color: 'green', fontSize: '20px' }}>5%</span> */}
                      </Typography>
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',
                          // marginBottom: 50,
                          fontSize: '2.4rem'
                        }}
                      >
                        <ComparativeAnalysis
                          style={{ height: '15px', width: '15px' }}
                          arrow={analysisData && analysisData.processed_calls_actual}
                          count={analysisData && analysisData.processed_calls_improvement}
                        />
                      </Typography>
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.cards}
                style={{ paddingLeft: 20, paddingRight: 20 }}
              >
                <Card
                  style={{
                    height: 100,
                    boxShadow: 0,
                    textAlign: 'center',
                    borderRadius: '2px'
                  }}
                >
                  <div
                    style={{
                      // borderBottom: '1px solid #f1f3f4',
                      padding: 5,
                      backgroundColor: '#3892CF'
                    }}
                  >
                    <Typography style={{ color: '#fff' }} variant="h7">
                      <b>Negative Sentiment Calls</b>
                    </Typography>
                  </div>

                  <div
                    style={{
                      width: '100%',
                      height: '70%',
                      padding: '4px',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <Icon
                        icon="fluent:people-error-16-filled"
                        style={{ color: '#60AAD9', fontSize: 35 }}
                      />
                    </div>

                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',

                          fontSize: '2.4rem'
                        }}
                      >
                        {cardData ? formatNumber(cardData?.sentiment_count) : 0}

                        {/* <ArrowDownwardIcon sx={{ marginLeft: 5, color: 'red' }} />
                      <span style={{ color: 'red', fontSize: '20px' }}>3%</span> */}
                      </Typography>
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',

                          fontSize: '2.4rem'
                        }}
                      >
                        <ComparativeAnalysis
                          style={{ height: '15px', width: '15px' }}
                          arrow={analysisData && analysisData.sentiment_actual}
                          count={analysisData && analysisData.sentiment_count_improvement}
                        />
                      </Typography>
                    </div>
                  </div>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.cards}
                style={{ paddingLeft: 20, paddingRight: 20 }}
              >
                <Card
                  style={{
                    height: 100,
                    boxShadow: 0,
                    textAlign: 'center',
                    borderRadius: '2px'
                  }}
                >
                  <div
                    style={{
                      // borderBottom: '1px solid #f1f3f4',
                      padding: 5,
                      backgroundColor: '#3892CF'
                    }}
                  >
                    <Typography style={{ color: '#fff' }} variant="h7">
                      <b>High Tone Calls</b>
                    </Typography>
                  </div>

                  <div
                    style={{
                      width: '100%',
                      height: '70%',
                      padding: '4px',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <Icon
                        icon="ph:speaker-simple-high-duotone"
                        style={{ color: '#60AAD9', fontSize: 35 }}
                      />
                    </div>

                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',
                          fontSize: '2.4rem'
                        }}
                      >
                        {cardData ? formatNumber(cardData?.tone_count) : 0}

                        {/* <ArrowUpwardIcon sx={{ marginLeft: 5, color: 'green' }} />
                      <span style={{ color: 'green', fontSize: '20px' }}>2%</span> */}
                      </Typography>
                      <Typography
                        // variant="h3"
                        style={{
                          color: '#000',
                          fontStyle: 'bold',

                          fontSize: '2.4rem'
                        }}
                      >
                        <ComparativeAnalysis
                          style={{ height: '15px', width: '15px' }}
                          arrow={analysisData && analysisData.tone_actual}
                          count={analysisData && analysisData.tone_count_improvement}
                        />
                      </Typography>
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          )}
          {/* <Grid item xs={12} md={6}>
            <Card sx={{ height: 500 }}> */}
          {/* <Grid container spacing={10}> */}
          {/* <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'space-between' }}> */}
          {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
          {/* <div
                style={{
                  padding: 15,
                  borderBottom: '1px solid #f1f3f4',
                  backgroundColor: '#F4F4F4',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              > */}
          {/* <b>Unpleasant Words</b>
                {unpleasantData && (
                  <Button
                    // style={{ marginTop: 20, marginLeft: '7rem' }}
                    size="small"
                    onClick={(e) => downloadChart('unpleasant_graph')}
                    variant="primary"
                  >
                    <Icon icon={download} />
                  </Button>
                )}
              </div> */}
          {/* </Grid>
                <Grid item xs={12} md={6}> */}
          {/* {toneData && unpleasantData && deadAir && sentimentData && (
                <Button
                  // style={{ marginTop: 20, marginLeft: '7rem' }}
                  onClick={(e) => downloadChart('unpleasant_graph')}
                  variant="primary"
                  startIcon={<Icon style={{ marginLeft: 10 }} icon={download} />}
                />
              )} */}
          {/* </div> */}
          {/* </Grid> */}
          {/* </Grid> */}
          {/* {graphDataForUnpleasant?.length > 0 ? (
                <ResponsiveContainer width="100%" height={450}>
                  <BarChart
                    // width={500}
                    // height={300}
                    data={graphDataForUnpleasant}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  > */}
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          {/* <XAxis
                      dataKey="dates"
                      fontFamily="Roboto, sans-serif"
                      fontSize={12}
                      reversed
                      // angle={-40}
                      position="outside"
                      fontWeight="bold"
                    />
                    <YAxis
                      label={{
                        value: 'Count',
                        angle: -90,
                        position: 'insideLeft',
                        fontWeight: 'bold',
                        color: '#000'
                      }}
                      fontSize={14}
                      fontWeight="bold"
                    />
                    <Tooltip content={<BarTooltip />} />
                    <Legend
                      payload={format.map((item, index) => ({
                        type: 'square',
                        value: `Total Unpleasant Frequency`,
                        color: '#8884d8'
                      }))}
                      wrapperStyle={{ fontWeight: 'bold' }}
                    />
                    <Bar
                      dataKey="total"
                      fill="#8884d8"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        if (defaultDashboardData) {
                          getNewDefaultDashboard(
                            'unpleasant',
                            moment(date, 'DD/MM/YYYY').format('YYYY-M-D')
                          );
                        } else {
                          handleUnpleasantPopup();
                        }
                      }}
                    />
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: 20 }}>
                    <b>No Unpleasant Word Calls</b>
                  </span>
                </div>
              )}
            </Card>
          </Grid> */}
          {/* <Grid item md={12} xs={12} sm={12} xl={12}>
            <ToggleButtonGroup
              value={toggleValue}
              onChange={handleToggle}
              aria-label="Platform"
              style={{
                marginTop: 5,
                marginBottom: 5,
                width: 200,
                paddingLeft: 8,
                color: '#3892CF'
              }}
            >
              <ToggleButton
                value="customer"
                style={{ color: toggleValue === 'customer' ? '#3892CF' : 'grey' }}
              >
                Customer
              </ToggleButton>
              <ToggleButton
                value="agent"
                style={{ color: toggleValue === 'agent' ? '#3892CF' : 'grey' }}
              >
                Agent
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid> */}
          {tabValue === '1' && (
            <Grid item xs={12} md={6}>
              <StackBar
                downloadChart={downloadChart}
                graphData={graphDataForTone}
                handleDialog={handleDialog}
                setDate={setDate}
                // handleTonePopup={handleTonePopup}
                // newDefaultTone={getNewDefaultDashboard}
                setDeadAirDate={setDeadAirDate}
                filteredTone={filteredTone}
                toneData={toneData}
                defaultDashboardData={defaultDashboardData}
                // handleSummaryTonePopup={handleSummaryTonePopup}
                setPageForPopup={setPagePopup}
              />
            </Grid>
          )}
          {/* <Grid item xs={12} md={6}>
            <Card sx={{ height: 450 }}>
              <div
                style={{
                  padding: 15,
                  borderBottom: '1px solid #f1f3f4',
                  backgroundColor: '#F4F4F4',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <b>Dead Air</b>
                {deadAir && (
                  <Button
                    // style={{ marginTop: 20, marginLeft: '7rem' }}
                    size="small"
                    onClick={(e) => downloadChart('dead_air_graph')}
                    variant="primary"
                  >
                    <Icon icon={download} />
                  </Button>
                )}
              </div>
              {graphDataForDeadAir?.length > 0 ? (
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart
                    // width={700}
                    // height={378}
                    data={graphDataForDeadAir}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                    padding={{
                      top: 40,
                      bottom: 40
                    }}
                  >
                    <XAxis
                      dataKey="dates"
                      fontFamily="Roboto, sans-serif"
                      fontSize={14}
                      reversed
                      // angle={-40}
                    />
                    <YAxis
                      label={{ value: 'Dead Air in seconds', angle: -90, position: 'insideLeft' }}
                      fontSize={14}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                      dataKey="total"
                      dot={false}
                      strokeWidth={2}
                      stroke="#4F2FE8"
                      activeDot={{
                        r: 8,
                        style: { cursor: 'pointer' }
                        // onClick: () => {
                        //   console.log('filteredDeadAir --------', filteredDeadAir);
                        //   if (filteredDeadAir.filter((item) => item.dead_air > 0).length > 0) {
                        //     handleDialog();
                        //   }

                        //   // console.log('welcome');
                        // }
                      }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: 20 }}>
                    <b>No Calls With DeadAir Found</b>
                  </span>
                </div>
              )}
            </Card>
          </Grid> */}
          {tabValue === '1' && (
            <Grid item xs={12} md={6}>
              <SentimentGraph
                downloadChart={downloadChart}
                sentimentData={sentimentData}
                // handleSentimentPopup={handleSentimentPopup}
                setDate={setDate}
                handleDialog={handleDialog}
                setDeadAirDate={setDeadAirDate}
                // defaultDashboardData={defaultSentimentData}
                setPageForPopup={setPagePopup}
                defaultDashboardData={defaultSentimentData}
                // newDefaultTone={getNewDefaultDashboard}
                // handleSummarySentimentPopup={handleSummarySentimentPopup}
              />
            </Grid>
          )}
          {process.env.REACT_APP_TO_SHOW_QRC === 'true' && tabValue === '2' && (
            <Grid item xs={12} md={customerLevel === 'level_1' ? 12 : 5}>
              <Card sx={{ height: 515 }}>
                <div
                  style={{
                    padding: 10,
                    borderBottom: '1px solid #f1f3f4',
                    backgroundColor: '#F4F4F4',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <b>Disposition Type</b>

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Selectbox
                      size="small"
                      value={chartTypeForQRC}
                      onChange={(e) => setChartTypeForQRC(e.target.value)}
                      // MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                      <MenuItem value="summary">Summary</MenuItem>
                      <MenuItem value="trend">Trend</MenuItem>
                    </Selectbox>
                  </div>
                </div>
                {chartTypeForQRC === 'trend' && (
                  <>
                    {QRCData?.length > 0 ? (
                      <ResponsiveContainer width="100%" height={450}>
                        <BarChart
                          data={QRCData}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <XAxis
                            dataKey="date"
                            fontWeight="bold"
                            angle={-35} // Rotate labels vertically
                            tick={{
                              fontFamily: 'cursive, sans-serif',
                              fontSize: 10,
                              fontWeight: 'bold'
                            }}
                            tickLine={false}
                            tickFormatter={(value) => shortenLabel(value)}
                          />
                          <YAxis
                            label={{
                              value:
                                initialData.value === 'all'
                                  ? 'Call Count'
                                  : `${initialData.label} Call Count`,
                              angle: -90,
                              position: 'insideLeft',
                              fontWeight: 'bold',
                              color: 'grey'
                            }}
                            interval={1}
                            tickLine={false}
                            tick={{
                              fontFamily: 'cursive, sans-serif',
                              fontSize: 10,
                              fontWeight: 'bold'
                            }}
                            fontSize={14}
                            fontWeight="bold"
                          />
                          <Tooltip content={<CustomTooltip />} />
                          <Legend content={<CustomLegend />} />
                          {/* <Bar dataKey="Query" fill="#8884d8" />
                          <Bar dataKey="Request" fill="#82ca9d" />
                          <Bar dataKey="Complaint" fill="#82ca9d" /> */}
                          {callCategories.map((category, index) => (
                            <Bar
                              // onClick={(e) => {
                              //   // console.log('QueryRelatedCalls', e);
                              //   getPopupDataForQRC(
                              //     category,
                              //     moment(e.date, 'DD/MM/YYYY').format('YYYY-MM-DD')
                              //   );
                              // }}
                              style={{ cursor: 'pointer' }}
                              barSize={20}
                              dataKey={category}
                              // stackId="a"
                              fill={
                                category.toLowerCase() === 'complaint'
                                  ? '#DC645C'
                                  : category.toLowerCase() === 'query'
                                  ? ' #F0B74A'
                                  : category.toLowerCase() === 'request'
                                  ? ' #72BE47'
                                  : COLORS[index % COLORS.length]
                              }
                            />
                          ))}
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '80%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <span style={{ fontSize: 20 }}>
                          <b>No Calls Available</b>
                        </span>
                      </div>
                    )}
                  </>
                )}
                {chartTypeForQRC === 'summary' && (
                  <>
                    {QRCData?.length > 0 &&
                    Object.keys(OverallQRCData).length > 0 &&
                    pieChartData ? (
                      <ResponsiveContainer width="100%" height="80%">
                        <PieChart width={500} height={400}>
                          <Pie
                            data={pieChartData}
                            cx="50%"
                            cy="50%"
                            // labelLine={false}
                            // label={renderCustomizedLabel}
                            outerRadius={150}
                            fill="#8884d8"
                            dataKey="value"
                          >
                            {pieChartData.map((entry, index) => (
                              <Cell
                                onClick={() => getDispositionWithIntent(entry, index)}
                                key={`cell-${index}`}
                                fill={
                                  entry.name.toLowerCase() === 'complaint'
                                    ? '#DC645C'
                                    : entry.name.toLowerCase() === 'query'
                                    ? ' #F0B74A'
                                    : entry.name.toLowerCase() === 'request'
                                    ? ' #72BE47'
                                    : COLORS[index % COLORS.length]
                                }
                                style={{ cursor: 'pointer', outline: 'none', stroke: 'none' }}
                              />
                            ))}
                          </Pie>
                          <Legend
                            layout="vertical"
                            verticalAlign="top"
                            align="right"
                            formatter={(value, entry) => `${value}: ${entry.payload.value} calls`}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '80%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <span style={{ fontSize: 20 }}>
                          <b>No Calls Available</b>
                        </span>
                      </div>
                    )}
                  </>
                )}
              </Card>
            </Grid>
          )}
          {process.env.REACT_APP_TO_SHOW_QRC === 'true' &&
            tabValue === '2' &&
            (customerLevel === 'level_2' || customerLevel === 'level_3') && (
              <Grid item xs={12} md={7}>
                <Card sx={{ height: 515 }}>
                  <div
                    style={{
                      padding: 10,
                      borderBottom: '1px solid #f1f3f4',
                      backgroundColor: '#F4F4F4',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <b>Disposition Sub-category ({trendDispositionGraph.length} Calls)</b>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <>
                        <Selectbox
                          size="small"
                          sx={{ marginRight: 1 }}
                          // defaultValue="Query"
                          value={filterForQRC}
                          onChange={(e) => {
                            setFilterForQRC(e.target.value);
                            // let index = '';
                            // if (e.target.value === 'Query') {
                            //   index = 0;
                            // } else if (e.target.value === 'Request') {
                            //   index = 1;
                            // } else if (e.target.value === 'Complaint') {
                            //   index = 2;
                            // } else if (e.target.value === 'All') {
                            //   index = 'all';
                            // }
                            // getDispositionWithIntent(index);
                          }}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                        >
                          <MenuItem value="All">All</MenuItem>
                          {callCategories.length > 0 ? (
                            callCategories.map((el) => <MenuItem value={el}>{el}</MenuItem>)
                          ) : (
                            <MenuItem>
                              <i>No Data</i>
                            </MenuItem>
                          )}
                        </Selectbox>
                        <Selectbox
                          size="small"
                          disabled={customerLevel === 'level_1' || customerLevel === 'level_2'}
                          // defaultValue="Credit Card"
                          value={dispositionType}
                          onChange={(e) => setDispositionType(e.target.value)}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                        >
                          <MenuItem value="All">All</MenuItem>
                          {dispositionList?.length > 0 ? (
                            dispositionList.map((el) => <MenuItem value={el}>{el}</MenuItem>)
                          ) : (
                            <MenuItem value="No Data">
                              <i>No Data</i>
                            </MenuItem>
                          )}
                        </Selectbox>
                      </>

                      <Selectbox
                        size="small"
                        sx={{ marginLeft: 1 }}
                        // defaultValue="Summary"
                        value={filterForDispositionChartType}
                        onChange={(e) => setFilterForDispositionChartType(e.target.value)}
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                      >
                        <MenuItem value="Summary">Summary</MenuItem>
                        <MenuItem value="Trend">Trend</MenuItem>
                      </Selectbox>
                    </div>
                  </div>

                  {filterForDispositionChartType === 'Summary' && (
                    <>
                      {newSummaryDispositionGraph.length > 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <Chart
                            id="chart"
                            height={450}
                            width={550}
                            // title="Gross State Product within the Great Lakes Region"
                            // barGroupPadding={0.2}
                            // barGroupWidth={80}
                            dataSource={newSummaryDispositionGraph}
                            // onPointClick={
                            //   (e) => {
                            //     console.log('e.target.series.input_id', e);
                            //     setShownQRCDetails(e.target.series.name);
                            //     setQRCList([
                            //       {
                            //         request_id: e.target.data.request_id,
                            //         input_id: e.target.data.input_id,
                            //         org_id: e.target.data.org_id,
                            //         agent_id: e.target.data.agent_id,
                            //         customer_id: e.target.data.customer_id,
                            //         agent_name: e.target.data.agent_name,
                            //         original_date: e.target.data.original_date,
                            //         s3url: e.target.data.name
                            //       }
                            //     ]);
                            //     handleDialogForQRCList();
                            //   }
                            //   // getPopupDataForQRC(
                            //   //   e.target.series.name,
                            //   //   moment(e.target.argument, 'DD/MM/YYYY').format('YYYY-MM-DD')
                            //   // )
                            // }
                            // onClick={(e) => getPopupDataForQRCPieChart(e.category)}
                            // onLegendClick={({ target: series }) => {
                            //   // console.log('event', event);
                            //   if (series.isVisible()) {
                            //     series.hide();
                            //   } else {
                            //     series.show();
                            //   }
                            // }}
                          >
                            <CommonSeriesSettings
                              argumentField="count"
                              type="bar"
                              // hoverMode="allArgumentPoints"
                              // selectionMode="allArgumentPoints"
                            >
                              {/* <ExtremeLabel visible>
                              <Format type="fixedPoint" precision={0} />
                            </ExtremeLabel> */}
                            </CommonSeriesSettings>
                            <ArgumentAxis
                              defaultVisualRange={{ startValue: 400, endValue: 450 }}
                              visualRangeUpdateMode="keep"
                              // visualRange={visualRange}
                            >
                              <ExtremeLabel
                                customizeText={customizeXAxisLabel}
                                onHover={handleLabelHover}
                              />
                            </ArgumentAxis>
                            <ExtremeTooltip
                              enabled
                              // location="edge"
                              customizeTooltip={customizeTooltip}
                            />

                            <ZoomAndPan argumentAxis="both" />
                            {/* <Series argumentField="state" valueField="year2018" name="2018" /> */}
                            {/* <Series valueField="year2017" name="2017" /> */}
                            {/* {dispositionBarData?.map((entry, index) => ( */}
                            <Series
                              valueField="count"
                              argumentField="category"
                              type="bar"
                              barWidth={30}
                              barPadding={0}
                            />
                            {/* ))} */}
                            <ExtremeLegend
                              visible={false}
                              verticalAlignment="bottom"
                              horizontalAlignment="center"
                            />
                            <ValueAxis allowDecimals={false}>
                              <ExtremeTitle text="Disposition Call Count" />
                              <ExtremeLabel>
                                <Format precision={0} />
                              </ExtremeLabel>
                            </ValueAxis>
                            <ScrollBar visible position="bottom" />
                          </Chart>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: '100%',
                            height: '80%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <span style={{ fontSize: 20 }}>
                            {!loading && <b>No Data Available</b>}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                  {filterForDispositionChartType === 'Trend' && (
                    <>
                      {trendDispositionGraph.length > 0 ? (
                        // <ResponsiveContainer width="100%" height={450}>
                        //   <BarChart
                        //     data={trendDispositionGraph}
                        //     margin={{
                        //       top: 20,
                        //       right: 30,
                        //       left: 20
                        //     }}
                        //   >
                        //     <defs>
                        //       {colors.map((el) => (
                        //         <linearGradient id={el} x1="0" y1="0" x2="0" y2="1">
                        //           <stop offset="5%" stopColor={el} stopOpacity={1} />
                        //           <stop offset="95%" stopColor={el} stopOpacity={0.4} />
                        //         </linearGradient>
                        //       ))}
                        //     </defs>
                        //     <XAxis
                        //       dataKey="date"
                        //       fontFamily="Roboto, sans-serif"
                        //       angle={-40} // Rotate labels vertically
                        //       reversed
                        //       fontWeight="bold"
                        //       tick={{
                        //         fontFamily: 'cursive, sans-serif',
                        //         fontSize: 10,
                        //         fontWeight: 'bold'
                        //       }}
                        //       tickLine={false}
                        //       // onMouseEnter={(value) => setHoveredLabel(value)}
                        //       tickFormatter={(value) => shortenLabel(value)}
                        //     />
                        //     <YAxis
                        //       // dataKey="values"
                        //       label={{
                        //         value: 'Count',
                        //         angle: -90,
                        //         position: 'insideLeft',
                        //         fontWeight: 'bold',
                        //         color: 'grey'
                        //       }}
                        //       tick={{
                        //         fontFamily: 'cursive, sans-serif',
                        //         fontSize: 10,
                        //         fontWeight: 'bold'
                        //       }}
                        //       allowDecimals={false}
                        //       tickLine={false}
                        //       fontWeight="bold"
                        //     />

                        //     {dispositionBarData?.map((entry, index) => (
                        //       <>
                        //         <Tooltip content={<CustomTooltip props={entry.name} />} />

                        //         <Bar
                        //           key={index}
                        //           barSize={25}
                        //           dataKey={entry.name}
                        //           onClick={(e) => {
                        //             // console.log('QueryRelatedCalls', e);
                        //             getPopupDataForQRC(
                        //               entry.name,
                        //               moment(e.date, 'DD/MM/YYYY').format('YYYY-MM-DD')
                        //             );
                        //           }}
                        //           // stackId="a"
                        //           fill={getNextColor()}
                        //           // onMouseOver={() => {
                        //           //   setBarName(entry.name);
                        //           // }}
                        //           style={{ opacity: 0.9, borderRadius: 5, cursor: 'pointer' }}
                        //         />
                        //       </>
                        //     ))}
                        //     <Legend
                        //       content={<CustomLegendForTrendDispositon />}
                        //       layout="vertical"
                        //       align="right"
                        //       verticalAlign="center"
                        //     />
                        //   </BarChart>
                        // </ResponsiveContainer>
                        <Chart
                          id="chart"
                          height={450}
                          // title="Gross State Product within the Great Lakes Region"
                          barGroupPadding={0.2}
                          // barGroupWidth={80}
                          dataSource={trendDispositionGraph}
                          onPointClick={
                            (e) => {
                              setQRCList([
                                {
                                  request_id: e.target.data.request_id,
                                  input_id: e.target.data.input_id,
                                  org_id: e.target.data.org_id,
                                  agent_id: e.target.data.agent_id,
                                  customer_id: e.target.data.customer_id,
                                  agent_name: e.target.data.agent_name,
                                  original_date: e.target.data.original_date,
                                  s3url: e.target.data.s3url
                                }
                              ]);
                              handleDialogForQRCList();
                            }
                            // getPopupDataForQRC(
                            //   e.target.series.name,
                            //   moment(e.target.argument, 'DD/MM/YYYY').format('YYYY-MM-DD')
                            // )
                          }
                          onLegendClick={({ target: series }) => {
                            // console.log('event', event);
                            if (series.isVisible()) {
                              series.hide();
                            } else {
                              series.show();
                            }
                          }}
                        >
                          <CommonSeriesSettings
                            argumentField="date"
                            type="stackedbar"
                            hoverMode="allArgumentPoints"
                            selectionMode="allArgumentPoints"
                          >
                            {/* <ExtremeLabel visible>
                            <Format type="fixedPoint" precision={2} />
                          </ExtremeLabel> */}
                          </CommonSeriesSettings>
                          <ArgumentAxis defaultVisualRange={{ startValue: 300, endValue: 500 }} />
                          <ZoomAndPan argumentAxis="both" />
                          {/* <Series argumentField="state" valueField="year2018" name="2018" /> */}
                          {/* <Series valueField="year2017" name="2017" /> */}
                          {allCallsList.map((el) => (
                            <Series valueField={el} name={el} barWidth={30} />
                          ))}
                          {/* <Series valueField="malemiddle" name="malemiddle" barWidth={30} />
                        <Series valueField="maleolder" name="maleolder" barWidth={30} />
                        <Series valueField="femaleyoung" name="femaleyoung" barWidth={30} />
                        <Series valueField="femalemiddle" name="femalemiddle" barWidth={30} />
                        <Series valueField="femaleolder" name="femaleolder" barWidth={30} /> */}
                          {/* {dispositionBarData?.map((entry, index) => (
                          <Series
                            valueField={entry}
                            name={entry}
                            barWidth={30}
                            barPadding={0}
                            stack="second"
                          />
                        ))} */}

                          <ExtremeTooltip
                            enabled
                            // location="edge"
                            customizeTooltip={customizeTooltipTrend}
                          />
                          <ExtremeLegend
                            visible={false}
                            verticalAlignment="bottom"
                            horizontalAlignment="center"
                          />
                          <ValueAxis tickInterval={1}>
                            <ExtremeTitle text="Disposition Call Count" />
                            <ExtremeLabel>
                              <Format precision={0} />
                            </ExtremeLabel>
                          </ValueAxis>
                          <ScrollBar visible position="bottom" />
                        </Chart>
                      ) : (
                        <div
                          style={{
                            width: '100%',
                            height: '80%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <span style={{ fontSize: 20 }}>
                            {!loading && <b>No Data Available</b>}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </Card>
              </Grid>
            )}
          {process.env.REACT_APP_TO_SHOW_QRC === 'true' &&
            tabValue === '2' &&
            (customerLevel === 'level_3' || customerLevel === 'level_2') && (
              <Grid item xs={12} md={12}>
                <TableComponent
                  TableinputData={QRCTableData}
                  tablecolData={[
                    'category',
                    'ai_audited_count',
                    'ai_score',
                    'manual_audited_count',
                    'manual_score',
                    'agent_positive',
                    'customer_positive'
                  ]}
                />
              </Grid>
            )}
          {process.env.REACT_APP_TO_SHOW_QRC === 'true' &&
            tabValue === '2' &&
            (customerLevel === 'level_3' || customerLevel === 'level_2') && (
              <Grid item xs={12} md={6}>
                <Card sx={{ height: 400 }}>
                  <div
                    style={{
                      padding: 10,
                      borderBottom: '1px solid #f1f3f4',
                      backgroundColor: '#F4F4F4',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <b>Disposition Wise Quality Score</b>
                  </div>

                  <BarChartUBI data={QRCChartData} />
                </Card>
              </Grid>
            )}

          {tabValue === '3' && (
            <Grid item xs={12} md={12}>
              <Card sx={{ height: 515 }}>
                {defaultCategoryData || categoryData ? (
                  <StackBarForCategory
                    chartType={chartTypeForCategory}
                    setChartType={setChartTypeForCategory}
                    categoryData={categoryData}
                    handlePopup={handleCategoryPopup}
                    handlePopupForSummary={handleKeywordPopupForSummary}
                    defaultCategoryData={defaultCategoryData}
                  />
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: '80%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <span style={{ fontSize: 20 }}>
                      <b>No Data Available</b>
                    </span>
                  </div>
                )}
              </Card>
            </Grid>
          )}
          {tabValue === '4' && (
            <Grid item xs={12} md={12}>
              <Card sx={{ height: 515 }}>
                <div
                  style={{
                    padding: 10,
                    borderBottom: '1px solid #f1f3f4',
                    backgroundColor: '#F4F4F4',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <b>Collection Info </b>
                </div>
                <>
                  {collectionData ? (
                    <CollectionBarChart
                      // chartType={chartTypeForCategory}
                      data={collectionData}
                      handleCollectionPopup={handleCollectionPopup}
                      handleDialogForCollection={handleDialogForCollection}
                      setPagePopupForCollection={setPagePopupForCollection}
                      setShownCategory={setShownCategory}
                      // handlePopup={handleCategoryPopup}
                      // handlePopupForSummary={handleKeywordPopupForSummary}
                      // defaultCategoryData={defaultCategoryData}
                    />
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: '80%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <span style={{ fontSize: 20 }}>
                        <b>No Data Available</b>
                      </span>
                    </div>
                  )}
                </>
              </Card>
            </Grid>
          )}

          <Container>
            <CustomFormModel
              fullScreen={fullScreen}
              open={open}
              title={
                (filteredUnpleasant && date && 'Unpleasant Data Details') ||
                (filteredTone && toneDate && 'Tone Data Details') ||
                (filteredSentiment && typeof dataIndex === 'number' && 'Sentiments Data Details') ||
                (filteredDeadAir && deadAirDate && 'DeadAir Data Details')
              }
              handleDialog={handleDialog}
              downloadButton
              downloadFunction={downloadGraphPopupDetails}
            >
              <Container>
                <TableContainer sx={{ minWidth: 800, maxHeight: 350 }}>
                  {loading ? (
                    <div
                      style={{
                        width: '100%',
                        height: 360,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CircularProgress sx={{ color: '#3892CF' }} size={34} thickness={4} />
                    </div>
                  ) : (
                    <Table stickyHeader aria-label="sticky table">
                      {filteredUnpleasant && date && (
                        <UserListHead headLabel={UNPLEASANT_TABLE_HEAD} />
                      )}
                      {filteredTone && toneDate && <UserListHead headLabel={TONE_TABLE_HEAD} />}
                      {filteredSentiment && typeof dataIndex === 'number' && (
                        <UserListHead headLabel={SENTIMENT_TABLE_HEAD} />
                      )}
                      {filteredDeadAir && deadAirDate && (
                        <UserListHead headLabel={DEADAIR_TABLE_HEAD} />
                      )}
                      {filteredUnpleasant && date && (
                        <TableBody>
                          {filteredUnpleasant.map((row, index) => {
                            const {
                              agent_name,
                              word_count,
                              s3url,
                              input_id,
                              request_id,
                              org_id,
                              customer_id,
                              agent_id
                            } = row;
                            // console.log('org_id', org_id, customer_id, agent_id);
                            // console.log('word_count', word_count);
                            const arr = [];
                            if (word_count) {
                              arr.push(word_count);
                            } else {
                              arr.push({});
                            }

                            return (
                              <>
                                <TableRow hover key={1} tabIndex={-1} role="checkbox">
                                  {/* <TableCell align="left">{agent_name}</TableCell> */}
                                  {arr.map((element, index) => {
                                    const obj = element;
                                    const array = Object.keys(element).filter(
                                      (item) => element[item] !== 0
                                    );
                                    // console.warn('element ---', element);
                                    // console.warn('array ----', array);
                                    if (array.length > 0) {
                                      return <TableCell align="left">{agent_name}</TableCell>;
                                    }
                                    return null;
                                  })}
                                  {arr.map((element, index) => {
                                    const obj = element;
                                    const array = Object.keys(element).filter(
                                      (item) => element[item] !== 0
                                    );
                                    // console.warn('element ---', element);
                                    // console.warn('array ----', array);
                                    if (array.length > 0) {
                                      return (
                                        <>
                                          <TableCell>{date}</TableCell>
                                          <TableCell component="th" scope="row" align="center">
                                            {array.map((unpWord) => {
                                              // console.warn('unpWord ---', unpWord);
                                              return (
                                                <Badge badgeContent={obj[unpWord]} color="primary">
                                                  <Chip label={unpWord} />
                                                </Badge>
                                              );
                                            })}
                                          </TableCell>
                                          <TableCell align="center">
                                            <Link
                                              underline="hover"
                                              sx={{ cursor: 'pointer' }}
                                              onClick={() => {
                                                navigate('/dashboard/workspace/Upload/Results', {
                                                  state: {
                                                    path: '/dashboard/app/collections',
                                                    showDownloadButton,
                                                    customerLevel,
                                                    QRCChartData,
                                                    navigateDefaultDate: defaultDate,
                                                    navigateDispositionList: dispositionList,
                                                    navigateDispositionType: dispositionType,
                                                    navigateToggleValue: toggleValue,
                                                    navigateTone: toneData,
                                                    navigateSentiment: sentimentData,
                                                    navigateCategoryData: categoryData,
                                                    navigateUnpleasant: unpleasantData,
                                                    navigateDeadAir: deadAir,
                                                    navigateCards: cardData,
                                                    navigateTonePop: newToneData,
                                                    navigateUnpleasantPop: newUnpleasantData,
                                                    navigateDeadAirPop: newDeadAirData,
                                                    navigateSentimentPop: newSentimentData,
                                                    navigateQRCData: QRCData,
                                                    navigateOverallQRCData: OverallQRCData,
                                                    formikValues: {
                                                      organisation: organisationName
                                                        ? organisationName
                                                        : null,
                                                      customer: customerName ? customerName : null,
                                                      process: processName ? processName : null,
                                                      agent: selectedAgents ? selectedAgents : null,
                                                      startDate: formik.values.startDate
                                                        ? formik.values.startDate
                                                        : null,
                                                      endDate: formik.values.endDate
                                                        ? formik.values.endDate
                                                        : null,
                                                      agentIds: formik.values.agent
                                                        ? formik.values.agent
                                                        : null
                                                    }
                                                  }
                                                });
                                                setinputId(input_id);
                                                setinputOrgId(org_id);
                                                setrequestId(request_id);
                                                // setUploadedAudio(
                                                //   decodeURI(
                                                //     s3_url
                                                //       .split('/')
                                                //       .pop()
                                                //       .split('#')[0]
                                                //       .split('?')[0]
                                                //   )
                                                // );
                                                setinputOrgId(org_id);
                                                setrequestId(request_id);
                                                setUploadedAudio(
                                                  decodeURI(
                                                    s3url
                                                      ?.split('/')
                                                      .pop()
                                                      .split('#')[0]
                                                      .split('?')[0]
                                                  )
                                                );
                                                setAudioS3(s3url);
                                                setProcess(formik.values.process);
                                                setcustomerId(customer_id);
                                                setOriginalDate(date);
                                                setAgentName(agent_name);
                                                setAgentLastName('');
                                                setAgentId(agent_id);
                                              }}
                                            >
                                              <Tip
                                                title={decodeURI(
                                                  s3url.split('/').pop().split('#')[0].split('?')[0]
                                                )}
                                                followCursor
                                                placement="top"
                                              >
                                                <Typography sx={{ fontWeight: 600 }}>
                                                  {`${decodeURI(
                                                    s3url
                                                      ?.split('/')
                                                      .pop()
                                                      .split('#')[0]
                                                      .split('?')[0]
                                                  ).slice(0, 14)}.....`}
                                                </Typography>
                                              </Tip>
                                            </Link>
                                          </TableCell>
                                        </>
                                      );
                                    }
                                    return null;
                                  })}
                                  {/* {arr.map((element, index) => {
                                    const obj = element;
                                    const array = Object.keys(element).filter(
                                      (item) => element[item] !== 0
                                    );
                                    // console.warn('element ---', element);
                                    // console.warn('array ----', array);
                                    if (array.length > 0) {
                                      return (
                                        <TableCell align="right">
                                          <Box
                                            style={{
                                              width: '75%',
                                              display: 'flex',
                                              margin: 'auto'
                                            }}
                                          >
                                            <audio
                                              controls
                                              id="transAdio"
                                              preload="auto"
                                              src={s3_url}
                                            >
                                              <source src={s3_url} />
                                              <track
                                                default
                                                kind="captions"
                                                srcLang="en"
                                                src={transcript}
                                              />
                                            </audio>
                                          </Box>
                                        </TableCell>
                                      );
                                    } */}
                                  {/* return ''; */}
                                  {/* })} */}
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      )}
                      {toneDate && filteredTone && (
                        <TableBody>
                          {/* {filteredTone
                          .filter((el) => el.agent_tone.high / 1000 > 15) */}
                          {filteredTone
                            // .slice(
                            //   pageForPopup * rowsPerPageForPopup,
                            //   pageForPopup * rowsPerPageForPopup + rowsPerPageForPopup
                            // )
                            .map((row, index) => {
                              const {
                                agent_name,
                                s3url,
                                tone,
                                input_id,
                                request_id,
                                org_id,
                                customer_id,
                                agent_id
                              } = row;
                              // const arr = Object.keys(agent_tone);
                              // console.log('agent_tone -', agent_tone);
                              return (
                                <>
                                  {tone?.total !== 0 && (
                                    <TableRow hover key={1} tabIndex={-1} role="checkbox">
                                      <TableCell align="left">{agent_name}</TableCell>
                                      <TableCell align="left">
                                        {row.original_date
                                          ? moment(row.original_date, 'YYYY-MM-DD').format(
                                              'DD/MM/YYYY'
                                            )
                                          : toneDate}
                                      </TableCell>
                                      <TableCell align="center">
                                        {/* {agent_tone?.total ? (
                                        0
                                      ) : ( */}
                                        <>
                                          {/* <Badge
                                          badgeContent={(tone?.high / 1000).toFixed(0)}
                                          color="info"
                                          sx={{
                                            marginX: 2
                                          }}
                                        > */}
                                          <Chip
                                            label={toneLabel}
                                            sx={{
                                              backgroundColor:
                                                toneLabel === 'high'
                                                  ? '#f75e5e'
                                                  : toneLabel === 'medium'
                                                  ? '#00c49f'
                                                  : toneLabel === 'low'
                                                  ? '#ffbb28'
                                                  : '#fffff'
                                            }}
                                          />
                                          {/* </Badge> */}
                                          {/* <Badge
                                          badgeContent={(tone?.medium / 1000).toFixed(0)}
                                          color="info"
                                          sx={{ marginX: 2 }}
                                        >
                                          <Chip label="Medium" />
                                        </Badge>
                                        <Badge
                                          badgeContent={(tone?.low / 1000).toFixed(0)}
                                          color="info"
                                          sx={{ marginX: 2 }}
                                        >
                                          <Chip label="Low" />
                                        </Badge> */}
                                        </>
                                        {/* )} */}
                                      </TableCell>

                                      {/* <TableCell align="left">
                                      <Box
                                        style={{
                                          width: '75%',
                                          display: 'flex',
                                          margin: 'auto'
                                        }}
                                      >
                                        <audio controls id="transAdio">
                                          <source
                                            src={agent_tone?.high !== 0 ? s3url : null}
                                            type="audio/mp3"
                                          />
                                          <track
                                            default
                                            kind="captions"
                                            srcLang="en"
                                            src={transcript}
                                          />
                                        </audio>
                                      </Box>
                                    </TableCell> */}
                                      <TableCell align="center">
                                        <Link
                                          underline="hover"
                                          sx={{ cursor: 'pointer', color: '#3892CF' }}
                                          onClick={() => {
                                            navigate('/dashboard/workspace/Upload/Results', {
                                              state: {
                                                path: '/dashboard/app/collections',
                                                showDownloadButton,
                                                QRCChartData,
                                                customerLevel,
                                                navigateDefaultDate: defaultDate,
                                                navigateDispositionList: dispositionList,
                                                navigateDispositionType: dispositionType,
                                                navigateToggleValue: toggleValue,
                                                navigateTone: toneData,
                                                navigateSentiment: sentimentData,
                                                navigateCategoryData: categoryData,
                                                navigateUnpleasant: unpleasantData,
                                                navigateDeadAir: deadAir,
                                                navigateCards: cardData,
                                                navigateTonePop: newToneData,
                                                navigateUnpleasantPop: newUnpleasantData,
                                                navigateDeadAirPop: newDeadAirData,
                                                navigateSentimentPop: newSentimentData,
                                                navigateQRCData: QRCData,
                                                navigateOverallQRCData: OverallQRCData,
                                                formikValues: {
                                                  organisation: organisationName
                                                    ? organisationName
                                                    : null,
                                                  customer: customerName ? customerName : null,
                                                  process: processName ? processName : null,
                                                  agent: selectedAgents ? selectedAgents : null,
                                                  startDate: formik.values.startDate
                                                    ? formik.values.startDate
                                                    : null,
                                                  endDate: formik.values.endDate
                                                    ? formik.values.endDate
                                                    : null,
                                                  agentIds: formik.values.agent
                                                    ? formik.values.agent
                                                    : null
                                                }
                                              }
                                            });
                                            setinputId(input_id);
                                            setinputOrgId(org_id);
                                            setrequestId(request_id);
                                            setUploadedAudio(
                                              decodeURI(
                                                s3url?.split('/').pop().split('#')[0].split('?')[0]
                                              )
                                            );
                                            setAudioS3(s3url);
                                            setProcess(formik.values.process);
                                            setcustomerId(customer_id);
                                            setOriginalDate(
                                              row.original_date
                                                ? moment(row.original_date, 'YYYY-MM-DD').format(
                                                    'DD/MM/YYYY'
                                                  )
                                                : toneDate
                                            );
                                            setAgentName(agent_name);
                                            setAgentLastName('');
                                            setAgentId(agent_id);
                                          }}
                                        >
                                          <Tip
                                            title={decodeURI(
                                              s3url?.split('/').pop().split('#')[0].split('?')[0]
                                            )}
                                            followCursor
                                            placement="top"
                                          >
                                            <Typography sx={{ fontWeight: 600 }}>
                                              {`${decodeURI(
                                                s3url?.split('/').pop().split('#')[0].split('?')[0]
                                              ).slice(0, 14)}.....`}
                                            </Typography>
                                          </Tip>
                                        </Link>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </>
                              );
                            })}
                        </TableBody>
                      )}
                      {filteredSentiment && typeof dataIndex === 'number' && (
                        <TableBody>
                          {filteredSentiment
                            // .filter((el) => el.sentiments?.NEGATIVE?.toFixed(2) >= 25)
                            // .slice(
                            //   pageForPopup * rowsPerPageForPopup,
                            //   pageForPopup * rowsPerPageForPopup + rowsPerPageForPopup
                            // )
                            .map((row, index) => {
                              const {
                                agent_name,
                                s3url,
                                sentiments,
                                input_id,
                                request_id,
                                org_id,
                                customer_id,
                                agent_id
                              } = row;
                              // const arr = Object.keys(agent_tone);
                              // console.log('filteredSentiment ---', filteredSentiment);
                              // console.log('agent_tone -', sentiments);
                              return (
                                <>
                                  {sentiments?.total !== 0 && (
                                    // sentiments?.NEGATIVE?.toFixed(2) >= 25 && (
                                    <TableRow hover key={1} tabIndex={-1} role="checkbox">
                                      <TableCell align="left">{agent_name}</TableCell>
                                      <TableCell align="left">
                                        {row.original_date
                                          ? moment(row.original_date, 'YYYY-MM-DD').format(
                                              'DD/MM/YYYY'
                                            )
                                          : sentDate}
                                      </TableCell>
                                      <TableCell align="center">
                                        <>
                                          {/* <Badge
                                          badgeContent={sentiments?.POSITIVE?.toFixed(2)}
                                          color="info"
                                          sx={{ marginX: 1 }}
                                        >
                                          <Chip
                                            label="Positive"
                                            sx={{ backgroundColor: '#65f0a6' }}
                                          />
                                        </Badge>
                                        <Badge
                                          badgeContent={sentiments?.NEGATIVE?.toFixed(2)}
                                          color="info"
                                          sx={{ marginX: 1 }}
                                        >
                                          <Chip
                                            label="Negative"
                                            sx={{ backgroundColor: '#f75e5e' }}
                                          />
                                        </Badge>
                                        <Badge
                                          badgeContent={sentiments?.NEUTRAL?.toFixed(2)}
                                          color="info"
                                          sx={{ marginX: 1 }}
                                        >
                                          <Chip
                                            label="Neutral"
                                            sx={{ backgroundColor: '#affaeb' }}
                                          />
                                        </Badge> */}
                                          <Chip
                                            label={sentimentLabel}
                                            sx={{
                                              backgroundColor:
                                                sentimentLabel === 'positive'
                                                  ? '#65f0a6'
                                                  : sentimentLabel === 'negative'
                                                  ? '#f75e5e'
                                                  : sentimentLabel === 'neutral'
                                                  ? '#affaeb'
                                                  : '#fffff'
                                            }}
                                          />
                                        </>
                                      </TableCell>

                                      {/* <TableCell align="left">
                                      <Box
                                        style={{
                                          width: '75%',
                                          display: 'flex',
                                          margin: 'auto'
                                        }}
                                      >
                                        <audio controls id="transAdio">
                                          <source
                                            src={sentiments?.NEGATIVE >= 15 ? s3url : null}
                                            type="audio/mp3"
                                          />
                                          <track
                                            default
                                            kind="captions"
                                            srcLang="en"
                                            // src={transcript}
                                          />
                                        </audio>
                                      </Box>
                                    </TableCell> */}
                                      <TableCell align="center">
                                        <Link
                                          underline="hover"
                                          sx={{ cursor: 'pointer', color: '#3892CF' }}
                                          onClick={() => {
                                            // handleDialog();
                                            navigate('/dashboard/workspace/Upload/Results', {
                                              state: {
                                                path: '/dashboard/app/collections',
                                                showDownloadButton,
                                                QRCChartData,
                                                customerLevel,
                                                navigateDefaultDate: defaultDate,
                                                navigateDispositionList: dispositionList,
                                                navigateDispositionType: dispositionType,
                                                navigateToggleValue: toggleValue,
                                                navigateTone: toneData,
                                                navigateSentiment: sentimentData,
                                                navigateCategoryData: categoryData,
                                                navigateUnpleasant: unpleasantData,
                                                navigateDeadAir: deadAir,
                                                navigateCards: cardData,
                                                navigateTonePop: newToneData,
                                                navigateUnpleasantPop: newUnpleasantData,
                                                navigateDeadAirPop: newDeadAirData,
                                                navigateSentimentPop: newSentimentData,
                                                navigateQRCData: QRCData,
                                                navigateOverallQRCData: OverallQRCData,
                                                formikValues: {
                                                  organisation: organisationName
                                                    ? organisationName
                                                    : null,
                                                  customer: customerName ? customerName : null,
                                                  process: processName ? processName : null,
                                                  agent: selectedAgents ? selectedAgents : null,
                                                  startDate: formik.values.startDate
                                                    ? formik.values.startDate
                                                    : null,
                                                  endDate: formik.values.endDate
                                                    ? formik.values.endDate
                                                    : null,
                                                  agentIds: formik.values.agent
                                                    ? formik.values.agent
                                                    : null
                                                }
                                              }
                                            });
                                            setinputId(input_id);
                                            setinputOrgId(org_id);
                                            setrequestId(request_id);
                                            setUploadedAudio(
                                              decodeURI(
                                                s3url?.split('/').pop().split('#')[0].split('?')[0]
                                              )
                                            );
                                            setAudioS3(s3url);
                                            setProcess(formik.values.process);
                                            setcustomerId(customer_id);
                                            setOriginalDate(
                                              row.original_date
                                                ? moment(row.original_date, 'YYYY-MM-DD').format(
                                                    'DD/MM/YYYY'
                                                  )
                                                : sentDate
                                            );
                                            setAgentName(agent_name);
                                            setAgentLastName('');
                                            setAgentId(agent_id);
                                          }}
                                        >
                                          <Tip
                                            title={decodeURI(
                                              s3url?.split('/').pop().split('#')[0].split('?')[0]
                                            )}
                                            followCursor
                                            placement="top"
                                          >
                                            <Typography sx={{ fontWeight: 600 }}>
                                              {`${decodeURI(
                                                s3url?.split('/').pop().split('#')[0].split('?')[0]
                                              ).slice(0, 14)}.....`}
                                            </Typography>
                                          </Tip>
                                        </Link>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </>
                              );
                            })}
                        </TableBody>
                      )}
                      {filteredDeadAir && deadAirDate && (
                        <TableBody>
                          {filteredDeadAir.map((row, index) => {
                            const {
                              agent_name,
                              s3url,
                              dead_air,
                              dead_time,
                              input_id,
                              request_id,
                              agent_id,
                              org_id,
                              customer_id
                            } = row;
                            // const arr = Object.keys(agent_tone);
                            // console.log('dead_Air----', dead_air);
                            return (
                              <>
                                {dead_air !== 0 && (
                                  <TableRow hover key={1} tabIndex={-1} role="checkbox">
                                    <TableCell align="left">{agent_name}</TableCell>
                                    <TableCell align="left">{deadAirDate}</TableCell>
                                    <TableCell align="center">{dead_air}</TableCell>
                                    <TableCell align="center">
                                      {dead_time?.map((item) => (
                                        <Box
                                          sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginBottom: '2px'
                                          }}
                                        >
                                          <Chip
                                            label={`Start Time-${item[0]}`}
                                            color="primary"
                                            size="small"
                                          />
                                          <Chip
                                            label={`End Time-${Math.ceil(item[1])}`}
                                            color="primary"
                                            size="small"
                                          />
                                        </Box>
                                      ))}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      onClick={() => {
                                        handleDialog();
                                        navigate('/dashboard/workspace/Upload/Results', {
                                          state: {
                                            path: '/dashboard/app/collections',
                                            showDownloadButton,
                                            QRCChartData,
                                            customerLevel,
                                            navigateDefaultDate: defaultDate,
                                            navigateDispositionList: dispositionList,
                                            navigateDispositionType: dispositionType,
                                            navigateToggleValue: toggleValue,
                                            navigateTone: toneData,
                                            navigateSentiment: sentimentData,
                                            navigateCategoryData: categoryData,
                                            navigateUnpleasant: unpleasantData,
                                            navigateDeadAir: deadAir,
                                            navigateCards: cardData,
                                            navigateTonePop: newToneData,
                                            navigateUnpleasantPop: newUnpleasantData,
                                            navigateDeadAirPop: newDeadAirData,
                                            navigateSentimentPop: newSentimentData,
                                            navigateQRCData: QRCData,
                                            navigateOverallQRCData: OverallQRCData,
                                            formikValues: {
                                              organisation: organisationName
                                                ? organisationName
                                                : null,
                                              customer: customerName ? customerName : null,
                                              process: processName ? processName : null,
                                              agent: selectedAgents ? selectedAgents : null,
                                              startDate: formik.values.startDate
                                                ? formik.values.startDate
                                                : null,
                                              endDate: formik.values.endDate
                                                ? formik.values.endDate
                                                : null,
                                              agentIds: formik.values.agent
                                                ? formik.values.agent
                                                : null
                                            }
                                          }
                                        });
                                        setinputId(input_id);
                                        setinputOrgId(org_id);
                                        setrequestId(request_id);
                                        setUploadedAudio(
                                          decodeURI(
                                            s3url?.split('/').pop().split('#')[0].split('?')[0]
                                          )
                                        );
                                        setAudioS3(s3url);
                                        setProcess(formik.values.process);
                                        setcustomerId(customer_id);
                                        setOriginalDate(date);
                                        setAgentName(agent_name);
                                        setAgentLastName('');
                                        setAgentId(agent_id);
                                      }}
                                    >
                                      <Link underline="hover" sx={{ cursor: 'pointer' }}>
                                        {decodeURI(
                                          s3url?.split('/').pop().split('#')[0].split('?')[0]
                                        )}
                                      </Link>
                                    </TableCell>
                                    {/* <TableCell align="left">
                                      <Box
                                        style={{
                                          width: '75%',
                                          display: 'flex',
                                          margin: 'auto'
                                        }}
                                      >
                                        <audio controls id={`transAdio${index}`}>
                                          <source src={s3url} type="audio/mp3" />
                                          <track
                                            default
                                            kind="captions"
                                            srcLang="en"
                                            // src={transcript}
                                          />
                                        </audio>
                                      </Box>
                                    </TableCell> */}
                                  </TableRow>
                                )}
                              </>
                            );
                          })}
                        </TableBody>
                      )}
                    </Table>
                  )}
                </TableContainer>
                {toneDate && filteredTone && (
                  // <TablePagination
                  //   rowsPerPageOptions={[5, 10, 25]}
                  //   rowsPerPage={rowsPerPageForPopup}
                  //   component="div"
                  //   count={Math.ceil(filteredTone.length)}
                  //   onRowsPerPageChange={handleChangeRowsPerPageForPopup}
                  //   page={pageForPopup}
                  //   onPageChange={handleChangePageForPopup}
                  // />
                  <div
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                  >
                    <Pagination
                      classes={{
                        root: classes.ul
                      }}
                      count={Math.ceil(overallCount / 10)}
                      page={pagePopup}
                      onChange={handleChangePagePopup}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                )}
                {filteredSentiment && typeof dataIndex === 'number' && !loading && (
                  <div
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                  >
                    <Pagination
                      classes={{
                        root: classes.ul
                      }}
                      count={Math.ceil(overallCount / 10)}
                      page={pagePopup}
                      onChange={handleChangePagePopup}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                  // <TablePagination
                  //   rowsPerPageOptions={[5, 10, 25]}
                  //   rowsPerPage={rowsPerPageForPopup}
                  //   component="div"
                  //   count={Math.ceil(filteredSentiment.length)}
                  //   onRowsPerPageChange={handleChangeRowsPerPageForPopup}
                  //   page={pageForPopup}
                  //   onPageChange={handleChangePageForPopup}
                  // />
                )}
                {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginY: '5px' }}>
                    <Button
                      variant="contained"
                      onClick={(e) => handleDialog()}
                      style={{ marginRight: 5 }}
                    >
                      Close
                    </Button>
                  </div> */}
              </Container>
            </CustomFormModel>
            <CustomFormModel
              fullScreen={fullScreen}
              open={subscriptionOpen}
              title="Subscription Details"
              handleDialog={handleSubscription}
            >
              <Container
                style={{
                  padding: 10
                }}
              >
                <TableContainer sx={{ minWidth: '100%', maxHeight: 390 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                        >
                          Org Name
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                        >
                          Customer Org Name
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                        >
                          Total files Processed
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                        >
                          Total time Processed (min)
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                        >
                          Remaining Time
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                        >
                          Allocated Time
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dummySubscription.map((element) => (
                        <TableRow>
                          <TableCell align="left">{element.org_name}</TableCell>
                          <TableCell align="center">{element.customer_name}</TableCell>
                          <TableCell align="center">{element.process_count}</TableCell>
                          <TableCell align="center">{element.processed_time}</TableCell>
                          <TableCell align="center">{element.rem_time}</TableCell>
                          <TableCell align="center">{element.allocated_time}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </CustomFormModel>
            <CustomFormModel
              fullScreen={fullScreen}
              open={keyWordlistOpen}
              title={`${showKeyWord} keyword related Calls`}
              handleDialog={handleKeywordListPopup}
              downloadButton
              downloadFunction={downloadGraphPopupDetails}
            >
              <Container
                style={{
                  padding: 10
                }}
              >
                <TableContainer sx={{ minWidth: 810, maxHeight: 340 }}>
                  <Table stickyHeader aria-label="sticky table">
                    {keywordListData && (
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              fontWeight: 'bold',
                              backgroundColor: '#f8f6fa'
                            }}
                          >
                            Agent Name
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontWeight: 'bold',
                              backgroundColor: '#f8f6fa'
                            }}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontWeight: 'bold',
                              backgroundColor: '#f8f6fa'
                            }}
                          >
                            Word Detail
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontWeight: 'bold',
                              backgroundColor: '#f8f6fa'
                            }}
                          >
                            Audio Duration
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontWeight: 'bold',
                              backgroundColor: '#f8f6fa'
                            }}
                          >
                            View
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    )}
                    {/* {keywordListData && <UserListHead headLabel={TONE_TABLE_HEAD} />} */}
                    {keywordListData && !loading ? (
                      <TableBody>
                        {/* {filteredTone
                          .filter((el) => el.agent_tone.high / 1000 > 15) */}

                        {keywordListData
                          .slice(
                            pageForPopup * rowsPerPageForPopup,
                            pageForPopup * rowsPerPageForPopup + rowsPerPageForPopup
                          )
                          .map((row, index) => {
                            const {
                              agent_name,
                              s3url,
                              keywords,
                              input_id,
                              request_id,
                              org_id,
                              customer_id,
                              agent_id,
                              original_date,
                              call_duration
                            } = row;
                            // const arr = Object.keys(agent_tone);
                            // console.log('agent_tone -', agent_tone);
                            return (
                              <>
                                <TableRow hover key={1} tabIndex={-1} role="checkbox">
                                  <TableCell align="left">{agent_name}</TableCell>
                                  <TableCell align="center">
                                    {moment(row.original_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                  </TableCell>
                                  <TableCell align="center">
                                    {keywords && Object.keys(keywords).length > 0
                                      ? Object.keys(keywords).map((word) => (
                                          <Badge badgeContent={keywords[word]} color="info">
                                            <Chip label={word} />
                                          </Badge>
                                        ))
                                      : '-'}
                                  </TableCell>
                                  <TableCell align="center">
                                    {call_duration ? formattedDuration(call_duration) : '-'}
                                  </TableCell>

                                  <TableCell align="center">
                                    <Link
                                      underline="hover"
                                      sx={{ cursor: 'pointer', color: '#3892CF' }}
                                      onClick={() => {
                                        navigate('/dashboard/workspace/Upload/Results', {
                                          state: {
                                            path: '/dashboard/app/collections',
                                            showDownloadButton,
                                            QRCChartData,
                                            customerLevel,
                                            navigateDefaultDate: defaultDate,
                                            navigateDispositionList: dispositionList,
                                            navigateDispositionType: dispositionType,
                                            navigateToggleValue: toggleValue,
                                            navigateTone: toneData,
                                            navigateSentiment: sentimentData,
                                            navigateCategoryData: categoryData,
                                            navigateUnpleasant: unpleasantData,
                                            navigateDeadAir: deadAir,
                                            navigateCards: cardData,
                                            navigateTonePop: newToneData,
                                            navigateUnpleasantPop: newUnpleasantData,
                                            navigateDeadAirPop: newDeadAirData,
                                            navigateSentimentPop: newSentimentData,
                                            navigateQRCData: QRCData,
                                            navigateOverallQRCData: OverallQRCData,
                                            formikValues: {
                                              organisation: organisationName
                                                ? organisationName
                                                : null,
                                              customer: customerName ? customerName : null,
                                              process: processName ? processName : null,
                                              agent: selectedAgents ? selectedAgents : null,
                                              startDate: formik.values.startDate
                                                ? formik.values.startDate
                                                : null,
                                              endDate: formik.values.endDate
                                                ? formik.values.endDate
                                                : null,
                                              agentIds: formik.values.agent
                                                ? formik.values.agent
                                                : null
                                            }
                                          }
                                        });
                                        setinputId(input_id);
                                        setinputOrgId(org_id);
                                        setrequestId(request_id);
                                        setUploadedAudio(
                                          decodeURI(
                                            s3url?.split('/').pop().split('#')[0].split('?')[0]
                                          )
                                        );
                                        setAudioS3(s3url);
                                        setProcess(formik.values.process);
                                        setcustomerId(customer_id);
                                        setOriginalDate(
                                          moment(row.original_date, 'YYYY-MM-DD').format(
                                            'DD/MM/YYYY'
                                          )
                                        );
                                        setAgentName(agent_name);
                                        setAgentLastName('');
                                        setAgentId(agent_id);
                                      }}
                                    >
                                      <Tip
                                        title={decodeURI(
                                          s3url?.split('/').pop().split('#')[0].split('?')[0]
                                        )}
                                        followCursor
                                        placement="top"
                                      >
                                        <Typography sx={{ fontWeight: 600 }}>
                                          {`${decodeURI(
                                            s3url?.split('/').pop().split('#')[0].split('?')[0]
                                          ).slice(0, 14)}.....`}
                                        </Typography>
                                      </Tip>
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                      </TableBody>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '80%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {loading ? (
                          <CircularProgress sx={{ color: '#3892CF' }} />
                        ) : (
                          <span style={{ fontSize: 20 }}>
                            <b>No Data Available</b>
                          </span>
                        )}
                      </div>
                    )}
                  </Table>
                </TableContainer>
                {keywordListData && (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    rowsPerPage={rowsPerPageForPopup}
                    component="div"
                    count={Math.ceil(keywordListData.length)}
                    onRowsPerPageChange={handleChangeRowsPerPageForPopup}
                    page={pageForPopup}
                    onPageChange={handleChangePageForPopup}
                  />
                )}
              </Container>
            </CustomFormModel>
            <CustomFormModel
              fullScreen={fullScreen}
              open={categoryDetailsOpen}
              title={`${shownCategory} Category Details`}
              handleDialog={handleCategoryDialog}
              downloadFunction={downloadGraphPopupDetails}
              downloadButton
            >
              <Container>
                {newCategoryData && !loading ? (
                  <>
                    <TableContainer sx={{ minWidth: '100%', maxHeight: 350 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="left"
                              sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                            >
                              Agent Name
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                            >
                              Date
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                            >
                              Words
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                            >
                              View
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {newCategoryData && newCategoryData?.length > 0 && (
                          <TableBody>
                            {newCategoryData
                              .filter(
                                (element) =>
                                  Object?.keys(element?.keywords[shownCategory])?.length > 0
                              )
                              .slice(
                                pageForPopup * rowsPerPageForPopup,
                                pageForPopup * rowsPerPageForPopup + rowsPerPageForPopup
                              )
                              .map((ele) => {
                                // console.log('eleeeeeeeeeee', ele);
                                // let dynamicValues;
                                // newCategoryData.forEach((item) => {
                                //   const dynamicKey = Object.keys(item.keywords)[0];
                                //   dynamicValues = item.keywords[dynamicKey];

                                //   return dynamicValues;
                                // });
                                const category = Object.keys(ele.keywords);
                                const array =
                                  ele.keywords &&
                                  shownCategory &&
                                  ele.keywords[shownCategory] !== undefined &&
                                  Object?.keys(ele.keywords[shownCategory])?.length > 0
                                    ? Object.keys(ele.keywords[shownCategory])
                                    : [];
                                console.log('array Object', ele.keywords[shownCategory]);

                                return (
                                  <>
                                    <TableRow>
                                      <TableCell component="th" scope="row" align="left">
                                        {ele.agent_name}
                                      </TableCell>
                                      <TableCell component="th" scope="row" align="center">
                                        {moment(ele.original_date, 'YYYY-MM-DD').format(
                                          'DD/MM/YYYY'
                                        )}
                                      </TableCell>
                                      {array.length > 0 ? (
                                        <TableCell component="th" scope="row" align="center">
                                          {array.map((word) => (
                                            <Badge
                                              badgeContent={ele.keywords[shownCategory][word]}
                                              color="info"
                                            >
                                              <Chip label={word} />
                                            </Badge>
                                          ))}
                                        </TableCell>
                                      ) : (
                                        <TableCell align="center">No Words</TableCell>
                                      )}
                                      {/* <TableCell component="th" scope="row" align="center">
                                  {decodeURI(
                                    ele.s3url.split('/').pop().split('#')[0].split('?')[0]
                                  )}
                                </TableCell> */}
                                      <TableCell align="center">
                                        <Link
                                          underline="hover"
                                          sx={{ cursor: 'pointer', color: '#3892CF' }}
                                          onClick={() => {
                                            handleDialog();
                                            navigate('/dashboard/workspace/Upload/Results', {
                                              state: {
                                                path: '/dashboard/app/collections',
                                                showDownloadButton,
                                                QRCChartData,
                                                customerLevel,
                                                navigateDefaultDate: defaultDate,
                                                navigateDispositionList: dispositionList,
                                                navigateDispositionType: dispositionType,
                                                navigateToggleValue: toggleValue,
                                                navigateTone: toneData,
                                                navigateSentiment: sentimentData,
                                                navigateCategoryData: categoryData,
                                                navigateUnpleasant: unpleasantData,
                                                navigateDeadAir: deadAir,
                                                navigateCards: cardData,
                                                navigateTonePop: newToneData,
                                                navigateUnpleasantPop: newUnpleasantData,
                                                navigateDeadAirPop: newDeadAirData,
                                                navigateSentimentPop: newSentimentData,
                                                navigateQRCData: QRCData,
                                                navigateOverallQRCData: OverallQRCData,
                                                formikValues: {
                                                  organisation: organisationName
                                                    ? organisationName
                                                    : null,
                                                  customer: customerName ? customerName : null,
                                                  process: processName ? processName : null,
                                                  agent: selectedAgents ? selectedAgents : null,
                                                  startDate: formik.values.startDate
                                                    ? formik.values.startDate
                                                    : null,
                                                  endDate: formik.values.endDate
                                                    ? formik.values.endDate
                                                    : null,
                                                  agentIds: formik.values.agent
                                                    ? formik.values.agent
                                                    : null
                                                }
                                              }
                                            });
                                            setinputId(ele.input_id);
                                            setinputOrgId(ele.org_id);
                                            setrequestId(ele.request_id);
                                            // setUploadedAudio(
                                            //   decodeURI(
                                            //     s3_url
                                            //       .split('/')
                                            //       .pop()
                                            //       .split('#')[0]
                                            //       .split('?')[0]
                                            //   )
                                            // );
                                            setinputOrgId(ele.org_id);
                                            setrequestId(ele.request_id);
                                            setUploadedAudio(
                                              decodeURI(
                                                ele.s3url
                                                  .split('/')
                                                  .pop()
                                                  .split('#')[0]
                                                  .split('?')[0]
                                              )
                                            );
                                            setAudioS3(ele.s3url);
                                            setProcess(formik.values.process);
                                            setcustomerId(ele.customer_id);
                                            setOriginalDate(
                                              moment(ele.original_date, 'YYYY-MM-DD').format(
                                                'DD/MM/YYYY'
                                              )
                                            );
                                            setAgentName(ele.agent_name);
                                            setAgentLastName('');
                                            setAgentId(ele.agent_id);
                                          }}
                                        >
                                          <Tip
                                            title={decodeURI(
                                              ele.s3url.split('/').pop().split('#')[0].split('?')[0]
                                            )}
                                            followCursor
                                            placement="top"
                                          >
                                            <Typography sx={{ fontWeight: 600 }}>
                                              {`${decodeURI(
                                                ele.s3url
                                                  .split('/')
                                                  .pop()
                                                  .split('#')[0]
                                                  .split('?')[0]
                                              ).slice(0, 14)}.....`}
                                            </Typography>
                                          </Tip>
                                        </Link>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      rowsPerPage={rowsPerPageForPopup}
                      component="div"
                      count={
                        newCategoryData &&
                        newCategoryData?.length > 0 &&
                        Math.ceil(
                          newCategoryData.filter(
                            (element) => Object?.keys(element?.keywords[shownCategory])?.length > 0
                          )?.length
                        )
                      }
                      onRowsPerPageChange={handleChangeRowsPerPageForPopup}
                      page={pageForPopup}
                      onPageChange={handleChangePageForPopup}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: '80%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <span style={{ fontSize: 20 }}>
                      <b>No Data Available</b>
                    </span>
                  </div>
                )}
              </Container>
            </CustomFormModel>
            <CustomFormModel
              fullScreen={fullScreen}
              open={keywordDetailsOpen}
              title={`${shownCategory} Category Details`}
              handleDialog={handleDialogForSummary}
              downloadButton
              setDownloadChart={setchartDownload}
              downloadFunction={downloadCSVForCategoryDetails}
              downloadStart={downloadStart}
            >
              {keywordsGraphData && !loading ? (
                <div>
                  <BarChart
                    width={800}
                    height={400}
                    data={keywordsGraphData}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20
                    }}
                  >
                    <XAxis
                      dataKey="keyword"
                      fontFamily="Roboto, sans-serif"
                      // fontSize={12}
                      reversed
                      fontWeight="bold"
                      tick={{
                        fontFamily: 'cursive, sans-serif',
                        fontSize: 10,
                        fontWeight: 'bold'
                      }}
                      tickLine={false}
                    />
                    <YAxis
                      label={{
                        value: 'Count',
                        angle: -90,
                        position: 'insideLeft',
                        fontWeight: 'bold',
                        color: 'grey'
                      }}
                      tick={{
                        fontFamily: 'cursive, sans-serif',
                        fontSize: 10,
                        fontWeight: 'bold'
                      }}
                      tickLine={false}
                      fontWeight="bold"
                    />
                    <Tooltip content={CustomTooltipForKeywords} />
                    <Bar
                      // key={index}
                      barSize={25}
                      dataKey="count"
                      onClick={(e) => {
                        // handleKeywordPopupForSummary();
                        // handleCategoryDialog();
                        handleKeywordCountListAPi(e.keyword, shownCategory);
                      }}
                      stackId="a"
                      fill="grey"
                      // onMouseOver={(e) => {
                      //   // console.log(e);
                      //   setBarName(entry.name);
                      // }}
                      style={{ opacity: 0.9, borderRadius: 5, cursor: 'pointer' }}
                    />
                    <Legend content={<CustomLegendForKeyword />} />
                  </BarChart>
                </div>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: '#3892CF' }} />
                  ) : (
                    <span style={{ fontSize: 20 }}>
                      <b>No Data Available</b>
                    </span>
                  )}
                </div>
              )}
            </CustomFormModel>
            <CustomFormModel
              fullScreen={fullScreen}
              open={QRCOpen}
              title={`${shownQRCDetails} Details`}
              handleDialog={handleDialogForQRCList}
            >
              <Container
                style={{
                  padding: 10
                }}
              >
                <TableContainer sx={{ minWidth: '100%', maxHeight: 390 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                        >
                          Agent Name
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                        >
                          Upload Date
                        </TableCell>
                        {/* <TableCell
                          align="center"
                          sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                        >
                          Sentences
                        </TableCell> */}
                        <TableCell
                          align="center"
                          sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                        >
                          File
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {QRCList?.length > 0 && (
                      <TableBody>
                        {QRCList?.slice(
                          pageForPopup * rowsPerPageForPopup,
                          pageForPopup * rowsPerPageForPopup + rowsPerPageForPopup
                        ).map((row, index) => {
                          const {
                            agent_name,
                            s3url,
                            input_id,
                            request_id,
                            org_id,
                            customer_id,
                            agent_id,
                            original_date
                          } = row;

                          return (
                            <>
                              <TableRow hover key={1} tabIndex={-1} role="checkbox">
                                <TableCell align="left">{agent_name}</TableCell>
                                <TableCell align="left">
                                  {moment(original_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                </TableCell>

                                {/* <TableCell align="center">
                                  <IconButton onClick={() => handleDispositonSentences(row)}>
                                    <Icon icon={eyeIcon} width={20} height={20} />
                                  </IconButton>
                                </TableCell> */}

                                <TableCell align="center">
                                  <Link
                                    underline="hover"
                                    sx={{ cursor: 'pointer', color: '#3892CF' }}
                                    onClick={() => {
                                      navigate('/dashboard/workspace/Upload/Results', {
                                        state: {
                                          path: '/dashboard/app/collections',
                                          showDownloadButton,
                                          QRCChartData,
                                          customerLevel,
                                          navigateDefaultDate: defaultDate,
                                          navigateDispositionList: dispositionList,
                                          navigateDispositionType: dispositionType,
                                          navigateToggleValue: toggleValue,
                                          navigateTone: toneData,
                                          navigateSentiment: sentimentData,
                                          navigateCategoryData: categoryData,
                                          navigateUnpleasant: unpleasantData,
                                          navigateDeadAir: deadAir,
                                          navigateCards: cardData,
                                          navigateTonePop: newToneData,
                                          navigateUnpleasantPop: newUnpleasantData,
                                          navigateDeadAirPop: newDeadAirData,
                                          navigateSentimentPop: newSentimentData,
                                          navigateQRCData: QRCData,
                                          navigateOverallQRCData: OverallQRCData,
                                          formikValues: {
                                            organisation: organisationName
                                              ? organisationName
                                              : null,
                                            customer: customerName ? customerName : null,
                                            process: processName ? processName : null,
                                            agent: selectedAgents ? selectedAgents : null,
                                            startDate: formik.values.startDate
                                              ? formik.values.startDate
                                              : null,
                                            endDate: formik.values.endDate
                                              ? formik.values.endDate
                                              : null,
                                            agentIds: formik.values.agent
                                              ? formik.values.agent
                                              : null
                                          }
                                        }
                                      });
                                      setinputId(input_id);
                                      setinputOrgId(org_id);
                                      setrequestId(request_id);
                                      setUploadedAudio(
                                        decodeURI(
                                          s3url?.split('/').pop().split('#')[0].split('?')[0]
                                        )
                                      );
                                      setAudioS3(s3url);
                                      setProcess(formik.values.process);
                                      setcustomerId(customer_id);
                                      setOriginalDate(
                                        moment(original_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                                      );
                                      setAgentName(agent_name);
                                      setAgentLastName('');
                                      setAgentId(agent_id);
                                    }}
                                  >
                                    <Tip
                                      title={decodeURI(
                                        s3url?.split('/').pop().split('#')[0].split('?')[0]
                                      )}
                                      followCursor
                                      placement="top"
                                    >
                                      <Typography sx={{ fontWeight: 600 }}>
                                        {`${decodeURI(
                                          s3url?.split('/').pop().split('#')[0].split('?')[0]
                                        ).slice(0, 14)}.....`}
                                      </Typography>
                                    </Tip>
                                  </Link>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                {QRCList?.length > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    rowsPerPage={rowsPerPageForPopup}
                    component="div"
                    count={Math.ceil(QRCList?.length)}
                    onRowsPerPageChange={handleChangeRowsPerPageForPopup}
                    page={pageForPopup}
                    onPageChange={handleChangePageForPopup}
                  />
                )}
              </Container>
            </CustomFormModel>
            <CustomFormModel
              fullScreen={fullScreen}
              open={isDispositionSentenceOpen}
              title={`${dispositionType} Sentences `}
              handleDialog={() => setIsDispositionSentenceOpen(!isDispositionSentenceOpen)}
            >
              <Container>
                {shownSentences?.length > 0 ? (
                  <>
                    <TableContainer sx={{ minWidth: '100%', maxHeight: 350 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="left"
                              sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                            >
                              Sr.No
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                            >
                              Sentence
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {shownSentences.map((el, index) => (
                            <TableRow>
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">{el.Content.join(',')}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: '80%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <span style={{ fontSize: 20 }}>
                      <b>No Data Available</b>
                    </span>
                  </div>
                )}
              </Container>
            </CustomFormModel>
            <CustomFormModel
              fullScreen={fullScreen}
              open={collectionPopupOpen}
              title={`${shownCategory} Details`}
              handleDialog={handleDialogForCollection}
              downloadButton
              downloadFunction={downloadGraphPopupDetails}
            >
              {!loading ? (
                <Container
                  style={{
                    padding: 10
                  }}
                >
                  <TableContainer sx={{ minWidth: '100%', maxHeight: 350 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                          >
                            Agent Name
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                          >
                            Upload Date
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{ fontWeight: 'bold', backgroundColor: '#f8f6fa' }}
                          >
                            File
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      {collectionPopupData &&
                        collectionPopupData?.results?.collection_calls.length > 0 && (
                          <TableBody>
                            {collectionPopupData?.results?.collection_calls?.map((row, index) => {
                              const {
                                agent_name,
                                s3url,
                                input_id,
                                request_id,
                                org_id,
                                customer_id,
                                agent_id,
                                original_date
                              } = row;

                              return (
                                <>
                                  <TableRow hover key={1} tabIndex={-1} role="checkbox">
                                    <TableCell align="left">{agent_name}</TableCell>
                                    <TableCell align="left">
                                      {moment(original_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </TableCell>
                                    {/* 
                                <TableCell align="center">
                                  <IconButton onClick={() => handleDispositonSentences(row)}>
                                    <Icon icon={eyeIcon} width={20} height={20} />
                                  </IconButton>
                                </TableCell> */}

                                    <TableCell align="center">
                                      <Link
                                        underline="hover"
                                        sx={{ cursor: 'pointer', color: '#3892CF' }}
                                        onClick={() => {
                                          navigate('/dashboard/workspace/Upload/Results', {
                                            state: {
                                              path: '/dashboard/app/collections',
                                              showDownloadButton,
                                              customerLevel,
                                              QRCChartData,
                                              navigateDefaultDate: defaultDate,
                                              navigateDispositionList: dispositionList,
                                              navigateDispositionType: dispositionType,
                                              navigateToggleValue: toggleValue,
                                              navigateTone: toneData,
                                              navigateSentiment: sentimentData,
                                              navigateCategoryData: categoryData,
                                              navigateUnpleasant: unpleasantData,
                                              navigateDeadAir: deadAir,
                                              navigateCards: cardData,
                                              navigateTonePop: newToneData,
                                              navigateUnpleasantPop: newUnpleasantData,
                                              navigateDeadAirPop: newDeadAirData,
                                              navigateSentimentPop: newSentimentData,
                                              navigateQRCData: QRCData,
                                              navigateOverallQRCData: OverallQRCData,
                                              formikValues: {
                                                organisation: organisationName
                                                  ? organisationName
                                                  : null,
                                                customer: customerName ? customerName : null,
                                                process: processName ? processName : null,
                                                agent: selectedAgents ? selectedAgents : null,
                                                startDate: formik.values.startDate
                                                  ? formik.values.startDate
                                                  : null,
                                                endDate: formik.values.endDate
                                                  ? formik.values.endDate
                                                  : null,
                                                agentIds: formik.values.agent
                                                  ? formik.values.agent
                                                  : null
                                              }
                                            }
                                          });
                                          setinputId(input_id);
                                          setinputOrgId(org_id);
                                          setrequestId(request_id);
                                          setUploadedAudio(
                                            decodeURI(
                                              s3url?.split('/').pop().split('#')[0].split('?')[0]
                                            )
                                          );
                                          setAudioS3(s3url);
                                          setProcess(formik.values.process);
                                          setcustomerId(customer_id);
                                          setOriginalDate(
                                            moment(original_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                                          );
                                          setAgentName(agent_name);
                                          setAgentLastName('');
                                          setAgentId(agent_id);
                                        }}
                                      >
                                        <Tip
                                          title={decodeURI(
                                            s3url?.split('/').pop().split('#')[0].split('?')[0]
                                          )}
                                          followCursor
                                          placement="top"
                                        >
                                          <Typography sx={{ fontWeight: 600 }}>
                                            {`${decodeURI(
                                              s3url?.split('/').pop().split('#')[0].split('?')[0]
                                            ).slice(0, 14)}.....`}
                                          </Typography>
                                        </Tip>
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            })}
                          </TableBody>
                        )}
                    </Table>
                  </TableContainer>
                  {collectionPopupData?.results?.collection_calls?.length > 0 && (
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                    >
                      <Pagination
                        classes={{
                          root: classes.ul
                        }}
                        count={Math.ceil(collectionPopupData.count / 10)}
                        page={pagePopupForCollection}
                        onChange={handleChangePageCollection}
                        showFirstButton
                        showLastButton
                      />
                    </div>
                  )}
                </Container>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress sx={{ color: '#3892CF' }} />
                </Box>
              )}
            </CustomFormModel>
          </Container>
        </Grid>
      </>
      {/* ) : ( */}
      {loading && (
        <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress sx={{ color: '#3892CF' }} />
          </Box>
        </Backdrop>
      )}
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </Page>
  );
}
