import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Box } from '@mui/material';
// layouts
import AnimationControl from '../components/animation-control/animationControl';
// components
import Page from '../components/Page';

import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 650,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();
  return (
    <RootStyle title="Login">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography
            variant="h4"
            sx={{ px: 1.5, mt: 2, mb: 2, textAlign: 'center', color: '#2A236B' }}
          >
            Enhance Customer Experience using State-of-the-Art AI Powered Conversational
            Intelligence
          </Typography>
          <div style={{ width: '100%', height: '4px', backgroundColor: '#35B5A8' }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '10px'
            }}
          >
            <Typography
              variant="p"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#2A236B',
                fontSize: '14px'
              }}
            >
              Monitor Compliances. Improve Advisor Performance. Understand Customer Sentiments.
            </Typography>
            <Typography
              variant="p"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#2A236B',
                fontSize: '14px'
              }}
            >
              Uplift Customer Service
            </Typography>
          </Box>
          <AnimationControl />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Sign in
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>

          <LoginForm />

          {/* <MHidden width="smUp"> */}
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            Don’t have an account?&nbsp;
            <Link
              variant="subtitle2"
              // component={RouterLink}
              onClick={() => navigate('/register')}
              sx={{ cursor: 'pointer', color: '#3892CF' }}
            >
              Signup as Guest
            </Link>
          </Typography>
          {/* </MHidden> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
