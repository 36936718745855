import React, { useState, useRef, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { FormikProvider, Form, FieldArray } from 'formik';
import {
  Grid,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Container,
  FormHelperText,
  Alert,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { LoadingButton } from '@mui/lab';
import LinearProgress, { LinearProgressProps as props } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import DatePicker from 'react-datepicker';
import './CampaignForm.css';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import API from '../../E2E/axios.util';
import { Notification } from '../../components/Notification/Notification';
// components

// ----------------------------------------------------------------------

const CampaignForm = ({ formik, handleDialog, uploaded }) => {
  const inputRef = useRef(null);
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const [userAudio, setuserAudio] = useState({ File_Name: '', Data: '' });

  const userDetails = JSON.parse(localStorage.getItem('user'));

  /* ----- Creating state for customer, process and agent ------ */
  const [customerList, setcustomerList] = useState();
  const [processList, setprocessList] = useState();
  const [agentList, setagentList] = useState();
  const [OrganisationList, setOrganisationList] = useState();
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');

  const handleCancel = () => {
    handleDialog();
    formik.resetForm();
  };
  const handleAlert = () => {
    setAlert(false);
  };

  const handleAudioFileChange = (event, fileName) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (fileReader.readyState === 2) {
        const file = e.target.result;
        setuserAudio({ File_Name: fileName.name, Data: file });
        formik.setFieldValue(`uploadFile`, { File_Name: fileName.name, Data: file });
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setOrganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/list_of_customer?organisation_id=${formik.values.organisationId}`,
      config
    )
      .then((res) => {
        setcustomerList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formik.values.organisationId !== '') {
      getCustomerList();
    } else {
      setcustomerList([]);
    }
  }, [formik.values.organisationId]);

  const getProccesList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `secure/process_list?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisationId}`,
        config
      );
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   if (formik.values.customer !== '') {
  //     getProccesList();
  //   } else {
  //     setprocessList([]);
  //   }
  // }, [formik.values.customer]);

  const getAgentList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getRole() {
      return API.get(
        `secure/list_of_agent?customer_id=${formik.values.customer}&organisation_id=${formik.values.organisationId}&processes=${formik.values.process}`,
        config
      );
    }
    Promise.all([getRole()])
      .then(([agentData]) => {
        setagentList(agentData.data.agent_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   console.log(formik.values.process, 'formik.values.process');
  //   if (formik.values.process.length > 0) {
  //     getAgentList();
  //   } else {
  //     setagentList([]);
  //   }
  // }, [formik.values.process]);

  const setCustomerValue = (value) => {
    formik.setFieldValue('customer', value.customer_id);
    formik.setFieldValue('domainId', value.domain_id);
  };

  const handleProcess = (processValue) => {
    formik.setFieldValue('process', [processValue]);
  };

  // ---------------------------------------------
  const [audios, setAudios] = useState(formik.values.uploadFile);

  const setDateFormat = (date) => {
    const formatStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return formatStartDate;
  };

  const handleCheckInDate = (date) => {
    console.log('Date-----', typeof Date());
    console.log('date-----', date);
    setCheckInDate(date);
    setCheckOutDate('');
    formik.setFieldValue('endDate', '');
    const start_date = setDateFormat(date);
    formik.setFieldValue('startDate', start_date);
  };

  const handleCheckOutDate = (date) => {
    setCheckOutDate(date);
    const start_date = setDateFormat(date);
    formik.setFieldValue('endDate', start_date);
  };

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));

  const classes = useStyles();

  const CustomInput = React.forwardRef((props, ref) => (
    <div style={{ position: 'relative' }}>
      <input {...props} ref={ref} />
      <CalendarMonthIcon
        onClick={props.onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '10px',
          // pointerEvents: 'none',
          cursor: 'pointer',
          zIndex: 0
        }}
      />
    </div>
  ));

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={6}>
            {userDetails.role !== 'Super_Admin' ? (
              <TextField
                required
                fullWidth
                label="Organisation Name"
                defaultValue={userDetails.organisation_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="Organisation-select-label">
                  Select Organisation
                </InputLabel>
                <Select
                  required
                  labelId="Organisation-select-label"
                  label="Select Organisation"
                  id="select-organisation"
                  {...getFieldProps('organisationId')}
                  error={Boolean(touched.organisationId && errors.organisationId)}
                  helperText={touched.organisationId && errors.organisationId}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {OrganisationList
                    ? OrganisationList.map((element) => {
                        const { organisation_id, organisation_name } = element;
                        return (
                          <MenuItem key={organisation_id} value={organisation_id}>
                            {organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.organisationId && errors.organisationId}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            {userDetails.role === 'QA' ? (
              <TextField
                required
                fullWidth
                label="Customer Name"
                defaultValue={userDetails.customer_name}
                disabled
              />
            ) : (
              <FormControl fullWidth>
                <InputLabel required id="customer-select-label">
                  Select Customer
                </InputLabel>
                <Select
                  required
                  labelId="customer-select-label"
                  label="Select Customer"
                  id="select-customer"
                  error={Boolean(touched.customer && errors.customer)}
                  helperText={touched.customer && errors.customer}
                  onChange={(e) => setCustomerValue(e.target.value)}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {customerList
                    ? customerList.map((element) => {
                        const { customer_id, customer_organisation_name } = element;
                        return (
                          <MenuItem key={customer_id} value={element}>
                            {customer_organisation_name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {touched.customer && errors.customer}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={6}>
            <FieldArray
              name="campaignName"
              render={(arrayHelpers) => (
                <>
                  {formik.values.campaignName.map((row, index) => {
                    console.log('row ---', row);
                    console.log('index--', index);
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '5px'
                        }}
                      >
                        <TextField
                          required
                          fullWidth
                          label="Campaign Name"
                          {...getFieldProps(`campaignName[${index}]`)}
                          error={Boolean(
                            touched &&
                              touched.campaignName &&
                              touched.campaignName[index] &&
                              touched.campaignName[index] &&
                              errors &&
                              errors.campaignName &&
                              errors.campaignName[index] &&
                              errors.campaignName[index]
                          )}
                          helperText={
                            touched &&
                            touched.campaignName &&
                            touched.campaignName[index] &&
                            touched.campaignName[index] &&
                            errors &&
                            errors.campaignName &&
                            errors.campaignName[index] &&
                            errors.campaignName[index]
                          }
                        />
                        {index === formik.values.campaignName.length - 1 ? (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: `${index > 0 ? 'visible' : 'hidden'}`
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <RemoveCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px'
                              }}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            <AddCircleIcon
                              sx={{
                                color: 'white',
                                backgroundColor: '#3892CF',
                                '&:hover': {
                                  backgroundColor: '#3892CF'
                                },
                                borderRadius: '50%',
                                cursor: 'pointer',
                                marginX: '5px',
                                visibility: 'hidden'
                              }}
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                required
                fullWidth
                label="Product"
                {...getFieldProps('campaignProduct')}
                error={Boolean(touched.campaignProduct && errors.campaignProduct)}
                helperText={touched.campaignProduct && errors.campaignProduct}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              id="datepicker"
              selected={checkInDate}
              onChange={handleCheckInDate}
              // maxDate={new Date()}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
              placeholderText="Start date"
              error={Boolean(touched.startDate && errors.startDate)}
              helperText={touched.startDate && errors.startDate}
            />
            <FormHelperText sx={{ marginLeft: '10px', color: '#d32f2f' }}>
              {touched.startDate && errors.startDate}
            </FormHelperText>
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              selected={checkOutDate}
              // maxDate={new Date()}
              minDate={checkInDate}
              onChange={handleCheckOutDate}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput />}
              placeholderText="End date"
              error={Boolean(touched.endDate && errors.endDate)}
              helperText={touched.endDate && errors.endDate}
              // disabled
            />
            <FormHelperText sx={{ marginLeft: '10px', color: '#d32f2f' }}>
              {touched.endDate && errors.endDate}
            </FormHelperText>
          </Grid>
        </Grid>
        <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={handleCancel}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={isSubmitting}
            style={{
              marginRight: 5,
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              }
            }}
          >
            Submit
          </LoadingButton>
        </Container>
        <Notification
          message={alertContent}
          open={alert}
          severity={alertType}
          onClose={handleAlert}
        />
      </Form>
    </FormikProvider>
  );
};

export default CampaignForm;
