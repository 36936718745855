/* eslint-disable   arrow-body-style */
/* eslint-disable   no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import * as Yup from 'yup';
import { FormikProvider, Form, FieldArray, useFormik } from 'formik';
import { Steps, Input } from 'rsuite';
import 'rsuite/Input/styles/index.css';
import 'rsuite/Steps/styles/index.css';
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Container,
  FormHelperText,
  Box,
  Stack,
  Collapse,
  Button,
  Chip,
  Slide,
  Typography,
  IconButton,
  Divider
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import API from '../../E2E/axios.util';

// -------------------------------------------------------
// const categoryList = [
//   { name: 'Unpleasant', id: 'Unpleasant' },
//   { name: 'Lead Identifiers', id: 'Lead_Identifier' }
// ];

export default function DomainForm({
  formik,
  update,
  setUpdate,
  handleDialog,
  currentLevel,
  setCurrentLevel
}) {
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const [organisationList, setorganisationList] = useState([]);
  const [customerOrgList, setcustomerOrgList] = useState([]);
  const [processList, setprocessList] = useState([]);
  const [processName, setprocessName] = useState();
  const [customerName, setCustomerName] = useState();
  const [services, setServices] = useState([]);
  const [dispositionLevel, setDispositionLevel] = useState('level_1');
  const [categoryList, setCategoryList] = useState([]);
  const [isQueryDetailsExpanded, setIsQueryDetailsExpanded] = useState(true);
  const [isRequestDetailsExpanded, setIsRequestDetailsExpanded] = useState(false);
  const [isComplaintDetailsExpanded, setIsComplaintDetailsExpanded] = useState(false);
  const [systemPrompt, setSystemPrompt] = useState('');
  const [userPrompt, setUserPrompt] = useState('');
  const [assistancePrompt, setAssistancePrompt] = useState('');
  const [intentValue, setIntentValue] = useState('');
  const [intentArray, setIntentArray] = useState([]);
  const [openStates, setOpenStates] = useState([]);
  const [initialValuesLevel2, setInitialValuesLevel2] = useState({ data_level: {} });
  const [initialValuesLevel3, setInitialValuesLevel3] = useState({ data_level: {} });
  const [Level2Schema, setLevel2Schema] = useState();
  const [Level3Schema, setLevel3Schema] = useState();
  const [Level2Enable, setLevel2Enable] = useState(true);
  const [Level3Enable, setLevel3Enable] = useState(true);

  const handleToggle = (index) => {
    setOpenStates((prev) => prev.map((isOpen, i) => (i === index ? !isOpen : isOpen)));
  };

  const userDetails = JSON.parse(localStorage.getItem('user'));

  const handleCancel = () => {
    handleDialog();
    formik.resetForm();
  };

  const handleDomain = (domainValue, index) => {
    formik.setFieldValue('domain', domainValue);
    formikLevel1.setFieldValue('process', domainValue);
    setprocessName(domainValue);
  };

  const atLeastOneFieldRequired = (value, context) => {
    const { system, user, assistant } = context.parent;
    return system.length > 0 || user.length > 0 || assistant?.length > 0;
  };

  const Level1Schema = Yup.object().shape({
    organisation: Yup.string().required('This field is required.'),
    customerOrganisation: Yup.string().required('This field is required.'),
    process: Yup.string().required('This field is required.'),
    data_level: Yup.object().shape({
      intents: Yup.array()
        .of(Yup.string().required('Intent is required.'))
        .min(1, 'At least one intent is required.'),
      system: Yup.array()
        // .of(Yup.string().required('System prompt is required.'))
        .min(0)
        .test('at-least-one', 'At least one prompt is required.', atLeastOneFieldRequired),
      user: Yup.array()
        // .of(Yup.string().required('User prompt is required.'))
        .min(0)
        .test('at-least-one', 'At least one prompt is required.', atLeastOneFieldRequired),
      assistant: Yup.array()
        // .of(Yup.string().required('Assistant prompt is required.'))
        .min(0)
        .test('at-least-one', 'At least one prompt is required.', atLeastOneFieldRequired)
    })
  });

  const formikLevel1 = useFormik({
    enableReinitialize: true,
    initialValues: {
      organisation: `${userDetails.role !== 'Super_Admin' ? userDetails.organisation_id : ''}`,
      customerOrganisation: userDetails.role === 'QA' ? userDetails.customer_id : '',
      process: '',
      data_level: {
        intents: [],
        system: [],
        user: [],
        assistant: []
      }
    },
    validationSchema: Level1Schema,
    onSubmit: async (values) => {
      console.log('formik1values', values);

      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        // processes: formik.values.process,
        org_id: formikLevel1.values.organisation,
        customer_id: formikLevel1.values.customerOrganisation,
        processes: formikLevel1.values.process,
        level: 1,
        data_level: {
          intents: formikLevel1.values.data_level.intents,
          system: formikLevel1.values.data_level.system,
          user: formikLevel1.values.data_level.user,
          assistant: formikLevel1.values.data_level.assistant
        },
        priority_intent: formikLevel1.values.data_level.intents
      };
      await API.post('/secure/add_disposition', data, config)
        .then((res) => {
          setLevel2Enable(false);
          if (dispositionLevel === 'level_1') {
            handleDialog();
            setUpdate(update + 1);
          }
          // formikLeve.resetForm();
          // setCurrentLevel(1);
          // setChecked(true);
          // setAlertContent(`Success: ${res.data.message}`);
          // setAlertType('success');
          // setAlert(true);
          // handleDialog();
          // setUpdate(update + 1);
        })
        .catch((err) => {
          // setLevel2Enable(false);
          // setCurrentLevel(1);
          // setChecked(true);
          // setAlertContent(`${err.response.data.message}`);
          // setAlertType('error');
          // setAlert(true);
        });
    }
  });

  function removeInputCat(obj) {
    Object.keys(obj).forEach((key) => {
      if (key === 'inputcat') {
        delete obj[key];
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        removeInputCat(obj[key]);
      }
    });
    return obj;
  }

  const formikLevel2 = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesLevel2,
    validationSchema: Level2Schema,
    onSubmit: async (values) => {
      console.log('formik2values', values);

      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        org_id: formikLevel1.values.organisation,
        customer_id: formikLevel1.values.customerOrganisation,
        processes: formikLevel1.values.process,
        level: 2,
        data_level: removeInputCat(formikLevel2.values.data_level),
        priority_intent: formikLevel1.values.data_level.intents
      };
      await API.post('/secure/add_disposition', data, config)
        .then((res) => {
          setLevel3Enable(false);
          if (dispositionLevel === 'level_2') {
            handleDialog();
            setUpdate(update + 1);
          }
          // setCurrentLevel(1);
          // setChecked(true);
          // setAlertContent(`Success: ${res.data.message}`);
          // setAlertType('success');
          // setAlert(true);
          // handleDialog();
          // setUpdate(update + 1);
        })
        .catch((err) => {
          // setLevel3Enable(false);
          // setCurrentLevel(1);
          // setChecked(true);
          // setAlertContent(`${err.response.data.message}`);
          // setAlertType('error');
          // setAlert(true);
        });
    }
  });

  function removePropertyRecursively(obj, propToRemove) {
    if (Array.isArray(obj)) {
      obj.forEach((item) => removePropertyRecursively(item, propToRemove));
    } else if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach((key) => {
        if (key === propToRemove) {
          delete obj[key];
        } else {
          removePropertyRecursively(obj[key], propToRemove);
        }
      });
    }
    return obj;
  }
  const formikLevel3 = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesLevel3,
    validationSchema: Level3Schema,
    onSubmit: async (values) => {
      // console.log('formik3valuesAfterSubmit', values);
      // setLevel3Enable(false);
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const data = {
        org_id: formikLevel1.values.organisation,
        customer_id: formikLevel1.values.customerOrganisation,
        processes: formikLevel1.values.process,
        priority_intent: formikLevel1.values.data_level.intents,
        level: 3,
        data_level: removePropertyRecursively(formikLevel3.values.data_level, 'inputsubcat')
      };
      await API.post('/secure/add_disposition', data, config)
        .then((res) => {
          // setLevel3Enable(false);
          if (dispositionLevel === 'level_3') {
            handleDialog();
            setUpdate(update + 1);
          }
          // setChecked(true);
          // setAlertContent(`Success: ${res.data.message}`);
          // setAlertType('success');
          // setAlert(true);
          // handleDialog();
          // setUpdate(update + 1);
        })
        .catch((err) => {
          // setLevel3Enable(false);
          // setCurrentLevel(1);
          // setChecked(true);
          // setAlertContent(`${err.response.data.message}`);
          // setAlertType('error');
          // setAlert(true);
        });
    }
  });

  useEffect(() => {
    const initialValues2 = { data_level: {} };
    intentArray?.forEach((intent) => {
      initialValues2.data_level[intent] = {
        system: [],
        user: [],
        assistant: [],
        category: [],
        inputcat: ''
      };
    });
    const initialValues3 = { data_level: {} };
    intentArray?.forEach((intent) => {
      initialValues3.data_level[intent] = formikLevel2.values.data_level[intent]?.category.map(
        (cat, index) => ({ category: cat, subCategory: [], system: [], user: [], assistant: [] })
      );
    });
    const schemaLevel2 = Yup.object().shape({
      data_level: Yup.object().shape(
        Object.fromEntries(
          intentArray.map((intent) => [
            intent,
            Yup.object().shape({
              category: Yup.array().min(1, 'At least one category is required.'),
              // subCategory: Yup.array().of(Yup.string()).required('SubCategory is required'),
              system: Yup.array()
                // .of(Yup.string().required('System prompt is required.'))
                .min(0)
                .test('at-least-one', 'At least one prompt is required.', atLeastOneFieldRequired),
              user: Yup.array()
                // .of(Yup.string().required('User prompt is required.'))
                .min(0)
                .test('at-least-one', 'At least one prompt is required.', atLeastOneFieldRequired),
              assistant: Yup.array()
                // .of(Yup.string().required('Assistant prompt is required.'))
                .min(0)
                .test('at-least-one', 'At least one prompt is required.', atLeastOneFieldRequired)
            })
          ])
        )
      )
    });
    // const schemaLevel2 = Yup.object().shape({
    //   data_level: Yup.object().shape(
    //     intentArray.reduce((acc, intent) => {
    //       acc[intent] = Yup.array()
    //         .shape({
    //           category: Yup.array(),
    //           system: Yup.string(),
    //           user: Yup.string(),
    //           assistant: Yup.string()
    //         })
    //         .test(
    //           'at-least-one',
    //           'At least one of the fields (system, user, assistant) must be filled.',
    //           (value) => {
    //             return value.system || value.user || value.assistant;
    //           }
    //         );
    //       return acc;
    //     }, {})
    //   )
    // });
    const dataLevelSchema = Yup.object().shape({
      category: Yup.string().required('Category is required'),
      // subCategory: Yup.array().of(Yup.string()).required('SubCategory is required'),
      subCategory: Yup.array().min(1, 'At least one subcategory is required.'),
      system: Yup.array()
        // .of(Yup.string().required('System prompt is required.'))
        .min(0)
        .test('at-least-one', 'At least one prompt is required.', atLeastOneFieldRequired),
      user: Yup.array()
        // .of(Yup.string().required('User prompt is required.'))
        .min(0)
        .test('at-least-one', 'At least one prompt is required.', atLeastOneFieldRequired),
      assistant: Yup.array()
        // .of(Yup.string().required('Assistant prompt is required.'))
        .min(0)
        .test('at-least-one', 'At least one prompt is required.', atLeastOneFieldRequired)
    });
    // .test(
    //   'at-least-one',
    //   'At least one of System, User, or Assistant is required',
    //   (value) => !!value.system || !!value.user || !!value.assistant
    // );

    const schemaLevel3 = Yup.object().shape({
      data_level: Yup.object().shape({
        // Use Yup.lazy to dynamically create the shape based on the intent keys
        ...Object.fromEntries(
          intentArray.map((intent) => [intent, Yup.array().of(dataLevelSchema)])
        )
      })
    });

    setLevel2Schema(schemaLevel2);
    setLevel3Schema(schemaLevel3);
    setInitialValuesLevel2(initialValues2);
    setInitialValuesLevel3(initialValues3);
  }, [intentArray, formikLevel2.values.data_level]);

  useEffect(() => {
    setOpenStates(intentArray.map(() => false));
  }, [intentArray]);

  // console.log('formik1values', formikLevel1);
  // console.log('formik2values', formikLevel2);
  console.log('formik3values', formikLevel3);

  const getOrganisationList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get('/secure/list_organisation?page=1&max_rows=10', config)
      .then((res) => {
        setorganisationList(res.data.organisation_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getCustomerList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    await API.get(
      `/secure/list_of_customer?organisation_id=${formikLevel1.values.organisation}`,
      config
    )
      .then((res) => {
        setcustomerOrgList(res.data.customer_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formikLevel1.values.organisation !== '') {
      getCustomerList();
    } else {
      setcustomerOrgList([]);
    }
  }, [formikLevel1.values.organisation]);

  const getProcessList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getProcess() {
      return API.get(
        `/secure/process_list?customer_id=${formikLevel1.values.customerOrganisation}&organisation_id=${formikLevel1.values.organisation}`,
        config
      );
    }
    Promise.all([getProcess()])
      .then(([processData]) => {
        setprocessList(processData.data.processes);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCategoryList = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    function getCategory() {
      return API.get(
        `/secure/get_categories?customer_id=${formikLevel1.values.customerOrganisation}&org_id=${formikLevel1.values.organisation}`,
        config
      );
    }
    Promise.all([getCategory()])
      .then(([categoryData]) => {
        setCategoryList(categoryData.data.categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formikLevel1.values.customerOrganisation) {
      getProcessList();
      getCategoryList();
    } else {
      setprocessList([]);
      setCategoryList([]);
    }
  }, [formikLevel1.values.customerOrganisation]);

  // -----------------------------------------------------------
  // handlecustomer values.

  const handleCustomerChange = (e) => {
    setCustomerName(e);
    formik.setFieldValue('customerOrganisation', e);
    formikLevel1.setFieldValue('customerOrganisation', e);
    setServices(e.dashboard_services);
    const levels = customerOrgList.find((customer) => customer.customer_id === e);
    setDispositionLevel(
      levels.level_1 ? 'level_1' : levels.level_2 ? 'level_2' : levels.level_3 ? 'level_3' : ''
    );
  };

  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200
    }
  }));

  const handleIntentChange = (event) => {
    setIntentValue(event.target.value);
  };

  const handleAddIntent = () => {
    if (intentValue.trim()) {
      setIntentArray((prevArray) => {
        const updatedIntents = [...prevArray, intentValue.trim()];
        formikLevel1.setFieldValue('data_level.intents', updatedIntents);
        return updatedIntents;
      });
      setIntentValue('');
    }
  };
  const handleAddCategory = (intent) => {
    const categoryInput = formikLevel2.values.data_level[intent].inputcat || '';
    const currentIntents = formikLevel2.values.data_level[intent].category || [];

    // Create a new array with the updated intents
    const updatedIntents = [...currentIntents, categoryInput.trim()];

    // Update the Formik field value
    formikLevel2.setFieldValue(`data_level[${intent}].category`, updatedIntents);
  };
  const handleAddSubCategory = (intent, index) => {
    const categoryInputSubCategory =
      formikLevel3.values.data_level[intent][index].inputsubcat || '';
    const currentSubCategory = formikLevel3.values.data_level[intent][index].subCategory || [];

    // Create a new array with the updated intents
    const updatedIntents = [...currentSubCategory, categoryInputSubCategory.trim()];

    // Update the Formik field value
    formikLevel3.setFieldValue(`data_level[${intent}][${index}].subCategory`, updatedIntents);
  };

  const classes = useStyles();
  const [checked, setChecked] = React.useState(true);
  const [direction, setDirection] = React.useState();

  return (
    <div>
      {userDetails.role !== 'Super_Admin' && (
        <Steps
          current={currentLevel}
          style={{
            marginBottom: 5,
            paddingBottom: 30,
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
            zIndex: 1000
          }}
        >
          <Steps.Item title="Level 1" />
          <Steps.Item disabled title="Level 2" />
          <Steps.Item title="Level 3" />
          <Steps.Item title="Priority" />
        </Steps>
      )}

      {currentLevel === 0 && (
        <FormikProvider value={formikLevel1}>
          <Slide
            direction={direction}
            in={checked}
            timeout={800}
            appear={direction}
            mountOnEnter
            unmountOnExit
          >
            <Form autoComplete="off" noValidate onSubmit={formikLevel1.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {userDetails.role !== 'Super_Admin' ? (
                    <TextField
                      required
                      fullWidth
                      label="Organisation Name"
                      defaultValue={userDetails.organisation_name}
                      disabled
                    />
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel required id="Organisation-select-label">
                        Select Organisation
                      </InputLabel>
                      <Select
                        required
                        labelId="Organisation-select-label"
                        label="Select Organisation"
                        id="select-organisation"
                        {...formikLevel1.getFieldProps('organisation')}
                        error={Boolean(
                          formikLevel1.touched.organisation && formikLevel1.errors.organisation
                        )}
                        helperText={
                          formikLevel1.touched.organisation && formikLevel1.errors.organisation
                        }
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                      >
                        {organisationList
                          ? organisationList.map((element) => {
                              const { organisation_id, organisation_name } = element;
                              return (
                                <MenuItem key={organisation_id} value={organisation_id}>
                                  {organisation_name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                      <FormHelperText sx={{ color: '#d32f2f' }}>
                        {formikLevel1.touched.organisation && formikLevel1.errors.organisation}
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {userDetails.role === 'QA' ? (
                    <TextField
                      required
                      fullWidth
                      label="Customer Name"
                      defaultValue={userDetails.customer_name}
                      disabled
                    />
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel required id="customer-select-label">
                        Select Customer
                      </InputLabel>
                      <Select
                        required
                        labelId="customer-select-label"
                        label="Select Customer"
                        id="select-customer"
                        // {...getFieldProps('customerOrganisation')}
                        value={customerName}
                        onChange={(e) => handleCustomerChange(e.target.value)}
                        error={Boolean(
                          formikLevel1.touched.customerOrganisation &&
                            formikLevel1.errors.customerOrganisation
                        )}
                        helperText={
                          formikLevel1.touched.customerOrganisation &&
                          formikLevel1.errors.customerOrganisation
                        }
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                      >
                        {customerOrgList
                          ? customerOrgList.map((element) => {
                              const { customer_id, customer_organisation_name } = element;
                              return (
                                <MenuItem key={customer_id} value={customer_id}>
                                  {customer_organisation_name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                      <FormHelperText sx={{ color: '#d32f2f' }}>
                        {formikLevel1.touched.customerOrganisation &&
                          formikLevel1.errors.customerOrganisation}
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel required id="process-select-label">
                      Select Process
                    </InputLabel>
                    <Select
                      required
                      labelId="process-select-label"
                      label="Select Process"
                      id="select-process"
                      value={processName}
                      error={Boolean(formikLevel1.touched.process && formikLevel1.errors.process)}
                      helperText={formikLevel1.touched.process && formikLevel1.errors.process}
                      onChange={(e) => handleDomain(e.target.value)}
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                      {processList
                        ? processList.map((element, index) => {
                            return (
                              <MenuItem key={element} value={element}>
                                {element}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {formikLevel1.touched.process && formikLevel1.errors.process}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexDirection: 'row'
                    }}
                  >
                    <Grid item md={9}>
                      <TextField
                        required
                        fullWidth
                        label="Intent"
                        type="text"
                        value={intentValue}
                        onChange={handleIntentChange}
                        placeholder="Enter a Intent"
                        error={Boolean(
                          formikLevel1.touched?.data_level?.intents &&
                            formikLevel1.errors?.data_level?.intents
                        )}
                        helperText={
                          formikLevel1.touched?.data_level?.intents &&
                          formikLevel1.errors?.data_level?.intents
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        startIcon={<Icon icon={plusFill} />}
                        sx={{
                          backgroundColor: '#3892CF',
                          '&:hover': {
                            backgroundColor: '#3892CF'
                          }
                        }}
                        onClick={handleAddIntent}
                      >
                        Add
                      </Button>
                    </Grid>
                  </div>

                  <div style={{ marginTop: '10px' }}>
                    {intentArray.length > 0
                      ? intentArray.map((intent, index) => (
                          <Chip
                            color="info"
                            label={intent}
                            onDelete={() => {
                              setIntentArray((deleteIntent) => {
                                const updatedIntents = deleteIntent.filter(
                                  (chip, chipIndex) => chipIndex !== index
                                );
                                formikLevel1.setFieldValue('data_level.intents', updatedIntents);
                                return updatedIntents;
                              });
                            }}
                            sx={{ margin: '4px' }}
                          />
                        ))
                      : null}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    as="textarea"
                    rows={3}
                    value={formikLevel1.values?.data_level.system.join('<>')}
                    onChange={(e) => {
                      setSystemPrompt(e);
                      if (e === '') {
                        formikLevel1.setFieldValue('data_level.system', []);
                      } else formikLevel1.setFieldValue('data_level.system', e.split('<>'));
                    }}
                    onBlur={formikLevel1.handleBlur}
                    placeholder="Add System Prompt"
                    style={
                      formikLevel1.touched?.data_level?.system &&
                      formikLevel1.errors?.data_level?.system
                        ? { borderColor: '#D14343' }
                        : null
                    }
                  />
                  <FormHelperText sx={{ color: '#d32f2f' }}>
                    {formikLevel1.touched?.data_level?.system &&
                      formikLevel1.errors?.data_level?.system}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    as="textarea"
                    rows={3}
                    value={formikLevel1.values?.data_level?.user.join('<>')}
                    onChange={(e) => {
                      setUserPrompt(e);
                      if (e === '') {
                        formikLevel1.setFieldValue('data_level.user', []);
                      } else formikLevel1.setFieldValue('data_level.user', e.split('<>'));
                      // formikLevel1.setFieldValue('data_level.user', e.split('<>'));
                    }}
                    onBlur={formikLevel1.handleBlur}
                    placeholder="Add User Prompt"
                    style={
                      formikLevel1.touched?.data_level?.user &&
                      formikLevel1.errors?.data_level?.user
                        ? { borderColor: '#D14343' }
                        : null
                    }
                  />
                  <FormHelperText sx={{ color: '#d32f2f' }}>
                    {formikLevel1.touched?.data_level?.user &&
                      formikLevel1.errors?.data_level?.user}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    as="textarea"
                    rows={3}
                    value={formikLevel1.values?.data_level?.assistant?.join('<>')}
                    onChange={(e) => {
                      // setAssistancePrompt(e);
                      if (e === '') {
                        formikLevel1.setFieldValue('data_level.assistant', []);
                      } else formikLevel1.setFieldValue('data_level.assistant', e.split('<>'));
                      // formikLevel1.setFieldValue('data_level.assistant', e.split('<>'));
                    }}
                    onBlur={formikLevel1.handleBlur}
                    placeholder="Add Assistant Prompt"
                    style={
                      formikLevel1.touched?.data_level?.assistant &&
                      formikLevel1.errors?.data_level?.assistant
                        ? { borderColor: '#D14343' }
                        : null
                    }
                  />
                  <FormHelperText sx={{ color: '#d32f2f' }}>
                    {formikLevel1.touched?.data_level?.assistant &&
                      formikLevel1.errors?.data_level?.assistant}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  variant="contained"
                  onClick={formikLevel1.handleSubmit}
                  style={{
                    marginRight: 5,
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                >
                  Save
                </LoadingButton>

                {(dispositionLevel === 'level_2' || dispositionLevel === 'level_3') && (
                  <LoadingButton
                    variant="contained"
                    disabled={Level2Enable || Object.keys(formikLevel1.errors).length > 0}
                    onClick={() => {
                      setCurrentLevel(1);
                      setDirection('left');
                      setChecked(true);
                    }}
                    // loading={isSubmitting}
                    sx={{
                      backgroundColor: '#3892CF',
                      '&:hover': {
                        backgroundColor: '#3892CF'
                      }
                    }}
                  >
                    Next
                  </LoadingButton>
                )}
              </Container>
            </Form>
          </Slide>
        </FormikProvider>
      )}
      {currentLevel === 1 && (
        <FormikProvider value={formikLevel2}>
          <Slide direction={direction} in={checked} timeout={800} mountOnEnter unmountOnExit>
            <Form autoComplete="off" noValidate onSubmit={formikLevel2.handleSubmit}>
              <Grid container spacing={3}>
                {formikLevel1.values?.data_level?.intents.length > 0
                  ? formikLevel1.values?.data_level?.intents.map((intent, index) => (
                      <Grid item xs={12}>
                        <Stack
                          onClick={() => {
                            handleToggle(index);
                          }}
                          style={{
                            color: 'Black',
                            backgroundColor: '#3892CF',
                            '&:hover': {
                              backgroundColor: '#3892CF'
                            },
                            marginBottom: 15,
                            height: 45,
                            borderRadius: 3,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            cursor: 'pointer',
                            padding: 1
                          }}
                        >
                          <p
                            style={{
                              // marginLeft: 10,
                              color: '#fff',
                              fontSize: 15,
                              width: '20%',
                              fontFamily: 'sans-serif',
                              display: 'flex',
                              justifyContent: 'space-around',
                              alignItems: 'center'
                            }}
                          >
                            <AddIcon />
                            <Typography>{intent}</Typography>
                          </p>
                          {openStates[index] ? (
                            <ArrowDropUpIcon size={20} htmlColor="#fff" titleAccess="Collapse" />
                          ) : (
                            <ArrowDropDownIcon size={20} htmlColor="#fff" titleAccess="Collapse" />
                          )}
                        </Stack>
                        {/* <FormHelperText sx={{ color: '#d32f2f', margin: 2 }}>
                          {formikLevel2.touched?.data_level &&
                            formikLevel2.touched?.data_level[intent] &&
                            formikLevel2.errors?.data_level &&
                            formikLevel2.errors?.data_level[intent]}
                        </FormHelperText> */}
                        <div style={{ padding: '5px', margin: 1 }}>
                          <Collapse in={openStates[index]}>
                            <Grid container spacing={3}>
                              <Grid item xs={6}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexDirection: 'row'
                                  }}
                                >
                                  <Grid item md={9}>
                                    <TextField
                                      required
                                      fullWidth
                                      label="Category"
                                      type="text"
                                      value={formikLevel2.values?.data_level[intent]?.inputcat}
                                      onChange={(e) => {
                                        formikLevel2.setFieldValue(
                                          `data_level[${intent}].inputcat`,
                                          e.target.value
                                        );
                                      }}
                                      placeholder="Enter a Category"
                                      error={Boolean(
                                        formikLevel2.touched &&
                                          formikLevel2.errors &&
                                          formikLevel2.errors?.data_level &&
                                          formikLevel2.touched?.data_level &&
                                          formikLevel2.touched?.data_level[intent]?.category &&
                                          formikLevel2.errors?.data_level[intent]?.category
                                      )}
                                      helperText={
                                        formikLevel2.touched &&
                                        formikLevel2.errors &&
                                        formikLevel2.errors?.data_level &&
                                        formikLevel2.touched?.data_level &&
                                        formikLevel2.touched?.data_level[intent]?.category &&
                                        formikLevel2.errors?.data_level[intent]?.category
                                      }
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      startIcon={<Icon icon={plusFill} />}
                                      sx={{
                                        backgroundColor: '#3892CF',
                                        '&:hover': {
                                          backgroundColor: '#3892CF'
                                        }
                                      }}
                                      onClick={() => {
                                        handleAddCategory(intent);
                                        formikLevel2.setFieldValue(
                                          `data_level[${intent}].inputcat`,
                                          ''
                                        );
                                      }}
                                    >
                                      Add
                                    </Button>
                                  </Grid>
                                </div>

                                <div style={{ marginTop: '10px' }}>
                                  {formikLevel2.values?.data_level[intent]?.category.length > 0
                                    ? formikLevel2.values?.data_level[intent]?.category.map(
                                        (category, index) => (
                                          <Chip
                                            color="info"
                                            label={category}
                                            onDelete={() => {
                                              // console.log('deleteCategory', index);
                                              // setCategoryArray((deleteCategory) => {
                                              const deleteCategory =
                                                formikLevel2.values?.data_level[intent]?.category;
                                              const updatedCategory = deleteCategory.filter(
                                                (chip, chipIndex) => chipIndex !== index
                                              );
                                              formikLevel2.setFieldValue(
                                                `data_level[${intent}].category`,
                                                updatedCategory
                                              );
                                              // return updatedIntents;
                                              // });
                                            }}
                                            sx={{ margin: '4px' }}
                                          />
                                        )
                                      )
                                    : null}
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <Input
                                  as="textarea"
                                  rows={3}
                                  value={formikLevel2.values?.data_level[intent]?.system.join('<>')}
                                  onChange={(e) => {
                                    // setSystemPrompt(e);
                                    if (e === '') {
                                      formikLevel2.setFieldValue(
                                        `data_level[${intent}].system`,
                                        []
                                      );
                                    } else
                                      formikLevel2.setFieldValue(
                                        `data_level[${intent}].system`,
                                        e.split('<>')
                                      );
                                  }}
                                  onBlur={formikLevel2.handleBlur}
                                  placeholder="Add System Prompt"
                                  style={
                                    formikLevel2.touched &&
                                    formikLevel2.errors &&
                                    formikLevel2.errors?.data_level &&
                                    formikLevel2.touched?.data_level &&
                                    formikLevel2.touched?.data_level[intent]?.system &&
                                    formikLevel2.errors?.data_level[intent]?.system
                                      ? { borderColor: '#D14343' }
                                      : null
                                  }
                                />
                                <FormHelperText sx={{ color: '#d32f2f' }}>
                                  {formikLevel2.touched &&
                                    formikLevel2.errors &&
                                    formikLevel2.errors?.data_level &&
                                    formikLevel2.touched?.data_level &&
                                    formikLevel2.touched?.data_level[intent]?.system &&
                                    formikLevel2.errors?.data_level[intent]?.system}
                                </FormHelperText>
                              </Grid>
                              <Grid item xs={12}>
                                <Input
                                  as="textarea"
                                  rows={3}
                                  value={formikLevel2.values?.data_level[intent]?.user.join('<>')}
                                  onChange={(e) => {
                                    // setUserPrompt(e);
                                    if (e === '') {
                                      formikLevel2.setFieldValue(`data_level[${intent}].user`, []);
                                    } else
                                      formikLevel2.setFieldValue(
                                        `data_level[${intent}].user`,
                                        e.split('<>')
                                      );
                                  }}
                                  onBlur={formikLevel2.handleBlur}
                                  placeholder="Add User Prompt"
                                  style={
                                    formikLevel2.touched &&
                                    formikLevel2.errors &&
                                    formikLevel2.errors?.data_level &&
                                    formikLevel2.touched?.data_level &&
                                    formikLevel2.touched?.data_level[intent]?.user &&
                                    formikLevel2.errors?.data_level[intent]?.user
                                      ? { borderColor: '#D14343' }
                                      : null
                                  }
                                />
                                <FormHelperText sx={{ color: '#d32f2f' }}>
                                  {formikLevel2.touched &&
                                    formikLevel2.errors &&
                                    formikLevel2.errors?.data_level &&
                                    formikLevel2.touched?.data_level &&
                                    formikLevel2.touched?.data_level[intent]?.user &&
                                    formikLevel2.errors?.data_level[intent]?.user}
                                </FormHelperText>
                              </Grid>
                              <Grid item xs={12}>
                                <Input
                                  as="textarea"
                                  rows={3}
                                  value={formikLevel2.values?.data_level[intent]?.assistant?.join(
                                    '<>'
                                  )}
                                  onChange={(e) => {
                                    // setAssistancePrompt(e);
                                    if (e === '') {
                                      formikLevel2.setFieldValue(
                                        `data_level[${intent}].assistant`,
                                        []
                                      );
                                    } else
                                      formikLevel2.setFieldValue(
                                        `data_level[${intent}].assistant`,
                                        e.split('<>')
                                      );
                                  }}
                                  onBlur={formikLevel2.handleBlur}
                                  placeholder="Add Assistant Prompt"
                                  style={
                                    formikLevel2.touched &&
                                    formikLevel2.errors &&
                                    formikLevel2.errors?.data_level &&
                                    formikLevel2.touched?.data_level &&
                                    formikLevel2.touched?.data_level[intent]?.assistant &&
                                    formikLevel2.errors?.data_level[intent]?.assistant
                                      ? { borderColor: '#D14343' }
                                      : null
                                  }
                                />
                                <FormHelperText sx={{ color: '#d32f2f' }}>
                                  {formikLevel2.touched &&
                                    formikLevel2.errors &&
                                    formikLevel2.errors?.data_level &&
                                    formikLevel2.touched?.data_level &&
                                    formikLevel2.touched?.data_level[intent]?.assistant &&
                                    formikLevel2.errors?.data_level[intent]?.assistant}
                                </FormHelperText>
                              </Grid>
                            </Grid>
                          </Collapse>
                        </div>
                      </Grid>
                    ))
                  : null}
              </Grid>
              <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  variant="contained"
                  onClick={() => {
                    setCurrentLevel(0);
                    setDirection('right');
                    setChecked(true);
                  }}
                  // loading={isSubmitting}
                  sx={{
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                >
                  Back
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  onClick={formikLevel2.handleSubmit}
                  style={{
                    marginLeft: 5,
                    marginRight: 5,
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                >
                  Save
                </LoadingButton>

                {dispositionLevel === 'level_3' && (
                  <LoadingButton
                    variant="contained"
                    disabled={Level3Enable}
                    onClick={() => {
                      setCurrentLevel(2);
                      setDirection('left');
                      setChecked(true);
                    }}
                    // loading={isSubmitting}
                    sx={{
                      backgroundColor: '#3892CF',
                      '&:hover': {
                        backgroundColor: '#3892CF'
                      }
                    }}
                  >
                    Next
                  </LoadingButton>
                )}
              </Container>
            </Form>
          </Slide>
        </FormikProvider>
      )}
      {currentLevel === 2 && (
        <FormikProvider value={formikLevel3}>
          <Slide direction={direction} in={checked} timeout={800} mountOnEnter unmountOnExit>
            <Form autoComplete="off" noValidate onSubmit={formikLevel3.handleSubmit}>
              {/* <Grid container spacing={3}> */}
              {formikLevel1.values?.data_level?.intents.length > 0
                ? formikLevel1.values?.data_level?.intents.map((intent, index) => (
                    <Grid container>
                      <Grid item xs={12}>
                        <Stack
                          onClick={() => {
                            handleToggle(index);
                          }}
                          sx={{
                            color: 'Black',
                            backgroundColor: '#3892CF',
                            '&:hover': {
                              backgroundColor: '#3892CF'
                            },
                            marginBottom: 3,
                            height: 45,
                            borderRadius: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }}
                        >
                          <p
                            style={{
                              // marginLeft: 10,
                              color: '#fff',
                              fontSize: 15,
                              width: '20%',
                              fontFamily: 'sans-serif',
                              display: 'flex',
                              justifyContent: 'space-around',
                              alignItems: 'center'
                            }}
                          >
                            <AddIcon />
                            <Typography>{intent}</Typography>
                          </p>
                          {openStates[index] ? (
                            <ArrowDropUpIcon size={20} htmlColor="#fff" titleAccess="Collapse" />
                          ) : (
                            <ArrowDropDownIcon size={20} htmlColor="#fff" titleAccess="Collapse" />
                          )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <Collapse in={openStates[index]}>
                          {formikLevel3?.values?.data_level[intent]?.map((row, catIndex) => {
                            return (
                              <Grid
                                container
                                spacing={3}
                                style={{
                                  padding: '3px',
                                  border: '2px solid #3892CF',
                                  borderRadius: '10px',
                                  margin: 'auto'
                                }}
                              >
                                <Grid
                                  item
                                  xs={6}
                                  sx={{
                                    display: 'flex',
                                    // justifyContent: 'space-around',
                                    alignItems: 'center'
                                  }}
                                >
                                  <TextField
                                    required
                                    // labelId="category-select-label"
                                    label="Category"
                                    placeholder="Enter A Category"
                                    value={row.category}
                                    disabled
                                    // {...getFieldProps(
                                    //   `data_level[${intent}][${index}].category`
                                    // )}
                                    MenuProps={{
                                      classes: { paper: classes.menuPaper }
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      flexDirection: 'row'
                                    }}
                                  >
                                    <Grid item md={9}>
                                      <TextField
                                        required
                                        fullWidth
                                        label="Sub Category"
                                        type="text"
                                        value={
                                          formikLevel3.values.data_level[intent][catIndex]
                                            ?.inputsubcat
                                        }
                                        onChange={(e) => {
                                          formikLevel3.setFieldValue(
                                            `data_level[${intent}][${catIndex}].inputsubcat`,
                                            e.target.value
                                          );
                                        }}
                                        placeholder="Enter a Sub Category"
                                        error={Boolean(
                                          formikLevel3.touched &&
                                            formikLevel3.errors &&
                                            formikLevel3.errors?.data_level &&
                                            formikLevel3.touched?.data_level &&
                                            formikLevel3.touched?.data_level[intent] &&
                                            formikLevel3.errors?.data_level[intent] &&
                                            formikLevel3.touched?.data_level[intent][catIndex]
                                              ?.subCategory &&
                                            formikLevel3.errors?.data_level[intent][catIndex]
                                              ?.subCategory
                                        )}
                                        helperText={
                                          formikLevel3.touched &&
                                          formikLevel3.errors &&
                                          formikLevel3.errors?.data_level &&
                                          formikLevel3.touched?.data_level &&
                                          formikLevel3.touched?.data_level[intent] &&
                                          formikLevel3.errors?.data_level[intent] &&
                                          formikLevel3.touched?.data_level[intent][catIndex]
                                            ?.subCategory &&
                                          formikLevel3.errors?.data_level[intent][catIndex]
                                            ?.subCategory
                                        }
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        startIcon={<Icon icon={plusFill} />}
                                        sx={{
                                          backgroundColor: '#3892CF',
                                          '&:hover': {
                                            backgroundColor: '#3892CF'
                                          }
                                        }}
                                        onClick={() => {
                                          handleAddSubCategory(intent, catIndex);
                                          formikLevel3.setFieldValue(
                                            `data_level[${intent}][${catIndex}].inputsubcat`,
                                            ''
                                          );
                                        }}
                                      >
                                        Add
                                      </Button>
                                    </Grid>
                                  </div>

                                  <div style={{ marginTop: '10px' }}>
                                    {formikLevel3.values?.data_level[intent][catIndex]?.subCategory
                                      .length > 0
                                      ? formikLevel3.values?.data_level[intent][
                                          catIndex
                                        ]?.subCategory.map((category, index) => (
                                          <Chip
                                            color="info"
                                            label={category}
                                            onDelete={() => {
                                              // console.log('deleteCategory', index);
                                              // setCategoryArray((deleteCategory) => {
                                              const deleteCategory =
                                                formikLevel3.values?.data_level[intent][catIndex]
                                                  ?.subCategory;
                                              const updatedCategory = deleteCategory.filter(
                                                (chip, chipIndex) => chipIndex !== index
                                              );
                                              formikLevel3.setFieldValue(
                                                `data_level[${intent}][${catIndex}].subCategory`,
                                                updatedCategory
                                              );
                                              // return updatedIntents;
                                              // });
                                            }}
                                            sx={{ margin: '4px' }}
                                          />
                                        ))
                                      : null}
                                  </div>
                                </Grid>
                                <Grid item xs={12}>
                                  <Input
                                    as="textarea"
                                    rows={3}
                                    value={formikLevel3.values.data_level[intent][
                                      catIndex
                                    ].system.join('<>')}
                                    onChange={(e) => {
                                      if (e === '') {
                                        formikLevel3.setFieldValue(
                                          `data_level[${intent}][${catIndex}].system`,
                                          []
                                        );
                                      } else
                                        formikLevel3.setFieldValue(
                                          `data_level[${intent}][${catIndex}].system`,
                                          e.split('<>')
                                        );
                                    }}
                                    placeholder="Add System Prompt"
                                    onBlur={formikLevel3.handleBlur}
                                    style={
                                      formikLevel3.touched &&
                                      formikLevel3.errors &&
                                      formikLevel3.errors?.data_level &&
                                      formikLevel3.touched?.data_level &&
                                      formikLevel3.touched?.data_level[intent] &&
                                      formikLevel3.errors?.data_level[intent] &&
                                      formikLevel3.touched?.data_level[intent][catIndex]?.system &&
                                      formikLevel3.errors?.data_level[intent][catIndex]?.system
                                        ? { borderColor: '#D14343' }
                                        : null
                                    }
                                  />
                                  <FormHelperText sx={{ color: '#d32f2f' }}>
                                    {formikLevel3.touched &&
                                      formikLevel3.errors &&
                                      formikLevel3.errors?.data_level &&
                                      formikLevel3.touched?.data_level &&
                                      formikLevel3.touched?.data_level[intent] &&
                                      formikLevel3.errors?.data_level[intent] &&
                                      formikLevel3.touched?.data_level[intent][catIndex]?.system &&
                                      formikLevel3.errors?.data_level[intent][catIndex]?.system}
                                  </FormHelperText>
                                </Grid>
                                <Grid item xs={12}>
                                  <Input
                                    as="textarea"
                                    rows={3}
                                    value={formikLevel3.values.data_level[intent][
                                      catIndex
                                    ]?.user.join('<>')}
                                    onChange={(e) => {
                                      // setSystemPrompt(e);
                                      if (e === '') {
                                        formikLevel3.setFieldValue(
                                          `data_level[${intent}][${catIndex}].user`,
                                          []
                                        );
                                      } else
                                        formikLevel3.setFieldValue(
                                          `data_level[${intent}][${catIndex}].user`,
                                          e.split('<>')
                                        );
                                    }}
                                    placeholder="Add User Prompt"
                                    onBlur={formikLevel3.handleBlur}
                                    style={
                                      formikLevel3.touched &&
                                      formikLevel3.errors &&
                                      formikLevel3.errors?.data_level &&
                                      formikLevel3.touched?.data_level &&
                                      formikLevel3.touched?.data_level[intent] &&
                                      formikLevel3.errors?.data_level[intent] &&
                                      formikLevel3.touched?.data_level[intent][catIndex]?.user &&
                                      formikLevel3.errors?.data_level[intent][catIndex]?.user
                                        ? { borderColor: '#D14343' }
                                        : null
                                    }
                                  />
                                  <FormHelperText sx={{ color: '#d32f2f' }}>
                                    {formikLevel3.touched &&
                                      formikLevel3.errors &&
                                      formikLevel3.errors?.data_level &&
                                      formikLevel3.touched?.data_level &&
                                      formikLevel3.touched?.data_level[intent] &&
                                      formikLevel3.errors?.data_level[intent] &&
                                      formikLevel3.touched?.data_level[intent][catIndex]?.user &&
                                      formikLevel3.errors?.data_level[intent][catIndex]?.user}
                                  </FormHelperText>
                                </Grid>
                                <Grid item xs={12}>
                                  <Input
                                    as="textarea"
                                    rows={3}
                                    value={formikLevel3.values.data_level[intent][
                                      catIndex
                                    ]?.assistant?.join('<>')}
                                    onChange={(e) => {
                                      // setSystemPrompt(e);
                                      if (e === '') {
                                        formikLevel3.setFieldValue(
                                          `data_level[${intent}][${catIndex}].assistant`,
                                          []
                                        );
                                      } else
                                        formikLevel3.setFieldValue(
                                          `data_level[${intent}][${catIndex}].assistant`,
                                          e.split('<>')
                                        );
                                    }}
                                    placeholder="Add Assistant Prompt"
                                    onBlur={formikLevel3.handleBlur}
                                    style={
                                      formikLevel3.touched &&
                                      formikLevel3.errors &&
                                      formikLevel3.errors?.data_level &&
                                      formikLevel3.touched?.data_level &&
                                      formikLevel3.touched?.data_level[intent] &&
                                      formikLevel3.errors?.data_level[intent] &&
                                      formikLevel3.touched?.data_level[intent][catIndex]
                                        ?.assistant &&
                                      formikLevel3.errors?.data_level[intent][catIndex]?.assistant
                                        ? { borderColor: '#D14343' }
                                        : null
                                    }
                                  />
                                  <FormHelperText sx={{ color: '#d32f2f' }}>
                                    {formikLevel3.touched &&
                                      formikLevel3.errors &&
                                      formikLevel3.errors?.data_level &&
                                      formikLevel3.touched?.data_level &&
                                      formikLevel3.touched?.data_level[intent] &&
                                      formikLevel3.errors?.data_level[intent] &&
                                      formikLevel3.touched?.data_level[intent][catIndex]
                                        ?.assistant &&
                                      formikLevel3.errors?.data_level[intent][catIndex]?.assistant}
                                  </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sx={{ marginBottom: 3 }}>
                                  <Divider />
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Collapse>
                      </Grid>
                    </Grid>
                  ))
                : null}
              {/* </Grid> */}
              <Container style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  variant="contained"
                  // disabled={currentLevel === 0}
                  onClick={() => {
                    setCurrentLevel(1);
                    setDirection('right');
                    setChecked(true);
                  }}
                  // loading={isSubmitting}
                  sx={{
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                >
                  Back
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  onClick={formikLevel3.handleSubmit}
                  style={{
                    marginLeft: 5,
                    marginRight: 5,
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                >
                  Add
                </LoadingButton>
                {/* <LoadingButton
                  variant="contained"
                  onClick={() => {
                    setCurrentLevel(3);
                    setDirection('right');
                    setChecked(true);
                  }}
                  style={{
                    marginLeft: 5,
                    marginRight: 5,
                    backgroundColor: '#3892CF',
                    '&:hover': {
                      backgroundColor: '#3892CF'
                    }
                  }}
                >
                  Next
                </LoadingButton> */}
              </Container>
            </Form>
          </Slide>
        </FormikProvider>
      )}
      {currentLevel === 3 &&
        intentArray.length > 0 &&
        intentArray.map((intent) => (
          <Stack
            style={{
              color: 'Black',
              backgroundColor: '#3892CF',
              '&:hover': {
                backgroundColor: '#3892CF'
              },
              width: '50%',
              marginBottom: 15,
              height: 45,
              borderRadius: 3,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'flex-start',

              padding: 1
            }}
          >
            <p
              style={{
                // marginLeft: 10,
                color: '#fff',
                fontSize: 15,
                width: '20%',
                fontFamily: 'sans-serif',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center'
              }}
            >
              <Typography>{intent}</Typography>
            </p>
          </Stack>
        ))}
    </div>
  );
}
