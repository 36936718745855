/* eslint-disable arrow-body-style */
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Box,
  Container,
  Link,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Button,
  MenuItem,
  FormHelperText,
  InputLabel,
  Select as Selectbox,
  Collapse
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './registration.css';
import { styled } from '@mui/material/styles';
import Lottie from 'react-lottie';
import { MHidden } from '../../@material-extend';
import registerJson from '../../animate/animations/register.json';
import API from '../../../E2E/axios.util';
import Page from '../../Page';
import { Notification } from '../../Notification/Notification';

// ----------------------------------------------------------------------

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: registerJson,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    height: '100%'
  }
}));

const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 650,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const useStyles = makeStyles({
  radio: {
    color: '#1D9FD9', // Set the desired color value here
    '&$checked': {
      color: '#1D9FD9' // Set the desired color value for the checked state
    }
  },
  checked: {} // Empty placeholder to reference the checked state
});

export default function RegisterForm() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isOtpVerified, setIsOTpVerified] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isOtpMatch, setIsOtpMatch] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [emailOtp, setEmailOtp] = useState('');
  const [isRegistrationCompleted, setIsRegistrationCompleted] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState('');
  const [isExpanded, setIsExpanded] = useState(true);
  const [isCompanyDetailsExpanded, setIsCompanyDetailsExpanded] = useState(false);

  const handleAlert = () => {
    setAlert(false);
  };

  const RegisterSchema = Yup.object().shape({
    // password: Yup.string()
    //   .required('Password is required.')
    //   .matches(
    //     /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    //     'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special Character.'
    //   ),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref('password'), null], 'Password must match.')
    //   .required('Confirm Password is required.'),
    email_otp: Yup.string()
      .matches(/^\d{6}$/, 'OTP must be a 6-digit number.')
      .max(6, 'OTP must be exactly 6 digits.')
      .required('OTP is required.'),
    mobile: Yup.string()
      .matches(/^\d{12}$/, 'Mobile number must be a 10-digit number.')
      .required('Mobile number is required.'),
    language: Yup.string().required('Language is required.'),
    company_name: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{3,49}$/,
        'Company name should be 3 to 50 character long and it should not start with blank space and not have special character and number.'
      )
      .max(50, 'Company name should be 3 to 50 character long.')
      .required('This field is required.'),
    company_email: Yup.string()
      // .email('Email must be a valid email address.')
      .matches(/^[^\s]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, 'Email must be a valid email address. ')
      .required('This field is required.')
      .test('is-not-hotmail', 'Hotmail addresses are not allowed.', (value) => {
        return !value || !value.endsWith('@hotmail.com');
      })
      .test('is-not-gmail', 'Gmail addresses are not allowed.', (value) => {
        return !value || !value.endsWith('@gmail.com');
      })
      .test('no-leading-space', 'Email should not start with a blank space.', (value) => {
        return !value || !value.startsWith(' ');
      }),
    company_mobile: Yup.string()
      .matches(/^\d{12}$/, 'Mobile number must be a 10-digit number.')
      .required('Mobile number is required.')
  });

  const OtpVerificationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{3,29}$/,
        'First name should be 3 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'First name should be 3 to 30 character long.')
      .required('This field is required.'),
    last_name: Yup.string()
      .matches(
        /^(?!^ )[A-Za-z ]{3,29}$/,
        'Last name should be 3 to 30 character long and it should not start with blank space and not have special character and number.'
      )
      .max(30, 'Last name should be 3 to 30 character long.')
      .required('This field is required.'),
    email: Yup.string()
      // .email('Email must be a valid email address.')
      .matches(/^[^\s]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, 'Email must be a valid email address. ')
      .required('This field is required.')
      .test('is-not-hotmail', 'Hotmail addresses are not allowed.', (value) => {
        return !value || !value.endsWith('@hotmail.com');
      })
      .test('no-leading-space', 'Email should not start with a blank space.', (value) => {
        return !value || !value.startsWith(' ');
      })
    // company_name: Yup.string()
    //   .matches(
    //     /^(?!^ )[A-Za-z ]{3,49}$/,
    //     'Company name should be 3 to 50 character long and it should not start with blank space and not have special character and number.'
    //   )
    //   .max(50, 'Company name should be 3 to 50 character long.')
    //   .required('This field is required.'),
    // company_email: Yup.string()
    //   // .email('Email must be a valid email address.')
    //   .matches(/^[^\s]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, 'Email must be a valid email address. ')
    //   .required('This field is required.')
    //   .test('is-not-hotmail', 'Hotmail addresses are not allowed.', (value) => {
    //     return !value || !value.endsWith('@hotmail.com');
    //   })
    //   .test('is-not-gmail', 'Gmail addresses are not allowed.', (value) => {
    //     return !value || !value.endsWith('@gmail.com');
    //   })
    //   .test('no-leading-space', 'Email should not start with a blank space.', (value) => {
    //     return !value || !value.startsWith(' ');
    //   })
  });
  const EmailFormik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      company_name: '',
      company_email: '',
      company_mobile: ''
    },
    validationSchema: OtpVerificationSchema,
    onSubmit: async (values) => {
      // console.log('values', values);
      await API.post(`/auth/otp_generation`, values)
        .then((res) => {
          setShowOtpField(true);
          setTimeRemaining(300);
          setAlertContent(res.data.message);
          setAlert(true);
          setAlertType('success');
        })
        .catch((err) => {
          console.log('errorrrrrtejas', err.response.data.message);
          setAlertContent(err.response.data.message);
          setAlert(true);
          setAlertType('error');
        });
    }
  });
  // console.log('EmailFormik', EmailFormik);
  const formik = useFormik({
    initialValues: {
      first_name: EmailFormik.values.first_name,
      last_name: EmailFormik.values.last_name,
      email: EmailFormik.values.email,
      company_name: '',
      company_email: '',
      company_mobile: '',
      email_otp: '',
      mobile: '',
      language: ''
      // password: '',
      // confirmPassword: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      values.first_name = EmailFormik.values.first_name;
      values.last_name = EmailFormik.values.last_name;
      values.email = EmailFormik.values.email;
      values.email_otp = `${values.email_otp}`;
      // console.log('values', values);
      await API.post(`/auth/register_guest_user`, values)
        .then((res) => {
          setIsRegistrationCompleted(true);
          setAlertContent(res.data.message);
          setAlert(true);
          setAlertType('success');
        })
        .catch((err) => {
          console.log(err);
          setAlertContent(err.response.data.message);
          setAlert(true);
          setAlertType('error');
        });
    }
  });
  // create state for loading resend otp
  const [sendingOtp, setLoadingForResendOtp] = useState(false);
  const handleResendOTP = async () => {
    setLoadingForResendOtp(true);
    const values = {
      first_name: EmailFormik.values.first_name,
      last_name: EmailFormik.values.last_name,
      email: EmailFormik.values.email
    };
    // console.log('values', values);
    await API.post(`/auth/otp_generation`, values)
      .then((res) => {
        setShowOtpField(true);
        setTimerFinished(false);
        setTimeRemaining(300);
        setAlertContent(res.data.message);
        setAlert(true);
        setAlertType('success');
        setLoadingForResendOtp(false);
      })
      .catch((err) => {
        setAlertContent(err.response.data.message ? err.response.data.message : err);
        setLoadingForResendOtp(false);
        setAlert(true);
        setAlertType('error');
      });
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  // console.log('formik', formik);

  // resend OTP functionality with timer set
  const [timeRemaining, setTimeRemaining] = useState(); // 5 minutes in seconds
  const [timerFinished, setTimerFinished] = useState(false);
  // console.log('timerFinished', timerFinished);
  useEffect(() => {
    // Start the timer
    if (timeRemaining) {
      const timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
      // console.log('timer', timer);
      // Clear the timer and mark it as finished when timeRemaining reaches 0
      if (timeRemaining === 1) {
        console.log('checked');
        setTimerFinished(true);
        clearInterval(timer);
      }

      // Clean up the timer when the component unmounts
      return () => {
        clearInterval(timer);
      };
    }
  }, [timeRemaining]);

  // Format the remaining time as mm:ss
  const formattedTime = timeRemaining && new Date(timeRemaining * 1000).toISOString().substr(14, 5);

  return (
    <RootStyle title="Register">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography
            variant="h4"
            sx={{ px: 1.5, mt: 2, mb: 2, textAlign: 'center', color: '#2A236B' }}
          >
            Register and get started!
          </Typography>
          <div style={{ width: '100%', height: '4px', backgroundColor: '#35B5A8' }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '10px'
            }}
          >
            <Typography
              variant="p"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#2A236B',
                fontSize: '14px'
              }}
            >
              Experience a seamless registration process and get started instantly!
            </Typography>
          </Box>
          <Lottie options={defaultOptions} height={400} width={400} />
        </SectionStyle>
      </MHidden>
      {!showOtpField ? (
        <Container maxWidth="sm">
          {/* <ContentStyle> */}
          <Box
            style={{
              //  marginTop: 20,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            <Box>
              <Stack sx={{ mb: 3 }}>
                <Typography variant="h3">Register</Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
              </Stack>

              <FormikProvider value={EmailFormik}>
                <Form autoComplete="off" noValidate onSubmit={EmailFormik.handleSubmit}>
                  <Stack spacing={2}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        name="first_name"
                        fullWidth
                        label="First name"
                        {...EmailFormik.getFieldProps('first_name')}
                        error={Boolean(
                          EmailFormik.touched.first_name && EmailFormik.errors.first_name
                        )}
                        helperText={EmailFormik.touched.first_name && EmailFormik.errors.first_name}
                        size="small"
                      />

                      <TextField
                        name="last_name"
                        fullWidth
                        label="Last name"
                        {...EmailFormik.getFieldProps('last_name')}
                        error={Boolean(
                          EmailFormik.touched.last_name && EmailFormik.errors.last_name
                        )}
                        helperText={EmailFormik.touched.last_name && EmailFormik.errors.last_name}
                        size="small"
                      />
                    </Stack>
                    <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <TextField
                        name="email"
                        fullWidth
                        autoComplete="off"
                        type="email"
                        label="Email address"
                        {...EmailFormik.getFieldProps('email')}
                        error={Boolean(EmailFormik.touched.email && EmailFormik.errors.email)}
                        helperText={EmailFormik.touched.email && EmailFormik.errors.email}
                        size="small"
                      />
                    </Stack>

                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      // onClick={() => {
                      //   setIsSubmit(true);
                      // }}
                      loading={EmailFormik.isSubmitting}
                      style={{
                        backgroundColor: '#3892CF',
                        '&:hover': {
                          backgroundColor: '#3892CF'
                        }
                      }}
                    >
                      Next
                    </LoadingButton>
                  </Stack>
                </Form>
              </FormikProvider>

              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Already have an account?&nbsp;
                <Link
                  variant="subtitle2"
                  // component={RouterLink}
                  onClick={() => navigate('/Login', { replace: true })}
                  sx={{ cursor: 'pointer', color: '#3892CF' }}
                >
                  Login
                </Link>
              </Typography>
            </Box>
          </Box>
          {/* </ContentStyle> */}
        </Container>
      ) : (
        <>
          {isRegistrationCompleted ? (
            <Container maxWidth="sm">
              {/* <ContentStyle> */}
              <Box
                style={{
                  //  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  textAlign: 'center'
                }}
              >
                <Box style={{ marginBottom: 30 }}>
                  <Stack sx={{ mb: 1 }}>
                    <Box
                      style={{
                        //  marginTop: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%'
                      }}
                    >
                      <VerifiedIcon style={{ color: '#0ecf0e', fontSize: 100, marginLeft: 10 }} />
                    </Box>
                    <Typography
                      variant="h3"
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      Thank You!
                    </Typography>
                    <Typography
                      variant="h5"
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      Registration Successful! Welcome to IntelliConvo!
                    </Typography>
                    {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}
                    <span style={{ fontWeight: '600', color: '#0ECF0E', padding: 5, fontSize: 18 }}>
                      Password Sent to Your Email!
                    </span>
                    {/* </div> */}
                  </Stack>

                  <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                    Click here to&nbsp;
                    <Link
                      variant="subtitle2"
                      // component={RouterLink}
                      onClick={() => navigate('/Login', { replace: true })}
                      sx={{ cursor: 'pointer', color: '#3892CF' }}
                    >
                      Login.
                    </Link>
                  </Typography>
                </Box>
              </Box>
              {/* </ContentStyle> */}
            </Container>
          ) : (
            <Container maxWidth="sm">
              <Box
                style={{
                  marginTop: 70,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <Box>
                  <Stack sx={{ mb: 3 }}>
                    <Typography variant="h3">Register</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                      Enter your details below.
                    </Typography>
                  </Stack>
                  <div>
                    <Stack
                      onClick={() => {
                        setIsExpanded(!isExpanded);
                        if (isExpanded === false) {
                          setIsCompanyDetailsExpanded(false);
                        }
                      }}
                      style={{
                        color: 'Black',
                        backgroundColor: '#edeff3',
                        '&:hover': {
                          backgroundColor: '#edeff3'
                        },
                        marginBottom: 15,
                        height: 45,
                        borderRadius: 3,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        cursor: 'pointer'
                      }}
                    >
                      <p
                        style={{
                          marginLeft: 10,
                          color: '#3892CF',
                          fontSize: 15,
                          fontFamily: 'sans-serif',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <AddIcon />
                        &nbsp;Personal Details
                      </p>
                      {isExpanded ? (
                        <ArrowDropUpIcon size={20} htmlColor="#3892CF" titleAccess="Collapse" />
                      ) : (
                        <ArrowDropDownIcon size={20} htmlColor="#3892CF" titleAccess="Collapse" />
                      )}
                    </Stack>
                  </div>
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Stack spacing={2}>
                        <Collapse in={isExpanded}>
                          <>
                            <Stack
                              direction={{ xs: 'column', sm: 'row' }}
                              spacing={2}
                              sx={{ marginBottom: 2 }}
                            >
                              <TextField
                                disabled
                                name="first_name"
                                fullWidth
                                label="First name"
                                value={EmailFormik.values.first_name}
                                // error={Boolean(touched.first_name && errors.first_name)}
                                // helperText={touched.first_name && errors.first_name}
                                size="small"
                              />

                              <TextField
                                disabled
                                name="last_name"
                                fullWidth
                                label="Last name"
                                value={EmailFormik.values.last_name}
                                // error={Boolean(touched.last_name && errors.last_name)}
                                // helperText={touched.last_name && errors.last_name}
                                size="small"
                              />
                            </Stack>
                            <Stack
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                              }}
                              sx={{ marginBottom: 2 }}
                            >
                              <TextField
                                disabled
                                name="email"
                                fullWidth
                                autoComplete="off"
                                type="email"
                                label="Email address"
                                value={EmailFormik.values.email}
                                // error={Boolean(touched.email && errors.email)}
                                // helperText={touched.email && errors.email}
                                size="small"
                                // InputProps={{
                                //   endAdornment: (
                                //     <InputAdornment position="end">
                                //       <IconButton edge="end">
                                //         <VerifiedIcon style={{ color: '#0ecf0e' }} />
                                //       </IconButton>
                                //     </InputAdornment>
                                //   )
                                // }}
                              />
                            </Stack>
                            <Stack sx={{ marginBottom: 2 }}>
                              <TextField
                                name="email_otp"
                                fullWidth
                                autoComplete="off"
                                type="text"
                                label="OTP"
                                {...getFieldProps('email_otp')}
                                error={Boolean(touched.email_otp && errors.email_otp)}
                                helperText={touched.email_otp && errors.email_otp}
                                size="small"
                              />
                              <Typography
                                sx={{
                                  color: 'text.secondary',
                                  fontSize: 12,
                                  marginTop: 1,
                                  paddingLeft: 1
                                }}
                              >
                                <div>
                                  {timerFinished ? (
                                    <LoadingButton
                                      // fullWidth
                                      size="small"
                                      variant="contained"
                                      onClick={handleResendOTP}
                                      loading={sendingOtp}
                                      style={{
                                        backgroundColor: '#3892CF',
                                        '&:hover': {
                                          backgroundColor: '#3892CF'
                                        }
                                      }}
                                      startIcon={<RefreshIcon size={12} color="inherit" />}
                                    >
                                      Resend OTP
                                    </LoadingButton>
                                  ) : (
                                    <p style={{ display: 'flex', flexDirection: 'row' }}>
                                      {`OTP sent to ${EmailFormik.values.email}.`}
                                      OTP Expire in &nbsp;
                                      <b style={{ color: 'red' }}>{formattedTime}</b>
                                    </p>
                                  )}
                                </div>
                              </Typography>
                            </Stack>
                            <PhoneInput
                              style={{ width: '100%', maxWidth: '1000px', marginBottom: 2 }}
                              inputClass={`form-control ${
                                formik.touched.mobile && formik.errors.mobile ? 'is-invalid' : ''
                              }`}
                              country="in"
                              value={formik.values.mobile}
                              onChange={(value) => {
                                formik.setFieldValue('mobile', value);

                                // console.log('valueee', value);
                              }}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.mobile && formik.errors.mobile ? (
                              <div
                                style={{
                                  color: '#D14343',
                                  lineHeight: 1.5,
                                  fontSize: '0.75rem',
                                  fontFamily: 'Public sans,sans-serif',
                                  fontWeight: 300,
                                  textAlign: 'left',
                                  marginTop: '4px',
                                  marginRight: '14px',
                                  marginBottom: 0,
                                  marginLeft: '14px'
                                }}
                              >
                                {formik.errors.mobile}
                              </div>
                            ) : null}
                          </>
                        </Collapse>
                        <Stack
                          onClick={() => {
                            setIsCompanyDetailsExpanded(!isCompanyDetailsExpanded);
                            if (isCompanyDetailsExpanded === false) {
                              setIsExpanded(false);
                            }
                          }}
                          style={{
                            color: 'Black',
                            backgroundColor: '#edeff3',
                            '&:hover': {
                              backgroundColor: '#edeff3'
                            },
                            marginBottom: 15,
                            height: 45,
                            borderRadius: 3,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }}
                        >
                          <p
                            style={{
                              marginLeft: 10,
                              color: '#3892CF',
                              fontSize: 15,
                              fontFamily: 'sans-serif',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <AddIcon />
                            &nbsp;Company Details
                          </p>
                          {isCompanyDetailsExpanded ? (
                            <ArrowDropUpIcon size={20} htmlColor="#3892CF" titleAccess="Collapse" />
                          ) : (
                            <ArrowDropDownIcon
                              size={20}
                              htmlColor="#3892CF"
                              titleAccess="Collapse"
                            />
                          )}
                        </Stack>
                        {/* <Button
                          onClick={() => setIsCompanyDetailsExpanded(!isCompanyDetailsExpanded)}
                          color="primary"
                          fullWidth
                          style={{
                            color: 'white',
                            backgroundColor: '#3892CF',
                            '&:hover': {
                              backgroundColor: '#3892CF'
                            }
                          }}
                          startIcon={
                            isCompanyDetailsExpanded ? (
                              <ExpandLessIcon size={14} color="inherit" />
                            ) : (
                              <ExpandMoreIcon size={14} color="inherit" />
                            )
                          }
                        >
                          Company Details
                        </Button> */}
                        <Collapse in={isCompanyDetailsExpanded}>
                          <>
                            <Stack
                              direction={{ xs: 'column', sm: 'row' }}
                              spacing={2}
                              sx={{ marginBottom: 2 }}
                            >
                              <TextField
                                name="company_name"
                                fullWidth
                                label="Company Name *"
                                {...formik.getFieldProps('company_name')}
                                value={formik.values.company_name}
                                error={Boolean(touched.company_name && errors.company_name)}
                                helperText={touched.company_name && errors.company_name}
                                size="small"
                              />
                            </Stack>
                            <Stack
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                              }}
                              sx={{ marginBottom: 2 }}
                            >
                              <TextField
                                // disabled
                                name="company_email"
                                fullWidth
                                autoComplete="off"
                                type="email"
                                label="Company Email *"
                                {...formik.getFieldProps('company_email')}
                                value={formik.values.company_email}
                                error={Boolean(touched.company_email && errors.company_email)}
                                helperText={touched.company_email && errors.company_email}
                                size="small"
                                // InputProps={{
                                //   endAdornment: (
                                //     <InputAdornment position="end">
                                //       <IconButton edge="end">
                                //         <VerifiedIcon style={{ color: '#0ecf0e' }} />
                                //       </IconButton>
                                //     </InputAdornment>
                                //   )
                                // }}
                              />
                            </Stack>
                            <PhoneInput
                              style={{ width: '100%', maxWidth: '1000px', marginBottom: 2 }}
                              inputClass={`form-control ${
                                formik.touched.company_mobile && formik.errors.company_mobile
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              country="in"
                              value={formik.values.company_mobile}
                              onChange={(value) => {
                                formik.setFieldValue('company_mobile', value);

                                // console.log('valueee', value);
                              }}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.company_mobile && formik.errors.company_mobile ? (
                              <div
                                style={{
                                  color: '#D14343',
                                  lineHeight: 1.5,
                                  fontSize: '0.75rem',
                                  fontFamily: 'Public sans,sans-serif',
                                  fontWeight: 300,
                                  textAlign: 'left',
                                  marginTop: '4px',
                                  marginRight: '14px',
                                  marginBottom: 0,
                                  marginLeft: '14px'
                                }}
                              >
                                {formik.errors.company_mobile}
                              </div>
                            ) : null}
                            {/* {isSubmit && formik.values.company_mobile === '' && (
                              <div
                                style={{
                                  color: '#D14343',
                                  lineHeight: 1.5,
                                  fontSize: '0.75rem',
                                  fontFamily: 'Public sans,sans-serif',
                                  fontWeight: 300,
                                  textAlign: 'left',
                                  marginTop: '4px',
                                  marginRight: '14px',
                                  marginBottom: 0,
                                  marginLeft: '14px'
                                }}
                              >
                                Phone number is required.
                              </div>
                            )} */}
                          </>
                        </Collapse>
                        <FormControl fullWidth size="small">
                          <InputLabel required id="language-select-label">
                            Transcript Language
                          </InputLabel>
                          <Selectbox
                            required
                            labelId="language-select-label"
                            label="Transcript Language"
                            id="select-Language"
                            {...getFieldProps('language')}
                            error={Boolean(touched.language && errors.language)}
                            helperText={touched.language && errors.language}
                            size="small"
                          >
                            <MenuItem key="hindi" value="Hindi">
                              Hindi
                            </MenuItem>
                            <MenuItem key="english" value="English">
                              English
                            </MenuItem>
                          </Selectbox>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {touched.language && errors.language}
                          </FormHelperText>
                        </FormControl>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <FormLabel
                            id="demo-radio-buttons-group-label"
                            style={{ color: '#4B5563', marginRight: 15 }}
                          >
                            Account Type :
                          </FormLabel>
                          <FormControl component="fieldset" style={{ display: 'flex' }}>
                            <RadioGroup defaultValue="option1">
                              <FormControlLabel
                                value="option1"
                                label="Guest User"
                                control={
                                  <Radio
                                    classes={{ root: classes.radio, checked: classes.checked }}
                                    size="small"
                                  />
                                }
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>

                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          onClick={() => {
                            setIsSubmit(true);
                          }}
                          loading={isSubmitting}
                          style={{
                            backgroundColor: '#3892CF',
                            '&:hover': {
                              backgroundColor: '#3892CF'
                            }
                          }}
                        >
                          Register
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>

                  <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                    Already have an account?&nbsp;
                    <Link
                      variant="subtitle2"
                      // component={RouterLink}
                      onClick={() => navigate('/Login', { replace: true })}
                      sx={{ cursor: 'pointer', color: '#3892CF' }}
                    >
                      Login
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </Container>
          )}
        </>
      )}
      <Notification
        message={alertContent}
        open={alert}
        severity={alertType}
        onClose={handleAlert}
      />
    </RootStyle>
  );
}
