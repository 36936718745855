import { useState, React, useEffect } from 'react';
// import TableComponent from '../TableComponent/TableComponent';
import UBITabs from './UBITabs';

function UBIDashboard() {
  return (
    <>
      <UBITabs />
    </>
  );
}

export default UBIDashboard;
