import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label
} from 'recharts';

const CollectionBarChart = ({
  data,
  setShownCategory,
  setPagePopupForCollection,
  handleCollectionPopup,
  handleDialogForCollection
}) => {
  const chartData =
    data && Object.keys(data).length > 0
      ? Object.keys(data).map((el, index) => ({
          name: el,
          Count: data[el]
        }))
      : [];

  const legendStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'right',
    listStyleType: 'none'
  };

  // CSS for the circular legend marker
  const legendIconStyle = {
    width: '10px', // Adjust the size as needed
    height: '10px', // Adjust the size as needed
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '5px' // Adjust spacing
  };

  // Apply a cursive font-family to the Legend
  const legendTextStyle = {
    fontFamily: 'arial',
    fontWeight: 'bold',
    fontSize: 12
  };
  const CustomTickFormatter = (tick) => Math.round(tick);
  const CustomLegendForSummary = (e) => (
    <div
      style={{
        fontFamily: 'arial',
        fontWeight: 'bold',
        fontSize: 12,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      {e.payload.map((entry, index) => (
        <li key={index} style={{ listStyleType: 'none' }}>
          <span
            className="legend-icon"
            style={{ ...legendIconStyle, backgroundColor: entry.color }}
          />
          <span style={legendTextStyle}>Category</span>
        </li>
      ))}
    </div>
  );
  return (
    <>
      {chartData && chartData.length > 0 ? (
        <ResponsiveContainer width="100%" height="86%">
          <BarChart
            data={chartData}
            margin={{
              right: 30,
              left: 20
            }}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis
              dataKey="name"
              fontFamily="Roboto, sans-serif"
              angle={-45} // Rotate labels vertically
              height={50}
              reversed
              fontWeight="bold"
              tick={{
                fontFamily: 'cursive, sans-serif',
                fontSize: 9,
                fontWeight: 'bold'
              }}
              tickLine={false}
              // tickFormatter={shortenLabel}
            />

            <YAxis
              // dataKey="values"
              label={{
                value: 'Call Count',
                angle: -90,
                position: 'insideLeft',
                fontWeight: 'bold',
                color: 'grey'
              }}
              tickFormatter={CustomTickFormatter}
              //   domain={[0, 'dataMax']}
              //   interval="preserveStartEnd"
              tick={{ fontFamily: 'cursive, sans-serif', fontSize: 10, fontWeight: 'bold' }}
              tickLine={false}
              fontWeight="bold"
            />
            {/* <Tooltip content={CustomTooltipForSummary} /> */}
            <Tooltip />
            <Bar
              // key={index}
              barSize={25}
              dataKey="Count"
              onClick={(e) => {
                // setShownCategory(e.name);
                setPagePopupForCollection(1);
                handleCollectionPopup(e);
                handleDialogForCollection();
              }}
              stackId="a"
              fill="#3892CF"
              onMouseOver={(e) => {
                // console.log(e);
                // setBarName(entry.name);
              }}
              style={{ opacity: 0.9, borderRadius: 5, cursor: 'pointer' }}
            />
            <Legend content={<CustomLegendForSummary />} />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div
          style={{
            width: '100%',
            height: '80%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <span style={{ fontSize: 20 }}>
            <b>No Category</b>
          </span>
        </div>
      )}
    </>
  );
};

export default CollectionBarChart;
